// Modules
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'
import prop from 'lodash/fp/prop'
import find from 'lodash/fp/find'
import where from 'lodash/fp/where'
import head from 'lodash/fp/head'
import contains from 'lodash/fp/contains'
import slice from 'lodash/fp/slice'
import uniqBy from 'lodash/fp/uniqBy'

// Routing
import { history } from 'utils/history'


const getCityName = compose(
  prop('long_name'),
  find(
    where({
      types: contains('locality'),
    })
  ),
  prop('addressComponents'),
)

export const getCityNameAdapter = compose(
  getCityName,
  head,
  prop('locations')
)

const getCountsByLocation = item => ({
  placeId: item?.googlePlaceId,
  description: item?.location_city,
  talentsCount: item?.talentsCount,
  offersCount: item?.offersCount,
  latitude: item?.latitude,
  longitude: item?.longitude,
})

export const getCountsByLocationAdapter = compose(
  map(getCountsByLocation),
  slice(0, 5),
  uniqBy(prop('location_city')),
  prop('counts'),
)

export const onItemClick = ({
  redirectUrl,
  setter,
  callback,
}) =>
  ({
    description,
    latitude,
    longitude,
  }) => {
    setter({
      location: {
        label: description,
        value: {
          latitude,
          longitude,
        }
      }
    })
    callback()
    history.push(redirectUrl)
  }
