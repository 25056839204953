import React from 'react'

import { isUserHasTalent } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'

import TalentCalendar from '../../Calendar/TalentCalendar'
import UserCalendar from '../UserCalendar'

const CalendarHandler = () => {
  const hasTalent = useUser(isUserHasTalent)
  return hasTalent ? <TalentCalendar /> : <UserCalendar />
}

export default CalendarHandler
