// Utils
import { _get } from 'api/utils'


export const getPromotedTalents = ({ query }) => _get(
  '/searches/results/talents/search/promoted',
  { needAuthorization: true, query }
)

export const getPromotedOffers = ({ query }) => _get(
  '/searches/results/offers/search/promoted',
  { needAuthorization: true, query }
)
