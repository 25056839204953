import { BehaviorSubject } from 'rxjs'
import { CalendarSlotType, TalentOffersType } from 'shared'
import { addWeeks, startOfWeek } from 'date-fns'

import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

const TALENT_CALENDAR_SLOTS = 'talentCalendarSlots'
const TALENT_CALENDAR_FILTERS = 'talentCalendarFilters'
const TALENT_CALENDAR_BUSINESS_HOURS = 'talentCalendarBusinessHours'

const startOfActiveWeek = startOfWeek(new Date(), { weekStartsOn: 1 })
const lastDayOfActiveWeek = addWeeks(startOfActiveWeek, 1)

export const INITIAL_STATE = {
  TALENT_CALENDAR_SLOTS: null,
  TALENT_CALENDAR_BUSINESS_HOURS: null,
  TALENT_CALENDAR_FILTERS: {
    page: 1,
    startTime: new Date(),
    endTime: lastDayOfActiveWeek,
    typeIn: [CalendarSlotType.TIME_UNAVAILABLE, CalendarSlotType.MY_EVENT, 'SYNCED_CALENDAR'],
    occurrenceTypeIn: [
      TalentOffersType.IN_PERSON_EXPERIENCE,
      TalentOffersType.VIRTUAL_EVENT,
      TalentOffersType.VIDEO_CHAT,
      'LESSONS',
    ],
  }
}

export const useTalentCalendarSlots = createUseGlobalState(TALENT_CALENDAR_SLOTS)
export const useSetTalentCalendarSlots = createUseSetGlobalState(TALENT_CALENDAR_SLOTS)

export const useTalentCalendarFilters = createUseGlobalState(TALENT_CALENDAR_FILTERS)
export const useSetTalentCalendarFilters = createUseSetGlobalState(TALENT_CALENDAR_FILTERS)

export const useTalentCalendarBusinessHours = createUseGlobalState(TALENT_CALENDAR_BUSINESS_HOURS)
export const useSetTalentCalendarBusinessHours = createUseSetGlobalState(TALENT_CALENDAR_BUSINESS_HOURS)

export const talentCalendarSlots = new BehaviorSubject(INITIAL_STATE.TALENT_CALENDAR_SLOTS)
export const talentCalendarBusinessHours = new BehaviorSubject(INITIAL_STATE.TALENT_CALENDAR_BUSINESS_HOURS)
export const talentCalendarFilters = new BehaviorSubject(INITIAL_STATE.TALENT_CALENDAR_FILTERS)
