import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { getDirtyValues } from './getDirtyValues';

import {
  submitAddressAdapter,
  submitBiographiesAdapter,
  submitCharitiesWithoutPercentAdapter,
  submitSocialMediaAdapter,
  submitLicensesAdapter,
  submitMultiSelectLibraryAdapter,
  submitLanguagesAdapter, submitCategoryArrayAdapter, submitCategoryAdapter,
  submitTimeZoneAdapter,
} from 'utils/forms/adapters'
import { formatDateOfBirth } from 'utils/forms/adapters/dateOfBirth'


function getUser(user, allUserValues) {
  if (isEmpty(user) && isEmpty(allUserValues)) return

  // backend need all value
  const address = get(allUserValues, 'address', {});
  const dateOfBirth = get(user, 'dateOfBirth')
  return {
    profileImage: get(user, 'profileImage'),
    backupEmail: get(user, 'backupEmail')  || undefined,
    address: submitAddressAdapter(address),
    timeZoneId: submitTimeZoneAdapter(user?.timezone),
    dateOfBirth: dateOfBirth ? formatDateOfBirth(dateOfBirth) : undefined,
    socialSecurityOrTIN: get(user, 'socialSecurityOrTIN'),
    licenses: submitLicensesAdapter(user?.licenses),
    phone: get(user, 'phone'),
    verifyPhoneKey: get(user, 'verifyPhoneKey'),
    socialMedia: submitSocialMediaAdapter(user?.socialMedia),
    profileMedia: submitMultiSelectLibraryAdapter(allUserValues?.profileMedia, allUserValues?.profileMediaPrimaryFile),
    taxpayerStatus: get(user, 'taxpayerStatus'),
  }
}

function getTalent(talent) {
  if (isEmpty(talent)) return;
  return {
    location: get(talent, 'location.value'),
    fullBodyImage: get(talent, 'fullBodyImage'),
    halfBodyImage: get(talent, 'halfBodyImage'),
    profileImage: get(talent, 'profileImage'),
    ownWords: get(talent, 'ownWords'),
    nationality: get(talent, 'nationality.value'),
    languages: submitLanguagesAdapter(talent?.languages),
    biographies: submitBiographiesAdapter(talent?.biographies),
    charities: submitCharitiesWithoutPercentAdapter(talent?.charities),
    licenses: submitLicensesAdapter(talent?.licenses),
    categories: submitCategoryArrayAdapter(get(talent, 'categories')),
    primaryCategory: submitCategoryAdapter(get(talent, 'primaryCategory')),
    title: get(talent,'title'),
    promoCode: get(talent, 'promoCode.id', null)
  }
}

export const submitAdapter = (allValues, dirtyFields) => {
  const values = getDirtyValues(dirtyFields, allValues)

  return {
    user: getUser(values?.user, allValues?.user),
    talent: getTalent(values?.talent),
    talentUserUser: getUser(values?.talentUserUser, allValues?.talentUserUser)
  };
};
