const ACCESS_TOKEN_CONSTANT = 'ACCESS_TOKEN';
const REFRESH_TOKEN_CONSTANT = 'REFRESH_TOKEN';
const TWILIO_ACCESS_TOKEN_CONSTANT = 'TWILIO_ACCESS_TOKEN_CONSTANT';
const USER_DATA_CONSTANT = 'USER_DATA';
const ENTITIES_CONSTANT = 'ENTITIES';
const FILE_LIBRARY_CONSTANT = 'FILE_LIBRARY';

export const handleLocalStorage = (CONSTANT) => {
  const setData = (data) => {
    localStorage.setItem(CONSTANT, JSON.stringify(data));
  };
  const getData = () => {
    try {
      return JSON.parse(localStorage.getItem(CONSTANT));
    }
    catch {
      return;
    }
  };
  const removeData = () => {
    localStorage.removeItem(CONSTANT);
  };
  return [setData, getData, removeData];
};

export const [setAccessToken, getAccessToken, removeAccessToken] = handleLocalStorage(ACCESS_TOKEN_CONSTANT);
export const [setRefreshToken, getRefreshToken, removeRefreshToken] = handleLocalStorage(REFRESH_TOKEN_CONSTANT);
export const [setTwilioAccessToken, getTwilioAccessToken, removeTwilioAccessToken] = handleLocalStorage(TWILIO_ACCESS_TOKEN_CONSTANT);
export const [setUserData, getUserData, removeUserData] = handleLocalStorage(USER_DATA_CONSTANT);
export const [setEntitiesData, getEntitiesData, removeEntitiesData] = handleLocalStorage(ENTITIES_CONSTANT);
export const [setLibraryData, getLibraryData, removeLibraryData] = handleLocalStorage(FILE_LIBRARY_CONSTANT);

export const setTokens = ({ accessToken, refreshToken, twilioAccessToken }) => {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setTwilioAccessToken(twilioAccessToken);
};

export const clearTokens = () => {
  removeAccessToken();
  removeRefreshToken();
  removeTwilioAccessToken();
};
