import { format, formatDuration, intervalToDuration } from 'date-fns'
import get from 'lodash/get'
import { isValid, isEqual } from 'date-fns'
import { map, pluck, compose, propOr, range, identity } from 'lodash/fp'

import { parseDate, prepareDate } from 'utils/services/timezone/'
import { getLocationFormattedAddress, getLocationName } from 'utils/location'
import { getMonthDayYearDate } from 'utils/date'

// date utils
export const toDate = date => new Date(date)

export const getTimeFormat = time => format(toDate(time), 'hh:mm aaa')
export const getDateFormat = date => format(toDate(date), 'MMMM dd, yyyy')

export const getDateValue = date => toDate(date).toString()

export function getRangeTime(time) {
  const startTime = new Date(get(time, 'startTime', ''))
  const endTime = new Date(get(time, 'endTime', ''))

  return isValid(startTime) && isValid(endTime)
    ? `${getTimeFormat(startTime)} - ${getTimeFormat(endTime)}`
    : ''
}

export function getHumanDuration(duration) {
  if (typeof duration === 'number' && duration > 0) {
    return formatDuration(intervalToDuration({ start: 0, end: duration * 1000 }))
  }
  return 0
}

export function getDuration(startTime, endTime) {
  if (isValid(startTime) && isValid(endTime)) {
    return formatDuration(
      intervalToDuration({
        start: new Date(startTime),
        end: new Date(endTime),
      })
    )
  }
  return null
}

// select options
// location
export const getLocationOption = location => ({
  title: getLocationName(location),
  label: getLocationFormattedAddress(location),
  value: location.id,
})
export const getLocationOptions = compose(
  map(getLocationOption),
  propOr([], 'locations')
)

// participant
export const getParticipantOption = participant => ({
  label: String(participant),
  value: participant,
})
export const getParticipantOptions = compose(
  map(getParticipantOption),
  range(0),
  propOr(0, 'maxNumberOfParticipants')
)

// date
export const getDateOption = date => ({
  value: getDateValue(date),
  label: getDateFormat(date),
})
export const getDateOptions = compose(
  map(getDateOption),
  pluck('date')
)

// time window
export const getTimeWindowOption = _window => ({
  value: getDateValue(_window.startTime),
  label: getRangeTime(_window),
})

export const transformSlotWithTimezone = (transformer = identity) => timezone => slot => ({
  ...slot,
  date: transformer(slot?.date, timezone),
  startTime: transformer(slot?.startTime, timezone),
  endTime: transformer(slot?.endTime, timezone),
})

export const mapSlotToTimezone = transformSlotWithTimezone(parseDate)
export const mapSlotToUTC = transformSlotWithTimezone(prepareDate)

// First argument set - date to compare, second argument set - experience slot
export const isSlotEqualToSelectedDate = watchedDate => ({ date }) => isEqual(new Date(date), new Date(watchedDate))
export const isSlotEqualToSelectedTime = watchedTime => ({ startTime }) => isEqual(new Date(startTime), new Date(watchedTime))

export const getFixedDateOption = (slot) => ({
  value: slot.offerExperienceOccurrenceId,
  label: getMonthDayYearDate(slot.startTime)
})
