import styled from 'styled-components';

export const StyledLicenseList = styled.ul`
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    overflow-y: hidden;

    margin: 4px 0 0 -16px;
    width: calc(100% + 16px);
`;

