import { api, types } from 'shared';
import { map } from 'lodash';

const { Levels, List, NationalitiesList } = types.languages;
const { ProfileManager, ProfileType } = types.talent;
const { sendNotificationEmails, sendNotificationPhone } = api.auth.signUp.asTalent.query;

export const profileTypes = [
  { name: 'person', value: ProfileType.PERSON },
  { name: 'company', value: ProfileType.COMPANY, disabled: true },
];

export const profileManagers = [
  { name: 'self', value: ProfileManager.SELF  },
  { name: 'agent', value: ProfileManager.AGENT  },
  { name: 'manager', value: ProfileManager.MANAGER  },
  { name: 'company-representative', value: ProfileManager.COMPANY_REPRESENTATIVE, disabled: true }
];

export const nationalities = map(NationalitiesList, (value, key) => ({
  label: value.name,
  value: key
}));

export const languagesList = map(List, (value, key) => ({
  label: value.native,
  value: key
}));

export const levelOptions = (t) => [
  {
    label: t('register.languages.language-levels.native'),
    value: Levels.NATIVE,
  },
  {
    label: t('register.languages.language-levels.fluent'),
    value: Levels.FLUENT,
  },
  {
    label: t('register.languages.language-levels.basic'),
    value: Levels.BASIC
  },
];

export const calendarOptions = [
  { value: 'qqq', label: 'qqq' },
  { value: 'www', label: 'www' },
  { value: 'eee', label: 'eee' },
];

export const emailNotificationType = [
  {
    label: 'Both',
    value: sendNotificationEmails.BOTH,
  },
  {
    label: 'None',
    value: sendNotificationEmails.NONE,
  },
  {
    label: 'Send notifications to talent',
    value: sendNotificationEmails.SEND_NOTIFICATION_TO_TALENT,
  },
  {
    label: 'Send notification to representative',
    value: sendNotificationEmails.SEND_NOTIFICATION_TO_REPRESENTATIVE,
  },
];

export const phoneNotificationType = [
  {
    label: 'Both',
    value: sendNotificationPhone.BOTH,
  },
  {
    label: 'None',
    value: sendNotificationPhone.NONE,
  },
  {
    label: 'Send notifications to talent',
    value: sendNotificationPhone.SEND_NOTIFICATION_TO_TALENT,
  },
  {
    label: 'Send notification to representative',
    value: sendNotificationPhone.SEND_NOTIFICATION_TO_REPRESENTATIVE,
  },
];


export const getEmailLabel = (t, profileManager) => {
  const emailLabel = {
    [ProfileManager.SELF]: t('register.form.email.label.talent'),
    [ProfileManager.AGENT]: t('register.form.email.label.agent'),
    [ProfileManager.MANAGER]: t('register.form.email.label.manager'),
    ['TALENT_USER']: t('register.form.email.label.talent'),
  };

  return emailLabel[profileManager || ProfileManager.SELF];
};

export const getLicenseLabel = (t, profileManager) => {
  const licenseLabel = {
    [ProfileManager.SELF]: t('register.form.licenses.label.talent'),
    [ProfileManager.AGENT]: t('register.form.licenses.label.agent'),
    [ProfileManager.MANAGER]: t('register.form.licenses.label.manager'),
    ['TALENT_USER']: t('register.form.licenses.label.talent'),
  };

  return licenseLabel[profileManager || ProfileManager.SELF];
};

export const getProfileTypesWithTranslate = (t) => profileTypes.map(type => ({
  ...type,
  label: t(`register.form.profile-type.options.${type.name}`)
}));

export const getProfileManagersWithTranslate = (t) => profileManagers.map(manager => ({
  ...manager,
  label: t(`register.form.profile-manager.options.${manager.name}`)
}));
