import React from 'react'

import { notificationConfig } from '_legacy/components/Notification'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

export default React.memo(function Notification({ isLoading, ...props }) {
  if (isLoading) return (
    <div className='notification-table-loader'>
      <Loader className='notification-table-loader__icon' />
      <Loader className='notification-table-loader__message' />
    </div>
  )

  const Component = notificationConfig(props)
  return <Component {...props} isTable />
})
