import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'

import BasicDesktopOverlay from '_legacy/components/TalentPublicView/Slider/Overlay/BasicDesktopOverlay';

import Tooltip from '../../../../../Tooltip'

const Overlay = ({ date, name, video }) => {
  const classes = cx('tbv-slider-slide-overlay-live-experience', {
    'tbv-slider-slide-overlay-live-experience--video': video
  })

  return (
    <BasicDesktopOverlay withGradientOverhead>
      <div className={classes}>
        <Tooltip tooltipElement={name}>
          <h3 className="tbv-slider-slide-overlay-live-experience-name">
            {name}
          </h3>
        </Tooltip>
        {date && (
          <h4 className="tbv-slider-slide-overlay-live-experience-date">
            {date}
          </h4>
        )}
      </div>
    </BasicDesktopOverlay>
  );
};

Overlay.propTypes = {
  date: PropTypes.string,
  name: PropTypes.string.isRequired,
  video: PropTypes.bool,
};

export default Overlay;
