import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  color: ${({ whiteTheme }) => (whiteTheme ? '#fff' : '#000')};

  &:not(:last-of-type) {
    margin-right: 45px;
    @media screen and (max-width: 425px) {
      margin-right: 0;
    }
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &:checked + i:after {
      content: ' ';
      background: ${({ whiteTheme }) => (whiteTheme ? '#fff' : '#000')};
      height: 12px;
      width: 12px;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  i {
    position: relative;
    height: 26px;
    width: 26px;
    min-width: 26px;
    margin-right: 8px;
    background: transparent;
    border: 2px solid ${({ whiteTheme }) => (whiteTheme ? '#fff' : '#000')};
    cursor: pointer;
    appearance: none;
    outline: none;
    border-radius: 50%;
  }
`;

const Radio = ({
  onClick,
  name,
  value,
  defaultChecked,
  label,
  className,
  disabled,
  whiteTheme,
}) => {
  const { register } = useFormContext();

  return (
    <StyledRadio className={className} whiteTheme={whiteTheme}>
      <input
        type="radio"
        ref={register}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onClick={onClick}
        disabled={disabled}
      />
      <i />
      {label}
    </StyledRadio>
  );
};

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  whiteTheme: PropTypes.bool,
};

export default Radio;
