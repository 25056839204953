import { get } from 'lodash'

import { getCharitiesForForm } from 'utils/services/loaders/charities'
import { getDaysTimesTypeOptions } from '../../utils.js'


export const liveVirtualLessonFormAdapter = (values, t) => {
  const {
    active,
    lengthOfTime,
    price,
    charities,
    specialInstructions,
    daysTimesType,
  } = values

  const mediaId = get(values, 'media.file.fileToken')

  return {
    active: String(active),
    lengthOfTime: String(lengthOfTime / 60),
    price: String(price),
    charities: getCharitiesForForm(charities),
    media: mediaId,
    mediaTemp: mediaId,
    specialInstructions,
    daysTimesType: getDaysTimesTypeOptions(t).find(option => option.value === daysTimesType),
  }
}
