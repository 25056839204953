
import reject from 'lodash/fp/reject'
import propEq from 'lodash/fp/propEq'
import anyPass from 'lodash/fp/anyPass'
import compose from 'lodash/fp/compose'
import size from 'lodash/fp/size'
import filter from 'lodash/fp/filter'
import identity from 'lodash/fp/identity'

import { OrderStatus } from 'shared'


const {
  APPROVED,
  REJECTED,
  COMPLETED,
  CLOSED,
  DRAFT,
  CANCELED,
} = OrderStatus

const hasStatusFilter = propEq('status')
const hasStatus = status => compose(
  size,
  filter(hasStatusFilter(status))
)

const hasEndingStatus = anyPass([
  hasStatus(COMPLETED),
  hasStatus(REJECTED),
  hasStatus(CLOSED),
  hasStatus(CANCELED),
])

export const removeDraftStatus = reject(hasStatusFilter(DRAFT))
export const removeClosedStatus = reject(hasStatusFilter(CLOSED))
export const removeClosedStatusIfAficionado = isAficionado => isAficionado ? removeClosedStatus : identity
const draftCompletedStep = {
  status: COMPLETED,
}

const draftApprovedStep = {
  status: APPROVED,
}

export const addCompletedStep = arr => {
  if(!hasEndingStatus(arr)) {
    arr.push(draftCompletedStep)
  }
  return arr
}

export const addApprovedStep = arr => {
  if(!hasStatus(APPROVED)(arr)) {
    arr.push(draftApprovedStep)
  }
  return arr
}
