import React from 'react';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import BackdropWrapper from '../../components/Backdrop/BackdropWrapper';

const MediaBackdrop = ({ maxSize }) => {
  const t = useGetTranslate()
  const { setValue } = useFormContext()
  const onClick = () => setValue(name, null)

  return (
    <MediaBackdropWrapper>
      <i onClick={onClick} className="icon-frame icon-camera" style={{ paddingTop: 40 }} />
      <h3>{t('video-message.form.media.placeholder.title')}</h3>
      <p>
        <Trans tKey="video-message.form.media.placeholder.text" phs={[{ ph: '{{max-size}}', value: maxSize }]} />
      </p>
    </MediaBackdropWrapper>
  )
}

const MediaBackdropWrapper = styled(BackdropWrapper)`
  i {
    margin-top: 62px;
  }

  h3 {
    margin-bottom: 32px;
  }
`

export default MediaBackdrop
