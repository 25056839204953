import { api } from 'shared';

const { errors: errorsKeysInfo } = api.auth.resetPassword.info;
const { errors: errorsKeysSubmit } = api.auth.resetPassword.submit;

export const sharedErrors = {
  [errorsKeysInfo.UNKNOWN_KEY]: 'reset-password.errors.unknown-key',
  [errorsKeysInfo.KEY_IS_EXPIRED]: 'reset-password.errors.key-is-expired',
  [errorsKeysInfo.KEY_ALREADY_USED]: 'reset-password.errors.key-already-use',
  [errorsKeysSubmit.REPEAT_PASSWORD]: 'reset-password.errors.repeat-password',
};
