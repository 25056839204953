import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const FormRow = ({ left, right, rowClassName, rowStyle, leftItemStyle, rightItemStyle }) => (
  <div className={cx('form-row', rowClassName)} style={rowStyle}>
    <div style={leftItemStyle} className="form-row__item">{left}</div>
    <div  style={rightItemStyle} className="form-row__item">
      <div className="form-row__width">{right}</div>
    </div>
  </div>
);

FormRow.propTypes = {
  left: PropTypes.element,
  right: PropTypes.element,
  rowClassName: PropTypes.string,
  rowStyle: PropTypes.object,
  leftItemStyle: PropTypes.object,
  rightItemStyle: PropTypes.object,
};

export default FormRow;
