import React from 'react'

import {
  useReviewsOfMeFilters,
  useReviewsOfMeTable,
  useSetReviewsOfMeFilters,
} from 'store/hooks/globalState/reviws/useReviewsOfMe'
import {
  counterConfig,
  fields,
  subFiltersConfig,
} from '_legacy/configs/table/reviewsOfMe'
import { useFetchReviewsOfMe } from 'requests/reviews/reviews-of-me'

import { REVIEWS } from '_legacy/components/Table/table-components/types/reviews/config'
import { elementsTypes } from '_legacy/components/Table/table-components/types/reviews/config'

import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'

const tableConfig = [
  {
    header: 'table.header.offering',
    field: 'occurrenceOfferType',
    type: elementsTypes.offering,
  },
  {
    header: 'table.header.aficionado-review',
    field: 'rating',
    type: elementsTypes.review,
  },
  {
    header: 'table.header.from',
    field: 'from',
    type: elementsTypes.user,
  },
  {
    header: 'table.header.date',
    field: 'createdAt',
    type: elementsTypes['review-date'],
  },
]

const ReviewsOfMe = () => {
  // EN-3151 hide options "Of Me"
  // const actionsConfig = React.useMemo(() => {
  //   return [
  //     {
  //       label: 'table.actions.request-deletion',
  //       cbFn: () => alert('This action isn\'t implemented'),
  //     },
  //     {
  //       label: 'table.actions.message-aficionado',
  //       cbFn: () => alert('This action isn\'t implemented'),
  //     },
  //   ]
  // }, [])

  return (
    <React.Fragment>
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        setFn={useSetReviewsOfMeFilters}
        getFn={useReviewsOfMeFilters}
      />
      <Table
        fields={fields}
        tableName={REVIEWS}
        tableConfig={tableConfig}
        // actionsConfig={actionsConfig}
        getTableFn={useReviewsOfMeTable}
        getFiltersFn={useReviewsOfMeFilters}
        setFiltersFn={useSetReviewsOfMeFilters}
        fetchTableFn={useFetchReviewsOfMe}
      />
    </React.Fragment>
  )
}

export default ReviewsOfMe
