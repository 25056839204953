import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import ModalPortal from 'portals/ModalPortal';

import getTypeInvite from '_legacy/components/JoinToTalentContent/utils';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { LIST, PROFILE_MANAGERS } from '_legacy/constants/routes';
import { useStateModal } from 'store/hooks/globalState/useModal';

const JoinToTalentModal = ({ type, items }) => {
  const t = useGetTranslate();
  const closeModal = useStateModal(() => null)
  const [active, setActive] = React.useState(0);

  const openTab = idx => e => {
    e?.preventDefault();
    setActive(idx);
  };

  return (
    <ModalPortal isOpen showCloseButton>
      <div className='joinToTalent-modal'>
        <h2 className='joinToTalent-modal__title'>
          {t(`join-to-talent.title.${getTypeInvite(type)}`)}
        </h2>
        <Link
          to={`/${PROFILE_MANAGERS}/${LIST}`}
          onClick={closeModal}
          className='joinToTalent-modal__user-link joinToTalent-modal__user-link--top'>
          {t('join-to-talent.link-manager')}
        </Link>
        <div className='joinToTalent-modal__wrapper'>
          <nav className='joinToTalent-modal__nav'>
            <ul className='joinToTalent-modal__tabs'>
              {items?.map(({ title }, idx) => (
                <li key={title} className='joinToTalent-modal__tabs_item'>
                  <button
                    className={`joinToTalent-modal__tabs_btn ${active === idx ? 'active' : ''}`}
                    onClick={openTab(idx)}
                  >{t(title)}</button>
                </li>
              ))}
            </ul>
            <Link
              to={`/${PROFILE_MANAGERS}/${LIST}`}
              onClick={closeModal}
              className='joinToTalent-modal__user-link'>
              {t('join-to-talent.link-manager')}
            </Link>
          </nav>
          <div className='joinToTalent-modal__content'>
            {items[active].component}
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

JoinToTalentModal.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    component: PropTypes.oneOfType(
      [
        PropTypes.arrayOf([PropTypes.node]),
        PropTypes.node,
        PropTypes.func
      ])
  })).isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default JoinToTalentModal;
