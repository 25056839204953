import React from 'react'
import get from 'lodash/get'

import { Trans } from 'store/hooks/globalState/useTranslates'

const OrderSlot = props => {
  const timeText = get(props, 'timeText', '')
  const title = get(props, 'event.title', '')

  return (
    <div className="calendar-slot calendar-slot--top">
      <div className="calendar-slot__info calendar-slot__info--center">
        <div className="calendar-slot__info__title">{title || <Trans tKey="calendar.slot.default-title" />}</div>
        <div className="calendar-slot__info__desc">{timeText}</div>
      </div>
    </div>
  )
}

export default OrderSlot
