import React from 'react'
import styled from 'styled-components';

import Title from './Title'
import ReasonsList from './ReasonsList'

function TalentMemberBenefitsSection() {
  return (
    <StyledSection className="section">
      <div className='content wrapper content-wrapper styled-wrapper'>
        <Title />
        <ReasonsList />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 100%;
  
  .styled-wrapper {
    @media only screen and (max-width: 1440px) {
      padding: 0 40px 0;
    }
    @media only screen and (max-width: 1024px) {
      padding: 0 24px 0;
    }
    @media only screen and (max-width: 480px) {
      padding: 0 16px 0;
    }

    @media only screen and (max-height: 715px) {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
  }
`

export default TalentMemberBenefitsSection
