import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import esLocale from '@fullcalendar/core/locales/es-us'
import ptLocale from '@fullcalendar/core/locales/pt'

import CalendarSlot from '../components/CalendarSlot'

import { useUserLang } from 'store/hooks/globalState/useTranslates'
import { buildTitleDateFormat } from '../utils/buildTitleDateFormat/buildTitleDateFormat'
import { buildDayHeaderFormat } from '../utils/buildDayHeaderFormat/buildDayHeaderFormat'

const BaseCalendar = React.forwardRef((props, ref) => {
  const userLang = useUserLang()

  const locale = {
    ES: esLocale,
    PT: ptLocale
  }[userLang]

  return (
    <FullCalendar
      ref={ref}
      scrollTime="08:00"
      plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
      locale={locale}
      {...props}
      firstDay={1}
      slotDuration='00:15:00'
      slotLabelInterval='01:00'
      eventContent={CalendarSlot}
      titleFormat={buildTitleDateFormat}
      dayHeaderFormat={buildDayHeaderFormat}
    />
  )
})

export default BaseCalendar
