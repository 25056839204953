import React from 'react'
import styled from 'styled-components'

import { ReactComponent as Icon } from 'assets/info-circle.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import ModalPortal from 'portals/ModalPortal'

export default React.memo(function HintModal ({ header, hint }) {
  const t = useGetTranslate()
  const [isOpen, setIsOpen] = React.useState(false)
  return (
    <>
      <StyledIcon
        className='info-icon'
        onClick={() => setIsOpen(true)}
      />
      <ModalPortal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        showCloseButton
      >
        <StyledWrapper>
          <h3 className='hint-header'>
            {t(header)}:
          </h3>
          <div className='hint-description'>
            {t(hint)}
          </div>
        </StyledWrapper>
      </ModalPortal>
    </>
  )
})

const StyledIcon = styled(Icon)`
  margin-left: 4px;
  border-radius: 8px;
  cursor: pointer;
  path {
    transition: fill 0.2s;
    fill: ${({ theme: { colors } }) => colors.lightGrey};
  }
  :hover {
    path {
      fill: ${({ theme: { colors } }) => colors.blue};
    }
  }
`

const StyledWrapper = styled.div`
  padding: 16px;
  max-width: 500px;
  border-radius: 8px;
  background: #fff;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.blue};
  .hint-header {
    text-transform: uppercase;
    font-weight: 700;
  }
  .hint-description {
    margin-top: 25px;
    font-weight: 500;
  }
`
