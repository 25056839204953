// Modules
import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { ChatMessageType } from 'shared'

// Utils
import { linkify } from 'utils/linkify'
import { getAmPmTime } from 'utils/date'
import { getTalentId, getUserId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'

// Legacy components
import Avatar from '_legacy/components/Avatar'

// Components
import ActiveChatSystemMessage from './ActiveChatSystemMessage'
import ActionDropdown from '_legacy/components/Table/table-components/TableRow/common/ActionDropdown'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useStateModal } from 'store/hooks/globalState/useModal'
import { DELETE_MESSAGE_MODAL } from '_legacy/components/Modal/modals-types'


const ActiveChatMessage = ({ message, chatInfo, messageZonedDate }) => {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const userId = useUser(getUserId)
  const [messageBody, setMessageBody] = useState(message.body)
  const [isEditing, setIsEditing] = useState(false)
  const [isDeleted, setIsDeleted] = useState(false)
  const [inputEdition, setInputEdition] = useState(linkify(messageBody))
  const setDeleteModal = useStateModal(DELETE_MESSAGE_MODAL)

  const currentUserId = talentId || userId
  const currentUserMessage = currentUserId === message.author

  const messageAuthorDetails = chatInfo?.participants?.find(p => p.id === message.author)

  const additionalStylesClass = cx({
    'from-system': message.attributes?.messageType === ChatMessageType.SYSTEM,
    'from-me': currentUserMessage,
    'from-others': !currentUserMessage,
  })

  const systemMessage = message.attributes?.messageType === ChatMessageType.SYSTEM

  const isEdited = message.dateCreated.getTime() !== message.dateUpdated.getTime()

  const editMessage = (e) => {
    if(e.key === 'Enter') {
      e.preventDefault()
      message.updateBody(inputEdition)
        .then(({ body }) => setMessageBody(body))
        .catch(e => console.warn(e))
        .finally(() => setIsEditing(false))
    }

    if(e.key === 'Escape') {
      e.preventDefault()
      setIsEditing(false)
      setInputEdition(messageBody)
    }
  }

  const setEditMode = () => {
    setIsEditing(true)
  }

  const onRemove = () => {
    setIsDeleted(true)
  }

  const openDeleteModal = () => {
    setDeleteModal({ message, isOpen: true, onRemove })
  }

  const actionsConfig = React.useMemo(() => {
    return [
      { label: t('shared.words.edit'), cbFn: setEditMode },
      { label: t('shared.words.delete'), cbFn: openDeleteModal },
    ]
  }, [t, message])

  const handleChange = e => {
    setInputEdition(e.target.value)
  }

  if(isDeleted) return null

  return (
    <div className={`chat-message__wrapper ${additionalStylesClass}`}>
      {systemMessage ? (
        <ActiveChatSystemMessage
          chatInfo={chatInfo}
          currentUserId={currentUserId}
          currentUserMessage={currentUserMessage}
          message={message}
          messageAuthorDetails={messageAuthorDetails}
        />
      ) : (
        <>
          {!currentUserMessage && (
            <Avatar file={messageAuthorDetails?.profileImage?.file} className="chat-message__sender-icon" />
          )}

          <div className={`chat-message__body ${additionalStylesClass}`}>
            <div
              className={`chat-message__message ${additionalStylesClass}`}
            >
              <div className={`chat-message__message__content ${additionalStylesClass}`}>
                { isEditing
                  ?
                  <input style={{ width: `${inputEdition.length?inputEdition.length+1:2}ch` }} value={inputEdition} onChange={handleChange} onKeyDown={editMessage} />
                  :
                  <div dangerouslySetInnerHTML={{ __html: linkify(messageBody) }} />
                }
                { isEdited && !isEditing && <div className="chat-message__message__edited">{`(${t('shared.words.edited')})`}</div> }
              </div>
              { !isEditing && currentUserMessage && <ActionDropdown actionsConfig={actionsConfig} /> }
            </div>
            <div className="chat-message__message-time">{getAmPmTime(messageZonedDate)}</div>
          </div>
        </>
      )}
    </div>
  )
}

ActiveChatMessage.propTypes = {
  message: PropTypes.object.isRequired,
  chatInfo: PropTypes.object.isRequired,
}

export default ActiveChatMessage
