import React from 'react'
import PropTypes from 'prop-types'
import { prop } from 'lodash/fp'
import cx from 'classnames'

import TableHeader from '_legacy/components/Table/table-components/TableHeader'
import TableContent from '_legacy/components/Table/table-components/TableContent'
import SelectCleaner from '_legacy/components/Table/table-components/SelectCleaner'
import { TableStyledWrapper } from '_legacy/components/Table/table-components/Table'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'
import { elementsTypes, PRODUCT_FILES } from '_legacy/components/Table/table-components/types/product-files/config'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { bulkFileDownload } from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/state/requests'
import { useProductFilesFilters, useSetProductFilesFilters } from 'store/hooks/globalState/useProductFiles'

const tableConfig = [
  {
    header: 'table.header.file',
    type: elementsTypes.file,
  },
  {
    header: 'table.header.actions',
    type: elementsTypes.action,
  },
]

const ProductTable = ({ getTableFn, className }) => {
  const t = useGetTranslate()
  const isMobile = useWindowDimensions(prop('width')) <= 768
  const classes = cx('product-table-wrapper', className)

  const bulkActionsConfig = React.useMemo(
    () => ({
      getFilters: useProductFilesFilters,
      setFilters: useSetProductFilesFilters,
      actions: [{ label: t('table.actions.download'), cbFn: bulkFileDownload(getTableFn()) }],
    }),
    [t, getTableFn, bulkFileDownload]
  )

  return (
    <div className={classes}>
      <TableStyledWrapper isMobile={isMobile}>
        <SelectCleaner
          fields={[]}
          getFiltersFn={useProductFilesFilters}
          setFiltersFn={useSetProductFilesFilters}
        />
        <TableHeader
          tableName={PRODUCT_FILES}
          tableConfig={tableConfig}
          bulkActionsConfig={bulkActionsConfig}
          getTableFn={getTableFn}
          getFiltersFn={useProductFilesFilters}
          setFiltersFn={useSetProductFilesFilters}
        />
        <TableContent
          tableName={PRODUCT_FILES}
          tableConfig={tableConfig}
          bulkActionsConfig={bulkActionsConfig}
          getTableFn={getTableFn}
        />
      </TableStyledWrapper>
    </div>
  )
}

ProductTable.propTypes = {
  getTableFn: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default ProductTable
