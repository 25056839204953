import React from 'react'
import { types } from 'shared'
import { fields } from '_legacy/configs/table/profileManagers'

import {
  getProfileManagers as getProfileManagersApi,
  removeProfileManagers as removeProfileManagersApi,
  disconnectProfileManager as disconnectProfileManagerApi,
  cancelInviteProfileManager as cancelInviteProfileManagerApi,
  resendInvitationProfileManager as resendInvitationProfileManagerApi,
} from 'api/appApi/talent/profileManagers';

import {
  useCurrentPage,
  SUCCESS_NOTIFICATION,
  useTableActionsNotifications,
} from 'store/hooks/globalState/useUtils'
import {
  useSetProfileManagersFilters,
  useSetProfileManagersTable,
  useProfileManagersFilters,
} from 'store/hooks/globalState/useProfileManagers'
import { useUser } from 'store/hooks/globalState/useUser'

import { getTalentId } from 'utils/user'
import normalizeParams from 'utils/normalizeParams'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchProfileManagers() {
  const setProfileManagersFilters = useSetProfileManagersFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setProfileManagersTable = useSetProfileManagersTable(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    (options, user) => {
      const query = normalizeParams(options)
      setProfileManagersTable(null)

      getProfileManagersApi({ query, talentId: getTalentId(user) })
        .then(({ profileManagers, pageInfo }) => {
          setProfileManagersFilters(pageInfo)
          setProfileManagersTable(profileManagers)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setProfileManagersFilters, setProfileManagersTable]
  )
}

const {
  CANNOT_RESEND_INVITATION_THIS_STATUS,
} = types.talent.profileManager.actions.resendInvitation.ErrorReason

export function useReIviteProfileManager() {
  const fetchProfileManagers = useFetchProfileManagers()
  const filtersOptions = useProfileManagersFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    (profileManagerIds = []) => {
      resendInvitationProfileManagerApi({
        talentId: getTalentId(user),
        profileManagerIds,
      })
        .then(({ results }) => {
          fetchProfileManagers({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]:
              'table.notifications.pm.invitation-was-resent',
            [CANNOT_RESEND_INVITATION_THIS_STATUS]:
              'table.notifications.pm.cannot-resend-invitation-this-status',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [
      user,
      fetchProfileManagers,
      filtersOptions,
      page,
      tableActionsNotifications,
    ]
  )
}

const {
  CANNOT_CANCEL_INVITATION_THIS_STATUS,
} = types.talent.profileManager.actions.cancelInvitation.ErrorReason

export function useCancleInviteProfileManager() {
  const fetchProfileManagers = useFetchProfileManagers()
  const filtersOptions = useProfileManagersFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    profileManagerIds => {
      cancelInviteProfileManagerApi({
        talentId: getTalentId(user),
        profileManagerIds,
      })
        .then(({ results }) => {
          fetchProfileManagers({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.pm.cancle-invitation',
            [CANNOT_CANCEL_INVITATION_THIS_STATUS]:
              'table.notifications.pm.cannot-cancel-invitation-this-status',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => console.warn(error))
    },
    [
      user,
      fetchProfileManagers,
      filtersOptions,
      page,
      tableActionsNotifications,
    ]
  )
}

const {
  CANNOT_BE_DISCONNECTED_WITH_THIS_STATUS,
} = types.talent.profileManager.actions.disconnect.ErrorReason

export function useDisconnectProfileManager() {
  const fetchProfileManagers = useFetchProfileManagers()
  const filtersOptions = useProfileManagersFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    profileManagerIds => {
      disconnectProfileManagerApi({
        talentId: getTalentId(user),
        profileManagerIds,
      })
        .then(({ results }) => {
          fetchProfileManagers({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.pm.disconnect',
            [CANNOT_BE_DISCONNECTED_WITH_THIS_STATUS]:
              'table.notifications.pm.cannot-cancel-invitation-this-status',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => console.warn(error))
    },
    [
      user,
      fetchProfileManagers,
      filtersOptions,
      page,
      tableActionsNotifications,
    ]
  )
}

const PROFILE_MANAGER_IS_ACCEPTED = 'PROFILE_MANAGER_IS_ACCEPTED'; // todo: fix when shared will update

export function useRemoveProfileManager() {
  const user = useUser()
  const page = useCurrentPage()
  const fetchProfileManagers = useFetchProfileManagers()
  const tableActionsNotifications = useTableActionsNotifications()
  const filtersOptions = useProfileManagersFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )

  return React.useCallback(
    profileManagerIds => {
      removeProfileManagersApi({ talentId: getTalentId(user), profileManagerIds })
        .then(({ results }) => {
          fetchProfileManagers({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.pm.remove',
            [PROFILE_MANAGER_IS_ACCEPTED]:
              'table.notifications.pm.cannot-remove',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => console.warn(error))
    },
    [
      user,
      fetchProfileManagers,
      tableActionsNotifications,
      filtersOptions,
      page,
    ]
  )
}
