import React from 'react';
import { Row } from '_legacy/common/Template';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import Body2 from '_legacy/common/Typography/Body2';

const PurposeLocation = () => {
  const t = useGetTranslate();

  return (
    <Row
      component={
        <>
          <Body2 marginBottom='24px'>{t('create-experience.form.purpose-location')}</Body2>
        </>
      }
    />
  );
};

export default PurposeLocation;
