import React from 'react'

import OfferingDetailsGenericStatus from '../OfferingDetailsGenericStatus'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const CancelStatusWithContact = ({ refundText, title, complaintText }) => {
  const t = useGetTranslate()
  return (
    <OfferingDetailsGenericStatus
      title={title}
      type={t('order.header.cancelled')}
      reasonText={complaintText}
    >
      {refundText && <p>{refundText}</p>}
    </OfferingDetailsGenericStatus>
  )
}

export default CancelStatusWithContact
