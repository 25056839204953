import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';

import Checkbox from '_legacy/components/Form/Taboos/Checkbox';
import Loader from '_legacy/components/Common/Loader';
import FileInfo from '../../components/Library/FileInfo';
import FilePreview from '../../components/Library/FilePreview';

const CheckboxListItem = ({ file, name, disabled }) => {
  const { register } = useFormContext(); // todo: refactor with context
  return (
    <FileItemWrapper disabled={disabled}>
      <FilePreviewContainer file={file} />
      <FileInfoContainer file={file} />
      <FileControlContainer>
        {file.isLoading
          ? <Loader size={24} fill="#636583" />
          : <Checkbox value={file.fileToken} name={name} register={register} disabled={disabled} />}
      </FileControlContainer>
    </FileItemWrapper>
  );
};

const FileItemWrapper = styled.li`
  display: grid;
  grid-gap: 12px;
  grid-template: 'file info control' 52px / 52px 1fr 50px;

  cursor: default;
  margin-bottom: 12px;

  ${({ disabled }) => disabled && css`
    opacity: 0.4;
    cursor: not-allowed;
    pointer-events: none;
  `}
`;

const FilePreviewContainer = styled(FilePreview)`
  grid-area: file;
`;

const FileInfoContainer = styled(FileInfo)`
  grid-area: info;
`;

const FileControlContainer = styled.div`
  grid-area: control;
  align-self: center;
  justify-self: center;

  .loader {
    margin-left: 12px;
  }
`;

CheckboxListItem.propTypes = {
  file: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default CheckboxListItem;
