import React from 'react'
import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { useFormContext, useWatch } from 'react-hook-form'
import { enums } from 'shared'
import cond from 'lodash/cond'
import is from 'lodash/fp/isEqual'
import always from 'lodash/fp/always'
import T from 'lodash/fp/T'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { Row } from '_legacy/common/Template'
import { RadioButtons } from '_legacy/common/Radio'
import LocationNameAndAddress from '_legacy/components/RegisterForms/shared/components/LocationNameAndAddress'
import FlexibleLocationWithName from './FlexibleLocationWithName'
import PurposeLocation from './PurposeLocation'
const locationTypes = [enums.TypesOfLocation.FIXED, enums.TypesOfLocation.FLEXIBLE,enums.TypesOfLocation.TBD]

export const getLocationsWithTranslate = t =>
  locationTypes.map(type => ({
    value: type,
    label: t(`create-experience.form.types-of-location.options.${kebabCase(type)}.label`),
  }))

const TypesOfLocation = ({ precision }) => {
  const t = useGetTranslate()
  const locationsWithTranslate = getLocationsWithTranslate(t)

  const { control } = useFormContext()
  const typesOfLocation = useWatch({
    control,
    defaultValue: enums.TypesOfLocation.FIXED,
    name: 'typesOfLocation',
  })

  const Content = cond([
    [is(enums.TypesOfLocation.FIXED), always(LocationNameAndAddress)],
    [is(enums.TypesOfLocation.FLEXIBLE), always(FlexibleLocationWithName)],
    [is(enums.TypesOfLocation.TBD), always(PurposeLocation)],
    [T, always(() => null)],
  ])(typesOfLocation)

  return (
    <>
      <Row
        nameBlock={t('create-experience.form.types-of-location.label')}
        component={
          <RadioButtons
            name="typesOfLocation"
            options={locationsWithTranslate}
            customValidation={{
              required: 'create-experience.form.types-of-location.errors.required',
            }}
          />
        }
      />
      <Content precision={precision} />
    </>
  )
}

TypesOfLocation.propTypes = {
  precision: PropTypes.string
}

export default TypesOfLocation
