import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

//Experience Booking Details
const experienceOrderKey = 'experienceBookingDetails'

export const useExperienceBookingDetails = createUseGlobalState(experienceOrderKey)
export const useSetExperienceBookingDetails = createUseSetGlobalState(experienceOrderKey)

//Experience Order Participants
const experienceBookingDetailsParticipants = 'experienceBookingDetailsParticipants'
const experienceBookingDetailsParticipantsFilters = 'experienceBookingDetailsParticipantsFilters'

export const useExperienceBookingDetailsParticipants = createUseGlobalState(experienceBookingDetailsParticipants)
export const useSetExperienceBookingDetailsParticipants = createUseSetGlobalState(experienceBookingDetailsParticipants)

export const useExperienceBookingDetailsParticipantsFilters = createUseGlobalState(experienceBookingDetailsParticipantsFilters)
export const useSetExperienceBookingDetailsParticipantsFilters = createUseSetGlobalState(experienceBookingDetailsParticipantsFilters)
