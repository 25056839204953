import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const TypesOfDateInfoModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="upload-info-modal">
        <h3 className="upload-info-modal__title">
          {t('create-experience.form.types-of-date.modal.header')}
        </h3>
        {t('create-experience.form.types-of-date.modal.hint')}
        <br/>
      </div>
    </ModalPortal>
  );
};

TypesOfDateInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TypesOfDateInfoModal;
