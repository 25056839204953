import { pipe, cond, always, merge, T } from 'lodash/fp'
import { statuses } from '../utils'

const fillLiveInPersonLessonBookingConfig = merge({
  isShowInProgress: false,

  isShowContact: false,
  isShowHold: false,
  isShowWaitingForApprove: false,

  isShowRejected: false,
  isShowCompleted: false,
  isShowCanceled: false,
})

export const getLiveInPersonLessonBookingConfig = pipe(
  cond([
    [statuses.isOccurrenceInProgress, always({ isShowInProgress: true })],

    [statuses.isOrderUnderReview, always({ isShowWaitingForApprove: true })],
    [statuses.isOrderApprovedWithTime, always({ isShowHold: true })],
    [statuses.isOrderApprovedWithoutTime, always({ isShowContact: true })],

    [statuses.isOrderRejected, always({ isShowRejected: true })],
    [statuses.isOrderCompletedOrClosed, always({ isShowCompleted: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],

    [T, always({})],
  ]),
  fillLiveInPersonLessonBookingConfig
)
