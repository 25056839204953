import React from 'react'
import get from 'lodash/get'

import { getFavoriteTalents, getFavoriteOffers } from 'api/appApi/favorites/'
import { useUser } from 'store/hooks/globalState/useUser'
import { useSetFavoritesTalents, useSetFavoritesTalentsFilters, useSetFavoritesOffers, useSetFavoritesOffersFilters } from 'store/hooks/globalState/useFavorites'
import { useWindowDimensions } from 'store/hooks';
import { getLimitByResolution } from 'utils/getLimitByResolution';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import normalizeParams from 'utils/normalizeParams';

export function useFetchFavoriteTalents({ isMobile }) {
  const setFavoritesTalents = useSetFavoritesTalents((prev, next) => next)
  const mergeFavoritesTalents = useSetFavoritesTalents((prev, next = []) => ([...(prev || []), ...next]))
  const setFavoritesTalentsFilters = useSetFavoritesTalentsFilters((prev, next) => ({ ...prev, ...next }))
  const handleResponseError = useHandleResponseError()

  const { width } = useWindowDimensions()
  const userId = useUser(state => get(state, 'user.id'))

  return React.useCallback(({ ...query }) => {
    const params = normalizeParams(query)
    getFavoriteTalents({
      userId, query: {
        limit: getLimitByResolution(width),
        ...params,
      }, })
      .then(({ talents = [], pageInfo }) => {
        if (isMobile && 1 < pageInfo.page) {
          mergeFavoritesTalents(talents)
        } else {
          setFavoritesTalents(talents)
        }
        setFavoritesTalentsFilters(pageInfo)
      })
      .catch((error) => {
        handleResponseError(error)
        console.warn(error)
      })
  },
  [setFavoritesTalentsFilters, userId]
  )
}

export function useFetchFavoriteOffers({ isMobile }) {
  const setFavoritesOffers = useSetFavoritesOffers((prev, next) => next)
  const mergeFavoritesOffers = useSetFavoritesOffers((prev, next = []) => ([...(prev || []), ...next]))
  const setFavoritesOffersFilters = useSetFavoritesOffersFilters((prev, next) => ({ ...prev, ...next }))
  const handleResponseError = useHandleResponseError()

  const { width } = useWindowDimensions()
  const userId = useUser(state => get(state, 'user.id'))

  return React.useCallback(({ ...query }) => {
    const params = normalizeParams(query)
    getFavoriteOffers({
      userId, query: {
        limit: getLimitByResolution(width),
        ...params,
      },
    })
      .then(({ offers = [], pageInfo }) => {
        if (isMobile && 1 < pageInfo.page) {
          mergeFavoritesOffers(offers)
        } else {
          setFavoritesOffers(offers)
        }
        setFavoritesOffersFilters(pageInfo)
      })
      .catch((error) => {
        handleResponseError(error)
        console.warn(error)
      })
  },
  [setFavoritesOffersFilters, userId]
  )
}
