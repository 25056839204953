import React from 'react'

import './index.scss'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const EmptyAficionadosList = () => {
  const t = useGetTranslate()

  return (
    <div className="empty-aficionados-list">
      <p>{t('chats.users-search.list-is-empty')}</p>
    </div>
  )
}

export default EmptyAficionadosList
