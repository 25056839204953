import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import OfferingInfoModal from '_legacy/components/Modal/OfferingInfoModal';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import Machine from '_legacy/components/TalentPublicView/DesktopSlider/SlideMachine/Mobile/Machine';
import BasicActions from '_legacy/components/TalentPublicView/DesktopSlider/BasicActions';
import OffersNavigation from '_legacy/components/TalentPublicView/DesktopSlider/Mobile/OffersNavigation';
import {
  transformLessonsSlides,
  transformLiveExperiencesSlides,
  transformLiveVideoChatsSlides,
  transformVideoMessagesSlides,
  transformLiveVirtualEventsSlides,
  transformReadyMadeEventsSlides,
} from '_legacy/components/TalentPublicView/DesktopSlider/Mobile/utils';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import cond from 'lodash/cond'
import hasIn from 'lodash/fp/hasIn'
import invoke from 'lodash/fp/invoke'
import T from 'lodash/fp/T'
import './mobile.scss'
import {
  useCommercialEngagements,
} from 'store/hooks/globalState/useCommercialEngagements'
import { ReactComponent as MuteIcon } from 'assets/media-controls/mute.svg'
import { ReactComponent as PlayIcon } from 'assets/media-controls/play.svg'
import { ReactComponent as PauseIcon } from 'assets/media-controls/pause.svg'
import { ReactComponent as FullScreenIcon } from 'assets/media-controls/full-screen.svg'
import { ReactComponent as VolumeIcon } from 'assets/media-controls/volume.svg'
import { useFetchCommercialEngagements } from 'requests/commercial-engagements'
import { isIos } from 'utils/isBrowser';


const { warn } = console
const Mobile = React.memo(function Mobile({
  liveExperiencesSlides = [],
  videoMessages,
  videoChats,
  lessonsSlides = [],
  liveVirtualEvents = [],
  readyMadeEvents = [],
  firstName,
  plaqueNickname,
}) {
  const t = useGetTranslate();
  const [isPause,setPause]= React.useState(false)
  const [isPlay,setIsPlay]= React.useState(false)
  const [isMute,setIsMute]= React.useState(true)
  const [empty,setEmpty]= React.useState(false)
  const [commercialVideoMedia,setCommercialVideoMedia]= React.useState([])
  const [commercialVideoId,setCommercialVideoId]= React.useState([])
  const [isVideoSlide,setIsVideoSlide]= React.useState(false)
  const fetchCommercialEngagements = useFetchCommercialEngagements()
  React.useEffect(() => {
    fetchCommercialEngagements()
  }, [])
  const PlayControl = !isPlay ? PauseIcon : PlayIcon
  const VolumeControl = !isMute ? VolumeIcon : MuteIcon
  const commercialEngagements = useCommercialEngagements()
  const commercialVideoMessageData = get(commercialEngagements, 'commercialVideoMessages',[])
  const commercialVideoMessageActive = commercialVideoMessageData.filter(element => element.active === true);
  React.useEffect(()=>{
    var tempMedia = [];
    var tempCommercialOfferId= [];
    if(commercialVideoMessageActive){
      commercialVideoMessageActive.forEach(element => {
        if(element.active === true){
          tempCommercialOfferId.push(element.id)
          element.media.forEach(item => {
            tempMedia.push({
              ...item,
              commercialVideoMessageName: element.commercialVideoMessageName
            });
          })
        }
      });
    }
    setCommercialVideoId(tempCommercialOfferId)
    setCommercialVideoMedia(tempMedia)
  },[commercialVideoMessageData])

  const videoMessagesOffering = get(videoMessages, 'media', []);
  const videoMessagesOfferId = get(videoMessages, 'offerSequentialId', null);
  if(videoMessagesOffering.length > 0){
    videoMessagesOffering.forEach(function(element) {
      element.isCommercial = false;
    });
  }
  const videoOffering = commercialVideoMedia ? videoMessagesOffering.concat(commercialVideoMedia) : videoMessagesOffering
  const videoMessagesSlides = videoOffering.filter(element => element.isPrimary === true || element.isPromo === true);
  const videoChatsSlides= get(videoChats, 'media', []);
  const videoChatsOfferId= get(videoChats, 'offerSequentialId', null);
  const [currentSlideIndex, setCurrentSlideIndex] = React.useState(() => {
    if (liveExperiencesSlides.length !== 0) return 0;
    if (videoMessagesSlides.length !== 0) return 1;
    if (videoChatsSlides.length !== 0) return 2;
    if (lessonsSlides.length !== 0) return 3;
    if (readyMadeEvents.length !== 0) return 4;
    if (liveVirtualEvents.length !== 0) return 5;
    return 0;
  });
  function toggelPause(isPause){
    setPause(!isPause)
  }

  const slides = [
    ...transformLiveExperiencesSlides({ liveExperiencesSlides, firstName, plaqueNickname, toggelPause }),
    ...transformVideoMessagesSlides({ videoMessagesSlides, videoMessagesOfferId, commercialVideoId, plaqueNickname, firstName, toggelPause }),
    ...transformLiveVideoChatsSlides({ videoChatsSlides, videoChatsOfferId, firstName, plaqueNickname, toggelPause }),
    ...transformLessonsSlides({ lessonsSlides, firstName, plaqueNickname, toggelPause }),
    ...transformReadyMadeEventsSlides({ readyMadeEvents, firstName, plaqueNickname, toggelPause }),
    ...transformLiveVirtualEventsSlides({ liveVirtualEvents, firstName, plaqueNickname, toggelPause }),
  ];

  function pauseVideoByIndex(index)
  {
    if(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty !== true && slides[index].props.file.file.type === 'VIDEO'){
      const vid = document.getElementsByClassName('slide-elements')
      vid[index].pause();
    }
  }
  React.useEffect(()=>{
    if(slides.length > 0 && !empty){
      let count = 0;
      while(typeof slides[count] !== 'undefined' && slides[count].props.isEmpty === true){
        count++
      }
      if(count>=5){
        setCurrentSlideIndex(0);
        setEmpty(true)
      }
    }
  },[currentSlideIndex])
  React.useEffect(() => {
    if (typeof slides[currentSlideIndex] !== 'undefined' && slides[currentSlideIndex].props.isEmpty !== true) {
      if (slides[currentSlideIndex].props.file.file.type === 'VIDEO' || slides[currentSlideIndex].props.file.type === 'VIDEO') {
        setIsVideoSlide(true)
      }
      else{
        setIsVideoSlide(false)
      }
    }
    else{
      setIsVideoSlide(false)
    }

    if(typeof slides[currentSlideIndex] !== 'undefined' && slides[currentSlideIndex].props.isEmpty !== true  && slides[currentSlideIndex].props.file.file.type === 'VIDEO'){
      const vid = document.getElementsByClassName('slide-elements')
      if(isIos()) {
        vid[currentSlideIndex].muted = true;
        setIsMute(true)
      }
      vid[currentSlideIndex].currentTime=0;
      vid && vid[currentSlideIndex].play();
      if(!isIos()){
        if(!isMute) {
          vid[currentSlideIndex].volume = 1
          vid[currentSlideIndex].muted = false
        }
        else
        {
          vid[currentSlideIndex].volume = 0
        }
      }
      setIsPlay(false)
    }
  },[currentSlideIndex])
  function playSlideForward(currentInd)
  {
    pauseVideoByIndex(currentInd-1);

    let index = currentInd;
    while(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty === true)
    {
      index++;
    }

    if(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty !== true)
    {
      setCurrentSlideIndex(index);
      if(slides[index].props.file.file.type === 'VIDEO' || slides[index].props.file.type === 'VIDEO'){
        setPause(true)
        setIsPlay(false)
      }
      else{
        setPause(false)
      }
    }
    else
    {
      playSlideForward(0)
    }
  }
  function playSlideBackward(currentInd)
  {
    pauseVideoByIndex(currentInd+1);

    let index = currentInd;
    if(index < 0 || (index === 0 && typeof slides[index] !== 'undefined' && slides[index].props.isEmpty === true)){
      index = slides.length-1;
    }
    while(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty === true)
    {
      index--;
    }

    if(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty !== true)
    {
      setCurrentSlideIndex(index);
      if(slides[index].props.file.file.type === 'VIDEO' || slides[index].props.file.type === 'VIDEO'){
        setPause(true)
        setIsPlay(false)
      }
      else{
        setPause(false)
      }
    }
    else
    {
      playSlideBackward(0)
    }
  }
  const getCurrentActive = () => {
    const tempCurrentSlideIndex = currentSlideIndex < 0 ? currentSlideIndex % slides.length + slides.length : currentSlideIndex;

    let limit = liveExperiencesSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 0;

    limit += videoMessagesSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 1;

    limit += videoChatsSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 2;

    limit += lessonsSlides.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 3;

    limit += readyMadeEvents.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 4;

    limit += liveVirtualEvents.length || 1;
    if(tempCurrentSlideIndex % slides.length < limit) return 5;
  };

  const setCurrentActive = (activeIndex) => {
    let limit = 0;
    if(activeIndex === 0) return setCurrentSlideIndex(0);

    limit += liveExperiencesSlides.length || 1;
    if(activeIndex === 1) return setCurrentSlideIndex(limit);

    limit += videoMessagesSlides.length || 1;
    if(activeIndex === 2) return setCurrentSlideIndex(limit);

    limit += videoChatsSlides.length || 1;
    if(activeIndex === 3) return setCurrentSlideIndex(limit);

    limit += lessonsSlides.length || 1;
    if(activeIndex === 4) return setCurrentSlideIndex(limit);

    limit += readyMadeEvents.length || 1;
    if(activeIndex === 5) return setCurrentSlideIndex(limit);

    limit += liveVirtualEvents.length || 1;
    if(activeIndex === 6) return setCurrentSlideIndex(limit);

    throw new Error(`Unknown active index, expected: 0 or 1 or 2 or 3, instead get ${activeIndex}`);
  };
  function playPauseVideo(){
    const vid = document.getElementsByClassName('slide-elements')
    if (isPlay && vid[currentSlideIndex].paused) vid[currentSlideIndex].play()
    if (!isPlay && !vid[currentSlideIndex].paused) vid[currentSlideIndex].pause()
  }
  function muteUnmuteVideo(){
    const vid = document.getElementsByClassName('slide-elements')
    if (isMute && !isIos() && !vid[currentSlideIndex].volume) vid[currentSlideIndex].volume = 1
    if (!isMute && !isIos() && vid[currentSlideIndex].volume) vid[currentSlideIndex].volume = 0
    if(isMute && isIos()) vid[currentSlideIndex].muted = false;
    if(!isMute && isIos()) vid[currentSlideIndex].muted = true;
  }
  const triggerFullScreen = cond([
    [hasIn('requestFullscreen'), invoke('requestFullscreen')],
    [hasIn('webkitRequestFullscreen'), invoke('webkitRequestFullscreen')],
    [hasIn('mozRequestFullScreen'), invoke('mozRequestFullScreen')],
    [hasIn('msRequestFullscreen'), invoke('msRequestFullscreen')],
    [T, () => warn('Fullscreen API is not supported.')],
  ])
  function handleFullScreenChange() {
    const vid = document.getElementsByClassName('slide-elements');
    const videoElement = vid[currentSlideIndex];
    if (videoElement) {
      if (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      ) {
        // User entered fullscreen mode
      } else {
        videoElement.classList.remove('video-object-fit-contain');
        document.removeEventListener('fullscreenchange', handleFullScreenChange);
        document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
        document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
        document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
      }
    }
  }
  function onFullScreen() {
    const vid = document.getElementsByClassName('slide-elements');
    const videoElement = vid[currentSlideIndex];
    if (videoElement) {
      videoElement.classList.add('video-object-fit-contain');
      document.addEventListener('fullscreenchange', handleFullScreenChange);
      document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.addEventListener('mozfullscreenchange', handleFullScreenChange);
      document.addEventListener('MSFullscreenChange', handleFullScreenChange);
      triggerFullScreen(videoElement);
    }
  }

  return (
    <>
      <StyleWrapper className="mobile-wr">
        <StyledHeader className="mb-header">
          <div style={{ display:'flex', justifyContent:'center' }}>
            {t('talent-public-view.mobile-slider.title')}
            <div style={{ justifyContent:'center' }}>
              <TipButton modalComponent={OfferingInfoModal} />
            </div>
          </div>
        </StyledHeader>
        <OffersNavigation currentActive={getCurrentActive()} onActivate={setCurrentActive} />
      </StyleWrapper>
      <StyleWrapperMedia>
        <Machine
          slides={slides}
          currentSlideIndex={currentSlideIndex}
          setCurrentSlideIndex={setCurrentSlideIndex}
          isPause={isPause}
          setPause={setPause}
          setIsPlay={setIsPlay}
          pauseVideoByIndex={pauseVideoByIndex}
          empty={empty}
        />
        <StyleControl >
          {isVideoSlide &&
          <VolumeControl
            className="video-icon volume-icon-carousal"
            onClick={() => {muteUnmuteVideo(); setIsMute(!isMute)}}
          />}
          <BasicActions
            slidesCount={slides.length}
            onNext={() => !empty ? playSlideForward(currentSlideIndex + 1) : setCurrentSlideIndex(currentSlideIndex + 1)}
            onPrev={() => !empty ? playSlideBackward(currentSlideIndex - 1) : setCurrentSlideIndex(currentSlideIndex - 1)}
          >
            {<PlayControl
              className={ isVideoSlide ? "video-icon play-icon-carousal" : "video-icon play-icon-carousal hide-video-icon"}
              onClick={() => {typeof slides[currentSlideIndex] !== 'undefined' && slides[currentSlideIndex].props.isEmpty !== true &&  slides[currentSlideIndex].props.file.file.type === 'VIDEO' && playPauseVideo(); setIsPlay(!isPlay)}}
            />}
          </BasicActions>
          {isVideoSlide &&
          <FullScreenIcon
            className="video-icon fullscreen-icon-carousal"
            onClick={() => onFullScreen()}
          />}
        </StyleControl>
      </StyleWrapperMedia>
    </>
  );
})

const StyleWrapper = styled.div`
width: 400px;
margin: auto;
@media screen and (max-width: 950px) {
  width: 344px;
}
@media screen and (max-width: 366px) {
  width: 290px;
}
`
const StyleControl = styled.div`
 display:flex;
 align-items: center;
`
const StyleWrapperMedia = styled.div`
  width: 450px;
  margin: auto;

  @media screen and (max-width: 950px) {
    width: 344px;
  }
  @media screen and (max-width: 366px) {
    width: 290px;
  }
`
const StyledHeader = styled.h4`
  margin-top: 30px;
  margin-bottom: 26px;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 28px;
  line-height: 1.25;
  text-align: center;
  font-feature-settings: 'case' on, 'liga' off;
  color: ${({ theme }) => theme.colors.blue};
`

Mobile.propTypes = {
  liveExperiencesSlides: PropTypes.array,
  videoMessages: PropTypes.object,
  videoChats: PropTypes.object,
  lessonsSlides: PropTypes.array,
  liveVirtualEvents: PropTypes.array,
  readyMadeEvents: PropTypes.array,
  firstName: PropTypes.string,
  plaqueNickname: PropTypes.string
};

export default Mobile;
