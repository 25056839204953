import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import compose from 'lodash/fp/compose'
import size from 'lodash/fp/size'
import compact from 'lodash/fp/compact'
import values from 'lodash/fp/values'
import omit from 'lodash/fp/omit'
import isEmpty from 'lodash/fp/isEmpty'
import filter from 'lodash/fp/filter'
import propOr from 'lodash/fp/propOr'

import { ReactComponent as DoubleArrow } from 'assets/double-arrow.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const omitLocation = props => {
  if (isEmpty(props.location)) delete props.locationKmRadius
  return props
}

const extraKeys = [
  'sortField',
  'count',
  'remains',
  'limit',
  'page',
  'sortByLocationLatitude',
  'sortByLocationLongitude'
]
const getLength = compose(
  size,
  compact,
  values,
  filter(propOr(true, 'length')),
  omitLocation,
  omit(extraKeys),
)

const Controls = ({ clearFn, getFn, toggle, showMore }) => {
  const t = useGetTranslate()
  const activeFilterCount = getFn(getLength)
  const clearFilters = clearFn()

  return (
    <StyledWrapper>
      <Button onClick={toggle}>
        <span>{t('search.filters.common.all-filters')}</span>
        <DoubleArrowS showMore={showMore} />
      </Button>
      <Count
        disabled={!activeFilterCount}
        onClick={clearFilters}
        count={activeFilterCount}
      >
        {t('search.filters.common.clear')}
      </Count>
    </StyledWrapper>
  )
}

const textControl = css`
  font-weight: 700;
  font-size: 14px;
  line-height: 1.3;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.grey};
  transition: color .3s ease-in-out;
  &:hover {
    color: ${({ theme }) => theme.colors.blue};
  }
  &:disabled {
    cursor: not-allowed;
    &:hover {
      color: ${({ theme }) => theme.colors.grey};
    }
  }
`

const StyledWrapper = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
`

const Button = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  outline: none;
  ${textControl}

  span {
    margin-right: 8px;
  }
`

const DoubleArrowS = styled(DoubleArrow)`
  transition: transform .3s ease-in-out;
  transform: rotate(${({ showMore }) => showMore ? '-180deg' : 0});
`

const Count = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  ${textControl}

  &:after {
    margin-left: 8px;
    content: ${props => `"${props.count}"`};
    width: 18px;
    height: 18px;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
  }
`

Controls.propTypes = {
  getFn: PropTypes.func,
  clearFn: PropTypes.func,
  toggle: PropTypes.func,
  showMore: PropTypes.bool,
}

export default Controls
