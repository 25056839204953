import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// utils
import { notify } from 'utils/notify'
import { useFetch } from 'store/hooks/useFetch';
import { emailPattern } from 'utils/services/validators/validators';
import { appApi } from 'api';
import { useStateModal } from 'store/hooks/globalState/useModal';

// components
import Button from '_legacy/components/Button';
import CheckEmailModal from '_legacy/components/Modal/CheckEmail';
import CommonInput from '_legacy/components/Form/Inputs/Common';
import ModalPortal from 'portals/ModalPortal';

const RestorePasswordModal = ({ initialEmail = '' }) => {
  const t = useGetTranslate()
  const [{ data, isLoading }, resetPassword] = useFetch(
    appApi.auth.resetPasswordRequest,
  );
  const [showCheckEmailModal, setShowCheckEmailModal] = useState(false);
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: { email: '' },
  });

  const onSubmit = ({ email }) => {
    resetPassword({ email });
  };

  const onClose = useStateModal(() => null)

  // set default value to form
  useEffect(() => {
    reset({ email: initialEmail });
  }, [initialEmail]);

  useEffect(() => {
    if (data) {
      notify(t('restore-password.notify.success'), 'success')
      setShowCheckEmailModal(true);
      onClose();
    }
  }, [data, isLoading]);

  return (
    <>
      <ModalPortal isOpen showCloseButton>
        <div className="restore-modal">
          <form
            className="restore-modal__form default"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h1 className="login-modal__form-header">
              {t('restore-password.header.label')}
            </h1>

            <CommonInput
              containerClassName={'mb-24'}
              name='email'
              type={'email'}
              placeholder={t('restore-password.modal.email.placeholder')}
              errorMessage={errors?.email?.message}
              label={t('restore-password.modal.email.label')}
              forwardRef={register({
                required: 'restore-password.modal.email.errors.required',
                pattern: {
                  value: emailPattern,
                  message: 'restore-password.modal.email.errors.invalid'
                },
              })}
            />

            <Button
              text={t('restore-password.modal.submit-button.label')}
              typeButton="submit"
              stretch
              paddingVertical="16"
            />
          </form>
        </div>
      </ModalPortal>
      <CheckEmailModal
        closeModal={() => setShowCheckEmailModal(false)}
        isModalOpen={showCheckEmailModal}
      />
    </>
  );
};

RestorePasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  initialEmail: PropTypes.string,
};

export default RestorePasswordModal;
