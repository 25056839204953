import React from 'react'
import get from 'lodash/get'
import { useFieldArray, useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import last from 'lodash/last'
import './index.scss'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getLastCategoryTitle } from '_legacy/components/CategoriesSelect/utils'

import { Row } from '_legacy/common'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import Category from '_legacy/components/Form/CascaderSelect/Category'
import Input from '_legacy/components/RegisterForms/shared/components/Input'
import RegisterTitleCategoryModal from '_legacy/components/Modal/RegisterTitleCategoryModal'
import { useTalentCategories } from 'store/hooks/globalState/useCategories'

const getName = baseName => {
  const _baseName = baseName ? `${baseName}.` : ''
  return {
    titleKey: _baseName + 'title',
    categoriesKey: _baseName + 'categories',
    primaryCategoryKey: _baseName + 'primaryCategory',
  }
}

const CategoriesGroup = ({ baseName = '' }) => {
  const { titleKey, categoriesKey, primaryCategoryKey } = getName(baseName)
  const t = useGetTranslate()
  const methods = useFormContext()
  const values = methods.watch()
  const titleKeyValue = get(values, titleKey)
  const categoryValue = get(values, primaryCategoryKey)
  const categoriesValue = get(values, categoriesKey)
  const { append, fields, remove } = useFieldArray({ name: categoriesKey, control: methods.control })
  const primaryCategoryTitle = getLastCategoryTitle(categoryValue)

  React.useEffect(() => {
    methods.setValue(titleKey, getLastCategoryTitle(categoryValue), { shouldDirty: true })
  }, [primaryCategoryTitle])

  React.useEffect(() => {
    methods.setValue(titleKey, titleKeyValue, { shouldDirty: true })
  }, [titleKeyValue])

  const removeHandler = index => {
    categoriesValue.length > 1 ? remove(index) : methods.setValue(`${categoriesKey}.0`, [], { shouldDirty: true })
  }

  const appendHandler = () => {
    append([{}])
  }

  const triggerValidation = () => {
    methods.trigger(primaryCategoryKey);

    (categoriesValue || []).forEach((_, idx) => {
      methods.trigger(`${categoriesKey}[${idx}]`)
    })
  }

  const duplicate = (category, index) => {
    return ![methods.watch(primaryCategoryKey), ...methods.watch(categoriesKey, [])]
      .filter((_, idx) => idx !== index)
      .find(arr => last(arr)?.i === last(category)?.i)
  }

  return (
    <>
      <Row
        isRequired
        nameBlock={t('register.input-label.talent-category-label')}
        className="cascader-catagory"
        component={
          <Category
            withCount={false}
            name={primaryCategoryKey}
            getFn={useTalentCategories}
            customOnChange={triggerValidation}
            rules={{
              validate: {
                required: value => !isEmpty(value) || t('shared.words.required'),
                duplicate: category => duplicate(category, 0) || t('shared.categories.duplicate')
              }
            }}
            placeholder="register.input-label.talent-category-placeholder"
          />
        }
      />
      <Row
        nameBlock={t('register.input-label.talent-categories.label')}
        className="cascader-catagory"
        component={
          <>
            <ul>
              {fields.map((item, index) => (
                <li key={item.id} className="cascader-catagory__item">
                  <Category
                    withCount={false}
                    name={`${categoriesKey}.${index}`}
                    defaultValue={item.value}
                    getFn={useTalentCategories}
                    customOnChange={triggerValidation}
                    placeholder={t('search.filters.profile-type.placeholder')}
                    rules={{
                      validate: {
                        duplicate: category => duplicate(category, index + 1) || t('shared.categories.charity.errors.duplicate')
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => removeHandler(index)}
                    className="form-row__remove form-row__remove--black"
                  />
                </li>
              ))}
            </ul>
            {fields.length < 3 && (
              <button
                type="button"
                onClick={appendHandler}
                className="cascader-catagory__more"
              >
                + {t('shared.words.add-more')}
              </button>
            )}
            <p className="category-description">
              <Trans tKey="register.input-label.talent-categories.desc" />
            </p>
          </>
        }
      />
      <Input
        name={titleKey}
        label={t('register.input-label.title.label')}
        tip={<TipButton modalComponent={RegisterTitleCategoryModal} />}
        placeholder={t('register.input-label.title.placeholder')}
        isRequired
      />
    </>
  )
}

CategoriesGroup.propTypes = {
  baseName: PropTypes.string,
}

export default CategoriesGroup
