import get from 'lodash/get'
import size from 'lodash/size'
import { VideoMessageForType } from 'shared'

const shouldSendPhone = (values, key) => {
  const value = get(values, key)
  return 3 < size(value) ? value : undefined
}

export default (values, user) => {
  let requestor
  const forType = get(values, 'recipient.forType') || undefined

  if (forType === VideoMessageForType.MY_SELF) {
    requestor = {
      name: get(user, 'firstName', ''),
      email: get(user, 'email'),
      emailDeliverVersion: true,
      phoneDeliverVersion: !!shouldSendPhone(values, 'requestor.phoneDeliverVersion'),
    }
  } else {
    requestor = {
      name: get(values, 'requestor.name'),
      email: get(values, 'requestor.email'),
      emailDeliverVersion: get(values, 'requestor.emailDeliverVersion'),
      phone: shouldSendPhone(values, 'requestor.phone'),
      // EN-3151 hide checkbox under phone
      phoneDeliverVersion: !!shouldSendPhone(values, 'requestor.phoneDeliverVersion'),
    }
  }

  return {
    requestor,
    recipient: {
      forType,
      toEmail: get(values, 'recipient.toEmail'),
      name: get(values, 'recipient.name'),
      phone: shouldSendPhone(values, 'recipient.phone'),
      // EN-3151 hide checkbox under phone
      phoneDeliverVersion: !!shouldSendPhone(values, 'recipient.phone'),
    },
    message: {
      occasionType: get(values, 'occasionType'),
      script: get(values, 'message.script'),
    },
  }
}
