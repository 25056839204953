import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

const virtualEventOrderKey = 'virtualEventOrderDetails'
const virtualEventOrderTableKey = 'virtualEventOrderDetailsTable'
const virtualEventOrderTableFiltersKey = 'virtualEventOrderDetailsTableFilters'
const isEditableKey = 'isVirtualEventEditable'

export const useVirtualEventOrderDetails = createUseGlobalState(virtualEventOrderKey)
export const useSetVirtualEventOrderDetails = createUseSetGlobalState(virtualEventOrderKey)

export const useVirtualEventOrderDetailsTable = createUseGlobalState(virtualEventOrderTableKey)
export const useSetVirtualEventOrderDetailsTable = createUseSetGlobalState(virtualEventOrderTableKey)

export const useVirtualEventOrderDetailsTableFilters = createUseGlobalState(virtualEventOrderTableFiltersKey)
export const useSetVirtualEventOrderDetailsTableFilters = createUseSetGlobalState(virtualEventOrderTableFiltersKey)

export const useIsOccurrenceEditable = createUseGlobalState(isEditableKey)
export const useSetIsOccurrenceEditable = createUseSetGlobalState(isEditableKey)

