import React from 'react';
import { useParams, useHistory } from 'react-router-dom';

// components
import PasswordChangedModal from '../../components/Modal/PasswordChanged';
import ResetPasswordForm from '../../components/ResetPassword/ResetPasswordForm';

// utility
import { useFetch } from 'store/hooks/useFetch';
import { appApi } from 'api';
import { notify } from '../../../utils/notify';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { sharedErrors } from './errors';
import { Container } from '_legacy/common'

const getResetPasswordInfoApi = appApi.auth.getResetPasswordInfo;

const ResetPassword = () => {
  const t = useGetTranslate();
  const [showPasswordChangedModal, setShowPasswordChangedModal] = React.useState(false);
  const [{ error }, getResetPasswordInfo] = useFetch(getResetPasswordInfoApi);
  const { key } = useParams();
  const { push } = useHistory();

  const onCloseModal = () => {
    setShowPasswordChangedModal(false);
    push('/');
  };

  // get info
  React.useEffect(() => {
    getResetPasswordInfo({ key });
  }, []);

  // handle get password
  React.useEffect(() => {
    if (error?.name) {
      notify(t(sharedErrors[error.name]), 'error');
      push('/');
    }
  }, [error]);

  return (
    <div className={'reset-password'}>
      <Container>
        <h1 className={'reset-password__header'}>
          {t('reset-password.header.label')}
        </h1>

        <ResetPasswordForm handleModal={setShowPasswordChangedModal} />

        <PasswordChangedModal
          isModalOpen={showPasswordChangedModal}
          closeModal={onCloseModal}
        />
      </Container>
    </div>
  );
};

export default ResetPassword;
