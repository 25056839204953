import React from 'react';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common/Template';
import { RadioButtons } from '_legacy/common/Radio';

import { getProfileTypesWithTranslate } from '../../utils/config'

const ProfileTypes = ({ name = 'type', disabled, ...props }) => {
  const t = useGetTranslate();
  const options = getProfileTypesWithTranslate(t)

  return (
    <ProfileTypesWrapper
      isRequired
      nameBlock={t('register-aficionado.form.type.label')}
      component={
        <RadioButtons
          name={name}
          disabled={disabled}
          options={options}
          customValidation={{ required: 'register-aficionado.form.type.errors.required' }}
          {...props}
        />
      }
    />
  );
};

const ProfileTypesWrapper = styled(Row)`
  .row-left {
    padding-top: 0;
  }
`;

export default ProfileTypes;
