import {
  createUseGlobalState,
  createUseSetGlobalState,
} from './useGlobalState';

import indexBy from 'lodash/keyBy';
import prop from 'lodash/fp/prop';
import merge from 'lodash/fp/merge';
import omit from 'lodash/fp/omit';
import props from 'lodash/fp/props';

import { FILE_ID } from './useFileLibrary';

export const useFileEntities = createUseGlobalState('fileEntities');
export const useSetFileEntities = createUseSetGlobalState('fileEntities');

export const useAddFilesToEntities = () => {
  return useSetFileEntities((prev, next) => {
    const values = indexBy(next, FILE_ID);
    return merge(prev, values);
  });
};

export const useAddFileToEntities = () => {
  return useSetFileEntities((prev, id, file) => ({ ...prev, [id]: file }))
};

export const useFileEntityById = id => {
  const fileEntities = useFileEntities();
  return prop(id, fileEntities);
};

export const useFilesEntitiesByIds = ids => {
  return useFileEntities(props(ids), [ids]);
};

export const useRemoveFileEntityById = () => {
  return useSetFileEntities((prev, next) => omit(next, prev));
}

