import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form'

const DayCheckbox = ({ value, name, label }) => {
  const { register } = useFormContext()
  return (
    <div className='day-checkbox'>
      <input
        type="checkbox"
        name={name}
        value={Number(value)}
        ref={register}
        id={String(label + value)}
        className='day-checkbox__input'
      />
      <label
        className='day-checkbox__label'
        htmlFor={String(label + value)}
      >
        {label}
      </label>
    </div>
  );
};

DayCheckbox.propTypes = {
  register: PropTypes.func,
  value: PropTypes.number,
  name: PropTypes.string,
  label: PropTypes.string,
};

export default DayCheckbox;
