export const calcX = (clientX, windowWidth, offsetX = 0) => {
  const sum = clientX  + offsetX;
  const leftEdge = offsetX;
  if (sum > windowWidth) {
    return clientX > leftEdge ? clientX : leftEdge;
  }
  return clientX;
};

export const calcY = (clientY, windowHeight, offsetY = 0) => {
  const sum = clientY + offsetY;
  return sum > windowHeight ? clientY - (sum - windowHeight) : clientY;
};
