import React from 'react'
import { prop } from 'lodash/fp'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import NavigationHeader from '_legacy/common/mobile-navigation/NavigationHeader'

import Menu from './Menu'
import { getAccountMenuItems, getAgentTalentMenuItems } from './Menu/utils'
import { useUser } from 'store/hooks/globalState/useUser'
import { isAgentWithTalent } from 'utils/user'

function Title() {
  const t = useGetTranslate()
  return <h1 className="account-menu-header">{t('settings.header')}</h1>
}

export default React.memo(function AccountTemplate({ children, isNested, title }) {
  const windowWidth = useWindowDimensions(prop('width'))
  const agentWithTalent = useUser(isAgentWithTalent)
  const menuItems = getAccountMenuItems()
  const agentTalentMenuItems = getAgentTalentMenuItems()

  const filteredMenuItems = agentWithTalent ? agentTalentMenuItems : menuItems

  if (windowWidth <= 1024) {
    if (isNested) {
      return <div>{children}</div>
    } else {
      return (
        <div>
          <NavigationHeader text={title} />
          {children}
        </div>
      )
    }
  }

  return (
    <>
      <Title />
      <Menu menuItems={filteredMenuItems} />
      {children}
    </>
  )
})
