import { FileType, TalentOffersType, PromotionalMediaType } from 'shared'
import map from 'lodash/map'

import { filters } from '_legacy/components/Table/Filters/config'

const mediaTypes = {
  [FileType.PHOTO]: 'table.filters.media-types.photo',
  [FileType.VIDEO]: 'table.filters.media-types.video',
}
const mediaTypesOptions = map(mediaTypes, (label, value) => ({ value, label }))


const usedTypes = {
  LESSONS: 'table.filters.offerings-types.lessons',
  [TalentOffersType.VIDEO_CHAT]: 'table.filters.offerings-types.video-chat',
  [TalentOffersType.VIDEO_MESSAGE]: 'table.filters.offerings-types.video-message',
  [TalentOffersType.IN_PERSON_EXPERIENCE]: 'table.filters.offerings-types.in-person-experience',
  [TalentOffersType.VIRTUAL_EVENT]: 'table.filters.offerings-types.virtual-event',
  [TalentOffersType.READY_MADE_LESSON]: 'table.filters.offerings-types.ready-made-lesson',
}
const usedTypesOptions = map(usedTypes, (label, value) => ({ value, label }))


const promotionalTypes = {
  ALL: 'table.filters.offerings-types.all',
  [PromotionalMediaType.VIDEO_CHAT_PROMO]: 'table.filters.offerings-types.video-chat',
  [PromotionalMediaType.VIDEO_MESSAGE_PROMO]: 'table.filters.offerings-types.video-message',
  [PromotionalMediaType.LESSONS_PROMO]: 'table.filters.offerings-types.lessons',
  [PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO]: 'table.filters.offerings-types.in-person-experience',
  [PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO]: 'table.filters.offerings-types.virtual-event',
  [PromotionalMediaType.READY_MADE_LESSON_PROMO]: 'table.filters.offerings-types.ready-made-lesson',
}
const promotionalTypesOptions = map(promotionalTypes, (label, value) => ({ value, label }))


export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'search'
  },
  {
    type: filters.select,
    placeholder: 'table.filters.media-type',
    field: 'mediaTypeIn',
    options: mediaTypesOptions
  },
  {
    type: filters.select,
    placeholder: 'table.filters.used',
    field: 'usedInOffersTypes',
    options: usedTypesOptions
  },
  {
    type: filters.select,
    placeholder: 'table.filters.promotional',
    field: 'usedInPromotionalMediaTypes',
    options: promotionalTypesOptions
  }
]


export const counterConfig = {
  field: 'count',
  label: 'table.filters.media',
  pluralLabel: 'table.filters.medias',
}


export const sortKeys = {
  MEDIA: 'name',
  USED_COUNT: 'usedCount',
  PROMOTIONAL: 'promotionalCount',
  UPLOAD_DATE: 'uploadDate',
}
const sortFields = {
  [sortKeys.MEDIA]: 'table.header.name',
  [sortKeys.USED_COUNT]: 'table.header.used-in',
  [sortKeys.PROMOTIONAL]: 'table.header.promotional',
  [sortKeys.UPLOAD_DATE]: 'table.header.upload-date',
};
const sortFieldOptions = map(sortFields, (label, value) => ({ value, label }))


export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]


const additionalFields = [
  'sortOrder'
]


export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
