import React from 'react'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { types } from 'shared'

import Icon from 'assets/icons/Icon'

const { FACEBOOK, INSTAGRAM, LINKED_IN, TIK_TOK, TWITTER } = types.user.socialMedia.Types

const socialConfig = {
  [FACEBOOK]: {
    Icon: Icon.SocialFacebook,
    getDomain: nickname => `https://www.facebook.com${nickname}`,
  },
  [INSTAGRAM]: {
    Icon: Icon.SocialInstagram,
    getDomain: nickname => `https://www.instagram.com${nickname}`,
  },
  [TIK_TOK]: {
    Icon: Icon.SocialTikTok,
    getDomain: nickname => `https://www.tiktok.com${nickname}`,
  },
  [TWITTER]: {
    Icon: Icon.SocialTwitter,
    getDomain: nickname => `https://twitter.com${nickname}`,
  },
  [LINKED_IN]: {
    Icon: Icon.SocialLinkedIn,
    getDomain: nickname => `https://www.linkedin.com/in${nickname}`,
  },
}

const SocialList = ({ socialList = [] }) => {
  if (isEmpty(socialList)) return null

  return (
    <ul className='social-list'>
      {socialList
        .filter(item => Object.keys(socialConfig).includes(item.social) && !!item.link)
        .map(item => {
          const { Icon, getDomain } = socialConfig[item.social]

          const prepareLink = item.link.toString().charAt(0) === '/'
            ? item.link
            : `/${item.link}`;

          return (
            <li key={item.social} className='social-list__item'>
              <a target="_blank" href={getDomain(prepareLink)} className='social-list__link'>
                <Icon />
              </a>
            </li>
          )
        })}
    </ul>
  )
}

SocialList.propTypes = {
  socialList: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      social: PropTypes.oneOf(Object.keys(socialConfig)),
    }),
  ),
}

export default SocialList
