import React from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useWindowDimensions } from 'store/hooks';
import config from './config';

const HowItWorks = () => {
  const t = useGetTranslate();
  const { width } = useWindowDimensions();
  const isMobile = width <= 991;
  const [activeMenuIndex, setActiveMenuIndex] = React.useState(isMobile ? null : 0);

  const setElement = i => () => setActiveMenuIndex(i);

  return (
    <StyleWrapper className='how-it-works__wrapper'>
      <h1 id='title' className='title'>{t('how-it-works.header.title')}</h1>
      <StyledAccordion>
        {config.map((item, i) => {
          const active = activeMenuIndex === i;
          return (
            <React.Fragment key={item.id}>
              <StyledAccordionTab
                id={item.id}
                onClick={setElement(i)}
                active={active}
                href={`#${isMobile ? item.id : 'title'}`}
              >
                <Trans tKey={item.title} />
              </StyledAccordionTab>
              <StyledAccordionContent active={active}>
                {item.content.map(({ header, text, icon: Icon, isButton }, idx) => (
                  <StyledAccordionContentItem key={idx}>
                    <div className='img'>
                      <Icon prefixId={`${item.id}_${idx}`} />
                    </div>
                    <div className='wrapper'>
                      <div className='header'>{t(header)}</div>
                      <div className='content'>
                        {text && <p>{t(text)}</p>}
                        {isButton && (
                          <>
                            <p>{t(isButton.text)}</p>
                            <div className='lint-button'>
                              <Link to='/'>{t(isButton.buttonText)}</Link>
                              <p>{t(isButton.suffixText)}</p>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </StyledAccordionContentItem>
                ))}
              </StyledAccordionContent>
            </React.Fragment>
          );
        })}
      </StyledAccordion>
    </StyleWrapper>
  );
};

const StyleWrapper = styled.div`
  @media only screen and  (max-width: 767px) {
    padding: 0 16px;
    margin-bottom: 0;
  }
  .title {
    font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
    font-size: 60px;
    line-height: 1.25;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    color: ${({ theme: { colors } }) => colors.white};
    margin-bottom: 24px;
    margin-top: 30px;
    padding-top: 20px;
    @media only screen and (max-width: 991px) {
      text-align: center;
      font-size: 28px;
    }
  }

  .lint-button {
    display: flex;
    font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
    font-size: 16px;
    line-height: 1.625;
    color: ${({ theme: { colors } }) => colors.blueText};

    a {
      position: relative;
      z-index: 3;
      margin-right: 8px;
      color: ${({ theme: { colors } }) => colors.white};
      font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
    }
  }
`;

const StyledAccordion = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-between;
  margin-bottom: 50px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1.5fr 2fr;
  }
  @media only screen and (max-width: 991px) {
    display: block;
  }
`;


const StyledAccordionTab = styled.a`
  display: block;
  font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
  font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
  font-feature-settings: 'case' on, 'liga' off;
  font-size: 24px;
  color: ${({ theme: { colors } }) => colors.lightGrey};
  ${({ active }) => active && css`
    color: #fff;
  `}
  line-height: 1.25;
  cursor: pointer;
  padding: 0;
  margin-top: 45px;
  margin-bottom: 0;
  transition: all .3s ease-in-out;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: calc(100% + 12px);
    transform: translateY(-50%);
    max-width: 219px;
    width: ${({ active }) => active ? '100%' : 0};
    height: 1px;
    background-image: linear-gradient(270deg, #FFFFFF -0.77%, rgba(255, 255, 255, 0) 86.3%);
    transition: width .3s ease-in-out;
    @media only screen and (max-width: 991px) {
      right: -16px;
      width: 100%;
      max-width: 82px;
    }
  }
  br {
    @media only screen and (min-width: 475px) {
      display: none;
    }
  }
`;

const StyledAccordionContent = styled.div`
  counter-reset: numbers;
  grid-column: 2 / 3;
  grid-row: 1 / 10;
  transition: opacity .3s ease-in-out;
  opacity: 0;
  height: 0;
  pointer-events: none;
  visibility: hidden;
  overflow: hidden;
  ${({ active }) => active && css`
    opacity: 1;
    height: auto;
    pointer-events: auto;
    visibility: visible;
    overflow: auto;
  `}
  max-width: 760px;
  width: 100%;
  margin-left: auto;
  margin-top: -80px;
  padding-top: 120px;
  @media only screen and (max-width: 1280px) {
    max-width: 620px;
    margin-top: -80px;
    padding-top: 80px;
  }
  @media only screen and (max-width: 1024px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 100%;
    margin-top: 0;
    padding-top: 0;
  }
`;

const StyledAccordionContentItem = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;

  &:not(:last-of-type) {
    margin-bottom: 145px;
    @media only screen and (max-width: 991px) {
      margin-bottom: 120px;
    }
  }

  @media only screen and (max-width: 991px) {
    &:first-of-type {
      padding-top: 110px;

      .img {
        top: 55px;

        @media only screen and (max-width: 475px) {
          top: 60px;
        }
      }
    }

  }

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  .img {
    padding-top: 28px;
    @media only screen and (max-width: 991px) {
      position: absolute;
      right: 0;
      top: -30px;
      padding-top: 0;
    }
    @media only screen and (max-width: 475px) {
      top: -55px;
    }

    svg {
      z-index: 1;
      width: 130px;
      height: 130px;
      margin-right: 70px;
      @media only screen and (max-width: 1280px) {
        margin-right: 30px;
        width: 100px;
        height: 100px;
      }
      @media only screen and (max-width: 1024px) {
        margin-right: 20px;
        width: 80px;
        height: 80px;
      }
      @media only screen and (max-width: 991px) {
        margin-right: 0;
      }

      g path,
      path {
        @media only screen and (min-width: 991px) {
          fill: ${({ theme: { colors } }) => colors.white};
        }
      }
    }
  }

  .header {
    margin-bottom: 32px;
    position: relative;
    font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
    font-size: 42px;
    line-height: 1.3;
    font-feature-settings: 'case' on, 'liga' off;
    color: ${({ theme: { colors } }) => colors.white};
    z-index: 1;
    @media only screen and (max-width: 1024px) {
      font-size: 36px;
    }
    @media only screen and (max-width: 991px) {
      font-size: 20px;
      margin-bottom: 16px;
    }

    &:before {
      counter-increment: numbers;
      content: "0" counter(numbers);
      position: absolute;
      left: 0;
      bottom: -45px;
      color: ${({ theme: { colors } }) => colors.grey};
      opacity: .15;
      font-family: 'Playfair Display', sans-serif;
      font-weight: 700;
      font-size: 220px;
      font-feature-settings: "pnum" on, "lnum" on, "liga" off, "case" on;
      line-height: 1.13;
      z-index: -1;
      @media only screen and (max-width: 1280px) {
        font-size: 180px;
      }
      @media only screen and (max-width: 991px) {
        bottom: 100%;
        font-size: 120px;
        line-height: 1;
      }
      @media only screen and (max-width: 475px) {
        font-size: 100px;
      }
    }
  }

  .content {
    p {
      font-family: ${({ theme: { fontFamily } }) => fontFamily.default};
      font-size: 16px;
      line-height: 1.625;
      color: ${({ theme: { colors } }) => colors.blueText};
    }
  }
`;


export default HowItWorks;
