// Modules
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { CancelOrderReason } from 'shared'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Legacy components
import TextArea from '_legacy/common/TextArea'
import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent'

import { reasonsCancelAdapter } from './utils'

const CancelModal = ({ isOpen, onClose, orderId, cancelHandler, cancelProps, buttonText }) => {
  const t = useGetTranslate()
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      cancelReason: '',
      message: '',
    },
  })
  const cancelReason = methods.watch('cancelReason')
  const isBtnDisabled = cancelReason?.value === 0

  const onSubmit = React.useCallback(({ message = '', cancelReason }) => {
    const reason = cancelReason?.value
    if (cancelProps) {
      cancelHandler({ reason, message, cancelProps })
    } else {
      cancelHandler({ reason, offerId: orderId, message })
    }
    onClose()
  }, [cancelHandler, orderId])

  const reasonOptions = React.useMemo(() => {
    return [
      {
        label: t(reasonsCancelAdapter[CancelOrderReason.SCHEDULING_ISSUE]),
        value: CancelOrderReason.SCHEDULING_ISSUE,
      },
      {
        label: t(reasonsCancelAdapter[CancelOrderReason.WEATHER_CONDITIONS_ISSUE]),
        value: CancelOrderReason.WEATHER_CONDITIONS_ISSUE,
      },
      {
        label: t(reasonsCancelAdapter[CancelOrderReason.TECHNICAL_ISSUE]),
        value: CancelOrderReason.TECHNICAL_ISSUE,
      },
      {
        label: t(reasonsCancelAdapter[CancelOrderReason.INAPPROPRIATE_INFO_ISSUE]),
        value: CancelOrderReason.INAPPROPRIATE_INFO_ISSUE,
      },
      {
        label: t(reasonsCancelAdapter[CancelOrderReason.OTHER]),
        value: CancelOrderReason.OTHER,
      },
    ]
  }, [t])

  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <FormProvider {...methods}>
        <div className="cancel-modal">
          <h1 className="modal-header">{t('cancel-reason.modal.title')}</h1>
          <p className="modal-text">{t('cancel-reason.modal.description')}</p>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <label className="input-label">
              {t('cancel-reason.reason.label')}
              <SelectComponent
                name={'cancelReason'}
                placeholder={t('cancel-reason.reason.placeholder')}
                options={reasonOptions}
                rules={{ required: 'shared.words.required' }}
                menuPortalTarget={document.body}
              />
            </label>

            {cancelReason?.value === CancelOrderReason.OTHER && (
              <label className="input-label">
                {t('cancel-reason.other.label')}
                <TextArea
                  maxLength={200}
                  name="message"
                  className="review-modal__textarea"
                  placeholder={t('cancel-reason.other.placeholder')}
                  isRequired
                  requiredMessage={t('cancel-reason.required.message')}
                />
              </label>
            )}

            <Button stretch typeButton="submit" paddingVertical={16} disabled={isBtnDisabled} text={t(buttonText)} />
          </form>
        </div>
      </FormProvider>
    </ModalPortal>
  )
}

export default CancelModal
