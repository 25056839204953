import React from 'react';
import PropTypes from 'prop-types';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Slide from '../../../Slide';
import Icon from 'assets/icons/Icon';
import { Link } from 'react-router-dom';

const Empty = ({ isMobile = false, firstName }) => {
  const BasicEmpty = isMobile ? Slide.MobileSlide.Empty : Slide.DesktopSlide.Empty;
  const t = useGetTranslate()

  return (
    <BasicEmpty
      isMirroredInside={false}
      size="big"
      icon={<Icon.InPersonExperiences/>}
      content={
        <Trans
          tKey='talent-public-view.sliders.live-experiences.empty.content.label'
          phs={[{ ph: '{{firstName}}', value: firstName }]}
        />
      }
      hint={
        <span>
          {t('talent-public-view.sliders.lessons.empty.hint.label.text')}
          {' '}
          <Link
            className="tbv-slider-mobile-slide-empty-hint-link"
            onClick={() => alert('This actions isn\'t implemented')}
          >
            {t('talent-public-view.sliders.lessons.empty.hint.label.let')}
            {' '}
            {firstName}
            {' '}
            {t('talent-public-view.sliders.lessons.empty.hint.label.know')}
            .
          </Link>
        </span>
      }
    />
  );
};

Empty.propTypes = {
  isMobile: PropTypes.bool,
  firstName: PropTypes.string.isRequired,
};

export default Empty;
