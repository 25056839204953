import React from 'react'
import get from 'lodash/get'
import './index.scss'

import { slotTypes } from '../../utils/adapters'
import BackgroundSlot from './BackgroundSlot'
import LockSlot from './LockSlot'
import OfferSlot from './OfferSlot'
import OrderSlot from './OrderSlot'

const slotConfig = {
  [slotTypes.background]: BackgroundSlot,
  [slotTypes.lock]: LockSlot,
  [slotTypes.offer]: OfferSlot,
  [slotTypes.order]: OrderSlot,
}

const CalendarSlot = props => {
  const slotType = get(props, 'event.extendedProps.slotType')
  const Component = slotConfig[slotType]
  return Component ? <Component {...props} /> : null
}

export default CalendarSlot
