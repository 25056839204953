import React, {  useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'

import TalentPublicView from './TalentPublicView';
import { useSetTalentPublicView, useTalentPublicView } from 'store/hooks/globalState/useTalentPublic';
import { useFetchTalentPublicView, useFetchPreviewTalentPublicView } from 'requests/talent-public-view';
import { useParams } from 'react-router-dom'

const TalentPublicContainer = ({ isPreview, previewFromEditData = {} }) => {
  const currentUserPersonalData = useTalentPublicView(state => get(state, 'currentUserPersonalData'));
  const publicView = useTalentPublicView(state => get(state, 'talent'));
  const fetchTalentPublicView = useFetchTalentPublicView();
  const clearTalentPublicView = useSetTalentPublicView(() => null)
  const fetchPreviewTalentPublicView = useFetchPreviewTalentPublicView();
  const fetchData = isPreview ? fetchPreviewTalentPublicView : fetchTalentPublicView;
  const { talentNickname } = useParams();

  useEffect(() => {
    fetchData();
    return () => {
      clearTalentPublicView()
    }
  }, [talentNickname]);

  return (
    <TalentPublicView
      isPreview={isPreview}
      talentData={{ ...publicView, ...previewFromEditData }}
      currentUserPersonalData={currentUserPersonalData}
      // error={error} // TODO: add in the future
      loading={!publicView}
    />
  )
};

TalentPublicContainer.propTypes = {
  isPreview: PropTypes.bool,
  previewFromEditData: PropTypes.object,
};

export default TalentPublicContainer
