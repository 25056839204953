// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Utils
import { getMonthDate } from 'utils/date'


const MessagesDateDivider = ({ date }) => {
  return (
    <div className="date-divider">
      <p>
        <span> {getMonthDate(date)}</span>
      </p>
    </div>
  )
}

MessagesDateDivider.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]).isRequired,
}

export default MessagesDateDivider
