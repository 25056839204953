import { cond, equals, where } from 'lodash/fp'
import { EntourageSubscriptionType, TypesOfEntourage } from 'shared'

export const getEntourageError = cond([
  [
    where({
      expectedEntourageType: equals(TypesOfEntourage.FULL),
      currentUserEntourage: equals(EntourageSubscriptionType.LITE),
    }),
    () => '_errors.book-wrong-entourage-type--lite-to-full',
  ],
  [
    where({
      expectedEntourageType: equals(TypesOfEntourage.FULL),
    }),
    () => '_errors.book-wrong-entourage-type--full',
  ],
  [
    where({
      expectedEntourageType: equals(TypesOfEntourage.EITHER),
    }),
    () => '_errors.book-wrong-entourage-type--either'
  ]
])


