// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row } from '_legacy/common';
import Button from '_legacy/components/Button';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const SubmitButton = React.memo(({ className = '', loader }) => {
  const t = useGetTranslate()

  const { formState: { isDirty } } = useFormContext()

  return (
    <StyledWrapper
      className={className}
      component={
        <>
          <Button
            text={t('shared.words.book-now')}
            typeButton='submit'
            paddingVertical={13}
            paddingHorizontal={25}
            disabled={!isDirty}
            loader={loader}
          />
        </>
      }
    />
  );
});

const StyledWrapper = styled(Row)`
  margin-top: 100px;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
  ._button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.62;
    color: #272835;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
  }
`;

SubmitButton.propTypes = {
  className: PropTypes.string,
  loader: PropTypes.bool,
};

export default SubmitButton;
