import identity from 'lodash/identity'

import { queryNormalizer, slotsServerAdapter } from '../utils/adapters'
import { getTalentsCalendarSlots as getTalentsCalendarSlotsApi } from '../api/calendar'

import {
  getValidSlotsByStartEndTime,
  sortSlots,
  applyDriveTime,
  applyDriveTimeToAllSlots,
  mergeOverlappedSlot,
  getAvailableRange,
  splitSlotsByDate,
  applyBusinessHours,
  applyMinBookingTime,
  filterByDuration,
  getFutureSlots,
} from '../utils'
import { toBackgroundEvents, toLockEvents } from '../utils/adapters'

export const fetchTalentCalendarSlots = async ({ talentId, filters }) => {
  const { slots } = await getTalentsCalendarSlotsApi({
    talentId,
    query: { ...queryNormalizer(filters), limit: 1000, sortField: 'startTime' },
  })

  return slotsServerAdapter(slots)
}

export const fetchTalentAvailableCalendar = async ({
  talentId,
  filters,
  event: { travelTime, businessHours, minBookingTimeBeforeExperience, duration },
}, { beforeTransform = identity, afterTransform = identity } = {}) => {
  // todo: check it
  const { startTime, endTime } = queryNormalizer(filters)

  const slots = await fetchTalentCalendarSlots({ talentId, filters })

  const transformedSlots = beforeTransform(slots)

  const validSlots = getValidSlotsByStartEndTime(transformedSlots)
  const sorted = sortSlots(validSlots)
  const slotsWithDriveTime = applyDriveTime(sorted)
  const withTravelTime = applyDriveTimeToAllSlots(slotsWithDriveTime, travelTime)
  const merged = mergeOverlappedSlot(withTravelTime)
  // const slotsWithoutFirstDay = getSlotsInRange(merged, filters.startTime, filters.endTime)

  // todo: check it
  const freeSlots = getAvailableRange(merged, startTime, endTime)
  const freeSlotsByDate = splitSlotsByDate(freeSlots)
  const withBusinessHours = applyBusinessHours(freeSlotsByDate, businessHours)
  const withMinBookingTime = applyMinBookingTime(withBusinessHours, minBookingTimeBeforeExperience)
  const filteredFreeSlots = filterByDuration(withMinBookingTime, duration)
  const futureSlots = getFutureSlots(filteredFreeSlots)

  const slotsWithBackground = afterTransform(toBackgroundEvents(futureSlots))
  const slotsWithLock = toLockEvents(merged)

  return { slotsWithBackground, slotsWithLock }
}
