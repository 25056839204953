import React from 'react'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'
import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import styled from 'styled-components'
// Assets
import { ReactComponent as LoadMoreIcon } from 'assets/load-more.svg'

import { scrollToRef } from 'utils/scrollToRef';
import { useWindowDimensions } from'store/hooks/globalState/useGlobalState'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import DownloadButton from 'components/Buttons/DownloadButton'

import Button from '_legacy/components/Button'

const ResourceCenterItems = ({ shouldScroll, list, filters, onClickLoadMore }) => {
  const t = useGetTranslate()
  const isMobile = useWindowDimensions(prop('width')) <= 767

  const componentRef = React.useRef(null)

  React.useEffect(() => {
    if (isMobile && shouldScroll) {
      scrollToRef(componentRef)
    }
  }, [shouldScroll])

  return (
    <div ref={componentRef} className="resource-center__items">
      {list
        .map(({
          id,
          title,
          description,
          files = [],
        }) => (
          <div key={id} className="resource-center__item">
            <h2 className="font-header-h2">{title}</h2>
            <p className="font-body-2">{description}</p>
            {files.map(({ originalName, fullUrl }, i) => (
              <>
                { ['image/jpeg', 'image/png'].includes(files[i].mimeType) &&
                  <div key={`${originalName}_${i}`} style={{ marginBottom: '20px', height: '105.19px', width: '187px' }}>
                    <FilePreviewContainer file={files[i]} resource={'resource-center_preview'} />
                  </div>
                }
                <div key={`${originalName}_${i}`} className="resource-center__item--file">
                  <DownloadButton
                    fullUrl={fullUrl}
                    text={`${t('resource-center.items.download')} ${originalName.split('.').pop()}`}
                  />
                </div>
              </>
            ))}
          </div>
        ))}

      {!!filters?.remains && (
        <Button
          text={t('resource-center.items.see-more')}
          primary
          handleOnClick={onClickLoadMore}
          fontSize="16px"
          fontWeight={700}
          paddingVertical={12}
          paddingHorizontal={16}
          iconComponent={<LoadMoreIcon />}
        />
      )}
    </div>
  )
}

ResourceCenterItems.propTypes = {
  shouldScroll: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    files: PropTypes.array,
  })),
  filters: PropTypes.shape({ page: PropTypes.number }),
  onClickLoadMore: PropTypes.func,
}
const FilePreviewContainer = styled(FilePreview)`
  position: absolute;
  display: block;
  width: 100px;
  height: 100px;
  object-fit: cover;
  z-index: 0;
`;

export default ResourceCenterItems
