import { pipe, cond, always, merge, T } from 'lodash/fp'
import { statuses } from '../utils'

const fillInPersonExperienceOrderConfig = merge({
  isShowInProgress: false,

  isShowCalendar: false,
  isShowHold: false,
  isShowWaitingForApprove: false,

  isShowRejected: false,
  isShowCompleted: false,
  isShowClosed: false,
  isShowCanceled: false,
})

export const getInPersonExperienceOrderConfig = pipe(
  cond([
    [statuses.isOccurrenceInProgress, always({ isShowInProgress: true })],

    [statuses.isOrderUnderReview, always({ isShowWaitingForApprove: true })],
    [statuses.isOrderApprovedWithTime, always({ isShowHold: true })],
    [statuses.isOrderApprovedWithoutTime, always({ isShowCalendar: true })],

    [statuses.isOrderRejected, always({ isShowRejected: true })],
    [statuses.isOrderCompleted, always({ isShowCompleted: true })],
    [statuses.isOrderClosed, always({ isShowClosed: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],

    [T, always({})],
  ]),
  fillInPersonExperienceOrderConfig
)
