import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

const FILTERS_KEY = 'myBookingsFilters'
const TABLE_KEY = 'myBookingsTable'

export const useMyBookingsFilters = createUseGlobalState(FILTERS_KEY)
export const useSetMyBookingsFilters = createUseSetGlobalState(FILTERS_KEY)

export const useMyBookingsTable = createUseGlobalState(TABLE_KEY)
export const useSetMyBookingsTable = createUseSetGlobalState(TABLE_KEY)
