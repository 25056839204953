import { CalendarSlotType, enums, TalentOffersType, types } from 'shared'
import { format } from 'date-fns'
import kebabCase from 'lodash/kebabCase'
import { weekOfMonth } from '_legacy/containers/Experience/translates'
import { slotBackground } from 'components/Calendar/BaseCalendar/configs/slot-config'

const {
  DOES_NOT_REPEAT,
  DAILY,
  WEEKLY_ON_MONDAY,
  MONTHLY_ON_THE_SECOND_MONDAY,
  ANNUALLY_ON_AUGUST_10,
  EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
} = types.experiences.recurrence.RecurringDefinedInAdvanceValues

export const typeOptions = [
  {
    label: 'calendar.new-event-form.type.options.my-event',
    value: CalendarSlotType.MY_EVENT,
    color: slotBackground[CalendarSlotType.MY_EVENT],
  },
  {
    label: 'calendar.new-event-form.type.options.in-person-experience',
    value: TalentOffersType.IN_PERSON_EXPERIENCE,
    color: slotBackground[TalentOffersType.IN_PERSON_EXPERIENCE],
  },
  {
    label: 'calendar.new-event-form.type.options.virtual-events',
    value: TalentOffersType.VIRTUAL_EVENT,
    color: slotBackground[TalentOffersType.VIRTUAL_EVENT],
  },
  {
    label: 'calendar.new-event-form.type.options.time-unavialable',
    value: CalendarSlotType.TIME_UNAVAILABLE,
    color: slotBackground[CalendarSlotType.TIME_UNAVAILABLE],
  },
]
export const editTypeOptions = [
  {
    label: 'calendar.new-event-form.type.options.my-event',
    value: CalendarSlotType.MY_EVENT,
    color: slotBackground[CalendarSlotType.MY_EVENT],
  },
  {
    label: 'calendar.new-event-form.type.options.time-unavialable',
    value: CalendarSlotType.TIME_UNAVAILABLE,
    color: slotBackground[CalendarSlotType.TIME_UNAVAILABLE],
  },
]


const recurringTypes = [
  DOES_NOT_REPEAT,
  DAILY,
  WEEKLY_ON_MONDAY,
  MONTHLY_ON_THE_SECOND_MONDAY,
  ANNUALLY_ON_AUGUST_10,
  EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
  'CUSTOM',
]

export const getRecurringLabel = type => `create-experience.form.recurring.options.${kebabCase(type)}`

export const recurringTypesOptions = recurringTypes.map(type => ({
  value: type,
  label: getRecurringLabel(type),
}))

export const getMonthOptions = date => {
  return [
    {
      label: `Monthly on ${format(new Date(date), 'do')}`,
      value: enums.Recurrence.RepeatEveryOnMonthTypeEnum.SINGLE_DATE,
    },
    {
      label: `Monthly on the ${weekOfMonth(date).toLowerCase()} ${format(new Date(date), 'EEEE')}`,
      value: enums.Recurrence.RepeatEveryOnMonthTypeEnum.POSITION_DATE,
    },
  ]
}
