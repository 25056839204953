import React from 'react';
import get from 'lodash/get';
import compose from 'lodash/fp/compose';
import kebabCase from 'lodash/kebabCase';

import { useJoinToTalent } from 'requests/talent-invitation';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useTalentInvitation } from 'store/hooks/globalState/talentInvitation';

import RegistrationForm from '../User/Form';
import { submitAdapter } from '../User/utils/submitAdapter';


const TalentInvitationForm = React.memo(function TalentInvitationForm() {
  const t = useGetTranslate();

  const linkType = useTalentInvitation(state => get(state, 'data'))
  const loading = useTalentInvitation(state => get(state, 'loading'))
  const joinToTalent = useJoinToTalent()

  const onSubmit = compose(joinToTalent, submitAdapter)

  const getProfileManagersOptions = [{
    value: linkType,
    label: t(`register-user.form.profile-type.${kebabCase(linkType)}.label`),
  }]

  return (
    <RegistrationForm
      onSubmit={onSubmit}
      profileManagersOptions={getProfileManagersOptions}
      isLoading={loading}
    />
  );
});

export default TalentInvitationForm;
