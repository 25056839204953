import React from 'react'
import PropTypes from 'prop-types'
import { types } from 'shared'

const { PHOTO } = types.file.Types

import CustomVideo from '_legacy/common/custom-video/CustomVideo'
import CustomPicture from '_legacy/common/CustomPicture'
import PictureWrapper from './PictureWrapper';

const Picture = ({ className = '', file }) => {
  if (!file) return null;

  const isPhoto = file.type === PHOTO
  const Component = isPhoto ? CustomPicture : CustomVideo

  return (
    <PictureWrapper className={className}>
      <Component className='custom-picture' file={file} />
    </PictureWrapper>
  )
}


Picture.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
}

export default Picture
