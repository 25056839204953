import React, { useState } from 'react';
import { string, object } from 'prop-types';
import get from 'lodash/get'

import TalentInfoBlockIconsBar from './TalentInfoBlockIconsBar/';
import TalentInfoBlockHometown from './TalentInfoBlockHometown/';
import TalentInfoBlockNationality from './TalentInfoBlockNationality/';
import TalentInfoBlockLanguage from './TalentInfoBlockLanguage/';
import TalentInfoBlockCharities from './TalentInfoBlockCharities/';

import PointerLine from '../../Common/PointerLine';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';


const TalentInfoBlock = ({ talent, className }) => {
  const t = useGetTranslate();
  const [activeIconIndex, setActiveIconIndex] = useState(0);

  const { location, nationality, languages, charities } = talent;

  const city = get(location, 'city', '')
  const state = get(location, 'stateOrSimilar', '')
  const country = get(location, 'country', '')

  return (
    <div className={`talent-info-block ${className}`}>
      <h4 className='title'>{t('talent-public-view.general-info')}</h4>
      <TalentInfoBlockIconsBar
        activeIndex={activeIconIndex}
        setActiveIndex={setActiveIconIndex}
      />
      <PointerLine activeIconIndex={activeIconIndex} />
      <div>
        {activeIconIndex === 0 && (
          <TalentInfoBlockHometown city={city} state={state} country={country} />
        )}
        {activeIconIndex === 1 && (
          <TalentInfoBlockNationality nationality={nationality} />
        )}
        {activeIconIndex === 2 && (
          <TalentInfoBlockLanguage languages={languages} />
        )}
        {activeIconIndex === 3 && (
          <TalentInfoBlockCharities
            charities={charities}
          />
        )}
      </div>
    </div>
  );
};

TalentInfoBlock.propTypes = {
  talent: object.isRequired,
  className: string,
};

export default TalentInfoBlock;
