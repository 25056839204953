// TODO: Refactor this component and move to new structure

// Modules
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import {
  range,
  prop,
  isEmpty,
  uniqBy,
  compact,
  allPass,
  compose,
} from 'lodash/fp'
import { isValid } from 'date-fns'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchLiveVirtualEvent } from 'requests/public-views/live-virtual-event/live-virtual-events-public-view'
import { useLiveVirtualEvents, useSetLiveVirtualEvents } from 'store/hooks/globalState/usePublicView'
import { useBookLiveVirtualEvent } from 'requests/public-views/live-virtual-event/live-virtual-event-booking'

// Utils
import { getCharityLabel } from 'utils/charities'
import { getTimezoneAbbr } from 'utils/services/timezone'
import {
  getParticipantOption,
  getDuration,
  getTimeFormat,
  getDateOptions
} from '../utils'
import { getUserTimeZone } from 'utils/user'
import {
  mapSlotToTimezone,
  mapSlotToUTC,
  isSlotEqualToSelectedDate,
} from '_legacy/containers/PublicViews/utils'

// Legacy components
import PublicFormItem from '_legacy/components/PublicView/PublicFlexibleFormItemContext'
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicDetailsBlock from '_legacy/components/PublicView/PublicDetailsBlock'
import PublicFixedFormItem from '_legacy/components/PublicView/PublicFixedFormItem'
import PublicInfoForm from '_legacy/components/PublicView/PublicInfoForm'
import PublicDetailsItemBlock from '_legacy/components/PublicView/PublicDetailsItemBlock'
import PublicCardBlockInfo from '_legacy/components/PublicView/PublicCardBlockInfo'
import PublicCardBlockActions from '_legacy/components/PublicView/PublicCardBlockActions'
import PageLoader from '_legacy/common/Template/PageLoader'
import PublicSlider from '_legacy/components/PublicSlider/'

// TODO: import these styles elsewhere or move to the global styles
import '../public-view-common.scss'

// TODO: check why this is dummy data
const currentParticipantsCount = () => Math.floor(Math.random() * 100)
const participantsOptions = range(0, 100 - currentParticipantsCount).map(getParticipantOption)


const LiveVirtualEventPublic = () => {
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const t = useGetTranslate()

  const methods = useForm({ shouldUnregister: false })

  const fetchLiveVirtualEvent = useFetchLiveVirtualEvent()
  const bookLiveVirtualEvent = useBookLiveVirtualEvent(setIsSubmitting)
  const clearVirtualEvent = useSetLiveVirtualEvents(() => null)

  const virtualEvent = useLiveVirtualEvents(prop('liveVirtualEvent'))
  const slots = useLiveVirtualEvents(prop('slots'))
  const isFollowed = useLiveVirtualEvents(prop('currentUserPersonalData.isFollowed'))

  const timezone = useUser(getUserTimeZone)
  const timezoneAbbr = getTimezoneAbbr(timezone)

  React.useEffect(() => {
    fetchLiveVirtualEvent()
    return () => clearVirtualEvent()
  }, [])

  if (!virtualEvent || !slots) return <PageLoader />

  const {
    id,
    name,
    charities,
    price,
    description,
    physicalItems,
    media,
    talent,
  } = virtualEvent

  const mapSlotToUserTimezone = mapSlotToTimezone(timezone)
  const mapSlotToUTCFromTimezone = mapSlotToUTC(timezone)

  const watchedDate = methods.watch('date')

  // Transform watched slot date fields into UTC ISO string
  const timezoneSlots = (slots || []).map(mapSlotToUserTimezone)
  const timezoneSlot = (timezoneSlots || []).find(isSlotEqualToSelectedDate(watchedDate))
  const slot = mapSlotToUTCFromTimezone(timezoneSlot)

  const onSubmit = formData => bookLiveVirtualEvent({
    slot,
    includedFriends: formData?.includedFriends,
  })

  const allDateOptions = getDateOptions(timezoneSlots)
  const dateOptions = uniqBy('value', allDateOptions)

  const formattedStartTime = isValid(timezoneSlot?.startTime) && getTimeFormat(timezoneSlot?.startTime)
  const formattedEndTime = isValid(timezoneSlot?.endTime) && getTimeFormat(timezoneSlot?.endTime)

  const formattedDuration = allPass([
    compose(isValid, prop('startTime')),
    compose(isValid, prop('endTime')),
  ])(timezoneSlot)
    ? getDuration(timezoneSlot?.startTime, timezoneSlot?.endTime)
    : ''

  const submitButtonProps = slot?.booked ? { disabled: true } : {}

  return (
    <div className="experience-pv-wrapper ">
      <div className="public-view live-virtual-event">
        <PublicHeadingBlock title={t('shared.offers.live-virtual-event')} name={name} />

        <div className="public-view__card-wrapper wrapper">
          <div className="live-virtual-event__card">
            <div className="live-virtual-event__card__top-border">
              <span className="top-border"/>
            </div>

            <div className="public-view__card-inner-wrapper">
              <PublicCardBlockInfo
                talent={talent}
                withBg={false}
                duration={formattedDuration}
                locations={[{ address: t('experience.card.online') }]}
                isOnline={true}
                pricePerParticipant={price}
              />

              <PublicCardBlockActions id={id} isFollowed={isFollowed} centered>
                {
                  media &&
                    <PublicSlider covers={media} className="reversed" />
                }
              </PublicCardBlockActions>
            </div>
          </div>
        </div>

        <FormProvider {...methods}>
          <PublicInfoForm
            isSubmitting={isSubmitting}
            onSubmit={methods.handleSubmit(onSubmit)}
            textBelow={t('experience.info.background-check')}
            pricePerParticipant={price}
            buttonProps={submitButtonProps}
          >
            <h3>{t('live-virtual-event.info.title')}</h3>
            <p className="public-view__timezone-reminder">
              {t('experience.info.timezone-reminder-user')}:
              <span>{`(${timezoneAbbr})`}</span>
            </p>

            <div className="public-view__info-list">
              {!isEmpty(dateOptions) && (
                <PublicFormItem name="date" title={t('experience.info.date')} items={dateOptions} />
              )}

              <PublicFixedFormItem
                title={t('experience.info.start-time')}
                fixedItem={formattedStartTime}
              />

              <PublicFixedFormItem
                title={t('experience.info.end-time')}
                fixedItem={formattedEndTime}
              />

              <PublicFixedFormItem
                title={t('live-virtual-event.info.available-places')}
                fixedItem={`${currentParticipantsCount}`}
              />
            </div>

            <h3 className="alternate">{t('experience.info.add-friend')}</h3>

            <div className="public-view__subtitle">
              {t('experience.info.add-friend-info')}
            </div>

            <div className="public-view__info-list alternate">
              <PublicFormItem
                name="includedFriends"
                items={participantsOptions}
                title={t('experience.info.include-friends-you')}
              />

              <PublicFormItem
                name="invitedFriends"
                items={participantsOptions}
                title={t('experience.info.include-friends-they')}
              />
            </div>
          </PublicInfoForm>
        </FormProvider>

        <PublicDetailsBlock heading={t('experience.details.event-heading')}>
          <PublicDetailsItemBlock className="two-rows">
            <div>
              <h6>{t('experience.details.description')}</h6>
              <p>{description}</p>
            </div>
          </PublicDetailsItemBlock>

          {
            physicalItems && (
              <PublicDetailsItemBlock className="two-rows">
                <div>
                  <h6>{t('experience.details.physical-items')}</h6>
                  <p>{physicalItems}</p>
                </div>
              </PublicDetailsItemBlock>
            )
          }

          {isEmpty(charities) ? null : (
            <PublicDetailsItemBlock className="two-rows">
              <div>
                <h6>{t('experience.details.charity-beneficiary')}</h6>
                {
                  compact(charities)
                    .map(charity =>
                      <p key={charity.id}>
                        {getCharityLabel(charity)}
                      </p>
                    )
                }
              </div>
            </PublicDetailsItemBlock>
          )}
        </PublicDetailsBlock>
      </div>
    </div>
  )
}

export default LiveVirtualEventPublic
