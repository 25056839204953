import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Radio } from './Radio'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const RadioList = ({ name, options = [], defaultChecked }) => {
  const t = useGetTranslate()
  return (
    <RadioListWrapper>
      {options.map(option => (
        <RadioWrapper
          key={option.value}
          name={name}
          label={t(option.label)}
          value={option.value}
          defaultChecked={option.value === defaultChecked}
        />
      ))}
    </RadioListWrapper>
  )
}

const RadioListWrapper = styled.div`
  display: flex;
  min-height: 40px;

  label:not(:last-of-type) {
    margin-right: 45px;
  }
`

const RadioWrapper = styled(Radio)`
  min-width: 80px;
`


RadioList.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOf([PropTypes.string, PropTypes.bool, PropTypes.number]),
    })
  ),
}

export default RadioList
