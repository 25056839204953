import React from 'react'
import PropTypes from 'prop-types'
import { Prompt, useHistory } from 'react-router'

import { useModal } from 'store/hooks/useModal'
import { useSetShouldNavigate, useSetSwitchNavigation, useShouldNavigate } from 'store/hooks/globalState/useSwitchNavigation'

import ChangeRouteModal from './ChangeRouteModal'

const ChangeRouteHandler = ({ shouldHandleNavigation, onDraft, onSubmit, isDraft }) => {
  const shouldNavigate = useShouldNavigate()
  const setShouldNavigate = useSetShouldNavigate()
  const clearNavigation = useSetSwitchNavigation(prev => ({ ...prev, agent: null, idToSwitch: null }))
  const [isOpen, openModal, closeModal] = useModal()

  const [lastLocation, setLastLocation] = React.useState(null)
  const [confirmedNavigation, setConfirmedNavigation] = React.useState(false)
  const history = useHistory()

  React.useEffect(() => {
    setShouldNavigate(!shouldHandleNavigation)
  }, [shouldHandleNavigation])

  React.useEffect(() => () => {
    setShouldNavigate(true)
  }, [])

  const handleBlockedNavigation = nextLocation => {
    if (!confirmedNavigation) {
      openModal()
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const onCloseHandler = () => {
    clearNavigation()
    closeModal()
  }

  const handleConfirmNavigationClick = () => {
    setShouldNavigate(true)
    setConfirmedNavigation(true)
    closeModal()
  }

  const onDraftHandler = () => {
    if (onDraft) onDraft()
    setShouldNavigate(true)
    setConfirmedNavigation(true)
    onCloseHandler()
  }

  const onSubmitHandler = () => {
    if (onSubmit) onSubmit()
    setShouldNavigate(true)
    setConfirmedNavigation(true)
    onCloseHandler()
  }

  React.useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation.pathname)
    }
  }, [confirmedNavigation, lastLocation])

  return (
    <>
      <Prompt
        when={!shouldNavigate && !confirmedNavigation}
        message={handleBlockedNavigation}
      />
      <ChangeRouteModal
        isOpen={isOpen}
        closeModal={onCloseHandler}
        isDraft={isDraft}
        onDiscard={handleConfirmNavigationClick}
        onDraft={onDraftHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  )
}

ChangeRouteHandler.defaultProps = {
  shouldHandleNavigation: true,
  onConfirm: () => {},
}

ChangeRouteHandler.propTypes = {
  shouldHandleNavigation: PropTypes.bool,
  onConfirm: PropTypes.func,
  onDraft: PropTypes.func,
}

export default ChangeRouteHandler
