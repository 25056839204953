import React from 'react'
import { prop, propEq, propOr } from 'lodash/fp'
import { FormProvider, useForm } from 'react-hook-form'

import {
  useReviewsByMeFilters,
  useReviewsByMeTable,
  useSetReviewsByMeFilters,
} from 'store/hooks/globalState/reviws/useReviewsByMe'
import {
  counterConfig,
  fields,
  subFiltersConfig,
} from '_legacy/configs/table/reviewsByMy'
import { useModal } from 'store/hooks/useModal'
import { useEditReview } from 'requests/review'
import { useFetchReviewsByMe } from 'requests/reviews/reviews-by-me'

import { REVIEWS } from '_legacy/components/Table/table-components/types/reviews/config'
import { elementsTypes } from '_legacy/components/Table/table-components/types/reviews/config'

import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import ReviewModal from '_legacy/components/Modal/ReviewModal'
import { getSaveFullName, getTalentLink, getUserLink } from 'utils/user'

const tableConfig = [
  {
    header: 'table.header.offering',
    field: 'occurrenceOfferType',
    type: elementsTypes.offering,
  },
  {
    header: 'table.header.my-review',
    field: 'rating',
    type: elementsTypes.review,
  },
  {
    header: 'table.header.to',
    field: 'to',
    type: elementsTypes.user,
  },
  {
    header: 'table.header.date',
    field: 'createdAt',
    type: elementsTypes['review-date'],
  },
]

const ReviewsByMe = () => {
  const reviews = useReviewsByMeTable()
  const reviewFilters = useReviewsByMeFilters()
  const fetchReviewsByMe = useFetchReviewsByMe()
  const editReview = useEditReview(() => fetchReviewsByMe(reviewFilters))
  const [isOpen, openModal, closeModal] = useModal(false)
  const [selectedId, setSelectedId] = React.useState(null)

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      modalRating: null,
      modalDescription: '',
    },
  })

  const handleOpen = ([id]) => {
    const review = reviews.find(propEq('id', id))
    methods.setValue('modalRating', String(propOr(1, 'rating', review)))
    methods.setValue('modalDescription', propOr('', 'description', review))

    setSelectedId(id)
    openModal()
  }

  const onSubmit = formValues => {
    editReview({
      reviewId: selectedId,
      rating: Number(prop('modalRating', formValues)),
      description: prop('modalDescription', formValues),
    })
    closeModal()
  }

  const actionsConfig = React.useMemo(() => {
    return [{ label: 'table.actions.edit-review', cbFn: handleOpen }]
  }, [handleOpen])

  const getReviewReceiver = () => {
    const selectedReview = (reviews || []).find(review => review.id === selectedId)

    if (selectedReview?.toUser) {
      return {
        userLink: getUserLink(selectedReview.toUser),
        fullName: getSaveFullName(selectedReview.toUser)
      }
    } else if (selectedReview?.toTalent) {
      return {
        userLink: getTalentLink(selectedReview.toTalent),
        fullName: getSaveFullName(selectedReview.toTalent)
      }
    }
  }

  const getOfferType = () => {
    const selectedReview = (reviews || []).find(review => review.id === selectedId)
    return selectedReview?.offerOccurrence?.title
  }

  return (
    <React.Fragment>
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        setFn={useSetReviewsByMeFilters}
        getFn={useReviewsByMeFilters}
      />
      <Table
        fields={fields}
        tableName={REVIEWS}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        getTableFn={useReviewsByMeTable}
        getFiltersFn={useReviewsByMeFilters}
        setFiltersFn={useSetReviewsByMeFilters}
        fetchTableFn={useFetchReviewsByMe}
      />
      <FormProvider {...methods}>
        <ReviewModal
          isOpen={isOpen}
          onSubmit={onSubmit}
          closeModal={closeModal}
          user={getReviewReceiver()}
          userFullName={getReviewReceiver()?.fullName}
          userLink={getReviewReceiver()?.userLink}
          offerType={getOfferType()}
        />
      </FormProvider>
    </React.Fragment>
  )
}

export default ReviewsByMe
