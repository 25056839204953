import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { api } from 'shared';

import { Row, Radio } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import DisplayNameTypeInfoModal from '_legacy/components/Modal/DisplayNameTypeInfoModal';

const DisplayNameType = ({ name }) => {
  const t = useGetTranslate();

  const { FIRST_NAME_AND_LAST_NAME, NICKNAME } = api.auth.signUp.talent.query.displayNameType;

  return (
    <StyledWrapper>
      <Row
        nameBlock={t('register.form.public-name.label')}
        tip={<TipButton modalComponent={DisplayNameTypeInfoModal} />}
        isRequired
        component={
          <div className='radio-wrapper'>
            <Radio
              name={name}
              value={FIRST_NAME_AND_LAST_NAME}
              label='register.form.public-name.full-name' defaultChecked
            />
            <Radio
              name={name}
              value={NICKNAME}
              label='register.form.public-name.username'
            />
          </div>
        }
        alignItems='center'
      />
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .row {
    align-items: center;
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
  }
`;

DisplayNameType.propTypes = {
  name: PropTypes.string.isRequired,
};

export default DisplayNameType;
