import { appApi } from 'api';
import isEmpty from 'lodash/isEmpty';
import DefaultAvatar from 'assets/images/icon/defaultAvatar.svg';

export const userPreparator = ({ id, firstName, lastName, profileImage }) => ({
  value: id,
  label: `${firstName} ${lastName}`,
  image: isEmpty(profileImage) ?  DefaultAvatar : profileImage.fullUrl,
});

export const loadUsersOptions = async (search, page, type) =>  {
  try {
    const { users, pageInfo } = await appApi.talent.join.search({
      name: search, page, type
    });

    return {
      options: users.map(userPreparator),
      hasMore: pageInfo.remains !== 0,
      additional: {
        page: pageInfo.page + 1,
      },
    };
  } catch {
    return {
      options: [],
      hasMore: false,
    };
  }
};


