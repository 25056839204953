import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import LocationsSelect from '_legacy/components/Form/Selects/basic/LocationsSelect';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export const Location = ({ name = 'location', nameBlock, isRequired, precision }) => {
  const t = useGetTranslate();

  return (
    <Row
      isRequired={isRequired}
      nameBlock={nameBlock ?? t('create-experience.form.fixed-location.label')}
      component={
        <LocationsSelect
          name={name}
          placeholder={t('create-experience.form.fixed-location.placeholder')}
          precision={precision}
          rules={{
            validate: (p) => !!p?.label === true || 'create-experience.form.fixed-location.errors.required',
          }}
        />
      }
    />
  );
};

Location.propTypes = {
  name: PropTypes.string,
  nameBlock: PropTypes.string,
  precision: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default Location;
