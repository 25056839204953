import React from 'react'

import LineString from '../common/LineString'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'


export default React.memo(function Email ({ isLoading, email }) {
  return (
    <PopperTooltip Component={email}>
      <LineString
        isLoading={isLoading}
        str={email}
      />
    </PopperTooltip>
  )
})
