import React from 'react'

import { getReviewsOfMe as getReviewsOfMeApi } from 'api/appApi/talent/review'
import {
  useSetReviewsOfMeTable,
  useSetReviewsOfMeFilters,
} from 'store/hooks/globalState/reviws/useReviewsOfMe'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import normalizeParams from 'utils/normalizeParams'

export function useFetchReviewsOfMe() {
  const setReviewsOfMeTable = useSetReviewsOfMeTable((prev, next) => next)
  const setReviewsOfMeFilters = useSetReviewsOfMeFilters((prev, next) => ({
    ...prev,
    ...next,
  }))
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    options => {
      const query = normalizeParams(options)
      setReviewsOfMeTable(null)
      getReviewsOfMeApi({ query })
        .then(({ reviews, pageInfo }) => {
          setReviewsOfMeTable(reviews)
          setReviewsOfMeFilters(pageInfo)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setReviewsOfMeTable, setReviewsOfMeFilters]
  )
}
