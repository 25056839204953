import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map';
import { types } from 'shared';
import isEqual from 'lodash/fp/isEqual'

const isUsa = isEqual('UNITED_STATES')

export const states = map(
  types.locations.USAStatesKeypair,
  (label, key) => ({ value: key, label }),
);

export const countries = map(
  types.locations.Countries,
  (label, key) => ({ value: key, label: label.native, data: label.code }),
);


export const submitAddressAdapter = address => {
  if (isEmpty(address)) return

  const addressLabel = get(address, 'address.label')
  const country = get(address, 'country.value');
  const state = get(
    address,
    isUsa(country) ? 'usaState.value' : 'state'
  );

  const city = get(address, 'city.value')


  return {
    state,
    postalCode: get(address, 'zipCode'),
    city,
    address: addressLabel,
  };
}
