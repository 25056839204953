import React from 'react'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'

import {
  useWindowDimensions,
  useSetSubMenuActive,
} from 'store/hooks/globalState/useGlobalState'

import KeyPressHandler from './KeyPressHandler'
import SlidesLoader from './SlidesLoader'
import Arrow from './Arrow'
import CardsList from './CardsList'
import MobileCarouselButton from './MobileCarouselButton'

const widthScale = 0.9
const heightScale = 0.95

const getPlaqueNicknameBySlideId = (slides, id) => {
  const currentSlide = (slides || []).find(slide => slide.id === id) || slides?.[0]
  return currentSlide?.plaqueNickname
}

function calcWrapper(ref, width) {
  if (!ref.current) return { width: 0, height: 0 }
  let ratio = 2.1
  if (width <= 480) ratio = 1
  const ww = ref.current.offsetWidth
  const wh = ref.current.offsetHeight
  if (ww / wh > ratio) {
    return {
      width: Math.round(wh * widthScale * ratio),
      height: Math.round(wh * heightScale),
    }
  } else {
    return {
      width: Math.round(ww * widthScale),
      height: Math.round((ww * heightScale) / ratio),
    }
  }
}

export default React.memo(function CustomCarousel() {
  const { width, height } = useWindowDimensions()
  const ref = React.useRef()
  const setSubMenuActive = useSetSubMenuActive((prev, next) => next, [])
  const [wrapperDimensions, setWrapperDimensions] = React.useState(
    calcWrapper(ref, width)
  )
  const [currentSlide, setCurrentSlide] = React.useState()
  const [slides, setSlides] = React.useState(null)

  const handleSubMenu = React.useCallback(
    value => {
      if (width <= 1024) return setSubMenuActive(true)
      setSubMenuActive(value)
    },
    [setSubMenuActive, width]
  )

  React.useLayoutEffect(() => {
    setTimeout(() => setWrapperDimensions(calcWrapper(ref, width)), 500)
    handleSubMenu()
  }, [width, height, setWrapperDimensions])

  React.useEffect(() => {
    return () => setSubMenuActive(false)
  }, [setSubMenuActive])

  const navigateToLeft = React.useCallback(() => {
    if (!slides) return
    setCurrentSlide(current => {
      const currentIndex = slides.findIndex(item => item.id === current)
      if (!~currentIndex) return slides[slides.length - 1].id
      if (currentIndex === 0) return slides[slides.length - 1].id
      return slides[currentIndex - 1].id
    })
  }, [slides, setCurrentSlide])

  const navigateToRight = React.useCallback(() => {
    if (!slides) return
    setCurrentSlide(current => {
      const currentIndex = slides.findIndex(item => item.id === current)
      if (!~currentIndex) return slides[1].id
      if (currentIndex === slides.length - 1) return slides[0].id
      return slides[currentIndex + 1].id
    })
  }, [slides, setCurrentSlide])

  const handlers = useSwipeable({
    onSwipedLeft: navigateToRight,
    onSwipedRight: navigateToLeft,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  return (
    <>
      <KeyPressHandler
        navigateToLeft={navigateToLeft}
        navigateToRight={navigateToRight}
      />
      <StyledWrapper
        ref={ref}
        onMouseEnter={() => handleSubMenu(true)}
        onMouseLeave={() => handleSubMenu(false)}
      >
        <SlidesLoader setSlides={setSlides} />
        <Arrow className="left" clickHandler={navigateToLeft} />
        <CardsList
          wrapperDimensions={wrapperDimensions}
          slides={slides}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          handlers={handlers}
        />
        <Arrow className="right" clickHandler={navigateToRight} />
      </StyledWrapper>
      <MobileCarouselButton
        talentNickname={getPlaqueNicknameBySlideId(slides, currentSlide)}
        navigateToLeft={navigateToLeft}
        navigateToRight={navigateToRight}
      />
    </>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 10px;
  margin-bottom: 18vh;
  flex-grow: 1;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  max-width: 1440px;
  @media screen and (max-width: 1024px) {
    width: 90%;
    margin-bottom: 160px;
  }
  @media screen and (max-width: 768px) {
    width: 98%;
    margin-bottom: 100px;
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 10px;
  }
`
