// Base imports
import React from 'react'
import { Link, useHistory } from 'react-router-dom'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import * as r from '_legacy/constants/routes'

// Store (Hooks)
import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUnpublishOffer } from 'requests/manage-offerings'
import { fetchIsVirtualEventEditable, useCancelOrder, useFetchVirtualEventOrderDetails } from './state/requests'
import { useSetVirtualEventOrderDetails, useVirtualEventOrderDetails } from './state/store'

// Utils
import { getTalentPlaqueNickname, getUserTimeZone } from 'utils/user'
import { getDateTimeProps, getMonthDayOnly, getTimeRange } from 'utils/date'
import { getLocationLabel, getLocationPlace } from 'utils/location'
import {
  getCharitiesTitle,
  isClosed,
  isCompleted,
  isOccurrenceExpired,
  isShowHoldStatus,
  isWaitingOrInProgress,
} from 'components/OfferingDetails/utils'

// Components
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsCountdown from 'components/OfferingDetails/components/OfferingDetailsCountdown'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingsDetailsHeaderHold from 'components/OfferingDetails/components/OfferingsDetailsHeaderHold'
import VirtualEventOrderDetailsOrders from './components/VirtualEventOrderDetailsOrders'
import CancelModal from 'components/OfferingDetails/components/CancelModal'

// Legacy components
import PageLoader from '_legacy/common/Template/PageLoader'
import Button from '_legacy/components/Button'
import CustomMedia from '_legacy/common/CustomMedia'

const VirtualEventOrderDetails = () => {
  const t = useGetTranslate()
  const history = useHistory()

  const user = useUser()
  const timeZone = getUserTimeZone(user)
  const userNickname = getTalentPlaqueNickname(user)

  const occurrence = useVirtualEventOrderDetails()
  const clearOccurrence = useSetVirtualEventOrderDetails(() => null)

  const cancelOrder = useCancelOrder()
  const unpublishOffer = useUnpublishOffer()
  const fetchIsEditable = fetchIsVirtualEventEditable()
  const fetchOccurrence = useFetchVirtualEventOrderDetails()

  React.useEffect(() => {
    fetchOccurrence()
    fetchIsEditable()
    return () => {
      clearOccurrence()
    }
  }, [])

  if (!occurrence) return <PageLoader />

  const {
    name,
    startTime: _startTime,
    maxNumberOfParticipants,
    media,
    price,
    offerSequentialId,
    participantsCount,
    offerId,
    totalCharityFee,
  } = occurrence

  // date/time
  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })

  const infoConfig = [
    {
      icon: <Icon.Calendar />,
      item: formattedDate,
      subitem: formattedTimeRange,
    },
    {
      icon: <Icon.Pin />,
      item: t('recurring-list.locations.online'),
    },
    {
      icon: <Icon.Participants />,
      item: `${participantsCount} of ${maxNumberOfParticipants || t('recurring-list.info.unlimited')}`,
      subitem: '',
    },
    {
      icon: <Icon.Pricetag />,
      item: `$${price} ${t('currency.usd')}`,
      subitem: t('order-details.price-per-person'),
    },
  ]

  const [isOpenCancelModal, openCancelModal, closeCancelModal] = useModal(false)

  const handleUnpublish = () => {
    unpublishOffer([offerId])
    history.push(`/${r.ORDERS}/${r.LIST}`)
  }

  const handleStartEvent = () => alert('not implemented')

  return (
    <div className="live-virtual-event-order od__margins">
      <OfferingDetailsTitle typeHeader={t('order-details.live-virtual-event.header')} title={name} />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              {media?.[0] ? <CustomMedia file={{ ...media?.[0].file, originalName: '' }} /> : <img alt={''} />}
            </div>
            <div className="od__column od--padding-info">
              {infoConfig.filter(Boolean).map((item, i) => {
                return <OfferingsDetailsHeaderItem key={i} {...item} />
              })}
              <Link
                className="od__row od__public-link"
                to={`/${r.LIVE_VIRTUAL_EVENTS}/${userNickname}/${offerSequentialId}`}
              >
                <div className="od--main-text od--accented od__public-link--text">
                  {t('order-details.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="od--shadow-box od--centered">
          {isWaitingOrInProgress(occurrence) && (
            <div className="od--with-padding od__column">
              <p className="od--uppercase-title">{t('order-details.live-virtual-event.header')}</p>
              <OfferingDetailsCountdown
                countdownDate={_startTime}
                handleClick={handleStartEvent}
                title={t('order-details.virtual-chats.start-event')}
              />
              <Button
                handleOnClick={handleUnpublish}
                primary
                text={t('shared.words.cancel-order')}
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
              />
            </div>
          )}

          {isShowHoldStatus(occurrence) && (
            <OfferingsDetailsHeaderHold
              title={t('order-details.live-virtual-event.header')}
              date={getMonthDayOnly(startTime)}
              duration={getTimeRange([startTime, endTime])}
              locationMain={getLocationPlace(location.place)}
              locationFull={getLocationLabel(location.place)}
              typeText={t('shared.offers.live-in-person-lesson')}
            >
              <Button
                stretch
                primary
                paddingVertical={16}
                text={t('shared.words.cancel-order')}
                handleOnClick={() => openCancelModal()}
              />
            </OfferingsDetailsHeaderHold>
          )}

          {isCompleted(occurrence) && (
            <OfferingDetailsEscrowFunds
              title={t('order-details.live-virtual-event.header')}
              price={price * participantsCount}
              description={t('order.description.live-virtual-event')}
            />
          )}

          {isClosed(occurrence) && (
            <div className="od--centered">
              <OfferingDetailsEscrowFunds
                title={t('shared.offers.live-in-person-lesson')}
                subtitle={t('order.description.received')}
                price={price * participantsCount}
              />
              {totalCharityFee && price && (
                <OfferingDetailsEscrowFunds
                  className="od--bordered"
                  subtitle={getCharitiesTitle(t, price * participantsCount, totalCharityFee)}
                  price={totalCharityFee}
                  description={t('order.description.charities.text')}
                />
              )}
            </div>
          )}

          {isOccurrenceExpired(occurrence) && (
            <OfferingDetailsGenericStatus
              title={t('order-details.live-virtual-event.header')}
              type={t('order.header.expired')}
            />
          )}
        </div>
      </div>

      <CancelModal
        orderId={offerId}
        isOpen={isOpenCancelModal}
        onClose={closeCancelModal}
        cancelHandler={cancelOrder}
        buttonText="cancel-reason.cancel-occurrence.button"
      />

      <VirtualEventOrderDetailsOrders />
    </div>
  )
}

export default VirtualEventOrderDetails
