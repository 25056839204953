import React from 'react';
import PropTypes from 'prop-types';
import BasicActions from '../../../BasicActions';

const Actions = ({ slidesCount, onNext, onPrev }) => {
  return (
    <BasicActions
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={0}
      padding="0"
    />
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,

  slidesCount: PropTypes.number.isRequired,
};

export default Actions;
