import PropTypes from 'prop-types'
import { NotificationType, NotificationStatus, NotificationAdditionalType } from 'shared'

export const NotificationTypes = {
  additionalType: PropTypes.oneOf([
    NotificationAdditionalType.REJECTED,
    NotificationAdditionalType.ACCEPTED,
  ]),
  id: PropTypes.string,
  createdAt: PropTypes.string,
  isRead: PropTypes.bool,
  receiverUser: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    plaqueNickname: PropTypes.string,
  }),
  sender: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    plaqueNickname: PropTypes.string,
  }),
  status: PropTypes.oneOf([
    NotificationStatus.ACCEPTED,
    NotificationStatus.REJECTED,
    NotificationStatus.PENDING
  ]),
  type: PropTypes.oneOf([
    ...Object.values(NotificationType)
  ])
}
