import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import {
  errorsConfigWithSamePassword,
  getAsyncWithOldPasswordValidationConfig
} from 'utils/services/validators/passwordValidators';

import PasswordBlock from './index';

const ChangePasswordBlock = () => {
  const { control } = useFormContext();
  const oldPassword = useWatch({ control, name: 'oldPassword', defaultValue: '' });

  return (
    <PasswordBlock
      errorsConfig={errorsConfigWithSamePassword}
      passwordValidationConfig={getAsyncWithOldPasswordValidationConfig(oldPassword)}
    />
  );
};

export default ChangePasswordBlock;
