// Modules
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Legacy components
import Price from '_legacy/components/Price'


const OfferingDetailsEscrowFunds = ({
  title,
  subtitle,
  price,
  description,
  className,
}) =>
  <div className={cx('od__column', 'od--with-padding', className)}>
    {title && <h3 className="od--uppercase-title mb-12">{title}</h3>}
    {subtitle && <h3 className="od--main-text mb-12">{subtitle}</h3>}
    <Price className="od--status-title" price={price} withCurrency />
    {description && <p className="od--small-text mt-16 mb-0">{description}</p>}
  </div>

OfferingDetailsEscrowFunds.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  price: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default OfferingDetailsEscrowFunds;
