// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'

export const ChatParticipant = ({ participant }) => {
  return (
    <div className="chat-participant__wrapper">
      <UserInfo
        user={participant}
        ratingStarsSize={12}
        showChatButton={false}
        showBackgroundReportsLink={false}
        showUserLink={true}
      />
    </div>
  )
}

ChatParticipant.propTypes = {
  participant: PropTypes.object.isRequired,
}
