import React from 'react'
import prop from 'lodash/fp/prop'

import * as r from '_legacy/constants/routes'

import { filtersConfig }  from '_legacy/configs/table/notifications'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'
import {
  useSetNotificationsTableFilters,
  useNotificationsTableFilters,
} from 'store/hooks/globalState/useNotificationsState'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import CustomSelect from '_legacy/components/Table/Filters/Selects/components/Select'

const SettingsButton = React.memo(() => {
  const t = useGetTranslate()
  const isMobile = useWindowDimensions(prop('width')) < 769

  return (
    <div className='notifications-setting-controls'>
      {isMobile && (
        <CustomSelect
          className='notifications-setting-controls__select'
          field={filtersConfig[0].field}
          getFn={useNotificationsTableFilters}
          setFn={useSetNotificationsTableFilters}
          options={filtersConfig[0].options}
          placeholder={filtersConfig[0].placeholder}
        />
      )}
      <Button
        isLink
        primary
        fontSize={0.875}
        className='notifications-setting-controls__button'
        path={`/${r.ACCOUNT}/${r.NOTIFICATIONS}`}
        text={t('table.button.settings')}
        iconComponent={<Icon.Settings />}
      />
    </div>
  )
})

export default SettingsButton
