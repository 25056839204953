import React from 'react'
import styled from 'styled-components'
import propOr from 'lodash/fp/propOr'
import isFunction from 'lodash/fp/isFunction'
import PropTypes from 'prop-types'

import ModalPortal from 'portals/ModalPortal'
import Button from '_legacy/components/Button'

const HandleEditModal = ({
  header,
  text,
  isOpen,
  closeModal,
  discardText,
  viewText,
  cancelText,
  onRejectAllAndEdit,
  onViewRequests,
}) => {
  return (
    <ModalPortal
      showCloseButton
      isOpen={isOpen}
      onClose={closeModal}
      isMobileFullScreen
    >
      <StyledModal>
        <TextContainer>
          <StyledHeader>{header}</StyledHeader>
          <StyledText>{text}</StyledText>
        </TextContainer>
        <ButtonContainer
          className="buttons-wrapper"
          columns={[closeModal, onViewRequests, onRejectAllAndEdit].filter(isFunction).length}
        >
          <Button
            stretch
            primary
            text={cancelText}
            typeButton="button"
            handleOnClick={closeModal}
            paddingVertical={13}
          />
          {isFunction(onViewRequests) && (
            <Button
              stretch
              primary
              text={viewText}
              typeButton="button"
              handleOnClick={onViewRequests}
              paddingVertical={13}
            />
          )}
          {isFunction(onRejectAllAndEdit) && (
            <Button
              stretch
              text={discardText}
              typeButton="submit"
              handleOnClick={onRejectAllAndEdit}
              paddingVertical={13}
            />
          )}
        </ButtonContainer>
      </StyledModal>
    </ModalPortal>
  )
}

const StyledModal = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.modal};
  box-shadow: ${({ theme }) => theme.boxShadows.modal};

  display: flex;
  flex-direction: column;

  padding: 16px 40px 40px 40px;
  background: white;
  width: 695px;

  @media screen and (max-width: 1440px) {
    padding: 16px 24px 24px 24px;
    width: 663px;
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 575px) {
    flex: 1;
    justify-content: center;
  }
`

const StyledHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  font-family: 'Playfair Display', sans-serif;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0 auto 24px;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 28px;
  }

  @media screen and (max-width: 575px) {
    font-size: 23px;
    margin-bottom: 24px;
  }
`

const StyledText = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.colors.grey};
  line-height: 1.625;
  margin: 16px 0;

  @media screen and (max-width: 575px) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(${propOr(3, 'columns')}, 1fr);
  grid-gap: 16px;

  @media screen and (max-width: 575px) {
    grid-template-rows: repeat(${propOr(3, 'columns')}, 1fr);
    grid-template-columns: 1fr;
  }
`

HandleEditModal.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  discardText: PropTypes.string,
  viewText: PropTypes.string,
  cancelText: PropTypes.string,
  onRejectAllAndEdit: PropTypes.func,
  onViewRequests: PropTypes.func,
}

export default HandleEditModal
