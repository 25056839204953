import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get'

import { ErrorMessage } from '_legacy/common';

const LibraryWrapper = ({ name, rules, children }) => {
  const { register, errors } = useFormContext()
  const error = get(errors, name)

  React.useEffect(() => {
    register(name, rules)
  }, [register, name, rules])

  return (
    <React.Fragment>
      <LibraryInner className='library-inner'>
        {children}
      </LibraryInner>
      {error && <ErrorMessage message={error.message} />}
    </React.Fragment>
  )
}

const LibraryInner = styled.div`
  width: 100%;
  height: 100%;
`

LibraryWrapper.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  children: PropTypes.element
}

export default LibraryWrapper
