import React from 'react'
import './index.scss'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ContentTabs from '_legacy/containers/ContentTabs/ContentTabs'
import { getReviewConfig } from './reivew-config'

const Reviews = () => {
  const t = useGetTranslate()
  return (
    <div className="reviews-table wrapper content-wrapper">
      <h1 className="reviews-table__header">{t('reviews.header')}</h1>
      <ContentTabs menuItems={getReviewConfig()} />
    </div>
  )
}

export default Reviews
