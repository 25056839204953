import React from 'react'
import eq from 'lodash/fp/isEqual'
import cx from 'classnames'
import { NotificationStatus } from 'shared'

import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import TableRowCheckbox from '_legacy/components/Table/table-components/TableRow/common/TableRowCheckbox'
import ActionDropdown from '_legacy/components/Table/table-components/TableRow/common/ActionDropdown'

const isPending = eq(NotificationStatus.PENDING)

const MobileCardHeader = ({
  isLoading,
  rowId,
  row,
  actionsConfig,
  bulkActionsConfig,
}) => {
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()

  const markBtnHandler = row.isRead ? () => markAsUnRead(row.id) : () => markAsRead(row.id)

  const markBtnClasses =  cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': row.isRead
  })

  const markBtnText = row.isRead
    ? 'notification-menu.mark-as-unread'
    : 'notification-menu.mark-as-read'

  return (
    <div className="card-header">
      {bulkActionsConfig ? (
        <TableRowCheckbox
          isLoading={isLoading}
          rowId={rowId}
          bulkActionsConfig={bulkActionsConfig}
        />
      ) : (
        <div />
      )}
      {isPending(row.status) && (
        <div className="card-header__controls">
          <button
            type='button'
            className={markBtnClasses}
            onClick={markBtnHandler}
          >
            {t(markBtnText)}
          </button>
          {actionsConfig && (
            <ActionDropdown
              isLoading={isLoading}
              rowId={rowId}
              actionsConfig={actionsConfig}
              row={row}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default MobileCardHeader
