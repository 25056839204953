import React from 'react'
import styled from 'styled-components'

export default React.memo(function Poster ({ sources }) {
  const poster = React.useMemo(() => {
    if (!sources) return null
    const srcSet = sources.map(({ fullUrl, width }) => `${fullUrl} ${width}w`).join(', ')
    return (
      <StyledImage
        className='poster-img'
        srcSet={srcSet}
        alt="poster"
      />
    )
  }, [sources])

  return poster
})

const StyledImage = styled.img`
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
`
