import React from 'react'
import { api } from 'shared';
import map from 'lodash/map';

import { filters } from '_legacy/components/Table/Filters/config'

import CustomCircle from '_legacy/common/CustomCircle'

const {
  FOR_COMMERCIAL_USE, IN_PERSON_EXPERIENCE, LIVE_IN_PERSON_LESSON, READY_MADE_LESSON,
  VIDEO_CHAT, VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, VIRTUAL_EVENT, VIRTUAL_LESSON
} = api.talents.talent.offers.query.TypeFilter

export const offeringsTypes = {
  [IN_PERSON_EXPERIENCE]: {
    label: 'table.filters.offerings-types.in-person-experience',
    subText: 'table.filters.offerings-types.in-person-experience',
  },
  [VIDEO_MESSAGE]: {
    label: 'table.filters.offerings-types.video-message',
    subText: 'table.filters.offerings-types.video-message',
  },
  [VIDEO_CHAT]: {
    label: 'table.filters.offerings-types.live-video-chat',
    subText: 'table.filters.offerings-types.live-video-chat',
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    label: 'table.filters.offerings-types.video-voice-over-lesson',
    subText: 'table.filters.offerings-types.lessons',
  },
  [VIRTUAL_LESSON]: {
    label: 'table.filters.offerings-types.virtual-lesson',
    subText: 'table.filters.offerings-types.lessons',
  },
  [LIVE_IN_PERSON_LESSON]: {
    label: 'table.filters.offerings-types.live-in-person-lesson',
    subText: 'table.filters.offerings-types.lessons',
  },
  [VIRTUAL_EVENT]: {
    label: 'table.filters.offerings-types.virtual-event',
    subText: 'table.filters.offerings-types.virtual-event',
  },
  [READY_MADE_LESSON]: {
    label: 'table.filters.offerings-types.ready-made-lesson',
    subText: 'table.filters.offerings-types.ready-made-lesson',
  },
  [FOR_COMMERCIAL_USE]: {
    label: 'table.filters.offerings-types.video-message-for-commercial-use',
    subText: 'table.filters.offerings-types.video-message-for-commercial-use',
  },
};

export const offeringsTypesOptions = map(offeringsTypes, (key, val) => ({ value: val, label: key.label }))

const ACTIVE = 'ACTIVE'
const INACTIVE = 'INACTIVE'

export const statuses = {
  [ACTIVE]: {
    Icon: () => <CustomCircle className='accepted' />,
    label: 'table.filters.statuses.active',
    color: 'lightGrey'
  },
  [INACTIVE]: {
    Icon: () => <CustomCircle className='pending' />,
    label: 'table.filters.statuses.inactive',
    color: 'lightGrey'
  }
}
const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key].label }))


export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'nameLike',
  },
  /* Commented due BE issues */
  // {
  //   type: filters.select,
  //   placeholder: 'table.filters.offerings',
  //   field: 'fields',
  //   options: offeringsTypesOptions,
  // },
  {
    type: filters['range-datepicker'],
    placeholder: 'table.filters.expiration-date',
    field: 'date',
  },
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'statusEq',
    options: statusOptions,
  },
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.promo-code',
  pluralLabel: 'table.filters.promo-codes'
}


const NAME = 'name'
const CODE = 'code'
const OFFERINGS_COUNT = 'offeringsCount'
const DISCOUNT = 'discount'
const EXPIRATION_AT = 'expiresAt'
const LIMIT_OF_USERS = 'limitOfUsers'
const STATUS = 'status'

/* Commented due BE issues */

const sortFields = {
  [NAME]: 'table.header.name',
  [CODE]: 'table.header.code',
  [OFFERINGS_COUNT]: 'table.header.offerings',
  [DISCOUNT]: 'table.header.discount',
  [EXPIRATION_AT]: 'table.header.expiration-date',
  [LIMIT_OF_USERS]: 'table.header.usage-limit',
  [STATUS]: 'table.header.status',
}
const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 50, label: '50' }, { value: 100, label: '100' }, { value: 150, label: '150' }]
  }
]

const additionalFields = [
  'sortOrder'
]

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
