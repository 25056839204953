import React from 'react'

import NavigationLink from './NavigationLink'

export default React.memo(function NavigationLinksList ({
  routesConfig = []
}) {
  const parsedLinks = React.useMemo(() => {
    return routesConfig.map((item, i) => {
      return (
        <NavigationLink
          key={i}
          {...item}
        />
      )
    })
  }, [routesConfig])
  return parsedLinks
})
