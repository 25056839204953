import React from 'react';
import { useParams } from 'react-router';
import { OrderStatus } from 'shared'
import compose from 'lodash/fp/compose'
import { BulkOrdersAction } from 'shared'
import { notify } from 'utils/notify'

import {
  getVirtualEventOccurrence as getVirtualEventOccurrenceApi,
  checkVirtualEventOccurrenceAvailability as checkIsEditableApi,
  getVirtualEventOccurrenceOrders as getVirtualEventOccurrenceOrdersApi,
  orderRequestAction,
} from './api'

import {
  useSetVirtualEventOrderDetails,
  useSetIsOccurrenceEditable,
  useSetVirtualEventOrderDetailsTableFilters,
  useSetVirtualEventOrderDetailsTable,
} from './store'
import { useUser } from 'store/hooks/globalState/useUser';
import { getTalentId } from 'utils/user'
import { parseOrderParticipant } from '_legacy/components/Table/Filters/utils/participant'
import normalizeParams from 'utils/normalizeParams';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const normalizer = compose(
  normalizeParams,
  parseOrderParticipant,
)

const { BOOKED, CANCELED, COMPLETED } = OrderStatus

export function useFetchVirtualEventOrderDetails() {
  const setVirtualEventOrderDetails = useSetVirtualEventOrderDetails((prev, next) => next)
  const user = useUser()
  const talentId = getTalentId(user)
  const { orderId } = useParams();
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setVirtualEventOrderDetails(null);
    getVirtualEventOccurrenceApi({ talentId, orderId })
      .then(({ occurrence }) => {
        setVirtualEventOrderDetails(occurrence)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [orderId, setVirtualEventOrderDetails])
}

export function fetchIsVirtualEventEditable() {
  const setIsOccurrenceEditable = useSetIsOccurrenceEditable((prev, next) => next)
  const user = useUser()
  const talentId = getTalentId(user)
  const { orderId } = useParams();

  return React.useCallback(() => {
    setIsOccurrenceEditable(null);
    checkIsEditableApi({ talentId, orderId })
      .then(() => {
        setIsOccurrenceEditable(false)
      })
      .catch((error) => {
        setIsOccurrenceEditable(true)
        console.warn(error)
      })
  }, [orderId, setIsOccurrenceEditable])
}

export function useFetchVirtualEventOrderDetailsTable() {
  const setVirtualEventOrderDetailsTableFilters = useSetVirtualEventOrderDetailsTableFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setVirtualEventOrderDetailsTable = useSetVirtualEventOrderDetailsTable(
    (prev, next) => next
  )
  const user = useUser()
  const talentId = getTalentId(user)
  const { orderId } = useParams();

  return React.useCallback((options) => {
    const query = {
      orderStatusIn: [BOOKED, CANCELED, COMPLETED],
      ...normalizer(options)
    }

    setVirtualEventOrderDetailsTable(null)
    getVirtualEventOccurrenceOrdersApi({ talentId, orderId, query })
      .then(({ orders, pageInfo }) => {
        setVirtualEventOrderDetailsTable(orders);
        setVirtualEventOrderDetailsTableFilters(pageInfo);
      })
      .catch((error) => console.warn(error));
  }, [orderId, setVirtualEventOrderDetailsTableFilters, setVirtualEventOrderDetailsTable]);
}

export function useCancelOrder() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchVirtualEventOrderDetails = useFetchVirtualEventOrderDetails()

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message
    }

    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.notification.cancelled'))
        fetchVirtualEventOrderDetails()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t, fetchVirtualEventOrderDetails]
  )
}
