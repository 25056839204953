import React from 'react'

import { useUser } from 'store/hooks/globalState/useUser'

import { useFetchUnreadNotifications } from 'requests/notifications'

import { setUserData } from 'utils/storage'


const withSubscribeUser = Component => props => {
  const user = useUser()
  const fetchUnreadNotifications = useFetchUnreadNotifications({ isMerge: true })

  React.useEffect(() => {
    setUserData(user)

    if (user) {
      fetchUnreadNotifications()
    }
  }, [user])

  return <Component {...props} />
}

export default withSubscribeUser
