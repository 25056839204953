import cond from 'lodash/fp/cond';
import __ from 'lodash/fp/__';
import lt from 'lodash/fp/lt';
import T from 'lodash/fp/T';
import constant from 'lodash/constant';

export const getLimitByResolution = cond([
  [lt(__, 475), constant(10)],
  [lt(__, 1280), constant(24)],
  [lt(__, 1460), constant(30)],
  [T, constant(36)],
]);
