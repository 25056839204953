import React from 'react'
import { noop } from 'lodash'
import styled, { css } from 'styled-components'

import cx from 'classnames'

export default function Checkbox ({
  className = '', icon = 'chekmark', id, checked, clickHandler, disabled,
}) {
  const handleClick = !disabled
    ? React.useCallback(() => {
      if (!clickHandler) return
      clickHandler(id)
    }, [id, clickHandler])
    : noop

  return (
    <StyledWrapper
      className={cx(className, { disabled })}
      checked={checked}
      onClick={handleClick}
    >
      <div className={icon} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 24px;
  border: 2px solid ${({ theme: { colors } }) => colors.blueText};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  &.disabled {
    cursor: not-allowed;
    background-color: ${({ theme: { colors } }) => colors.blueText};
    :hover {
      border-color: ${({ theme: { colors } }) => colors.blueText};
    }
  }
  :hover {
    border-color: ${({ theme: { colors } }) => colors.blue};
  }
  ${({ checked }) => checked && css`
    background: ${({ theme: { colors } }) => colors.blue};
    border-color: ${({ theme: { colors } }) => colors.blue};
  `};
  .chekmark {
    width: 6px;
    height: 12px;
    border-right: solid 2px ${({ checked }) => checked ? 'white' : 'transparent'};
    border-bottom: solid 2px ${({ checked }) => checked ? 'white' : 'transparent'};
    transform: rotate(40deg) translate(-1px, -1px);
  }
  .minus {
    width: 8px;
    height: 2px;
    background: #fff;
  }
`
