import React from 'react';
import PropTypes from 'prop-types';
import ModalWithControls from 'portals/ModalWithControls';
import { useSetModal } from 'store/hooks/globalState/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const DeleteMessageModal = ({ message, isOpen, onRemove }) => {
  const closeModal = useSetModal(() => null)
  const t = useGetTranslate()

  const removeMessage = () => {
    message.remove()
      .then(onRemove)
      .catch(e => console.warn(e))
      .finally(closeModal)
  }

  const buttonsConfig = [
    { text: t('shared.words.cancel'), handleOnClick: closeModal, primary: true },
    { text: t('shared.words.delete'), handleOnClick: removeMessage },
  ]

  return (
    <ModalWithControls
      header={t('chats.chat.delete-modal.header')}
      message={t('chats.chat.delete-modal.body')}
      buttonsConfig={buttonsConfig}
      isOpen={isOpen}
    />
  );
};

DeleteMessageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DeleteMessageModal;
