import React from 'react';
import PropTypes from 'prop-types';

import BasicActions from '_legacy/components/TalentPublicView/DesktopSlider/BasicActions';
import BookNowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/BookNowButton';
import ArrowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/ArrowButton';

//import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { VIDEO_MESSAGE } from '_legacy/constants/routes';

const Actions = ({ slidesCount, onNext, onPrev, plaqueNickname, offerSequentialId, isPromo }) => {
  //const t = useGetTranslate()
  // const onRequestClick = () => {
  //   alert('This action isn\'t implemented');
  // };

  return (
    <BasicActions.WithoutArrows
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={48}
      padding="0 0 0 11px"
      className="tbv-slider-actions-video-messages"
      // onRequestClick={onRequestClick}
    >
      <div className="tbv-slider-actions-video-messages-content">
        {slidesCount > 1 && (
          <ArrowButton
            className="tbv-slider-actions-left-arrow"
            isMirrored={true}
            onClick={onPrev}
          />
        )}
        <div>
          <BookNowButton disabled={isPromo} margin="0" isLink path={`/${VIDEO_MESSAGE}/${plaqueNickname}/${offerSequentialId}`} />
        </div>
        {slidesCount > 1 && (
          <ArrowButton
            className="tbv-slider-actions-right-arrow"
            onClick={onNext}
          />
        )}
      </div>
      {/* here and above commented due to ui changes (will be uncommented and further developed in phase 2) */}
      {/* <span className="tbv-slider-actions-video-messages-response-time-label">
        {t('talent-public-view.sliders.video-messages.hint.label')}
      </span> */}
    </BasicActions.WithoutArrows>
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  slidesCount: PropTypes.number.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  offerSequentialId: PropTypes.number.isRequired,
};

export default Actions
