// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import path from 'lodash/fp/path';

import { Title } from '_legacy/common';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';


const HappensBlock = ({ config, fullName, className = '' }) => {
  const t = useGetTranslate();

  return (
    <StyledWrapper className={className}>
      <Title className='title'>{t('request-video-mes-commercial.happens.title')}</Title>
      <div className='list'>
        {config.map(c => (
          <div key={c.numb} className="item">
            <div className='item__top'>
              <span className='item__numb'>{c.numb}</span>
              <h4 className='item__title'>{t(c.title)}</h4>
            </div>
            <p className='item__desc'>
              <Trans
                tKey={c.desc}
                phs={[{ ph: '{{fullName}}', value: fullName }]}
              />
            </p>
          </div>
        ))}
      </div>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  @media screen and (max-width: 991px) {
    padding-top: 0;
  }
  .title {
    @media screen and (max-width: 991px) {
      font-size: 48px;
    }
    @media screen and (max-width: 575px) {
      font-size: 38px;
    }
  }
  .list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 34px;
    margin-top: 64px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr;
    }
  .item {
    &__top {
      position: relative;
      margin-bottom: 20px;
    }
    &__numb {
      font-family: 'Playfair Display', sans-serif;
      font-weight: 700;
      font-size: 220px;
      line-height: 1;
      text-align: center;
      text-transform: uppercase;
      font-feature-settings: "pnum" on,"lnum" on,"liga" off,"case" on;
      color: ${path(['theme', 'colors', 'grey'])};
      opacity: 0.15;
      display: block;
      @media screen and (max-width: 991px) {
        font-size: 180px;
      }
    }
    &__title {
      font-family: ${path(['theme', 'fontFamily', 'primary'])};
      font-weight: ${path(['theme', 'fontWeight', 'black'])};
      font-size: 42px;
      line-height: 54px;
      text-align: center;
      font-feature-settings: 'case' on, 'liga' off;
      color: ${path(['theme', 'colors', 'white'])};
      position: absolute;
      bottom: 24px;
      width: 100%;
      @media screen and (max-width: 991px) {
        font-size: 38px;
      }
    }
    &__desc {
      font-family: ${path(['theme', 'fontFamily', 'default'])};
      font-weight: ${path(['theme', 'fontWeight', 'normal'])};
      font-size: 22px;
      line-height: 1.5;
      text-align: center;
      color: ${path(['theme', 'colors', 'white'])};
      @media screen and (max-width: 991px) {
        font-size: 18px;
      }
    }
  }
  }`;

HappensBlock.propTypes = {
  className: PropTypes.string,
  fullName: PropTypes.string,
  config: PropTypes.object,
};

export default HappensBlock;
