import get from 'lodash/get'

import { api } from 'shared'
const offeringTypes = api.talents.talent.offers.query.TypeFilter
const offeringStatus = api.talents.talent.offers.query.StatusFilter

export const editActionMap = {
  [offeringTypes.IN_PERSON_EXPERIENCE]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.VIDEO_MESSAGE]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.VIDEO_CHAT]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.VIDEO_VOICE_OVER_LESSON]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.LIVE_IN_PERSON_LESSON]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.VIRTUAL_LESSON]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.READY_MADE_LESSON]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: false,
    [offeringStatus.UNPUBLISHED]: false,
  },
  // masterclass add when will updated
  [offeringTypes.VIRTUAL_EVENT]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
  [offeringTypes.FOR_COMMERCIAL_USE]: {
    [offeringStatus.DRAFT]: true,
    [offeringStatus.PUBLISHED]: true,
  },
}

export const shouldShowEditAction = row => {
  const type = get(row, 'type')
  const status = get(row, 'status')
  return get(editActionMap, [type, status], true)
}
