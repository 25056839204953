import map from 'lodash/map'

import { FileType } from 'shared'

import { filters } from '_legacy/components/Table/Filters/config'

const mediaTypes = {
  [FileType.PHOTO]: 'table.filters.media-types.photo',
  [FileType.VIDEO]: 'table.filters.media-types.video',
  [FileType.FILE]: 'table.filters.media-types.file',
}

const mediaTypesOptions = map(mediaTypes, (label, value) => ({ value, label }))

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'search'
  },
  {
    type: filters.select,
    placeholder: 'table.filters.media-type',
    field: 'typeIn',
    options: mediaTypesOptions
  },
]
