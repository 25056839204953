import React from 'react'
import { TypesOfEntourage } from 'shared'

// todo: replace path with new structure
import { ReactComponent as EntourageFull } from 'components/EntourageLogo/icons/EntourageFullIcon.svg'
import { ReactComponent as EntourageLite } from 'components/EntourageLogo/icons/EntourageLiteIcon.svg'

import { Trans } from 'store/hooks/globalState/useTranslates'
import { routesList } from 'router/routesMap'

const transConfig = {
  [TypesOfEntourage.EITHER]: {
    Icon: EntourageLite,
    text: 'shared.words.entourage-either',
  },
  [TypesOfEntourage.FULL]: {
    Icon: EntourageFull,
    text: 'shared.words.entourage-full',
  },
}

const EntourageRequired = ({ type }) => {
  if (!type) return null

  const { text, Icon } = transConfig[type]

  return (
    <div className="entourage-required">
      <Icon className="entourage-required__icon" />
      <p className="entourage-required__text">
        <Trans tKey={text} phs={[{ ph: '{{entourageLink}}', value: routesList.entouragePublic() }]} />
      </p>
    </div>
  )
}

export default EntourageRequired
