import { TalentOffersType } from 'shared'
import { pipe } from 'lodash/fp'
import { buildPhs, phsConfig } from '../../config'

export const getModifiers = ({ occurrence }) => {
  const { type } = occurrence

  const isVideoMessage = type === TalentOffersType.VIDEO_MESSAGE
  const isReadyMadeVideo = type === TalentOffersType.READY_MADE_LESSON
  const isVideoChat = type === TalentOffersType.VIDEO_CHAT
  const isVirtualLesson = type === TalentOffersType.VIRTUAL_LESSON
  const isLiveVirtualEvent = type === TalentOffersType.VIRTUAL_EVENT
  const isVideoVoiceOverLesson = type === TalentOffersType.VIDEO_VOICE_OVER_LESSON
  const isExperience = type === TalentOffersType.IN_PERSON_EXPERIENCE
  const isLiveInPersonLesson = type === TalentOffersType.LIVE_IN_PERSON_LESSON
  const isCommercialVideoMessage = type === TalentOffersType.FOR_COMMERCIAL_USE

  return {
    isVideoMessage,
    isReadyMadeVideo,
    isVideoChat,
    isVirtualLesson,
    isLiveVirtualEvent,
    isVideoVoiceOverLesson,
    isExperience,
    isLiveInPersonLesson,
    isCommercialVideoMessage
  }
}

export const matchKeys = ({
  isVideoMessage,
  isReadyMadeVideo,
  isVideoChat,
  isVirtualLesson,
  isLiveVirtualEvent,
  isVideoVoiceOverLesson,
  isExperience,
  isLiveInPersonLesson,
  isCommercialVideoMessage
}) => {
  return [
    'notification.offer-completed',

    isVideoChat && '--video-chat',
    isVideoMessage && '--video-message',
    isReadyMadeVideo && '--ready-made-video',
    isVirtualLesson && '--virtual-lesson',
    isLiveVirtualEvent && '--live-virtual-event',
    isVideoVoiceOverLesson && '--video-voice-over-lesson',
    isExperience && '--experience',
    isLiveInPersonLesson && '--live-in-person-lesson',
    isCommercialVideoMessage && '--video-message',
  ]
    .filter(Boolean)
    .join('')
}

export const buildOrderCompletedTransKey = pipe(getModifiers, matchKeys)

export const buildOrderCompletedPhs = buildPhs([phsConfig.senderTalentFullName, phsConfig.senderTalentLink])

export const getBtnTKey = props => {
  const { isVideoMessage, isVideoVoiceOverLesson } = getModifiers(props)
  return (isVideoMessage || isVideoVoiceOverLesson) ? 'shared.words.review-message' : 'shared.words.review'
}
