// TODO: refactor this component to work only with rows that are allowed to select

import React from 'react'
import get from 'lodash/get'

import { OrderStatus } from 'shared'

import Checkbox from '../../common/Checkbox'

const isNotRejected = row => row?.status !== OrderStatus.REJECTED


export default function SelectAll ({ bulkActionsConfig, getTableFn, withRejected }) {
  const rows = (getTableFn() || [])
  const rowsFiltered = withRejected ? rows : rows.filter(isNotRejected)

  const { getFilters, setFilters } = bulkActionsConfig
  const selectedCount = getFilters(current => get(current, 'selected', []).length)
  const limit = getFilters(current => get(current, 'limit'))

  const selectAll = setFilters(prev => {
    const selected = get(prev, 'selected', [])
    if (selected.length) return { ...prev, selected: [] }
    return { ...prev, selected: rowsFiltered.map(({ id }) => id) }
  }, [limit, rowsFiltered])

  return (
    <Checkbox
      checked={selectedCount !== 0}
      icon={selectedCount === rowsFiltered.length ? 'chekmark' : 'minus'}
      clickHandler={selectAll}
      disabled={!rowsFiltered.length}
    />
  )
}
