import React from 'react'
import { string } from 'prop-types'

const CookiesDetailsItem = ({ title, text, expire }) => (
  <div className='cookies-details-item'>
    <p>{title}</p>
    <p>{text}</p>
    <p>{expire}</p>
  </div>
)

CookiesDetailsItem.propTypes = {
  title: string.isRequired,
  text: string.isRequired,
  expire: string.isRequired,
}

export default CookiesDetailsItem
