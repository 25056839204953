import React from 'react';
import ISOCountries from 'i18n-iso-countries'

import {
  getSDKToken,
  checksApplicant,
  getApplicantLocation,
} from './api'

import {
  useSetOnfidoToken,
  useSetOnfidoCheckResult,
} from './store'
import { IDVerification } from 'utils/services/idVerification'

export function useGetSDKToken() {
  const setToken = useSetOnfidoToken((_, next) => next)

  return React.useCallback(({ applicantId }) => {
    getSDKToken({ applicantId })
      .then(({ token }) => {
        setToken(token)
      })
      .catch(err => console.warn(err))
  }, [setToken]);
}

export function useCheckOnfidoApplicant() {
  const setCheckResult = useSetOnfidoCheckResult((prev, next) => next)
  const NewCheck = new IDVerification()

  return React.useCallback(applicant => {
    checksApplicant({ applicantId: applicant })
      .then(({ checkId }) => {
        if (checkId) {
          NewCheck.connect()
            .backgroundCheck({ checkId, setCheckResult })
        } else {
          NewCheck.disconnect()
        }
      })
      .catch(err => console.warn(err))
  }, []);
}

export function useGetApplicantLocation() {
  return React.useCallback(() => {
    return getApplicantLocation()
      .then((data) => ({
        countryOfResidence: ISOCountries.alpha2ToAlpha3(data.country_code),
        ipAddress: data.IPv4
      }))
  }, [])
}
