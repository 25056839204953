import { types, UserRegistrationType } from 'shared';

const {
  TALENT_USER,
  AGENT,
  MANAGER,
} = types.UserType;

export const getViewerTypes = (viewerType, isWithTalent, registrationType) => ({
  isAficionado: UserRegistrationType.AFICIONADO === registrationType,
  isUserWithTalent: viewerType === TALENT_USER && isWithTalent ,
  isUserWithoutTalent: viewerType === TALENT_USER && !isWithTalent ,
  isAgentOrManagerWithTalent: (viewerType === AGENT || viewerType === MANAGER) && isWithTalent,
  isAgentOrManagerWithoutTalent: (viewerType === AGENT || viewerType === MANAGER) && !isWithTalent,
});
