import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import get from 'lodash/get';
import map from 'lodash/map';
import differenceBy from 'lodash/differenceBy';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { getOptionsWithTranslate } from './config'

import { Row } from '_legacy/common';
import AddMore from '_legacy/common/Buttons/AddMore';
import SocialMediaListItem from './SocialMediaListItem';


const SocialMediaLinks = ({ name = 'socialMediaLinks' }) => {
  const t = useGetTranslate()
  const { setValue, watch } = useFormContext();
  const { append, fields, remove } = useFieldArray({ name });
  const options = getOptionsWithTranslate();
  const values = watch();
  const value = get(values, name);

  const availableOptions = differenceBy(
    options,
    map(value, 'social'),
    'value'
  );

  const shouldShowAppendButton = fields.length < options.length;

  const removeHandler = (index) => {
    if (index === 0 && fields.length === 1) {
      setValue(`${name}.0`, { link: '', social: null }, { shouldDirty: true });
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    return !!value?.[0].social || !!value?.[0].link;
  };

  return (
    <Row
      nameBlock={t('edit-profile.form.social-media-links.label')}
      component={
        <SocialMediaLinksListWrapper>
          <SocialMediaLinksList>
            {fields.map((field, index) => (
              <SocialMediaListItem
                key={field.id}
                baseName={`${name}.${index}`}
                defaultValue={field}
                options={availableOptions}
                remove={() => removeHandler(index)}
                showRemoveButton={showRemoveButton(index)}
              />
            ))}
          </SocialMediaLinksList>
          {shouldShowAppendButton && (
            <AddMore type={'button'} onClick={append} />
          )}
        </SocialMediaLinksListWrapper>
      }
    />
  );
};

SocialMediaLinks.propTypes = {
  name: PropTypes.string,
};

const SocialMediaLinksListWrapper = styled.div`
  display: grid;
  gap: 34px;
`;

const SocialMediaLinksList = styled.ul`
  display: grid;
  gap: 20px;

  @media all and (max-width: 1024px) {
    li {
      position: relative;

      .remove-button {
        position: absolute;
        right: 12px;
      }

      input {
        padding-right: 42px;
      }
    }
  }
`;

export default SocialMediaLinks;
