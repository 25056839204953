import  HowItWorkIcons from 'assets/icons/HowItWorkIcons';

const { Member, Calendar, Enjoy, Draft, Share, Schedule, Organize, Choose, Prepare, Learn, Book, Execute, SandClock } = HowItWorkIcons;

export default [
  {
    id: 'experiences',
    title: 'how-it-works.header.experiences',
    content: [
      {
        header: 'how-it-works.content1.header.experiences',
        isButton: {
          text: 'how-it-works.content1.experiences.textFrom',
          buttonText: 'how-it-works.content1.experiences.button',
          suffixText: 'how-it-works.content1.experiences.textEnd',
        },
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.experiences',
        text: 'how-it-works.content2.experiences',
        icon: SandClock,
      },
      {
        header: 'how-it-works.content3.header.experiences',
        text: 'how-it-works.content3.experiences',
        icon: Calendar,
      },
      {
        header: 'how-it-works.content4.header.experiences',
        text: 'how-it-works.content4.experiences',
        icon: Enjoy,
      },
    ],
  },
  {
    id: 'voice-messaging',
    title: 'how-it-works.header.voice-messaging',
    content: [
      {
        header: 'how-it-works.content1.header.voice-messaging',
        text: 'how-it-works.content1.voice-messaging',
        icon: Member,
      },
      {
        bgNumber: '02',
        header: 'how-it-works.content2.header.voice-messaging',
        text: 'how-it-works.content2.voice-messaging',
        icon: Draft,
      },
      {
        header: 'how-it-works.content3.header.voice-messaging',
        text: 'how-it-works.content3.voice-messaging',
        icon: SandClock,
      },
      {
        header: 'how-it-works.content4.header.voice-messaging',
        text: 'how-it-works.content4.voice-messaging',
        icon: Share,
      },
    ],
  },
  {
    id: 'video-chats',
    title: 'how-it-works.header.video-chats',
    content: [
      {
        header: 'how-it-works.content1.header.video-chats',
        text: 'how-it-works.content1.video-chats',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.video-chats',
        text: 'how-it-works.content2.video-chats',
        icon: Schedule,
      },
      {
        header: 'how-it-works.content3.header.video-chats',
        text: 'how-it-works.content3.video-chats',
        icon: Organize,
      },
      {
        header: 'how-it-works.content4.header.video-chats',
        text: 'how-it-works.content4.video-chats',
        icon: Enjoy,
      },
    ],
  },
  {
    id: 'lessons',
    title: 'how-it-works.header.lessons',
    content: [
      {
        header: 'how-it-works.content1.header.lessons',
        text: 'how-it-works.content1.lessons',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.lessons',
        text: 'how-it-works.content2.lessons',
        icon: Choose,
      },
      {
        header: 'how-it-works.content3.header.lessons',
        text: 'how-it-works.content3.lessons',
        icon: Prepare,
      },
      {
        header: 'how-it-works.content4.header.lessons',
        text: 'how-it-works.content4.lessons',
        icon: Learn,
      },
    ],
  },
  {
    id: 'ready-made-events',
    title: 'how-it-works.header.ready-made-events',
    content: [
      {
        header: 'how-it-works.content1.header.ready-made-events',
        text: 'how-it-works.content1.ready-made-events',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.ready-made-events',
        text: 'how-it-works.content2.ready-made-events',
        icon: Choose,
      },
      {
        header: 'how-it-works.content3.header.ready-made-events',
        text: 'how-it-works.content3.ready-made-events',
        icon: Enjoy,
      },
    ],
  },
  {
    id: 'masterclasses',
    title: 'how-it-works.header.masterclasses',
    content: [
      {
        header: 'how-it-works.content1.header.masterclasses',
        text: 'how-it-works.content1.masterclasses',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.masterclasses',
        text: 'how-it-works.content2.masterclasses',
        icon: Book,
      },
      {
        header: 'how-it-works.content3.header.masterclasses',
        text: 'how-it-works.content3.masterclasses',
        icon: Enjoy,
      },
    ],
  },
  {
    id: 'virtual-events',
    title: 'how-it-works.header.virtual-events',
    content: [
      {
        header: 'how-it-works.content1.header.virtual-events',
        text: 'how-it-works.content1.virtual-events',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.virtual-events',
        text: 'how-it-works.content2.virtual-events',
        icon: Book,
      },
      {
        header: 'how-it-works.content3.header.virtual-events',
        text: 'how-it-works.content3.virtual-events',
        icon: Prepare,
      },
      {
        header: 'how-it-works.content4.header.virtual-events',
        text: 'how-it-works.content4.virtual-events',
        icon: Enjoy,
      },
    ],
  },
  {
    id: 'commercial-video',
    title: 'how-it-works.header.commercial-video',
    content: [
      {
        header: 'how-it-works.content1.header.commercial-video',
        text: 'how-it-works.content1.commercial-video',
        icon: Member,
      },
      {
        header: 'how-it-works.content2.header.commercial-video',
        text: 'how-it-works.content2.commercial-video',
        icon: Draft,
      },
      {
        header: 'how-it-works.content3.header.commercial-video',
        text: 'how-it-works.content3.commercial-video',
        icon: SandClock,
      },
      {
        header: 'how-it-works.content4.header.commercial-video',
        text: 'how-it-works.content4.commercial-video',
        icon: Execute,
      },
    ],
  },
];
