import React from 'react'

import { history } from 'utils/history'


const withScrollToTop = Component => props => {
  React.useEffect(() => {
    const unlisten = history.listen(
      () => window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    )

    return () => unlisten()
  }, [])

  return <Component {...props} />
}

export default withScrollToTop
