// TODO: refactor component (Formatting, module structure)

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Input } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { emailPattern } from 'utils/services/validators/validators';

export const EmailField = ({ name, label, subLabel, placeholder, isRequired, className = '' }) => {
  const t = useGetTranslate();
  return (
    <Row
      nameBlock={t(label)}
      subNameBlock={t(subLabel)}
      isRequired={isRequired}
      className={className}
      component={
        <Input
          name={t(name)}
          type='email'
          placeholder={t(placeholder)}
          requiredMessage='request-video-mes-commercial.email.error'
          isRequired={isRequired}
          validation={{
            pattern: {
              value: emailPattern,
              message: t('form.email.errors.invalid')
            }
          }}
        />
      }
    />
  );
};

EmailField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default EmailField;
