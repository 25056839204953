import React from 'react'

import { useUser } from 'store/hooks/globalState/useUser'
import { getCurrentUserName } from 'utils/user'
import { ReactComponent as Logo } from 'assets/logo.svg'

export default function PreJoinHeader() {
  const userFullName = useUser(getCurrentUserName)

  return (
    <header className="video-chat-pre-join-header">
      <div className="video-chat-pre-join-header__inner wrapper content-wrapper">
        <a href="/">
          <Logo />
        </a>
        <p className="video-chat-pre-join-header__user">{userFullName}</p>
      </div>
    </header>
  )
}
