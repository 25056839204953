import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import gerOr from 'lodash/fp/getOr';

export const Row = React.memo(function Row({ nameBlock, subNameBlock, component, tip, isRequired, className = '', alignItems }) {

  return (
    <StyledRow className={`row ${className}`} {...{ alignItems }}>
      <StyledLeft className='row-left' {...{ alignItems }}>
        <StyledName className='row-name'>
          {nameBlock}
          {isRequired && ' *'}
        </StyledName>
        {subNameBlock && <StyledSubName>{subNameBlock}</StyledSubName>}
        {tip}
      </StyledLeft>
      <StyledRight className='row-right'>
        <StyledComponent className='row-component'>{component}</StyledComponent>
      </StyledRight>
    </StyledRow>
  );
});

const StyledRow = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  align-items: ${gerOr('flex-start', 'alignItems')};
  outline: none;
  @media only screen and (max-width: 991px) {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-column-gap: 30px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: minmax(288px, 1fr);
    grid-row-gap: 22px;
    margin-bottom: 32px;
  }
  @media only screen and (max-width: 425px) {
    grid-row-gap: 12px;
  }
  @media screen and (max-width: 575px) {
    &.submit-button {
      margin: 0;
      grid-row-gap: 0;
      .button {
        width: 100%;
      }
    }
  }
`;

const StyledLeft = styled.div`
  flex: 0 0 30%;
  max-width: 30%;
  margin-right: 72px;
  position: relative;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  padding-top: ${({ alignItems }) => !alignItems && '10px'};
  @media only screen and (max-width: 991px) {
    flex: 0 0 auto;
    max-width: initial;
    margin-right: 0;
    align-items: flex-start;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 0
  }
`;

const StyledRight = styled.div`
  max-width: 60%;
  flex: 0 0 60%;
  @media only screen and (max-width: 991px) {
    flex: 0  0 auto;
    max-width: initial;
  }
`;
const StyledComponent = styled.div`
  max-width: 560px;
  flex: 0 0 560px;
  position: relative;
  @media only screen and (max-width: 991px) {
    flex: 0  0 auto;
    max-width: initial;
  }
  @media only screen and (max-width: 768px) {
    .cascader-select {
      &:not(:first-of-type) {
          padding-right: 50px;
      }
    }
  }
  
`;

const StyledName = styled.div`
  font-family: 'Playfair Display', sans-serif;
  font-feature-settings: 'lnum';
  font-weight: 900;
  font-size: 20px;
  line-height: 1.4;
  color: #272835;
  text-align: right;
  width: 100%;
  max-width: 340px;
  position: relative;
  @media only screen and (max-width: 768px) {
    max-width: 90%;
    text-align: left;
    display: flex;
  }
  @media only screen and (max-width: 425px) {
    font-size: 20px;
  }
`;

const StyledSubName = styled.div`
  z-index: 3;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.6;
  text-align: right;
  color: #272835;
  max-width: 320px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    position: unset;
    max-width: calc(100% - 32px);
    text-align: left;
  }
`;

Row.propTypes = {
  nameBlock: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  subNameBlock: PropTypes.oneOfType([
    PropTypes.string, PropTypes.func
  ]),
  component: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
  tip: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.func
    ]),
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  alignItems: PropTypes.string,
};
