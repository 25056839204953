// TODO: Refactor wrappers and forms according to DRY after project ends
import React from 'react'
import { string, number, object, shape, arrayOf, oneOfType } from 'prop-types'
import { toNumber, isEmpty } from 'lodash'
import { TypesOfDays } from 'shared'

import { getFullName } from 'utils/user'
import { getFullMonthWithTime } from 'utils/date.js'
import { getLocationFormattedAddressWithName, getLocationTimeZone } from 'utils/location.js'
import { getCharityLabel } from 'utils/charities.js'
import { fromDToS, fromSToM } from 'utils/convertTime.js'
import { prepareDate, getTimezoneAbbr } from 'utils/services/timezone/'

import { getLessonsPreviewNoteText } from '../../utils.js'

import { useModal } from 'store/hooks/useModal.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'

import { useBookLiveInPersonLessons } from './state/requests.js'

import Price from '_legacy/components/Price/'
import BookNowButton from '_legacy/components/Button/BookNowButton.js'

import OrderedRulesList from 'components/OrderedRulesList/'
import BookingCalendar from 'components/Calendars/BookingCalendar'
import EntourageRequired from '_legacy/components/PublicView/EntourageRequired'
import CalendarButton from '../../../Buttons/CalendarButton'

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  BOTH,
} = TypesOfDays


const LiveInPersonLessonBook = ({
  location,
  lengthOfTime,
  charities,
  price,
  percentToCharity,
  specialInstructions,
  rules,
  daysTimesType,
  talent,
  header,
  id,
  offeringType,
  entourageType
}) => {
  const t = useGetTranslate()
  const [isTalentCalendarModalOpened, , closeTalentCalendarModal, toggleTalentCalendarModal] = useModal(false)
  const [orderSlot, setOrderSlot] = React.useState(null)
  const bookLiveInPersonLessons = useBookLiveInPersonLessons()
  const lengthOfTimeInMinutes = Math.round(fromSToM(lengthOfTime))

  const locationTimeZone = getLocationTimeZone(location)
  const locationTimeZoneAbbr = getTimezoneAbbr(locationTimeZone)

  const startTime = orderSlot ? prepareDate(orderSlot.start, locationTimeZone) : null
  const endTime = orderSlot ? prepareDate(orderSlot.end, locationTimeZone) : null

  const onSubmit = () => {
    bookLiveInPersonLessons({
      offerLiveInPersonLessonId: id,
      offeringType,
      startTime: startTime,
      endTime: endTime,
    })
  }

  const isAnyAvailable = daysTimesType === ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR
  const isBoth = daysTimesType === BOTH
  const isDateSelected = !!orderSlot

  const isShowDateTime = isAnyAvailable || isBoth
  const isBookButtonDisabled = isAnyAvailable && !isDateSelected

  const formattedDateTime = startTime ? `${getFullMonthWithTime(startTime)} ${locationTimeZoneAbbr}` : null

  return (
    <div>
      <div className="lesson-preview">
        <OrderedRulesList rules={rules} />

        {specialInstructions && (
          <div className="special-instructions">
            <h6>{t('lessons.preview.special-instructions')}</h6>
            <p>{specialInstructions}</p>
          </div>
        )}

        <div className="lesson-preview__inner">
          <div className="location">
            <span className="label">{t('lessons.preview.location')} &nbsp;</span>
            <span className="value">{getLocationFormattedAddressWithName(location)}</span>
          </div>

          <div className="time-length">
            <span className="label">{t('lessons.preview.length-of-time')} &nbsp;</span>
            <span className="value">
              {lengthOfTimeInMinutes} {t('lessons.preview.minutes')}
            </span>
          </div>

          {toNumber(percentToCharity) > 0 && (
            <div className="charity-wrap">
              <span className="label">{t('lessons.preview.charity-proceed')} &nbsp;</span>
              <span className="value">{percentToCharity}%</span>
            </div>
          )}

          {!isEmpty(charities) && (
            <div className="charity-wrap">
              <span className="label">{t('lessons.preview.charity-beneficiary')} &nbsp;</span>
              {charities.map((charity) => (
                <p className="value" key={charity.id}>{getCharityLabel(charity)};&nbsp;</p>
              ))}
              <p className='charities-tip'>{t('shared.charities.tip')}</p>
            </div>
          )}
        </div>

        <Price price={Number(price)} withCurrency />

        <div className="lesson-preview__note">
          <p dangerouslySetInnerHTML={{ __html: t(getLessonsPreviewNoteText(daysTimesType)) }} />
        </div>

        {isShowDateTime && <CalendarButton onClick={toggleTalentCalendarModal} time={formattedDateTime} theme='light' />}

        <BookNowButton
          typeButton="submit"
          classBtn="lessons__button"
          paddingVertical="13"
          handleOnClick={onSubmit}
          disabled={isBookButtonDisabled}
        />

        <EntourageRequired type={entourageType} />
      </div>

      <BookingCalendar
        isOpen={isTalentCalendarModalOpened}
        onClose={closeTalentCalendarModal}
        talentId={talent.id}
        eventDuration={lengthOfTime}
        eventTitle={`${t(header)} (${getFullName(talent)})`}
        timeZone={locationTimeZone}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
        minBookingTimeBeforeExperience={fromDToS(1)}
      />
    </div>
  )
}

LiveInPersonLessonBook.propTypes = {
  location: string,
  lengthOfTime: string,
  charities: arrayOf(
    shape({
      label: string,
    })
  ),
  price: string,
  percentToCharity: oneOfType([string, number]),
  specialInstructions: string,
  rules: string,
  talent: object,
  header: string,
  id: oneOfType([string, number]),
  offeringType: string,
}

export default LiveInPersonLessonBook
