import React from 'react'
import { merge, __ } from 'lodash/fp'

import { ReactComponent as StripeIcon } from 'assets/stripe-icon.svg'

import { useSetUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { getExpressDashboardLoginLink, disconnectConnectedAccount, refreshAccountLink } from '_legacy/components/PaymentSettings/state/api'

const PaymentAccount = () => {
  const t = useGetTranslate()
  const [dashboardLink, setDashboardLink] = React.useState(null)
  const [editLink, setEditLink] = React.useState(null)
  const setStripeAccountReadyNull = useSetUser(merge(__, { talent: { isStripeAccountReady: null } }))

  React.useEffect(() => {
    getExpressDashboardLoginLink()
      .then(({ url }) => {
        setDashboardLink(url)
      })
      .catch(err => {
        console.warn(err)
      })

    refreshAccountLink()
      .then(({ url }) => {
        setEditLink(url)
      })
      .catch(err => console.warn(err))
  }, [])

  const handleDisconnect = () => {
    disconnectConnectedAccount()
      .then(() => {
        setStripeAccountReadyNull()
      })
  }

  return (
    <div className='payment-settings--connected'>
      <StripeIcon />
      <div className='payment-settings--connected-actions'>
        <p>{t('account.menu.payments.settings.info')}</p>
        <div className='payment-settings--links-wrapper'>
          <a className={dashboardLink ? '' : 'disabled'} href={dashboardLink} target="_blank">{t('account.menu.payments.button.view-dashboard')}</a>
          <a disabled href={editLink} target="_blank">{t('account.menu.payments.button.edit')}</a>
          <button type='button' onClick={handleDisconnect}>
            {t('account.menu.payments.button.disconnect')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PaymentAccount;
