import React from 'react';
import { useElements, CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types'
import { get, isEmpty, isNumber } from 'lodash'
import { compose, find, prop } from 'lodash/fp'
import { OrderPaymentStatus } from 'shared'
import { useForm, FormProvider } from 'react-hook-form';

import Button from '_legacy/components/Button';
import StripeFieldset from '_legacy/components/StripeFieldset';
import PaymentsSelect from '_legacy/components/PaymentsSelect';
import PromoCode from './PromoCode';
import { notify } from 'utils/notify';

import { usePurchase } from 'requests/purchase';
import { useFetchPaymentCard } from 'requests/payments';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { usePaymentCards } from 'store/hooks/globalState/usePaymentCards';
import { useGetPrice } from 'store/hooks/globalState/useGetPrice'
import { useModal } from 'store/hooks/useModal'

import {
  submitAddressAdapter,
} from './utils/addressAdapter'

import MultiLocationSelect from '_legacy/components/RegisterForms/shared/components/MultiLocationSelect';
import PurchaseConfirm from '_legacy/components/Modal/PurchaseConfirm'

import './index.scss';
import CheckoutForm from './CheckoutForm'

const PaymentForm = React.memo(({ data, setDiscountPrice }) => {
  const t = useGetTranslate()
  const elements = useElements()

  const getPrice = useGetPrice()
  const paymentCards = usePaymentCards()
  const fetchPaymentCard = useFetchPaymentCard()
  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [nameOnCard, setNameOnCard] = React.useState('');
  const [disabledPay, setDisabled] = React.useState(false);
  const [discountPromoCode, setDiscountPromoCode] = React.useState(null);
  const [saveCard, setSaveCard] = React.useState(false);
  const [isSuccessModalOpen, openSuccessModal, closeSuccessModal] = useModal(false)

  const withShipping = get(data, 'order.talentOffer.options.special.physicalItemsIncludedInPrice')

  const purchase = usePurchase({ setLoading, setDisabled, saveCard, onSuccess: openSuccessModal })

  const getPaymentMethodId = compose(
    prop('paymentMethodId'),
    find(({ card }) => card.isDefault)
  )

  const promoCodeId = get(discountPromoCode, 'promoCode.id') || undefined
  const discountPrice = get(discountPromoCode, 'calculatedData.totalPrice')
  const orderStatus = get(data, 'order.paymentStatus')
  const totalPrice = get(data, 'order.totalPrice')
  const userEmail = get(data, 'userData.email')
  const offerId = get(data, 'order.offerOccurrence.offerId')

  const actualPrice = isNumber(discountPrice) ? discountPrice : totalPrice

  const handleSubmit = (data) => {
    event.preventDefault();

    purchase({
      card: elements.getElement(CardElement),
      billing_details: { email: userEmail, name: nameOnCard },
      promoCodeId,
      defaultPaymentId: getPaymentMethodId(paymentCards),
      shippingAddress: submitAddressAdapter(data)
    })
  }

  React.useEffect(() => {
    if (OrderPaymentStatus.COMPLETED === orderStatus) {
      notify(t('payment-page.norify.order-completed'), 'success')
      setDisabled(true)
    }
  }, [])

  React.useEffect(() => setDiscountPrice(discountPrice), [discountPrice])

  React.useEffect(() => fetchPaymentCard(), [])

  const methods = useForm({
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false
  });

  return (
    <div className='payment-card__form-wrapper'>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)} className='payment-card__form'>
          {withShipping &&
            <>
              <div className="payment-card__shipment">
                <h4 className="payment-card__title">{t('payment-page.shipping.title')}</h4>
                <p className="payment-card__subtitle">{t('payment-page.shipping.subtitle')}</p>
                <MultiLocationSelect />
              </div>

              <h4 className="payment-card__title">{t('payment-page.payment.title')}</h4>
            </>
          }

          <label className="payment-card__field">
            <span className="payment-card__label">{t('payment-page.form.field.email.label')}</span>
            <input type='text' className="payment-card__input" disabled value={userEmail} />
          </label>

          {isEmpty(paymentCards)
            ? <StripeFieldset
              nameOnCard={nameOnCard}
              setNameOnCard={setNameOnCard}
              error={error}
              setError={setError}
              CardElement={CardElement}
              saveCard={saveCard}
              setSaveCard={setSaveCard}
            />
            : <div className="payment-card__field">
              <span className="payment-card__label">{t('payment-page.form.field.card.label')}</span>
              <PaymentsSelect listCard={paymentCards} />
            </div>}

          <PromoCode
            discountPromoCode={discountPromoCode}
            setDiscountPromoCode={setDiscountPromoCode}
            offerId={offerId}
            orderId={get(data, 'order.id')}
          />

          <Button
            text={`${t('payment-page.form.button.pay')} ${getPrice(actualPrice)}`}
            typeButton='submit'
            disabled={disabledPay}
            loader={isLoading}
            stretch
            paddingVertical={18}
            paddingHorizontal={20}
          />
        </form>

        <PurchaseConfirm
          talent={get(data, 'order.talentOffer.talent')}
          offerType={get(data, 'order.offerOccurrence.offerType')}
          isOpen={isSuccessModalOpen}
          closeModal={closeSuccessModal}
          orderId={get(data, 'order.id')}
        />
      </FormProvider>

      <CheckoutForm data={{ ...data, discountPromoCode }} openSuccessModal={openSuccessModal} />
    </div>
  );
});

PaymentForm.propTypes = {
  data: PropTypes.object,
  setDiscountPrice: PropTypes.func,
}

export default PaymentForm;
