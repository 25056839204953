import { _post, _get } from '../../utils';

export const resetPasswordRequest = ({ email }) => _post(
  '/auth/reset-password/request',
  { body: { email } },
);

export const resetPassword = ({ key, newPassword }) => _post(
  `/auth/reset-password/${key}`,
  { needAuthorization: true, body: { newPassword } },
);

export const getResetPasswordInfo = ({ key }) => _get(
  `/auth/reset-password/${key}`,
);
