import React from 'react';

const List = ({ children, offsetX, marginOffsetX }) => {
  return (
    <div
      className="tbv-slider-slide-machine-list"
      style={{
        transform: `translateX(${-offsetX}px)`,
        marginLeft: `${marginOffsetX}px`,
      }}
    >
      {children}
    </div>
  );
};

export default List;
