import { useEffect } from 'react'

export default function useHandleRoomDisconnection(
  room,
  onError,
  removeLocalAudioTrack,
  removeLocalVideoTrack,
) {
  useEffect(() => {
    if (room) {
      const onDisconnected = (_, error) => {
        if (error) {
          onError(error)
        }

        removeLocalAudioTrack()
        removeLocalVideoTrack()
      }

      room.on('disconnected', onDisconnected)

      return () => {
        room.off('disconnected', onDisconnected)
      }
    }
  }, [room, onError, removeLocalAudioTrack, removeLocalVideoTrack])
}
