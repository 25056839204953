import cond from 'lodash/cond';
import matches from 'lodash/matches';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';
import { types } from 'shared';

const typeInvite  = types.UserType;

const getTypeInvite = cond([
  [matches(typeInvite.AGENT), constant('agent')],
  [matches(typeInvite.MANAGER), constant('manager')],
  [matches(typeInvite.TALENT_USER), constant('talent')],
  [stubTrue, constant('talent')],
]);

export default getTypeInvite;
