import React from 'react'
import PlayIcon from 'mdi-react/PlayIcon'
import ChevronDownIcon from 'mdi-react/ChevronDownIcon'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import gary from './gary.png'
import garyName from './gary_name.png'
import video from './bg_video.mp4'
import bg from './bg.jpg'
import './index.scss'
import { ENTREEVIP_VIDEO_MODAL } from '_legacy/components/Modal/modals-types'
import { useStateModal } from 'store/hooks/globalState/useModal'

function DemocratizingTalentAccessSection({ setActiveSection }) {
  const t = useGetTranslate()
  const showVideoModal = useStateModal(ENTREEVIP_VIDEO_MODAL)
  const lang = useUserLang()

  let videoData = '#';
  if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    navigator.userAgent
  )) {
    videoData = video;
  }

  return (
    <>
      <video className="videoBg" autoPlay loop muted poster={bg}>
        <source src={videoData} type="video/mp4" />
      </video>
      <div className={`democratizing_section section lang_${lang}_section`}>
        <div className="sectionWrapper wrapper content-wrapper">
          <img src={gary} alt="" className="gary" />
          <img src={garyName} alt="" className="garyName" />
          <div className="content">
            <h1 className="title">{t('home.democratizing.title1')}<br /><span className="titleBold">{t('home.democratizing.title2')}</span></h1>
            <p className="desc">{t('home.democratizing.desc1')}</p>
            <p className="descSecond">{t('home.democratizing.desc2')}</p>
            <div className="playIcon  playIconMobile"  onClick={showVideoModal}>
              <PlayIcon size={50} />
            </div>
          </div>
          <div className="playIcon" onClick={showVideoModal}>
            <PlayIcon size={50} />
          </div>
          <div className="arrows" onClick={() => setActiveSection(1)} role="role" tabIndex="0">
            <ChevronDownIcon size={50} className="arrow1" />
            <ChevronDownIcon size={50} className="arrow2" />
          </div>
        </div>
      </div>
    </>
  )
}

export default DemocratizingTalentAccessSection
