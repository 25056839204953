import get from 'lodash/get'

import {
  submitRadio,
  submitSocialMediaAdapter,
  submitAddressAdapter
} from 'utils/forms/adapters'
import { getLibraryData } from 'utils/storage'
import { formatDateOfBirth } from 'utils/forms/adapters/dateOfBirth'

export const submitAdapter = data => {
  const libraryData = getLibraryData()
  const fileLibraryFilesTokens = get(libraryData, 'files', [])

  return {
    type: get(data, 'type'),
    profileImage: get(data, 'profileImage') || null,
    firstName: get(data, 'firstName'),
    lastName: get(data, 'lastName'),
    nickname: get(data, 'nickname'),
    email: get(data, 'email'),
    backupEmail: get(data, 'backupEmail') || null,
    password: get(data, 'password'),
    dateOfBirth: formatDateOfBirth(get(data, 'dateOfBirth')),
    phone: get(data, 'phone'),
    verifyPhoneKey: get(data, 'verifyPhoneKey'),
    socialMedia: submitSocialMediaAdapter(get(data, 'socialMedia')),
    marketingCommunicationsOptions: submitRadio(get(data, 'marketingCommunicationsOptions')),
    sign: get(data, 'sign'),
    fileLibraryFilesTokens,
    notificationPreferences: get(data, 'notificationPreferences'),
    ...submitAddressAdapter(data),
  }
}
