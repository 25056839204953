// Modules
import React from 'react'


const NotificationCounter = ({ unreadNotificationsCount }) => {
  return (
    unreadNotificationsCount > 0 && (
      <div className="user-notifications-counter user-account__notifications--counter">
        <span className='user-notifications-counter__number'>
          {unreadNotificationsCount <= 99 ? unreadNotificationsCount : '99+'}
        </span>
      </div>
    )
  )
}

export default NotificationCounter
