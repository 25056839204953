import get from 'lodash/get'

export const parsePrice = params => {
  const { price, ...rest } = params
  const priceFrom = get(price, 'from')
  const priceTo = get(price, 'to')

  return {
    ...rest,
    priceFrom,
    priceTo,
  }
}
