import React from 'react'
import { BulkNotificationAction, NotificationStatus, errorNames } from 'shared'
import {
  values,
  pick,
  omit,
  merge,
  pluck,
  keyBy,
  assocPath,
  mapValues,
  compact,
  compose,
  cond,
  propEq,
  T,
  identity,
  __,
  castArray,
} from 'lodash/fp'

import * as r from '_legacy/constants/routes'

import {
  getNotifications as getNotificationsApi,
  updateNotifications as updateNotificationsApi,
} from 'api/appApi/notifications'
import {
  useSetNotifications,
  useSetNotificationsTableState,
  useSetNotificationsMenuFilters,
  useSetNotificationsTableFilters,
  useSetNotificationsMenuState,
  useDecreaseUnReadNotification,
  useIncreaseUnReadNotification,
  useNotificationsTableFilters
} from 'store/hooks/globalState/useNotificationsState'
import normalizeParams from 'utils/normalizeParams'
import { useHistory } from 'react-router-dom'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { useCurrentPage } from 'store/hooks/globalState/useUtils'
import { fields } from '_legacy/configs/table/notifications'
import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const getIds = pluck('id')
const keyById = keyBy('id')

// fetch
export function useFetchUnreadNotifications({ isMerge } = { isMerge: false }) {
  const setNotifications = useSetNotifications((prev, next) => next)
  const mergeNotifications = useSetNotifications((prev, next) => ({ ...prev, ...next }))

  const setNotificationMenu = useSetNotificationsMenuState((prev, next) => compact(next))
  const mergeNotificationMenu = useSetNotificationsMenuState((prev = [], next) => compact([...prev, ...next]))

  const notificationHandler = isMerge ? mergeNotifications : setNotifications
  const menuHandler = isMerge ? mergeNotificationMenu : setNotificationMenu

  const setNotificationsMenuFilters = useSetNotificationsMenuFilters(merge)

  return React.useCallback(
    options => {
      getNotificationsApi({
        query: {
          isReadEq: false,
          sortField: 'createdAt',
          sortOrder: 'DESC',
          limit: 10,
          ...options,
        },
      })
        .then(({ notifications, pageInfo }) => {
          notificationHandler(keyById(notifications))
          menuHandler(getIds(notifications))
          setNotificationsMenuFilters(pageInfo)
        })
        .catch(console.warn)
    },
    [menuHandler, notificationHandler, setNotificationsMenuFilters]
  )
}

const parseSortBy = cond([
  [
    propEq('sortField', 'latestFirst'),
    merge(__, { sortOrder: 'DESC', sortField: 'createdAt' }),
  ],
  [
    propEq('sortField', 'oldestFirst'),
    merge(__, { sortOrder: 'ASC', sortField: 'createdAt' }),
  ],
  [
    propEq('sortField', 'read'),
    merge(__, { sortOrder: 'DESC', sortField: 'isRead' }),
  ],
  [
    propEq('sortField', 'unread'),
    merge(__, { sortOrder: 'ASC', sortField: 'isRead' }),
  ],
  [T, identity],
])

const parseNotificationType = cond([
  [propEq('type', 'allNotifications'), merge(__, { type: null })],
  [T, identity],
])

const normalize = compose(normalizeParams, parseSortBy, parseNotificationType)

export function useFetchNotificationsTable() {
  const setNotifications = useSetNotifications(merge)
  const setNotificationsTable = useSetNotificationsTableState(
    (prev, next) => next
  )
  const setNotificationsFilters = useSetNotificationsTableFilters(merge)

  return React.useCallback(
    ({ ...options }) => {
      const query = normalize({
        ...options,
        sortField: options.sortField || 'latestFirst',
      })
      setNotificationsTable(null)

      getNotificationsApi({ query })
        .then(({ notifications, pageInfo }) => {
          setNotifications(keyById(notifications))
          setNotificationsTable(getIds(notifications))
          setNotificationsFilters(pageInfo)
        })
        .catch(() => {
          // todo: temp solution. remove when filter was added
          setNotificationsTable([])
          setNotificationsFilters({ page: 0, remains: 0, count: 0 })
        })
    },
    [setNotificationsTable, setNotifications, setNotificationsFilters]
  )
}

// actions
export function useSetNotificationAsRead() {
  const decreaseUnReadNotification = useDecreaseUnReadNotification()

  return useSetNotifications((prev, ids = []) => {
    const idsArray = castArray(ids)
    const selectedNotification = values(pick(idsArray, prev))
    const unreadNotificationCount = selectedNotification.filter(propEq('isRead', false)).length
    decreaseUnReadNotification(unreadNotificationCount)
    return idsArray.reduce((acc, id) => assocPath([id, 'isRead'], true, acc), prev)
  }, [decreaseUnReadNotification])
}

export function useSetNotificationAsUnRead() {
  const increaseUnReadNotification = useIncreaseUnReadNotification()

  return useSetNotifications((prev, ids = []) => {
    const idsArray = castArray(ids)
    const selectedNotification = values(pick(idsArray, prev))
    const unreadNotificationCount = selectedNotification.filter(propEq('isRead', true)).length
    increaseUnReadNotification(unreadNotificationCount)
    return idsArray.reduce((acc, id) => assocPath([id, 'isRead'], false, acc), prev)
  }, [increaseUnReadNotification])
}

export function useMarkAsRead() {
  const readNotifications = useSetNotificationAsRead()

  return React.useCallback(
    notificationIds => {
      const arrayOfIds = castArray(notificationIds)
      updateNotificationsApi({
        actionType: BulkNotificationAction.READ,
        notificationIds: arrayOfIds,
      })
        .then(() => {
          readNotifications(arrayOfIds)
        })
        .catch(console.warn)
    },
    [readNotifications]
  )
}

export function useMarkAsUnRead() {
  const unReadNotifications = useSetNotificationAsUnRead()

  return React.useCallback(
    notificationIds => {
      const arrayOfIds = castArray(notificationIds)

      updateNotificationsApi({
        actionType: BulkNotificationAction.UNREAD,
        notificationIds: arrayOfIds,
      })
        .then(() => {
          unReadNotifications(arrayOfIds)
        })
        .catch(console.warn)
    },
    [unReadNotifications]
  )
}

export function useMarkAllAsRead() {
  const resetUnReadNotificationCount = useSetNotificationsMenuFilters(
    filters => ({ ...filters, count: 0 })
  )
  const markAllNotificationAsRead = useSetNotifications(prev =>
    mapValues(notification => ({ ...notification, isRead: true }), prev)
  )

  return React.useCallback(() => {
    updateNotificationsApi({
      actionType: BulkNotificationAction.READ,
      notificationIds: [],
    })
      .then(() => {
        markAllNotificationAsRead()
        resetUnReadNotificationCount()
      })
      .catch(console.warn)
  }, [markAllNotificationAsRead, resetUnReadNotificationCount])
}

export function useUpdateStatus() {
  return useSetNotifications((prev, id, status) =>
    assocPath([id, 'status'], status, prev)
  )
}

export function useAcceptManagerInvitation() {
  const t = useGetTranslate()
  const updateStatus = useUpdateStatus()
  const setNotificationsAsRead = useSetNotificationAsRead()
  const acceptManagerInvitation = useSetNotifications((prev, id) =>
    assocPath([id, 'status'], NotificationStatus.ACCEPTED, prev)
  )

  return React.useCallback(
    notificationId => {
      updateNotificationsApi({
        actionType: BulkNotificationAction.ACCEPT,
        notificationIds: [notificationId],
      })
        .then(() => {
          acceptManagerInvitation(notificationId)
        })
        .catch(err => {
          if (err.name === errorNames.talents.join.link.INVITATION_CANCELLED) {
            notify(
              t('notifications.email.talent-invitation.invitation-cancelled'),
              'error'
            )
            updateStatus(notificationId, NotificationStatus.CANCELLED)
          }
        })
        .finally(() => {
          setNotificationsAsRead(notificationId)
        })
    },
    [acceptManagerInvitation, updateStatus]
  )
}

export function useAcceptEmailNotification() {
  const t = useGetTranslate()
  const history = useHistory()

  return React.useCallback(
    notificationId => {
      updateNotificationsApi({
        actionType: BulkNotificationAction.ACCEPT,
        notificationIds: [notificationId],
      })
        .then(() => {
          notify(t('notifications.email.talent-invitation.accept'))
        })
        .catch(err => {
          if (err.name === errorNames.talents.join.link.INVITATION_CANCELLED) {
            notify(
              t('notifications.email.talent-invitation.invitation-cancelled'),
              'error'
            )
          }
        })
        .finally(() => {
          history.push(`/${r.NOTIFICATIONS}`)
        })
    },
    [history, t]
  )
}

export function useRejectManagerInvitation() {
  const t = useGetTranslate()
  const updateStatus = useUpdateStatus()
  const setNotificationsAsRead = useSetNotificationAsRead()
  const rejectManagerInvitation = useSetNotifications((prev, id) =>
    assocPath([id, 'status'], NotificationStatus.REJECTED, prev)
  )

  return React.useCallback(
    notificationId => {
      updateNotificationsApi({
        actionType: BulkNotificationAction.REJECT,
        notificationIds: [notificationId],
      })
        .then(() => {
          rejectManagerInvitation(notificationId)
        })
        .catch(err => {
          if (err.name === errorNames.talents.join.link.INVITATION_CANCELLED) {
            notify(
              t('notifications.email.talent-invitation.invitation-cancelled'),
              'error'
            )
            updateStatus(notificationId, NotificationStatus.CANCELLED)
          }
        })
        .finally(() => {
          setNotificationsAsRead(notificationId)
        })
    },
    [t, rejectManagerInvitation, updateStatus]
  )
}

export function useRemoveNotification() {
  const readNotifications = useSetNotificationAsRead()
  const fetchNotificationTable = useFetchNotificationsTable()
  const reduceNotificationMenuCount = useSetNotificationsMenuFilters(
    (filters, count) => ({ ...filters, count: filters.count - count })
  )
  const removeNotifications = useSetNotifications((notifications, idsToRemove) => omit(idsToRemove, notifications), [])

  const page = useCurrentPage()
  const filtersOptions = useNotificationsTableFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )

  return React.useCallback(
    notificationIds => {
      const arrayOfIds = castArray(notificationIds)

      updateNotificationsApi({
        actionType: BulkNotificationAction.REMOVE,
        notificationIds: arrayOfIds,
      })
        .then(() => {
          fetchNotificationTable({ ...filtersOptions, page })
          readNotifications(arrayOfIds)
          removeNotifications(removeNotifications())
        })
        .catch(console.warn)
    },
    [fetchNotificationTable, reduceNotificationMenuCount, filtersOptions, page]
  )
}

export function useRedirectToNotificationTable() {
  const history = useHistory()

  return React.useCallback(
    notificationId => {
      getNotificationsApi({ query: { notificationId, limit: 25 } })
        .then(({ pageInfo }) => {
          history.push(`/${r.NOTIFICATIONS}?page=${pageInfo.page}`)
        })
        .catch(console.warn)
    },
    [history]
  )
}


export function useAcceptBookingRequest() {
  const t = useGetTranslate()
  const updateStatus = useUpdateStatus()
  const setNotificationsAsRead = useSetNotificationAsRead()
  const acceptManagerInvitation = useSetNotifications((prev, id) =>
    assocPath([id, 'status'], NotificationStatus.ACCEPTED, prev)
  )

  return React.useCallback(
    notificationId => {
      updateNotificationsApi({
        actionType: BulkNotificationAction.ACCEPT,
        notificationIds: [notificationId],
      })
        .then(() => {
          acceptManagerInvitation(notificationId)
        })
        .catch(err => {
          if (err.name === errorNames.talents.join.link.INVITATION_CANCELLED) {
            notify(
              t('notifications.email.talent-invitation.invitation-cancelled'),
              'error'
            )
            updateStatus(notificationId, NotificationStatus.CANCELLED)
          }
        })
        .finally(() => {
          setNotificationsAsRead(notificationId)
        })
    },
    [acceptManagerInvitation, updateStatus]
  )
}

export function useRejectBookingRequest() {
  const t = useGetTranslate()
  const updateStatus = useUpdateStatus()
  const setNotificationsAsRead = useSetNotificationAsRead()
  const rejectManagerInvitation = useSetNotifications((prev, id) =>
    assocPath([id, 'status'], NotificationStatus.REJECTED, prev)
  )

  return React.useCallback(
    (notificationId, rejectReason, rejectReasonDescription) => {
      updateNotificationsApi({
        actionType: BulkNotificationAction.REJECT,
        notificationIds: [notificationId],
        body: { rejectReason, rejectReasonDescription }
      })
        .then(() => {
          rejectManagerInvitation(notificationId)
        })
        .catch(err => {
          if (err.name === errorNames.offers.BOOK_ALREADY_REJECTED) {
            updateStatus(notificationId, NotificationStatus.REJECTED)
          }
        })
        .finally(() => {
          setNotificationsAsRead(notificationId)
        })
    },
    [t, rejectManagerInvitation, updateStatus]
  )
}
