import { _get } from 'api/utils'


export const fetchGooglePlaceByCoordinates = ({ longitude, latitude }) =>
  _get('/integrations/locations/geocode', {
    needAuthorization: false,
    query: { longitude, latitude },
  })

export const fetchCountsByLocationAggregated = query =>
  _get('searches/results/counts-by-location/aggregated', {
    needAuthorization: false,
    query,
  })

export const fetchCountsByLocationTop = query =>
  _get('searches/results/counts-by-location/top', {
    needAuthorization: false,
    query,
  })


