export const NOTIFICATION = 'notification'

export const elementsTypes = {
  [NOTIFICATION]: NOTIFICATION
}

import Notification from './Notifications'

export const NOTIFICATIONS = 'notifications'

export const notificationsComponents = {
  [NOTIFICATION]: Notification
}
