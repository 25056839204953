import React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'
import cond from 'lodash/cond'
import matches from 'lodash/matches'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import SelectOption from '_legacy/components/Table/Filters/Selects/common/SelectOption'
import SelectInput from '_legacy/components/Table/Filters/Selects/common/SelectInput'
import ClearIndicator from '_legacy/components/Table/Filters/Selects/common/ClearIndicator'
import DropdownIndicator from '_legacy/components/Table/Filters/Selects/common/DropdownIndicator'
import SelectControl from '_legacy/components/Table/Filters/Selects/common/SelectControl';

const AsyncSelect = (props) => {
  const {
    loadOptions,
    value,
    onChange: _onChange,
    placeholder,
    loadOptionsOnMenuOpen,
    groupedOptions,
    defaultOptions,
    cacheOptions,
    inputIcon,
    isDisabled
  } = props
  const t = useGetTranslate()
  const selectRef = React.useRef()
  const translatedGroupedOptions = groupedOptions.map((option) => ({
    ...option,
    label: t(option.label),
    options: option.options.map(option => ({ ...option, label: t(option.label) }))
  }))
  const [inputValue, setInputValue] = React.useState(null)

  const onInputChange = (inputValue, { action }) => {
    cond([
      [matches('input-blur'), () => setInputValue(value ? value.label : '')],
      [matches('input-change'), () => setInputValue(inputValue)],
      [matches('clear'), () => setInputValue(null)],
    ])(action)
  }

  const onChange = (option, e) => {
    setInputValue(option ? option.label : '')
    _onChange(option, e, setInputValue)
  }

  const onFocus = () => {
    if (value) {
      selectRef.current?.select?.inputRef?.select()
    }
  }

  React.useEffect(() => {
    if (!value) {
      setInputValue(undefined)
    }
  }, [value])

  return (
    <div className="react-select-container">
      <AsyncPaginate
        key={JSON.stringify(props)}
        isDisabled={isDisabled}
        ref={selectRef}
        isClearable={true}
        value={value}
        inputValue={inputValue}
        onInputChange={onInputChange}
        onChange={onChange}
        onFocus={onFocus}
        options={translatedGroupedOptions}
        controlShouldRenderValue={true}
        components={{
          Input: SelectInput,
          Option: SelectOption,
          Control: SelectControl,
          IndicatorSeparator: () => null,
          ClearIndicator: ClearIndicator,
          DropdownIndicator: DropdownIndicator,
        }}
        debounceTimeout={500}
        loadOptions={loadOptions}
        placeholder={placeholder}
        loadOptionsOnMenuOpen={loadOptionsOnMenuOpen}
        classNamePrefix="react-select"
        cache={false}
        cacheOptions={cacheOptions}
        defaultOptions={defaultOptions}
        IconComponent={inputIcon}
      />
    </div>
  )
}

AsyncSelect.defaultProps = {
  placeholder: '',
  groupedOptions: [],
  loadOptionsOnMenuOpen: false,
  defaultOptions: false
}

AsyncSelect.propTypes = {
  loadOptions: PropTypes.func,
  placeholder: PropTypes.string,
  groupedOptions: PropTypes.array,
  customOnChange: PropTypes.func,
  onChange: PropTypes.func,
  value: PropTypes.object,
  loadOptionsOnMenuOpen: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  cacheOptions: PropTypes.bool,
  inputIcon: PropTypes.element,
}

export default AsyncSelect
