import React from 'react'

import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import Icon from 'assets/icons/Icon'

const OrderUploadFiles = ({ files = [] }) => {
  const handleDownload = (fullUrl) => window.open(fullUrl, '_blank')

  return (
    <div className="upload-file-list">
      {files.map(({ file, fullUrl }) => (
        <div key={file.id} className="upload-file">
          <FilePreview file={file} iconSize={40}  />
          <button onClick={() => handleDownload(fullUrl)} type='button' className='upload-file__btn'>
            <Icon.Download  className='upload-file__icon' />
          </button>
        </div>
      ))}
    </div>
  )
}

export default OrderUploadFiles
