// Modules
import React from 'react'
import compose from 'lodash/fp/compose'

// Store (hooks)
import {
  getPromotedTalents as getPromotedTalentsApi,
  getPromotedOffers as getPromotedOffersApi,
} from './api'
import { useSetSearchPromotedTalents, useSetSearchPromotedOffers } from 'store/hooks/globalState/useSearchPromotedList'

// Utils
import normalizeParams from 'utils/normalizeParams'
import { parseRadius } from '_legacy/components/Table/Filters/utils/radius'
import { parseLocation } from '_legacy/components/Table/Filters/utils/location'
import { parseFollowers } from '_legacy/components/Table/Filters/utils/followers'
import { parseTalentCategoryIn } from '_legacy/components/Table/Filters/utils/categoryIn'
import { parsePrice } from '_legacy/components/Table/Filters/utils/price'
import { parseDuration } from '_legacy/components/Table/Filters/utils/duration'
import { parseParticipant } from '_legacy/components/Table/Filters/utils/participant'
import { parseCharities } from '_legacy/components/Table/Filters/utils/charities'
import { parseRangeDate } from '_legacy/components/Table/Filters/utils/rangeDate'
import { parseOfferCategoryIn } from '_legacy/components/Table/Filters/utils/categoryIn'
import { parseProximity } from '_legacy/components/Table/Filters/utils/proximity'


const talentsNormalizer = compose(
  normalizeParams,
  parseFollowers,
  parseRadius,
  parseLocation,
  parseTalentCategoryIn,
  parseProximity
)

export const useFetchPromotedTalentsItems = () => {
  const setSearchPromotedTalents = useSetSearchPromotedTalents((prev, next) => next)

  return React.useCallback(({ ...query }) => {
    const params = talentsNormalizer(query)
    getPromotedTalentsApi({
      query: {
        limit: null,
        fields: ['id', 'firstName', 'lastName', 'nickname'],
        relations: 'halfBodyImage',
        ...params,
      },
    }).then(({ talents = [] }) => {
      setSearchPromotedTalents(talents)
    })
  },
  [setSearchPromotedTalents]
  )
}

const offersNormalizer = compose(
  normalizeParams,
  parseRadius,
  parseLocation,
  parsePrice,
  parseDuration,
  parseCharities,
  parseParticipant,
  parseRangeDate,
  parseOfferCategoryIn
)

export const useFetchPromotedOffersItems = () => {
  const setSearchPromotedOffers = useSetSearchPromotedOffers((prev, next) => next)

  return React.useCallback(({ ...query }) => {
    const params = offersNormalizer(query)
    getPromotedOffersApi({
      query: {
        limit: null,
        ...params,
      },
    }).then(({ offers = [] }) => {
      setSearchPromotedOffers(offers)
    })
  },
  [setSearchPromotedOffers]
  )
}
