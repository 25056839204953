
import React from 'react'

import IOSa2hsModal from 'components/IOSa2hsModal'


const withIOSa2hsModal = Component => props =>
  <>
    <Component {...props} />
    <IOSa2hsModal />
  </>

export default withIOSa2hsModal
