import React from 'react'
import cx from 'classnames'
import { format } from 'date-fns'
import { NotificationAdditionalType } from 'shared'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import { NotificationTypes } from '_legacy/components/Notification/Notification.types'

const ManagerInvitationResponse = ({
  id,
  createdAt,
  sender = {},
  isRead,
  additionalType,
  isOverdue,
  isTable,
  isMenu,
}) => {
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  const transProps = {
    [NotificationAdditionalType.ACCEPTED]: {
      phs: [{ ph: '{{agentName}}', value: sender?.firstName }],
      tKey: 'notification-menu.item.message.manager-accepted-invitation',
    },
    [NotificationAdditionalType.REJECTED]: {
      phs: [{ ph: '{{agentName}}', value: sender?.firstName }],
      tKey: 'notification-menu.item.message.manager-rejected-invitation',
    },
  }

  return (
    <div className={containerClasses} id={id}>
      <div className="notification-item__icon">
        <Icon.Bell id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans {...transProps[additionalType]} />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {format(new Date(createdAt), 'MMM dd, yyyy')}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>
    </div>
  )
}

ManagerInvitationResponse.propTypes = NotificationTypes

export default ManagerInvitationResponse
