import React from 'react'
import { useParams } from 'react-router'
import { BulkOrdersAction } from 'shared'
import { notify } from 'utils/notify'

import { fetchVideoChatsOrderDetailsHeader as fetchHeader, updateVideoChatOccurrenceTime, orderRequestAction } from './api.js'

import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { useSetVideoChatsOrderDetailsHeader as useSetHeader } from './store.js'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export function useFetchVideoChatsOrderDetailsHeader() {
  const { orderId: id } = useParams()
  const setHeader = useSetHeader((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchHeader({ id })
      .then((details) => {
        setHeader(details)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [id, setHeader])
}

export function useUpdateVideChatOccurrenceTime() {
  const { orderId: offerVideoChatOccurrenceId } = useParams()
  const fetchVideoChatsOrderDetailsHeader = useFetchVideoChatsOrderDetailsHeader()

  return React.useCallback(
    data => {
      updateVideoChatOccurrenceTime({ data, offerVideoChatOccurrenceId }).then(() => {
        fetchVideoChatsOrderDetailsHeader()
      })
    },
    [offerVideoChatOccurrenceId, fetchVideoChatsOrderDetailsHeader]
  )
}

export function useCancelOrder() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchVideoChatsOrderDetails = useFetchVideoChatsOrderDetailsHeader()

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message
    }

    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.notification.cancelled'))
        fetchVideoChatsOrderDetails()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t, fetchVideoChatsOrderDetails]
  )
}
