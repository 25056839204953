import React from 'react'
import { useHistory } from 'react-router-dom'

import { bookOffer as bookOfferApi } from 'api/appApi/offers/offers'
import * as r from '_legacy/constants/routes'
import { useNotifyError } from 'utils/services/errors/error-config'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useBookOffer(setLoader) {
  const history = useHistory()
  const notifyError = useNotifyError()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    ({ offerId, data, offeringType }) => {
      setLoader && setLoader(true)
      bookOfferApi({ offerId, data })
        .then(({ order }) =>
          history.push(`/${r.OFFERINGS}/${offeringType}/${order.id}/${r.PURCHASE}`)
        )
        .catch((error) => {
          handleResponseError(error)
          notifyError(error)
        })
        .finally(() => setLoader && setLoader(false))
    },
    [history, notifyError]
  )
}
