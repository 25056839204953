import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icon from 'assets/icons/Icon'
import ControlledCollapse from 'components/ControlledCollapse';

const Collapse = ({ isCollapsed, withBorder, title, text, children }) => {
  const [collapsed, setCollapsed] = useState(isCollapsed || true)

  return (
    <div className={classnames('collapse', {
      'collapse--opened': !collapsed,
      'collapse--bordered': withBorder,
    })}>
      <div className="collapse--title">
        <button onClick={() => setCollapsed(!collapsed)} type='button'>
          <Icon.ArrowDownSelect />
          {title}
        </button>

        {text && <p>{text}</p>}
      </div>

      <ControlledCollapse collapsed={collapsed}>
        {children}
      </ControlledCollapse>
    </div>
  )
}

Collapse.propTypes = {
  isCollapsed: PropTypes.bool,
  withBorder: PropTypes.bool,
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default Collapse
