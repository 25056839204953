import React from 'react'
import ModalPortal from '../ModalPortal'

const InfoModal = ({ isOpen, onClose, children }) => {
  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <div className="info-modal">{children}</div>
    </ModalPortal>
  )
}

export default InfoModal
