import React from 'react';
import get from 'lodash/get';

import {
  getFiles as getFilesApi,
  removeFile as removeFileApi,
  getBulkFileLibrary as getBulkFileLibraryApi,
} from 'api/appApi/fileLibrary';
import {
  useAddFilesToLibrary,
  useUpdatePageInfo,
  useRemoveFileToken,
} from 'store/hooks/globalState/useFileLibrary';
import { useUser } from 'store/hooks/globalState/useUser';

// todo: remove - use bulk
export function useFetchFileLibrary() {
  const addFilesToLibrary = useAddFilesToLibrary();
  const updatePageInfo = useUpdatePageInfo();

  return React.useCallback(({ fileLibraryId, types, page, sortOrder = 'DESC' }) => {
    getFilesApi({ fileLibraryId, query: { types, page, sortOrder } })
      .then(({ files, pageInfo }) => {
        addFilesToLibrary(files);
        updatePageInfo(pageInfo);
      })
      .catch(error => console.warn(error));
  },
  [addFilesToLibrary, updatePageInfo]);
}

export function useFetchBulkFileLibrary() {
  const addFilesToLibrary = useAddFilesToLibrary();
  const updatePageInfo = useUpdatePageInfo();

  return React.useCallback(({ fileLibrariesIds, types, page, sortOrder = 'DESC' }) => {
    getBulkFileLibraryApi({ types, page, sortOrder, fileLibrariesIds })
      .then(({ files, pageInfo }) => {
        addFilesToLibrary(files);
        updatePageInfo(pageInfo);
      })
      .catch(error => console.warn(error));
  },
  [addFilesToLibrary, updatePageInfo]);
}

export function useRemoveFileFromFileLibrary() {
  const user = useUser();
  const removeFileToken = useRemoveFileToken();
  const fileLibraryId = get(user, ['talent', 'fileLibrary', 'id']);

  return React.useCallback(file => {
    const isLoading = get(file, 'isLoading');
    const fileId = get(file, 'id');
    const fileToken = get(file, 'fileToken');

    removeFileToken(fileToken);

    if (isLoading && file.abort) {
      file.abort();
    } else {
      removeFileApi({ fileLibraryId, fileId })
        .catch(error => console.warn(error));
    }
  },
  [removeFileToken, fileLibraryId]);
}
