import { _get, _post, _put } from 'api/utils'

export const getVideoMessageOccurrence = ({ occurrenceId }) =>
  _get(`/offers/video-messages/occurrences/${occurrenceId}`, {
    needAuthorization: true,
  })

export const uploadFileToVideoMessageOccurrence = ({ occurrenceId, fileToken }) =>
  _put(`/offers/video-messages/occurrences/${occurrenceId}/upload-video`, {
    needAuthorization: true,
    body: { fileToken },
  })

export const submitVideoMessageOccurrence = ({ occurrenceId }) =>
  _post(`/offers/video-messages/occurrences/${occurrenceId}/submit`, {
    needAuthorization: true,
  })
