import React from 'react'
import { api } from 'shared'
import { BehaviorSubject } from 'rxjs'
import isEqual from 'lodash/isEqual'
import get from 'lodash/get'
import { getEntitiesData, getUserData, getLibraryData } from 'utils/storage'
import mileToKm from 'utils/mileToKm'
import {
  talentCalendarFilters,
  talentCalendarSlots,
  talentCalendarBusinessHours
} from 'components/Calendar/TalentCalendar/state/store/useCalendar'

export const libraryInitialState = {
  files: [],
  pageInfo: null,
  isLoading: false,
}
const entitiesInitialState = {}

const initialUser = getUserData()
const initialEntitiesData = getEntitiesData() || entitiesInitialState
const initialLibraryData = getLibraryData() || libraryInitialState

const supportedLang = ['EN', 'ES', 'PT']

let userLang = get(initialUser, 'user.settings.language')
if (!userLang) {
  userLang = 'EN'
  const detectedLang = navigator.language.slice(0, 2).toUpperCase()
  if (supportedLang.includes(detectedLang)) userLang = detectedLang
}

const currency = {
  currentCurrencyCode: get(initialUser, 'user.settings.currency', 'USD'),
  fetchedCurrencies: [{ code: 'USD', rate: 1 }],
}

const { NAME } = api.searches.results.talents.search.SortField


export const globalContext = React.createContext({
  windowDimensions: new BehaviorSubject({
    width: window.innerWidth,
    height: window.innerHeight,
  }),
  userLang: new BehaviorSubject(userLang),
  currency: new BehaviorSubject(currency),
  translates: new BehaviorSubject(null),
  subMenuActive: new BehaviorSubject(false),
  accountMenuOffest: new BehaviorSubject(0),
  // Profile Managers
  profileManagersFilters: new BehaviorSubject({
    input: '',
    sortField: 'NAME',
    limit: 10,
  }),
  profileManagersTable: new BehaviorSubject(null),
  // Manage Offerings
  myOfferings: new BehaviorSubject(null),
  manageOfferingsFilters: new BehaviorSubject({
    input: '',
    sortField: 'TITLE',
    limit: 10,
    selected: [],
    sortOrder: undefined,
  }),
  manageOfferingsTable: new BehaviorSubject(null),
  // Manage Promo Codes
  managePromoCodesFilters: new BehaviorSubject({
    input: '',
    sortField: 'name',
    limit: 50,
    selected: [],
  }),
  managePromoCodesTable: new BehaviorSubject(null),
  connectedTalents: new BehaviorSubject(null),
  switchedProfile: new BehaviorSubject(null),
  commercialEngagements: new BehaviorSubject(null),
  liveVirtualEvents: new BehaviorSubject(null),
  user: new BehaviorSubject(initialUser),
  isUserLoading: new BehaviorSubject(null),
  fileEntities: new BehaviorSubject(initialEntitiesData),
  fileLibrary: new BehaviorSubject(initialLibraryData),
  lessons: new BehaviorSubject(null),
  videoMessage: new BehaviorSubject({
    loading: null,
    error: null,
    data: null,
  }),
  commercialVideoMessage: new BehaviorSubject({
    loading: null,
    error: null,
    data: null,
  }),
  videoChat: new BehaviorSubject({
    loading: null,
    error: null,
    data: null,
  }),
  experiencePublicView: new BehaviorSubject(null),
  experiencePublicViewOccurrence: new BehaviorSubject(null),
  liveVirtualEvent: new BehaviorSubject(null),
  liveVirtualEventOccurrence: new BehaviorSubject(null),
  talentPublicView: new BehaviorSubject(null),
  errors: new BehaviorSubject(null),
  editProfile: new BehaviorSubject({
    loading: null,
    error: null,
    data: null,
  }),
  talentInvitation: new BehaviorSubject({
    loading: null,
    error: null,
    data: null,
  }),
  modals: new BehaviorSubject({
    type: null,
    props: null,
  }),
  subModals: new BehaviorSubject({
    type: null,
    props: null,
  }),
  readyMadeEvents: new BehaviorSubject(null),
  experience: new BehaviorSubject(null),
  switchNavigation: new BehaviorSubject({
    path: null,
    agent: null,
    idToSwitch: null,
    shouldNavigate: true,
  }),
  searchList: new BehaviorSubject(null),
  searchListFilters: new BehaviorSubject({
    sortField: 'FOLLOWERS_COUNT', // todo: refactor with shared
    search: '',
    availableOfferingTypesIn: null,
    location: null,
    locationKmRadius: mileToKm(5),
    languagesIn: null,
    followers: null,
    ratingFrom: null,
    ratingTo: null,
    categoryIn: null,
    page: 1,
  }),
  offeringsPromoCodesFilters: new BehaviorSubject({
    input: '',
    offeringTypeIn: '',
    limit: 10,
    selected: [],
  }),
  offeringsPromoCodesTable: new BehaviorSubject([]),
  userSearchLocationHistory: new BehaviorSubject([]),
  offersTopLocations: new BehaviorSubject([]),
  talentTopLocations: new BehaviorSubject([]),
  videoMessagesPublic: new BehaviorSubject(null),
  fullpageBlock: new BehaviorSubject(false),
  readyMadeEventsPublic: new BehaviorSubject(null),
  searchOffers: new BehaviorSubject(null),
  searchOffersFilters: new BehaviorSubject({
    sortField: NAME,
    search: '',
    offeringTypesIn: null,
    location: null,
    locationKmRadius: mileToKm(5),
    languagesIn: null,
    followers: null,
    ratingFrom: null,
    ratingTo: null,
    date: null,
    talentCategoryIn: null,
    sortByLocationLatitude: null,
    sortByLocationLongitude: null
  }),
  searchPromotedTalents: new BehaviorSubject(null),
  searchPromotedOffers: new BehaviorSubject(null),
  videoChatPublic: new BehaviorSubject(null),
  talentCategories: new BehaviorSubject(null),
  offersCategories: new BehaviorSubject(null),
  manageOrdersFilters: new BehaviorSubject({
    searchLike: '',
    sortField: 'DATE',
    limit: 25,
  }),
  manageOrdersTable: new BehaviorSubject(null),
  myMediaFilters: new BehaviorSubject({
    searchLike: '',
    mediaType: '',
    usedInOffersTypes: '',
    usedInPromotionalMediaTypes: '',
    sortField: 'name',
    mediaTypeIn: null,
    limit: 25,
  }),
  myMediaTable: new BehaviorSubject(null),
  favoritesTalents: new BehaviorSubject(null),
  favoritesTalentsFilters: new BehaviorSubject({
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),
  favoritesOffers: new BehaviorSubject(null),
  favoritesOffersFilters: new BehaviorSubject({
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),
  paymentCards: new BehaviorSubject(null),
  notifications: new BehaviorSubject({}),
  notificationsTableState: new BehaviorSubject(null),
  notificationsTableFilters: new BehaviorSubject({
    type: 'allNotifications',
    sortField: '',
    limit: 25,
    selected: [],
  }),
  notificationsMenuState: new BehaviorSubject([]),
  notificationsMenuFilters: new BehaviorSubject({}),
  notificationsTable: new BehaviorSubject(null),
  videoMessageOrder: new BehaviorSubject(null),
  experienceOfferingDetailsHeader: new BehaviorSubject(null),
  experienceOfferingDetailsFilters: new BehaviorSubject({
    input: '',
    sortField: undefined,
    statusIn: [],
    startTimeFrom: '',
    startTimeTo: '',
    locationLike: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),
  experienceOfferingDetailsTable: new BehaviorSubject(null),
  virtualEventsOfferingDetailsHeader: new BehaviorSubject(null),
  virtualEventsOfferingDetailsFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),
  virtualEventsOfferingDetailsTable: new BehaviorSubject(null),
  reviewsByMeTable: new BehaviorSubject(null),
  reviewsByMeFilters: new BehaviorSubject({
    limit: 10,
    sortField: 'createdAt',
    sortOrder: null,
  }),
  reviewsOfMeTable: new BehaviorSubject(null),
  reviewsOfMeFilters: new BehaviorSubject({
    limit: 10,
    sortField: 'createdAt',
    sortOrder: null,
  }),
  experienceOrderDetails: new BehaviorSubject(null),
  experienceOrderParticipants: new BehaviorSubject(null),
  experienceOrderParticipantsFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: null,
  }),
  experienceOrderRequests: new BehaviorSubject(null),
  experienceOrderRequestsFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: null,
  }),
  isExperienceEditable: new BehaviorSubject(null),
  talentCalendarSlots,
  talentCalendarFilters,
  talentCalendarBusinessHours,
  readyMadeEventLessonsDetailsHeader: new BehaviorSubject(null),
  readyMadeEventLessonsDetailsOrderTable: new BehaviorSubject(null),
  readyMadeEventLessonsDetailsOrderFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),
  readyMadeEventLessonsDetailsProductTable: new BehaviorSubject(null),
  readyMadeEventLessonsDetailsProductFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: undefined,
  }),

  chatsList: new BehaviorSubject({
    list: {},
    pageInfo: {
      page: 0,
      count: 0,
      limit: 10,
      remains: 0,
    },
  }),
  isLoadingChat: new BehaviorSubject(false),
  isLoadingChatsList: new BehaviorSubject(true),
  hasUnreadChatMessages: new BehaviorSubject(false),
  chatClient: new BehaviorSubject(null),
  searchAficionados: new BehaviorSubject({
    list: [],
    pageInfo: {
      page: 1,
      limit: 30,
    },
    searchLike: '',
  }),
  virtualEventOrderDetails: new BehaviorSubject(null),
  isVirtualEventEditable: new BehaviorSubject(false),
  virtualEventOrderDetailsTable: new BehaviorSubject(null),
  virtualEventOrderDetailsTableFilters: new BehaviorSubject({
    input: '',
    sortField: '',
    limit: 10,
    page: 1,
    sortOrder: null,
  }),
  productFilesFilters: new BehaviorSubject({
    limit: 10,
    page: 1,
    sortOrder: undefined,
    selected: [],
  }),
  productFilesTable: new BehaviorSubject(null),
  videoVoiceOverLessonOrderDetails: new BehaviorSubject(null),
  complaint: new BehaviorSubject(null),
  complaintError: new BehaviorSubject(null),
  videoChatsOrderDetailsHeader: new BehaviorSubject(null),
  liveVirtualLessonOrderDetails: new BehaviorSubject(null),
  myBookingsFilters: new BehaviorSubject({
    input: '',
    sortField: 'ORDER_BOOKING_DATE',
    limit: 10,
    selected: [],
    sortOrder: 'DESC',
    orderStatusIn: null,
  }),
  myBookingsTable: new BehaviorSubject(null),
  liveInPersonOrderDetails: new BehaviorSubject(null),
  readyMadeVideosBookingDetails: new BehaviorSubject(null),
  liveVirtualLessonBookingsDetails: new BehaviorSubject(null),
  liveInPersonBookingsDetails: new BehaviorSubject(null),
  videoVoiceOverLessonBookingDetails: new BehaviorSubject(null),
  isExperienceCancelable: new BehaviorSubject(null),
  searchResults: new BehaviorSubject(null),
  recentSearchList: new BehaviorSubject(null),
  aficionadoProfile: new BehaviorSubject(null),
  howToVideos: new BehaviorSubject(null),
  howToVideosFilters: new BehaviorSubject({
    sortField: 'displayOrder',
    page: 1,
    limit: 10,
    sortOrder: 'DESC',
  }),
  videoMessageBookingsDetails: new BehaviorSubject(null),
  videoChatBookingsDetails: new BehaviorSubject(null),
  videoMessageDownloadPage: new BehaviorSubject(null),
  onfidoToken: new BehaviorSubject(null),
  onfidoCheckResult: new BehaviorSubject(null),
  experienceBookingDetails: new BehaviorSubject(null),
  videoMessageRequestDraft: new BehaviorSubject(null),
  experienceBookingDetailsParticipants: new BehaviorSubject(null),
  experienceBookingDetailsParticipantsFilters: new BehaviorSubject({
    limit: 10,
    page: 1,
  }),
  globalIsLoading: new BehaviorSubject(false),
  faqList: new BehaviorSubject(null),
  faqListFilters: new BehaviorSubject({
    limit: 10,
    page: 1,
  }),
  resourceCenter: new BehaviorSubject(null),
  resourceCenterFilters: new BehaviorSubject({
    sortField: 'displayOrder',
    page: 1,
    limit: 20,
    sortOrder: 'DESC',
  }),
  entourageOrder: new BehaviorSubject(null),
})

function identity(value) {
  return value
}

// Higher order hook for providing access to a single key of global state
export function createUseGlobalState(key) {
  return (fn = identity, deps = []) => {
    const context = React.useContext(globalContext)
    const withFn = React.useCallback(fn, deps)
    const stateObservable = context[key]
    const [localCopy, setLocalCopy] = React.useState(withFn(stateObservable.value))
    React.useEffect(() => {
      const sub = stateObservable.subscribe((...args) => {
        if (isEqual(localCopy, withFn(...args))) return
        setLocalCopy(withFn(...args))
      })
      return () => sub.unsubscribe()
    }, [stateObservable, localCopy, setLocalCopy, withFn])
    return localCopy
  }
}

function defaultStateMapper(setter, nextValue) {
  return setter(nextValue)
}

// Higher order hook for providing access to updating a single key of global state
export function createUseSetGlobalState(key, mapper = defaultStateMapper) {
  return (fn, deps) => {
    const withFn = React.useCallback(fn, deps)
    const context = React.useContext(globalContext)
    const stateObservable = context[key]
    const setter = React.useCallback(next => stateObservable.next(next), [stateObservable])
    return React.useCallback(
      (...args) => {
        return mapper(setter, withFn(stateObservable.value, ...args))
      },
      [setter, stateObservable, withFn]
    )
  }
}

export const useWindowDimensions = createUseGlobalState('windowDimensions')
export const useSetWindowDimensions = createUseSetGlobalState('windowDimensions')

export const useSubMenuActive = createUseGlobalState('subMenuActive')
export const useSetSubMenuActive = createUseSetGlobalState('subMenuActive')

export const useAccountMenuOffest = createUseGlobalState('accountMenuOffest')
export const useSetAccountMenuOffest = createUseSetGlobalState('accountMenuOffest')

export const useFullpageBlock = createUseGlobalState('fullpageBlock')
export const useSetFullpageBlock = createUseSetGlobalState('fullpageBlock')
