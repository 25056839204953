// TODO: Refactor this component and move to new structure

// Modules
import React from 'react'
import { compact, prop, isEmpty } from 'lodash/fp'
import { types } from 'shared'

// Store (hooks)
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchExperiencePublicView } from 'requests/public-views/experience/experience-public-view'
import { useExperiencePublicView, useSetExperiencePublicView } from 'store/hooks/globalState/usePublicView'

// Utils
import { getHumanDuration } from '../utils'
import { getCharityLabel } from 'utils/charities'

// Legacy components
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicDetailsBlock from '_legacy/components/PublicView/PublicDetailsBlock'
import PublicDetailsItemBlock from '_legacy/components/PublicView/PublicDetailsItemBlock'
import PublicCardBlockInfo from '_legacy/components/PublicView/PublicCardBlockInfo'
import PublicCardBlockActions from '_legacy/components/PublicView/PublicCardBlockActions'
import CustomPicture from '_legacy/common/CustomPicture'
import VideoModal from '_legacy/components/Modal/VideoModal'
import PageLoader from '_legacy/common/Template/PageLoader'
import EntourageRequired from '_legacy/components/PublicView/EntourageRequired'

// Components
import FixedTypesOfDate from './components/FixedTypesOfDate'
import AllowAficionadoToPropose from './components/AllowAficionadoToPropose'
import AnyAvailableDayTime from './components/AnyAvailableDayTime'
import TimeWindow from './components/TimeWindow'
import ExperienceLegalTermsList from './components/ExperienceLegalTermsList'

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  RECURRING_DAY_TIME_OPTION,
  UP_TO_5_DAY_TIME_OPTIONS
} = types.experiences.DaysTimesType
const { FIXED } = types.experiences.TypesOfDate


const ExperiencePublic = () => {
  const t = useGetTranslate()

  const [isVideoModalOpen, , closeVideoModal, toggleVideoModal] = useModal(false)

  const fetchExperiencePublicView = useFetchExperiencePublicView()
  const clearOccurrence = useSetExperiencePublicView(() => null)

  const isFollowed = useExperiencePublicView(prop('currentUserPersonalData.isFollowed'))
  const experience = useExperiencePublicView(prop('experience'))
  const slots = useExperiencePublicView(prop('slots'))

  React.useEffect(() => {
    fetchExperiencePublicView()
    return () => {
      clearOccurrence()
    }
  }, [])

  if (!experience || !slots) return <PageLoader />

  const {
    id,
    name,
    customTaboos,
    defaultTaboos,
    duration,
    locations,
    otherInformation,
    photo,
    pricePerParticipant,
    signaturesOk,
    takingPicturesOk,
    talent,
    video,
    whichCharities,
    typesOfDate,
    daysTimesType,
    hideAddressFromPubView,
    entourageType
  } = experience

  const isFixedTypeOfDate = FIXED === typesOfDate
  const isUpTo5DayTimeOptions = UP_TO_5_DAY_TIME_OPTIONS === daysTimesType
  const isRecurringDayTimeOption = RECURRING_DAY_TIME_OPTION === daysTimesType
  const isAllowAficionadoToProposeDayTime = ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME === daysTimesType
  const isAnyAvailableDayTimeInTalentCalendar = ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR === daysTimesType

  const takingPictures = takingPicturesOk ? t('experience.details.yes') : t('experience.details.no')
  const signature = signaturesOk ? t('experience.details.yes') : t('experience.details.no')

  return (
    <div className="experience-pv-wrapper">
      <div className="public-view">
        <PublicHeadingBlock title={t('experience.header.title')} name={name} />

        <div className="public-view__card-wrapper wrapper">
          <div className="experience-pv-wrapper__card">
            <CustomPicture className="experience-pv-wrapper__card__image" file={photo.file} />

            <div className="public-view__card-inner-wrapper">
              <PublicCardBlockInfo
                withBg
                talent={talent}
                duration={getHumanDuration(duration)}
                locations={locations}
                hideAddressFromPubView={hideAddressFromPubView}
                pricePerParticipant={pricePerParticipant}
              />

              <PublicCardBlockActions id={id} isFollowed={isFollowed} video={video} toggleModal={toggleVideoModal} />
            </div>
          </div>


          <VideoModal file={video?.file} isModalOpen={isVideoModalOpen} closeModal={closeVideoModal} />
        </div>

        {isFixedTypeOfDate && (
          <FixedTypesOfDate experience={experience} />
        )}

        {isAllowAficionadoToProposeDayTime && (
          <AllowAficionadoToPropose experience={experience} />
        )}

        {isAnyAvailableDayTimeInTalentCalendar && (
          <AnyAvailableDayTime experience={experience} />
        )}

        {(isRecurringDayTimeOption || isUpTo5DayTimeOptions) && (
          <TimeWindow experience={experience}  />
        )}

        <EntourageRequired type={entourageType} />

        <PublicDetailsBlock>
          {experience.commercialUseOfExperiencefootage ?
            <PublicDetailsItemBlock className="two-rows">
              <div>
                <h6>{t('vmcu.information.content.heading')}</h6>
                <ExperienceLegalTermsList commercialTerms={experience} />
              </div>
            </PublicDetailsItemBlock> :
            <PublicDetailsItemBlock className="two-rows">
              <div>
                <h6>{t('vmcu.information.not-commercial')}</h6>
              </div>
            </PublicDetailsItemBlock>
          }

          <PublicDetailsItemBlock>
            <div>
              <h6>{t('experience.details.pictures')}</h6>
              <p>{takingPictures}</p>
            </div>
          </PublicDetailsItemBlock>

          {isEmpty([...defaultTaboos, ...customTaboos]) ? null : (
            <PublicDetailsItemBlock>
              <div>
                <h6>{t('experience.details.taboo')}</h6>
                {compact(defaultTaboos).map(value => <p key={value}>{t(value)}</p>)}
                {compact(customTaboos).map(value => <p key={value}>{value}</p>)}
              </div>
            </PublicDetailsItemBlock>
          )}

          <PublicDetailsItemBlock>
            <div>
              <h6>{t('experience.details.signatures')}</h6>
              <p>{signature}</p>
            </div>
          </PublicDetailsItemBlock>

          {otherInformation &&
            <PublicDetailsItemBlock className="two-rows">
              <div>
                <h6>{t('experience.details.other-info')}</h6>
                <p>{otherInformation}</p>
              </div>
            </PublicDetailsItemBlock>
          }

          {isEmpty(whichCharities) ? null : (
            <PublicDetailsItemBlock className="two-rows">
              <div>
                <h6>{t('experience.details.charity-beneficiary')}</h6>
                {compact(whichCharities).map(charity => (
                  <p key={charity.id}>{getCharityLabel(charity)}</p>
                ))}
              </div>
            </PublicDetailsItemBlock>
          )}
        </PublicDetailsBlock>
      </div>
    </div>
  )
}

export default ExperiencePublic
