import React from 'react';
import PropTypes from 'prop-types'

import { Row } from '../Template';
import Textarea from './Textarea';

const RowTextarea = ({ label, ...props }) => {
  return (
    <Row
      nameBlock={label}
      isRequired={props?.isRequired}
      component={<Textarea {...props} />}
    />
  );
};

RowTextarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  shouldShowError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  height: PropTypes.number,
  max: PropTypes.number,
}

export default RowTextarea;
