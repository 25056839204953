import { isDate } from 'date-fns'

export function setMinutesToDate(date, dateWithMinutes) {
  const _date = new Date(date)
  const _dateWithMinutes = new Date(dateWithMinutes)

  if (isDate(_date) && isDate(_dateWithMinutes)) {
    const _hours = _dateWithMinutes.getHours()
    const _minutes = _dateWithMinutes.getMinutes()
    _date.setHours(_hours, _minutes)
    return _date
  }
  return undefined
}

export function getDate(date) {
  const _date = date ? new Date(date) : ''
  return isDate(_date) ? _date : ''
}

export function isTrue(value) {
  return value === 'true'
}

export function getTimeOfDayFromSeconds(seconds) {
  const hours = Math.floor(seconds / (60 * 60))
  const min = Math.floor((seconds - hours * 60 * 60) / 60)
  const sec = seconds % 60

  const date = new Date()
  date.setHours(hours, min, sec)

  return date
}

export function getSeconds(date) {
  const hours = +new Date(date).getHours()
  const minutes = +new Date(date).getMinutes()
  const secondsInHours = 60 * 60 * hours
  const secondsInMinutes = 60 * minutes

  return secondsInHours + secondsInMinutes
}
