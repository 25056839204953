import React from 'react'

import Icon from 'assets/icons/Icon'
import { getSaveFullName } from 'utils/user'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useTrack from '../../hooks/useTrack'
import usePublications from '../../hooks/usePublications'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting'
import { useVideoState } from '../../hooks/useVideoState'


export default function MainParticipantInfo({ participant, children }) {
  const t = useGetTranslate()
  const { previewInfo } = useVideoState()

  const publications = usePublications(participant)
  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video')
  const screenSharePublication = publications.find(p => p.trackName.includes('screen'))

  const videoTrack = useTrack(screenSharePublication || videoPublication)
  const isVideoEnabled = Boolean(videoTrack)

  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack)
  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  return (
    <div className='main-participant'>
      <div className='main-participant__info'>
        <p className='main-participant__title'>{t('video-chat.room.header')}</p>
        <p className='main-participant__identity'>{getSaveFullName(previewInfo?.remoteParticipant)}</p>
      </div>

      {(!isVideoEnabled || isVideoSwitchedOff) && (
        <div className="main-participant__avatar">
          <Icon.DefaultAvatar />
        </div>
      )}

      {isParticipantReconnecting && (
        <div className='main-participant__reconnecting'>
          {t('video-chat.participant.reconnecting')}
        </div>
      )}

      {children}
    </div>
  )
}
