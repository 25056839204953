import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

/* States for Experience Recurring list */
export const useExperienceOfferingDetailsHeader = createUseGlobalState('experienceOfferingDetailsHeader')
export const useSetExperienceOfferingDetailsHeader = createUseSetGlobalState('experienceOfferingDetailsHeader')

export const useExperienceOfferingDetailsTableFilters = createUseGlobalState('experienceOfferingDetailsFilters')
export const useSetExperienceOfferingDetailsTableFilters = createUseSetGlobalState('experienceOfferingDetailsFilters')

export const useExperienceOfferingDetailsTable = createUseGlobalState('experienceOfferingDetailsTable')
export const useSetExperienceOfferingDetailsTable = createUseSetGlobalState('experienceOfferingDetailsTable')
/* States for Recurring list */
