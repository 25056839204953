import range from 'lodash/range'
import get from 'lodash/get'
import { format, differenceInMinutes } from 'date-fns'
import { fromDToM } from 'utils/convertTime'

const ONE_DAY = fromDToM(1)

const STEP = 15

const DATE_FORMAT = 'hh:mm a'

function isCrossing(leftTime, rightTime) {
  if (!leftTime || !rightTime) return false
  const res = differenceInMinutes(new Date(leftTime), new Date(rightTime))
  return res < 0
}

export const getDateRangeOptions = options => {
  const minTime = get(options, 'minTime')
  const maxTime = get(options, 'maxTime')

  return range(0, ONE_DAY, STEP).map(minutes => {
    const date = new Date()
    date.setHours(Math.floor(minutes / 60), minutes % 60, 0)

    return {
      value: date.toUTCString(),
      label: format(date, DATE_FORMAT),
      disabled: isCrossing(date, minTime) || isCrossing(maxTime, date),
    }
  })
}
