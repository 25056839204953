import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Button from '_legacy/components/Button';
import { Row } from '_legacy/common/Template';

const Submit = ({ onDraft, methods, isLoading, isSubmitDisabled }) => {
  const t = useGetTranslate();

  /* here and above commented due to ui changes (will be uncommented and further developed in phase 2) */
  // const onReview = () => {
  //   alert('This action isn\'t implemented');
  // };

  const isDisabled = isSubmitDisabled !== undefined
    ? isSubmitDisabled
    : isEmpty(methods.formState.dirtyFields)

  return (
    <Row
      component={
        <SubmitWrapper>
          <Button
            text={t('create-experience.form.buttons.publish')}
            typeButton='submit'
            paddingHorizontal='45'
            paddingVertical='14'
            disabled={isDisabled || isLoading}
          />
          {/* here and above commented due to ui changes (will be uncommented and further developed in phase 2) */}
          {/* <Button
            primary
            text={t('create-experience.form.buttons.preview')}
            typeButton='button'
            handleOnClick={onReview}
            paddingHorizontal='20'
            paddingVertical='14'
          /> */}
          <Button
            primary
            text={t('create-experience.form.buttons.draft')}
            typeButton='button'
            handleOnClick={onDraft}
            paddingHorizontal='20'
            paddingVertical='14'
          />
        </SubmitWrapper>
      }
    />
  );
};

Submit.propTypes = {
  onDraft: PropTypes.func,
  isLoading: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
}

const SubmitWrapper = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 24px;
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    grid-auto-flow: row;
    width: 100%;
  }
`;

export default Submit;
