import { _get, _patch, _post } from 'api/utils'

export const getLiveInPersonOccurrence = ({ occurrenceId }) =>
  _get(`/offers/lessons/live-in-person-lesson/occurrences/${occurrenceId}`, {
    needAuthorization: true,
  })

export const updateLiveInPersonOccurrenceTime = ({ data, occurrenceId }) =>
  _patch(`/offers/lessons/live-in-person-lesson/occurrences/${occurrenceId}/update-time`, {
    body: data,
    needAuthorization: true,
  })

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })
