import React from 'react'

import Avatar from '_legacy/components/Avatar'
import EntourageLogo from '../EntourageLogo'

const AvatarWithEntourageType = ({ entourageType, entourageClassName, entourageSize, ...props }) => {
  return (
    <div className='avatar-with-entourage-type'>
      <EntourageLogo type={entourageType} className={entourageClassName} size={entourageSize} />
      <Avatar {...props} />
    </div>
  )
}

export default AvatarWithEntourageType
