import React from 'react'
import ModalPortal from 'portals/ModalPortal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'
import FacebookIcon from 'mdi-react/FacebookIcon'
import TwitterIcon from 'mdi-react/TwitterIcon'
import GmailIcon from 'mdi-react/GmailIcon'
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'
import { ReactComponent as ShareIcon } from 'assets/share-icon.svg'

import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import Button from '_legacy/components/Button'

const VideoMessageBookingDownload = ({ media }) => {
  const t = useGetTranslate()
  const [showSharePopup, setShowSharePopup] = React.useState(false)
  const videoUrl = media?.fullUrl
  const gmailComposeURL = `https://mail.google.com/mail/?view=cm&fs=1&to=&su=${t(
    'social-media.gmail.gmail-subject'
  )}&body=${videoUrl}`
  const facebookShareURL = `https://facebook.com/sharer.php?u=${videoUrl}`
  const twitterShareURL = `https://twitter.com/share?url=${videoUrl}`
  const handleShareClick = () => {
    setShowSharePopup(true)
  }
  const copyToClipboard = () => {
    const input = document.createElement('input')
    input.value = videoUrl
    document.body.appendChild(input)
    input.select()
    document.execCommand('copy')
    document.body.removeChild(input)
  }
  const handleCloseSharePopup = () => {
    setShowSharePopup(false)
  }
  const handleDownload = () => window.open(media?.fullUrl, '_blank')

  return (
    <div className="od__answer-grid mb-24">
      <FilePreview file={media} className="od__answer-media" />
      <div className="od__column">
        <h6 className="od--main-text mb-12 vm--column-text">{t('booking-details.download.video-message.text')}</h6>
        <div className="vm--button-wrapper">
          <Button
            primary
            stretch
            text={t('booking-details.share-video.button')}
            typeButton="button"
            handleOnClick={handleShareClick}
            paddingVertical={9}
            fontSize="0.75"
            iconComponent={<ShareIcon />}
          />
          <Button
            stretch
            text={t('booking-details.share.download-button')}
            typeButton="button"
            handleOnClick={handleDownload}
            paddingVertical={4}
            fontSize="0.75"
            className="vm-download-button"
            iconComponent={<DownloadIcon />}
          />
        </div>
      </div>
      {showSharePopup && (
        <ModalPortal isOpen onClose={handleCloseSharePopup} showCloseButton>
          <div className="selectJoinToTalentModal">
            <h2 className="selectJoinToTalentModal__title joinToTalent-modal__title">
              {t('booking-details.copy-link-header')}
            </h2>
            <form className="joinToTalent-link__form">
              <div className="joinToTalent-link__link">
                <span className="joinToTalent-link__label share-label">{t('booking-details.copy-link-message')}</span>
                <input
                  name="link"
                  className="joinToTalent-link__area share-label"
                  value={videoUrl}
                  autoComplete="nope"
                  readOnly
                />
                <Icon.Copy className="joinToTalent-link__icon share-label" />
              </div>
              <Button
                handleOnClick={copyToClipboard}
                text={t('join-to-talent.link.button.copy')}
                classBtn="joinToTalent-link__button joinToTalent__submit share-label"
                fontSize={0.75}
              />
            </form>
            <div className="joinToTalent-link__label share-label">{'Or share it through'}</div>
            <div className="social-buttons ">
              <a href={facebookShareURL} target="_blank" rel="noopener noreferrer">
                <Button
                  className="shareVideoModel"
                  handleOnClick={copyToClipboard}
                  text={
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                      <FacebookIcon style={{ color: 'white' }} size={20} />
                      <span style={{ marginLeft: '5px' }}>{t('social-media.facebook')}</span>
                    </span>
                  }
                  classBtn="joinToTalent-link__button joinToTalent__submit"
                  fontSize={0.75}
                />
              </a>

              <a href={twitterShareURL} target="_blank" rel="noopener noreferrer">
                <Button
                  className="shareVideoModel"
                  handleOnClick={copyToClipboard}
                  text={
                    <>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <TwitterIcon style={{ color: 'white' }} size={20} />
                        <span style={{ marginLeft: '5px' }}>{t('social-media.twitter')}</span>
                      </span>
                    </>
                  }
                  classBtn="joinToTalent-link__button joinToTalent__submit"
                  fontSize={0.75}
                />
              </a>
              <a href={gmailComposeURL} target="_blank" rel="noopener noreferrer">
                <Button
                  className="shareVideoModel"
                  handleOnClick={copyToClipboard}
                  text={
                    <>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <GmailIcon style={{ color: 'white' }} size={20} />
                        <span style={{ marginLeft: '5px' }}>{t('social-media.gmail.gmail')}</span>
                      </span>
                    </>
                  }
                  classBtn="joinToTalent-link__button joinToTalent__submit"
                  fontSize={0.75}
                />
              </a>
            </div>
          </div>
        </ModalPortal>
      )}
    </div>
  )
}

export default VideoMessageBookingDownload
