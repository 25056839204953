import styled from 'styled-components';

import { StyledRow } from '_legacy/containers/PromoCodes/components/Custom/rowStyled';

export const Loader = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
`

export const StyledWrapper = styled.div`
  width: 920px;
  border-radius: 8px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 991px) {
    width: 700px;
  }
  @media screen and (max-width: 767px) {
    width: 550px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
  }
`

export const StyledContent = styled.div`
  margin: 16px 0;
  padding-bottom: 60px;
`

export const StyledHeader = styled.div`
  background-image: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
  padding: 10px 0;
  @media screen and (max-width: 575px) {
    background-image: none;
  }
  h4 {
    font-weight: 900;
    font-family: 'Playfair Display', sans-serif;
    line-height: 1.25;
    text-align: center;
    font-size: 28px;
    color: ${({ theme }) => theme.colors.white};
    @media screen and (max-width: 575px) {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
`

export const StyledList = styled.div`
  height: 320px;
  overflow-y: scroll;
`

export const StyledNav = styled.div`
  display: grid;
  grid-template-columns: 1fr .3fr;
  align-items: center;
  grid-column-gap: 16px;
  justify-content: space-between;
  padding: 0 24px;
  @media screen and (max-width: 991px) {
    grid-template-columns: 1fr .7fr;
    padding: 0 12px;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 1fr;
    grid-row-gap: 12px;
    margin-bottom: 14px;
  }
  .filter {
    &__input {
      max-width: 100%;
      margin-right: 16px;
      .custom-input {
        padding: 6px 48px 6px 18px;
      }
      .icon {
        height: 40px;
      }
    }
    &__select {
      max-width: 100%;
      margin-right: 0;
      .react-dropdown-select {
        padding: 6px 18px;
        border-radius: 4px;
      }
      .react-dropdown-select-dropdown {
        width: 100%;
      }
    }
  }
`

export const StyledPanel = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  padding: 20px 24px;
  background-color: ${({ theme }) => theme.colors.white};
  @media screen and (max-width: 767px) {
    padding: 12px 14px;
  }
`

export const StyledSelected = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.blue};
  span {
    font-weight: 700;
  }
`

export const StyledButtons = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 14px;
`

export const StyledHeaderRow = styled(StyledRow)`
  padding: 26px;
  @media screen and (max-width: 767px) {
    padding: 14px;
  }
  .text {
    font-weight: 700;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.grey};
    &--price {
      text-align: right;
    }
  }
`
