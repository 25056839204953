import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import DefaultLightAvatar from 'assets/images/icon/defaultAvatar.svg'
import DefaultDarkAvatar from 'assets/images/icon/publicViewDefaultAvatar.svg'
import CustomPicture from '_legacy/common/CustomPicture'

const withDefaultFullUrl = (file = {}, isDark) => ({
  ...file,
  fullUrl: file?.fullUrl || isDark ? DefaultDarkAvatar : DefaultLightAvatar
})

const Avatar = React.memo(({ style, file, size, alt, className, isDark }) => {
  return (
    <CustomPicture
      alt={alt}
      style={{ ...style, width: size, height: size }}
      className={cx('avatar', className)}
      file={withDefaultFullUrl(file, isDark)}
    />
  )
})

Avatar.defaultProps = {
  alt: '',
  isDark: false
}

Avatar.propTypes = {
  file: PropTypes.object,
  size: PropTypes.number,
  alt: PropTypes.string,
  style: PropTypes.string,
  className: PropTypes.string,
  isDark: PropTypes.bool,
}

export default Avatar
