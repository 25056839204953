import get from 'lodash/get'
import { add } from 'date-fns';
import { types } from 'shared';

export default (values) => {
  const isApplyToAllOffers = get(values, 'applyToAllOffers', null) === types.ApplicableOfferings.ALL
  const expireDate = get(values, 'expiresAt', null)
  const tomorrowDate = add(new Date(expireDate),{ days: 1 })

  return ({
    name: get(values, 'name', null),
    code: get(values, 'code', '').toUpperCase(),
    discountType: get(values, 'discountType', null),
    discountAmount: Number(get(values, 'discountAmount', null)),
    applyToAllOffers: isApplyToAllOffers,
    applicableOfferIds: !isApplyToAllOffers ? get(values, 'applicableOfferIds', []) : undefined,
    expiresAt: expireDate ? tomorrowDate : undefined,
    limitOfUsers: get(values, 'limitOfUsers.value', null),
    type: types.PromoCodeType.TALENT_OFFERINGS,
  })
}
