import { types } from 'shared'
const { DIGITAL_DELIVERY } = types.talent.offers.readyMadeEvent.DeliveryType

export default {
  productName: '',
  productPrice: '',
  productDescription: '',
  media: '',
  productUpload: [],
  productReceptionInstructions: '',
  deliveryType: DIGITAL_DELIVERY,
}
