import React from 'react'

import { useCurrentPage } from 'store/hooks/globalState/useUtils'

import { sanitizeFilterFields } from '../utils'

export default function SelectCleaner ({ fields, getFiltersFn, setFiltersFn }) {
  const clearSelected = setFiltersFn(prev => ({ ...prev, selected: [] }))
  const page = useCurrentPage()
  const filtersOptions = getFiltersFn(current => sanitizeFilterFields({ current, fields }), [fields])

  React.useEffect(() => {
    clearSelected()
  }, [filtersOptions, page])

  return null
}
