import React from 'react';
import { useFormContext } from 'react-hook-form';

import Taboos from '../../../Form/Taboos/Wrapper';

const TaboosWrapper = () => {
  const { register } = useFormContext();

  return (
    <Taboos
      defaultEntities={[]}
      register={register}
    />
  );
};

export default TaboosWrapper;
