import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import compact from 'lodash/compact';
import castArray from 'lodash/castArray';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { useFileEntityById } from 'store/hooks/globalState/useFileEntites';

import FilePreview from '../../components/Library/FilePreview';
import CloseButton from '_legacy/components/Library/components/SelectedListItem/CloseButton';
import PrimaryLabelCommon from '_legacy/components/Library/components/SelectedListItem/PrimaryLabel';
import MakePrimaryBtn from '_legacy/components/Library/components/SelectedListItem/MakePrimaryBtn';
import SelectedListItemWrapper from '_legacy/components/Library/components/SelectedListItem/SelectedListItemWrapper';

import { FILE_ID } from 'store/hooks/globalState/useFileLibrary';

const SelectedListItem = ({ id, name, primaryName }) => {
  const { setValue, watch } = useFormContext();
  const file = useFileEntityById(id)
  const fileIds = compact(castArray(watch(name, [])));
  const primaryFileId = watch(primaryName);
  const isPrimary = primaryFileId === get(file, FILE_ID);

  const onPrimary = () => {
    setValue(primaryName, file.fileToken, { shouldDirty: true });
  };

  const onRemove = () => {
    const updatedFiles = fileIds.filter(fileId => fileId !== id);
    setValue(name, updatedFiles, { shouldDirty: true, shouldValidate: true });
    if (isPrimary) {
      setValue(primaryName, null);
    }
  };

  return (
    <SelectedListItemContainer>
      <CloseButton onClick={onRemove} />
      <PrimaryLabel isPrimary={isPrimary}>Primary</PrimaryLabel>
      <FilePreview file={file} />
      {!isPrimary && <MakePrimaryBtn onClick={onPrimary}>Make Primary</MakePrimaryBtn>}
    </SelectedListItemContainer>
  );
};
const PrimaryLabel = styled(PrimaryLabelCommon)`
  ${(props) => props.isPrimary && css`
    + ${MakePrimaryBtn} {
        bottom: 0;
        z-index: 0;
        opacity: 0;
      }
  `}
`

const SelectedListItemContainer = styled(SelectedListItemWrapper)`
  &:hover {
    ${CloseButton} {
      display: block;
    }
    
    ${MakePrimaryBtn} {
      display: block;
      bottom: -30px;
      z-index: 1;
      opacity: 1;
    }
  }
  
  @media screen and (max-width: 1024px) {
    ${MakePrimaryBtn} {
      display: block;
      bottom: -30px;
      z-index: 1;
      opacity: 1;
    }
  }
`;

SelectedListItem.propTypes = {
  name: PropTypes.string,
  primaryName: PropTypes.string,
  id: PropTypes.string,
};

export default SelectedListItem;
