import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getTimezoneAbbr } from 'utils/services/timezone'
import { buildWorkingDaysLabel, buildWorkingHoursLabel } from '../../utils'

const WorkingHours = ({ businessHours, timezone, userLang, hour12Format }) => {
  const t = useGetTranslate()
  if (businessHours) {
    const workingDaysLabel = buildWorkingDaysLabel(businessHours.daysOfWeek, userLang)
    const workingHoursLabel = buildWorkingHoursLabel(businessHours.startTime, businessHours.endTime, timezone, hour12Format)
    const label = `${workingDaysLabel} ${workingHoursLabel}`

    return (
      <div className="calendar-working-hours">
        <strong>{t('calendar.working-hours')}</strong>&nbsp;
        {label}
      </div>
    )
  }

  if (timezone) {
    const timezoneAbbr = getTimezoneAbbr(timezone)
    return (
      <div className="calendar-working-hours">
        <strong>{t('calendar.time-zone')}</strong>&nbsp;
        {timezoneAbbr}
      </div>
    )
  }

  return null
}

export default WorkingHours
