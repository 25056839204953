import { OrderStatus } from 'shared'

const {
  UNDER_REVIEW,
  BOOKED,
  APPROVED,
  REJECTED,
  COMPLETED,
  ADMIN_REVIEW,
  CANCELED,
  CLOSED,
} = OrderStatus

const orderStatusTitles = {
  [UNDER_REVIEW]: 'order.status.under-review',
  [BOOKED]: 'order.status.booked',
  [APPROVED]: 'order.status.approved',
  [REJECTED]: 'order.status.rejected',
  [COMPLETED]: 'order.status.completed',
  [ADMIN_REVIEW]: 'order.status.adminReview',
  [CANCELED]: 'order.status.cancelled',
  [CLOSED]: 'order.status.closed',
}

export const getStepTitle = status => orderStatusTitles[status] || ''
