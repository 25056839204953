import styled from 'styled-components';

const SelectedListWrapper = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(96px, 1fr));
  grid-gap: 44px 20px;
  margin-top: 28px;

  @media screen and (max-width: 520px) {
    grid-column-gap: 16px;
  }
`;

export default SelectedListWrapper
