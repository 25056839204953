import { queryNormalizer, slotsServerAdapter } from '../utils/adapters'
import { getUserCalendarSlots as getUserCalendarSlotsApi } from '../api/user-calendar'

export const fetchUserCalendarSlots = async ({ userId, filters }) => {
  const { slots } = await getUserCalendarSlotsApi({
    userId,
    query: { ...queryNormalizer(filters), limit: 1000, sortField: 'startTime' },
  })

  return slotsServerAdapter(slots)
}

