import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export const CustomOption = ({ innerProps, label, data }) => (
  <div {...innerProps}>
    <div className='_menu-item'>
      <div className='_menu-item__image'>
        <img src={data?.image} alt={label}/>
      </div>
      <div className='_menu-item__label'>
        {label}
      </div>
    </div>
  </div>
);

export const SelectedItem = ({ image, label, onRemove }) => (
  <li className='_selected-item'>
    <div className='_selected-item__image'>
      <img alt={label} src={image}/>
    </div>
    <div className='_selected-item__label'>
      <p>{label}</p>
    </div>
    <button className='_selected-item__remove-button' onClick={onRemove} />
  </li>
);

export const NoOptionsMessage = () => {
  const t = useGetTranslate();
  return (
    <div className='_no-option'>
      {t('join-to-talent.no-option')}
    </div>
  );
};

export const Menu = ({ innerProps, children }) => (
  <div {...innerProps} className='_menu'>
    {children}
  </div>
);

export const MenuList = ({ children }) => (
  <div className='_menu-item-list'>
    {children}
  </div>
);


MenuList.propTypes = {
  children: PropTypes.element.isRequired
};

Menu.propTypes = {
  innerProps: PropTypes.object.isRequired,
  children: PropTypes.element.isRequired,
};

SelectedItem.propTypes = {
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
};

CustomOption.propTypes = {
  innerProps: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
};

