import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PublicDetailsItemBlock = ({ className, children }) => {
  return (
    <StyledExperienceDetailsItem className={className}>
      <i className="icon-2" />
      {children}
    </StyledExperienceDetailsItem>
  );
};

export const StyledExperienceDetailsItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  width: 100%;
  &.two-rows {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  i {
    margin-right: 8px;
  }
  div {
    font-family: Montserrat;
    font-weight: 700;
    color: #272835;
    h6 {
      font-weight: bold;
      font-size: 1.25em;
      line-height: 32px;
    }
    p {
      font-family: Montserrat;
      font-weight: 500;
      font-size: 1em;
      line-height: 26px;
      color: #626473;
      margin-top: 16px;
      overflow-wrap: anywhere
    }
  }
`;

PublicDetailsItemBlock.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default PublicDetailsItemBlock;
