import { Topics } from 'shared/dist/src/types/oneDirectionRequests/contactUs';
import _ from 'lodash';

export const topicOptions = (t) => Object.values(Topics).map(topic => ({
  label: t(
    `contact.form.topic.options.${_.replace(
      topic.toLowerCase(),
      new RegExp('_', 'g'),
      '-'
    )}`
  ),
  value: topic,
}));
