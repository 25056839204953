import React from 'react';
import styled from 'styled-components'
import { types } from 'shared'
import propOr from 'lodash/fp/propOr'

import {
  useUser,
  useIsUserHasTalent,
  useUserType,
  useUserFullName,
  useTalentFullName,
} from 'store/hooks/globalState/useUser'
import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState'
import { useFetchConnectedTalents } from 'requests/talent-switcher'
import Icon from 'assets/icons/Icon'
import ProfilePic from 'assets/profile-pick-light.svg'

const { AGENT, MANAGER } = types.UserType

const MobileMenuProfile = ({ setMobileSwitcherActive }) => {
  const isUserHasTalent = useIsUserHasTalent()
  const userImage = useUser(propOr(ProfilePic, 'user.profileImage.fullUrl'))
  const talentImage = useUser(propOr(ProfilePic, 'talent.profileImage.fullUrl'))
  const profileImage = isUserHasTalent ? talentImage : userImage

  const blockFullpage = useSetFullpageBlock((prev, next) => next, [])
  const fetchConnectedTalents = useFetchConnectedTalents()
  const userType = useUserType()
  const fullName = useUserFullName()
  const talentFullName = useTalentFullName()
  const isUserAgent = userType === AGENT || userType === MANAGER

  const onSwitcherClick = () => {
    setMobileSwitcherActive(true)
    blockFullpage(true)
    fetchConnectedTalents()
  }

  return (
    <StyledWrapper>
      <div className="mobile-menu__profile">
        <div className="mobile-menu__profile--image">
          <img src={profileImage} alt={fullName} />
        </div>
        <div className="mobile-menu__profile--info">
          <StyledName>{fullName}</StyledName>
          {isUserAgent && isUserHasTalent ? (
            <StyledName>
              as&nbsp;<StyledNameTalent>{talentFullName}</StyledNameTalent>
            </StyledName>
          ) : null}
          <div>
            <i className="icon-talent" />
            <i className="icon-talent" />
            <i className="icon-talent" />
            <i className="icon-talent" />
            <i className="icon-talent" />
          </div>
        </div>
      </div>
      {isUserAgent ? (
        <Icon.SwitchProfile
          onClick={onSwitcherClick}
          className="switcher-icon"
        />
      ) : null}
    </StyledWrapper>
  )
};

const StyledName = styled.p`
  font-weight: 700;
  font-size: 1.125em;
  line-height: 26px;
  color: #272835;
  padding-bottom: 3px;
`

const StyledNameTalent = styled.p`
  font-weight: 900;
  display: inline-block;
`

const StyledWrapper = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export default MobileMenuProfile;
