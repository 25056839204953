// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import styled from 'styled-components';
import { Trans } from 'store/hooks/globalState/useTranslates';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const CustomLegalTermsList = ({ commercialTerms }) => {
  const t = useGetTranslate();
  const [terms,setTerms]=React.useState([]);

  React.useEffect(()=>{
    if(commercialTerms?.terms)
    {
      setTerms(commercialTerms.terms)
    }
  },[commercialTerms])

  return (
    <StyledLegalTerms>
      <ul className="list">
        <li className="list__item">{
          <Trans
            tKey="vmcu.information.content.valid"
            phs={[
              { ph: '{{months}}', value: commercialTerms.monthOfUse ? commercialTerms.monthOfUse : '3'  },
            ]}
          />}
        </li>
        {commercialTerms?.maxTalentRetakes &&
        <li className="list__item">{
          <Trans
            tKey="vmcu.information.content.re-takes"
            phs={[
              { ph: '{{retakes}}', value: commercialTerms.maxTalentRetakes ? commercialTerms.maxTalentRetakes : '3' },
            ]}
          />}
        </li>}
        <li className="list__item">{
          <Trans
            tKey="vmcu.information.content.formats"
            phs={[
              { ph: '{{formats}}', value: commercialTerms.defaultFormats+','+commercialTerms.customFormats },
            ]}
          />}
        </li>
        <li className="list__item">{
          <Trans
            tKey="vmcu.information.content.customer"
            phs={[
              { ph: '{{comapnyMayAddLogo}}', value: commercialTerms.comapnyMayAddLogo ? 'May' : 'May not' },
            ]}
          />}
        </li>
        {commercialTerms && terms?.length > 0 &&
        terms?.map(item => (
          <li className="list__item" key={item}>{item}</li>
        ))}
        <li className="list__item">
          {t('vmcu.information.content.other')}
        </li>
      </ul>
    </StyledLegalTerms>
  );
};

const StyledLegalTerms = styled.div`
  .list {
    margin-left: 14px;
    list-style-type: disc;

    &__inner {
      margin-top: 12px;
      margin-left: 14px;
      list-style-type: circle;
    }

    &__item {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #626473;
      position: relative;
      //padding-left: 14px;
      &::marker {
        font-size: 14px;
        color: #626473;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }

  .button {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.62;
    color: #272835;
    background: none;
    border: none;
    outline: none;
    padding: 0;
    margin-top: 12px;
    cursor: pointer;
  }
`;

export default CustomLegalTermsList;
