import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '../Template';
import CommonSelect from './Common';

const RowSelect = ({ label, subNameBlock, ...props }) => {
  return (
    <Row
      nameBlock={label}
      subNameBlock={subNameBlock}
      isRequired={props?.isRequired}
      component={<CommonSelect {...props} />}
    />
  );
};

RowSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  rules: PropTypes.object,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  blackTheme: PropTypes.bool,
  className: PropTypes.string,
}

export default RowSelect;
