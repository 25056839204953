import React from 'react';
import PropTypes from 'prop-types';

const BasicDesktopOverlay = ({ children }) => {
  return <div className="tbv-slider-slide-overlay">{children}</div>;
};

BasicDesktopOverlay.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default BasicDesktopOverlay;
