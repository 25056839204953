import React from 'react'
import prop from 'lodash/fp/prop'

import { useUser } from 'store/hooks/globalState/useUser'
import { useAddNotification } from 'store/hooks/globalState/useNotificationsState'

import { Notifications } from 'utils/services/notifications'


const withNotificationsHub = Component => props => {
  const userId = useUser(prop('user.id'))
  const talentId = useUser(prop('talent.id'))

  const addNotification = useAddNotification()

  const NotificationService = new Notifications()

  React.useEffect(() => {
    if (userId) {
      NotificationService.connect()
        .join(talentId || userId, userId)
        .listen(addNotification)
    } else {
      NotificationService.disconnect()
    }
  }, [userId, talentId])

  return <Component {...props} />
}

export default withNotificationsHub
