import React from 'react'
import { Link } from 'react-router-dom'

import Button from '_legacy/components/Button'

import paginateLinkBuilder from 'utils/paginateLinkBuilder'

export default React.memo(function PagesList ({ pagesCount, page }) {
  let pagesList = new Array(pagesCount).fill(null).map((item, i) => i + 1)

  if (pagesCount > 7) {
    if (page <= 3) {
      pagesList = pagesList.slice(0, 4)
      pagesList.push('...')
      pagesList.push(pagesCount)
    } else if (page > 3 && page < pagesCount - 2) {
      pagesList = []
      pagesList.push(1)
      pagesList.push('...')
      pagesList.push(page - 1, page, page + 1)
      pagesList.push('...')
      pagesList.push(pagesCount)
    } else if (page >= pagesCount - 3) {
      pagesList = pagesList.slice(pagesCount - 4)
      pagesList.unshift('...')
      pagesList.unshift(1)
    }
  }

  pagesList = pagesList.map((item, i) => {
    if (item === '...') {
      return (
        <div
          key={i}
          className='button-wrapper'
        >
          ...
        </div>
      )
    }

    if (item === page) {
      return (
        <Button
          key={i}
          classBtn='active-page'
          text={item.toString()}
        />
      )
    }

    return (
      <Link
        key={i}
        className='button-wrapper'
        to={paginateLinkBuilder(item)}
      >
        {item}
      </Link>
    )
  })

  return pagesList
})
