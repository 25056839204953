import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import Checkbox from './Checkbox';

const FixedCheckbox = ({ name, entities, register }) => {
  const t = useGetTranslate()
  return (
    <div className="multi-checkbox">
      {entities.map(entity => (
        <Checkbox
          name={name}
          value={entity}
          defaultChecked
          label={t(entity)}
          register={register}
          key={entity}
        />
      ))}
    </div>
  );
};

FixedCheckbox.propTypes = {
  name: PropTypes.string,
  entities: PropTypes.array,
  register: PropTypes.func,
};

export default FixedCheckbox;
