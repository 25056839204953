// TODO: refactor component (Formatting, module structure)

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Checkbox as CheckboxComponent } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Checkbox = React.memo(function Phone({ name, className = '' }) {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock=''
      className={className}
      component={
        <CheckboxComponent
          name={name}
          className='checkbox'
          label={<p>{t('request-video-mes-commercial.check-to-delivery')}</p>}
        />
      }
    />
  );
});

Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Checkbox;


