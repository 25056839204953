export default {
  'company': {
    'toEmail': '',
    'name': '',
    'industry': '',
    'productType': '',
    'productName': '',
    'phone': '',
    'phoneDeliverVersion': false,
    'makeTheMessagePublic': 'true'
  },
  'requestor': {
    'name': '',
    'title': '',
    'fromEmail': '',
    'fromEmailDeliverVersion': false,
    'phone': '',
    'phoneDeliverVersion': false
  },
  'message': {
    'productDescription': '',
    'script': '',
    'otherInstructions': ''
  }
}
