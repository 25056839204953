import React from 'react';
import styled from 'styled-components';
import { types } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common/Template';
import MediaBackdrop from '_legacy/components/Library/configs/Media/MediaBackdrop';
import MediaPicture from '_legacy/components/Library/configs/Media/MediaPicture';
import DropzoneBackdrop from './DropzoneBackdrop';
import LibraryWithCrop from '_legacy/components/Library/LibraryWithCrop';

const { PHOTO } = types.file.Types

const Image = () => {
  const t = useGetTranslate();

  const dropZoneProps = {
    accept: PHOTO,
    hintComponent: DropzoneBackdrop
  }
  const cropModalProps = {
    cropShape: 'rect',
    header: 'Crop Image',
    aspect: 16/9
  }
  const components = {
    customPicture: MediaPicture,
    customBackdrop: MediaBackdrop
  }
  const libraryModalProps = {
    enableTypes: PHOTO
  }

  const rules = {
    required: 'shared.words.required'
  }

  return (
    <StyledImage
      nameBlock={t('create-experience.form.image.label')}
      component={
        <LibraryWithCrop
          name="photo"
          rules={rules}
          components={components}
          dropZoneProps={dropZoneProps}
          cropModalProps={cropModalProps}
          libraryModalProps={libraryModalProps}
        />
      }
    />
  );
};

const StyledImage = styled(Row)`
  object-fit: contain;
  .single-select__library-button {
    padding: 0;
    width: 100%;
    height: 320px;
  }
  
  .icon-camera {
    margin-top: 0;
    padding-top: 12px;
  }
`;

export default Image;
