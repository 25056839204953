import React from 'react'
import { pipe, prop, isEqual, isEmpty } from 'lodash/fp'
import { Link, useParams } from 'react-router-dom'

import * as r from '_legacy/constants/routes'
import { getFullName, getUserId, getId, getEntourageType, getShouldShowRegisterTalentLink } from 'utils/user'

import Icon from 'assets/icons/Icon'
import Rating from '_legacy/components/Rating'
import ReviewSlider from './components/ReviewSlider'
import SocialList from '_legacy/components/SocialList'
import PageLoader from '_legacy/common/Template/PageLoader'

import { useStartChat } from 'requests/chats'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchAficionadoProfile } from './state/request'
import { useAficionadoProfile, useSetAficionadoProfile } from './state/store'
import AvatarWithEntourageType from 'components/AvatarWithEntourageType'

const AficionadoProfile = () => {
  const t = useGetTranslate()
  const { plaqueNickname } = useParams()
  const aficionado = useAficionadoProfile(prop('aficionado'))
  const startChat = useStartChat(getId(aficionado))
  const fetchAficionadoProfile = useFetchAficionadoProfile()
  const clearAficionadoProfile = useSetAficionadoProfile(() => null)
  const isSameUser = useUser(pipe(getUserId, isEqual(getId(aficionado))), [aficionado])
  const shouldShowRegisterTalentLink = useUser(getShouldShowRegisterTalentLink)

  React.useEffect(fetchAficionadoProfile, [plaqueNickname])
  React.useEffect(() => () => clearAficionadoProfile(), [])

  if (!aficionado) {
    return <PageLoader />
  }

  return (
    <div className="aficionado-profile wrapper content-wrapper">
      <div className="aficionado-profile__info">
        <div className="aficionado-profile__avatar-wrapper">
          <AvatarWithEntourageType
            file={aficionado?.profileImage?.file}
            entourageType={getEntourageType(aficionado)}
            entourageSize='lg'
          />
          {isSameUser ? (
            <>
              <Link to={`/${r.ACCOUNT}/${r.EDIT_PROFILE}`} className="aficionado-profile__edit-btn">
                {t('aficionado-profile.edit-profile')}
              </Link>
              {shouldShowRegisterTalentLink && (
                <Link to={`/${r.REGISTER}/${r.TALENT}`} className="aficionado-profile__edit-btn">
                  {t('aficionado-profile.create-talent-profile')}
                </Link>
              )}
            </>
          ) : (
            <button onClick={startChat} type='button' className="aficionado-profile__message-btn">
              {t('aficionado-profile.message')}
            </button>
          )}
        </div>

        <div className="aficionado-profile__main">
          <h3 className="aficionado-profile__full-name">{getFullName(aficionado)}</h3>
          {!!aficionado?.averageRating && (
            <Rating rating={aficionado?.averageRating} leftColor="#fff" rightColor="#626473" />
          )}
          {!!aficionado?.socialMedia && <SocialList socialList={aficionado?.socialMedia} />}
          <Icon.Separator className="aficionado-profile__separator" />
          {isEmpty(aficionado?.socialMedia) && !aficionado?.averageRating && (
            <p className="aficionado-profile__no-information">{t('aficionado-profile.no-information')}</p>
          )}
          {aficionado?.backgroundReports && (
            <Link to="/" className="aficionado-profile__background-reports">
              <Icon.BackgroundReports fill="#fff" /> {t('aficionado-profile.view-background-reports')}
            </Link>
          )}
          <ReviewSlider />
        </div>
      </div>

      {/* todo: wait for slider */}
      {/*<div className="aficionado-profile__photos-videos">*/}
      {/*  <h3>{t('aficionado-profile.photos-videos')}</h3>*/}
      {/*</div>*/}
    </div>
  )
}

export default AficionadoProfile
