import React from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import cx from 'classnames';
import get from 'lodash/get';

import ErrorMessage from '../../ErrorMessages/ErrorMessage';

const MaskInput = ({
  rules,
  name,
  value,
  label,
  labelContainerClassName,
  labelClassName,
  containerClassName,
  inputClassName,
  component,
  defaultValue,
  suffixComponent: ComponentSuffix,
  ...props
}) => {
  const { errors, control } = useFormContext();
  const error = get(errors, name);
  const containerClasses = cx('input-container', containerClassName);
  const labelContainerClasses = cx('input-label-container', labelContainerClassName);
  const labelClasses = cx('input-label', labelClassName);
  const inputClasses = cx('input input-default input-mask', inputClassName, { error: !!error });

  return (
    <div className={containerClasses}>
      <label>
        {label && (
          <div className={labelContainerClasses}>
            {label && <span className={labelClasses}>{label}</span>}
            {component}
          </div>
        )}
        <Controller
          name={name}
          rules={rules}
          defaultValue={defaultValue}
          control={control}
          render={({ onChange, value }) => (
            <NumberFormat
              value={value}
              className={inputClasses}
              thousandSeparator
              decimalSeparator={false}
              autoComplete="nope"
              onValueChange={({ value }) => onChange(value)}
              {...props}
            />
          )}
        />
        {value && ComponentSuffix}
      </label>
      {error && <ErrorMessage message={error.message}/>}
    </div>
  );
};

MaskInput.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  error: PropTypes.object,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  value: PropTypes.string,
  suffixComponent: PropTypes.oneOfType([
    PropTypes.arrayOf([PropTypes.node]),
    PropTypes.node,
    PropTypes.func,
  ]),
  label: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  labelClasses: PropTypes.string,
  component: PropTypes.PropTypes.oneOfType([
    PropTypes.arrayOf([PropTypes.node]),
    PropTypes.node,
    PropTypes.func,
  ]),
  defaultValue: PropTypes.object,
};

export default MaskInput;
