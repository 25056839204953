import get from 'lodash/get'
import { TaxpayerStatus } from 'shared'

import { getBrowserTimeZone } from 'utils/services/timezone/'
import { formatDateOfBirth } from 'utils/forms/adapters/dateOfBirth'

import {
  submitAddressAdapter,
  submitLicensesAdapter,
} from 'utils/forms/adapters'
import { getLibraryData } from 'utils/storage'

export const submitAdapter = (data) => {
  const libraryData = getLibraryData()
  const fileLibraryFilesTokens = get(libraryData, 'files', [])

  return {
    type: get(data, 'type'),
    firstName: get(data, 'firstName'),
    lastName: get(data, 'lastName'),
    applicantId: get(data, 'applicantId'),
    identityIsVerified: get(data, 'identityIsVerified'),
    nickname: get(data, 'nickname'),
    email: get(data, 'email'),
    backupEmail: get(data, 'backupEmail') || undefined,
    password: get(data, 'password'),
    profileImage: get(data, 'profileImage'),
    licenses: submitLicensesAdapter(get(data, 'licenses')),
    notificationPreferences: {
      email: get(data, 'notificationPreferences.email'),
      sms: get(data, 'notificationPreferences.sms'),
    },
    phone: get(data, 'phone'),
    verifyPhoneKey: get(data, 'verifyPhoneKey'),
    socialSecurityOrTIN: get(data, 'socialSecurityOrTIN'),
    dateOfBirth: formatDateOfBirth(get(data, 'dateOfBirth')),
    sign: get(data, 'sign'),
    fileLibraryFilesTokens: fileLibraryFilesTokens,
    timeZoneId: getBrowserTimeZone(),
    taxpayerStatus: get(data, 'taxpayerStatus') ?? TaxpayerStatus.US,
    ...submitAddressAdapter(data),
  }
};
