import React from 'react'

import { bookingsTypes } from '_legacy/configs/table/manageOrders'

import CommonOffering from '_legacy/components/Table/table-components/types/common/Offering'

const Type = React.memo(({ isLoading, id, occurrence }) => (
  <CommonOffering
    isLoading={isLoading}
    id={id}
    type={occurrence?.type}
    title={occurrence?.title}
    config={bookingsTypes[occurrence?.type]}
  />
))

export default Type
