import React from 'react'
import cx from 'classnames'

const DashButton = ({ text, className, ...props }) => {
  const classes = cx('dash-button', className)

  return (
    <button className={classes} type='button' {...props}>
      {text}
    </button>
  )
}

export default DashButton
