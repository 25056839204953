import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'


import SearchList from './SearchList'
import ListHeader from './ListHeader'
import MobileList from './MobileList';
import TableLoader from '_legacy/components/Table/table-components/TableLoader'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import '_legacy/components/Search/List/index.scss'

const List = ({ fetchListFn, getListFn, getFiltersFn, setFiltersFn, EmptyList, fields }) => {
  const isMobile = useWindowDimensions(prop('width')) <= 575
  return (
    <WrapperS>
      <TableLoader
        shouldHandleUser={false}
        isMobile={isMobile}
        fetchTableFn={fetchListFn}
        fields={fields}
        getFiltersFn={getFiltersFn}
      />
      <ListHeader
        getListFn={getListFn}
        getFn={getFiltersFn}
        setFn={setFiltersFn}
      />
      {isMobile
        ? <MobileList
          getFn={getFiltersFn}
          setFn={setFiltersFn}
          getListFn={getListFn}
          EmptyList={EmptyList}
          fetchTableFn={fetchListFn}
          fields={fields}
          isMobile={isMobile}
        />
        : <SearchList
          getFiltersFn={getFiltersFn}
          getListFn={getListFn}
          EmptyList={EmptyList}
        />
      }
    </WrapperS>
  )
}

const WrapperS = styled.div`
  padding-bottom: 50px;
`

List.propTypes = {
  getListFn: PropTypes.func,
  getFiltersFn: PropTypes.func,
  fetchListFn: PropTypes.func,
  setFiltersFn: PropTypes.func,
  EmptyList: PropTypes.element,
  fields: PropTypes.array,
}

export default List
