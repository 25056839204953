import React from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import get from 'lodash/get'

const EmptyIcon = () => <div />

const SelectOption = props => {
  const Icon = get(props, 'data.data.icon') || EmptyIcon
  const count = get(props, 'data.data.count')
  return (
    <StyledOption {...props} key={JSON.stringify(props.children)}>
      <Icon />
      <span className="option-text">{props.children}</span>
      {count && <span className='option-count'>{count}</span>}
    </StyledOption>
  )
}

const StyledOption = styled(components.Option)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;

  svg {
    margin-right: 10px;
  }

  .option-text {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;

    color: ${({ theme }) => theme.colors.grey};
  }

  .option-count {
    position: absolute;
    top: 50%;
    right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 24px;
    background: #e9eaf0;
    border-radius: 4px;
    transform: translateY(-50%)
  }
`

export default SelectOption
