import React from 'react'
import cx from 'classnames'
import { prop } from 'lodash/fp'

import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { getShortMonthDate } from 'utils/date'

import Icon from 'assets/icons/Icon'

const OrderExpiredNotification = ({ id, createdAt, isRead, isOverdue, isTable, isMenu, options }) => {
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  return (
    <div className={containerClasses} id={id}>
      <div className="notification-item__icon">
        <Icon.RedBell id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans
          tKey="notification-menu.item.message.expired"
          phs={[{ ph: '{{eventName}}', value: prop('eventName', options) }]}
        />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>
    </div>
  )
}

export default OrderExpiredNotification
