import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import cond from 'lodash/cond';
import matches from 'lodash/matches';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')] // todo: refactor with theme
]);

const StyledTextarea = styled.textarea`
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: rgba(255,255,255, 0.1);
  border-radius: 4px;
  border: 2px solid ${getBorderColor};
  padding: 17px;
  resize: none;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif; // todo: refactor with theme
  line-height: normal;

  &:focus, &:hover {
    border-color: #000;
  }
  
  ${({ height = 150 }) => css`
    height: ${height}px;
  `}
  
  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
    pointer-events: none;
    background-color: #E9EAF0;
    
    &:hover {
      border-color: #BABBCD;
    }
  `}

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type=number] {
    -moz-appearance: textfield;
  }
`;

StyledTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  shouldShowError: PropTypes.bool,
};

export default StyledTextarea;
