import React from 'react'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Backdrop = ({ maxSize }) => {
  const t = useGetTranslate()

  return (
    <div className="button-backdrop-wrapper">
      <i className="icon-frame icon-camera" />
      <strong>{t('video-message.form.media.placeholder.title')}</strong>
      <p>
        <Trans tKey="video-message.form.media.placeholder.text" phs={[{ ph: '{{max-size}}', value: maxSize }]} />
      </p>
    </div>
  )
}

export default Backdrop
