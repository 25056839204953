import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

import {
  info as getTalentInvitationInfoApi,
  join as joinTalentApi,
} from 'api/appApi/talent/join';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetTalentInvitation } from 'store/hooks/globalState/talentInvitation';
import { notify } from 'utils/notify';
import { errors } from '../_legacy/containers/Registration/TalentInvitation/error';

export function useFetchTalentInvitation() {
  const t = useGetTranslate();
  const history = useHistory();
  const { link } = useParams();

  const setTalentInvitationData = useSetTalentInvitation((prev, data) => ({ ...prev, data }));

  return React.useCallback(() => {
    getTalentInvitationInfoApi({ talentInvitationLink: link })
      .then(({ linkType }) => {
        setTalentInvitationData(linkType);
      })
      .catch(error => {
        // todo: refactor with own notify or insert notify in root
        setTimeout(() => {
          notify(t(errors[error.name]), 'error');
        }, 10);
        history.push('/');
      });
  }, [setTalentInvitationData]);
}

export function useJoinToTalent() {
  const t = useGetTranslate();
  const history = useHistory();
  const { link } = useParams();

  const setTalentInvitationLoading = useSetTalentInvitation((prev, loading) => ({ ...prev, loading }));

  return React.useCallback((data) => {
    joinTalentApi({ talentInvitationLink: link, data  })
      .then(() => {
        // todo: refactor with own notify
        setTimeout(() => {
          notify(t('register.form.message.success'), 'success');
        }, 10);
        history.push('/');
      });
  }, [setTalentInvitationLoading]);
}
