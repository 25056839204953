import axios from 'axios';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeUserData,
  setTokens,
  clearTokens,
} from '../utils/storage';

let alreadySentRefresh = false;

const logoutUser = () => {
  alreadySentRefresh = false;
  removeUserData();
  clearTokens();
};

const getTokenIsExpired = (token = '') => {
  const data = token.split('.')[1] || '';
  const { exp } = JSON.parse(atob(data));

  return new Date().getTime() > new Date(exp * 1000).getTime();
};

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

let tokenIsRefreshing = false;

export const refreshTokens = async () => {
  alreadySentRefresh = true;
  if (tokenIsRefreshing) {
    return new Promise((resolve) => {
      const interval = setInterval(() => {

        if (!tokenIsRefreshing) {
          resolve();
          clearInterval(interval);
        }
      }, 1);
    });
  }

  tokenIsRefreshing = true;
  removeAccessToken();
  if (getRefreshToken()) {
    try {
      const {
        data: {
          data: {
            accessToken,
            refreshToken,
            twilioAccessToken,
          }
        }
      } = await axios.post('/auth/refresh-tokens', {
        refreshToken: getRefreshToken()
      });

      setTokens({ accessToken, refreshToken, twilioAccessToken });
    } catch {
      logoutUser();
      clearTokens();
    }
  } else {
    logoutUser();
  }

  tokenIsRefreshing = false;
};

export const buildErrorResponse = error => {
  return {
    code: error.response?.status,
    message: error.response?.data?.error?.message || error.message,
    name: error.response?.data?.error?.name,
    context: error.response?.data?.error?.context,
    error,
  }
}

axios.interceptors.request.use(async (config) => {
  if (config.headers.Authorization === true) {
    const token = getAccessToken();

    if (token) {
      try {
        if (getTokenIsExpired(token)) {
          await refreshTokens();
        }
      } catch (e) {
        logoutUser();
      }
    } else {
      await refreshTokens();
    }
    if (getAccessToken()) config.headers.Authorization = `Bearer ${getAccessToken()}`;
  }

  return config;
});

axios.interceptors.response.use(async (response) => {
  return response
}, async (error) => {
  if (error.message === 'FILE_ABORT_ERR') {
    throw buildErrorResponse(error);
  }

  const { response, config } = error;

  if ((response?.status === 401 || response?.status === 403) && !alreadySentRefresh) {
    if (!getRefreshToken()) throw buildErrorResponse(error);

    await refreshTokens();

    if (getAccessToken())
      return axios(config);
    return error;
  } else if (response?.status >= 400) {
    throw buildErrorResponse(error);
  }

  return buildErrorResponse(error);
});


export const _get = async (path, { needAuthorization = false, query, config } = {}) => {
  try {
    return await axios.get(path, {
      params: query,
      headers: {
        Authorization: needAuthorization,
      },
      ...config
    }).then(response => response?.data?.data || true)

  } catch (error) {
    console.warn(error)
    throw error;
  }
};


export const _post = async (path, { needAuthorization = false, query, body, config } = {}) => {
  try {
    const { data: { data } }  = await axios.post(path, body, {
      params: query,
      headers: {
        Authorization: needAuthorization
      },
      ...config
    });

    return data || true;
  } catch (error) {
    console.warn(error)
    throw error;
  }
};

export const _put = async (path, { needAuthorization = false, query, body } = {}) => {
  try {
    const { data: { data } } = await axios.put(path, body, {
      params: query,
      headers: {
        Authorization: needAuthorization
      }
    });

    return data || true;
  } catch (error) {
    console.warn(error)
    throw error;
  }
};

export const _patch = async (path, { needAuthorization = false, query, body } = {}) => {
  try {
    const { data: { data } } = await axios.patch(path, body, {
      params: query,
      headers: {
        Authorization: needAuthorization
      }
    });

    return data || true;
  } catch (error) {
    console.warn(error)
    throw error;
  }
};

export const _delete = async (path, { needAuthorization = false, query } = {}) => {
  try {
    const { data: { data } } = await axios.delete(path, {
      params: query,
      headers: {
        Authorization: needAuthorization
      }
    });

    return data || true;
  } catch (error) {
    console.warn(error)
    throw error;
  }
};
