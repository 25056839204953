import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as BackIcon } from 'assets/arrow-back-icon.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function NavigationHeader ({
  clickHandler, text = ''
}) {
  const history = useHistory()
  const t = useGetTranslate()

  const navigateBack = React.useCallback(() => {
    if (clickHandler) return clickHandler()
    history.goBack()
  }, [clickHandler])

  return (
    <StyledWrapper>
      <BackIcon
        className='back-btn'
        onClick={navigateBack}
      />
      <div className='title'>
        {t(text)}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.ultraLightBlue};
  .back-btn {
    box-sizing: content-box;
    width: 18px;
    height: 18px;
    padding: 20px 20px 20px 16px;
  }
  .title {
    font-family: 'Playfair Display';
    font-size: 23px;
    font-weight: 900;
    line-height: 29px;
  }
`
