import React from 'react'

import TableRow from './TableRow'

export default function TableRowsList ({
  isLoading, tableName, tableConfig, actionsConfig, bulkActionsConfig, rows, components, withRejected
}) {
  const parsedRows = React.useMemo(() => {
    return rows.map((row, i) => {
      return (
        <TableRow
          key={row.id || i}
          isLoading={isLoading}
          tableName={tableName}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          bulkActionsConfig={bulkActionsConfig}
          row={row}
          components={components}
          withRejected={withRejected}
        />
      )
    })
  }, [rows, tableName, tableConfig, actionsConfig])

  return (
    <div className='table-content-wrapper'>
      {parsedRows}
    </div>
  )
}
