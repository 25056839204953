import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import T from 'lodash/fp/T'
import lt from 'lodash/fp/lt'
import __ from 'lodash/fp/__'
import cond from 'lodash/cond'
import constant from 'lodash/constant'

import DesktopFilters from '_legacy/components/Table/Filters/DesktopFilters'
import Controls from '_legacy/components/Search/Filters/Controls'
import MobileFilters from '_legacy/components/Table/Filters/MobileFilters';

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState';

const mandatory = ['filtersConfig', 'getFn', 'setFn']

export default function Filters(props) {
  const { getFn, clearFn, filtersConfig } = props
  const { width } = useWindowDimensions()
  const [isShowMore, setIsShowMore] = React.useState(false)
  const filters = getFn()
  let isValidProps = true

  mandatory.forEach(field => {
    if (!props[field]) {
      console.warn(`<Filters /> missed "${field}"`)
      isValidProps = false
    }
  })

  const handleShowMore = () => setIsShowMore(p => !p)

  if (!isValidProps) return null

  const filteredFilters = React.useMemo(() => {
    return filtersConfig.filter(({ shouldShow }) => shouldShow ? shouldShow(filters) : true)
  }, [filters, filtersConfig])

  const config = React.useMemo(() => {
    return cond([
      [constant(isShowMore), constant(filteredFilters)],
      [lt(__, 991), constant(filteredFilters.slice(0, 2))],
      [lt(__, 1280), constant(filteredFilters.slice(0, 3))],
      [lt(__, 1440), constant(filteredFilters.slice(0, 4))],
      [T, constant(filteredFilters.slice(0, 5))],
    ])(width)
  }, [isShowMore, width, filteredFilters])

  if (width <= 768) {
    return (
      <MobileFiltersS {...props} filtersConfig={filteredFilters} />
    )
  }

  return (
    <StyledWrapper>
      <FiltersWrapper
        showMore={isShowMore}
        {...props}
        filtersConfig={config}
      />
      <Controls
        showMore={isShowMore}
        toggle={handleShowMore}
        getFn={getFn}
        clearFn={clearFn}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 230px;
  grid-column-gap: 18px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 48px;
`

const FiltersWrapper = styled(DesktopFilters)`
  display: grid;
  grid-template-columns: repeat(5, minmax(230px, 1fr));
  grid-gap: 16px 8px;
  position: relative;

  & > * {
    margin: 0;
  }
  
  @media screen and (max-width: 1440px) {
    grid-template-columns: repeat(4, minmax(230px, 1fr));
  }
  @media screen and (max-width: 1280px) {
    grid-template-columns: repeat(3, minmax(230px, 1fr));
  }
  @media screen and (max-width: 991px) {
    grid-template-columns: repeat(2, minmax(230px, 1fr));
  }

  .filter {
    height: 48px;
    max-width: initial;
    width: 100%;
    .custom-input {
      padding: 10px 36px 10px 18px;
    }
    .react-datepicker {
      &-wrapper {
        height: 100%;
      }
      &__input-container {
        height: 100%;
        input {
          height: 100%;
          padding: 5px 10px;
        }
      }
    }
  }
  .react-select-container .react-select__option {
    display: flex;
  }
`

const MobileFiltersS = styled(MobileFilters)`
  margin-bottom: 32px;
  .second-line-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 18px;
    margin: 16px 0;

    @media screen and (max-width: 575px) {
      grid-template-columns: 1fr;
    }
  }
`

Filters.propTypes = {
  setFn: PropTypes.func,
  clearFn: PropTypes.func,
  getFn: PropTypes.func,
  filtersConfig: PropTypes.array,
}
