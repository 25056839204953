// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Routing
import { SEARCH, TALENT, OFFERINGS } from '_legacy/constants/routes'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Legacy components
import NavigationList from '_legacy/components/Search/Navigation/NavigationList'
import Filters from '_legacy/components/Search/Filters'
import FeaturedSlider from '_legacy/components/Search/FeaturedSlider'
import List from '_legacy/components/Search/List'


const Search = ({
  fields,
  clearFn,
  getListFn,
  getPromotedListFn,
  filtersConfig,
  fetchListFn,
  fetchPromotedListFn,
  getFiltersFn,
  setFiltersFn,
  EmptyListComponent,
}) => {
  const t = useGetTranslate()
  const clearSearchFilters = clearFn()
  React.useEffect(() => () => clearSearchFilters(), [])

  const navigationProps = [
    {
      linkTo: `/${SEARCH}/${TALENT}`,
      linkText: t('search.navigation.talent.label'),
    },
    {
      linkTo: `/${SEARCH}/${OFFERINGS}`,
      linkText: t('search.navigation.offering.label'),
    },
  ]

  return (
    <div className="search-container">
      <NavigationList navigationProps={navigationProps} />
      <Filters
        filtersConfig={filtersConfig}
        getFn={getFiltersFn}
        setFn={setFiltersFn}
        clearFn={clearFn}
      />
      <FeaturedSlider
        fields={fields}
        getFiltersFn={getFiltersFn}
        getPromotedListFn={getPromotedListFn}
        fetchPromotedListFn={fetchPromotedListFn}
      />
      <List
        fields={fields}
        getListFn={getListFn}
        EmptyList={EmptyListComponent}
        fetchListFn={fetchListFn}
        getFiltersFn={getFiltersFn}
        setFiltersFn={setFiltersFn}
      />
    </div>
  )
}

Search.propTypes = {
  fields: PropTypes.array,
  getListFn: PropTypes.func,
  clearFn: PropTypes.func,
  filtersConfig: PropTypes.array,
  fetchListFn: PropTypes.func,
  getFiltersFn: PropTypes.func,
  setFiltersFn: PropTypes.func,
  EmptyListComponent: PropTypes.func,
}

export default Search
