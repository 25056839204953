import React from 'react';
import PropTypes from 'prop-types';

// components
import { Row } from '_legacy/common/Template';
import { Input as CustomInput } from '_legacy/common/Input';

const Input = ({ label, tip, ...props }) => (
  <Row
    tip={tip}
    nameBlock={label}
    isRequired={props?.isRequired}
    component={<CustomInput {...props} />}
  />
);

Input.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  isRequired: PropTypes.bool,
  validation: PropTypes.object,
  tip: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.func
    ]),
};

export default Input;
