import React from 'react';
import PropTypes from 'prop-types';

import MultipleSlideList from './MultipleSlideList.';

const TwiceSlideList = ({
  size,
  isMirroredInside,
  slides,
  currentSlideIndex,
}) => {

  return (
    <MultipleSlideList
      size={size}
      slides={[
        ...slides,
        ...slides.map(c => React.cloneElement(c, { key: c.key + 'copy' })),
      ]}
      currentSlideIndex={currentSlideIndex}
      isMirroredInside={isMirroredInside}
    />
  );
};

TwiceSlideList.propTypes = {
  size: PropTypes.string,
  isMirroredInside: PropTypes.bool.isRequired,
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
}

export default TwiceSlideList;
