import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import FollowButton from '_legacy/components/Button/FollowButton';

const PublicCardBlockActions = ({ video, toggleModal, children, isFollowed, id, centered }) => {
  const t = useGetTranslate()

  return (
    <StyledPublicViewCardActions centered={centered}>
      {children}
      <StyledFollowButton
        id={id}
        type="OFFERING"
        isFollowed={isFollowed}
        text={t('experience.card.save')}
        className="button-like dark"
      />
      {video && (
        <StyledPublicViewVideo onClick={toggleModal}>
          <i className="icon-play-circle-o" />
        </StyledPublicViewVideo>
      )}
    </StyledPublicViewCardActions>
  )
}
const StyledPublicViewCardActions = styled.div`
  flex: 1 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 18px;

  .public-slider-wrapper {
    align-self: center;
    margin-top: 48px;
  }

  @media only screen and (max-width: 767px) {
    flex: 0;
    padding: 0px;
  }
`

const StyledFollowButton = styled(FollowButton)`
  position: absolute;
  top: 24px;
  right: 34px;
  background: rgba(39, 40, 53, 0.6);

  span {
    color: #ffffff;
  }

  @media only screen and (max-width: 767px) {
    position: absolute;
    top: 14px;
    background: none;
    border: none;
    box-shadow: none;

    span {
      display: none;
    }
  }
`

const StyledPublicViewVideo = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  color: #fff;
  margin: 24px 32px;
  font-size: 42px;
  cursor: pointer;
  
  @media only screen and (max-width: 767px) {
    left: 50%;
    transform: translateX(-50%);
  }
`;

PublicCardBlockActions.propTypes = {
  video: PropTypes.object,
  toggleModal: PropTypes.func,
  children: PropTypes.node,
  isFollowed: PropTypes.bool,
  id: PropTypes.string,
  centered: PropTypes.bool,
}

export default PublicCardBlockActions;
