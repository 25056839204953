import { _get } from 'api/utils';

export const getOccurrencePublic = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/experiences/occurrences/public/${talentPlaqueNickname}/${offerSequentialId}`);

export const getLiveVirtualEvent = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/live-virtual-events/public/${talentPlaqueNickname}/${offerSequentialId}`);

export const getLiveVirtualEventOccurrence = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/live-virtual-events/occurrences/public/${talentPlaqueNickname}/${offerSequentialId}`);

export const getVideoMessage = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/video-messages/public/${talentPlaqueNickname}/${offerSequentialId}`)

export const getReadyMadePublic = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/ready-made-events/public/${talentPlaqueNickname}/${offerSequentialId}`)

export const getVideoChat = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/video-chats/public/${talentPlaqueNickname}/${offerSequentialId}`)

