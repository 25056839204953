import { _get, _put, _post } from 'api/utils'

export const getVideoVoiceOverLesson = ({ occurrenceId }) =>
  _get(`/offers/lessons/video-voice-over-lesson/occurrences/${occurrenceId}`, {
    needAuthorization: true,
  })

export const uploadFileToVideoVoiceOverLesson = ({ occurrenceId, data }) =>
  _put(`/offers/lessons/video-voice-over-lesson/occurrences/${occurrenceId}/upload-video`, {
    needAuthorization: true,
    body: data,
  })

export const submitVideoVoiceOverLesson = ({ occurrenceId }) =>
  _post(`/offers/lessons/video-voice-over-lesson/occurrences/${occurrenceId}/submit`, {
    needAuthorization: true,
  })

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })

