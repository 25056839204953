import { prop, identity } from 'lodash/fp'

import { OrderStatusFactory } from '../utils'

export const statuses = new OrderStatusFactory(
  prop('status'),
  prop('occurrence.status'),
  prop('occurrence'),
  identity
)
