import get from 'lodash/get'
import map from 'lodash/map'
import find from 'lodash/find'
import castArray from 'lodash/castArray'

export const FIELD_ID = 'fileToken'

export const getFilesFromMedia = (response, key = 'media') => {
  const media = get(response, key, []);
  return map(media, 'file')
}

export function submitMultiSelectLibraryAdapter(key, formState) {
  const primaryKey = key + 'PrimaryFile'
  const field = castArray(get(formState, key))
  const primaryFileId = get(formState, primaryKey)

  return {
    [key]: map(field, fileToken => ({
      fileToken,
      isPrimary: primaryFileId === fileToken,
    })).filter(media => media.fileToken),
  }
}

export function getPrimaryFileId (media) {
  const primaryFile = find(media, 'isPrimary')
  const primaryFileId = get(primaryFile, ['file', FIELD_ID])
  return primaryFileId
}

export function getMultiSelectLibraryAdapter(key, response) {
  const tempKey = key + 'Temp'
  const primaryKey = key + 'PrimaryFile'
  const files = getFilesFromMedia(response, key)

  return {
    [key]: map(files, FIELD_ID),
    [tempKey]: map(files, FIELD_ID),
    [primaryKey]: getPrimaryFileId(response[key])
  }
}

export const multiSelectLibraryValidate = value => value?.length > 0 || 'shared.words.required'
