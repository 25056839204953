import React from 'react';
import PropTypes from 'prop-types';

import BasicActions from '_legacy/components/TalentPublicView/DesktopSlider/BasicActions';
import BookNowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/BookNowButton';

import { EXPERIENCES } from '_legacy/constants/routes';

const Actions = ({ slidesCount, onNext, onPrev, plaqueNickname, offerSequentialId, isPromo }) => {
  // const onRequestClick = () => {
  //   alert('This action isn\'t implemented');
  // };

  const path = `/${EXPERIENCES}/${plaqueNickname}/${offerSequentialId}`

  return (
    <BasicActions
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={48}
      padding="0 11px 0 0"
      // onRequestClick={onRequestClick}
    >
      <BookNowButton disabled={isPromo} isLink path={path} />
    </BasicActions>
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  slidesCount: PropTypes.number.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  offerSequentialId: PropTypes.number.isRequired,
  multipleDatesExperience: PropTypes.number,
};

export default Actions;
