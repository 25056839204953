import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

function Title({ title }) {
  const t = useGetTranslate()

  return <StyledTitle>{t(title)}</StyledTitle>
}

const StyledTitle = styled.div`
  max-width: 647px;
  margin: 0 auto 38px;
  font-family: PlayfairDisplay;
  font-size: 30px;
  line-height: 1.33;
  text-align: center;
  color: #fff;

  @media only screen and (max-width: 768px) {
    font-size: 22px;
    margin-left: 12px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 22px;
    margin-left: 12px;
  }
`

Title.propTypes = {
  title: PropTypes.string.isRequired
}

export default Title
