import { _post, _get } from 'api/utils'

export const getMyOfferings = ({ talentId }) =>
  _get(`/talents/${talentId}/offers/my-offerings`, {
    needAuthorization: true,
  })

export const getManageOffers = ({ talentId, query }) =>
  _get(`/talents/${talentId}/offers`, {
    needAuthorization: true,
    query,
  })

export const publishOffers = ({ talentId, offerIds }) =>
  _post(`/talents/${talentId}/offers/bulk/publish`, {
    needAuthorization: true,
    body: {
      offerIds,
    },
  })

export const unPublishOffers = ({ talentId, offerIds }) =>
  _post(`/talents/${talentId}/offers/bulk/unpublish`, {
    needAuthorization: true,
    body: {
      offerIds,
    },
  })

export const deleteOffers = ({ talentId, offerIds, cancelReason, cancelReasonDescription }) =>
  _post(`/talents/${talentId}/offers/bulk/delete`, {
    needAuthorization: true,
    body: {
      offerIds,
      cancelReason,
      cancelReasonDescription
    },
  })

export const checkAvailabilityToEdit = ({ talentId, offerIds }) =>
  _post(`/talents/${talentId}/offers/bulk/check-availability-to-edit`, {
    needAuthorization: true,
    body: {
      offerIds,
    },
  })
