import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'
import get from 'lodash/get'

export const useCurrency = createUseGlobalState('currency')
export const useSetCurrency = createUseSetGlobalState('currency')

export const useCurrencyCode = () => {
  return useCurrency(state => get(state, 'currentCurrencyCode'))
}

export const useFetchedCurrencies= () => {
  return useCurrency(state => get(state, 'fetchedCurrencies'))
}

export const useCurrencyRate = () => {
  const currencyCode = useCurrencyCode()
  const fetchedCurrencies = useFetchedCurrencies()
  const current = fetchedCurrencies.find(currency => currency.code === currencyCode)
  return get(current, 'rate')
}
