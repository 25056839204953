import React from 'react'
import { OrderStatus as Status } from 'shared'
import { complement, includes, __, compose, prop } from 'lodash/fp'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchMyBookings } from './state/request'
import { useMyBookingsFilters, useMyBookingsTable, useSetMyBookingsFilters } from './state/store'

import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import { counterConfig, fields, filtersConfig, subFiltersConfig } from '_legacy/configs/table/myBookings'
import { elementsTypes, MY_BOOKINGS } from '_legacy/components/Table/table-components/types/my-bookings/config'

const tableConfig = [
  {
    header: 'table.header.order',
    type: elementsTypes.order,
    field: 'ORDER_NUMBER',
  },
  {
    header: 'table.header.type',
    type: elementsTypes.type,
    field: 'OFFER_TYPE',
  },
  {
    header: 'table.header.talent',
    type: elementsTypes.talent,
    field: 'OFFER_TALENT_NAME',
  },
  {
    header: 'table.header.occurrence',
    type: elementsTypes.occurrence,
    field: 'OFFER_OCCURRENCE_DATE',
  },
  {
    header: 'table.header.location',
    type: elementsTypes.location,
    field: 'OFFER_OCCURRENCE_LOCATION',
  },
  {
    header: 'table.header.booked',
    type: elementsTypes.booked,
    field: 'ORDER_BOOKING_DATE',
  },
  {
    header: 'table.header.status',
    type: elementsTypes.status,
    field: 'ORDER_STATUS',
  },
]

const shouldShowCancelAction = compose(
  complement(includes(__, [Status.COMPLETED, Status.CANCELED, Status.REJECTED, Status.ADMIN_REVIEW])),
  prop('status'),
)

const MyBookings = () => {
  const t = useGetTranslate()

  const actionsConfig = React.useMemo(
    () => [
      {
        label: t('my-orders.action.cancel-booking'),
        cbFn: () => alert('This action not implemented'),
        shouldShow: shouldShowCancelAction,
      },
    ],
    [t]
  )

  return (
    <div className="my-bookings wrapper content-wrapper">
      <h1 className="my-bookings__header">{t('table.title.my-bookings')}</h1>
      <Filters filtersConfig={filtersConfig} getFn={useMyBookingsFilters} setFn={useSetMyBookingsFilters} />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useMyBookingsFilters}
        setFn={useSetMyBookingsFilters}
      />
      <Table
        tableName={MY_BOOKINGS}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        getTableFn={useMyBookingsTable}
        fields={fields}
        getFiltersFn={useMyBookingsFilters}
        setFiltersFn={useSetMyBookingsFilters}
        fetchTableFn={useFetchMyBookings}
      />
    </div>
  )
}

export default MyBookings
