import React from 'react';
import { types } from 'shared';

import ViaEntreeVIP from '../JoinToTalentContent/ViaEntreeVIP';
import ViaEmail from '../JoinToTalentContent/ViaEmail';
import ViaLink from '../JoinToTalentContent/ViaLink';
import ModalPortal from 'portals/ModalPortal';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { JOIN_TO_TALENT_MODAL } from '../Modal/modals-types';
import { useIsTalentHasUser } from 'store/hooks/globalState/useUser';

const {
  TALENT_USER,
  AGENT,
  MANAGER,
} = types.UserType;

const itemsAgent = [
  { title: 'join-to-talent.tab.label1', component: <ViaEntreeVIP type={AGENT} /> },
  { title: 'join-to-talent.tab.label2', component: <ViaEmail type={AGENT} /> },
  { title: 'join-to-talent.tab.label3', component: <ViaLink type={AGENT} /> },
];

const itemsManager = [
  { title: 'join-to-talent.tab.label1', component: <ViaEntreeVIP type={MANAGER} /> },
  { title: 'join-to-talent.tab.label2', component: <ViaEmail type={MANAGER} /> },
  { title: 'join-to-talent.tab.label3', component: <ViaLink type={MANAGER} /> },
];

const itemsTalent = [
  // { title: 'join-to-talent.tab.label1', component: <ViaEntreeVIP type={TALENT_USER} /> },
  { title: 'join-to-talent.tab.label2', component: <ViaEmail type={TALENT_USER} /> },
  { title: 'join-to-talent.tab.label3', component: <ViaLink type={TALENT_USER} /> },
];

const SelectJoinToTalentModal = () => {
  const t = useGetTranslate();

  const openModal  = useStateModal(JOIN_TO_TALENT_MODAL);
  const isTalentHasUser = useIsTalentHasUser();

  return (
    <>
      <ModalPortal isOpen showCloseButton>
        <div className="selectJoinToTalentModal">
          <h2 className='selectJoinToTalentModal__title joinToTalent-modal__title'>{t('join-to-talent.title.manager')}</h2>
          <h4 className='selectJoinToTalentModal__subtitle'>{t('join-to-talent.select.desc')}</h4>
          <div className='selectJoinToTalentModal__buttons'>
            <button
              type='button'
              className='selectJoinToTalentModal__button'
              onClick={() => openModal({ type : AGENT, items: itemsAgent })}
            >
              {t('join-to-talent.select.button.agent')}
            </button>
            <button
              type='button'
              className='selectJoinToTalentModal__button'
              onClick={() => openModal({ type : MANAGER, items: itemsManager })}
            >
              {t('join-to-talent.select.button.manager')}
            </button>
            {!isTalentHasUser && (
              <button
                type='button'
                className='selectJoinToTalentModal__button'
                onClick={() => openModal({ type: TALENT_USER, items: itemsTalent })}
              >
                {t('join-to-talent.select.button.talent')}
              </button>
            )}
          </div>
        </div>
      </ModalPortal>
    </>
  );
};

export default SelectJoinToTalentModal;
