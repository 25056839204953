import React from 'react'

import PaymentAccount from './components/PaymentAccount'
import PaymentAccountConnect from './components/PaymentAccountConnect'

const PaymentSettings = ({ isStripeAccountReady }) => {
  return (
    isStripeAccountReady ?
      <PaymentAccount isStripeAccountReady={isStripeAccountReady}/> :
      <PaymentAccountConnect isStripeAccountReady={isStripeAccountReady} />
  );
};

export default PaymentSettings;
