import React from 'react'
import cond from 'lodash/fp/cond'

import Button from '_legacy/components/Button'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useModal } from 'store/hooks/useModal'
import CancelModal from '../CancelModal'
import { useCancelOrder } from '../../OrderDetails/LiveInPersonLessonOrderDetails/state/request'
import ModalWithControls from 'portals/ModalWithControls'

const CancelOrderButton = ({
  orderId,
  onSuccess,
  message,
  header,
  refundBtnLabel,
  immediatelyOpenCancelModal,
  immediatelyCancelOrder,
}) => {
  const t = useGetTranslate()
  const cancelOrder = useCancelOrder(onSuccess)

  const [isCancelOrderModalOpen, openCancelOrderModal, closeCancelOrderModal] = useModal(false)
  const [isCancelReasonModalOpen, openCancelReasonModal, closeCancelReasonModal] = useModal(false)

  const onCancelOrderModal = React.useCallback(cond([
    [() => immediatelyOpenCancelModal, openCancelReasonModal],
    [() => immediatelyCancelOrder, () => cancelOrder({ offerId: orderId })],
    [() => true, openCancelOrderModal],
  ]), [immediatelyOpenCancelModal, immediatelyCancelOrder, openCancelOrderModal, orderId])

  const onRefund = React.useCallback(() => {
    closeCancelOrderModal()
    openCancelReasonModal()
  }, [])

  return (
    <React.Fragment>
      <Button
        primary
        stretch
        text={t('order.answer.buttons.cancel-order')}
        paddingVertical={16}
        handleOnClick={onCancelOrderModal}
      />

      <ModalWithControls
        isOpen={isCancelOrderModalOpen}
        onClose={closeCancelOrderModal}
        header={t(header) || t('cancel-order-modal.header')}
        message={t(message) || t('cancel-order-modal.message')}
        buttonsConfig={[
          { text: t('cancel-order-modal.controls.go-back'), handleOnClick: closeCancelOrderModal, primary: true },
          { text: t(refundBtnLabel) || t('cancel-order-modal.controls.refund'), handleOnClick: onRefund },
        ]}
      />

      <CancelModal
        orderId={orderId}
        onClose={closeCancelReasonModal}
        isOpen={isCancelReasonModalOpen}
        cancelHandler={cancelOrder}
        buttonText="cancel-reason.cancel-occurrence.button"
      />
    </React.Fragment>
  )
}

export default CancelOrderButton
