import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import prop from 'lodash/fp/prop'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const ASC = 'ASC'
const DESC = 'DESC'
const NONE = undefined

const statuses = [
  NONE,
  ASC,
  DESC,
]

const SortItem = ({ title, field, getFn, setFn }) => {
  const t = useGetTranslate()
  const sortField = getFn(prop('sortField'))
  const sortOrder = getFn(prop('sortOrder'))
  const handleChange = setFn((prev, sortOrder) => ({ ...prev, sortOrder, sortField: field }))
  const isSelectedField = sortField === field

  const itemClasses = cx('table-filter-sort-item', {
    'table-filter-sort-item--pointer': Boolean(field)
  })

  const arrowClasses = cx('table-filter-sort-item__controls', {
    'table-filter-sort-item__controls--desc': isSelectedField && sortOrder === DESC,
    'table-filter-sort-item__controls--asc': isSelectedField && sortOrder === ASC,
  })

  const onStatusClick = () => {
    if (field) {
      const idx = statuses.indexOf(sortOrder)
      const nextIdx = isSelectedField ? (idx + 1) % statuses.length : 1
      handleChange(statuses[nextIdx])
    }
  }

  return (
    <div className={itemClasses} onClick={onStatusClick}>
      {field && <div className={arrowClasses} />}
      <div className='table-filter-sort-item__title'>
        {t(title)}
      </div>
    </div>
  )
}

SortItem.propTypes = {
  title: PropTypes.string,
  field: PropTypes.string,
  getFn: PropTypes.func,
  setFn: PropTypes.func,
}

export default SortItem
