import React from 'react'

import * as r from '_legacy/constants/routes'

import ReviewsByMe from './ReviewsByMe'
import ReviewsOfMe from './ReviewsOfMe'

export const getReviewConfig = () => [
  {
    translation: 'reviews.tabs.by-me',
    pathname: `/${r.REVIEWS}/${r.LIST}/${r.BY_ME}`,
    pathComponent: `/${r.REVIEWS}/${r.LIST}/${r.BY_ME}`,
    itemRef: React.useRef(),
    component: ReviewsByMe,
  },
  {
    translation: 'reviews.tabs.of-me',
    pathname: `/${r.REVIEWS}/${r.LIST}/${r.TO_ME}`,
    pathComponent: `/${r.REVIEWS}/${r.LIST}/${r.TO_ME}`,
    itemRef: React.useRef(),
    component: ReviewsOfMe,
  },
]
