import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ImageSection from './ImageSection';
import ButtonsSection from './ButtonsSection';

function Plate ({ plate, data, isLoading }) {
  const t = useGetTranslate();
  const { icon, title, description, isMulti, isSpecial, createLink, editLink, field, available } = plate;

  if (!available) {
    return (
      <div className='plate-container plate-container--disabled'>
        <ImageSection
          isSpecial={isSpecial}
          icon={icon}
        />
        <div className='title'>
          {t(title)}
        </div>
        <p className='description'>
          {t('shared.words.coming-soon')}
        </p>
      </div>
    )
  }

  return (
    <div className={`plate-container ${isSpecial ? 'special-plate' : ''}`}>
      <ImageSection
        isSpecial={isSpecial}
        data={data}
        icon={icon}
      />
      <div className='title'>
        {t(title)}
      </div>
      {!data && (
        <p className='description'>
          {t(description)}
        </p>
      )}
      <ButtonsSection
        isEdit={!!data}
        createLink={createLink}
        editLink={editLink}
        isMulti={isMulti}
        isLoading={isLoading}
        field={field}
      />
    </div>
  );
}

Plate.propTypes = {
  plate: PropTypes.object.isRequired,
  data: PropTypes.object || PropTypes.undefined,
  isLoading: PropTypes.bool.isRequired
};

export default Plate;
