import React from 'react'
import PropTypes from 'prop-types'
import prop from 'lodash/fp/prop'


import FavoritesList from './FavoritesList'
import MobileList from '_legacy/components/Search/List/MobileList'
import TableLoader from '_legacy/components/Table/table-components/TableLoader'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import '_legacy/components/Search/List/index.scss'

const List = ({ fetchListFn, getListFn, getFiltersFn, setFiltersFn, EmptyList }) => {
  const isMobile = useWindowDimensions(prop('width')) <= 575

  return (
    <>
      <TableLoader
        shouldHandleUser={false}
        isMobile={isMobile}
        fetchTableFn={fetchListFn}
        fields={[]}
        getFiltersFn={getFiltersFn}
      />
      {isMobile
        ? <MobileList
          getFn={getFiltersFn}
          setFn={setFiltersFn}
          getListFn={getListFn}
          EmptyList={EmptyList}
          fetchTableFn={fetchListFn}
          fields={[]}
          isMobile={isMobile}
        />
        : <FavoritesList
          getFiltersFn={getFiltersFn}
          getListFn={getListFn}
          EmptyList={EmptyList}
        />
      }
    </>
  )
}

List.propTypes = {
  getListFn: PropTypes.func,
  getFiltersFn: PropTypes.func,
  fetchListFn: PropTypes.func,
  setFiltersFn: PropTypes.func,
  EmptyList: PropTypes.element,
  fields: PropTypes.array,
}

export default List
