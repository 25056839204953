import React from 'react'
import PropTypes from 'prop-types'
import { Swiper, SwiperSlide } from 'swiper/react'

import Icon from 'assets/icons/Icon'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.min.css'
import './index.scss'

import PublicSliderSlide from './PublicSliderSlide'

const PublicSlider = ({ covers, className }) => {
  const prevRef = React.useRef(null)
  const nextRef = React.useRef(null)
  const paginationRef = React.useRef(null)

  const loop = covers.length > 1

  return (
    <div className="public-slider-wrapper">
      {loop && (
        <div className="public-slider__nav">
          <div
            ref={prevRef}
            className="public-slider__nav_btn public-slider__nav_btn--prev"
          >
            <Icon.ArrowSliderPrev width={12} height={22} />
          </div>
          <div
            ref={nextRef}
            className="public-slider__nav_btn public-slider__nav_btn--next"
          >
            <Icon.ArrowSliderNext width={12} height={22} />
          </div>
        </div>
      )}
      <div className="public-slider">
        <Swiper
          spaceBetween={80}
          slidesPerView={1}
          loop={loop}
          threshold={20}
          allowTouchMove={loop}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.params.pagination.el = paginationRef.current
          }}
        >
          {covers.map(cover => (
            <SwiperSlide key={cover.file.id}>
              <PublicSliderSlide
                file={cover}
                id={cover.file.id}
                className={className}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

PublicSlider.propTypes = {
  covers: PropTypes.array,
  className: PropTypes.string,
}

export default PublicSlider
