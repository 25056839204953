import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import './public-view-taboos.scss'

const PublicTaboos = ({ title = '', list = [] }) => {
  const t = useGetTranslate();

  return (!isEmpty(list) &&
    <div className='public-view__taboos'>
      <div className='top'>
        <div className='line'/>
        <h4 className='title'>{t(title)}</h4>
        <div className='line'/>
      </div>
      <ul className='public-view__taboos_list'>
        {list.map((l, i) => <li key={i}>{t(l)}</li>)}
      </ul>
    </div>
  );
};

PublicTaboos.propTypes = {
  title: PropTypes.string,
  list: PropTypes.array,
}

export default PublicTaboos;
