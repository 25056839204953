import React from 'react'

import useChatContext from '../../hooks/useChatContext'
import ChatInput from './components/ChatInput'
import MessageList from './components/MessageList'
import ChatWindowHeader from './components/ChatWindowHeader'

export default function ChatWindow() {
  const { isChatWindowOpen, messages, conversation, videoChatInfo } = useChatContext()

  if (!isChatWindowOpen) return null

  return (
    <aside className='chat-window'>
      <ChatWindowHeader />
      <MessageList messages={messages} chatInfo={videoChatInfo} />
      <ChatInput conversation={conversation} />
    </aside>
  )
}
