import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const AddToCalendarButton = ({ startTime, endTime, title, location, timeZone, className }) => {
  const t = useGetTranslate()

  React.useEffect(() => {
    window.addeventatc.refresh()
  }, [])

  return (
    <div className={`addeventatc ${className}`} data-dropdown-y="up">
      {t('calendar.info-sidebar.title.add-to-calendar')}
      <span className="start">{startTime}</span>
      <span className="end">{endTime}</span>
      <span className="timezone">{timeZone}</span>
      <span className="title">{title || t('calendar.slot.default-title')}</span>
      {location && <span className="location">{location}</span>}
    </div>
  )
}

export default AddToCalendarButton
