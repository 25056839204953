import React from 'react'
import { useElements, CardElement } from '@stripe/react-stripe-js';

import Button from '_legacy/components/Button';
import StripeFieldset from '_legacy/components/StripeFieldset';

import { useSetPaymentCard } from 'requests/payments';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import './index.scss'

const PaymentCard = () => {
  const t = useGetTranslate()
  const elements = useElements()

  const [error, setError] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const [nameOnCard, setNameOnCard] = React.useState(null);

  const setPaymentCard = useSetPaymentCard({ setLoading })

  const handleAddCard = event => {
    event.preventDefault();
    setPaymentCard({
      card: elements.getElement(CardElement),
      billing_details: { name: nameOnCard },
    })
  }

  return (
    <div className='payment-modal'>
      <h4 className='payment-modal__title'>{t('payment-modal.title')}</h4>

      <StripeFieldset
        nameOnCard={nameOnCard}
        setNameOnCard={setNameOnCard}
        error={error}
        setError={setError}
        CardElement={CardElement}
      />

      <Button
        text={t('payment-modal.button')}
        paddingVertical={13}
        paddingHorizontal={22}
        loader={isLoading}
        stretch
        handleOnClick={handleAddCard}
        className='payment-modal__btn-add'
      />
    </div>
  )
}

export default PaymentCard
