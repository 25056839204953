import React from 'react'
import isFunction from 'lodash/isFunction'

import { leaveReview as leaveReviewApi } from 'api/appApi/offers/reviews'
import { editReview as editReviewApi } from 'api/appApi/reivews/reviews'

import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export function useLeaveReview(onSuccess) {
  const t = useGetTranslate()

  return React.useCallback(
    (orderId, options) => {
      leaveReviewApi({ orderId, options })
        .then(() => {
          notify(t('review.notification.update.create'))

          if (isFunction(onSuccess)) onSuccess()
        })
        .catch(() => {
          notify(t('review.notification.update.error'), 'error')
        })
    },
    [t]
  )
}

export function useEditReview(onSuccess) {
  const t = useGetTranslate()

  return React.useCallback(
    ({ reviewId, rating, description }) => {
      editReviewApi({ reviewId, rating, description })
        .then(() => {
          notify(t('review.notification.update.edit'))

          if (isFunction(onSuccess)) onSuccess()
        })
        .catch(() => {
          notify(t('review.notification.update.error'), 'error')
        })
    },
    [t]
  )
}
