import React from 'react'
import styled from 'styled-components'
import { useWatch } from 'react-hook-form'
import { TypesOfEntourage } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { submitRadio } from 'utils/forms/adapters/radio'
import { Row } from '_legacy/common'
import RadioList from '_legacy/common/Radio/RadioList'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import EntourageMembershipModal from 'components/EntourageMembershipModal'
import EntourageMembershipTypeModal from 'components/EntourageMembershipTypeModal'

export const entourageOptions = [
  { label: 'create-experience.form.entourage.options.yes', value: true },
  { label: 'create-experience.form.entourage.options.no', value: false },
]

export const entourageTypeOptions = [
  { label: 'create-experience.form.entourage-type.options.full', value: TypesOfEntourage.FULL },
  { label: 'create-experience.form.entourage-type.options.full-or-lite', value: TypesOfEntourage.EITHER },
]

const Entourage = () => {
  const t = useGetTranslate()
  const entourage = submitRadio(useWatch({ name: 'entourage' }))

  return (
    <>
      <EntourageWrapper
        tip={<TipButton modalComponent={EntourageMembershipModal} />}
        nameBlock={t('create-experience.form.entourage.label')}
        component={
          <RadioList
            name="entourage"
            options={entourageOptions}
            defaultChecked={true}
          />
        }
      />
      {entourage && (
        <EntourageWrapper
          tip={<TipButton modalComponent={EntourageMembershipTypeModal} />}
          nameBlock={t('create-experience.form.entourage-type.label')}
          component={
            <RadioList
              name="entourageType"
              options={entourageTypeOptions}
              defaultChecked={TypesOfEntourage.FULL}
            />
          }
        />
      )}
    </>
  )
}

const EntourageWrapper = styled(Row)`
  align-items: center;
`

export default Entourage
