import {
  languageConfig,
  locationKmRadiusConfig,
  searchConfig,
  searchSubFiltersConfig,
  profileTypeConfig,
} from '../shared'
import {
  locationConfig,
  priceConfig,
  participantsConfig,
  schedulingConfig,
  durationConfig,
  offersTypeIn,
  charitiesConfig,
  rangeDateConfig
} from './configs'

export const filtersConfig = [
  searchConfig,
  profileTypeConfig,
  offersTypeIn,
  locationConfig,
  locationKmRadiusConfig,
  priceConfig,
  languageConfig,
  schedulingConfig,
  rangeDateConfig,
  durationConfig,
  participantsConfig,
  charitiesConfig
]

export const additionalFields = [
  'sortByLocationLatitude',
  'sortByLocationLongitude'
]

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...searchSubFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
