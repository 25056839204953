import React from 'react'
import { complement, includes, __, compose, prop } from 'lodash/fp'
import { OrderStatus as Status } from 'shared'

import { filtersConfig, counterConfig, subFiltersConfig, fields } from '_legacy/configs/table/manageOrders'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useManageOrdersFilters, useSetManageOrdersFilters, useManageOrdersTable } from './state/store'
import { useModal } from 'store/hooks/useModal'

import { useFetchManageOrders, useTryCancelOccurrence, useCancelOccurrence } from './state/request'

import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import { ORDERS, elementsTypes } from '_legacy/components/Table/table-components/types/manage-orders/config'
import Table from '_legacy/components/Table/table-components/Table'
import CancelModal from 'components/OfferingDetails/components/CancelModal'

const tableConfig = [
  {
    header: 'table.header.order',
    type: elementsTypes.order,
    field: 'ORDER_NUMBER'
  },
  {
    header: 'table.header.type',
    type: elementsTypes.type,
    field: 'TYPE'
  },
  {
    header: 'table.header.booked-participants',
    type: elementsTypes.participants,
    className: 'center',
    field: 'BOOKED_PARTICIPANTS'
  },
  {
    header: 'table.header.date-time',
    type: elementsTypes.date_time,
    field: 'DATE'
  },
  {
    header: 'table.header.location',
    type: elementsTypes.location,
    field: 'LOCATION'
  },
  {
    header: 'table.header.current-pending-actions',
    type: elementsTypes.current_pending_actions,
    field: 'PENDING_ACTION'
  },
  {
    header: 'table.header.status',
    type: elementsTypes.status,
    field: 'STATUS'
  }
]

const shouldCancelledStatus = compose(
  complement(includes(__, [Status.COMPLETED, Status.CANCELED, Status.CLOSED, Status.REJECTED, Status.ADMIN_REVIEW])),
  prop('status'),
)

const getOfferIdFromOccurrences = (occurrenceId, occurrences) => {
  const occurrence = occurrences.find(occurrence => occurrence.id === occurrenceId)
  return occurrence?.offerId
}

const ManageOrders = () => {
  const t = useGetTranslate()
  const cancelOccurrence = useCancelOccurrence()
  const occurrences = useManageOrdersTable()
  const [selectedOccurrence, setSelectedOccurrence] = React.useState(null)
  const [isOpenCancelModal, openCancelModal, closeCancelModal] = useModal(false)
  const tryCancelOccurrence = useTryCancelOccurrence(openCancelModal)

  const handleTryToCancel = React.useCallback(([occurrenceId]) => {
    const offerId = getOfferIdFromOccurrences(occurrenceId, occurrences)
    setSelectedOccurrence({ offerId, occurrenceId })
    tryCancelOccurrence({ offerId, occurrenceId })
  }, [occurrences])

  const handleCancelOccurrence = React.useCallback(({ message, reason }) => {
    cancelOccurrence({
      message,
      reason,
      occurrenceId: selectedOccurrence.occurrenceId,
      offerId: selectedOccurrence.offerId,
    })
  }, [selectedOccurrence])

  const actionsConfig = React.useMemo(() => [
    { label: t('my-orders.action.cancel'), cbFn: handleTryToCancel, shouldShow: shouldCancelledStatus },
  ], [t, handleTryToCancel])

  return (
    <div className='wrapper content-wrapper manage-orders-table'>
      <h1 className='title'>{t('table.title.manage-orders')}</h1>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useManageOrdersFilters}
        setFn={useSetManageOrdersFilters}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useManageOrdersFilters}
        setFn={useSetManageOrdersFilters}
      />
      <Table
        tableName={ORDERS}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        getTableFn={useManageOrdersTable}
        fields={fields}
        getFiltersFn={useManageOrdersFilters}
        setFiltersFn={useSetManageOrdersFilters}
        fetchTableFn={useFetchManageOrders}
      />

      <CancelModal
        isOpen={isOpenCancelModal}
        onClose={closeCancelModal}
        cancelHandler={handleCancelOccurrence}
        buttonText='cancel-reason.cancel-order.button'
      />
    </div>
  )
}

export default ManageOrders
