import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState'

const ControlledCollapse = ({ collapsed, children, blockFullPageScroll = true }) => {
  const ref = useRef()
  const [transitionStarted, setTransitionStarted] = useState(false)
  const blockFullPage = useSetFullpageBlock((prev, next) => next, [])

  const onTransitionEnd = () => {
    setTransitionStarted(false)
  }

  // If transition in progress - scroll bar isn't visible
  useEffect(() => {
    ref.current.addEventListener('transitionend', onTransitionEnd)
  }, [])

  useEffect(() => {
    setTransitionStarted(true)

    if (blockFullPageScroll) {
      blockFullPage(true)
    }
  }, [collapsed])

  return (
    <div ref={ref} className={classnames('controlled-collapse', {
      'controlled-collapse__opened': !collapsed,
      'controlled-collapse__transition-going': transitionStarted,
    })}>
      {children}
    </div>
  )
}

ControlledCollapse.propTypes = {
  collapsed: PropTypes.bool,
  blockFullPageScroll: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
}

export default ControlledCollapse
