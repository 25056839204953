import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select'
import { Controller, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import compose from 'lodash/fp/compose'
import prop from 'lodash/fp/prop'
import cx from 'classnames';


import { ErrorMessage } from '_legacy/common/ErrorMessage'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// todo: unify styles & components - needed for rendering in portal
const Option = props => {
  const disabled = get(props, 'data.disabled')
  const classes = cx('select-option', { 'select-option--disabled': disabled })

  return (
    <components.Option {...props} className={classes}>
      {props.children}
    </components.Option>
  )
}

const Select = React.memo(function Select(props) {
  const t = useGetTranslate()
  const { customOnChange, name, options, rules, defaultValue, placeholder, disabled, blackTheme, className, components, ...rest } = props
  const { control, errors } = useFormContext();
  const error = get(errors, name);

  const classes = cx('react-select-container', className, {
    'react-select-container--black-theme': blackTheme,
    'react-select-container--danger': error,
  });

  const optionsWithTranslate = options.map(o => ({ ...o, label: t(o.label) }))

  return (
    <div className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={rules}
        render={({ onChange, value }) => (
          <ReactSelect
            defaultValue={defaultValue}
            value={optionsWithTranslate.find(option => option.value === value?.value)}
            onChange={(...values) => {
              if (customOnChange) customOnChange(values);
              onChange(...values);
            }}
            options={optionsWithTranslate}
            isOptionDisabled={compose(Boolean, prop('disabled'))}
            placeholder={placeholder}
            isDisabled={disabled}
            classNamePrefix="react-select"
            components={{
              Option,
              IndicatorSeparator: () => null,
              // Option: CustomOption
              ...components
            }}
            {...rest}
          />
        )}
      />
      {error && <ErrorMessage className="error-message" message={error?.message} />}
    </div>
  );
});

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  rules: PropTypes.object,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  blackTheme: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.string,
  customOnChange: PropTypes.func,
};

export default Select;
