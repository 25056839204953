import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button'
import TextArea from '_legacy/common/TextArea'
import StarRating from '_legacy/components/StarRating'
import ModalPortal from 'portals/ModalPortal'

const ReviewModal = ({ userLink, userFullName, isOpen, offerType, onSubmit, closeModal }) => {
  const t = useGetTranslate()
  const { handleSubmit, watch } = useFormContext()
  const rating = watch('rating')

  return (
    <ModalPortal
      isOpen={isOpen}
      isMobileFullScreen
      showCloseButton
      onClose={closeModal}
    >
      <div className="review-modal">
        <h1 className="modal-header">{t('rating.modal.header')}</h1>
        <p className="modal-text">
          <Trans
            tKey="rating.modal.description"
            phs={[
              { ph: '{{url}}', value: userLink },
              { ph: '{{offerType}}', value: offerType },
              { ph: '{{userName}}', value: userFullName },
            ]}
          />
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          <StarRating defaultChecked={rating} name="modalRating" />

          <label className="input-label">
            {t('rating.modal.form.comment.placeholder')}
            <TextArea
              maxLength={200}
              name="modalDescription"
              className="review-modal__textarea"
              placeholder={t('rating.modal.form.comment.placeholder')}
            />
          </label>

          <Button
            stretch
            typeButton="submit"
            paddingVertical={16}
            text={t('shared.words.submit')}
          />
        </form>
      </div>
    </ModalPortal>
  )
}

export default ReviewModal
