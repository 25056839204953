import { _get, _post } from 'api/utils';

export const createPromoCodes = ({ talentId, data }) => _post(`/talents/${talentId}/promo-codes/`, { body: data, needAuthorization: true });

export const verifyPromoCodes = (promoCode, talentId) =>
  _get(`/verify/promo-code/${promoCode}/check-unique`, {
    query: { talentId },
  })

export const fetchOfferingsForPromoCodes = ({ talentId, query }) =>
  _get(`/talents/${talentId}/promo-codes/search-offers`, {
    needAuthorization: true, query
  })

export const managePromoCodes = ({ id, query }) =>
  _get(`/talents/${id}/promo-codes`, {
    needAuthorization: true,
    query,
  })

export const inactivatePromoCodes = ({ id, promoCodeIds }) =>
  _post(`/talents/${id}/promo-codes/bulk/inactivate`, {
    needAuthorization: true, query: { promoCodeIds }
  })

export const verifyPromoCodesForTalent = ({ promoCode, isRegistration = false }) =>
  _post(`verify/promo-code/${promoCode}/check-available-for-talent`, {
    query: { isRegistration },
    needAuthorization: true
  })

export const changeOfferingsPromoCodes = ({ id, talentOffersIds, promoCodeIds }) =>
  _post(`/talents/${id}/promo-codes/bulk/change-offerings`, {
    needAuthorization: true,
    query: { talentOffersIds, promoCodeIds },
  })
