import React from 'react'
import useLocalTracks from './hooks/useLocalTracks'
import useRoom from './hooks/useRoom'
import useHandleRoomDisconnection from './hooks/useHandleRoomDisconection'
import useHandleTrackPublicationFailed from './hooks/useHandleTrackPublicationFailed'
import useRestartAudioTrackOnDeviceChange from './hooks/useRestartAudioTrackOnDeviceChange'

export const VideoContext = React.createContext(null)

export const VideoProvider = ({ options, children, onError }) => {
  const onErrorCallback = React.useCallback(
    error => {
      // eslint-disable-next-line no-console
      console.log(`ERROR: ${error.message}`, error)
      onError(error)
    },
    [onError]
  )

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks()
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options)

  // Register callback functions to be called on room disconnect.
  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
  )

  useHandleTrackPublicationFailed(room, onError)
  useRestartAudioTrackOnDeviceChange(localTracks)

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        getAudioAndVideoTracks,
      }}
    >
      {children}
    </VideoContext.Provider>
  )
}
