import React from 'react'

import { types } from 'shared'
import VideoViewer from './VideoViewer'
import ImageViewer from './ImageViewer'

const { VIDEO } = types.file.Types

export default React.memo(function Viewer ({ isMain, file }) {
  if (!file) return null

  if (file.type === VIDEO) {
    return <VideoViewer isMain={isMain} file={file} />
  }

  return (
    <ImageViewer
      isMain={isMain}
      file={file}
    />
  )
})
