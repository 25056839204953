import React from 'react'
import Select from 'react-select'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useDevices from '../../hooks/useDevices'
import useVideoContext from '../../hooks/useVideoContext'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack'
import { SELECTED_AUDIO_INPUT_KEY } from '../../constants'

export default function AudioInputList() {
  const t = useGetTranslate()
  const { audioInputDevices } = useDevices()
  const { localTracks } = useVideoContext()

  const localAudioTrack = localTracks.find(track => track.kind === 'audio')
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack)
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId

  function replaceTrack(newDeviceId) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId)
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } })
  }

  const audioInputDevicesOptions = audioInputDevices.map(device => ({ value: device.deviceId, label: device.label }))
  const activeInputOption = audioInputDevicesOptions.find(option => option.value === localAudioInputDeviceId)
  const activeAudioInputLabel = localAudioTrack?.mediaStreamTrack.label || t('video-chat.device-selection-modal.no-local-audio')

  return (
    <div className="media-list">
      <h6 className="media-list__header">
        {t('video-chat.device-selection-modal.audio-input')}
      </h6>

      {audioInputDevices.length > 1 ? (
        <div className="react-select-container">
          <Select
            classNamePrefix="react-select"
            components={{ IndicatorSeparator: () => null }}
            isSearchable={false}
            options={audioInputDevicesOptions}
            onChange={option => replaceTrack(option.value)}
            value={activeInputOption}
          />
        </div>
      ) : (
        <p className='media-list__default'>{activeAudioInputLabel}</p>
      )}
    </div>
  )
}
