import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import Body2 from '_legacy/common/Typography/Body2';
import CharitiesSelect from '_legacy/components/Form/Selects/basic/CharitiesSelect';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Charities = ({ name }) => {
  const t = useGetTranslate();
  return (
    <Row
      nameBlock={t('shared.charities.label')}
      subNameBlock={t('shared.charities.tip')}
      component={
        <CharitiesWrapper>
          <CharitiesSelect name={name} showSeparator={false} />
          <Body2 marginTop="10px">
            <Trans tKey='shared.charities.text' />
          </Body2>
        </CharitiesWrapper>
      }
    />
  );
};

const CharitiesWrapper = styled.div`
  a {
    color: #272835;

    &:hover {
      text-decoration: underline;
    }
  }
`;

Charities.propTypes = {
  name: PropTypes.string,
}

export default Charities;
