import get from 'lodash/get'

export const getPriceLabel = (price, currencyCode, options) => {
  const formattedPrice = Intl.NumberFormat('en-US', options).format(price);

  const config = {
    USD: `$${formattedPrice}`,
    EUR: `€${formattedPrice}`,
  }

  return get(config, currencyCode, formattedPrice)
}

export const getPriceCurrencyLabel = currencyCode => {
  const config = {
    USD: 'USD',
    EUR: 'EUR',
  }

  return get(config, currencyCode, currencyCode)
}
