import React from 'react'
import PropTypes from 'prop-types'
import { VideoMessageForType } from 'shared'
import { FormProvider } from 'react-hook-form'
import kebabCase from 'lodash/kebabCase'

import TextArea from '_legacy/common/TextArea'
import { Checkbox, Input, RadioButtons, Row } from '_legacy/common'
import BlockWithBorder from '_legacy/common/Template/BlockWithBorder'
import PhoneWithAsyncValidation from '_legacy/common/Input/PhoneWithValidation'
import RequiredLabel from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest/CommercialEngagementsRequestForm/component/shared/RequiredLabel'
import SubmitButton from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest/CommercialEngagementsRequestForm/component/SubmitButton'
import HappensBlock from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest/CommercialEngagementsRequestForm/component/HappensBlock'

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { emailPattern } from 'utils/services/validators/validators'
import { forTypeOptions, occasionTypeOptions } from './utils/optionsRadioButton'
import { happensBlockConfig } from './utils/happensBlockConfig'
import { getUser } from 'utils/user'

const VideoMessageRequestForm = React.memo(({ methods, onSubmit, fullName, loader }) => {
  const t = useGetTranslate()
  const forType = methods.watch('recipient.forType')
  const { firstName, email, phone } = useUser(getUser)

  const optionsWithTranslateForType = forTypeOptions.map(type => ({
    value: type,
    label: t(`video-message.request.recipient-info.this-video-for.${kebabCase(type)}`),
  }))

  const optionsWithTranslateOccasionType = occasionTypeOptions.map(type => ({
    value: type,
    label: t(`video-message.request.my-message.occasion.block.${kebabCase(type)}`),
  }))

  React.useEffect(() => {
    if (forType === VideoMessageForType.SOMEONE_ELSE) {
      methods.setValue('recipient.name', '')
      methods.setValue('recipient.toEmail', '')
      methods.setValue('recipient.phone', '+1')

      methods.setValue('requestor.name', firstName)
      methods.setValue('requestor.email', email)
      methods.setValue('requestor.phone', phone)
    } else {
      methods.setValue('recipient.name', firstName, { shouldValidate: true })
      methods.setValue('recipient.toEmail', email, { shouldValidate: true })
      methods.setValue('recipient.phone', phone, { shouldValidate: true })
    }
  }, [forType])

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="video-message-request__form white-background">
        <BlockWithBorder title="video-message.request.recipient-info.header">
          <RequiredLabel />
          <Row
            nameBlock={t('video-message.request.recipient-info.this-video-for.label')}
            className="video-message-request__form_field-radio"
            component={<RadioButtons name="recipient.forType" options={optionsWithTranslateForType} />}
          />
          <Row
            nameBlock={t('video-message.request.recipient-info.email.label')}
            subNameBlock={t('video-message.request.recipient-info.email.sublabel')}
            isRequired
            component={
              <Input
                name="recipient.toEmail"
                type="email"
                placeholder={t('video-message.request.recipient-info.email.placeholder')}
                requiredMessage="video-message.request.recipient-info.email.error.required"
                isRequired
                validation={{
                  pattern: {
                    value: emailPattern,
                    message: t('video-message.request.recipient-info.email.error.not-valid'),
                  },
                }}
              />
            }
          />
          <Row
            nameBlock={t('video-message.request.recipient-info.name.label')}
            isRequired
            component={
              <Input
                isRequired
                name="recipient.name"
                placeholder={t('video-message.request.recipient-info.name.placeholder')}
                requiredMessage="video-message.request.recipient-info.name.error.required"
              />
            }
          />
          <Row
            nameBlock={t('video-message.request.recipient-info.phone.label')}
            component={
              <div className="video-message-request__form_field-checkbox">
                <PhoneWithAsyncValidation name="recipient.phone" isRequired={false} />
                {/* EN-3151 hide checkbox under phone */}
                {/*<Checkbox*/}
                {/*  name='recipient.phoneDeliverVersion'*/}
                {/*  className='checkbox'*/}
                {/*  label={<p>{t('video-message.request.recipient-info.phone.delivery')}</p>}*/}
                {/*/>*/}
              </div>
            }
          />
        </BlockWithBorder>

        {forType === VideoMessageForType.SOMEONE_ELSE && (
          <BlockWithBorder title="video-message.request.requestor-info.header">
            <RequiredLabel />
            <Row
              nameBlock={t('video-message.request.requestor-info.name.label')}
              isRequired
              component={
                <Input
                  isRequired
                  name="requestor.name"
                  placeholder={t('video-message.request.requestor-info.name.placeholder')}
                  requiredMessage="video-message.request.requestor-info.name.error.required"
                />
              }
            />
            <Row
              nameBlock={t('video-message.request.requestor-info.email.label')}
              isRequired
              component={
                <div className="video-message-request__form_field-checkbox">
                  <Input
                    name="requestor.email"
                    type="email"
                    placeholder={t('video-message.request.requestor-info.email.placeholder')}
                    requiredMessage="video-message.request.requestor-info.email.error.required"
                    isRequired
                    validation={{
                      pattern: {
                        value: emailPattern,
                        message: t('video-message.request.requestor-info.email.error.not-valid'),
                      },
                    }}
                  />
                  <Checkbox
                    name="requestor.emailDeliverVersion"
                    className="checkbox"
                    label={<p>{t('video-message.request.requestor-info.email.delivery')}</p>}
                  />
                </div>
              }
            />
            <Row
              nameBlock={t('video-message.request.requestor-info.phone.label')}
              component={
                <div className="video-message-request__form_field-checkbox">
                  <PhoneWithAsyncValidation name="requestor.phone" isRequired={false} />
                  {/* EN-3151 hide checkbox under phone */}
                  {/*<Checkbox*/}
                  {/*  name='requestor.phoneDeliverVersion'*/}
                  {/*  className='checkbox'*/}
                  {/*  label={<p>{t('video-message.request.requestor-info.phone.delivery')}</p>}*/}
                  {/*/>*/}
                </div>
              }
            />
          </BlockWithBorder>
        )}

        <BlockWithBorder title="video-message.request.my-message.header">
          <RequiredLabel />
          <Row
            nameBlock={t('video-message.request.my-message.occasion.label')}
            isRequired
            className="video-message-request__form_field-radio"
            component={
              <RadioButtons
                name="occasionType"
                options={optionsWithTranslateOccasionType}
                customValidation={{ required: 'shared.words.required' }}
              />
            }
          />
          <Row
            nameBlock={t('video-message.request.my-message.my-script.label')}
            isRequired
            component={
              <TextArea
                name="message.script"
                placeholder={t('video-message.request.my-message.my-script.placeholder')}
                maxLength={500}
                requiredMessage="video-message.request.my-message.my-script.error.required"
                isRequired
              />
            }
          />

          <SubmitButton loader={loader} />
        </BlockWithBorder>

        <HappensBlock fullName={fullName} config={happensBlockConfig} />
      </form>
    </FormProvider>
  )
})

VideoMessageRequestForm.propTypes = {
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  fullName: PropTypes.string,
  loader: PropTypes.bool,
}

export default VideoMessageRequestForm
