import React from 'react';
import PropTypes from 'prop-types'
import { types } from 'shared';

import ExampleHalfBodyImg from 'assets/images/example-half-body.png';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common';
import LibraryWithCrop from '_legacy/components/Library/LibraryWithCrop';
import HalfBodyBackdrop from '_legacy/components/Library/configs/HalfBody/HalfBodyBackdrop';
import PictureWithText from '_legacy/components/Library/configs/common/PictureWithText';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import HalfBodyInfoModal from '_legacy/components/Modal/HalfBodyInfoModal';

const { PHOTO } = types.file.Types

const HalfBodyImage = ({ name, isRequired=true }) => {
  const t = useGetTranslate();

  const dropZoneProps = {
    accept: PHOTO,
  }
  const components = {
    customPicture: PictureWithText,
    customBackdrop: HalfBodyBackdrop
  }
  const cropModalProps = {
    aspect: 3 / 4,
    cropShape: 'rect',
    header: 'Crop Half-Body Image'
  }

  const rules = isRequired ? { required: 'shared.words.required' } : null

  return (
    <Row
      isRequired={isRequired}
      nameBlock={t('register.upload.half-body.label')}
      subNameBlock={t('register.upload.half-body.desc')}
      tip={<TipButton modalComponent={HalfBodyInfoModal} />}
      component={
        <div className="upload-container">
          <div className="upload-item">
            <div className="upload-item__half-body-wrapper">
              <LibraryWithCrop
                name={name}
                rules={rules}
                components={components}
                dropZoneProps={dropZoneProps}
                cropModalProps={cropModalProps}
              />
            </div>
            <div className="upload-label upload-label--light">
              {t('register.upload.hint.desc.half-body')}
            </div>
          </div>
          <div className="upload-item">
            <div className="upload-item__half-body-wrapper">
              <img className="upload-item__image" src={ExampleHalfBodyImg} alt="" />
            </div>
            <div className="upload-label upload-label--dark">
              {t('shared.words.example')}
            </div>
          </div>
        </div>
      }
    />
  )
}

HalfBodyImage.propTypes = {
  name: PropTypes.string,
}


export default HalfBodyImage;
