import React from 'react';
import { string } from 'prop-types';
import { Trans } from 'store/hooks/globalState/useTranslates';
import { COMMERCIAL_ENGAGEMENTS } from '_legacy/constants/routes';
import { Link } from 'react-router-dom';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import CommercialEngagementTextLink from '_legacy/components/Modal/CommercialEngagementTextLink';
const CommercialEngagementText = ({ className = '', nickname }) => {
  return (
    <div className={`commercial-engangement-book ${className}`}>
      <div style={{ display:'flex', justifyContent:'center' }}>
        <Link to={`/${nickname}/${COMMERCIAL_ENGAGEMENTS}` }>
          <p className='text'><Trans tKey='talent-public-view.commercial-engagement.label' /></p>
        </Link>
        <div style={{ justifyContent:'center', margin:'auto' }}>
          <TipButton modalComponent={CommercialEngagementTextLink} />
        </div>
      </div>
    </div>
  );
};

CommercialEngagementText.propTypes = {
  nickname: string.isRequired,
  className: string
};

export default CommercialEngagementText;
