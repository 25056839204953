// Modules
import _get from 'lodash/get'
import head from 'lodash/head'

import { types } from 'shared'

// Utils
import { getRecurringLabel, getRecurringTypesWithTranslate } from '_legacy/components/CreateExperience/components/Recurring/config'
import { getDayTypesWithTranslate } from '_legacy/components/CreateExperience/components/SetDaysTime/config'
import { durationOptions } from '_legacy/components/CreateExperience/utils/timeConfig'

import { parseRecurring } from 'utils/forms/adapters/recurring'

import { getCharitiesForForm } from 'utils/services/loaders/charities'
import { getLocationFormattedAddress } from 'utils/location'
import { getTimeSelectOption } from 'utils/forms/adapters/timeWithTimeZone'
import { getTimeZoneFromOccurrence } from 'utils/forms/adapters/timezone'
import { parseDate, UTCTimeZone } from 'utils/services/timezone'

// Constants
import { minBookingTimeOptions } from '_legacy/components/CreateExperience/components/MinBookingTimeBeforeExperience/config'
import { defaultTimeWindow } from '_legacy/components/CreateExperience/utils/defaultValues'
import { getEntourageAdapter, getEntourageTypeAdapter } from 'utils/forms/adapters/entourage'

const { RECURRING_DAY_TIME_OPTION, UP_TO_5_DAY_TIME_OPTIONS } = types.experiences.DaysTimesType;
const { DOES_NOT_REPEAT } = types.experiences.recurrence.RecurringDefinedInAdvanceValues;
const { FIXED, FLEXIBLE } = types.experiences.TypesOfLocation


function getTimeWindow(timeWindow, timeZoneId) {
  const parsedStartTime = parseDate(_get(timeWindow, 'startTime'), timeZoneId)
  const parsedEndTime = parseDate(_get(timeWindow, 'endTime'), timeZoneId)

  return {
    dateOfExperience: parseDate(_get(timeWindow, 'date'), UTCTimeZone),
    endTime: getTimeSelectOption(parsedEndTime),
    startTime: getTimeSelectOption(parsedStartTime),
  }
}

function getTimeWindows(timeWindows = [], timeZoneId) {
  if (timeWindows.length === 0) return [defaultTimeWindow]
  return timeWindows.map(timeWindow => getTimeWindow(timeWindow, timeZoneId))
}

function getLocation(location) {
  return {
    label: getLocationFormattedAddress(location),
    value: _get(location, 'id'),
  }
}
function getLocationName(location) {
  return {
    label: location.custom_location_name,
    value: location.custom_location_name,
  }
}

function getLocations(location) {
  return {
    name: {
      label: location.custom_location_name,
      value: location.custom_location_name,
    },
    address:{
      label: getLocationFormattedAddress(location),
      value: _get(location, 'id'),
    }
  }
}

function getLocationFields(data) {
  const _locations = _get(data, 'locations', [])
  const typesOfLocation = _get(data, 'typesOfLocation')

  const location = typesOfLocation === FIXED ? getLocation(head(_locations)) : undefined
  const locationName = typesOfLocation === FIXED ? getLocationName(head(_locations)) : undefined
  const locations = typesOfLocation === FLEXIBLE ?  _locations.map(getLocations) : undefined
  return {
    typesOfLocation,
    locations,
    location,
    locationName,
  }
}

function getMinBookingTimeBeforeExperience(data) {
  const minBookingTimeBeforeExperience = _get(data, 'minBookingTimeBeforeExperience')
  return minBookingTimeOptions.find(option => option.value === minBookingTimeBeforeExperience)
}

function getTimeOption(time) {
  return durationOptions.find(({ value }) => value === +time)
}

function getRecurring(data, t) {
  const recurring = _get(data, 'recurring.type')
  return getRecurringTypesWithTranslate(t).find(({ value }) => value === recurring)
}

function isTrue(value) {
  return value ? 'true' : 'false'
}

function getFixedDateProps(data, t) {
  const timeZone = getTimeZoneFromOccurrence(data) || data?.timeZone

  return {
    dateOfExperience: parseDate(_get(data, 'date'), UTCTimeZone),
    startTime: getTimeSelectOption(
      parseDate(_get(data, 'startTime', ''), timeZone)
    ),
    endTime: getTimeSelectOption(
      parseDate(_get(data, 'endTime', ''), timeZone)
    ),
    recurring: getRecurring(data, t),
    recurringModal: parseRecurring(data, t),
  }
}

function getFlexibleDateProps(data, t) {
  const get = (path, defaultValue) =>
    _get(data, path, defaultValue) || defaultValue
  const typeOptions = getDayTypesWithTranslate(t)

  const timeZone = getTimeZoneFromOccurrence(data) || data?.timeZone

  const daysTimesType = get('daysTimesType')
  const occurrencesLimit = get('occurrencesLimit')
  const endsAfter = !!occurrencesLimit

  let timeWindows = [defaultTimeWindow]
  let timeWindow = defaultTimeWindow
  let recurring = {
    value: DOES_NOT_REPEAT,
    label: t(getRecurringLabel(DOES_NOT_REPEAT)),
  }

  if (daysTimesType === UP_TO_5_DAY_TIME_OPTIONS) {
    timeWindows = getTimeWindows(get('timeWindows'), timeZone)
  }

  if (daysTimesType === RECURRING_DAY_TIME_OPTION) {
    timeWindow = getTimeWindow(get('timeWindows[0]'), timeZone)
    recurring = getRecurring(data, t)
  }

  return {
    daysTimesType: typeOptions.find(({ value }) => value === daysTimesType),
    occurrencesLimit,
    endsAfter,
    timeWindows,
    recurring,
    duration: getTimeOption(get('duration')),
    ...timeWindow,
  }
}

function getDateProps(data, t) {
  const typesOfDate = _get(data, 'typesOfDate')

  const typesOfDateConfig = {
    [FIXED]: getFixedDateProps(data, t),
    [FLEXIBLE]: getFlexibleDateProps(data, t),
  }

  return {
    ..._get(typesOfDateConfig, typesOfDate, {}),
    typesOfDate,
  }
}

export default (data, t) => {
  const get = (path, defaultValue) =>
    _get(data, path, defaultValue) || defaultValue

  const deserializedData = {
    name: get('name', ''),
    pricePerParticipant: String(get('pricePerParticipant', '')),
    monthOfUse:get('monthOfUse','3'),
    defaultFormats:get('defaultFormats',[]),
    customFormats:get('customFormats',[]),
    comapnyMayAddLogo:isTrue(get('comapnyMayAddLogo')),
    terms:get('terms',[]),
    commercialUseOfExperiencefootage:isTrue(get('commercialUseOfExperiencefootage')),
    maxNumberOfParticipants: get('maxNumberOfParticipants', ''),
    hideAddressFromPubView: isTrue(get('hideAddressFromPubView')),
    travelTime: {
      before: getTimeOption(get('travelTime.before')),
      after: getTimeOption(get('travelTime.after')),
    },
    hideDayTime: isTrue(get('hideDayTime')),
    photo: get('photo.file'),
    video: get('video.file'),
    displayVideoInsteadOfImage: get('displayVideoInsteadOfImage'),
    // talentMembers: '', // todo: fix with backend
    defaultTaboos: get('defaultTaboos', []),
    customTaboos: get('customTaboos', []),
    takingPicturesOk: isTrue(get('takingPicturesOk')),
    signaturesOk: isTrue(get('signaturesOk')),
    otherInformation: get('otherInformation', ''),
    whatToBring: get('whatToBring', ''),
    ageRequirement: get('ageRequirement', ''),
    charities: getCharitiesForForm(get('charities')),
    minBookingTimeBeforeExperience: getMinBookingTimeBeforeExperience(data),
    entourage: getEntourageAdapter(data),
    entourageType: getEntourageTypeAdapter(data),
    ...getLocationFields(data),
    ...getDateProps(data, t),
  }

  return deserializedData
}
