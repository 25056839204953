import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useChatClient = createUseGlobalState('chatClient')
export const useSetChatClient = createUseSetGlobalState('chatClient')

export const useChatsList = createUseGlobalState('chatsList')
export const useSetChatsList = createUseSetGlobalState('chatsList')

export const useIsLoadingChatsList = createUseGlobalState('isLoadingChatsList')
export const useSetIsLoadingChatsList = createUseSetGlobalState('isLoadingChatsList')

export const useIsLoadingChat = createUseGlobalState('isLoadingChat')
export const useSetIsLoadingChat = createUseSetGlobalState('isLoadingChat')

export const useHasUnreadChatMessages = createUseGlobalState('hasUnreadChatMessages')
export const useSetHasUnreadChatMessages = createUseSetGlobalState('hasUnreadChatMessages')
