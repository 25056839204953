import React from 'react'
import styled from 'styled-components'

import { filtersConfig, counterConfig, subFiltersConfig, fields } from '_legacy/configs/table/managePromoCodes'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useManagePromoCodesFilters, useSetManagePromoCodesFilters,
  useManagePromoCodesTable
} from 'store/hooks/globalState/useManagePromoCodes'
import {
  useFetchManagePromoCodes,
  useInactivatePromoCodes,
  useChangeOfferingsPromoCodes,
} from 'requests/promoCodes/manage-promo-codes'
import { useSetOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes'
import {
  PROMO_CODES_SELECTS_OFFERINGS,
} from '_legacy/components/Modal/modals-types'
import { useFetchOfferingsPromoCodes } from 'requests/promoCodes/promo-codes'

import { useStateModal } from 'store/hooks/globalState/useModal'
import CustomButton from '_legacy/components/Button/CustomButton';
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import { PROMO_CODES, elementsTypes } from '_legacy/components/Table/table-components/types/manage-promo-codes/config'
import Table from '_legacy/components/Table/table-components/Table'
import { NEW } from '../../constants/routes';

const sortFields = {
  NAME: 'name',
  CODE: 'code',
  OFFERINGS_COUNT: 'offeringsCount',
  DISCOUNT: 'discount',
  EXPIRATION_AT: 'expiresAt',
  LIMIT_OF_USERS: 'limitOfUsers',
  STATUS: 'status'
}

const tableConfig = [
  {
    header: 'table.header.name',
    field: sortFields.NAME,
    type: elementsTypes.name
  },
  {
    header: 'table.header.code',
    field: sortFields.CODE,
    type: elementsTypes.code
  },
  {
    header: 'table.header.offerings',
    field: sortFields.OFFERINGS_COUNT,
    hint: 'table.header.hint.offerings',
    type: elementsTypes.offerings
  },
  {
    header: 'table.header.discount',
    field: sortFields.DISCOUNT,
    hint: 'table.header.hint.discount',
    type: elementsTypes.discount
  },
  {
    header: 'table.header.expiration-date',
    field: sortFields.EXPIRATION_AT,
    type: elementsTypes.expirationDate
  },
  {
    header: 'table.header.usage-limit',
    field: sortFields.LIMIT_OF_USERS,
    type: elementsTypes.usageLimit
  },
  {
    header: 'table.header.status',
    field: sortFields.STATUS,
    type: elementsTypes.status
  }
]

const AddPromoCodeButton = React.memo(function AddManagerButton ({ className = '' }) {
  const t = useGetTranslate();
  return (
    <CustomButton
      className={className}
      path={`/${PROMO_CODES}/${NEW}`}
    >
      + {t('table.button.pc')}
    </CustomButton>
  );
});

export default function ManagePromoCodes () {
  const t = useGetTranslate()
  const fetchOfferingsPromoCodes = useFetchOfferingsPromoCodes();
  const inactivatePromoCodes = useInactivatePromoCodes()
  const changeCustomOfferings = useStateModal(PROMO_CODES_SELECTS_OFFERINGS)
  const clearSelected = useSetOfferingsPromoCodesFilters(prev => ({
    ...prev,
    selected: [],
  }))
  const changeOfferings = useChangeOfferingsPromoCodes()

  React.useEffect(() => {
    fetchOfferingsPromoCodes()
    return clearSelected
  }, [])

  const onDone = ({ selected, props }) => {
    changeOfferings({
      talentOffersIds: selected.map(item => item.id),
      promoCodeIds: Object.values(props),
    })
  }

  const actionsConfig = React.useMemo(() => {
    return [
      {
        label: t('table.actions.manage-custom-offerings'),
        cbFn: props => changeCustomOfferings({ onDone, props }),
      },
      {
        label: t('table.actions.permanently-inactivate-promo-code'),
        cbFn: inactivatePromoCodes,
      },
    ]
  }, [t])
  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useManagePromoCodesFilters,
      setFilters: useSetManagePromoCodesFilters,
      actions: [
        {
          label: t('table.actions.manage-custom-offerings'),
          cbFn: props => changeCustomOfferings({ onDone, props }),
        },
        {
          label: t('table.actions.permanently-inactivate-promo-code'),
          cbFn: inactivatePromoCodes,
        },
      ],
    }
  }, [t, useManagePromoCodesFilters, useSetManagePromoCodesFilters])
  return (
    <StyledWrapper className='wrapper content-wrapper'>
      <h1 className='title'>{t('table.title.manage-promo-codes')}</h1>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useManagePromoCodesFilters}
        setFn={useSetManagePromoCodesFilters}
        ButtonComponent={AddPromoCodeButton}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useManagePromoCodesFilters}
        setFn={useSetManagePromoCodesFilters}
      />
      <Table
        tableName={PROMO_CODES}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        bulkActionsConfig={bulkActionsConfig}
        getTableFn={useManagePromoCodesTable}
        fields={fields}
        getFiltersFn={useManagePromoCodesFilters}
        setFiltersFn={useSetManagePromoCodesFilters}
        fetchTableFn={useFetchManagePromoCodes}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 40px;
  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: 900;
    line-height: 75px;
    color: ${({ theme: { colors } }) => colors.blue};
    text-align: center;
  }
  .row-content {
    min-height: 82px;
  }
`
