import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isNil from 'lodash/isNil'
import ModalPortal from 'portals/ModalPortal'
import Button from '_legacy/components/Button'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useExperience,
  useSetExperience
} from 'store/hooks/globalState/offerings/experience'
import {
  useFetchExperienceDraft,
  useCreateExperienceDraft,
  usePublishExperienceDraft,
  useUpdateExperienceDraft,
} from 'requests/offerings/experience'

import CreateExperienceForm from '_legacy/components/CreateExperience'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import PageLoader from '_legacy/common/Template/PageLoader'

import { getUserTimeZone } from 'utils/user'
import { createExperienceAdapter } from '_legacy/components/CreateExperience/utils/createExperienceAdapter'
import { getDefaultValues } from '_legacy/components/CreateExperience/utils/defaultValues'
import getExperienceAdapter from '_legacy/components/CreateExperience/utils/getExperienceAdapter'

const EditExperienceDraft = () => {
  const t = useGetTranslate()
  const publishExperienceDraft = usePublishExperienceDraft()
  const createExperienceDraft = useCreateExperienceDraft()
  const updateExperienceDraft = useUpdateExperienceDraft()
  const fetchExperienceDraft = useFetchExperienceDraft()
  const clearOccurrence = useSetExperience(() => null)
  const experienceDraft = useExperience()
  const timeZone = useUser(getUserTimeZone)

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    shouldUnregister: false,
  })

  const [nameValue, setNameValue] = React.useState('')
  const [sameName, setSameName] = React.useState(false)
  const [focus, setfocus] = React.useState(false)

  const onSubmit =
    compose(publishExperienceDraft, createExperienceAdapter)


  const updateNameValue = newValue => {
    setNameValue(newValue)
  }

  const closeHandler = () => {
    setSameName(false)
  }

  const updateExistingDraft = compose(
    publishExperienceDraft,
    updateExperienceDraft,
    createExperienceAdapter,
    methods.getValues
  )

  const changeName = () => {
    setfocus(true)
    window.scrollTo(0, 0)
    closeHandler()
  }

  const saveNewDraft = compose(
    publishExperienceDraft,
    createExperienceDraft,
    createExperienceAdapter,
    methods.getValues
  )

  const onDraft = () => {
    if (nameValue == '' || nameValue === methods.control.defaultValuesRef.current.name) {
      setSameName(true)
    } else {
      setSameName(false)
      saveNewDraft()
    }
  }

  React.useEffect(() => {
    fetchExperienceDraft()
    return () => {
      clearOccurrence()
    }
  }, [])

  React.useEffect(() => {
    if (!isNil(experienceDraft)) {
      methods.reset(getExperienceAdapter({ ...experienceDraft, timeZone }, t), { isDirty: false })
    }
  }, [experienceDraft])

  if (isNil(experienceDraft)) {
    return <PageLoader />
  }

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('create-experience.header.edit-draft')}</OfferingTitle>
      <CreateExperienceForm
        methods={methods}
        onSubmit={onSubmit}
        onDraft={onDraft}
        isSubmitDisabled={false}
        updateNameValue={updateNameValue}
        isFocus={focus}
      />
      <div>
        {sameName && (
          <ModalPortal isOpen isMobileFullScreens showCloseButton onClose={closeHandler}>
            <div className="edit-event-modal">
              <h1 className="modal-header">{t('create-experience.alert.header.same-name')}</h1>
              <p className="modal-text">{t('create-experience.alert.header.alert-message')}</p>
              <div className="edit-event-modal__controls mobileModalScreen">
                <Button
                  primary
                  className="mms"
                  handleOnClick={changeName}
                  text={t('create-experience.alert.action.change-name')}
                />
                <Button
                  className="mms"
                  handleOnClick={updateExistingDraft}
                  text={t('create-experience.alert.action.replace-current')}
                />
              </div>
            </div>
          </ModalPortal>
        )}
      </div>
    </OfferingTemplate>
  )
}

export default withLibrary(EditExperienceDraft)
