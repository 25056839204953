import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const calculatePointerLineColors = (value) => {
  const from = {
    r: 39,
    b: 40,
    g: 53
  };

  const to = {
    r: 160,
    b: 161,
    g: 168
  };

  const result = {
    r: from.r + (to.r - from.r) * value,
    b: from.b + (to.b - from.b) * value,
    g: from.g + (to.g - from.g) * value
  };

  return {
    firstLine:`linear-gradient(90deg, rgb(39,40,53) 0%, rgb(${result.r},${result.b},${result.g}) 100%)`,
    arrow: `rgb(${result.r},${result.b},${result.g})`,
    secondLine: `linear-gradient(90deg, rgb(${result.r},${result.b},${result.g}) 0%, rgb(160,161,168) 100%)`,
  };
};

const Pointer = ({ currentActive }) => {
  const offset = 7 + (currentActive * 44);

  const colors = calculatePointerLineColors(currentActive / 6);

  return (
    <StyledWrapper className="tbv-mobile-offers-navigation-pointer">
      <StyledLine maxWidth={offset} bgc={colors.firstLine} />
      <svg fill="none" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
        width="13.8px" height="9.5px" viewBox="0 0 13.8 9.5" >
        <path d="M0,9h2l5-8l5,8h1.9" stroke={colors.arrow}/>
      </svg>
      <StyledLine bgc={colors.secondLine}/>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  height: 8px;
  display: flex;
  align-items: flex-end;
`;

const StyledLine = styled.div`
  height: 1px;
  background-image: ${({ bgc }) => bgc};
  max-width: ${({ maxWidth }) => maxWidth}px;
  &:first-child {
    width: 100%;
    transition: max-width .3s ease;
  }
  &:last-child { flex: 1; }
`

Pointer.propTypes = {
  currentActive: PropTypes.number.isRequired
};

export default Pointer;
