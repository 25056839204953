import styled from 'styled-components'

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 220px;
  margin-right: 8px;
  
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input {
        height: auto;
        padding: 10px 18px;
        background: #fff;
        font-size: 16px;
        border-color: ${({ theme: { colors } }) => colors.ultraLightBlue};
        border-radius: 2px;
        transition: all 0.2s;
        :hover {
          border: solid 2px ${({ theme: { colors } }) => colors.blue};
        }
        :focus {
          border: solid 2px ${({ theme: { colors } }) => colors.blue};
        }
        &:disabled {
          background: ${({ theme: { colors } }) => colors.ultraLightBlue};
          border-color: ${({ theme: { colors } }) => colors.ultraLightBlue};
        }
      }
    }
  }
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      z-index: 10000;
    }
  }
  .icon {
    position: absolute;
    right: 12px;
    top: 50%;
    background: transparent;
    transform: translate(0, -50%);
    pointer-events: none;
  }

  .react-datepicker__current-month {
    text-align: start;
  }
`

export default StyledWrapper
