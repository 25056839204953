import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';

import { useFileEntityById } from 'store/hooks/globalState/useFileEntites';
import { useUploadFileToEntities } from 'requests/upload';
import CropModal from '../../../Modal/CropModal';


const LibraryCropModal = ({ closeModal, isModalOpen, name, tempName, cropShape, aspect, header }) => {
  const { setValue, watch } = useFormContext()
  const image = get(useFileEntityById(watch(tempName)), 'fullUrl');

  const uploadFileToEntities = useUploadFileToEntities(
    file => setValue(name, file.fileToken),
    res => setValue(name, res.file.fileToken, { shouldValidate: true, shouldDirty: true })
  );

  const onComplete = (file) => {
    uploadFileToEntities(file)
    setValue(tempName, null)
    closeModal()
  }

  return (
    <CropModal
      closeModal={closeModal}
      isModalOpen={isModalOpen}
      onComplete={onComplete}
      image={image}
      onBack={false}
      aspect={aspect}
      cropShape={cropShape}
      header={header}
    />
  );
};

LibraryCropModal.defaultProps = {
  cropShape: 'rect',
  aspect: 1
};

LibraryCropModal.propTypes = {
  header: PropTypes.string,
  closeModal: PropTypes.func,
  isModalOpen: PropTypes.bool,
  tempFile: PropTypes.object,
  name: PropTypes.string,
  tempName: PropTypes.string,
  cropShape: PropTypes.oneOf(['rect', 'round']),
  aspect: PropTypes.number,
};

export default LibraryCropModal;
