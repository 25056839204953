import React from 'react'
import get from 'lodash/get'

import Icon from 'assets/icons/Icon'
import { Trans } from 'store/hooks/globalState/useTranslates'

const CALENDAR_HOUR_HEIGHT = 48 // In pixels
const SECONDS_PER_HOUR = 3600

// Drive time - in seconds
const getDriveTimeHeight = driveTime =>
  [driveTime]
    .map(time => `${(time / SECONDS_PER_HOUR) * CALENDAR_HOUR_HEIGHT}px`)
    .map(height => ({ height: height, minHeight: height }))
    .pop()

const showDriveTimeIcon = time => time >= 1800

const OfferSlot = props => {
  const timeText = get(props, 'timeText', '')
  const title = get(props, 'event.title', '')
  const beforeDriveTime = get(props, 'beforeDriveTime')
  const afterDriveTime = get(props, 'afterDriveTime')

  return (
    <div className="calendar-slot">
      {!!beforeDriveTime && (
        <div className="calendar-slot__before" style={getDriveTimeHeight(beforeDriveTime)}>
          {showDriveTimeIcon(beforeDriveTime) && <Icon.DrivingTime />}
        </div>
      )}

      <div className="calendar-slot__info">
        <div className="calendar-slot__info__title">{title || <Trans tKey="calendar.slot.default-title" />}</div>
        <div className="calendar-slot__info__desc">{timeText}</div>
      </div>

      {!!afterDriveTime && (
        <div className="calendar-slot__after" style={getDriveTimeHeight(afterDriveTime)}>
          {showDriveTimeIcon(afterDriveTime) && <Icon.DrivingTime />}
        </div>
      )}
    </div>
  )
}

export default OfferSlot
