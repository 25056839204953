import styled from 'styled-components';

const SubModalHeader = styled.h6`
  font-family: Montserrat;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.grey};
  font-size: 16px;
  line-height: 1.625;
  text-align: center;


  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
`;

export default SubModalHeader;
