import React from 'react';
import styled from 'styled-components';
import get from 'lodash/get';

import { getCharitiesLabel } from 'utils/services/loaders/charities';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements';
import { useParams } from 'react-router-dom';


const Charities = () => {
  const t = useGetTranslate();
  const commercialEngagements = useCommercialEngagements();
  const { commercialVideoId } = useParams()
  const commercialVideoMessages = get(
    commercialEngagements,
    'commercialVideoMessages',[]
  );
  const currentCommercialVideo = commercialVideoMessages.filter(element => element.id === commercialVideoId)
  const whichCharities = get(currentCommercialVideo[0],'whichCharities')
  const hasCharities = Boolean(whichCharities.length)

  return (
    <CharitiesContainer>
      {
        hasCharities && (
          <>
            <CharitiesHeader>{t('commercial-engagements.main.charities.header')}</CharitiesHeader>

            <CharitiesList>
              {whichCharities.map(charity => (
                <CharitiesListItem key={charity.label}>
                  &nbsp;{getCharitiesLabel(charity.charity)} ({charity.percent}%);
                </CharitiesListItem>
              ))}
            </CharitiesList>
          </>
        )
      }
    </CharitiesContainer>
  );
};

const CharitiesContainer = styled.div`
  margin-bottom: 12px;

  @media screen and (max-width: 991px) {
    margin-bottom: 32px;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
  }
`;

const CharitiesHeader = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  display: inline;

  color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 767px) {
    text-align: center;
    display: block;
  }
`;

const CharitiesList = styled.ul`
  display: inline;

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const CharitiesListItem = styled.li`
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  font-size: 16px;
  line-height: 26px;
  display: inline;

  color: ${({ theme }) => theme.colors.blue};

  @media screen and (max-width: 767px) {
    text-align: center;
    display: block;
  }
`;

export default Charities;
