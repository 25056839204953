import React from 'react';
import PropTypes from 'prop-types';

import { CustomVideoPlayer } from '_legacy/common/custom-video/CustomVideoPlayer/'

const VideoViewer = React.memo(function VideoViewer ({ isMain, file }) {
  return (
    <CustomVideoPlayer
      file={file}
      isStoped={!isMain}
    />
  );
});

VideoViewer.propTypes = {
  fullUrl: PropTypes.string.isRequired,
  isMain: PropTypes.bool.isRequired,
};

export default VideoViewer;
