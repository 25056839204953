import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Slider from '../../../Slider';
import Slide from '../../../Slide';

import Actions from './Actions';
import Empty from './Empty';

const Lessons = ({ covers = [], firstName, plaqueNickname }) => {
  const t = useGetTranslate()

  const slides = covers.map((cover, i) => (
    <Slide.DesktopSlide key={`lessons-${i}`} file={cover.media} isPromo={cover.isPromo}  />
  ))

  return (
    <Slider
      size="big" // change to 'small' if you want small slider
      title={t('talent-public-view.sliders.lessons.header.label')}
      slides={slides}
      empty={<Empty firstName={firstName} />}
      withGradientOverhead={true}
      isMirroredInside={true}
      Actions={Actions}
      bottomSpace={40}
      plaqueNickname={plaqueNickname}
    />
  );
};

Lessons.propTypes = {
  covers: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  plaqueNickname: PropTypes.string,
};

export default Lessons
