import React from 'react';
import styled from 'styled-components';

import UploaderFiles from '_legacy/common/UploaderFiles';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Licenses = () => {
  const t = useGetTranslate();

  return (
    <StyledWrapper
      name='licenses'
      label={t('register.form.poa.label.talent')}
      subLabel={t('register.form.poa.desc.talent')}
      titleHint={t('register.upload.hint.file.title')}
      className='licenses'
    />
  );
};

const StyledWrapper = styled(UploaderFiles)`
  .dropzone {
    height: 230px;
  .wrapper {
    height: 100%;
    border-radius: 4px;
  }
`;

export default Licenses;
