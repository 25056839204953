import React from 'react'
import get from 'lodash/get'
import has from 'lodash/has'
import { BehaviorSubject } from 'rxjs'
import HTML from 'html-parse-stringify2'

import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useUserLang = createUseGlobalState('userLang');
export const useSetUserLang = createUseSetGlobalState('userLang');

export const useTranslates = createUseGlobalState('translates');
export const useSetTranslates = createUseSetGlobalState('translates');

const translates = new BehaviorSubject(null)

export function t (str = '') {
  return get(translates.value, str, '')
}

export function useFetchTranslates () {
  const setTranslates = useSetTranslates((prev, next) => next)
  return React.useCallback((userLang) => {
    fetch(`${process.env.REACT_APP_LOCALES_URL}${userLang.toLowerCase()}.json`)
      .then(res => res.json())
      .then((data) => {
        translates.next(data)
        setTranslates(data)
      })
      .catch((err) => console.warn(err))
  }, [setTranslates])
}

export function useGetTranslate () {
  const translates = useTranslates()
  return React.useCallback((str = '') => {
    const translate = get(translates, str, str)
    if (typeof translate !== 'string') {
      console.warn(`Not valid key: ${str}`)
      return str
    }
    return translate
  }, [translates])
}

const supportedTagsname = ['br', 'span', 'strong', 'i', 'b', 'a', 'blockquote']

function getTagName (name) {
  if (supportedTagsname.includes(name)) return name
  return 'span'
}

function parseNodes (children) {
  const nodes = Array.isArray(children) ? children : [children]
  return nodes.reduce((mem, node, i) => {
    const { type, content, voidElement, name, attrs, children } = node
    if (type === 'text') {
      mem.push(content)
      return mem
    }
    if (type === 'tag') {
      if (voidElement) {
        mem.push(React.createElement(getTagName(name), { key: `${name}-${i}` }))
        return mem
      }
      const inner = parseNodes(children)
      mem.push(React.createElement(getTagName(name), { key: `${name}-${i}`, ...attrs }, inner))
      return mem
    }
    return mem
  }, [])
}

function replaceAll(string, pattern, replacement) {
  return string.replace(new RegExp(pattern, 'g'), replacement)
}

export function Trans ({ tKey, phs = [], children }) {
  if (!tKey) return children || null

  let translate = useTranslates(current => get(current, tKey, ''))

  if (!translate) return children || null

  phs.forEach(({ ph, value }) => (translate = replaceAll(translate, ph, value)))

  return parseNodes(HTML.parse(`<0>${translate}</0>`)[0].children)
}

export function useGetTranslateWithKey() {
  const translates = useTranslates()

  return React.useCallback(({ tKey, phs = [] }) => {
    let translate = get(translates, tKey, '')
    phs.forEach(({ ph, value }) => (translate = replaceAll(translate, ph, value)))
    return translate
  }, [translates])
}

/**
 * Function that inserts values for a `<Trans>` component translations.
 * @argument {obj[]} tConfig - config with translates keys and ph keys.
 * @argument {obj[]} phsConfig - config of phs.
 *
 * Structure and usecase:
 * ------------
 * `tConfig`:
 * ------------
 * @param {string} tConfig[].key - translation sting key (required).
 * @param {obj[]} tConfig[].phs - translation phrase (optional).
 * @param {string} tConfig[].phs[].key - translation phrase key.
 * @param {null} tConfig[].phs[].value -always null (to be replaced by the function).
 * [
 *   {
 *      key: 'localisation.key'
 *      phs: [
 *        {
 *          ph: '{{ somePhKey }}',
 *          value: null,
 *        }
 *      ...
 *      ]
 *   },
 *   ...
 * ]
 * ------------
 * `phsConfig`:
 * ------------
 * @param {} phsConfig[key] - value for a translation key. `key` should be the same as `ph` in `tConfig`.
 * [
 *    {
 *      ['{{ somePhKey }}']: value,
 *    },
 *    ...
 * ]
 */
export const insertPhsValue = (tConfig, phsConfig) =>
  tConfig.reduce((acc, tItem) => {
    const tPhs = get(tItem, 'phs', [])
    const phs = tPhs.map(tPhsItem =>
      phsConfig
        .filter(phsConfigItem => has(phsConfigItem, tPhsItem.ph))
        .map(phsConfigItem => {
          return ({
            ph: tPhsItem.ph,
            value: phsConfigItem[tPhsItem.ph] || '',
          })
        }
        )
        .pop()
    )

    const item = phs?.length
      ? { ...tItem, phs, }
      : tItem

    acc.push(item)

    return acc
  }, [])
