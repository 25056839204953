// Modules
import React from 'react'

// Components
import BottomNavigationPanelItem from './BottomNavigationPanelItem'


const BottomNavigationPanel = ({ items = [] }) => {
  return (
    <div className="bottom-navigation__panel">
      {
        items
          .map(BottomNavigationPanelItem)
      }
    </div>
  )
}

export default BottomNavigationPanel
