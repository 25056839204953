// TODO: refactor resize detect without using global store to avoid useless rerenders
import React from 'react'

import { useSetWindowDimensions } from 'store/hooks/globalState/useGlobalState'


const withWindowDimentions = Component => props =>  {
  const setWindowDimensions = useSetWindowDimensions((prev, next) => next, [])
  const windowDimensionsHandler = React.useCallback(() => {
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [setWindowDimensions])

  React.useEffect(() => {
    window.addEventListener('resize', windowDimensionsHandler)
    return () => window.removeEventListener('resize', windowDimensionsHandler)
  }, [])

  return <Component {...props} />
}

export default withWindowDimentions
