import map from 'lodash/map';
import get from 'lodash/get';
import compact from 'lodash/compact';
import { submitMultiSelectLibraryAdapter } from 'utils/services/validators/library';

import { FILE_ID } from 'store/hooks/globalState/useFileLibrary';

export default (value) => ({
  productName: get(value, 'productName', '').trim(),
  productPrice: parseInt(get(value, 'productPrice', null), 10),
  productDescription: get(value, 'productDescription', null),
  productReceptionInstructions: get(value, 'productReceptionInstructions', null),
  deliveryType: value.deliveryType,
  productUpload: compact(map(value.productUpload, FILE_ID)),
  ...submitMultiSelectLibraryAdapter('media', value)
})
