import React from 'react';
import { useFormContext } from 'react-hook-form';
import Time from '../Time';
import Recurring from '../Recurring';
import TravelTime from '../TravelTime';
import HideDayTime from '../HideDayTime';
import DateOfExperience from '../DateOfExperience';

const OneTime = () => {
  const { watch } = useFormContext()
  const dateOfExperience = watch('dateOfExperience')

  return (
    <>
      <DateOfExperience name='dateOfExperience' />
      <Time selectedDate={dateOfExperience} />
      <Recurring />
      <TravelTime />
      <HideDayTime />
    </>
  );
};

export default OneTime;
