import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'


// Heading
export const useReadyMadeEventLessonsDetailsHeader = createUseGlobalState('readyMadeEventLessonsDetailsHeader')
export const useSetReadyMadeEventLessonsDetailsHeader = createUseSetGlobalState('readyMadeEventLessonsDetailsHeader')

// Order Table
export const useReadyMadeEventLessonsDetailsOrderTable = createUseGlobalState('readyMadeEventLessonsDetailsOrderTable')
export const useSetReadyMadeEventLessonsDetailsOrderTable = createUseSetGlobalState('readyMadeEventLessonsDetailsOrderTable')

export const useReadyMadeEventLessonsDetailsOrderFilters = createUseGlobalState('readyMadeEventLessonsDetailsOrderFilters')
export const useSetReadyMadeEventLessonsDetailsOrderFilters = createUseSetGlobalState('readyMadeEventLessonsDetailsOrderFilters')

// Product table
export const useReadyMadeEventLessonsDetailsProductTable = createUseGlobalState('readyMadeEventLessonsDetailsProductTable')
export const useSetReadyMadeEventLessonsDetailsProductTable = createUseSetGlobalState('readyMadeEventLessonsDetailsProductTable')

export const useReadyMadeEventLessonsDetailsProductFilters = createUseGlobalState('readyMadeEventLessonsDetailsProductFilters')
export const useSetReadyMadeEventLessonsDetailsProductFilters = createUseSetGlobalState('readyMadeEventLessonsDetailsProductFilters')
