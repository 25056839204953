import { _get, _post } from 'api/utils'

export const fetchManagerOrders = ({ id, query }) =>
  _get(`/talents/${id}/orders/`, {
    needAuthorization: true,
    query,
  })

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })
