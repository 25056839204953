import { enums } from 'shared';
import { formUnderscoreToKebabCase } from './utils';

export const typeOfDateOptions = [
  {
    value: enums.TypesOfDate.ONE_TIME,
  },
  {
    value: enums.TypesOfDate.RECURRING,
  },
  {
    value: enums.TypesOfDate.FLEXIBLE,
    subtitle: 'date-type.flexible.subtext',
  },
  {
    value: enums.TypesOfDate.TBD,
    subtitle: 'date-type.tbd.subtext',
  },
];


export const typeOfLocationOptions = [
  {
    value: enums.TypesOfLocation.FIXED,
  },
  {
    value: enums.TypesOfLocation.FLEXIBLE,
  },
  {
    value: enums.TypesOfLocation.TBD,
    subtitle: 'date-type.tbd.subtext',
  },
];

export const daysInWeek = [
  'experience.modal.day.sun',
  'experience.modal.day.mon',
  'experience.modal.day.tue',
  'experience.modal.day.wed',
  'experience.modal.day.thu',
  'experience.modal.day.fri',
  'experience.modal.day.sat',
];

export const repeatEveryType = [
  {
    label: 'experience.modal.type.day',
    value: enums.Recurrence.RepeatEveryType.DAY,
  },
  {
    label: 'experience.modal.type.week',
    value: enums.Recurrence.RepeatEveryType.WEEK,
  },
  {
    label: 'experience.modal.type.month',
    value: enums.Recurrence.RepeatEveryType.MONTH,
  },
  {
    label: 'experience.modal.type.year',
    value: enums.Recurrence.RepeatEveryType.YEAR,
  },
];


export const recurringTypes = [
  ...Object.values(enums.Recurrence.RecurringDefinedInAdvanceValues).map(v => ({
    value: v,
    label: formUnderscoreToKebabCase(v),
  })),
  { value: 'custom', label: 'custom' },
];


export const typeOfLocationWithTranslate = (t) => typeOfLocationOptions.map(type => ({
  ...type,
  subtitle: type.subtitle && t(`experience.${type.subtitle}`),
  label: t(`experience.${type.value}`)
}));

export const typeOfDateWithTranslate = (t) => typeOfDateOptions.map(type => ({
  ...type,
  subtitle: type.subtitle && t(`experience.${type.subtitle}`),
  label: t(`experience.${type.value}`)
}));
