import React from 'react'
import get from 'lodash/get'
import Main from './Main';
import PageLoader from '_legacy/common/Template/PageLoader'
import { useFetchCommercialEngagements } from 'requests/commercial-engagements'
import {
  useCommercialEngagements,
  useSetCommercialEngagements,
} from 'store/hooks/globalState/useCommercialEngagements'
import { scrollToRef } from 'utils/scrollToRef'
import { useParams } from 'react-router-dom';

const CommercialEngagements = () => {
  const commercialEngagements = useCommercialEngagements()
  const fetchCommercialEngagements = useFetchCommercialEngagements()
  const clearCommercialEngagements = useSetCommercialEngagements(() => null)
  const { commercialVideoId }  = useParams()
  const commercialVideoMessage = get(commercialEngagements, 'commercialVideoMessages',[])
  const currentCommercialVideoMessage = commercialVideoMessage.filter(element => element.id === commercialVideoId);
  const $el = document.querySelector('#header')

  const relationshipRef = React.useRef(null)

  React.useEffect(() => {
    fetchCommercialEngagements()
    return () => {
      clearCommercialEngagements()
    }
  }, [])

  React.useEffect(() => {
    if ($el) {
      $el.classList.remove(currentCommercialVideoMessage ? 'dark-theme' : 'light-theme')
      $el.classList.add(currentCommercialVideoMessage ? 'light-theme' : 'dark-theme')
    }
  }, [commercialEngagements, $el])

  if (!commercialEngagements) return <PageLoader />

  const scrollToRelationship = () => scrollToRef(relationshipRef)

  return (
    <>
      {currentCommercialVideoMessage && (
        <div className='content-wrapper'>
          <Main onClick={scrollToRelationship} currentCommercialVideoMessage={currentCommercialVideoMessage}/>
        </div>
      )}
    </>
  );
};

export default CommercialEngagements
