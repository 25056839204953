import { propEq } from 'lodash/fp'
import { recurringTypesOptions, typeOptions } from '../../Calendar/components/NewEventForm/options'

export const getRecurringOption = (t, value) => {
  const option = recurringTypesOptions.find(propEq('value', value))
  return option && { ...option, label: t(option.label) }
}

export const getEventTypeOption = (t, value) => {
  const option = typeOptions.find(propEq('value', value))
  return option && { ...option, label: t(option.label) }
}
