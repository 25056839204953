import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import propOr from 'lodash/fp/propOr'
import prop from 'lodash/fp/prop'
import compact from 'lodash/fp/compact'
import isEmpty from 'lodash/fp/isEmpty'
import { types } from 'shared'
const { PHOTO, VIDEO } = types.file.Types

import useScroll from 'store/hooks/useScroll'
import { useTalentLibraryId, useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFileLibrary, useLibraryFiles } from 'store/hooks/globalState/useFileLibrary'
import { useFetchBulkFileLibrary } from 'requests/file-library'

import LoaderIndicator from '_legacy/components/Common/Loader'

const FileList = React.memo(({ children }) => {
  const parentRef = React.useRef();
  const childRef = React.useRef();

  const t = useGetTranslate()
  const files = useLibraryFiles()
  const uniqueIds = new Set();

  // Use filter to remove duplicates based on "id"
  const uniqueFilesArray = files.filter(file => {
    if (!uniqueIds.has(file.id)) {
      uniqueIds.add(file.id);
      return true;
    }
    return false;
  });
  const talentLibraryId = useTalentLibraryId()
  const userLibraryId = useUser(prop('user.fileLibrary.id'))
  const fileLibrariesIds = [talentLibraryId, userLibraryId].filter(Boolean)
  const fetchFileLibrary = useFetchBulkFileLibrary()
  const page = useFileLibrary(propOr(0, 'pageInfo.page'))
  const remains = useFileLibrary(propOr(0, 'pageInfo.remains'))

  const fetchNext = () => fetchFileLibrary({
    fileLibrariesIds,
    types: [PHOTO, VIDEO],
    page: page + 1
  })

  useScroll({ parentRef, childRef, callback: fetchNext, hasMore: remains > 0 })
  return (
    <FileListWrapper>
      {!isEmpty(uniqueFilesArray) && (
        <FileListHeader>{t('file-library.file-list.header')}</FileListHeader>
      )}

      <FileListInner ref={parentRef}>
        {compact(uniqueFilesArray).map(children)}

        <Loader ref={childRef}>
          {remains > 0 && <LoaderIndicator size={24} fill='#636583' />}
        </Loader>
      </FileListInner>
    </FileListWrapper>
  )
})

const Loader = styled.div`
  height: 20px;
  display: flex;
  justify-content: center;
`

const FileListWrapper = styled.div`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
`;

const FileListHeader = styled.h4`
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 1.69;
  color: ${({ theme }) => theme.colors.blue};

  margin-bottom: 12px;

  @media screen and (max-width: 414px) {
    font-size: 12px;
    line-height: 1.33;
  }
`

const FileListInner = styled.ul`
  -ms-overflow-style: none;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  flex: 1;
  max-height: 244px;
  overflow-y: auto;
  overflow-x: hidden;

  .checkboxCustom {
    padding-left: 0;
  }

  @media screen and (max-width: 1366px) {
    //padding-left: 24px; // for shadow
    max-height: 187px;
  }

  @media screen and (max-width: 1280px) {
    //max-height: 180px;
  }

  @media screen and (max-width: 768px) and (max-height: 1024px) {
    //max-height: 308px;
  }

  @media screen and (max-width: 414px) {
    //padding-left: 16px; // for shadow
    max-height: none;
    overflow-y: scroll;
    height: 100%;
  }
`

FileList.propTypes = {
  children: PropTypes.element,
}

export default FileList
