import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import { propOr, prop, identity, pipe, split, propEq } from 'lodash/fp'
import { api } from 'shared'
const { FOLLOWERS_COUNT, NAME } = api.searches.results.talents.search.SortField
const { PROXIMITY } = api.searches.results.offers.search.SortField

import { TALENT } from '_legacy/constants/routes'
import { routesList } from 'router/routesMap'
import { useGetTranslate, useGetTranslateWithKey } from 'store/hooks/globalState/useTranslates'
import CustomSubSelect from '_legacy/components/Table/SubFilters/CustomSubSelect'

const istSearchTypeTalent = pipe(
  split('/'),
  propEq(2, TALENT)
)

const subSelectOptions = {
  [NAME]: { value: NAME, label: 'search.list-header.select.name.label' },
  [FOLLOWERS_COUNT]: { value: FOLLOWERS_COUNT, label: 'search.list-header.select.popular.label' },
  [PROXIMITY]: { value: PROXIMITY, label: 'search.list-header.select.proximity.label' },
}

const ListHeader = ({ getFn, setFn }) => {
  const location = useLocation()
  const isTalent = istSearchTypeTalent(location.pathname)

  const t = useGetTranslate()
  const tKey = useGetTranslateWithKey()

  const count = getFn(propOr(0, 'count'))
  const sortField = getFn(prop('sortField'))
  const updateState = setFn((prev, next) => ({ ...prev, ...next }))

  const prevSortField = React.useRef(sortField)
  const [localField, setLocalField] = React.useState({ sortField })
  const getLocalFn = (selector = identity) => selector(localField)
  const setLocalFn = (fn) => (next) => setLocalField(prev => fn(prev, next))

  const linkUrl = isTalent
    ? routesList.searchOfferings()
    : routesList.searchTalent()

  const linkKey = isTalent
    ? 'search.list-header.link.offerings'
    : 'search.list-header.link.talent'

  const countLabel = isTalent
    ? tKey({ tKey: 'search.list-header.show.talent', phs: [{ ph: '{{count}}', value: count }] })
    : tKey({ tKey: 'search.list-header.show.offerings', phs: [{ ph: '{{count}}', value: count }] })

  const subSelectConfig = isTalent
    ? [subSelectOptions[NAME], subSelectOptions[FOLLOWERS_COUNT]]
    : [subSelectOptions[NAME], subSelectOptions[PROXIMITY]]

  React.useEffect(() => {
    if (localField.sortField === PROXIMITY) {
      navigator.geolocation.getCurrentPosition(
        position => {
          updateState({
            sortByLocationLatitude: position.coords.latitude,
            sortByLocationLongitude: position.coords.longitude,
            sortField: PROXIMITY
          })
        },
        () => {
          setLocalField({
            sortField: prevSortField.current
          })
          updateState({
            sortByLocationLatitude: null,
            sortByLocationLongitude: null,
            sortField: prevSortField.current
          })
        }
      )
    } else {
      updateState({
        sortField: localField.sortField
      })

      prevSortField.current = localField.sortField
    }
  }, [localField])

  return (
    <div className="search-header">
      <div className="search-header__info">
        <p className="search-header__count">{countLabel}</p>
        <p className="search-header__dropdown">
          <CustomSubSelect
            options={subSelectConfig}
            field="sortField"
            getFn={getLocalFn}
            setFn={setLocalFn}
          />
        </p>
      </div>
      <Link className="search-header__link" to={linkUrl}>
        {t(linkKey)}
      </Link>
    </div>
  )
}

ListHeader.propTypes = {
  getListFn: PropTypes.func,
  getFn: PropTypes.func,
  setFn: PropTypes.func,
}

export default ListHeader
