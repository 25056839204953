import { appApi } from '../../../api';
import { getAsyncLoad } from './common';

export const initialCharities = [
  {
    charity: null,
    percent: '',
  },
];

export const getCharitiesLabel = ({ name = '', ein = '' }) => name ? `${name}${ein ? ` (${ein})` : ''}` : null;

export const charitiesPreparator = item => ({
  value: item.id,
  label: getCharitiesLabel(item),
});

export const loadCharitiesOptions = getAsyncLoad(
  appApi.charity.getCharities,
  'charities',
  charitiesPreparator,
);

export const getCharitiesForSubmit = (charities = []) => charities
  .filter(c => c.charity?.value && c.percent)
  .map(({ charity, percent }) => ({ charityId: charity.value, percent: +percent }));

export const getCharitiesForForm = (charities = []) => {
  if (charities.length === 0) {
    return initialCharities;
  }

  return charities.map(({ charity, percent }) => ({
    charity: charitiesPreparator(charity),
    percent: +percent,
  }));
};
