import React from 'react';

import RowTextarea from '_legacy/common/Textarea/RowTextarea';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const OtherInformation = () => {
  const t = useGetTranslate();

  return (
    <RowTextarea
      label={t('create-experience.form.other-information.label')}
      name="otherInformation"
      placeholder={t('create-experience.form.other-information.placeholder')}
      max={500}
      height={239}
    />
  );
};

export default OtherInformation;
