import React from 'react';
import styled from 'styled-components';
import { api, types } from 'shared'
import __ from 'lodash/fp/__'
import includes from 'lodash/fp/includes'
import where from 'lodash/fp/where'
const Status = types.talent.profileManager.Status // todo: refactor with beackend

import { filtersConfig, counterConfig, subFiltersConfig, fields } from '_legacy/configs/table/profileManagers';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import {
  useProfileManagersFilters, useSetProfileManagersFilters,
  useProfileManagersTable
} from 'store/hooks/globalState/useProfileManagers';
import {
  useFetchProfileManagers, useDisconnectProfileManager, useReIviteProfileManager, useCancleInviteProfileManager, useRemoveProfileManager
} from 'requests/profile-managers';

import CustomButton from '_legacy/components/Button/CustomButton';
import Filters from '_legacy/components/Table/Filters';
import SubFilters from '_legacy/components/Table/SubFilters';
import { PROFILE_MANAGERS, elementsTypes } from '_legacy/components/Table/table-components/types/profile-managers/config';
import Table from '_legacy/components/Table/table-components/Table';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { SELECT_JOIN_TO_TALENT_MODAL } from '_legacy/components/Modal/modals-types';

const { STATUS, NAME, ROLE } = api.talents.talent.profileManagers.SortField

const tableConfig = [
  {
    header: 'table.header.name',
    field: NAME,
    type: elementsTypes.invitedUser
  },
  {
    header: 'table.header.email',
    field: 'EMAIL', // need to add to shared
    type: elementsTypes.email
  },
  {
    header: 'table.header.role',
    field: ROLE,
    type: elementsTypes.role
  },
  {
    header: 'table.header.status',
    field: STATUS,
    type: elementsTypes.status
  }
];

const AddManagerButton = React.memo(function AddManagerButton ({ className = '' }) {
  const t = useGetTranslate();
  const openModal = useStateModal(SELECT_JOIN_TO_TALENT_MODAL);
  return (
    <CustomButton
      className={className}
      clickHandler={openModal}
    >
      + {t('table.button.pm')}
    </CustomButton>
  );
});


const shouldShowInviteAction = where({
  status: includes(__, [Status.PENDING, Status.DISCONNECTED]),
})
const shouldShowCancelAction = where({
  status: includes(__, [Status.PENDING]),
})
const shouldShowDisconnectAction = where({
  status: includes(__, [Status.ACCEPTED]),
})
const shouldShowRemoveAction = where({
  status: includes(__, [Status.PENDING, Status.DISCONNECTED, Status.ACCEPTED, Status.CANCELED, Status.REJECTED]),
})


export default function ProfileManagers () {
  const t = useGetTranslate();
  const reIviteProfileManager = useReIviteProfileManager();
  const cancleInviteProfileManager = useCancleInviteProfileManager();
  const disconnectProfileManager = useDisconnectProfileManager();
  const removeProfileManager = useRemoveProfileManager()

  const actionsConfig = React.useMemo(() => {
    return [
      {
        label: 'table.actions.resend-invitation',
        cbFn: reIviteProfileManager,
        shouldShow: shouldShowInviteAction,
      },
      {
        label: 'table.actions.cancel-invitation',
        cbFn: cancleInviteProfileManager,
        shouldShow: shouldShowCancelAction,
      },
      {
        label: 'table.actions.disconect',
        cbFn: disconnectProfileManager,
        shouldShow: shouldShowDisconnectAction,
      },
      {
        label: 'table.actions.remove',
        cbFn: removeProfileManager,
        shouldShow: shouldShowRemoveAction,
      },
    ]
  }, [t, reIviteProfileManager, cancleInviteProfileManager, disconnectProfileManager]);
  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useProfileManagersFilters,
      setFilters: useSetProfileManagersFilters,
      actions: [
        { label: 'table.actions.resend-invitation', cbFn: reIviteProfileManager },
        { label: 'table.actions.cancel-invitation', cbFn: cancleInviteProfileManager },
        { label: 'table.actions.remove', cbFn: disconnectProfileManager }
      ]
    };
  }, [
    t, useProfileManagersFilters, useSetProfileManagersFilters, reIviteProfileManager,
    cancleInviteProfileManager, disconnectProfileManager
  ]);
  return (
    <StyledWrapper className='wrapper content-wrapper'>
      <h1 className='title'>{t('table.title.profile-managers')}</h1>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useProfileManagersFilters}
        setFn={useSetProfileManagersFilters}
        ButtonComponent={AddManagerButton}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useProfileManagersFilters}
        setFn={useSetProfileManagersFilters}
      />
      <Table
        tableName={PROFILE_MANAGERS}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        bulkActionsConfig={bulkActionsConfig}
        getTableFn={useProfileManagersTable}
        fields={fields}
        getFiltersFn={useProfileManagersFilters}
        setFiltersFn={useSetProfileManagersFilters}
        fetchTableFn={useFetchProfileManagers}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 40px;
  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: 900;
    line-height: 75px;
    color: ${({ theme: { colors } }) => colors.blue};
    text-align: center;
  }
  .row-content {
    grid-template-columns: 27fr 28fr 27fr 18fr;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 27fr 33fr 20fr 20fr;
    }
  }
`;
