import React from 'react'

import { getReviewsByMe as getReviewsByMeApi } from 'api/appApi/talent/review'
import {
  useSetReviewsByMeTable,
  useSetReviewsByMeFilters,
} from 'store/hooks/globalState/reviws/useReviewsByMe'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import normalizeParams from 'utils/normalizeParams'

export function useFetchReviewsByMe() {
  const setReviewsByMeTable = useSetReviewsByMeTable((prev, next) => next)
  const setReviewsByMeFilters = useSetReviewsByMeFilters((prev, next) => ({
    ...prev,
    ...next,
  }))
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    options => {
      const query = normalizeParams(options)
      setReviewsByMeTable(null)
      getReviewsByMeApi({ query })
        .then(({ reviews, pageInfo }) => {
          setReviewsByMeTable(reviews)
          setReviewsByMeFilters(pageInfo)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setReviewsByMeTable, setReviewsByMeFilters]
  )
}
