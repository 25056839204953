import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PublicHeadingBlock = ({ title, name, className }) => {
  return (
    <StyledPublicViewHeading
      className={`wrapper content-wrapper public-view__heading ${className}`}
    >
      <h3>{title}</h3>
      <h1>{name}</h1>
    </StyledPublicViewHeading>
  )
};

const StyledPublicViewHeading = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0 80px;
  font-family: Playfair Display;
  font-weight: 900;
  h3 {
    font-size: 1.375em;
    line-height: 1.75em;
    color: #9495a7;
  }
  h1 {
    font-size: 3.75em;
    line-height: 75px;
    color: #ffffff;
    max-width: 680px;
    font-feature-settings: 'pnum' on, 'lnum' on, 'liga' off;
    overflow-wrap: anywhere;
  }
  @media only screen and (max-width: 1024px) {
    padding: 20px 0 40px;
    h3 {
      font-family: Montserrat;
      font-weight: bold;
      font-size: 16px;
    }
    h1 {
      font-size: 32px;
      line-height: 54px;
      font-feature-settings: 'case' on;
    }
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 28px;
      line-height: 38px;
    }
  }
`;

PublicHeadingBlock.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

export default PublicHeadingBlock;
