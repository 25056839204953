// Modules
import React from 'react'
import { useLocation } from 'react-router-dom'
import where from 'lodash/fp/where'
import equals from 'lodash/fp/equals'
import { api, BulkActionsResultStatus } from 'shared'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { notify } from 'utils/notify'


const { ALREADY_PUBLISHED } = api.talents.talent.offers.actions.publish.SkippedReason
const { REQUIRED_MORE_DATA } = api.talents.talent.offers.actions.publish.ErrorReason
const {
  OFFER_ALREADY_UNPUBLISHED,
  DRAFT_CANT_BE_UNPUBLISHED,
} = api.talents.talent.offers.actions.unpublish.SkippedReason

const {
  SUCCESS,
  ERROR,
  SKIPPED,
} = BulkActionsResultStatus

export const SUCCESS_NOTIFICATION = 'SUCCESS_NOTIFICATION'
export const TABLE_ACTION_ERROR_LIST = {
  REQUIRED_MORE_DATA,
  ALREADY_PUBLISHED,
  OFFER_ALREADY_UNPUBLISHED,
  DRAFT_CANT_BE_UNPUBLISHED,
}

export const isActionSuccess = where({ status: equals(SUCCESS) })
export const isActionError = where({ status: equals(ERROR) })
export const isActionSkipped = where({ status: equals(SKIPPED) })

export const useTableActionsNotifications = () => {
  const t = useGetTranslate()

  return React.useCallback(({ results, config }) => {
    const successList = []
    const errorsList = {}

    results.forEach((item) => {
      if (isActionSuccess(item)) successList.push(item)
      if (isActionError(item) || isActionSkipped(item)) errorsList[item.reason] = item.reason
    })

    if (successList.length !== 0 && config[SUCCESS_NOTIFICATION]) {
      notify(t(config[SUCCESS_NOTIFICATION]))
    }

    Object.keys(errorsList).forEach(error => {
      const tKey = config[error]
      if (tKey) notify(t(tKey), 'error')
    })
  }, [])
}

export const useCurrentPage = () => {
  const { search } = useLocation()

  return React.useMemo(() => {
    const query = new URLSearchParams(search)
    const page = query.get('page') || 1

    return page
  }, [search])
}
