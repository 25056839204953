import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';


// utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import CategorySelectWithInput from '_legacy/components/CategoriesSelect/CategorySelectWithInput';

const Category = ({ name, rules, defaultValue, getFn, filterFn, withCount, customOnChange }) => {
  const t = useGetTranslate()
  const { errors, control } = useFormContext()
  const error = get(errors, name + '.message')

  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultValue}
      rules={rules}
      render={({ onChange, value }) => (
        <CategorySelectWithInput
          value={value}
          error={error}
          setValue={(...props) => {
            onChange(...props)
            if (isFunction(customOnChange)) {
              customOnChange(...props)
            }
          }}
          getFn={getFn}
          filterFn={filterFn}
          withCount={withCount}
          placeholder={t('search.filters.profile-type.placeholder')}
        />
      )}
    />
  )
}

Category.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  filterFn: PropTypes.func,
  withCount: PropTypes.bool,
}

export default Category;
