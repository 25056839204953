import { sortBy, last, get } from 'lodash'
import {
  areIntervalsOverlapping,
  min,
  max,
  set,
  differenceInCalendarDays,
  isSameDay,
  addDays,
  addSeconds,
  differenceInSeconds,
  subSeconds,
} from 'date-fns'

import { isDateRangeValid, secondsFromStartDate } from 'utils/date'
import { getBrowserTimeZone, parseDate } from 'utils/services/timezone/'


export const getAvailableRange = (slots = [], from, to) => {
  const result = []

  for (let i = 0; i <= slots.length; i++) {
    result.push({
      startTime: get(slots, [i - 1, 'endTime'], from),
      endTime: get(slots, [i, 'startTime'], to),
    })
  }

  return result
}

export const sortSlots = (slots = []) => sortBy(slots, 'startTime')

export const mergeOverlappedSlot = slots => {
  const result = []

  for (let i = 0; i < slots.length; i++) {
    const current = slots[i]
    const lastResult = last(result)

    if (
      lastResult &&
      areIntervalsOverlapping(
        { start: new Date(current.startTime), end: new Date(current.endTime) },
        { start: new Date(lastResult.startTime), end: new Date(lastResult.endTime) }
      )
    ) {
      result[result.length - 1] = {
        startTime: min([new Date(lastResult.startTime), new Date(current.startTime)]),
        endTime: max([new Date(lastResult.endTime), new Date(current.endTime)]),
      }
    } else {
      result.push(current)
    }
  }
  return result
}

export const splitSlotsByDate = slots => {
  const result = []

  for (let i = 0; i < slots.length; i++) {
    const current = slots[i]
    const calendarDays = differenceInCalendarDays(new Date(current.endTime), new Date(current.startTime))

    if (calendarDays > 0) {
      for (let j = 0; j <= calendarDays; j++) {
        const slot = {
          ...current,

          startTime: isSameDay(new Date(current.startTime), addDays(new Date(current.startTime), j))
            ? new Date(current.startTime)
            : set(addDays(new Date(current.startTime), j), { hours: 0, minutes: 0 }),

          endTime: isSameDay(new Date(current.endTime), addDays(new Date(current.startTime), j))
            ? new Date(current.endTime)
            : set(addDays(new Date(current.startTime), j), { hours: 23, minutes: 59 }),
        }
        result.push(slot)
      }
    } else {
      result.push({
        ...current,
        startTime: new Date(current.startTime),
        endTime: new Date(current.endTime),
      })
    }
  }

  return result
}


export const applyBusinessHours = (slots, businessHours) => {
  if (get(businessHours, 'daysOfWeek.length', 0) === 0) return slots

  const [businessHoursStartTimeHours, businessHoursStartTimeMinutes] = businessHours.startTime.split(':')
  const [businessHoursEndTimeHours, businessHoursEndTimeMinutes] = businessHours.endTime.split(':')
  const businessHoursStartTime = +businessHoursStartTimeHours * 3600 + +businessHoursStartTimeMinutes * 60
  const businessHoursEndTime = +businessHoursEndTimeHours * 3600 + +businessHoursEndTimeMinutes * 60

  return slots
    .map(slot => {
      if ((businessHours.daysOfWeek || []).includes(new Date(slot.startTime).getDay())) {
        const currentSlotStartTime = secondsFromStartDate(slot.startTime)
        const currentSlotEndTime = secondsFromStartDate(slot.endTime)

        const businessHoursStartTimeDate = set(new Date(slot.startTime), {
          hours: businessHoursStartTimeHours,
          minutes: businessHoursStartTimeMinutes,
        })
        const businessHoursEndTimeDate = set(new Date(slot.endTime), {
          hours: businessHoursEndTimeHours,
          minutes: businessHoursEndTimeMinutes,
        })

        if (currentSlotEndTime < businessHoursStartTime) return null

        if (currentSlotStartTime > businessHoursEndTime) return null

        if (
          areIntervalsOverlapping(
            { start: businessHoursStartTimeDate, end: businessHoursEndTimeDate },
            { start: slot.startTime, end: slot.endTime }
          )
        ) {
          return {
            ...slot,
            startTime: new Date(slot.startTime) < businessHoursStartTimeDate ? businessHoursStartTimeDate : new Date(slot.startTime),
            endTime: new Date(slot.endTime) < businessHoursEndTimeDate ? new Date(slot.endTime) : businessHoursEndTimeDate,
          }
        }

        return slot
      }
    })
    .filter(Boolean)
}


export const applyMinBookingTime = (slots, minBookingTime, timeZoneId) => {
  const zonedNow = parseDate(new Date(), timeZoneId)
  const minBookingTimeFromZonedNow = addSeconds(zonedNow, minBookingTime)

  return slots
    .map(slot => {
      if (minBookingTimeFromZonedNow > slot.endTime) return null
      if (minBookingTimeFromZonedNow > slot.startTime) return { ...slot, startTime: minBookingTimeFromZonedNow }
      return slot
    })
    .filter(Boolean)
}

export const filterByDuration = (slots, duration) => {
  return slots.filter(slot => differenceInSeconds(new Date(slot.endTime), new Date(slot.startTime)) > duration)
}

export const getValidSlotsByStartEndTime = slots => slots.filter(isDateRangeValid)

export const applyDriveTime = slots => {
  return slots.map(slot => {
    return {
      ...slot,
      startTime: new Date(subSeconds(new Date(slot.startTime), slot.beforeDriveTime || 0).toISOString()),
      endTime: new Date(addSeconds(new Date(slot.endTime), slot.afterDriveTime || 0).toISOString())
    }
  })
}


export const applyDriveTimeToAllSlots = (slots, { after = 0, before = 0 } = {}) => {
  return slots.map(slot => {
    return {
      ...slot,
      startTime: new Date(subSeconds(new Date(slot.startTime), after)),
      endTime: new Date(addSeconds(new Date(slot.endTime), before)),
    }
  })
}

export const applyTimeZone = (slots, timeZone) => {
  const browserTimeZone = getBrowserTimeZone()
  const timeZoneId = timeZone || browserTimeZone

  return slots.map(slot => {
    return {
      ...slot,
      startTime: parseDate(slot.startTime, timeZoneId),
      endTime: parseDate(slot.endTime, timeZoneId),
    }
  })
}


export const getSlotsInRange = (slots = [], start, end) => {
  return slots
    .map(slot => {
      if (new Date(slot.endTime) < new Date(start)) return null
      if (new Date(slot.startTime) > new Date(end)) return null
      if (new Date(slot.startTime) < new Date(start)) return { ...slot, startTime: start }
      if (new Date(slot.endTime) > new Date(end)) return { ...slot, endTime: end }
      return slot
    })
    .filter(Boolean)
}
