import Aficionado from './Aficionado'
import Participants from './Participants'
import Status from './Status'

export const AFICIONADO = 'aficionado'
export const PARTICIPANTS = 'participants'
export const STATUS = 'status'

export const elementsTypes = {
  [AFICIONADO]: AFICIONADO,
  [PARTICIPANTS]: PARTICIPANTS,
  [STATUS]: STATUS,
}

export const BOOKING_DETAILS_PARTICIPANTS = 'bd-participants'

export const bookingParticipantsComponents = {
  [AFICIONADO]: Aficionado,
  [PARTICIPANTS]: Participants,
  [STATUS]: Status,
}
