import { addSeconds } from 'date-fns'

import { parseDate } from 'utils/services/timezone'

export const applyMinBookingTime = (slots, minBookingTime, timeZoneId) => {
  const zonedNow = parseDate(new Date(), timeZoneId)
  const minBookingTimeFromZonedNow = addSeconds(zonedNow, minBookingTime)

  return slots
    .map(slot => {
      if (minBookingTimeFromZonedNow > slot.end) return null
      if (minBookingTimeFromZonedNow > slot.start) return { ...slot, start: minBookingTimeFromZonedNow }
      return slot
    })
    .filter(Boolean)
}
