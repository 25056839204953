import React from 'react'
import Search from './Search'
import Talents from './Talents'
import Location from './Location'
import Offerings from './Offerings'
import './index.scss'

const MainMenu = ({ themeType }) => {
  return (
    <div className="select-container main-menu__nav">
      <Search />
      <Talents className="main-menu__nav--item" themeType={themeType} />
      <Offerings themeType={themeType} />
      <Location themeType={themeType} />
    </div>
  )
};

export default MainMenu;
