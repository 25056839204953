import React from 'react';
import { components } from 'react-select';

const NoOptionsMessage = props => (
  <components.NoOptionsMessage {...props}>
    No places
  </components.NoOptionsMessage>
);

export default NoOptionsMessage;
