import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import compose from 'lodash/fp/compose'
import prop from 'lodash/fp/prop'
import { types } from 'shared'

import { OFFERINGS } from '_legacy/constants/routes'
import { useSetModal } from 'store/hooks/globalState/useModal'
import { useCreatePromotionalMedia } from 'requests/promotional-media'

import SingleSelectLibraryModal from '_legacy/components/Library/SingleSelectLibrary/components/SingleSelectLibraryModal'

const fileTypes = types.file.Types

const defaultValues = {
  media: '',
}

const PromotionalMediaLibraryModal = ({ openExtraModal }) => {
  const closeModal = useSetModal(() => null)
  const history = useHistory()
  const createPromotionalMedia = useCreatePromotionalMedia(openExtraModal)
  const methods = useForm({ defaultValues })

  const onSubmit = compose(
    createPromotionalMedia,
    prop('media'),
    methods.getValues
  )

  const onClose = () => {
    closeModal()
    history.push(`/${OFFERINGS}`)
    openExtraModal()
  }

  return (
    <FormProvider {...methods}>
      <SingleSelectLibraryModal
        name="media"
        isOpen={true}
        onSubmit={onSubmit}
        closeModal={onClose}
        dropZoneProps={{ accept: [fileTypes.PHOTO, fileTypes.VIDEO] }}
      />
    </FormProvider>
  )
}

PromotionalMediaLibraryModal.propTypes = {
  openExtraModal: PropTypes.func
}

PromotionalMediaLibraryModal.defaultValues = {
  openExtraModal: ()=>null
}

export default PromotionalMediaLibraryModal
