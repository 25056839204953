import React from 'react'

import NavigationHeader from '_legacy/common/mobile-navigation/NavigationHeader'
import NavigationLinksList from '_legacy/common/mobile-navigation/NavigationLinksList'

import { getAccountMenuItems, getAgentTalentMenuItems } from './Menu/utils'
import { useUser } from 'store/hooks/globalState/useUser'
import { isAgentWithTalent } from 'utils/user'

const mapMenuProps = menuItems => menuItems.map(item => ({
  path: item.pathname,
  text: item.translation
}))

export default function Account() {
  const agentWithTalent = useUser(isAgentWithTalent)
  const menuItems = getAccountMenuItems()
  const agentTalentMenuItems = getAgentTalentMenuItems()

  const filteredMenuItems = agentWithTalent ? agentTalentMenuItems : menuItems
  const mappedMenuItems = mapMenuProps(filteredMenuItems)

  return (
    <div>
      <NavigationHeader text='settings.header' />
      <NavigationLinksList routesConfig={mappedMenuItems} />
    </div>
  )
}
