import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'assets/icons/Icon';

export const Checkbox = ({ onClick, label, name, defaultChecked, register, value, disabled, secondary, withRemove, onRemove }) => (
  <div className='custom-checkbox-container'>
    <label className={`checkboxCustom ${secondary ? 'checkboxCustom--secondary' : ''}`}>
      <span>{label}</span>
      <input
        type="checkbox"
        ref={register}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onClick={onClick}
      />
      <i />
    </label>
    {withRemove ? (
      <button
        className="checkbox-remove-btn"
        type="button"
        onClick={onRemove}
      >
        <Icon.DocumentRemove />
      </button>
    ) : null}
  </div>
);


Checkbox.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  register: PropTypes.func,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  withRemove: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default Checkbox;
