import React from 'react'
import { useFormContext } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'

import { ErrorMessage, Row } from '_legacy/common'

const MissedData = () => {
  const {
    formState: { errors },
  } = useFormContext()

  if (isEmpty(errors)) return null

  return <Row component={<ErrorMessage message="register-aficionado.form.errors.required-data-is-missing" />} />
}

export default MissedData
