import { _post } from 'api/utils'

export const verifyLiveVirtualLessonRoom = ({ occurrenceId }) =>
  _post(`/offers/lessons/live-virtual-lesson/occurrences/${occurrenceId}/verify-video-room`, {
    needAuthorization: true,
  })

export const joinLiveVirtualLessonRoom = ({ occurrenceId }) =>
  _post(`/offers/lessons/live-virtual-lesson/occurrences/${occurrenceId}/join-video-room`, {
    needAuthorization: true,
  })

export const closeLiveVirtualLessonRoom = ({ occurrenceId }) =>
  _post(`/offers/lessons/live-virtual-lesson/occurrences/${occurrenceId}/close-video-room`, {
    needAuthorization: true,
  })
