import React from 'react';
import PropTypes from 'prop-types';

import BaseSlide from '../BaseSlide';
import MediaViewer from '../MediaViewer';

const Desktop = ({
  size,
  isMirroredInside,
  isCurrent,
  isNext,
  file,
  bottomCss = '30px',
  gradient,
  setGlobalIsPlay = () => {},
}) => {
  return (
    <BaseSlide
      gradient={gradient}
      slideProps={{
        'attr-size': size,
        'attr-is-current': String(isCurrent),
        'attr-mirrored-inside': String(isMirroredInside),
        'attr-is-next': String(isNext),
      }}
    >
      <MediaViewer
        file={file}
        isCurrent={isCurrent}
        isNext={isNext}
        bottomCss={bottomCss}
        setGlobalIsPlay={setGlobalIsPlay}
      />
    </BaseSlide>
  );
};

Desktop.propTypes = {
  isMirroredInside: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isNext: PropTypes.bool,
  size: PropTypes.string,
  overlay: PropTypes.object,

  file: PropTypes.object.isRequired,
  bottomCss: PropTypes.string,
};

export default Desktop;
