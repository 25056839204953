import { _get, _post } from '../../utils';

export const getConnectedTalents = () => _get(
  '/auth/profiles',
  { needAuthorization: true }
)

export const switchToTalent = (switchProfileId) => _post(
  `/auth/profiles/${switchProfileId}/switch`,
  { needAuthorization: true }
);

export const switchToManager = () => _post(
  '/auth/profiles/me/switch',
  { needAuthorization: true }
)

