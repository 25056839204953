import { _get, _post } from 'api/utils'

export const getVirtualEventOccurrence = ({ talentId, orderId }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/occurrences/${orderId}`,
  { needAuthorization: true },
)

export const getVirtualEventOccurrenceOrders = ({ talentId, orderId, query }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/occurrences/${orderId}/orders`,
  { needAuthorization: true, query },
)

export const checkVirtualEventOccurrenceAvailability = ({ talentId, orderId }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/occurrences/${orderId}/check-availability-to-edit`,
  { needAuthorization: true },
)

export const orderRequestAction = ({ talentId, actionType, ordersIds }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }
  })
