import React from 'react';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import compact from 'lodash/compact'

import {
  createUseGlobalState,
  createUseSetGlobalState,
  libraryInitialState,
} from './useGlobalState';
import {
  useFilesEntitiesByIds,
  useAddFilesToEntities,
  useAddFileToEntities,
  useRemoveFileEntityById,
  useSetFileEntities,
} from './useFileEntites';

export const useFileLibrary = createUseGlobalState('fileLibrary');
export const useSetFileLibrary = createUseSetGlobalState('fileLibrary');

export const FILE_ID = 'fileToken';

export const useUpdatePageInfo = () => {
  return useSetFileLibrary((prev, next) => ({ ...prev, pageInfo: next }));
};

export const useAddFileIdToLibrary = () => {
  return useSetFileLibrary((prev, next) => ({
    ...prev,
    files: uniq([next, ...prev.files]),
  }));
};

export const useAddFileIdsToLibrary = () => {
  return useSetFileLibrary((prev, next) => ({
    ...prev,
    files: uniq([...prev.files, ...next]),
  }));
};

export const useFileLibraryIds = () => {
  const fileLibrary = useFileLibrary();
  return get(fileLibrary, 'files', []);
};

export const useLibraryFiles = () => {
  const fileLibraryFileIds = useFileLibraryIds();
  return useFilesEntitiesByIds(fileLibraryFileIds);
};

export const useRemoveFileToken = () => {
  return useSetFileLibrary((prev, next) => ({
    ...prev,
    files: prev.files.filter(id => id !== next),
  }));
};

export const useRemoveFile = () => {
  const removeFileToken = useRemoveFileToken();
  const removeEntity = useRemoveFileEntityById();

  return React.useCallback(
    fileId => {
      removeFileToken(fileId);
      removeEntity(fileId);
    },
    [removeFileToken, removeEntity]
  );
};

export const useReplaceFileId = () => {
  return useSetFileLibrary((prev, oldId, newId) => {
    return {
      ...prev,
      files: prev.files.map(id => (id === oldId ? newId : id)),
    }})
};

export const useAddFileToLibrary = () => {
  const addFileIdToLibrary = useAddFileIdToLibrary();
  const addFileToEntities = useAddFileToEntities();

  return React.useCallback(
    file => {
      addFileIdToLibrary(file[FILE_ID]);
      addFileToEntities(file[FILE_ID], file);
    },
    [addFileIdToLibrary, addFileToEntities]
  );
};

export const useAddFilesToLibrary = () => {
  const addFileIdsToLibrary = useAddFileIdsToLibrary();
  const addFilesToEntities = useAddFilesToEntities();
  const getFileId = file => get(file, FILE_ID)

  return React.useCallback(files => {
    if (!files) return;
    const filteredFiles = compact(files)
    const fileIds = map(filteredFiles, getFileId)
    addFilesToEntities(filteredFiles);
    addFileIdsToLibrary(fileIds);
  },
  [addFileIdsToLibrary, addFilesToEntities]);
};

export const useClearFileLibrary = () => {
  const clearFileEntities = useSetFileEntities(() => null)
  const clearFileIds = useSetFileLibrary(() => libraryInitialState)
  return React.useCallback(() => {
    clearFileEntities()
    clearFileIds()
  }, [clearFileEntities, clearFileIds])
}
