import { TalentOffersType } from 'shared'
import { pipe, isNil } from 'lodash/fp'

import { getTalentOrUserId } from 'utils/user'

import { phsConfig, buildPhs } from '../../config'

export const getBookingRequestPhsConfig = buildPhs([
  phsConfig.senderTalentLink,
  phsConfig.senderTalentFullName,
  phsConfig.aficionadoLink,
  phsConfig.aficionadoFullName,
  phsConfig.offeringName,
  phsConfig.offeringLink,
  phsConfig.location,
  phsConfig.date,
  phsConfig.time,
  phsConfig.receiverTalentFullName,
  phsConfig.receiverTalentLink,
])

export const getModifiers = ({ occurrence, user, receiverTalent, receiverUser }) => {
  const { type, startTime } = occurrence
  const receiver = receiverTalent || receiverUser

  const isAgent = getTalentOrUserId(user) !== receiver.id

  const isInPersonExperience = type === TalentOffersType.IN_PERSON_EXPERIENCE
  const isLiveInPersonLesson = type === TalentOffersType.LIVE_IN_PERSON_LESSON

  const withDate = Boolean(startTime)
  const dateNotSelected = isNil(startTime)

  return {
    isAgent,

    isInPersonExperience,
    isLiveInPersonLesson,

    withDate,
    dateNotSelected,
  }
}

export const matchRequestKeys = ({ isAgent, isInPersonExperience, isLiveInPersonLesson, withDate, dateNotSelected }) => {
  return [
    'notification.booking-request',

    isInPersonExperience && '--experience',
    isLiveInPersonLesson && '--live-in-person-lesson',

    withDate && '--with-date',
    dateNotSelected && '--date-not-selected',

    isAgent && '--agent',
  ]
    .filter(Boolean)
    .join('')
}

export const matchRequestApprovedKeys = ({ isAgent, isInPersonExperience, isLiveInPersonLesson, withDate, dateNotSelected }) => {
  return [
    'notification.booking-request-approved',

    isInPersonExperience && '--experience',
    isLiveInPersonLesson && '--live-in-person-lesson',

    withDate && '--with-date',
    dateNotSelected && '--date-not-selected',

    isAgent && '--agent',
  ]
    .filter(Boolean)
    .join('')
}

export const matchRequestRejectedKeys = ({ isAgent, isInPersonExperience, isLiveInPersonLesson, withDate, dateNotSelected }) => {
  return [
    'notification.booking-request-rejected',

    isInPersonExperience && '--experience',
    isLiveInPersonLesson && '--live-in-person-lesson',

    withDate && '--with-date',
    dateNotSelected && '--date-not-selected',

    isAgent && '--agent',
  ]
    .filter(Boolean)
    .join('')
}

export const buildRequestTransKey = pipe(getModifiers, matchRequestKeys)

export const buildRequestApprovedTransKey = pipe(getModifiers, matchRequestApprovedKeys)

export const buildRequestRejectedTransKey = pipe(getModifiers, matchRequestRejectedKeys)
