// TODO: Refactor this component and move to new structure

// Modules
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/fp/isEmpty'

// Store (hooks)
import { useFetchVideoMessage } from 'requests/public-views'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useVideoMessagePublic } from 'store/hooks/globalState/usePublicView'

// LEgacy components
import PublicViewBorderedCard from '_legacy/components/PublicView/PublicViewBorderedCard'
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicDescriptionOneLine from '_legacy/components/PublicView/PublicDescriptionOneLine'
import PublicHowToList from '_legacy/components/PublicView/PublicHowToList'
import PublicPriceBlock from '_legacy/components/PublicView/PublicPriceBlock'
import PublicFixedFormItem from '_legacy/components/PublicView/PublicFixedFormItem'
import PublicSlider from '_legacy/components/PublicSlider/'
import ArrowScroll from '_legacy/components/Landing/ArrowScrollDown'
import PageLoader from '_legacy/common/Template/PageLoader'
import Icon from 'assets/icons/HowItWorkIcons'

// Utils
import { scrollToRef } from 'utils/scrollToRef'

// Constants
import { OFFERINGS, REQUEST, VIDEO_MESSAGE, COMMERCIAL_ENGAGEMENTS } from '_legacy/constants/routes';

const howItWork = [
  {
    icon: <Icon.Member />,
    number: '01',
    title: 'video-message.subtitle.select-talent',
    text: 'video-message.text.select-talent',
  },
  {
    icon: <Icon.Draft />,
    number: '02',
    title: 'video-message.subtitle.create-script',
    text: 'video-message.text.create-script',
  },
  {
    icon: <Icon.SandClock />,
    number: '03',
    title: 'video-message.subtitle.be-patient',
    text: 'video-message.text.be-patient',
  },
  {
    icon: <Icon.Share />,
    number: '04',
    title: 'video-message.subtitle.share',
    text: 'video-message.text.share',
  },
]


const VideoMessagePublic = () => {
  const t = useGetTranslate()

  const howToRef = React.useRef(null)

  const fetchVideoMessage = useFetchVideoMessage()
  const videoMessage = useVideoMessagePublic()

  React.useEffect(() => fetchVideoMessage(), [])

  if (!videoMessage) return <PageLoader />

  const {
    media,
    price,
    talent,
    whichCharities,
  } = videoMessage

  const sortMedia = sortBy(media, ({ isPrimary }) => !isPrimary)

  const convertedCharities = whichCharities
    .filter(Boolean)
    .map(item => `${item.charity.name} (${item.percent}%);`)

  const scrollToHowTo = () => scrollToRef(howToRef)

  const { talentPlaqueNickname, offerSequentialId } = useParams()

  return (
    <div className="public-view">
      <PublicHeadingBlock
        name={t('video-message.title.main')}
        className="inverted short"
      />

      <div className="video-messages__wrapper wrapper content-wrapper">
        <PublicViewBorderedCard borderColor={'gradient'}>
          <div className="slider">
            <PublicSlider covers={sortMedia} />
          </div>

          <div className="description">
            <PublicDescriptionOneLine>
              <p>
                <Trans
                  tKey="video-message.text.get-video"
                  phs={[
                    { ph: '{{displayName}}', value: talent.displayName },
                    {
                      ph: '{{talentLink}}',
                      value: talent.plaqueNickname,
                    },
                  ]}
                />
              </p>
            </PublicDescriptionOneLine>

            <PublicPriceBlock
              text={t('video-message.label.price')}
              price={price}
              className={'inverted'}
              buttonProps={{
                text: t('video-message.label.book-now'),
                isLink: true,
                path: `/${OFFERINGS}/${VIDEO_MESSAGE}/${talentPlaqueNickname}/${offerSequentialId}/${REQUEST}`
              }}
            />

            {isEmpty(convertedCharities) ? null : (
              <PublicDescriptionOneLine>
                <span>{t('video-message.text.charity-list')} </span>

                <p className="inline grayed">{convertedCharities.join(' ')}</p>

                <span className="tip">
                  {t('video-message.text.charity-percent')}
                </span>
              </PublicDescriptionOneLine>
            )}
          </div>
        </PublicViewBorderedCard>

        <div className="arrow-down">
          <ArrowScroll clickHandler={scrollToHowTo} />
        </div>
      </div>
      <div className="public-view__how-to public-view__how-to--video-message" ref={howToRef}>
        <div className="public-view__how-to--type wrapper content-wrapper">
          <h1>{t('video-message.title.type')}</h1>

          <div className="public-view__how-to--grid">
            <PublicFixedFormItem
              title={t('video-message.subtitle.shout-outs')}
              fixedItem={t('video-message.text.shout-outs')}
            />

            <PublicFixedFormItem
              title={t('video-message.subtitle.announcements')}
              fixedItem={t('video-message.text.announcements')}
            />
          </div>

          <p className="sponsorship-tip">
            {t('video-message.text.sponsorship')}
            <Link to={`/${talentPlaqueNickname}/${COMMERCIAL_ENGAGEMENTS}`}>{t('video-message.text.sponsorship-link')}</Link>
          </p>
        </div>
        <div className="public-view__how-to--list wrapper content-wrapper">
          <h1>{t('video-message.title.how-it-work')}</h1>
          {
            howItWork
              .filter(Boolean)
              .map((item, index) =>
                <PublicHowToList
                  key={index}
                  icon={item.icon}
                  numericBg={item.number}
                  title={item.title}
                  text={item.text}
                />
              )
          }
        </div>
      </div>
    </div>
  )
}

export default VideoMessagePublic
