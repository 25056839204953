import React from 'react'
import cx from 'classnames'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useMarkAsRead,
  useMarkAsUnRead,
  useRejectManagerInvitation,
  useAcceptManagerInvitation,
} from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import { NotificationTypes } from '_legacy/components/Notification/Notification.types'
import { getShortDateWithTime } from 'utils/date'
import { isPending, statusTrans } from '../config'

const ManagerInvitationRequest = ({ id, createdAt, sender, isRead, isOverdue, isTable, isMenu, status }) => {
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()
  const acceptManagerInvitation = useAcceptManagerInvitation()
  const rejectManagerInvitation = useRejectManagerInvitation()

  // handlers
  const declineHandler = () => rejectManagerInvitation(id)

  const acceptHandler = () => acceptManagerInvitation(id)

  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  return (
    <div className={containerClasses} id={id}>
      <div className="notification-item__icon">
        <Icon.Bell id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans
          phs={[
            { ph: '{{talentName}}', value: sender?.firstName },
            { ph: '{{talentLink}}', value: sender?.plaqueNickname },
          ]}
          tKey={'notification-menu.item.message.manager-invitation-via-the-platform'}
        />
      </div>
      <time className="notification-item__date notification-date" dateTime={createdAt}>
        {getShortDateWithTime(createdAt)}
      </time>

      {isPending(status) && (
        <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
          {t(markBtnText)}
        </button>
      )}

      {isPending(status) && (
        <div className="notification-item__controls">
          <Button
            primary
            handleOnClick={declineHandler}
            text={t('shared.words.decline')}
            className="notification-item__action-button"
          />
          <Button
            handleOnClick={acceptHandler}
            text={t('shared.words.accept')}
            className="notification-item__action-button"
          />
        </div>
      )}

      {!isPending(status) && (
        <div className="notification-item__controls notification-item__controls--status">
          <p className="notification-status">{t(statusTrans[status])}</p>
        </div>
      )}
    </div>
  )
}

ManagerInvitationRequest.propTypes = NotificationTypes

export default ManagerInvitationRequest
