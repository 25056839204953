import React from 'react'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import uniqueId from 'lodash/uniqueId'
import { FileType } from 'shared'
import get from 'lodash/get'

// components
import { ErrorMessage } from '_legacy/common/ErrorMessage'
import { UploadHint } from '_legacy/common/UploadHint'
import StyledDropzone from '_legacy/common/Dropzone'
import Icon from 'assets/icons/Icon'
import LicenseListItem from './LicenseListItem'
import { StyledLicenseList } from './LicenseList'

// utils
import { upload as uploadApi } from 'api/appApi/utils/upload'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import cond from 'lodash/cond'
import matches from 'lodash/matches'
import constant from 'lodash/constant'
import stubTrue from 'lodash/stubTrue'

const Uploader = React.memo(function Uploader({
  name,
  titleHint,
  textHint,
  typesFiles,
  requiredMessage,
  isRequired,
}) {
  const { setValue, register, unregister, errors, watch } = useFormContext()
  const watchedFiles = watch(name, [])
  const t = useGetTranslate()
  const error = get(errors, name)

  const errorMessageRequired = requiredMessage
    ? requiredMessage
    : 'shared.words.required'

  const setter = (value, config) => setValue(name, value, { shouldDirty: true, ...config })

  const uploadFile = (file, _id) => {
    const state = [{ file, isLoading: true, _id }, ...watch(name, [])]
    setter(state)

    uploadApi(file).then(({ file }) => {
      const updatedState = watch(name, []).map(entity =>
        entity._id === _id ? { ...entity, isLoading: false, ...file } : entity
      )
      setter(updatedState, { shouldValidate: true })
    })
  }

  const _onDrop = files => {
    files.forEach(file => uploadFile(file, uniqueId('file_')))
  }

  const onRemove = idx => setter(watchedFiles.filter((_, i) => i !== idx))

  React.useEffect(() => {
    register(
      name,
      { validate: v => (isRequired ? v?.length > 0 || errorMessageRequired : true) }
    )
    return () => unregister(name)
  }, [name, isRequired])

  return (
    <StyledWrapper error={Boolean(error)}>
      <StyledDropzone onDrop={_onDrop} multiple accept={typesFiles}>
        <UploadHint
          iconComponent={<Icon.File />}
          title={titleHint}
          text={textHint || t('register.upload.hint.file.subtitle')}
        />
      </StyledDropzone>
      <StyledLicenseList>
        {watchedFiles.map((item, i) => (
          <LicenseListItem
            key={i}
            name={item.file.name}
            isLoading={item.isLoading}
            onRemove={() => onRemove(i)}
          />
        ))}
      </StyledLicenseList>
      {error && <ErrorMessage message={error.message} />}
    </StyledWrapper>
  )
})

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')],
])

const StyledWrapper = styled.div`
  .wrapper {
    height: 240px;
    border-radius: 4px;

    border-color: ${getBorderColor};

    @media only screen and (max-width: 768px) {
      height: 135px;
    }

    .description {
      color: #9495a7;
    }
  }
`

Uploader.defaultProps = {
  typesFiles: FileType.FILE,
}

Uploader.propTypes = {
  name: PropTypes.string.isRequired,
  titleHint: PropTypes.string.isRequired,
  textHint: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredMessage: PropTypes.string,
  typesFiles: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export default Uploader
