export const linkify = (text = '') =>
  text.replace(
    /([^\S]|^)(((https?:\/\/)|(www\.))(\S+))/gi,
    (match, space, url) => {
      let hyperlink = url;
      if (!hyperlink.match('^https?://')) {
        hyperlink = `http://${hyperlink}`;
      }
      return `${space}<a href="${hyperlink}" target="_blank">${url}</a>`;
    },
  );
