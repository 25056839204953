import React from 'react';
import uniqueId from 'lodash/uniqueId';
import axios from 'axios';

import { getNormalizeFileType } from 'utils/isFile';
import { upload as uploadApi } from 'api/appApi/utils';

import { useAddFileIdToLibrary, useReplaceFileId } from 'store/hooks/globalState/useFileLibrary';
import { useAddFileToEntities, useRemoveFileEntityById } from 'store/hooks/globalState/useFileEntites';

export const normalizeLocalFile = ({ file, _id, abort }) => ({
  id: _id,
  fileToken: _id,
  file,
  isLoading: true,
  type: getNormalizeFileType(file.type),
  size: file.size,
  originalName: file.name,
  fullUrl: URL.createObjectURL(file),
  abort
});

export const normalizeBackendFile = ({ file }) => ({
  ...file,
  fileToken: file.fileToken,
  isLoading: false,
  originalName: file.originalName,
});

export const getSource = () => {
  const CancelToken = axios.CancelToken;
  return CancelToken.source();
};

export const useUploadFileToFileLibrary = () => {
  const addFileIdToLibrary = useAddFileIdToLibrary();
  const addFileToEntities = useAddFileToEntities();
  const replaceFileId = useReplaceFileId();
  const removeFileEntityById = useRemoveFileEntityById();

  return React.useCallback((file) => {
    const _id = uniqueId('_file-entity');
    const source = getSource();

    addFileToEntities(_id, normalizeLocalFile({ file, _id, abort: () => source.cancel('FILE_ABORT_ERR') }));
    addFileIdToLibrary(_id);

    uploadApi(file, { cancelToken: source.token }, true)
      .then(res => {
        addFileToEntities(res.file.fileToken, normalizeBackendFile(res));
        replaceFileId(_id, res.file.fileToken);
      })
      .finally(() => {
        removeFileEntityById(_id);
      });
  },
  [addFileIdToLibrary, addFileToEntities, replaceFileId, removeFileEntityById]);
};

export const useUploadFileToEntities = (onStart, onComplete) => {
  const addFileToEntities = useAddFileToEntities();
  const removeFileEntityById = useRemoveFileEntityById();

  return React.useCallback((fileOrBlob, ex = 'jpeg') => {
    const isBlob = fileOrBlob instanceof Blob;
    const file = isBlob ? new File([fileOrBlob], `filename.${ex}`) : fileOrBlob;

    const _id = uniqueId('_file-entity');
    const _file = normalizeLocalFile({ file, _id });
    addFileToEntities(_id, _file);

    if (onStart) onStart(_file);

    uploadApi(file)
      .then(res => {
        addFileToEntities(res.file.fileToken, normalizeBackendFile(res));
        if (onComplete) onComplete(res);
      })
      .finally(() => {
        removeFileEntityById(_id);
      });
  }, [addFileToEntities]);
};
