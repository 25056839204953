import compact from 'lodash/compact'
import _get from 'lodash/get'
import castArray from 'lodash/castArray'
import map from 'lodash/map'

import { enums, types } from 'shared'

import { prepareRecurring } from 'utils/forms/adapters/recurring'
import { setMinutesToDate, isTrue } from './utils'
import { getCharitiesForSubmit } from 'utils/services/loaders/charities'
import { prepareDate, UTCTimeZone } from 'utils/services/timezone'
import { submitEntourageAdapter } from 'utils/forms/adapters/entourage'

const FIXED_DATE = types.experiences.TypesOfDate.FIXED
const FIXED_LOCATION = enums.TypesOfLocation.FIXED
const TBD_LOCATION = enums.TypesOfLocation.TBD
const {
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  RECURRING_DAY_TIME_OPTION,
  UP_TO_5_DAY_TIME_OPTIONS,
} = types.experiences.DaysTimesType


function getLocation(data) {
  const typesOfLocation = _get(data, 'typesOfLocation')

  const locationValue =
    typesOfLocation === FIXED_LOCATION
      ? { location: _get(data, 'location.value'), locationName: _get(data, 'locationName.label') }
      : typesOfLocation !== TBD_LOCATION && { locations: compact(map(_get(data, 'locations'), 'address.value')), locationsName: compact(map(_get(data, 'locations'), 'name.label')) }

  return {
    typesOfLocation,
    ...locationValue,
  }
}

function getOccurrencesLimit(data) {
  return data?.endsAfter ? data?.occurrencesLimit : undefined
}

function getFixedTypeOfDate(data) {
  return {
    recurring: prepareRecurring(data),
    date: prepareDate(
      data?.dateOfExperience,
      UTCTimeZone
    ),
    startTime: prepareDate(
      setMinutesToDate(data?.dateOfExperience, data?.startTime?.value),
      data?.timeZone,
    ),
    endTime: prepareDate(
      setMinutesToDate(data?.dateOfExperience, data?.endTime?.value),
      data?.timeZone,
    ),
    hideDayTime: isTrue((data, 'hideDayTime')),
  }
}

function getFlexibleTypeOfDate(data) {
  const occurrencesLimit = Number(getOccurrencesLimit(data))
  const recurring = prepareRecurring(data)
  const duration = _get(data, 'duration.value')

  const types = {
    [ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME]: {
      daysTimesType: ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
      occurrencesLimit,
      duration,
    },
    [ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR]: {
      daysTimesType: ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
      occurrencesLimit,
      duration,
    },
    [RECURRING_DAY_TIME_OPTION]: {
      daysTimesType: RECURRING_DAY_TIME_OPTION,
      timeWindow: {
        date: prepareDate(
          data?.dateOfExperience,
          UTCTimeZone
        ),
        startTime: prepareDate(
          setMinutesToDate(data?.dateOfExperience, data?.startTime?.value),
          data?.timeZone,
        ),
        endTime: prepareDate(
          setMinutesToDate(data?.dateOfExperience, data?.endTime?.value),
          data?.timeZone,
        ),
      },
      recurring,
      occurrencesLimit,
      duration,
    },
    [UP_TO_5_DAY_TIME_OPTIONS]: {
      daysTimesType: UP_TO_5_DAY_TIME_OPTIONS,
      timeWindows: data?.timeWindows?.map(
        ({ dateOfExperience, startTime, endTime }) => ({
          date: prepareDate(
            dateOfExperience,
            UTCTimeZone
          ),
          startTime: prepareDate(
            setMinutesToDate(dateOfExperience, startTime?.value),
            data?.timeZone
          ),
          endTime: prepareDate(
            setMinutesToDate(dateOfExperience, endTime?.value),
            data?.timeZone
          ),
        })
      ),
      occurrencesLimit,
      duration,
    },
  }

  return types[data?.daysTimesType?.value]
}

export const getMinBookingTimeBeforeExperience = formValues => {
  if (formValues?.daysTimesType?.value === ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME) {
    return undefined
  }

  return Number(formValues?.minBookingTimeBeforeExperience.value)
}

export const createExperienceAdapter = data => {
  const get = (path, defaultValue) => _get(data, path, defaultValue) || undefined
  const getTimeOfDateProps = data.typesOfDate === FIXED_DATE
    ? getFixedTypeOfDate
    : getFlexibleTypeOfDate

  const serializedData = {
    name: get('name', '').trim(),
    pricePerParticipant: +get('pricePerParticipant', ''),
    monthOfUse:+get('monthOfUse'),
    defaultFormats:get('defaultFormats',[]),
    customFormats:compact(castArray(get('customFormats',[]))),
    comapnyMayAddLogo:isTrue(get('comapnyMayAddLogo')),
    terms:compact(castArray(get('terms',[]))),
    commercialUseOfExperiencefootage:isTrue(get('commercialUseOfExperiencefootage')),
    maxNumberOfParticipants: +get('maxNumberOfParticipants', ''),
    hideAddressFromPubView: isTrue(get('hideAddressFromPubView')),
    typesOfDate: get('typesOfDate'),
    travelTime: {
      before: get('travelTime.before.value'),
      after: get('travelTime.after.value'),
    },
    photo: get('photo.fileToken') ? get('photo.fileToken') : get('photo'),
    video: get('video.fileToken') ? get('video.fileToken') : get('video'),
    displayVideoInsteadOfImage: get('displayVideoInsteadOfImage', false) || false,
    talentMembers: [],
    taboos: {
      customTaboos: compact(castArray(get('customTaboos', []))),
      defaultTaboos: get('defaultTaboos', []),
    },
    takingPicturesOk: isTrue(get('takingPicturesOk')),
    signaturesOk: isTrue(get('signaturesOk')),
    otherInformation: get('otherInformation'),
    whatToBring: get('whatToBring'),
    ageRequirement: +get('ageRequirement', ''),
    charities: getCharitiesForSubmit(get('charities')),
    minBookingTimeBeforeExperience: getMinBookingTimeBeforeExperience(data),
    processRequestsType: 'MANUALLY', // todo: fix with backend
    entourageType: submitEntourageAdapter(data),
    nameColor: '#000',
    ...getLocation(data),
    ...getTimeOfDateProps(data)
  }

  return serializedData
}
