import { _get } from 'api/utils'

export const fetchReadyMadeEventLessonsDetailsHeader = ({ id }) => _get(`/offers/ready-made-events/occurrences/${id}`, {
  needAuthorization: true,
})

export const fetchReadyMadeEventLessonsDetailsOrdersList = ({ id, query }) => _get(`/offers/ready-made-events/occurrences/${id}/orders`, {
  needAuthorization: true,
  query,
})

export const fetchReadyMadeEventLessonsDetailsProductList = ({ id, query }) => _get(`/offers/ready-made-events/occurrences/${id}/uploads`, {
  needAuthorization: true,
  query,
})

export const fetchFilesArchive = ({ query }) => _get('/download/multiple', {
  query,
})
