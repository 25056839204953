import React from 'react'
import { useParams } from 'react-router'

import { getAficionadoProfile } from './api.js'

import { useSetAficionadoProfile } from './store.js'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchAficionadoProfile() {
  const { plaqueNickname } = useParams()
  const handleError = useHandleResponseError()
  const setAficionadoProfile = useSetAficionadoProfile((_, next) => next)

  return React.useCallback(() => {
    getAficionadoProfile({ plaqueNickname })
      .then(({ publicView }) => {
        setAficionadoProfile({ aficionado: publicView })
      })
      .catch(err => {
        handleError(err)
      })
  }, [plaqueNickname, setAficionadoProfile])
}
