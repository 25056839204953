import React from 'react';
import { /*useFieldArray, useForm,*/ FormProvider } from 'react-hook-form';
// import compose from 'lodash/fp/compose';
// import { useHistory } from 'react-router-dom';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
// import { useFetch } from 'store/hooks/useFetch';

import { /*socialOptions,*/ arr/*, defaultValues, submitAdapter*/ } from './config';
// import { emailPattern } from 'utils/services/validators/validators';
// import { whyJoin as whyJoinApi } from 'api/appApi/oneDirectionRequests/oneDirectionRequests'
// import { notify } from 'utils/notify';

// components
// import Input from '_legacy/components/Form/Inputs/Input';
// import FormRow from '_legacy/components/Form/Row';
// import Button from '_legacy/components/Button/Button';
// import SocialChannelHandle from './SocialChannelHandle';
// import PreferredSocialChannel from './PreferredSocialChannel';
// import Label from '_legacy/components/Form/Label';
// import PhoneWithValidation from '_legacy/common/Input/PhoneWithValidation';


const WhyJoin = () => {
  const t = useGetTranslate();
  // const history = useHistory();
  // const methods = useForm({ mode: 'onChange', defaultValues });
  // const { register, handleSubmit, errors, control, getValues } = methods;
  // const [{ data, isLoading }, whyJoinRequest] = useFetch(whyJoinApi);

  // const { fields, append, remove } = useFieldArray({
  //   control,
  //   name: 'socialChannelHandle',
  // });

  // const onSubmit = compose(whyJoinRequest, submitAdapter);

  // const addMoreHandler = () => {
  //   append({ social: socialOptions[0], username: '', followersCount: '' });
  // };


  // React.useEffect(() => {
  //   if (data) {
  //     notify(t('apply-to-join.form.message.success'));
  //     history.push('/');
  //   }
  // }, [data]);

  return (
    <FormProvider /*{...methods}*/>
      <div className="why-join-wrap">
        <div className="wrapper content-wrapper">
          <div className="why-join-heading">
            <h1 className="why-join-heading__header">
              {t('why-join.header.title')}
            </h1>
            <h3 className="why-join-heading__subheader">
              {t('why-join.monetize-brand')}
            </h3>
          </div>

          <div className="why-join-reason">
            <ul className="why-join-reason__list">
              {arr.map(item => (
                <li className="why-join-reason__list-item" key={item.header}>
                  <h6>{t(item.header)}</h6>
                  <p>{t(item.text)}</p>
                </li>
              ))}
            </ul>
          </div>

          {/* EN-3230 hide join form */}
          {/*<div className="why-join-heading why-join-apply">*/}
          {/*  <h1 className="why-join-heading__header">*/}
          {/*    {t('why-join.apply-join')}*/}
          {/*  </h1>*/}
          {/*  <h3 className="why-join-heading__subheader">*/}
          {/*    {t('why-join.be-in-touch')}*/}
          {/*  </h3>*/}
          {/*</div>*/}

          {/*<form*/}
          {/*  className="why-join form-dark-background darken"*/}
          {/*  onSubmit={handleSubmit(onSubmit)}*/}
          {/*>*/}
          {/*  <FormRow*/}
          {/*    rightItemStyle={{ marginBottom: 0 }}*/}
          {/*    right={*/}
          {/*      <span className="required-information">*/}
          {/*        {t('contact.form.tip.required')}*/}
          {/*      </span>*/}
          {/*    }*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    name="firstName"*/}
          {/*    error={errors.firstName}*/}
          {/*    label={t('apply-to-join.form.first-name.label')}*/}
          {/*    placeholder={t('apply-to-join.form.first-name.placeholder')}*/}
          {/*    forwardRef={register({*/}
          {/*      required: t('apply-to-join.form.first-name.errors.required'),*/}
          {/*    })}*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    name="lastName"*/}
          {/*    error={errors.lastName}*/}
          {/*    label={t('apply-to-join.form.last-name.label')}*/}
          {/*    placeholder={t('apply-to-join.form.last-name.placeholder')}*/}
          {/*    forwardRef={register({*/}
          {/*      required: t('apply-to-join.form.last-name.errors.required'),*/}
          {/*    })}*/}
          {/*  />*/}
          {/*  <FormRow*/}
          {/*    left={<Label text={t('apply-to-join.form.phone.label')} />}*/}
          {/*    right={<PhoneWithValidation name='phone' isRequired />}*/}
          {/*  />*/}
          {/*  <Input*/}
          {/*    name="email"*/}
          {/*    label={t('apply-to-join.form.email.label')}*/}
          {/*    placeholder={t('apply-to-join.form.email.placeholder')}*/}
          {/*    error={errors.email}*/}
          {/*    forwardRef={register({*/}
          {/*      pattern: {*/}
          {/*        value: emailPattern,*/}
          {/*        message: t('commercial-engagements.form.email.errors.email'),*/}
          {/*      },*/}
          {/*    })}*/}
          {/*  />*/}
          {/*  <PreferredSocialChannel*/}
          {/*    label={t('apply-to-join.form.preferred-social-channel.label')}*/}
          {/*    name={'preferredSocialChannel'}*/}
          {/*    control={control}*/}
          {/*    register={register}*/}
          {/*    options={socialOptions}*/}
          {/*    field={getValues('preferredSocialChannel')}*/}
          {/*  />*/}
          {/*  <SocialChannelHandle*/}
          {/*    label={t('apply-to-join.form.social-channel-handle.label')}*/}
          {/*    fields={fields}*/}
          {/*    append={addMoreHandler}*/}
          {/*    name={'socialChannelHandle'}*/}
          {/*    control={control}*/}
          {/*    register={register}*/}
          {/*    options={socialOptions}*/}
          {/*    remove={remove}*/}
          {/*  />*/}
          {/*  <FormRow*/}
          {/*    right={*/}
          {/*      <Button*/}
          {/*        disabled={isLoading}*/}
          {/*        text={t('shared.words.join')}*/}
          {/*        typeButton="submit"*/}
          {/*        paddingVertical="13"*/}
          {/*        paddingHorizontal="60"*/}
          {/*      />*/}
          {/*    }*/}
          {/*  />*/}
          {/*</form>*/}
        </div>
      </div>
    </FormProvider>
  );
};

export default WhyJoin;
