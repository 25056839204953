import React from 'react'
import get from 'lodash/get'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import DesktopHeader from './DesktopHeader'
import MobileHeader from './MobileHeader'

export default function TableHeader (props) {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 768)

  if (isMobile) return <MobileHeader {...props} />

  return <DesktopHeader {...props} />
}
