export const fontsCardElement = [{ cssSrc: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=block' }]

export const optionsCardElement = {
  hidePostalCode: true,
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#BABBCD',
      fontSize: '18px',
      fontWeight: 500,
      color: '#272835',
      fontFamily: 'Montserrat, sans-serif',
      '::placeholder': {
        color: '#9495A7',
      },
    },
    invalid: {
      iconColor: 'red',
      color: 'red',
    },
  },
};
