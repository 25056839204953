import { filtersConfig } from './filtersConfig.js'
import { subFiltersConfig } from './subFiltersConfig.js'

// TODO: Check if it would be better to keep all column components in a one place instead of near the each separate table
import Aficionado from '../components/Aficionado'
import Amount from '../components/Amount'
import Status from '../components/Status'
import Order from '../components/Order'


export const READY_MADE_EVENT_LESSONS_DETAILS_ORDERS = 'ready-made-event-lessons-details-orders'
export const AFICIONADO = 'aficionado'
export const ORDER = 'order'
export const AMOUNT = 'amount'
export const STATUS = 'status'

export const elementsTypes = {
  [AFICIONADO]: AFICIONADO,
  [ORDER]: ORDER,
  [AMOUNT]: AMOUNT,
  [STATUS]: STATUS,
}

export const tableConfig = [
  {
    header: 'table.header.aficionado',
    type: elementsTypes.aficionado,
    field: 'RATING',
  },
  {
    header: 'table.header.order',
    type: elementsTypes.order,
    field: 'ORDER_NUMBER',
  },
  {
    header: 'table.header.amount',
    type: elementsTypes.amount,
    field: 'ORDER_PRICE_AMOUNT',
  },
  {
    header: 'table.header.status',
    type: elementsTypes.status,
    field: 'ORDER_STATUS',
  },
]

const additionalFields = ['sortOrder']

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields,
]

export const readyMadeEventLessonsOrdersComponents = {
  [AFICIONADO]: Aficionado,
  [ORDER]: Order,
  [AMOUNT]: Amount,
  [STATUS]: Status,
}
