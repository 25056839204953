import React from 'react';
import PropTypes from 'prop-types';
import BaseSlide from '../BaseSlide';

const Empty = ({ size, isMirroredInside, icon, content, /* hint */ }) => {
  return (
    <BaseSlide
      slideProps={{
        'attr-size': size,
        'attr-mirrored-inside': String(isMirroredInside)
      }}
      withGradientOverhead={false}
    >
      <div className="tbv-slider-slide-empty">
        <div className="tbv-slider-slide-empty-icon">{icon}</div>
        <div className="tbv-slider-slide-empty-content">{content}</div>
        {/* EN-3151 hide hint */}
        {/*<div className="tbv-slider-slide-empty-hint">{hint}</div>*/}
      </div>
    </BaseSlide>
  );
};

Empty.propTypes = {
  isMirroredInside: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  hint: PropTypes.object.isRequired,
};

export default Empty;
