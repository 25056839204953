import React from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import map from 'lodash/map';
import flatMap from 'lodash/flatMap';
import isEmpty from 'lodash/isEmpty';
import { PROFILE_MANAGERS, LIST } from '_legacy/constants/routes';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useNavigateWithReload } from 'store/hooks/globalState/useSwitchNavigation';
import Button from '../Button';
import AsyncSelect from '../Form/Selects/basic/AsyncSelectComponent';

import { loadUsersOptions } from 'utils/services/loaders/users';
import { CustomOption, SelectedItem, NoOptionsMessage } from '../Form/Selects/basic/AsyncSelectsMultiComponents';
import { useFetch } from 'store/hooks/useFetch';
import { appApi } from 'api';
import { notify } from 'utils/notify';
import getTypeInvite from './utils';
import { getError } from 'utils/services/errors/error-config'
import { useStateModal } from 'store/hooks/globalState/useModal';

const ViaEntreeVIP = ({ type }) => {
  const t = useGetTranslate();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {},
  });
  const nevigate = useNavigateWithReload();
  const { handleSubmit, watch, setValue, clearErrors, setError } = methods;

  const [{ data, error, isLoading }, sendInvite] = useFetch(appApi.talent.join.joinAgentOrManager);
  const closeModal = useStateModal(() => null)
  const values = watch('entreevip');

  const onRemove = index => {
    setValue('entreevip', values.filter((_, i) => i !== index));
    clearErrors('entreevip');
  };

  const onSubmit = async (value) => {
    const invitations = map(flatMap(Object.values(value)), (v) => v.value);

    await sendInvite({ data: invitations, type });
  };

  React.useEffect(() => {
    if (data) {
      closeModal()
      nevigate(`/${PROFILE_MANAGERS}/${LIST}`,t('join-to-talent.notify.success'), 'success')
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setError('entreevip', { type: 'server', message: t(getError(error?.name)) });
      notify(t('join-to-talent.notify.error'), 'error');
    }
  }, [error]);

  return (
    <>
      <h2 className='joinToTalent-modal__subtitle'>{t('join-to-talent.subtitle')}</h2>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className='joinToTalent-entreevip__form'>
          <div className='joinToTalent-entreevip__form_select'>

            <span className='joinToTalent-link__label'>
              {t(`join-to-talent.entreevip.label.${getTypeInvite(type)}`)}
            </span>
            <AsyncSelect
              placeholder={t(`join-to-talent.entreevip.placeholder.${getTypeInvite(type)}`)}
              name='entreevip'
              loadOptions={(search, _, { page }) => loadUsersOptions(search, page, type)}
              openMenuOnClick
              isShowArrow={false}
              customComponents={{
                Option: CustomOption,
                NoOptionsMessage
              }}
              isMulti
              isClearable={false}
            />

            {!isEmpty(values) && <ul className='_multi-select__list'>
              {map(values, ({ value, label, image }, i) => (
                <SelectedItem
                  key={value}
                  label={label}
                  image={image}
                  onRemove={() => onRemove(i)}
                />
              ),
              )}
            </ul>}
          </div>

          <Button
            typeButton='submit'
            text={t('join-to-talent.button.submit')}
            classBtn='joinToTalent-email__button--submit joinToTalent-modal__submit'
            paddingVertical='16'
            paddingHorizontal='56'
            loader={isLoading}
            disabled={isEmpty(values)}
          />
        </form>
      </FormProvider>
    </>
  );
};

ViaEntreeVIP.propTypes = {
  type: PropTypes.string,
};

export default ViaEntreeVIP;
