import React from 'react';
import { useParams } from 'react-router';
import { notify } from 'utils/notify'
import { BulkOrdersAction } from 'shared'

import {
  fetchLiveVirtualLessonOrderDetails,
  updateLiveVirtualLessonTime as updateLiveVirtualLessonTimeApi,
  orderRequestAction
} from './api.js'

import { useSetLiveVirtualLessonOrderDetails } from './store.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'


export function useFetchLiveVirtualLessonOrderDetails() {
  const { orderId: occurrenceId } = useParams()

  const setBookingsDetails = useSetLiveVirtualLessonOrderDetails((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchLiveVirtualLessonOrderDetails({ occurrenceId })
      .then((details) => {
        setBookingsDetails(details)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [occurrenceId, setBookingsDetails]);
}

export function updateLiveVirtualLessonTime(){
  const t = useGetTranslate();
  const { orderId: occurrenceId } = useParams()
  const fetchOccurrence = useFetchLiveVirtualLessonOrderDetails()

  return React.useCallback(
    ({ startTime, endTime  }) => {
      updateLiveVirtualLessonTimeApi({ occurrenceId, startTime, endTime })
        .then(() => {
          fetchOccurrence()

          notify(t('order-details.live-virtual-lesson.time-change.success'))
        })
        .catch(console.warn)
    },
    [occurrenceId, fetchOccurrence]
  )
}

export function useCancelOrder() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchLiveVirtualLessonOrderDetails = useFetchLiveVirtualLessonOrderDetails()

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message
    }
    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.notification.cancelled'))
        fetchLiveVirtualLessonOrderDetails()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t, fetchLiveVirtualLessonOrderDetails]
  )
}
