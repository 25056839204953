import { loadCategories } from 'utils/services/loaders/categories'
import { loadLocations } from 'utils/services/loaders/locations'

export const CURRENT_LOCATION = 'CURRENT_LOCATION'

import { ReactComponent as NearMeIcon } from 'assets/near-me.svg'
import { ReactComponent as LastLocationIcon } from 'assets/last-location.svg'

export function getLocation(onSuccess, onError) {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(onSuccess, onError)
  }
}

export function getGroupedOptions(latestLocations, topLocations) {
  let location = [
    {
      label: 'search.filters.location.group.near-me',
      options: [
        {
          value: CURRENT_LOCATION,
          label: 'search.filters.location.options.current-location',
          data: {
            icon: NearMeIcon,
          },
        },
      ],
    },
  ]

  if (latestLocations) {
    location = [
      {
        options: latestLocations,
      },
      ...location,
    ]
  }

  if (topLocations) {
    location = [
      ...location,
      {
        label: 'search.filters.location.group.top',
        options: topLocations,
      },
    ]
  }

  return location
}

export function transformLocation({ details }) {
  return {
    value: details.placeId,
    label: details.name,
  }
}

export function transformLatestLocation({ details }) {
  return {
    value: details.placeId,
    label: details.name,
    data: {
      icon: LastLocationIcon,
    },
  }
}

export function transformTopLocation({ location_city, latitude, longitude, count }) {
  return {
    value: {
      latitude,
      longitude
    },
    label: location_city,
    data: {
      count
    }
  }
}

export async function locationsLoader (...rest) {
  if (rest[0].trim().length <= 0) return { options: [], hasMore: false }
  return await loadLocations(...rest)
}

export async function categoriesLoader (...rest) {
  return await loadCategories(...rest)
}
