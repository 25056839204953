import React from 'react'
import PropTypes from 'prop-types'

import DayCheckbox from './DayCheckbox'
import { getDaysInWeek } from 'utils/date'
import { useUserLocale } from 'store/hooks/globalState/useUser'

const DaysList = ({ name }) => {
  const locale = useUserLocale()
  const daysInWeek = getDaysInWeek(locale)

  return (
    <div className="days-list">
      {daysInWeek.map((day, idx) => (
        <DayCheckbox key={day} label={day} name={name} value={idx} />
      ))}
    </div>
  )
}

DaysList.propTypes = {
  name: PropTypes.string,
}

DaysList.defaultProps = {
  name: 'on',
}

export default DaysList
