import React from 'react'

import LineString from '_legacy/components/Table/table-components/types/common/LineString'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Participants = React.memo(({ isLoading, participantsCount, maxParticipantsCount }) => {
  const t = useGetTranslate()
  const bookParticipants = maxParticipantsCount
    ? `${participantsCount} ${t('my-orders.book-participants.of')} ${maxParticipantsCount}`
    : participantsCount

  return <LineString isLoading={isLoading} str={bookParticipants} className="center" />
})

export default Participants
