// Modules
import _get from 'lodash/get'

import { types } from 'shared'

// Utils
import { getRecurringTypesWithTranslate } from '_legacy/components/CreateExperience/components/Recurring/config'
import { parseRecurring, prepareRecurring } from 'utils/forms/adapters/recurring'

import { getCharitiesForForm, getCharitiesForSubmit } from 'utils/services/loaders/charities'
import { getMultiSelectLibraryAdapter, submitMultiSelectLibraryAdapter } from 'utils/services/validators/library'

import { getRadio } from 'utils/forms/adapters'
import { getTimeSelectOption } from 'utils/forms/adapters/timeWithTimeZone'
import { setMinutesToDate } from '_legacy/components/CreateExperience/utils/utils'
import { prepareDate, parseDate, UTCTimeZone } from 'utils/services/timezone'

import { getVirtualEventTypesOptions } from './config'

const { OTHER } = types.talent.offers.liveVirtualEvent.VirtualEventType


const getCustomType = data => {
  if (data?.type?.value === OTHER) {
    return data.customType
  }
  return undefined
}

const isTrue = (value) => {
  return value === 'true'
}

const setType = (data, t) => {
  const types = getVirtualEventTypesOptions(t)
  return types.find(type => data.type === type.value)
}

const setCustomType = data => {
  const type = data.type === OTHER
  return type ? data.customType : undefined
}

const setRecurring = (data, t) => {
  const recurringTypes = getRecurringTypesWithTranslate(t)
  return recurringTypes.find(type => type.value === data.recurring.type)
}

export const getLiveVirtualEventsDataAdapter = (data, t) => {
  const get = (key, defaultValue) => _get(data, key, defaultValue) || undefined
  const timeZone = data?.timeZone

  const deserializedData = {
    requiredPhysicalItem: getRadio(get('requiredPhysicalItem', false)),
    physicalItemsIncludedInPrice: getRadio(get('physicalItemsIncludedInPrice', true)),
    type: setType(data, t),
    customType: setCustomType(data),
    dateOfExperience: parseDate(get('date'), UTCTimeZone),
    startTime: getTimeSelectOption(
      parseDate(get('startTime'), timeZone),
    ),
    endTime: getTimeSelectOption(
      parseDate(get('endTime'), timeZone),
    ),
    recurring: setRecurring(data, t),
    physicalItems: get('physicalItems'),
    name: get('name'),
    price: String(get('price')),
    description: get('description'),
    recurringModal: parseRecurring(data, t),
    charities: getCharitiesForForm(get('charities')),
    timeZone,
    ...getMultiSelectLibraryAdapter('media', data),
  }

  return deserializedData
}

export const createLiveVirtualEventsAdapter = data => {
  const get = (key, defaultValue) => _get(data, key, defaultValue) || undefined
  const timeZone = data?.timeZone

  const serializedData = {
    price: +get('price'),
    recurring: prepareRecurring(data),
    date: prepareDate(
      get('dateOfExperience'),
      UTCTimeZone,
    ),
    startTime: prepareDate(
      setMinutesToDate(
        get('dateOfExperience'),
        get('startTime.value')
      ),
      timeZone,
    ),
    endTime: prepareDate(
      setMinutesToDate(
        get('dateOfExperience'),
        get('endTime.value')
      ),
      timeZone,
    ),
    type: get('type.value'),
    customType: getCustomType(data),
    name: get('name'),
    description: get('description'),
    requiredPhysicalItem: isTrue(get('requiredPhysicalItem')),
    physicalItemsIncludedInPrice: isTrue(get('requiredPhysicalItem'))
      ? isTrue(get('requiredPhysicalItem'))
      : undefined,
    physicalItems: isTrue(get('requiredPhysicalItem'))
      ? get('physicalItems')
      : undefined,
    charities: getCharitiesForSubmit(get('charities')),
    ...submitMultiSelectLibraryAdapter('media', data),
  }

  return serializedData
}
