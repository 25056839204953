import React from 'react'
import { useFormContext } from 'react-hook-form'

import TravelTime from '../TravelTime'
import Duration from '../Duration'
import SetDaysTime from '../SetDaysTime'
import OccurrenceLimit from '../OccurrenceLimit'
import TimeWindow from '../TimeWindow'
import RecurringWindow from '../RecurringWindow'
import { getMaxFlexibleEventDuration, isCustomRecurring, isRecurring, isTimeWindow } from './config'

const Flexible = () => {
  const { watch } = useFormContext()
  const formState = watch()

  return (
    <>
      <SetDaysTime />
      {isTimeWindow(formState) && <TimeWindow />}
      {isRecurring(formState) && <RecurringWindow />}
      {!isCustomRecurring(formState) && <OccurrenceLimit />}
      <TravelTime />
      <Duration maxDuration={getMaxFlexibleEventDuration(formState)} />
    </>
  )
}

export default Flexible
