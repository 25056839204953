import styled from 'styled-components';
import PropTypes from 'prop-types';

export const SubTitle = styled.h1`
  font-size: 23px;
  line-height: 29px;
  font-weight: 900;
  font-family: 'Playfair Display', sans-serif;
  font-feature-settings: 'case' on, 'liga' off;
  color: #9495A7;
  margin: 0 auto;
  text-align: center;
`;

SubTitle.propTypes = {
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.string,
  textAlign: PropTypes.string,
};

