// Base imports
import React from 'react'

// Requests
import { useFetchExperienceOrderRequests, useAcceptOrderRequest, useRejectOrderRequest } from '../state/requests/experience-order-requests'

// Store (Hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useExperienceOrderRequestsFilters,
  useSetExperienceOrderRequestsFilters,
  useExperienceOrderRequests,
} from '../state/store'

// Utils
import {
  filtersConfig,
  counterRequestsConfig,
  subFiltersConfig,
  fields,
} from '_legacy/configs/table/orderDetailsRequests'

// Legacy components
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import {
  OFFERINGS_DETAILS_REQUESTS,
  elementsTypes,
} from '_legacy/components/Table/table-components/types/order-details/config'

const sortFields = {
  AFICIONADO: 'AFICIONADO_FULL_NAME',
  PARTICIPANTS: 'PARTICIPANTS_COUNT',
  AMOUNT: 'ORDER_PRICE_AMOUNT',
  STATUS: 'ORDER_STATUS'
}

const tableConfig = [
  {
    header: 'table.header.aficionado',
    field: sortFields.AFICIONADO,
    type: elementsTypes.aficionado,
  },
  {
    header: 'table.header.participants',
    field: sortFields.PARTICIPANTS,
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.amount',
    field: sortFields.AMOUNT,
    type: elementsTypes.amount,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    field: sortFields.STATUS,
    type: elementsTypes.status,
    className: 'centered',
  },
]

const ExperienceOrderDetailsRequests = () => {
  const t = useGetTranslate()
  const fetchTable = () => useFetchExperienceOrderRequests()
  const acceptOrderRequest = useAcceptOrderRequest()
  const rejectOrderRequest = useRejectOrderRequest()

  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useExperienceOrderRequestsFilters,
      setFilters: useSetExperienceOrderRequestsFilters,
      actions: [
        {
          label: t('order-details.bulk-actions.accept'),
          cbFn: acceptOrderRequest,
        },
        {
          label: t('order-details.bulk-actions.cancel'),
          cbFn: rejectOrderRequest,
        },
      ],
    }
  }, [t, useExperienceOrderRequestsFilters, useSetExperienceOrderRequestsFilters])

  return (
    <div className="wrapper content-wrapper">
      <Filters
        filtersConfig={filtersConfig}
        getFn={useExperienceOrderRequestsFilters}
        setFn={useSetExperienceOrderRequestsFilters}
      />
      <SubFilters
        counterConfig={counterRequestsConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useExperienceOrderRequestsFilters}
        setFn={useSetExperienceOrderRequestsFilters}
      />
      <Table
        tableName={OFFERINGS_DETAILS_REQUESTS}
        emptyTableText={'table.empty-requests'}
        tableConfig={tableConfig}
        bulkActionsConfig={bulkActionsConfig}
        getTableFn={useExperienceOrderRequests}
        fields={fields}
        getFiltersFn={useExperienceOrderRequestsFilters}
        setFiltersFn={useSetExperienceOrderRequestsFilters}
        fetchTableFn={fetchTable}
      />
    </div>
  )
}

export default ExperienceOrderDetailsRequests
