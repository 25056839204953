// Modules
import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { getDate } from 'date-fns'
import get from 'lodash/get'
import cx from 'classnames'

// Store (hooks)
import useScroll from 'store/hooks/useScroll'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetHasUnreadChatMessages } from 'store/hooks/globalState/useChats'

// Utils
import { parseDate } from 'utils/services/timezone'
import {
  getTalentId,
  getUserId,
  getUserTimeZone,
} from 'utils/user'

// Legacy components
import LoaderIndicator from '_legacy/components/Common/Loader'

// Components
import Message from './ActiveChatMessage'
import ActiveChatIsEmpty from './ActiveChatIsEmpty'
import MessagesDateDivider from './MessagesDateDivider'


const ActiveChatMessages = ({
  selectedChat: {
    chatInstance,
    chatInfo,
    hasNewMessages,
    newMessage,
    newMessageSid,
  },
  isMobile,
}) => {
  const t = useGetTranslate()

  const talentId = useUser(getTalentId)
  const userId = useUser(getUserId)

  const currentUserId = talentId || userId

  const timeZone = useUser(getUserTimeZone)

  const setHasUnreadChatMessages = useSetHasUnreadChatMessages((prev, next) => next, [])

  const [isLoading, setIsLoading] = useState(true)
  const [listState, setListState] = useState({})
  const [messagesList, setMessagesList] = useState([])
  const [newMassageIndex, setNewMessageIndex] = useState(null)

  const mobileClassName = cx({ 'mobile-view': isMobile })

  const updateMessagesList = (
    {
      items,
      hasNextPage,
      hasPrevPage,
      nextPage,
      prevPage,
    },
    isNew = false,
  ) => {

    const newMessagesList = isNew
      ? items
      : items.concat(messagesList)

    setMessagesList(newMessagesList)

    setListState({
      ...(!isNew ? listState : {}),
      hasNextPage,
      hasPrevPage,
      nextPage,
      prevPage,
    })

    setIsLoading(false)
  }

  const updateLastReadMessageIndex = async () => {
    const lastReadMessageIndex = chatInstance.lastReadMessageIndex
    const lastMessage = messagesList.slice(-1).pop()

    const isPageVisible = document.visibilityState === 'visible'
    const newMessageIsNotReadYet = lastMessage && lastReadMessageIndex < lastMessage.index

    const shouldUpdateLastReadMessageIndex = newMessageIsNotReadYet && isPageVisible

    if (shouldUpdateLastReadMessageIndex) {
      await chatInstance.updateLastReadMessageIndex(lastMessage.index)
    }
  }

  const processNewMessages = async () => {
    if (hasNewMessages) {
      const existedMessage = messagesList.find(m => m.sid === newMessageSid)

      if (!existedMessage) {
        const updatedList = messagesList.concat([newMessage])

        setMessagesList(updatedList)

        const isPageVisible = document.visibilityState === 'visible'
        const newMessageIsNotReadYet = chatInstance?.lastReadMessageIndex === newMessage.index - 1

        if (newMessage.author !== currentUserId) {
          if (isPageVisible && newMessageIsNotReadYet) {
            setNewMessageIndex(newMessage.index)
          } else {
            setNewMessageIndex((chatInstance?.lastReadMessageIndex || 0) + 1)
            setHasUnreadChatMessages(true)
          }
        } else {
          setNewMessageIndex(null)
        }
      }
    }
  }

  const fetchMessages = async () => {
    setIsLoading(true)
    await updateMessagesList(await chatInstance.getMessages(), true)
  }

  const hasMore = get(listState, 'hasPrevPage', false) && !isLoading

  const fetchNext = async () => {
    if (listState?.hasPrevPage && !isLoading) {
      setIsLoading(true)
      updateMessagesList(await listState.prevPage())
    }
  }

  // INFINITE SCROLL
  const parentLoaderRef = useRef()
  const childLoaderRef = useRef()

  useScroll({
    parentRef: parentLoaderRef,
    childRef: childLoaderRef,
    callback: fetchNext,
    hasMore: hasMore,
  })

  // SCROLL TO BOTTOM
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    if (!isMobile) {
      messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  useEffect(fetchMessages, [chatInstance])

  useEffect(updateLastReadMessageIndex, [
    chatInstance,
    messagesList,
    document.visibilityState,
  ])

  useEffect(processNewMessages, [hasNewMessages, newMessageSid, newMessage])

  useEffect(scrollToBottom, [messagesList, isMobile])

  return (
    <div className={`messages-list ${mobileClassName}`} ref={parentLoaderRef}>
      {hasMore && (
        <div ref={childLoaderRef} className="messages-list__loader">
          <LoaderIndicator size={24} fill="#636583" />
        </div>
      )}

      {
        !messagesList.length
          ? (<ActiveChatIsEmpty />)
          : messagesList.map((message, index) => {
            const isNewMessage = message.index === newMassageIndex

            const nextMessage = messagesList[index + 1] || null

            const messageZonedDate = parseDate(message?.dateCreated, timeZone)
            const nextMessageZonedDate = parseDate(nextMessage?.dateCreated, timeZone)

            const withDateDivider =
            nextMessage &&
            getDate(messageZonedDate) !== getDate(nextMessageZonedDate)

            return (
              <div key={index}>
                {isNewMessage && (
                  <div className="new-messages">
                    <p>{t('chats.chat.new-messages')}</p>
                  </div>
                )}

                {!hasMore && index === 0 && (
                  <MessagesDateDivider date={messageZonedDate} />
                )}

                <Message message={message} chatInfo={chatInfo} messageZonedDate={messageZonedDate} />

                {withDateDivider && (
                  <MessagesDateDivider date={nextMessageZonedDate} />
                )}
              </div>
            )
          })
      }

      <div ref={messagesEndRef} />
    </div>
  )
}

ActiveChatMessages.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  selectedChat: PropTypes.objectOf(
    PropTypes.shape({
      chatInfo: PropTypes.object.isRequired,
      chatInstance: PropTypes.object.isRequired,
      newMessage: PropTypes.object,
      newMessageSid: PropTypes.string,
      hasNewMessages: PropTypes.bool,
    })
  ),
}

ActiveChatMessages.defaultProps = {
  selectedChat: {
    hasNewMessages: false,
    newMessageSid: null,
    newMessage: null,
  },
}

export default ActiveChatMessages
