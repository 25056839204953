import React from 'react'
import { isFunction, includes } from 'lodash/fp'

import { errorNames } from 'shared'
import {
  leaveComplaint as leaveComplaintApi,
  checkComplaint as checkComplaintApi
} from 'api/appApi/offers/complaint'
import { useSetComplaint, useSetComplaintAvailableError } from 'store/hooks/globalState/useComplaint'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { notify } from 'utils/notify'

export function useLeaveComplaint(onSuccess) {
  const t = useGetTranslate()
  const setComplaint = useSetComplaint(() => false)

  return React.useCallback(
    ({ orderId, message }) => {
      leaveComplaintApi({ orderId, message })
        .then(() => {
          notify(t('complaint.notification.success'))
          setComplaint()
          if (isFunction(onSuccess)) onSuccess()
        })
        .catch(() => {
          notify(t('_errors.default'), 'error')
        })
    },
    [t, setComplaint]
  )
}

export function useCheckComplaint(onSuccess) {
  const setComplaint = useSetComplaint((prev, next) => next)
  const setComplaintError = useSetComplaintAvailableError((prev, next) => next)

  return React.useCallback(
    (orderId) => {
      checkComplaintApi({ orderId })
        .then(() => {
          setComplaint(true)
          if (isFunction(onSuccess)) onSuccess()
        })
        .catch((error) => {
          setComplaint(false)

          const isComplaintAvailableError = includes(
            error.name,
            [
              errorNames.reviews.REVIEW_ALREADY_EXISTS,
              errorNames.orders.ORDER_COMPLETED_N_DAYS_AGO,
              errorNames.orders.ORDER_ALREADY_CLOSED,
            ]
          )

          setComplaintError(isComplaintAvailableError)
        })
    },
    [setComplaint, onSuccess]
  )
}
