import React from 'react'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

import { PROMO_CODES, LIST } from '_legacy/constants/routes'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'
import { useUser } from 'store/hooks/globalState/useUser.js'
import { useLessons, useSetLessons } from 'store/hooks/globalState/useLessons.js'

import {
  useFetchLessons,
} from 'requests/lessons.js'

import PageLoader from '_legacy/common/Template/PageLoader.js'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary.js'

import LessonFormWrapper from './components/LessonFormWrapper/'

import VoiceOverLessonForm from './components/VoiceOverLessonForm/'
import LiveVirtualLessonForm from './components/LiveVirtualLessonForm/'
import LiveInPersonLessonForm from './components/LiveInPersonLessonForm/'

import {
  videoVoiceTextContent,
  liveVirtualTextContent,
  liveInPersonTextContent,
} from './constants.js'

const LESSON_KEYS = {
  videoVoiceOverLesson: 'videoVoiceOverLesson',
  liveVirtualLesson: 'liveVirtualLesson',
  liveInPersonLesson: 'liveInPersonLesson',
}


// TODO: Think about more specific name
const Lessons = () => {
  const t = useGetTranslate()
  const [activeBlock, setActiveBlock] = React.useState(LESSON_KEYS.videoVoiceOverLesson)

  const user = useUser()
  const lessons = useLessons(state => get(state, 'lessons'))

  const fetchLessons = useFetchLessons()
  const clearLessons = useSetLessons(() => null)

  React.useEffect(() => {
    fetchLessons()
    return () => {
      clearLessons()
    }
  }, [user])

  if (!lessons) {
    return <PageLoader />
  }

  const setElement = key => e => {
    e.preventDefault()
    setActiveBlock(key)
  }

  // TODO: Spread the rest of props between forms
  const voiceOverLessonFormProps = {
    key: LESSON_KEYS.videoVoiceOverLesson,
    header: 'lessons.header.video-voice',
    rules: videoVoiceTextContent,
    defaultData: get(lessons, 'videoVoiceOverLesson') || {},
  }

  const liveVirtualLessonProps = {
    key: LESSON_KEYS.liveVirtualLesson,
    header: 'lessons.header.live-virtual',
    rules: liveVirtualTextContent,
    defaultData: get(lessons, 'liveVirtualLesson') || {},
  }

  const liveInPersonLessonProps = {
    key: LESSON_KEYS.liveInPersonLesson,
    header: 'lessons.header.live-in-person',
    rules: liveInPersonTextContent,
    defaultData: get(lessons, 'liveInPersonLesson') || {},
  }

  // TODO: Simplify the markup
  return (
    <div className="lessons-container wrapper content-wrapper">
      <h1 className={'large'}>
        {t('lessons.header.title')}
      </h1>

      <p className="lessons-container--subtitle">
        {t('lessons.header.subtitle')}
      </p>

      <p className="lessons-title-hint">
        {t('offerings.manage-promo-codes.text')}&nbsp;
        <Link to={`/${PROMO_CODES}/${LIST}`}>{t('offerings.manage-promo-codes.link')}</Link>
      </p>

      <div className="lessons__wrapper">
        <ul className="lessons__tabs">
          <li key={LESSON_KEYS.videoVoiceOverLesson}>
            <button
              className={`lessons__tabs_tab ${
                activeBlock === LESSON_KEYS.videoVoiceOverLesson ? 'on' : ''
              }`}
              onClick={setElement(LESSON_KEYS.videoVoiceOverLesson)}
            >
              {t(voiceOverLessonFormProps.header)}
            </button>
          </li>

          <li key={LESSON_KEYS.liveVirtualLesson}>
            <button
              className={`lessons__tabs_tab ${
                activeBlock === LESSON_KEYS.liveVirtualLesson ? 'on' : ''
              }`}
              onClick={setElement(LESSON_KEYS.liveVirtualLesson)}
            >
              {t(liveVirtualLessonProps.header)}
            </button>
          </li>

          <li key={LESSON_KEYS.liveInPersonLesson}>
            <button
              className={`lessons__tabs_tab ${
                activeBlock === LESSON_KEYS.liveInPersonLesson ? 'on' : ''
              }`}
              onClick={setElement(LESSON_KEYS.liveInPersonLesson)}
            >
              {t(liveInPersonLessonProps.header)}
            </button>
          </li>
        </ul>

        <div className="lessons-list lesson">
          <LessonFormWrapper
            key={voiceOverLessonFormProps.header}
            header={voiceOverLessonFormProps.header}
            showActiveBlock={voiceOverLessonFormProps.key === activeBlock}
          >
            <VoiceOverLessonForm {...voiceOverLessonFormProps } />
          </LessonFormWrapper>

          <LessonFormWrapper
            key={liveVirtualLessonProps.header}
            header={liveVirtualLessonProps.header}
            showActiveBlock={liveVirtualLessonProps.key === activeBlock}
          >
            <LiveVirtualLessonForm {...liveVirtualLessonProps } />
          </LessonFormWrapper>

          <LessonFormWrapper
            key={liveInPersonLessonProps.header}
            header={liveInPersonLessonProps.header}
            showActiveBlock={liveInPersonLessonProps.key === activeBlock}
          >
            <LiveInPersonLessonForm {...liveInPersonLessonProps } />
          </LessonFormWrapper>
        </div>
      </div>
    </div>
  )
}

export default withLibrary(Lessons)
