import { api } from 'shared'
import mileToKm from 'utils/mileToKm'
import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

const { NAME, FOLLOWERS_COUNT } = api.searches.results.talents.search.SortField

// talent
export const useSearchList = createUseGlobalState('searchList')
export const useSetSearchList = createUseSetGlobalState('searchList')

export const useSearchListFilters = createUseGlobalState('searchListFilters')
export const useSetSearchListFilters = createUseSetGlobalState('searchListFilters')

const filtersInitialState = {
  sortField: FOLLOWERS_COUNT,
  search: '',
  availableOfferingTypesIn: null,
  languagesIn: null,
  followersCountFrom: null,
  followersCountTo: null,
  ratingFrom: null,
  ratingTo: null,
  location: null,
  locationKmRadius: mileToKm(5),
  categoryIn: null,
}

export function useClearSearchFilters() {
  return useSetSearchListFilters(() => filtersInitialState)
}


// offers
export const useSearchOffers = createUseGlobalState('searchOffers')
export const useSetSearchOffers = createUseSetGlobalState('searchOffers')

export const useSearchOffersFilters = createUseGlobalState('searchOffersFilters')
export const useSetSearchOffersFilters = createUseSetGlobalState('searchOffersFilters')

const offersSearchFiltersInitialState = {
  sortField: NAME,
  search: '',
  offeringTypesIn: null,
  location: null,
  locationKmRadius: mileToKm(5),
  price: null,
  languagesIn: null,
  scheduling: null,
  date: null,
  duration: null,
  participants: null,
  talentCategoryIn: null,
  sortByLocationLatitude: null,
  sortByLocationLongitude: null
}

export function useClearSearchOffersFilters() {
  return useSetSearchOffersFilters(() => offersSearchFiltersInitialState)
}

// aficionados
export const useSearchAficionados = createUseGlobalState('searchAficionados')
export const useSetSearchAficionados = createUseSetGlobalState('searchAficionados')
