import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
// import { useClearSearchOffersFilters } from 'store/hooks/globalState/useSearchList'
// import Button from '_legacy/components/Button'

// import * as r from '_legacy/constants/routes'

const EmptyOffersList = () => {
  const t = useGetTranslate()
  // const clearFilters = useClearSearchOffersFilters()

  return (
    <div className="empty-list">
      <h3>{t('search.list.offerings.not-found.header')}</h3>
      <p>{t('search.list.offerings.not-found.content')}</p>
      {/* TODO: We are commenting this as part of requirement "https://entreevip.atlassian.net/browse/ES-70". We will use this in future. */}
      {/* <Button
        isLink
        path={`/${r.SEARCH}/${r.OFFERINGS}`}
        text={t('search.list.offerings.not-found.button')}
        handleOnClick={clearFilters}
      /> */}
    </div>
  )
}

export default EmptyOffersList
