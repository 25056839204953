import CalendarSlot from 'components/Calendar/components/CalendarSlot'

export const defaultCalendarConfig = {
  themeSystem: 'standard',
  allDaySlot: false,
  editable: true,
  selectable: true,
  selectMirror: true,
  selectOverlap: false,
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short'
  }
}

export const initialView = {
  ['week']: 'timeGridWeek',
  ['day']: 'timeGridDay',
}

export const talentCalendarConfig = {
  ...defaultCalendarConfig,
  initialView: 'timeGridWeek',
  eventContent: CalendarSlot,
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek timeGridDay listWeek'
  },
}

export const bookingCalendarConfig = {
  ...defaultCalendarConfig,
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek timeGridDay'
  },
  select: () => false,
}

export const calendarScrollTime = '12:00:00'
