import identity from 'lodash/fp/identity'
import { getCharities as getCharitiesApi } from 'api/appApi/charity/charity'
import { charitiesPreparator } from 'utils/services/loaders/charities'

// todo: find way to add translate
export const appendDefaultOptions = list => [
  { value: 'NOT_SPECIFIED', label: 'Not Specified' },
  ...list,
]

export const charitiesLoader = async (...props) => {
  try {
    const [search = '', meta] = props
    const { charities = [], pageInfo } = await getCharitiesApi({
      name: search,
      page: meta?.page,
    })

    const handler = pageInfo.page === 1 ? appendDefaultOptions : identity

    return {
      options: handler(charities.map(charitiesPreparator)),
      hasMore: pageInfo.remains !== 0,
      additional: {
        page: pageInfo.page + 1,
      },
    }
  } catch (err) {
    return {
      options: appendDefaultOptions([]),
      hasMore: false,
    }
  }
}
