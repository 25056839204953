import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useSetModal } from 'store/hooks/globalState/useModal'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

const ESC_KEY = 27

const Modal = ({
  isSubModal,
  children,
  isOpen,
  showCloseButton,
  onClose: _onClose,
  isMobileFullScreen,
  modalClassName,
  modalContentClassName,
  closeClassName,
  overlayClassName,
  closeOnClickOutside
}) => {
  const { height, width } = useWindowDimensions()
  const isMobile = 575 > width

  if (!isOpen) {
    return null
  }

  const onClose = _onClose || useSetModal(() => null)

  React.useEffect(() => {
    const close = e => {
      if (e.keyCode === ESC_KEY) onClose()
    }

    window.addEventListener('keydown', close)

    return () => window.removeEventListener('keydown', close)
  }, [])

  React.useEffect(() => {
    document.documentElement.classList.toggle('stop-scrolling', isOpen)

    return () => {
      // Don't remove stop-scrolling if subModal closes
      if (!isSubModal) document.documentElement.classList.remove('stop-scrolling')
    }
  }, [isOpen])

  return (
    <div className={cx('modal-wrapper', {
      [modalClassName]: !!modalClassName,
    })}>
      <div className={cx('modal-wrapper-overlay', {
        'modal-wrapper-overlay__submodal': isSubModal,
        [overlayClassName]: !!overlayClassName,
      })} onClick={closeOnClickOutside && onClose} />

      <div
        style={{ ...(isMobileFullScreen && isMobile && { height }) }}
        className={cx('modal-wrapper-content', {
          'modal-wrapper-content__mobile-fullscreen': isMobileFullScreen,
        })}
      >
        {showCloseButton && (
          <button
            className={cx('modal-wrapper-content-button', {
              [closeClassName]: !!closeClassName,
            })}
            type="button"
            onClick={onClose}
          />
        )}
        <div className={cx('modal-wrapper-content-children', {
          'modal-wrapper-content-children__mobile-fullscreen': isMobileFullScreen,
          [modalContentClassName]: !!modalContentClassName,
        })}>
          {children}
        </div>
      </div>
    </div>
  )
}

Modal.propTypes = {
  isSubModal: PropTypes.bool,
  children: PropTypes.element,
  isOpen: PropTypes.bool,
  isMobileFullScreen: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  modalClassName: PropTypes.string,
  modalContentClassName: PropTypes.string,
  closeClassName: PropTypes.string,
  overlayClassName: PropTypes.string,
  closeOnClickOutside: PropTypes.bool,
}

export default Modal
