import React from 'react';
import { api } from 'shared'

import {
  followOffer as followOfferApi,
  unFollowOffer as unFollowOfferApi,
  followTalent as followTalentApi,
  unFollowTalent as unFollowTalentApi,
} from 'api/appApi/talent/follow';
import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const { TALENT_USER_CANNOT_FOLLOW_SELF_TALENT } = api.talents.talent.actions.follow.errors

export function useUpdateFollowTalent(setFollow) {
  const t = useGetTranslate()

  return React.useCallback(({ id, isFollowed }) => {
    const request = isFollowed ? unFollowTalentApi : followTalentApi;
    request({ talentId: id })
      .catch((e) => {
        if (TALENT_USER_CANNOT_FOLLOW_SELF_TALENT === e.name) {
          notify(t('follow.errors.talent-user-cannot-self-talent'), 'error')
          setFollow(false)
        }
      });
  }, [t]);
}

export function useUpdateFollowOffering() {
  return React.useCallback(({ id, isFollowed }) => {
    const request = isFollowed ? unFollowOfferApi : followOfferApi;
    request({ offerId: id });
  }, []);
}
