import { getDate } from 'date-fns';


export const weekOfMonth = date => {
  const prefixes = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'LAST'];
  return prefixes[0 | (getDate(date) / 7)];
};

export const parseToBoolean = value => value === 'Yes';

export const formUnderscoreToNormal = (text = '') => {
  const words = text
    .toLowerCase()
    .split('_')
    .join(' ');
  const firstChar = words.charAt(0).toUpperCase();
  return firstChar + words.slice(1);
};

export const formUnderscoreToKebabCase = (text = '') => {
  return text.toLowerCase().split('_').join('-');
};

export const getValidateObj = obj => {
  const copy = { ...obj };
  const objKeys = Object.keys(copy);

  objKeys.forEach(key => {
    if (copy[key] === '') {
      copy[key] = null;
    } else if (copy[key] === 'true') {
      copy[key] = true;
    } else if (copy[key] === 'false') {
      copy[key] = false;
    }
  });

  return copy;
};

export const createArray = (data = []) => {
  return typeof (data) === 'string' ? [data] : data;
};

