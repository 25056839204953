import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import listPlugin from '@fullcalendar/list'
import enLocale from '@fullcalendar/core/locales/en-gb'
import esLocale from '@fullcalendar/core/locales/es-us'
import ptLocale from '@fullcalendar/core/locales/pt'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import { initialView as INITIAL_VIEW } from './configs/calendar-config'

import { calendarAdapter } from './utils'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import {
  useSetTalentCalendarFilters,
} from 'components/Calendar/TalentCalendar/state/store/useCalendar'

const Calendar = ({
  slots,
  initialDate,
  initialView,
  className,
  ...props
}) => {
  const t = useGetTranslate()
  const calendarRef = React.useRef()
  const calendarEvents = React.useMemo(() => calendarAdapter(slots), [slots])
  const setTime = useSetTalentCalendarFilters((prev, startTime, endTime) => ({ ...prev, startTime, endTime }))
  const userLang = useUserLang()
  const history = useHistory()

  const onNextButton = () => {
    const calendar = calendarRef.current.getApi()
    calendar.next()
    setTime(calendar.view.activeStart, calendar.view.activeEnd)
  }

  const onPrevButton = () => {
    const calendar = calendarRef.current.getApi()
    calendar.prev()
    setTime(calendar.view.activeStart, calendar.view.activeEnd)
  }

  const onTodayButton = () => {
    const calendar = calendarRef.current.getApi()
    calendar.today()
    setTime(calendar.view.activeStart, calendar.view.activeEnd)
  }

  React.useEffect(() => {
    const calendar = calendarRef.current.getApi()
    calendar.gotoDate(initialDate)
  }, [initialDate])

  React.useEffect(() => {
    const calendar = calendarRef.current.getApi()
    calendar.changeView(initialView)
  }, [initialView])

  const handleDatesChange = data => {
    const viewType = data.view.type

    if (viewType !== initialView) {
      const viewSearchParam = Object
        .keys(INITIAL_VIEW)
        .filter(key => viewType === INITIAL_VIEW[key])
        .pop()

      if (viewSearchParam) {
        history.replace({
          pathname: history.location.pathname,
          search: `?view=${viewSearchParam}`
        })
      }
    }
  }

  const classes = cx('calendar', className)
  const [locLang,setLocLang] = React.useState();
  const locale = {
    EN: enLocale,
    ES: esLocale,
    PT: ptLocale
  }[userLang]
  React.useEffect(()=>{
    if(locale.code === "en-gb"){
      for (const key in locale) {
        if (key.includes('Hint')) {
          delete locale[key];
        }
      }
      setLocLang(locale);
    }
  },[locale])

  return (
    <div className={classes}>
      <FullCalendar
        ref={calendarRef}
        slotEventOverlap={false}
        longPressDelay='100'
        initialDate={initialDate}
        customButtons={{
          prev: { click: onPrevButton },
          next: { click: onNextButton },
          today: { click: onTodayButton, text: t('calendar.talent.today-btn') },
        }}
        events={calendarEvents}
        plugins={[dayGridPlugin, listPlugin, timeGridPlugin, interactionPlugin]}
        locale={locale.code === "en-gb" ? locLang : locale}
        datesSet={handleDatesChange}
        {...props}
        slotDuration='00:15:00'
        slotLabelInterval='01:00'
      />
    </div>
  )
}

export default Calendar
