import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import ModalButton from '../../components/Modal/ModalButton'
import DefaultBackdrop from '../../components/Backdrop/Backdrop'

const MultiSelectLibraryButton = ({ maxSize = 200, name, onClick, customBackdrop: CustomBackdrop }) => {
  const Backdrop = CustomBackdrop || DefaultBackdrop
  const { errors } = useFormContext()
  const error = !isEmpty(get(errors, name))

  // todo: find best way to handle error
  return (
    <ModalButton error={error} onClick={onClick}>
      <Backdrop name={name} maxSize={maxSize} />
    </ModalButton>
  )
}

MultiSelectLibraryButton.propTypes = {
  name: PropTypes.string,
  onClick: PropTypes.func,
  customBackdrop: PropTypes.element,
}

export default MultiSelectLibraryButton
