import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { Link } from 'react-router-dom';
import * as r from '_legacy/constants/routes'


function VerificationAttemptsCoundown({ fields }) {
  const t = useGetTranslate();
  const { watch } = useFormContext();
  const value = watch([fields.isCodeConfirm, fields.isPhoneSand, fields.canRetry, fields.remainingAttempts]);
  const remainingAttempts = get(value, fields.remainingAttempts);

  return (
    <AttemptsWrapper remainingAttempts={remainingAttempts}>
      { remainingAttempts > 0 ?
        <Trans
          tKey="register-user.form.phone.verification.attempts"
          phs={[{ ph: '{{attempts}}', value: remainingAttempts }]}
        />
        :
        <>
          { t('register-user.form.phone.verification.no-attempts.code-not-received') }
          <Link to={`/${r.CONTACT}`}>{t('register-user.form.phone.verification.no-attempts.contact-us')}</Link>
        </>
      }
      {/* { remainingAttempts === 0 && t("register-user.form.phone.verification.contact-us") } */}
    </AttemptsWrapper>
  )
}

VerificationAttemptsCoundown.propTypes = {
  fields: PropTypes.object,
}

const AttemptsWrapper = styled.p`
  text-align: ${({ remainingAttempts }) => (remainingAttempts? 'right' : 'left')};;
  padding: 6px;
`

export default VerificationAttemptsCoundown
