import React from 'react'
import styled from 'styled-components'
import { useWatch } from 'react-hook-form'
import FixedCheckbox from '_legacy/components/Form/Taboos/FixedCheckbox';
import MultiCheckbox from '_legacy/components/Form/Taboos/MultiCheckbox';
import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { submitRadio } from 'utils/forms/adapters/radio'
import { Row, Input } from '_legacy/common'
import RadioList from '_legacy/common/Radio/RadioList'
import { useFormContext } from 'react-hook-form';
export const commercialUseOfExperiencefootage= [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
]
export const defaultFormats = [
  'Company Owned Website',
  'Email',
  'Social Media Channels'
];


const CommercialUseTerms = () => {
  const t = useGetTranslate()
  const { register, getValues } = useFormContext();
  const customFormatsName = "customFormats"
  const getCustomFormats = getValues(customFormatsName)
  const commercialUseTerms = submitRadio(useWatch({ name: 'commercialUseOfExperiencefootage' }))

  return (
    <>
      <CommercialUseTermsWrapper
        nameBlock={'Commercial Use of Experience Footage:'}
        component={
          <RadioList
            name="commercialUseOfExperiencefootage"
            options={commercialUseOfExperiencefootage}
            defaultChecked={false}
          />
        }
      />
      {commercialUseTerms && (
        <div>
          <Row
            component={
              <StyledHeader>Commercial Use Terms</StyledHeader>
            }
          />
          <Row
            isRequired
            nameBlock={t('video-message.form.month-of-use.title')}
            subNameBlock={t('video-message.form.month-of-use.sub-title')}
            component={
              <StyledWrapper>
                <Input
                  name="monthOfUse"
                  type="number"
                  defaultValue="3"
                  placeholder={t('create-experience.form.number-of-participants.placeholder')}
                  validation={{
                    require: 'create-experience.form.number-of-participants.errors.required',
                    min: {
                      value: 1,
                      message: 'create-experience.form.number-of-participants.errors.min',
                    },
                  }}
                />
              </StyledWrapper>
            }
          />
          <Row
            isRequired
            nameBlock={t('video-message.form.format-included.title')}
            component={
              <>
                <FixedCheckbox
                  name="defaultFormats"
                  entities={defaultFormats}
                  register={register}
                />
                <MultiCheckbox
                  name="customFormats"
                  register={register}
                  defaultEntities={getCustomFormats ? getCustomFormats : []}
                  buttonLabel={t('video-message.form.format-included.buttom-label')}
                  placeholder={t('video-message.form.format-included.placeholder')}
                />
              </>
            }
          />
          <CompanyMayAddLogoWrapper
            nameBlock={t('video-message.form.company-may-add-logo')}
            component={<RadioSwitcher name={'comapnyMayAddLogo'} />}
          />
          <Row
            nameBlock={t('video-message.form.add-terms.label')}
            component={
              <MultiCheckbox
                name="terms"
                register={register}
                defaultEntities={getValues('terms') || []}
                placeholder={t('video-message.form.add-terms.placeholder')}
                buttonLabel={t('video-message.form.add-terms.button-label')}
              />
            }
          />
        </div>
      )}
    </>
  )
}

const CommercialUseTermsWrapper = styled(Row)`
  align-items: center;
`
const StyledWrapper = styled.div`
  max-width: 262px;
  @media screen and (max-width: 425px) {
    max-width: 100%;
  }
`
const CompanyMayAddLogoWrapper = styled(Row)`
  align-items: center;
`;
const StyledHeader = styled.h4`
  margin-top: 0;
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 20px;
  line-height: 1.25;
  font-feature-settings: 'case' on, 'liga' off;
  color: ${({ theme }) => theme.colors.blue};
`

export default CommercialUseTerms
