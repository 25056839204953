import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import get from 'lodash/get';

import { Title } from '_legacy/common';
import Button from '_legacy/components/Button';

import { useVerifyRemoveAccount } from 'requests/account/remove';
import { removeRequestErrorsConfig, removeVerifyRequestErrorsConfig } from 'requests/account/errorsConfig';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const VerifyRemoveAccount = () => {
  const t = useGetTranslate();
  const { key } = useParams();
  const [error, setError] = React.useState(null)
  const verifyRemoveAccount = useVerifyRemoveAccount(setError);

  React.useEffect(() => verifyRemoveAccount(key), [key])

  return (
    <StyledWrapper>
      <Title>
        {t(get(removeRequestErrorsConfig, error?.name, 'account.menu.delete.request-verify.success.header'))}
      </Title>
      <h4 className='subtitle'>
        {t(get(removeVerifyRequestErrorsConfig, error?.name, 'account.menu.delete.request-verify.success.description'))}
      </h4>
      <Button
        text={t('verify-email.actions.home.label')}
        isLink
        path='/'
        paddingVertical="13"
        paddingHorizontal="55"
      />
    </StyledWrapper>
  )
};

const StyledWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  ${Title} {
    font-size: 48px;
  }
  .subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.4;
    color: ${({ theme: { colors } }) => colors.black};
  }
`;

export default VerifyRemoveAccount;
