import React from 'react'

import LineString from '../common/LineString'

export default React.memo(function Requests({ isLoading, requestsCount }) {
  return (
    <LineString
      isLoading={isLoading}
      str={requestsCount?.toString()}
      className='centered'
    />
  )
})
