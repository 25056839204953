import React, { useEffect } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import prop from 'lodash/fp/prop'

import { useFetch } from 'store/hooks/useFetch'
import { useIsUserLoading, useUser } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import {
  getNotifications as getTalentNotificationsApi,
  patchNotifications as updateTalentNotificationsApi,
} from 'api/appApi/talent/notifications'
import {
  getUserNotificationSettings as getUserNotificationApi,
  updateUserNotificationSettings as updateUserNotificationsApi,
} from 'api/appApi/auth/settings'
import { notify } from 'utils/notify'
import { getTalentId } from 'utils/user'

import AccountTemplate from '../AccountTemplate'
import PageLoader from '_legacy/common/Template/PageLoader'
import MobileNotificationsTemplate from './MobileNotificationsTemplate'
import DesktopNotificationsTemplate from './DesktopNotificationsTemplate'
import { talentNotificationConfig, userNotificationConfig } from './config'

const Notifications = () => {
  const t = useGetTranslate()
  const isSwitching = useIsUserLoading()
  const talentId = useUser(getTalentId)
  const windowWidth = useWindowDimensions(prop('width'))
  const methods = useForm({})

  const config = talentId ? talentNotificationConfig : userNotificationConfig
  const getApiFn = talentId ? getTalentNotificationsApi : getUserNotificationApi
  const updateApiFn = talentId ? updateTalentNotificationsApi : updateUserNotificationsApi

  const [{ data: getData }, getNotifications] = useFetch(getApiFn)
  const [{ data: patchData, isLoading }, patchNotifications] = useFetch(updateApiFn)

  useEffect(() => {
    getNotifications({ talentId })
  }, [talentId])

  useEffect(() => {
    if (getData?.profileNotifications) methods.reset(getData?.profileNotifications)
  }, [getData])

  useEffect(() => {
    if (patchData?.profileNotifications) notify(t('account.notifications.form.messages.success'))
  }, [patchData])

  const onSubmit = profileNotifications => {
    patchNotifications({ talentId, data: { profileNotifications } })
  }

  if (!getData || isSwitching === true)
    return (
      <AccountTemplate isNested={true}>
        <PageLoader />
      </AccountTemplate>
    )

  return (
    <AccountTemplate isNested={true}>
      <FormProvider {...methods}>
        <form className="notifications" onSubmit={methods.handleSubmit(onSubmit)}>
          {windowWidth <= 1024 ? (
            <MobileNotificationsTemplate isLoading={isLoading} config={config} />
          ) : (
            <DesktopNotificationsTemplate isLoading={isLoading} config={config} />
          )}
        </form>
      </FormProvider>
    </AccountTemplate>
  )
}

export default Notifications
