import React from 'react'
import { useHistory } from 'react-router-dom'
import { last } from 'lodash'

import * as r from '_legacy/constants/routes'
import {
  checkSubitems,
  getIds,
  shouldFetchMoreCategories,
} from '_legacy/components/CategoriesSelect/utils'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useOffersCategories } from 'store/hooks/globalState/useCategories'
import { useFetchOffersCategoriesByParentId } from 'requests/categories'
import { useSetSearchOffersFilters } from 'store/hooks/globalState/useSearchList'

import Dropdown from '_legacy/components/Common/Dropdown'
import CategoriesSelect from '_legacy/components/CategoriesSelect'
import BackgroundComponenOfferings from '_legacy/components/CategoriesSelect/CategoriesSelectComponents/BackgroundComponents/BackgroundComponenOfferings'

const Offerings = ({ themeType }) => {
  const ref = React.useRef()
  const t = useGetTranslate()
  const history = useHistory()

  const setOfferSearchFilters = useSetSearchOffersFilters((p, n) => ({
    ...p,
    ...n,
  }))

  const fetchOffersCategoriesByParentId = useFetchOffersCategoriesByParentId()

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [value, setValue] = React.useState([])
  const [pointerShift, setPointerShift] = React.useState(ref.current?.offsetLeft)

  const dropdownStateHandler = state => {
    setIsDropdownOpen(state)
    if (!state) setValue([])
  }

  const onChange = newState => {
    setValue(newState)
    if (shouldFetchMoreCategories(newState)) {
      fetchOffersCategoriesByParentId(getIds(newState))
      return
    }

    if (!checkSubitems(last(newState))) {
      const offeringType = newState[0].i
      const _categoryIn = newState.slice(1)
      const categoryIn = _categoryIn?.length === 1 && _categoryIn[0]?.isHeader
        ? null
        : _categoryIn

      setOfferSearchFilters({ categoryIn, offeringTypesIn: offeringType })
      history.push(`/${r.SEARCH}/${r.OFFERINGS}`)

      setIsDropdownOpen(false)
      setValue([])
    }
  }

  const onResize = () => {
    setPointerShift(ref.current?.offsetLeft)
  }

  React.useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <div ref={ref} className="main-menu-item-wrapper">
      <Dropdown
        themeType={themeType}
        label={t('main-menu.offerings')}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={dropdownStateHandler}
      >
        <CategoriesSelect
          withCount
          showAllCategories
          value={value}
          setValue={onChange}
          getFn={useOffersCategories}
          allCategoriesLabel={t('main-menu.offerings')}
          className="multi-level-select__offerings"
          backgroundComponent={<BackgroundComponenOfferings />}
          pointerShift={pointerShift}
        />
      </Dropdown>
    </div>
  )
}

export default Offerings
