import React from 'react'
import Helmet from 'react-helmet'


const APP_URL = process.env.REACT_APP_URL
const TITLE = 'EntreeVIP'

const withHelmetTags = Component => props => {
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
        <meta name="title" content={TITLE}></meta>
        <meta name="description" content={TITLE}></meta>

        {/* <!-- Open Graph / Facebook --> */}
        <meta property="og:type" content="website"></meta>
        <meta property="og:url" content={APP_URL}></meta>
        <meta property="og:title" content={TITLE}></meta>
        <meta property="og:description" content={TITLE}></meta>
        <meta property="og:image" content={`${APP_URL}/AppImages/icon_x512.png`}></meta>
        {/* <!-- Twitter --> */}
        <meta property="twitter:card" content="summary_large_image"></meta>
        <meta property="twitter:url" content={APP_URL}></meta>
        <meta property="twitter:title" content={TITLE}></meta>
        <meta property="twitter:description" content={TITLE}></meta>
        <meta property="twitter:image" content={`${APP_URL}/AppImages/icon_x512.png`}></meta>
      </Helmet>
      <Component {...props} />
    </>

  )
}

export default withHelmetTags
