import React from 'react'
import { useHistory } from 'react-router'
import get from 'lodash/get'

import { notify } from 'utils/notify'
import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useSwitchNavigation = createUseGlobalState('switchNavigation')
export const useSetSwitchNavigation = createUseSetGlobalState('switchNavigation')

// get
export const useSetIdToSwitch = () => {
  return useSetSwitchNavigation((prev, idToSwitch) => ({ ...prev, idToSwitch }))
}

export const useSetNavigationAgent = () => {
  return useSetSwitchNavigation((prev, agent) => ({ ...prev, agent }))
}

export const useSetNavigationPath = () => {
  return useSetSwitchNavigation((prev, path) => ({ ...prev, path }))
}

export const useSetShouldNavigate = () => {
  return useSetSwitchNavigation((prev, shouldNavigate) => ({ ...prev, shouldNavigate }))
}

// set
export const useSwitchNavigationPath = () => {
  return useSwitchNavigation(state => get(state, 'path', '/'))
}

export const useSwitchAgent = () => {
  return useSwitchNavigation(state => get(state, 'agent'))
}

export const useSwitchId = () => {
  return useSwitchNavigation(state => get(state, 'idToSwitch'))
}

export const useShouldNavigate = () => {
  return useSwitchNavigation(state => get(state, 'shouldNavigate'))
}

// hooks
export const useSetPathToNavigate = ({ talentRoute, agentRoute }) => {
  const setPath = useSetNavigationPath()

  React.useEffect(() => {
    setPath({ talentRoute, agentRoute })
  }, [talentRoute, agentRoute, setPath])

  React.useEffect(() => () => {
    setPath(null)
  }, [])
}

export function useNavigate() {
  const history = useHistory()
  const setShouldNavigate = useSetShouldNavigate()

  return function (path, message, type = 'success') {
    if (message) {
      setTimeout(() => notify(message, type), 10)
    }
    setShouldNavigate(true)
    history.push(path)
  }
}
export function useNavigateWithReload() {
  const history = useHistory();

  return function (path, message, type = 'success') {
    if (message) {
      setTimeout(() => notify(message, type), 10);
    }
    if (history.location.pathname === path) {
      const newPath = `${path}?reRender=${true}`;
      history.push(newPath);
    } else {
      history.push(path);
    }
  };
}
