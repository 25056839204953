import React from 'react'

import { refreshAccountLink } from '_legacy/components/PaymentSettings/state/api'

import PageLoader from '_legacy/common/Template/PageLoader'

const RefreshPage = () => {

  React.useEffect(async () => {
    try {
      await refreshAccountLink()
        .then(({ url }) => {
          window.location.href = url
        })
        .catch(err => console.warn(err))
    } catch (e) {
      console.warn(e)
    }
  }, [])

  return (<PageLoader />)
};

export default RefreshPage;
