import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSubMenuActive } from 'store/hooks/globalState/useGlobalState'

import CardBack from './CardBack'
import Overlay from './Overlay'
import Shadow from './Shadow'
import Button from '_legacy/components/Button';

export default React.memo(function Card ({
  talentNickname, type, setCurrentSlide, file, wrapperDimensions, layers
}) {
  const isMain = type === 'main'
  const isActive = useSubMenuActive(current => isMain && current, [isMain])
  const t = useGetTranslate()
  const buttonClass = classnames('button-wrapper', { active: !!isActive })
  return (
    <StyledWrapper
      isActive={isMain && isActive}
      isMain={isMain}
      className={type}
      wrapperDimensions={wrapperDimensions}
      onClick={setCurrentSlide}
    >
      <Shadow
        type={type}
      />
      <CardBack
        file={file}
        isMain={isMain}
        alt="test"
      />
      <Overlay
        layers={layers}
        isMain={isMain}
      />
      <Button
        withoutLoader
        text={t('home-page.buttons.visit-profile')}
        path={talentNickname}
        classBtn={buttonClass}
        isLink
        gradient
        paddingHorizontal="18"
        fontSize="1"
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  transition-duration: 350ms;
  transition-timing-function: ease;
  background: #272835;
  transition-property: transform, left, top, height, width;
  &.card-3 {
    width: ${({ wrapperDimensions }) => wrapperDimensions.height / 6.6}px;
    height: ${({ wrapperDimensions }) => wrapperDimensions.height / 2.2}px;
    filter: blur(4px);
    z-index: 20;
  }
  &.card-2 {
    width: ${({ wrapperDimensions }) => wrapperDimensions.height / 4.9}px;
    height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.6}px;
    filter: blur(2px);
    z-index: 30;
  }
  &.card-1 {
    width: ${({ wrapperDimensions }) => wrapperDimensions.height / 4.2}px;
    height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.15}px;
    filter: blur(1px);
    z-index: 40;
  }
  &.left-outer {
    width: 0;
    height: 0;
    left: 20%;
    z-index: 10;
    pointer-events: none;
    filter: blur(4px);
  }
  &.left-3 {
    transform: translate3d(0, 0, 2px) perspective(12px) rotateY(-1deg);
    left: 0;
  }
  &.left-2 {
    transform: translate3d(0, 0, 4px) perspective(15px) rotateY(-1deg);
    left: 11%;
  }
  &.left-1 {
    transform: translate3d(0, 0, 6px) perspective(25px) rotateY(-1deg);
    left: 24%;
  }
  &.main {
    width: ${({ wrapperDimensions }) => wrapperDimensions.height / 2.15}px;
    height: ${({ wrapperDimensions }) => wrapperDimensions.height}px;
    left: calc(50% - ${({ wrapperDimensions }) => wrapperDimensions.height / (2.15 * 2)}px);
    transform: translate3d(0, 0, 10px);
    ${({ isActive }) => isActive && 'transform: translate3d(0, 0, 10px) scale(1.2)'};
    z-index: 50;
    pointer-events: none;
  }
  &.right-1 {
    transform: translate3d(0, 0, 6px) perspective(25px) rotateY(1deg);
    left: 64%;
    z-index: 40;
  }
  &.right-2 {
    transform: translate3d(0, 0, 4px) perspective(15px) rotateY(1deg);
    left: 79%;
  }
  &.right-3 {
    transform: translate3d(0, 0, 2px) perspective(12px) rotateY(1deg);
    left: calc(100% - ${({ wrapperDimensions }) => wrapperDimensions.height / 6.6}px);
  }
  &.right-outer {
    width: 0;
    height: 0;
    left: 80%;
    filter: blur(4px);
  }
  .button-wrapper {
    display: none;
  }
  &.main {
    .button-wrapper {
      display: initial;
      position: absolute;
      left: 50%;
      bottom: 14%;
      width: 164px;
      height: 50px;
      z-index: 25;
      opacity: 0;
      transition: opacity 0s ease-in;
      ${({ isMain }) => !isMain && 'pointer-events: none'};
      ${({ isMain }) => isMain && 'pointer-events: all'};
      transform: translate(-50%, 50%) scale(${({ wrapperDimensions }) => wrapperDimensions.height / (2.15 * 164) * 0.8});

      &.active {
        opacity: 1;
        transition: opacity .45s ease-out;
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &.left-3 {
      left: 5%;
    }
    &.left-2 {
      left: 14%;
    }
    &.left-1 {
      left: 25%;
    }
    &.main {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 2}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height}px;
      left: calc(50% - ${({ wrapperDimensions }) => wrapperDimensions.height / (2 * 2)}px);
      pointer-events: auto;

      .button-wrapper {
        transform: translate(-50%, 50%) scale(${({ wrapperDimensions }) => wrapperDimensions.height / (2 * 164) * 0.8});
      }
    }
    &.right-1 {
      left: 63%;
    }
    &.right-2 {
      left: 76%;
    }
    &.right-3 {
      left: calc(95% - ${({ wrapperDimensions }) => wrapperDimensions.height / 6.6}px);
    }
  }
  @media screen and (max-width: 768px) {
    &.card-3 {
      width: 0;
      height: 0;
    }
    &.card-2 {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 4}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.25}px;
    }
    &.card-1 {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 3.3}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 0.95}px;
    }
    &.left-2 {
      left: 4%;
    }
    &.left-1 {
      left: 18%;
    }
    &.main {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.5}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 0.75}px;
      left: calc(50% - ${({ wrapperDimensions }) => wrapperDimensions.height / (1.5 * 2)}px);

      .button-wrapper {
        transform: translate(-50%, 50%) scale(${({ wrapperDimensions }) => wrapperDimensions.height / (1.5 * 164) * 0.8});
      }
    }
    &.right-1 {
      left: 67%;
    }
    &.right-2 {
      left: calc(96% - ${({ wrapperDimensions }) => wrapperDimensions.height / 4.2}px);
    }
  }
  @media screen and (max-width: 480px) {
    &.card-2 {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 3.8}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.85}px;
    }
    &.card-1 {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 3}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.45}px;
    }
    &.left-2 {
      left: 0%;
    }
    &.left-1 {
      left: 13%;
      left: calc(30% - ${({ wrapperDimensions }) => wrapperDimensions.height / (3 * 2)}px);
    }
    &.main {
      width: ${({ wrapperDimensions }) => wrapperDimensions.height / 2.8}px;
      height: ${({ wrapperDimensions }) => wrapperDimensions.height / 1.35}px;
      left: calc(50% - ${({ wrapperDimensions }) => wrapperDimensions.height / (2.8 * 2)}px);

      .button-wrapper {
        display: none;
      }
    }
    &.right-1 {
      left: 55%;
      left: calc(70% - ${({ wrapperDimensions }) => wrapperDimensions.height / (3 * 2)}px);
    }
    &.right-2 {
      left: calc(100% - ${({ wrapperDimensions }) => wrapperDimensions.height / 3.8}px);
    }
  }
`
