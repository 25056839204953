import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';
import { RemoveAccountComplianceRequestType } from 'shared';

import { Checkbox, PasswordInput, Row } from '_legacy/common';
import TextArea from '_legacy/common/TextArea';
import Button from '_legacy/components/Button';
import Radio from '_legacy/components/Form/Radio/Radio';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

const { EXERCISING_MY_RIGHT_UNDER_THE_GDPR, EXERCISING_MY_RIGHT_UNDER_THE_CCPA, OTHER } = RemoveAccountComplianceRequestType;

const RemoveAccountForm = () => {
  const t = useGetTranslate();
  const { control } = useFormContext();

  const typesCompliance  = useWatch({
    control,
    defaultValue: OTHER,
    name: 'complianceRequestType',
  });

  const isConfirm = useWatch({
    control,
    defaultValue: false,
    name: 'confirm',
  });

  return (
    <StyledWrapper>
      <Row
        nameBlock={t('account.menu.delete.label')}
        component={
          <>
            <p className='description'>{t('account.menu.delete.description')}</p>
            <div className='compliance'>
              <h4 className='compliance__label'>{t('account.menu.delete.compliance.label')}</h4>
              <div className='compliance__radios'>
                <Radio
                  name='complianceRequestType'
                  value={EXERCISING_MY_RIGHT_UNDER_THE_GDPR}
                  label={t('account.menu.delete.compliance.gdrp')}
                  className='compliance__radio'
                />
                <Radio
                  name='complianceRequestType'
                  value={EXERCISING_MY_RIGHT_UNDER_THE_CCPA}
                  label={t('account.menu.delete.compliance.ccpa')}
                  className='compliance__radio'
                />
                <Radio
                  name='complianceRequestType'
                  value={OTHER}
                  label={t('account.menu.delete.compliance.other')}
                  defaultChecked
                  className='compliance__radio'
                />
              </div>
              { OTHER === typesCompliance && (
                <TextArea
                  name='customComplianceRequestType'
                  placeholder='account.menu.delete.other.placeholder'
                  maxLength={false}
                  className='_textarea'
                  isRequired
                />
              )}
            </div>
            <Checkbox
              name='confirm'
              className='confirm'
              label={<Trans tKey='account.menu.delete.confirm.label' />}
            />
          </>
        }
      />
      <Row
        nameBlock={t('account.menu.delete.password.label')}
        isRequired
        component={
          <PasswordInput
            placeholder={t('account.menu.delete.password.placeholder')}
            name="password"
            isRequired
            requiredMessage={t('account.menu.delete.password.error')}
          />
        }
      />
      <Row
        component={
          <Button
            text={t('account.menu.delete.submit')}
            paddingVertical="13"
            paddingHorizontal="23"
            typeButton='submit'
            disabled={!isConfirm}
          />
        }
      />
    </StyledWrapper>
  )
};

const StyledWrapper = styled.div`
  .row-left {
    padding-top: 0;
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.625;
    color: ${({ theme }) => theme.colors.grey};
    margin-top: 0;
    margin-bottom: 24px;
  }
  .compliance {
    &__label {
      margin-top: 0;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      color: ${({ theme }) => theme.colors.grey};
    }
    &__radios {
      margin-bottom: 24px;
    }
    &__radio {
      &:not(:last-of-type) {
        margin-bottom: 16px;
      }
    }
  }
  ._textarea {
    margin-bottom: 24px;
  }
  .confirm {
    font-size: 16px;
    font-weight: 500;
    line-height: 1.62;
    b {
      font-weight: 700;
    }
    i {
      top: 4px;
      transform: translateY(0);
      &:before {
        top: 6px;
      }
    }
  }
`;

export default RemoveAccountForm;
