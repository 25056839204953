// TODO: refactor component (Formatting, module structure)

import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import PhoneWithAsyncValidation from '_legacy/common/Input/PhoneWithValidation';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Phone = React.memo(function Phone({ name, className = '' }) {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('request-video-mes-commercial.phone')}
      className={className}
      component={
        <PhoneWithAsyncValidation name={name} />
      }
    />
  );
});

Phone.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default Phone;


