import React from 'react'
// import { Link } from 'react-router-dom'
import { useForm, FormProvider } from 'react-hook-form'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import get from 'lodash/get'

import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'
import VideoMessageForm from '_legacy/components/VideoMessageForm';
import withLibrary from '_legacy/containers/Offerings/components/withLibrary';

import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
// import { useUser } from 'store/hooks/globalState/useUser'
import { useVideoMessage } from 'store/hooks/globalState/videoMessage'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useCreateVideoMessage } from 'requests/video-message';
import { submitVideoMessageAdapter, videoMessageDefaultValues } from '_legacy/components/VideoMessageForm/utils';
import { /* COMMERCIAL_ENGAGEMENTS,*/ OFFERINGS } from '_legacy/constants/routes'
import { useRequiredTaxInfoModalHandler } from 'store/hooks/globalState/useUser'

const CreateVideoMessage = () => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: videoMessageDefaultValues,
  })

  // const talentNickname = useUser(state => get(state, ['talent', 'nickname']))
  const videoMessageLoading = useVideoMessage(state => get(state, 'loading'))

  const taxInfoModalHandler = useRequiredTaxInfoModalHandler()
  const options = { openExtraModal: taxInfoModalHandler }
  const createVideoMessage = useCreateVideoMessage(options)

  const onSubmit = compose(createVideoMessage, submitVideoMessageAdapter)

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <div className="video-message">
        <h1 className="header">
          {t('video-message.header.title.create')}
        </h1>
        {/* EN-3151 hide text and link */}
        {/*<h3 className="video-message__subheader">*/}
        {/*  {t('video-message.subheader.title')}&nbsp;*/}
        {/*  <Link to={`/${talentNickname}/${COMMERCIAL_ENGAGEMENTS}`}>*/}
        {/*    {t('video-message.subheader.link')}*/}
        {/*  </Link>*/}
        {/*</h3>*/}
        <VideoMessageForm onSubmit={onSubmit} isLoading={videoMessageLoading} />
      </div>
    </FormProvider>
  )
}

export default withLibrary(CreateVideoMessage)
