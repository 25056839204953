// Modules
import React from 'react'
import { ReactComponent as NoConnetionIcon } from 'assets/no-connection.svg'


const NoConnection = () => {
  const onTryAgainClick = () => {
    window.location.reload()
  }
  return (
    <div className="offline">
      <NoConnetionIcon />
      <h3>No internet connection</h3>
      <p>You must be online to use EntreeVIP</p>
      <button type="button" onClick={onTryAgainClick}>Try again</button>
    </div>
  )
}

export default NoConnection
