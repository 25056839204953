import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form'

// components
import ProfileManager from '../User/components/ProfileManager';
import Nickname from '../shared/components/Nickname';
import PhoneConfirm from '../shared/components/PhoneConfirm';
import DateOfBirth from '../shared/components/DateOfBirth';
import Email from '../User/components/Email';
import BackupEmail from '../shared/components/BackupEmail';
import MultiLocationSelect from '../shared/components/MultiLocationSelect';
import HeadshotImage from '../shared/components/HeadshotImage';
import SocialMediaLinks from './components/SocialMediaLinks';
import TimeZoneSelect from '_legacy/components/RegisterForms/shared/components/TimeZoneSelect';

// utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Input from '../shared/components/Input';
import UploadProfilePics from './components/UploadProfilePics';

const AgentForm = React.memo(function AgentForm({ viewerTypes }) {
  const t = useGetTranslate();
  const methods = useFormContext()

  const { isAgentOrManagerWithoutTalent, isAficionado } = viewerTypes;

  const formState = methods.watch()
  React.useEffect(() => {
    if (methods.formState.isSubmitted) {
      methods.trigger('user.licenses')
    }
  }, [formState, methods])

  return (
    <>
      {!isAficionado && (
        <ProfileManager
          disabled
          name='user.type'
          customValidation={{ required: false }}
        />
      )}
      {(isAgentOrManagerWithoutTalent || isAficionado) && (
        <>
          <HeadshotImage name='user.profileImage'/>
          <UploadProfilePics />
          <Input
            isDisabled
            isRequired
            name='user.firstName'
            placeholder={t('register-user.form.first-name.placeholder')}
            label={t('register-user.form.first-name.label')}
          />
          <Input
            isDisabled
            isRequired
            name='user.lastName'
            placeholder={t('register-user.form.last-name.placeholder')}
            label={t('register-user.form.last-name.label')}
          />
          <Nickname name='user.nickname' isDisabled/>
          <Email name='user.email' shouldValidate={false} disabled/>
          <BackupEmail name='user.backupEmail'/>
          <MultiLocationSelect baseName='user.address'/>
          <TimeZoneSelect name='user.timezone' />
          <DateOfBirth name='user.dateOfBirth'/>
          <PhoneConfirm baseName='user' isEditable/>
          <SocialMediaLinks name='user.socialMedia'/>
        </>)}
    </>
  );
});

AgentForm.propTypes = {
  viewerTypes: PropTypes.object
};

export default AgentForm;

