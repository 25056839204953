import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import eventPath from 'utils/eventPath'

export default function BulkActionDropdown ({ getFilters, actions }) {
  const t = useGetTranslate()
  const selectedItems = getFilters(current => get(current, 'selected', []))
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)
  const togleMenu = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])

  const closeMenu = React.useCallback((e) => {
    if (eventPath(e).includes(ref.current)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const parsedOptions = React.useMemo(() => {
    return actions.map(({ label, cbFn }, i) => {
      return (
        <div
          key={i}
          className='menu-item'
          onClick={() => cbFn(selectedItems)}
        >
          {t(label)}
        </div>
      )
    })
  }, [actions, selectedItems, t])

  return (
    <StyledWrapper
      ref={ref}
      isOpen={isOpen}
      onClick={togleMenu}
    >
      <div className='action-label'>
        {t('table.header.actions')}
      </div>
      <div className='arrow' />
      {isOpen && (
        <div className='menu-wrapper'>
          {parsedOptions}
        </div>
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 5px 12px;
  margin-left: 16px;
  border: 2px solid ${({ theme: { colors } }) => colors.blue};
  border-radius: 4px;
  transition: all 0.2s;
  cursor: pointer;
  :hover {
    background: rgba(233, 234, 240, 0.99);
  }
  :active {
    background: #fff;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    margin-left: auto;
  }
  .action-label {
    font-size: 13px;
    font-weight: 700;
    line-height: 22px;
    margin-right: 10px;
    text-transform: uppercase;
  }
  .arrow {
    width: 10px;
    height: 10px;
    border-right: solid 3px ${({ theme: { colors } }) => colors.blue};
    border-bottom: solid 3px ${({ theme: { colors } }) => colors.blue};
    transform: rotate(45deg);
    margin-top: -4px;
    ${({ isOpen }) => isOpen && `
      transform: rotate(-135deg);
      margin-top: 4px;
    `};
    transition: transform 0.2s;
  }
  .menu-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    background: #fff;
    border-radius: 4px;
    min-width: 140px;
    z-index: 5;
    white-space: nowrap;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    transform: translate(-50%, 10px);
    @media screen and (max-width: 768px) {
      right: 0;
      left: auto;
      transform: translate(0, 10px);
    }
    .menu-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      color: ${({ theme: { colors } }) => colors.grey};
      padding: 7px 16px;
      margin: 4px 0;
      :hover {
        background: ${({ theme: { colors } }) => colors.ultraLightBlue};
        cursor: pointer;
      }
    }
    .menu-item.active {
      color: ${({ theme: { colors } }) => colors.blue};
      font-weight: 700;
    }
  }
`
