import React from 'react'
import pick from 'lodash/fp/pick'

import normalizeParams from 'utils/normalizeParams'
import {
  fetchResourceCenterList as resourceCenterListApi,
} from './api'
import {
  useSetResourceCenter,
  useResourceCenterFilters,
  useSetResourceCenterFilters,
  useResourceCenter,
} from './store';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useUserLang } from 'store/hooks/globalState/useTranslates'

export function useFetchResourceCenter() {
  const userLang = useUserLang()
  const resourceCenter = useResourceCenter()
  const setResourceCenter = useSetResourceCenter((prev, next) => next)
  const resourceCenterFilters = useResourceCenterFilters()
  const setResourceCenterFilters = useSetResourceCenterFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ ...query }) => {
    const params = normalizeParams(query)
    resourceCenterListApi({
      query: {
        ...pick(['sortField', 'sortOrder', 'limit', 'page'], resourceCenterFilters),
        languageIn: [userLang],
        ...params,
      }
    })
      .then(({ templates, pageInfo }) => {
        setResourceCenter([...(pageInfo.page > 1 ? resourceCenter : []), ...templates])
        setResourceCenterFilters(pageInfo)
      })
      .catch(error => {
        console.warn(error)
        handleResponseError(error)
      })
  },
  [setResourceCenterFilters, userLang]
  )
}
