import React from 'react'
import FilePreview from '_legacy/components/Library/components/Library/FilePreview'

const File = ({ ...file }) => {
  return (
    <div className='file'>
      <FilePreview className='file__preview' isLoading={false} file={file} />
      <p className="file__name">{file.originalName}</p>
    </div>
  )
}

export default File
