import React from 'react'
import { components } from 'react-select'

const ClearIndicator = props => {
  if (props?.hasValue) {
    return <components.ClearIndicator {...props} />
  }
  return null
}

export default ClearIndicator
