import React from 'react';
import get from 'lodash/get';

import {
  removeAccount as removeAccountApi,
  verifyRemoveAccount as verifyRemoveAccountApi,
} from 'api/appApi/talent';

import { notify } from 'utils/notify';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { removeRequestErrorsConfig } from './errorsConfig';

export const useRemoveAccount = () => {
  const t = useGetTranslate();

  return React.useCallback((data) => removeAccountApi(data).then(() => {
    notify(t('account.menu.delete.request.success'), 'success')
  })
    .catch(error => {
      notify(t(get(removeRequestErrorsConfig, error.name)), 'error')
    }), [])
}

export const useVerifyRemoveAccount = (setError) =>
  React.useCallback((key) => verifyRemoveAccountApi(key).catch(setError), [])
