import React from 'react';
import { errorNames, api } from 'shared';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

import { useSetUser, useUser } from 'store/hooks/globalState/useUser';
import {
  setTokens,
  clearTokens,
  setUserData,
  removeUserData,
  removeLibraryData,
  removeEntitiesData,
} from 'utils/storage';
import {
  me as meApi,
  login as loginApi,
  logout as logoutApi,
  asUser as registerAsUserApi,
  asTalent as registerAsTalentApi,
  editProfile as editProfileApi,
  resetPassword as resetPasswordApi,
  changePassword as changePasswordApi,
  asAficionado as registerAsAficionadoApi,
  getProfileToEdit as getProfileToEditApi,
  asTalentDraft as registerAsTalentDraftApi,
  resetPasswordRequest as resetPasswordRequestApi,
} from 'api/appApi/auth';
import { notify } from 'utils/notify';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetError } from 'store/hooks/globalState/useErrors';
import { getAdapter } from '_legacy/components/RegisterForms/ProfileEdit/utils/getAdapter';
import { submitAdapter } from '_legacy/components/RegisterForms/ProfileEdit/utils/submitAdapter';
import { useSetEditProfile } from 'store/hooks/globalState/useEditProfile';
import { useAddFilesToEntities, useAddFileToEntities } from 'store/hooks/globalState/useFileEntites';
import { useClearFileLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useSetUserLang } from 'store/hooks/globalState/useTranslates'
import { useTalentCategories } from 'store/hooks/globalState/useCategories'
import { OFFERINGS } from '_legacy/constants/routes'
import { useSetCurrency } from 'store/hooks/globalState/useCurrency'
import { useSetNotifications } from '../store/hooks/globalState/useNotificationsState'


const { UNKNOWN_DATA } = errorNames.signIn;

export function useLoginUser(methods) {
  const t = useGetTranslate();
  const setUser = useSetUser((prev, next) => next);
  const setLang = useSetUserLang((prev, next) => next);
  const setCurrency = useSetCurrency((prev, currentCurrencyCode) => ({ ...prev, currentCurrencyCode }))

  return React.useCallback(
    ({ email, password }) => {
      loginApi({ email, password })
        .then(({ accessToken, refreshToken, twilioAccessToken, userData }) => {
          setTokens({ accessToken, refreshToken, twilioAccessToken });
          setUserData(userData);
          setUser(userData);
          setLang(userData.user.settings.language);
          setCurrency(userData.user.settings.currency)
        })
        .catch(error => {
          if (error.name === api.auth.signIn.errors.EMAIL_IS_NOT_VERIFIED) {
            notify(t('_errors.email-is-not-verified'), 'error')
          }

          if (error.name === UNKNOWN_DATA) {
            methods.setError('email', { type: 'server', message: ' ' }) // for error border without error message
            methods.setError('password', { type: 'server', message: t('login.error.incorrect') })
          }
        });
    },
    [setUser, setCurrency]
  );
}

export function useGetMe() {
  const setUser = useSetUser((prev, next) => next);
  const setCurrency = useSetCurrency((prev, currentCurrencyCode) => ({ ...prev, currentCurrencyCode }))

  return React.useCallback(() => {
    meApi()
      .then(res => {
        setUser(res)
        setCurrency(res.user.settings.currency)
      })
      .catch(() => clearTokens());
  }, [setUser]);
}

export function useLogout() {
  const clearUser = useSetUser(() => null);
  const clearNotification = useSetNotifications(() => null)
  const clearFileLibrary = useClearFileLibrary();

  return React.useCallback(() => {
    logoutApi().finally(() => {
      clearUser();
      clearTokens();
      removeUserData();
      clearFileLibrary();
      clearNotification()
    });
  }, [clearUser, clearFileLibrary, clearNotification]);
}

export function useRegisterAsTalent(setLoading) {
  const t = useGetTranslate();
  const setTalent = useSetUser((_, next) => next);
  const navigate = useNavigate();

  return React.useCallback(
    data => {
      if (setLoading) setLoading(true);

      registerAsTalentApi(data)
        .then(({ userData, accessToken, refreshToken, twilioAccessToken }) => {
          setTokens({ accessToken, refreshToken, twilioAccessToken });
          setTalent(userData)
          navigate(`/${OFFERINGS}`, t('register.form.talent.message.talent-profile-created'))
        })
        .finally(() => {
          if (setLoading) setLoading(false);
        });
    },
    [setTalent, navigate]
  );
}

export function useRegisterAsUser(setLoading) {
  const t = useGetTranslate();
  const navigate = useNavigate();
  const clearFileLibrary = useClearFileLibrary();

  return React.useCallback((data) => {
    setLoading(true);

    registerAsUserApi(data)
      .then(() => {
        removeLibraryData();
        removeEntitiesData();
        clearFileLibrary();
        navigate('/', t('register.form.talent.message.success'));
      })
      .catch(err => {
        notify(t('shared.words.errors.something-went-wrong'), 'error');
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, clearFileLibrary, navigate]);
}

export function useRegisterAsAficionado(setLoading) {
  const t = useGetTranslate();
  const navigate = useNavigate();
  const clearFileLibrary = useClearFileLibrary();

  return React.useCallback((data) => {
    setLoading(true);

    registerAsAficionadoApi(data)
      .then(() => {
        removeLibraryData();
        removeEntitiesData();
        clearFileLibrary();
        navigate('/', t('register.form.talent.message.success'));
      })
      .catch(err => {
        notify(t('shared.words.errors.something-went-wrong'), 'success');
        console.warn(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [t, navigate, clearFileLibrary]);
}

function setFormError(methods, error) {
  if (error?.name === 'NEW_PASSWORD_CANNOT_BE_THE_SAME') {
    methods.setError('oldPassword', {
      type: 'server',
      message: 'New password cannot be the same',
    });
    methods.setError('password', {
      type: 'server',
      message: 'New password cannot be the same',
    });
    methods.setError('rePassword', {
      type: 'server',
      message: 'New password cannot be the same',
    });
    return;
  }

  if (error?.name === 'OLD_PASSWORD_INCORRECT') {
    methods.setError('oldPassword', {
      type: 'server',
      message: 'Old password incorrect',
    });
    return;
  }

  methods.clearErrors();
}

export function useChangePassword(methods) {
  const setError = useSetError('changePassword');
  return React.useCallback(data => {
    changePasswordApi(data)
      .then(() => {
        notify('Password changed successfully', 'success');
        methods.reset({}); // todo: need it ?
      })
      .catch(err => {
        setError(err);
        setFormError(methods, err); // todo: need it ?
        notify(err.message, 'error');
      });
  }, []);
}

export function useResetPasswordRequest(triggerModal) {
  const user = useUser();
  const email = get(user.user, 'email');
  return React.useCallback(() => {
    resetPasswordRequestApi({ email }).then(() => {
      triggerModal();
    });
  }, []);
}

export function useResetPassword(triggerModal) {
  return React.useCallback(() => {
    resetPasswordApi().then(() => {
      triggerModal();
    });
  }, []);
}

export function useFetchProfile(methods) {
  const t = useGetTranslate()
  const setEditProfile = useSetEditProfile((prev, next) => next)
  const addFilesToEntities = useAddFilesToEntities()
  const addFileToEntities = useAddFileToEntities()
  const categories = useTalentCategories()

  return React.useCallback(() => {
    getProfileToEditApi().then(data => {
      setEditProfile(data);
      if (!isEmpty(get(data, 'user.profileImage'))) {
        addFileToEntities(
          data.user.profileImage.file.id,
          data.user.profileImage
        );
      }

      if (!isEmpty(get(data, 'talent.fullBodyImage'))) {
        // addFilesToEntities(data.talent.fullBodyImage)
        addFileToEntities(
          data.talent.fullBodyImage.file.id,
          data.talent.fullBodyImage
        );
      }

      if (!isEmpty(get(data, 'talent.halfBodyImage'))) {
        // addFilesToEntities(data.talent.halfBodyImage)
        addFileToEntities(
          data.talent.halfBodyImage.file.id,
          data.talent.halfBodyImage
        );
      }

      if (!isEmpty(get(data, 'talent.profileImage'))) {
        addFileToEntities(
          data.talent.profileImage.file.id,
          data.talent.profileImage
        );
      }

      if (!isEmpty(get(data, 'talent.fullBodyImages'))) {
        addFilesToEntities(data.talent.fullBodyImages.map(img => img.file));
      }

      methods.reset(getAdapter(data, t, categories), { isDirty: false })
    })
  }, [t, setEditProfile, categories])
}

export function usePostEditProfile(methods) {
  const t = useGetTranslate();

  return React.useCallback(
    (values, dirtyFields) => {
      const data = submitAdapter(values, dirtyFields);

      editProfileApi(data)
        .then(() => {
          methods.reset(values, { isDirty: false }); // set field dirty after success
          notify(t('edit-profile.form.message.success'), 'success');
        })
        .catch(() => {
          notify(t('edit-profile.form.message.failure'), 'error');
        });
    },
    [t]
  );
}

export function useRegisterAsTalentDraft() {
  const t = useGetTranslate();
  const navigate = useNavigate();

  return React.useCallback(
    talent => {
      registerAsTalentDraftApi(talent)
        .then(() => {
          navigate('/', t('register.form.talent.message.draft-success'));
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [navigate]
  );
}

export function useCompleteRegisterAsTalent() {
  const t = useGetTranslate();
  const navigate = useNavigate();

  return React.useCallback(
    talent => {
      registerAsTalentDraftApi(talent)
        .then(() => {
          navigate('/', t('register.form.talent.message.success'));
        })
        .catch(err => {
          console.warn(err);
        });
    },
    [navigate]
  );
}
