import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { FileType } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Modal from '../../components/Modal/Modal';
import DropZone from '../../components/DropZone/DropZone';
import ModalHeader from '../../components/Modal/ModalHeader';
import MultiSelectFileList from './MultiSelectFileList';
import ModalSubmitButton from '../../components/Modal/SubmitButton';
import SubModalHeader from '../../components/Modal/SubModalHeader'

const { VIDEO, PHOTO } = FileType


const MultiSelectLibraryModal = React.memo(({ onSubmit, isOpen, closeModal, name, maxSelectItems, dropZoneProps, libraryModalProps }) => {
  const t = useGetTranslate();
  const submitBtnText = get(libraryModalProps, 'submitBtnText', t('shared.words.upload'))
  const header = get(libraryModalProps, 'header', t('video-message.form.media.placeholder.header'))
  const subHeader = get(libraryModalProps, 'subHeader')
  const disabled = get(libraryModalProps, 'submitBtnDisabled', false)

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isMobileFullScreen>
      <ModalHeader>{header}</ModalHeader>
      {subHeader && <SubModalHeader>{subHeader}</SubModalHeader>}
      <DropZone {...dropZoneProps} />
      <MultiSelectFileList name={name} maxSelectItems={maxSelectItems} enableTypes={libraryModalProps?.enableTypes} />
      <ModalSubmitButton onClick={onSubmit} disabled={disabled}>{submitBtnText}</ModalSubmitButton>
    </Modal>
  );
});

MultiSelectLibraryModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  maxSelectItems: PropTypes.number,
  dropZoneProps: PropTypes.shape({
    accept: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    hintComponent: PropTypes.element,
    multiple: PropTypes.bool,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    maxSize: PropTypes.number,
  }),
  libraryModalProps: PropTypes.shape({
    header: PropTypes.string,
    submitBtnText: PropTypes.string,
    submitBtnDisabled: PropTypes.bool,
    enableTypes: PropTypes.oneOf([VIDEO, PHOTO]),
  }),
};

export default MultiSelectLibraryModal;
