import { EntourageSubscriptionType } from 'shared'

import { ReactComponent as OnfidoLogo } from '../icons/OnfidoLogo.svg'
import { ReactComponent as SterlingLogo } from '../icons/SterlingLogo.svg'
import { activateEntourage } from '../api/api'

const partners = {
  Onfido: {
    link: 'https://onfido.com/',
    Logo: OnfidoLogo,
    header: 'entourage-public.partners.onfido.header',
    text: 'entourage-public.partners.onfido.text',
  },
  Sterling: {
    link: 'https://www.sterlingcheck.com/',
    Logo: SterlingLogo,
    header: 'entourage-public.partners.sterling.header',
    text: 'entourage-public.partners.sterling.text',
  },
}

const requirements = {
  applicationFee: 'entourage-public.requirement.application-fee',
  applicationFeeLight: 'entourage-public.requirement.application-fee-light',
  annualFee: 'entourage-public.requirement.annual-fee',
  upgradeFee: 'entourage-public.requirement.upgrade-fee',
  passIdVerification: 'entourage-public.requirement.pass-id-verification',
  passCriminalBackgroundCheck: 'entourage-public.requirement.pass-criminal-background-check',
}

const acceptance = {
  entreevip: 'entourage-public.acceptance.entreevip',
  onfido: 'entourage-public.acceptance.onfido',
  sterling: 'entourage-public.acceptance.sterling',
}

const identityVerification = {
  header: 'entourage-public.identity-verification.header',
  list: [
    {
      header: 'entourage-public.document-verification.header',
      text: 'entourage-public.document-verification.text',
    },
    {
      header: 'entourage-public.biometric-verification.header',
      text: 'entourage-public.biometric-verification.text',
    },
  ],
}

const backgroundCheck = {
  header: 'entourage-public.background-check.header',
  list: [
    {
      header: 'entourage-public.ssn-trace.header',
      text: 'entourage-public.ssn-trace.text',
    },
    {
      header: 'entourage-public.federal-criminal-search.header',
      text: 'entourage-public.federal-criminal-search.text',
    },
    {
      header: 'entourage-public.extend-global-sanctions.header',
      text: 'entourage-public.extend-global-sanctions.text',
    },
    {
      header: 'entourage-public.enhanced-nationwide.header',
      text: 'entourage-public.enhanced-nationwide.text',
    },
    {
      header: 'entourage-public.doj-sex-offender.header',
      text: 'entourage-public.doj-sex-offender.text',
    },
    {
      header: 'entourage-public.county-criminal-search.header',
      text: 'entourage-public.county-criminal-search.text',
    },
    {
      header: 'entourage-public.location-select.header',
      text: 'entourage-public.location-select.text',
    },
  ],
}

export const statusKeys = {
  full: 'FULL',
  lite: 'LITE',
  upgrade: 'UPGRADE'
}

const statuses = {
  [statusKeys.full]: { value: statusKeys.full, text: 'entourage-public.statuses.full' },
  [statusKeys.lite]: { value: statusKeys.lite, text: 'entourage-public.statuses.lite' }
}

export const fullStatusConfig = {
  text: 'entourage-public.text',
  note: 'entourage-public.full-status.note',
  api: () => activateEntourage({ type: EntourageSubscriptionType.FULL }),
  statuses: [statuses[statusKeys.full], statuses[statusKeys.lite]],
  requirements: [
    requirements.applicationFee,
    requirements.annualFee,
    requirements.passIdVerification,
    requirements.passCriminalBackgroundCheck,
  ],
  price: 30,
  acceptance: [acceptance.entreevip, acceptance.onfido, acceptance.sterling],
  partners: [partners.Onfido, partners.Sterling],
  partnersLabel: 'entourage-public.party-partners',
  benefits: [identityVerification, backgroundCheck],
}

export const liteStatusConfig = {
  text: 'entourage-public.text',
  note: 'entourage-public.lite-status.note',
  api: () => activateEntourage({ type: EntourageSubscriptionType.LITE }),
  statuses: [statuses[statusKeys.full], statuses[statusKeys.lite]],
  requirements: [requirements.applicationFeeLight, requirements.passIdVerification],
  price: 15,
  acceptance: [acceptance.entreevip, acceptance.onfido],
  partners: [partners.Onfido],
  partnersLabel: 'entourage-public.party-partner',
  benefits: [identityVerification],
}

export const upgradeStatusConfig = {
  infoHeader: 'entourage-public.upgrade.text',
  note: 'entourage-public.full-status.note',
  api: () => activateEntourage({ type: EntourageSubscriptionType.FULL }),
  requirements: [
    requirements.upgradeFee,
    requirements.annualFee,
    requirements.passCriminalBackgroundCheck,
  ],
  price: 15,
  acceptance: [acceptance.entreevip, acceptance.sterling],
  partners: [partners.Sterling],
  partnersLabel: 'entourage-public.party-partner',
  benefits: [backgroundCheck],
}

export const pageConfig = {
  [statusKeys.full]: fullStatusConfig,
  [statusKeys.lite]: liteStatusConfig,
}

export const upgradePageConfig = {
  [statusKeys.upgrade]: upgradeStatusConfig
}

