// Modules
import React from 'react'

// Assets
import Icon from 'assets/icons/Icon'

// Utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const NotActiveChat = () => {
  const t = useGetTranslate()

  return (
    <div className="empty-chat">
      <Icon.Chats />
      <p>{t('chats.chat.chat-is-not-selected')}</p>
    </div>
  )
}

export default NotActiveChat
