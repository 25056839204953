import React from 'react'
import cx from 'classnames'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'
import ViewCalendarInfoModal from '_legacy/components/Modal/ViewCalendarInfoModal'
const CalendarButtonWithTip = ({ className, ...props }) => {
  const t = useGetTranslate()
  const classes = cx('action-button','calendar-button-height', className)

  return (
    <div style={{ display:'flex', justifyContent:'center', marginLeft:'32px' }}>
      <button type="button" className={classes} {...props} >
        <div className="calendar-button-color" >
          <div style={{ marginRight:'5px', marginTop:'2px' }}> <Icon.ViewCalendar /></div>
          <span className="action-button__text">{t('shared.words.view-calendar-mode')}</span>
        </div>
      </button>
      <div>
        <TipButton modalComponent={ViewCalendarInfoModal} />
      </div>
    </div>
  )
}

export default CalendarButtonWithTip
