import React from 'react';
import PropTypes from 'prop-types';
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

const PublicFixedFormItem = ({ title, fixedItem, fixedAbbr, onClick }) => {
  return (
    <div className="public-view__fixed-item" onClick={onClick}>
      <PopperTooltip Component={`${fixedItem} ${fixedAbbr && <span>{fixedAbbr}</span>}`} placement={'bottom'} offset={[0, 30]}>
        <h2>
          <span>{title}</span>
        </h2>
        <p>
          {fixedItem} {fixedAbbr && <span>{fixedAbbr}</span>}
        </p>
      </PopperTooltip>
    </div>
  )
};

PublicFixedFormItem.propTypes = {
  title: PropTypes.string.isRequired,
  fixedItem: PropTypes.string.isRequired,
  fixedAbbr: PropTypes.string,
};

export default PublicFixedFormItem;
