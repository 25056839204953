import styled from 'styled-components';

const H3 = styled.h1`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 38px;

  color: #272835;

  ${({ ...props }) => props}
`;

export default  H3;
