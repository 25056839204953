import React from 'react'
import { types } from 'shared'

const { PHOTO, VIDEO } = types.file.Types

import { getFileLibraryIds } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { useFetchBulkFileLibrary } from 'requests/file-library'

const withLibrary = (Component, hocProps) => props => {
  const fileLibrariesIds = useUser(getFileLibraryIds)
  const fetchFileLibrary = useFetchBulkFileLibrary()

  const types = hocProps?.types || [PHOTO, VIDEO]

  React.useEffect(() => {
    fetchFileLibrary({ fileLibrariesIds, types })
  }, [fileLibrariesIds])

  return <Component {...props} />
}

export default withLibrary
