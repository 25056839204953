import React from 'react'

import LineString from '../common/LineString'

export default React.memo(function UsageLimit ({ isLoading, limitOfUsers, usedCount }) {
  return (
    <LineString
      isLoading={isLoading}
      str={`${usedCount} / ${limitOfUsers || '∞'}`}
    />
  )
})
