import React from 'react'
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'
import filter from 'lodash/fp/filter'
import reject from 'lodash/fp/reject'
import pick from 'lodash/fp/pick'
import size from 'lodash/fp/size'
import propOr from 'lodash/fp/propOr'

import { componentConfig, SEARCH } from './config'
import CustomInput from './CustomInput'
import Button from '_legacy/components/Button/Button'

const getConfigKeys = compose(
  map('field'),
  reject(['type', 'search'])
)

export default function MobileFilters ({
  className = '', filtersConfig, getFn, setFn, ButtonComponent
}) {
  const activeFilterCount = getFn(
    compose(
      size,
      filter(propOr(true, 'length')), // filter empty array
      filter(Boolean),
      pick(getConfigKeys(filtersConfig))
    ),
    [filtersConfig]
  )

  const searchConfig = filtersConfig.find(({ type }) => type === SEARCH)
  const restFilterConfigs = filtersConfig.filter(({ type }) => type !== SEARCH)
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleFilters = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])

  const parsedRestFiltersConfig = React.useMemo(() => {
    return restFilterConfigs.map(({ type, ...rest }) => {
      const Component = componentConfig[type]
      if (!Component) return console.warn(`Component with type ${type} not implemented.`)
      return (
        <Component
          key={rest.field}
          {...rest}
          getFn={getFn}
          setFn={setFn}
        />
      )
    })
  }, [restFilterConfigs, getFn, setFn])

  return (
    <StyledWrapper className={className} restFiltersCount={activeFilterCount}>
      {ButtonComponent && <ButtonComponent className='button-component' />}
      <div className='first-line-wrapper'>
        <CustomInput
          className='search-input'
          {...searchConfig}
          getFn={getFn}
          setFn={setFn}
        />
        {restFilterConfigs.length !== 0 && (
          <Button
            classBtn='filters-button'
            text='Filter'
            primary
            handleOnClick={toggleFilters}
          />
        )}
      </div>

      {isOpen && (
        <div className='second-line-wrapper'>
          {parsedRestFiltersConfig}
        </div>
      )}
    </StyledWrapper>
  )
}

MobileFilters.propTypes = {
  className: PropTypes.string,
  filtersConfig: PropTypes.array.isRequired,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired
}

const StyledWrapper = styled.div`
  .button-component {
    width: 100%;
    margin: 0 0 16px 0;
  }
  .first-line-wrapper {
    display: flex;
    .search-input {
      max-width: initial;
      margin-right: 16px;
    }
    .filters-button {
      position: relative;
      &:after {
        ${({ restFiltersCount }) => restFiltersCount && css`
          content: '${restFiltersCount}';
        `}
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 18px;
        height: 18px;
        font-size: 11px;
        font-weight: 700;
        line-height: 18px;
        text-align: center;
        color: #fff;
        background: linear-gradient(360deg, #272835 0%, #636583 97.83%);
        border-radius: 50%;
        transform: translate(50%, -50%);
        z-index: 1;
      }
    }
  }
  .second-line-wrapper {
    display: flex;
    margin: 16px -8px 0;

    & > * {
      margin: 8px;
    }

    .filter {
      max-width: 100%;
      width: auto;
    }

    @media screen and (max-width: 480px) {
      display: block;
      margin: 8px 0;

      & > * {
        margin: 8px 0;
      }
    }
    
  }
`
