import React from 'react'
import styled, { keyframes } from 'styled-components'

export default React.memo(function Loader ({ className = '' }) {
  return (
    <StyledWrapper
      className={className}
    />
  )
})

const opacity = keyframes`
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
`

const StyledWrapper = styled.div`
  height: 24px;
  width: 60%;
  background: ${({ theme: { colors } }) => colors.ultraLightBlue};
  animation: ${opacity} 1.5s infinite;
`
