import { types } from 'shared';
import kebabCase from 'lodash/kebabCase';
import { format, getWeekOfMonth } from 'date-fns'
import { useGetTranslateWithKey, useUserLang } from 'store/hooks/globalState/useTranslates'
import { getLocale } from 'utils/date'

const {
  DOES_NOT_REPEAT,
  DAILY,
  WEEKLY_ON_MONDAY,
  MONTHLY_ON_THE_SECOND_MONDAY,
  ANNUALLY_ON_AUGUST_10,
  EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
} = types.experiences.recurrence.RecurringDefinedInAdvanceValues;

const recurringTypes = [
  DOES_NOT_REPEAT,
  DAILY,
  WEEKLY_ON_MONDAY,
  MONTHLY_ON_THE_SECOND_MONDAY,
  ANNUALLY_ON_AUGUST_10,
  EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
  'CUSTOM'
];

export const useGetRecurringOptions = () => {
  const userLang = useUserLang()
  const tKey = useGetTranslateWithKey()

  return (_date) => {
    const date = _date || new Date()
    const locale = getLocale(userLang)

    const weekOfMonth = {
      1: tKey({ tKey: 'shared.words.first' }),
      2: tKey({ tKey: 'shared.words.second' }),
      3: tKey({ tKey: 'shared.words.third' }),
      4: tKey({ tKey: 'shared.words.fourth' }),
      5: tKey({ tKey: 'shared.words.fifth' }),
    }[getWeekOfMonth(date)]

    const dateOfMonth = format(date, 'd')

    const dayOfWeek = format(date, 'EEEE', { locale })

    const month = format(date, 'MMMM', { locale })

    return [
      {
        value: DOES_NOT_REPEAT,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.does-not-repeat',
        }),
      },
      {
        value: DAILY,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.daily',
        }),
      },
      {
        value: WEEKLY_ON_MONDAY,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.weekly-on',
          phs: [{ ph: '{{dayOfWeek}}', value: dayOfWeek }],
        }),
      },
      {
        value: MONTHLY_ON_THE_SECOND_MONDAY,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.monthly-on',
          phs: [
            { ph: '{{weekOfMonth}}', value: weekOfMonth },
            { ph: '{{dayOfWeek}}', value: dayOfWeek },
          ],
        }),
      },
      {
        value: ANNUALLY_ON_AUGUST_10,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.annually-on',
          phs: [
            { ph: '{{month}}', value: month },
            { ph: '{{dateOfMonth}}', value: dateOfMonth },
          ],
        }),
      },
      {
        value: EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
        label: tKey({
          tKey: 'create-experience.form.recurring.options.every-weekday-monday-to-friday',
        }),
      },
      {
        value: 'CUSTOM',
        label: tKey({
          tKey: 'create-experience.form.recurring.options.custom',
        }),
      },
    ]
  }
}

export const getRecurringLabel = type =>
  `create-experience.form.recurring.options.${kebabCase(type)}`;


export const getRecurringTypesWithTranslate = t =>
  recurringTypes.map(type => ({
    value: type,
    label: t(getRecurringLabel(type)),
  }));
