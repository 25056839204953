import React from 'react'

import CommonStatus from '_legacy/components/Table/table-components/types/common/Status'

import { statuses } from '_legacy/configs/table/manageOrders'


const Status = ({ isLoading, status }) =>
  <CommonStatus
    isLoading={isLoading}
    status={status}
    config={statuses[status]}
  />

export default Status
