import React from 'react';
import PropTypes from 'prop-types';
import NotificationCheckbox from './NotificationCheckbox';

const NotificationRow = ({ cells = [], label = '' }) => {
  return (
    <div className='notifications-row'>
      <div className='notifications-cell notifications-cell--start'>
        <p className='notifications-cell__label'>{label}</p>
      </div>

      {cells.map(cell => <NotificationCheckbox key={cell.name} content={cell}/>)}
    </div>
  );
};

NotificationRow.propTypes = {
  cells: PropTypes.array,
  label: PropTypes.string,
};

export default NotificationRow;
