import { format, isSameYear, isSameMonth, isSameDay } from 'date-fns'
import { dateLocaleCalendar, getLocale } from 'utils/date'


export const buildTitleDateFormat = ({ start, end, localeCodes }) => {
  const locale = getLocale(dateLocaleCalendar[localeCodes])

  const startDate = new Date(start.year, start.month, start.day)
  const endDate = new Date(end.year, end.month, end.day)

  if (isSameDay(startDate, endDate)) {
    const formattedStart = format(startDate, 'MMM d', { locale })
    const year = format(endDate, 'yyyy', { locale })

    return `${formattedStart}, ${year}`
  }

  if (isSameMonth(startDate, endDate)) {
    const formattedStart = format(startDate, 'MMM d', { locale })
    const formattedEnd = format(endDate, 'd', { locale })
    const year = format(endDate, 'yyyy', { locale })

    return `${formattedStart} – ${formattedEnd}, ${year}`
  }

  if (isSameYear(startDate, endDate)) {
    const formattedStart = format(startDate, 'MMM d', { locale })
    const formattedEnd = format(endDate, 'MMM d', { locale })
    const year = format(endDate, 'yyyy', { locale })

    return `${formattedStart} – ${formattedEnd}, ${year}`
  }

  const formattedStart = format(startDate, 'MMM d yyy', { locale })
  const formattedEnd = format(endDate, 'MMM d yyy', { locale })

  return `${formattedStart} – ${formattedEnd}`
}
