// Modules
import React from 'react'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Legacy components
import Dropdown from '_legacy/components/Common/Dropdown'

// Components
import LocationSearch from 'components/LocationSearch'


const Location = ({ themeType }) => {
  const t = useGetTranslate()
  const ref = React.useRef()

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)

  return (
    <div ref={ref} className="main-menu-item-wrapper">
      <Dropdown
        themeType={themeType}
        label={t('main-menu.location')}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={setIsDropdownOpen}
        className="dropdown-wrapper__location"
      >
        <div className="multi-level-select multi-level-select__location">
          <div className="multi-level-select__pointer__wrapper">
            <span className="multi-level-select__pointer"/>
          </div>

          <LocationSearch className="dark" onSubmit={() => setIsDropdownOpen(false)} />
        </div>
      </Dropdown>
    </div>
  )
}

export default Location
