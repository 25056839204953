import React from 'react'
import get from 'lodash/get'

import AvatarWithName from '../common/AvatarWithName'
import { getMonthDayYearDate } from 'utils/date'

export default React.memo(function InvitedUser({ isLoading, invitedUser }) {
  const id = get(invitedUser, 'id')
  const firstName = get(invitedUser, 'firstName')
  const lastName = get(invitedUser, 'lastName')
  const profileImage = get(invitedUser, 'profileImage')
  const linkCopiedAt = get(invitedUser, 'linkCopiedAt')
  const linkCopiedAtFormat = linkCopiedAt && getMonthDayYearDate(linkCopiedAt)

  return (
    <AvatarWithName
      isLoading={isLoading}
      id={id}
      firstName={firstName}
      lastName={lastName}
      image={profileImage}
      date={linkCopiedAtFormat}
    />
  )
})
