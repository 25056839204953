import React from 'react'
import isNil from 'lodash/isNil'
import isEmpty from 'lodash/isEmpty'
import { Link } from 'react-router-dom'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import { isAvailableToEdit } from 'components/Calendar/TalentCalendar'

import { getCalendarTime, getExternalCalendarTime } from 'components/Calendar/utils/getCalendarDateTime.js'
import { eventPublicLink, eventManageLink } from './utils'
import { getTalentPlaqueNickname, getUserTimeZone } from 'utils/user'
import { getFirstLocationSubtitle, getFirstLocationTitle } from 'utils/location'
import { fromSToM } from 'utils/convertTime.js'
import { isChosenLocationOfferType, isOnlineOfferType } from 'utils/offer'
import { getMonthDayYearLocaleDate } from 'utils/date';

const InfoSidebar = ({
  title,
  date,
  locations,
  price,
  participants,
  maxParticipants,
  backgroundColor,
  type,
  publicId,
  occurrenceId,
  onEdit,
  onDelete,
  beforeDriveTime,
  afterDriveTime,
  hour12Format,
  start,
  end,
}) => {
  const t = useGetTranslate()
  const locale = useUserLocale()
  const plaqueNickname = useUser(getTalentPlaqueNickname)

  const getTime = getCalendarTime(hour12Format)
  const duration = `${getTime(start)} - ${getTime(end)}`
  const externalStartTime = getExternalCalendarTime(start)
  const externalEndTime = getExternalCalendarTime(end)
  const timeZone = useUser(getUserTimeZone)

  React.useEffect(() => {
    window.addeventatc.refresh()
  }, [])

  return (
    <div className="calendar-sidebar">
      <div className="calendar-sidebar-heading">
        <span style={{ backgroundColor }} />
        <h6>{title || t('calendar.slot.default-title')}</h6>
      </div>

      {date &&
        <OfferingsDetailsHeaderItem
          icon={<Icon.Calendar />}
          item={getMonthDayYearLocaleDate(date, locale)}
          subitem={duration}
        />
      }

      {(!!beforeDriveTime || !!afterDriveTime) && (
        <OfferingsDetailsHeaderItem
          icon={<Icon.DrivingTimeOutlined />}
          item={`${fromSToM(beforeDriveTime || 0)} min / ${fromSToM(afterDriveTime || 0)} min`}
          subitem={t('calendar.info-sidebar.title.driving-time')}
        />
      )}

      {isChosenLocationOfferType(type) && !isEmpty(locations) && (
        <OfferingsDetailsHeaderItem
          icon={<Icon.Pin />}
          item={getFirstLocationTitle(locations)}
          subitem={getFirstLocationSubtitle(locations)}
        />
      )}

      {isOnlineOfferType(type) && (
        <OfferingsDetailsHeaderItem
          icon={<Icon.Pin />}
          item={t('recurring-list.locations.online')}
        />
      )}

      {!isNil(participants) && !isNil(maxParticipants) && (
        <OfferingsDetailsHeaderItem
          icon={<Icon.Participants />}
          item={`${participants} of ${maxParticipants}`}
        />
      )}

      {price && (
        <OfferingsDetailsHeaderItem
          icon={<Icon.Pricetag />}
          item={`$${price} ${t('currency.usd')}`}
          subitem={t('order-details.price-per-person')}
        />
      )}

      {!isAvailableToEdit(type) && (
        <React.Fragment>
          <Link
            className="od__row od__public-link"
            /* TODO get sequentialId from BE to navigate to public view */
            to={eventPublicLink({ type, plaqueNickname, publicId })}
          >
            <div className="od--main-text od--accented od__public-link--text">{t('calendar.info-sidebar.button.public-view')}</div>
            <div className="od__public-link--arrow" />
          </Link>
          {/* TODO we need to clarify where exactly this route should navigate */}
          <Button
            text={t('calendar.info-sidebar.button.manage')}
            paddingVertical="10"
            paddingHorizontal="10"
            stretch
            isLink
            path={eventManageLink({ type, occurrenceId })}
          />
        </React.Fragment>
      )}

      {isAvailableToEdit(type) && (
        <div className="calendar-sidebar__controls">
          <Button stretch primary handleOnClick={onEdit} text={t('calendar.info-sidebar.button.edit')} />
          <Button stretch primary handleOnClick={onDelete} text={t('calendar.info-sidebar.button.delete')} />
        </div>
      )}

      {start &&
        <div className="addeventatc" data-dropdown-y="up">
          {t('calendar.info-sidebar.title.add-to-calendar')}
          <span className="start">{externalStartTime}</span>
          <span className="end">{externalEndTime}</span>
          <span className="timezone">{timeZone}</span>
          <span className="title">{title || t('calendar.slot.default-title')}</span>
          {!isEmpty(locations) &&
            <span className="location">{getFirstLocationTitle(locations)}</span>
          }
        </div>
      }

    </div>
  )
}

export default InfoSidebar
