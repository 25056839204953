import React from 'react';

import {
  ACCOUNT,
  // CALENDAR,
  CHANGE_PASSWORD,
  REMOVE,
  EDIT_PROFILE,
  NOTIFICATIONS,
  PAYMENTS, ENTOURAGE,
} from '_legacy/constants/routes'

export const getAgentTalentMenuItems = () => {
  return [
    {
      translation: 'account.menu.profile.edit',
      pathname: `/${ACCOUNT}/${EDIT_PROFILE}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.notifications.title',
      pathname: `/${ACCOUNT}/${NOTIFICATIONS}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.payments.title',
      pathname: `/${ACCOUNT}/${PAYMENTS}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.delete.title',
      pathname: `/${ACCOUNT}/${REMOVE}`,
      itemRef: React.useRef(),
    },
  ];
}

export const getAccountMenuItems = () => {
  return [
    {
      translation: 'account.menu.profile.edit',
      pathname: `/${ACCOUNT}/${EDIT_PROFILE}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.entourage.title',
      pathname: `/${ACCOUNT}/${ENTOURAGE}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.notifications.title',
      pathname: `/${ACCOUNT}/${NOTIFICATIONS}`,
      itemRef: React.useRef(),
    },
    // EN-3151 hide calendar
    // {
    //   translation: 'account.menu.submenu.calendar',
    //   pathname: `/${ACCOUNT}/${CALENDAR}`,
    //   itemRef: React.useRef(),
    // },
    {
      translation: 'account.menu.payments.title',
      pathname: `/${ACCOUNT}/${PAYMENTS}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.change-password',
      pathname: `/${ACCOUNT}/${CHANGE_PASSWORD}`,
      itemRef: React.useRef(),
    },
    {
      translation: 'account.menu.delete.title',
      pathname: `/${ACCOUNT}/${REMOVE}`,
      itemRef: React.useRef(),
    },
  ];
};

export const calcOffset = (
  fullMenuWidth,
  listWidth,
  itemsWidths,
  gapWidth,
  currentIndex
) => {
  let offsetBefore = 0;

  for (let i = 0; i < currentIndex; i++) {
    offsetBefore += itemsWidths[i] + gapWidth;
  }

  return (
    (fullMenuWidth - listWidth) / 2 +
    offsetBefore +
    itemsWidths[currentIndex] / 2 -
    10
  );
};
