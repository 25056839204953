import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const AddMore = props => {
  const t = useGetTranslate();
  return <StyledAddMore type='button' {...props}>+ {props.title || t('shared.words.add-more')}</StyledAddMore>;
};

AddMore.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
};

const StyledAddMore = styled.button`
  background: transparent;
  display: flex;
  justify-content: start;
  border: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
  cursor: pointer;
`;

export default AddMore;
