import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';
import Machine from './SlideMachine';

const usePrevOrNext = (isNext, setSlideIndex) =>
  useCallback(
    throttle(
      () => setSlideIndex(slideIndex => slideIndex + (isNext ? 1 : -1)),
      300,
      {
        leading: true,
        trailing: false,
      }
    ),
    []
  );

const Slider = ({
  size = 'big' /* or small */,
  isMirroredInside = false,
  withGradientOverhead,
  title,
  titleFontSize,
  slides = [],
  empty,
  bottomSpace,
  Actions,
  leftSpace = 0,
  rightSpace = 0,
  className = '',
  plaqueNickname
}) => {
  const [slideIndex, setSlideIndex] = useState(0);

  const onPrev = usePrevOrNext(false, setSlideIndex);
  const onNext = usePrevOrNext(true, setSlideIndex);

  const slide = slides[((slideIndex % slides.length) + slides.length) % slides.length];
  const overlay = slide?.props?.overlay;
  const offerSequentialId = slide?.props?.offerSequentialId;
  const multipleDatesExperience = slide?.props?.multipleDatesExperience;
  const isPromo = slide?.props?.isPromo;
  const hasText = overlay?.props?.name;

  const _withGradientOverhead = isPromo || !hasText ? false : withGradientOverhead

  return (
    <div
      className={`tbv-slider ${className}`}
      style={{
        marginBottom: bottomSpace,
        marginLeft: leftSpace,
        marginRight: rightSpace,
      }}
      attr-size={size}
      attr-with-gradient-overhead={String(_withGradientOverhead)}
    >
      {title && (
        <h3 className="tbv-slider-title" style={{ fontSize: titleFontSize }}>
          {title}
        </h3>
      )}
      <Machine
        size={size}
        currentSlideIndex={slideIndex}
        isMirroredInside={isMirroredInside}
      >
        {slides.length === 0 ? [empty] : slides}
      </Machine>
      {overlay}
      <div>
        <Actions
          isPromo={isPromo}
          offerSequentialId={offerSequentialId}
          multipleDatesExperience={multipleDatesExperience}
          plaqueNickname={plaqueNickname}
          slidesCount={slides.length}
          onPrev={onPrev}
          onNext={onNext}
        />
      </div>
    </div>
  );
};

Slider.propTypes = {
  isMirroredInside: PropTypes.bool,
  withGradientOverhead: PropTypes.bool,

  size: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleFontSize: PropTypes.number,
  slides: PropTypes.array.isRequired,
  empty: PropTypes.object.isRequired,
  Actions: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),

  bottomSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  leftSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rightSpace: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  plaqueNickname: PropTypes.string,
};

export default Slider;
