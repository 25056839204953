import React from 'react';
import { useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Title } from '_legacy/common';
import PageLoader from '_legacy/common/Template/PageLoader';
import OfferingCard from './OfferingCard';
import PaymentForm from './PaymentForm';

import { appApi } from 'api';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetch } from 'store/hooks/useFetch';
import { fontsCardElement } from './utils/stylesStripe'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Purchase = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const [{ data, isLoading }, doFetch] = useFetch(appApi.purchase.getOrderOffer)
  const [discountPrice, setDiscountPrice] = React.useState(null)

  React.useEffect(() => doFetch(orderId), [orderId])

  if (isLoading || !data) return <PageLoader />

  return (
    <div className="payment-card">
      <Title className="payment-card__title">{t('payment-page.header')}</Title>
      <div className='payment-card__wrapper'>
        <OfferingCard data={data?.order} discountPrice={discountPrice} />
        <Elements stripe={stripePromise} options={{ fontsCardElement }}>
          <PaymentForm data={data} setDiscountPrice={setDiscountPrice} />
        </Elements>
      </div>
    </div>
  )
};

export default Purchase;
