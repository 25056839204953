import React from 'react'
import get from 'lodash/get'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import MobileTemplate from './MobileTemplate'
import DesktopTemplate from './DesktopTemplate'

export default function Template ({
  children, themeType = 'light-theme', backgroundType = 'default'
}) {
  const windowWidth = useWindowDimensions(current => get(current, 'width'))

  if (windowWidth <= 1024) {
    return (
      <MobileTemplate
        themeType={themeType}
        backgroundType={backgroundType}
      >
        {children}
      </MobileTemplate>
    )
  }

  return (
    <DesktopTemplate
      themeType={themeType}
      backgroundType={backgroundType}
    >
      {children}
    </DesktopTemplate>
  )
}
