import { useHistory } from 'react-router-dom'
import { cond, propEq, anyPass } from 'lodash/fp'
import { useLogout } from 'requests/auth'

import { HASH_LOGIN, NOT_FOUND } from '_legacy/constants/routes'
import { hashHistory } from 'utils/history'

export const useHandleResponseError = () => {
  const history = useHistory()
  const logout = useLogout();

  return cond([
    [propEq('code', 404), () => history.replace(`/${NOT_FOUND}`)],
    [anyPass([
      propEq('name', 'UNAUTHORIZED'),
      propEq('code', 401)
    ]), () => {
      logout()
      hashHistory.push(HASH_LOGIN)
    }],
    [propEq('name', 'VALIDATION_FAILED'), () => hashHistory.push(HASH_LOGIN)],
  ])
}
