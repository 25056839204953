import { _patch, _post } from 'api/utils'

export const publishOffer = ({ talentId, offerId }) => _patch(
  `talents/${talentId}/offers/${offerId}/publish`,
  { needAuthorization: true }
)

export const unpublishOffer = ({ talentId, offerId }) => _patch(
  `talents/${talentId}/offers/${offerId}/unpublish`,
  { needAuthorization: true }
)

export const bookOffer = ({ offerId, data }) =>
  _post(`/offers/${offerId}/book`, { body: data, needAuthorization: true })
