import React from 'react'

import { StyledCheckbox } from '_legacy/common'
import Price from '_legacy/components/Price'
import Button from '_legacy/components/Button/BookNowButton'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Card = React.memo(({ price, onClick, list = [] }) => {
  const t = useGetTranslate()
  const [checkedState, setCheckedState] = React.useState({})
  const onChange = React.useCallback(e => {
    setCheckedState(prev => ({ ...prev, [e.target.name]: !prev[e.target.name] }))
  }, [])

  const isBtnEnabled = list.reduce((acc, i) => acc && checkedState[i], true)

  return (
    <div className='entourage-card'>
      <h6 className='entourage-card__header'>{t('entourage-public.entourage-card.application-fee')}</h6>
      <Price price={price} withCurrency rounded />
      <ul className='entourage-card__list'>
        {list.map((term, i) => (
          <StyledCheckbox key={i} className='entourage-card__list-item'>
            <span><Trans tKey={term} /></span>
            <input type='checkbox' name={term} onChange={onChange} checked={checkedState[term]} />
            <i />
          </StyledCheckbox>
        ))}
      </ul>
      <Button text={t('entourage-public.entourage-card.apply-now')} handleOnClick={onClick} stretch paddingVertical={13} disabled={!isBtnEnabled} />
    </div>
  )
})

export default Card
