import {
  ProfileManager,
  ProfileType,
  ProfileStatus,
} from 'shared/dist/src/types/talent';

import { levelOptions, languagesList } from '../../../components/Register/config';

export const transformLanguages = (t, { level, language }) => {
  const lvl = levelOptions(t).find(op => op.value === level);
  const lang = languagesList.find(op => op.value === language);
  return { level: lvl, language: lang };
};

export const managerTypes = {
  talentUser: ProfileManager.SELF,
  agent: ProfileManager.AGENT,
  manager: ProfileManager.MANAGER,
};

export const profileTypes = [
  {
    name: 'person',
    value: ProfileType.PERSON,
    disabled: true,
  },
  {
    name: 'company',
    value: ProfileType.COMPANY,
    disabled: true,
  },
];

export const profileManagers = [
  {
    name: 'self',
    value: ProfileManager.SELF,
    disabled: true,
  },
  {
    name: 'agent',
    value: ProfileManager.AGENT,
    disabled: true,
  },
  {
    name: 'manager',
    value: ProfileManager.MANAGER,
    disabled: true,
  },
  {
    name: 'company-representative',
    value: ProfileManager.COMPANY_REPRESENTATIVE,
    disabled: true,
  },
];

export const profileStatus = [
  {
    name: 'hidden',
    value: ProfileStatus.HIDDEN,
  },
  {
    name: 'published',
    value: ProfileStatus.PUBLISHED,
  },
];

export const profileTypesWithTranslate = (t) =>
  profileTypes.map(type => ({
    ...type,
    label: t(`register.form.profile-type.options.${type.name}`),
  }));

export const profileManagersWithTranslate = (t) =>
  profileManagers.map(manager => ({
    ...manager,
    label: t(`register.form.profile-manager.options.${manager.name}`),
  }));

export const profileStatusWithTranslate = (t) =>
  profileStatus.map(status => ({
    ...status,
    label: t(`edit-profile.form.profile-status.options.${status.name}`),
  }));

export const getEmailLabel = (t, profileManager = ProfileManager.SELF) => {
  const emailLabel = {
    [ProfileManager.SELF]: t('edit-profile.form.email.label.talent'),
    [ProfileManager.AGENT]: t('edit-profile.form.email.label.agent'),
    [ProfileManager.MANAGER]: t('edit-profile.form.email.label.manager'),
  };

  return emailLabel[profileManager];
};

export const getLicenseLabel = (t, profileManager = ProfileManager.SELF) => {
  const licenseLabel = {
    [ProfileManager.SELF]: t('edit-profile.form.licenses.label.talent'),
    [ProfileManager.AGENT]: t('edit-profile.form.licenses.label.agent'),
    [ProfileManager.MANAGER]: t('edit-profile.form.licenses.label.manager'),
  };

  return licenseLabel[profileManager];
};

export const config = [
  {
    label: 'user',
    isTalent: false,
    isTalentVisible: false,
  },
  {
    label: 'talent',
    isTalent: true,
    isTalentVisible: true,
  },
];
