import React from 'react';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';

import Button from '_legacy/components/Button';
import CommonInput from '_legacy/components/Form/Inputs/Common';

import { useLoginUser } from 'requests/auth';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { emailPattern } from 'utils/services/validators/validators';
import { RESTORE_PASSWORD } from '_legacy/components/Modal/modals-types';

const defaultValues = {
  email: '',
  password: ''
};

const LoginForm = ({ closeModal }) => {
  const t = useGetTranslate();
  const methods = useForm({ defaultValues });
  const loginUser = useLoginUser(methods);

  const onChange = () => {
    if (
      methods.errors?.email?.type === 'server' ||
      methods.errors?.password?.type === 'server'
    ) {
      methods.clearErrors(['email', 'password']);
    }
  };

  const openRestorePassword = useStateModal(RESTORE_PASSWORD)

  const onResetPassword = () => {
    openRestorePassword({ initialEmail: methods.watch('email' || '') });
    closeModal();
  };

  const tip = (
    <button
      type='button'
      onClick={onResetPassword}
      className="modal-form-link"
    >
      {t('login.forgot-your-password')}
    </button>
  );

  return (
    <>
      <form
        className="login-modal__form default"
        onSubmit={methods.handleSubmit(loginUser)}
      >
        <CommonInput
          containerClassName={'mb-24'}
          name="email"
          type={'email'}
          placeholder={'example@email.com'}
          errorMessage={methods.errors?.email?.message}
          label={t('login.email')}
          forwardRef={methods.register({
            required: 'Required',
            pattern: { value: emailPattern, message: 'Email is required' },
          })}
          onChange={onChange}
        />

        <CommonInput
          containerClassName={'mb-24'}
          name="password"
          type={'password'}
          placeholder={'Password'}
          errorMessage={methods.errors?.password?.message}
          label={t('login.password')}
          forwardRef={methods.register({
            required: 'Password is required',
          })}
          onChange={onChange}
          component={tip}
          labelContainerClassName={'login-modal__password-label'}
        />

        <Button
          text={t('login.login-button')}
          typeButton="submit"
          stretch
          paddingVertical="16"
        />
      </form>
    </>
  );
};

LoginForm.propTypes = {
  closeModal: PropTypes.func,
}

export default LoginForm;
