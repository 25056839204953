import { VideoMessageForType } from 'shared';

export default {
  recipient: {
    forType: VideoMessageForType.MY_SELF,
    toEmail: '',
    name: '',
    phone: '',
    phoneDeliverVersion: false
  },
  requestor: {
    name: '',
    email: '',
    emailDeliverVersion: false,
    phone: '',
    phoneDeliverVersion: false
  },
  message: {
    occasionType: '',
    script: ''
  }
}
