import React from 'react'
import styled from 'styled-components';

import bg1920 from 'assets/landing/first-section/bg-1920.jpg'
import bg1440 from 'assets/landing/first-section/bg-1440.jpg'
import bg1024 from 'assets/landing/first-section/bg-1024.jpg'
import bg768 from 'assets/landing/first-section/bg-768.jpg'
import bg480 from 'assets/landing/first-section/bg-480.jpg'

import Title from './Title'
import CustomCarousel from './CustomCarousel'
import ArrowScrollDown from '../ArrowScrollDown'

export default React.memo(function CarouselSection ({ setActiveSection }) {
  return (
    <StyledWrapper className='section'>
      <Title />
      <CustomCarousel />
      <ArrowScrollDown clickHandler={() => setActiveSection(1)} />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(${bg1920}) center / cover no-repeat;
  min-height: 100%;
  user-select: none;
  @media screen and (max-width: 1440px) {
    background: url(${bg1440}) center / cover no-repeat;
  }
  @media screen and (max-width: 1024px) {
    background: url(${bg1024}) center / cover no-repeat;
  }
  @media screen and (max-width: 768px) {
    background: url(${bg768}) center / cover no-repeat;
  }
  @media screen and (max-width: 480px) {
    background: url(${bg480}) center / cover no-repeat;
  }
`
