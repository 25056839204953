import get from 'lodash/get'
import prop from 'lodash/fp/prop'

import { getTimezoneAbbr } from 'utils/services/timezone'


const getTimeZoneLabel = tz => `${tz} (${getTimezoneAbbr(tz)})`

export const getTimeZoneAdapter = timeZoneId =>
  [timeZoneId]
    .filter(Boolean)
    .map(timeZoneId => ({
      value: timeZoneId,
      label: getTimeZoneLabel(timeZoneId)
    }))
    .pop()

export const submitTimeZoneAdapter = prop('value')


export const getTimeZoneFromOccurrenceLocation = occurrence => {

  // TODO: Unify location data format that's coming from BE
  const location =
     occurrence?.location?.place?.[0]
  || occurrence?.locations?.[0]
  || occurrence?.location?.place

  const timeZone = get(location, 'timezone.id')
  return timeZone
}

export const getTimeZoneFromOccurrence = occurrence => {
  const timeZone = occurrence?.timeZone || getTimeZoneFromOccurrenceLocation(occurrence)
  return timeZone
}
