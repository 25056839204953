import React from 'react';
import PropTypes from 'prop-types';

import { defaultTaboos } from './config';
// components
import { Row } from '_legacy/common';
import FixedCheckbox from './FixedCheckbox';
import MultiCheckbox from './MultiCheckbox';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Wrapper = ({ label, defaultCheckbox, register, tip, defaultEntities = [], buttonLabel, placeholder }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={label || t('shared.taboos.label')}
      subNameBlock={tip || t('shared.taboos.tip')}
      component={
        <>
          <FixedCheckbox
            name="defaultTaboos"
            entities={defaultCheckbox || defaultTaboos}
            register={register}
          />
          <MultiCheckbox
            name="customTaboos"
            register={register}
            defaultEntities={defaultEntities}
            buttonLabel={buttonLabel}
            placeholder={placeholder}
          />
        </>
      }
    />
  );
};

Wrapper.propTypes = {
  label: PropTypes.string,
  defaultCheckbox: PropTypes.array,
  defaultEntities: PropTypes.array,
  register: PropTypes.func,
  tip: PropTypes.string,
  buttonLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default Wrapper;
