import { _get } from 'api/utils'

export const getTalents = ({ query }) => _get(
  '/searches/results/talents/search',
  { needAuthorization: true, query }
)

export const getTalentTopLocation = ({ query }) => _get(
  '/searches/results/talents/locations/top',
  { query }
)
