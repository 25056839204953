import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'

import { componentConfig } from './config'

export default function DesktopFilters ({
  className = '', filtersConfig, getFn, setFn, ButtonComponent
}) {
  const filters = getFn()
  const parsedFiltersConfig = React.useMemo(() => {
    return filtersConfig.map(({ type, ...rest }) => {
      const Component = componentConfig[type]
      if (!Component) return console.warn(`Component with type ${type} not implemented.`)
      return (
        <Component
          key={rest.field}
          {...rest}
          getFn={getFn}
          setFn={setFn}
        />
      )
    })
  }, [filtersConfig, getFn, setFn, filters])
  return (
    <StyledWrapper className={className}>
      {parsedFiltersConfig}
      {ButtonComponent && <ButtonComponent className='button-component' />}
    </StyledWrapper>
  )
}

DesktopFilters.propTypes = {
  className: PropTypes.string,
  filtersConfig: PropTypes.array.isRequired,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  .button-component {
    margin-left: auto;
  }
`
