import React from 'react';
import { object, number } from 'prop-types';
import get from 'lodash/get';

import RatingAndMoney from '../RatingAndMoney/';
import TalentInfoBlock from '../TalentInfoBlock/';
import ImageLayer from '../ImageLayer/';
import DesktopSlider from '../DesktopSlider/index'
import CommercialEngagementBook from '../CommercialEngagementBook/';
import { getSlides } from '../utils.js';

import { useWindowDimensions } from 'store/hooks';
import TalentReview from '../TalentReview'


const MobileBlock = React.memo(function MobileBlock({ talentData }) {
  const {
    nickname,
    firstName,
    lastName,
    publicView,
    videoMessage,
    videoChat,
    ownWords,
    plaqueNickname,
    rating,
    totalCharityFee,
  } = talentData

  const { width } = useWindowDimensions(current => get(current, 'width'))

  const {
    LiveExperiencesCover,
    LiveVideoChatsCover,
    // LiveVirtualEventsCover,
    VideoMessagesCover,
    LessonsCover,
    ReadyMadeEventsCover,
  } = getSlides(talentData)

  return (
    <div className="tpv-page-mobile">
      <div className="tpv-page-mobile__wrapper">
        <ImageLayer className='main-image' layers={publicView?.layers} altImg={`${firstName} ${lastName}`} />

        <div className="tpv-page-mobile__content">
          {768 > width && ownWords && (<p className="tpv-page__description">{ownWords}</p>)}
          <DesktopSlider.Mobile
            liveExperiencesSlides={LiveExperiencesCover}
            videoChats={{ media: LiveVideoChatsCover, offerSequentialId: videoChat?.sequentialId }}
            videoMessages={{ media: VideoMessagesCover, offerSequentialId: videoMessage?.sequentialId }}
            lessonsSlides={LessonsCover}
            readyMadeEvents={ReadyMadeEventsCover}
            liveVirtualEvents={[]}
            firstName={firstName}
            plaqueNickname={plaqueNickname}
          />
          <CommercialEngagementBook nickname={nickname} className='commercial-engagement' />

        </div>
      </div>
      <div className="tpv-page-mobile__block-info">
        <div className='block-info'>
          <TalentReview />
          <RatingAndMoney rating={rating} totalCharityFee={totalCharityFee} />
          <TalentInfoBlock talent={talentData} />
        </div>
        {768 <= width && ownWords && (<p className="tpv-page__description">{ownWords}</p>)}
      </div>
    </div>
  )
})

MobileBlock.propTypes = {
  talentData: object,
  width: number,
};

export default MobileBlock
