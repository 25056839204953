import React from 'react'
import { Link, useParams } from 'react-router-dom'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import isEmpty from 'lodash/isEmpty'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.min.css'

import { getSaveFullName } from 'utils/user'
import { routesList } from 'router/routesMap'
import { getCityOrCountry } from 'utils/location'
import { getTalentReviews } from 'api/appApi/talent/publicView'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'


SwiperCore.use([Navigation, Pagination])

const LIMIT = 10

const TalentReviewSlider = () => {
  const t = useGetTranslate()
  const { talentNickname } = useParams()

  const prevRef = React.useRef(null)
  const nextRef = React.useRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const [reviews, setReviews] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState({
    page: 1,
    limit: LIMIT,
    count: null,
    remains: null,
  })

  const fetchReviews = ({ page }) => {
    getTalentReviews({ page, talentNickname, limit: LIMIT }).then(({ reviews, pageInfo }) => {
      setReviews(prevReviews => [...prevReviews, ...reviews])
      setPageInfo(pageInfo)
    })
  }

  const onSlideChange = ({ activeIndex }) => {
    setActiveSlideIndex(activeIndex)

    if (LIMIT * pageInfo.page - 3 === activeIndex && pageInfo.remains > 0) {
      fetchReviews({ page: pageInfo.page + 1 })
    }
  }

  React.useEffect(() => {
    fetchReviews({ page: 1 })
  }, [])

  if (isEmpty(reviews)) return null

  return (
    <div className="talent-reviews">
      <div className="talent-reviews__nav">
        <div className="talent-reviews__title">{t('talent-public-view.reviews')}</div>
        {reviews.length > 1 && (
          <div className="talent-reviews__controls">
            <button
              ref={prevRef}
              disabled={activeSlideIndex === 1}
              className="talent-reviews__nav-btn talent-reviews__nav-btn--prev"
            />
            <button
              ref={nextRef}
              disabled={pageInfo.count === activeSlideIndex}
              className="talent-reviews__nav-btn talent-reviews__nav-btn--next"
            />
          </div>
        )}
      </div>

      <div className="talent-reviews__container">
        <Swiper
          loop
          slidesPerView={1}
          onSlideChange={onSlideChange}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
        >
          {reviews.map(review => {
            const sender = review.fromTalent || review.fromUser
            const location = getCityOrCountry(sender?.location)

            return (
              <SwiperSlide key={review.id}>
                <div className="talent-reviews__slide">
                  <blockquote className="talent-reviews__quote">{review.description}</blockquote>
                  <Icon.AvatarSliderPointerBlack />
                  <div className="talent-reviews__sender">
                    <Link to={`${routesList.talentPublicPage(review?.plaqueNickname)}`}>{getSaveFullName(sender)}&nbsp;</Link>
                    {location && <span>/&nbsp;{location}</span>}
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default TalentReviewSlider
