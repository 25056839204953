import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cond from 'lodash/cond';
import is from 'lodash/fp/isEqual';
import always from 'lodash/fp/always';
import T from 'lodash/fp/T';
import { types } from 'shared';

import { Radio, Row } from '_legacy/common';
import HintButton from '_legacy/common/Buttons/HintButton';
import DiscountPercent from '_legacy/containers/PromoCodes/components/DiscountPercent';
import DiscountPrice from '_legacy/containers/PromoCodes/components/DiscountPrice';

import { PROMO_CODES_DISCOUNT_HIT } from '_legacy/components/Modal/modals-types';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const { PromoCodeDiscountType } = types;

const Discount = ({ name }) => {
  const t = useGetTranslate();
  const { control } = useFormContext()

  const typesOfDiscount = useWatch({
    control,
    defaultValue: PromoCodeDiscountType.PERCENTAGE,
    name,
  });

  const ContentDiscount = cond([
    [is(PromoCodeDiscountType.PERCENTAGE), always(DiscountPercent)],
    [is(PromoCodeDiscountType.AMOUNT), always(DiscountPrice)],
    [T, always(() => null)],
  ])(typesOfDiscount);

  return (
    <StyledWrapper
      isRequired
      nameBlock={t('promo-codes.form.discount.label')}
      tip={<HintButton modalType={PROMO_CODES_DISCOUNT_HIT} />}
      component={
        <>
          <StyledRadios>
            <Radio
              name={name}
              label={t('promo-codes.form.discount.percentage.label')}
              value={PromoCodeDiscountType.PERCENTAGE}
              defaultChecked
            />
            <Radio
              name={name}
              label={t('promo-codes.form.discount.amount.label')}
              value={PromoCodeDiscountType.AMOUNT}
            />
          </StyledRadios>
          <ContentDiscount />
        </>
      }
    />
  )
}

const StyledWrapper = styled(Row)`
  .row-left {
    padding-top: 0;
  }
  .discount {
    max-width: 262px;
    width: 100%;
    @media screen and (max-width: 575px) {
      max-width: 100%;
    }
  }
`

const StyledRadios = styled.div`
  display: flex;
  margin-bottom: 32px;
`

Discount.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Discount
