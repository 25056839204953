import React from 'react'
import cx from 'classnames'

import { getShortMonthDate } from 'utils/date'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import { buildPhs, phsConfig } from '../config'

const getStripeSuccessfulRefundPhsConfig = buildPhs([
  phsConfig.orderNumber,
  phsConfig.bookingLink,
])

const StripeSuccessfulRefundNotification = (props) => {
  const { id, createdAt, isRead, isOverdue, isTable, isMenu } = props
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  return (
    <div className={containerClasses} id={id}>
      <div className="notification-item__icon">
        <Icon.AdminNotificationIcon id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans phs={getStripeSuccessfulRefundPhsConfig(props)} tKey='notification.stripe-successful-refund' />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>
    </div>
  )
}

export default StripeSuccessfulRefundNotification
