import React from 'react'
import { useHistory } from 'react-router-dom'
import { last } from 'lodash'

import * as r from '_legacy/constants/routes'
import { checkSubitems } from '_legacy/components/CategoriesSelect/utils'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetSearchListFilters } from 'store/hooks/globalState/useSearchList'
import { useTalentCategories } from 'store/hooks/globalState/useCategories'

import Dropdown from '_legacy/components/Common/Dropdown'
import CategoriesSelect from '_legacy/components/CategoriesSelect'
import BackgroundComponenTalent from '_legacy/components/CategoriesSelect/CategoriesSelectComponents/BackgroundComponents/BackgroundComponenTalent'

const Talents = ({ themeType }) => {
  const ref = React.useRef()
  const t = useGetTranslate()
  const history = useHistory()
  const setTalentSearchFilters = useSetSearchListFilters((p, n) => ({
    ...p,
    ...n,
  }))

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const [value, setValue] = React.useState([])
  const [pointerShift, setPointerShift] = React.useState(ref.current?.offsetLeft)

  const dropdownStateHandler = state => {
    setIsDropdownOpen(state)
    if (!state) setValue([])
  }

  const onChange = newState => {
    setValue(newState)
    if (!checkSubitems(last(newState))) {
      const categoryIn =
        newState?.length === 1 && newState[0]?.isHeader ? null : newState

      setTalentSearchFilters({ categoryIn })
      setIsDropdownOpen(false)
      setValue([])
      history.push(`/${r.SEARCH}/${r.TALENT}`)
    }
  }

  const onResize = () => {
    setPointerShift(ref.current?.offsetLeft)
  }

  React.useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    <div ref={ref} className="main-menu-item-wrapper">
      <Dropdown
        themeType={themeType}
        label={t('main-menu.talent')}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={dropdownStateHandler}
      >
        <CategoriesSelect
          withCount
          value={value}
          setValue={onChange}
          getFn={useTalentCategories}
          showAllCategories
          allCategoriesLabel={t('main-menu.talent')}
          className="multi-level-select__talent"
          backgroundComponent={<BackgroundComponenTalent />}
          pointerShift={pointerShift}
        />
      </Dropdown>
    </div>
  )
}

export default Talents
