import React from 'react'
import { prop } from 'lodash/fp'
import cx from 'classnames'
import { init } from 'onfido-sdk-ui'

import { notify } from 'utils/notify'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { ReactComponent as IDNoVerifiedIcon } from '../icons/IDNoVerifiedIcon.svg'
import { ReactComponent as IDVerifiedIcon } from '../icons/IDVerifiedIcon.svg'
import { sharedErrors } from '_legacy/components/RegisterForms/shared/components/IDVerification/utils'
import {
  useOnfidoToken,
  useOnfioCheckResult,
} from '_legacy/components/RegisterForms/shared/components/IDVerification/state/store'
import {
  useGetSDKToken,
  useCheckOnfidoApplicant,
  useGetApplicantLocation,
} from '_legacy/components/RegisterForms/shared/components/IDVerification/state/request'
import { createApplicant } from '_legacy/components/RegisterForms/shared/components/IDVerification/state/api'
import Button from '_legacy/components/Button'
import { useGetMe } from 'requests/auth'
import { debounce } from 'lodash'

const IDVerification = () => {
  const t = useGetTranslate()
  const getMe = useGetMe()
  const token = useOnfidoToken()
  const getSDKToken = useGetSDKToken()
  const checkResult = useOnfioCheckResult()
  const checkApplicant = useCheckOnfidoApplicant()
  const { firstName, lastName, identityIsVerified } = useUser(prop('user'))
  const getApplicantLocation = useGetApplicantLocation()

  const [applicantId, setApplicantId] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(null)
  const [isVerified, setIsVerified] = React.useState(identityIsVerified)

  // get check results from socket
  const { result, status } = checkResult || {}

  let onfido = {}
  const triggerOnfido = () => {
    onfido = init({
      useModal: true,
      isModalOpen: true,
      onModalRequestClose: function () {
        onfido.setOptions({ isModalOpen: false })
      },
      token,
      onComplete: debounce(function () {
        checkApplicant(applicantId)
        setIsLoading(true)
        onfido.setOptions({ isModalOpen: false })
      }, 500),
      onError: function () {
        const message = t(sharedErrors['check'])
        notify(message, 'error')
        onfido.setOptions({ isModalOpen: false })
      },
      steps: ['welcome', 'document', 'face', 'complete'],
    })
  }

  // Create Applicant ID and token to be able to run onfido SDK
  React.useEffect(debounce(async () => {
    try {
      const location = await getApplicantLocation()
      await createApplicant({ firstName, lastName, location })
        .then(({ applicantId }) => {
          setApplicantId(applicantId)
          getSDKToken({ applicantId })
        })
        .catch(err => console.warn(err))
    } catch (e) {
      console.warn(e)
    }
  }, 500), []);

  React.useEffect(() => {
    if (status === 'complete') {
      setIsLoading(false)
      if (result === 'clear') {
        setIsVerified(true)
        notify(t('register.id-verification.verified'), 'success')
        getMe()
      }
      if (result === 'consider') {
        const message = t(sharedErrors['consider'])
        notify(message, 'error')
      }
    }
  }, [status, result])

  if (isVerified) {
    return (
      <div className="entourage-verify-card">
        <IDVerifiedIcon className="entourage-verify-card__icon" />
        <h3 className="entourage-verify-card__header">{t('entourage-verify.id-verification.header.verified')}</h3>
      </div>
    )
  }

  return (
    <div className={cx('entourage-verify-card', { 'entourage-verify-card--disable': isLoading })}>
      <div id="onfido-mount" />

      <IDNoVerifiedIcon className="entourage-verify-card__icon" />
      <h3 className="entourage-verify-card__header">{t('entourage-verify.id-verification.header.no-verified')}</h3>
      <p className="entourage-verify-card__text">{t('entourage-verify.id-verification.text.no-verified')}</p>
      <Button
        text="Start ID Verification"
        handleOnClick={triggerOnfido}
        loader={isLoading}
        className="entourage-verify-card__button"
      />
    </div>
  )
}

export default IDVerification
