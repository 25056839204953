import React from 'react'
import { Link } from 'react-router-dom';
import styled from 'styled-components'

export default React.memo(function CustomButton ({
  className = '', path, children, clickHandler
}) {
  const button = React.useMemo(() => {
    if (path) {
      return (
        <Link
          className='inner-button'
          to={path}
        >
          {children}
        </Link>
      )
    }
    return (
      <button className='inner-button'>
        {children}
      </button>
    )
  }, [path, children])
  return (
    <StyledWrapper
      className={className}
      onClick={clickHandler}
    >
      {button}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: inline-block;
  padding: 2px;
  border-radius: 4px;
  background-image: linear-gradient(0, #272835 0, #636583 97.83%);
  cursor: pointer;
  .inner-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 14px 16px;
    background-image: linear-gradient(309.69deg, #636583 17.05%, #272835 69.2%);
    outline: none;
    border-radius: 4px;
    border: none;
    font-size: 13px;
    font-weight: 700;
    color: #fff;
    white-space: nowrap;
    text-transform: uppercase;
    cursor: pointer;
    :hover {
      background-image: linear-gradient(309.69deg, #707394 17.05%, #0F0F15 69.2%);
    }
    :active {
      background-image: linear-gradient(309.69deg, #5A5D85 17.05%, #090911 69.2%);
    }
  }
`
