import { always, cond, merge, pipe, T } from 'lodash/fp'
import { statuses } from '../utils'

const fillVideoVoiceOverLessonOrder = merge({
  isShowWaiting: false,
  isShowCompleted: false,
  isShowCanceled: false,
})

export const getVideoVoiceOverLessonOrderConfig = pipe(
  cond([
    [statuses.isOrderBooked, always({ isShowWaiting: true })],
    [statuses.isOrderCompletedOrClosed, always({ isShowCompleted: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [T, always({})],
  ]),
  fillVideoVoiceOverLessonOrder
)
