import React from 'react'
import { get } from 'lodash'

import { Trans } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'


const CalendarLockedSlot = (eventInfo) => {
  const event = get(eventInfo, 'event', {})
  const timeText = get(eventInfo, 'timeText', '')
  const extendedProps = get(event, 'extendedProps', {})

  const { title } = event

  const { offerType } = extendedProps
  const isAficionadoChoose = offerType === 'AFICIONADO'

  return (
    <div className="calendar-slot">
      { isAficionadoChoose && (
        <div className="calendar-slot__info">
          <div className="calendar-slot__info__title">{title || <Trans tKey="calendar.slot.default-title" />}</div>
          <div className="calendar-slot__info__desc">{timeText}</div>
        </div>
      )}

      {!(isAficionadoChoose || event.display === 'background') && (
        <div className='calendar-slot__locked'>
          <Icon.Lock />
        </div>
      )}
    </div>
  )
}

export default CalendarLockedSlot
