import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetManageOfferingsFilters } from 'store/hooks/globalState/useManageOfferings'
import Button from '../../../components/Button';

const SingleButton = function SingleButton ({
  createLink, editLink, isEdit, isLoading
}) {
  const t = useGetTranslate()
  if (isEdit) {
    return (
      <div className='buttons-wrapper single'>
        <Button
          classBtn='button'
          isLink
          path={editLink}
          primary
          text={t('offerings.new.buttons.edit')}
          typeButton="submit"
          disabled={isLoading}
          fontSize='0.875'
          paddingVertical='10'
          paddingHorizontal='10'
        />
      </div>
    );
  }
  return (
    <div className='buttons-wrapper single'>
      <Button
        classBtn='button'
        isLink
        path={createLink}
        text={t('offerings.new.buttons.create')}
        typeButton="submit"
        disabled={isLoading}
        fontSize='0.875'
        paddingVertical='10'
        paddingHorizontal='10'
      />
    </div>
  );
};

const MultiButtons = function MultiButtons ({
  createLink, editLink, isEdit, isLoading, field
}) {
  const t = useGetTranslate()
  const setType = useSetManageOfferingsFilters((prev) => ({ ...prev, typeEq: field }), [field])

  if (isEdit) {
    return (
      <div className='buttons-wrapper multi'>
        <Button
          classBtn='button'
          isLink
          path={createLink}
          text={t('offerings.new.buttons.create')}
          typeButton="submit"
          disabled={isLoading}
          fontSize='0.875'
          paddingVertical='10'
          paddingHorizontal='10'
        />
        <Button
          classBtn='button'
          isLink
          primary
          handleOnClick={setType}
          path={editLink}
          text={t('offerings.new.buttons.manage')}
          typeButton="submit"
          disabled={isLoading}
          fontSize='0.875'
          paddingVertical='10'
          paddingHorizontal='10'
        />
      </div>
    );
  }

  return (
    <div className='buttons-wrapper single'>
      <Button
        classBtn='button'
        isLink
        path={createLink}
        text={t('offerings.new.buttons.create')}
        typeButton="submit"
        disabled={isLoading}
        fontSize='0.875'
        paddingVertical='10'
        paddingHorizontal='10'
      />
    </div>
  );
};

export default function ButtonsSection ({
  createLink, editLink, isEdit, isMulti, isLoading, field
}) {
  if (isMulti) {
    return (
      <MultiButtons
        isEdit={isEdit}
        createLink={createLink}
        editLink={editLink}
        isLoading={isLoading}
        field={field}
      />
    );
  }

  return (
    <SingleButton
      isEdit={isEdit}
      createLink={createLink}
      editLink={editLink}
      isLoading={isLoading}
    />
  );
}

ButtonsSection.propTypes = {
  createLink: PropTypes.string,
  editLink: PropTypes.string,
  isEdit: PropTypes.bool,
  isMulti: PropTypes.bool,
  isLoading: PropTypes.bool,
  field: PropTypes.string
};
