import React from 'react'
import Dropdown from '../../Common/Dropdown'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate, useSetUserLang, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useGetMe } from 'requests/auth'
import { useCurrencyCode, useSetCurrency } from 'store/hooks/globalState/useCurrency'
import { useUser } from 'store/hooks/globalState/useUser'
import { cookies } from 'utils/services/cookies'

import CustomSelect from '_legacy/common/CustomSelect'
import CustomButton from '_legacy/components/Button/CustomButton'

import { updateUserSettings } from 'api/appApi/auth'
import { langOptions } from 'utils/language/language'

const LanguageHeader = ({ themeType }) => {
  const t = useGetTranslate()
  const getMe = useGetMe()
  const isLoggedIn = useUser(Boolean)

  // lang
  const userLang = useUserLang()
  const [localLang, setLocalLang] = React.useState(userLang)
  const setUserLang = useSetUserLang((prev, next) => next || localLang, [localLang])
  const handleLocalLang = React.useCallback(value => setLocalLang(get(value, 'value')), [setLocalLang])

  // currency
  const currency = useCurrencyCode()
  const [localCurrency, setLocalCurrency] = React.useState(currency)
  const setCurrency = useSetCurrency((prev, next) => ({ ...prev, currentCurrencyCode: next || localCurrency }), [localCurrency])

  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false)
  const handleDropdownState = value => {
    setLocalLang(userLang)
    setLocalCurrency(currency)
    setIsDropdownOpen(value)
  }

  const submitHandler = React.useCallback(() => {
    setUserLang()

    if (isLoggedIn) {
      updateUserSettings({ data: { language: localLang } })
        .then(() => getMe())
        .catch(error => console.warn(error))
    }

    const preferences = cookies.preferences
    if (preferences) {
      cookies.language = localLang
      cookies.currency = localCurrency
    }

    setIsDropdownOpen(false)
  }, [setUserLang, localLang, getMe, setIsDropdownOpen])

  React.useEffect(() => {
    const initialLang = cookies.language
    const initialCurrency = cookies.currency

    if (initialLang) {
      setLocalLang(initialLang)
      setUserLang(initialLang)
    }

    if (initialCurrency) {
      setLocalCurrency(initialCurrency)
      setCurrency(initialCurrency)
    }
  }, [])

  return (
    <StyledWrapper className="language_selector">
      <Dropdown
        themeType={themeType}
        label={userLang}
        isDropdownOpen={isDropdownOpen}
        setIsDropdownOpen={handleDropdownState}
      >
        <div className="menu-wrapper">
          <CustomSelect
            label="main-menu.language"
            options={langOptions}
            value={localLang}
            changeHandler={handleLocalLang}
          />
          <CustomButton className="save-button" clickHandler={submitHandler}>
            {t('main-menu.save')}
          </CustomButton>
        </div>
      </Dropdown>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  && {
    .menu-wrapper {
      left: auto;
      right: 0;
      transform: translate(0, 10px);
      max-width: 220px;
    }
  }

  .currency {
    margin-top: 16px;
  }

  .save-button {
    margin-top: 16px;
    width: 100%;
  }
`

export default LanguageHeader
