import styled from 'styled-components'
import PropTypes from 'prop-types'

import CloseButtonCommon from '_legacy/components/Button/CloseButton'

const CloseButton = styled(CloseButtonCommon)`
  top: -13px;
  right: -13px;
  position: absolute;
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
  }

  @media screen and (max-width: 600px) {
    top: -9px;
    right: -9px;
    height: 18px;
    width: 18px;

    &:after,
    &:before {
      height: 10px;
      width: 2px;
    }
  }
`

CloseButton.propTypes = {
  onClick: PropTypes.func,
}

export default CloseButton
