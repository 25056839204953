import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'
import BoldButton from '../BoldButton'
import DashButton from '_legacy/components/Button/DashButton'

const CalendarButton = ({ onClick, time, theme = 'dark' }) => {
  const t = useGetTranslate()
  const classes = cx('calendar-button', {
    'calendar-button--dark': theme === 'dark',
    'calendar-button--light': theme === 'light',
  })

  return (
    <div className={classes}>
      {time ? (
        <React.Fragment>
          <p className="calendar-button__text">
            <Icon.ViewCalendar className="calendar-button__icon" />
            {time}
          </p>
          <DashButton
            onClick={onClick}
            className="calendar-button__dash-btn"
            text={t('video-chat.public.button.change-date-time')}
          />
        </React.Fragment>
      ) : (
        <BoldButton className="calendar-button__bold-btn" onClick={onClick}>
          <Icon.ViewCalendar className="calendar-button__icon" />
          {t('calendar.button.pick-date-time')}
        </BoldButton>
      )}
    </div>
  )
}

CalendarButton.propTypes = {
  onClick: PropTypes.func,
  time: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark']),
}

export default CalendarButton
