import React from 'react';
import { FileType } from 'shared';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common'
import { Radio } from '_legacy/common/Radio'
import Button from '_legacy/components/Button';
import MultiSelectLibrary from '_legacy/components/Library/MultiSelectLibrary';
import PriceInput from '_legacy/common/Input/PriceInput';
import CharitiesSelect from '_legacy/components/Form/Selects/basic/CharitiesSelect';
import MultiSelectBackdrop from '_legacy/components/Library/configs/MutliSelect/MultiSelectBackdrop';
import OfferingsLinkToManagerPromoCodes from '_legacy/components/OfferingsLinkToManagerPromoCodes';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { multiSelectLibraryValidate } from 'utils/services/validators/library';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import './index.scss'

const { PHOTO, VIDEO } = FileType

const VideoMessageForm = ({ onSubmit, isLoading }) => {
  const t = useGetTranslate();
  const accept = [PHOTO, VIDEO];
  const { handleSubmit } = useFormContext();

  return (
    <form className='default video-message-offering-form' onSubmit={handleSubmit(onSubmit)}>
      <Row
        nameBlock={t('video-message.form.price.label')}
        subNameBlock={t('video-message.form.price.tip')}
        component={
          <PriceInput
            name='price'
            placeholder='video-message.form.price.placeholder'
            requiredMessage='video-message.form.price.errors.required'
            isRequired
          />
        }
      />
      <Row
        nameBlock={t('video-message.form.charities.label')}
        component={
          <>
            <CharitiesSelect name={'whichCharities'} showSeparator={false} />
            <span className='charity-tip'><Trans tKey='shared.charities.text' /></span>
          </>
        }
      />
      <Row
        nameBlock={t('video-message.form.enable.label')}
        className='enable-in-you-services'
        component={
          <div className='enable-in-you-services-radio'>
            <Radio
              value={true}
              name='enableInYourServices'
              label={t('shared.words.label.yes')}
              defaultChecked
            />
            <Radio
              value={false}
              name='enableInYourServices'
              label={t('shared.words.label.no')}
            />
          </div>
        }
      />
      <Row
        nameBlock={t('video-message.form.media.label')}
        subNameBlock={t('video-chat.form.multi-media.tip')}
        component={
          <MultiSelectLibrary
            name='media'
            dropZoneProps={{ accept }}
            customBackdrop={MultiSelectBackdrop}
            rules={{ validate: multiSelectLibraryValidate }}
          />
        }
      />
      <OfferingsLinkToManagerPromoCodes />
      <Row
        component={
          <Button
            text={t('video-message.form.button.label')}
            typeButton="submit"
            paddingVertical="13"
            paddingHorizontal="38"
            className='video-message-offering-form__submit'
            disabled={isLoading}
          />
        }
      />
      <MissedData />
    </form>
  );
};

VideoMessageForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}

export default VideoMessageForm;
