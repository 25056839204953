import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { types } from 'shared';
import Location from '_legacy/components/RegisterForms/Talent/components/Location'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { PRECISION_TYPES } from '_legacy/components/Form/Selects/basic/LocationsContants';
import UploaderFiles from '_legacy/common/UploaderFiles';
import Input from '_legacy/components/RegisterForms/shared/components/Input';
import Nickname from '_legacy/components/RegisterForms/shared/components/Nickname';
import HeadshotImage from '_legacy/components/RegisterForms/shared/components/HeadshotImage';
import DisplayNameType from '_legacy/components/RegisterForms/Talent/components/DisplayNameType';
import HalfBodyImage from '_legacy/components/RegisterForms/Talent/components/HalfBodyImage';
import Languages from '_legacy/components/RegisterForms/Talent/components/Languages';
import Nationality from '_legacy/components/RegisterForms/Talent/components/Nationality';
import Charities from '_legacy/components/RegisterForms/Talent/components/Charities';
import Biographies from '_legacy/components/RegisterForms/Talent/components/Biographies';
import Categories from '../Talent/components/Categories';
import OwnWords from '../Talent/components/OwnWords';
import FullBodyWithPrevious from '../Talent/components/FullBodyImage/FullBodyWithPrevious';
import PromoCode from '_legacy/components/RegisterForms/shared/PromoCode';
import { Row } from '_legacy/common';
import TipButton from '_legacy/components/Form/LabelTips/TipButton';
import PromoCodeInfoModal from '_legacy/components/Modal/PromoCodeInfoModal';

const { FILE, PHOTO } = types.file.Types;

const TalentForm = React.memo(function TalentForm({ viewerTypes }) {
  const t = useGetTranslate();
  const { isUserWithTalent, isAgentOrManagerWithTalent } = viewerTypes;

  return (
    <>
      {isUserWithTalent && (
        <StyledTitle>
          <div className='line line--left' />
          <h4 className='title'>{t('edit-profile.form.talent-title.title')}</h4>
          <div className='line line--right' />
        </StyledTitle>
      )}
      <Input
        label={t('register.form.talent-first-name.label')}
        name='talent.firstName'
        placeholder={t('register.form.talent-first-name.placeholder')}
        validation={{ required: 'register.form.talent-first-name.errors.required' }}
        isRequired
        isDisabled
      />

      <Input
        label={t('register.form.talent-last-name.label')}
        name='talent.lastName'
        placeholder={t('register.form.talent-last-name.placeholder')}
        validation={{ required: 'register.form.talent-last-name.errors.required' }}
        isRequired
        isDisabled
      />

      {isAgentOrManagerWithTalent && <Nickname name='talent.nickname' isDisabled />}

      <DisplayNameType name='talent.displayNameType' />

      <Categories baseName='talent' />

      <FullBodyWithPrevious namePrevious='talent.fullBodyImages' name='talent.fullBodyImage' isRequired={false} />

      {isAgentOrManagerWithTalent && <HeadshotImage name='talent.profileImage' />}

      <HalfBodyImage name='talent.halfBodyImage' isRequired={false} />

      <Location name='talent.location' isRequired nameBlock={t('register.form.location-city.label')} precision={PRECISION_TYPES.CITY} />

      <Languages name='talent.languages' />

      <Nationality name='talent.nationality' />

      <Charities name='talent.charities' />

      <OwnWords name='talent.ownWords' />

      <Biographies name='talent.biographies' />

      <Row
        nameBlock={t('register.promocode.label')}
        component={<PromoCode name='talent.promoCode' />}
        tip={<TipButton modalComponent={PromoCodeInfoModal} />}
      />

      <UploaderFiles
        name='talent.licenses'
        label={t('register.form.poa.label.talent')}
        subLabel={t('register.form.poa.desc.talent')}
        titleHint={t('register.upload.hint.file.title')}
        typesFiles={[FILE, PHOTO]}
      />
    </>
  );
});

const StyledTitle = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 70px;
  margin-bottom: 50px;
  @media screen and (max-width: 768px) {
    display: none;
  }
  .title {
    font-family: 'Playfair Display', sans-serif;
    font-weight: 900;
    font-size: 28px;
    line-height: 1;
    color: #272835;
    margin: 0 45px 0 45px !important;
  }
  .line {
    flex: 1;
    height: 1px;
    &--left {
      background-image: linear-gradient(
        270deg,
        #272835 -21.16%,
        rgba(39, 40, 53, 0) 83.53%
      );
    }
    &--right {
      background-image: linear-gradient(
        90deg,
        #272835 -21.16%,
        rgba(39, 40, 53, 0) 83.53%
      );
    }
  }
`;

TalentForm.propTypes = {
  viewerTypes: PropTypes.object,
};

export default TalentForm;
