import React from 'react'
// import PropTypes from 'prop-types'

import Input from '_legacy/components/RegisterForms/shared/components/Input'
import Taxes from '_legacy/components/RegisterForms/shared/components/Taxes'
import UploaderFiles from '_legacy/common/UploaderFiles'

import { Types } from 'shared/dist/src/types/file';

import { isLicensesIsRequiredForEdit, shouldShowTaxMessageForEdit } from '_legacy/components/RegisterForms/User/utils/validators'
import TaxLegalDocumentationInfoModal from '_legacy/components/Modal/TaxLegalDocumentationInfoModal'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import { useFormContext } from 'react-hook-form'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

function TaxesAndSecuritySocialForm() {
  const t = useGetTranslate()
  const methods = useFormContext()

  const formState = methods.watch()
  React.useEffect(() => {
    if (methods.formState.isSubmitted) {
      methods.trigger('user.licenses')
    }
  }, [formState, methods])

  return (
    <>
      <Input
        isRequired
        name='user.socialSecurityOrTIN'
        placeholder={t('register-user.form.social-security-or-tin.placeholder')}
        label={t('register-user.form.social-security-or-tin.label')}
      />
      <Taxes name='user.taxpayerStatus' showTaxMessage={shouldShowTaxMessageForEdit(formState)} />
      <UploaderFiles
        name='user.licenses'
        isRequired={isLicensesIsRequiredForEdit(formState)}
        label={t('register-user.form.w9.label')}
        titleHint={t('register-user.form.w9.placeholder.desc')}
        typesFiles={[Types.FILE, Types.PHOTO, Types.VIDEO]}
        tip={<TipButton modalComponent={TaxLegalDocumentationInfoModal} />}
      />
    </>
  )
}

TaxesAndSecuritySocialForm.propTypes = {}

export default TaxesAndSecuritySocialForm
