import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from './AsyncSelectComponent';
import { loadCitiesOptions } from 'utils/services/loaders/locations';

const CitiesSelect = React.memo(function CitiesSelect(props) {
  const citiesLoader = async (...props) => {
    if (props[0].trim().length === 0) return { options: [], hasMore: false };
    return await loadCitiesOptions(...props);
  };

  return (
    <AsyncSelect
      isShowArrow={false}
      openMenuOnClick={false}
      loadOptions={citiesLoader}
      {...props}
    />
  );
});

CitiesSelect.propTypes = {
  placeholder: PropTypes.string,
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  error: PropTypes.object,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
};

export default CitiesSelect;
