import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const ArrayListItem = ({ className = '', onRemove, children, showRemoveButton = true, showSeparator }) => (
  <StyledArrayListItem className={className} showSeparator={showSeparator}>
    {children}
    {showRemoveButton && <RemoveButton type='button' className='remove-button' onClick={onRemove} />}
  </StyledArrayListItem>
);

const StyledArrayListItem = styled.li`
  position: relative;
  ${({ showSeparator }) => showSeparator && css`
    padding-bottom: 24px;
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
    &:before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-image: linear-gradient(to right, rgba(39, 40, 53, 0), white, rgba(39, 40, 53, 0));
    }
  `}
`;

const RemoveButton = styled.button`
  position: absolute;
  right: -52px;
  top: 50%;
  transform: translateY(-50%);

  padding: 0;
  height: 26px;
  width: 26px;

  outline: none;
  cursor: pointer;
  background: transparent;
  border: none;

  border-radius: 50%;

  &::after,
  &::before {
    content: ' ';
    height: 16px;
    width: 3px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  background: linear-gradient(360deg, #272835 0%, #636583 97.83%) !important;

  &::after,
  &::before {
    background: white;
  }
`;

ArrayListItem.propTypes = {
  className: PropTypes.string,
  onRemove: PropTypes.func,
  children: PropTypes.element,
  showRemoveButton: PropTypes.bool,
  showSeparator: PropTypes.bool,
};

export default ArrayListItem;
