import React from 'react';
import PropTypes from 'prop-types';
import SingleSlideList from './SingleSlideList';
import MultipleSlideList from './MultipleSlideList.';
import TwiceSlideList from './TwiceSlidesList';

const SlideMachineList = ({
  size,
  children: slides,
  currentSlideIndex,
  isMirroredInside,
}) => {
  if (slides.length === 1) {
    return (
      <SingleSlideList
        size={size}
        slide={slides[0]}
        isMirroredInside={isMirroredInside}
      />
    );
  }

  if (slides.length === 2) {
    return (
      <TwiceSlideList
        size={size}
        slides={slides}
        isMirroredInside={isMirroredInside}
        currentSlideIndex={currentSlideIndex}
      />
    );
  }

  return (
    <MultipleSlideList
      size={size}
      slides={slides}
      isMirroredInside={isMirroredInside}
      currentSlideIndex={currentSlideIndex}
    />
  );
};

SlideMachineList.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentSlideIndex: PropTypes.number,
  isMirroredInside: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default SlideMachineList;
