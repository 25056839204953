import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import Checkbox from '_legacy/components/Form/Taboos/Checkbox';

const NotificationCheckbox = ({ content }) => {
  const { register } = useFormContext();
  return (
    <div className='notifications-cell notifications-cell--center'>
      <Checkbox
        name={content.name}
        register={register}
        disabled={content.disabled}
        defaultChecked={content.value}
      />
    </div>
  );
};

NotificationCheckbox.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
  }),
};

export default NotificationCheckbox;
