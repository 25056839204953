import React from 'react';
import debounce from 'lodash/debounce';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';

import PhoneInput from '_legacy/common/Input/PhoneInput';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { checkPhone as checkPhoneApi } from 'api/appApi/utils';
import { sharedErrors } from './phoneErrorMessages';
import { MAX_ATTEMPTS } from '_legacy/components/RegisterForms/shared/components/PhoneConfirm/constants';

const PhoneWithAsyncValidation = ({ isEditable, disabled, fields }) => {
  const name = get(fields, 'phone');
  const oldName = get(fields, 'oldPhone');
  const t = useGetTranslate();
  const { clearErrors, setError, control, formState, getValues, setValue } = useFormContext();
  const phone = useWatch({ name, control, defaultValue: '' });
  const oldPhone = useWatch({ name: oldName, control, defaultValue: '' });

  const isDirty = get(formState.dirtyFields, name, false);
  const [localUniqError, setLocalUniqError] = React.useState();

  const checkPhone = async phone => {
    try {
      await checkPhoneApi({ phone });
      clearErrors(name);
      setLocalUniqError(null);
      if(oldPhone != phone)
        setValue(fields.remainingAttempts, MAX_ATTEMPTS)
    } catch (e) {
      const message = t(sharedErrors[e.name]);
      setError(name, { message });
      setLocalUniqError(message);

      if (getValues(name) === getValues(fields.oldPhone)) {
        clearErrors(name);
        setLocalUniqError(() => null);
      }
    }
  };

  const debounceCheck = React.useCallback(debounce(checkPhone, 800), []);

  React.useEffect(() => {
    if (isDirty && phone && phone !== oldPhone) {
      debounceCheck(phone);
      setValue(fields.isPhoneSand, false)
    }
  }, [phone, isDirty, isEditable]);

  return (
    <PhoneInput
      validation={{ validate: () => localUniqError }}
      disabled={disabled}
      name={name}
    />
  );
};

PhoneWithAsyncValidation.propTypes = {
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  isEditable: PropTypes.bool,
  fields: PropTypes.object,
};

export default PhoneWithAsyncValidation;
