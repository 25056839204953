import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Input } from '_legacy/common';
import ArrayListItem from '_legacy/common/Template/ArrayListItem';
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const SocialMediaListItem = ({
  baseName,
  defaultValue,
  remove,
  options,
  showRemoveButton,
}) => {
  const t = useGetTranslate();

  return (
    <SocialMediaLinksListItem onRemove={remove} showRemoveButton={showRemoveButton}>
      <SocialItemWrapper>
        <SelectComponent
          options={options}
          name={`${baseName}.social`}
          defaultValue={defaultValue?.social}
          placeholder={t('edit-profile.form.social-media-links.type.placeholder')}
        />
        <Input
          autoComplete='off'
          name={`${baseName}.link`}
          placeholder={t('edit-profile.form.social-media-links.link.placeholder')}
          defaultValue={defaultValue?.link}
        />
      </SocialItemWrapper>
    </SocialMediaLinksListItem>
  );
};

SocialMediaListItem.propTypes = {
  showRemoveButton: PropTypes.bool,
  baseName: PropTypes.string,
  defaultValue: PropTypes.object,
  remove: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
};

const SocialItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 34px;
  align-items: center;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    gap: 16px;
    margin-bottom: 20px;
  }
`

const SocialMediaLinksListItem = styled(ArrayListItem)`
  
`;

export default SocialMediaListItem;
