import React from 'react';

import offeringsConfig from './config';

import { appApi } from 'api';
import { useFetch } from 'store/hooks/useFetch';
import { useTalentId } from 'store/hooks/globalState/useUser';

import Plate from './Plate';

export default function PlatesList () {
  const talentId = useTalentId()
  const [{ data, isLoading }, getMyOfferings] = useFetch(appApi.offers.offerings.getMyOfferings);

  React.useEffect(() => {
    getMyOfferings(talentId);
  }, [talentId]);

  const parsedOfferingsConfig = React.useMemo(() => {
    return offeringsConfig.map((plate) => {
      const { key } = plate;
      const plateData = data?.[key];
      return (
        <Plate
          key={key}
          plate={plate}
          data={plateData}
          isLoading={isLoading}
        />
      );
    });
  }, [offeringsConfig, data, isLoading]);

  return (
    <div className='offerings-wrapper'>
      {parsedOfferingsConfig}
    </div>
  );
}
