import get from 'lodash/get'

export const parseFollowers = params => {
  const { followers, ...rest } = params
  const followersCountFrom = get(followers, 'from')
  const followersCountTo = get(followers, 'to')

  return {
    ...rest,
    followersCountFrom,
    followersCountTo,
  }
}
