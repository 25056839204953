import { addSeconds, subSeconds } from 'date-fns'

export const applyDriveTimeToAllSlots = (slots, { after = 0, before = 0 } = {}) => {
  return slots.map(slot => {
    return {
      ...slot,
      start: new Date(subSeconds(new Date(slot.start), after)),
      end: new Date(addSeconds(new Date(slot.end), before)),
    }
  })
}
