import React from 'react'

import Favorites from '_legacy/components/Favorites'
import EmptyTalentList from '_legacy/components/Search/List/EmptyTalentList'

import {
  useFavoritesTalents,
  useFavoritesTalentsFilters,
  useClearTalentFilters,
  useSetFavoritesTalentsFilters,
} from 'store/hooks/globalState/useFavorites'
import { useFetchFavoriteTalents } from 'requests/favorites.js'

const FavoritesTalents = () => {
  return (
    <Favorites
      getListFn={useFavoritesTalents}
      clearFn={useClearTalentFilters}
      fetchListFn={useFetchFavoriteTalents}
      getFiltersFn={useFavoritesTalentsFilters}
      EmptyListComponent={EmptyTalentList}
      setFiltersFn={useSetFavoritesTalentsFilters}
    />
  )
}

export default FavoritesTalents
