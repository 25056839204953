import React from 'react'

import normalizeParams from 'utils/normalizeParams'
import {
  fetchFaqList as fetchFaqListApi
} from './api'
import {
  useSetFaqList,
  useSetFaqListFilters,
} from './store'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchFaqList() {
  const setFaqList = useSetFaqList((prev, next) => next)
  const setFaqListFilters = useSetFaqListFilters((prev, next) => ({ ...prev, ...next }))

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ ...query }) => {
    const params = normalizeParams(query)

    fetchFaqListApi({
      query: {
        ...params,
      }
    })
      .then(({ faq, pageInfo }) => {
        setFaqList(faq)
        setFaqListFilters(pageInfo)
      })
      .catch(error => {
        console.warn(error)
        handleResponseError(error)
      })
  }, [setFaqListFilters])
}
