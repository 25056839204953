import React from 'react';
import PropTypes from 'prop-types';

import Slider from '../../../Slider';
import Slide from '../../../Slide';

import Actions from './Actions';
import Empty from './Empty';

const CommercialEngagements = ({ covers = [], firstName, className = '' }) => {
  const slides = covers.map(cover => (
    <Slide.DesktopSlide
      id={cover.id}
      key={cover.id}
      file={cover}
      // gradient={'linear-gradient(to bottom, rgba(255, 0, 255, 0) 0%, rgba(255, 255, 255, 0.78) 100%)'}
    />
  ));

  return (
    <Slider
      size="big" // change to 'small' if you want small slider
      slides={slides}
      empty={<Empty firstName={firstName} />}
      // withGradientOverhead={true}
      isMirroredInside={false}
      Actions={Actions}
      className={className}
    />
  );
};

CommercialEngagements.propTypes = {
  covers: PropTypes.array.isRequired,
  firstName: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default CommercialEngagements
