import React from 'react'
import PropTypes from 'prop-types'
import ReactSelect from 'react-select'
import styled from 'styled-components'
import get from 'lodash/get'
import prop from 'lodash/fp/prop'
import isEqual from 'lodash/isEqual'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import ClearIndicator from '../../common/ClearIndicator'
import DropdownIndicator from '../../common/DropdownIndicator'
import { StyledOption } from '../../../../../CreateExperience/components/TimeSelectWithTimeZone/common'

const CustomSelect = ({ placeholder, className, options, field, getFn, setFn, disabled }) => {
  const t = useGetTranslate()
  const value = getFn(current => get(current, field, ''))
  const handleChange = setFn((prev, values) => ({ ...prev, [field]: get(values, 'value') }))

  const translatedOptions = options.map(option => ({ ...option, label: t(option.label) }))
  const selectedOption = translatedOptions.find(option => isEqual(option.value, value))

  const disabledValues = React.useMemo(
    () => options.map(option => option.disabled && option.value).filter(Boolean),
    [options]
  )

  React.useEffect(() => {
    if (disabled && value) {
      handleChange(undefined)
      return
    }

    if (value && disabledValues.includes(value)) {
      handleChange(undefined)
    }
  }, [disabledValues, disabled, value])

  return (
    <StyledWrapper className={`react-select-container ${className}`}>
      <ReactSelect
        isClearable={true}
        onChange={handleChange}
        isDisabled={disabled}
        value={selectedOption || ''}
        options={translatedOptions}
        placeholder={t(placeholder)}
        classNamePrefix="react-select"
        isOptionDisabled={option => option.disabled}
        components={{
          Option: StyledOption,
          IndicatorSeparator: () => null,
          ClearIndicator: ClearIndicator,
          DropdownIndicator: DropdownIndicator,
        }}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-right: 8px;

  .react-select__control {
    border: 2px solid rgb(233, 234, 240);
    height: 48px;
    background-color: white;
  }

  .react-select__menu {
    top: 50px;
  }

  .react-select__control--is-disabled {
    background-color: ${prop('theme.colors.ultraLightBlue')};
    cursor: not-allowed;
  }
`

CustomSelect.propTypes = {
  placeholder: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  field: PropTypes.string,
  getFn: PropTypes.func,
  setFn: PropTypes.func,
  disabled: PropTypes.bool,
}

CustomSelect.defaultProps = {
  placeholder: '',
  className: '',
  options: [],
}

export default CustomSelect
