import React from 'react';
import { lt, allPass, isNumber } from 'lodash/fp'
import Price from '_legacy/components/Price'
import Rating from '_legacy/components/Rating'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const shouldShowTotalCharityFee = allPass([lt(0), isNumber])

// TODO: prop type validation
const RatingAndMoney = ({ rating, totalCharityFee }) => {
  const t = useGetTranslate()

  return (
    <div className="rating-and-money">
      <div className="rating-and-money__header">
        <div className="text">{t('talent-public-view.rating-and-money.ranking')}</div>
        {shouldShowTotalCharityFee(totalCharityFee) && (
          <div className="text text--right">{t('talent-public-view.rating-and-money.proceeds_to_charity')}</div>
        )}
      </div>
      <div className="rating-and-money__content">
        <div className="rating-and-money__content-inner">
          <Rating rating={rating} />
        </div>
        {shouldShowTotalCharityFee(totalCharityFee) && (
          <Price price={totalCharityFee} withCurrency />
        )}
      </div>
    </div>
  )
}

export default RatingAndMoney;
