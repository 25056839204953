import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import CustomPicture from '_legacy/common/CustomPicture'
import PictureWrapper from '../../components/Picture/PictureWrapper';
import PictureText from '../common/PictureText';

const PictureWithText = ({ file }) => {
  const t = useGetTranslate();
  return (
    <PictureWithTextWrapper>
      <CustomPicture file={file} />
      <PictureText>{t('shared.words.change')}</PictureText>
    </PictureWithTextWrapper>
  )
}


const PictureWithTextWrapper = styled(PictureWrapper)`
  &:hover {
    ${PictureText} {
      opacity: 1;
    }
  }
`;

PictureWithText.propTypes = {
  file: PropTypes.object,
}

export default PictureWithText
