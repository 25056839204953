import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { errorNames } from 'shared';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import Input from '_legacy/components/RegisterForms/shared/components/Input';

import { verifyPromoCodes as verifyPromoCodesApi } from 'api/appApi/promoCodes';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'

const { PROMO_CODE_IS_NOT_UNIQUE } = errorNames.promoCodes

const errors = {
  PROMO_CODE_IS_TOO_SHORT: 'promo-codes.form.code.error.short',
  PROMO_CODE_IS_TOO_LONG: 'promo-codes.form.code.error.long',
  [PROMO_CODE_IS_NOT_UNIQUE]: 'promo-codes.form.code.error.not-unique',
  VALIDATION_FAILED: 'promo-codes.form.code.error.not-valid',
};

const Code = ({ name = 'code' ,...props }) => {
  const t = useGetTranslate();
  const { clearErrors, setError, control, formState } = useFormContext();
  const code = useWatch({ name, control, defaultValue: '' });
  const isDirty = get(formState.dirtyFields, name, false);
  const [localUniqError, setLocalUniqError] = React.useState();
  const talentId = useUser(getTalentId)

  const checkCode = async code => {
    if (code.length < 3) {
      setError(name, { message: t(get(errors,'PROMO_CODE_IS_TOO_SHORT', 'VALIDATION_FAILED')) });
      return;
    }

    if (code.length > 15) {
      setError(name, { message: t(get(errors,'PROMO_CODE_IS_TOO_LONG', 'VALIDATION_FAILED')) });
      return;
    }

    try {
      await verifyPromoCodesApi(code.toUpperCase(), talentId);
      clearErrors(name);
      setLocalUniqError(null);
    } catch (error) {
      const message = t(get(errors, error.name));
      setError(name, { message });
      setLocalUniqError(message);
    }
  };

  const debounceCheck = React.useCallback(debounce(checkCode, 1000), []);

  React.useEffect(() => {
    if (isDirty) {
      debounceCheck(code);
    }
  }, [code, isDirty, talentId]);

  return (
    <StyledInput
      isRequired
      name={name}
      validation={{ validate: () => localUniqError }}
      label={t('promo-codes.form.code.label')}
      placeholder={t('promo-codes.form.code.placeholder')}
      requiredMessage={t('promo-codes.form.code.error.required')}
      {...props}
    />
  );
};

const StyledInput = styled(Input)`
  .input {
    text-transform: uppercase;
    &::placeholder {
      text-transform: initial;
    }
  }
`

Code.propTypes = {
  name: PropTypes.string
};

export default Code;
