import { _get, _post } from 'api/utils';

export const getCommercialEngagement = ({ talentNickname }) => _get(
  `/talents/public-view/${talentNickname}/commercial-engagement`
);

export const requestCommercialEngagement = ({ talentId, data }) => _post(
  `/talents/${talentId}/commercial-engagements`,
  { body: data }
);

export const requestCommercialEngagementVideoMessage = ({ offerId, data }) => _post(
  `/offers/commercial-video-messages/${offerId}/book`,
  { body: data, needAuthorization: true }
);

