import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import styled from 'styled-components';
import { ReactComponent as CommercialEngagementIcon } from 'assets/commercial-engagement-icon.svg';
import BasicMobileOverlay from '_legacy/components/TalentPublicView/DesktopSlider/Overlay/BasicMobileOverlay';
import './overlay.scss'
import FaBan from 'mdi-react/BanIcon'
import BookNowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/BookNowButton';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
const Overlay = ({ date, name, isCommercial, path, isPromo, hideControl, isVideo }) => {
  const t = useGetTranslate();
  const classes = cx('tbv-slider-slide-overlay-live-experience')
  // 'tbv-slider-slide-overlay-live-experience--video'

  return (
    <BasicMobileOverlay withGradientOverhead>
      <div className={classes}>
        <h3 className="tbv-slider-slide-overlay-live-experience-name">
          {name}
        </h3>
        <h4 className="tbv-slider-slide-overlay-live-experience-date">
          {date}
        </h4>
        {isPromo && <h3 className="tbv-slider-slide-overlay-live-experience-name">
          {t('shared.offers.experience')}
        </h3>}
      </div>
      <div className='video-overlay'>
        {isCommercial && (
          <div className='move-up-right-corner'>
            <div className='icon'><CommercialEngagementIcon /></div>
            <h4 className="text-commercial">
              {t('shared.offers.commercial-offering')}
            </h4>
          </div>
        )}
        {!isCommercial && (
          <div className='move-up-right-corner'>
            <div className='relative'>
              <div className='icon-buildings-with-ban'><CommercialEngagementIcon /></div>
              <div className='icon-ban'><FaBan color='#626473'/></div>
            </div>
            <h4 className="text-fan">
              {t('shared.offers.fans-only')}
            </h4>
          </div>
        )}
      </div>
      <div className='overlay-button'>
        {!hideControl && !isPromo && <BookNowButton isLink={true} path={path} />}
        {!hideControl && isPromo && <StyledPromoVideo>{isVideo === "VIDEO" ? "Promo Video" : "Promo Image"}</StyledPromoVideo>}
      </div>
    </BasicMobileOverlay>
  );
};

Overlay.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isVideo: PropTypes.bool
};
const StyledPromoVideo = styled.h3`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  margin-left: 10px;
  font-feature-settings: 'case' on, 'liga' off;
  color: #ffffff;
`
export default Overlay;
