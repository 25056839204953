import { api, OrderStatus } from 'shared'

import Icon from 'assets/icons/Icon'

import { ReactComponent as LiveVideoChats } from 'assets/offerings-icons/live-video-chats.svg'
import { ReactComponent as InPersonExperience } from 'assets/offerings-icons/in-person-experience.svg'
import { ReactComponent as VideoMessage } from 'assets/offerings-icons/video-message.svg'
import { ReactComponent as Lessons } from 'assets/offerings-icons/lessons.svg'
import { ReactComponent as VideoMessageForCommercialUse } from 'assets/offerings-icons/video-message-for-commercial-use.svg'
import { ReactComponent as ReadyMadeLesson } from 'assets/offerings-icons/ready-made-lesson.svg'
import { ReactComponent as VirtualEvent } from 'assets/offerings-icons/virtual-event.svg'

import { filters } from '_legacy/components/Table/Filters/config'
import { ORDERS, BOOKINGS } from '_legacy/constants/routes'
import * as r from '_legacy/constants/routes'

const {
  FOR_COMMERCIAL_USE, IN_PERSON_EXPERIENCE, LIVE_IN_PERSON_LESSON, READY_MADE_LESSON,
  VIDEO_CHAT, VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, VIRTUAL_EVENT, VIRTUAL_LESSON
} = api.talents.talent.offers.query.TypeFilter

const { UNDER_REVIEW, APPROVED, REJECTED, COMPLETED, ADMIN_REVIEW, CANCELED, BOOKED, CLOSED, DRAFT } = OrderStatus

export const offeringsTypes = {
  [IN_PERSON_EXPERIENCE]: {
    label: 'table.filters.offerings-types.in-person-experience',
    Icon: InPersonExperience,
    subText: 'table.filters.offerings-types.in-person-experience',
    href: `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}`
  },
  [VIDEO_MESSAGE]: {
    label: 'table.filters.offerings-types.video-message',
    Icon: VideoMessage,
    subText: 'table.filters.offerings-types.video-message',
    href: `/${ORDERS}/${r.VIDEO_MESSAGE}/${r.DETAILS}`
  },
  [VIDEO_CHAT]: {
    label: 'table.filters.offerings-types.live-video-chat',
    Icon: LiveVideoChats,
    subText: 'table.filters.offerings-types.live-video-chat',
    href: `/${ORDERS}/${r.VIDEO_CHAT}/${r.DETAILS}`
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    label: 'table.filters.offerings-types.video-voice-over-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${ORDERS}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}`
  },
  [VIRTUAL_LESSON]: {
    label: 'table.filters.offerings-types.virtual-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${ORDERS}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}`
  },
  [LIVE_IN_PERSON_LESSON]: {
    label: 'table.filters.offerings-types.live-in-person-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${ORDERS}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}`
  },
  [VIRTUAL_EVENT]: {
    label: 'table.filters.offerings-types.virtual-event',
    Icon: VirtualEvent,
    subText: 'table.filters.offerings-types.virtual-event',
    href: `/${ORDERS}/${r.LIVE_VIRTUAL_EVENTS}/${r.DETAILS}`
  },
  [READY_MADE_LESSON]: {
    label: 'table.filters.offerings-types.ready-made-lesson',
    Icon: ReadyMadeLesson,
    subText: 'table.filters.offerings-types.ready-made-lesson',
    href: `/${ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}`
  },
  [FOR_COMMERCIAL_USE]: {
    label: 'table.filters.offerings-types.video-message-for-commercial-use',
    Icon: VideoMessageForCommercialUse,
    subText: 'table.filters.offerings-types.video-message-for-commercial-use',
    href: `/${ORDERS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.DETAILS}`
  },
}

export const bookingsTypes = {
  [IN_PERSON_EXPERIENCE]: {
    label: 'table.filters.offerings-types.in-person-experience',
    Icon: InPersonExperience,
    subText: 'table.filters.offerings-types.in-person-experience',
    href: `/${r.BOOKINGS}/${r.EXPERIENCES}/${r.DETAILS}`
  },
  [VIDEO_MESSAGE]: {
    label: 'table.filters.offerings-types.video-message',
    Icon: VideoMessage,
    subText: 'table.filters.offerings-types.video-message',
    href: `/${BOOKINGS}/${r.VIDEO_MESSAGE}/${r.DETAILS}`
  },
  [VIDEO_CHAT]: {
    label: 'table.filters.offerings-types.live-video-chat',
    Icon: LiveVideoChats,
    subText: 'table.filters.offerings-types.live-video-chat',
    href: `/${BOOKINGS}/${r.VIDEO_CHAT}/${r.DETAILS}`
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    label: 'table.filters.offerings-types.video-voice-over-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${BOOKINGS}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}`
  },
  [VIRTUAL_LESSON]: {
    label: 'table.filters.offerings-types.virtual-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${BOOKINGS}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}`
  },
  [LIVE_IN_PERSON_LESSON]: {
    label: 'table.filters.offerings-types.live-in-person-lesson',
    Icon: Lessons,
    subText: 'table.filters.offerings-types.lessons',
    href: `/${BOOKINGS}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}`
  },
  [VIRTUAL_EVENT]: {
    label: 'table.filters.offerings-types.virtual-event',
    Icon: VirtualEvent,
    subText: 'table.filters.offerings-types.virtual-event',
    href: `/${BOOKINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.DETAILS}`
  },
  [READY_MADE_LESSON]: {
    label: 'table.filters.offerings-types.ready-made-lesson',
    Icon: ReadyMadeLesson,
    subText: 'table.filters.offerings-types.ready-made-lesson',
    href: `/${BOOKINGS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}`
  },
  [FOR_COMMERCIAL_USE]: {
    label: 'table.filters.offerings-types.video-message-for-commercial-use',
    Icon: VideoMessageForCommercialUse,
    subText: 'table.filters.offerings-types.video-message-for-commercial-use',
    href: `/${BOOKINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.DETAILS}`
  },
}

const offeringsTypesOptions = Object.keys(offeringsTypes).map(key => ({ value: key, label: offeringsTypes[key].label }))

export const statuses = {
  [UNDER_REVIEW]: {
    Icon: Icon.OrderStatusUnderReview,
    label: 'my-orders.status.under-review',
  },
  [BOOKED]: {
    Icon: Icon.OrderStatusUnderReview,
    label: 'my-orders.status.booked',
  },
  [APPROVED]: {
    Icon: Icon.OrderStatusApproved,
    label: 'my-orders.status.approved',
  },
  [REJECTED]: {
    Icon: Icon.OrderStatusReject,
    label: 'my-orders.status.reject',
  },
  [COMPLETED]: {
    Icon: Icon.OrderStatusCompleted,
    label: 'my-orders.status.completed',
  },
  [ADMIN_REVIEW]: {
    Icon: Icon.OrderStatusAdminReview,
    label: 'my-orders.status.admin-review',
  },
  [CANCELED]: {
    Icon: Icon.OrderStatusCancel,
    label: 'my-orders.status.cancel',
  },
  [CLOSED]: {
    Icon: Icon.OrderStatusCompleted,
    label: 'my-orders.status.closed',
  },
  [DRAFT]: {
    Icon: Icon.OrderStatusUnderReview,
    label: 'my-orders.status.draft',
  }
}
const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key].label }))

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'searchLike'
  },
  {
    type: filters.select,
    placeholder: 'table.filters.offering-type',
    field: 'typeIn',
    options: offeringsTypesOptions
  },
  {
    type: filters['range-datepicker'],
    placeholder: 'search.filters.date.placeholder',
    field: 'date',
  },
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'orderStatusIn',
    options: statusOptions
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.order',
  pluralLabel: 'table.filters.orders',
}

const sortFields = {
  ORDER_NUMBER: 'table.header.order',
  TYPE: 'table.header.type',
  BOOKED_PARTICIPANTS: 'table.header.booked-participants',
  DATE: 'table.header.date-time',
  LOCATION: 'table.header.location',
  PENDING_ACTION: 'table.header.current-pending-actions',
  STATUS: 'table.header.status',
};

const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

const additionalFields = ['sortOrder']

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
