import curry from 'lodash/fp/curry';
import kebabCase from 'lodash/kebabCase';
import keys from 'lodash/keys';
import { types } from 'shared';

const virtualEventTypes = keys(
  types.talent.offers.liveVirtualEvent.VirtualEventType
);

export const getVirtualEventTypesLabel = type =>
  `live-virtual-events.form.virtual-event-type.options.${kebabCase(type)}`;

export const getVirtualEventTypeOptions = curry((t, type) => ({
  value: type,
  label: t(getVirtualEventTypesLabel(type)),
}))

export const getVirtualEventTypesOptions = t =>
  virtualEventTypes.map(getVirtualEventTypeOptions(t));
