import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'

import { useFileEntityById } from 'store/hooks/globalState/useFileEntites';

const FileName = ({ name }) => {
  const { watch } = useFormContext()
  const file = useFileEntityById(watch(name))

  if (!file) return null

  return <StyledFileName>{file.originalName}</StyledFileName>
}

const StyledFileName = styled.p`
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  color: ${({ theme }) => theme.colors.grey};
`

FileName.propTypes = {
  name: PropTypes.string,
}

export default FileName
