import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import './index.scss'

import { useTalentCategories } from 'store/hooks/globalState/useCategories'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import CategorySelectWithInput from '_legacy/components/CategoriesSelect/CategorySelectWithInput'


const CategoriesCustomSelect = ({ getFn, setFn, field }) => {
  const t = useGetTranslate()
  const value = getFn(state => get(state, field))
  const setValue = setFn((prev, next) => ({ ...prev, [field]: next }))

  return (
    <CategorySelectWithInput
      value={value}
      setValue={setValue}
      getFn={useTalentCategories}
      allCategoriesLabel={t('main-menu.talent')}
      placeholder={t('search.filters.profile-type.placeholder')}
      showAllCategories
    />
  )
}

CategoriesCustomSelect.propTypes = {
  getFn: PropTypes.func,
  setFn: PropTypes.func,
  field: PropTypes.string,
}

export default CategoriesCustomSelect
