import React from 'react'
import { prop, compose, cond, propEq, always, T, propOr, eq } from 'lodash/fp'

import TwoLineString from '../common/TwoLineString'
import { getSaveFullName, getTalentFullName } from 'utils/user'

// todo: Fix after participant will added
const getParticipant = compose(
  cond([
    [compose(eq(0), propOr(0, 'talentMembers.length')), always('')],
    [propEq('talentMembers.length', 1), compose(getSaveFullName, prop('talentMembers.0'))],
    [T, offer => `+ ${offer?.talentMembers?.length} Talent Members`]
  ]),
  prop('offer')
)

const Talent = React.memo(({ isLoading, occurrence }) => {
  return (
    <TwoLineString
      isLoading={isLoading}
      mainText={getTalentFullName(occurrence)}
      subText={getParticipant(occurrence)}
    />
  )
})

export default Talent
