import React from 'react'
import compose from 'lodash/fp/compose'
import map from 'lodash/fp/map'

import { fetchUsersOrders } from './api'
import { getTalentUserIdOrUserId } from 'utils/user';
import normalizeParams from 'utils/normalizeParams'
import { useSetMyBookingsTable, useSetMyBookingsFilters } from './store'
import { parseRangeDate } from '_legacy/components/Table/Filters/utils/rangeDate'
import { replaceClosedWithCompletedInOrders, mergerClosedWithCompletedInQuery } from '_legacy/components/Table/Filters/utils/statuses'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const normalize = compose(
  normalizeParams,
  parseRangeDate,
  mergerClosedWithCompletedInQuery,
)

const parse = map(replaceClosedWithCompletedInOrders)


export const useFetchMyBookings = () => {
  const setMyBookingsFilters = useSetMyBookingsFilters((prev, next) => ({ ...prev, ...next }))
  const setMyBookingsTable = useSetMyBookingsTable((prev, next) => next)

  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    ({ ...options }, user) => {
      const currentUserId = getTalentUserIdOrUserId(user)

      const query = normalize(options)
      setMyBookingsTable(null)

      fetchUsersOrders({ userId: currentUserId, query })
        .then(({ orders, pageInfo }) => {
          const parsedOrders = parse(orders)

          setMyBookingsFilters(pageInfo)
          setMyBookingsTable(parsedOrders)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setMyBookingsFilters, setMyBookingsTable]
  )
}
