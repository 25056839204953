import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useMyOfferings = createUseGlobalState('myOfferings')
export const useSetMyOfferings = createUseSetGlobalState('myOfferings')

export const useManageOfferingsFilters = createUseGlobalState('manageOfferingsFilters')
export const useSetManageOfferingsFilters = createUseSetGlobalState('manageOfferingsFilters')

export const useManageOfferingsTable = createUseGlobalState('manageOfferingsTable')
export const useSetManageOfferingsTable = createUseSetGlobalState('manageOfferingsTable')
