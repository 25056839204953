import { types } from 'shared';
import { repeatEveryTypeWithTranslate } from '../../../containers/Experience/translates';
import { getRecurringLabel } from '../components/Recurring/config';

const {
  DOES_NOT_REPEAT,
} = types.experiences.recurrence.RecurringDefinedInAdvanceValues;

export const recurringModalDefaultState = {
  through: '2',
  ends: 'NEVER',
  type: repeatEveryTypeWithTranslate[0],
};

export const defaultTimeWindow = { dateOfExperience: null, startTime: null, endTime: null }

export const getDefaultValues = t => ({
  timeWindows: [defaultTimeWindow],
  name: '',
  pricePerParticipant: '',
  commercialUseOfExperiencefootage: 'false',
  monthOfUse: 3,
  maxNumberOfParticipants: '',
  typesOfLocation: null,
  location: '',
  locations: [],
  hideAddressFromPubView: 'false',
  typesOfDate: null,
  dateOfExperience: null, // {..}
  recurring: {
    value: DOES_NOT_REPEAT,
    label: t(getRecurringLabel(DOES_NOT_REPEAT)),
  },
  startTime: '',
  endTime: '',
  travelTime: {
    // seconds Integer
    after: '',
    before: '',
  },
  duration: '', // seconds Integer
  hideDayTime: 'false',
  nameColor: '#000000',
  photo: '',
  video: '',
  displayVideoInsteadOfImage: null,
  // talentMembers: [],
  // taboos: [],
  takingPicturesOk: 'true',
  signaturesOk: 'true',
  otherInformation: '',
  whatToBring: '',
  ageRequirement: '',
  charities: [{ charity: { value: null, label: '' }, percent: '' }],
  // reminderPreferences: 'false',
  minBookingTimeBeforeExperience: undefined,
  processRequestsType: '',
  accept: '',
  recurringModal: recurringModalDefaultState,
})
