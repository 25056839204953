import React, { useState } from 'react'
import styled from 'styled-components'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import NavigationLinksList from '_legacy/common/mobile-navigation/NavigationLinksList'
import MobileNotificationsItem from './NotificationForm/MobileNotificationsItem'
import Button from '_legacy/components/Button'
import NavigationHeader from '_legacy/common/mobile-navigation/NavigationHeader'

const MobileNotificationsTemplate = ({ isLoading, config }) => {
  const t = useGetTranslate()
  const [slug, setSlug] = useState(null)

  const routesConfig = config.map(i => ({
    path: '',
    text: i.tKey,
    clickHandler: () => setSlug(i.label),
  }))

  const parsedItems = React.useMemo(() => {
    return config.filter(item => item.label === slug)
  }, [slug])

  return slug ? (
    parsedItems.map(items => (
      <>
        <NavigationHeader
          text={`account.notifications.layout.block.${slug}`}
          clickHandler={() => setSlug(null)}
        />
        <MobileNotificationsList>
          <MobileNotificationsItem
            key={items.label}
            category={items.category}
          />
        </MobileNotificationsList>
        <Button
          text={t('account.notifications.form.submit-button.label')}
          typeButton="submit"
          paddingHorizontal={55}
          classBtn="notifications-submit-btn"
          disabled={isLoading}
        />
      </>
    ))
  ) : (
    <>
      <NavigationHeader text={'account.menu.notifications.title'} />
      <NavigationLinksList routesConfig={routesConfig} />
    </>
  )
}

const MobileNotificationsList = styled.div`
  div:last-child {
    border: none;
  }
`

export default MobileNotificationsTemplate
