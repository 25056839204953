import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Slider from '../../../Slider';
import Slide from '../../../Slide';

import Actions from './Actions';
import Empty from './Empty';
import Overlay from '../../LiveExperiences/Desktop/Overlay';

import { getMedia, isVideo } from '../../LiveVirtualEvents/Desktop/LiveVirtualEvents'

const ReadyMadeEvents = ({ firstName, covers = [], plaqueNickname }) => {
  const t = useGetTranslate();
  const [globalIsPlay, setGlobalIsPlay] = React.useState(false)

  const slides = covers.map((cover, i) => (
    <Slide.DesktopSlide
      key={`readu-made-event-${i}`}
      file={getMedia(cover)}
      isPromo={cover.isPromo}
      offerSequentialId={cover.sequentialId}
      setGlobalIsPlay={setGlobalIsPlay}
      overlay={!globalIsPlay ?
        <Overlay
          key={cover.id}
          name={cover.productName}
          video={isVideo(cover)}
        /> : null
      }
    />
  ))

  return (
    <Slider
      size="big" // change to 'small' if you want small slider
      title={t('talent-public-view.sliders.readyMadeEvents.header.label')}
      titleFontSize={27}
      slides={slides}
      empty={<Empty firstName={firstName} />}
      withGradientOverhead={true}
      isMirroredInside={true}
      Actions={Actions}
      plaqueNickname={plaqueNickname}
    />
  );
};

ReadyMadeEvents.propTypes = {
  firstName: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
};

export default ReadyMadeEvents
