import React from 'react'
import cx from 'classnames'

import { getShortMonthDate } from 'utils/date'
import { useUser } from 'store/hooks/globalState/useUser'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import { buildPhs, phsConfig } from '../config'
import { getOrderPageRoute } from 'router/routesMap'
import { getIsOwnByNotificationProps } from '../utils'

const getUploadVideoOverduePhsConfig = buildPhs([
  phsConfig.orderName,
  phsConfig.orderLink,
  phsConfig.talentLink,
  phsConfig.talentFullName,
  phsConfig.dueDate,
])

const UploadVideoOverdueReminderNotification = props => {
  const { id, createdAt, isRead, isOverdue, isTable, isMenu, occurrence } = props
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()
  const iOwnNotification = useUser(getIsOwnByNotificationProps(props), [props])

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'
  const transKey = iOwnNotification ? 'notification.reminder-video-overdue' : 'notification.reminder-video-overdue--agent'
  const phs = getUploadVideoOverduePhsConfig(props)

  return (
    <div className={containerClasses} id={id} key={transKey}>
      <div className="notification-item__icon">
        <Icon.Bell id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans phs={phs} tKey={transKey} />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>

      <div className="notification-item__controls">
        <Button
          isLink
          path={getOrderPageRoute(occurrence.offerType, occurrence.id)}
          text={t('shared.words.review')}
          className="notification-item__action-button"
        />
      </div>
    </div>
  )
}

export default UploadVideoOverdueReminderNotification
