import { _post } from '../../utils';

export const upload = (file, config, isAddToLibrary = false) => {
  const formData = new FormData();
  formData.append('file', file);

  return _post(
    '/upload',
    { needAuthorization: true, body: formData, config, query: { isAddToLibrary } },
  )
};
