import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { Trans } from 'store/hooks/globalState/useTranslates';

import Icon from 'assets/icons/Icon';

const Item = ({ iconNoActive, iconActive, active = false, label, onClick }) => (
  <StyledItem
    key={label}
    className='mb-item'
    active={active}
    onClick={onClick}
  >
    <StyledItemIcons className='mb-item-icons'>
      <StyledItemIconNoActive>{iconNoActive}</StyledItemIconNoActive>
      <StyledItemIconActive>{iconActive}</StyledItemIconActive>
    </StyledItemIcons>
    <StyledItemLabel className='mb-item-label'><Trans tKey={label} /></StyledItemLabel>
  </StyledItem>
);

const Items = React.memo(function Items({ currentActive, onActivate }) {
  const createItem = (index, label, IconComponentNoActive, IconComponentActive) => {
    const activeItem = currentActive === index;
    return (
      <Item
        key={index}
        label={label}
        active={activeItem}
        onClick={() => onActivate(index)}
        iconNoActive={
          <IconComponentNoActive
            active={activeItem}
          />
        }
        iconActive={
          <IconComponentActive
            active={activeItem}
          />
        }
      />
    );
  };

  return (
    <StyledItems className='mb-items'>
      {
        createItem(
          0,
          'talent-public-view.sliders.offers-navigation.live-experiences.label',
          Icon.InPersonExperiencesNoActive,
          Icon.InPersonExperiencesActive
        )}
      {
        createItem(
          1,
          'talent-public-view.sliders.offers-navigation.video-messages.label',
          Icon.VideoMessagesNoActive,
          Icon.VideoMessagesActive
        )}
      {
        createItem(
          2,
          'talent-public-view.sliders.offers-navigation.live-video-chats.label',
          Icon.VideoChatsNoActive,
          Icon.VideoChatsActive
        )}
      {
        createItem(
          3,
          'talent-public-view.sliders.offers-navigation.lessons.label',
          Icon.LessonsVirtualEventsNoActive,
          Icon.LessonsVirtualEventsActive
        )}
      {
        createItem(
          4,
          'talent-public-view.sliders.offers-navigation.ready-made.label',
          Icon.ReadyMadeEventsNoActive,
          Icon.ReadyMadeEventsActive
        )}
      {
        createItem(
          5,
          'talent-public-view.sliders.offers-navigation.virtual-events.label',
          Icon.LiveVirtualEventsNoActive,
          Icon.LiveVirtualEventsActive
        )}
    </StyledItems>
  );
});

const StyledItems = styled.div`
  display: flex;
  align-items: center;
`
const StyledItem = styled.div`
  display: flex;
  align-items: center;
  margin-right: 10px;
  width: 24px;
  position: relative;
  flex: 1;
  overflow: hidden;
  transition: .3s ease;
  transition-property: margin-right, flex, max-width;
  cursor: pointer;
  ${({ active }) => active && css`
    flex: 5 1;
    max-width: 100%;
  `}
`
const StyledItemIcons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
  flex: 0 0 26px;
  height: 24px;
  position: relative;
`
const styleIcon = css`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  transition: opacity .5s ease-in-out;
`
const StyledItemIconActive = styled.div`
  ${styleIcon};
  opacity: 0;
  ${({ children }) => children.props.active && css`
    opacity: 1
  `}
`
const StyledItemIconNoActive = styled.div`
  ${styleIcon};
  opacity: 1;
  ${({ children }) => children.props.active && css`
    opacity: 0
  `}
`
const StyledItemLabel = styled.div`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-feature-settings: 'case' on;
  color: #272835;
  white-space: nowrap;
  overflow: hidden;
`

Item.propTypes = {
  iconNoActive: PropTypes.func,
  iconActive: PropTypes.func,
  active: PropTypes.number,
  label: PropTypes.string,
  onClick: PropTypes.func
};

Items.propTypes = {
  currentActive: PropTypes.number,
  onActivate: PropTypes.func
};

export default Items
