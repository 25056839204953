import React from 'react'
import { string, bool } from 'prop-types'
import cx from 'classnames'

import PriceInput from '_legacy/common/Input/PriceInput'

const PriceWithLabel = ({
  name,
  placeholder,
  label,
  requiredMessage,
  whiteTheme,
  isRequired,
  className,
}) => (
  <div className={cx('price-with-label', className)}>
    <p className="price-with-label__label">{label}</p>
    <PriceInput
      name={name}
      placeholder={placeholder}
      requiredMessage={requiredMessage}
      whiteTheme={whiteTheme}
      isRequired={isRequired}
      className='inputPrice'
    />
  </div>
)

PriceWithLabel.propTypes = {
  name: string.isRequired,
  label: string.isRequired,
  placeholder: string,
  className: string,
  requiredMessage: string,
  whiteTheme: string,
  isRequired: bool,
};

export default PriceWithLabel;
