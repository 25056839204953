import React from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import ModalPortal from 'portals/ModalPortal';
import PaymentCard from './PaymentCard';

import { fontsCardElement } from '_legacy/containers/Purchase/utils/stylesStripe';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentModal = () =>
  <ModalPortal showCloseButton isOpen isMobileFullScreen closeClassName='payment-modal--close'>
    <Elements stripe={stripePromise} options={{ fontsCardElement }}>
      <PaymentCard />
    </Elements>
  </ModalPortal>

export default PaymentModal
