import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { errorNames } from 'shared'

import { ErrorMessage } from '_legacy/common';
import Button from '_legacy/components/Button';
import Icon from 'assets/icons/Icon'

import { appApi } from 'api';
import { useFetch } from 'store/hooks/useFetch';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { discountView } from 'utils/discountView';

const {
  PROMO_CODE_NOT_FOUND,
  PROMO_CODE_IS_EXPIRED,
  PROMO_CODE_LIMIT_EXCEEDED,
  PROMO_CODE_NOT_APPLICABLE_FOR_THIS_OFFER } = errorNames.promoCodes

const errorPromoCode = {
  [PROMO_CODE_NOT_FOUND]: 'payment-page.promo-code.error.not-found',
  [PROMO_CODE_IS_EXPIRED]: 'payment-page.promo-code.error.expired',
  [PROMO_CODE_LIMIT_EXCEEDED]: 'payment-page.promo-code.error.limit-execeeded',
  [PROMO_CODE_NOT_APPLICABLE_FOR_THIS_OFFER]: 'payment-page.promo-code.error.not-applicable-for-this-offer',
}

const PromoCode = React.memo(({ offerId, discountPromoCode, setDiscountPromoCode, orderId }) => {
  const t = useGetTranslate();
  const [showPromoCode, setShowPromoCode] = React.useState(false);
  const [promoCodeValue, setPromoCodeValue] = React.useState('');
  const [promoCodeError, setPromoCodeError] = React.useState('');

  const [{ data, error, isLoading }, verifyPromocode] = useFetch(appApi.purchase.verifyPromoCode)

  const togglePromoCode = () => {
    setPromoCodeValue('')
    setPromoCodeError('')
    setShowPromoCode(s => !s)
  }

  const handleRemove = () => {
    setPromoCodeValue('')
    setPromoCodeError('')
    setDiscountPromoCode(null);
  }

  React.useEffect(() => {
    if (promoCodeValue.length === 0 && promoCodeError) {
      setPromoCodeError('')
    }
  }, [promoCodeValue, promoCodeError])

  const changePromoCode = e => setPromoCodeValue(e.target.value.toUpperCase())

  const sendPromoCode = async () => await verifyPromocode({ promoCode: promoCodeValue, offerId, orderId })

  const classError = promoCodeValue ? 'error' : ''

  React.useEffect(() => {
    if (data) setDiscountPromoCode(data)
  }, [data])

  React.useEffect(() => {
    if (error) {
      const errorMessage = get(errorPromoCode, error?.name, '');
      setPromoCodeError(errorMessage)
    }
  }, [error])


  return (
    <div className='payment-card__promo-code'>
      <button type='button' className='payment-card__promo-code_btn' onClick={togglePromoCode}>
        {t('payment-page.promo-code.button')}&nbsp;<Icon.Arrow data-open={String(showPromoCode)} />
      </button>
      <label className="payment-card__field payment-card__promo-code_block" data-open={String(showPromoCode)}>
        <span className='payment-card__label'>{t('payment-page.promo-code.label')}</span>
        {isEmpty(discountPromoCode) && <div className='payment-card__promo-code_enter'>
          <input
            type='text'
            className={`payment-card__input ${classError}`}
            value={promoCodeValue}
            onChange={changePromoCode}
          />
          <Button
            typeButton='button'
            text={t('payment-page.promo-code.apply')}
            stretch
            paddingVertical={13}
            paddingHorizontal={20}
            handleOnClick={sendPromoCode}
            disabled={isEmpty(promoCodeValue)}
            loader={isLoading}
          />
          {promoCodeError && <ErrorMessage className='_error-message' message={t(promoCodeError)} />}
        </div>}
        {!isEmpty(discountPromoCode) && <div className='payment-card__promo-code-view'>
          <div className="payment-card__promo-code-view_wr">
            <p>{data.promoCode.code}</p>
            <span>
              {discountView(data.promoCode.discountType, data.promoCode.discountAmount)}
              &nbsp;
              {t('payment-page.promo-code.discount')}
            </span>
          </div>
          <span className="payment-card__promo-code-view_btn" onClick={handleRemove} />
        </div>}
      </label>
    </div>
  );
});

PromoCode.propTypes = {
  offerId: PropTypes.string,
  orderId: PropTypes.string,
  discountPromoCode: PropTypes.object,
  setDiscountPromoCode: PropTypes.func
}

export default PromoCode;
