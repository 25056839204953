import get from 'lodash/get'

export const parseLocation = params => {
  const { location, locationKmRadius, ...rest } = params
  const _location = get(location, 'value')

  if (typeof _location === 'string') {
    return {
      ...rest,
      locationKmRadius,
      locationGooglePlaceId: _location,
    }
  } else if (typeof _location === 'object') {
    return {
      ...rest,
      locationKmRadius,
      locationLatitude: get(_location, 'latitude'),
      locationLongitude: get(_location, 'longitude'),
    }
  } else {
    return params
  }
}
