import React, { createContext, useReducer, useState } from 'react'
import { errorNames } from 'shared'
import { useHistory, useParams } from 'react-router-dom'
import * as r from '_legacy/constants/routes'

const { VIDEO_ROOM_WAS_CLOSED } = errorNames.videoRooms

import {
  closeVideoChatRoom as closeVideoChatRoomApi,
  joinVideoChatRoom as joinVideoChatRoomApi,
  verifyVideoChatRoom as verifyVideoChatRoomApi,
  closeLiveVirtualLessonRoom as closeLiveVirtualLessonRoomApi,
  joinLiveVirtualLessonRoom as joinLiveVirtualLessonRoomApi,
  verifyLiveVirtualLessonRoom as verifyLiveVirtualLessonRoomApi,
} from '../api'
import { initialSettings, settingsReducer } from './settings/settingsReducer'
import useActiveSinkId from './useActiveSinkId'
import { useUser } from 'store/hooks/globalState/useUser'
import { getTalentId } from 'utils/user'
import { routesList } from 'router/routesMap'

const closeApi = {
  [r.VIDEO_CHAT]: closeVideoChatRoomApi,
  [r.LIVE_VIRTUAL_LESSON]: closeLiveVirtualLessonRoomApi,
}

const joinApi = {
  [r.VIDEO_CHAT]: joinVideoChatRoomApi,
  [r.LIVE_VIRTUAL_LESSON]: joinLiveVirtualLessonRoomApi,
}

const verifyApi = {
  [r.VIDEO_CHAT]: verifyVideoChatRoomApi,
  [r.LIVE_VIRTUAL_LESSON]: verifyLiveVirtualLessonRoomApi,
}

const orderRoute = {
  [r.VIDEO_CHAT]: routesList.videoChatOrderDetails,
  [r.LIVE_VIRTUAL_LESSON]: routesList.liviuVirtualLessonOrderDetails,
}

const bookingRoute = {
  [r.VIDEO_CHAT]: routesList.videoChatBookingDetails,
  [r.LIVE_VIRTUAL_LESSON]: routesList.liveVirtualLessonBookingDetails,
}

export const StateContext = createContext(null)

export default function VideoStateProvider(props) {
  const { offerType } = useParams()
  const history = useHistory()
  const talentId = useUser(getTalentId)

  const [error, setError] = useState(null)
  const [isFetching, setIsFetching] = useState(false)
  const [activeSinkId, setActiveSinkId] = useActiveSinkId()
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings)
  const [isRoomExist, setIsRoomExist] = useState(null)
  const [previewInfo, setPreviewInfo] = useState(null)

  const contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
    isRoomExist,
    setIsRoomExist,
    previewInfo,
    setPreviewInfo,
  }

  const processApiError = (error) => {
    if (error.name === VIDEO_ROOM_WAS_CLOSED) {
      const { context } = error?.error?.response?.data?.error

      if (context) {
        if (talentId && talentId === context?.occurrenceTalentId) {
          history.push(orderRoute[offerType](context?.occurrenceId))
        } else {
          history.push(bookingRoute[offerType](context?.orderId))
        }
      }
    }
  }

  const verifyVideoChatRoom = (occurrenceId) => {
    const verifyApiFn = verifyApi[offerType]
    return verifyApiFn({ occurrenceId })
      .then((res) => {
        setPreviewInfo(res)
        setIsRoomExist(true)
      })
      .catch((error) => {
        processApiError(error)

        setIsRoomExist(false)
      })
  }

  const joinVideoChatRoom = (occurrenceId) => {
    const joinApiFn = joinApi[offerType]
    setIsFetching(true)

    return joinApiFn({ occurrenceId })
      .catch((err) => {
        processApiError(err)

        setError(err)
        return Promise.reject(err)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  const closeVideoChatRoom = (occurrenceId) => {
    const closeApiFn = closeApi[offerType]

    return closeApiFn({ occurrenceId })
      .catch(processApiError)
      .finally(() => {
        setIsRoomExist(false)
      })
  }

  return (
    <StateContext.Provider value={{
      ...contextValue,
      verifyVideoChatRoom,
      closeVideoChatRoom,
      joinVideoChatRoom,
    }}>
      {props.children}
    </StateContext.Provider>
  )
}

