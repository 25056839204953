import React from 'react';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import PlatesList from './PlatesList';
import Button from '../../../components/Button';
import { useUser } from 'store/hooks/globalState/useUser'
import {
  getTalentPlaqueNickname
} from 'utils/user'


function NewOfferings () {
  const t = useGetTranslate()
  return (
    <div className='new-offerings-container wrapper content-wrapper'>
      <h1 >
        {t('offerings.new.title')}
      </h1>
      <PlatesList />
      <p className='hint'>
        {t('offerings.new.hint')}
      </p>
      <Button
        classBtn='complete-button'
        isLink
        path={useUser(getTalentPlaqueNickname)}
        text={t('offerings.new.buttons.done')}
        typeButton="submit"
        paddingVertical='15'
        paddingHorizontal='59'
      />
    </div>
  );
}

export default NewOfferings
