import React from 'react';
import PropTypes from 'prop-types';

const NotificationHead = ({ rows = [] }) => {
  return (
    <div className='notifications-head'>
      <div className='notifications-row'>
        <div className='notifications-cell notifications-cell--start'/>

        {rows.map(row => (
          <div key={row} className='notifications-cell notifications-cell--center'>
            <h6 className='notifications-cell-head'>{row}</h6>
          </div>
        ))}
      </div>
    </div>
  );
};

NotificationHead.propTypes = {
  rows: PropTypes.array,
};

export default NotificationHead;
