import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const TalentPendingForApproval = () => {
  const t = useGetTranslate()
  return (
    <div className="not-found-container">
      <p className="talent-pending-for-approval-content">{t('talent-pending-for-approval.content')}</p>
    </div>
  );
};

TalentPendingForApproval.propTypes = {
  setHeader: PropTypes.func,
  setBackground: PropTypes.func,
};

export default TalentPendingForApproval
