import React from 'react'
import styled from 'styled-components'

import { Row, Input } from '_legacy/common'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const MaxNumberOfParticipants = () => {
  const t = useGetTranslate()

  return (
    <Row
      isRequired
      nameBlock={t('create-experience.form.number-of-participants.label')}
      subNameBlock={t('create-experience.form.number-of-participants.tip')}
      component={
        <MaxNumberOfParticipantsWrapper>
          <Input
            name="maxNumberOfParticipants"
            type="number"
            placeholder={t('create-experience.form.number-of-participants.placeholder')}
            validation={{
              require: 'create-experience.form.number-of-participants.errors.required',
              min: {
                value: 1,
                message: 'create-experience.form.number-of-participants.errors.min',
              },
            }}
          />
        </MaxNumberOfParticipantsWrapper>
      }
    />
  )
}

const MaxNumberOfParticipantsWrapper = styled.div`
  max-width: 262px;
  @media screen and (max-width: 425px) {
    max-width: 100%;
  }
`

export default MaxNumberOfParticipants
