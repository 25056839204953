import React from 'react'
import styled from 'styled-components'

import { ReactComponent as NoData } from 'assets/no-data.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default function EmptyTable ({ text }) {
  const t = useGetTranslate()
  return (
    <StyledWrapper className='table-content-wrapper'>
      <NoData className='icon' />
      <div className='text'>
        {t(text)}
      </div>
    </StyledWrapper>
  )
}

EmptyTable.defaultProps = {
  text: 'table.empty',
}

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .icon {
    margin-top: 40px;
  }
  .text {
    margin-top: 20px;
    font-weight: 500;
    color: ${({ theme: { colors } }) => colors.lightGrey};
  }
`
