// Modules
import React from 'react'

// Store (hooks)
import useScroll from 'store/hooks/useScroll'

// Legacy components
import LoaderIndicator from '_legacy/components/Common/Loader'

// Components
import EmptyChatsList from './EmptyChatsList'
import ChatListItem from './ChatListItem'


const ChatsList = ({ list = [], hasMore, fetchNext }) => {
  // INFINITE SCROLL
  const parentLoaderRef = React.useRef()
  const childLoaderRef = React.useRef()

  useScroll({
    parentRef: parentLoaderRef,
    childRef: childLoaderRef,
    callback: fetchNext,
    hasMore: hasMore,
  })

  return (
    <div className="chats-list__wrapper">
      {list.length === 0 ? (
        <EmptyChatsList />
      ) : (
        <>
          {list.map((chat) => (
            <ChatListItem key={chat.chatInfo.id} chat={chat} />
          ))}

          {hasMore && (
            <div ref={childLoaderRef} className="messages-list__loader">
              <LoaderIndicator size={24} fill="#636583" />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ChatsList
