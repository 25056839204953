import React from 'react';
import { useWatch, useFormContext } from 'react-hook-form';
import cond from 'lodash/cond';
import debounce from 'lodash/debounce';
import is from 'lodash/fp/isEqual';
import always from 'lodash/fp/always';
import T from 'lodash/fp/T';
import isEqual from 'lodash/isEqual'
import { types } from 'shared';
import {
  getTalentsCalendarCheckOverlap as getTalentsCalendarCheckOverlapApi
} from 'components/Calendar/TalentCalendar/state/api/calendar'

import { getTypesOfDateWithTranslate } from './config';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common/Template';
import { RadioButtons } from '_legacy/common/Radio';
import Flexible from './Flexible';
import OneTime from './OneTime';
import TipButton from '../../../Form/LabelTips/TipButton';
import TypesOfDateInfoModal from '../../../Modal/TypesOfDateInfoModal';

import { useUser } from 'store/hooks/globalState/useUser'
import { getTalentId } from 'utils/user'
import { prepareRecurring } from 'utils/forms/adapters/recurring'
import { setMinutesToDate } from '../../utils/utils'
import { getSelectValue } from 'utils/forms/adapters/select'

const { FIXED, FLEXIBLE } = types.experiences.TypesOfDate;
const name = 'typesOfDate';

const TypesOfDate = ({ recurringIdToExclude }) => {
  const t = useGetTranslate();
  const methods = useFormContext();
  const typeOfDate = useWatch({ control: methods.control, name });
  const formKeys = ['dateOfExperience', 'startTime', 'endTime', 'travelTime.before', 'travelTime.after', 'recurring', 'recurringModal']
  const formState = methods.watch(formKeys)
  const prevForm = React.useRef(formState)
  const prevRecurringIdToExclude = React.useRef(recurringIdToExclude)
  const typesOfDateOptions = getTypesOfDateWithTranslate(t);
  const talentId = useUser(getTalentId)

  const Content = cond([
    [is(FIXED), always(OneTime)],
    [is(FLEXIBLE), always(Flexible)],
    [T, always(() => null)],
  ])(typeOfDate);

  const checkFixedEventOverlap = React.useCallback(debounce(async(formState, prevForm, recurringIdToExclude) => {
    const { dateOfExperience, startTime, endTime, 'travelTime.before': travelTimeBefore, 'travelTime.after': travelTimeAfter } = formState

    if (dateOfExperience && getSelectValue(startTime) && getSelectValue(endTime)) {
      const lastChangedKey = formKeys.find(key => !isEqual(formState[key],  prevForm[key]))

      try {
        await getTalentsCalendarCheckOverlapApi({
          talentId,
          body: {
            startTime: setMinutesToDate(dateOfExperience, getSelectValue(startTime)),
            endTime: setMinutesToDate(dateOfExperience, getSelectValue(endTime)),
            beforeDriveTime: getSelectValue(travelTimeBefore),
            afterDriveTime: getSelectValue(travelTimeAfter),
            recurring: prepareRecurring(formState),
            recurringIdToExclude
          }
        })

        formKeys.forEach(key => {
          methods.clearErrors(key)
        })
      } catch(err) {
        if (lastChangedKey === 'recurringModal') {
          methods.setError('recurring', { message: 'create-experience.form.errors.event-overlaps' })
        } else {
          methods.setError(lastChangedKey, { message: 'create-experience.form.errors.event-overlaps' })
        }
      }
    } else {
      formKeys.forEach(key => {
        methods.clearErrors(key)
      })
    }
  }, 400), [])

  // trigger overlap validation
  React.useEffect(() => {
    if (!isEqual(prevForm.current, formState) || !isEqual(prevRecurringIdToExclude.current, recurringIdToExclude)) {
      checkFixedEventOverlap(formState, prevForm.current, recurringIdToExclude)
      prevForm.current = formState
      prevRecurringIdToExclude.current = recurringIdToExclude
    }
  }, [formState, recurringIdToExclude])

  // clear fields on change type of date radio button
  React.useEffect(() => {
    if (typeOfDate === FLEXIBLE) {
      methods.setValue('dateOfExperience', null)
      methods.setValue('startTime', null)
      methods.setValue('endTime', null)
    }
  }, [typeOfDate])

  return (
    <>
      <Row
        isRequired
        nameBlock={t('create-experience.form.types-of-date.label')}
        tip={<TipButton modalComponent={TypesOfDateInfoModal} />}
        component={
          <RadioButtons
            name={name}
            options={typesOfDateOptions}
            customValidation={{ required: 'create-experience.form.types-of-date.errors.required' }}
          />
        }
      />
      <Content />
    </>
  );
};

export default TypesOfDate;
