import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import BookNowButton from '../../../components/BookNowButton';
import BasicMobileOverlay from '../../../Overlay/BasicMobileOverlay';
import { ReactComponent as CommercialEngagementIcon } from 'assets/commercial-engagement-icon.svg';
import FaBan from 'mdi-react/BanIcon'
import styled from 'styled-components';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
const Overlay = ({ slide, path, hideControl, isPromo, isVideo }) => {
  const t = useGetTranslate();
  const classes = cx('tbv-slider-slide-overlay-live-experience')
  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className={classes}>
        {slide.isCommercial === false &&
          <h3 className="tbv-slider-slide-overlay-live-experience-name">
            {t('shared.offers.personal-video-message')}
          </h3>}
        {slide.isCommercial === undefined && slide.commercialVideoMessageName &&
          <h3 className="tbv-slider-slide-overlay-live-experience-name">
            {slide.commercialVideoMessageName}
          </h3>}
      </div>
      <div className='video-overlay'>
        {slide.isCommercial === undefined && (
          <div className='move-up-right-corner'>
            <div className='icon'><CommercialEngagementIcon /></div>
            <h4 className="text-commercial">
              {t('shared.offers.commercial-offering')}
            </h4>
          </div>
        )}
        {slide.isCommercial === false && (
          <div className='move-up-right-corner'>
            <div className='relative'>
              <div className='icon-buildings-with-ban'><CommercialEngagementIcon /></div>
              <div className='icon-ban'><FaBan color='#626473' /></div>
            </div>
            <h4 className="text-fan">
              {t('shared.offers.fans-only')}
            </h4>
          </div>
        )}
      </div>
      <div className='overlay-button'>
        {!hideControl && !isPromo && <BookNowButton isLink={true} path={path} />}
        {!hideControl && isPromo && <StyledPromoVideo>{isVideo === "VIDEO" ? "Promo Video": "Promo Image"}</StyledPromoVideo>}
      </div>
    </BasicMobileOverlay>
  );
};

Overlay.propTypes = {
  onBookNowClick: PropTypes.func.isRequired,
};

const StyledPromoVideo = styled.h3`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  margin-left: 10px;
  font-feature-settings: 'case' on, 'liga' off;
  color: #ffffff;
`

export default Overlay;
