import React from 'react'
import { find, propEq, prop } from 'lodash/fp'
import { PromotionalMediaType, TalentOffersType } from 'shared'
import './index.scss'

import { useSetModal } from 'store/hooks/globalState/useModal'
import { useSetPromotionalMedia } from 'requests/my-media'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMyMediaTable } from 'store/hooks/globalState/useMyMedia'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'
import { offeringIcons } from '_legacy/components/OfferIcons'

const offerTypesOptions = [
  {
    value: PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO,
    Icon: offeringIcons[TalentOffersType.IN_PERSON_EXPERIENCE],
    label: 'shared.offers.in-person-experience',
  },
  {
    value: PromotionalMediaType.VIDEO_MESSAGE_PROMO,
    Icon: offeringIcons[TalentOffersType.VIDEO_MESSAGE],
    label: 'shared.offers.video-message',
  },
  {
    value: PromotionalMediaType.VIDEO_CHAT_PROMO,
    Icon: offeringIcons[TalentOffersType.VIDEO_CHAT],
    label: 'shared.offers.video-chat',
  },
  {
    value: PromotionalMediaType.LESSONS_PROMO,
    Icon: offeringIcons[TalentOffersType.LIVE_IN_PERSON_LESSON],
    label: 'shared.offers.lessons',
  },
  {
    value: PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO,
    Icon: offeringIcons[TalentOffersType.VIRTUAL_EVENT],
    label: 'shared.offers.virtual-event',
  },
  {
    value: PromotionalMediaType.READY_MADE_LESSON_PROMO,
    Icon: offeringIcons[TalentOffersType.READY_MADE_LESSON],
    label: 'shared.offers.ready-made-lesson',
  },
]

const SelectPromotionalMediaModal = React.memo(props => {
  const t = useGetTranslate()
  const setPromotionalMedia = useSetPromotionalMedia()
  const closeModal = useSetModal(() => null)
  const file = useMyMediaTable(find(propEq('id', props[0])))
  const [promotionalMediaTypes, setPromotionalMediaTypes] = React.useState(file.promotionalMedia.map(prop('type')))

  const onChange = value => {
    setPromotionalMediaTypes(prev => (prev.includes(value) ? prev.filter(i => i !== value) : [...prev, value]))
  }

  const onCancel = () => {
    closeModal()
  }

  const onDone = () => {
    setPromotionalMedia({ fileToken: file.fileToken, promotionalMediaTypes })
    closeModal()
  }

  return (
    <ModalPortal isMobileFullScreen isOpen onClose={closeModal} showCloseButton>
      <div className="promotional-medial-modal">
        <h1 className="promotional-medial-modal__header modal-header">
          {t('my-library.select-promotional-media-modal.header')}
        </h1>
        <div className="promotional-medial-modal__list">
          {offerTypesOptions.map(option => (
            <CheckboxListItem
              key={option.value}
              value={option.value}
              label={t(option.label)}
              onChange={onChange}
              checked={promotionalMediaTypes.includes(option.value)}
              Icon={option.Icon}
            />
          ))}
        </div>
        <footer className="promotional-medial-modal__footer">
          <p className="promotional-medial-modal__count">
            {t('my-library.select-promotional-media-modal.selected')}&nbsp;
            <strong>{promotionalMediaTypes.length}</strong>
          </p>
          <div className="promotional-medial-modal__controls">
            <Button
              primary
              text={t('shared.words.cancel')}
              handleOnClick={onCancel}
              className="promotional-medial-modal__cancel-button"
            />
            <Button text={t('shared.words.done')} handleOnClick={onDone} />
          </div>
        </footer>
      </div>
    </ModalPortal>
  )
})

const CheckboxListItem = ({ label, Icon, value, checked, onChange }) => {
  return (
    <label className="checkbox-list-item">
      <span className="checkbox-list-item__content">
        <Icon className="checkbox-list-item__icon" />
        {label}
      </span>
      <input
        type="checkbox"
        value={value}
        checked={checked}
        onChange={event => onChange(event.target.value)}
        className="checkbox-list-item__input"
      />
      <i className="checkbox-list-item__checkbox" />
    </label>
  )
}

export default SelectPromotionalMediaModal
