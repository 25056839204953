import React from 'react'
import { TalentOffersType } from 'shared'
import get from 'lodash/get'

import { routesList, getBookingPageRoute } from 'router/routesMap'
import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

const getDesc = offerType => {
  const descConfig = {
    [TalentOffersType.VIDEO_CHAT]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.VIDEO_MESSAGE]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.VIRTUAL_EVENT]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.VIRTUAL_LESSON]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.READY_MADE_LESSON]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.FOR_COMMERCIAL_USE]: 'payment-page.modal.confirm.desc',
    [TalentOffersType.IN_PERSON_EXPERIENCE]: 'payment-page.modal.confirm.exp-desc',
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'payment-page.modal.confirm.exp-desc',
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'payment-page.modal.confirm.desc',
    DEFAULT: 'payment-page.modal.confirm.desc',
  }

  return get(descConfig, offerType, descConfig.DEFAULT)
}

const getButton = offerType => {
  const buttonConfig = {
    [TalentOffersType.VIDEO_CHAT]: 'payment-page.modal.button.go-to-order',
    [TalentOffersType.VIDEO_MESSAGE]: 'payment-page.modal.button.go-to-listing',
    [TalentOffersType.VIRTUAL_EVENT]: 'payment-page.modal.button.go-to-order',
    [TalentOffersType.VIRTUAL_LESSON]: 'payment-page.modal.button.go-to-order',
    [TalentOffersType.READY_MADE_LESSON]: 'payment-page.modal.button.go-to-order',
    [TalentOffersType.FOR_COMMERCIAL_USE]: 'payment-page.modal.button.go-to-listing',
    [TalentOffersType.IN_PERSON_EXPERIENCE]: 'payment-page.modal.button.go-to-listing',
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'payment-page.modal.button.go-to-listing',
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'payment-page.modal.button.go-to-listing',
    DEFAULT: 'payment-page.modal.button.go-to-listing',
  }

  return get(buttonConfig, offerType, buttonConfig.DEFAULT)
}

const getPath = (offerType, orderId) => {
  const defaultRoute = routesList.bookingList()
  const existRoutes = [
    TalentOffersType.VIRTUAL_LESSON,
    TalentOffersType.READY_MADE_LESSON,
    TalentOffersType.VIDEO_CHAT
  ].reduce((acc, type) => ({ ...acc, [type]: getBookingPageRoute(type, orderId) }), {})

  return get(existRoutes, offerType, defaultRoute)
}

const PurchaseConfirm = ({ isOpen, closeModal, offerType, talent, orderId }) => {
  const t = useGetTranslate()
  const descKey = getDesc(offerType)
  const buttonKey = getButton(offerType)
  const path = getPath(offerType, orderId)

  return (
    <ModalPortal isOpen={isOpen}>
      <div className="check-email-modal">
        <span className="check-email-modal__check-icon" />
        <h1 className="check-email-modal__header">{t('payment-page.modal.confirm.title')}</h1>
        <p className="check-email-modal__text">
          <Trans tKey={descKey} phs={[{ ph: '{{talentName}}', value: talent?.firstName }]} />
        </p>
        <Button
          text={t(buttonKey)}
          handleOnClick={closeModal}
          isLink
          path={path}
          stretch
          paddingVertical="16"
          paddingHorizontal="20"
        />
      </div>
    </ModalPortal>
  )
}

export default PurchaseConfirm
