import React from 'react';
import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { FileType } from 'shared';
import { head } from 'lodash'

import { Input, Row } from '_legacy/common'
import { Radio } from '_legacy/common/Radio'
import Taboos from '_legacy/components/Form/Taboos/Wrapper';
import Button from '_legacy/components/Button';
import PriceInput from '_legacy/common/Input/PriceInput';
import CharitiesSelect from '_legacy/components/Form/Selects/basic/CharitiesSelect';
import MultiSelectLibrary from '_legacy/components/Library/MultiSelectLibrary';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';
import MultiSelectBackdrop from '_legacy/components/Library/configs/MutliSelect/MultiSelectBackdrop';
import OfferingsLinkToManagerPromoCodes from '_legacy/components/OfferingsLinkToManagerPromoCodes';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import { useVideoChat } from 'store/hooks/globalState/videoChat';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { multiSelectLibraryValidate } from 'utils/services/validators/library';

import './index.scss'
import Select from '../../common/Select/Common'
import { daysTimesTypeOptions } from './utils'


const VideoChatForm = ({ methods, onSubmit, onDraft, onPreview, isLoading }) => {
  const t = useGetTranslate();
  const accept = [FileType.PHOTO, FileType.VIDEO];
  const videoChat = useVideoChat();

  return (
    <FormProvider {...methods}>
      <form className="default video-chat-offering-form wrapper content-wrapper" onSubmit={methods.handleSubmit(onSubmit)}>
        <RequiredLabel />
        <Row
          nameBlock={t('video-message.form.enable.label')}
          className='enable-in-you-services'
          component={
            <div className='enable-in-you-services-radio'>
              <Radio
                value={true}
                name='enableInYourServices'
                label={t('shared.words.label.yes')}
                defaultChecked
              />
              <Radio
                value={false}
                name='enableInYourServices'
                label={t('shared.words.label.no')}
              />
            </div>
          }
        />
        <Row
          nameBlock={t('video-chat.form.price.label')}
          subNameBlock={t('video-chat.form.price.tip')}
          component={
            <PriceInput
              name='price'
              placeholder='video-chat.form.price.placeholder'
              requiredMessage='video-chat.form.price.errors.required'
              isRequired
            />
          }
        />
        <Row
          nameBlock={t('video-chat.form.days-times-type.label')}
          component={
            <Select
              name='daysTimesType'
              options={daysTimesTypeOptions}
              defaultValue={head(daysTimesTypeOptions)}
            />
          }
        />
        <Row
          nameBlock={t('video-chat.form.duration.label')}
          component={
            <Input
              isRequired
              type='number'
              name='duration'
              validation={{
                min: {
                  value: 5,
                  message: t('video-chat.form.duration.errors.min-length')
                }
              }}
              requiredMessage='video-chat.form.duration.errors.required'
              placeholder={t('video-chat.form.duration.placeholder')}
            />
          }
        />
        <Row
          nameBlock={t('video-chat.form.charities.label')}
          component={
            <>
              <CharitiesSelect name={'whichCharities'} showSeparator={false} />
              <span className='charity-tip'><Trans tKey='shared.charities.text' /></span>
            </>
          }
        />
        <Taboos
          defaultEntities={videoChat?.data?.customTaboos || []}
          register={methods.register}
        />
        <Row
          nameBlock={t('video-chat.form.media.label')}
          subNameBlock={t('video-chat.form.multi-media.tip')}
          component={
            <MultiSelectLibrary
              name='media'
              dropZoneProps={{ accept }}
              customBackdrop={MultiSelectBackdrop}
              rules={{ validate: multiSelectLibraryValidate }}
            />
          }
        />
        <OfferingsLinkToManagerPromoCodes />
        <Row
          component={
            <div className='video-chat-offering-form__buttons'>
              <Button
                disabled={isLoading}
                text={t('video-message.form.submit-button.label')}
                typeButton="submit"
                paddingVertical="13"
                paddingHorizontal="24"
              />
              {onPreview && (
                <Button
                  primary
                  text={t('video-message.form.preview-button.label')}
                  typeButton="button"
                  paddingVertical="13"
                  paddingHorizontal="24"
                  handleOnClick={onPreview}
                />
              )}
              {onDraft && (
                <Button
                  primary
                  text={t('video-message.form.draft-button.label')}
                  typeButton="button"
                  paddingVertical="13"
                  paddingHorizontal="24"
                  handleOnClick={onDraft}
                />
              )}
            </div>
          }
        />
        <MissedData />
      </form>
    </FormProvider>
  );
};

VideoChatForm.propTypes = {
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool
}
export default VideoChatForm;
