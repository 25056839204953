import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withResizeDetector } from 'react-resize-detector';

const Item = ({ active, itemRef, label, linkPath, onWidthChanged, width, onHover }) => {
  useEffect(() => {
    onWidthChanged(width);
  }, [width]);

  return (
    <li
      className="account-submenu-list-item"
      key={label}
      ref={itemRef}
      onMouseEnter={onHover}
      attr-active={String(active)}
    >
      <Link to={linkPath}>{label}</Link>
    </li>
  );
};

Item.propTypes = {
  active: PropTypes.bool.isRequired,
  itemRef: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  linkPath: PropTypes.string.isRequired,

  width: PropTypes.number,
  onWidthChanged: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
};

export default withResizeDetector(Item);
