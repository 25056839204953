import React from 'react';
import styled, { css } from 'styled-components';
import { useFormContext } from 'react-hook-form';

import { Checkbox, Input, Row } from '_legacy/common';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const OccurrenceLimit = () => {
  const t = useGetTranslate()
  const { watch } = useFormContext();
  const checkbox = watch('endsAfter', false);

  return (
    <Row
      nameBlock={t('create-experience.form.occurrences-limit.label')}
      component={
        <OccurrenceLimitWrapper>
          <Checkbox label={t('create-experience.form.ends-after.label')} name="endsAfter" />
          <InputWrapper disabled={!checkbox}>
            <Input
              min={1}
              defaultValue={1}
              type="number"
              name="occurrencesLimit"
              hideArrow={false}
              disabled={!checkbox}
            />
            <InputLabel>{t('create-experience.form.ends-after-occurrences.label')}</InputLabel>
          </InputWrapper>
        </OccurrenceLimitWrapper>
      }
    />
  );
};

const InputWrapper = styled.div`
  position: relative;
  ${({ disabled }) => disabled && 'cursor: not-allowed;'}
    
  input {
    padding-right: 145px;
    ${({ disabled }) => disabled && css`
        color: #babbcd; 
        cursor: not-allowed;
    `}
  }
`;

const InputLabel = styled.p`
  position: absolute;
  right: 18px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #babbcd;
`;

const OccurrenceLimitWrapper = styled.div`
  display: grid;
  grid-template-columns: 150px minmax(min-content, 225px);
  justify-content: start;
  align-items: center;
`;

export default OccurrenceLimit;
