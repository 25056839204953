import React from 'react'

import Icon from 'assets/icons/Icon'
import FilePreview from '_legacy/components/Library/components/Library/FilePreview'

const Media = ({ file, onRemove }) => {
  return (
    <div className="order-media">
      <FilePreview file={file} />
      <p className="od--main-text od--accented">{file.originalName}</p>
      <button type='button' className='order-media__remove-btn' onClick={onRemove}>
        <Icon.CloseCross fill='#9495A7' />
      </button>
    </div>
  )
}

export default Media
