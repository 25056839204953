import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { types } from 'shared';
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Button from '../Button';
import Icon from 'assets/icons/Icon';
import getInviteTypeName from './getInviteTypeName';
import { notify } from 'utils/notify';
import { useFetch } from 'store/hooks/useFetch';
import { appApi } from 'api';
import { useStateModal } from 'store/hooks/globalState/useModal';
const {
  TALENT_USER,
} = types.UserType;
const ViaLink = ({ type }) => {
  const t = useGetTranslate();
  const [linkValue, setLinkValue] = useState('');
  const closeModal = useStateModal(() => null)

  const inputRef = useRef(null);

  const [{ data, isLoading }, getLinkToInvite] = useFetch(appApi.talent.join.joinLink);

  useEffect(() => {
    (async () => {
      await getLinkToInvite({ type });
    })();
  }, []);

  useEffect(() => {
    setLinkValue(`${process.env.REACT_APP_URL}/talent/join/${data?.link}`);
    inputRef.current.focus();
  }, [data]);

  const copyToClipboard = () => {
    inputRef.current.select();
    document.execCommand('copy');
    notify(t('join-to-talent.link.button.copied'), 'success');
    closeModal()
  };

  return (
    <>
      {type === TALENT_USER ?
        (<h2 className='joinToTalent-modal__subtitle'>{t('join-to-talent.subtitle1')}</h2>) :
        (<h2 className='joinToTalent-modal__subtitle'>{t('join-to-talent.subtitle')}</h2>)}
      <form className='joinToTalent-link__form'>
        <div className='joinToTalent-link__link'>
          <span className='joinToTalent-link__label'>{t('join-to-talent.link.label')}</span>
          <input
            name='link'
            ref={inputRef}
            className='joinToTalent-link__area'
            value={isLoading ? '' : linkValue}
            autoComplete='nope'
            readOnly
          />
          <Icon.Copy className='joinToTalent-link__icon' />
        </div>
        <Button
          handleOnClick={copyToClipboard}
          text={t('join-to-talent.link.button.copy')}
          classBtn='joinToTalent-link__button joinToTalent__submit'
          loader={isLoading}
          fontSize={0.75}
        />
      </form>
      <p className='joinToTalent-link__desc'>
        <Trans
          tKey='join-to-talent.link.description'
          phs={[{ ph: '{{displayName}}', value: getInviteTypeName(type) }]}
        />
      </p>
    </>
  );
};

ViaLink.propTypes = {
  type: PropTypes.string,
};

export default ViaLink;
