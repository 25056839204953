export const videoVoiceTextContent = [
  {
    key: 'lessons.video-voice.content-1'
  },
  {
    key: 'lessons.video-voice.content-2',
  },
]

export const liveVirtualTextContent = [
  {
    key: 'lessons.live-virtual.content-1'
  },
  {
    key: 'lessons.live-virtual.content-2',
  },
  {
    key: 'lessons.live-virtual.content-3',
  },
  {
    key: 'lessons.live-virtual.content-4'
  }
]

export const liveInPersonTextContent = [
  {
    key: 'lessons.live-in-person.content-1'
  },
  {
    key: 'lessons.live-in-person.content-2',
  },
  {
    key: 'lessons.live-in-person.content-3',
  },
  {
    key: 'lessons.live-in-person.content-4',
  }
]
