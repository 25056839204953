import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledWrapper = styled.div`
  position: relative;
  margin-left: ${({ params }) => params ? '72px' : '105px'};
  padding-right: ${({ params }) => params && '28px'};

  .img-wrapper {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .params {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 1.625;
    color: #272835;
    text-align: center;
    @media only screen and (max-width: 575px) {
      font-size: 12px;
    }
    strong {
      font-weight: 700;
    }
    &--bottom {
      margin-top: 8px;
    }
    &--right{
      position: absolute;
      top: 0;
      right: 0;
      height: 90%;
      transform: rotate(180deg);
      writing-mode: vertical-rl;
    }
  }
`;
const StyledText = styled.p`
    text-align: center;
    color: #272835;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.625;
    margin-top: 8px;
    margin-bottom: 0;`;

const Tip = () => (
  <>
    <div className='params params--right'>Min. <strong>1,400 px</strong> / Ideal <strong>2,400 px</strong></div>
    <div className='params params--bottom'>Min. <strong>900 px</strong> / Ideal <strong>1,600 px</strong></div>
  </>
);

export const ExampleHint = ({ exampleImgSrc, className, params = false }) => {
  const content = params ? <Tip /> : <StyledText>Example</StyledText>;
  return (
    <StyledWrapper className={className} {...{ params }}>
      <div className='img-wrapper'>
        <img src={exampleImgSrc} alt='example-image' />
      </div>
      {content}
    </StyledWrapper>
  );
};

export const UploadHintWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  @media only screen and (max-width: 768px) {
    justify-content: center;
  }
`;

ExampleHint.propTypes = {
  className: PropTypes.string,
  roundImg: PropTypes.bool,
  exampleImgSrc: PropTypes.string.isRequired,
  params: PropTypes.bool,
};
