import { _get, _post } from 'api/utils'

export const createApplicant = ({ firstName, lastName, location }) =>
  _post('/integrations/onfido/applicants/', {
    needAuthorization: true,
    body: { firstName, lastName, location },
  })

export const checksApplicant = ({ applicantId }) =>
  _post('/integrations/onfido/checks/', {
    body: {
      applicantId: applicantId,
      privacy_notices_read_consent_given: true,
    },
  })

export const getSDKToken = ({ applicantId }) => _get(`/integrations/onfido/sdk-token/${applicantId}`)

export const getApplicantLocation = () => fetch('https://geolocation-db.com/json/').then(res => res.json())
