import React from 'react'
import { components } from 'react-select'

const DropdownIndicator = props => {
  if (!props?.hasValue) {
    return <components.DropdownIndicator {...props} />
  }
  return null
}

export default DropdownIndicator
