import React from 'react'
import PropTypes from 'prop-types'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'

import Icon from 'assets/icons/Icon'
import { useModal } from 'store/hooks/useModal'
import CategoriesSelect from '_legacy/components/CategoriesSelect'
import OutsideHandler from '_legacy/components/Form/OutsideHandler'
import { checkSubitems, getLabel, isAllCategories } from '_legacy/components/CategoriesSelect/utils'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const CategorySelectWithInput = ({ allCategoriesLabel, value, setValue, placeholder, getFn, error, showAllCategories, filterFn, withCount = true }) => {
  const t = useGetTranslate()

  const selectRef = React.useRef()
  const [isShowCategory, showCategory, hideCategory, toggleCategory] = useModal(false)
  const [localValue, setLocalValue] = React.useState([])

  const onClear = (e) => {
    e.stopPropagation()
    setValue([])
  }

  const onChange = (newState) => {
    if (isAllCategories(newState)) {
      setValue([])
      setLocalValue([])
      hideCategory()
      return
    }

    setLocalValue(newState)

    if (!checkSubitems(last(newState)) && newState.length) {
      setValue(newState)
      setLocalValue([])
      hideCategory()
    }
  }

  const onToggleCategory = (e) => {
    e.stopPropagation()
    toggleCategory()
  }

  return (
    <OutsideHandler
      className={cx('categories-select-outside-handler', {
        'categories-select-outside-handler--opened': isShowCategory
      })}
      handler={hideCategory}
    >
      <div ref={selectRef} className="categories-select-wrapper">
        <div
          className='cascader-field'
          onClick={showCategory}
        >
          <input
            className={cx('cascader-field__input', { 'cascader-field__input--error': !!error })}
            placeholder={placeholder || ''}
            value={t(getLabel(value))}
          />
          {!isEmpty(value)
            ? <Icon.DragRemove className='cascader-field__icon' onClick={onClear} />
            : <Icon.ArrowDownSelect
              className={cx('cascader-field__icon', { 'cascader-field__icon--open': isShowCategory })}
              onClick={onToggleCategory}
            />}
          {error && <p className='cascader-field__error'>{error}</p>}
        </div>
        {isShowCategory && (
          <CategoriesSelect
            filterFn={filterFn}
            onClose={hideCategory}
            getFn={getFn}
            withHeading={false}
            withCount={withCount}
            value={localValue}
            setValue={onChange}
            allCategoriesLabel={allCategoriesLabel}
            showAllCategories={showAllCategories}
          />
        )}
      </div>
    </OutsideHandler>
  )
}

CategorySelectWithInput.propTypes = {
  getFn: PropTypes.func,
  value: PropTypes.object,
  error: PropTypes.string,
  setValue: PropTypes.func,
  withCount: PropTypes.bool,
  categories: PropTypes.array,
  placeholder: PropTypes.string,
  showAllCategories: PropTypes.bool,
}

export default CategorySelectWithInput


