import React from 'react'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'
import throttle from 'lodash/throttle'
import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'

import { fetchTermsUrls } from './state/api'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { TermsAndConditionsType  } from 'shared'
import {
  TALENT_TERMS_OF_SERVICE,
  SITE_TERMS_OF_SERVICE,
  FEE_TERMS,
  COMMUNITY_GUIDELINES,
  PRIVACY_POLICY,
  BOOKING_FEE_SCALE,
} from '_legacy/constants/routes'

const MAX_WRAPPER_WIDTH = 800

pdfjs.GlobalWorkerOptions.workerSrc =
  `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`

const TermsPages = () => {
  const t = useGetTranslate()
  const { pathname } = useLocation()

  const pdfWrapperRef = React.useRef(null)
  const [url, setUrl] = React.useState()
  const [width, setWidth] = React.useState()
  const [title, setTitle] = React.useState('')

  const [numPages, setNumPages] = React.useState(0)

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
  }

  const setPDFWidth = () => {
    const widthEl = pdfWrapperRef.current.offsetWidth > MAX_WRAPPER_WIDTH
      ? MAX_WRAPPER_WIDTH
      : pdfWrapperRef.current.offsetWidth
    setWidth(widthEl)
  }

  const throttledSetPDFWidth = throttle(setPDFWidth, 500);

  React.useEffect(() => {
    setPDFWidth()

    // event listener when window is resized
    window.addEventListener('resize', throttledSetPDFWidth)

    return () => window.removeEventListener('resize', throttledSetPDFWidth)
  }, [])


  React.useEffect(() => {
    const currentType = {
      [`/${TALENT_TERMS_OF_SERVICE}`]: {
        type: TermsAndConditionsType.TALENT_TERMS_OF_SERVICE,
        title: t('terms.pdf.title.talent-terms'),
      },
      [`/${SITE_TERMS_OF_SERVICE}`]: {
        type: TermsAndConditionsType.SITE_TERMS_OF_SERVICE,
        title: t('terms.pdf.title.site-terms'),
      },
      [`/${COMMUNITY_GUIDELINES}`]: {
        type: TermsAndConditionsType.COMMUNITY_GUIDELINES,
        title: t('terms.pdf.title.guidelines-terms'),
      },
      [`/${FEE_TERMS}`]: {
        type: TermsAndConditionsType.FEES_PAYMENTS_TERMS_AND_REFUNDS,
        title: t('terms.pdf.title.fees-terms'),
      },
      [`/${PRIVACY_POLICY}`]: {
        type: TermsAndConditionsType.PRIVACY_POLICY,
        title: t('terms.pdf.title.privacy-terms'),
      },
      [`/${BOOKING_FEE_SCALE}`]: {
        type: TermsAndConditionsType.BOOKING_FEE_SCALE,
        title: t('terms.pdf.title.feescale-terms'),
      },
    }[pathname]

    setTitle(currentType?.title)

    fetchTermsUrls({ query: { type: currentType?.type } })
      .then(data => {
        if (data) {
          setUrl(data?.template?.file?.fullUrl)
        }
      })
      .catch(error => console.error(error))
  }, [])

  const onItemClick = ({ pageNumber }) => {
    document
      .querySelector(`[data-page-number="${pageNumber}"`)
      .scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div
      className={cx('pdf-wrapper', {
        'visible': !!url
      })}
      ref={pdfWrapperRef}
    >
      <h1 className="font-header-h1">{title}</h1>
      <Document
        className='pdf-document'
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={t('terms.pdf.loading.label')}
        onItemClick={onItemClick}
      >
        {!!numPages && Array.apply(null, Array(numPages))
          .map((_, index) =>
            <Page
              className='pdf-page'
              key={index}
              pageNumber={index + 1}
              width={width}
              loading=''
            />
          )
        }
      </Document>
    </div>
  )
}

export default TermsPages
