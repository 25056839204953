import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common'
import FullBodyImageCommon from './FullBodyImage'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import UploadInfoModal from '_legacy/components/Modal/UploadInfoModal';

const FullBodyImage = ({ isRequired=true }) => {
  const t = useGetTranslate()

  return (
    <StyledWrapper>
      <Row
        isRequired={isRequired}
        nameBlock={t('register.upload.fullBody')}
        component={<FullBodyImageCommon isRequired={isRequired} />}
        tip={<TipButton modalComponent={UploadInfoModal} />}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .full-body__example {
    @media only screen and (max-width: 991px) {
      margin-left: 30px;
    }
    @media only screen and (max-width: 500px) {
      margin-left: 16px;
      padding-right: 16px;
    }
    .img-wrapper {
      width: 234px;
      height: 352px;

      @media only screen and (max-width: 500px) {
        width: 153px;
        height: 230px;
      }
      @media only screen and (max-width: 375px) {
        width: 128px;
        height: 192px;
      }
    }
  }
`

FullBodyImage.propTypes = {
  name: PropTypes.string
};

export default FullBodyImage
