import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icon from 'assets/icons/Icon';

const TipButton = ({ modalComponent: Component }) => {
  const [showModal, setShowModal] = useState(false);

  const handleOpen = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  return (
    <div className='tip-container'>
      <button
        type='button'
        className='tip-container__button'
        onClick={handleOpen}
      >
        <Icon.BoxInfo className='tip-container__icon' />
      </button>
      <Component isOpen={showModal} onClose={handleClose} />
    </div>
  );
};

TipButton.defaultProps = {
  modalComponent: () => null,
};

TipButton.propTypes = {
  onClick: PropTypes.func,
  modalComponent: PropTypes.oneOfType(
    [
      PropTypes.arrayOf([PropTypes.node]),
      PropTypes.node,
      PropTypes.func
    ]).isRequired,
};

export default TipButton;
