import React from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'assets/logo.svg'
import { useUser } from 'store/hooks/globalState/useUser'
import { getCurrentUserName } from 'utils/user'
import useVideoContext from '../../hooks/useVideoContext'

export default function RoomHeader() {
  const userFullName = useUser(getCurrentUserName)
  const { room } = useVideoContext()

  return (
    <header className="video-chat-room-header">
      <div className="video-chat-room-header__inner wrapper content-wrapper">
        <Link to="/" onClick={() => room.disconnect()}>
          <Logo className="video-chat-room-header__logo" />
        </Link>
        <p className="video-chat-room-header__user">{userFullName}</p>
      </div>
    </header>
  )
}
