import prop from 'lodash/fp/prop'
import compose from 'lodash/fp/compose'
import reduce from 'lodash/fp/reduce'
import last from 'lodash/fp/last'
import join from 'lodash/fp/join'
import pluck from 'lodash/fp/pluck'
import filter from 'lodash/fp/filter'
import map from 'lodash/fp/map'
import isEmpty from 'lodash/fp/isEmpty'
import has from 'lodash/fp/has'

export const getAllTalentCount = reduce((acc, i) => acc + i.c, 0)

export const getSubcategories = prop('s')

export const getSubcategoriesCount = prop('sc')

export const getSelectedCategoryId = compose(prop('i'), last)

export const getSelectedCategoryIds = map(getSelectedCategoryId)

export const getLastSubcategories = compose(getSubcategories, last)

export const getLastCategoryTitle = compose(prop('n'), last)

export const getLastCategoryId = compose(prop('i'), last)

export const getIds = pluck('i')

export const shouldFetchMoreCategories = state => {
  const lastState = last(state)
  return lastState.sc && !lastState.s
}

export const checkSubitems = compose(Boolean, getSubcategoriesCount)

export const getLabel = compose(join('/'), filter(Boolean), pluck('n'))

export const filterRecursive = (category, foreignCategoriesIds) => {
  if (
    category.s.length === 0 &&
    foreignCategoriesIds.find(f => f === category.i)
  )
    return null

  category.s = category.s
    .map(c => {
      if (foreignCategoriesIds.find(f => f === c.i)) return null
      return filterRecursive(c, foreignCategoriesIds)
    })
    .filter(Boolean)

  return category
}

export const filterCategoriesByIds = (categories, foreignCategoriesIds) =>
  (categories || [])
    .map(c => filterRecursive(c, foreignCategoriesIds))
    .filter(Boolean)

const getCategoryObj = obj => ({
  i: obj.i,
  n: obj.n,
  s: [obj.i],
})

const hasId = has('i')
const hasName = has('n')

export const getCategoryAdapter = category => {
  const arr = []

  ;[category]
    .filter(Boolean)
    .filter(hasId)
    .filter(hasName)
    .forEach(item => {
      arr.push(getCategoryObj(item))
      if (!isEmpty(item.s)) {
        arr.push(...getCategoryAdapter(item.s))
      }
    })

  return arr
}

export const getFullCategoryAdapter = category => {
  const arr = []

  ;[category]
    .filter(Boolean)
    .filter(has('id'))
    .filter(has('name'))
    .forEach(item => {
      arr.push({
        id: item.id,
        name: item.name,
        subcategories: [item.id],
      })
      if (!isEmpty(item.subcategory)) {
        arr.push(...getFullCategoryAdapter(item.subcategory))
      }
    })

  return arr
}

export function getCategoriesAdapter(categories, id) {
  if (categories.find(c => c.i === id)) {
    return [categories.find(c => c.i === id)]
  }

  const path = categories
    .map(c => {
      const found = getCategoriesAdapter(c.s, id)

      if (found.length === 0) return undefined

      return [c, ...found]
    })
    .filter(Boolean)
    .flat(1)

  return path
}

export const isAllCategories = compose(isEmpty, prop([0, 'i']))

export const mapper = (state, ids, level, pointer = 0) => {
  if (level === 0) return state

  const found = [...(state || [])].find(item => item.i === ids[pointer])

  if (pointer < level - 1 && ids[pointer + 1] && found?.sc)
    return mapper(found.s, ids, level, pointer + 1)

  return found?.s
}
