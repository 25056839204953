import React from 'react'

import LineString from '../common/LineString'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { statuses } from '_legacy/configs/table/recurringList'

export default React.memo(function Status({ isLoading, status }) {
  const t = useGetTranslate()

  return (
    <LineString
      isLoading={isLoading}
      str={t(statuses[status]?.label)}
      className='centered'
    />
  )
})
