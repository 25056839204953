import { types } from 'shared'
const { PERSON } = types.AficionadoUserType
import kebabCase from 'lodash/kebabCase'

export const profileTypes = [PERSON] // todo: replace with shared

const getProfileTypeLabel = (type) => `register-aficionado.form.type.options.${kebabCase(type)}`

const getProfileTypeOption = (type, t) => ({
  value: type,
  label: t(getProfileTypeLabel(type))
})

export const getProfileTypesWithTranslate = t => profileTypes.map(type => getProfileTypeOption(type, t))
