import React from 'react'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common'
import FullBodyImage from './FullBodyImage'
import PreviousImages from './PreviousImages'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import UploadInfoModal from '_legacy/components/Modal/UploadInfoModal';

const FullBodyWithPrevious = ({ name, namePrevious, isRequired }) => {
  const t = useGetTranslate()
  return (
    <Row
      isRequired={isRequired}
      nameBlock={t('register.upload.fullBody')}
      tip={<TipButton modalComponent={UploadInfoModal} />}
      component={
        <FullBodyImage
          name={name}
          isRequired={isRequired}
          component={<PreviousImages name={name} namePrevious={namePrevious} />}
        />
      }
    />
  )
}

FullBodyWithPrevious.propTypes = {
  name: PropTypes.string,
  namePrevious: PropTypes.string,
}

export default FullBodyWithPrevious
