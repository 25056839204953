import { prop, identity, cond, propEq, T } from 'lodash/fp'

import { OrderStatusFactory } from '../utils'

export const statuses = new OrderStatusFactory(
  prop('orderStatus'),
  prop('status'),
  identity,
  prop('pendingAction.type')
)

export const cancelModalProps = {
  hasPendingBookingRequests: {
    message: 'cancel-order-modal.pending-booking-requests-message',
    refundBtnLabel: 'cancel-order-modal.controls.reject-all-and-cancel'
  },
  hasBookedParticipants: {
    message: 'cancel-order-modal.booked-participant-message',
    refundBtnLabel: 'cancel-order-modal.controls.reject-all-and-cancel'
  },
  default: {
    message: 'cancel-order-modal.message',
    refundBtnLabel: 'cancel-order-modal.controls.reject-all-and-cancel'
  }
}

export const getCancelModalProps = cond([
  [propEq('hasBookedParticipants', true), () => cancelModalProps.hasBookedParticipants],
  [propEq('hasPendingBookingRequests', true), () => cancelModalProps.hasPendingBookingRequests],
  [T, () => cancelModalProps.default],
])
