import { always, cond, merge, pipe, T } from 'lodash/fp'
import { statuses } from '../utils'

const fillVideoVoiceOverLessonOrderConfig = merge({
  isShowUpload: false,
  isShowWaitForPayment: false,
  isShowCanceled: false,
  isShowClosed: false,
  isShowCompleted: false
})

export const getVideoVoiceOverLessonOrderConfig = pipe(
  cond([
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [statuses.isOrderClosed, always({ isShowClosed: true })],
    [statuses.isOrderCompleted, always({ isShowCompleted: true })],

    [statuses.isOrderUploadVideo, always({ isShowUpload: true })],
    [statuses.isOrderWaitForPayment, always({ isShowWaitForPayment: true })],

    [T, always({})],
  ]),
  fillVideoVoiceOverLessonOrderConfig
)
