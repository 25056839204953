// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

// Assets
import Icon from 'assets/icons/Icon'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { getDateRange } from 'utils/date'
import { getLocationDetails, getLocationName } from 'utils/location'

// Components
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'

import { routesList } from 'router/routesMap'


export const EventDetails = ({ chatInfo }) => {
  const t = useGetTranslate()

  const {
    event: { typesOfDate, startTime, endTime },
  } = chatInfo

  const eventDateType = t(`chats.chat.event-details.event-date-type.${typesOfDate.toLowerCase()}`)
  const eventDate = getDateRange([startTime, endTime])

  const locationsTitle = getLocationName(chatInfo?.event)
  const locationsText = getLocationDetails(chatInfo?.event)

  const infoConfig = [
    {
      icon: <Icon.Calendar />,
      item: eventDateType,
      subitem: eventDate,
    },
    {
      icon: <Icon.Pin />,
      item: locationsTitle,
      subitem: locationsText,
    },
  ]

  const getLink = () => {
    let path = routesList.inPersonExperienceBookingDetails(chatInfo.event?.orderId);

    if (chatInfo.event?.currentUserIsEventOwner) {
      path = routesList.inPersonExperienceOrderDetails(chatInfo.event?.id);
    }

    return {
      path,
      text: t('chats.chat.event-details.order.link-text'),
    }
  }

  const link = getLink()

  return (
    <div className="active-chat-details__event-info">
      <div className="event-title">{chatInfo.event.title}</div>

      {infoConfig.map((item, i) => {
        return <OfferingsDetailsHeaderItem key={i} {...item} />
      })}

      <div className="event-link__wrapper">
        <Link className="event-link__link" to={link.path}>
          <div className="event-link__link--text">{link.text}</div>
          <div className="event-link__link--arrow" />
        </Link>
      </div>
    </div>
  )
}

EventDetails.propTypes = {
  chatInfo: PropTypes.object.isRequired,
}
