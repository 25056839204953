// default settings can be changed if needed
export const defaultCalendarConfig = {
  themeSystem: 'standard',
  allDaySlot: false,
  editable: true,
  selectable: true,
  selectMirror: true,
  selectOverlap: false,
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short'
  }
}

export const initialView = {
  ['week']: 'timeGridWeek',
  ['day']: 'timeGridDay',
}

