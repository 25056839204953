import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'

import Counter from './Counter'
import CustomSubSelect from './CustomSubSelect'

export default function SubFilters ({
  className = '', counterConfig, subFiltersConfig = [], getFn, setFn
}) {
  const parsedSubFiltersConfig = React.useMemo(() => {
    return subFiltersConfig.map((filter, i) => {
      return (
        <CustomSubSelect
          key={i}
          {...filter}
          getFn={getFn}
          setFn={setFn}
        />
      )
    })
  }, [subFiltersConfig, getFn, setFn])

  if (!getFn) {
    console.warn('<SubFilters /> missed "getFn"')
    return null
  }
  if (!setFn) {
    console.warn('<SubFilters /> missed "setFn"')
    return null
  }

  return (
    <StyledWrapper className={className}>
      {counterConfig && (
        <Counter
          {...counterConfig}
          getFn={getFn}
        />
      )}
      <div className='subfilter-container'>
        {parsedSubFiltersConfig}
      </div>
    </StyledWrapper>
  )
}

SubFilters.propTypes = {
  className: PropTypes.string,
  counterConfig: PropTypes.object,
  subFiltersConfig: PropTypes.array,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  .subfilter-container {
    display: flex;
  }
`
