import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useModal } from 'store/hooks/useModal';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useRemoveFileFromFileLibrary } from 'requests/file-library'
import ConfirmRemoveItemModal from '_legacy/components/Modal/ConfirmRemoveItemModal'

const RemoveButton = ({ file }) => {
  const t = useGetTranslate()
  const [isModalOpen, openModal, closeModal] = useModal(false)
  const removeFile = useRemoveFileFromFileLibrary()

  const onRemove = () => {
    if (file.isLoading) {
      removeFile(file)
    } else {
      openModal()
    }
  }

  const removeHandler = () => {
    removeFile(file)
    closeModal()
  }

  return (
    <>
      <StyledRemoveButton onClick={onRemove}>
        <i className="icon-trash" />
        <span>{t('shared.words.delete')}</span>
      </StyledRemoveButton>
      <ConfirmRemoveItemModal
        file={file}
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        onAgreeClick={removeHandler}
        onDisagreeClick={closeModal}
      />
    </>
  )
}

const StyledRemoveButton = styled.button.attrs({ type: 'button' })`
  cursor: pointer;
  padding: 0;
  border: none;
  background-color: transparent;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.lightGrey};

  i {
    font-size: 15px;
  }
`

RemoveButton.propTypes = {
  file: PropTypes.object,
}

export default RemoveButton
