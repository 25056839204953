import React from 'react'

import ParticipantTracks from '../ParticipantTracks'
import ParticipantInfo from '../ParticipantInfo'

export default function Participant({
  participant,
  videoOnly,
  enableScreenShare,
  onClick,
  isLocalParticipant,
  hideParticipant,
}) {
  return (
    <ParticipantInfo
      participant={participant}
      onClick={onClick}
      isLocalParticipant={isLocalParticipant}
      hideParticipant={hideParticipant}
    >
      <ParticipantTracks
        participant={participant}
        videoOnly={videoOnly}
        enableScreenShare={enableScreenShare}
        isLocalParticipant={isLocalParticipant}
      />
    </ParticipantInfo>
  )
}
