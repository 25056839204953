import React from 'react'
import MaskInput from './MaskInput'
import PropTypes from 'prop-types'

const PriceInput = ({
  name,
  rules,
  defaultValue,
  placeholder,
  requiredMessage,
  isRequired,
  isDisabled,
  className,
  whiteTheme,
  onCustomChange,
  ...props
}) => {
  return (
    <MaskInput
      prefix="$ "
      suffix=" (USD)"
      rules={{
        min: {
          message: 'shared.input.price.errors.min',
          value: 5,
        },
        max: {
          message: 'shared.input.price.errors.max',
          value: 100000,
        },
        ...rules,
      }}
      name={name}
      placeholder={placeholder}
      defaultValue={defaultValue}
      requiredMessage={requiredMessage}
      isRequired={isRequired}
      isDisabled={isDisabled}
      whiteTheme={whiteTheme}
      className={className}
      onCustomChange={onCustomChange}
      {...props}
    />
  )
}

PriceInput.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  whiteTheme: PropTypes.bool,
  className: PropTypes.string,
  requiredMessage: PropTypes.string,
  onCustomChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default PriceInput
