import prop from 'lodash/fp/prop'
import last from 'lodash/fp/last'
import compose from 'lodash/fp/compose'

const getCategoryIn = compose(
  prop('i'),
  last
)

export const parseTalentCategoryIn = params => {
  const { categoryIn, ...rest } = params
  return {
    ...rest,
    categoryIn: getCategoryIn(categoryIn)
  }
}

export const parseOfferCategoryIn = params => {
  const { categoryIn, ...rest } = params
  return {
    ...rest,
    talentCategoryIn: getCategoryIn(categoryIn)
  }
}
