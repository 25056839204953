import { addSeconds, subSeconds } from 'date-fns'

export const applyDriveTime = slots => {
  return slots.map(slot => {
    return {
      ...slot,
      start: new Date(subSeconds(new Date(slot.start), slot.beforeDriveTime || 0).toISOString()),
      end: new Date(addSeconds(new Date(slot.end), slot.afterDriveTime || 0).toISOString())
    }
  })
}
