import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function Title () {
  const t = useGetTranslate()
  return (
    <StyledWrapper>
      {t('home.why-entreevip.header.title')}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.h1`
  font-family: 'Playfair Display';
  font-size: 42px;
  font-weight: 900;
  color: #fff;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 32px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 28px;
  }
`
