import { _post, _get, _patch } from 'api/utils'

export const createLiveVirtualEvents = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/live-virtual-events`,
  { body: data, needAuthorization: true },
)

export const createLiveVirtualEventsDraft = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/live-virtual-events/drafts`,
  { body: data, needAuthorization: true },
)


export const getLiveVirtualEventsTemplates = ({ talentId, limit = 100, name }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/templates`,
  { query: { limit, name }, needAuthorization: true },
)

export const getLiveVirtualEventsTemplateById = ({ liveVirtualEventTemplateId, talentId, isDraft }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/templates/${liveVirtualEventTemplateId}`,
  { query: { isDraft }, needAuthorization: true },
)

export const getLiveVirtualEventById = ({ talentId, talentLiveVirtualEventId }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/${talentLiveVirtualEventId}`,
  { needAuthorization: true }
)

export const patchLiveVirtualEventById = ({ talentId, talentLiveVirtualEventId, body }) => _patch(
  `/talents/${talentId}/offers/live-virtual-events/${talentLiveVirtualEventId}`,
  { needAuthorization: true, body }
)

export const getLiveVirtualEventDraftById = ({ talentId, liveVirtualEventDraftId }) => _get(
  `/talents/${talentId}/offers/live-virtual-events/drafts/${liveVirtualEventDraftId}`,
  { needAuthorization: true }
)

export const patchLiveVirtualEventDraftById = ({ talentId, liveVirtualEventDraftId, body }) => _patch(
  `/talents/${talentId}/offers/live-virtual-events/drafts/${liveVirtualEventDraftId}`,
  { needAuthorization: true, body }
)
