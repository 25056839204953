import React from 'react'
import PropTypes from 'prop-types'
import { usePopperTooltip } from 'react-popper-tooltip'
import propEq from 'lodash/fp/propEq'
import cond from 'lodash/fp/cond'
import always from 'lodash/fp/always'
import T from 'lodash/fp/T'
import prop from 'lodash/fp/prop'
import compose from 'lodash/fp/compose'
import divide from 'lodash/fp/divide'
import subtract from 'lodash/fp/subtract'
import concat from 'lodash/fp/concat'
import sortBy from 'lodash/fp/sortBy'
import last from 'lodash/fp/last'
import __ from 'lodash/fp/__'
import useWith from 'lodash/fp/useWith'
import lt from 'lodash/fp/lt'
import cx from 'classnames'

// (padding top (8) + padding bottom(8) + line height(22)) / 2 = 19
const TOP_GAP = 19

const getParams = compose(
  concat(__, 10),
  subtract(__, TOP_GAP),
  divide(__, 2),
  prop('popper.height')
)

const getOffset = cond([
  [propEq('placement', 'right'), getParams],
  [propEq('placement', 'left'), getParams],
  [T, always([])],
])

const getChildMaxWidth = compose(
  prop('scrollWidth'),
  last,
  sortBy('scrollWidth'),
  prop('children')
)

const getWrapperWidth = prop('current.offsetWidth')

const shouldShowTooltip = useWith(lt, [getWrapperWidth, getChildMaxWidth])

function PopperTooltip({ children, Component, isStretch, ...props }) {
  const wrapperRef = React.useRef()
  const {
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    triggerRef
  } = usePopperTooltip({
    placement: 'auto',
    interactive: true,
    offset: getOffset,
    ...props,
  })

  const showTooltip = shouldShowTooltip(wrapperRef, triggerRef)

  const classes = cx('tooltip-wrapper', {
    'tooltip-wrapper--stretch': isStretch
  })

  if (!Component) return children

  return (
    <div className={classes} ref={wrapperRef}>
      <span className="tooltip-children" ref={setTriggerRef}>
        {children}
      </span>
      {showTooltip && visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'tooltip-container' })}
        >
          {Component}
          <div {...getArrowProps({ className: 'tooltip-arrow' })} />
        </div>
      )}
    </div>
  )
}

PopperTooltip.propTypes = {
  children: PropTypes.node,
  Component: PropTypes.node,
}

export default PopperTooltip
