export const happensBlockConfig = [
  {
    numb: '01',
    title: 'request-video-mes-commercial.happens.block1.title',
    desc: 'request-video-mes-commercial.happens.block1.desc'
  },
  {
    numb: '02',
    title: 'request-video-mes-commercial.happens.block2.title',
    desc: 'request-video-mes-commercial.happens.block2.desc'
  },
  {
    numb: '03',
    title: 'request-video-mes-commercial.happens.block3.title',
    desc: 'request-video-mes-commercial.happens.block3.desc'
  },
]
