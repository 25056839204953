import React from 'react'
import get from 'lodash/get'

import { useModal } from 'store/hooks/globalState/useModal'

import modalsConfig from '_legacy/components/Modal/modals-config'


const withModal = Component => props => {
  const modalType = useModal(state => get(state, 'type'))
  const modalProps = useModal(state => get(state, 'props'))

  if (!modalType) return <Component {...props} />

  const Modal = modalsConfig[modalType]

  return (
    <>
      <Component {...props} />
      <Modal {...modalProps} />
    </>
  )
}

export default withModal
