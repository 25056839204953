import { _get, _patch, _post } from 'api/utils'

export const fetchVideoChatsOrderDetailsHeader = ({ id }) => _get(`/offers/video-chats/occurrences/${id}`, {
  needAuthorization: true,
})

export const videoChatsReject = ({ id }) => _post(`/offers/video-chats/occurrences/${id}/reject`, {
  needAuthorization: true,
})

export const updateVideoChatOccurrenceTime = ({ data, offerVideoChatOccurrenceId }) =>
  _patch(`/offers/video-chats/occurrences/${offerVideoChatOccurrenceId}/update-time`, {
    body: data,
    needAuthorization: true,
  })

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })
