import React from 'react'
import PropTypes from 'prop-types'
import RowInput from '_legacy/common/Input/RowInput'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { validateEventNameMinLength } from 'utils/services/validators/eventNameValidator'
const Name = ({ updateNameValue2, isFocus, }) => {
  const nameInputRef = React.useRef(null)

  const handleNameChange = e => {
    if (isFocus) {
      const newValue = e.target.value
      updateNameValue2(newValue)
    }
  }


  const t = useGetTranslate()
  return (
    <RowInput
      isRequired
      name="name"
      label={t('create-experience.form.name.label')}
      placeholder={t('create-experience.form.name.placeholder')}
      onChange={e => handleNameChange(e)}
      ref={nameInputRef}
      isFocus={isFocus}
      validation={{
        validate: {
          minLength: eventName => validateEventNameMinLength(eventName),
          maxLength: eventName => (eventName || '').length < 50 || 'create-experience.form.name.errors.max-length',
        },
        required: 'create-experience.form.name.errors.required',
      }}
    />
  )
}

Name.propTypes = {
  updateNameValue: PropTypes.func,
  isFocus: PropTypes.bool,
}

export default Name
