import React from 'react';
import { useParams } from 'react-router-dom';
import { useStripe } from '@stripe/react-stripe-js';
import isFunction from 'lodash/isFunction'

import { purchaseEntourageStatus, sendPaymentData } from 'api/appApi/purchase'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { createPaymentMethod, useSetDefaultPaymentCard } from './payments';
import { notify } from 'utils/notify';
import { usePaymentCards } from 'store/hooks/globalState/usePaymentCards';

const DELAY_AFTER_PURCHASE = 1000;

export const usePurchase = ({ setLoading, setDisabled, saveCard, onSuccess }) => {
  const t = useGetTranslate()
  const stripe = useStripe()
  const { orderId } = useParams()
  const paymentCards = usePaymentCards()
  const setDefaultPaymentCard = useSetDefaultPaymentCard()

  return React.useCallback(async ({ card, billing_details, promoCodeId, defaultPaymentId, shippingAddress }) => {
    setLoading(true)

    const sendPayment = async (paymentMethodId) => {
      const { isNeedRequiresAction, secret } = await sendPaymentData({ orderId, paymentMethodId, promoCodeId, saveCard, shippingAddress })
      if (isNeedRequiresAction) {
        const resp = await stripe.confirmCardPayment(secret, { receipt_email: billing_details.email, payment_method: paymentMethodId })
        if (saveCard && paymentCards.length === 0) setDefaultPaymentCard(paymentMethodId)
        if (resp.error) throw resp.error.code;
        if (resp.paymentIntent.status !== 'succeeded') throw resp.code;
      }
    };

    try {
      let paymentMethodId

      if (defaultPaymentId) {
        paymentMethodId = defaultPaymentId
      } else {
        const { id } = await createPaymentMethod({ stripe, card, billing_details })
        paymentMethodId = id
      }

      await sendPayment(paymentMethodId)

      // TODO: remove setTimeout - needs to be fixed on BE side(await while data on stripe is changed and then send response to client)
      if (isFunction(onSuccess)) {
        setTimeout(onSuccess, DELAY_AFTER_PURCHASE)
        // Delayed loading
        setTimeout(() => setLoading(false), DELAY_AFTER_PURCHASE)
        // Delayed disabled because when button is disabled loader isn't shown
        setTimeout(() => setDisabled(true), DELAY_AFTER_PURCHASE)
      } else {
        setLoading(false)
        setDisabled(true)
      }
    } catch (errorCode) {
      const errorMessage = errorCode ? `payment-page.error.card.${errorCode}` : 'payment-page.error.card.some-error';

      notify(t(errorMessage), 'error');
      setLoading(false);
    }
  }, [stripe, orderId, setLoading, setDisabled, saveCard])
}

export const useEntouragePurchase = ({ setLoading, setDisabled, saveCard, onSuccess }) => {
  const t = useGetTranslate()
  const stripe = useStripe()
  const paymentCards = usePaymentCards()
  const setDefaultPaymentCard = useSetDefaultPaymentCard()

  return React.useCallback(async ({ card, billing_details, defaultPaymentId, orderId }) => {
    setLoading(true)

    const sendPayment = async (paymentMethodId) => {
      const { isNeedRequiresAction, secret } = await purchaseEntourageStatus({ orderId, paymentMethodId, saveCard })
      if (isNeedRequiresAction) {
        const resp = await stripe.confirmCardPayment(secret, { receipt_email: billing_details.email, payment_method: paymentMethodId })
        if (saveCard && paymentCards.length === 0) setDefaultPaymentCard(paymentMethodId)
        if (resp.error) throw resp.error.code;
        if (resp.paymentIntent.status !== 'succeeded') throw resp.code;
      }
    };

    try {
      let paymentMethodId

      if (defaultPaymentId) {
        paymentMethodId = defaultPaymentId
      } else {
        const { id } = await createPaymentMethod({ stripe, card, billing_details })
        paymentMethodId = id
      }

      await sendPayment(paymentMethodId)
      setDisabled(true)

      if (isFunction(onSuccess)) onSuccess()
    } catch (errorCode) {
      const errorMessage = errorCode ? `payment-page.error.card.${errorCode}` : 'payment-page.error.card.some-error';

      notify(t(errorMessage), 'error');
    }
    finally {
      setLoading(false);
    }
  }, [stripe, setLoading, setDisabled, saveCard])
}
