import React from 'react'

import Favorites from '_legacy/components/Favorites'
import EmptyOffersList from '_legacy/components/Search/List/EmptyOffersList'

import {
  useFavoritesOffers,
  useFavoritesOffersFilters,
  useClearOffersFilters,
  useSetFavoritesOffersFilters,
} from 'store/hooks/globalState/useFavorites'
import { useFetchFavoriteOffers } from 'requests/favorites.js'

const FavoritesOfferings = () => {
  return (
    <Favorites
      getListFn={useFavoritesOffers}
      clearFn={useClearOffersFilters}
      fetchListFn={useFetchFavoriteOffers}
      getFiltersFn={useFavoritesOffersFilters}
      EmptyListComponent={EmptyOffersList}
      setFiltersFn={useSetFavoritesOffersFilters}
    />
  )
}


export default FavoritesOfferings
