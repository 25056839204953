import React from 'react'

import { Trans } from 'store/hooks/globalState/useTranslates'

const zeroPad = (num, places) => String(num).padStart(places, '0')


const OrderedRulesList = ({ rules }) => (
  <ul className='rules-list'>
    {
      rules.map((item, index) => (
        <li key={`rules_${index}`}>
          <span>{zeroPad(index + 1, 2)}</span>
          <div>
            { <Trans tKey={item.key} phs={item.phs || []} /> }
          </div>
        </li>
      ))
    }
  </ul>
)

export default OrderedRulesList
