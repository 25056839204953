import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import has from 'lodash/fp/has';
import PropTypes from 'prop-types';
import propOr from 'lodash/fp/propOr'
import prop from 'lodash/fp/prop'

import PageLoader from '_legacy/common/Template/PageLoader';
import SearchTalentCard from '_legacy/components/Search/SearchCardItem/SearchTalentCard';
import SearchOfferingCard from '_legacy/components/Search/SearchCardItem/SearchOfferingCard';
import Icon from 'assets/icons/Icon';

import { sanitizeFilterFields } from '_legacy/components/Table/utils';

import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.min.css';
import '_legacy/components/Search/FeaturedSlider/index.scss'

SwiperCore.use([Navigation, Pagination]);

const isOffering = has('offerType')

const MobileList = ({ getListFn, EmptyList, getFn, fields, fetchTableFn, isMobile }) => {
  const list = getListFn()
  const page = getFn(propOr(1, 'page'))
  const total = getFn(prop('count'))
  const fetchTableData = fetchTableFn({ isMobile })
  const filtersOptions = getFn(current => sanitizeFilterFields({ current, fields }), [fields])

  const prevRef = React.useRef(null);
  const nextRef = React.useRef(null);
  const [activeIndex, setActiveIndex] = React.useState(1)

  const { push } = useHistory()
  const { search, state, pathname } = useLocation()

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search)
    queryParams.delete('page')
    push(`${state || pathname}`)
  }, [])

  const navigate = ({ activeIndex }) => {
    setActiveIndex(activeIndex)
    if ((page * 10 - 2) === activeIndex) {
      fetchTableData({ ...filtersOptions, page: page + 1 })
    }
  }

  if (!list) return <PageLoader />

  if (list.length === 0) return <EmptyList />

  //TODO: [data-disabled] - this solution is added because, without loop - there is a switching through one slide.
  return (
    <div className='search-feature__slider'>
      <div className='search-feature__nav'>
        <div
          ref={prevRef}
          data-disabled={activeIndex === 1}
          className='search-feature__nav_btn search-feature__nav_btn--prev'
        >
          <Icon.ArrowSliderPrev />
        </div>
        <div className='search-feature__nav_pagination'>
          <span className="swiper-pagination-current">{activeIndex}</span>
          &nbsp; / &nbsp;
          <span className="swiper-pagination-total">{total}</span>
        </div>
        <div
          ref={nextRef}
          data-disabled={activeIndex === total}
          className='search-feature__nav_btn search-feature__nav_btn--next'
        >
          <Icon.ArrowSliderNext />
        </div>
      </div>

      <Swiper
        slidesPerView={1}
        loop
        simulateTouch={false}
        onSlideChange={navigate}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = prevRef.current;
          swiper.params.navigation.nextEl = nextRef.current;
          swiper.navigation.init();
          swiper.navigation.update();
        }}
      >
        {list.map(value => (
          <SwiperSlide key={value.id} className='search-feature__slider_slide'>
            {isOffering(value)
              ? <SearchOfferingCard value={value} />
              : <SearchTalentCard value={value}/>}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

MobileList.propTypes = {
  getListFn: PropTypes.func,
  EmptyList: PropTypes.func,
  getFn: PropTypes.func,
  fields: PropTypes.array,
  fetchTableFn: PropTypes.func,
  isMobile: PropTypes.bool,
}

export default MobileList;
