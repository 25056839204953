import React from 'react';

import { Row } from '_legacy/common/Template';
import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import Body2 from '_legacy/common/Typography/Body2';

const HideAddressFromPubView = () => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('create-experience.form.hide-address-from-pub-view.label')}
      component={
        <>
          <Body2 marginBottom='24px'>{t('create-experience.form.hide-address-from-pub-view.content')}</Body2>
          <RadioSwitcher name='hideAddressFromPubView' />
        </>
      }
    />
  );
};

export default HideAddressFromPubView;
