import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'portals/ModalPortal';
import styled from 'styled-components';
import { useSetModal } from 'store/hooks/globalState/useModal';
import { globalContext } from 'store/hooks/globalState/useGlobalState';
import Button from '_legacy/components/Button'
import { ENTOURAGE, FULL } from '_legacy/constants/routes';
import { UPGRADE } from '_legacy/constants/routes';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { Trans } from 'store/hooks/globalState/useTranslates';
import { useExperiencePublicView } from 'store/hooks/globalState/usePublicView';
import { prop } from 'lodash/fp'
const EntourageModal = () => {
  const t = useGetTranslate();
  const context = React.useContext(globalContext)
  const closeModal = useSetModal(() => null)
  const experience = useExperiencePublicView(prop('experience'))
  const { entourageType } = experience;
  return (
    <ModalPortal isOpen onClose={closeModal} showCloseButton >
      {context.user.value.user.entourageType === 'LITE' ?
        (
          <div className="entourage-modal-card ">
            <p className="entourage-modal__text">
              {t('shared.words.entourage-required-full')}
            </p>
            <ButtonContainer>
              <Button
                stretch
                text={t('shared.words.go-back')}
                typeButton="button"
                paddingVertical={13}
                handleOnClick={closeModal}
                primary
              />
              <Button
                stretch
                text={t('shared.words.upgrade')}
                typeButton="button"
                paddingVertical={13}
                handleOnClick={closeModal}
                isLink={true}
                path={`/${ENTOURAGE}/${UPGRADE}`}
              />
            </ButtonContainer>
          </div>
        ):(
          <div className="entourage-card">
            <p className="check-email-modal__text">
              <Trans
                tKey="shared.words.entourage-required"
                phs={[
                  { ph: '{{entourageType}}', value: entourageType==='EITHER' ? 'FULL or LITE' : 'FULL' },
                ]}
              />
            </p>
            <ButtonContainer>
              <Button
                stretch
                text={t('shared.words.go-back')}
                typeButton="button"
                paddingVertical={13}
                handleOnClick={closeModal}
                primary
              />
              <Button
                stretch
                text={t('shared.words.join-now')}
                typeButton="button"
                paddingVertical={13}
                handleOnClick={closeModal}
                isLink={true}
                path={`/${ENTOURAGE}/${FULL}`}
              />
            </ButtonContainer>
          </div>)}
    </ModalPortal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media screen and (max-width: 575px) {
    margin-top: auto;
  }
  .button:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    width: 100%;

    .button:not(:last-of-type) {
      margin-right: 0;
      margin-top: 16px;
    }
  }
`

EntourageModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default EntourageModal;
