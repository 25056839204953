import { TypesOfEntourage } from 'shared'
import { cond, propEq, prop, T, always } from 'lodash/fp'

export const submitEntourageAdapter = cond([
  [propEq('entourage', 'true'), prop('entourageType')],
  [T, always(null)],
])

export const getEntourageAdapter = cond([
  [propEq('entourageType', TypesOfEntourage.EITHER), always('true')],
  [propEq('entourageType', TypesOfEntourage.FULL), always('true')],
  [T, always('false')],
])

export const getEntourageTypeAdapter = cond([
  [propEq('entourageType', null), always(TypesOfEntourage.FULL)],
  [T, prop('entourageType')],
])
