import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { charitiesLoader } from './config'

import Select from '../index'

const CharitiesSelect = ({ getFn, setFn, field, disabled }) => {
  const t = useGetTranslate()

  const value = getFn(state => get(state, field))
  const setValue = setFn((prev, next) => ({ ...prev, [field]: next }))

  React.useEffect(() => {
    if (disabled && value) {
      setValue(undefined)
    }
  }, [value, disabled])

  return (
    <StyledWrapper className='filter'>
      <Select
        isDisabled={disabled}
        placeholder={t('search.filters.charities.placeholder')}
        loadOptions={charitiesLoader}
        onChange={setValue}
        value={value}
        defaultOptions
        loadOptionsOnMenuOpen
        cacheOptions
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .react-select__control {
    border: 2px solid rgb(233, 234, 240);
    height: 48px;
    background-color: white;
  }
`

CharitiesSelect.propTypes = {
  getFn: PropTypes.func,
  setFn: PropTypes.func,
  field: PropTypes.string,
}

export default CharitiesSelect
