import React from 'react'
import { identity, assoc, compact } from 'lodash/fp'

import { useCurrentPage } from './useUtils'
import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

const stateName = 'notifications'

const notificationsTableState = 'notificationsTableState'
const notificationsTableFilters = 'notificationsTableFilters'

const notificationsMenuState = 'notificationsMenuState'
const notificationsMenuFilters = 'notificationsMenuFilters'

export const useNotifications = createUseGlobalState(stateName)
export const useSetNotifications = createUseSetGlobalState(stateName)

// table
export const useNotificationsTableState = createUseGlobalState(
  notificationsTableState
)
export const useSetNotificationsTableState = createUseSetGlobalState(
  notificationsTableState
)

export const useNotificationsTableFilters = createUseGlobalState(
  notificationsTableFilters
)
export const useSetNotificationsTableFilters = createUseSetGlobalState(
  notificationsTableFilters
)

// menu
export const useNotificationsMenuState = createUseGlobalState(
  notificationsMenuState
)
export const useSetNotificationsMenuState = createUseSetGlobalState(
  notificationsMenuState
)

export const useNotificationsMenuFilters = createUseGlobalState(
  notificationsMenuFilters
)
export const useSetNotificationsMenuFilters = createUseSetGlobalState(
  notificationsMenuFilters
)

// selectors
export const useNotificationsTable = (fn = identity) => {
  const ids = useNotificationsTableState()
  return useNotifications(
    notifications => fn((ids && notifications) ? compact(ids.map(id => notifications[id])) : null),
    [ids]
  )
}

export function useIncreaseUnReadNotification() {
  return useSetNotificationsMenuFilters((filters, count = 0) => ({
    ...filters,
    count: filters.count + count,
  }))
}

export function useDecreaseUnReadNotification() {
  return useSetNotificationsMenuFilters((filters, count = 0) => ({
    ...filters,
    count: filters.count - count,
  }))
}

export function useAddNotification() {
  const increaseUnReadNotificationCount = useIncreaseUnReadNotification()

  const addNotification = useSetNotifications((state, notification) =>
    assoc(notification.id, notification, state)
  )

  const page = useCurrentPage()
  const addNotificationToTable = useSetNotificationsTableState(
    (table = [], notification) => [notification.id, ...table]
  )

  return React.useCallback(
    notification => {
      addNotification(notification)
      increaseUnReadNotificationCount(1)

      if (page === 1) {
        addNotificationToTable(notification)
      }
    },
    [
      addNotification,
      increaseUnReadNotificationCount,
      addNotificationToTable,
      page,
    ]
  )
}
