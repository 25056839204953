import React from 'react'
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'
import BackdropWrapper from '../../components/Backdrop/BackdropWrapper';

const FullBodyBackdrop = () => {
  const t = useGetTranslate()
  return (
    <FullBodyBackdropWrapper>
      <Icon.Camera />
      <h3>{t('register.upload.hint.label')}</h3>
      <p>{t('register.upload.hint.description')}</p>
    </FullBodyBackdropWrapper>
  )
}

const FullBodyBackdropWrapper = styled(BackdropWrapper)`
`

export default FullBodyBackdrop
