import { filters } from '_legacy/components/Table/Filters/config'
import { offeringsTypesOptions } from '../../table/managePromoCodes'

export const offersTypeIn = {
  type: filters.select,
  placeholder: 'search.filters.offerings-type.placeholder',
  field: 'offeringTypesIn',
  options: offeringsTypesOptions
}

const priceOptions = [
  { value: { from: 0, to: 99 }, label: 'search.filters.price.from-0-to-100' },
  { value: { from: 100, to: 249 }, label: 'search.filters.price.from-100-to-250' },
  { value: { from: 250, to: 499 }, label: 'search.filters.price.from-250-to-500' },
  { value: { from: 500, to: 999 }, label: 'search.filters.price.from-500-to-1000' },
  { value: { from: 1000, to: 4999 }, label: 'search.filters.price.from-1000-to-5000' },
  { value: { from: 5000 }, label: 'search.filters.price.from-5000' },
]

export const priceConfig = {
  type: filters.select,
  placeholder: 'search.filters.price.placeholder',
  field: 'price',
  options: priceOptions
}

const participantsFilter = [
  { value: { from: 0, to: 1 }, label: 'search.filters.participants.one' },
  { value: { from: 2, to: 4 }, label: 'search.filters.participants.small' },
  { value: { from: 6, to: 8 }, label: 'search.filters.participants.medium' },
  { value: { from: 10 }, label: 'search.filters.participants.from-large' },
]

export const participantsConfig = {
  type: filters.select,
  placeholder: 'search.filters.participants.placeholder',
  field: 'participants',
  options: participantsFilter
}

export const schedulingOptions = [
  { value: 'ONE_TIME', label: 'search.filters.schedule.one-time', disabled: false },
  { value: 'RECURRING', label: 'search.filters.schedule.recurring', disabled: false },
  { value: 'FLEXIBLE', label: 'search.filters.schedule.flexible', disabled: false },
]

export const filteredSchedulingOptions = [
  { value: 'ONE_TIME', label: 'search.filters.schedule.one-time', disabled: true },
  { value: 'RECURRING', label: 'search.filters.schedule.recurring', disabled: true },
  { value: 'FLEXIBLE', label: 'search.filters.schedule.flexible', disabled: false },
]

export const schedulingConfig = {
  type: filters.select,
  placeholder: 'search.filters.schedule.placeholder',
  field: 'scheduleOptionsIn',
  options: schedulingOptions
}

const timeToSec = ({ hours = 0, minutes = 0 }) => hours * 60 * 60 + minutes * 60

const durationFilter = [
  { value: { to: timeToSec({ minutes: 59 }) }, label: 'search.filters.duration.to-1' },
  { value: { from: timeToSec({ hours: 1 }), to: timeToSec({ hours: 1, minutes: 59 }) }, label: 'search.filters.duration.from-1-to-2' },
  { value: { from: timeToSec({ hours: 2 }), to: timeToSec({ hours: 2, minutes: 59 }) }, label: 'search.filters.duration.from-2-to-3' },
  { value: { from: timeToSec({ hours: 3 }), to: timeToSec({ hours: 3, minutes: 59 }) }, label: 'search.filters.duration.from-3-to-4' },
  { value: { from: timeToSec({ hours: 4 }) }, label: 'search.filters.duration.from-4' },
]

export const durationConfig = {
  type: filters.select,
  placeholder: 'search.filters.duration.placeholder',
  field: 'duration',
  options: durationFilter
}

export const charitiesConfig = {
  type: filters['charities-select'],
  field: 'charitiesIdsIn',
}

export const rangeDateConfig = {
  type: filters['range-datepicker'],
  placeholder: 'search.filters.date.placeholder',
  field: 'date',
}

export const locationConfig = {
  type: filters['offer-location-select'],
  field: 'location',
}
