import React from 'react'
import { juxt, compose } from 'lodash/fp'
import { useLocation } from 'react-router-dom'
import get from 'lodash/get'

import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'

import { fetchUserCalendarSlots } from '../requests/user-calendar'

import { getUserId, getUserTimeZone } from 'utils/user'
import { getEventTimeFormats } from '../configs/hour-formats'
import { getTimezoneAbbr } from 'utils/services/timezone'
import { talentCalendarConfig } from '../configs/calendar'
import { toUserCalendarEvents } from '../utils/adapters'
import { initialView } from '../../Calendar/BaseCalendar/configs/calendar-config'
import { calendarFilters, userCalendarSidebarFilters } from '../configs/filters'

import BaseCalendar from '../BaseCalendar'
import MenuDots from '../../Calendar/components/MenuDots'
import CategoriesSidebar from '../components/CategoriesSidebar'
import SelectedSlotSidebar from '../components/SelectedSlotSidebar/SelectedSlotSidebar'
import { getLocale } from 'utils/date'

const UserCalendar = () => {
  const t = useGetTranslate()
  const { search } = useLocation()
  const calendarRef = React.useRef(null)
  const [userId, timeZoneAbbr, timeZone] = useUser(juxt([
    getUserId,
    compose(getTimezoneAbbr, getUserTimeZone),
    getUserTimeZone
  ]))
  const userLang = useUserLang()
  const locale = getLocale(userLang)

  // ************ calendar state ************
  const [selectedEvent, setSelectedEvent] = React.useState(null)
  const [events, setEvents] = React.useState({
    lockEvents: [],
    backgroundEvents: [],
  })
  const [filters, setFilters] = React.useState(calendarFilters)
  const [isDrawerOpen, openDrawer, closeDrawer] = useModal(false)

  // ************ dot menu ************
  const [hour12Format, setHourFormat] = React.useState(true)
  const [showWeekends, setShowWeekends] = React.useState(true)

  const toggleHourFormat = React.useCallback(() => setHourFormat(curr => !curr), [])
  const toggleShowWeekends = React.useCallback(() => setShowWeekends(curr => !curr), [])

  const openListOptions = React.useMemo(
    () => [
      { label: 'calendar.dot-button.hour-format', cb: toggleHourFormat },
      { label: 'calendar.dot-button.show-hide-weekends', cb: toggleShowWeekends },
    ],
    []
  )

  const withUserTimezone = React.useCallback(
    (slots) => slots.map(slot => ({ ...slot, userTimezone: timeZone })),
    [timeZone]
  )

  // ************ fetch fns ************
  const fetchCalendarSlots = React.useCallback(async () => {
    const slots = await fetchUserCalendarSlots({ userId, filters })

    setEvents(toUserCalendarEvents(withUserTimezone(slots)))
  }, [userId, filters, withUserTimezone])

  // ************ calendar navigation handlers ************
  const onNextButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.next()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onPrevButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.prev()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onTodayButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.today()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  // ************ calendar events handlers ************
  const onEventClick = React.useCallback(e => {
    if (!e?.event?.extendedProps?.locked) {
      setSelectedEvent(e.event)
      openDrawer()
    }
  }, [])

  // ************ effects ************
  React.useEffect(() => {
    fetchCalendarSlots()
  }, [fetchCalendarSlots, filters])

  React.useEffect(() => {
    const queryParams = new URLSearchParams(search)
    const initialFormat = queryParams.get('view')
    const calendar = calendarRef.current.getApi()
    calendar.changeView(get(initialView, initialFormat, initialView.week))
  }, [search])

  return (
    <div className="user-calendar">
      <h1 className="user-calendar__header">{t('calendar.talent.heading')}</h1>
      {timeZoneAbbr && (
        <p className='user-calendar__time-zone'>
          {t('calendar.talent.current-time-zone')}:<span>{timeZoneAbbr}</span>
        </p>
      )}
      <div className="user-calendar__wrapper">
        <div className="user-calendar__info">
          <SelectedSlotSidebar showAddToCalendarButton isOpen={isDrawerOpen} close={closeDrawer} selectedEvent={selectedEvent} hour12Format={hour12Format} />
          <CategoriesSidebar options={userCalendarSidebarFilters} filters={filters} setFilters={setFilters} />
        </div>
        <div className="user-calendar__calendar calendar calendar--pr32">
          <MenuDots optionsList={openListOptions} />
          <BaseCalendar
            events={events}
            weekends={showWeekends}
            customButtons={{
              prev: { click: onPrevButton },
              next: { click: onNextButton },
              today: { click: onTodayButton, text: t('calendar.talent.today-btn') },
            }}
            ref={calendarRef}
            eventClick={onEventClick}
            slotEventOverlap={false}
            eventDurationEditable={false}
            selectAllow={() => false}
            {...getEventTimeFormats(hour12Format, locale)}
            {...talentCalendarConfig}
          />
        </div>
      </div>
    </div>
  )
}

export default UserCalendar
