import React from 'react'
import PropTypes from 'prop-types'

const PublicViewBorderedCard = ({ children, borderColor = '' }) => {
  return (
    <div className={`public-view-card--bordered ${borderColor}`}>
      <div className="top-border">
        <span></span>
      </div>
      <div className="content">{children}</div>
    </div>
  )
}

PublicViewBorderedCard.propTypes = {
  children: PropTypes.node,
  borderColor: PropTypes.string,
}

export default PublicViewBorderedCard
