import React from 'react';
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const OfferingDetailsGenericStatus = ({
  title,
  type,
  reasonText,
  children
}) => {
  const t = useGetTranslate()

  return (
    <div className="od--with-padding od__column od--centered">
      <h3 className="od--uppercase-title mb-12">{title}</h3>
      <h6 className="od--status-title mb-16">{type}</h6>
      {reasonText &&
        <React.Fragment>
          <p className="od--main-text od--accented mb-8">{t('shared.words.reason')}</p>
          <p className="od--main-text mb-24">{reasonText}</p>
        </React.Fragment>
      }
      {/* Children here cause we can pass Trans with link here */}
      {children &&
        <p className="od--small-text od--with-link">{children}</p>
      }
    </div>
  )
}

OfferingDetailsGenericStatus.propTypes = {
  title: PropTypes.string,
  type: PropTypes.string,
  reasonText: PropTypes.string,
  children: PropTypes.node
}

export default OfferingDetailsGenericStatus;
