// Modules
import React from 'react'
import { Link } from 'react-router-dom'

import { api } from 'shared'

// Router
import { routesList } from 'router/routesMap'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { isChosenLocationOfferType } from 'utils/offer'
import { getLocationTimeZone } from 'utils/location'
import { getDateTimeText, applyTimezoneToDateTime } from '_legacy/components/Table/table-components/types/common/DateTime/utils'

// Legacy components
import TwoLineStringWithBold from '_legacy/components/Table/table-components/types/common/TwoLineStringWithBold'

const { RANGE } = api.talents.talent.offers.DateTimeType


const DateTime = ({
  isLoading,
  id,
  type,
  startTime,
  endTime,
  locations,
  offerType,
}) => {
  const timeZone =
    isChosenLocationOfferType(offerType)
      // TODO: choose what function of getting time zone from location should stay
      // getLocationTimeZone() vs getTimeZoneFromOccurrence()
      ? getLocationTimeZone(locations?.[0])
      : useUser(getUserTimeZone)

  // TODO: unify DateTime table component by passing dateTime object as row prop (as in common case)
  const dateTime = {
    type: RANGE,
    date: startTime,
    from: startTime,
    to: endTime,
  }

  const zonedDateTime = applyTimezoneToDateTime(dateTime, timeZone)
  const locale = useUserLocale()
  const dateTimeText = getDateTimeText(zonedDateTime, timeZone, locale)

  const href = type === 'experience'
    ? routesList.inPersonExperienceOrderDetails(id)
    : routesList.liveVirtualEventsOrderDetails(id)

  return (
    <div className="recurring-list__date-time">
      <Link to={href} >
        <TwoLineStringWithBold
          isLoading={isLoading}
          mainText={dateTimeText?.mainText}
          subText={dateTimeText?.subText}
        />
      </Link>
    </div>
  )
}

export default DateTime
