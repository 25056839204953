import React from 'react';
import styled from 'styled-components';
import { types } from 'shared';

import { Row, Radio } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const { DIGITAL_DELIVERY } = types.talent.offers.readyMadeEvent.DeliveryType;

const DeliveryField = React.memo(function MakeTheMessagePublicField() {
  const t = useGetTranslate();

  return (
    <StyledWrapper>
      <Row
        nameBlock={t('offerings.ready-made.delivery.label')}
        subNameBlock={t('offerings.ready-made.delivery.sub-label')}
        alignItems='center'
        isRequired
        component={
          <div className='radio-wrapper'>
            <Radio
              isRequired
              name='deliveryType'
              value={DIGITAL_DELIVERY}
              label='offerings.ready-made.delivery.digital'
              defaultChecked
            />
            {/* here and above commented due to ui changes (will be uncommented and further developed in phase 2) */}
            {/* <Radio
              isRequired
              name='deliveryType'
              value={PHYSICAL_DELIVERY}
              label='offerings.ready-made.delivery.physical'
            /> */}
          </div>
        }
      />
    </StyledWrapper>
  );
});

const StyledWrapper = styled.div`
  .radio-wrapper {
    display: flex;
    @media only screen and (max-width: 425px) {
      flex-direction: column;
    }
    label:not(:last-of-type) {
        margin-right: 45px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
          margin-bottom: 12px;
        }
      }
  }
`;

export default DeliveryField;
