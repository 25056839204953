import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'
import {
  getFirstCityOrCountry,
  getFirstLocationAddress,
  getFirstLocationFormattedAddress,
  getFirstLocationName,
  getLocationLabel,
} from 'utils/location'
import MapMarkerAccountOutlineIcon from 'mdi-react/MapMarkerAccountOutlineIcon'
const PublicLocationSingle = ({ locations, isOnline, hideAddressFromPubView }) => {
  const placeId = locations[0]?.googlePlaceId;
  const latitude = locations[0]?.locationPoint?.latitude
  const longitude = locations[0]?.locationPoint?.longitude
  const t = useGetTranslate()
  const label = hideAddressFromPubView ? getFirstCityOrCountry(locations) : (locations.custom_location_name ? locations.custom_location_name : getFirstLocationName(locations))

  return(
    <>
      {!hideAddressFromPubView ? (
        <div className="public-location__single">
          <h3>{t('experience.card.location-name')}</h3>
          <p className='margin-top-publicLocation'>{label || t('experience.card.location')}</p>
          {!hideAddressFromPubView && (
            <div className='margin-top-bottom'>
              <h3>{t('experience.card.location-address')}
              </h3>
              <p className='margin-top-publicLocation'>{!isOnline ? getFirstLocationFormattedAddress(locations) : getFirstLocationAddress(locations)}</p>
            </div>
          )}
          {longitude && latitude &&
          (
            <StyledWrapper>
              <h3>{t('shared.words.goto-map')+':'}</h3>
              <a
                target='_blank'
                href={`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&query_place_id=${placeId}`}>
                <MapMarkerAccountOutlineIcon size={30} />
              </a>
            </StyledWrapper>
          )}
        </div>
      ):<p>{t('experience.card.location-hidden')}</p>}
    </>
  )
}


const PublicLocationMultiple = ({ locations, hideAddressFromPubView }) => {
  const t = useGetTranslate()
  const toggleDropdown = event => event.target.parentElement.classList.toggle('active')

  return (
    <>
      {!hideAddressFromPubView ? (
        <div className="public-location__multiple">
          <h4>{t('experience.card.location-options')}</h4>

          {locations?.map((location,index) => {
            return (
              <div key={location.id} className="pv-card-location-flexible">
                {location.name || location.custom_location_name ? (
                  <div className="public-location__multiple__title" onClick={toggleDropdown}>
                    <p>{t('shared.words.location')+' '+(index+1)}</p>
                    <Icon.ArrowDownSelectMenu />
                  </div>
                ) : null}
                <p className={`${location.name ? '' : 'visible'}`}>
                  <div className='margin-top-left-publicLocation'>
                    <h3>
                      {t('experience.card.location-name')}
                    </h3>
                    {location.custom_location_name ? (<p className='margin-top-publicLocation'>{location.custom_location_name }</p>):(<p className='margin-top-publicLocation'>{location.name}</p>)}
                  </div>
                  <div className='margin-top-left-publicLocation'>
                    <h3>
                      {t('experience.card.location-address')}
                    </h3>
                    <p className='margin-top-publicLocation'>{getLocationLabel(location)}</p>
                  </div>
                  {location?.locationPoint?.latitude && location?.locationPoint?.longitude && (<StyledWrapper>
                    <h3 className='margin-left-publicLocation'>{t('shared.words.goto-map')+':'}</h3>
                    <a
                      target='_blank'
                      href={`https://www.google.com/maps/search/?api=1&query=${location.locationPoint.latitude},${location.locationPoint.longitude}&query_place_id=${location.googlePlaceId}`}>
                      <MapMarkerAccountOutlineIcon
                        className='do-not-rotate'
                        size={30}
                      />
                    </a>
                  </StyledWrapper>)}
                </p>
              </div>
            )
          })}
        </div>
      ):<p>{t('experience.card.location-hidden')}</p>}
    </>
  )
}


const PublicLocation = ({
  locations,
  isOnline,
  hideAddressFromPubView
}) => (
  <div className="public-location">
    {
      locations?.length === 1 ? (
        <PublicLocationSingle locations={locations} isOnline={isOnline} hideAddressFromPubView={hideAddressFromPubView} />
      ) : (
        <PublicLocationMultiple locations={locations} hideAddressFromPubView={hideAddressFromPubView} />
      )
    }
  </div>
)


const StyledWrapper = styled.div`
{
  display: block;
  padding-top: 5px;
  color: white;
  cursor: pointer;
  }
a{
  color:white;
  margin-left:15px;
}
`
PublicLocation.propTypes = {
  locations: PropTypes.array,
  isOnline: PropTypes.bool,
}

export default PublicLocation
