import React from 'react'
import { useHistory } from 'react-router-dom'
import { errorNames } from 'shared'

import { bookVideoChat as bookVideoChatApi } from 'api/appApi/offers/videoChat'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { notify } from 'utils/notify'
import * as r from '_legacy/constants/routes'

const offersErrors = errorNames.offers

export function useBookVideoChat(setLoading) {
  const t = useGetTranslate();
  const history = useHistory()
  return React.useCallback(({ offerVideoChatId, ...data }) => {
    setLoading(true)
    bookVideoChatApi({ data, offerVideoChatId })
      .then(({ order }) => {
        history.push(`/${r.OFFERINGS}/${r.VIDEO_CHAT}/${order.id}/${r.PURCHASE}`)
      })
      .catch(({ error }) => {
        //TODO add proper error handling
        if (error.name === offersErrors.BOOK_OCCURRENCES_OVERLAP_ERROR) {
          notify(t('lessons.booking.errors.book-already-has-order'), 'error')
        }
        console.warn(error)
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
}
