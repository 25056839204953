import React from 'react';
import styled from 'styled-components'

import CustomPicture from '_legacy/common/CustomPicture'

export default React.memo(function ImageViewer({ isMain, file }) {
  return (
    <StyledCustomPicture
      isMain={isMain}
      file={file}
    />
  );
});

const StyledCustomPicture = styled(CustomPicture)`
  display: block;
  height: 100%;
  width: 100%;
  ${({ isMain }) => !isMain && 'filter: grayscale(1)'};
  object-fit: cover;
`
