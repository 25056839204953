import React from 'react'
import { cond, isEqual, always, T, isEmpty } from 'lodash/fp'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { parseDate } from 'utils/services/timezone'
import { getShortDateWithLocaleTime } from 'utils/date';

// Legacy components
import LineString from '_legacy/components/Table/table-components/types/common/LineString.js'

const INFINITY = '∞'

export default React.memo(function ExpirationDate({ isLoading, expiresAt }) {
  const timeZone = useUser(getUserTimeZone)
  const locale = useUserLocale()

  const date = cond([
    [isEqual(INFINITY), always(INFINITY)],
    [isEmpty, always(INFINITY)],
    [T, always(getShortDateWithLocaleTime(parseDate(expiresAt, timeZone), locale))]
  ])(expiresAt)

  return <LineString isLoading={isLoading} str={date} />
})
