import React from 'react';
import { string, any } from 'prop-types';


const TalentInfoBoxTab = ({ header, info, className }) => (
  <div className={`talent-info-block-tab ${className}`}>
    <h5 className="talent-info-block-tab__header">{header}</h5>
    <span className="talent-info-block-tab__info">{info}</span>
  </div>
);

TalentInfoBoxTab.propTypes = {
  header: string.isRequired,
  info: any.isRequired, // html-element
};

export default TalentInfoBoxTab;
