import React from 'react'
import { useHistory } from 'react-router-dom'
import { errorNames } from 'shared'

import { bookLiveInPersonLesson as bookLiveInPersonLessonApi } from './api.js'
import * as r from '_legacy/constants/routes'
import { notify } from 'utils/notify'
import { getUserEntourageType } from 'utils/user'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getEntourageError } from 'utils/services/errors/entourage-errors'
import { useUser } from 'store/hooks/globalState/useUser'

const offersErrors = errorNames.offers

export function useBookLiveInPersonLessons(setLoader) {
  const t = useGetTranslate()
  const history = useHistory()
  const currentUserEntourage = useUser(getUserEntourageType)

  return React.useCallback(
    ({ offerLiveInPersonLessonId, userFilesTokens, offeringType, startTime, endTime }) => {
      setLoader && setLoader(true)
      bookLiveInPersonLessonApi({ offerLiveInPersonLessonId, userFilesTokens, startTime, endTime })
        .then(({ order }) =>
          history.push(
            `/${r.OFFERINGS}/${offeringType}/${order.id}/${r.PURCHASE}`
          )
        )
        .catch(({ name, context }) => {
          //TODO add proper error handling
          if (name === offersErrors.BOOK_OCCURRENCES_OVERLAP_ERROR) {
            notify(t('lessons.booking.errors.book-already-has-order'), 'error')
          }
          if (name === offersErrors.BOOK_OWN_OFFER_NOT_ALLOWED_ERROR) {
            notify(t('lessons.booking.errors.cant-book-own-lesson'), 'error')
          }
          if (name === offersErrors.BOOK_WRONG_ENTOURAGE_TYPE) {
            notify(t(getEntourageError({ expectedEntourageType: context.offerEntourageType, currentUserEntourage })), 'error')
          }
          console.warn(name)
        })
        .finally(() => setLoader && setLoader(false))
    },
    [history, currentUserEntourage]
  )
}
