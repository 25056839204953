// Modules
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import prop from 'lodash/fp/prop'
import set from 'lodash/set'

// Routing
import { routesList } from 'router/routesMap'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
// import { useUnpublishOffer } from 'requests/manage-offerings'
import { useChat } from 'requests/chats'
import { useFetchVideoChatBookingsDetails } from './state/requests'
import { useLiveVideoChatBookingsDetails, useSetLiveVideoChatBookingsDetails } from './state/store'

// Utils
import { getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getDateTimeProps } from 'utils/date'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText } from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsCountdown from 'components/OfferingDetails/components/OfferingDetailsCountdown'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import { getVideoChatBookingConfig } from './utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'
import AddToCalendarButton from 'components/Calendars/components/AddToCalendarButton'

const VideoChatBookingsDetails = () => {
  const t = useGetTranslate()
  const history = useHistory()
  const { orderId } = useParams()

  // state
  const currentUser = useUser()
  const timeZone = getUserTimeZone(currentUser)

  const order = useLiveVideoChatBookingsDetails()
  const occurrence = useLiveVideoChatBookingsDetails(prop('occurrence'))
  const statusTimeline = useLiveVideoChatBookingsDetails(prop('statusTimeline'))
  const review = useLiveVideoChatBookingsDetails(prop('review'))
  const clearOccurrenceDetails = useSetLiveVideoChatBookingsDetails(() => null)

  const { id: occurrenceId, startTime: _startTime, endTime: _endTime, talent } = occurrence || {}

  const { isLoading, handleStartChat } = useChat(talent?.id)

  const { formattedDate, formattedTimeRange, startTime } = getDateTimeProps({ ...occurrence, timeZone })

  // requests
  const fetchOccurrenceDetails = useFetchVideoChatBookingsDetails()
  // const unPublishOffer = () => useUnpublishOffer()

  // others
  // const handleUnPublish = () => {
  //   unPublishOffer([offerId])
  //   history.push(`/${r.ORDERS}/${r.LIST}`)
  // }

  const handleStartEvent = () => history.push(routesList.videoChatRoom(occurrenceId))

  // effects
  React.useEffect(() => {
    fetchOccurrenceDetails()
  }, [])

  React.useEffect(() => () => {
    clearOccurrenceDetails()
  }, [])

  if (!occurrence) return <PageLoader />

  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)
  const cancelText = getCancelText({ t, ...order })
  const refundText = getAficionadoRefundText({ t, refundRequests: order.refundRequests })
  const c = getVideoChatBookingConfig(order)

  return (
    <div className="video-chat-order od">
      <OfferingDetailsTitle
        typeHeader={t('order-details.virtual-chats.header')}
        title={`${t('order-details.virtual-chats.header-with')} ${getSaveFullName(talent)}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, timeZone, true)} />
          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />
              </div>

              {startTime && (
                <div className="od__column">
                  <h6 className="od--uppercase-title mb-16">{t('order-details.virtual-chats.video-date')}</h6>
                  <p className="od--main-text od--accented">{formattedDate}</p>
                  <p className="od--small-text">{formattedTimeRange}</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowSelectDate && (
              <div className="od__column od--with-padding">
                <p className="od--uppercase-title mb-24">{t('order-details.virtual-chats.info-header')}</p>
                <p className="od--main-text mb-24">{t('booking-details.contact.text')}</p>
                <Button
                  handleOnClick={handleStartChat}
                  disabled={isLoading}
                  text={t('booking-details.contact.title')}
                  fontSize="0.875"
                  paddingVertical="16"
                  paddingHorizontal="10"
                  className="mb-16"
                />
                {/* Hidden for now due to changes from Chris */}
                {/* <Button
                  handleOnClick={handleUnPublish}
                  primary
                  text={t('booking-details.cancel.title')}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                  paddingHorizontal="10"
                /> */}
              </div>
            )}

            {c.isShowCounter && (
              <div className="od--with-padding">
                <p className="od--uppercase-title">{t('order-details.virtual-chats.info-header')}</p>
                <OfferingDetailsCountdown
                  countdownDate={_startTime}
                  handleClick={handleStartEvent}
                  title={t('order-details.virtual-chats.start-event')}
                />
                <AddToCalendarButton
                  startTime={_startTime}
                  endTime={_endTime}
                  timeZone={timeZone}
                  className="fat"
                />
                {/* Hidden for now due to changes from Chris */}
                {/* <Button
                  handleOnClick={handleUnPublish}
                  primary
                  stretch
                  text={t('shared.words.cancel-order')}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                  paddingHorizontal="10"
                /> */}
              </div>
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsGenericStatus
                  title={t('order-details.virtual-chats.info-header')}
                  type={t('booking-details.main-completed.title')}
                />
                <p className="od--main-text mb-12">{t('booking-details.main-completed.text')}</p>
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={orderId}
                  onSuccess={fetchOccurrenceDetails}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('order-details.virtual-chats.info-header')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}

            {c.isShowCanceled && (
              <CancelStatusWithContact
                refundText={refundText}
                cancelText={cancelText}
                title={t('shared.offers.video-chat')}
              />
            )}
          </div>

          <ComplaintInfo
            orderId={orderId}
            userLink={getTalentLink(talent)}
            userName={getSaveFullName(talent)}
            onSuccess={fetchOccurrenceDetails}
          />
        </div>
      </div>
    </div>
  )
}

export default VideoChatBookingsDetails
