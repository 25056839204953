import React from 'react';
import styled from 'styled-components';

import { Title } from '_legacy/common';
import Form from '_legacy/containers/PromoCodes/Form';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetchOfferingsPromoCodes } from 'requests/promoCodes/promo-codes';
import { useOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes';
import { sanitizeFilterFields } from '_legacy/components/Table/utils.js';

const PromoCodes = () => {
  const t = useGetTranslate();

  const fetchOfferingsPromoCodes = useFetchOfferingsPromoCodes();

  const filtersOptions = useOfferingsPromoCodesFilters(current => sanitizeFilterFields({ current, fields: ['input', 'offeringTypeIn'] }))

  const prevFiltersOptions = React.useRef(filtersOptions)

  React.useEffect(() => {
    fetchOfferingsPromoCodes({ ...filtersOptions })
    prevFiltersOptions.current = filtersOptions
  }, [filtersOptions, prevFiltersOptions])

  return (
    <StyledWrapper>
      <Title>{t('promo-codes.header')}</Title>
      <Form />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-top: 50px;
  ${Title} {
    margin-bottom: 40px;
  }
`

export default PromoCodes
