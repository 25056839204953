import Aficionado from './Aficionado'
import Participants from './Participants'
import Amount from './Amount'
import Status from './Status'
import Order from './Order'

export const AFICIONADO = 'aficionado'
export const PARTICIPANTS = 'participants'
export const AMOUNT = 'amount'
export const STATUS = 'status'
export const ORDER = 'order'

export const elementsTypes = {
  [AFICIONADO]: AFICIONADO,
  [PARTICIPANTS]: PARTICIPANTS,
  [AMOUNT]: AMOUNT,
  [STATUS]: STATUS,
  [ORDER]: ORDER,
}

export const OFFERINGS_DETAILS_REQUESTS = 'od-requests'
export const OFFERINGS_DETAILS_PARTICIPANTS = 'od-participants'

export const orderDetailsComponents = {
  [AFICIONADO]: Aficionado,
  [PARTICIPANTS]: Participants,
  [AMOUNT]: Amount,
  [STATUS]: Status,
  [ORDER]: Order,
}
