import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import NotificationRow from './NotificaitonRow';

const NotificationBody = ({ rows = [] }) => {
  const t = useGetTranslate()
  return (
    <div className='notifications-body'>
      {rows.map((row) => (
        <NotificationRow
          key={row.label}
          cells={row.notifications}
          label={t(row.tKey)}
        />
      ))}
    </div>
  );
};

NotificationBody.propTypes = {
  rows: PropTypes.array,
};

export default NotificationBody
