import get from 'lodash/get';
import castArray from 'lodash/castArray';
import compact from 'lodash/compact'
import {
  submitCharitiesAdapter,
  getCharitiesAdapter,
  getRadio,
  submitRadio
} from 'utils/forms/adapters'
import {
  getMultiSelectLibraryAdapter,
  submitMultiSelectLibraryAdapter
} from 'utils/services/validators/library';

export const videoMessageDefaultValues = {
  price: '',
  enableInYourServices: 'true',
  whichCharities: [
    { charity: { value: null, label: '' }, percent: '' }
  ],
  media: [],
  mediaTemp: [],
  terms: [],
  monthOfUse: 3,
  maxTalentRetakes: 3,
  commercialVideoMessageName: '3 Month SM Post',
}

export const defaultFormats = [
  'Company Owned Website',
  'Email',
  'Social Media Channels'
];

export const getVideoMessageAdapter = data => ({
  price: String(get(data, 'price')),
  enableInYourServices: getRadio(data.active),
  commercialVideoMessageName:get(data,'commercialVideoMessageName'),
  terms:get(data,'terms',[]),
  monthOfUse:get(data,'monthOfUse'),
  maxTalentRetakes:get(data,'maxTalentRetakes'),
  defaultFormats:get(data,'defaultFormats',[]),
  customFormats:get(data,'customFormats',[]),
  comapnyMayAddLogo:getRadio(get(data,'comapnyMayAddLogo')),
  whichCharities: getCharitiesAdapter(data.whichCharities),
  ...getMultiSelectLibraryAdapter('media', data),
})

export const submitVideoMessageAdapter = (formState) => {
  return {
    price: Number(formState.price),
    enableInYourServices: submitRadio(formState.enableInYourServices),
    commercialVideoMessageName:get(formState,'commercialVideoMessageName'),
    terms:compact(castArray(get(formState,'terms',[]))),
    monthOfUse:Number(formState.monthOfUse),
    maxTalentRetakes:Number(formState.maxTalentRetakes),
    defaultFormats:get(formState,'defaultFormats',[]),
    customFormats:compact(castArray(get(formState,'customFormats',[]))),
    comapnyMayAddLogo:submitRadio(formState.comapnyMayAddLogo),
    whichCharities: submitCharitiesAdapter(formState.whichCharities),
    ...submitMultiSelectLibraryAdapter('media', formState)
  }
}
