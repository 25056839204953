import React from 'react';
import styled from 'styled-components';

import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher';
import { Row } from '_legacy/common';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const HideDayTime = () => {
  const t = useGetTranslate();

  return (
    <HideDayTimeWrapper
      nameBlock={t('create-experience.form.hide-day-time.label')}
      component={<RadioSwitcher name="hideDayTime" />}
    />
  );
};

const HideDayTimeWrapper = styled(Row)`
  align-items: center;
`;


export default HideDayTime;
