import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useExperiencePublicView = createUseGlobalState('experiencePublicView');
export const useSetExperiencePublicView = createUseSetGlobalState('experiencePublicView');

export const useExperiencePublicViewOccurrence = createUseGlobalState('experiencePublicViewOccurrence');
export const useSetExperiencePublicViewOccurrence = createUseSetGlobalState('experiencePublicViewOccurrence');

export const useLiveVirtualEvents = createUseGlobalState('liveVirtualEvent');
export const useSetLiveVirtualEvents = createUseSetGlobalState('liveVirtualEvent');

export const useLiveVirtualEventsOccurrence = createUseGlobalState('liveVirtualEventOccurrence');
export const useSetLiveVirtualEventsOccurrence = createUseSetGlobalState(
  'liveVirtualEventOccurrence'
);

export const useVideoMessagePublic = createUseGlobalState('videoMessagesPublic');
export const useSetVideoMessagePublic = createUseSetGlobalState('videoMessagesPublic');

export const useReadyMadeEventsPublic = createUseGlobalState('readyMadeEventsPublic');
export const useSetReadyMadeEventsPublic = createUseSetGlobalState('readyMadeEventsPublic');

export const useVideoChatPublic = createUseGlobalState('videoChatPublic');
export const useSetVideoChatPublic = createUseSetGlobalState('videoChatPublic');
