import { _post } from 'api/utils'

export const verifyVideoChatRoom = ({ occurrenceId }) =>
  _post(`/offers/video-chats/occurrences/${occurrenceId}/verify-video-room`, {
    needAuthorization: true,
  })

export const joinVideoChatRoom = ({ occurrenceId }) =>
  _post(`/offers/video-chats/occurrences/${occurrenceId}/join-video-room`, {
    needAuthorization: true,
  })

export const closeVideoChatRoom = ({ occurrenceId }) =>
  _post(`/offers/video-chats/occurrences/${occurrenceId}/close-video-room`, {
    needAuthorization: true,
  })
