import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

// Search Result
export const useSearchResults = createUseGlobalState('searchResults')
export const useSetSearchResults = createUseSetGlobalState('searchResults')

// Recent Searches

export const useRecentSearchList = createUseGlobalState('recentSearchList')
export const useSetRecentSearchList = createUseSetGlobalState('recentSearchList')
