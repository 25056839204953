import React from 'react'

import Icon from 'assets/icons/Icon'

export const listLogoCard = {
  ['visa']: <Icon.VisaCard />,
  ['mastercard']: <Icon.MasterCard />,
  ['discover']: <Icon.DiscoverCard />,
  ['amex']: <Icon.AmericanExpressCard />,
  ['diners_club']: <Icon.DinersClubCard />,
  ['unionpay']: <Icon.UnionPayCard />,
  ['jcb']: <Icon.JSBCard />,
}
