import React from 'react';
import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState';

export const useModal = createUseGlobalState('modals');
export const useSetModal = createUseSetGlobalState('modals');

export const useSubModal = createUseGlobalState('subModals');
export const useSetSubModal = createUseSetGlobalState('subModals');

export const useStateModal = (type) => {
  const setModalProps = useSetModal((prev, next) => ({ ...prev, type, props: next }));

  return React.useCallback((props) => setModalProps(props), [setModalProps]);
};

export const useStateSubModal = (type) => {
  const setModalProps = useSetSubModal((prev, next) => ({ ...prev, type, props: next }));

  return React.useCallback((props) => setModalProps(props), [setModalProps]);
};
