import React from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import TalentInfoBlockTab from '../TalentInfoBlockTab/';

const TalentInfoBlockNationality = ({ nationality }) => {
  const t = useGetTranslate()
  return (
    <TalentInfoBlockTab
      header={t('talent-public-view.info-block.nationality')}
      info={startCase((nationality || '').toLowerCase()) || t('talent-public-view.info-block.not-specified')}
    />
  );
};

TalentInfoBlockNationality.propTypes = {
  nationality: PropTypes.string.isRequired,
};

export default TalentInfoBlockNationality;
