import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { types } from 'shared'

import CustomPicture from '_legacy/common/CustomPicture';
import CustomVideo from '_legacy/common/custom-video/CustomVideo';

const { PHOTO } = types.file.Types

const MediaViewer = ({ file }) => {
  const typeFile = get(file, 'type', PHOTO)
  return  typeFile === PHOTO
    ? <CustomPicture file={file} />
    : <CustomVideo file={file} />
};

MediaViewer.propTypes = {
  file: PropTypes.object
};

export default MediaViewer;
