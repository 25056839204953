import React from 'react'
import styled from 'styled-components'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

const DropzoneBackdrop = ({ maxSize }) => {
  const t = useGetTranslate()
  return (
    <DropzoneBackdropWrapper>
      <i className="icon-frame icon-camera" />
      <h3>{t('video-message.form.media.placeholder.title')}</h3>
      <p>
        <Trans tKey="video-message.form.media.placeholder.text" phs={[{ ph: '{{max-size}}', value: maxSize }]} />
      </p>
    </DropzoneBackdropWrapper>
  )
}

const DropzoneBackdropWrapper = styled.div`
  border: ${({ theme }) => theme.border.default}; 
  outline: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.default};;
  
  width: 100%;
  
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  i {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    color: ${({ theme }) => theme.colors.blue};
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    color: ${({ theme }) => theme.colors.lightGrey};
  }
`

export default DropzoneBackdrop
