import styled from 'styled-components'

const PictureText = styled.p`
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translateX(-50%);
  z-index: 1;
  background-color: #fff;
  border: 2px solid ${({ theme }) => theme.colors.blue};
  border-radius: 4px;
  font-family: Montserrat, sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.blue};
  padding: 5px 8px;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
`

export default PictureText
