import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { Row, ErrorMessage } from '_legacy/common';
import CodeConfirm from './CodeConfirm';
import PhoneConfirm from './PhoneConfirm';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { sharedErrors } from '_legacy/common/Input/phoneErrorMessages';

const PhoneConfirmForm = ({ baseName, isEditable }) => {
  const t = useGetTranslate();
  const _baseName = baseName ? `${baseName}.` : '';

  const fields = {
    isPhoneSand: _baseName + 'isPhoneSand',
    isCodeConfirm: _baseName + 'isCodeConfirm',
    oldPhone: _baseName + 'oldPhone',
    verifyPhoneKey: _baseName + 'verifyPhoneKey',
    phone: _baseName + 'phone',
    confirm: _baseName + 'confirm',
    canRetry: _baseName + 'canRetry',
    remainingAttempts: _baseName + 'remainingAttempts'
  };

  const { watch, register, unregister, errors } = useFormContext();
  const values = watch([fields.isPhoneSand, fields.isCodeConfirm, fields.canRetry, fields.remainingAttempts]);
  const isPhoneSand = get(values, fields.isPhoneSand);
  const isCodeConfirm = get(values, fields.isCodeConfirm);
  const isShowCodeConfirmation = isPhoneSand && !isCodeConfirm;

  React.useEffect(() => {
    register(fields.isPhoneSand, { required: sharedErrors.PHONE_SEND });
    register(fields.isCodeConfirm, { required: sharedErrors.CODE_CONFIRM });
    register(fields.verifyPhoneKey);
    register(fields.oldPhone);
    register(fields.canRetry);
    register(fields.remainingAttempts);
    return () => {
      unregister(fields.isPhoneSand);
      unregister(fields.isCodeConfirm);
    };
  }, [register, unregister]);

  const isCodeConfirmErrors = get(errors, fields.isCodeConfirm + '.message');
  const isPhoneSandErrors = get(errors, fields.isPhoneSand + '.message');
  const phoneErrors = get(errors, fields.phone + '.message');

  const errorMessage = phoneErrors || isPhoneSandErrors || isCodeConfirmErrors;

  return (
    <Row
      isRequired
      nameBlock={t('shared.phone.label')}
      subNameBlock={t('shared.phone.tip')}
      component={
        <PhoneConfirmTemplate>
          <PhoneConfirm fields={fields} isEditable={isEditable} />
          {isShowCodeConfirmation && <CodeConfirm fields={fields} />}
          {errorMessage && (
            <ErrorMessage
              position='absolute'
              top='100%'
              message={errorMessage}
            />
          )}
        </PhoneConfirmTemplate>
      }
    />
  );
};

PhoneConfirmForm.propTypes = {
  baseName: PropTypes.string,
  isEditable: PropTypes.bool,
};

const PhoneConfirmTemplate = styled.div`
  width: 100%;
  display: grid;
  grid-row-gap: 28px;
`;

export default PhoneConfirmForm;
