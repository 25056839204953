import React from 'react';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import cond from 'lodash/fp/cond';
import prop from 'lodash/fp/prop';
import always from 'lodash/fp/always';
import T from 'lodash/fp/stubTrue';
import { types } from 'shared';

import { getAvailableExtensions } from 'utils/isFile';

export const getMaxSize = (maxSize = 200) => {
  const oneMb = 1048576;
  return maxSize * oneMb
}

const StyledDropzone = React.memo(function StyledDropzone({ children, onDrop, multiple, accept: _accept, noClick, noDrag, error, maxSize }) {
  const accept = getAvailableExtensions(_accept);
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    multiple,
    accept,
    noClick,
    noDrag,
    maxSize: getMaxSize(maxSize),
  });

  return (
    <div className="container">
      <Container {...getRootProps({ isDragActive, isDragAccept, isDragReject })} error={error}>
        <input {...getInputProps()} />
        {children}
      </Container>
    </div>
  );
});

const getColor = cond([
  [prop('isDragAccept'), always('#00e676')],
  [prop('isDragReject'), always('#ff1744')],
  [prop('isDragActive'), always('#2196f3')],
  [T, always('#eeeeee')]
]);

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: ${props => getColor(props)};
  outline: none;
  height: 100%;
  transition: border .24s ease-in-out;
`;

const typesFile = types.file.Types;

StyledDropzone.defaultProps = {
  accept: typesFile.PHOTO,
  multiple: true,
  noDrag: false
};

StyledDropzone.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  onDrop: PropTypes.func,
  multiple: PropTypes.bool,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  maxSize: PropTypes.number,
};

export default StyledDropzone;


