import React from 'react';
import MaskInput from './MaskInput';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const PercentInput = ({ rules, ...props }) => {
  const t = useGetTranslate()
  return (
    <MaskInput
      suffix=' %'
      min={0}
      max={100}
      rules={{
        max: {
          message: t('shared.input.percent.errors.max'),
          value: 100,
        },
        min: {
          message: t('shared.input.percent.errors.min'),
          value: 1,
        },
        ...rules
      }}
      {...props}
    />
  );
};

PercentInput.propTypes = {
  control: PropTypes.object,
  rules: PropTypes.object,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  className: PropTypes.string,
  value: PropTypes.string,
};

export default PercentInput
