import React from 'react';

export default function useScroll({ parentRef, childRef, callback, hasMore }) {
  const observer = React.useRef();

  React.useEffect(() => {
    const options = {
      root: parentRef.current,
      rootMargin: '0px',
      threshold: 0,
    };
    observer.current = new IntersectionObserver(([target]) => {
      if (target.isIntersecting && hasMore) {
        callback();
      }
    }, options);

    childRef.current && observer.current.observe(childRef.current);

    return function () {
      childRef.current && observer.current.unobserve(childRef.current);
    };
  }, [callback]);
}
