import styled from 'styled-components';

const Body2 = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: ${({ bold }) => bold ? '700' : '500'};
  font-size: 16px;
  line-height: 26px;
  
  color: #272835; // todo: fix with theme
  ${({ ...props }) => props}
`;

export default Body2;
