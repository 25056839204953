import React from 'react';
import { useParams } from 'react-router';

import {
  getDownloadPageContent as fetchHeading
} from './api'

import {
  useSetVideoMessageDownloadPage as useSetHeading
} from './store'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchVideoMessageDownloadPage() {
  const { offerVideoMessageOccurrenceId } = useParams()

  const setHeading = useSetHeading((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchHeading({ offerVideoMessageOccurrenceId })
      .then(setHeading)
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [offerVideoMessageOccurrenceId, setHeading]);
}
