export const fitText = (text, { minFontSize }) => {
  if (text) {
    const parent = text.parentNode;
    const parentWidth = parent.clientWidth;
    const parentPaddingLeft = parseInt(
      window.getComputedStyle(parent).paddingLeft
    );
    const parentPaddingRight = parseInt(
      window.getComputedStyle(parent).paddingRight
    );
    const parentPading = parentPaddingLeft + parentPaddingRight;

    let textWidth = text.scrollWidth;

    let textFontSize = parseInt(window.getComputedStyle(text).fontSize);

    if (textWidth < parentWidth - parentPading) {
      text.style.fontSize = '';
    }

    while (
      textWidth >= parentWidth - parentPading &&
      textFontSize > minFontSize
    ) {
      text.style.fontSize = `${textFontSize - 1}px`;

      textWidth = text.scrollWidth;
      textFontSize = parseInt(window.getComputedStyle(text).fontSize);
    }
  }
};
