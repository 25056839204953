import { _post, _put } from 'api/utils'

// todo: remove name after backen
export const createPromotionalMedia = ({ talentId, type, media, name = '' }) =>
  _post(`/talents/${talentId}/promotional-media/`, { body: { type, media, name }, needAuthorization: true })

export const setToPromotionalMedia = ({ promotionalMediaTypes, fileToken }) =>
  _put('/files/set-to-promotional', {
    needAuthorization: true,
    body: { fileToken, promotionalMediaTypes },
  })
