import React from 'react'
import PropTypes from 'prop-types'

import Select from '_legacy/components/Form/Selects/SelectComponent'
import OptionWithColor from './OptionWithColor'
import SingleValueWithColor from './SingleValueWithColor'


const SelectWithColor = (props) => {
  return (
    <Select
      components={{
        Option: OptionWithColor,
        SingleValue: SingleValueWithColor,
      }}
      {...props}
    />
  )
}

SelectWithColor.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  minTime: PropTypes.string,
  maxTime: PropTypes.string,
  rules: PropTypes.object,
}

export default SelectWithColor
