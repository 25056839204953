import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import has from 'lodash/has';

// utils
import { basicErrorsConfig } from 'utils/services/validators/passwordValidators';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import { Row, PasswordInput } from '_legacy/common';
import PasswordErrorList from './PasswordErrorList';

const PasswordBlock = React.memo(function PasswordBlock({
  passwordLabel,
  rePasswordLabel,
  errorsConfig = basicErrorsConfig,
  passwordValidationConfig,
  onPasswordChange,
  onRePasswordChange
}) {
  const t = useGetTranslate();
  const { errors, formState: { dirtyFields, touched }, trigger, control } = useFormContext();
  const password = useWatch({ control, name: 'password', defaultValue: '' });
  const [passwordFocused, setPasswordFocused] = React.useState(false);

  const triggerRePassword = () => dirtyFields.rePassword && trigger('rePassword')
  const triggerPassword = () => dirtyFields.Password && trigger('password')

  const onPasswordChangeHandler = onPasswordChange || triggerRePassword;
  const onRePasswordChangeHandler = onRePasswordChange || triggerPassword;

  const allErrors = {
    ...errors?.password?.types,
    ...(errors?.password?.type === 'server.same' && { same: true }),
  }
  const errorCount = errorsConfig.filter((error) => allErrors?.[error.key]).length

  return (
    <>
      <Row
        isRequired
        nameBlock={passwordLabel || t('shared.password-block.password.label')}
        component={
          <>
            <PasswordInput
              placeholder={t('shared.password-block.password.placeholder')}
              success={dirtyFields?.['password'] && errorCount === 0}
              onFocus={() => setPasswordFocused(true)}
              onBlur={() => setPasswordFocused(false)}
              onChange={onPasswordChangeHandler}
              name="password"
              validation={{
                validate: passwordValidationConfig,
              }}
              autoComplete="new-password"
            />
            <PasswordErrorList
              isTouched={has(touched, 'password')}
              isDirty={dirtyFields?.['password']}
              isFocused={passwordFocused}
              errorsConfig={errorsConfig}
              errors={allErrors}
            />
          </>
        }
      />
      <Row
        isRequired
        nameBlock={rePasswordLabel || t('shared.password-block.re-password.label')}
        component={
          <PasswordInput
            isRequired
            name="rePassword"
            onChange={onRePasswordChangeHandler}
            validation={{
              validate: rePassword =>
                rePassword === password ||
                t('shared.password-block.errors.not-equals'),
            }}
            placeholder={t('shared.password-block.re-password.placeholder')}
          />
        }
      />
    </>
  );
});

PasswordBlock.propTypes = {
  errorsConfig: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
  passwordValidationConfig: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
};

export default PasswordBlock;
