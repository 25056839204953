import React from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { api, UserRegistrationType } from 'shared';
import styled from 'styled-components';

import { useSetUser } from 'store/hooks/globalState/useUser';

import { Title } from '_legacy/common/Title';
import Button from '_legacy/components/Button';
import Loader from '_legacy/components/Common/Loader';

import { appApi } from 'api';
import { useFetch } from 'store/hooks/useFetch';
import { REGISTER, TALENT } from '_legacy/constants/routes';
import { setTokens } from 'utils/storage';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';


const errors = api.verify.email.verify.errors;


const getTexts = (t, error) => {
  if (!error) {
    return {
      header: t('verify-email.header.label'),
      tip: t('verify-email.tip.label'),
    };
  }

  const results = {
    [errors.UNKNOWN_KEY]: {
      header: t('verify-email.unknown-key.header.label'),
      tip: t('verify-email.unknown-key.tip.label'),
    },
    [errors.KEY_IS_EXPIRED]: {
      header: t('verify-email.key-is-expired.header.label'),
      tip: t('verify-email.key-is-expired.tip.label'),
    },
    [errors.ALREADY_VERIFIED]: {
      header: t('verify-email.already-verified.header.label'),
      tip: t('verify-email.already-verified.tip.label'),
    },
    [errors.ALREADY_USED]: {
      header: t('verify-email.already-used.header.label'),
      tip: t('verify-email.already-used.tip.label'),
    },
  };

  return (
    results[error.name] || {
      header: 'Unknown error',
      tip: 'Unknown error',
    }
  );
};

const pathConfig = {
  [UserRegistrationType.AFICIONADO]: '/',
  [UserRegistrationType.TALENT]: `/${REGISTER}/${TALENT}`,
}

const VerifyEmail = () => {
  const setUser = useSetUser((prev, next) => next);
  const t = useGetTranslate();
  const { key } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const [{ data, isLoading, error }, fetchData] = useFetch(appApi.verify.email.confirm);

  React.useEffect(() => {
    fetchData({ key });
  }, []);

  React.useEffect(() => {
    if (data) {
      setTokens(data);
      setUser(data.userData);

      const query = new URLSearchParams(search);

      if (query.get('viaInvite') === 'true') {
        history.push('/')
      } else {
        history.push(pathConfig[data.userData.user.registrationType])
      }
    }
  }, [data]);

  const loader = (
    <StyledWrapper>
      <Title>{t('verify-email.header.loader')}</Title>
      <Loader size="80" fill="#636583" />
    </StyledWrapper>
  );

  const content = (
    <StyledWrapper>
      <Title>{getTexts(t, error).header}</Title>
      <h4 className='subtitle'>{getTexts(t, error).tip}</h4>
      <Button
        text={t('verify-email.actions.home.label')}
        handleOnClick={() => history.push('/')}
        paddingVertical="13"
        paddingHorizontal="55"
      />
    </StyledWrapper>
  );

  return isLoading ? loader : content;
};

const StyledWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  .subtitle {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1.4;
    color: ${({ theme: { colors } }) => colors.black};
  }
`;

export default VerifyEmail;

