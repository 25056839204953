// Modules
import React from 'react'
import { bool } from 'prop-types'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import { types } from 'shared'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useCreateLiveVirtualEvents, useCreateLiveVirtualEventsDraft } from 'requests/live-virtual-events'
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { getDefaultValues } from '_legacy/containers/LiveVirtualEvents/utils/defaultValues'
import { getVirtualEventTypeOptions } from '_legacy/containers/LiveVirtualEvents/utils/config'
import { createLiveVirtualEventsAdapter } from '_legacy/containers/LiveVirtualEvents/utils/createLiveVirtualEventsAdapter'
import { getUserTimeZone } from 'utils/user'

// Legacy components
import Form from '_legacy/containers/LiveVirtualEvents/Form'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import OfferingDescription from '_legacy/containers/Offerings/components/OfferingDescription'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'


const CreateLiveVirtualEvent = React.memo(({ isMasterclass }) => {
  const t = useGetTranslate()
  const [isLoading, setIsLoading] = React.useState(false)
  const createLiveVirtualEvents = useCreateLiveVirtualEvents(setIsLoading)
  const createLiveVirtualEventsDraft = useCreateLiveVirtualEventsDraft(setIsLoading)

  const timeZoneId = useUser(getUserTimeZone)

  const defaults = {
    timeZone: timeZoneId,
  }

  if (isMasterclass) {
    Object.assign(defaults, {
      type: getVirtualEventTypeOptions(t, types.talent.offers.liveVirtualEvent.VirtualEventType.MASTERCLASS)
    })
  }

  const methods = useForm({
    defaultValues: { ...getDefaultValues(t), ...defaults },
    shouldUnregister: false,
    mode: 'onChange',
  })

  const onSubmit = compose(
    createLiveVirtualEvents,
    createLiveVirtualEventsAdapter
  )

  const onDraft = compose(
    createLiveVirtualEventsDraft,
    createLiveVirtualEventsAdapter,
    methods.getValues
  )

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('live-virtual-events.title.create')}</OfferingTitle>
      <OfferingDescription>{t('live-virtual-events.subtitle')}</OfferingDescription>
      <Form methods={methods} onSubmit={onSubmit} onDraft={onDraft} isLoading={isLoading} />
    </OfferingTemplate>
  )
})

CreateLiveVirtualEvent.propTypes = {
  isMasterclass: bool
}

CreateLiveVirtualEvent.defaultProps = {
  isMasterclass: false
}

export default withLibrary(CreateLiveVirtualEvent)
