import React from 'react'
import cx from 'classnames'
import { eq } from 'lodash/fp'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { STATUS_STATE } from '../constants.js'


const StatusBarMarker = ({ title, state, subtitle, className, ...props }) => {
  const t = useGetTranslate()
  return (
    <div className={
      cx(
        'status-bar-step',
        className,
        {
          'status-bar-step--done': eq(state, STATUS_STATE.DONE),
          'status-bar-step--active': eq(state, STATUS_STATE.ACTIVE),
          'status-bar-step--inactive': eq(state, STATUS_STATE.INACTIVE),
        }
      )
    } {...props}>
      <div className="status-bar-step__title">{t(title)}</div>
      <span className="status-bar-step__marker"></span>
      {
        subtitle &&
          <div className="status-bar-step__subtitle">{t(subtitle)}</div>
      }
    </div>
  )
}

export default StatusBarMarker
