import React from 'react'
import { Link, useLocation, Router } from 'react-router-dom'
import cx from 'classnames'

import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { hashHistory } from 'utils/history'

import {
  RESOURCE_CENTER_FREE_ADVERTISEMENTS,
  RESOURCE_CENTER_GUIDES,
  RESOURCE_CENTER_HOW_TO_VIDEOS,
  RESOURCE_CENTER_MEDIA_KITS_TEMPLATES
} from '_legacy/constants/routes'

// Store (Hooks)
import {
  useResourceCenter,
  useSetResourceCenter,
  useResourceCenterFilters,
} from './state/store'
import { useFetchResourceCenter } from './state/requests'

import ResourceCenterRouter from './components/ResourceCenterRouter'
import ResourceCenterItems from './components/ResourceCenterItems'

const ResourceCenter = () => {
  const t = useGetTranslate()
  const userLang = useUserLang()

  const location = useLocation()
  const { hash } = location

  const [page, setPage] = React.useState(1)
  const [activeHash, setActiveHash] = React.useState(hash.slice(1))

  const fetchResourceCenter = useFetchResourceCenter()
  const resourceCenterList = useResourceCenter()
  const resourceCenterFilters = useResourceCenterFilters()
  const clearResourceCenterList = useSetResourceCenter(() => null)

  React.useEffect(() => {
    setPage(1)
    fetchResourceCenter({ page: 1, typeIn: [(activeHash || RESOURCE_CENTER_GUIDES).toUpperCase()] })

    return () => clearResourceCenterList()
  }, [hash, userLang])

  const onClickLoadMore = () => {
    const currPage = page + 1
    setPage(currPage)
    fetchResourceCenter({ page: currPage, typeIn: [(activeHash || RESOURCE_CENTER_GUIDES).toUpperCase()] })
  }

  const isActiveLink = (link, isRootPath) => {
    if (isRootPath) {
      return hash === link || hash === ''
    }

    return hash === link
  }

  const commonComponentProps = {
    activeHash,
    onClickLoadMore,
    Component: ResourceCenterItems,
    list: resourceCenterList || [],
    filters: resourceCenterFilters,
  }

  const resourceRoutesConfig = [
    {
      title: t('resource-center.nav.guides'),
      to: RESOURCE_CENTER_GUIDES,
      isDefault: true,
      ...commonComponentProps
    },
    {
      title: t('resource-center.nav.how-to-videos'),
      to: RESOURCE_CENTER_HOW_TO_VIDEOS,
      ...commonComponentProps
    },
    {
      title: t('resource-center.nav.free-advertisements'),
      to: RESOURCE_CENTER_FREE_ADVERTISEMENTS,
      ...commonComponentProps
    },
    {
      title: t('resource-center.nav.templates'),
      to: RESOURCE_CENTER_MEDIA_KITS_TEMPLATES,
      ...commonComponentProps
    },
  ]

  return (
    <div className="resource-center wrapper content-wrapper">
      <h1 className="font-header-h1">{t('resource-center.header.title')}</h1>
      <p className="font-body-1">{t('resource-center.header.description')}</p>

      <div className="resource-center__wrapper">
        <div className="resource-center__nav">
          <Router history={hashHistory}>
            {resourceRoutesConfig.map(({ title, to, isDefault }) => (
              <Link
                key={to}
                onClick={() => setActiveHash(to)}
                to={`/${to}`}
                className={cx({
                  'active': isActiveLink(`#${to}`, isDefault)
                })}
              >
                {title}
              </Link>
            ))}
          </Router>
        </div>
        <ResourceCenterRouter config={resourceRoutesConfig} />
      </div>
    </div>
  )
}

export default ResourceCenter
