import React from 'react'
import { propOr } from 'lodash/fp'

import { filters } from './config'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetTalentCalendarFilters, useTalentCalendarFilters } from 'components/Calendar/TalentCalendar/state/store/useCalendar'

import { CommonCheckbox } from '_legacy/common/Checkbox/Checkbox'

const CategoriesSidebar = () => {
  return (
    <aside className="categories-sidebar">
      {filters.map(filterGroup => (
        <FilterGroup key={filterGroup.field} {...filterGroup} />
      ))}
    </aside>
  )
}

const FilterGroup = ({ options, label, field }) => {
  const t = useGetTranslate()
  const groupState = useTalentCalendarFilters(propOr([], field))
  const setGroupState = useSetTalentCalendarFilters((prev, next) => {
    const prevState = propOr([], field, prev)
    const nextState = prevState.includes(next) ? prevState.filter(key => key !== next) : [...prevState, next]
    return { ...prev, [field]: nextState }
  })

  return (
    <div className="calendar-filter-group">
      <h6 className="calendar-filter-group__header">{t(label)}</h6>
      <ul className="calendar-filter-group__option-list">
        {options.map(option => (
          <label key={option.value} className="calendar-checkbox">
            <CommonCheckbox
              type="checkbox"
              value={option.value}
              checked={groupState.includes(option.value)}
              onChange={() => setGroupState(option.value)}
              className='calendar-checkbox__input'
            />
            <span className='calendar-checkbox__label'>{t(option.label)}</span>
          </label>
        ))}
      </ul>
    </div>
  )
}

export default CategoriesSidebar
