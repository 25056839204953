import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input } from './Input';
import CheckIcon from 'mdi-react/CheckIcon'
import EyeOutlineIcon from 'mdi-react/EyeOutlineIcon'
import EyeOffOutlineIcon from 'mdi-react/EyeOffOutlineIcon'

const StyledPasswordWrapper = styled.div`
  position: relative;
  
  input {
    padding-right: 64px;
  }
`;

const IconsWrapper = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  right: 8px;
  top: 2px;
  height: 58px;
`;

const EyeWrapper = styled.button.attrs(p => ({
  type: p.type || 'button'
}))`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #BABBCD;
  transition: all .3s linear;
`;

export const PasswordInput = React.memo(function PasswordInput ({ success, ...props }) {
  const [isFieldTypePassword, setIsFieldTypePassword] = useState(true);

  const style = {}

  if (success) {
    style.borderColor = 'green'
  }

  return (
    <StyledPasswordWrapper>
      <Input
        type={isFieldTypePassword ? 'password' : 'text'}
        style={style}
        {...props}
      />
      <IconsWrapper>
        <EyeWrapper
          onClick={() => setIsFieldTypePassword(p => !p)}
        >
          {isFieldTypePassword ? <EyeOutlineIcon size={30} /> : <EyeOffOutlineIcon size={30} />}
        </EyeWrapper>
        {success && <CheckIcon style={{ color: 'green' }} size={30} />}
      </IconsWrapper>
    </StyledPasswordWrapper>
  );
});

PasswordInput.propTypes = {
  success: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
};

PasswordInput.defaultProps = {
  success: false,
};

EyeWrapper.propTypes = {
  isOpen: PropTypes.bool,
};
