import { TalentOffersType } from 'shared';
import OfferingTypesIcon from 'assets/icons/OfferingTypesIcon';

const {
  VIDEO_CHAT, VIDEO_MESSAGE, IN_PERSON_EXPERIENCE, VIDEO_VOICE_OVER_LESSON,
  LIVE_IN_PERSON_LESSON, VIRTUAL_LESSON, VIRTUAL_EVENT, READY_MADE_LESSON, FOR_COMMERCIAL_USE,
} = TalentOffersType;


export const typesOfferings = {
  [VIDEO_CHAT]: {
    icon: OfferingTypesIcon.VideoChat,
    name: 'payment-page.offering.label.video-chat'
  },
  [VIDEO_MESSAGE]: {
    icon: OfferingTypesIcon.VideoMessages,
    name: 'payment-page.offering.label.video-message'
  },
  [IN_PERSON_EXPERIENCE]: {
    icon: OfferingTypesIcon.InPersonExperiences,
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    icon: OfferingTypesIcon.Lessons,
    name: 'payment-page.offering.label.lessons.video-voice'
  },
  [LIVE_IN_PERSON_LESSON]: {
    icon: OfferingTypesIcon.Lessons,
    name: 'payment-page.offering.label.lessons.live-in-persone'
  },
  [VIRTUAL_LESSON]: {
    icon: OfferingTypesIcon.Lessons,
    name: 'payment-page.offering.label.lessons.virtual'
  },
  [VIRTUAL_EVENT]: {
    icon: OfferingTypesIcon.VirtualEvent,
  },
  [READY_MADE_LESSON]: {
    icon: OfferingTypesIcon.ReadyMadeEvents,
  },
  [FOR_COMMERCIAL_USE]: {
    icon: OfferingTypesIcon.CommercialVideoMessage,
    name: 'payment-page.offering.label.video-message-commercial'
  },
};
