import styled from 'styled-components'
import PropTypes from 'prop-types'

const MakePrimaryBtn = styled.button.attrs({
  type: 'button'
})`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightGrey};

  padding: 4px 6px;

  background: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.borderRadius.default};

  border: none;
  cursor: pointer;
  transition: all .3s ease-in-out;
  opacity: 0;

  position: absolute;
  bottom: 0;
`;

MakePrimaryBtn.propTypes = {
  onClick: PropTypes.func,
}

export default MakePrimaryBtn
