import { io } from 'socket.io-client'
import { getAccessToken } from 'utils/storage'

const { error, debug } = console

const SOCKET_SERVER_URL = process.env.REACT_APP_API_URL
const CONNECT_EVENT = 'connect'
const JOIN_EVENT = 'join'
const NOTIFICATION_EVENT = 'notification'
const DISCONNECT_EVENT = 'disconnect'

export class Notifications {
  constructor() {
    if (Notifications._instance) {
      return Notifications._instance
    }

    Notifications._instance = this

    this._socket = null
    this._room = null
    this._userId = null
  }

  connect() {
    this._socket = io(SOCKET_SERVER_URL, { autoConnect: false, auth: { token: getAccessToken() } })

    try {
      this._socket.removeAllListeners(CONNECT_EVENT)
      this._socket.on(CONNECT_EVENT, () => {
        debug('Notifications::Connected', this._socket.id)

        this._socket.removeAllListeners(DISCONNECT_EVENT)
        this._socket.on(DISCONNECT_EVENT, () => {
          debug('Notifications::Disconnected')

          this._socket.removeAllListeners(NOTIFICATION_EVENT)
          this._room = null
          this._userId = null
        })
      })

      this._socket.connect()
    } catch (e) {
      error('Notifications::Error', e)
    }

    return this
  }

  join(roomId, userId) {
    try {
      if (this._room !== roomId) {
        debug('Notifications::Joining Room', roomId)
        this._socket.emit(JOIN_EVENT, roomId,)
        this._room = roomId
        this._userId = userId
      }
    } catch (e) {
      error('Notifications::Error ', e)
    }

    return this
  }

  listen(listener) {
    const notificationHandler = (...args) => {
      debug('Notifications::Notification', ...args)

      try {
        listener.call(this, ...args)
      } catch (e) {
        error('Notifications::Error', e)
      }
    }

    try {
      this._socket.removeAllListeners(NOTIFICATION_EVENT)
      this._socket.on(NOTIFICATION_EVENT, notificationHandler) // aficionado notifications
      this._socket.on(`${NOTIFICATION_EVENT}:${this._userId}`, notificationHandler) // talent notifications
      this._socket.on('error', e => error('Socket::Error', e))
    } catch (e) {
      error('Notifications::Error', e)
    }

    return this
  }

  disconnect() {
    try {
      if (this._socket)
        this._socket.disconnect()
    } catch (e) {
      error('Notifications::Error', e)
    }

    return this
  }
}
