import { cond, equals, constant, T } from 'lodash/fp'

import { TypesOfDays } from 'shared'

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  BOTH,
} = TypesOfDays

export const getVideoChatNoteText =
  cond([
    [equals(ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR), constant('video-chat.public.any-available-day-time')],
    [equals(ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME), constant('video-chat.public.allow-aficionado-to-propose')],
    [equals(BOTH), constant('video-chat.public.both')],
    [T, constant('video-chat.public.both')],
  ])
