import React from 'react';
import { number, func } from 'prop-types';
import styled , { css } from 'styled-components';

import LocationIcon from 'assets/icons/LocationIcon';
import NationalityIcon from 'assets/icons/NationalityIcon';
import LanguageIcon from 'assets/icons/LanguageIcon';
import CharitiesIcon from 'assets/icons/CharitiesIcon';

const TalentInfoBlockIconsBar = ({ activeIndex, setActiveIndex }) => {
  return (
    <div className="talent-info-block__icons-bar">
      <StyledIconItem
        active={activeIndex === 0}
        onClick={() => setActiveIndex(0)}
      >
        <LocationIcon />
      </StyledIconItem>
      <StyledIconItem
        active={activeIndex === 1}
        onClick={() => setActiveIndex(1)}
      >
        <NationalityIcon />
      </StyledIconItem>
      <StyledIconItem
        active={activeIndex === 2}
        onClick={() => setActiveIndex(2)}
      >
        <LanguageIcon />
      </StyledIconItem>
      <StyledIconItem
        active={activeIndex === 3}
        onClick={() => setActiveIndex(3)}
      >
        <CharitiesIcon />
      </StyledIconItem>
    </div>
  );
};

const StyledIconItem = styled.div`
  width: 62px;
  margin: 7px;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
    fill: ${({ theme }) =>  theme.colors.lightGrey};
    transition: all .3s ease-in-out;
  }
  ${({ active, theme }) => active && css`
    svg {
      fill: ${theme.colors.blue};
    }
  `}
`

TalentInfoBlockIconsBar.propTypes = {
  activeIndex: number.isRequired,
  setActiveIndex: func.isRequired,
};

export default TalentInfoBlockIconsBar;
