import React from 'react'

import { _get } from 'api/utils';

export default function SlidesLoader ({ dataUrl, dataField, setSlides }) {
  React.useEffect(() => {
    _get(dataUrl, {
      query: {
        limit: 50
      }
    })
      .then(data => {
        setSlides(data[dataField])
      })
      .catch(err => console.warn(err))
  }, [setSlides])

  return null
}
