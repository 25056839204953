import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import CheckboxListItem from './CheckboxListItem'
import FileList from '../../components/Library/FileList'
import compact from 'lodash/compact';
import castArray from 'lodash/castArray';

const MultiSelectFileList = ({ name, maxSelectItems, enableTypes }) => {
  const { watch } = useFormContext()
  const files = compact(castArray(watch(name, [])))

  const isDisabled = file => {
    const _enableTypes = compact(castArray(enableTypes))
    const checkFile = _enableTypes.length === 0 ? false : !_enableTypes.includes(file.type)
    return files.length >= maxSelectItems && !files.includes(file.fileToken) || checkFile
  }

  return (
    <FileList>
      {file => <CheckboxListItem key={file.id} file={file} name={name} disabled={isDisabled(file)} />}
    </FileList>
  )
}

MultiSelectFileList.propTypes = {
  name: PropTypes.string,
  maxSelectItems: PropTypes.number,
}

export default MultiSelectFileList
