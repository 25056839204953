import React from 'react'

//table configs
import {
  virtualEventFiltersConfig,
  counterParticipantsConfig,
  virtualEventSubFiltersConfig,
  virtualEventFields,
} from '_legacy/configs/table/orderDetailsRequests'
import {
  useVirtualEventOrderDetailsTableFilters,
  useSetVirtualEventOrderDetailsTableFilters,
  useVirtualEventOrderDetailsTable,
} from '../state/store'
import { useFetchVirtualEventOrderDetailsTable } from '../state/requests'
//table components
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import {
  OFFERINGS_DETAILS_PARTICIPANTS,
  elementsTypes,
} from '_legacy/components/Table/table-components/types/order-details/config'

const sortFields = {
  AFICIONADO_FULL_NAME: 'AFICIONADO_FULL_NAME',
  PARTICIPANTS_COUNT: 'PARTICIPANTS_COUNT',
  ORDER_PRICE_AMOUNT: 'ORDER_PRICE_AMOUNT',
  ORDER_NUMBER: 'ORDER_NUMBER',
  ORDER_STATUS: 'ORDER_STATUS',
}

const tableConfig = [
  {
    header: 'table.header.aficionado',
    field: sortFields.AFICIONADO_FULL_NAME,
    type: elementsTypes.aficionado,
  },
  {
    header: 'table.header.order',
    type: elementsTypes.order,
    field: sortFields.ORDER_NUMBER
  },
  {
    header: 'table.header.participants',
    field: sortFields.PARTICIPANTS_COUNT,
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.amount',
    field: sortFields.ORDER_PRICE_AMOUNT,
    type: elementsTypes.amount,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    field: sortFields.ORDER_STATUS,
    type: elementsTypes.status,
    className: 'centered',
  },
]

const VirtualEventOrderDetailsOrders = () => {
  const fetchTable = () => useFetchVirtualEventOrderDetailsTable()

  return (
    <div className="wrapper content-wrapper">
      <Filters
        filtersConfig={virtualEventFiltersConfig}
        getFn={useVirtualEventOrderDetailsTableFilters}
        setFn={useSetVirtualEventOrderDetailsTableFilters}
      />
      <SubFilters
        counterConfig={counterParticipantsConfig}
        subFiltersConfig={virtualEventSubFiltersConfig}
        getFn={useVirtualEventOrderDetailsTableFilters}
        setFn={useSetVirtualEventOrderDetailsTableFilters}
      />
      <Table
        tableName={OFFERINGS_DETAILS_PARTICIPANTS}
        emptyTableText={'table.empty-participants'}
        tableConfig={tableConfig}
        getTableFn={useVirtualEventOrderDetailsTable}
        fields={virtualEventFields}
        getFiltersFn={useVirtualEventOrderDetailsTableFilters}
        setFiltersFn={useSetVirtualEventOrderDetailsTableFilters}
        fetchTableFn={fetchTable}
      />
    </div>
  )
}

export default VirtualEventOrderDetailsOrders
