import range from 'lodash/range'
import { minutesToSeconds } from 'date-fns'
import { getTimeOptions } from 'utils/secondsToWdhms/secondsToWdhms'

// 5min, 10, 15, 20, 25, 30, 45, 1h, 1.5h, 2h ... 24h
const durationMinutes = [
  5, 10, 15, 20, 25, 30, 45,
  ...range(60, 24 * 60 + 30, 30),
].map(minutesToSeconds);

export const getDurationOptions = options => getTimeOptions(durationMinutes, options)

export const durationOptions = getDurationOptions()
