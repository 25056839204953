import React from 'react';
import PropTypes from 'prop-types';
import { Row } from '_legacy/common';
import LocationsSelect from '_legacy/components/Form/Selects/basic/LocationsSelect';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
// utils
import { useFetch } from 'store/hooks/useFetch';
import {
  getLocationDetails as getLocationDetailsApi
} from 'api/appApi/integration/locations/details/details';
import './index.scss'
import { getAdapter } from './utils';
import { Trans } from 'store/hooks/globalState/useTranslates';
export const LocationNameAndAddress = ({ name = 'location', isRequired, precision }) => {
  const t = useGetTranslate();
  const fields = {
    address: name,
    LocationName: name+'Name',
  };
  const { setValue, watch } = useFormContext();
  const [{ data }, getLocationDetails] = useFetch(getLocationDetailsApi);
  const values = watch([fields.LocationName,fields.address]);
  const LocationName = get(values, fields.LocationName);

  React.useEffect(() => {
    if (LocationName?.data) {
      getLocationDetails({ googlePlaceId: LocationName.value });
    }
  }, [LocationName]);
  React.useEffect(() => {
    if (data) {
      const response = getAdapter(data);
      forEach(
        response,
        (value, key) => setValue(fields[key], value, { shouldValidate: true, shouldDirty:true })
      );
    }
  }, [data]);

  return (
    <>
      <Row
        isRequired={isRequired}
        nameBlock={<Trans
          tKey="register.form.location-name.label"
          phs={[
            { ph: '{{index}}', value: '' },
          ]}
        />}
        component={
          <LocationsSelect
            isCreatable
            name={fields.LocationName}
            placeholder={t('create-experience.form.fixed-location.placeholder')}
            precision={precision}
            rules={{
              validate: (p) => !!p?.label === true || 'create-experience.form.fixed-location.errors.required',
            }}
          />
        }
      />
      <Row
        nameBlock={<Trans
          tKey="register.form.location-address.label"
          phs={[
            { ph: '{{index}}', value: '' },
          ]}
        />}
        component={
          <LocationsSelect
            isRequired={isRequired}
            defaultValue={fields.address}
            name={fields.address}
            precision={precision}
            placeholder={t('register.form.location-address.placeholder')}
            rules={{
              validate: (p) => !!p?.label === true || 'create-experience.form.fixed-location.errors.required',
            }}
          />
        }
      />
    </>
  );
};

LocationNameAndAddress.propTypes = {
  name: PropTypes.string,
  nameBlock: PropTypes.string,
  precision: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default LocationNameAndAddress;
