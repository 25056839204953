import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import debounce from 'lodash/debounce';
import get from 'lodash/get';

import { emailPattern } from 'utils/services/validators/emailValidator';
import { checkEmail as checkEmailApi } from 'api/appApi/utils/email';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Input from '../Input';

const errors = {
  EMAIL_IS_NOT_UNIQUE: 'shared.input.email.email-is-not-unique',
  EMAIL_IS_NOT_EMAIL: 'shared.input.email.email-is-not-email',
};

const Email = ({ name = 'email', shouldValidate, ...props }) => {
  const t = useGetTranslate();
  const { clearErrors, setError, control, formState } = useFormContext();
  const email = useWatch({ name, control, defaultValue: '' });
  const isDirty = get(formState.dirtyFields, name, false);
  const [localUniqError, setLocalUniqError] = React.useState();

  const checkEmail = async email => {
    if (!emailPattern.test(email)) {
      setError(name, { message: t(errors.EMAIL_IS_NOT_EMAIL) });
      return;
    }

    try {
      await checkEmailApi({ email });
      clearErrors(name);
      setLocalUniqError(null);
    } catch (e) {
      const message = t(errors.EMAIL_IS_NOT_UNIQUE);
      setError(name, { message });
      setLocalUniqError(message);
    }
  };

  const debounceCheck = React.useCallback(debounce(checkEmail, 500), []);

  React.useEffect(() => {
    if (shouldValidate && isDirty) {
      debounceCheck(email);
    }
  }, [email, isDirty]);

  return (
    <Input
      isRequired
      name={name}
      type="email"
      validation={{ validate: () => localUniqError }}
      label={t('register-user.form.email.label')}
      placeholder={t('register-user.form.email.placeholder')}
      {...props}
    />
  );
};

Email.propTypes = {
  name: PropTypes.string,
  shouldValidate: PropTypes.bool,
};

export default Email;
