import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import castArray from 'lodash/castArray'

export const submitMultiSelectLibraryAdapter = (media, primaryMedia) => {
  return primaryMedia || !isEmpty(media)
    ? map(castArray(media), fileToken => ({ fileToken, isPrimary: fileToken === primaryMedia }))
    : undefined
}

