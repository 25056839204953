import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components'

import Price from '_legacy/components/Price'

import PublicLocation from '_legacy/components/PublicView/PublicLocation/';
import Avatar from '_legacy/components/Avatar'
import Rating from '_legacy/components/Rating'
import PurposeLocationInfo from './PublicLocation/PurposeLocationInfo';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { getFullName } from 'utils/user'

const PublicCardBlockInfo = ({
  talent,
  duration,
  locations,
  pricePerParticipant,
  withBg,
  isOnline,
  hideAddressFromPubView
}) => {
  const t = useGetTranslate();
  const userName = getFullName(talent)

  return (
    <StyledPublicViewCardInfo withBg={withBg}>
      <StyledPublicViewAuthor>
        <Avatar file={talent.profileImage?.file} alt={userName} isDark />
        <StyledPublicViewAuthorInfo>
          <h5 className="experience-pv-card-heading">
            {t('experience.card.heading')}
          </h5>
          <h2 className="experience-pv-card-title">{userName}</h2>
          <p className="title">{talent.title}</p>
          <StyledPublicViewAuthorCta>
            <Rating rating={talent?.reviewsInfo.averageRating} leftColor="#fff" rightColor="#626473" />
            <Link to={`/${talent.nickname}`}>
              {t('experience.card.profile-link')}
              <svg
                width="11"
                height="7"
                viewBox="0 0 11 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 1L5.5 5.5L0.999999 1"
                  stroke="#ffffff"
                  strokeWidth="2"
                />
              </svg>
            </Link>
          </StyledPublicViewAuthorCta>
        </StyledPublicViewAuthorInfo>
      </StyledPublicViewAuthor>
      <StyledPublicViewBody>
        {locations.length > 0 ? (
          <PublicLocation locations={locations} isOnline={isOnline} hideAddressFromPubView={hideAddressFromPubView} />
        ) : <PurposeLocationInfo />}
        {duration && (
          <StyledCardItem>
            <h4>{t('experience.card.duration')}</h4>
            <p>{duration}</p>
          </StyledCardItem>
        )}
        <StyledCardItem>
          <h4>{t('experience.card.price')}</h4>
          <Price price={pricePerParticipant} withCurrency />
        </StyledCardItem>
      </StyledPublicViewBody>
    </StyledPublicViewCardInfo>
  )
};

const StyledPublicViewCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 0 50%;
  height: 100%;
  padding: 64px 16px 28px 64px;
  ${({ withBg }) =>
  withBg &&
    css`
      background: #292c38;
      background: linear-gradient(
        146deg,
        #292c38 0%,
        #292c38 32%,
        rgba(0, 0, 0, 0) 100%
      );
    `}
  @media only screen and (max-width: 1200px) {
    padding: 24px 16px 28px 24px;
  }
  @media only screen and (max-width: 767px) {
    padding: 28px 16px;
    flex: 0;
  }
`

const StyledPublicViewAuthor = styled.div`
  display: flex;
  
  .avatar {
    width: 182px;
    height: 182px;
    background: #1e1f28;
    border: 8px solid #2a2c39;
    box-shadow: 14px 17px 35px #16171f, -7px -10px 20px 7px #4d516a;

    @media only screen and (max-width: 1024px) {
      width: 86px;
      height: 86px;
      border: 4px solid #2a2c39;
      margin: 0;
    }
  }
`;

const StyledPublicViewAuthorInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 48px;
  h5 {
    font-family: Playfair Display;
    font-weight: 900;
    font-size: 1.375em;
    color: #9495a7;
    font-feature-settings: 'case' on, 'liga' off;
  }
  h2 {
    font-family: Playfair Display;
    font-weight: 900;
    font-size: 2.625em;
    margin-top: 18px;
    color: #ffffff;
    font-feature-settings: 'case' on, 'liga' off;
  }
  .title {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.4em;
    text-transform: uppercase;
    color: #ffffff;
  }
  @media only screen and (max-width: 1200px) {
    margin-left: 24px;
  }
  @media only screen and (max-width: 1024px) {
    margin-left: 16px;
    h5 {
      font-size: 16px;
      line-height: 24px;
    }
    h2 {
      font-size: 23px;
      line-height: 29px;
      margin-top: 8px;
    }
  }
`

const StyledPublicViewAuthorCta = styled.div`
  display: flex;
  justify-content: space-between;
  a {
    font-family: Montserrat;
    font-weight: 700;
    font-size: 1em;
    line-height: 26px;
    color: #ffffff;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    svg {
      margin-left: 8px;
      transform: rotate(-90deg);
    }
    @media only screen and (max-width: 1024px) {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      width: auto;
    }
  }
`

const StyledPublicViewBody = styled.div`
  margin: 16px 0 32px;
  overflow: overlay;
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  flex: 1 1;
  text-align: left;
  margin-left: 230px;
  @media only screen and (max-width: 1200px) {
    margin-left: 210px;
  }
  @media only screen and (max-width: 1024px) {
    text-align: 0 0 32px 0;
    margin: 0;
  }
  @media only screen and (max-width: 767px) {
    text-align: center;
  }
`;

const StyledCardItem = styled.div`
  margin-top: 36px;
  h4 {
    font-family: Playfair Display;
    font-weight: 900;
    font-size: 1.375em;
    line-height: 29px;
    color: #ffffff;
  }
  p, ._price__value, ._price__currency {
    font-family: Montserrat;
    font-weight: 500;
    font-size: 1em;
    line-height: 26px;
    color: #ffffff;
    margin-top: 12px;
  }
  @media (max-width: 1440px) {
    margin-top: 24px;
  }
`

PublicCardBlockInfo.propTypes = {
  talent: PropTypes.object,
  duration: PropTypes.string,
  locations: PropTypes.array,
  pricePerParticipant: PropTypes.number,
  withBg: PropTypes.bool,
  isOnline: PropTypes.bool,
};

export default PublicCardBlockInfo;
