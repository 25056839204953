import React from 'react';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const configDescription = [
  {
    number: '01',
    title: 'careers.info.first.label',
    description: 'careers.text.first.label',
  },
  {
    number: '02',
    title: 'careers.info.second.label',
    description: 'careers.text.second.label',
  },
  {
    number: '03',
    title: 'careers.info.third.label',
    description: 'careers.text.third.label',
  },
  {
    number: '04',
    title: 'careers.info.fourth.label',
    description: 'careers.text.fourth.label',
  },
];

const DescriptionCareers = () => {
  const t = useGetTranslate();
  return (
    <StyledWrapper className="careers-list">
      {configDescription.map((c, i) => (
        <div className="careers-list__item" key={i}>
          <div className='careers-list__top'>
            <span>{c.number}</span>
            <h4>{t(c.title)}</h4>
          </div>
          <p>{t(c.description)}</p>
        </div>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`

    &__top {
      position: relative;
      margin-bottom: 20px;
      span {
        font-family: 'Playfair Display',sans-serif;
        font-weight: 700;
        font-size: 220px;
        line-height: 220px;
        text-align: center;
        text-transform: uppercase;
        font-feature-settings: 'case' on;
        color: ${({ theme: { colors } }) => colors.grey};
        opacity: 0.08;
        display: block;
      }

      h4 {
        font-family: 'Playfair Display',sans-serif;
        font-weight: 900;
        font-size: 42px;
        line-height: 54px;
        text-align: center;
        font-feature-settings: 'case' on, 'liga' off;
        color: ${({ theme: { colors } }) => colors.blue};

        top: 28%;
        bottom: 24px;
        width: 100%;
      }
    }
    &__item {
      p {
        font-weight: 500;
        font-size: 20px;
        line-height: 1.6;
        text-align: center;
        color: ${({ theme: { colors } }) => colors.blue};
      }
    }

    //@media #{$dt-m},
    //#{$md} {
    //  grid-template-columns: repeat(2, 1fr);
    //  grid-template-rows: repeat(2, 1fr);
    //
    //  &__item {
    //    span {
    //      font-size: $careers-info-label-fs-dt-m;
    //      line-height: $careers-info-label-fs-dt-m;
    //    }
    //
    //    h4 {
    //      font-size: get-font-size('l-2');
    //      line-height: 48px;
    //    }
    //
    //    p {
    //      font-size: get-font-size('s-1');
    //      line-height: get-spacer('6');
    //    }
    //  }
    //}
    //
    //@media #{$sm} {
    //  //grid-template-columns: repeat(1, 1fr);
    //  display: flex;
    //  align-items: center;
    //  flex-direction: column;
    //
    //  &__top {
    //    margin-bottom: 24px;
    //  }
    //
    //  &__item {
    //    span {
    //      font-size: $careers-info-label-fs-dt-m;
    //      line-height: $careers-info-label-fs-dt-m;
    //    }
    //
    //    h4 {
    //      font-size: get-font-size('l-2');
    //    }
    //
    //    p {
    //      font-size: get-font-size('m-5');
    //      line-height: get-spacer('6');
    //    }
    //  }
    //}
`;

export default DescriptionCareers;
