import React from 'react'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import eventPath from 'utils/eventPath'

const MenuDots = ({ optionsList = [] }) => {
  const t = useGetTranslate()
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleMenu = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])

  const closeMenu = React.useCallback(
    e => {
      if (eventPath(e).includes(ref.current)) return
      setIsOpen(false)
    },
    [ref, setIsOpen]
  )

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const menuOptions = React.useMemo(() => {
    return optionsList.map(option => (
      <li className="menu-list__item" key={option.label} onClick={option.cb}>
        {t(option.label)}
      </li>
    ))
  }, [t])

  return (
    <div className="dots-wrapper" onClick={toggleMenu} ref={ref}>
      <span className="menu-dot" />
      <span className="menu-dot" />
      <span className="menu-dot" />

      {isOpen && <ul className="menu-list">{menuOptions}</ul>}
    </div>
  )
}

MenuDots.propTypes = {
  optionsList: PropTypes.arrayOf(PropTypes.shape({
    cb: PropTypes.func,
    label: PropTypes.string,
  }))
}

export default MenuDots
