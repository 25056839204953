import React from 'react'
import { useParams } from 'react-router-dom'
import { prop } from 'lodash/fp'
import { OfferOccurrenceStatus } from 'shared'

import {
  getVideoMessageOccurrence,
  submitVideoMessageOccurrence,
  uploadFileToVideoMessageOccurrence,
} from './api'

import { useSetVideoMessageOrder } from './store'
import { useAddFilesToLibrary, useAddFileToLibrary } from 'store/hooks/globalState/useFileLibrary'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useAddFileToEntities } from 'store/hooks/globalState/useFileEntites'

export function useFetchVideoMessageOccurrence() {
  const { orderId: occurrenceId } = useParams()

  const setOrderDetails = useSetVideoMessageOrder((_, next) => next)
  const addFilesToLibrary = useAddFilesToLibrary()
  const handleResponseError = useHandleResponseError()
  const addFileToLibrary = useAddFileToLibrary()
  const addFileToEntities = useAddFileToEntities()

  return React.useCallback(() => {
    getVideoMessageOccurrence({ occurrenceId })
      .then(details => {
        setOrderDetails(details)

        const uploadedFile = prop('upload.file', details)
        if (uploadedFile) {
          addFileToEntities(
            uploadedFile.id,
            { ...uploadedFile, fileToken: uploadedFile.id },
          )
        }
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [
    occurrenceId,
    setOrderDetails,
    addFileToLibrary,
    addFileToEntities,
    addFilesToLibrary,
    handleResponseError,
  ])
}

export function useUploadVideo() {
  const { orderId: occurrenceId } = useParams()

  return React.useCallback(
    ({ fileToken }) => {
      uploadFileToVideoMessageOccurrence({ fileToken, occurrenceId })
        .then(() => {})
        .catch(console.warn)
    },
    [occurrenceId]
  )
}

export function useSubmitAnswer() {
  const { orderId: occurrenceId } = useParams()
  const fetchOccurrence = useFetchVideoMessageOccurrence()

  const updateVideoMessageOrder = useSetVideoMessageOrder(prev => ({
    ...prev,
    occurrence: {
      ...prev.occurrence,
      actions: [
        ...prev.occurrence.actions,
        {
          date: new Date(),
          type: OfferOccurrenceStatus.AWAITING_AFICIONADO_CONFIRM,
        },
      ],
      pendingAction: null,
    },
  }))

  return React.useCallback(() => {
    submitVideoMessageOccurrence({ occurrenceId })
      .then(() => fetchOccurrence())
      .catch(console.warn)
  }, [
    occurrenceId,
    fetchOccurrence,
    updateVideoMessageOrder,
  ])
}

export function useRejectOrder() {
  return React.useCallback(() => { }, [])
}
