// Modules
import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Assets
import Icon from 'assets/icons/Icon'


const ActiveChatInput = ({
  selectedChat: { chatInstance },
  isMobile,
}) => {
  const t = useGetTranslate()

  const minRows = 1;
  const maxRows = 4;
  const textareaLineHeight = 14 * 1.86;

  const [message, setMessage] = React.useState('')
  const [rows, setRows] = React.useState(1)

  const isValidMessage = /\S/.test(message)

  const handleSendMessage = message => {
    if (isValidMessage) {
      chatInstance.sendMessage(message.trim())
      setMessage('')
      setRows(minRows)
    }
  }

  const handleChange = event => {
    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setMessage(event.target.value)
    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  const handleKeyPress = event => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(message);
    }
  }

  const additionalStylesClass = cx({ 'active': isValidMessage, 'disabled': !isValidMessage });
  const mobileClassName = cx({ 'mobile-view': isMobile });

  return (
    <div className={`message-input__wrapper ${mobileClassName}`}>
      <textarea
        className="message-input__field"
        rows={rows}
        value={message}
        placeholder={t('chats.chat.input-placeholder')}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        autoFocus={true}
      />
      <Icon.Send
        className={`message-input__icon ${additionalStylesClass}`}
        onClick={() => handleSendMessage(message)}
      />
    </div>
  )
}

ActiveChatInput.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  selectedChat: PropTypes.objectOf(
    PropTypes.shape({
      chatInfo: PropTypes.object,
    })
  ),
}

export default ActiveChatInput
