import { useEffect, useState } from 'react'
import useVideoContext from './useVideoContext'

export const RoomStateTypes = {
  disconnected: 'disconnected',
  connected: 'connected',
  reconnecting: 'reconnecting',
}

export const useRoomState = () => {
  const { room } = useVideoContext()
  const [state, setState] = useState(RoomStateTypes.disconnected)

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state)
      setRoomState()

      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState)

      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState)
      }
    }
  }, [room])

  return state
}
