import React, { useEffect, useRef } from 'react'
import Message from '_legacy/components/Chats/ActiveChat/ActiveChatMessages/ActiveChatMessage/ActiveChatMessage'

export default function MessageList({ messages, chatInfo }) {
  const messagesEndRef = useRef(null)

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(scrollToBottom, [messages])

  return (
    <div className="messages-list-wrapper">
      {messages.map((message) => {
        return (
          <React.Fragment key={message.sid}>
            <Message message={message} chatInfo={chatInfo} />
          </React.Fragment>
        );
      })}

      <div ref={messagesEndRef} />
    </div>
  );
}
