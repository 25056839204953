import React from 'react'
import { find, propEq, cond, T, map, prop, values } from 'lodash/fp'

import { useRemoveMediaFromTable } from 'requests/my-media'
import { useSetModal } from 'store/hooks/globalState/useModal'
import { useMyMediaTable } from 'store/hooks/globalState/useMyMedia'
import { useGetTranslate, useGetTranslateWithKey } from 'store/hooks/globalState/useTranslates'

import { getOfferTranslateKey } from 'utils/offer'
import ModalWithControls from 'portals/ModalWithControls'

const getRemoveDescription = (offer, t) =>
  cond([
    [
      propEq('offers.length', 0),
      ({ originalName }) => ({
        tKey: 'my-library.remove-media-modal.description-without-offers',
        phs: [{ ph: '{{originalName}}', value: originalName }],
      }),
    ],
    [
      T,
      ({ originalName, offers }) => ({
        tKey: 'my-library.remove-media-modal.description-with-offers',
        phs: [
          { ph: '{{originalName}}', value: originalName },
          { ph: '{{offers}}', value: offers.map(prop('offerType')).map(getOfferTranslateKey).map(t).join(', ') },
        ],
      }),
    ],
  ])(offer)

const getBulkRemoveDescription = cond([
  [
    find(file => file.offers.length > 0),
    files => ({
      tKey: 'my-library.remove-media-modal.multiple-remove-description-with-offers',
      phs: [{ ph: '{{count}}', value: files.length }],
    }),
  ],
  [
    T,
    files => ({
      tKey: 'my-library.remove-media-modal.multiple-remove-description-without-offers',
      phs: [{ ph: '{{count}}', value: files.length }],
    }),
  ],
])

const RemoveMediaModal = React.memo(fileIds => {
  const t = useGetTranslate()
  const tKey = useGetTranslateWithKey()
  const removeMediaFromTable = useRemoveMediaFromTable()
  const fileIdsArray = values(fileIds)

  const files = useMyMediaTable(state => map(id => find(media => media.id === id, state), fileIdsArray))
  const transOptions = files.length > 1 ? getBulkRemoveDescription(files) : getRemoveDescription(files[0], t)
  const closeModal = useSetModal(() => null)

  const onDone = () => {
    removeMediaFromTable(fileIdsArray)
    closeModal()
  }

  return (
    <ModalWithControls
      isOpen
      onClose={closeModal}
      header={t('my-library.remove-media-modal.header')}
      message={tKey(transOptions)}
      buttonsConfig={[
        { primary: true, text: t('shared.words.cancel'), handleOnClick: closeModal },
        { text: t('shared.words.remove'), handleOnClick: onDone },
      ]}
    />
  )
})

export default RemoveMediaModal
