import includes from 'lodash/fp/includes'
import __ from 'lodash/fp/__'
import where from 'lodash/fp/where'
import anyPass from 'lodash/fp/anyPass'
import allPass from 'lodash/fp/allPass'

import { TalentOffersType, TalentOfferStatus } from 'shared'

const {
  VIRTUAL_EVENT,
  VIDEO_CHAT,
  VIRTUAL_LESSON,
  VIDEO_MESSAGE,
  VIDEO_VOICE_OVER_LESSON,
  READY_MADE_LESSON,
  FOR_COMMERCIAL_USE,
  IN_PERSON_EXPERIENCE,
  LIVE_IN_PERSON_LESSON,
} = TalentOffersType

const {
  PUBLISHED,
  UNPUBLISHED,
  DRAFT
} = TalentOfferStatus

// type utils
// group by location
export const isOnlineOfferType = includes(__, [VIRTUAL_EVENT, VIDEO_CHAT, VIRTUAL_LESSON])

export const isNALocationOfferType = includes(__, [VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, READY_MADE_LESSON, FOR_COMMERCIAL_USE])

export const isChosenLocationOfferType = includes(__, [IN_PERSON_EXPERIENCE, LIVE_IN_PERSON_LESSON])

// group by time
export const isNATimeOfferType = includes(__, [VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, READY_MADE_LESSON, FOR_COMMERCIAL_USE])

export const isChosenTimeOfferType = includes(__, [VIRTUAL_EVENT, VIDEO_CHAT, LIVE_IN_PERSON_LESSON, VIRTUAL_LESSON, IN_PERSON_EXPERIENCE])

// group by enabling
export const isPublishedOfferType = includes(__, [VIRTUAL_EVENT, READY_MADE_LESSON, IN_PERSON_EXPERIENCE])

export const isNotPublishedOfferType = includes(__, [VIDEO_MESSAGE, VIDEO_CHAT, FOR_COMMERCIAL_USE, VIDEO_VOICE_OVER_LESSON, LIVE_IN_PERSON_LESSON, VIRTUAL_LESSON])

// status utils
export const isPublishedOfferStatus = includes(__, [PUBLISHED])

export const isUnpublishedOfferStatus = includes(__, [UNPUBLISHED])

export const isDraftOfferStatus = includes(__, [DRAFT])

// offer utils
export const isOnlineOffer = anyPass([
  where({
    type: isOnlineOfferType
  }),
  where({
    offerType: isOnlineOfferType
  })
])

export const isNALocationOffer = anyPass([
  where({
    type: isNALocationOfferType
  }),
  where({
    offerType: isNALocationOfferType
  })
])

export const isChosenLocationOffer = anyPass([
  where({
    type: isChosenLocationOfferType
  }),
  where({
    offerType: isChosenLocationOfferType
  })
])


export const isPublishableOffer = where({
  type: isPublishedOfferType
})

export const isEnablableOffer = where({
  type: isNotPublishedOfferType
})

export const isPublishedOffer = where({
  status: isPublishedOfferStatus
})

export const isNotPublishedOffer = anyPass([
  where({
    status: isUnpublishedOfferStatus
  }),
  where({
    status: isDraftOfferStatus
  }),
])

export const isPublishablePublishedOffer = allPass([
  isPublishableOffer,
  isPublishedOffer,
])

export const isPublishableNotPublishedOffer = allPass([
  isPublishableOffer,
  isNotPublishedOffer,
])

export const isEnablablePublishedOffer = allPass([
  isEnablableOffer,
  isPublishedOffer,
])

export const isEnablableNotPublishedOffer = allPass([
  isEnablableOffer,
  isNotPublishedOffer,
])

export const getOfferLabel = (offerType) => {
  const offerLabelConfig = {
    [TalentOffersType.READY_MADE_LESSON]: 'Ready Made Video',
    [TalentOffersType.VIDEO_CHAT]: 'Live Video Chat',
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'Video Voice Over Lesson',
    [TalentOffersType.VIRTUAL_EVENT]: 'Virtual Event',
    [TalentOffersType.VIRTUAL_LESSON]: 'Virtual Lesson',
    [TalentOffersType.FOR_COMMERCIAL_USE]: 'For Commercial Use',
    [TalentOffersType.VIDEO_MESSAGE]: 'Video Message',
    [TalentOffersType.IN_PERSON_EXPERIENCE]: 'In Person Experience',
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'Live In Person Lesson',
  }

  return offerLabelConfig[offerType] || ''
}

export const getOfferTranslateKey = offerType => {
  const offerLabelConfig = {
    [TalentOffersType.READY_MADE_LESSON]: 'shared.offers.ready-made-lessons',
    [TalentOffersType.VIDEO_CHAT]: 'shared.offers.video-chat',
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'shared.offers.video-voice-over-lesson',
    [TalentOffersType.VIRTUAL_EVENT]: 'shared.offers.virtual-event',
    [TalentOffersType.VIRTUAL_LESSON]: 'shared.offers.virtual-lesson',
    [TalentOffersType.FOR_COMMERCIAL_USE]: 'shared.offers.for-commercial-use',
    [TalentOffersType.VIDEO_MESSAGE]: 'shared.offers.video-message',
    [TalentOffersType.IN_PERSON_EXPERIENCE]: 'shared.offers.in-person-experience',
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'shared.offers.live-in-person-lesson',
  }

  return offerLabelConfig[offerType] || ''
}

export const getMergedOfferTranslateKey = offerType => {
  const offerLabelConfig = {
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'shared.offers.lessons',
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'shared.offers.lessons',
    [TalentOffersType.READY_MADE_LESSON]: 'shared.offers.lessons',
    [TalentOffersType.VIDEO_CHAT]: 'shared.offers.video-chat',
    [TalentOffersType.VIDEO_MESSAGE]: 'shared.offers.video-message',
    [TalentOffersType.IN_PERSON_EXPERIENCE]: 'shared.offers.experience',
    [TalentOffersType.VIRTUAL_EVENT]: 'shared.offers.live-virtual-event',
    [TalentOffersType.READY_MADE_LESSON]: 'shared.offers.ready-made-lessons',
  }

  return offerLabelConfig[offerType] || ''
}
