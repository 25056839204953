import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Loader from './Loader'

export default React.memo(function Status ({ isLoading, status, config, className }) {
  const t = useGetTranslate()
  if (isLoading) return <Loader />
  if (!config) {
    console.warn(`Status: ${status} not implemented`)
    return null
  }
  const { Icon, label, color } = config
  return (
    <StyledWrapper color={color} className={className}>
      {Icon && <Icon className='icon' />}
      <div className='text'>
        {t(label)}
      </div>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
  }
  .icon {
    width: 15px;
    flex-shrink: 0;
    @media screen and (max-width: 768px) {
      width: 18px;
      height: 18px;
    }
  }
  .text {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 500;
    color: ${({ theme: { colors }, color }) => colors[color]};
    @media screen and (max-width: 768px) {
      font-weight: 700;
      line-height: 26px;
    }
  }
`
