import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

const PrimaryLabel = styled.p`
  position: absolute;
  top: 0;
  left: 50%;

  margin-right: -50%;
  transform: translate(-50%, -50%);

  padding: 4px;

  transition: all .3s linear;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #fff;

  background: ${({ theme }) => theme.colors.blue};
  border: 2px solid #fff;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  
  ${({ isPrimary }) => isPrimary
  ? css`
      z-index: 1;
      transform: translate(-50%, -50%);
      opacity: 1;`
  : css`
      z-index: 0;
      transform: translate(-50%, 0%);
      opacity: 0;`
  }

  @media screen and (max-width: 600px) {
    font-size: 8px;
  }
`;

PrimaryLabel.propTypes = {
  isPrimary: PropTypes.bool,
}

export default PrimaryLabel

