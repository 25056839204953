import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormContext, useWatch } from 'react-hook-form';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const CharactersTip = React.memo(function CharactersTip({ name, maxLength }) {
  const t = useGetTranslate();
  const { control } = useFormContext();
  const field = useWatch({ control, name, defaultValue: '' });

  const tip = field.length === 1
    ? 'shared.words.character.singular'
    : 'shared.words.character.plural';

  return (
    <StyledCharactersTip>
      {field.length} / {maxLength} {t(tip)}
    </StyledCharactersTip>
  );
});

const StyledCharactersTip = styled.p`
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 22px;
  color: #272835;
  
  margin-left: auto;
`;

CharactersTip.propTypes = {
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number.isRequired,
};

export default CharactersTip;
