import React from 'react';
import { useHistory } from 'react-router';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { types } from 'shared';

// components
import { Row } from '_legacy/common';
import RowInput from '_legacy/common/Input/RowInput';
import TextAreaField from '_legacy/common/Textarea/TextAreaField';
import Button from '_legacy/components/Button/Button';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';

import { appApi } from 'api';
import { useFetch } from 'store/hooks/useFetch';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { emailPattern } from 'utils/services/validators/validators';
import { notify } from 'utils/notify';
import DescriptionCareers from './DescriptionCareers';
import Uploader from '../../common/UploaderFiles/Uploader';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

const { FILE, PHOTO } = types.file.Types

const Careers = () => {
  const t = useGetTranslate();
  const history = useHistory();
  const [{ isLoading }, doFetch] = useFetch(appApi.oneDirectionRequests.careers);
  const methods = useForm({ mode: 'onChange' });

  const onSubmit = async data => {
    const files = map(get(data, 'files'), 'fileToken')
    await doFetch({ ...data, files });
    notify(t('careers.form.message.success'), 'success');
    history.push('/');
  };

  return (
    <div className="careers wrapper content-wrapper">
      <h5 className="careers__heading">{t('careers.header.label')}</h5>
      <DescriptionCareers />
      <div className="careers-form">
        <p className="careers-form__title">{t('careers.subheader.label')}</p>
        <FormProvider {...methods}>
          <form
            className="careers-form careers-form__wr white-background default"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <RequiredLabel />
            <RowInput
              name="firstName"
              label={t('careers.form.first-name.label')}
              placeholder={t('careers.form.first-name.placeholder')}
              requiredMessage='careers.form.first-name.errors.required'
              isRequired
            />
            <RowInput
              name="lastName"
              label={t('careers.form.last-name.label')}
              placeholder={t('careers.form.last-name.placeholder')}
              requiredMessage='careers.form.last-name.errors.required'
              isRequired
            />
            <RowInput
              name="company"
              label={t('careers.form.company.label')}
              placeholder={t('careers.form.company.placeholder')}
            />
            <RowInput
              name="email"
              label={t('careers.form.email.label')}
              placeholder={t('careers.form.email.placeholder')}
              validation={{ pattern: {
                value: emailPattern,
                message: 'careers.form.email.errors.invalid-email',
              } }}
              requiredMessage='careers.form.email.errors.required'
              isRequired
            />
            <TextAreaField
              name='message'
              maxLength={500}
              label='careers.form.message.label'
              placeholder='careers.form.message.placeholder'
              requiredMessage='careers.form.message.errors.required'
              isRequired
            />
            <Row
              nameBlock={t('careers.form.media.label')}
              component={
                <Uploader
                  name='files'
                  titleHint={t('register.upload.hint.file.title')}
                  typesFiles={[FILE, PHOTO]}
                />
              }
            />
            <Row
              component={
                <Button
                  text={t('careers.form.button.submit')}
                  typeButton="submit"
                  disabled={isLoading}
                  paddingVertical="13"
                  paddingHorizontal="55"
                />
              }
            />
            <MissedData />
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

Careers.propTypes = {
  isPreview: PropTypes.bool,
};

export default Careers;
