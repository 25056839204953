import React from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import get from 'lodash/get'
import prop from 'lodash/fp/prop'
import propEq from 'lodash/fp/propEq'
import always from 'lodash/fp/always'
import cond from 'lodash/fp/cond'
import T from 'lodash/fp/T'


const StyledAbbr = styled.span`
  color: ${prop(['theme', 'color', 'grey'])};
`

const Abbr = props => (
  <StyledAbbr>&nbsp;({props.children})</StyledAbbr>
)

export const OptionWithZoneAbbr = props => {
  const abbr = get(props, 'selectProps.abbr')
  return (
    <StyledOption {...props}>
      <span>{props.children}</span>
      {abbr && <Abbr>{abbr}</Abbr>}
    </StyledOption>
  )
}

export const DropdownIndicator = styled(components.DropdownIndicator)`
  padding-left: 0px !important;
  padding-right: 0px !important;
`

export const SingleValue = (props) => {
  const abbr = get(props, 'selectProps.abbr')
  return (
    <components.SingleValue {...props} key={abbr}>
      {props.children}
      {abbr && <Abbr>{abbr}</Abbr>}
    </components.SingleValue>
  )
}

const getColor = cond([
  [propEq('isSelected', true), always('#e9eaf0')],
  [propEq('isFocused', true), always('#e9eaf0')],
  [T, always('#fff')]
])

const getFontWeight = cond([
  [propEq('isSelected', true), always(700)],
  [T, always(500)]
])


export const StyledOption = styled(components.Option)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  opacity: ${props => (get(props, 'data.disabled') ? '0.5' : '1')};
  cursor: ${props => (get(props, 'data.disabled') ? 'not-allowed' : 'pointer')} !important;
  color: #626473 !important;
  font-family: Montserrat;
  font-weight: ${getFontWeight};
  line-height: 1.5;
  padding: 12px 7px !important;
  transition: all .3s ease-in;

  background-color: ${getColor} !important;
  
  &:focus,
  &:hover {
    background: var(--theme-color--ultraLightBlue);
  }
`
