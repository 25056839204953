// Modules
import React from 'react'

// API
import {
  fetchGooglePlaceByCoordinates,
  fetchCountsByLocationAggregated,
  fetchCountsByLocationTop,
} from './api'


export function useFetchGooglePlaceByCoordinates(
  setIsLoading,
  onSuccess,
  onError = console.warn
) {
  return React.useCallback(({ longitude, latitude }) => {
    setIsLoading(true)
    fetchGooglePlaceByCoordinates({ longitude, latitude })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
}

export function useFetchCountsByLocationAggregated(
  setIsLoading,
  onSuccess,
  onError = console.warn
) {
  return React.useCallback(query => {
    setIsLoading(true)
    fetchCountsByLocationAggregated(query)
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
}

export function useFetchCountsByLocationTop(
  setIsLoading,
  onSuccess,
  onError = console.warn
) {
  return React.useCallback(query => {
    setIsLoading(true)
    fetchCountsByLocationTop(query)
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [])
}
