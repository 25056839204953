import React from 'react'
import get from 'lodash/get'
import { ReminderType } from 'shared'

import EventReminderNotification from './EventReminderNotification'
import UploadVideoDueReminderNotification from './UploadVideoDueReminderNotification'
import UploadVideoOverdueReminderNotification from './UploadVideoOverdueReminderNotification'
import AcceptBookingRequestReminderNotification from './AcceptBookingRequestReminderNotification'
import EmptyNotification from '../EmptyNotification'

const remindersNotificationConfig = {
  [ReminderType.COMING_SOON_EVENT]: EventReminderNotification,
  [ReminderType.UPLOAD_VIDEO_DUE]: UploadVideoDueReminderNotification,
  [ReminderType.UPLOAD_VIDEO_OVERDUE]: UploadVideoOverdueReminderNotification,
  [ReminderType.PENDING_BOOKING_REQUEST]: AcceptBookingRequestReminderNotification,
}

const ReminderNotification = props => {
  const subType = get(props, 'options.reminderType')
  const Component = remindersNotificationConfig[subType] || EmptyNotification
  return <Component {...props} />
}

export default ReminderNotification
