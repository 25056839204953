import { _get, _post } from 'api/utils'

export const refreshAccountLink = () => _post('/integrations/stripe/account-links/refresh',
  { needAuthorization: true })

export const getExpressDashboardLoginLink = () => _get('/integrations/stripe/account-links/login-link',
  { needAuthorization: true })

export const createConnectedAccount = () => _post('/integrations/stripe/connected-accounts/create',
  { needAuthorization: true })

export const disconnectConnectedAccount = () => _post('/integrations/stripe/connected-accounts/disconnect',
  { needAuthorization: true })
