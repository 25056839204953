import React from 'react';
import PropTypes from 'prop-types';

const BaseSlide = ({
  children,
  withGradientOverhead = true,
  slideProps = {},
  contentProps = {},
  overlay,
  gradient
}) => {
  return (
    <div
      className="tbv-slider-slide"
      {...slideProps}
    >
      <div className="tbv-slider-slide-content" {...contentProps}>
        <div className="tbv-slider-slide-content-border">
          {children}
          {withGradientOverhead && (
            <div
              style={{ backgroundImage: gradient }}
              className="tbv-slider-slide-content-gradient-overhead"
            />
          )}
        </div>
        {overlay}
      </div>
    </div>
  );
};

BaseSlide.propTypes = {
  slideProps: PropTypes.object,
  contentProps: PropTypes.object,
  overlay: PropTypes.object,

  withGradientOverhead: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

export default BaseSlide;
