// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { getZonedTimeAbbr } from 'utils/services/dates/getZonedTime'
import { getUserTimeZone } from 'utils/user'
import { getDateRangeOptions } from './utils'

// Legacy components
import Select from '_legacy/components/Form/Selects/SelectComponent'
import {
  SingleValue,
  OptionWithZoneAbbr,
  DropdownIndicator,
} from './common'

// Constants
import { KEY } from '_legacy/components/CreateExperience/components/TimeZoneHandler'


const TimeSelectWithTimeZone = ({
  name,
  placeholder,
  minTime,
  maxTime,
  ...props
}) => {
  const { watch } = useFormContext()

  const userTimeZone = useUser(getUserTimeZone)
  const timeZone = watch(KEY) || userTimeZone
  const abbr = timeZone && getZonedTimeAbbr(new Date, timeZone)

  const options = getDateRangeOptions({ minTime, maxTime })

  return (
    <Select
      name={name}
      placeholder={placeholder || '00:00'}
      options={options}
      components={{
        Option: OptionWithZoneAbbr,
        SingleValue,
        DropdownIndicator,
      }}
      abbr={abbr}
      {...props}
    />
  )
}

TimeSelectWithTimeZone.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  minTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  maxTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  rules: PropTypes.object,
}

export default TimeSelectWithTimeZone
