import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const NotFound = () => {
  const t = useGetTranslate()
  return (
    <div className="not-found-container">
      <h1 className="not-found-header">404</h1>
      <p className="not-found-content">{t('not-found.content')}</p>
    </div>
  );
};

NotFound.propTypes = {
  setHeader: PropTypes.func,
  setBackground: PropTypes.func,
};

export default NotFound
