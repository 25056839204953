import React from 'react';
import get from 'lodash/get';
import PublicSlider from '_legacy/components/PublicSlider/'

const CommercialSlider = ({ currentCommercialVideoMessage }) => {
  const media = get(currentCommercialVideoMessage[0], 'media', []);

  return <PublicSlider covers={media} />
};


export default CommercialSlider;
