import React from 'react'
import debounce from 'lodash/debounce'
import prop from 'lodash/fp/prop'
import { useHistory, Link } from 'react-router-dom'
import * as r from '_legacy/constants/routes'

// Assets
import Icon from 'assets/icons/Icon'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'

// Requests
import { useFetchSearchResult, useFetchUserRecentSearches } from './state/requests'

// Store (Hooks)
import { useSearchResults, useSetSearchResults, useRecentSearchList } from './state/store'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser'
import { useSetSearchOffersFilters, useSetSearchListFilters } from 'store/hooks/globalState/useSearchList'

// Components
import KeywordSearchAggregationList from './component/KeywordSearchAggregationList'
import KeywordSearchListLink from './component/KeywordSearchListLink'
import KeywordSearchListItem from './component/KeywordSearchListItem'
import { routesList } from 'router/routesMap'

import {
  buildSearchOfferTitle,
  buildSearchTalentTitle,
  buildSearchOfferLink,
  buildSearchTalentLink,
  transformLabels,
  transformOfferings,
  getCoordinates,
} from './utils'

const KeywordSearch = () => {
  const t = useGetTranslate()
  const history = useHistory()
  const isLoggedIn = useIsUserLoggedIn()

  const fetchSearchResults = useFetchSearchResult()
  const clearSearch = useSetSearchResults(() => null)
  const fetchRecentSearches = useFetchUserRecentSearches()

  const offerings = useSearchResults(prop('talentOffers'))
  const offeringsCategory = useSearchResults(prop('talentOffers.aggregations.categories'))
  const offeringsLanguage = useSearchResults(prop('talentOffers.aggregations.language'))
  const offeringsLocation = useSearchResults(prop('talentOffers.aggregations.locations'))
  const offeringsType = useSearchResults(prop('talentOffers.aggregations.offeringTypes'))

  const talents = useSearchResults(prop('talents'))
  const talentsCategory = useSearchResults(prop('talents.aggregations.primaryCategory'))
  const talentsLocation = useSearchResults(prop('talents.aggregations.locations'))
  const talentsLanguage = useSearchResults(prop('talents.aggregations.language'))
  const recentSearches = useRecentSearchList()

  const setTalentSearchFilters = useSetSearchListFilters((p, n) => ({ ...p, ...n }))

  const setOfferSearchFilters = useSetSearchOffersFilters((p, n) => ({ ...p, ...n }))

  const [localSearch, setLocalSearch] = React.useState('')
  const debounced = React.useCallback(debounce((search) => {
    fetchSearchResults({ search }, isLoggedIn)
  }, 500), [])

  const onClear = React.useCallback(() => {
    clearSearch()
    setLocalSearch('')
  }, [])

  React.useEffect(() => {
    debounced(localSearch)
  }, [localSearch])

  React.useEffect(() => {
    fetchRecentSearches()
    return () => {
      clearSearch()
    }
  }, [])

  const handleTalentCategory = React.useCallback((searchItem) => {
    setTalentSearchFilters({ search: localSearch, categoryIn: [{ i: searchItem.id, n: searchItem.key }] })
    history.push(`/${r.SEARCH}/${r.TALENT}`)
  }, [localSearch])

  const handleTalentLocation = React.useCallback(searchItem => {
    const coordinates = getCoordinates(searchItem.source)
    setTalentSearchFilters({ search: localSearch, location: { label: searchItem.key, value: coordinates } })
    history.push(`/${r.SEARCH}/${r.TALENT}`)
  }, [localSearch])

  const handleTalentLanguages = React.useCallback((searchItem) => {
    setTalentSearchFilters({ search: localSearch, languagesIn: searchItem.key })
    history.push(`/${r.SEARCH}/${r.TALENT}`)
  }, [localSearch])

  const handleOfferingTypesIs = React.useCallback(searchItem => {
    setOfferSearchFilters({ search: localSearch, offeringTypesIn: searchItem.key })
    history.push(`/${r.SEARCH}/${r.OFFERINGS}`)
  }, [localSearch])

  const handleOfferingLocation = React.useCallback(searchItem => {
    const coordinates = getCoordinates(searchItem.source)
    setOfferSearchFilters({ search: localSearch, location: { label: searchItem.key, value: coordinates } })
    history.push(`/${r.SEARCH}/${r.OFFERINGS}`)
  }, [localSearch])

  const handleOfferingCategoryIn = React.useCallback(searchItem => {
    setOfferSearchFilters({ search: localSearch, categoryIn: [{ i: searchItem.id, n: searchItem.key }] })
    history.push(`/${r.SEARCH}/${r.OFFERINGS}`)
  }, [localSearch])

  const handleOfferingLanguagesIn = React.useCallback(searchItem => {
    setOfferSearchFilters({ search: localSearch, languagesIn: searchItem.key })
    history.push(`/${r.SEARCH}/${r.OFFERINGS}`)
  }, [localSearch])

  const hasResults = talents || offerings
  const showNotFound = talents && talents.total === 0 && offerings && offerings.total === 0 && !!localSearch

  return (
    <div className="global-wrapper keyword-search">
      <div className="keyword-search__wrapper wrapper content-wrapper">
        <div className="keyword-search__input-wrapper">
          <Icon.BackIcon className="keyword-search__svg-button" size={24} onClick={history.goBack} />
          <input
            type="text"
            className="keyword-search__input"
            placeholder={t('search.keywords-search.placeholder')}
            value={localSearch}
            onChange={e => setLocalSearch(e.target.value)}
          />
          {localSearch && <Icon.Close className="keyword-search__svg-button" size={24} onClick={onClear} />}
        </div>
      </div>
      {talents && !showNotFound && (
        <div className="keyword-search__row wrapper content-wrapper">
          <div className="keyword-search__list">
            <Link className="keyword-search__categories keyword-search__categories--link" to={routesList.searchTalent()}>
              {`${t('search.keywords-search.talent.title')} (${talents.total})`}
            </Link>
            {talents?.list?.map((item, i) =>(
              <KeywordSearchListLink
                key={i}
                icon={<SearchIcon />}
                itemHtml={buildSearchTalentTitle(item)}
                link={buildSearchTalentLink(item)}
              />
            ))}
          </div>
          <div className='keyword-search__aggregation-row'>
            {talentsCategory &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.talent.category')}
                list={transformLabels(talentsCategory)}
                handleOnClick={handleTalentCategory}
              />
            }
            {talentsLocation &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.talent.location')}
                list={transformLabels(talentsLocation)}
                handleOnClick={handleTalentLocation}
              />
            }
            {talentsLanguage &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.talent.language')}
                list={transformLabels(talentsLanguage)}
                handleOnClick={handleTalentLanguages}
              />
            }
          </div>
        </div>
      )}
      {offerings && !showNotFound && (
        <div className="keyword-search__row wrapper content-wrapper">
          <div className="keyword-search__list">
            <Link className="keyword-search__categories keyword-search__categories--link" to={routesList.searchOfferings()}>
              {`${t('search.keywords-search.offerings.title')} (${offerings.total})`}
            </Link>
            {offerings?.list?.map((item, i) => (
              <KeywordSearchListLink
                key={i}
                icon={<SearchIcon />}
                itemHtml={buildSearchOfferTitle(item)}
                link={buildSearchOfferLink(item)}
              />
            ))}
          </div>
          <div className='keyword-search__aggregation-row'>
            {offeringsType &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.offerings.type')}
                list={transformOfferings(offeringsType)}
                handleOnClick={handleOfferingTypesIs}
              />
            }
            {offeringsCategory &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.offerings.category')}
                list={transformLabels(offeringsCategory)}
                handleOnClick={handleOfferingCategoryIn}
              />
            }
            {offeringsLocation &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.offerings.locations')}
                list={transformLabels(offeringsLocation)}
                handleOnClick={handleOfferingLocation}
              />
            }
            {offeringsLanguage &&
              <KeywordSearchAggregationList
                title={t('search.keywords-search.offerings.language')}
                list={transformLabels(offeringsLanguage)}
                handleOnClick={handleOfferingLanguagesIn}
              />
            }
          </div>
        </div>
      )}
      {recentSearches && !hasResults && (
        <div className="keyword-search__row wrapper content-wrapper">
          <div className="keyword-search__list">
            <h5 className='keyword-search__categories'>{t('search.keywords-search.recent.title')}</h5>
            {recentSearches?.map((item, i) => (
              <KeywordSearchListItem
                key={i}
                item={item}
                onClick={() => setLocalSearch(item)}
                icon={<Icon.SearchRecent height={32} width={32} />}
              />
            ))}
          </div>
        </div>
      )}

      {showNotFound && (
        <div className="keyword-search__no-result wrapper content-wrapper">
          <Icon.SadIcon className="keyword-search__no-result-icon" />
          <p className="keyword-search__no-result-text">
            <Trans tKey="search.keywords-search.no-result.title" phs={[{ ph: '{{search}}', value: localSearch }]} />
          </p>
        </div>
      )}
    </div>
  )
}

export default KeywordSearch
