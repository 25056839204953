import React from 'react';
import PropTypes from 'prop-types'

const OfferingDetailsWaiting = ({
  waitingTitle,
  waitingText,
  timeLeft,
  children,
}) => {
  return (
    <div className="od--with-padding od__column od--centered">
      <p className="od--uppercase-title mb-12">{waitingTitle}</p>
      {waitingText && <p className="od--main-text mb-12">{waitingText}</p>}
      {timeLeft && <h3 className="od--status-title mb-32">{timeLeft}</h3>}
      {children}
    </div>
  )
}

OfferingDetailsWaiting.propTypes = {
  typeHeader: PropTypes.string,
  title: PropTypes.string,
}

export default OfferingDetailsWaiting;
