import React from 'react'
import { useParams } from 'react-router-dom'
import { BulkNotificationAction } from 'shared'

import { useAcceptEmailNotification } from 'requests/notifications'

import PageLoader from '_legacy/common/Template/PageLoader'

const NotificationsHandler = () => {
  const { notificationId, actionType } = useParams()
  const acceptEmailNotification = useAcceptEmailNotification()

  if (BulkNotificationAction.ACCEPT === actionType) {
    acceptEmailNotification(notificationId)
  }

  return <PageLoader />
}

export default NotificationsHandler
