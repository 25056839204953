import React from 'react';
import PropTypes from 'prop-types';
import BasicActions from '../../../BasicActions';
import BookNowButton from '../../../components/BookNowButton';
import { LESSONS } from '../../../../../../constants/routes';

const Actions = ({ slidesCount, onNext, onPrev, plaqueNickname, isPromo }) => {
  // const onRequestClick = () => {
  //   alert('This action isn\'t implemented');
  // };

  return (
    <BasicActions
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={45}
      padding="0 0 0 11px"
      // onRequestClick={onRequestClick}
    >
      <BookNowButton margin="0" isLink path={`/${LESSONS}/${plaqueNickname}`} disabled={isPromo} />
    </BasicActions>
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,

  slidesCount: PropTypes.number.isRequired,
  plaqueNickname: PropTypes.string,
};

export default Actions;
