export const MEDIA = 'media'
export const USED_IN = 'usedIn'
export const PROMOTIONAL = 'promotional'
export const UPLOAD_DATE = 'uploadDate'

export const elementsTypes = {
  [MEDIA]: MEDIA,
  [USED_IN]: USED_IN,
  [UPLOAD_DATE]: UPLOAD_DATE,
  [PROMOTIONAL]: PROMOTIONAL,
}

import Media from './Media'
import UsedIn from './UsedIn'
import UploadDate from './UploadDate'
import Promotional from './Promotional'

export const MY_MEDIA = 'my-media'

export const myMediaComponents = {
  [MEDIA]: Media,
  [USED_IN]: UsedIn,
  [UPLOAD_DATE]: UploadDate,
  [PROMOTIONAL]: Promotional,
}
