import React from 'react'
import get from 'lodash/get'
import { useHistory } from 'react-router-dom'

import Button from '_legacy/components/Button'
import CustomSelect from '_legacy/common/CustomSelect'
import NavigationHeader from '_legacy/common/mobile-navigation/NavigationHeader'

import { langOptions } from 'utils/language/language'
import { cookies } from 'utils/services/cookies'
import { getCurrencies } from 'utils/currency/currency'

import { useGetMe } from 'requests/auth'
import { updateUserSettings } from 'api/appApi/auth'
import { useUser } from 'store/hooks/globalState/useUser'
import { useCurrency, useCurrencyCode, useSetCurrency } from 'store/hooks/globalState/useCurrency'
import { useGetTranslate, useSetUserLang, useUserLang } from 'store/hooks/globalState/useTranslates'


const CurrencyAndLanguage = () => {
  const t = useGetTranslate()
  const history = useHistory()
  const getMe = useGetMe()
  const isLoggedIn = useUser(Boolean)

  // lang
  const userLang = useUserLang()
  const [localLang, setLocalLang] = React.useState(userLang)
  const setUserLang = useSetUserLang((prev, next) => next, [])
  const handleLocalLang = React.useCallback(value => setLocalLang(get(value, 'value')), [setLocalLang])

  // currency
  const currency = useCurrencyCode()
  const currencyOptions = useCurrency(getCurrencies)
  const [localCurrency, setLocalCurrency] = React.useState(currency)
  const setCurrency = useSetCurrency((prev, next) => ({ ...prev, currentCurrencyCode: next }), [])
  const handleLocalCurrency = React.useCallback(value => setLocalCurrency(get(value, 'value')), [setLocalCurrency])

  const onSubmit = React.useCallback(() => {
    setUserLang(localLang)
    setCurrency(localCurrency)

    if (isLoggedIn) {
      updateUserSettings({ data: { language: localLang, currency: localCurrency } })
        .then(() => getMe())
        .catch(error => console.warn(error))
    }

    const preferences = cookies.preferences
    if (preferences) {
      cookies.language = localLang
      cookies.currency = localCurrency
    }

    history.goBack()
  }, [setUserLang, setCurrency, localLang, getMe, localCurrency])

  return (
    <div className="currency-and-language">
      <NavigationHeader text="settings.language-and-currency" />
      <div className="currency-and-language__inner">
        <CustomSelect
          label="main-menu.language"
          options={langOptions}
          value={localLang}
          changeHandler={handleLocalLang}
        />
        <CustomSelect
          className="currency"
          label={t('main-menu.currency')}
          options={currencyOptions}
          value={localCurrency}
          changeHandler={handleLocalCurrency}
        />
        <Button
          stretch
          paddingVertical={16}
          handleOnClick={onSubmit}
          text={t('main-menu.save')}
          className="currency-and-language__btn"
        />
      </div>
    </div>
  )
}

export default CurrencyAndLanguage
