import React from 'react'
import { getLocationSubtitle, getLocationTitle } from 'utils/location'

const LocationTooltip = ({ locations }) => (
  <ul className="location-list">
    {locations.map((location, i) => {
      const locationTitle = getLocationTitle(location)
      const locationSubtitle = getLocationSubtitle(location)

      return (
        <li key={i} className="location-list__item">
          <strong>{locationTitle}</strong>
          {locationSubtitle}
        </li>
      )
    })}
  </ul>
)

export default React.memo(LocationTooltip)
