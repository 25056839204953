import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classnames from 'classnames'
import transparent from 'assets/transparent.png'

import { isIos } from 'utils/isBrowser'

import Poster from '../Poster'

import './CustomVideoPlayer.scss'

export const SlideCustomVideoPlayer = React.memo(function SlideCustomVideoPlayer({
  className = '',
  file,
  toggelPause,
}) {
  const ref = React.useRef()

  React.useEffect(() => {
    if (!ref.current.volume) ref.current.volume = 1
    if (ref.current.volume) ref.current.volume = 0
    // if (isIos) ref.current.muted = false
  }, [ref])

  if (!file) return null

  const sources =
    get(file, 'file.preview.sources') || get(file, 'preview.sources')

  const classNames = classnames(
    'custom-video-player',
    className,
  )

  return (
    <div className={classNames}>
      <Poster sources={sources} />
      <video
        ref={ref}
        preload="none"
        className='slide-elements'
        poster={transparent}
        src={get(file, 'file.fullUrl') || get(file, 'fullUrl')}
        onEnded={() => {toggelPause(true);}}
        tabIndex="-1"
        // muted={isIos()}
        webkitplaysinline={isIos()}
        playsInline={isIos()}
        x-webkit-airplay={isIos() || 'allow'}
        controlsList={isIos() || 'nodownload'}
        // controls={isIos()}
        onPlay={() => toggelPause(false)}
      />
    </div>
  )
})

SlideCustomVideoPlayer.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  isStoped: PropTypes.bool,
  isVolumed: PropTypes.bool,
}
