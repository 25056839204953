import React from 'react';
import { useFormContext } from 'react-hook-form';

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetchLiveVirtualEventsTemplateById } from 'requests/live-virtual-events';

import { getAsyncTemplates } from 'utils/services/loaders/live-virtual-events';

import { Row } from '_legacy/common';
import AsyncSelect from '_legacy/components/Form/Selects/basic/AsyncSelectComponent';


const PreFill = React.memo(({ name = 'prefill' }) => {
  const t = useGetTranslate();
  const methods = useFormContext()
  const talentId = useTalentId()
  const prefill = methods.watch(name)

  const fetchLiveVirtualEventsTemplateById = useFetchLiveVirtualEventsTemplateById(methods)

  const _getAsyncTemplates = async (...props) => {
    return await getAsyncTemplates({ talentId, ...props, })
  }

  React.useEffect(() => {
    if (prefill?.value) {
      fetchLiveVirtualEventsTemplateById({
        liveVirtualEventTemplateId: prefill.data.id,
        isDraft: prefill.data.isDraft
      })
    }
  }, [prefill?.value])

  return (
    <Row
      nameBlock={t('live-virtual-events.form.pre-fill.label')}
      component={
        <AsyncSelect
          loadOptionsOnMenuOpen={false}
          placeholder={t('live-virtual-events.form.pre-fill.placeholder')}
          isClearable={false}
          name={name}
          isShowArrow={true}
          openMenuOnClick={false}
          loadOptions={_getAsyncTemplates}
        />
      }
    />
  );
});

export default PreFill;
