import get from 'lodash/get'

import {
  getLicensesAdapter,
  getCharitiesAdapter,
  getBiographiesAdapter,
  getNationalitiesAdapter,
  getLanguagesAdapter,
  getLocationAdapter,
  getFullBodyImage,
  getHalfBodyImage,
  getProfileImage,
  getCategoryAdapter,
  getCategoryArrayAdapter,
} from 'utils/forms/adapters'


export default (data, t, allCategories) => {
  return {
    firstName: get(data, 'firstName'),
    lastName: get(data, 'lastName'),
    nickname: get(data, 'nickname'),
    displayNameType: get(data, 'displayNameType'),
    primaryCategory: getCategoryAdapter(allCategories, get(data, 'primaryCategory')),
    categories: getCategoryArrayAdapter(allCategories, get(data, 'categories')),
    title: get(data, 'title'),
    fullBodyImage: getFullBodyImage(get(data, 'fullBodyImage')),
    halfBodyImage: getHalfBodyImage(get(data, 'halfBodyImage')),
    profileImage: getProfileImage(get(data, 'profileImage')),
    location: getLocationAdapter(get(data, 'location')),
    languages: getLanguagesAdapter(get(data, 'languages'), t),
    nationality: getNationalitiesAdapter(get(data, 'nationality')),
    charities: getCharitiesAdapter(get(data, 'charities')),
    ownWords: get(data, 'ownWords'),
    biographies: getBiographiesAdapter(get(data, 'biographies')),
    licenses: getLicensesAdapter(get(data, 'licenses'))
  }
}
