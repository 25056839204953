import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const KeywordSearchListLink = ({ icon, itemHtml, link }) => {
  return (
    <Link className="keyword-search__list--item" to={link}>
      {icon}
      <div className="keyword-search__list-item" dangerouslySetInnerHTML={{ __html: itemHtml }} />
    </Link>
  )
}

KeywordSearchListLink.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  itemHtml: PropTypes.string,
  link: PropTypes.string,
}

export default KeywordSearchListLink
