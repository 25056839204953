import React from 'react'
import { useHistory } from 'react-router-dom'
import { CalendarSlotType, TalentOffersType } from 'shared'

import * as r from '_legacy/constants/routes'
import { postTalentsCalendarSlots as createNewTimeSlotApi } from '../api/calendar'
import { createExperienceDraft as createExperienceDraftApi } from 'api/appApi/offers/experience/experience'
import { createLiveVirtualEventsDraft as createLiveVirtualEventsDraftApi } from 'api/appApi/offers/liveVirtualEvents'

import { getTalentId } from 'utils/user'
import { Recurrence } from 'shared/dist/src/enums'
import { notify } from 'utils/notify'
import { useFetchTalentCalendar } from './talent-calendar'
import { weekOfMonth } from '_legacy/containers/Experience/utils'
import { useUser } from 'store/hooks/globalState/useUser'
import { useTalentCalendarFilters } from '../store/useCalendar'
import { getUserTimeZone } from 'utils/user'
import { prepareDate } from 'utils/services/timezone'
import { applyTimeToDate } from '../../../utils/applyTimeToDate'


export function getRecurring(formValues, timeZone) {
  if (formValues.recurring?.value === 'CUSTOM') {
    const endsType = {
      NEVER: 'NEVER',
      ON: prepareDate(formValues['ends-on'], timeZone),
      AFTER: Number(formValues['after-on']),
    }

    const typeMonth = {
      [Recurrence.RepeatEveryOnMonthTypeEnum.SINGLE_DATE]: {
        type: Recurrence.RepeatEveryOnMonthTypeEnum.SINGLE_DATE,
        dayNumber: new Date(formValues.date).getDate(),
      },
      [Recurrence.RepeatEveryOnMonthTypeEnum.POSITION_DATE]: {
        type: Recurrence.RepeatEveryOnMonthTypeEnum.POSITION_DATE,
        positionType: weekOfMonth(formValues.date),
        positionDay: new Date(formValues.date).getDay(),
      },
    }

    const repeatEvery = {
      [Recurrence.RepeatEveryType.DAY]: {
        through: Number(formValues.through),
        type: Recurrence.RepeatEveryType.DAY,
      },
      [Recurrence.RepeatEveryType.WEEK]: {
        through: Number(formValues.through),
        on: (formValues.on || []).map(Number),
        type: Recurrence.RepeatEveryType.WEEK,
      },
      [Recurrence.RepeatEveryType.MONTH]: {
        type: Recurrence.RepeatEveryType.MONTH,
        through: Number(formValues.through),
        on: typeMonth[formValues['repeat-on']?.value],
      },
      [Recurrence.RepeatEveryType.YEAR]: {
        through: Number(formValues.through),
        type: Recurrence.RepeatEveryType.YEAR,
      },
    }

    return {
      type: 'CUSTOM',
      options: {
        ends: endsType[formValues.ends],
        repeatEvery: repeatEvery[formValues.type.value],
      },
    }
  }

  return {
    type: formValues.recurring?.value,
  }
}

function handleError(err) {
  notify(err.message, 'error')
}

export const timeSlotAdapter = (formValues, timeZone) => ({
  title: formValues?.title,
  type: formValues?.eventType?.value,
  startTime: prepareDate(
    applyTimeToDate(formValues?.date, formValues?.startTime),
    timeZone,
  ),
  endTime: prepareDate(
    applyTimeToDate(formValues?.date, formValues?.endTime),
    timeZone,
  ),
  recurring: getRecurring(formValues, timeZone),
})

export function useCreateEvent() {
  const history = useHistory()
  const talentId = useUser(getTalentId)
  const timeZone = useUser(getUserTimeZone)
  const calendarFilters = useTalentCalendarFilters()
  const fetchTalentCalendar = useFetchTalentCalendar(talentId)

  return React.useCallback(
    (formValues, onSuccess) => {
      const eventType = formValues.eventType.value
      if ([CalendarSlotType.TIME_UNAVAILABLE, CalendarSlotType.MY_EVENT].includes(eventType)) {
        createNewTimeSlotApi({
          talentId,
          data: timeSlotAdapter(formValues, timeZone),
        })
          .then(() => {
            onSuccess()
            fetchTalentCalendar(calendarFilters)
          })
          .catch(handleError)
      }

      if (eventType === TalentOffersType.IN_PERSON_EXPERIENCE) {
        createExperienceDraftApi({
          talentId,
          data: {
            name: formValues.title,
            pricePerParticipant: null,
            takingPicturesOk: true,
            signaturesOk: true,
            startTime: prepareDate(
              applyTimeToDate(formValues?.date, formValues?.startTime),
              timeZone,
            ),
            endTime: prepareDate(
              applyTimeToDate(formValues?.date, formValues?.endTime),
              timeZone,
            ),
            typesOfDate: 'FIXED',
            date: prepareDate(formValues.date, timeZone),
            recurring: getRecurring(formValues, timeZone),
          },
        })
          .then(({ experienceDraft }) => {
            history.push(`/${r.OFFERINGS}/${r.EXPERIENCES}/${experienceDraft.id}/${r.DRAFT}`)
          })
          .catch(handleError)
      }

      if (eventType === TalentOffersType.VIRTUAL_EVENT) {
        createLiveVirtualEventsDraftApi({
          talentId,
          data: {
            name: formValues.title,
            startTime: prepareDate(
              applyTimeToDate(formValues?.date, formValues?.startTime),
              timeZone,
            ),
            endTime: prepareDate(
              applyTimeToDate(formValues?.date, formValues?.endTime),
              timeZone,
            ),
            date: prepareDate(formValues.date, timeZone),
            recurring: getRecurring(formValues, timeZone),
          },
        })
          .then(({ liveVirtualEventDraft }) => {
            history.push(`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${liveVirtualEventDraft.id}/${r.DRAFT}`)
          })
          .catch(handleError)
      }
    },
    [talentId, fetchTalentCalendar, calendarFilters]
  )
}
