import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import TwoLineStringWithBold from '_legacy/components/Table/table-components/types/common/TwoLineStringWithBold'
import { getLocationDetails, getLocationName } from 'utils/location'

const normalizeOccurrence = ({ locations, offerType }) => ({
  locations,
  type: offerType,
})

export default React.memo(function Location({ isLoading, ...rest }) {
  const t = useGetTranslate()
  const occurrence = normalizeOccurrence(rest)
  return (
    <TwoLineStringWithBold
      isLoading={isLoading}
      mainText={t(getLocationName(occurrence))}
      subText={t(getLocationDetails(occurrence))}
    />
  )
})
