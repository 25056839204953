// Modules
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import isEmpty from 'lodash/fp/isEmpty'
import always from 'lodash/fp/always'
import castArray from 'lodash/castArray'
import { FileType } from 'shared'

// Assets
import Icon from 'assets/icons/Icon'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useFilesEntitiesByIds } from 'store/hooks/globalState/useFileEntites'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useSubmitAnswer, useUploadVideos, useFetchVideoVoiceOverLessonOrderDetails } from './state/requests'
import { useVideoVoiceOverLessonOrderDetails, useSetVideoVoiceOverLessonOrderDetails } from 'store/hooks/globalState/useVideoVoiceOverLessonOrderDetails'

// Utils
import { getDaysTo, getLocale } from 'utils/date'
import { getSaveFullName, getUserLink, getUserTimeZone } from 'utils/user'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import {
  isPendingTypeUploadVideo,
  getCharitiesTitle,
  getTalentRefundText,
} from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import ProductTable from 'components/OfferingDetails/components/ProductTable'
import PendingAction from 'components/OfferingDetails/components/PendingAction'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'
import Media from './components/Media'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import MultiSelectLibraryModal from '_legacy/components/Library/MultiSelectLibrary/components/MultiSelectLibraryModal'
import { getVideoVoiceOverLessonOrderConfig } from './utils'
import CancelOrderButton from '../../components/CancelOrderButton'
import CancelStatus from '../../components/CancelStatus'

const MEDIA_KEY = 'order-media'
const TEMP_MEDIA_KEY = 'temp-order-media'

const VideoVoiceOverLessonOrderDetails = () => {
  const t = useGetTranslate()
  const timeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  // state
  const occurrence = useVideoVoiceOverLessonOrderDetails()
  const clearOccurrence = useSetVideoVoiceOverLessonOrderDetails(() => null)

  const { review, order } = occurrence || {}

  // requests
  const fetchOccurrence = useFetchVideoVoiceOverLessonOrderDetails()
  const uploadVideos = useUploadVideos()
  const submitAnswer = useSubmitAnswer()

  // modals
  const [isOpenLibraryModal, openLibraryModal, closeLibraryModal] = useModal(false)

  const libraryMethods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      [MEDIA_KEY]: [],
      [TEMP_MEDIA_KEY]: [],
    },
  })

  // library modal handlers
  const onCloseLibraryModal = () => {
    libraryMethods.setValue(TEMP_MEDIA_KEY, libraryMethods.getValues(MEDIA_KEY))
    closeLibraryModal()
  }

  const onOpenLibraryModal = () => {
    libraryMethods.setValue(TEMP_MEDIA_KEY, libraryMethods.getValues(MEDIA_KEY))
    openLibraryModal()
  }

  const onSubmitLibraryForm = () => {
    closeLibraryModal()
    const formKeys = libraryMethods.getValues(TEMP_MEDIA_KEY)
    const keys = castArray(formKeys)
    libraryMethods.setValue(MEDIA_KEY, keys)
    uploadVideos({ fileTokens: keys })
  }

  const onRemoveUploadFile = fileToken => {
    const fileTokens = libraryMethods.getValues(MEDIA_KEY).filter(token => token !== fileToken)
    const keys = castArray(fileTokens)
    libraryMethods.setValue(MEDIA_KEY, keys)
    uploadVideos({ fileTokens: keys })
  }

  // others
  const medias = useFilesEntitiesByIds(libraryMethods.watch(MEDIA_KEY, []))

  // effects
  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => {
    if (occurrence) {
      // update media form
      libraryMethods.setValue(
        MEDIA_KEY,
        occurrence.uploads.map(upload => upload.fileToken)
      )
    }
  }, [occurrence])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!occurrence) return <PageLoader />

  const { user, userUploads, uploads, pendingAction, totalCharityFee } = occurrence || {}

  const daysLeft = isPendingTypeUploadVideo(occurrence) && getDaysTo(new Date(occurrence?.pendingAction?.due), { locale: getLocale(userLang) })

  const c = getVideoVoiceOverLessonOrderConfig(occurrence)
  const refundText = getTalentRefundText({ t, refundRequests: occurrence.order.refundRequests })
  const cancelOrderButton = <CancelOrderButton orderId={order.id} onSuccess={fetchOccurrence} />

  return (
    <div className="video-voice-over-lesson-order od__margins">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.video-voice-over-lesson')}
        title={`${t('shared.words.order')} #${order.plaqueNumber}`}
      />

      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrence, timeZone)} />
          {pendingAction && <PendingAction action={pendingAction} title={t('shared.offers.video-voice-over-lesson')} />}

          <div className="od__inner-wrapper">
            <div className="od__row od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.aficionado')}</h6>
                <UserInfo user={user} />
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">
                  {t('order.video-message.submitted')} {user?.firstName}
                </h6>
                <p className="od--main-text od--accented">
                  <OrderUploadFiles files={userUploads} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box">
            {c.isShowUpload && (
              <FormProvider {...libraryMethods}>
                <div className="od--with-padding od--centered">
                  <h3 className="od--uppercase-title mb-16">{t('order.header.your-video-answer')}</h3>
                  {isEmpty(medias) ? (
                    <React.Fragment>
                      <h3 className="od--status-small-title mb-12">{daysLeft}</h3>
                      <Icon.UploadVideo className="mb-12" />
                      <p className="od--main-text mb-12">{t('order.answer.label.upload-or-drag')}</p>
                      <p className="od--small-text mb-24">{t('order.answer.label.requirements')}</p>
                      <Button
                        stretch
                        paddingVertical={16}
                        handleOnClick={openLibraryModal}
                        className="od__answer__upload-btn mb-16"
                        text={t('order.answer.buttons.upload-video')}
                      />
                      {cancelOrderButton}
                    </React.Fragment>
                  ) : (
                    <div className="od__column">
                      <div className="order-media-list">
                        {medias.map(media => (
                          <Media key={media.id} file={media} onRemove={() => onRemoveUploadFile(media.fileToken)} />
                        ))}
                      </div>
                      <button className="od__answer__add-more-btn mb-24" onClick={onOpenLibraryModal} type="button">
                        + {t('shared.words.add-more')}
                      </button>
                      <Button
                        stretch
                        handleOnClick={submitAnswer}
                        paddingVertical={16}
                        className="mb-16"
                        text={t('order.answer.buttons.submit')}
                      />
                      {cancelOrderButton}
                    </div>
                  )}
                </div>
                <MultiSelectLibraryModal
                  name={TEMP_MEDIA_KEY}
                  isOpen={isOpenLibraryModal}
                  onSubmit={onSubmitLibraryForm}
                  closeModal={onCloseLibraryModal}
                  maxSelectItems={5}
                  libraryModalProps={{ enableTypes: FileType.VIDEO }}
                  dropZoneProps={{ accept: FileType.VIDEO, maxSize: 1000 }}
                />
              </FormProvider>
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('shared.offers.video-voice-over-lesson')}
                  subtitle={t('order.description.funds')}
                  price={order?.totalPrice}
                  description={t('order.description.video-voice-over-lesson')}
                />
                <OfferingDetailsReviewStars
                  orderId={order.id}
                  review={review}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('shared.offers.video-voice-over-lesson')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}

            {c.isShowCanceled && (
              <CancelStatus
                refundText={refundText}
                title={t('shared.offers.video-voice-over-lesson')}
              />
            )}

            {c.isShowClosed && (
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('shared.offers.video-voice-over-lesson')}
                  subtitle={t('order.description.received')}
                  price={order?.totalPrice}
                />
                {!!totalCharityFee && order?.totalPrice && (
                  <OfferingDetailsEscrowFunds
                    className="od--bordered"
                    subtitle={getCharitiesTitle(t, order?.totalPrice, totalCharityFee)}
                    price={totalCharityFee}
                    description={t('order.description.charities.text')}
                  />
                )}
                <OfferingDetailsReviewStars
                  orderId={order.id}
                  review={review}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('shared.offers.video-voice-over-lesson')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {c.isShowWaitForPayment && (
        <div className="wrapper content-wrapper mt-100 mb-62">
          <ProductTable getTableFn={always(uploads || [])} />
        </div>
      )}
    </div>
  )
}

export default withLibrary(VideoVoiceOverLessonOrderDetails, { types: FileType.VIDEO })
