import React from 'react'
import Button from '_legacy/components/Button'
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg';

import Loader from '_legacy/components/Table/table-components/types/common/Loader.js'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const Actions = ({ isLoading, fullUrl }) => {
  const t = useGetTranslate()
  const handleDownload = () => window.open(fullUrl, '_blank')

  if (isLoading) return <Loader />

  return (
    <div className="table-action-cell">
      <Button
        text={t('ready-made-event-lesson-details.product.actions.download')}
        typeButton="button"
        handleOnClick={handleDownload}
        paddingVertical={4}
        fontSize="0.75"
        iconComponent={<DownloadIcon />}
      />
    </div>
  )
}

export default Actions
