// Modules
import React from 'react'
import get from 'lodash/get'
import { api } from 'shared'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { isChosenLocationOfferType } from 'utils/offer'
import { getLocationTimeZone } from 'utils/location'
import { getDateTimeText, applyTimezoneToDateTime } from '_legacy/components/Table/table-components/types/common/DateTime/utils'

// Legacy components
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'
import TwoLineString from '_legacy/components/Table/table-components/types/common/TwoLineString'

const { RANGE } = api.talents.talent.offers.DateTimeType


const Occurrence = ({ isLoading, occurrence }) => {
  const offerType = get(occurrence, 'offer.type')
  const location = get(occurrence, 'locations[0]')
  const startTime = get(occurrence, 'startTime')
  const endTime = get(occurrence, 'endTime')

  const timeZone =
    isChosenLocationOfferType(offerType)
      // TODO: choose what function of getting time zone from location should stay
      // getLocationTimeZone() vs getTimeZoneFromOccurrence()
      ? getLocationTimeZone(location)
      : useUser(getUserTimeZone)

  // TODO: unify DateTime table component by passing dateTime object as row prop (as in common case)
  const dateTime = {
    type: RANGE,
    date: startTime,
    from: startTime,
    to: endTime,
  }

  const zonedDateTime = applyTimezoneToDateTime(dateTime, timeZone)
  const locale = useUserLocale()
  const dateTimeText = getDateTimeText(zonedDateTime, timeZone, locale)

  const TooltipDate =
    <div>
      <p>{dateTimeText?.mainText}</p>
      <p>{dateTimeText?.subText}</p>
    </div>

  return (
    <PopperTooltip Component={TooltipDate}>
      <TwoLineString
        isLoading={isLoading}
        mainText={dateTimeText?.mainText}
        subText={dateTimeText?.subText}
      />
    </PopperTooltip>
  )
}

export default Occurrence
