export const parseRadius = params => {
  const {
    locationKmRadius,
    locationGooglePlaceId,
    locationLatitude,
    locationLongitude,
    ...rest
  } = params
  if (
    locationKmRadius === 'UNLIMITED' ||
    !(locationGooglePlaceId || (locationLatitude && locationLongitude))
  ) {
    return rest
  }

  return params
}
