import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Pointer from './Pointer';
import Items from './Items';

const OffersNavigation = ({ currentActive, onActivate }) => (
  <StyledNavigation className="mb-navigation">
    <Items onActivate={onActivate} currentActive={currentActive} />
    <Pointer currentActive={currentActive} />
  </StyledNavigation>
)

const StyledNavigation = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

OffersNavigation.propTypes = {
  currentActive: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]).isRequired,
  onActivate: PropTypes.func.isRequired
};

export default OffersNavigation;
