import React from 'react'
import PropTypes from 'prop-types'

import Icon from 'assets/icons/Icon'

const MobileCategoryHeader = ({ onGoBack, onClose, title }) => {
  return (
    <li className="category__header">
      {onGoBack && (
        <button
          onClick={onGoBack}
          className="category-header__button category-header__button--back"
        >
          <Icon.BackIcon />
        </button>
      )}
      <h4 className="category__header--title">{title}</h4>
      <button
        onClick={onClose}
        className="category-header__button category-header__button--close"
      >
        <Icon.CloseCross />
      </button>
    </li>
  )
}

MobileCategoryHeader.propTypes = {
  onGoBack: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
}

export default MobileCategoryHeader
