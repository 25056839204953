import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import forEach from 'lodash/forEach';
import get from 'lodash/get';

// utils
import { useFetch } from 'store/hooks/useFetch';
import {
  getLocationDetails as getLocationDetailsApi
} from 'api/appApi/integration/locations/details/details';
import { getAdapter } from './utils';
import { countries, states } from './data';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import { Input } from '_legacy/common/Input'
import { Row } from '_legacy/common/Template';
import AddressSelect from '_legacy/components/Form/Selects/basic/AddressSelect';
import CitiesSelect from '_legacy/components/Form/Selects/basic/CitiesSelect';
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent';

const MultiLocationSelect = React.memo(function MultiLocationSelect ({ baseName }) {
  const _baseName = baseName ? `${baseName}.` : '';
  const fields = {
    address: _baseName + 'address',
    city: _baseName + 'city',
    country: _baseName + 'country',
    state: _baseName + 'state',
    usaState: _baseName + 'usaState',
    zipCode: _baseName + 'zipCode',
  };

  const [{ data }, getLocationDetails] = useFetch(getLocationDetailsApi);
  const t = useGetTranslate();

  const { setValue, watch } = useFormContext();
  const values = watch([fields.address, fields.country]);
  const address = get(values, fields.address);
  const country = get(values, fields.country);

  const isUSSelect = country?.value === 'UNITED_STATES';

  React.useEffect(() => {
    if (address?.data) {
      getLocationDetails({ googlePlaceId: address.value });
    }
  }, [address]);

  React.useEffect(() => {
    if (data) {
      const response = getAdapter(data);
      forEach(
        response,
        (value, key) => setValue(fields[key], value, { shouldValidate: true })
      );
    }
  }, [data]);

  return (
    <>
      <Row
        nameBlock={t('register-user.form.address.label')}
        isRequired
        component={
          <AddressSelect
            isCreatable
            name={fields.address}
            placeholder={t('register-user.form.address.placeholder')}
            rules={{ validate: value => !!value?.label === true ||'shared.words.required' }}
          />
        }
      />
      <Row
        isRequired
        nameBlock={t('register-user.form.city.label')}
        component={
          <CitiesSelect
            name={fields.city}
            placeholder={t('register-user.form.city.placeholder')}
            rules={{ validate: value => !!value?.label === true || 'shared.words.required' }}
            isCreatable
          />
        }
      />
      <StateWrapper visible={isUSSelect}>
        <Row
          isRequired
          nameBlock={t('register-user.form.state.label')}
          component={
            <SelectComponent
              name={fields.usaState}
              placeholder={t('register-user.form.state.placeholder')}
              options={states}
              rules={{ required: 'shared.words.required' }}
            />
          }
        />
      </StateWrapper>
      <StateWrapper visible={!isUSSelect}>
        <Row
          nameBlock={t('register-user.form.state.label')}
          component={
            <Input
              name={fields.state}
              placeholder={t('register-user.form.state.placeholder')}
            />
          }
        />
      </StateWrapper>
      <Row
        isRequired
        nameBlock={t('register-user.form.postal-code.label')}
        component={
          <ZipCodeWrapper className="zip-code">
            <Input
              isRequired
              name={fields.zipCode}
              placeholder={t('register-user.form.postal-code.placeholder')}
              type='string'
            />
          </ZipCodeWrapper>
        }
      />
      <Row
        isRequired
        nameBlock={t('register-user.form.country.label')}
        component={
          <SelectComponent
            name={fields.country}
            placeholder={t('register-user.form.country.placeholder')}
            options={countries}
            rules={{ required: 'shared.words.required' }}
          />
        }
      />
    </>
  );
});

const ZipCodeWrapper = styled.div`
  max-width: 262px;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
  }
`;

const StateWrapper = styled.div`
  display: ${({ visible }) => visible ? 'block' : 'none'};
`;

MultiLocationSelect.propTypes = {
  baseName: PropTypes.string,
}

export default MultiLocationSelect;
