import React from 'react'
import get from 'lodash/get'
import { components } from 'react-select'

const SingleValueWithColor = props => {
  const background = get(props, 'data.color')

  return (
    <components.SingleValue {...props} className='select-single-value'>
      <span style={{ background }} className="color-section" />
      {props.children}
    </components.SingleValue>
  )
}

export default SingleValueWithColor
