import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import BiographyItem from '../Form/Biography/BiographyItem';

const Biography = ({ name }) => {
  const t = useGetTranslate();
  const { fields, append, remove, move } = useFieldArray({ name });

  const appendBiographyHandler = () => {
    append({ header: '', subheader: '', description: '' });
  };

  const handleDrag = ({ source, destination }) => {
    if (destination) {
      move(source.index, destination.index);
    }
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDrag}>
        <ul className="biography-list">
          <Droppable droppableId="biography-items">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {fields.map((item, idx) => (
                  <BiographyItem
                    key={`${name}[${idx}]`}
                    name={`${name}[${idx}]`}
                    idx={idx}
                    item={item}
                    move={move}
                    remove={remove}
                    isDownButtonDisable={idx === fields.length - 1}
                    isRemoveButtonDisable={fields.length === 1}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </ul>
      </DragDropContext>
      {fields.length < 12 && (
        <button className="form-row__button form-row__button--black mt-32" type="button" onClick={appendBiographyHandler}>
          + {t('edit-profile.form.biographies.button')}
        </button>
      )}
    </>
  );
};

Biography.propTypes = {
  name: PropTypes.string.isRequired
};

export default Biography;
