import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import RemoveButton from './RemoveButton';

const FileInfo = ({ file, className }) => {
  const originalName = get(file, 'originalName')
  // not removed cause related to sergii todo
  //const fileSize = get(file, 'size')

  return (
    <FileInfoContainer className={className}>
      <FileName>{originalName}</FileName>
      {/* todo: mb useful */}
      {/* <FileSize>{fileSize}</FileSize> */}
      <RemoveButton file={file} />
    </FileInfoContainer>
  )
}

const FileInfoContainer = styled.span`
  white-space: nowrap;
  overflow: hidden;
`

const FileName = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue};

  margin-bottom: 5px;

  text-overflow: ellipsis;
  overflow: hidden;
`

//const FileSize = styled.span`
//font-family: 'Montserrat', sans-serif;
//font-weight: bold;
//font-size: 13px;
//line-height: 1;
//color: ${({ theme }) => theme.colors.lightGrey};
//`

FileInfo.propTypes = {
  file: PropTypes.object,
  className: PropTypes.string,
}

export default FileInfo
