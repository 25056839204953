import React from 'react'
import { errorNames } from 'shared'
import { useHistory } from 'react-router-dom'
import {
  range,
  prop,
  propEq,
  assocPath,
  findIndex,
  cond,
  T,
  eq,
} from 'lodash/fp'
import { notify } from 'utils/notify'

import * as r from '_legacy/constants/routes'
import { bookLiveVirtualEventByOccurrenceId } from 'api/appApi/offers/publicViews/live-virtual-event'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useLiveVirtualEvents, useSetLiveVirtualEvents } from 'store/hooks/globalState/usePublicView'

const offersErrors = errorNames.offers

const bookingErrorMessages = {
  [offersErrors.BOOK_ALREADY_HAS_ORDER_ERROR]: 'experience.booking.errors.book-already-has-order',
  [offersErrors.BOOK_OUT_OF_TIME_BOUND_ERROR]: 'experience.booking.errors.book-out-time-bound',
  [offersErrors.BOOK_PARTICIPANTS_LIMIT_EXCEEDED_ERROR]: 'experience.booking.errors.book-participants-limit-exceeded',
  [offersErrors.BOOK_WRONG_TYPE_ERROR]: 'experience.booking.errors.book-wrong-type',
  [offersErrors.BOOK_OCCURRENCES_LIMIT_EXCEEDED_ERROR]: 'experience.booking.errors.book-occurrences-limit-exceeded',
  [offersErrors.BOOK_OCCURRENCES_OVERLAP_ERROR]: 'experience.booking.book-occurrences-overlap',
  [offersErrors.BOOK_OFFER_IS_NOT_PUBLIC]: 'experience.booking.errors.book-offer-is-not-public',
}

export function useBookLiveVirtualEvent(setIsSubmitting) {
  const t = useGetTranslate()
  const history = useHistory()

  const liveVirtualEventId = useLiveVirtualEvents(prop('liveVirtualEvent.id'))

  //
  // LiveVirtualEvent data update
  //

  const updateSlotParticipantsCount = useSetLiveVirtualEvents((prev, slotId, bookedCount, expectedCount, maxCount) => {
    const slotIdx = findIndex(propEq('id', slotId), prev.slots)
    const newCurrentCount = maxCount - (expectedCount - bookedCount);
    return assocPath(['slots', slotIdx, 'maxNumberOfParticipants'], newCurrentCount, prev)
  })

  const updateAlreadyBookedSlot = useSetLiveVirtualEvents((prev, slotId) => {
    const slotIdx = findIndex(propEq('id', slotId), prev.slots)
    return assocPath(['slots', slotIdx, 'booked'], true, prev)
  })

  //
  // Error handling
  //

  const onBookParticipantsLimitExceededError = (slot, includedFriends, error) => {
    const bookedCount = parseInt(includedFriends) + 1 // All booked friends + user itself
    updateSlotParticipantsCount(
      slot.id,
      bookedCount,
      error.context.expectedParticipantsCount,
      error.context.maxParticipantsCount,
    )
    notify(t(bookingErrorMessages[offersErrors.BOOK_PARTICIPANTS_LIMIT_EXCEEDED_ERROR]), 'error')
  }

  const onBookAlreadyHasOrderError = (slot) => {
    updateAlreadyBookedSlot(slot.id)
    notify(t(bookingErrorMessages[offersErrors.BOOK_ALREADY_HAS_ORDER_ERROR]), 'error')
  }

  const onDefaultError = (slot, error) => {
    notify(t(bookingErrorMessages[error.name]), 'error')
  }

  return React.useCallback(({ slot, includedFriends }) => {
    setIsSubmitting(true)

    const isOccurrence = !!slot?.offerLiveVirtualEventOccurrenceId

    const occurrenceParams = {
      offerLiveVirtualEventOccurrenceId: slot?.offerLiveVirtualEventOccurrenceId
    }

    const slotParams = {
      liveVirtualEventId,
      endTime: slot?.endTime,
      startTime: slot?.startTime
    }

    const params = {
      includedFriends: range(0, includedFriends).fill('3fa85f64-5717-4562-b3fc-2c963f66afa6'), // todo: fix id with back
      ...(isOccurrence ? occurrenceParams : slotParams)
    }

    bookLiveVirtualEventByOccurrenceId(params)
      .then(({ order }) => {
        history.push(`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${order.id}/${r.PURCHASE}`)
      })
      .catch(error => {
        cond([
          [
            eq(offersErrors.BOOK_PARTICIPANTS_LIMIT_EXCEEDED_ERROR),
            () => onBookParticipantsLimitExceededError(slot, includedFriends, error),
          ],
          [
            eq(offersErrors.BOOK_ALREADY_HAS_ORDER_ERROR),
            () => onBookAlreadyHasOrderError(slot)
          ],
          [
            T,
            () => onDefaultError(slot, error),
          ]
        ])(error.name)
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }, [t, liveVirtualEventId])
}
