import compose from 'lodash/fp/compose'

import withRouter from './withRouter.js'
import withModal from './withModal.js'
import withWindowDimentions from  './withWindowDimentions.js'
import withTwilioConversations from './withTwilioConversations.js'
import withScrollToTop from './withScrollToTop.js'
import withFetchCurrency from './withFetchCurrency.js'
import withNotificationsHub from './withNotificationsHub.js'
import withSubscribeLibrary from './withSubscribeLibrary.js'
import withSubscribeUser from './withSubscribeUser.js'
import withStyledTheme from './withStyledTheme.js'
import withTranslates from './withTranslates.js'
import withFetchInitialData from './withFetchInitialData.js'
import withIOSa2hsModal from './withIOSa2hsModal.js'
import withHelmetTags from './withHelmetTags.js'

const init = compose(
  withStyledTheme,
  withRouter,
  withIOSa2hsModal,
  withModal,
  withWindowDimentions,
  withTwilioConversations,
  withScrollToTop,
  withFetchCurrency,
  withNotificationsHub,
  withSubscribeLibrary,
  withSubscribeUser,
  withTranslates,
  withFetchInitialData,
  withHelmetTags,
)

export default init
