import React from 'react'

import { useSubMenuActive } from 'store/hooks/globalState/useGlobalState'
import { useNavigationMenu } from '_legacy/configs/navigation-menu/useNavigationMenu'
import AccountMenuItem from './AccountMenuItem'

const AccountMenu = () => {
  const subMenuActive = useSubMenuActive()
  const accountItems = useNavigationMenu()

  return (
    <div className={`account-menu ${subMenuActive ? 'active' : ''}`}>
      <div className="account-menu__nav wrapper content-wrapper">
        {accountItems.map(item => (
          <AccountMenuItem
            key={item.slug}
            icon={item.icon}
            name={item.name}
            linkProps={item.linkProps}
            subMenuActive={subMenuActive}
            isLink={item.isLink}
            linkTo={item.linkTo}
            hasUnreadChatMessages={item.hasUnreadChatMessages}
          />
        ))}
      </div>
    </div>
  )
}

export default AccountMenu
