import React from 'react'
import PropTypes from 'prop-types'
import identity from 'lodash/fp/identity'

import MobileCategoryHeader from './MobileCategoryHeader'
import { getLastCategoryTitle, checkSubitems, mapper, getLastCategoryId } from '../utils'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';


const MobileCategoriesSelect = ({ onClose, setValue, value, getFn, filterFn = identity, allCategoriesLabel }) => {
  const t = useGetTranslate()

  const currentItems = getFn(state => filterFn(mapper(state, value.map(v => v.i), value.length), value.length, value), [value])
  const prevItems = getFn(
    state => mapper(state, value.slice(0, value?.length - 1).map(v => v.i), value.length - 1),
    [value]
  )
  const itemsToRender = currentItems?.length ? currentItems : prevItems || []
  const valueForTitle = currentItems?.length ? value : value.slice(0, value?.length - 1)

  const onGoBack = (e) => {
    e.stopPropagation()
    setValue([...(value || []).slice(0,  currentItems.length ? value.length - 1 : value.length - 2)])
  }

  const onSelect = (category, e) => {
    e.stopPropagation()
    setValue([...(value || []), category])
  }

  return (
    <ul className="cascader-list__list">
      <MobileCategoryHeader
        title={`${t('shared.words.all')} ${t(getLastCategoryTitle(valueForTitle) || allCategoriesLabel || '')}`}
        onGoBack={value.length ? onGoBack : null}
        onClose={onClose}
      />
      {itemsToRender?.map(category => (
        <li
          key={category.i}
          onClick={e => onSelect(category, e)}
          data-selected={String(category.i === getLastCategoryId(value))}
          data-arrow={String(checkSubitems(category))}
        >
          {t(category?.n)}
        </li>
      ))}
    </ul>
  )
}

MobileCategoriesSelect.propTypes = {
  getFn: PropTypes.func,
  filterFn: PropTypes.func,
  onClose: PropTypes.func,
  setValue: PropTypes.func,
  value: PropTypes.array,
}

export default MobileCategoriesSelect
