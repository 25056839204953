import React from 'react'
import PropTypes from 'prop-types'
import { size } from 'lodash/fp'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'
import { getShortMonthDate, getTimeRange } from 'utils/date'
import { getLocationFormattedAddress, getLocationPlace } from 'utils/location'

const JoinModal = ({ isOpen, onClose, list= [], joinHandler }) => {
  const t = useGetTranslate()

  const onJoin = React.useCallback((occurrence) => {
    joinHandler(occurrence)
    onClose()
  }, [joinHandler])

  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} isMobileFullScreen showCloseButton>
      <div className="join-modal">
        <h1 className="join-modal__header">{t('experience-public-view.join-modal.header')}</h1>

        <div className="join-table">
          <div className="join-table__row join-table__row--header">
            <span className="join-table__label">{size(list)} {t('experience-public-view.join-modal.expreiences')}</span>
            <span className="join-table__label">{t('experience-public-view.join-modal.location')}</span>
            <span className="join-table__label join-table__label--center">{t('experience-public-view.join-modal.current-participant')}</span>
            <span className="join-table__label" />
          </div>

          <div className="join-table__body">
            {(list || []).map((occurrence, i) => (
              <div key={i} className="join-table__row join-table__row--body">

                <span className="join-table__row-cell">
                  <div className="join-table__label">{t('experience-public-view.join-modal.expreiences')}</div>
                  <span className="join-table__cell-wrapper">
                    <strong>{getShortMonthDate(occurrence.start)}</strong>
                    <span>{getTimeRange([occurrence.start, occurrence.end])}</span>
                  </span>
                </span>

                <span className="join-table__row-cell">
                  <div className="join-table__label">{t('experience-public-view.join-modal.location')}</div>
                  <span className="join-table__cell-wrapper">
                    <span>{getLocationPlace(occurrence.bookedLocation)}</span>
                    <span>{getLocationFormattedAddress(occurrence.bookedLocation)}</span>
                  </span>
                </span>

                <span className="join-table__row-cell join-table__row-cell--center">
                  <div className="join-table__label">{t('experience-public-view.join-modal.current-participant')}</div>
                  <span className="join-table__cell-wrapper">
                    <span>{occurrence.participantsCount} of {occurrence.maxNumberOfParticipants}</span>
                  </span>
                </span>

                <span className="join-table__row-cell">
                  <Button disabled={occurrence.booked} text="Join" paddingVertical={5} paddingHorizontal={15} handleOnClick={() => onJoin(occurrence)} />
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}

JoinModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  list: PropTypes.array.isRequired,
}

export default JoinModal
