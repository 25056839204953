import { _get, _post } from 'api/utils'

export const getPdfLinkToPreview = () =>
  _get('/integrations/sterling/reports/pdf', {
    needAuthorization: true,
  })

export const cancelSubscription = () =>
  _post('/auth/me/entourage/cancel-subscription', {
    needAuthorization: true,
  })
