import { format } from 'date-fns-tz';
import { isDate } from 'date-fns'
import enGB from 'date-fns/locale/en-GB'

export const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone //get local browser timezone

export const getZonedTimeAbbr = (time, timeZone, timeFormat) => {
  if (isDate(time)) {
    return format(new Date(time), timeFormat || 'zzz', {
      timeZone: timeZone || localTimezone,
      locale: enGB,
    })
  }
  return null
}

