import React from 'react'
import { useHistory } from 'react-router-dom'

import Card from './Card'
import { StyledRadioButton } from '_legacy/common'
import { ReactComponent as EntourageIcon } from '../icons/EntourageIcon.svg'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetEntourageOrder } from 'store/hooks/globalState/useEntourageOrder'
import { routesList } from 'router/routesMap'

const EntouragePublicPage = React.memo(({ pageConfig, initialStatus }) => {
  const t = useGetTranslate()
  const history = useHistory()
  const setEntourageOrder = useSetEntourageOrder((prev, next) => next, [])

  const [selectedStatus, setSelectedStatus] = React.useState(initialStatus)
  const handleOnChange = React.useCallback(e => {
    setSelectedStatus(e.target.value)
  }, [])

  const currentConfig = pageConfig[selectedStatus]

  const applyNowHandle = React.useCallback(() => {
    currentConfig.api().then(({ order }) => {
      setEntourageOrder(order)
      history.push(routesList.entouragePurchase())
    })
  }, [currentConfig, history])

  return (
    <div className="entourage-public wrapper content-wrapper">
      <EntourageIcon className="entourage-public-icon" />
      <h1 className="entourage-public-header">{t('entourage-public.entourage-membership')}</h1>

      {/* ENTOURAGE INFO */}
      <div className="entourage-info">
        <div className="entourage-info__description">
          <p className="entourage-info__description-text">{t(currentConfig.text)}</p>
        </div>

        <div className="entourage-info__wrapper">
          {currentConfig.infoHeader && <div className="entourage-info__header">{t(currentConfig.infoHeader)}</div>}

          {currentConfig.statuses && (
            <div className="entourage-info__controls">
              {currentConfig.statuses.map(status => (
                <StyledRadioButton
                  dark
                  key={status.value}
                  value={status.value}
                  onChange={handleOnChange}
                  checked={status.value === selectedStatus}
                >
                  {t(status.text)}
                </StyledRadioButton>
              ))}
            </div>
          )}

          <div className="entourage-info__details">
            <div className="entourage-info__details-item">
              <p className="entourage-info__note">
                <Trans key={currentConfig.note} tKey={currentConfig.note} />
              </p>

              <h4 className="entourage-public-sub-header">{t('entourage-public.membership-requirements')}</h4>
              <ul className="entourage-info__requirement-list">
                {currentConfig.requirements.map(requirement => (
                  <li key={requirement} className="entourage-info__requirement-item">
                    <Trans tKey={requirement} />
                  </li>
                ))}
              </ul>
            </div>
            <div className="entourage-info__details-item">
              <Card price={currentConfig.price} list={currentConfig.acceptance} onClick={applyNowHandle} />
            </div>
          </div>

          {/* PARTNERS */}
          <h4 className="entourage-public-sub-header">{t(currentConfig.partnersLabel)}</h4>
          <div className="entourage-info__partners-list">
            {currentConfig.partners.map(({ Logo, header, text, link }) => (
              <div key={header} className="entourage-info__partner">
                <a href={link} className="entourage-info__partner-link">
                  <Logo className="entourage-info__partner-logo" />
                </a>
                <div className="entourage-info__partner-info">
                  <strong>{t(header)}</strong>
                  <p>{t(text)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* BENEFITS */}
      <div className="entourage-benefits">
        {currentConfig.benefits.map(benefit => (
          <div key={benefit.header} className="entourage-benefits__item">
            <h2 className="entourage-benefits__header">{t(benefit.header)}</h2>

            <div className="entourage-benefits__details">
              {benefit.list.map(process => (
                <div className="entourage-benefits__detail" key={t(process.header)}>
                  <h3 className="entourage-benefits__process-header">{t(process.header)}</h3>
                  <p className="entourage-benefits__text">{t(process.text)}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
})

export default EntouragePublicPage
