import React from 'react'

import { offeringsTypes, offeringsTypesDraft } from '_legacy/configs/table/manageOfferings';

import CommonOffering from '../common/Offering'

export default React.memo(function Offering({ isLoading, id, type, title, status }) {

  const href = status === 'DRAFT' ? offeringsTypesDraft[`${type}_DRAFT`] : offeringsTypes[type]

  return (
    <CommonOffering
      isLoading={isLoading}
      id={id}
      type={type}
      title={title}
      status={status}
      config={href}
    />
  )
})
