import React from 'react'
import styled from 'styled-components'

import Loader from './Loader'
import Avatar from './Avatar'
import { getSaveFullName } from 'utils/user'

export default React.memo(function AvatarWithName({
  isLoading, firstName = '', lastName = '', date = '', image
}) {
  const content = React.useMemo(() => {
    if (isLoading) return <Loader className='placeholder' />
    return (
      <div className='name'>
        {date || getSaveFullName({ firstName, lastName })}
      </div>
    )
  }, [isLoading, firstName, lastName])

  return (
    <StyledWrapper>
      <Avatar
        isLoading={isLoading}
        image={image}
        date={date}
      />
      {content}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 17px 0;
  @media screen and (max-width: 768px) {
    padding: 0;
    margin-top: 4px;
  }
  .name {
    margin-left: 12px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.blue};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .placeholder {
    margin-left: 12px;
  }
`
