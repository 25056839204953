import React from 'react'

import Icon from 'assets/icons/Icon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import './index.scss'

const BackgroundComponenTalent = () => {
  const t = useGetTranslate()

  return (
    <div className='multi-level-select-backgorund'>
      <div className='multi-level-select-backgorund__desc'>
        <span className='multi-level-select-backgorund__icon'>
          <span className='multi-level-select-backgorund__arrow'><Icon.ArrowLong fill="#525361" /></span>
          <Icon.Star fill="#525361" />
        </span>
        <p className='multi-level-select-backgorund__text'>{t('cascader.background-text.choose.talent')}</p>
      </div>
    </div>
  )
}

export default BackgroundComponenTalent
