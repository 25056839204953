import get from 'lodash/get'
import cond from 'lodash/cond';
import is from 'lodash/fp/isEqual';
import always from 'lodash/fp/always';
import T from 'lodash/fp/T';
import { CalendarSlotType, TalentOffersType } from 'shared'
import { getMonthDayYearDate } from 'utils/date'
import { slotBackground } from 'components/Calendar/BaseCalendar/configs/slot-config'

import * as r from '_legacy/constants/routes'

export const eventTitle = {
  OFFER: 'calendar.info-sidebar.title.custom-offer',

  [CalendarSlotType.MY_EVENT]: 'calendar.info-sidebar.title.my-events',
  [CalendarSlotType.TIME_UNAVAILABLE]: 'calendar.info-sidebar.title.time-unavailable',
  SYNCED_CALENDAR: 'calendar.info-sidebar.title.synced-calendar',

  [TalentOffersType.IN_PERSON_EXPERIENCE]: 'calendar.info-sidebar.title.experiences',
  [TalentOffersType.VIRTUAL_EVENT]: 'calendar.info-sidebar.title.virtual-events',
  [TalentOffersType.VIDEO_CHAT]: 'calendar.info-sidebar.title.live-video-chats',
  LESSONS: 'calendar.info-sidebar.title.lessons',
}

export const eventInfoAdapter = eventInfo => {
  const { id, title, extendedProps } = eventInfo.event || {}

  return {
    eventHeading: get(eventTitle, extendedProps?.offerType, 'calendar.slot.default-title'),
    id,
    title,
    backgroundColor: slotBackground[extendedProps?.offerType],
    date: getMonthDayYearDate(extendedProps.start),
    locations: extendedProps?.locations,
    participants: extendedProps?.participants,
    maxParticipants: extendedProps?.maxParticipants,
    price: extendedProps?.price,
    type: extendedProps?.offerType,
    publicId: extendedProps?.offerSequentialId,
    occurrenceId: extendedProps?.occurrenceId,
    beforeDriveTime: extendedProps?.beforeDriveTime,
    afterDriveTime: extendedProps?.afterDriveTime,
    start: extendedProps?.start,
    end: extendedProps?.end,
  }
}

export const eventPublicLink = ({ type, plaqueNickname, publicId }) => cond([
  [is(TalentOffersType.IN_PERSON_EXPERIENCE), always(`/${r.EXPERIENCES}/${plaqueNickname}/${publicId}`)],
  [is(TalentOffersType.VIRTUAL_EVENT), always(`/${r.LIVE_VIRTUAL_EVENTS}/${plaqueNickname}/${publicId}`)],
  [is(TalentOffersType.VIDEO_CHAT), always(`/${r.VIDEO_CHAT}/${plaqueNickname}/${publicId}`)],
  [is(TalentOffersType.VIRTUAL_LESSON), always(`/${r.LESSONS}/${plaqueNickname}`)],
  [is(TalentOffersType.VIDEO_VOICE_OVER_LESSON), always(`/${r.LESSONS}/${plaqueNickname}`)],
  [is(TalentOffersType.LIVE_IN_PERSON_LESSON), always(`/${r.LESSONS}/${plaqueNickname}`)],
  [T, always(`/${r.NOT_FOUND}`)],
])(type)

export const eventManageLink = ({ type, occurrenceId }) => cond([
  [is(TalentOffersType.IN_PERSON_EXPERIENCE), always(`/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/${occurrenceId}`)],
  [is(TalentOffersType.VIRTUAL_EVENT), always(`/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/${occurrenceId}`)],
  [is(TalentOffersType.VIDEO_CHAT), always(`/${r.ORDERS}/${r.VIDEO_CHAT}/${r.DETAILS}/${occurrenceId}`)],
  [is(TalentOffersType.VIRTUAL_LESSON), always(`/${r.ORDERS}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}/${occurrenceId}`)],
  [is(TalentOffersType.VIDEO_VOICE_OVER_LESSON), always(`/${r.ORDERS}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}/${occurrenceId}`)],
  [is(TalentOffersType.LIVE_IN_PERSON_LESSON), always(`/${r.ORDERS}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}/${occurrenceId}`)],
  [T, always(`/${r.NOT_FOUND}`)],
])(type)
