import React from 'react'

import { _get } from 'api/utils';

export default function SlidesLoader ({ setSlides }) {
  React.useEffect(() => {
    _get('/talents/carousel', {
      query: {
        limit: 100
      }
    })
      .then(({ carousel }) => {
        setSlides(carousel)
      })
      .catch(err => console.warn(err))
  }, [setSlides])

  return null
}
