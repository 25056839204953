import React from 'react'
import sortBy from 'lodash/sortBy'
import { types } from 'shared'

import { READY_MADE_EVENT_LESSON } from '_legacy/constants/routes'

import { useBookOffer } from 'requests/offerings/book'
import { useGetTranslate, Trans } from 'store/hooks/globalState/useTranslates'
import { useFetchReadyMadeEvents } from 'requests/public-views'
import {
  useReadyMadeEventsPublic,
  useSetReadyMadeEventsPublic,
} from 'store/hooks/globalState/usePublicView'

import PageLoader from '_legacy/common/Template/PageLoader'

import PublicViewBorderedCard from '_legacy/components/PublicView/PublicViewBorderedCard'
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicDescriptionOneLine from '_legacy/components/PublicView/PublicDescriptionOneLine'
import PublicPriceBlock from '_legacy/components/PublicView/PublicPriceBlock'
import PublicSlider from '_legacy/components/PublicSlider/'
import FollowButton from '_legacy/components/Button/FollowButton'

import './index.scss'

const { DIGITAL_DELIVERY } = types.talent.offers.readyMadeEvent.DeliveryType

const ReadyMadeEventPublic = () => {
  const [loader, setLoader] = React.useState(false)

  const t = useGetTranslate()
  const readyMadeEvent = useReadyMadeEventsPublic()
  const fetchExperiencePublicView = useFetchReadyMadeEvents()
  const clearOccurrence = useSetReadyMadeEventsPublic(() => null)
  const bookReadyMadeEvent = useBookOffer(setLoader)

  React.useEffect(() => {
    fetchExperiencePublicView()
    return () => {
      clearOccurrence()
    }
  }, [])

  if (!readyMadeEvent) return <PageLoader />

  const {
    deliveryType,
    media,
    productDescription,
    productName,
    productPrice,
    talent,
    id: offerId
  } = readyMadeEvent

  const sortMedia = sortBy(media, ({ isPrimary }) => !isPrimary)

  return (
    <div className="ready-made-lesson">
      <PublicHeadingBlock name={productName} className="inverted short" />
      <div className="wrapper content-wrapper">
        <PublicViewBorderedCard borderColor={'gradient'}>
          <FollowButton
            id={offerId}
            isFollowed={readyMadeEvent?.isFollowed}
            type='OFFERING'
            className="button-like light absolute absolute-top-right"
            text={t('shared.words.save')}
          />
          <div className="slider">
            <PublicSlider covers={sortMedia} />
          </div>
          <div className="description">
            <PublicDescriptionOneLine>
              <p>
                <Trans
                  tKey="ready-made-event.text.get-ready-made-event"
                  phs={[
                    { ph: '{{displayName}}', value: talent.displayName },
                    {
                      ph: '{{talentLink}}',
                      value: talent.plaqueNickname,
                    },
                  ]}
                />
              </p>
            </PublicDescriptionOneLine>
            <PublicPriceBlock
              text={t('ready-made-event.label.price')}
              price={productPrice}
              buttonProps={{
                text: t('ready-made-event.label.button'),
                handleOnClick: () => bookReadyMadeEvent({ offerId, offeringType: READY_MADE_EVENT_LESSON }),
                loader,
              }}
            />
            <div className="desktop-info">
              <PublicDescriptionOneLine className="public-view-description--gray">
                <h4>{t('ready-made-event.subtitle.description')}</h4>
                <p>{productDescription}</p>
              </PublicDescriptionOneLine>
              <PublicDescriptionOneLine>
                <h4>{t('ready-made-event.subtitle.delivery')}</h4>
                <div>
                  <p>
                    {deliveryType === DIGITAL_DELIVERY
                      ? t('ready-made-event.delivery-type.digital')
                      : t('ready-made-event.delivery-type.physical')}
                  </p>
                </div>
              </PublicDescriptionOneLine>
            </div>
          </div>
        </PublicViewBorderedCard>
        <div className="mobile-info">
          <PublicDescriptionOneLine className="public-view-description--gray">
            <h4>{t('ready-made-event.subtitle.description')}</h4>
            <p>{productDescription}</p>
          </PublicDescriptionOneLine>
          <PublicDescriptionOneLine>
            <h4>{t('ready-made-event.subtitle.delivery')}</h4>
            <div>
              <p>
                {deliveryType === DIGITAL_DELIVERY
                  ? t('ready-made-event.delivery-type.digital')
                  : t('ready-made-event.delivery-type.physical')}
              </p>
            </div>
          </PublicDescriptionOneLine>
        </div>
      </div>
    </div>
  )
}

export default ReadyMadeEventPublic
