import React from 'react';
import PropTypes from 'prop-types';

import Common from './Common';

const DatePicker = (props) => {
  return (
    <Common
      showYearDropdown
      autoComplete="off"
      dateFormatCalendar="LLLL"
      {...props}
    />
  )
};

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.string,
  isRequired: PropTypes.bool,
  minDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default DatePicker;
