import React from 'react';
import PropTypes from 'prop-types';
import { types } from 'shared';

import Icon from 'assets/icons/Icon';
import CustomPicture from '_legacy/common/CustomPicture';

import getFile from 'utils/getFile';

const { VIDEO } = types.file.Types;

export default function ImageSection ({ isSpecial, data, icon }) {
  const file = getFile(data);

  if (file) {
    if (file.type === VIDEO) {
      return (
        <div className='image-wrapper'>
          <CustomPicture
            className='image'
            file={{
              ...file,
              fullUrl: '',
              sources: file?.preview?.sources
            }}
          />
        </div>
      );
    }
    return (
      <div className='image-wrapper'>
        <CustomPicture
          className='image'
          file={file}
        />
      </div>
    );
  }

  const IconComponent = Icon[icon];

  return (
    <div className='icon-wrapper'>
      <IconComponent className={`icon ${isSpecial ? 'special-icon' : ''}`} />
    </div>
  );
}

ImageSection.propTypes = {
  isSpecial: PropTypes.bool,
  data: PropTypes.object,
  icon: PropTypes.string.isRequired
};
