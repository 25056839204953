import React from 'react'
import { pipe, prop, includes, __ } from 'lodash/fp'
import { OfferOccurrenceStatus } from 'shared'

import Filters from '_legacy/components/Table/Filters'
import { counterConfig, fields, filtersConfig, subFiltersConfig } from '_legacy/configs/table/recurringList'
import {
  useExperienceOfferingDetailsTable,
  useExperienceOfferingDetailsTableFilters,
  useSetExperienceOfferingDetailsTableFilters,
} from '../state/store'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import { elementsTypes, RECURRING_LIST } from '_legacy/components/Table/table-components/types/recurring-list/config'
import { useCancelOccurrence, useFetchExperienceOfferingDetails, useTryCancelOccurrence } from '../state/requests'

import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import CancelModal from '../../../components/CancelModal'

const sortFields = {
  DATE: 'DATE',
  LOCATION: 'LOCATION',
  REQUESTS: 'REQUESTS',
  PARTICIPANTS: 'PARTICIPANTS',
  STATUS: 'STATUS',
}

const tableConfig = [
  {
    header: 'table.header.date',
    field: sortFields.DATE,
    type: elementsTypes.date,
  },
  {
    header: 'table.header.location',
    field: sortFields.LOCATION,
    type: elementsTypes.location,
  },
  {
    header: 'table.header.requests',
    field: sortFields.REQUESTS,
    type: elementsTypes.requests,
    className: 'centered',
  },
  {
    header: 'table.header.participants',
    field: sortFields.PARTICIPANTS,
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    field: sortFields.STATUS,
    type: elementsTypes.status,
    className: 'centered',
  },
]

const shouldShowCancelAction = pipe(
  prop('status'),
  includes(__, [OfferOccurrenceStatus.OPEN, OfferOccurrenceStatus.FULLY_BOOKED])
)

const ExperienceRecurringList = () => {
  const t = useGetTranslate()
  const [isOpenCancelModal, openCancelModal, closeCancelModal] = useModal(false)
  const [occurrenceToCancel, setOccurrenceToCancel] = React.useState(null)
  const cancelOccurrence = useCancelOccurrence()
  const tryCancel = useTryCancelOccurrence(openCancelModal)

  const cancelHandler = React.useCallback(({ reason, message }) => {
    cancelOccurrence({ reason, message, occurrenceId: occurrenceToCancel })
  }, [occurrenceToCancel])

  const tryCancelHandler = React.useCallback(([occurrenceId]) => {
    setOccurrenceToCancel(occurrenceId)
    tryCancel(occurrenceId)
  }, [])

  const actionsConfig = React.useMemo(() => [
    { label: t('table.actions.cancel'), cbFn: tryCancelHandler, shouldShow: shouldShowCancelAction }
  ], [t, tryCancelHandler])

  return (
    <React.Fragment>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useExperienceOfferingDetailsTableFilters}
        setFn={useSetExperienceOfferingDetailsTableFilters}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useExperienceOfferingDetailsTableFilters}
        setFn={useSetExperienceOfferingDetailsTableFilters}
      />
      <Table
        tableName={RECURRING_LIST}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        getTableFn={useExperienceOfferingDetailsTable}
        fields={fields}
        getFiltersFn={useExperienceOfferingDetailsTableFilters}
        setFiltersFn={useSetExperienceOfferingDetailsTableFilters}
        fetchTableFn={useFetchExperienceOfferingDetails}
      />

      <CancelModal
        isOpen={isOpenCancelModal}
        onClose={closeCancelModal}
        cancelHandler={cancelHandler}
        buttonText='cancel-reason.cancel-order.button'
      />
    </React.Fragment>
  )
}

export default ExperienceRecurringList
