import React from 'react';
import PropTypes from 'prop-types'
import styled from 'styled-components';

import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

const OfferingsDetailsHeaderItem = ({ icon, item = '', subitem = '', TooltipComponent }) => {
  return (
    <StyledRecurringListHeaderLeft>
      {icon}
      <StyledTextWrapper className='offering-details-header-item__text'>
        {item && (
          <PopperTooltip Component={TooltipComponent}>
            <p className='item od--main-text od--accented'>{item}</p>
          </PopperTooltip>
        )}
        <span className='subitem'>{subitem}</span>
      </StyledTextWrapper>
    </StyledRecurringListHeaderLeft>
  )
};

const StyledRecurringListHeaderLeft = styled.div`
  color: ${({ theme: { colors } }) => colors.blue};
  font-family: Montserrat;
  display: flex;
  svg {
    fill: #626473;
    width: 20px;
    margin-top: 2px;
  }
`;

const StyledTextWrapper = styled.div`
  padding-bottom: 24px;
  margin-left: 12px;
  .item {
    font-weight: bold;
    font-size: 16px;
    line-height: 1.625;
    margin-bottom: 2px;
    display: inline-block;
  }
  .subitem {
    font-weight: 500;
    font-size: 14px;
    line-height: 1.5;
    display: block;
  }
`;

OfferingsDetailsHeaderItem.propTypes = {
  icon: PropTypes.node,
  item: PropTypes.string,
  subitem: PropTypes.string,
  TooltipComponent: PropTypes.node,
}

export default OfferingsDetailsHeaderItem;
