import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import styled from 'styled-components'
import compose from 'lodash/fp/compose'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useRegisterAsAficionado } from 'requests/auth'

import { defaultValues } from './utils/defaultValues'
import { submitAdapter } from './utils/submitAdapter'

import ProfileTypes from './components/ProfileTypes'
import RequiredLabel from '../shared/components/RequiredLabel'
import HeadshotImage from '../shared/components/HeadshotImage'
import FirstName from '../shared/components/FirstName'
import LastName from '../shared/components/LastName'
import Nickname from '../shared/components/Nickname'
import Email from '../shared/components/Email'
import BackupEmail from '../shared/components/BackupEmail'
import RegisterPasswordBlock from '../shared/components/Password/RegisterPasswordBlock'
import MultiLocationSelect from '../shared/components/MultiLocationSelect'
import PhoneConfirm from '../shared/components/PhoneConfirm'
import DateOfBirth from '../shared/components/DateOfBirth'
import SocialMediaLinks from '../ProfileEdit/components/SocialMediaLinks' // todo: move to shared
import DataProcessingConsent from '../shared/components/DataProcessingConsent'
import MarketingCommunications from './components/MarketingCommunications'
import SubmitButton from '../shared/components/SubmitButton'
import CommunicationsPreference from './components/CommunicationPreference'
import MissedData from '../shared/components/MissedData'

const RegistrationAficionadoForm = React.memo(function RegistrationAficionadoForm() {
  const t = useGetTranslate()
  const [loading, setLoading] = React.useState(false)
  const registerAsAficionado = useRegisterAsAficionado(setLoading)

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  })

  const onSubmit = compose(registerAsAficionado, submitAdapter)

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)} autoComplete='off' className='white-background'>
        <RequiredLabel />
        <ProfileTypes />
        <HeadshotImage name='profileImage' />
        <FirstName />
        <LastName />
        <Nickname />
        <Email shouldValidate />
        <BackupEmail />
        <RegisterPasswordBlock />
        <MultiLocationSelect />
        <DateOfBirth />
        <PhoneConfirm isEditable={false} />
        <SocialMediaLinks name='socialMedia' />
        <MarketingCommunications />
        <CommunicationsPreference />
        <DataProcessingConsent />
        <SubmitButton text={t('register-aficionado.form.submit-button.label')} disabled={loading} />
        <MissedData />
      </StyledForm>
    </FormProvider>
  )
})

const StyledForm = styled.form`
  padding-bottom: 50px;
`

export default RegistrationAficionadoForm
