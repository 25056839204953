import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import Checkbox from './Checkbox';

import { useOnClickOutside } from 'store/hooks/useOnClickOutside'


const MultiCheckbox = ({ name, register, defaultEntities = [], buttonLabel, placeholder }) => {
  const t = useGetTranslate()
  const [entities, setEntities] = useState(() => defaultEntities);
  const [showInput, setShowInput] = useState(false);
  const [value, setValue] = useState('');
  const node = React.useRef()

  function onAdd() {
    if (value.trim().length === 0) return;
    setEntities(prev => [...prev, value]);
    setValue('');
  }

  function addHandler(e) {
    if (e.key === 'Enter') {
      onAdd();
      e.preventDefault();
    }
  }

  function onCancel() {
    setShowInput(false);
    setValue('');
  }

  function onRemove(checkboxIndex) {
    setEntities(
      prev => prev.filter((_, index) => index !== checkboxIndex)
    )
  }

  useEffect(() => {
    if (entities.length !== 0) return;

    setEntities(defaultEntities);
  }, [defaultEntities]);

  useOnClickOutside(node, onCancel)

  return (
    <div className="checkbox-wrapper">
      <div className="multi-checkbox">
        {entities.map((entity, index) => (
          <Checkbox
            withRemove
            onRemove={() => onRemove(index)}
            name={name}
            value={entity}
            defaultChecked
            label={entity}
            register={register}
            key={entity}
          />
        ))}
        {!showInput && (
          <button
            className="multi-checkbox__button"
            type="button"
            onClick={() => setShowInput(p => !p)}
          >
            + {buttonLabel || t('shared.taboos.button')}
          </button>
        )}
        {entities.length < 6 && showInput && (
          <div className="multi-checkbox__container" ref={node}>
            <input
              className="input input-default"
              value={value}
              onChange={e => setValue(e.target.value)}
              onKeyDown={addHandler}
              placeholder={placeholder || t('shared.taboos.placeholder')}
            />
            <button type="button" style={{ display: 'none' }} />
          </div>
        )}
      </div>
    </div>
  );
};

MultiCheckbox.propTypes = {
  name: PropTypes.string,
  defaultEntities: PropTypes.array,
  register: PropTypes.func,
  buttonLabel: PropTypes.string,
  placeholder: PropTypes.string,
};

export default MultiCheckbox;
