import React from 'react'
import { prop, pipe, juxt, eq } from 'lodash/fp'
import { EntourageSubscriptionStatus } from 'shared'

import { getMonthDayYearDate } from 'utils/date'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetLink } from '../hooks/useGetLink'
import { useCancelRenewal } from '../hooks/useCancelRenewal'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'
import { Row } from '_legacy/common'
import Button from '_legacy/components/Button'
import DashButton from '_legacy/components/Button/DashButton'
import { ReactComponent as EntourageFullIcon } from '../icons/EntourageFullIcon.svg'


const FullStatus = () => {
  const t = useGetTranslate()
  const cancelRenewal = useCancelRenewal()
  const getLink = useGetLink()
  const [due, subscribed] = useUser(
    juxt([
      pipe(
        prop('user.entourageSubscription.due'),
        getMonthDayYearDate
      ),
      pipe(
        prop('user.entourageSubscription.status'),
        eq(EntourageSubscriptionStatus.ACTIVE)
      )
    ])
  )

  return (
    <Row
      nameBlock={t('account.entourage.full-status.title')}
      component={
        <div className="account-entourage">
          <div className="account-entourage__status-wrapper">
            <div className="account-entourage__status">
              <EntourageFullIcon className="account-entourage__status-icon" />
              <div className="account-entourage__status-info">
                <strong>{t('account.entourage.full-status.status')}</strong>
                <Trans
                  tKey="account.entourage.full-status.sub-status"
                  phs={[{ ph: '{{date}}', value: due }]}
                />
              </div>
            </div>

            {subscribed && (
              <Button text={t('account.entourage.full-status.cancel')} primary handleOnClick={cancelRenewal} />
            )}
          </div>

          <p className="account-entourage__text">{t('account.entourage.full-status.text')}</p>

          <p className="account-entourage__text">
            <Trans tKey='account.entourage.full-status.contact' />
          </p>

          <ul className="account-entourage__benefits-list">
            <li className="account-entourage__benefits-item">
              <Icon.OrderStatusCompleted />
              <div className="account-entourage__benefits-item-info">
                <div>{t('account.entourage.statuses.id-verification')}</div>
              </div>
            </li>

            <li className="account-entourage__benefits-item">
              <Icon.OrderStatusCompleted />
              <div className="account-entourage__benefits-item-info">
                <div>{t('account.entourage.statuses.background-reports')}</div>
                <DashButton text={t('account.entourage.full-status.view-reports')} onClick={getLink} />
              </div>
            </li>
          </ul>
        </div>
      }
    />
  )
}

export default FullStatus
