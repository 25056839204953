import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { startOfTomorrow } from 'date-fns'
import styled from 'styled-components';
import compose from 'lodash/fp/compose';
import map from 'lodash/map';
import get from 'lodash/get';

import { Input, Row } from '_legacy/common';
import DatePicker from '_legacy/common/DatePicker/DatePicker';
import Button from '_legacy/components/Button';
import HintButton from '_legacy/common/Buttons/HintButton.js';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent';
import Discount from '_legacy/containers/PromoCodes/components/Discount';
import Offerings from '_legacy/containers/PromoCodes/components/Offerings';
import Code from '_legacy/containers/PromoCodes/components/Codes';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import { useCreatePromoCodes } from 'requests/promoCodes/promo-codes';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { defaultValues } from '_legacy/containers/PromoCodes/utils/defaultValues';
import { maximumUsesOptions } from '_legacy/containers/PromoCodes/utils/maximumUsesOptions';
import submitAdapter from '_legacy/containers/PromoCodes/utils/submitAdapter';
import { useOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes';
import { PROMO_CODES_EXPIRATION_DATE_HIT, PROMO_CODES_MAXIMUM_OF_USES_HIT } from '_legacy/components/Modal/modals-types.js';
import { LIST, PROMO_CODES } from '_legacy/constants/routes';

const applicableOfferIdsName = 'applicableOfferIds'

const Form = () => {
  const t = useGetTranslate();
  const createPromoCodes = useCreatePromoCodes()
  const selectsOffering = useOfferingsPromoCodesFilters(state => get(state, 'selected', []))

  const methods = useForm({
    mode: 'onChange',
    defaultValues: defaultValues(t) ,
    shouldUnregister: false,
  })

  const applicableOfferIds = map(selectsOffering, 'id')

  React.useEffect(() => {
    methods.register(applicableOfferIdsName)
  }, [methods.register])

  React.useEffect(() => {
    methods.setValue(applicableOfferIdsName, applicableOfferIds)
  }, [selectsOffering])

  const onCreate = compose(createPromoCodes, submitAdapter)

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onCreate)}>
        <RequiredLabel />
        <Row
          isRequired
          nameBlock={t('promo-codes.form.promo-code-name.label')}
          component={
            <Input
              name='name'
              placeholder={t('promo-codes.form.promo-code-name.placeholder')}
              requiredMessage={t('promo-codes.form.promo-code-name.error.required')}
              validation={{ maxLength: { value: 70, message: t('promo-codes.form.promo-code-name.error.short') } }}
              isRequired
            />
          }
        />
        <Code name='code' />
        <Discount name='discountType' />
        <Offerings name='applyToAllOffers' />
        <Row
          nameBlock={t('promo-codes.form.expiration-date.label')}
          tip={<HintButton modalType={PROMO_CODES_EXPIRATION_DATE_HIT} />}
          component={
            <DatePicker
              name='expiresAt'
              placeholder={t(
                'promo-codes.form.expiration-date.placeholder'
              )}
              minDate={startOfTomorrow()}
              dateFormat={t('utils.date.format')}
              locale={t('utils.date.locale')}
            />
          }
        />
        <Row
          nameBlock={t('promo-codes.form.maximum-uses.label')}
          tip={<HintButton modalType={PROMO_CODES_MAXIMUM_OF_USES_HIT} />}
          component={
            <div>
              <SelectComponent
                name='limitOfUsers'
                options={maximumUsesOptions(t)}
                defaultValue={maximumUsesOptions(t)[0]}
              />
            </div>
          }
        />
        <Row
          component={
            <SubmitWrapper>
              <Button
                typeButton='submit'
                text={t('promo-codes.button.submit')}
                paddingVertical='13'
                paddingHorizontal='22'
                className='submit'
              />
              <Button
                primary
                text={t('promo-codes.button.cancel')}
                paddingVertical='13'
                paddingHorizontal='22'
                isLink
                path={`/${PROMO_CODES}/${LIST}`}
              />
            </SubmitWrapper>
          }
        />
        <MissedData />
      </StyledForm>
    </FormProvider>
  )
}

const StyledForm = styled.form`
  padding: 0 16px;
`

const SubmitWrapper = styled.div`
  display: flex;
  align-items: center;
  .submit {
    margin-right: 24px;
  }
  @media screen and (max-width: 768px) {
    justify-content: space-between;
    .submit {
      margin-right: 0;
    }
  }
  @media screen and (max-width: 425px) {
    flex-direction: column;
    .button {
      width: 100%;
    }
    .submit {
      margin-bottom: 16px;
    }
  }
`

export default Form;
