export const SEARCH = 'search';
export const DATEPICKER = 'datepicker';
export const OFFER_LOCATION_SELECT = 'offer-location-select'
export const TALENT_LOCATION_SELECT = 'talent-location-select'
export const RADIO = 'radio'
export const SELECT = 'select'
export const CHARITIES_SELECT = 'charities-select'
export const RANGE_DATEPICKER = 'range-datepicker'
export const CATEGORIES_SELECT = 'categories-select'

export const filters = {
  [SEARCH]: SEARCH,
  [DATEPICKER]: DATEPICKER,
  [OFFER_LOCATION_SELECT]: OFFER_LOCATION_SELECT,
  [TALENT_LOCATION_SELECT]: TALENT_LOCATION_SELECT,
  [RADIO]: RADIO,
  [SELECT]: SELECT,
  [CHARITIES_SELECT]: CHARITIES_SELECT,
  [RANGE_DATEPICKER]: RANGE_DATEPICKER,
  [CATEGORIES_SELECT]: CATEGORIES_SELECT,
}

import CustomInput from './CustomInput'
import CustomDatePicker from './DatePicker/DatePicker'
import OfferLocationSelect from './Selects/components/Async/LocationSelect/OfferLocationSelect'
import TalentLocationSelect from './Selects/components/Async/LocationSelect/TalentLocationSelect'
import CustomRadio from './CustomRadio/index'
import ReactSelect from './Selects/components/Select'
import CharitiesSelect from './Selects/components/Async/CharitiesSelect'
import CustomRangeDatePicker from './DatePicker/RangeDatePicker'
import CategoriesCustomSelect from './Selects/components/Async/CategoriesCustomSelect'

export const componentConfig = {
  [SEARCH]: CustomInput,
  [DATEPICKER]: CustomDatePicker,
  [OFFER_LOCATION_SELECT]: OfferLocationSelect,
  [TALENT_LOCATION_SELECT]: TalentLocationSelect,
  [RADIO]: CustomRadio,
  [SELECT]: ReactSelect,
  [CHARITIES_SELECT]: CharitiesSelect,
  [RANGE_DATEPICKER]: CustomRangeDatePicker,
  [CATEGORIES_SELECT]: CategoriesCustomSelect,
}
