// Modules
import React from 'react'
import { castArray, head } from 'lodash/fp'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { isChosenLocationOfferType } from 'utils/offer'
import { getLocationTimeZone } from 'utils/location'
import { getDateTimeText, applyTimezoneToDateTime } from '_legacy/components/Table/table-components/types/common/DateTime/utils'

// Legacy components
import TwoLineString from '_legacy/components/Table/table-components/types/common/TwoLineString'


const DateTime = ({ isLoading, dateTime, location, offerType }) => {
  const timeZone =
    isChosenLocationOfferType(offerType)
      ? getLocationTimeZone(head(location?.places) || head(castArray(location?.place)))
      : useUser(getUserTimeZone)

  const zonedDateTime = applyTimezoneToDateTime(dateTime, timeZone)
  const locale = useUserLocale()
  const dateTimeText = getDateTimeText(zonedDateTime, timeZone, locale)

  return (
    <TwoLineString
      isLoading={isLoading}
      mainText={dateTimeText?.mainText}
      subText={dateTimeText?.subText}
    />
  )
}

export default DateTime
