import React from 'react'
import { identity, prop, cond, T, F } from 'lodash/fp'

import { useModal } from 'store/hooks/useModal'

import {
  useTalentCalendarBusinessHours,
} from 'components/Calendar/TalentCalendar/state/store/useCalendar'
import { useFetchBusinessHours } from 'components/Calendar/TalentCalendar/state/requests/talent-calendar'

import WorkingHoursModal from 'components/Calendar/components/WorkingHoursModal'

const businessHoursAdapter = cond([
  [prop('daysOfWeek.length'), identity],
  [T, F]
])


export const withBusinessHours = CalendarComponent => ({
  talentId,
  openListOptions = [],
  ...restProps
}) => {
  const [isWorkingHoursModalOpen, openWorkingHoursModal, closeWorkingHoursModal] = useModal(false)

  const businessHours = useTalentCalendarBusinessHours()
  const fetchBusinessHours = useFetchBusinessHours(talentId)

  const openListOptionsWithBusinessHours = [...openListOptions,
    { label: 'calendar.dot-button.set-working-hours', cb: openWorkingHoursModal }
  ]

  React.useEffect(() => {
    fetchBusinessHours()
  }, [])

  React.useEffect(() => {
    fetchBusinessHours()
  }, [talentId])

  return (
    <React.Fragment>
      <CalendarComponent
        businessHours={businessHoursAdapter(businessHours)}
        openListOptions={openListOptionsWithBusinessHours}
        { ...restProps }
      />
      <WorkingHoursModal
        defaultData={businessHours}
        isOpen={isWorkingHoursModalOpen}
        closeModal={closeWorkingHoursModal}
      />
    </React.Fragment>
  )
}
