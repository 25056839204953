// Modules
import React from 'react'
import get from 'lodash/get'

// Store (hooks)
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

// Components
import ChatsPanel from './ChatsPanel'
import ActiveChat from './ActiveChat'


const Chats = () => {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 767)

  return (
    <div className="content-wrapper">
      <div className="chats__wrapper">
        <ChatsPanel isMobile={isMobile}/>

        <ActiveChat isMobile={isMobile}/>
      </div>
    </div>
  )
}

export default Chats
