import React from 'react';
import PropTypes from 'prop-types';

const NationalityIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 20 25"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M12.7446 5L9.03366 2.5H2.47396V0H0V25H2.47396V15H8.28405L11.995 17.5H19.7917V5H12.7446Z" />
    </svg>
  );
};

NationalityIcon.defaultProps = {
  className: '',
};

NationalityIcon.propTypes = {
  className: PropTypes.string,
};

export default NationalityIcon;
