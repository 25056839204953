import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { useAccountMenuOffest, useSetAccountMenuOffest } from 'store/hooks/globalState/useGlobalState'

import { calcOffset } from './utils';

const Pointer = ({
  type,
  itemRefs,
  listRef,
  currentIndex,
  isAnimate,
  isCalculate,
}) => {
  const offset = useAccountMenuOffest()
  const setOffset = useSetAccountMenuOffest((prev, next) => next)

  const ref = useRef();

  React.useEffect(() => {
    if (itemRefs && itemRefs[0].current && isCalculate) {
      const listWidth = listRef.current.clientWidth;
      const itemsWidths = itemRefs.map(r => r.current.clientWidth);
      const itemsWidthsTogether = itemsWidths.reduce((p, c) => p + c, 0);

      const gapWidth = (listWidth - itemsWidthsTogether) / (itemRefs.length - 1);
      const newOffset = calcOffset(
        ref.current.clientWidth,
        listWidth,
        itemsWidths,
        gapWidth,
        currentIndex
      );
      setOffset(newOffset)
    }
  }, [itemRefs, isCalculate, listRef, currentIndex, offset, setOffset])

  return (
    <div className="account-submenu-pointer" ref={ref}>
      <div
        className="account-submenu-pointer-line"
        attr-before="true"
        attr-is-animate={String(isAnimate)}
        style={{ width: offset }}
      />
      <div
        className="account-submenu-pointer-arrow"
        attr-reverse={String(type === 'upper')}
      />
      <div
        className="account-submenu-pointer-line"
        attr-after="true"
        attr-is-animate={String(isAnimate)}
      />
    </div>
  );
};

Pointer.propTypes = {
  type: PropTypes.oneOf(['lower', 'upper']).isRequired,
  itemRefs: PropTypes.array.isRequired,
  listRef: PropTypes.object.isRequired,
  currentIndex: PropTypes.number.isRequired,

  isAnimate: PropTypes.bool.isRequired,
  isCalculate: PropTypes.bool.isRequired,
};

export default Pointer;
