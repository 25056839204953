import React from 'react'
import prop from 'lodash/fp/prop'
import PropTypes from 'prop-types'

import { Row } from '_legacy/common'
import PaymentsSelect from '_legacy/components/PaymentsSelect'
import PaymentSettings from '_legacy/components/PaymentSettings'
import AccountTemplate from '_legacy/containers/Account/AccountTemplate'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { usePaymentCards } from 'store/hooks/globalState/usePaymentCards'
import { useFetchPaymentCard } from 'requests/payments'
import { isAficionado, isAgentWithTalent } from 'utils/user'
import TaxesAndSecuritySocialForm from './TaxesAndSecuritySocialForm'
import TalentTaxesAndSecuritySocialForm from './TalentTaxesAndSecuritySocialForm'
import { FormProvider, useForm } from 'react-hook-form'
import { defaultValues } from './utils/defaultValues'
import SubmitButton from '_legacy/components/RegisterForms/shared/components/SubmitButton'
import { usePostEditProfile } from 'requests/edit-profile'
import { useEditProfile } from 'store/hooks/globalState/useEditProfile'
import { useUser } from 'store/hooks/globalState/useUser';
import PageLoader from '_legacy/common/Template/PageLoader'
import { getTaxAdapter as getEditTaxAdapter } from '_legacy/components/RegisterForms/ProfileEdit/utils/getAdapter';
import { useFetchEditProfile } from 'requests/edit-profile';
import { getTalentId } from 'utils/user'

const Payments = ({ user }) => {
  const t = useGetTranslate()
  const paymentCards = usePaymentCards()
  const fetchPaymentCard = useFetchPaymentCard()
  const isStripeAccountReady = prop('talent.isStripeAccountReady', user)
  const isUserAficionado = isAficionado(user)
  const agentWithTalent = isAgentWithTalent(user)

  const withdrawalAccountTitle = isStripeAccountReady
    ? t('account.menu.payments.settings.label')
    : t('account.menu.payments.setup.label')

  React.useEffect(() => fetchPaymentCard(), [])

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
  })

  const editProfile = usePostEditProfile(methods)
  const profileLoading = useEditProfile(prop('loading'))
  const profileData = useEditProfile(prop('data'))

  const talentId = useUser(getTalentId)
  const fetchEditProfile = useFetchEditProfile()


  React.useEffect(() => {
    if (profileData) {
      const normalizedData = getEditTaxAdapter(profileData)
      methods.reset(normalizedData, { isDirty: false, dirtyFields: false })
    }
  }, [profileData])

  React.useEffect(() => {
    fetchEditProfile();
  }, [talentId])

  if (profileLoading && !profileData) return (
    <AccountTemplate title="account.menu.payments.title">
      <PageLoader />
    </AccountTemplate>
  )

  const onSubmit = values => editProfile(values, methods.formState.dirtyFields)

  return (
    <AccountTemplate title="account.menu.payments.title">
      <FormProvider {...methods}>
        {!isUserAficionado && isStripeAccountReady !== undefined && (
          <Row
            className="account-setting--row"
            nameBlock={withdrawalAccountTitle}
            component={<PaymentSettings isStripeAccountReady={isStripeAccountReady} />}
          />
        )}
        {!agentWithTalent && (
          <Row
            className="account-setting--row"
            nameBlock={t('account.menu.payments.methods.label')}
            component={<PaymentsSelect listCard={paymentCards} placeholder="account.menu.payments.methods.placeholder" />}
          />
        )}
        <form onSubmit={methods.handleSubmit(onSubmit)} className='white-background'>
          {!agentWithTalent && (
            <TaxesAndSecuritySocialForm />
          )}
          {agentWithTalent && (
            <TalentTaxesAndSecuritySocialForm />
          )}
          <SubmitButton
            text={t('shared.words.save')}
            paddingHorizontal={45}
            paddingVertical={13}
            disabled={!methods.formState.isDirty}
          />
        </form>
      </FormProvider>
    </AccountTemplate>
  )
}

Payments.propTypes = {
  user: PropTypes.shape({
    talent: PropTypes.shape({
      isStripeAccountReady: PropTypes.bool,
    }),
  }),
}

export default Payments
