import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import cond from 'lodash/cond';
import matches from 'lodash/matches';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export const Radio = React.memo(function Radio({ checked, onClick, name, value, defaultChecked, label, className, disabled, requiredMessage, isRequired }) {
  const t = useGetTranslate();
  const { register, errors } = useFormContext();
  const error = get(errors, name);

  const requiredText = t('shared.words.required');

  const errorMessageRequired = requiredMessage ? t(requiredMessage) : requiredText;

  return (
    <StyledRadio className={className} error={Boolean(error)}>
      <input
        type="radio"
        ref={register({ required: isRequired && errorMessageRequired })}
        name={name}
        value={value}
        defaultChecked={defaultChecked}
        onClick={onClick}
        checked={checked}
        disabled={disabled}
      />
      <i />
      <span>{t(label)}</span>
    </StyledRadio>
  );
});

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#000')]
]);

const StyledRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media only screen and (max-width: 425px) {
    width: 100%;
  }
  
  input {
    position: absolute;
    height: 1px;
    width: 1px ;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    
    &:checked + i:after {
      content: ' ';
      background: black;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
    }
  }

  i {
    position: relative;
    height: 26px;
    flex: 0 0 26px;
    width: 26px;
    min-width: 26px;
    margin-right: 8px;
    background: transparent;
    border: 2px solid ${getBorderColor};
    cursor: pointer;
    appearance: none;
    outline: none;
    border-radius: 50%;
  }

  span {
    color: ${getBorderColor};
    padding-right: 16px;
  }
`;


Radio.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]).isRequired,
  defaultChecked: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  requiredMessage: PropTypes.string,
  isRequired: PropTypes.bool,
};
