const sortFields = {
  originalName: 'table.header.name',
}

const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.header.file',
  pluralLabel: 'table.filters.files',
}

