import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import { identity } from 'lodash'

import { useEditReview, useLeaveReview } from 'requests/review'
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate, Trans } from 'store/hooks/globalState/useTranslates'

import StarRating from '_legacy/components/StarRating'
import ReviewModal from '_legacy/components/Modal/ReviewModal'

const forms = {
  RATING: 'rating',
  MODAL_RATING: 'modalRating',
  MODAL_DESCRIPTION: 'modalDescription',
}

const OfferingDetailsReviewStars = ({
  orderId,
  children,
  userLink,
  userFullName,
  offerType,
  reviewType,
  review,
  onSuccess = identity,
}) => {
  const t = useGetTranslate()

  const leaveReview = useLeaveReview(onSuccess)
  const editReview = useEditReview(onSuccess)

  const ratingMethods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
    defaultValues: {
      [forms.RATING]: '',
      [forms.MODAL_RATING]: null,
      [forms.MODAL_DESCRIPTION]: '',
    },
  })

  const [isOpenReviewModal, openReviewModal, closeReviewModal] = useModal(false)

  const onOpenReviewModal = React.useCallback(() => {
    ratingMethods.setValue(forms.MODAL_RATING, ratingMethods.getValues(forms.RATING))
    openReviewModal()
  }, [])

  const onCloseReviewModal = React.useCallback(() => {
    closeReviewModal()
    ratingMethods.setValue(forms.RATING, String(review?.rating))
  }, [])

  const onSubmitReviewForm = React.useCallback(({ modalRating: rating, modalDescription: description }) => {
    if (review) {
      editReview({ reviewId: review.id, rating, description })
    } else {
      leaveReview(orderId, { rating: Number(rating), description })
    }

    ratingMethods.setValue(forms.RATING, rating)
    closeReviewModal()
  }, [editReview, leaveReview, orderId])

  React.useEffect(() => {
    if (review) {
      ratingMethods.reset({
        rating: String(review.rating),
        modalRating: String(review.rating),
        modalDescription: String(review.description),
      })
    }
  }, [review])

  if (!userFullName) return null

  return (
    <div className="od__review--stars">
      <p className="od--main-text od--with-link">
        {`${t('order.answer.rate.text')} ${offerType} `}
        {children}
        <Trans
          tKey={reviewType}
          phs={[
            { ph: '{{userLink}}', value: userLink },
            { ph: '{{userName}}', value: userFullName },
          ]}
        />
      </p>

      {/* rating block */}
      <FormProvider {...ratingMethods}>
        <StarRating onClick={onOpenReviewModal} />
        <ReviewModal
          isOpen={isOpenReviewModal}
          onSubmit={onSubmitReviewForm}
          closeModal={onCloseReviewModal}
          userFullName={userFullName}
          userLink={userLink}
          offerType={offerType}
        />
      </FormProvider>
    </div>
  )
}

OfferingDetailsReviewStars.propTypes = {
  icon: PropTypes.node,
  item: PropTypes.string,
  subitem: PropTypes.string,
  TooltipComponent: PropTypes.node,
}

export default OfferingDetailsReviewStars
