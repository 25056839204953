import map from 'lodash/map'
import compact from 'lodash/compact'
import last from 'lodash/last'
import isEmpty from 'lodash/isEmpty'

import {
  getCategoriesAdapter,
  getFullCategoryAdapter,
  getSelectedCategoryId,
  getSelectedCategoryIds
} from '_legacy/components/CategoriesSelect/utils'


export const submitCategoriesAdapter = (categories) => {
  return categories ? compact(map(categories, getSelectedCategoryId)) : undefined
}

export const getCategoryAdapter = (allCategories, primaryCategory) => {
  return getCategoriesAdapter(
    (allCategories || []),
    last(getFullCategoryAdapter(primaryCategory))?.id
  )
}

export const getCategoryArrayAdapter = (allCategories, categories) => {
  return isEmpty(categories)
    ? [[]]
    : categories.map(c => getCategoryAdapter(allCategories, c)).filter(Boolean)
}


export const submitCategoryAdapter = (category) => {
  return isEmpty(category) ? null : getSelectedCategoryId(category)
}

export const submitCategoryArrayAdapter = (categories) => {
  return isEmpty(categories) ? null : getSelectedCategoryIds(categories).filter(Boolean)
}
