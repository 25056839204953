import React from 'react'

import LineString from '../common/LineString'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'


export default React.memo(function Name ({ isLoading, name }) {
  return (
    <PopperTooltip Component={name}>
      <LineString isLoading={isLoading} str={name} />
    </PopperTooltip>
  )
})
