import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import * as r from '_legacy/constants/routes'

import ExperienceOrderDetails from './ExperienceOrderDetails'
import VideoMessageOrderDetails from './VideoMessageOrderDetails'
import CommercialVideoMessageOrderDetails from './CommercialVideoMessageOrderDetails'
import VirtualEventOrderDetails from './VirtualEventOrderDetails'
import ReadyMadeEventLessonsDetails from './ReadyMadeEventLessonsDetails'
import VideoVoiceOverLessonOrderDetails from './VideoVoiceOverLessonOrderDetails/VideoVoiceOverLessonOrderDetails'
import VideoChatOrderDerails from './VideoChatOrderDetails'
import LiveInPersonLessonOrderDetails from './LiveInPersonLessonOrderDetails'
import LiveVirtualLessonOrderDetails from './LiveVirtualLessonOrderDetails'

const OfferingDetailsRouter = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/${r.VIDEO_MESSAGE}/${r.DETAILS}/:orderId`} component={VideoMessageOrderDetails} />
      <Route path={`${match.path}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.DETAILS}/:orderId`} component={CommercialVideoMessageOrderDetails} />
      <Route path={`${match.path}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}/:orderId`} component={VideoVoiceOverLessonOrderDetails} />
      <Route path={`${match.path}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/:orderId`} component={ReadyMadeEventLessonsDetails} />
      <Route path={`${match.path}/${r.VIDEO_CHAT}/${r.DETAILS}/:orderId`} component={VideoChatOrderDerails} />
      <Route path={`${match.path}/${r.EXPERIENCES}/${r.DETAILS}/:orderId`} component={ExperienceOrderDetails} />
      <Route path={`${match.path}/${r.LIVE_VIRTUAL_EVENTS}/${r.DETAILS}/:orderId`} component={VirtualEventOrderDetails} />
      <Route path={`${match.path}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}/:orderId`} component={LiveVirtualLessonOrderDetails} />
      <Route path={`${match.path}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}/:orderId`} component={LiveInPersonLessonOrderDetails} />
    </Switch>
  )
}

export default OfferingDetailsRouter
