import React from 'react'
import PropTypes from 'prop-types'

import Button from '_legacy/components/Button/BookNowButton'
import Price from '_legacy/components/Price'

const PublicPriceBlock = ({ text, price, className = '', buttonProps }) => (
  <div className={`public-view__price ${className}`}>
    <div className="price-wrapper">
      <p>{text}</p>
      <Price price={price} withCurrency />
    </div>
    <div className="button-wrapper">
      <Button
        paddingVertical="13"
        paddingHorizontal="26"
        {...buttonProps}
      />
    </div>
  </div>
)
PublicPriceBlock.propTypes = {
  text: PropTypes.string,
  price: PropTypes.string,
  className: PropTypes.string,
  buttonProps: PropTypes.object,
}

export default PublicPriceBlock
