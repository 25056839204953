import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import ErrorMessage from '_legacy/components/Form/ErrorMessages';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  .wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-width: ${({ imgSrc }) => !imgSrc && '2px'};
    border-style: solid;
    border-color: ${({ error }) => !error ? '#BABBCD' : 'red'};
    outline: none;
    cursor: pointer;
    border-radius: ${({ round }) => round ? '50%' : '0'};
    margin-bottom: ${({ desc }) => desc ? '8px' : '0'};
    &:hover {
      border-color: #272835;
    }
    
    .title {
      font-family: 'Montserrat', sans-serif;
      font-weight: 700;
      font-size: 16px;
      line-height: 1.625;
      color: #272835;
      margin-top: 16px;
      margin-bottom: 0;
      text-align: center;
    }

    .error-message-container {
      top: -30px;
      left: 0;
    }
  }


  .description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    color: #9495A7;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0;
    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }
`;

const StyledWrapperImage = styled.div`
  border-radius: ${({ round }) => round ? '50%' : '0'};
  position: relative;
  width: 100%;
  height: 100%;
  margin: -2px;
  overflow: hidden;
  background-color: #E9EAF0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0.8) 67.71%);
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }
  &:hover {
    &:before,
    button {
      opacity: 1;
      visibility: visible;
    }
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: ${({ round }) => round ? '50%' : '0'};
  }
  button {
    position: absolute;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    z-index: 1;
    background-color: #fff;
    border: 2px solid #272835;
    border-radius: 4px;
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 12px;
    line-height: 1.4;
    color: #272835;
    padding: 5px 8px;
    cursor: pointer;
    opacity: 0;
    transition: opacity .3s ease-in-out;
  }
`;

const Image = ({ round, imgSrc }) => {
  const t = useGetTranslate();
  return (
    <StyledWrapperImage round={round}>
      <img src={imgSrc} alt='img' />
      <button type='button'>{t('register.upload.hint.button.change')}</button>
    </StyledWrapperImage>
  );
};

const Tip = ({ iconComponent, title, text }) => {
  return (
    <>
      {iconComponent}
      {title && <h4 className='title'>{title}</h4>}
      {text && <p className='description'>{text}</p>}
    </>
  );
};

export const UploadHint = ({ title, text, desc, round, className, iconComponent, imgSrc, error }) => (
  <StyledWrapper {...{ round, imgSrc, error }} className={className}>
    <div className='wrapper'>
      {imgSrc
        ? <Image round={round} imgSrc={imgSrc} />
        : <Tip iconComponent={iconComponent} title={title} text={text} />
      }

      {error && <ErrorMessage message={error} />}
    </div>
    { desc && <p className='description'>{desc}</p>}
  </StyledWrapper>
);

UploadHint.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ]),
  text: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ]),
  desc: PropTypes.oneOfType([
    PropTypes.object, PropTypes.string
  ]),
  round: PropTypes.bool,
  className: PropTypes.string,
  imgSrc: PropTypes.string,
  error: PropTypes.string,
  iconComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.node,
    PropTypes.element,
    PropTypes.elementType,
  ]),
};

