import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import MediaViewer from '_legacy/containers/PromoCodes/components/Custom/MediaViewer';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import OfferingCheck from '_legacy/containers/PromoCodes/components/Custom/OfferingCheck';
import { offeringsInfo } from '_legacy/containers/PromoCodes/utils/offeringsTypesOptions';
import { toMoneyWithComa } from 'utils/moneyTransformator';

import { StyledInfo, StyledImage, StyledPrice, StyledRow, StyledTitle, StyledName } from '_legacy/containers/PromoCodes/components/Custom/rowStyled';

const OfferingItem = ({ offering, bulkActionsConfig }) => {
  const t = useGetTranslate();
  const offeringType = get(offering, 'type')
  const { title, label, price, Icon, media } = offeringsInfo(offering)[offeringType];

  const offeringId = get(offering, 'id', 0);

  let isSelected = false;

  if (bulkActionsConfig) {
    isSelected = bulkActionsConfig
      .getFilters(current => get(current, 'selected', [])
        .find(item => item.id === offeringId), [offeringId]);
  }

  return (
    <StyledRow isSelected={isSelected}>
      <StyledInfo>
        <StyledImage>
          <MediaViewer file={media} />
        </StyledImage>
        <div>
          <StyledTitle>
            {title}
          </StyledTitle>
          <StyledName><Icon /> {t(label)}</StyledName>
        </div>
      </StyledInfo>
      <StyledPrice>
        $ {toMoneyWithComa(price)}
      </StyledPrice>
      <OfferingCheck
        offering={offering}
        bulkActionsConfig={bulkActionsConfig}
      />
    </StyledRow>
  );
};

OfferingItem.propTypes = {
  offering: PropTypes.object.isRequired,
  bulkActionsConfig: PropTypes.object.isRequired,
};

export default OfferingItem;

