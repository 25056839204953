import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { FileType } from 'shared'

import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFileEntityById } from 'store/hooks/globalState/useFileEntites'
import { useUploadVideo, useSubmitAnswer } from '../state/requests'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import SingleSelectLibraryModal from '_legacy/components/Library/SingleSelectLibrary/components/SingleSelectLibraryModal'
import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import CancelOrderButton from '../../../components/CancelOrderButton'

const mediaKey = 'order-media'
const tempMediaKey = 'temp-order-media'

const UploadVideoVoiceMessageBlock = ({ uploadedFileId, date, offerType, orderId, onSuccessCancel }) => {
  const [isModalOpen, openModal, closeModal] = useModal(false)

  const t = useGetTranslate()
  const uploadVideo = useUploadVideo()
  const submitAnswer = useSubmitAnswer()

  const methods = useForm({
    shouldUnregister: false,
    defaultValues: {
      [mediaKey]: uploadedFileId,
      [tempMediaKey]: uploadedFileId,
    },
  })

  const fileId = methods.watch(mediaKey)
  const tempFileId = methods.watch(tempMediaKey)
  const media = useFileEntityById(fileId)

  const closeModalHandler = () => {
    methods.setValue(tempMediaKey, methods.getValues(mediaKey))
    closeModal()
  }

  const openModalHandler = () => {
    methods.setValue(tempMediaKey, methods.getValues(mediaKey))
    openModal()
  }

  const modalSubmitHandler = () => {
    closeModal()
    methods.setValue(mediaKey, methods.getValues(tempMediaKey))
    uploadVideo({ fileToken: tempFileId })
  }

  return (
    <FormProvider {...methods}>
      <div className="od--shadow-box od--centered od--with-padding">
        <h3 className="od--uppercase-title mb-24">{offerType}</h3>

        {fileId && (
          <div className="od__answer-grid mb-24">
            <FilePreview file={media} className="od__answer-media" />
            <div className="od__column">
              <p className="od--main-text od--stick-to-bottom">{t('order.answer.label.submit-or-upload')}</p>
              <Button
                stretch
                text={t('order.answer.buttons.submit')}
                className="mb-16"
                fontSize={0.8125}
                handleOnClick={submitAnswer}
              />
              <Button
                primary
                stretch
                fontSize={0.8125}
                text={t('order.answer.buttons.upload-new-video')}
                handleOnClick={openModalHandler}
              />
            </div>
          </div>
        )}

        {!fileId && (
          <div className="od__column">
            <h3 className="od--status-small-title mb-16">{date}</h3>
            <div className="mb-16">
              <Icon.UploadVideo />
            </div>
            <p className="od--main-text mb-4">{t('order.answer.label.upload-or-drag')}</p>
            <p className="od--small-text mb-24">{t('order.answer.label.requirements')}</p>
            <Button
              stretch
              text={t('order.answer.buttons.upload-video')}
              paddingVertical={16}
              handleOnClick={openModal}
              className="od__answer__upload-btn mb-16"
            />
          </div>
        )}

        <div className="od__column">
          <CancelOrderButton orderId={orderId} onSuccess={onSuccessCancel} />
        </div>
      </div>

      <SingleSelectLibraryModal
        name={tempMediaKey}
        isOpen={isModalOpen}
        onSubmit={modalSubmitHandler}
        closeModal={closeModalHandler}
        dropZoneProps={{ accept: FileType.VIDEO, maxSize: 1000 }}
        libraryModalProps={{ enableTypes: FileType.VIDEO }}
      />
    </FormProvider>
  )
}

export default UploadVideoVoiceMessageBlock
