import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const ActiveChatIsEmpty = () => {
  const t = useGetTranslate()

  return (
    <div className="messages-list-is-empty">
      <p>{t('chats.chat.messages-list-is-empty')}</p>
    </div>
  )
}

export default ActiveChatIsEmpty
