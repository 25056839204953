import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { calcX, calcY } from './config';
import throttle from 'lodash/throttle';

const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

const Tooltip = React.memo(function Tooltip({
  tooltipElement,
  tooltipClassName = '',
  disable,
  children,
  className = '',
  style,
  offset = 0
}) {
  const [cords, setCords] = React.useState({ x: 0, y: 0 });
  const [visible, setVisible] = React.useState(false);

  const tooltipRef = React.useRef(null);
  const elRef = React.useRef(null);
  const tooltipRootRef = React.useRef(null);
  const childRef = React.useRef(null);

  const handleScroll = React.useCallback(throttle(() => setVisible(false), 1000), []);

  React.useEffect(() => {
    window.addEventListener(wheelEvent, handleScroll, { passive: false });
    return () =>  window.removeEventListener(wheelEvent, handleScroll);
  }, []);

  React.useEffect(() => {
    const tooltipRoot = document.getElementById('tooltip-root');
    elRef.current = document.createElement('div');

    tooltipRootRef.current = tooltipRoot;

    tooltipRootRef.current.appendChild(elRef.current);

    return () => {
      tooltipRootRef.current.removeChild(elRef.current);
    };
  }, []);

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleMouseMove = ({ clientX, clientY }) => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const x = calcX(clientX, windowWidth, offset);
    const y = calcY(clientY, windowHeight, offset);
    setCords({ x, y });
  };

  const tooltip = (
    <StyledTooltipWrapper
      ref={tooltipRef}
      style={{ top: cords.y - 15, left: cords.x + 25, }}
      className={tooltipClassName}
    >
      {tooltipElement}
    </StyledTooltipWrapper>
  );

  if (disable) return children;

  const mouseMoveHandler = () => {
    if (!childRef.current) return;

    const { offsetHeight, scrollHeight, offsetWidth, scrollWidth, attributes } = childRef.current;

    if ((offsetHeight < scrollHeight || offsetWidth < scrollWidth) && attributes) setVisible(true);
  };

  return (
    <>
      {(visible) && createPortal(tooltip, elRef.current)}
      <StyledTooltip
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        className={className}
        style={style}
      >
        {React.cloneElement(children, {
          onMouseMove: mouseMoveHandler,
          ref: childRef,
        })}
      </StyledTooltip>
    </>
  );
});

const StyledTooltip = styled.div``;

const StyledTooltipWrapper = styled.div`
  position: fixed;
  pointer-events: none;
  font-size: 16px;
  color: ${({ theme: { colors } }) => colors.white};
  line-height: 1.375;
  background-color: ${({ theme: { colors } }) => colors.grey};
  border-radius: 4px;
  filter: drop-shadow(0 2px 12px rgba(0, 0, 0, 0.3));
  padding: 8px 16px;
  max-width: 220px;
  width: 100%;
  min-height: 50px;
  word-break: break-all;
  z-index: 999;
  transform: translate3d(0,0, 10px);
  &:before {
    content: '';
    position: absolute;
    top: 15px;
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid ${({ theme: { colors } }) => colors.grey};
  }
`;

Tooltip.propTypes = {
  disable: PropTypes.bool,
  offset: PropTypes.number,
  tooltipElement: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
  className: PropTypes.string,
  tooltipClassName: PropTypes.string,
  style: PropTypes.object,
};

export default Tooltip;
