// Modules
import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { TalentOffersType } from 'shared'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { isChosenLocationOfferType } from 'utils/offer'
import { getLocationTimeZone } from 'utils/location'
import { getMonthDate } from 'utils/date'
import { parseDate } from 'utils/services/timezone'

// Legacy components
import Tooltip from '_legacy/components/Tooltip/index.js'
import OfferingTypesIcon from 'assets/icons/OfferingTypesIcon'
import FollowButton from '_legacy/components/Button/FollowButton'
import CustomMedia from '_legacy/common/CustomMedia'

import './index.scss'
import { getPublicPageRoute } from 'router/routesMap'

const {
  VIDEO_CHAT,
  VIDEO_MESSAGE,
  IN_PERSON_EXPERIENCE,
  VIDEO_VOICE_OVER_LESSON,
  LIVE_IN_PERSON_LESSON,
  VIRTUAL_LESSON,
  VIRTUAL_EVENT,
  READY_MADE_LESSON,
  FOR_COMMERCIAL_USE,
} = TalentOffersType

const typesOfferings = {
  [VIDEO_CHAT]: OfferingTypesIcon.VideoChat,
  [VIDEO_MESSAGE]: OfferingTypesIcon.VideoMessages,
  [IN_PERSON_EXPERIENCE]: OfferingTypesIcon.InPersonExperiences,
  [VIDEO_VOICE_OVER_LESSON]: OfferingTypesIcon.Lessons,
  [LIVE_IN_PERSON_LESSON]: OfferingTypesIcon.Lessons,
  [VIRTUAL_LESSON]: OfferingTypesIcon.Lessons,
  [VIRTUAL_EVENT]: OfferingTypesIcon.VirtualEvent,
  [READY_MADE_LESSON]: OfferingTypesIcon.ReadyMadeEvents,
  [FOR_COMMERCIAL_USE]: OfferingTypesIcon.CommercialVideoMessage,
}


const SearchOfferingCard = ({ value = {} }) => {
  const {
    id,
    offerType,
    cardImage,
    name,
    startTime: _startTime,
    sequentialId,
    location,
    talent: {
      plaqueNickname,
      firstName,
      lastName,
    },
    multipleDates,
    currentUserPersonalData,
  } = value

  const timeZone =
    isChosenLocationOfferType(offerType)
      ? getLocationTimeZone(location)
      : useUser(getUserTimeZone)

  const startTime = parseDate(_startTime, timeZone)
  const formattedStartTime = getMonthDate(startTime)

  const offeringLink = getPublicPageRoute(offerType, plaqueNickname, sequentialId)
  const IconOffering = get(typesOfferings, offerType)

  return (
    <Link to={offeringLink} className='search-card'>
      <div className='search-card__image'>
        <CustomMedia file={cardImage?.file} />
      </div>

      <div className='search-card__top'>
        <IconOffering className='search-card__offering' />
        <FollowButton id={id} isFollowed={currentUserPersonalData?.isFollowed} type='OFFERING' className='search-card__save simple dark' />
      </div>
      <div className='search-card__content'>
        <div className='search-card__fullName'>
          <p className='search-card__fullName_name'>{firstName}</p>
          <p className='search-card__fullName_lastname'>{lastName}</p>
        </div>
        <Tooltip tooltipElement={name}>
          <div className='search-card__offers'>{name}</div>
        </Tooltip>
        {(!multipleDates && startTime) && (
          <div className='search-card__date'>
            {formattedStartTime}
          </div>)}
      </div>
    </Link>
  )
}

SearchOfferingCard.propTypes = {
  value: PropTypes.object.isRequired,
}

export default SearchOfferingCard
