import { _get, _post } from 'api/utils'

export const checkAbilityToStartBackgroundVerification = () =>
  _get('/integrations/sterling/start-verification/can-start-verification', { needAuthorization: true })

export const startBackgroundVerification = () =>
  _post('/integrations/sterling/start-verification/', {
    needAuthorization: true,
    body: {
      rescreening: false,
    },
  })
