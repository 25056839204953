import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import './blockWithBorderS.scss'

const BlockWithBorder = React.memo(function BlockWithBorder({ title, className = '', children, titleComponent }) {
  const t = useGetTranslate();

  return (
    <div className={`block-width-border ${className}`}>
      <div className='top'>
        <div className='line' />
        <h4 className='title'>{titleComponent || t(title)}</h4>{/* todo: refactor without t */}
        <div className='line' />
      </div>
      <div className='content'>{children}</div>
    </div>
  );
});

BlockWithBorder.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  titleComponent: PropTypes.element,
};

export default BlockWithBorder;
