import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Row } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { LIST, PROMO_CODES } from '_legacy/constants/routes';

const OfferingsLinkToManagerPromoCodes = () => {
  const t = useGetTranslate();

  return (
    <Row
      component={
        <TextS>
          {t('offerings.manage-promo-codes.text')}&nbsp;
          <LinkS to={`/${PROMO_CODES}/${LIST}`}>{t('offerings.manage-promo-codes.link')}</LinkS>
        </TextS>}
    />
  );
};

const TextS = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.625;
  color: ${({ theme }) => theme.colors.grey}
`;
const LinkS = styled(Link)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.blue};
  &:hover {
    text-decoration: underline;
  }
`;

export default OfferingsLinkToManagerPromoCodes;
