import React from 'react'
import { propOr, values, compose, filter, propEq, cond, always, T, prop, orderBy } from 'lodash/fp'

import useScroll from 'store/hooks/useScroll'
import { useFetchUnreadNotifications } from 'requests/notifications'
import { useNotificationsMenuFilters, useNotifications } from 'store/hooks/globalState/useNotificationsState'
import { useUser } from 'store/hooks/globalState/useUser'

import { notificationConfig } from '_legacy/components/Notification'
import LoaderIndicator from '_legacy/components/Common/Loader'
import { getTalentId, isAficionado, isAgentWithoutTalent } from 'utils/user'

const NotificationList = () => {
  const parentRef = React.useRef()
  const childRef = React.useRef()

  const user = useUser()
  const talentId = getTalentId(user)
  const aficionado = isAficionado(user)
  const agentWithoutTalent = isAgentWithoutTalent(user)

  const unreadNotifications = useNotifications(
    compose(
      orderBy(prop('createdAt'), 'desc'),
      filter(
        cond([
          [propEq('receiverTalent.id', talentId), T],
          [always(agentWithoutTalent), T],
          [always(aficionado), T],
        ])
      ),
      filter(propEq('isRead', false)),
      values
    ), [user]
  )

  const fetchNotifications = useFetchUnreadNotifications({ isMerge: true })
  const page = useNotificationsMenuFilters(propOr(0, 'page'))
  const remains = useNotificationsMenuFilters(propOr(0, 'remains'))
  const fetchNext = () => fetchNotifications({ page: page + 1 })

  useScroll({
    parentRef,
    childRef,
    callback: fetchNext,
    hasMore: remains > 0,
  })

  return (
    <ul className="notification-list" ref={parentRef}>
      {unreadNotifications.map(notification => {
        const Component = notificationConfig(notification)
        return <Component key={notification.id} {...notification} isMenu />
      })}
      {remains > 0 && (
        <div ref={childRef} className="notification-list__loader">
          <LoaderIndicator size={24} fill="#636583" />
        </div>
      )}
    </ul>
  )
}

export default NotificationList
