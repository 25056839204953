import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Types } from 'shared/dist/src/types/file';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import CustomPicture from '_legacy/common/CustomPicture'
import CustomVideo from '_legacy/common/custom-video/CustomVideo'

import SubModalPortal from 'portals/SubModalPortal'

const ConfirmRemoveItemModal = ({ file, isModalOpen, closeModal, onAgreeClick, onDisagreeClick }) => {
  const t = useGetTranslate()
  const fileRef = useRef();
  const [isHorizontal, setIsHorizontal] = useState(false);

  const onImageLoad = file => {
    setIsHorizontal(file.naturalHeight <= file.naturalWidth);
  };

  const onVideoLoad = e => {
    setIsHorizontal(e.target.videoHeight <= e.target.videoWidth);
  };

  const content = file.type === Types.PHOTO
    ? <CustomPicture
      className={`
          remove-file__image
          remove-file--${isHorizontal ? 'horizontal' : 'vertical'}
        `}
      imgRef={fileRef}
      file={file}
      onLoad={() => onImageLoad(fileRef.current)}
    />
    : <CustomVideo
      file={file}
      className={`
          remove-file__video
          remove-file--${isHorizontal ? 'horizontal' : 'vertical'}
        `}
      controls
      onLoadedMetadata={(e) => onVideoLoad(e)}
    />;

  const disabledAgreeButton = file.linksCount > 1;

  return (
    <SubModalPortal isOpen={isModalOpen} onClose={closeModal} showCloseButton>
      <div className="remove-item-modal modal">
        <h1 className="remove-item-modal__header modal-header">{t('remove-item-modal.header')}</h1>
        <div className="remove-item-modal__file">
          <div className="remove-file">
            {content}
          </div>
        </div>
        <div className="remove-item-modal__content">{file.originalName}</div>
        <div className="remove-item-modal__button-container">
          <button
            className="remove-item-modal-button remove-item-agree"
            onClick={onAgreeClick}
            disabled={disabledAgreeButton}
          >
            {t('shared.words.label.yes')}
          </button>
          <button
            className="remove-item-modal-button remove-item-disagree"
            onClick={onDisagreeClick}
          >
            {t('shared.words.label.no')}
          </button>
        </div>
      </div>
    </SubModalPortal>
  );
};
ConfirmRemoveItemModal.propTypes = {
  file: PropTypes.object,
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onAgreeClick: PropTypes.func,
  onDisagreeClick: PropTypes.func,
};
export default ConfirmRemoveItemModal;
