import styled from 'styled-components'

const OfferingDescription = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 21px;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.grey};
  text-align: center;

  max-width: 980px;
  margin: 0 auto 48px;
`

export default OfferingDescription
