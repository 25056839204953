import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import compose from 'lodash/fp/compose'
import styled from 'styled-components';
import { RemoveAccountComplianceRequestType } from 'shared';

import RemoveAccountForm from '_legacy/components/RemoveAccountForm';

import AccountTemplate from '_legacy/containers/Account/AccountTemplate';
import { useRemoveAccount } from 'requests/account/remove';
import removeSubmitAdapter from './removeSubmitAdapter';

const { OTHER } = RemoveAccountComplianceRequestType;

const RemoveAccount = () => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      complianceRequestType: OTHER,
      customComplianceRequestType: '',
      password: '',
      confirm: false
    }
  })

  const removeAccount = useRemoveAccount();

  const onSubmit = compose(removeAccount, removeSubmitAdapter)

  return (
    <AccountTemplate>
      <FormProvider {...methods}>
        <StyledForm onSubmit={methods.handleSubmit(onSubmit)}>
          <RemoveAccountForm />
        </StyledForm>
      </FormProvider>
    </AccountTemplate>
  );
};

const StyledForm = styled.form`
  padding: 0 16px;
  @media screen and (max-width: 1024px) {
    margin-top: 30px;
    margin-bottom: 130px;
  }
`


export default RemoveAccount;
