import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useForm, FormProvider, useWatch } from 'react-hook-form'
import { addMinutes, set, subMinutes } from 'date-fns'

import { getMilitaryTime, militaryTimeToDate } from 'utils/date'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUpdateCalendarSettings } from 'components/Calendar/TalentCalendar/state/requests/calendar-settings'

import Label from 'components/forms/Label'
import DaysList from 'components/forms/DaysList'
import Button from '_legacy/components/Button'
import TimePicker from '_legacy/common/DatePicker/TimePicker'
import ModalPortal from 'portals/ModalPortal'

const submitAdapter = ({ on, from, to }) => {
  if (on.length) {
    return {
      on: on.map(Number),
      to: getMilitaryTime(to),
      from: getMilitaryTime(from),
    }
  }
  return {
    on: null,
    to: getMilitaryTime(to),
    from: getMilitaryTime(from),
  }
}

const getAdapter = data => {
  return {
    to: militaryTimeToDate(get(data, 'endTime', '')),
    from: militaryTimeToDate(get(data, 'startTime', '')),
    on: (get(data, 'daysOfWeek') || []).map(String),
  }
}

const WorkingHoursModal = ({ isOpen, closeModal, defaultData }) => {
  const t = useGetTranslate()
  const updateCalendarSettings = useUpdateCalendarSettings()

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: getAdapter(defaultData),
  })
  const isOnExist = !!useWatch({ name: 'on', control: methods.control, defaultValue: [] }).length
  const from = useWatch({ name: 'from', control: methods.control })
  const to = useWatch({ name: 'to', control: methods.control })

  const isEnableButton = isOnExist ? from && to : true

  const onSubmit = React.useCallback(() => {
    methods.handleSubmit(workingHours =>
      updateCalendarSettings({
        calendarSettings: {
          workingHours: submitAdapter(workingHours),
        },
      })
    )()
    closeModal()
  }, [])

  React.useEffect(() => {
    methods.reset(getAdapter(defaultData))
  }, [defaultData])

  return (
    <ModalPortal isOpen={isOpen} onClose={closeModal} modalClassName={'working-hours-modal__wrapper'} isMobileFullScreen showCloseButton>
      <div className="working-hours-modal">
        <h1 className="modal-header">{t('working-hours-modal.header')}</h1>
        <p className="modal-text">{t('working-hours-modal.subheader')}</p>

        <FormProvider {...methods}>
          <form className="working-hours-form" onSubmit={onSubmit}>
            <div className="working-hours-form__form-group">
              <Label>
                {t('working-hours-modal.form.on.label')}
                <DaysList name="on" />
              </Label>
            </div>

            <div className="working-hours-form__form-group working-hours-form__form-group--two-col">
              <div>
                <Label>{t('working-hours-modal.form.from.label')}</Label>
                <TimePicker
                  name="from"
                  className='react-datepicker--without-shadow'
                  disabled={!isOnExist}
                  minTime={to && set(new Date(), { minutes: 0, hours: 0 })}
                  maxTime={to && subMinutes(new Date(to), 15)}
                  placeholder={t('working-hours-modal.form.from.placeholder')}
                />
              </div>

              <div>
                <Label>{t('working-hours-modal.form.to.label')} </Label>
                <TimePicker
                  name="to"
                  disabled={!isOnExist}
                  minTime={from && addMinutes(new Date(from), 15)}
                  maxTime={from && set(new Date(), { minutes: 59, hours: 23 })}
                  placeholder={t('working-hours-modal.form.to.placeholder')}
                />
              </div>
            </div>

            <Button
              stretch
              typeButton="submit"
              paddingVertical={16}
              disabled={!isEnableButton}
              text={t('working-hours-modal.form.submit-button')}
            />
          </form>
        </FormProvider>
      </div>
    </ModalPortal>
  )
}

WorkingHoursModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  defaultData: PropTypes.shape({
    to: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    from: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    on: PropTypes.arrayOf(PropTypes.number),
  }),
}

export default WorkingHoursModal
