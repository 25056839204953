// Modules
import React from 'react'
import get from 'lodash/get'
import T from 'lodash/fp/T'
import F from 'lodash/fp/F'
import cond from 'lodash/fp/cond'
import where from 'lodash/fp/where'
import equals from 'lodash/fp/equals'
import complement from 'lodash/fp/complement'
import propEq from 'lodash/fp/propEq'
import { TalentOfferPendingActionType, TalentOffersType } from 'shared'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getUserTimeZone } from 'utils/user'
import { getMonthDayOnly } from 'utils/date'
import { parseDate } from 'utils/services/timezone'

// Legacy components
import TwoLineString from '_legacy/components/Table/table-components/types/common/TwoLineString'

// Constants
import { currentActionStatus } from './config'

const isShowDueDate = cond([
  [
    where({
      pendingAction: propEq('type', TalentOfferPendingActionType.WAIT_FOR_PAYMENT)
    }),
    F
  ],
  [
    where({
      date: Boolean,
      type: complement(equals(TalentOffersType.READY_MADE_LESSON))
    }),
    T
  ],
])


const CurrentPendingActions = React.memo(({ isLoading, type, pendingAction }) => {
  const t = useGetTranslate()
  const timeZone = useUser(getUserTimeZone)

  const typeAction = get(pendingAction, 'type')
  const reviewRequestsCount = get(pendingAction, 'reviewRequestsCount', '')
  const status = get(currentActionStatus, [type, typeAction], 'my-orders.current-action.status.none')
  const currentStatus = `${t(status)}${reviewRequestsCount && `: ${reviewRequestsCount}`}`

  const due = get(pendingAction, 'due')
  const date = due && getMonthDayOnly(parseDate(due, timeZone))
  const showDueDate = isShowDueDate({ date, type, pendingAction })
  const currentDate = showDueDate ? `${t('my-orders.current-action.due')} ${date}` : ''

  return (
    <TwoLineString
      isLoading={isLoading}
      mainText={currentStatus}
      subText={currentDate}
    />
  )
})

export default CurrentPendingActions
