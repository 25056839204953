import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

const PRODUCT_FILES_FILTERS = 'productFilesFilters'
const PRODUCT_FILES_TABLE = 'productFilesTable'

export const useProductFilesFilters = createUseGlobalState(PRODUCT_FILES_FILTERS)
export const useSetProductFilesFilters = createUseSetGlobalState(PRODUCT_FILES_FILTERS)

export const useProductFilesTable = createUseGlobalState(PRODUCT_FILES_TABLE)
export const useSetProductFilesTable = createUseSetGlobalState(PRODUCT_FILES_TABLE)
