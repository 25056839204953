export const counterParticipantsConfig = {
  field: 'count',
  label: 'table.filters.participant',
  pluralLabel: 'table.filters.participants'
}

export const defaultConfig = [
  {
    field: 'fields'
  },
  {
    field: 'relations'
  },
  {
    field: 'experienceIdIn'
  }
]

export const detailsConfig = {
  fields: ['id', 'recurring', 'occurrencesLimit', 'name', 'daysTimesType', 'timeWindows', 'maxNumberOfParticipants'],
  relations: ['photo', 'video', 'talent', 'whichCharities', 'otherTalentParticipating']
}

export const fields = [
  ...defaultConfig.map(({ field }) => field),
]

