import React from 'react'

import OfferingDetailsGenericStatus from '../OfferingDetailsGenericStatus'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import * as r from '_legacy/constants/routes'

const CancelStatusWithContact = ({ cancelText, refundText, title }) => {
  const t = useGetTranslate()
  return (
    <OfferingDetailsGenericStatus
      title={title}
      type={t('order.header.cancelled')}
      reasonText={cancelText}
    >
      <p className="od--small-text od--with-link">
        <Trans tKey="order.description.canceled" phs={[{ ph: '{{contact-link}}', value: `/${r.CONTACT}` }]} />
      </p>
      {refundText && <p>{refundText}</p>}
    </OfferingDetailsGenericStatus>
  )
}

export default CancelStatusWithContact
