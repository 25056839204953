// TODO: Create a separate cascader list component to match DRY
// Modules
import React from 'react'
import last from 'lodash/last'
import assign from 'lodash/assign'

// Routing
import * as r from '_legacy/constants/routes'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useTalentCategories } from 'store/hooks/globalState/useCategories'
import { useSetSearchListFilters } from 'store/hooks/globalState/useSearchList'

// Utils
import { history } from 'utils/history'
import { closeOverlay } from '../../utils'
import { checkSubitems } from '_legacy/components/CategoriesSelect/utils'
import { mapper, getLastCategoryTitle, getLastCategoryId } from '_legacy/components/CategoriesSelect/utils'

// Components
import BottomNavigationOverlay from '../../BottomNavigationOverlay'


const Talent = () => {
  const [talentSelectValue, setTalentSelectValue] = React.useState([])

  const t = useGetTranslate()
  const setTalentSearchFilters = useSetSearchListFilters(assign)

  const currentItems = useTalentCategories(
    state => mapper(
      state,
      talentSelectValue.map(v => v.i),
      talentSelectValue.length,
    ),
    [talentSelectValue]
  )

  const prevItems = useTalentCategories(
    state => mapper(
      state,
      talentSelectValue.slice(0, talentSelectValue?.length - 1).map(v => v.i),
      talentSelectValue.length - 1,
    ),
    [talentSelectValue]
  )

  const itemsToRender = currentItems?.length ? currentItems : prevItems || []

  const setCategoryAndRedirect = state => {
    setTalentSearchFilters({ categoryIn: state })
    closeOverlay()
    history.push(`/${r.SEARCH}/${r.TALENT}`)
  }

  const onBack = () => {
    setTalentSelectValue([
      ...(talentSelectValue || [])
        .slice(
          0,
          currentItems.length
            ? talentSelectValue.length - 1
            : talentSelectValue.length - 2
        )
    ])
  }

  const onSelect = (category, e) => {
    e.stopPropagation()

    const newState = [...(talentSelectValue || []), category]
    setTalentSelectValue(newState)

    if (!checkSubitems(last(newState))) {
      setCategoryAndRedirect(newState)
    }
  }

  const valueForTitle = currentItems?.length
    ? talentSelectValue
    : talentSelectValue.slice(0, talentSelectValue?.length - 1)

  const bottomNavigationOverlayProps = talentSelectValue.length
    ? {
      title: `${t('shared.words.all')} ${t(getLastCategoryTitle(valueForTitle))}`,
      onBack,
    }
    : {
      title: t('footer.mobile.search-by-talent'),
    }

  return (
    <BottomNavigationOverlay
      className="search-by-talent"
      {...bottomNavigationOverlayProps}
    >
      <div className="multi-level-select">
        <ul className="cascader-list__list">
          {itemsToRender?.map(category => (
            <li
              key={category.i}
              onClick={e => onSelect(category, e)}
              data-selected={String(category.i === getLastCategoryId(talentSelectValue))}
              data-arrow={String(checkSubitems(category))}
            >
              {t(category?.n)}
            </li>
          ))}
        </ul>
      </div>
    </BottomNavigationOverlay>
  )
}

export default Talent
