// Modules
import React from 'react'
import cx from 'classnames'

// Components
import BottomNavigationOverlayHeader from './BottomNavigationOverlayHeader'

const toggleBodyScroll = forse => document.body.classList.toggle('noscroll', forse)


const BottomNavigationOverlay = ({
  children,
  title,
  onClose,
  onBack,
  className,
}) => {

  React.useEffect(() => {
    toggleBodyScroll(true)

    return () => {
      toggleBodyScroll(false)
    }
  },[])

  const classList = cx(
    'bottom-navigation__overlay',
    className,
  )

  return (
    <div className={classList}>
      <BottomNavigationOverlayHeader
        title={title}
        onClose={onClose}
        onBack={onBack}
      />

      <div className="bottom-navigation__overlay__body">
        {children}
      </div>
    </div>
  )
}

export default BottomNavigationOverlay
