import { CalendarSlotType, TalentOffersType } from 'shared'
import { addWeeks, startOfWeek } from 'date-fns'
import { getSlotBackground } from '../../Calendar/BaseCalendar/configs/slot-config'

const startOfActiveWeek = startOfWeek(new Date(), { weekStartsOn: 1 })
const lastDayOfActiveWeek = addWeeks(startOfActiveWeek, 1)

export const calendarFilters = {
  page: 1,
  startTime: startOfActiveWeek,
  endTime: lastDayOfActiveWeek,
  typeIn: [
    CalendarSlotType.TIME_UNAVAILABLE,
    CalendarSlotType.MY_EVENT,
    'SYNCED_CALENDAR'
  ],
  occurrenceTypeIn: [
    TalentOffersType.IN_PERSON_EXPERIENCE,
    TalentOffersType.VIRTUAL_EVENT,
    TalentOffersType.VIDEO_CHAT,
    'LESSONS',
  ],
}

export const talentCalendarSidebarFilters = [
  {
    field: 'occurrenceTypeIn',
    label: 'calendar.filters.offerings',
    options: [
      {
        value: TalentOffersType.IN_PERSON_EXPERIENCE,
        label: 'calendar.filters.in-person-experience',
        color: getSlotBackground(TalentOffersType.IN_PERSON_EXPERIENCE),
      },
      {
        value: TalentOffersType.VIRTUAL_EVENT,
        label: 'calendar.filters.virtual-event',
        color: getSlotBackground(TalentOffersType.VIRTUAL_EVENT),
      },
      {
        value: TalentOffersType.VIDEO_CHAT,
        label: 'calendar.filters.video-chat',
        color: getSlotBackground(TalentOffersType.VIDEO_CHAT),
      },
      {
        value: 'LESSONS',
        label: 'calendar.filters.lessons',
        color: getSlotBackground('LESSONS'),
      },
    ],
  },
  {
    field: 'typeIn',
    label: 'calendar.filters.other',
    options: [
      {
        value: CalendarSlotType.MY_EVENT,
        label: 'calendar.filters.my-event',
        color: getSlotBackground(CalendarSlotType.MY_EVENT),
      },
      {
        value: CalendarSlotType.TIME_UNAVAILABLE,
        label: 'calendar.filters.time-unavailable',
        color: getSlotBackground(CalendarSlotType.TIME_UNAVAILABLE),
      },
      {
        value: 'SYNCED_CALENDAR', // todo: implement later
        label: 'calendar.filters.synced-calendar',
        color: getSlotBackground(CalendarSlotType.MY_EVENT),
      },
    ],
  },
]

export const userCalendarSidebarFilters = [
  {
    field: 'occurrenceTypeIn',
    label: 'calendar.filters.offerings',
    options: [
      {
        value: TalentOffersType.IN_PERSON_EXPERIENCE,
        label: 'calendar.filters.in-person-experience',
        color: getSlotBackground(TalentOffersType.IN_PERSON_EXPERIENCE),
      },
      {
        value: TalentOffersType.VIDEO_CHAT,
        label: 'calendar.filters.video-chat',
        color: getSlotBackground(TalentOffersType.VIDEO_CHAT),
      },
      {
        value: 'LESSONS',
        label: 'calendar.filters.lessons',
        color: getSlotBackground('LESSONS'),
      },
    ],
  },
]

export const talentPublicViewCalendarSidebarFilters = [
  {
    field: 'occurrenceTypeIn',
    label: 'calendar.filters.offerings',
    options: [
      {
        value: TalentOffersType.IN_PERSON_EXPERIENCE,
        label: 'calendar.filters.in-person-experience',
        color: getSlotBackground(TalentOffersType.IN_PERSON_EXPERIENCE),
      },
      {
        value: TalentOffersType.VIRTUAL_EVENT,
        label: 'calendar.filters.virtual-event',
        color: getSlotBackground(TalentOffersType.VIRTUAL_EVENT),
      },
    ],
  },
]
