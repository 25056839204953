import React from 'react'
import useVideoContext from '../../hooks/useVideoContext'
import VideoTrack from '../VideoTrack/VideoTrack'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default function LocalVideoPreview() {
  const { localTracks } = useVideoContext()
  const t = useGetTranslate()

  const videoTrack = localTracks.find(track => !track.name.includes('screen') && track.kind === 'video')

  return videoTrack
    ? <VideoTrack track={videoTrack} isLocal />
    : <div className="local-video-default">{t('video-chat.video-preview.camera-is-off')}</div>
}
