import React from 'react'

import { useSetSubMenuActive } from 'store/hooks/globalState/useGlobalState'

import eventPath from 'utils/eventPath'

let prevTime = new Date().getTime()

const ANIMATION_DELAY = 500

function isAnimated () {
  const currentTime = new Date().getTime()
  if (currentTime - prevTime <= ANIMATION_DELAY) return true
  return false
}

export default function KeyPressHandler ({
  navigateToLeft, navigateToRight
}) {
  const setSubMenuActive = useSetSubMenuActive((prev, next) => next, [])

  const downHandler = React.useCallback((e) => {
    const { key } = e

    if (isAnimated()) return

    const isContainInput = eventPath(e)
      .map(({ nodeName }) => nodeName)
      .includes('INPUT')

    if (isContainInput) return

    prevTime = new Date().getTime()

    if (key === 'ArrowLeft') {
      navigateToLeft()
      setSubMenuActive(true)
      return
    }
    if (key === 'ArrowRight') {
      navigateToRight()
      setSubMenuActive(true)
      return
    }
  }, [navigateToLeft, navigateToRight, setSubMenuActive])

  React.useEffect(() => {
    window.addEventListener('keydown', downHandler);
    return () => window.removeEventListener('keydown', downHandler);
  }, [downHandler])

  return null
}
