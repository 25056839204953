import { _get, _post, _patch, _delete } from 'api/utils'

export const getTalentsCalendar = ({ talentId }) =>
  _get(`/talents/${talentId}/calendar`, { needAuthorization: true })

export const editCalendarSettings = ({ talentId, data }) =>
  _patch(`/talents/${talentId}/calendar`, { needAuthorization: true, body: data })

export const getTalentsCalendarSlots = ({ talentId, query }, config) =>
  _get(`/talents/${talentId}/calendar/slots`, { needAuthorization: true, query, config })

export const postTalentsCalendarSlots = ({ talentId, data }) =>
  _post(`/talents/${talentId}/calendar/slots`, { needAuthorization: true, body: data })

export const getTalentsCalendarCheckOverlap = ({ talentId, body }) =>
  _post(`/talents/${talentId}/calendar/slots/check-overlap`, { needAuthorization: true, body })

export const getTalentsCalendarSlot = ({ talentId, slotId }) =>
  _get(`/talents/${talentId}/calendar/slots/${slotId}`, { needAuthorization: true })

export const editTalentsCalendarSlot = ({ talentId, slotId, data }) =>
  _patch(`/talents/${talentId}/calendar/slots/${slotId}`, { needAuthorization: true, body: data })

export const deleteTalentsCalendarSlot = ({ talentId, slotId }) =>
  _delete(`/talents/${talentId}/calendar/slots/${slotId}`, { needAuthorization: true })
