import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common/Template';
import Select from '_legacy/common/Select/Common';
import { nationalities } from 'utils/services/configs/forms';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export const Nationality = ({ name = 'nationality' }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('register.form.nationality.label')}
      component={
        <Select
          name={name}
          options={nationalities}
          rules={{ required: 'register.form.nationality.errors.required' }}
          placeholder={t('register.form.nationality.placeholder')}
        />
      }
    />
  );
};

Nationality.propTypes = {
  name: PropTypes.string,
};

export default Nationality;
