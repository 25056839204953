import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Counter = React.memo(function Counter ({ field, label, getFn, pluralLabel = label }) {
  const t = useGetTranslate()
  const count = getFn(current => get(current, field, 0))
  const _label = count !== 1 ? pluralLabel : label
  return (
    <StyledWrapper>
      {count} {t(_label)}
    </StyledWrapper>
  )
})

Counter.propTypes = {
  count: PropTypes.string,
  label: PropTypes.string,
  pluralLabel: PropTypes.string
}

const StyledWrapper = styled.div`
  font-size: 13px;
  font-weight: 500;
  line-height: 24px;
  color: ${({ theme: { colors } }) => colors.blue};
`

export default Counter
