import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import styled from 'styled-components';

import Select from '_legacy/components/Form/Selects/SelectComponent';
import { languagesList, levelOptions } from '_legacy/components/Register/config';
import ArrayListItem from '_legacy/common/Template/ArrayListItem';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates';

const LanguageSelect = ({ name = 'languages', className = '', ...props }) => {
  const t = useGetTranslate();
  const { control } = useFormContext();
  const watchedValue = useWatch({ control, name, defaultValue: [] });
  const { fields, append, remove } = useFieldArray({ name });
  const appendLanguageHandler = () => {
    append({ level: '', language: '' });
  };

  const getUniqueLanguages = () =>
    languagesList.filter(
      l => !watchedValue.find(f => f?.language?.value === l.value)
    );

  const requiredText = t('shared.words.required');

  return (
    <>
      <StyledList className={className} {...props}>
        {fields.map((item, idx) => (
          <StyledItem key={item.id} showRemoveButton={idx > 0} onRemove={() => remove(idx)}>
            <StyledItemSelect className='language-name'>
              <Select
                name={`${name}.${idx}.language`}
                options={getUniqueLanguages()}
                rules={{ required: requiredText }}
                defaultValue={item?.language}
                placeholder={t('register.languages.placeholder')}
              />
            </StyledItemSelect>
            <StyledItemSelect className="language-level">
              <StyledLabelLevel className='language__label-level'>
                <Trans tKey='register.form.language.level' />
              </StyledLabelLevel>
              <Select
                name={`${name}.${idx}.level`}
                options={levelOptions(t)}
                rules={{ required: requiredText }}
                defaultValue={item?.level || levelOptions(t)[0]}
              />
            </StyledItemSelect>
          </StyledItem>
        ))}
      </StyledList>
      {fields.length < 3 && (
        <StyledMoreButton
          type="button"
          onClick={appendLanguageHandler}
        >
          + {t('shared.words.add-more')}
        </StyledMoreButton>
      )}
    </>
  );
};

const StyledList = styled.ul`
  .language {
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-child) {
        margin-bottom: 50px;
      }
    }
  }
`;

const StyledItem = styled(ArrayListItem)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 30px;
  }
  @media only screen and (max-width: 1024px) {
    flex-wrap: wrap;
    .language-name {
      margin-bottom: 20px;
    }
    &:not(:first-of-type) {
      .language-level {
        flex: 1;
        .react-select-container {
          width: 100%;
        }
      }
    }
  }
  .remove-button {
    flex: 0 0 26px;
    height: 26px;
    margin-left: 20px;
    @media only screen and (max-width: 1024px) {
      position: relative;
      top: 0;
      right: 0;
      transform: translateY(0);
    }
  }
`;

const StyledItemSelect = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    flex: 0 0 100%;
    max-width: 100%;
    .react-select-container {
      width: 100%;
    }
  }
`;

const StyledLabelLevel = styled.p`
  font-size: 16px;
  font-weight: 500;
  color: #272835;
  text-align: right;
  line-height: 1.625;
  margin: 0 18px 0 24px;
`;

const StyledMoreButton = styled.button`
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.black};
  cursor: pointer;
  padding: 20px 0 0 0;
  background: none;
  border: none;
  outline: none;
`;

LanguageSelect.propTypes = {
  name: PropTypes.string,
  className: PropTypes.string,
};

export default LanguageSelect;
