import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import compose from 'lodash/fp/compose'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

import { types } from 'shared'

const { FACEBOOK, INSTAGRAM, LINKED_IN, TIK_TOK, TWITTER } = types.user.socialMedia.Types
const socialOptions = [FACEBOOK, INSTAGRAM, LINKED_IN, TIK_TOK, TWITTER]

export const getOptionLabel = compose(startCase, lowerCase)

export const getSocialOption = option => ({
  value: option,
  label: getOptionLabel(option),
})

export const optionsWithTranslate = socialOptions.map(getSocialOption)

export const socialMediaLinkInitialState = [
  {
    social: optionsWithTranslate[0],
    link: ''
  }
]


export const getMedia = media => {
  const link = get(media, 'link')
  const social = get(media, 'social.value')
  return link && social ? { link, social } : undefined
}

export const submitSocialMediaAdapter = formSocialMedia => {
  return formSocialMedia
    ? formSocialMedia.map(getMedia).filter(Boolean)
    : undefined
}

export const getSocialMediaAdapter = backendSocialMedia => {
  return isEmpty(backendSocialMedia)
    ? socialMediaLinkInitialState
    : backendSocialMedia.map(mediaLink => ({
      link: mediaLink.link,
      social: {
        value: mediaLink.social,
        label: getOptionLabel(mediaLink.social),
      },
    }))
}
