import React from 'react'
import get from 'lodash/get'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import DesktopRow from './DesktopRow'
import MobileCard from './MobileCard'

export default React.memo(function TableRow (props) {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 768)

  if (isMobile) return <MobileCard {...props} />

  return <DesktopRow {...props} />
})
