import React from 'react'

import { filters } from '_legacy/components/Table/Filters/config'
import range from 'lodash/range'
import ViewStarIcon from 'assets/icons/ViewStarIcon';
import Icon from 'assets/icons/Icon'

import { OrderStatus } from 'shared'

const { CANCELED, BOOKED, COMPLETED } = OrderStatus


const FillStar = () => <ViewStarIcon percentageFill={100} size={20} />
const UnFillStar = () => <ViewStarIcon percentageFill={0} size={20} />

const Rating = ({ value }) => {
  const rating = [...range(0, value).fill(true), ...range(value, 5).fill(false)]
  return rating.map(filled => filled ? <FillStar /> : <UnFillStar />)
}

const participantsFilter = [
  { value: { from: 1, to: 1 }, label: '1' },
  { value: { from: 2, to: 2 }, label: '2' },
  { value: { from: 3, to: 3 }, label: '3' },
  { value: { from: 4, to: 4 }, label: '4' },
  { value: { from: 5, to: 5 }, label: '5' },
  { value: { from: 6, to: 6 }, label: '6' },
  { value: { from: 7, to: 7 }, label: '7' },
  { value: { from: 8, to: 8 }, label: '8' },
  { value: { from: 9, to: 9 }, label: '9' },
  { value: { from: 10 }, label: '>10' },
]

const ratingOptions = [
  { value: 1, label: <Rating value={1} /> },
  { value: 2, label: <Rating value={2} /> },
  { value: 3, label: <Rating value={3} /> },
  { value: 4, label: <Rating value={4} /> },
  { value: 5, label: <Rating value={5} /> },
]

export const participantsConfig = {
  type: filters.select,
  placeholder: 'search.filters.participants.placeholder',
  field: 'participants',
  options: participantsFilter
}

export const ratingConfig = {
  type: filters.select,
  placeholder: 'search.filters.rating.placeholder',
  field: 'ratingFrom',
  options: ratingOptions,
}

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'searchLike',
  },
  ratingConfig,
  participantsConfig,
]

export const counterParticipantsConfig = {
  field: 'count',
  label: 'table.filters.participant',
  pluralLabel: 'table.filters.participants'
}

export const counterRequestsConfig = {
  field: 'count',
  label: 'table.filters.request',
  pluralLabel: 'table.filters.requests'
}

//const { DATE, LOCATION, PRICE, STATUS, TITLE } = api.talents.talent.offers.query.SortField;

const sortFields = {
  AFICIONADO_FULL_NAME: 'order-details.filters.aficionado.placeholder',
  PARTICIPANTS_COUNT: 'order-details.filters.participants.placeholder',
  ORDER_PRICE_AMOUNT: 'order-details.filters.price.placeholder',
  ORDER_NUMBER: 'order-details.filters.order.placeholder',
}

const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

///  SAME AS ON PROMO CODES WE RECEIVE FROM BE FIELDS THAT DIFFERS FROM DESIGNS AND REQUIREMENTS UNCOMMENT AND TEST AFTER CHANGE FROM BE

export const subFiltersConfig = [
  {
    label: 'table.filters.sort-by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const defaultConfig = [
  {
    field: 'fields'
  },
  {
    field: 'relations'
  },
  {
    field: 'experienceIdIn'
  }
]

export const detailsConfig = {
  fields: ['id', 'recurring', 'occurrencesLimit', 'name', 'daysTimesType', 'timeWindows', 'maxNumberOfParticipants'],
  relations: ['photo', 'video', 'talent', 'whichCharities', 'otherTalentParticipating']
}

const additionalFields = [
  'sortOrder'
]

export const fields = [
  ...defaultConfig.map(({ field }) => field),
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]

//Virtual event config
export const statuses = {
  [BOOKED]: {
    Icon: Icon.OrderStatusUnderReview,
    label: 'my-orders.status.booked',
  },
  [CANCELED]: {
    Icon: Icon.OrderStatusCancel,
    label: 'my-orders.status.cancel',
  },
  [COMPLETED]: {
    Icon: Icon.OrderStatusCompleted,
    label: 'my-orders.status.completed',
  },
}
const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key].label }))


export const virtualEventFiltersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'searchLike',
  },
  ratingConfig,
  participantsConfig,
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'orderStatusIn',
    options: statusOptions
  }
]

const virtualEventSortFields = {
  AFICIONADO_FULL_NAME: 'order-details.filters.aficionado.placeholder',
  PARTICIPANTS_COUNT: 'order-details.filters.participants.placeholder',
  ORDER_PRICE_AMOUNT: 'order-details.filters.price.placeholder',
  ORDER_NUMBER: 'order-details.filters.order.placeholder',
  ORDER_STATUS: 'table.header.status',
}

const virtualEventSortFieldOptions = Object.keys(virtualEventSortFields).map(key => ({ value: key, label: virtualEventSortFields[key] }))

export const virtualEventSubFiltersConfig = [
  {
    label: 'table.filters.sort-by',
    field: 'sortField',
    options: virtualEventSortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const virtualEventFields = [
  ...defaultConfig.map(({ field }) => field),
  ...virtualEventFiltersConfig.map(({ field }) => field),
  ...virtualEventSubFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
