import styled from 'styled-components';

const BackdropWrapper = styled.div`
  width: 100%;
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  border: ${({ theme }) => theme.border.default};
  background: transparent;

  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  i {
    font-size: 24px;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  h3 {
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    font-size: 16px;
    line-height: 26px;

    color: ${({ theme }) => theme.colors.blue};
  }

  p {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    color: ${({ theme }) => theme.colors.lightGrey};
  }
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
`
export default BackdropWrapper
