import React from 'react'

import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import {
  useReadyMadeEventLessonsDetailsProductTable as useTable,
  useReadyMadeEventLessonsDetailsProductFilters as useFilters,
  useSetReadyMadeEventLessonsDetailsProductFilters as useSetFilters,
} from '../../state/store.js'
import {
  useFetchReadyMadeEventLessonsDetailsProductTable as fetchOrders,
  bulkFileDownload,
} from '../../state/requests.js'

import { READY_MADE_EVENT_LESSONS_DETAILS_PRODUCT, tableConfig, fields } from './configs/tableConfig.js'
import { filtersConfig } from './configs/filtersConfig.js'
import { subFiltersConfig, counterConfig } from './configs/subFiltersConfig.js'


const ReadyMadeEventLessonsDetailsOrdersTable = () => {
  const t = useGetTranslate()
  const files = useTable()

  const actionsConfig = React.useMemo(() =>
    [{
      label: 'ready-made-event-lesson-details.product.actions.download',
      cbFn: bulkFileDownload(files),
    }], [t, bulkFileDownload, files])

  const bulkActionsConfig = React.useMemo(() => ({
    getFilters: useFilters,
    setFilters: useSetFilters,
    actions: actionsConfig,
  }), [t, useFilters, useSetFilters, bulkFileDownload, files])


  return (
    <div className='wrapper content-wrapper ready-made-event-lessons-details-product-table'>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useFilters}
        setFn={useSetFilters}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useFilters}
        setFn={useSetFilters}
      />
      <Table
        tableName={READY_MADE_EVENT_LESSONS_DETAILS_PRODUCT}
        emptyTableText={'table.empty-requests'}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        getTableFn={useTable}
        fields={fields}
        bulkActionsConfig={bulkActionsConfig}
        getFiltersFn={useFilters}
        setFiltersFn={useSetFilters}
        fetchTableFn={fetchOrders}
      />
    </div>
  )
}

export default ReadyMadeEventLessonsDetailsOrdersTable
