import React from 'react'
import PropTypes from 'prop-types'

import Avatar from '_legacy/components/Table/table-components/types/common/Avatar'
import './index.scss'

import { getFullName } from 'utils/user'
import { useStartChat } from 'requests/chats'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const AficionadoListItem = ({ aficionado, isLoading }) => {
  const t = useGetTranslate()

  const { id, profileImage } = aficionado

  const startChat = useStartChat(id)

  const fullName = getFullName(aficionado)

  const handleClick = () => {
    if (!isLoading) {
      return startChat()
    }
  }

  return (
    <div className="aficionado__wrapper" onClick={handleClick}>
      <Avatar image={profileImage} className="aficionado__icon" />

      <div className="aficionado__info">
        <p className="aficionado__full-name">{fullName}</p>
        <p className="aficionado__start-chat">{t('chats.chat.start-a-chat')}</p>
      </div>
    </div>
  )
}

AficionadoListItem.propTypes = {
  aficionado: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      profileImage: PropTypes.object.isRequired,
    }).isRequired
  ),
}

export default AficionadoListItem
