import React from 'react';
import { array } from 'prop-types';
import { types } from 'shared';
const { List } = types.languages;

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import TalentInfoBlockTab from '../TalentInfoBlockTab/';

import { languageLevel } from './utils'

const TalentInfoBlockLanguage = ({ languages }) => {
  const t = useGetTranslate()

  return (
    <TalentInfoBlockTab
      className="talent-info-block__tab--language"
      header={t('talent-public-view.info-block.languages-spoken')}
      info={
        languages.length === 0 ? (
          t('talent-public-view.info-block.not-specified')
        ) : (
          <div className="talent-info-block__tab--language__block">
            {languages.map(({ language, level }) => (
              <span className="talent-info-block__tab--language__block-item" key={language}>
                {`${List[language].native} (${t(languageLevel[level])})`}
              </span>
            ))}
          </div>
        )
      }
    />
  );
};

TalentInfoBlockLanguage.propTypes = {
  languages:array.isRequired,
};

export default TalentInfoBlockLanguage;
