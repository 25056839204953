import get from 'lodash/get';
import {
  createUseGlobalState,
  createUseSetGlobalState,
} from './useGlobalState';

export const useErrors = createUseGlobalState('errors');
export const useSetErrors = createUseSetGlobalState('errors');

export const useSetError = key => {
  return useSetErrors((prev, next) => ({ ...prev, [key]: next }));
}

export const useClearError = key => {
  return useSetErrors((prev) => ({ ...prev, [key]: null }));
}

export const useError = key => {
  const errors = useErrors();
  return get(errors, key);
};
