import React from 'react'
import Select from 'react-select'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useDevices from '../../hooks/useDevices'
import { useVideoState } from '../../hooks/useVideoState'

export default function AudioOutputList() {
  const t = useGetTranslate()
  const { audioOutputDevices } = useDevices()
  const { activeSinkId, setActiveSinkId } = useVideoState()

  const audioOutputDevicesOptions = audioOutputDevices.map(device => ({ value: device.deviceId, label: device.label }))
  const activeOutputOption = audioOutputDevicesOptions.find(option => option.value === activeSinkId)
  const activeOutputLabel = activeOutputOption?.label ?? t('video-chat.device-selection-modal.default-audio')

  return (
    <div className="media-list">
      <h6 className="media-list__header">{t('video-chat.device-selection-modal.audio-output')}</h6>
      {audioOutputDevices.length > 1 ? (
        <div className="react-select-container">
          <Select
            classNamePrefix="react-select"
            components={{ IndicatorSeparator: () => null }}
            isSearchable={false}
            options={audioOutputDevicesOptions}
            onChange={option => setActiveSinkId(option.value)}
            value={activeOutputOption}
          />
        </div>
      ) : (
        <p className='media-list__default'>{activeOutputLabel}</p>
      )}
    </div>
  )
}
