// Modules
import React from 'react'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const LocationListItem = ({
  onTalentItemClick,
  onOfferingItemClick,
  item,
}) => {
  const t = useGetTranslate()

  const {
    talentsCount,
    offersCount,
    description,
  } = item

  return (
    <li className="location-search__list__item">
      <p className="location-search__list__item__name mb-4">
        {description}
      </p>

      <div className="location-search__list__item__counters">
        <span
          className="location-search__list__item__counters__counter"
          onClick={() => onTalentItemClick(item)}
        >
          {t('search.filters.location.talents')} {talentsCount}
        </span>
        <span
          className="location-search__list__item__counters__counter"
          onClick={() => onOfferingItemClick(item)}
        >
          {t('search.filters.location.experiences')} {offersCount}
        </span>
      </div>
    </li>
  )
}

export default LocationListItem
