import React from 'react'
import { Route, Redirect, useParams, useHistory } from 'react-router-dom'
import { get, any, compose, prop, map, eq } from 'lodash/fp'

import Menu from '_legacy/containers/Account/Menu'


function RouteWithSubRoutes(route) {
  const id = useParams()
  return (
    <Route
      exact
      path={route.pathComponent}
      render={props => <route.component {...props} id={id} routes={route.routes} />}
    />
  )
}

const ContentTabs = ({
  menuItems,
  routeToRedirect,
}) => {
  const history = useHistory()

  const getHistoryPathname = get('location.pathname', history)
  const getMenuItemPathname = prop('pathname')

  const isLocationInMenuList = compose(
    any(eq(getHistoryPathname)),
    map(getMenuItemPathname),
  )

  const isRedirect = !isLocationInMenuList(menuItems)
  const _routeToRedirect = routeToRedirect || get([0, 'pathname'], menuItems)

  return (
    <>
      <Menu menuItems={menuItems} className='table-tabs' />
      {menuItems.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}

      { isRedirect &&
        <Redirect replace to={_routeToRedirect} />
      }
    </>
  )
}

export default ContentTabs
