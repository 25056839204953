import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import CustomPicture from '_legacy/common/CustomPicture';

import '_legacy/components/Search/SearchCardItem/index.scss'
import FollowButton from '_legacy/components/Button/FollowButton';

const SearchTalentCard = ({ value = {} }) => {
  const { id, firstName, lastName, halfBodyImage, plaqueNickname, currentUserPersonalData: { isFollowed } } = value;

  return (
    <Link to={`/${plaqueNickname}`} className='search-card'>
      <div className='search-card__image'>
        <CustomPicture file={halfBodyImage} />
      </div>
      <div className='search-card__top'>
        <FollowButton id={id} isFollowed={isFollowed} type='TALENT' className='search-card__save simple dark' />
      </div>
      <div className='search-card__content'>
        <div className='search-card__fullName'>
          <p className='search-card__fullName_name'>{firstName}</p>
          <p className='search-card__fullName_lastname'>{lastName}</p>
        </div>
      </div>
    </Link>
  )
}

SearchTalentCard.propTypes = {
  value: PropTypes.object.isRequired,
}

export default SearchTalentCard;
