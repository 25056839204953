// Modules
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import isFunction from 'lodash/isFunction'

// Store (hooks)
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useLeaveComplaint } from 'requests/complaint'

// Legacy components
import TextArea from '_legacy/common/TextArea'
import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'


const ComplaintModal = ({
  isOpen,
  onClose,
  userLink,
  userName,
  orderId,
  onSuccess
}) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      message: ''
    }
  })
  const t = useGetTranslate()
  const leaveComplaint = useLeaveComplaint(() => {
    if (isFunction(onSuccess)) onSuccess()
    onClose()
  })
  const isBtnDisabled = methods.watch('message')?.length === 0

  const onSubmit = ({ message }) => {
    leaveComplaint({ message, orderId })
  }

  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <FormProvider {...methods}>
        <div className="review-modal">
          <h1 className="modal-header">{t('complaint-modal.header')}</h1>
          <p className="modal-text">
            <Trans
              tKey="complaint-modal.sub-header"
              phs={[
                { ph: '{{userLink}}', value: userLink },
                { ph: '{{userName}}', value: userName },
              ]}
            />
          </p>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <label className="input-label">
              {t('complaint-modal.form.message.label')}
              <TextArea
                maxLength={500}
                name="message"
                className="review-modal__textarea"
                placeholder={t('complaint-modal.form.message.placeholder')}
              />
            </label>

            <Button
              stretch
              typeButton="submit"
              paddingVertical={16}
              disabled={isBtnDisabled}
              text={t('complaint-modal.form.submit-button')}
            />
          </form>
        </div>
      </FormProvider>
    </ModalPortal>
  )
}

export default ComplaintModal
