const sortFields = {
  RATING: 'search.filters.rating.placeholder',
  ORDER_NUMBER: 'table.header.order',
  ORDER_PRICE_AMOUNT: 'table.header.amount',
  ORDER_STATUS: 'table.header.status',
};

const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

export const subFiltersConfig = [
  {
    label: 'table.filters.by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.order',
  pluralLabel: 'table.filters.orders',
}

