import React from 'react';
import { Link } from 'react-router-dom'

import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'

import * as r from '_legacy/constants/routes'

const Search = () => {

  return (
    <Link to={`/${r.SEARCH}/${r.KEYWORDS}`}>
      <SearchIcon className="search main-menu__search" />
    </Link>
  );
};

export default Search;
