import React from 'react'

import LineString from '../common/LineString'

export default React.memo(function Code ({ isLoading, code }) {
  return (
    <LineString
      isLoading={isLoading}
      str={code}
    />
  )
})
