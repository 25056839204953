import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import Button from '_legacy/components/Button';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { MAX_ATTEMPTS } from './constants';

const PhoneVerifyButton = ({ fields, onClick, isLoading }) => {
  const t = useGetTranslate();
  const { watch, errors, formState } = useFormContext();
  const phoneErrors = get(errors, fields.phone);
  const isPhoneDirty = get(formState.dirtyFields, fields.phone, false);
  const phone = watch(fields.phone, '');
  const oldPhone = watch(fields.oldPhone, '');
  const isPhoneSand = watch(fields.isPhoneSand, false);
  const canRetry = watch(fields.canRetry, false)
  const remainingAttempts = watch(fields.remainingAttempts, MAX_ATTEMPTS)

  const isVerifying = remainingAttempts < MAX_ATTEMPTS
  const disabled = oldPhone === phone || isLoading || phoneErrors || phone.length < 3 || (isPhoneSand && (!remainingAttempts || !canRetry)) || !isPhoneDirty

  const text = isLoading && t('shared.phone.button.verifying')
    || isVerifying && t('shared.phone.button.retry')
    || t('shared.phone.button.verify')

  return (
    <Button
      text={text}
      disabled={disabled}
      handleOnClick={onClick}
      paddingVertical="18"
    />
  );
};

PhoneVerifyButton.propTypes = {
  fields: PropTypes.object,
};

export default PhoneVerifyButton;
