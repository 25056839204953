import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'

import * as r from '_legacy/constants/routes'

import LiveVirtualLessonBookingsDetails from './LiveVirtualLessonBookingsDetails'
import ReadyMadeVideoBookingsDetails from './ReadyMadeVideoBookingsDetails'
import LiveInPersonLessonBookingsDetails from './LiveInPersonLessonBookingsDetails'
import VideoVoiceOverLessonBookingsDetails from './VideoVoiceOverLessonOrderDetails'
import VideoMessageBookingsDetails from './VideoMessageBookingsDetails'
import VideoChatBookingDetails from './VideoChatBookingDetails'
import ExperienceBookingsDetails from './ExperienceBookingsDetails'

const BookingsDetailsRouter = () => {
  const match = useRouteMatch()
  return (
    <Switch>
      <Route path={`${match.path}/${r.LIVE_VIRTUAL_LESSON}/${r.DETAILS}/:orderId`} component={LiveVirtualLessonBookingsDetails} />
      <Route path={`${match.path}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/:orderId`} component={ReadyMadeVideoBookingsDetails} />
      <Route path={`${match.path}/${r.LIVE_IN_PERSON_LESSON}/${r.DETAILS}/:orderId`} component={LiveInPersonLessonBookingsDetails} />
      <Route path={`${match.path}/${r.VIDEO_VOICE_OVER_LESSON}/${r.DETAILS}/:orderId`} component={VideoVoiceOverLessonBookingsDetails} />
      <Route path={`${match.path}/${r.VIDEO_MESSAGE}/${r.DETAILS}/:orderId`} component={VideoMessageBookingsDetails} />
      <Route path={`${match.path}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.DETAILS}/:orderId`} component={VideoMessageBookingsDetails} />
      <Route path={`${match.path}/${r.VIDEO_CHAT}/${r.DETAILS}/:orderId`} component={VideoChatBookingDetails} />
      <Route path={`${match.path}/${r.EXPERIENCES}/${r.DETAILS}/:orderId`} component={ExperienceBookingsDetails} />
    </Switch>
  )
}

export default BookingsDetailsRouter
