import React from 'react'

import LineString from '../common/LineString'

export default React.memo(function Participants({ isLoading, participantsCount }) {
  return (
    <LineString
      isLoading={isLoading}
      str={participantsCount?.toString()}
      className='centered'
    />
  )
})
