import map from 'lodash/map'
import { filters } from '../../components/Table/Filters/config';

const notificationTypes = {
  reminders: 'table.filters.notification-types.reminders',
  communityCommunication: 'table.filters.notification-types.community-communication',
  accountRelated: 'table.filters.notification-types.account-related',
  other: 'table.filters.notification-types.other',
}
const notificationTypesOptions = map(notificationTypes, (label, value) => ({ value, label }))

export const filtersConfig = [
  {
    type: filters.select,
    placeholder: 'table.filters.notification-types.placeholder',
    field: 'type',
    options: notificationTypesOptions
  }
]


const sortFields = {
  latestFirst: 'table.header.latest-first',
  oldestFirst: 'table.header.oldest-first',
  read: 'table.header.read',
  unread: 'table.header.unread',
};
const sortFieldOptions = map(sortFields, (label, value) => ({ value, label }))

export const subFiltersConfig = [
  {
    label: 'table.filters.sort-by',
    field: 'sortField',
    options: sortFieldOptions
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.notification',
  pluralLabel: 'table.filters.notifications'
}


export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
]
