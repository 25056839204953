import React from 'react';
import PropTypes from 'prop-types';

import * as r from '_legacy/constants/routes'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import CurrencyAndLanguage from './CurrencyAndLanguage';
import UserAccount from './UserAccount';
import Button, { SignUpButton } from '_legacy/components/Button';

import { REGISTER, USER, AFICIONADO } from '_legacy/constants/routes';

import { hashHistory } from 'utils/history'

const PersonalMenu = ({
  isUserLoggedIn,
  isAccountMenuActive,
  toggleAccountMenu,
  themeType
}) => {
  const t = useGetTranslate();

  return (
    <div className="personal-wrap main-menu__personal">
      {!isUserLoggedIn && (
        <div className="auth-buttons-wrap main-menu__auth">
          <Button
            text={t('login.header')}
            handleOnClick={() => hashHistory.push(`${r.HASH_LOGIN}`)}
            secondary
            paddingVertical="7"
            fontSize="0.75"
            paddingHorizontal="24"
            classBtn="login"
          />
          <SignUpButton
            text={t('header.sign-up')}
            className="sign-up__button"
            paddingVertical="8"
            paddingHorizontal="22"
            fontSize="0.75"
          >
            <Button text={t('header.dropdown.sign_up_aficionado')}
              isLink
              path={`/${REGISTER}/${AFICIONADO}`}
              primary
              stretch
              paddingVertical="7"
              paddingHorizontal="24"
              fontSize="0.75"
            />
            <Button text={t('header.dropdown.sign_up_talent')}
              isLink
              path={`/${REGISTER}/${USER}`}
              primary
              stretch
              paddingVertical="7"
              paddingHorizontal="24"
              fontSize="0.75"
            />
          </SignUpButton>
        </div>
      )}

      {/*  This part is only for demonstration purposes, remove after user profile implementation  */}
      {isUserLoggedIn && (
        <UserAccount
          isAccountMenuActive={isAccountMenuActive}
          onClick={() => toggleAccountMenu()}
        />
      )}

      <CurrencyAndLanguage themeType={themeType} />
    </div>
  );
};

PersonalMenu.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  isSignInModalOpen: PropTypes.bool,
  toggleSignInModal: PropTypes.func,
  isAccountMenuActive: PropTypes.bool,
  toggleAccountMenu: PropTypes.func,
};

export default PersonalMenu;
