import { _get, _put } from 'api/utils'

export const getTalentLessonsList = ({ talentId }) => _get(`/talents/${talentId}/lessons`, { needAuthorization: true })

export const getLessonsList = ({ talentPlaqueNickname }) => _get(`offers/lessons/public/${talentPlaqueNickname}`)

export const updateLesson = ({ talentId, route, data }) =>
  _put(`/talents/${talentId}/lessons${route}`, {
    needAuthorization: true,
    body: data,
  })
