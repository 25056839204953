import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Arrow from './Arrow'
import Button from '_legacy/components/Button'

export default React.memo(function BookNow ({ path, navigateToLeft, navigateToRight }) {
  const t = useGetTranslate()
  return (
    <StyledWrapper>
      <Arrow
        className='mobile-arrow left'
        clickHandler={navigateToLeft}
      />
      <Button
        withoutLoader
        text={t('home-page.buttons.learn-more')}
        path={path}
        classBtn="button-wrapper"
        isLink={!!path}
        gradient
        paddingHorizontal="18"
        fontSize="0.875"
      />
      <Arrow
        className='mobile-arrow right'
        clickHandler={navigateToRight}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 45px auto 0;
  @media only screen and (max-width: 480px) {
    width: 100%;
    padding: 0 25px;
  }
  .button-wrapper {
    .button__text {
      font-size: 16px;
    }
    @media only screen and (max-width: 480px) {
      .button__item {
        padding: 15px 32px;
      }
    }
  }
  .mobile-arrow {
    display: none;
    @media only screen and (max-width: 480px) {
      position: static;
      display: block;
    }
  }
`
