// Modules
import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { parseDate } from 'utils/services/timezone'
import { getMonthDayYearDate } from 'utils/date'

// Legacy components
import Slider from '_legacy/components/TalentPublicView/DesktopSlider/Slider'
import Slide from '_legacy/components/TalentPublicView/DesktopSlider/Slide'

import Actions from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences/Desktop/Actions'
import Empty from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences/Desktop/Empty'
import Overlay from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences/Desktop/Overlay'


export const getDateLabel = t => slide => {
  const isMultipleDates = get(slide, 'multipleDates')
  const timeZone = get(slide, 'location.timezone.id')
  const startTime = get(slide, 'startTime')

  return isMultipleDates
    ? t('home.slider.top-experiences.date')
    : getMonthDayYearDate(
      parseDate(startTime, timeZone)
    )
}


const LiveExperience = ({ firstName, covers = [], plaqueNickname }) => {
  const [globalIsPlay, setGlobalIsPlay] = React.useState(false)

  const t = useGetTranslate()
  const getDate = getDateLabel(t)

  const slides = covers.map((cover, i) => {
    return (
      <Slide.DesktopSlide
        key={`experiences-${i}`}
        offerSequentialId={cover.sequentialId}
        multipleDatesExperience={cover.sequentialId}
        file={cover.video || cover.photo}
        isPromo={cover.isPromo}
        setGlobalIsPlay={setGlobalIsPlay}
        overlay={!globalIsPlay ?
          <Overlay
            key={cover.id}
            date={getDate(cover)}
            name={cover.name}
            nameColor={cover.nameColor}
            video={Boolean(cover.video)}
          /> : null
        }
      />
    )})

  return (
    <Slider
      size="big"
      title={t('talent-public-view.sliders.live-experiences.header.label')}
      titleFontSize={27}
      withGradientOverhead={true}
      slides={slides}
      empty={<Empty firstName={firstName} />}
      Actions={Actions}
      bottomSpace={40}
      plaqueNickname={plaqueNickname}
    />
  )
}

LiveExperience.propTypes = {
  firstName: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
}

export default LiveExperience
