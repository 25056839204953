// Modules
import React from 'react'
import { get } from 'lodash'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import prop from 'lodash/fp/prop'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import * as r from '_legacy/constants/routes'

// Store (Hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useFetchReadyMadeEventLessonsDetailsHeader } from './state/requests'
import { useUnpublishOffer } from 'requests/manage-offerings'
import { useReadyMadeEventLessonsDetailsHeader, useSetReadyMadeEventLessonsDetailsHeader } from './state/store'

// Utils
import { toMoneyWithComa } from 'utils/moneyTransformator'
import { getMenuItems, defaultRoute } from './utils/getMenuItems'

// Components
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'

// Legacy components
import PageLoader from '_legacy/common/Template/PageLoader'
import ContentTabs from '_legacy/containers/ContentTabs'
import CustomMedia from '_legacy/common/CustomMedia'
import Button from '_legacy/components/Button'

// Constants
import { DELIVERY_TYPES } from './constants.js'
import { getTalentPlaqueNickname } from 'utils/user'

const ReadyMadeEventLessonsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const history = useHistory()

  const userNickname = useUser(getTalentPlaqueNickname)
  const fetchOccurrence = useFetchReadyMadeEventLessonsDetailsHeader()
  const occurrence = useReadyMadeEventLessonsDetailsHeader(prop('occurrence'))
  const clearOccurrence = useSetReadyMadeEventLessonsDetailsHeader(() => null)
  const menuItems = getMenuItems(orderId)
  const unpublishOffer = useUnpublishOffer()

  React.useEffect(() => {
    fetchOccurrence()
    return () => {
      clearOccurrence()
    }
  }, [])

  // TODO: Check is this ok to show loader even if offering never came
  if (!occurrence) return <PageLoader />

  const { title, media, participantsCount, productUpload, price, offerId, offerSequentialId, deliveryType } = occurrence

  const headerInfo = [
    {
      icon: <Icon.PageOutlined />,
      item: `${productUpload.length} ${t('ready-made-event-lesson-details.info.product-files')}`,
    },
    {
      icon: <Icon.CubeOutlined />,
      item: t(get(DELIVERY_TYPES, deliveryType, '')),
    },
    {
      icon: <Icon.Pricetag />,
      item: `$${toMoneyWithComa(price)} ${t('currency.usd')}`,
      subitem: t('ready-made-event-lesson-details.info.price'),
    },
  ]

  const handleUnpublish = () => {
    unpublishOffer([offerId])
    history.push(`/${r.ORDERS}/${r.LIST}`)
  }

  return (
    <div className="ready-made-event-lesson-order">
      <OfferingDetailsTitle typeHeader={t('ready-made-event-lesson-details.header.title')} title={title} />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              {media[0] ? <CustomMedia file={{ ...media[0].file, originalName: '' }} /> : <img alt={''} />}
            </div>
            <div className="od__column od--padding-info">
              {headerInfo.filter(Boolean).map((item, i) => {
                return <OfferingsDetailsHeaderItem key={i} {...item} />
              })}
              <Link
                className="od__row od__public-link"
                to={`/${r.READY_MADE_EVENT_LESSON}/${userNickname}/${offerSequentialId}`}
              >
                <div className="od--main-text od--accented od__public-link--text">
                  {t('ready-made-event-lesson-details.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="od--shadow-box od--centered">
          <div className="od__column od--with-padding">
            <p className="od--uppercase-title mb-32">{t('ready-made-event-lesson-details.header.title')}</p>

            {participantsCount && (
              <React.Fragment>
                <p className="od--main-text">{t('ready-made-event-lesson-details.actions.orders')}</p>
                <span className="od--status-title mb-32">{participantsCount}</span>
              </React.Fragment>
            )}

            <Button
              handleOnClick={handleUnpublish}
              primary
              text={t('ready-made-event-lesson-details.actions.unpublish')}
              typeButton="submit"
              fontSize="0.875"
              paddingVertical="16"
              paddingHorizontal="10"
            />
          </div>
        </div>
      </div>
      <ContentTabs menuItems={menuItems} defaultRoute={defaultRoute} />
    </div>
  )
}

export default ReadyMadeEventLessonsDetails
