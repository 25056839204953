import React from 'react'
import styled from 'styled-components'

export default React.memo(function Navigation ({
  config, activeSection, setActiveSection
}) {
  return (
    <StyledWrapper>
      {config.map((item, i) => {
        return (
          <li
            key={i}
            className={activeSection === i ? 'active' : ''}
            onClick={() => setActiveSection(i)}
          />
        )
      })}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.ul`
  position: fixed;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9995;
  @media screen and (max-width: 1024px) {
    display: none;
  }
  li {
    position: relative;
    display: block;
    box-sizing: border-box;
    border: 2px solid #9495a7;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-bottom: 18px;
    cursor: pointer;
  }
  .active {
    background: #fff;
    border-color: #fff;
  }
`
