import cond from 'lodash/cond';
import matches from 'lodash/matches';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';
import { types } from 'shared';

const typeInvite  = types.UserType;

const getInviteTypeName = cond([
  [matches(typeInvite.AGENT), constant('Agent')],
  [matches(typeInvite.MANAGER), constant('Manager')],
  [matches(typeInvite.TALENT_USER), constant('Talent')],
  [stubTrue, constant('Talent')],
]);

export default getInviteTypeName;
