import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { Row } from '_legacy/common/Template';
import TimeSelector from './TimeSelector';

const Time = ({ selectedDate }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('create-experience.form.time.label')}
      subNameBlock={t('create-experience.form.time.tip')}
      isRequired
      component={<TimeSelector selectedDate={selectedDate} />}
    />
  );
};

Time.propTypes = {
  selectedDate: PropTypes.instanceOf(Date),
};

export default Time;
