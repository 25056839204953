import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useCreateLiveVirtualEventsDraft, useEditLiveVirtualEvent, useFetchLiveVirtualEvent } from 'requests/live-virtual-events'
import { useLiveVirtualEvents, useSetLiveVirtualEvents } from 'store/hooks/globalState/useLiveVirtualEvents'

import Form from '_legacy/containers/LiveVirtualEvents/Form'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import OfferingDescription from '_legacy/containers/Offerings/components/OfferingDescription'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import { getUserTimeZone } from 'utils/user'

import { getDefaultValues } from '_legacy/containers/LiveVirtualEvents/utils/defaultValues'
import { createLiveVirtualEventsAdapter, getLiveVirtualEventsDataAdapter } from '_legacy/containers/LiveVirtualEvents/utils/createLiveVirtualEventsAdapter'

const EditLiveVirtualEvent = () => {
  const t = useGetTranslate()
  const fetchLiveVirtualEvent = useFetchLiveVirtualEvent()
  const editLiveVirtualEvent = useEditLiveVirtualEvent()
  const createLiveVirtualEventsDraft = useCreateLiveVirtualEventsDraft()
  const liveVirtualEvent = useLiveVirtualEvents()
  const clearVirtualEvent = useSetLiveVirtualEvents(() => null)

  const timeZoneId = useUser(getUserTimeZone)

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    shouldUnregister: false,
    mode: 'onChange',
  })

  const onSubmit = compose(
    editLiveVirtualEvent,
    createLiveVirtualEventsAdapter,
  )

  const onDraft = compose(
    createLiveVirtualEventsDraft,
    createLiveVirtualEventsAdapter,
    methods.getValues
  )

  React.useEffect(() => {
    fetchLiveVirtualEvent()
    return () => {
      clearVirtualEvent()
    }
  }, [])

  React.useEffect(() => {
    if (liveVirtualEvent) {
      methods.reset(getLiveVirtualEventsDataAdapter({ ...liveVirtualEvent, timeZone: timeZoneId }, t))
    }
  }, [liveVirtualEvent])

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('live-virtual-events.title.edit')}</OfferingTitle>
      <OfferingDescription>{t('live-virtual-events.subtitle')}</OfferingDescription>
      <Form methods={methods} onSubmit={onSubmit} onDraft={onDraft} />
    </OfferingTemplate>
  )
}

export default withLibrary(EditLiveVirtualEvent)
