import React from 'react';
import styled from 'styled-components';

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import BackdropWrapper from '../../components/Backdrop/BackdropWrapper';

const MultiSelectBackdrop = ({ maxSize }) => {
  const t = useGetTranslate()

  return (
    <MultiSelectBackdropWrapper>
      <i className="icon-frame icon-camera" />
      <h3>{t('video-message.form.media.placeholder.title')}</h3>
      <p>
        <Trans tKey="video-message.form.media.placeholder.text" phs={[{ ph: '{{max-size}}', value: maxSize }]} />
      </p>
    </MultiSelectBackdropWrapper>
  )
}

const MultiSelectBackdropWrapper = styled(BackdropWrapper)`
  width: 100%;
  height: 220px;
`;

export default MultiSelectBackdrop
