import { getAddresses } from 'api/appApi/integration/locations/autocomplete/address';
import { getCities } from 'api/appApi/integration/locations/autocomplete/cities';
import { getLocations } from 'api/appApi/integration/locations/autocomplete/locations';
import { getAddressandlocations } from 'api/appApi/integration/locations/autocomplete/addressandlocations';
import { getLocationFormattedAddressWithName } from 'utils/location'


export const lessonLocation = item => ({
  value: item.id,
  label: getLocationFormattedAddressWithName(item),
});

export const locationPreparator = item => ({
  value: item.placeId,
  label: item.description,
  data: item.terms,
});

export const cityPreparator = item => ({
  value: item.placeId,
  label: item.description,
  data: item.terms,
});

const getAsyncLocation = (apiFunction, itemsName, itemPreparator) => async (
  search = '',
  _,
  { page }
) => {
  try {
    const { [itemsName]: items } = await apiFunction({ input: search, page });
    return {
      options: items.map(itemPreparator),
      hasMore: false
    };
  } catch {
    return {
      options: [],
      hasMore: false
    }
  }
};

export const loadLocations = async (input) => {
  try {
    const { locations } = await getLocations({ input })
    return {
      options: locations.map(locationPreparator),
      hasMore: false
    };
  } catch (err) {
    return {
      options: [],
      hasMore: false,
    }
  }
}

export const loadLocationsOptions = getAsyncLocation(
  getLocations,
  'locations',
  locationPreparator
);

export const loadAddressOptions = getAsyncLocation(
  getAddresses,
  'addresses',
  locationPreparator
);

export const loadCitiesOptions = getAsyncLocation(
  getCities,
  'cities',
  cityPreparator
);

export const loadAddressandlocationsOptions = getAsyncLocation(
  getAddressandlocations,
  'addresses',
  locationPreparator
);
