import React from 'react';
import styled from 'styled-components';

import bg from 'assets/landing/reason-bg.jpg'

import Title from './Title'
import ReasonsList from './ReasonsList'
import Footer from '_legacy/components/Footer';

export default React.memo(function ReasonsToJoin ({ fullpageApi }) {
  React.useEffect(() => {
    fullpageApi && fullpageApi.reBuild()
  }, [fullpageApi])
  return (
    <StyledSection className='section'>
      <StyledWrapper>
        <div className='content wrapper content-wrapper styled-wrapper'>
          <Title />
          <ReasonsList />
        </div>
        <Footer />
      </StyledWrapper>
    </StyledSection>
  );
})

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  .styled-wrapper {
    padding-top: 155px;
    margin: 0 auto auto;
    background: #292835 url(${bg}) center / cover no-repeat;
    @media only screen and (max-width: 1440px) {
      padding: 155px 40px 0;
    }
    @media only screen and (max-width: 1024px) {
      padding: 100px 24px 0;
    }
    @media only screen and (max-width: 480px) {
      padding: 100px 16px 0;
    }
  }
`
