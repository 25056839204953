import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'

import PictureWrapper from '../../components/Picture/PictureWrapper';
import RemoveButton from '../common/RemoveButton';
import Picture from '../../components/Picture/Picture';

const MediaPicture = ({ file, name }) => {
  const { setValue } = useFormContext()
  const removeHandler = (e) => {
    e.stopPropagation()
    setValue(name, null, { shouldDirty: true, shouldValidate: true })
  }

  return (
    <MediaPictureWrapper>
      <Picture file={file} />
      <RemoveButton onClick={removeHandler} />
    </MediaPictureWrapper>
  )
}


const MediaPictureWrapper = styled(PictureWrapper)`
  &:hover {
    .remove-button {
     opacity: 1; 
    }
  }
`

MediaPicture.propTypes = {
  file: PropTypes.object,
  name: PropTypes.string,
}

export default MediaPicture
