import React from 'react'
import { VIDEO_MESSAGE } from '_legacy/constants/routes'

import NewSlider from './NewSlider'
import { CardText } from './CardText'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function TopVideoMessages() {
  const t = useGetTranslate()

  return (
    <NewSlider
      title={t('home.slider.top-video-messages.title')}
      dataUrl="/offers/video-messages/top"
      dataField="topVideoMessages"
      navigatePath={VIDEO_MESSAGE}
      CardText={CardText}
    />
  )
})
