import { OrderStatus } from 'shared'


export const replaceClosedWithCompletedInOrders = order => ({
  ...order,
  status: order?.status === OrderStatus.CLOSED
    ? OrderStatus.COMPLETED
    : order?.status
})

export const mergerClosedWithCompletedInQuery = query => ({
  ...query,
  orderStatusIn: query?.orderStatusIn === OrderStatus.COMPLETED
    ? [OrderStatus.COMPLETED, OrderStatus.CLOSED]
    : query?.orderStatusIn
})

