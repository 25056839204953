import React from 'react'
import styled from 'styled-components';
import get from 'lodash/get'

import transparent from 'assets/transparent.png'

import Poster from './Poster'

import { isIos } from 'utils/isBrowser'

export default React.memo(function CustomVideo ({
  className = '', file, controls, onLoadedMetadata, autoplay=false
}) {
  if (!file) return null

  return (
    <StyledWrapper className={className}>
      <Poster sources={get(file, 'preview.sources')} />
      <video
        preload={onLoadedMetadata ? 'metadata' : 'none'}
        poster={onLoadedMetadata && transparent}
        controls={controls}
        src={get(file, 'fullUrl')}
        onLoadedMetadata={onLoadedMetadata}
        tabIndex='-1'
        webkitplaysinline={isIos()}
        playsInline={isIos()}
        x-webkit-airplay={isIos() || 'allow'}
        controlsList={isIos() || 'nodownload'}
        autoPlay={autoplay}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  background: #272835;
  video {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 1;
    outline: none;
  }
`
