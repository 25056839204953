// TODO: move to the new structure after refactore
// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// Legacy components
import MobileMenuHeading from './MobileMenuHeading'
import MobileMenuProfile from './MobileMenuProfile'
import MobileMenuList from './MobileMenuList'
import { useUser } from 'store/hooks/globalState/useUser'


const MobileMenu = ({
  mobileMenuActive,
  setMobileMenuActive,
  setMobileSwitcherActive,
}) => {
  const history = useHistory()
  const isLoggedIn = useUser(Boolean)

  // TODO: Think about a better way to close the menu when going to another page or opening hash overlay
  //  If it would cause any problems
  React.useEffect(() => {
    history.listen(() => {
      setMobileMenuActive(false)
    })
  }, [])

  const activeClass = mobileMenuActive ? 'active' : ''

  return (
    <div className={`mobile-menu ${activeClass}`}>
      <MobileMenuHeading setMobileMenuActive={setMobileMenuActive} isLoggedIn={isLoggedIn} />
      <div className="mobile-menu__body">
        { isLoggedIn ?
          <MobileMenuProfile
            setMobileSwitcherActive={() => setMobileSwitcherActive(true)}
          /> :
          <br />
        }
        <MobileMenuList setMobileMenuActive={setMobileMenuActive} />
      </div>
    </div>
  )
}

MobileMenu.propTypes = {
  mobileMenuActive: PropTypes.bool,
  setMobileMenuActive: PropTypes.func,
}

export default MobileMenu
