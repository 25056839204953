import React from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';

import {
  createReadyMadeEvent as createReadyMadeEventApi,
  getReadyMadeEventDraft as getReadyMadeEventDraftApi,
  updateReadyMadeEventDraft as updateReadyMadeEventDraftApi,
  createReadyMadeEventDraft as createReadyMadeEventDraftApi,
} from 'api/appApi/offers/readyMadeEvent';

import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useReadyMadeEvents, useSetReadyMadeEvents } from 'store/hooks/globalState/useReadyMadeEvents';
import { useStateModal } from 'store/hooks/globalState/useModal';

import { notify } from 'utils/notify';
import { OFFERINGS } from '_legacy/constants/routes';
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';
import { getFilesFromMedia } from 'utils/services/validators/library';


export function useCreateReadyMadeEvent(options = {}) {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);
  const { openExtraModal } = options


  return React.useCallback(data => {
    createReadyMadeEventApi({ data, talentId })
      .then(({ isPromotionalMediaNeeded }) => {
        // todo: remove conditional when backend fixed it
        if (isPromotionalMediaNeeded) {
          notify(t('offerings.ready-made.submit.success'));
          openAskModal({ openExtraModal });
        } else {
          navigate(`/${OFFERINGS}`, t('offerings.ready-made.submit.success'));
          openExtraModal()
        }
      })
      .catch(err => {
        console.warn(err);
        notify(t('offerings.ready-made.submit.error'), 'error');
      });
  },
  [t, talentId, navigate]);
}

export function useCreateReadyMadeEventDraft() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();

  return React.useCallback(data => {
    createReadyMadeEventDraftApi({ data, talentId })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('offerings.ready-made.draft.success'));
      })
      .catch(err => {
        console.warn(err);
        notify(t('offerings.ready-made.draft.error'), 'error');
      });
  },
  [t, talentId, navigate]);
}

export function useFetchReadyMadeEventDraft() {
  const { id: talentReadyMadeEventDraftId } = useParams();

  const t = useGetTranslate();
  const talentId = useTalentId();
  const addFilesToFilesLibrary = useAddFilesToLibrary();
  const setReadyMadeEvent = useSetReadyMadeEvents((prev, next) => next);

  return React.useCallback(() => {
    getReadyMadeEventDraftApi({ talentId, talentReadyMadeEventDraftId })
      .then(({ readyMadeEventDraft }) => {
        const media = getFilesFromMedia(readyMadeEventDraft);
        addFilesToFilesLibrary(media);
        setReadyMadeEvent(readyMadeEventDraft);
      })
      .catch((error) => {
        console.warn(error);
        notify(t('offerings.ready-made.submit.error'), 'error');
      });
  }, [talentId, setReadyMadeEvent, talentReadyMadeEventDraftId]);
}

export function useUpdateReadyMadeEventDraft() {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const talentReadyMadeEventDraftId = useReadyMadeEvents(state => get(state, 'id'));

  return React.useCallback((data) => {
    updateReadyMadeEventDraftApi({ talentId, talentReadyMadeEventDraftId, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('offerings.ready-made.draft-update.success'));
      })
      .catch(() => {
        notify(t('offerings.ready-made.draft.error'), 'error');
      });
  }, [t, talentId, talentReadyMadeEventDraftId, navigate]);
}

export function useUpdateReadyMadeEvent(options = {}) {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const talentReadyMadeEventDraftId = useReadyMadeEvents(state => get(state, 'id'));
  const { openExtraModal } = options

  return React.useCallback((data) => {
    createReadyMadeEventApi({ talentId, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('offerings.ready-made.draft-update.success'));
        openExtraModal()
      })
      .catch(() => {
        notify(t('offerings.ready-made.draft.error'), 'error');
      });
  }, [t, talentId, talentReadyMadeEventDraftId, navigate]);
}
