import React from 'react'

import {
  getUserSearchLocationHistory as getUserSearchLocationHistoryApi
} from 'api/appApi/auth/history'
import { useSetUserSearchLocationHistory } from 'store/hooks/globalState/auth/history'

export function useFetchUserSearchLocationHistory() {
  const setUserSearchLocationHistory = useSetUserSearchLocationHistory((_, next) => next)

  return React.useCallback(() => {
    getUserSearchLocationHistoryApi()
      .then(({ history }) => {
        setUserSearchLocationHistory(history.locations)
      })
  }, [setUserSearchLocationHistory])
}
