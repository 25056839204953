import { always, cond, merge, pipe, T } from 'lodash/fp'
import { statuses } from '../utils'

const fillVideoChatOrderDetailsConfig = merge({
  isShowClosed: false,
  isShowCompleted: false,
  isShowCanceled: false,
  isShowCalendar: false,
  isShowCounter: false,
})

export const getVideoChatOrderConfig = pipe(
  cond([
    [statuses.isOrderClosed, always({ isShowClosed: true })],
    [statuses.isOrderCompleted, always({ isShowCompleted: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],

    [statuses.isBookedWithTime, always({ isShowCounter: true })],
    [statuses.isBookedWithoutTime, always({ isShowCalendar: true })],

    [T, always({})],
  ]),
  fillVideoChatOrderDetailsConfig
)
