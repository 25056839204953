import React from 'react'
import debounce from 'lodash/debounce'
import { useLocation } from 'react-router-dom'

// Assets
import Icon from 'assets/icons/Icon'
import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'

// Requests
import {
  useFetchHowToVideos,
} from './state/requests'

// Store (Hooks)
import {
  useHowToVideos,
  useSetHowToVideos,
  useHowToVideosFilters,
} from './state/store'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Pagination from '_legacy/components/Table/table-components/Pagination';

const HowToVideos = () => {
  const t = useGetTranslate()

  const fetchHowToVideos = useFetchHowToVideos()
  const clearSearch = useSetHowToVideos(() => null)

  const howToVideosList = useHowToVideos()

  const [localSearch, setLocalSearch] = React.useState('')
  const handleChange = searchText => fetchHowToVideos({ ['searchLike']: searchText })
  const debounced = React.useCallback(debounce((localSearch) => {
    handleChange(localSearch)
  }, 500), [localSearch])

  const onClear = () => {
    setLocalSearch('')
  }

  React.useEffect(() => {
    debounced(localSearch)

    return () => {
      clearSearch()
    }
  }, [localSearch])

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = +query.get('page') || 1

  React.useEffect(() => {
    fetchHowToVideos({ page: page })

    return () => {
      clearSearch()
    }
  }, [page])


  return (
    <div className='how-to-videos wrapper content-wrapper'>
      <h1>{t('how-to-videos.header.title')}</h1>
      <div className='how-to-videos__search'>
        <input
          type='text'
          className='how-to-videos__search--input'
          placeholder={t('how-to-videos.search.title')}
          value={localSearch}
          onChange={(e) => setLocalSearch(e.target.value)}
        />
        {localSearch ? <Icon.Close  width={18} height={18} class="how-to-videos__svg-button" onClick={onClear} /> : <SearchIcon  />}
      </div>
      {howToVideosList &&
        <>
          <div className='how-to-videos__list'>
            {howToVideosList.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1).map(({ title, description, link },i) => {
              return (
                <div className='how-to-videos__item' key={i}>
                  <h6 className="mb-24">{title}</h6>
                  <p className="mb-12">{description}</p>
                  <a href={link} target="_blank" >
                    <span>{link}</span>
                  </a>
                  <div className='how-to-videos__item--border-line'></div>
                </div>
              )
            })}
          </div>
          <Pagination getFiltersFn={useHowToVideosFilters} />
        </>
      }
    </div>
  )
}

export default HowToVideos
