// Modules
import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import has from 'lodash/fp/has'
import isFunction from 'lodash/isFunction'

// Assets
import Icon from 'assets/icons/Icon'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { sanitizeFilterFields } from '_legacy/components/Table/utils'

// Legacy components
import SearchTalentCard from '_legacy/components/Search/SearchCardItem/SearchTalentCard'
import SearchOfferingCard from '_legacy/components/Search/SearchCardItem/SearchOfferingCard'

const isOffering = has('offerType')


const FeaturedSlider = ({
  fields,
  getFiltersFn,
  fetchPromotedListFn,
  getPromotedListFn,
}) => {
  const t = useGetTranslate()
  const swiperAPI = React.useRef(null)

  const prevRef = React.useRef(null)
  const nextRef = React.useRef(null)
  const paginationRef = React.useRef(null)

  const filters = getFiltersFn(current => sanitizeFilterFields({ current, fields }), [fields])
  const featuredData = getPromotedListFn()
  const fetchList = fetchPromotedListFn()


  React.useEffect(() => {
    fetchList({ ...filters })
  }, [filters])

  React.useEffect(() => {
    const swiper = swiperAPI?.current?.swiper
    const isResetSwiperProgerss = swiper && isFunction(swiper?.slideToLoop)

    isResetSwiperProgerss && swiper?.slideToLoop(0, 0)
  }, [featuredData, swiperAPI])

  if (featuredData?.length < 1) return null

  return (
    <div className='search-feature'>
      <h4 className='search-feature__header'>{t('search.featured.block.header')}</h4>
      <div className='search-feature__slider'>

        <div className='search-feature__nav'>
          <div ref={prevRef} className='search-feature__nav_btn search-feature__nav_btn--prev'>
            <Icon.ArrowSliderPrev />
          </div>
          <div ref={paginationRef} className='search-feature__nav_pagination' />
          <div ref={nextRef} className='search-feature__nav_btn search-feature__nav_btn--next'>
            <Icon.ArrowSliderNext />
          </div>
        </div>

        <Swiper
          ref={swiperAPI}
          spaceBetween={26}
          slidesPerView={6}
          loop={featuredData?.length >= 6}
          pagination={{ type: 'fraction' }}
          onInit={(swiper) => {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.params.pagination.el = paginationRef.current;
          }}
          breakpoints={{
            1461: { slidesPerView: 6, loop: featuredData?.length >= 6 },
            1281: { slidesPerView: 5, loop: featuredData?.length >= 5 },
            1025: { slidesPerView: 4, loop: featuredData?.length >= 4 },
            821: { slidesPerView: 3, loop: featuredData?.length >= 3 },
            576: { slidesPerView: 2, loop: featuredData?.length >= 2 },
            320: { slidesPerView: 1, loop: featuredData?.length >= 1 },
          }}
        >
          {[...(featuredData || [])].map((value, idx) => (
            <SwiperSlide key={idx} className='search-feature__slider_slide'>
              {isOffering(value)
                ? <SearchOfferingCard value={value} />
                : <SearchTalentCard value={value} />}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  )
}

export default FeaturedSlider
