import React from 'react'
import { Link, useParams } from 'react-router-dom'
import SwiperCore, { Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import isEmpty from 'lodash/isEmpty'

import 'swiper/swiper.scss'
import 'swiper/components/navigation/navigation.scss'
import 'swiper/components/pagination/pagination.min.css'

import { getSaveFullName } from 'utils/user'
import { routesList } from 'router/routesMap'
import { getMonthDayYearDate } from 'utils/date'
import { getCityOrCountry } from 'utils/location'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getAficionadoProfileReviews } from '../../state/api'

import Icon from 'assets/icons/Icon'
import Rating from '_legacy/components/Rating'
import Avatar from '_legacy/components/Avatar'


SwiperCore.use([Navigation, Pagination])

const LIMIT = 10

const ReviewSlider = () => {
  const t = useGetTranslate()
  const { plaqueNickname } = useParams()

  const prevRef = React.useRef(null)
  const nextRef = React.useRef(null)
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0)

  const [reviews, setReviews] = React.useState([])
  const [pageInfo, setPageInfo] = React.useState({
    page: 1,
    limit: LIMIT,
    count: null,
    remains: null,
  })

  const fetchReviews = ({ page }) => {
    getAficionadoProfileReviews({ page, plaqueNickname, limit: LIMIT }).then(({ reviews, pageInfo }) => {
      setReviews(prevReviews => [...prevReviews, ...reviews])
      setPageInfo(pageInfo)
    })
  }

  const onSlideChange = ({ activeIndex }) => {
    setActiveSlideIndex(activeIndex)

    if (LIMIT * pageInfo.page - 3 === activeIndex && pageInfo.remains > 0) {
      fetchReviews({ page: pageInfo.page + 1 })
    }
  }

  React.useEffect(() => {
    fetchReviews({ page: 1 })
  }, [])

  if (isEmpty(reviews)) return null

  return (
    <div className="aficionado-reviews">
      <div className="aficionado-reviews__nav">
        <div className="aficionado-reviews__title">{t('aficionado-profile.reviews-by')}</div>
        {reviews.length > 1 && (
          <div className="aficionado-reviews__controls">
            <button
              ref={prevRef}
              disabled={activeSlideIndex === 1}
              className="aficionado-reviews__nav-btn aficionado-reviews__nav-btn--prev"
            />
            <button
              ref={nextRef}
              disabled={pageInfo.count === activeSlideIndex}
              className="aficionado-reviews__nav-btn aficionado-reviews__nav-btn--next"
            />
          </div>
        )}
      </div>

      <div className="aficionado-reviews__container">
        <Swiper
          loop
          slidesPerView={1}
          onSlideChange={onSlideChange}
          onInit={swiper => {
            swiper.params.navigation.prevEl = prevRef.current
            swiper.params.navigation.nextEl = nextRef.current
            swiper.navigation.init()
            swiper.navigation.update()
          }}
        >
          {reviews.map(review => {
            const location = getCityOrCountry(review.fromTalent.location)
            return (
              <SwiperSlide key={review.id}>
                <div className="aficionado-reviews__slide">
                  <div className="aficionado-reviews__rating-date">
                    <Rating rating={review.rating} leftColor="#fff" rightColor="#626473" />
                    <span className="aficionado-reviews__date">{getMonthDayYearDate(review.date)}</span>
                  </div>
                  <blockquote className="aficionado-reviews__quote">{review.description}</blockquote>
                  <Icon.AvatarSliderPointer />
                  <div className="aficionado-reviews__sender">
                    <Avatar file={review.fromTalent?.profileImage?.file} className="aficionado-reviews__sender-avatar" />
                    <div className='aficionado-reviews__sender-info'>
                      <Link to={routesList.talentPublicPage(review?.fromTalent?.plaqueNickname)}>
                        {getSaveFullName(review?.fromTalent)}&nbsp;
                      </Link>
                      {location && <span>/&nbsp;{location}</span>}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default ReviewSlider
