import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'


//Experience Order Details
const experienceOrderKey = 'experienceOrderDetails'
const isEditableKey = 'isExperienceEditable'
const isCancelableKey = 'isExperienceCancelable'

export const useExperienceOrderDetails = createUseGlobalState(experienceOrderKey)
export const useSetExperienceOrderDetails = createUseSetGlobalState(experienceOrderKey)

export const useIsExperienceEditable = createUseGlobalState(isEditableKey)
export const useSetIsExperienceEditable = createUseSetGlobalState(isEditableKey)

export const useIsExperienceCancelable = createUseGlobalState(isCancelableKey)
export const useSetIsExperienceCancelable = createUseSetGlobalState(isCancelableKey)

//Experience Order Participants
const experienceOrderParticipants = 'experienceOrderParticipants'
const experienceOrderParticipantsFilters = 'experienceOrderParticipantsFilters'

export const useExperienceOrderParticipants = createUseGlobalState(experienceOrderParticipants)
export const useSetExperienceOrderParticipants = createUseSetGlobalState(experienceOrderParticipants)

export const useExperienceOrderParticipantsFilters = createUseGlobalState(experienceOrderParticipantsFilters)
export const useSetExperienceOrderParticipantsFilters = createUseSetGlobalState(experienceOrderParticipantsFilters)

//Experience Order Requests
const experienceOrderRequest = 'experienceOrderRequests'
const experienceOrderRequestFilters = 'experienceOrderRequestsFilters'

export const useExperienceOrderRequests = createUseGlobalState(experienceOrderRequest)
export const useSetExperienceOrderRequests = createUseSetGlobalState(experienceOrderRequest)

export const useExperienceOrderRequestsFilters = createUseGlobalState(experienceOrderRequestFilters)
export const useSetExperienceOrderRequestsFilters = createUseSetGlobalState(experienceOrderRequestFilters)

export const useGlobalIsLoading = createUseGlobalState('globalIsLoading')
export const useSetGlobalIsLoading = createUseSetGlobalState('globalIsLoading')
