import React from 'react'
import { components } from 'react-select'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const SelectControl = ({ children, ...props }) => (
  <WrapperS {...props}>
    {props?.selectProps?.IconComponent && (
      <props.selectProps.IconComponent className="_icon" />
    )}
    {children}
  </WrapperS>
)

const WrapperS = styled(components.Control)`
  ._icon {
    margin-right: 10px;
    fill: ${({ active }) => (active ? '#272835' : '#9495A7')};
  }
`

SelectControl.propTypes = {
  icon: PropTypes.element,
  children: PropTypes.node,
}

export default SelectControl
