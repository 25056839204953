import { cond, propEq, T, always } from 'lodash/fp'
import { NotificationType } from 'shared'
import './index.scss'

import ManagerInvitationNotification from './ManagerInvitationNotification'
import OrderExpiredNotification from './OrderExpiredNotification'
import OfferCompletedNotification from './OrderCompletedNotification'
import EmptyNotification from './EmptyNotification'
import ReviewReceivedNotification from './ReviewReceivedNotification';
import AdminNotification from './AdminNotification'
import NewMessageNotification from './NewMessageNotification'
import ReviewDidNotPassProfanityNotification from './ReviewDidNotPassProfanityNotification'
import EntourageNotification from './EntourageNotification'
import NewOrderNotification from './NewOrderNotification'
import BookingRequestNotification from './BookingRequestNotification'
import ReminderNotification from './RemindersNotification'
import OrderRefundedNotification from './OrderRefundedNotification'
import StripeConnectNotification from './StripeConnectNotification'
import StripeSuccessfulRefundNotification from './StripeSuccessfulRefundNotification'
import OrderCancelledNotification from './OrderCancelledNotification'

export const notificationConfig = cond([
  [propEq('type', NotificationType.PROFILE_MANAGER_INVITATION), ManagerInvitationNotification],
  [propEq('type', NotificationType.BOOKING_REQUEST), always(BookingRequestNotification)],
  [propEq('type', NotificationType.ORDER_EXPIRED), always(OrderExpiredNotification)],
  [propEq('type', NotificationType.OFFER_COMPLETED), always(OfferCompletedNotification)],
  [propEq('type', NotificationType.REVIEW_RECEIVED), always(ReviewReceivedNotification)],
  [propEq('type', NotificationType.NEWS), always(AdminNotification)],
  [propEq('type', NotificationType.PROMO), always(AdminNotification)],
  [propEq('type', NotificationType.NEW_MESSAGE), always(NewMessageNotification)],
  [propEq('type', NotificationType.REVIEW_DID_NOT_PASS_PROFANITY), always(ReviewDidNotPassProfanityNotification)],
  [propEq('type', NotificationType.ENTOURAGE), always(EntourageNotification)],
  [propEq('type', NotificationType.NEW_ORDER), always(NewOrderNotification)],
  [propEq('type', NotificationType.ORDER_REMINDER), always(ReminderNotification)],
  [propEq('type', NotificationType.ORDER_REFUNDED), always(OrderRefundedNotification)],
  [propEq('type', NotificationType.STRIPE_CONNECT), always(StripeConnectNotification)],
  [propEq('type', NotificationType.STRIPE_SUCCESSFUL_REFUND), always(StripeSuccessfulRefundNotification)],
  [propEq('type', NotificationType.ORDER_CANCELLED), always(OrderCancelledNotification)],
  [T, always(EmptyNotification)],
])
