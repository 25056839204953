import React from 'react'
import cx from 'classnames'

import eventPath from 'utils/eventPath'
import Icon from 'assets/icons/Icon'


const SelectFormItem = ({ placeholder, options, value, onChange, customTitle, header, subtitle }) => {
  const dropdownRef = React.useRef()
  const [isDropDownOpen, setIsDropDownOpen] = React.useState(false)
  const toggleDropDown = React.useCallback(() => setIsDropDownOpen(prev => !prev), [])

  const closeDropDown = React.useCallback((e) => {
    if (eventPath(e).includes(dropdownRef.current)) return

    setIsDropDownOpen(false)
  }, [dropdownRef, isDropDownOpen])

  const onChangeWrapper = React.useCallback((option, event) => {
    if (option.disabled) {
      event.stopPropagation()
      return
    }

    onChange(option.value)
  }, [onChange])

  const title = React.useMemo(() => customTitle || options.find(option => option.value === value)?.label || placeholder,
    [customTitle, options, placeholder]
  )

  React.useEffect(() => {
    window.addEventListener('click', closeDropDown)
    return () => window.removeEventListener('click', closeDropDown)
  }, [])

  return (
    <div className="public-view__fixed-item" onClick={toggleDropDown} ref={dropdownRef}>
      <h2>
        <span>{header}</span>
      </h2>
      <p>{title}</p>
      <small>{subtitle}</small>
      <Icon.ArrowDownSelectMenu className={isDropDownOpen && 'rotate'} />

      {isDropDownOpen && (
        <div className='public-select-list'>
          {options.map(option => (
            <div
              key={option.value}
              className={cx('public-select-list__item', {
                'public-select-list__item--disabled': option.disabled
              })}
              onClick={(event) => onChangeWrapper(option, event)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default SelectFormItem;
