import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const FAQsTab = ({ clickHandler, header, isActive, content }) => {
  const classes = classnames({
    hidden: !isActive,
  });

  return (
    <div onClick={clickHandler} className="faqs-tab">
      <h3>{header}</h3>
      <p className={classes} dangerouslySetInnerHTML={{ __html: content }} />
      <div className="border" />
    </div>
  );
};

FAQsTab.propTypes = {
  clickHandler: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  content: PropTypes.string.isRequired,
};

export default FAQsTab;
