import React from 'react';
import Input from '../Input';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFormContext } from 'react-hook-form';

const FirstName = (props) => {
  const t = useGetTranslate();
  const { trigger } = useFormContext();

  return (
    <Input
      placeholder={t('register-user.form.first-name.placeholder')}
      name='firstName'
      label={t('register-user.form.first-name.label')}
      autocomplete="cc-name"
      isRequired
      onChange={() => trigger('password')}
      {...props}
    />
  );
};

export default FirstName;
