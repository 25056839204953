// Modules
import React from 'react'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const EmptyChatsList = () => {
  const t = useGetTranslate()

  return (
    <div className="empty-chats-list">
      <p>{t('chats.chats-list-is-empty')}</p>
    </div>
  )
}

export default EmptyChatsList
