import React from 'react'
import { useHistory } from 'react-router-dom'
import { errorNames } from 'shared'

import { bookVoiceOverLesson as bookVoiceOverLessonApi } from './api.js'
import * as r from '_legacy/constants/routes'
import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const offersErrors = errorNames.offers

export function useBookVoiceOverLessons(setLoader) {
  const t = useGetTranslate()
  const history = useHistory()

  return React.useCallback(
    ({ offerVoiceOverLessonId, userFilesTokens, offeringType }) => {
      setLoader && setLoader(true)
      bookVoiceOverLessonApi({ offerVoiceOverLessonId, userFilesTokens })
        .then(({ order }) =>
          history.push(
            `/${r.OFFERINGS}/${offeringType}/${order.id}/${r.PURCHASE}`
          )
        )
        .catch(({ name }) => {
          //TODO add proper error handling
          if (name === offersErrors.BOOK_OCCURRENCES_OVERLAP_ERROR) {
            notify(t('lessons.booking.errors.book-already-has-order'), 'error')
          }
          if (name === offersErrors.BOOK_OWN_OFFER_NOT_ALLOWED_ERROR) {
            notify(t('lessons.booking.errors.cant-book-own-lesson'), 'error')
          }
          console.warn(name)
        })
        .finally(() => setLoader && setLoader(false))
    },
    [history]
  )
}
