import React from 'react';
import { useParams } from 'react-router';

import {
  fetchReadyMadeEventLessonsDetailsHeader as fetchHeader,
  fetchReadyMadeEventLessonsDetailsOrdersList as fetchOrders,
  fetchReadyMadeEventLessonsDetailsProductList as fetchProduct,
  fetchFilesArchive,
} from './api.js'

import {
  useSetReadyMadeEventLessonsDetailsHeader as useSetHeader,
  useSetReadyMadeEventLessonsDetailsOrderTable as useSetOrderTable,
  useSetReadyMadeEventLessonsDetailsOrderFilters as useSetOrderFilters,
  useSetReadyMadeEventLessonsDetailsProductTable as useSetProductTable,
  useSetReadyMadeEventLessonsDetailsProductFilters as useSetProductFilters,
} from './store.js'
import { useTalentId } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import normalizeParams from 'utils/normalizeParams'

// RoomHeader details
export function useFetchReadyMadeEventLessonsDetailsHeader() {
  const { orderId: id } = useParams()
  const talentId = useTalentId()
  const setHeader = useSetHeader((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setHeader(null);
    fetchHeader({ talentId, id })
      .then(({ occurrence }) => {
        setHeader({ occurrence })
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [id, setHeader]);
}

// Orders table and filters
export const useFetchReadyMadeEventLessonsDetailsOrderTable = () => {
  const setFilters = useSetOrderFilters((prev, next) => ({
    ...prev,
    ...next,
  }))
  const setTable = useSetOrderTable((_, next) => next)

  const { orderId: id } = useParams();

  return React.useCallback(
    ({ ...options }) => {
      const query = normalizeParams(options)
      setTable(null)
      fetchOrders({ id, query })
        .then(({ orders, pageInfo }) => {
          setFilters(pageInfo)
          setTable(orders)
        })
        .catch(error => console.warn(error))
    },
    [setTable, setFilters]
  )
}

// Product table and filters
export const useFetchReadyMadeEventLessonsDetailsProductTable = () => {
  const setFilters = useSetProductFilters((prev, next) => ({
    ...prev,
    ...next,
  }))
  const setTable = useSetProductTable((_, next) => next)

  const { orderId: id } = useParams();

  return React.useCallback(
    ({ ...options }) => {
      const query = normalizeParams(options)
      setTable(null)
      fetchProduct({ id, query })
        .then(({ uploads, pageInfo }) => {
          setFilters(pageInfo)
          setTable(uploads)
        })
        .catch(error => console.warn(error))
    },
    [setTable, setFilters]
  )
}

// Download multiple files as an archive
export const bulkFileDownload = files => keys => {
  const query = normalizeParams({
    keys: files
      .filter(({ id }) => keys.includes(id))
      .map(({ key }) => key),
    archiveName: 'product-files',
  })

  fetchFilesArchive({ query })
    .then(({ location }) => {
      window.open(location, '_blank')
    })
    .catch(error => console.warn(error))
}
