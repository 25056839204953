import cond from 'lodash/cond';
import constant from 'lodash/constant';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const isError = ({ isError }) => isError;
const isOk = ({ isError }) => !isError;

const getErrorListItemColor = cond([
  [isOk, constant('green')],
  [isError, constant('red')],
]);

const PasswordErrorListItem = styled.li`
    svg {
      flex-shrink: 0;
      margin-top: 3px;
      margin-right: 5px;
    }
    align-items: flex-start;
    display: flex;
    font-family: Montserrat;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    transition: color .3s linear;
    color: ${getErrorListItemColor};
    ${p => p};
`;

PasswordErrorListItem.propTypes = {
  isDirty: PropTypes.bool,
  isError: PropTypes.bool,
  isFocused: PropTypes.bool,
  isTouched: PropTypes.bool,
};

export default PasswordErrorListItem;
