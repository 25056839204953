import React, { useState } from 'react'

import Icon from 'assets/icons/Icon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default function ChatInput({ conversation, className }) {
  const t = useGetTranslate()

  const minRows = 1;
  const maxRows = 4;
  const textareaLineHeight = 14 * 1.86;

  const [message, setMessage] = useState('')
  const [rows, setRows] = useState(1)

  const isValidMessage = /\S/.test(message)

  const handleChange = event => {
    const previousRows = event.target.rows;
    event.target.rows = minRows;

    const currentRows = ~~(event.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      event.target.rows = currentRows;
    }

    if (currentRows >= maxRows) {
      event.target.rows = maxRows;
      event.target.scrollTop = event.target.scrollHeight;
    }

    setMessage(event.target.value)
    setRows(currentRows < maxRows ? currentRows : maxRows)
  }

  const handleSendMessage = message => {
    if (isValidMessage) {
      conversation.sendMessage(message.trim())
      setMessage('')
      setRows(minRows)
    }
  }

  const handleKeyPress = event => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      handleSendMessage(message);
    }
  }

  return (
    <div className={`chat-input ${className}`}>
      <textarea
        className="chat-input__field"
        rows={rows}
        value={message}
        placeholder={t('chats.chat.input-placeholder')}
        onChange={handleChange}
        onKeyDown={handleKeyPress}
        autoFocus={true}
      />

      <button
        disabled={!isValidMessage}
        className="chat-input__button"
        onClick={() => handleSendMessage(message)}
      >
        <Icon.Send className="chat-input__icon" />
      </button>
    </div>
  )
}
