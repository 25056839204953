/* eslint-disable */
//TODO chack if t in 71 line is still needed
import * as passwordValidator from 'password-validator';
import { reduce } from 'lodash';

export const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const backupEmailValidator = (email, backupEmail, error) => {
  if (email === backupEmail) {
    return error;
  }
  return true;
};

export const passwordErrors = {
  length: 'shared.password.errors.length',
  forbidden: 'shared.password.errors.forbidden',
  min: 'shared.password.errors.min',
  uppercase: 'shared.password.errors.uppercase',
  lowercase: 'shared.password.errors.lowercase',
  digits: 'shared.password.errors.digits',
};

export const simplePasswordValidation = (t, password) => {
  const passwordValidatorSchema = new passwordValidator();
  passwordValidatorSchema
    .is()
    .min(8)
    .is()
    .max(100)
    .has()
    .uppercase()
    .has()
    .lowercase()
    .has()
    .digits(1)
    .has()
    .oneOf('~!@#$%^&*_-+=`|(){}[]:;"\'<>,./');

  const validationResult = passwordValidatorSchema.validate(password, {
    list: true,
  });

  if (validationResult.length > 1) {
    return t(passwordErrors[validationResult[0]]);
  }

  // return true;
};

export const forbiddenWordsValidation = (t, forbiddenWords = []) => word => {
  const condition = forbiddenWords.find(
    w => word.toLowerCase().indexOf(w.toLowerCase()) !== -1,
  );

  if (condition) {
    return t(passwordErrors.forbidden);
  }
  // return true;
};

export const passwordValidation = (password, email, firstName, lastName) => {
  // get first and second name
  // get email to "@"
  const forbiddenWords = [
    firstName,
    lastName,
    email.slice(0, email.indexOf('@')),
  ];

  const validators = [
    simplePasswordValidation,
    forbiddenWordsValidation(t, forbiddenWords),
  ];

  const error = reduce(
    validators,
    (value, validator) => value || validator(t, password),
    false
  );

  return error;
};

export const rePasswordValidation = (rePassword, password, error) => {
  if (password !== rePassword) return error;
  return true;
};
