import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { useFullscreen } from 'store/hooks/useFullscreen'
import SlideMachineList from './SlideMachineList';

const Machine = ({
  isMirroredInside = false,
  size,
  children: slides,
  currentSlideIndex,
}) => {
  const isFullscreen = useFullscreen()
  const classNames = classnames('tbv-slider-slide-machine', {
    fullscreen: isFullscreen,
  })

  return (
    <div
      className={classNames}
      attr-size={size}
      attr-mirrored-inside={String(isMirroredInside)}
    >
      <SlideMachineList
        currentSlideIndex={currentSlideIndex}
        size={size}
        isMirroredInside={isMirroredInside}
      >
        {slides}
      </SlideMachineList>
    </div>
  );
};

Machine.propTypes = {
  isMirroredInside: PropTypes.bool.isRequired,

  size: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
};

export default Machine;
