import React from 'react'
import get from 'lodash/get'
import keys from 'lodash/keys'
import PropTypes from 'prop-types'
import { TalentOffersType } from 'shared'

import { ReactComponent as LiveVideoChats } from 'assets/offerings-icons/live-video-chats.svg'
import { ReactComponent as VideoMessage } from 'assets/offerings-icons/video-message.svg'
import { ReactComponent as Lessons } from 'assets/offerings-icons/lessons.svg'
import { ReactComponent as VideoMessageForCommercialUse } from 'assets/offerings-icons/video-message-for-commercial-use.svg'
import { ReactComponent as ReadyMadeLesson } from 'assets/offerings-icons/ready-made-lesson.svg'
import { ReactComponent as VirtualEvent } from 'assets/offerings-icons/virtual-event.svg'
import { ReactComponent as InPersonExperience } from 'assets/offerings-icons/in-person-experience.svg'

export const offeringIcons = {
  [TalentOffersType.VIDEO_CHAT]: LiveVideoChats,
  [TalentOffersType.VIDEO_MESSAGE]: VideoMessage,
  [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: Lessons,
  [TalentOffersType.VIRTUAL_LESSON]: Lessons,
  [TalentOffersType.LIVE_IN_PERSON_LESSON]: Lessons,
  [TalentOffersType.FOR_COMMERCIAL_USE]: VideoMessageForCommercialUse,
  [TalentOffersType.READY_MADE_LESSON]: ReadyMadeLesson,
  [TalentOffersType.VIRTUAL_EVENT]: VirtualEvent,
  [TalentOffersType.IN_PERSON_EXPERIENCE]: InPersonExperience,
}

const DefaultIcon = () => <div />

const OfferIcons = ({ type, ...props }) => {
  const Icon = get(offeringIcons, type, DefaultIcon)
  return <Icon {...props} />
}

OfferIcons.propTypes = {
  type: PropTypes.oneOf(keys(offeringIcons)).isRequired,
}

export default OfferIcons
