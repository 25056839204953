import React from 'react'
import { cond, compose, concat, always,  __, T, prop, propEq } from 'lodash/fp'

import LineString from '../common/LineString'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getMergedOfferTranslateKey } from 'utils/offer'

export default React.memo(function UsedIn ({ isLoading, offers }) {
  const t = useGetTranslate()

  const getUsedInLabel = cond([
    [
      propEq('length', 0),
      always('shared.words.na')
    ],
    [
      propEq('length', 1),
      compose(getMergedOfferTranslateKey, prop([0, 'offerType']))
    ],
    [
      T,
      compose(concat(__, ` ${t('table.filters.offerings')}`), prop('length'))
    ],
  ])

  return (
    <LineString isLoading={isLoading} str={t(getUsedInLabel(offers))} />
  )
})
