// Modules
import React from 'react'
import PropTypes from 'prop-types'

// Utils
import { formatSystemMessage } from '_legacy/components/Chats/helpers'


const ActiveChatSystemMessage = ({
  chatInfo,
  message,
  currentUserMessage,
  currentUserId,
  messageAuthorDetails,
}) => {
  const formattedMessage = formatSystemMessage({
    chatInfo,
    message,
    currentUserId,
    currentUserMessage,
    messageAuthorDetails,
  })

  return (
    <div className="chat-message__message from-system">
      {formattedMessage.messageWithMarkup || formattedMessage.message}
    </div>
  )
}

ActiveChatSystemMessage.propTypes = {
  chatInfo: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  currentUserMessage: PropTypes.bool.isRequired,
  currentUserId: PropTypes.string.isRequired,
  messageAuthorDetails: PropTypes.object.isRequired,
}

export default ActiveChatSystemMessage
