// TODO: Refactor wrappers and forms according to DRY after project ends
import React from 'react'
import { string, number, shape, arrayOf, oneOfType, object } from 'prop-types'
import { toNumber, isEmpty, get, castArray } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'

import { FileType, TypesOfDays } from 'shared'

import { getFullName } from 'utils/user'
import { getFullMonthWithTime } from 'utils/date.js'
import { getCharityLabel } from 'utils/charities.js'
import { fromSToM } from 'utils/convertTime.js'
import { prepareDate, getTimezoneAbbr } from 'utils/services/timezone/'
import { getUserTimeZone } from 'utils/user.js'

import { getLessonsPreviewNoteText } from '../../utils.js'

import { useUser } from 'store/hooks/globalState/useUser.js'
import { useModal } from 'store/hooks/useModal.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'

import { useBookLiveVirtualLessons } from './state/requests.js'

import Price from '_legacy/components/Price/'
import BookNowButton from '_legacy/components/Button/BookNowButton.js'
import MultiSelectLibraryModal from '_legacy/components/Library/MultiSelectLibrary/components/MultiSelectLibraryModal.js'

import OrderedRulesList from 'components/OrderedRulesList/'
import BookingCalendar from 'components/Calendars/BookingCalendar'
import CalendarButton from '../../../Buttons/CalendarButton'

const { VIDEO } = FileType

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  BOTH,
} = TypesOfDays


const LiveVirtualLessonBook = ({
  lengthOfTime,
  charities,
  price,
  percentToCharity,
  specialInstructions,
  rules,
  daysTimesType,
  talent,
  header,
  id,
  offeringType,
}) => {
  const [isOpen, openModal, closeModal] = useModal()
  const [isTalentCalendarModalOpened, , closeTalentCalendarModal, toggleTalentCalendarModal] = useModal(false)
  const [orderSlot, setOrderSlot] = React.useState(null)

  const t = useGetTranslate()
  const bookLiveVirtualLessons = useBookLiveVirtualLessons()

  const methods = useForm({ shouldUnregister: false })
  const form = methods.watch()
  const selectedFileLength = get(form, 'userFilesTokens.length', 0)
  const selectedDate = get(form, 'date')
  const lengthOfTimeInMinutes = Math.round(fromSToM(lengthOfTime))

  const timezone = useUser(getUserTimeZone)
  const timezoneAbbr = getTimezoneAbbr(timezone)
  const watchedStartTime = get(form, 'startTime')
  const watchedEndTime = get(form, 'endTime')

  const startTime = prepareDate(watchedStartTime, timezone)
  const endTime = prepareDate(watchedEndTime, timezone)


  const onConfirm = event => {
    methods.setValue('date', event.start)
    methods.setValue('startTime', event.start)
    methods.setValue('endTime', event.end)

    closeTalentCalendarModal()
  }

  const onSubmit = () => {
    bookLiveVirtualLessons({
      offerLiveVirtualLessonId: id,
      userFilesTokens: castArray(methods.getValues('userFilesTokens')),
      offeringType,
      startTime: startTime ? startTime : null,
      endTime: endTime ? endTime : null,
    })
  }

  const isAnyAvailable = daysTimesType === ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR
  const isBoth = daysTimesType === BOTH
  const isDateSelected = !!selectedDate

  const isShowDateTime = isAnyAvailable || isBoth
  const isBookButtonDisabled = isAnyAvailable && !isDateSelected

  const formattedDateTime = watchedStartTime ? `${getFullMonthWithTime(watchedStartTime)} ${timezoneAbbr}` : null

  return (
    <div>
      <div className="lesson-preview">
        <OrderedRulesList rules={rules} />

        {specialInstructions && (
          <div className="special-instructions">
            <h6>{t('lessons.preview.special-instructions')}</h6>
            <p>{specialInstructions}</p>
          </div>
        )}

        <div className="lesson-preview__inner">
          <div className="time-length">
            <span className="label">{t('lessons.preview.length-of-time')} &nbsp;</span>
            <span className="value">
              {lengthOfTimeInMinutes} {t('lessons.preview.minutes')}
            </span>
          </div>

          {toNumber(percentToCharity) > 0 && (
            <div className="charity-wrap">
              <span className="label">{t('lessons.preview.charity-proceed')} &nbsp;</span>
              <span className="value">{percentToCharity}%</span>
            </div>
          )}

          {!isEmpty(charities) && (
            <div className="charity-wrap">
              <span className="label">{t('lessons.preview.charity-beneficiary')} &nbsp;</span>
              {charities.map((charity) => (
                <p className="value" key={charity.id}>{getCharityLabel(charity)};&nbsp;</p>
              ))}
              <p className='charities-tip'>{t('shared.charities.tip')}</p>
            </div>
          )}
        </div>

        <Price price={Number(price)} withCurrency />

        <div className="lesson-preview__note">
          <p dangerouslySetInnerHTML={{ __html: t(getLessonsPreviewNoteText(daysTimesType)) }} />
        </div>

        {isShowDateTime && <CalendarButton onClick={toggleTalentCalendarModal} time={formattedDateTime} theme='light' />}

        <BookNowButton
          typeButton="submit"
          classBtn="lessons__button"
          paddingVertical="13"
          handleOnClick={openModal}
          disabled={isBookButtonDisabled}
        />
      </div>

      <FormProvider {...methods}>
        <MultiSelectLibraryModal
          name={'userFilesTokens'}
          isOpen={isOpen}
          onSubmit={onSubmit}
          closeModal={closeModal}
          maxSelectItems={5}
          libraryModalProps={{
            subHeader: t('lessons.book-modal.sub-header'),
            enableTypes: VIDEO,
            submitBtnDisabled: selectedFileLength === 0,
          }}
          dropZoneProps={{
            maxSize: 1000,
            accept: VIDEO
          }}
        />

        <BookingCalendar
          isOpen={isTalentCalendarModalOpened}
          onClose={closeTalentCalendarModal}
          onConfirm={onConfirm}
          eventDuration={lengthOfTime}
          talentId={talent.id}
          eventTitle={`${t(header)} (${getFullName(talent)})`}
          minBookingTimeBeforeExperience={0}
          orderSlot={orderSlot}
          setOrderSlot={setOrderSlot}
        />

      </FormProvider>
    </div>
  )
}

LiveVirtualLessonBook.propTypes = {
  lengthOfTime: string,
  charities: arrayOf(
    shape({
      label: string,
    })
  ),
  price: string,
  percentToCharity: oneOfType([string, number]),
  specialInstructions: string,
  rules: string,
  daysTimesType: string,
  talent: object,
  header: string,
  id: oneOfType([string, number]),
  offeringType: string,
}

export default LiveVirtualLessonBook
