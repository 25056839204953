// Base imports
import React from 'react'
import prop from 'lodash/fp/prop'

// Assets
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'
import { ReactComponent as ShareIcon } from 'assets/share-icon.svg'

// Requests
import {
  useFetchVideoMessageDownloadPage as fetchDetails
} from './state/request'

// Store (Hooks)
import { Trans,useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useVideoMessageDownloadPage as getDetails,
  useSetVideoMessageDownloadPage as setDetails,
} from './state/store'

// Legacy components
import PublicViewBorderedCard from '_legacy/components/PublicView/PublicViewBorderedCard'
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicDescriptionOneLine from '_legacy/components/PublicView/PublicDescriptionOneLine'
import PublicSlider from '_legacy/components/PublicSlider/'
import Button from '_legacy/components/Button'
import PageLoader from '_legacy/common/Template/PageLoader'

import { getFullName } from 'utils/user'


const VideoMessageDownloadPage = () => {
  const t = useGetTranslate()

  const details = getDetails(prop('previewInfo'))
  const clearDetails = setDetails(() => null)
  const fetchDownloadDetails = fetchDetails()

  React.useEffect(() => {
    fetchDownloadDetails()
    return () => {
      clearDetails()
    }
  }, [])

  if (!details) return <PageLoader />

  const {
    requestor,
    upload,
    talent,
  } = details

  const handleDownload = () => window.open(upload?.fullUrl, '_blank')
  const talentFullName = getFullName(talent)

  return (
    <div className="video-message-download mb-24">
      <PublicHeadingBlock
        name={`${t('download-page.video-message.title')} ${talentFullName}`}
        className="inverted short"
      />
      <div className="video-message-download__wrapper wrapper content-wrapper">
        <PublicViewBorderedCard borderColor={'gradient'}>
          <div className="slider">
            {upload && <PublicSlider covers={[upload]} />}
          </div>
          <div className="video-message-download__description">
            <PublicDescriptionOneLine>
              <p>
                <Trans
                  tKey="download-page.video-message.text"
                  phs={[
                    { ph: '{{aficionadoName}}', value: requestor?.name },
                    {
                      ph: '{{talentName}}',
                      value: talentFullName,
                    },
                  ]}
                />
              </p>
            </PublicDescriptionOneLine>
            <div className="video-message-download--button-wrapper">
              <Button
                text={t('ready-made-event-lesson-details.product.actions.download')}
                typeButton="button"
                handleOnClick={handleDownload}
                paddingVertical={4}
                fontSize="0.75"
                className="vm-download-button"
                iconComponent={<DownloadIcon />}
              />
              <Button
                primary
                text={t('shared.words.share')}
                typeButton="button"
                handleOnClick={() => alert('action is not implemented')}
                paddingVertical={9}
                fontSize="0.75"
                iconComponent={<ShareIcon />}
              />
            </div>
          </div>
        </PublicViewBorderedCard>
      </div>
    </div>
  )
}

export default VideoMessageDownloadPage
