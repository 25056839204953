import React from 'react'
import PropTypes from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'

// components
import ProfileManager from './components/ProfileManager'
import RequiredLabel from '../shared/components/RequiredLabel'
import Nickname from '../shared/components/Nickname'
import PhoneConfirm from '../shared/components/PhoneConfirm'
import DateOfBirth from '../shared/components/DateOfBirth'
import Email from './components/Email'
import BackupEmail from '../shared/components/BackupEmail'
import MultiLocationSelect from '../shared/components/MultiLocationSelect'
import NotificationPreference from './components/NotificationPreference'
import DataProcessingConsent from '../shared/components/DataProcessingConsent'
import SubmitButton from '../shared/components/SubmitButton'
import HeadshotImage from '../shared/components/HeadshotImage'
import FirstName from './components/FirstName'
import LastName from './components/LastName'
import RegisterPasswordBlock from '../shared/components/Password/RegisterPasswordBlock'
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'
import IDVerification from '_legacy/components/RegisterForms/shared/components/IDVerification'

// utils
import { defaultValues } from './utils/defaultValues'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import './index.scss'

const RegistrationForm = React.memo(function RegistrationForm({ isLoading, onSubmit, profileManagersOptions }) {
  const t = useGetTranslate()
  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    criteriaMode: 'all',
    shouldUnregister: false,
  })

  return (
    <FormProvider {...methods}>
      <form className="registration-user-form white-background" onSubmit={methods.handleSubmit(onSubmit)}>
        <RequiredLabel />
        <ProfileManager options={profileManagersOptions} />
        <FirstName />
        <LastName />
        <Nickname />
        <IDVerification />
        <HeadshotImage name="profileImage" />
        <Email />
        <BackupEmail />
        <RegisterPasswordBlock />
        <MultiLocationSelect />
        <DateOfBirth />
        <PhoneConfirm isEditable={false} />
        <NotificationPreference />
        <DataProcessingConsent withTalent />
        <SubmitButton text={t('register-talent.form.submit-button.register-user')} disabled={isLoading} />
        <MissedData />
      </form>
    </FormProvider>
  )
})

RegistrationForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
  profileManagersOptions: PropTypes.array,
}

export default RegistrationForm
