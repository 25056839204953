// TODO: refactor this component structure and styles
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'
import get from 'lodash/get'
import identity from 'lodash/identity'

import { useModal } from 'store/hooks/useModal'

import Icon from 'assets/icons/Icon'
import ModalPortal from 'portals/ModalPortal'
import PublicRadioInputItem from './PublicRadioInputItemContext'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

const PublicFlexibleFormItem = ({ title, name, items = [] }) => {
  const { watch, setValue } = useFormContext()
  const [isModalOpen, openModal, closeModal] = useModal()
  const selectedItemValue = watch(name)
  const selectedItem = items.find(i => i.value == selectedItemValue)
  const shouldOpenModal = items.length > 1
  const modalHandler = shouldOpenModal ? openModal : identity

  React.useEffect(() => {
    if (!selectedItem) setValue(name, String(get(items, [0, 'value'], '')))
  }, [selectedItem])

  return (
    <>
      <StyledInfoListItem isMultiple={shouldOpenModal} onClick={modalHandler}>
        <PopperTooltip Component={selectedItem?.title || selectedItem?.label} placement={'bottom'}>
          <h2>
            <span>{title}</span>
          </h2>
          <p>{selectedItem?.title || selectedItem?.label}</p>
          {shouldOpenModal && <Icon.ArrowDownSelectMenu />}
        </PopperTooltip>
      </StyledInfoListItem>

      <ModalPortal showCloseButton isOpen={isModalOpen} onClose={closeModal}>
        <StyledInfoListItemModal style={{ maxWidth: 500 }}>
          <h1>{title}</h1>
          <div style={{ width:'100%', height:'1px', backgroundColor: '#D5DFEC', marginTop:'10px'  }}></div>
          <StyledInfoListItemWrapper>
            {items.map(item => (
              <>
                <PublicRadioInputItem
                  key={item.value}
                  name={name}
                  value={String(item.value)}
                  label={item.label}
                  title={item.title}
                />
                <div style={{ width:'100%', height:'1px', backgroundColor: '#D5DFEC', marginTop:'10px', marginBottom:'10px'  }}></div>
              </>
            ))}
          </StyledInfoListItemWrapper>
        </StyledInfoListItemModal>
      </ModalPortal>
    </>
  )
};

export const StyledInfoListItemWrapper = styled.div`
  text-align: left;
  width: 100%;
  margin: 12px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
`;

export const StyledInfoListItemModal = styled.div`
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 20px 60px rgba(0,0,0,.3);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;

  h1 {
    font-family: ${({ theme: { fontFamily } }) => fontFamily.primary};
    font-weight: ${({ theme: { fontWeight } }) => fontWeight.black};
    font-size: 32px;

    @media all and (max-width: 767px) {
      font-size: 21px;
    }
  }
`

export const StyledInfoListItem = styled.div`
  border: 1px solid #ffffff;
  border-top-width: 0;
  text-align: center;
  flex: 1 1;
  height: 80px;
  position: relative;
  h2 {
    display: table;
    width: 100%;
    margin-bottom: -10px;
    &:before,
    &:after {
      content: '';
      border-top: 1px solid #ffffff;
      width: 25%;
    }
    &:before,
    &:after,
    span {
      display: table-cell;
      white-space: nowrap;
    }
    span {
      font-size: 1.5em;
      color: #ffffff;
      text-align: center;
      padding: 0 10px;
      position: relative;
      top: -16px;
      @media only screen and (max-width: 767px) {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;
        max-width: 100%;
        font-size: 1.175em;
      }
    }
  }
  p {
    font-family: Montserrat;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.5em;
    margin-top: 8px;
    color: #ffffff;
    max-height: 36px;
    padding: 0 42px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: ${({ isMultiple }) => isMultiple ? 'pointer' : 'default'};
    @media only screen and (max-width: 767px) {
      padding: 0 42px;
      font-size: 16px;
    }
    span {
      color: #b9baca;
    }
  }
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    height: 60px;
  }
  svg {
    stroke: #ffffff;
    position: absolute;
    top: 50%;
    right: 20px;
  }
`
PublicFlexibleFormItem.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  items: PropTypes.array,
};

export default PublicFlexibleFormItem;
