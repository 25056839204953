import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { OrderStatus } from 'shared'

import tableComponents from '../types/config'
import ActionDropdown from './common/ActionDropdown'
import TableRowCheckbox from './common/TableRowCheckbox'

export default function DesktopRow ({
  isLoading, tableName, tableConfig, actionsConfig, bulkActionsConfig, row, withRejected,
}) {
  const rowId = get(row, 'id')
  const isRejected = row?.status === OrderStatus.REJECTED
  let isSelected = false
  if (bulkActionsConfig) {
    isSelected = bulkActionsConfig.getFilters(current => get(current, 'selected', []).find(item => (item === rowId)), [rowId])
  }
  const parsedRow = React.useMemo(() => {
    return tableConfig.map(({ type }, i) => {
      const Component = tableComponents[tableName][type]
      if (!Component) {
        console.warn(`Component with type: ${type} not implemented`)
        return null
      }
      return (
        <div key={i} className={`col col-${i + 1}`}>
          <Component {...row} />
        </div>
      )
    })
  }, [tableName, tableConfig, row])
  return (
    <StyledWrapper
      count={tableConfig.length}
      isSelected={isSelected}
      actionsConfig={actionsConfig}
      isRead={row.isRead}
    >

      {/* TODO: Pass show/hide checkbox prop from the top component after reimplementing data flow within tables (pass real data, not getters/setters from top) */}
      {bulkActionsConfig && (
        <TableRowCheckbox
          rowId={rowId}
          isLoading={isLoading}
          bulkActionsConfig={bulkActionsConfig}
          disabled={withRejected ? false : isRejected}
        />
      )}
      <div className={`row-content ${tableName}-row`}>
        {parsedRow}
      </div>
      {actionsConfig && (
        <ActionDropdown
          isLoading={isLoading}
          rowId={rowId}
          actionsConfig={actionsConfig}
          row={row}
        />
      )}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  ${({ isRead }) => isRead && 'background: #F2F2F6'};
  ${({ isSelected }) => isSelected && 'background: #d5dfec'};
  .row-content {
    width: 100%;
    display: grid;
    grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
    grid-gap: 5px;
    align-items: center;
  }
  padding-left: 34px;
  ${({ actionsConfig }) => actionsConfig && 'padding-right: 16px;'}
  border-bottom: solid 1px ${({ theme: { colors } }) => colors.ultraLightBlue};
`
