import isEmpty from 'lodash/isEmpty'

export const biographiesInitialState = [
  { header: '', subheader: '', description: '' }
]

export const getBiographiesAdapter = (biographies) => {
  return isEmpty(biographies) ? biographiesInitialState : biographies
}

export const submitBiographiesAdapter = (biographies) => {
  return biographies
    ? biographies
      .filter(b => b.header || b.subheader || b.description)
      .map((b, index) => ({ ...b, index }))
    : undefined
}
