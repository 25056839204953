import React from 'react';
import styled from 'styled-components';
import './index.scss'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const PurposeLocationInfo = () => {
  const t = useGetTranslate();

  return (
    <div>
      <StyledCardItem>
        <h4>{t('experience.card.purpose-location.heading')}</h4>
      </StyledCardItem>
      <StyledPurposeLocation>
        <ul className="list">
          <li className="list__item">{t('experience.card.purpose-location.point-1')}
          </li>
          <li className="list__item">{t('experience.card.purpose-location.point-2')}
          </li>
          <li className="list__item">{t('experience.card.purpose-location.point-3')}
          </li>
          <li className="list__item">{t('experience.card.purpose-location.point-4')}
          </li>
        </ul>
      </StyledPurposeLocation>
    </div>
  );
};

const StyledPurposeLocation = styled.div`
  .list {
    margin-left: 14px;
    list-style-type: disc;

    &__inner {
      margin-top: 12px;
      margin-left: 14px;
      list-style-type: circle;
    }

    &__item {
      font-family: 'Montserrat', sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: white;
      position: relative;
      //padding-left: 14px;
      &::marker {
        font-size: 14px;
        color: white;
      }

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }
  }
`;
const StyledCardItem = styled.div`
  margin-top: 13px;
  margin-bottom: 13px;
  h4 {
    font-family: Playfair Display;
    font-weight: 900;
    font-size: 1.375em;
    line-height: 29px;
    color: #ffffff;
  }
  `;

export default PurposeLocationInfo;
