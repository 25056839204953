import React from 'react'
import { get } from 'lodash'

import { editCalendarSettings, getTalentsCalendar as getTalentsCalendarApi } from '../api/calendar'
import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { useSetTalentCalendarBusinessHours } from '../store/useCalendar'

export function useFetchBusinessHours() {
  const talentId = useUser(getTalentId)
  const setTalentCalendarBusinessHours = useSetTalentCalendarBusinessHours((prev, next) => next)

  return React.useCallback(() => {
    getTalentsCalendarApi({ talentId }).then(({ calendar }) => {
      setTalentCalendarBusinessHours({
        startTime: get(calendar, 'settings.workingHours.from'),
        endTime: get(calendar, 'settings.workingHours.to'),
        daysOfWeek: get(calendar, 'settings.workingHours.on'),
      })
    })
  }, [setTalentCalendarBusinessHours])
}

export function useUpdateCalendarSettings() {
  const talentId = useUser(getTalentId)
  const fetchBusinessHours = useFetchBusinessHours((prev, next) => next)

  return React.useCallback(
    data => {
      editCalendarSettings({ talentId, data }).then(() => {
        fetchBusinessHours()
      })
    },
    [talentId, fetchBusinessHours]
  )
}
