import curry from 'lodash/fp/curry'
import where from 'lodash/fp/where'
import has from 'lodash/fp/has'
import __ from 'lodash/fp/__'
import isNumber from 'lodash/fp/isNumber'


export const TIME_UNITS = {
  ms: 'ms',
  s: 's',
  m: 'm',
  h: 'h',
  d: 'd',
}

export const TIME_UNITS_IN_MS = {
  [TIME_UNITS.ms]: 1,
  [TIME_UNITS.s]: 1000,
  [TIME_UNITS.m]: 60 * 1000,
  [TIME_UNITS.h]: 60 * 60 * 1000,
  [TIME_UNITS.d]: 24 * 60 * 60 * 1000,
}

const check = where({
  from: has(__, TIME_UNITS),
  to: has(__, TIME_UNITS),
  value: isNumber
})

const convertUnits = ({ from, to, value }) => value * TIME_UNITS_IN_MS[from] / TIME_UNITS_IN_MS[to]

export const convert = curry((from, to, value) =>
  [{ from, to, value }]
    .filter(check)
    .map(convertUnits)
    .pop()
)

const fromMs = convert(TIME_UNITS.ms)
const fromS = convert(TIME_UNITS.s)
const fromM = convert(TIME_UNITS.m)
const fromH = convert(TIME_UNITS.h)
const fromD = convert(TIME_UNITS.d)

export const fromMsToS = fromMs(TIME_UNITS.s)
export const fromMsToM = fromMs(TIME_UNITS.m)
export const fromMsToH = fromMs(TIME_UNITS.h)
export const fromMsToD = fromMs(TIME_UNITS.d)

export const fromSToMs = fromS(TIME_UNITS.ms)
export const fromSToM = fromS(TIME_UNITS.m)
export const fromSToH = fromS(TIME_UNITS.h)
export const fromSToD = fromS(TIME_UNITS.d)

export const fromMToMs = fromM(TIME_UNITS.ms)
export const fromMToS = fromM(TIME_UNITS.s)
export const fromMToH = fromM(TIME_UNITS.h)
export const fromMToD = fromM(TIME_UNITS.d)

export const fromHToMs = fromH(TIME_UNITS.ms)
export const fromHToS = fromH(TIME_UNITS.s)
export const fromHToM = fromH(TIME_UNITS.m)
export const fromHToD = fromH(TIME_UNITS.d)

export const fromDToMs = fromD(TIME_UNITS.ms)
export const fromDToS = fromD(TIME_UNITS.s)
export const fromDToM = fromD(TIME_UNITS.m)
export const fromDToH = fromD(TIME_UNITS.h)
