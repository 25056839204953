import { VideoMessageForType } from 'shared';

export const forTypeOptions = [
  VideoMessageForType.MY_SELF,
  VideoMessageForType.SOMEONE_ELSE,
];

export const occasionTypeOptions = [
  'Announcement', 'Rubbing it In', 'Taunt', 'Birthday', 'Valentine\'s Day', 'Mother\'s Day', 'Father\’s Day',
  'Pep Talk', 'Advice', 'Wedding Anniversary', 'Work Anniversary', 'Holiday Wishes', 'Promotional', 'Other'
];
