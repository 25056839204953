import { getTimezoneAbbr } from 'utils/services/timezone'
import { getAmPmTime, getMilitaryTime } from 'utils/date'
import { set } from 'date-fns'

const getTimeAndHoursFromMilitary = militaryTime => {
  const [hours, minutes] = militaryTime.split(':')
  return set(new Date(), { hours, minutes })
}

export const buildWorkingHoursLabel = (from, to, timeZone, hour12 = true) => {
  const fromDate = getTimeAndHoursFromMilitary(from)
  const toDate = getTimeAndHoursFromMilitary(to)
  const timeMapper = hour12 ? getAmPmTime : getMilitaryTime
  const timeZoneLabel = getTimezoneAbbr(timeZone)

  return `${timeMapper(fromDate)} - ${timeMapper(toDate)} ${timeZoneLabel}`
}
