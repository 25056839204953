import React from 'react';
import PropTypes from 'prop-types';

import { Trans } from 'store/hooks/globalState/useTranslates';

import Slide from '../../../Slide';

const Empty = ({ firstName }) => {
  return (
    <Slide.DesktopSlide.Empty
      isMirroredInside={true}
      size="big"
      icon={<i className="icon-tv" style={{ fontSize: 90 }}></i>}
      content={
        <Trans
          tKey="talent-public-view.sliders.lessons.empty.content.label"
          phs={[{ ph: '{{firstName}}', value: firstName }]}
        />
      }
      hint={
        <Trans
          tKey="talent-public-view.sliders.lessons.empty.hint.label.title"
          phs={[{ ph: '{{firstName}}', value: firstName }]}
        />
      }
    />
  );
};

Empty.propTypes = {
  firstName: PropTypes.string.isRequired,
};

export default Empty;
