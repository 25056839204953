import React from 'react'
import CommercialEngagementsForm from './CommercialEngagementsForm'


const CommercialProposalPage = () => {
  const formRef = React.useRef()

  return (
    <>
      <CommercialEngagementsForm ref={formRef} />
    </>
  );
};

export default CommercialProposalPage
