import styled from 'styled-components';

const ModalHeader = styled.h3`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 900;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 32px;
  line-height: 54px;
  text-align: center;

  @media screen and (max-width: 1366px) {
    font-size: 42px;
    line-height: 54px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 28px;
    line-height: 36px;
  }

  @media screen and (max-width: 375px) {
    font-size: 24px;
    line-height: 32px;
  }

  @media screen and (max-width: 360px) {
    font-size: 22px;
    line-height: 28px;
  }
`;

export default ModalHeader;
