import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import Card from './Card/index'

function getCurrentIndex (slides, currentSlide) {
  if (!currentSlide) return 0
  return slides.findIndex(item => item.id === currentSlide)
}

function getLeftSlides (slides, currentIndex) {
  const mask = (new Array(6))
    .fill()
    .map((item, i) =>  (i - 6))
  const filteredSlides = []
  mask.forEach(key => {
    const slideKey = currentIndex + key
    if (slideKey < 0) return filteredSlides.push(slides[slides.length + slideKey])
    filteredSlides.push(slides[slideKey])
  })
  return filteredSlides.map((slide, i) => {
    let type
    if (i < 3) type = 'left-outer'
    if (!type) type = `card-${6 - i} left-${6 - i}`
    return ({ ...slide, type })
  })
}

function getMainSlide (slides, currentIndex) {
  let mainSlide = slides[currentIndex]
  if (!mainSlide) mainSlide = slides[0] || {}
  mainSlide.type = 'main'
  mainSlide.style = { left: '50%' }
  return mainSlide
}

function getRightSlides (slides, currentIndex) {
  const mask = (new Array(6)).fill().map((item, i) => (i + 1))
  const filteredSlides = []
  mask.forEach(key => {
    const slideKey = currentIndex + key
    if (slideKey >= slides.length) return filteredSlides.push(slides[slideKey - slides.length])
    filteredSlides.push(slides[slideKey])
  })
  return filteredSlides.map((slide, i) => {
    let type = `card-${i+1} right-${i+1}`
    if (i+1 > 3) type = 'right-outer'
    return ({ ...slide, type })
  })
}

export default React.memo(function CardsList ({
  wrapperDimensions, slides, currentSlide, setCurrentSlide, handlers
}) {
  const parsedCards = React.useMemo(() => {
    if (!slides || isEmpty(slides)) return null
    const currentIndex = getCurrentIndex(slides, currentSlide)
    const leftSlides = getLeftSlides(slides, currentIndex)
    const mainSlide = getMainSlide(slides, currentIndex)
    const rightSlides = getRightSlides(slides, currentIndex)
    const final = [
      ...leftSlides,
      mainSlide,
      ...rightSlides
    ]
    return final
      .map((slide) => {
        const { plaqueNickname, sliderView: { card, layers } } = slide
        return (
          <Card
            key={plaqueNickname}
            talentNickname={plaqueNickname}
            type={slide.type}
            layers={layers}
            file={card?.file}
            setCurrentSlide={() => setCurrentSlide(slide.id)}
            wrapperDimensions={wrapperDimensions}
          />
        )
      })
  }, [slides, currentSlide, wrapperDimensions])

  return (
    <StyledWrapper
      wrapperDimensions={wrapperDimensions}
      {...handlers}
    >
      {parsedCards}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  min-height: 150px;
  height: ${({ wrapperDimensions }) => wrapperDimensions.height}px;
  width: ${({ wrapperDimensions }) => wrapperDimensions.width}px;
  @media only screen and (max-width: 825px) and (orientation: landscape) {
    height: 250px;
  }
  @media only screen and (max-width: 680px) and (orientation: landscape) {
    height: 180px;
  }
  @media screen and (max-width: 480px) {
    height: ${({ wrapperDimensions }) => wrapperDimensions.height * 1.05}px;
    width: ${({ wrapperDimensions }) => wrapperDimensions.width * 1.1}px;
  }
`
