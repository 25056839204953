import React from 'react'
import { FormProvider } from 'react-hook-form'
import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import { FileType, types } from 'shared'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual'

import {
  getTalentsCalendarCheckOverlap as getTalentsCalendarCheckOverlapApi
} from 'components/Calendar/TalentCalendar/state/api/calendar'


import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation'

import { Row } from '_legacy/common'
import Button from '_legacy/components/Button'
import PreFill from './components/PreFill'
import RowInput from '_legacy/common/Input/RowInput'
import Charities from '_legacy/components/Charities'
import RowSelect from '_legacy/common/Select/RowSelect'
import PriceInput from '_legacy/common/Input/PriceInput'
import RowTextarea from '_legacy/common/Textarea/RowTextarea'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import RadioSwitcherRow from '_legacy/common/Radio/RadioSwitcherRow'
import Time from '_legacy/components/CreateExperience/components/Time'
import Recurring from '_legacy/components/CreateExperience/components/Recurring'
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel'
import DateOfExperience from '_legacy/components/CreateExperience/components/DateOfExperience'
import PhysicalItemsIncludedPriceModal from '_legacy/components/Modal/PhysicalItemsIncludedInPriceModal'
import MultiSelectLibrary from '_legacy/components/Library/MultiSelectLibrary'
import MultiSelectBackdrop from '_legacy/components/Library/configs/MutliSelect/MultiSelectBackdrop'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'
import OfferingsLinkToManagerPromoCodes from '_legacy/components/OfferingsLinkToManagerPromoCodes';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import { getTalentId } from 'utils/user'
import { validateEventName } from 'utils/services/validators/eventNameValidator'
import { setMinutesToDate } from '_legacy/components/CreateExperience/utils/utils'
import { prepareRecurring } from 'utils/forms/adapters/recurring'
import { getVirtualEventTypesOptions } from './utils/config'
import { getSelectValue } from 'utils/forms/adapters/select'
import { multiSelectLibraryValidate } from 'utils/services/validators/library'
import * as routes from '_legacy/constants/routes'
import { prepareDate } from 'utils/services/timezone'
import { getUserTimeZone } from 'utils/user'

import './index.scss'

const { OTHER } = types.talent.offers.liveVirtualEvent.VirtualEventType

const Form = ({ methods, onSubmit, onDraft, isLoading }) => {
  useSetPathToNavigate({ talentRoute: `/${routes.OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const timeZoneId = useUser(getUserTimeZone)

  const formKeys = ['dateOfExperience', 'startTime', 'endTime', 'travelTime', 'recurring', 'recurringModal']
  const formState = methods.watch(formKeys)
  const prevForm = React.useRef(formState)

  const checkEventOverlap = React.useCallback(debounce(async(formState, prevForm) => {
    if (!!formState?.dateOfExperience && !!getSelectValue(formState?.startTime) && !!getSelectValue(formState?.endTime)) {
      const lastChangedKey = formKeys.find(key => !isEqual(formState[key],  prevForm[key]))

      try {
        await getTalentsCalendarCheckOverlapApi({
          talentId,
          body: {
            startTime: prepareDate(
              setMinutesToDate(formState?.dateOfExperience, getSelectValue(formState?.startTime)),
              timeZoneId
            ),
            endTime: prepareDate(
              setMinutesToDate(formState?.dateOfExperience, getSelectValue(formState?.endTime)),
              timeZoneId
            ),
            beforeDriveTime: getSelectValue(formState?.travelTime?.before),
            afterDriveTime: getSelectValue(formState?.travelTime?.after),
            recurring: prepareRecurring(formState)
          }
        })

        formKeys.forEach(key => {
          methods.clearErrors(key)
        })
      } catch(err) {
        if (lastChangedKey === 'recurringModal') {
          methods.setError('recurring', { message: t('create-experience.form.errors.event-overlaps') })
        } else {
          methods.setError(lastChangedKey, { message: t('create-experience.form.errors.event-overlaps') })
        }
      }
    }
  }, 400), [])

  React.useEffect(() => {
    if (!isEqual(prevForm.current, formState)) {
      checkEventOverlap(formState, prevForm.current)
      prevForm.current = formState
    }
  }, [formState])

  return (
    <>
      <ChangeRouteHandler
        isDraft={true}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
        onDraft={onDraft}
      />
      <FormProvider {...methods}>
        <form className='default live-virtual-events' onSubmit={methods.handleSubmit(onSubmit)}>
          <RequiredLabel />
          <PreFill />
          <Row
            isRequired
            nameBlock={t('live-virtual-events.form.price.label')}
            component={
              <PriceInput
                isRequired
                name='price'
                placeholder={t('live-virtual-events.form.price.placeholder')}
              />
            }
          />
          <DateOfExperience isRequired name='dateOfExperience' />
          <Recurring />
          <Time selectedDate={methods.watch('dateOfExperience')} />
          <RowInput
            name='talentMember'
            label={t('live-virtual-events.form.other-talent-participant.label')}
            placeholder={t('live-virtual-events.form.other-talent-participant.placeholder')}
          />
          <RowSelect
            isRequired
            name='type'
            options={getVirtualEventTypesOptions(t)}
            label={t('live-virtual-events.form.event-type.label')}
            placeholder={t('live-virtual-events.form.event-type.placeholder')}
          />
          {getSelectValue(methods.watch('type')) === OTHER && (
            <RowInput
              isRequired
              name='customType'
              label={t('live-virtual-events.form.custom-type.label')}
              placeholder={t('live-virtual-events.form.custom-type.placeholder')}
            />
          )}
          <RowInput
            isRequired
            name='name'
            label={t('live-virtual-events.form.event-name.label')}
            placeholder={t('live-virtual-events.form.event-name.placeholder')}
            validation={{
              validate: eventName => t(validateEventName(eventName)),
              maxLength: value => (value || '').length < 50 || t('live-virtual-events.form.event-name.errors.max-length'),
            }}
          />
          <RowTextarea
            isRequired
            max={500}
            height={239}
            name='description'
            label={t('live-virtual-events.form.event-description.label')}
            placeholder={t('live-virtual-events.form.event-description.placeholder')}
          />
          <RadioSwitcherRow
            isRequired
            name='requiredPhysicalItem'
            nameBlock={t('live-virtual-events.form.physical-items-requirements.label')}
            defaultChecked={false}
          />
          {methods.watch('requiredPhysicalItem') === 'true' && (
            <React.Fragment>
              <RadioSwitcherRow
                isRequired
                name='physicalItemsIncludedInPrice'
                defaultChecked={true}
                nameBlock={t('live-virtual-events.form.physical-items-included-price.label')}
                tip={<TipButton modalComponent={PhysicalItemsIncludedPriceModal} />}
              />
              <RowTextarea
                max={500}
                height={239}
                name='physicalItems'
                validation={{ required: 'shared.words.required' }}
                placeholder={methods.watch('physicalItemsIncludedInPrice') === 'true'
                  ? t('live-virtual-events.form.physical-items-included.yes-placeholder')
                  : t('live-virtual-events.form.physical-items-included.no-placeholder')
                }
              />
            </React.Fragment>
          )}
          <Row
            nameBlock={t('video-message.form.media.label')}
            component={
              <MultiSelectLibrary
                name='media'
                maxSelectItems={1}
                dropZoneProps={{ accept: [FileType.PHOTO, FileType.VIDEO] }}
                customBackdrop={MultiSelectBackdrop}
                rules={{ validate: multiSelectLibraryValidate }}
              />
            }
          />
          <Charities name='charities' />
          <OfferingsLinkToManagerPromoCodes />
          <Row
            component={
              <div className='live-virtual-events__submit-wrapper'>
                <Button
                  disabled={isLoading}
                  typeButton='submit'
                  text={t('live-virtual-events.form.button.publish')}
                  paddingHorizontal='45'
                  paddingVertical='13'
                />
                <Button
                  primary
                  disabled={isLoading}
                  text={t('live-virtual-events.form.button.draft')}
                  paddingVertical='13'
                  handleOnClick={onDraft}
                />
              </div>
            }
          />
          <MissedData />
        </form>
      </FormProvider>
    </>
  )
}

Form.propTypes = {
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  onDraft: PropTypes.func,
  isLoading: PropTypes.bool,
}

export default Form
