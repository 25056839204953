import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'

import { OrderStatus } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import tableComponents from '../types/config'
import TableRowCheckbox from './common/TableRowCheckbox'
import ActionDropdown from './common/ActionDropdown'

function CardHead({ isLoading, rowId, actionsConfig, bulkActionsConfig, row }) {
  const isRejected = row?.status === OrderStatus.REJECTED

  return (
    <div className='card-header'>
      {/* TODO: Pass show/hide checkbox prop from the top component after reimplementing data flow within tables (pass real data, not getters/setters from top) */}
      {bulkActionsConfig
        ? (
          <TableRowCheckbox
            isLoading={isLoading}
            rowId={rowId}
            bulkActionsConfig={bulkActionsConfig}
            disabled={isRejected}
          />
        )
        : <div />
      }
      {actionsConfig && (
        <ActionDropdown
          isLoading={isLoading}
          rowId={rowId}
          actionsConfig={actionsConfig}
          row={row}
        />
      )}
    </div>
  )
}

function CardBody({ tableName, tableConfig, row }) {
  const t = useGetTranslate()
  const parsedRow = React.useMemo(() => {
    return tableConfig.map(({ header, type }, i) => {
      const Component = tableComponents[tableName][type]
      if (!Component) {
        console.warn(`Component with type: ${type} not implemented`)
        return null
      }
      return (
        <div
          key={i}
          className='cell-wrapper'
        >
          <div className='cell-label'>
            {t(header)}
          </div>
          <Component {...row} />
        </div>

      )
    })
  }, [tableName, tableConfig, row, t])
  return (
    <div className='card-body'>
      {parsedRow}
    </div>
  )
}

export default function MobileCard({
  isLoading, tableName, tableConfig, actionsConfig, bulkActionsConfig, row, components
}) {
  const rowId = get(row, 'id')
  let isSelected = false
  if (bulkActionsConfig) {
    isSelected = bulkActionsConfig.getFilters(current => get(current, 'selected', []).find(item => (item === rowId)), [rowId])
  }

  const CardHeadComponent = components?.MobileCardHeader || CardHead

  return (
    <StyledWrapper isSelected={isSelected} isRead={row.isRead}>
      <CardHeadComponent
        isLoading={isLoading}
        rowId={rowId}
        row={row}
        actionsConfig={actionsConfig}
        bulkActionsConfig={bulkActionsConfig}
      />
      <CardBody
        tableName={tableName}
        tableConfig={tableConfig}
        row={row}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.16);
  margin-bottom: 16px;
  ${({ isRead }) => isRead && 'background: #F2F2F6'};
  ${({ isSelected }) => isSelected && 'background: #d5dfec'};
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: solid 1px ${({ theme: { colors } }) => colors.ultraLightBlue};

    &__controls {
      display: flex;
      
      .mark-as-read-button {
        margin-right: 16px;
      }
    }
  }
  .card-body {
    padding: 12px 16px;
    .cell-wrapper {
      margin-top: 16px;
      .cell-label {
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 700;
        line-height: 16px;
        color: ${({ theme: { colors } }) => colors.lightGrey};
        text-transform: uppercase;
      }
    }
    .cell-wrapper:first-child {
      margin-top: 0;
    }
  }
`
