import React from 'react';
import styled from 'styled-components';

// components
import { Row } from '_legacy/common/Template';
import { Checkbox } from '_legacy/common/Checkbox';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const nameEmail = 'notificationPreferences.email';
const nameSms = 'notificationPreferences.sms';

const NotificationPreference = React.memo(function NotificationPreference() {
  const t = useGetTranslate();

  return (
    <NotificationWrapper
      nameBlock={t('register-user.form.notifications.label')}
      component={
        <NotificationTemplate>
          <Checkbox
            label={t('register-user.form.notifications.email')}
            name={nameEmail}
          />
          <Checkbox
            label={t('register-user.form.notifications.sms')}
            name={nameSms}
          />
        </NotificationTemplate>
      }
    />
  );
});

const NotificationWrapper = styled(Row)`
  .row-left {
    padding-top: 0;
  }
`;

const NotificationTemplate = styled.div`
  display: flex;
  padding-top: 12px;

  label {
    margin-right: 48px;
  }
`;

export default NotificationPreference;
