import React from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { PRECISION_TYPES } from '_legacy/components/Form/Selects/basic/LocationsContants';
import { Row } from '_legacy/common/Template';
import LocationsSelect from '_legacy/components/Form/Selects/basic/LocationsSelect';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import get from 'lodash/get';
// utils
import { useFetch } from 'store/hooks/useFetch';
import {
  getLocationDetails as getLocationDetailsApi
} from 'api/appApi/integration/locations/details/details';
import { getAdapter } from './utils';
import { Trans } from 'store/hooks/globalState/useTranslates';

const FlexibleLocationWithName = ({ name = 'locations' }) => {
  const t = useGetTranslate();

  const { setValue, watch, getValues } = useFormContext();
  const { fields, append, remove } = useFieldArray({ name });

  const [tempIndex,setTempIndex]=React.useState(0)
  const appendHandler = () => {
    append({ name: { label: null, value: null }, address: { label: null, value: null } });
  }
  React.useEffect(() => {
    if (fields && fields.length === 0) {
      appendHandler();
    }
  }, [fields]);
  const values = watch(name);
  const [{ data }, getLocationDetails] = useFetch(getLocationDetailsApi);
  const LocationName = get(values,tempIndex);

  React.useEffect(()=>{
    if(LocationName?.name?.data)
    {
      getLocationDetails({ googlePlaceId: LocationName.name.value });
    }
  },[LocationName])

  React.useEffect(() => {
    if (data) {
      const response = getAdapter(data);
      setValue(name+'['+tempIndex+'].name',response.LocationName)
      setValue(name+'['+tempIndex+'].address',response.address)
    }
  }, [data]);


  const removeHandler = (index) => {
    if (index === 0 && fields.length === 1) {
      setValue(name+'[0].name', { value: null, label: '' })
      setValue(name+'[0].address', { value: null, label: '' })
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    return !!values?.[0].name.value;
  };

  return (
    <>
      {fields.length > 0 ? (fields.map((location, index) => (
        <div key={location.id}>
          <Row
            nameBlock={<Trans
              tKey="register.form.location-name.label"
              phs={[
                { ph: '{{index}}', value: index+1 },
              ]}
            />}
            component={
              <div className='location-input-container'>
                <LocationsSelect
                  precision={PRECISION_TYPES.ADDRESS_AND_LOCATION}
                  isCreatable
                  usedLocations={values}
                  onFocus={()=> setTempIndex(index)}
                  name={`locations[${index}].name`}
                  placeholder={t('create-experience.form.flexible-location.placeholder')}
                  defaultValue={getValues('locations['+index+'].name')}
                  rules={{ validate: value => !!value?.label === true || 'create-experience.form.flexible-location.errors.required' }}
                />
                {showRemoveButton(index) && (
                  <button
                    className='form-row__remove form-row__remove--black'
                    type='button'
                    onClick={() => removeHandler(index)}
                    title={t('shared.words.remove')}
                  />
                )}
              </div>
            }
          />
          <Row
            nameBlock={<Trans
              tKey="register.form.location-address.label"
              phs={[
                { ph: '{{index}}', value: index+1 },
              ]}
            />}
            component={
              <LocationsSelect
                defaultValue={getValues('locations['+index+'].address')}
                name={`locations[${index}].address`}
                placeholder={t('register.form.location-address.placeholder')}
                precision={PRECISION_TYPES.ADDRESS_AND_LOCATION}
              />}
          />
        </div>
      ))):''}
      {fields.length < 5 && (
        <Row
          component={
            <button
              type="button"
              className="multiple-location__button"
              onClick={appendHandler}
            >
              + {t('create-experience.form.flexible-location.add-button')}
            </button>
          }
        />)}
    </>
  );
};

FlexibleLocationWithName.propTypes = {
  name: PropTypes.string,
};

export default FlexibleLocationWithName;
