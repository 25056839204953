import { _get, _patch, _post } from 'api/utils'

export const fetchLiveVirtualLessonOrderDetails = ({ occurrenceId }) =>
  _get(`/offers/lessons/live-virtual-lesson/occurrences/${occurrenceId}`, {
    needAuthorization: true,
  })

export const updateLiveVirtualLessonTime = ({ occurrenceId, startTime, endTime }) =>
  _patch(`/offers/lessons/live-virtual-lesson/occurrences/${occurrenceId}/update-time`, {
    needAuthorization: true,
    body: { startTime, endTime }
  })

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })
