import { last } from 'lodash'
import { areIntervalsOverlapping, max, min } from 'date-fns'

export const mergeOverlappedSlot = slots => {
  const result = []

  for (let i = 0; i < slots.length; i++) {
    const current = slots[i]
    const lastResult = last(result)

    if (
      lastResult &&
      areIntervalsOverlapping(
        { start: new Date(current.start), end: new Date(current.end) },
        { start: new Date(lastResult.start), end: new Date(lastResult.end) }
      )
    ) {
      result[result.length - 1] = {
        start: min([new Date(lastResult.start), new Date(current.start)]),
        end: max([new Date(lastResult.end), new Date(current.end)]),
      }
    } else {
      result.push(current)
    }
  }
  return result
}
