import { compose } from 'lodash/fp'

import { getFormattedDate, dateFormats } from 'utils/date'


export const getCalendarTimeFormat = hour12Format =>
  hour12Format
    ? dateFormats.timeAmPm
    : dateFormats.militaryTime

export const getCalendarDateFormat = hour12Format =>
  hour12Format
    ? dateFormats.fullMonthWithTime
    : dateFormats.fullMonthWithMilitaryTime

export const getCalendarTime = compose(getFormattedDate, getCalendarTimeFormat)
export const getCalendarDate = compose(getFormattedDate, getCalendarDateFormat)

export const getExternalCalendarTime = getFormattedDate(dateFormats.externalCalendarTime)
