import React from 'react'
import PropTypes from 'prop-types'
import { Router, Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

import * as routes from '_legacy/constants/routes'

import { history } from 'utils/history';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'

import { closeOverlay } from 'components/BottomNavigation/utils'

// components
import LoginForm from './LoginForm'
import ModalPortal from 'portals/ModalPortal'

const SignIn = () => {
  const t = useGetTranslate()

  const user = useUser()
  const isUserLoggedIn = !isEmpty(user)
  const [signInModal,, closeSignInModal] = useModal(true)

  const closeModal = () => {
    closeSignInModal()
    closeOverlay()
  }

  React.useEffect(() => {
    if (isUserLoggedIn && signInModal) closeModal()
  }, [isUserLoggedIn, signInModal])

  return (
    <>
      <ModalPortal isOpen={signInModal} onClose={closeModal} showCloseButton>
        <div className="login-modal">
          <h1 className="login-modal__form-header">{t('login.header')}</h1>
          <LoginForm closeModal={closeModal} />
          <div className="login-modal__footer">
            <h6>{t('login.dont-have-account')}</h6>
            <h6>
              <Router history={history}>
                {t('login.tip.sign-up')}&nbsp;
                <Link onClick={closeModal} to={`/${routes.REGISTER}/${routes.AFICIONADO}`}>
                  {t('login.tip.aficionado')}
                </Link>{' '}
                {t('login.tip.or')}&nbsp;
                <Link onClick={closeModal} to={`/${routes.REGISTER}/${routes.USER}`}>
                  {t('login.tip.talent')}
                </Link>
              </Router>
            </h6>
          </div>
        </div>
      </ModalPortal>
    </>
  )
}

SignIn.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
}

export default SignIn
