// Modules
import React from 'react'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { getAmPmTime, getShortMonthLocaleDate } from 'utils/date'
import { parseDate, getTimezoneAbbr } from 'utils/services/timezone'

// Legacy components
import TwoLineString from '_legacy/components/Table/table-components/types/common/TwoLineString'


const Booked = ({ isLoading, purchasedAt }) => {
  const timeZone = useUser(getUserTimeZone)
  const timeZoneAbbr = getTimezoneAbbr(timeZone)
  const parsedPurchasedAt = parseDate(purchasedAt, timeZone)
  const locale = useUserLocale()

  const mainText = getShortMonthLocaleDate(parsedPurchasedAt, locale)
  const subText = `${getAmPmTime(parsedPurchasedAt)} (${timeZoneAbbr})`

  return (
    <TwoLineString
      isLoading={isLoading}
      mainText={mainText}
      subText={subText}
    />
  )
}

export default Booked
