import React from 'react'
import compose from 'lodash/fp/compose'

import normalizeParams from 'utils/normalizeParams'
import {
  getOffers as getOffersApi,
  getOffersTopLocation as getOffersTopLocationApi
} from 'api/appApi/search/offers'
import {
  useSetSearchOffersFilters,
  useSetSearchOffers,
} from 'store/hooks/globalState/useSearchList';
import { parseRadius } from '_legacy/components/Table/Filters/utils/radius'
import { parseLocation } from '_legacy/components/Table/Filters/utils/location'
import { parsePrice } from '_legacy/components/Table/Filters/utils/price'
import { parseDuration } from '_legacy/components/Table/Filters/utils/duration'
import { parseParticipant } from '_legacy/components/Table/Filters/utils/participant'
import { parseCharities } from '_legacy/components/Table/Filters/utils/charities'
import { parseRangeDate } from '_legacy/components/Table/Filters/utils/rangeDate'
import { useSetOffersTopLocation } from 'store/hooks/globalState/useLocationList'
import { useWindowDimensions } from 'store/hooks';
import { getLimitByResolution } from 'utils/getLimitByResolution';
import { parseOfferCategoryIn } from '_legacy/components/Table/Filters/utils/categoryIn'
import { parseProximity } from '_legacy/components/Table/Filters/utils/proximity'

const normalizer = compose(
  normalizeParams,
  parseRadius,
  parseLocation,
  parsePrice,
  parseDuration,
  parseCharities,
  parseParticipant,
  parseRangeDate,
  parseOfferCategoryIn,
  parseProximity,
)

export function useFetchOffersItems({ isMobile }) {
  const setSearchOffers = useSetSearchOffers((prev, next) => next)
  const mergeSearchOffers = useSetSearchOffers((prev, next = []) => ([...(prev || []), ...next]))
  const setSearchOffersFilters = useSetSearchOffersFilters((prev, next) => ({ ...prev, ...next }))
  const { width } = useWindowDimensions()

  return React.useCallback(({ ...query }) => {
    const params = normalizer(query)
    getOffersApi({
      query: {
        limit: getLimitByResolution(width),
        ...params,
      },
    }).then(({ offers, pageInfo }) => {
      if (isMobile && 1 < pageInfo?.page) {
        mergeSearchOffers(offers)
      } else {
        setSearchOffers(offers)
      }
      setSearchOffersFilters(pageInfo)
    })
  },
  [setSearchOffers, setSearchOffersFilters, width]
  )
}

export function useFetchOfferTopLocations() {
  const setOffersTopLocation = useSetOffersTopLocation((prev, next) => next)
  return React.useCallback(() => {
    getOffersTopLocationApi({ query: { limit: 5 } })
      .then(({ data: { topLocations } }) => {
        setOffersTopLocation(topLocations)
      })
  }, [setOffersTopLocation])
}
