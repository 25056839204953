import React from 'react'

import 'react-responsive-carousel/lib/styles/carousel.min.css'

import styled from 'styled-components'
import { Carousel } from 'react-responsive-carousel'
import SlideTemplate from './Slides/SlideTemplate'
import Indicators from './Indicators'
import Arrows from './Arrows'


const getSlideInfo = (index) => ({
  title: `home.everything-you-need.slide.${index}.title`,
  items: [...Array(6)].map((_, subindex) => `home.everything-you-need.slide.${index}.items.${subindex+1}`),
})

function EveryThingYouNeedCarousel() {
  return (
    <StyledWrapper>
      <Carousel
        showThumbs={false}
        showStatus={false}
        renderArrowPrev={(onClickHandler, hasPrev) => <Arrows direction="left" onClick={onClickHandler} visible={hasPrev} />}
        renderArrowNext={(onClickHandler, hasNext) => <Arrows onClick={onClickHandler} visible={hasNext} />}
        renderIndicator={(onClickHandler, isSelected) => <Indicators onClick={onClickHandler} isSelected={isSelected} />}
      >
        {[getSlideInfo(1), getSlideInfo(2), getSlideInfo(3)].map(({ title, items }) => (
          <SlideTemplate key={title} title={title} items={items} />
        ))}
      </Carousel>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  & [direction="left"],
  & [direction="right"] {
    @media only screen and (max-width: 768px) {
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.8);
      border-radius: 100px;
      color: #ffffff;
      display: flex;
      justify-content: center;
      height: 50px;
      padding: 0;
      width: 50px;
      bottom: 10px;
      top: auto;
      
      &:hover {
        transform: scale(1);
      }
    }
  }
  
  & [direction="right"] {
    @media only screen and (max-width: 768px) {
      right: 20px;
    }
  }
  
  & [direction="left"] {
    @media only screen and (max-width: 768px) {
        left: 20px;
    }
  }
`

export default React.memo(EveryThingYouNeedCarousel)
