import { useState, useEffect } from 'react'

const getFullscreen = () => {
  return !!(
    document.fullscreenElement ||
    document.mozFullScreenElement ||
    document.webkitFullscreenElement ||
    document.msFullscreenElement
  )
}

export const useFullscreen = () => {
  const [isFullscreen, isFullscreenSet] = useState(getFullscreen())

  const onFullScreenChange = () => {
    isFullscreenSet(getFullscreen())
  }

  useEffect(() => {
    window.addEventListener('fullscreenchange', onFullScreenChange)
    window.addEventListener('webkitfullscreenchange', onFullScreenChange)

    return () => {
      window.removeEventListener('fullscreenchange', onFullScreenChange)
      window.removeEventListener('webkitfullscreenchange', onFullScreenChange)
    }
  }, [])

  return isFullscreen
}
