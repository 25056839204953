import React from 'react';
import { OrderPaymentStatus, OrderStatus } from 'shared'
import compose from 'lodash/fp/compose'
import { useParams } from 'react-router';

import {
  getExperienceOccurrenceOrders as getExperienceOccurrenceOrdersApi,
  orderRequestAction as orderRequestActionApi,
} from '../api'

import {
  useCurrentPage,
} from 'store/hooks/globalState/useUtils'
import {
  useSetExperienceOrderRequests,
  useSetExperienceOrderRequestsFilters,
  useExperienceOrderRequestsFilters,
  useSetGlobalIsLoading,
} from '../store'
import { useTalentId } from 'store/hooks/globalState/useUser'
import normalizeParams from 'utils/normalizeParams'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { parseOrderParticipant } from '_legacy/components/Table/Filters/utils/participant'

import { notify } from 'utils/notify'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { fields } from '_legacy/configs/table/orderDetailsRequests'
import { useFetchExperienceOrderDetails } from './experience-order-details'

const { REJECTED, UNDER_REVIEW } = OrderStatus
const { FROZEN, CANCELED, COMPLETED: ORDER_COMPLETE } = OrderPaymentStatus

const normalizer = compose(
  normalizeParams,
  parseOrderParticipant,
)

export function useFetchExperienceOrderRequests() {
  const setExperienceOrderRequestsFilters = useSetExperienceOrderRequestsFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setExperienceOrderRequests = useSetExperienceOrderRequests(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  const talentId = useTalentId()

  const { orderId } = useParams()

  return React.useCallback((options) => {
    const query = {
      orderStatusIn: [REJECTED, UNDER_REVIEW],
      orderPaymentStatusIn: [FROZEN, CANCELED, ORDER_COMPLETE],
      ...normalizer(options)
    }

    setExperienceOrderRequests(null)

    getExperienceOccurrenceOrdersApi({ talentId, orderId, query })
      .then(({ items, pageInfo }) => {
        setExperienceOrderRequestsFilters(pageInfo);
        setExperienceOrderRequests(items);
      })
      .catch((error) => {
        handleResponseError(error)
        console.warn(error)
      });
  }, [orderId, setExperienceOrderRequestsFilters, setExperienceOrderRequests]);
}


export function useAcceptOrderRequest() {
  const t = useGetTranslate()
  const talentId = useTalentId()
  const fetchRequests = useFetchExperienceOrderRequests()
  const filtersOptions = useExperienceOrderRequestsFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const setLoader = useSetGlobalIsLoading(
    (prev, next) => next
  )

  const fetchOccurrence = useFetchExperienceOrderDetails()
  const page = useCurrentPage()
  const handleResponseError = useHandleResponseError()
  return React.useCallback(ordersIds => {
    setLoader(true)

    orderRequestActionApi({
      talentId,
      actionType: 'ACCEPT',
      ordersIds
    })
      .then(() => {
        fetchRequests({ ...filtersOptions, page })
        fetchOccurrence()
        notify(t('order-details.notification.request.accepted'))
        setLoader(false)
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
        setLoader(false)
      })
  }, [t, fetchRequests, filtersOptions, page, fetchOccurrence])
}

export function useRejectOrderRequest() {
  const t = useGetTranslate()
  const talentId = useTalentId()
  const fetchRequests = useFetchExperienceOrderRequests()
  const filtersOptions = useExperienceOrderRequestsFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const handleResponseError = useHandleResponseError()

  const page = useCurrentPage()

  return React.useCallback(({ ordersIds, reason, message }) => {
    const body = {
      rejectReason: reason,
      rejectReasonDescription: message
    }
    orderRequestActionApi({
      talentId,
      actionType: 'REJECT',
      ordersIds,
      body
    })
      .then(() => {
        fetchRequests({ ...filtersOptions, page })
        notify(t('order-details.notification.request.rejected'))
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  }, [t, fetchRequests, filtersOptions, page])
}
