import React from 'react'

import { statuses } from '_legacy/configs/table/manageOfferings'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import LineString from '../common/LineString'

export default React.memo(function Status ({ isLoading, status }) {
  const t = useGetTranslate()
  return (
    <LineString
      isLoading={isLoading}
      str={t(statuses[status])}
    />
  )
})
