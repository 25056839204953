// TODO: Create a separate cascader list component to match DRY
// Modules
import React from 'react'
import last from 'lodash/last'
import assign from 'lodash/assign'

// Routing
import * as r from '_legacy/constants/routes'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useOffersCategories } from 'store/hooks/globalState/useCategories'
import { useSetSearchOffersFilters } from 'store/hooks/globalState/useSearchList'
import { useFetchOffersCategoriesByParentId } from 'requests/categories'

// Utils
import { history } from 'utils/history'
import { closeOverlay } from '../../utils'
import { checkSubitems } from '_legacy/components/CategoriesSelect/utils'
import {
  mapper,
  getLastCategoryTitle,
  getLastCategoryId,
  getIds,
  shouldFetchMoreCategories,
} from '_legacy/components/CategoriesSelect/utils'

// Components
import BottomNavigationOverlay from '../../BottomNavigationOverlay'


const Offering = () => {
  const [offeringSelectValue, setofferingSelectValue] = React.useState([])

  const t = useGetTranslate()
  const setOfferingSearchFilters = useSetSearchOffersFilters(assign)
  const fetchOffersCategoriesByParentId = useFetchOffersCategoriesByParentId()

  const currentItems = useOffersCategories(
    state => mapper(
      state,
      offeringSelectValue.map(v => v.i),
      offeringSelectValue.length,
    ),
    [offeringSelectValue]
  )

  const prevItems = useOffersCategories(
    state => mapper(
      state,
      offeringSelectValue.slice(0, offeringSelectValue?.length - 1).map(v => v.i),
      offeringSelectValue.length - 1,
    ),
    [offeringSelectValue]
  )

  const itemsToRender = currentItems?.length ? currentItems : prevItems || []

  const setCategoryAndRedirect = (filters) => {
    setOfferingSearchFilters(filters)
    closeOverlay()
    history.push(`/${r.SEARCH}/${r.OFFERINGS}`)
  }

  const onBack = () => {
    setofferingSelectValue([
      ...(offeringSelectValue || [])
        .slice(
          0,
          currentItems.length
            ? offeringSelectValue.length - 1
            : offeringSelectValue.length - 2
        )
    ])
  }

  const onSelect = (category, e) => {
    e.stopPropagation()

    const newState = [...(offeringSelectValue || []), category]
    setofferingSelectValue(newState)

    if (shouldFetchMoreCategories(newState)) {
      fetchOffersCategoriesByParentId(getIds(newState))
      return
    }

    if (!checkSubitems(last(newState))) {
      const categoryIn = newState.slice(1)
      const offeringTypesIn = newState[0].i

      setCategoryAndRedirect({
        categoryIn,
        offeringTypesIn,
      })
    }
  }

  const valueForTitle = currentItems?.length
    ? offeringSelectValue
    : offeringSelectValue.slice(0, offeringSelectValue?.length - 1)

  const bottomNavigationOverlayProps = offeringSelectValue.length
    ? {
      title: `${t('shared.words.all')} ${t(getLastCategoryTitle(valueForTitle))}`,
      onBack,
    }
    : {
      title: t('footer.mobile.search-by-offering'),
    }

  return (
    <BottomNavigationOverlay
      className="search-by-offering"
      {...bottomNavigationOverlayProps}
    >
      <div className="multi-level-select">
        <ul className="cascader-list__list">
          {itemsToRender?.map(category => (
            <li
              key={category.i}
              onClick={e => onSelect(category, e)}
              data-selected={String(category.i === getLastCategoryId(offeringSelectValue))}
              data-arrow={String(checkSubitems(category))}
            >
              {t(category?.n)}
            </li>
          ))}
        </ul>
      </div>
    </BottomNavigationOverlay>
  )
}

export default Offering
