import React from 'react';
import PropTypes from 'prop-types'

const OfferingsDetailsHeaderHold = ({
  title,
  onHoldTitle,
  date,
  duration,
  locationMain,
  locationFull,
  typeText,
  children
}) => {
  return (
    <div className="od--with-padding od__column">
      <h3 className="od--centered od--uppercase-title mb-12">{title}</h3>
      <div className="od-hold od--centered">
        {onHoldTitle && <p className="od--main-text mb-12">{onHoldTitle} {typeText}</p>}
        <h5 className="od--status-title mb-8">{date}</h5>
        <p className="od--main-text od--accented mb-24">{duration}</p>
        <p className="od--main-text od--accented">{locationMain}</p>
        <span className="od--small-text">{locationFull}</span>
      </div>
      {children}
    </div>
  )
};

OfferingsDetailsHeaderHold.propTypes = {
  icon: PropTypes.node,
  item: PropTypes.string,
  subitem: PropTypes.string,
  TooltipComponent: PropTypes.node,
}

export default OfferingsDetailsHeaderHold;
