import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

const COMPLAINT = 'complaint'
const COMPLAINT_ERROR = 'complaintError'

export const useComplaint = createUseGlobalState(COMPLAINT)
export const useSetComplaint = createUseSetGlobalState(COMPLAINT)

export const useComplaintAvailableError = createUseGlobalState(COMPLAINT_ERROR)
export const useSetComplaintAvailableError = createUseSetGlobalState(COMPLAINT_ERROR)
