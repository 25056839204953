import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Loader from '_legacy/components/Common/Loader';
import Icon from 'assets/icons/Icon';

import { getDocumentIconByFileName } from 'utils/isFile';

const LicenseListItem = ({ isLoading, name, onRemove }) => {

  const icon = isLoading
    ? <Loader size="20" fill="#636583" />
    : getDocumentIconByFileName(name);

  return (
    <StyledLicenseListItem isLoading={isLoading}>
      <span className="icon">{icon}</span>
      <span className="file-name">{name}</span>
      <div className="controls">
        <button type="button" onClick={onRemove}>
          <Icon.DocumentRemove />
        </button>
      </div>
    </StyledLicenseListItem>
  );
};

const StyledLicenseListItem = styled.li`
  display: grid;
  grid-template-columns: 40px 1fr fit-content(80px);
  align-items: center;
  margin: 16px 0 0 16px;

  .icon {
    margin-right: 18px;
    align-self: center;
    justify-self: center;
  }
  
  .file-name {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    ${({ loading }) => loading && css`
      color: rgba(#272835, .4); // todo: refactor with global styles
    `}
  }
  
  .controls {
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      background-color: transparent;
      border: none;
    }
  }
`;

LicenseListItem.propTypes = {
  name: PropTypes.string,
  isLoading: PropTypes.bool,
  onRemove: PropTypes.func,
};

export default LicenseListItem;
