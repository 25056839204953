import React from 'react'
import './index.scss'

import FilePreview from '_legacy/components/Library/components/Library/FilePreview'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'


export default React.memo(function Media ({ isLoading, sources, fullUrl, type, originalName, mediaLoading, preview }) {
  return (
    <PopperTooltip Component={originalName}>
      <div className='table-media'>
        <div className='table-media__file'>
          {isLoading
            ? <Loader className='table-media__file-loader' />
            : <FilePreview isLoading={mediaLoading} file={{ sources, fullUrl, type , preview }} />}
        </div>
        {isLoading ? <Loader /> : <p className='table-media__name'>{originalName}</p>
        }
      </div>
    </PopperTooltip>
  )
})
