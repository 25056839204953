import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '../../../Button';

const RequestButton = ({ onClick }) => {
  const t = useGetTranslate()
  return (
    <Button
      text={t('shared.words.request')}
      handleOnClick={onClick}
      fontSize="0.875"
      paddingVertical="10"
      paddingHorizontal="30"
    />
  )
};

RequestButton.propTypes = {
  onClick: PropTypes.func,
};

export default RequestButton
