// Modules
import React from 'react'
import cx from 'classnames'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const BottomNavigationPanelItem = ({
  label,
  onClick,
  isActive,
  Icon,
  Counter,
}) => {
  const t = useGetTranslate()

  const classList = cx(
    'bottom-navigation__panel__item',
    { 'active': isActive },
  )

  return (
    <div
      key={label}
      className={classList}
      onClick={onClick}
    >
      <div className="bottom-navigation__panel__item__icon">
        { Counter ? <Counter /> : null }
        { Icon ? <Icon /> : null }
      </div>

      <span className="bottom-navigation__panel__item__label">
        {t(label)}
      </span>
    </div>
  )
}

export default BottomNavigationPanelItem
