import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

const Label = ({ text, className, smallTip, component: Component }) => {
  const classes = cx('label', className);

  return (
    <div>
      <h4 className={classes}>{text}</h4>
      {smallTip && <div className='tip'>{smallTip}</div>}
      {Component}
    </div>
  );
};

Label.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  smallTip: PropTypes.string,
  component: PropTypes.element,
};

export default Label;
