import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames'
import BookNowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/BookNowButton';
import BasicMobileOverlay from '_legacy/components/TalentPublicView/DesktopSlider/Overlay/BasicMobileOverlay';
import styled from 'styled-components';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const OverlayLessonsMobile = ({ date, name, isVideo, path, hideControl, isPromo }) => {
  const t = useGetTranslate();
  const classes = cx('tbv-slider-slide-overlay-live-experience')

  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className={classes}>
        <h3 className="tbv-slider-slide-overlay-live-experience-name">
          {name}
        </h3>
        <h4 className="tbv-slider-slide-overlay-live-experience-date">
          {date}
        </h4>
        {isPromo && <h3 className="tbv-slider-slide-overlay-live-experience-name">
          {t('shared.offers.lessons')}
        </h3>}
      </div>
      <div className='overlay-button'>
        {!hideControl && !isPromo && <BookNowButton isLink={true} path={path} />}
        {!hideControl && isPromo && <StyledPromoVideo>{isVideo === "VIDEO" ? "Promo Video": "Promo Image"}</StyledPromoVideo>}
      </div>
    </BasicMobileOverlay>
  );
};

OverlayLessonsMobile.propTypes = {
  date: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isVideo: PropTypes.bool
};
const StyledPromoVideo = styled.h3`
  font-family: 'Playfair Display', sans-serif;
  font-weight: 300;
  font-size: 18px;
  text-align: center;
  margin-left: 10px;
  font-feature-settings: 'case' on, 'liga' off;
  color: #ffffff;
`

export default OverlayLessonsMobile;
