import React from 'react'
import styled from 'styled-components';

import access from 'assets/landing/access.svg';
import format from 'assets/landing/format.svg';
import included from 'assets/landing/included.svg';
import passion from 'assets/landing/passion.svg';
import oneOnOne from 'assets/landing/one-on-one.svg';
import giving from 'assets/landing/giving.svg';

import Reason from './Reason'

const reasons = [
  {
    className: 'access',
    bg: access,
    title: 'home.why-entreevip.subheader.access',
    description: 'home.why-entreevip.text.access',
  },
  {
    className: 'format',
    bg: format,
    title: 'home.why-entreevip.subheader.format',
    description: 'home.why-entreevip.text.format',
  },
  {
    className: 'included',
    bg: included,
    title: 'home.why-entreevip.subheader.included',
    description: 'home.why-entreevip.text.included',
  },
  {
    className: 'passion',
    bg: passion,
    title: 'home.why-entreevip.subheader.passion',
    description: 'home.why-entreevip.text.passion',
  },
  {
    className: 'one-on-one',
    bg: oneOnOne,
    title: 'home.why-entreevip.subheader.one-on-one',
    description: 'home.why-entreevip.text.one-on-one',
  },
  {
    className: 'giving',
    bg: giving,
    title: 'home.why-entreevip.subheader.giving',
    description: 'home.why-entreevip.text.giving',
  },
];

export default React.memo(function ReasonsList () {
  return (
    <StyledWrapper>
      {reasons.map((reason, i) => (
        <Reason
          key={i}
          {...reason}
        />
      ))}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0 35px;
  padding-bottom: 60px;
  @media only screen and (max-width: 1024px) {
    grid-gap: 0 20px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 35px;
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`
