import React from 'react'
import PropTypes from 'prop-types'
import ReactDatePicker from 'react-datepicker'
import get from 'lodash/get'

import { ReactComponent as Icon } from 'assets/calendar.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import StyledWrapper from './StyledWrapper'

const DatePicker = React.memo(({ className, placeholder, field, getFn, setFn }) => {
  const t = useGetTranslate()
  const selected = getFn(current => get(current, field, ''))
  const handleChange = setFn((prev, next) => ({ ...prev, [field]: next }))

  return (
    <StyledWrapper className={`filter ${className}`}>
      <ReactDatePicker
        placeholderText={t(placeholder)}
        popperPlacement="top"
        selected={selected}
        onChange={handleChange} />
      <Icon className="icon" />
    </StyledWrapper>
  )
})


DatePicker.defaultProps = {
  className: '',
  placeholder: '',
}

DatePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.string,
  getFn: PropTypes.func,
  setFn: PropTypes.func,
}

export default DatePicker

