import React from 'react'
import prop from 'lodash/fp/prop'
import propEq from 'lodash/fp/propEq'
import { EntourageSubscriptionType } from 'shared'

import { getMonthDayYearDate } from 'utils/date'
import { useUser } from 'store/hooks/globalState/useUser'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'
import { Row } from '_legacy/common'
import Button from '_legacy/components/Button'
import { routesList } from 'router/routesMap'
import { ReactComponent as EntourageFullIcon } from '../icons/EntourageFullIcon.svg'

const Inactive = () => {
  const t = useGetTranslate()
  const dueDate = useUser(prop('user.entourageSubscription.createdAt'))
  const hasUpgrade = useUser(propEq('user.entourageSubscription.entourageOrder.entourageType', EntourageSubscriptionType.FULL))

  return (
    <Row
      nameBlock={t('account.entourage.lite-status.title')}
      component={
        <div className="account-entourage">
          <div className="account-entourage__status-wrapper">
            <div className="account-entourage__status">
              <EntourageFullIcon className="account-entourage__status-icon" />
              <div className="account-entourage__status-info">
                <strong>{t('account.entourage.lite-status.status')}</strong>
                <Trans
                  tKey="account.entourage.lite-status.sub-status"
                  phs={[{ ph: '{{date}}', value: getMonthDayYearDate(dueDate) }]}
                />
              </div>
            </div>
          </div>

          <p className="account-entourage__text">{t('account.entourage.lite-status.text')}</p>

          <ul className="account-entourage__benefits-list">
            <li className="account-entourage__benefits-item">
              <Icon.OrderStatusCompleted />
              <div className="account-entourage__benefits-item-info">
                <div>{t('account.entourage.statuses.id-verification')}</div>
              </div>
            </li>
          </ul>

          <Button
            className="account-entourage__button"
            isLink
            text={hasUpgrade ? t('account.entourage.inactive.verify') : t('account.entourage.lite-status.upgrade')}
            path={hasUpgrade ? routesList.entourageVerify() : routesList.entourageUpgrade()}
          />
        </div>
      }
    />
  )
}

export default Inactive
