import { pipe, prop, gte } from 'lodash/fp'

/**
 * Function that checks is current layout is mobile
 * @func getIsMobile
 * @param {Object} windowDimensions
 * @param {Number} windowDimensions.width
 * @param {Number} windowDimensions.height
 * @return {boolean}
 *
 * @example
 *     useWindowDimensions(isMobile)
 */
export const getIsMobile = pipe(
  prop('width'),
  gte(1024)
)
