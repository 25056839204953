import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


export default React.memo(function Title() {
  const t = useGetTranslate()

  return <StyledWrapper>{t('home.everything-you-need.title')}</StyledWrapper>
})

const StyledWrapper = styled.h1`
  max-width: 768px;
  height: 108px;
  margin: 0 auto 84px;
  font-family: Playfair Display;
  font-size: 48px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.13;
  letter-spacing: normal;
  text-align: center;
  color: #fff;

  @media only screen and (min-height: 821px) {
    margin-bottom: 120px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 32px;
    margin-bottom: 0;
    line-height: 42px;
    margin-top: 0;
  }
  
  @media only screen and (max-width: 480px) {
    font-size: 28px;
    margin-bottom: 0;
    margin-top: 0;
  }
`
