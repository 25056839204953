import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// Assets
import Icon from 'assets/icons/Icon'

function Indicators({ onClick, isSelected }) {
  return (
    <StyledWrapper onClick={onClick} isSelected={isSelected}>
      <div className='svg-container'>
        <Icon.Triangle />
      </div>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  opacity: ${props => (props.isSelected ? '1' : '.5')};
  transition: all .25s;
  margin-left: 8px;

  :hover {
    transform: scale(1.5);
    opacity: 1;
  }

  .svg-container {
    transform: rotate(90deg);
  }

  svg polygon {
    fill: ${props => (props.isSelected ? 'white' : 'none')};
  }
`

Indicators.propTypes = {
  onClick: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
}

Indicators.defaultProps = {
  isSelected: false,
}

export default Indicators
