import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFaqList, useFaqListFilters, useSetFaqListFilters } from './state/store'
import { useFetchFaqList } from './state/requests'

import FAQsTab from './components/FAQsTab/'
import Pagination from '_legacy/components/Table/table-components/Pagination'

const FAQs = () => {
  const t = useGetTranslate()
  const [activeTab, setActiveTab] = useState(0)

  const fetchFaqList = useFetchFaqList()
  const clearFaqList = useSetFaqListFilters(() => null, [])
  const faqList = useFaqList()

  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = +query.get('page') || 1

  useEffect(() => {
    fetchFaqList({ page })

    return () => {
      clearFaqList()
    }
  }, [page])

  return (
    <div className="faqs-container">
      <h1 className="faqs-header">
        {t('faqs.header')}
      </h1>
      {faqList && faqList
        .filter(Boolean)
        .map(({ id, question, answer }, idx) => (
          <FAQsTab
            key={id}
            header={question}
            content={answer}
            isActive={activeTab === idx}
            clickHandler={() => setActiveTab(idx)}
          />
        ))
      }
      <Pagination getFiltersFn={useFaqListFilters} />
    </div>
  )
}

export default FAQs;
