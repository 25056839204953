import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Radio } from './Radio';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const RadioSwitcher = ({ name, defaultChecked = true }) => {
  const t = useGetTranslate();

  return (
    <RadioSwitcherWrapper>
      <Radio name={name} label={t('experience.details.yes')} value={true} defaultChecked={defaultChecked} />
      <Radio name={name} label={t('experience.details.no')} value={false} defaultChecked={!defaultChecked} />
    </RadioSwitcherWrapper>
  );
};

const RadioSwitcherWrapper = styled.div`
  display: flex;
  
  label:not(:last-of-type) {
    margin-right: 45px;
  }
`;

RadioSwitcher.propTypes = {
  name: PropTypes.string,
};

export default RadioSwitcher;
