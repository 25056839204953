import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import MobileCategoriesSelect from './CategoriesSelectComponents/MobileCategoriesSelect'
import DesktopCategoriesSelect from './CategoriesSelectComponents/DesktopCategoriesSelect'

import './index.scss'

const CategoriesSelect = ({
  getFn,
  value,
  onClose,
  setValue,
  filterFn,
  withCount,
  withHeading,
  showAllCategories,
  allCategoriesLabel,
  className = '',
  backgroundComponent,
  pointerShift,
}) => {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 1024)
  const Select = isMobile ? MobileCategoriesSelect : DesktopCategoriesSelect

  return (
    <div className={`multi-level-select ${className}`}>
      <div className="multi-level-select__pointer__wrapper" style={{ left: pointerShift }}>
        <span className="multi-level-select__pointer"/>
      </div>
      <Select
        getFn={getFn}
        value={value}
        onClose={onClose}
        filterFn={filterFn}
        setValue={setValue}
        withCount={withCount}
        withHeading={withHeading}
        allCategoriesLabel={allCategoriesLabel}
        showAllCategories={showAllCategories}
      />
      {value.length === 0 && backgroundComponent && backgroundComponent}
    </div>
  )
}

CategoriesSelect.propTypes = {
  onClose: PropTypes.func,
  withCount: PropTypes.bool,
  withHeading: PropTypes.bool,
  showAllCategories: PropTypes.bool,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  getFn: PropTypes.func.isRequired,
  className: PropTypes.string,
  backgroungComponent: PropTypes.node,
  allCategoriesLabel: PropTypes.string,
}

export default CategoriesSelect
