import React from 'react';
import { array } from 'prop-types';

import TalentInfoBlockTab from '../TalentInfoBlockTab/';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';


const TalentInfoBlockCharities = ({ charities }) => {
  const t = useGetTranslate();
  return (
    <TalentInfoBlockTab
      className="talent-info-block__tab--charities"
      header={t('talent-public-view.info-block.charities')}
      info={
        charities?.length === 0 ? (
          t('talent-public-view.info-block.not-specified')
        ) : (
          <div className="talent-info-block__tab--charities__block">
            {charities?.map(({ name }) => (
              <span className="talent-info-block__tab--charities__block-item" key={name}>
                {name}
              </span>
            ))}
          </div>
        )
      }
    />
  );
};

TalentInfoBlockCharities.propTypes = {
  charities: array.isRequired,
};

export default TalentInfoBlockCharities;
