import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';

import { useModal } from 'store/hooks/useModal';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useChangePassword, useResetPasswordRequest } from 'requests/auth';

import { Row, PasswordInput } from '_legacy/common';
import Button from '_legacy/components/Button';
import AccountTemplate from '../AccountTemplate';
import CheckEmailModal from '_legacy/components/Modal/CheckEmail';
import ChangePasswordBlock from '_legacy/components/RegisterForms/shared/components/Password/ChangePasswordBlock';

const defaultValues = {
  oldPassword: '',
  password: '',
  rePassword: '',
};

const ChangePassword = () => {
  const t = useGetTranslate();
  const methods = useForm({ mode: 'onChange', criteriaMode: 'all', defaultValues });
  const [checkEmailModal, openCheckEmailModal, closeCheckEmailModal] = useModal(false);

  const changePassword = useChangePassword(methods);
  const resetPassword = useResetPasswordRequest(openCheckEmailModal);

  const onSubmit = ({ oldPassword, password }) =>
    changePassword({ oldPassword, newPassword: password });

  const onOldPasswordChange = () =>
    methods.formState.dirtyFields.password && methods.trigger('password')

  return (
    <FormProvider {...methods}>
      <AccountTemplate title={'account.menu.change-password'}>
        <Container>
          <form className="default" onSubmit={methods.handleSubmit(onSubmit)}>
            <Row
              nameBlock={
                <>
                  {t('settings.change-password.label.current')}
                  <button
                    className={'forgot-password-button'}
                    type={'button'}
                    onClick={resetPassword}
                  >
                    {t('login.forgot-your-password')}
                  </button>
                </>
              }
              component={
                <PasswordInput
                  placeholder={t('settings.change-password.placeholder.current')}
                  onChange={onOldPasswordChange}
                  name="oldPassword"
                />
              }
            />
            <ChangePasswordBlock />
            <Row
              component={
                <Button
                  text={t('settings.change-password.save')}
                  paddingVertical="13"
                  paddingHorizontal="52"
                  typeButton="submit"
                  style={{ marginTop: 50 }}
                />
              }
            />
            <CheckEmailModal
              closeModal={closeCheckEmailModal}
              isModalOpen={checkEmailModal}
            />
          </form>
        </Container>
      </AccountTemplate>
    </FormProvider>
  );
};

export const Container = styled.div`
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  .current-pass {
    input {
      padding-right: 64px;
    }
  }
  input {
    height: 60px;
  }
  form {
    padding-top: 48px;
  }
  @media only screen and (max-width: 768px) {
    .row:not(.row:first-of-type) {
      margin-bottom: 0;
    }
    .input-container {
      margin-bottom: 0;
    }
    .button {
      width: 100%;
    }
    input::placeholder {
      font-size: 16px;
    }
  }
`;

export default ChangePassword;
