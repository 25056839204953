import React from 'react'
import { juxt, includes, __, pipe, prop } from 'lodash/fp'
import { EntourageSubscriptionType } from 'shared'

import { useGetMe } from 'requests/auth'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import IDVerification from './cards/IDVerification'
import BackgroundCheck from './cards/BackgroundCheck'
import PageLoader from '_legacy/common/Template/PageLoader'


const EntourageVerify = () => {
  const t = useGetTranslate()
  const getMe = useGetMe()
  const [isLoading, setIsLoading] = React.useState(false)

  const [withIDVerification, withBackgroundVerification] = useUser(
    pipe(
      prop('user.entourageSubscription.entourageOrder.entourageType'),
      juxt([
        includes(__, [EntourageSubscriptionType.FULL, EntourageSubscriptionType.LITE]),
        includes(__, [EntourageSubscriptionType.FULL]),
      ])
    )
  )

  React.useEffect(() => {
    setIsLoading(true)

    setTimeout(() => {
      getMe()
      setIsLoading(false)
    }, 2000)
  }, [])

  if (isLoading) {
    return <PageLoader />
  }

  return (
    <div className="entourage-verify wrapper content-wrapper">
      <h1 className="entourage-verify__header">{t('entourage-verify.header')}</h1>
      <p className="entourage-verify__text">{t('entourage-verify.sub-header')}</p>

      <div className="entourage-verify__cards">
        {withIDVerification && <IDVerification />}
        {withBackgroundVerification && <BackgroundCheck />}
      </div>
    </div>
  )
}

export default EntourageVerify
