import React from 'react';
import { string } from 'prop-types';

const BiographyItem = ({ header, subheader, description }) => (
  <>
    <div className="biography-slider__item_header">
      <div className="biography-slider__item_header--line" />
      <div className="biography-slider__item_header--text" data-star-size={String(!header)}>
        {header || '☆☆☆☆☆☆'}
      </div>
      <div className="biography-slider__item_header--line" />
    </div>
    <div className="biography-slider__inner">
      <div className="biography-slider__inner_title" >
        {subheader}
      </div>
      <div className="biography-slider__inner_description" >
        {description}
      </div>
    </div>
  </>
);

BiographyItem.propTypes = {
  header: string.isRequired,
  subheader: string.isRequired,
  description: string.isRequired,
};

export default BiographyItem;
