import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { useOnClickOutside } from 'store/hooks/useOnClickOutside'

const DropdownWrapper = ({ active, close, children, className = '' }) => {
  const node = React.useRef()
  useOnClickOutside(node, close)

  if (!active) return null

  return (
    <StyledWrapper className={className} ref={node}>
      {children}
    </StyledWrapper>
  )
}

DropdownWrapper.propTypes = {
  active: PropTypes.bool,
  close: PropTypes.func,
  children: PropTypes.element,
}

const StyledWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  width: 100%;
  background: #fff;
  border-radius: 4px;
  transform: translate(-50%, 10px);
  min-width: 140px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  z-index: 11;
  padding: 16px
`

export default DropdownWrapper
