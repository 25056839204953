import React from 'react'

import { useModal } from 'store/hooks/useModal'
import { useCheckComplaint } from 'requests/complaint'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useComplaint,
  useComplaintAvailableError,
  useSetComplaint,
  useSetComplaintAvailableError,
} from 'store/hooks/globalState/useComplaint'

import ComplaintModal from '../ComplaintModal'


const ComplaintInfo = ({ orderId, userName, userLink, onSuccess }) => {
  const t = useGetTranslate()
  const checkComplaint = useCheckComplaint()
  const isComplaintAvailable = useComplaint()
  const isComplaintError = useComplaintAvailableError()
  const clearComplaint = useSetComplaint(() => null)
  const clearComplaintError = useSetComplaintAvailableError(() => null)

  const [isOpenComplaintModal, openComplaintModal, closeComplaintModal] = useModal(false)

  React.useEffect(() => {
    if (orderId) {
      checkComplaint(orderId)
    }
  }, [orderId])

  React.useEffect(() => () => {
    clearComplaint()
    clearComplaintError()
  }, [])

  if (!isComplaintAvailable) return null

  return (
    <React.Fragment>
      <button onClick={openComplaintModal} type="button" className="od__complaint-btn">
        {t('order.answer.buttons.complaint')}
      </button>

      {isComplaintError && <p className="od__complaint-btn">{t('order.answer.buttons.complaint-error')}</p>}

      <ComplaintModal
        userLink={userLink}
        userName={userName}
        orderId={orderId}
        isOpen={isOpenComplaintModal}
        onClose={closeComplaintModal}
        onSuccess={onSuccess}
      />
    </React.Fragment>
  )
}

export default ComplaintInfo
