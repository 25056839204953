import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'
import { compose, padCharsStart, map, join, chunk } from 'lodash/fp'

import Icon from 'assets/icons/Icon'
import useOutsideClick from '_legacy/components/Header/UserAccount/AccountMenu/useOutsideClick'
import Loader from '_legacy/components/Common/Loader'

import { useDeletePaymentCard, useSetDefaultPaymentCard } from 'requests/payments';
import { useStateModal } from 'store/hooks/globalState/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { PAYMENT_MODAL } from '_legacy/components/Modal/modals-types'
import { listLogoCard } from './utils/listLogoCard'

import './index.scss'

const formatedCard = compose(join(' '), map(join('')), chunk(4), padCharsStart('*', 16))

const PaymentsSelect = ({ listCard = [], placeholder = '' }) => {
  const t = useGetTranslate()
  const [toggleDropdown, setToggleDropdown] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const wrapperRef = React.useRef(null);

  const deletePaymentCard = useDeletePaymentCard(setLoading)
  const setDefaultPaymentCard = useSetDefaultPaymentCard(setLoading)

  const defaultCard = listCard?.find(({ card }) => card.isDefault)
  const defaultCardName = get(defaultCard, 'card.name')
  const defaultCardNumber = get(defaultCard, 'card.last4', '')
  const defaultCardBrand = get(defaultCard, 'card.brand')

  const openModalAddCard = useStateModal(PAYMENT_MODAL)
  const handlerToggle = () => setToggleDropdown(s => !s)
  const handlerPayment = (paymentMethodId) => {
    setDefaultPaymentCard(paymentMethodId)
    setToggleDropdown(false)
  }
  const handlerDeletePayment = (e, paymentMethodId) => {
    e.stopPropagation()
    deletePaymentCard(paymentMethodId)
    setToggleDropdown(false)
  }

  const handlerOpenModal = () => {
    openModalAddCard()
    setToggleDropdown(false)
  }

  useOutsideClick(wrapperRef, () => setToggleDropdown(false));

  const classesArrowSelect = cx('payments-selector__arrow-select', {
    'payments-selector__arrow-select--open': toggleDropdown
  })

  const paymentsList = listCard?.map(({ paymentMethodId, card: { name = '', last4, isDefault, brand } }) => {
    const classes = cx('payments-selector__view', { 'payments-selector__view--default': isDefault })
    return (
      <li
        key={paymentMethodId}
        className='payments-selector__item'
        onClick={() => handlerPayment(paymentMethodId)}
      >
        <button type='button' className={classes}>
          <span className='payments-selector__logo-card__provider'>{listLogoCard[brand]}</span>
          <span className='payments-selector__logo-card__name'>{name}</span>
          <span className='payments-selector__logo-card__number'>{formatedCard(last4)}</span>
        </button>
        <button className='payments-selector__delete' type='button' onClick={(e) => handlerDeletePayment(e, paymentMethodId)} />
      </li>
    )
  })

  return (
    <div ref={wrapperRef} className='payments-selector'>
      <div className='payments-selector__field' onClick={handlerToggle}>{
        defaultCard
          ? loading
            ? <Loader />
            : <>
              <span className='payments-selector__logo-card__provider'>{listLogoCard[defaultCardBrand]}</span>
              <span className='payments-selector__logo-card__name'>{defaultCardName}</span>
              <span className='payments-selector__logo-card__number'>{formatedCard(defaultCardNumber)}</span>
            </>
          : <span className='payments-selector__field--placeholder'>{t(placeholder)}</span>}

      <button type='button' className={classesArrowSelect}><Icon.ArrowDownSelect /></button>
      </div>
      {toggleDropdown && (
        <ul className='payments-selector__list'>
          {paymentsList}
          <li className='payments-selector__item'>
            <button type='button' className='payments-selector__view' onClick={handlerOpenModal}>
              + {t('payment-select.add-card')}
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default PaymentsSelect;
