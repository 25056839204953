import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import TextArea from '_legacy/common/TextArea';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const TextAreaField = React.memo(function TextAreaField({ name, label, placeholder, maxLength, isRequired, requiredMessage, className = '' }) {
  const t = useGetTranslate();
  return (
    <Row
      nameBlock={t(label)}
      className={className}
      isRequired={isRequired}
      component={
        <TextArea
          name={name}
          placeholder={placeholder}
          maxLength={maxLength}
          className={className}
          requiredMessage={requiredMessage}
          isRequired={isRequired}
        />
      }
    />
  );
});

TextAreaField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  maxLength: PropTypes.number,
  requiredMessage: PropTypes.string,
};

export default TextAreaField;
