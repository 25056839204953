import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { Row } from '_legacy/common'
import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher'

const MarketingCommunications = React.memo(function MarketingCommunications({ name = 'marketingCommunicationsOptions' }) {
  const t = useGetTranslate();

  return (
    <MarketingCommunicationsWrapper
      nameBlock={t('register-aficionado.form.marketing-communications.label')}
      component={<RadioSwitcher name={name} />}
    />
  );
});

MarketingCommunications.propTypes = {
  name: PropTypes.string,
}

const MarketingCommunicationsWrapper = styled(Row)`
  align-items: center;
`;

export default MarketingCommunications;
