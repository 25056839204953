import { TalentOffersType } from 'shared'
import map from 'lodash/fp/map'
import get from 'lodash/get'

import { routesList, getPublicPageRoute } from 'router/routesMap'
import { getOfferLabel } from 'utils/offer'

export const offeringsPrepositions = {
  [TalentOffersType.VIDEO_CHAT]: 'with',
  [TalentOffersType.VIDEO_MESSAGE]: 'from',
  [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: 'from',
  [TalentOffersType.VIRTUAL_LESSON]: 'with',
  [TalentOffersType.LIVE_IN_PERSON_LESSON]: 'with',
  [TalentOffersType.READY_MADE_LESSON]: 'by',
  [TalentOffersType.VIRTUAL_EVENT]: 'by',
  [TalentOffersType.IN_PERSON_EXPERIENCE]: 'by',
  [TalentOffersType.FOR_COMMERCIAL_USE]: 'from',
}

// ****** talent ******
export const buildSearchTalentTitle = (item) => {
  const firstName = item.highlight?.['first_name']?.[0] || item.first_name || ''
  const lastName = item.highlight?.['last_name']?.[0] || item.last_name || ''
  const primaryCategoryName = item.highlight?.['primary_category.name']?.[0] || item.primary_category?.name || ''

  return `${firstName} ${lastName} <small>in ${primaryCategoryName}</small>`
}

export const buildSearchTalentLink = item => {
  return routesList.talentPublicPage(item.plaque_nickname)
}

// ****** offer ******
export const buildSearchOfferTitle = (item) => {
  const talentFirstName = item.highlight?.['talent.first_name']?.[0] || item.talent?.first_name || ''
  const talentLastName = item.highlight?.['talent.last_name']?.[0] || item.talent?.last_name || ''
  const title = item?.highlight?.['title']?.[0] || item.title || ''

  return `${title} <small>${offeringsPrepositions[item?.type]} ${talentFirstName} ${talentLastName}</small>`
}

export const buildSearchOfferLink = item => {
  return getPublicPageRoute(item.type, item.talent.plaque_nickname, item.sequential_id)
}

export const transformLabel = item => {
  return {
    label: `${item.key.replace(/_/g, ' ').toLowerCase()} (${item.total || item.doc_count})`,
    value: item,
  }
}

export const transformOfferLabel = item => {
  return {
    label: getOfferLabel(item.key),
    value: item
  }
}

export const getCoordinates = (source) => {
  return {
    latitude: get(source, 'location_point.coordinates.1'),
    longitude: get(source, 'location_point.coordinates.0'),
  }
}

export const transformLabels = map(transformLabel)

export const transformOfferings = map(transformOfferLabel)
