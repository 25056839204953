import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'

const DownloadButton = ({ fullUrl, text, className }) => (
  <a href={fullUrl} download className={cx('button__download', className)}>
    <DownloadIcon />
    {text}
  </a>
)

DownloadButton.propTypes = {
  fullUrl: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  className: PropTypes.string,
}

export default DownloadButton
