import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'

import VideoInputList from '../VideoInputList'
import AudioInputList from '../AudioInputList'
import AudioOutputList from '../AudioOutputList'

export default function DeviceSelectionDialog({ isOpen, onClose }) {
  const t = useGetTranslate()

  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen modalContentClassName='device-selection-modal-wrapper'>
      <div className="device-selection-modal">
        <h1 className="device-selection-modal__header">
          {t('video-chat.device-selection-modal.audio-and-video')}
        </h1>

        <div className='device-selection-modal__container'>
          <div className="device-selection-modal__group">
            <h3 className="device-selection-modal__group-header">
              {t('video-chat.device-selection-modal.video')}
            </h3>

            <div className="device-selection-modal__section">
              <VideoInputList />
            </div>
          </div>

          <div className="device-selection-modal__group">
            <h3 className="device-selection-modal__group-header">
              {t('video-chat.device-selection-modal.audio')}
            </h3>

            <div className="device-selection-modal__section">
              <AudioInputList />
            </div>

            <div className="device-selection-modal__section">
              <AudioOutputList />
            </div>
          </div>
        </div>

        <Button
          stretch
          paddingVertical={16}
          handleOnClick={onClose}
          text={t('video-chat.device-selection-modal.done')}
        />
      </div>
    </ModalPortal>
  )
}
