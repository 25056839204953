import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components'

import { SlideCustomVideoPlayer } from '_legacy/common/custom-video/CustomVideoPlayer/SlideCustomVideoPlayer';

const VideoViewer = ({ file, isCurrent, isMobile, toggelPause, bottomCss, setGlobalIsPlay = () => {} }) => {
  const wrapClassName = isMobile ? 'tbv-slider-mobile-slide-content-media-video' : 'tbv-slider-slide-content-media-video';
  return (
    <StyledCustomVideoPlayer
      className={wrapClassName}
      file={file}
      toggelPause={toggelPause}
      isStoped={!isCurrent}
      bottomCss={bottomCss}
      setGlobalIsPlay={setGlobalIsPlay}
    />
  )
};

VideoViewer.propTypes = {
  file: PropTypes.object,
  isCurrent: PropTypes.bool.isRequired,
  isNext: PropTypes.bool.isRequired,
  bottomCss: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
}

const StyledCustomVideoPlayer = styled(SlideCustomVideoPlayer)`
  .icon {
    ${({ bottomCss }) => bottomCss && `top: auto; bottom: ${bottomCss}`};
    z-index: 3;
  }
`

export default VideoViewer;
