import React from 'react';
import { useParams } from 'react-router';

import {
  getExperienceOccurrence as getExperienceOccurrenceApi,
  checkExperienceOccurrenceAvailability as checkIsEditableApi,
  rejectOrders as rejectOrdersApi,
  cancelOccurrence as cancelOccurrenceApi,
  checkOccurrenceAvailabilityToCancel as checkIsCancelableApi,
} from '../api'

import {
  useSetExperienceOrderDetails,
  useSetIsExperienceEditable,
  useSetIsExperienceCancelable,
} from '../store'
import { useTalentId, useUser } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { getTalentId } from 'utils/user'
import { notify } from 'utils/notify'
import { orderRequestAction } from '../../../LiveInPersonLessonOrderDetails/state/api'
import { BulkOrdersAction } from 'shared'


export function useFetchExperienceOrderDetails() {
  const setExperienceOrderDetails = useSetExperienceOrderDetails((prev, next) => next)
  const talentId = useTalentId()
  const { orderId } = useParams();
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setExperienceOrderDetails(null);
    getExperienceOccurrenceApi({ talentId, orderId })
      .then(({ occurrence }) => {
        setExperienceOrderDetails(occurrence)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [orderId, setExperienceOrderDetails])
}

export const occurrenceStatus = {
  hasBookedParticipants: false,
  hasPendingBookingRequests: false,
  hasPassedOccurrences: false,
}

export function useCheckIsOfferEditable() {
  const setIsExperienceEditable = useSetIsExperienceEditable((prev, next) => next)
  const talentId = useTalentId()

  return React.useCallback((offerId) => {
    setIsExperienceEditable(null);
    checkIsEditableApi({ talentId, offerId })
      .then(() => {
        setIsExperienceEditable(occurrenceStatus)
      })
      .catch(({ error }) => {
        const { context } = error?.response?.data.error
        console.warn(error)
        setIsExperienceEditable({ ...occurrenceStatus, ...context })
      })
  }, [setIsExperienceEditable])
}

export function useRejectOrders(onSuccess) {
  const talentId = useUser(getTalentId)
  const setIsExperienceEditable = useSetIsExperienceEditable(() => occurrenceStatus)

  return React.useCallback(offerId => {
    rejectOrdersApi({ talentId, offerId })
      .then(() => {
        setIsExperienceEditable()
        if (onSuccess) onSuccess()
      })
      .catch((err) => console.warn(err))
  }, [talentId, setIsExperienceEditable])
}

export function checkAvailabilityToCancel() {
  const setIsExperienceCancelable = useSetIsExperienceCancelable((prev, next) => next)
  const talentId = useTalentId()
  const { orderId: experienceOccurrenceId } = useParams()
  return React.useCallback(offerId => {
    setIsExperienceCancelable(null);
    checkIsCancelableApi({ talentId, offerId, experienceOccurrenceId })
      .then(occurrenceStatus => {
        setIsExperienceCancelable(occurrenceStatus)
      })
      .catch(({ error }) => {
        const { context } = error?.response?.data.error
        console.warn(error)
        setIsExperienceCancelable({ ...occurrenceStatus, ...context })
      })
  }, [experienceOccurrenceId, setIsExperienceCancelable])
}

export function useCancelOccurrence() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const { orderId: experienceOccurrenceId } = useParams()
  const fetchExperienceOrderDetails = useFetchExperienceOrderDetails()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message || ''
    }
    cancelOccurrenceApi({ talentId, offerId, experienceOccurrenceId, body })
      .then(() => {
        notify(t('order-details.experience.cancel-success'), 'success')
        fetchExperienceOrderDetails()
      })
      .catch((err) => console.warn(err))
  }, [talentId, experienceOccurrenceId])
}

export function useCancelOrder() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const { orderId: experienceOccurrenceId } = useParams()
  const fetchExperienceOrderDetails = useFetchExperienceOrderDetails()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message || ''
    }
    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.experience.order-cancel-success'))
        fetchExperienceOrderDetails()
      })
      .catch((err) => console.warn(err))
  }, [talentId, experienceOccurrenceId])
}
