// TODO: remove after wiping out styled components
import React from 'react'
import { ThemeProvider } from 'styled-components'

const theme = {
  colors: {
    blue: '#272835',
    grey: '#626473',
    blueText: '#babbcd',
    lightGrey: '#9495a7',
    ultraLightBlue: '#e9eaf0',
    accepted: '#007b2a',
    rejected: '#d93025',
    white: '#fff',
    black: '#000',
  },
  fontFamily: {
    default: 'Montserrat',
    primary: 'Playfair Display',
  },
  fontWeight: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
  boxShadows: {
    modal: '0px 20px 60px rgba(0, 0, 0, 0.3)'
  },
  borderRadius: {
    default: '4px',
    modal: '8px'
  },
  border: {
    default: '2px solid #BABBCD'
  }
}


const withStyledTheme = Component => props =>
  <ThemeProvider theme={theme}>
    <Component {...props} />
  </ThemeProvider>

export default withStyledTheme
