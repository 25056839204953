import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const NavigationItem = ({ to, text }) => {
  return (
    <li className="search-navigation-item">
      <NavLink className="search-navigation-item__link" exact to={to}>
        {text}
      </NavLink>
    </li>
  )
}

NavigationItem.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
}

export default NavigationItem
