import React from 'react'
import get from 'lodash/get'

import { TalentOffersType } from 'shared'

import { useGetTranslate, insertPhsValue } from 'store/hooks/globalState/useTranslates.js'
import { useLessons, useSetLessons } from 'store/hooks/globalState/useLessons.js'

import { useFetchLessonsPublic } from 'requests/lessons.js'

import { getFullName } from 'utils/user.js'

import withLibrary from '_legacy/containers/Offerings/components/withLibrary.js'
import PageLoader from '_legacy/common/Template/PageLoader.js'

import LessonBookWrapper from './components/LessonBookWrapper/'

import VoiceOverLessonBook from './components/VoiceOverLessonBook/'
import LiveVirtualLessonBook from './components/LiveVirtualLessonBook/'
import LiveInPersonLessonBook from './components/LiveInPersonLessonBook/'

import {
  videoVoiceTextContentBook,
  liveVirtualTextContentBook,
  liveInPersonTextContentBook,
} from './constants.js'

const LESSON_KEYS = {
  videoVoiceOverLesson: 'videoVoiceOverLesson',
  liveVirtualLesson: 'liveVirtualLesson',
  liveInPersonLesson: 'liveInPersonLesson',
}


const LessonsPublic = () => {
  const t = useGetTranslate()
  const [activeBlock, setActiveBlock] = React.useState(LESSON_KEYS.videoVoiceOverLesson)

  const lessons = useLessons(state => get(state, 'lessons'))
  const talent = useLessons(state => get(state, 'talent'))
  const fullName = getFullName(talent)

  const fetchLessonsPublic = useFetchLessonsPublic()
  const clearLessons = useSetLessons(() => null)

  React.useEffect(() => {
    fetchLessonsPublic()
    return () => {
      clearLessons()
    }
  }, [])

  if (!lessons || !talent) {
    return <PageLoader />
  }

  const setElement = key => e => {
    e.preventDefault()
    setActiveBlock(key)
  }

  const addTalentNameToTranslates = tConfig => insertPhsValue(
    tConfig,
    [{ ['{{talentName}}']: fullName }]
  )

  const voiceOverLessonDefaultProps = get(lessons, 'videoVoiceOverLesson')
  const isShowVoiceOverLesson = !!voiceOverLessonDefaultProps
  const voiceOverLessonProps = {
    ...(voiceOverLessonDefaultProps || {}),
    header: 'lessons.header.video-voice',
    rules: addTalentNameToTranslates(videoVoiceTextContentBook),
    offeringType: TalentOffersType.VIDEO_VOICE_OVER_LESSON,
  }

  const liveVirtualLessonDefaultProps = get(lessons, 'liveVirtualLesson')
  const isShowLiveVirtualLesson = !!liveVirtualLessonDefaultProps
  const liveVirtualLessonProps = {
    ...(liveVirtualLessonDefaultProps || {}),
    header: 'lessons.header.live-virtual',
    rules: addTalentNameToTranslates(liveVirtualTextContentBook),
    offeringType: TalentOffersType.VIRTUAL_LESSON,
    talent,
  }

  const liveInPersonLessonDefaultProps = get(lessons, 'liveInPersonLesson')
  const isShowLiveInPersonLesson = !!liveInPersonLessonDefaultProps
  const liveInPersonLessonProps = {
    ...(liveInPersonLessonDefaultProps || {}),
    header: 'lessons.header.live-in-person',
    rules: addTalentNameToTranslates(liveInPersonTextContentBook),
    offeringType: TalentOffersType.LIVE_IN_PERSON_LESSON,
    talent,
  }

  const lessonBookCards = [
    {
      key: LESSON_KEYS.videoVoiceOverLesson,
      show: isShowVoiceOverLesson,
      props: voiceOverLessonProps,
      Component: VoiceOverLessonBook,
    },
    {
      key: LESSON_KEYS.liveVirtualLesson,
      show: isShowLiveVirtualLesson,
      props: liveVirtualLessonProps,
      Component: LiveVirtualLessonBook,
    },
    {
      key: LESSON_KEYS.liveInPersonLesson,
      show: isShowLiveInPersonLesson,
      props: liveInPersonLessonProps,
      Component: LiveInPersonLessonBook,
    }
  ]

  return (
    <div className="lessons-container wrapper content-wrapper">
      <h1>
        {t('lessons.header.book')}
        <br />
        <span>{fullName}!</span>
      </h1>
      <div className="lessons__wrapper">
        <ul className="lessons__tabs">
          {
            lessonBookCards
              .filter(({ show }) => !!show)
              .map(card => (
                <li key={card.key}>
                  <button
                    className={`lessons__tabs_tab ${
                      activeBlock === card.key ? 'on' : ''
                    }`}
                    onClick={setElement(card.key)}
                  >
                    {t(card.props?.header)}
                  </button>
                </li>
              ))
          }
        </ul>

        <div className="lessons-list lesson">
          {
            lessonBookCards
              .filter(({ show }) => !!show)
              .map(card => (
                <LessonBookWrapper
                  key={card.key}
                  header={card.props?.header}
                  showActiveBlock={card.key === activeBlock}
                >
                  <card.Component {...card.props } />
                </LessonBookWrapper>
              ))
          }
        </div>
      </div>
    </div>
  )
}

export default withLibrary(LessonsPublic)
