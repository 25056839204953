import React from 'react'

export default React.memo(function DocumentHandler () {
  React.useEffect(() => {
    const html = document.getElementsByTagName('html')[0]
    const body = document.getElementsByTagName('body')[0]
    html.style.overflow = 'hidden'
    html.style.height = '100%'
    body.style.overflow = 'hidden'
    body.style.height = '100%'
    return () => {
      html.removeAttribute('style')
      body.removeAttribute('style')
    }
  }, [])
  return null
})
