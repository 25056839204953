import React from 'react';
import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { types } from 'shared';
const { PHOTO, VIDEO, FILE } = types.file.Types

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { multiSelectLibraryValidate } from 'utils/services/validators/library';
import { validateEventNameMinLength } from 'utils/services/validators/eventNameValidator'

import { Row } from '_legacy/common';
import DeliveryField from './components/Delivery';
import RequiredLabel from './components/RequiredLabel';
import PriceInput from '_legacy/common/Input/PriceInput';
import UploaderFiles from '_legacy/common/UploaderFiles';
import SubmitButtons from './components/SubmitButtons';
import TextAreaField from '_legacy/common/Textarea/TextAreaField';
import MultiSelectLibrary from '../Library/MultiSelectLibrary';
import MultiSelectBackdrop from '_legacy/components/Library/configs/MutliSelect/MultiSelectBackdrop';
import RowInput from '_legacy/common/Input/RowInput';
import OfferingsLinkToManagerPromoCodes from '_legacy/components/OfferingsLinkToManagerPromoCodes';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

const ReadyMadeEventForm = ({ onCreate, onDraft, methods }) => {
  const t = useGetTranslate();
  const accept = [PHOTO, VIDEO];

  return (
    <StyledWrapper>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onCreate)}>
          <RequiredLabel />
          <RowInput
            isRequired
            label={t('offerings.ready-made.product-name.label')}
            name='productName'
            placeholder={t('offerings.ready-made.product-name.placeholder')}
            requiredMessage='offerings.ready-made.product-name.error'
            validation={{
              validate: {
                minLength: eventName => t(validateEventNameMinLength(eventName)),
                maxLength: eventName => (eventName || '').length < 50 || t('offerings.ready-made.product-name.max-length-error')
              }
            }}
          />
          <Row
            nameBlock={t('offerings.ready-made.product-price.label')}
            isRequired
            component={
              <PriceInput
                name="productPrice"
                placeholder={t('offerings.ready-made.product-price.placeholder')}
                requiredMessage='offerings.ready-made.product-price.error'
                isRequired
              />
            }
          />
          <TextAreaField
            name='productDescription'
            maxLength={500}
            label='offerings.ready-made.product-description.label'
            placeholder='offerings.ready-made.product-description.placeholder'
            requiredMessage='offerings.ready-made.product-description.error'
            isRequired
          />
          <Row
            nameBlock={t('offerings.ready-made.media.label')}
            isRequired
            component={
              <MultiSelectLibrary
                name='media'
                maxSelectItems={5}
                dropZoneProps={{ accept }}
                customBackdrop={MultiSelectBackdrop}
                rules={{ validate: multiSelectLibraryValidate }}
              />
            }
          />
          <UploaderFiles
            name='productUpload'
            label={t('offerings.ready-made.upload.label')}
            titleHint={t('register.form.licenses.body.header')}
            textHint={t('offerings.ready-made.upload.hint-placeholder')}
            typesFiles={[FILE, PHOTO, VIDEO]}
            requiredMessage='offerings.ready-made.upload.error'
            isRequired
          />
          <TextAreaField
            name='productReceptionInstructions'
            maxLength={500}
            label='offerings.ready-made.instructions.label'
            placeholder='offerings.ready-made.instructions.placeholder'
          />
          <DeliveryField />
          <OfferingsLinkToManagerPromoCodes />
          <SubmitButtons onDraft={onDraft} />
          <MissedData />
        </form>
      </FormProvider>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .input-format {
    height: 60px;
  }
  .multi-select-library__open-modal-btn {
    background-color: transparent;
  }
`;

ReadyMadeEventForm.propTypes = {
  onCreate: PropTypes.func.isRequired,
  onDraft: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
};

export default ReadyMadeEventForm;
