import React from 'react'

import MenuDots from 'components/Calendar/components/MenuDots'


export const withDotMenu = CalendarComponent => ({
  openListOptions = [],
  ...restProps
}) => {
  return (
    <div className="calendar--pr32">
      <MenuDots optionsList={openListOptions} />
      <CalendarComponent
        {...restProps}
      />
    </div>
  )
}
