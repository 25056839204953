import React from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'

import {
  useWindowDimensions,
  useFullpageBlock,
} from 'store/hooks/globalState/useGlobalState'

import DocumentHandler from './DocumentHandler'
import ScrollHandler from './ScrollHandler'
import KeyHandler from './KeyHandler'
import Navigation from './Navigation'
import Footer from '_legacy/components/Footer'

let prevTime = new Date().getTime()

const ANIMATION_DELAY = 1000
const MIN_SECTION_HEIGHT = 820

let isMount1 = false
let isMount2 = false
let isMount3 = false

function isAnimated () {
  const currentTime = new Date().getTime()
  if (currentTime - prevTime <= ANIMATION_DELAY) return true
  return false
}

function getSectionScroll ({ refsList, section, windowHeight }) {
  const parentNode = refsList[section].current
  const sectionNode = parentNode.getElementsByClassName('section')[0]
  const sectionNodeHeight = Math.round(sectionNode.getBoundingClientRect().height)
  const maxScroll = sectionNodeHeight - windowHeight
  return maxScroll
}

export default function Fullpage ({ config }) {
  const windowDimensions = useWindowDimensions()
  const isBlocked = useFullpageBlock()
  const [activeSection, setActiveSection] = React.useState(0)
  const [sectionsScrolls, setSectionsScrolls] = React.useState({})

  const refsList = React.useMemo(() => {
    return config.map(() => React.createRef())
  }, [config])

  const wrapperRef = React.useRef()

  React.useEffect(() => {
    if (!config[activeSection]) setActiveSection(config.length - 1)
  }, [config, activeSection, setActiveSection])

  React.useEffect(() => {
    if (isMount1) {
      prevTime = new Date().getTime()
    } else {
      isMount1 = true
    }
  }, [activeSection])

  React.useEffect(() => {
    if (isMount2) {
      setSectionsScrolls({})
    } else {
      isMount2 = true
    }
  }, [windowDimensions])

  React.useEffect(() => {
    if (isMount3) {
      if (isAnimated()) {
        document.querySelectorAll('.blured-on-scroll').forEach(node => {
          node.style.background = 'rgba(39, 40, 53, 1)'
        })
        setTimeout(() => {
          if (!sectionsScrolls[activeSection]) {
            document.querySelectorAll('.blured-on-scroll').forEach(node => {
              node.style.background = 'transparent'
            })
          }
        }, 700)
      } else {
        if (!sectionsScrolls[activeSection]) {
          document.querySelectorAll('.blured-on-scroll').forEach(node => {
            node.style.background = 'transparent'
          })
        } else {
          document.querySelectorAll('.blured-on-scroll').forEach(node => {
            node.style.background = 'rgba(39, 40, 53, 1)'
          })
        }
      }
    } else {
      isMount3 = true
    }
  }, [activeSection, sectionsScrolls])

  React.useEffect(() => {
    return () => {
      isMount1 = false
      isMount2 = false
      isMount3 = false
      document.querySelectorAll('.blured-on-scroll').forEach(node => {
        node.style.background = 'transparent'
      })
    }
  }, [])

  const handleSectionChange = React.useCallback(({ direction, config }) => {
    setActiveSection(current => {
      let newActiveSection = current + direction
      if (newActiveSection < 0) newActiveSection = 0
      if (newActiveSection >= config.length - 1) newActiveSection = config.length - 1
      return newActiveSection
    })
  }, [setActiveSection])

  const throttledSectionChange = React.useRef(throttle(handleSectionChange, ANIMATION_DELAY, { trailing: false }))
  const scrollHandler = React.useCallback(({ direction, deltaY }) => {
    if (isAnimated()) return
    const maxScroll = getSectionScroll({ refsList, section: activeSection, windowHeight: windowDimensions.height })
    if (!maxScroll) return throttledSectionChange.current({ direction, config })
    if (direction === -1 && sectionsScrolls[activeSection] === 0) return throttledSectionChange.current({ direction, config })
    if (direction === 1 && sectionsScrolls[activeSection] === maxScroll) return throttledSectionChange.current({ direction, config })
    setSectionsScrolls(current => {
      let newSectionScroll = (current[activeSection] || 0) + deltaY
      if (newSectionScroll < 0) newSectionScroll = 0
      if (newSectionScroll > maxScroll) newSectionScroll = maxScroll
      return { ...current, [activeSection]: newSectionScroll }
    })
  }, [refsList, activeSection, windowDimensions, sectionsScrolls, setSectionsScrolls, throttledSectionChange, config, wrapperRef])

  const wrapperStyles = React.useMemo(() => {
    const shouldScroll = windowDimensions.height > MIN_SECTION_HEIGHT
    const sectionHeight =  shouldScroll ? windowDimensions.height : MIN_SECTION_HEIGHT

    return { transform: `translate(0, -${sectionHeight * activeSection}px)` }
  }, [windowDimensions, activeSection])

  const sectionStyles = React.useMemo(() => {
    return {
      width: windowDimensions.width,
      height: windowDimensions.height
    }
  }, [windowDimensions])

  const ignoreList = [
    '.multi-level-select',
  ]

  return (
    <>
      <DocumentHandler />
      <ScrollHandler scrollHandler={scrollHandler} isBlocked={isBlocked} ignoreList={ignoreList} />
      <KeyHandler scrollHandler={scrollHandler} />
      <Navigation
        config={config}
        activeSection={activeSection}
        setActiveSection={setActiveSection}
      />
      <StyledWrapper
        ref={wrapperRef}
        style={wrapperStyles}
      >
        {config.map((Component, i) => {
          const scrollerStyles = {
            transform: `translate(0, -${sectionsScrolls[i] || 0}px)`,
            transition: 'all .3s ease-out'
          }
          return (
            <div
              key={i}
              ref={refsList[i]}
              className='section-wrapper'
              style={sectionStyles}
            >
              <div className='scroller' style={{ ...sectionStyles, ...scrollerStyles }}>
                <Component setActiveSection={setActiveSection} active={activeSection === i} />
              </div>
            </div>
          )
        })}
      </StyledWrapper>
      <Footer />
    </>
  )
}

const StyledWrapper = styled.div`
  height: 100%;
  overflow: hidden;
  transition: transform 700ms ease 0s;
  .section-wrapper {
    min-height: ${MIN_SECTION_HEIGHT}px;
    overflow: hidden;
    .scroller {
      transition-timing-function: cubic-bezier(0.1, 0.57, 0.1, 1);
      transition-duration: 0ms;
    }
  }
`
