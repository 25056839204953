import React, { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useWindowDimensions } from 'store/hooks';

import Pointer from './Pointer';
import Item from './Item';

const Menu = ({ menuItems, className }) => {
  const t = useGetTranslate()
  const history = useHistory()
  const [isAnimate, setIsAnimate] = useState(true);
  const [widthChangedTime, setWidthChangedTime] = useState(0);

  const listRef = useRef();

  useWindowDimensions();

  const { pathname } = useLocation();

  const currentIndex = menuItems.findIndex(item => item.pathname === pathname);

  if (currentIndex === -1) {
    const path = get(menuItems, [0, 'pathname'], '/')
    history.push(path)
  }

  return (
    <div className={`account-submenu ${className}`}>
      <Pointer
        type="upper"
        listRef={listRef}
        itemRefs={menuItems.map(i => i.itemRef)}
        currentIndex={currentIndex}
        isAnimate={isAnimate}
        isCalculate={widthChangedTime !== 0}
      />
      <div className="account-submenu-list-wrapper">
        <ul className="account-submenu-list" ref={listRef}>
          {menuItems.map((item, index) => (
            <Item
              active={currentIndex === index}
              key={item.translation}
              itemRef={item.itemRef}
              label={t(item.translation)}
              linkPath={item.pathname}
              onWidthChanged={() => setWidthChangedTime(widthChangedTime + 1)}
              onHover={() => setIsAnimate(true)}
            />
          ))}
        </ul>
      </div>
      <Pointer
        type="lower"
        listRef={listRef}
        itemRefs={menuItems.map(i => i.itemRef)}
        currentIndex={currentIndex}
        isAnimate={isAnimate}
        isCalculate={widthChangedTime !== 0}
      />
    </div>
  );
};

export default Menu
