import React from 'react'
import styled from 'styled-components'

import * as r from '_legacy/constants/routes'

import { useSetModal } from 'store/hooks/globalState/useModal'
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'

function RequiredPaymentsInformationModal() {
  const t = useGetTranslate()
  const navigate = useNavigate()
  const closeModal = useSetModal(() => null)

  const onConfirm = () => {
    navigate(`/${r.ACCOUNT}/${r.PAYMENTS}`)
    closeModal()
  }

  return (
    <ModalPortal isOpen onClose={closeModal} showCloseButton isMobileFullScreen>
      <ModalWrapper className="check-email-modal">
        <h2 className="check-email-modal__header">{t('offerings.new.required-payments-info-modal.title')}</h2>
        <p className="check-email-modal__desc">{t('offerings.new.required-payments-info-modal.description')}</p>

        <ButtonContainer>
          <Button
            stretch
            text={t('offerings.new.required-payments-info-modal.cancel')}
            typeButton="button"
            paddingVertical={13}
            handleOnClick={closeModal}
            primary
          />
          <Button
            stretch
            text={t('offerings.new.required-payments-info-modal.confirm')}
            typeButton="button"
            paddingVertical={13}
            handleOnClick={onConfirm}
          />
        </ButtonContainer>
      </ModalWrapper>
    </ModalPortal>
  )
}

const ModalWrapper = styled.div`
  min-width: 320px;
  width: 660px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 15px 40px;
  border-radius: 4px;
  @media screen and (max-width: 1280px) {
    width: 628px;
    padding: 16px 24px;
  }
  @media screen and (max-width: 768px) {
    width: 550px;
  }
  @media screen and (max-width: 575px) {
    width: 100%;
    height: 100vh;
    border-radius: 0;
  }
  .check-email-modal {
    &__header {
      font-size: 32px;
      margin-bottom: 24px;
      @media screen and (max-width: 1280px) {
        font-size: 28px;
      }
      @media screen and (max-width: 575px) {
        margin-top: auto;
      }
    }
    &__desc {
      margin-top: 0;
      margin-bottom: 32px;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.625;
      color: ${({ theme }) => theme.colors.grey};
      @media screen and (max-width: 575px) {
        text-align: center;
      }
    }
  }
`

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media screen and (max-width: 575px) {
    margin-top: auto;
  }
  .button:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    width: 100%;

    .button:not(:last-of-type) {
      margin-right: 0;
      margin-top: 16px;
    }
  }
`

export default RequiredPaymentsInformationModal
