import { _delete, _get, _post } from 'api/utils'

export const verifyPromoCode = ({ promoCode, offerId, orderId }) => _post(`verify/promo-code/${promoCode}/check-available-for-offer`, {
  query: { offerId, orderId },
  needAuthorization: true
})

export const getOrderOffer = (orderId) => _get(`/orders/${orderId}`, { needAuthorization: true })

export const sendPaymentData = async ({ orderId, paymentMethodId, promoCodeId, saveCard, shippingAddress }) => {
  try {
    return await _post(`/orders/${orderId}/purchase`, {
      query: { paymentMethodId, promoCodeId, saveCard }, body: { shippingAddress }, needAuthorization: true
    })
  } catch (error) {
    const { context } = error

    throw context ? { code: context.declinedCode, message: context.message } : error.name?.toLowerCase()
  }
}

export const getPaymentCard = () => _get('/auth/me/payments/cards', { needAuthorization: true })

export const setPaymentCard = (paymentMethodId) =>
  _post('/auth/me/payments/cards', { body: { paymentMethodId }, needAuthorization: true })

export const deletePaymentCard = (paymentMethodId) =>
  _delete(`/auth/me/payments/cards/${paymentMethodId}`, { needAuthorization: true })

export const setDefaultPaymentCard = (paymentMethodId) =>
  _post(`/auth/me/payments/cards/${paymentMethodId}/set-as-default`, { needAuthorization: true })


// entourage purchase
export const purchaseEntourageStatus = async ({ orderId, paymentMethodId, saveCard }) => {
  try {
    return await _post(`/auth/me/entourage/purchase/${orderId}`, {
      query: { paymentMethodId, saveCard }, needAuthorization: true
    })
  } catch (error) {
    const { context } = error
    throw context ? { code: context.declinedCode, message: context.message } : error.name?.toLowerCase()
  }
}
