import {
  languageConfig,
  locationKmRadiusConfig,
  offeringTypesConfig,
  searchConfig,
  searchSubFiltersConfig,
  profileTypeConfig,
} from '../shared'
import {
  locationConfig,
  followersConfig,
  ratingConfig
} from './configs'

export const filtersConfig = [
  searchConfig,
  profileTypeConfig,
  offeringTypesConfig,
  locationConfig,
  locationKmRadiusConfig,
  languageConfig,
  followersConfig,
  ratingConfig,
]

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...searchSubFiltersConfig.map(({ field }) => field),
]
