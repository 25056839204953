import React from 'react'
import { EntourageSubscriptionType } from 'shared'
import { pipe, prop, eq, cond, T  } from 'lodash/fp'

import FullStatus from './components/FullStatus'
import Inactive from './components/Inactive'
import LiteStatus from './components/LiteStatus'
import AccountTemplate from '../AccountTemplate'

import { useUser } from 'store/hooks/globalState/useUser'


const AccountEntourageStatus = React.memo(() => {
  const Component = useUser(
    pipe(
      prop('user.entourageType'),
      cond([
        [eq(EntourageSubscriptionType.LITE), () => <LiteStatus />],
        [eq(EntourageSubscriptionType.FULL), () => <FullStatus />],
        [T, () => <Inactive />],
      ])
    )
  )

  return (
    <AccountTemplate title='account.menu.entourage.title'>
      <div className="account-entourage-status">
        {Component}
      </div>
    </AccountTemplate>
  )
})

export default AccountEntourageStatus
