import { get } from 'lodash'
import { secondsFromStartDate } from 'utils/date'
import { areIntervalsOverlapping, set } from 'date-fns'

export const applyBusinessHours = (slots, businessHours) => {
  if (get(businessHours, 'daysOfWeek.length', 0) === 0) return slots

  const [businessHoursStartTimeHours, businessHoursStartTimeMinutes] = businessHours.startTime.split(':')
  const [businessHoursEndTimeHours, businessHoursEndTimeMinutes] = businessHours.endTime.split(':')
  const businessHoursStartTime = +businessHoursStartTimeHours * 3600 + +businessHoursStartTimeMinutes * 60
  const businessHoursEndTime = +businessHoursEndTimeHours * 3600 + +businessHoursEndTimeMinutes * 60

  return slots
    .map(slot => {
      if ((businessHours.daysOfWeek || []).includes(new Date(slot.start).getDay())) {
        const currentSlotStartTime = secondsFromStartDate(slot.start)
        const currentSlotEndTime = secondsFromStartDate(slot.end)

        const businessHoursStartTimeDate = set(new Date(slot.start), {
          hours: businessHoursStartTimeHours,
          minutes: businessHoursStartTimeMinutes,
        })
        const businessHoursEndTimeDate = set(new Date(slot.end), {
          hours: businessHoursEndTimeHours,
          minutes: businessHoursEndTimeMinutes,
        })

        if (currentSlotEndTime < businessHoursStartTime) return null

        if (currentSlotStartTime > businessHoursEndTime) return null

        if (
          areIntervalsOverlapping(
            { start: businessHoursStartTimeDate, end: businessHoursEndTimeDate },
            { start: slot.start, end: slot.end }
          )
        ) {
          return {
            ...slot,
            start: new Date(slot.start) < businessHoursStartTimeDate ? businessHoursStartTimeDate : new Date(slot.start),
            end: new Date(slot.end) < businessHoursEndTimeDate ? new Date(slot.end) : businessHoursEndTimeDate,
          }
        }

        return slot
      }
    })
    .filter(Boolean)
}

