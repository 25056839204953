import React from 'react'

import { offeringsTypes } from '_legacy/configs/table/manageOrders'

import CommonOffering from '_legacy/components/Table/table-components/types/common/Offering'

const Type = React.memo(({ isLoading, id, type, title }) => (
  <CommonOffering
    isLoading={isLoading}
    id={id}
    type={type}
    title={title}
    config={offeringsTypes[type]}
  />
))

export default Type
