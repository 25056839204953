import React from 'react'
import styled from 'styled-components'

import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { Row } from '_legacy/common/Template'
import Body2 from '_legacy/common/Typography/Body2'
import SelectComponent from '../../../Form/Selects/SelectComponent'
import { getDurationOptions } from '../../utils/timeConfig'
import { getLocale } from 'utils/date'

const TravelTime = () => {
  const t = useGetTranslate()
  const locale = useUserLang(getLocale)
  const durationOptions = getDurationOptions({ locale })

  return (
    <Row
      nameBlock={t('create-experience.form.travel-time.label')}
      subNameBlock={t('create-experience.form.travel-time.tip')}
      isRequired
      component={
        <StyledTime>
          <StyledTimeWrapper>
            <Body2 textAlign="end">{t('create-experience.form.travel-time.before')}</Body2>
            <SelectComponent
              name="travelTime.before"
              rules={{
                required: 'shared.words.required',
              }}
              options={durationOptions}
              placeholder="00:00"
            />
          </StyledTimeWrapper>
          <StyledTimeWrapper>
            <Body2 textAlign="end">{t('create-experience.form.travel-time.after')}</Body2>
            <SelectComponent
              name="travelTime.after"
              rules={{
                required: 'shared.words.required',
              }}
              options={durationOptions}
              placeholder="00:00"
            />
          </StyledTimeWrapper>
        </StyledTime>
      }
    />
  )
}

const StyledTimeWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 176px;
  grid-column-gap: 8px;
  align-items: center;

  .error-message {
    position: absolute;
    margin-top: 4px;
  }

  @media screen and (max-width: 560px) {
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
  }
`

const StyledTime = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 12px;
`

export default TravelTime
