import { getLocationFormattedAddress } from 'utils/location.js'
import { getLocationAddressWithName } from 'utils/location.js'

export const getLocationAdapter = location => {
  if (!location) return

  return {
    value: location.id,
    label: getLocationFormattedAddress(location),
  }
}
export const getLocationNameAdapter = location => {
  if (!location) return

  return {
    value: location.id,
    label: getLocationAddressWithName(location),
  }
}
