import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import get from 'lodash/get'

import Tooltip from '_legacy/components/Tooltip';
import MediaViewer from '_legacy/containers/PromoCodes/components/Custom/MediaViewer';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes';
import { offeringsInfo } from '_legacy/containers/PromoCodes/utils/offeringsTypesOptions';

import { StyledImage, StyledInfo, StyledName, StyledTitle } from '_legacy/containers/PromoCodes/components/Custom/rowStyled';

const CustomOffering = ({ offering }) => {
  const t = useGetTranslate();
  const offeringType = get(offering, 'type');
  const { title, label, Icon, media } = offeringsInfo(offering)[offeringType];

  const handleRemove = useSetOfferingsPromoCodesFilters((prev, next) => {
    const selected = get(prev, 'selected', [])
    const newSelected = selected.filter(select => select.id !== next)
    return ({
      ...prev,
      selected: newSelected
    })
  });

  return (
    <StyledItem>
      <StyledInfo>
        <StyledImage>
          <MediaViewer file={media} />
        </StyledImage>
        <div>
          <StyledTitle>
            <Tooltip tooltipElement={title}>
              <span>{title}</span>
            </Tooltip>
          </StyledTitle>
          <StyledName><Icon /> {t(label)}</StyledName>
        </div>
      </StyledInfo>
      <StyledDelete type='button' onClick={() => handleRemove(offering.id)} />
    </StyledItem>
  )
}

const StyledItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:not(:last-of-type) {
    margin-bottom: 16px;
  }
`
const StyledDelete = styled.button`
  position: relative;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  width: 20px;
  height: 20px;
  background-color: transparent;
  transition: background 0.3s ease-in-out;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 14px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.lightGrey};
    transform: translate(-50%, -50%) rotate(0);
  }
  &:before {transform: translate(-50%, -50%) rotate(45deg);}
  &:after {transform: translate(-50%, -50%) rotate(-45deg);}
  &:hover {
    &:before, &:after {
      background-color: ${({ theme }) => theme.colors.blue};
    }
  }
`

CustomOffering.propTypes = {
  offering: PropTypes.object.isRequired
}

export default CustomOffering
