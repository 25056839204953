import { _get, _post, _put } from 'api/utils'

export const createVideoChat = ({ talentId, data }) =>
  _post(`/talents/${talentId}/offers/videoChat`, {
    needAuthorization: true,
    body: data,
  })

export const getVideoChat = ({ talentId }) =>
  _get(`/talents/${talentId}/offers/videoChat`, {
    needAuthorization: true
  })

export const updateVideoChat = ({ talentId, data }) =>
  _put(`/talents/${talentId}/offers/videoChat`, {
    needAuthorization: true,
    body: data,
  })

export const bookVideoChat = ({ offerVideoChatId, data }) =>
  _post(`/offers/video-chats/${offerVideoChatId}/book`, {
    needAuthorization: true,
    body: data,
  })
