import React from 'react';
import styled from 'styled-components';

import { Container, Title, SubTitle } from '_legacy/common';
import RegistrationFormUser from '_legacy/components/RegisterForms/User';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const RegistrationUser = () => {
  const t = useGetTranslate();

  return (
    <StyledWrapper>
      <Container>
        <SubTitle className='sub-title'>{t('register-user.page.sub-header')}</SubTitle>
        <Title className='title'>{t('register-user.page.header')}</Title>
        <RegistrationFormUser/>
      </Container>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .sub-title {
    margin-bottom: 10px;
  }
  .title {
    margin-bottom: 50px;
  }
  .radio-wrapper {
    display: flex;
    align-items: center;
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #626473;
    line-height: 1.625;
    margin-top: 16px;
    margin-bottom: 0;
  }
`;

export default RegistrationUser;
