export const PROFILE_MANAGERS = 'profile-managers'
export const TALENT = 'talent'
export const USER = 'user'
export const AFICIONADO = 'aficionado'
export const REGISTER = 'register'
export const CONTACT = 'contact'
export const HOW_IT_WORKS = 'how-it-works'
export const WHY_JOIN = 'why-join'
export const FAQS = 'faqs'
export const LESSONS = 'lessons'
export const EXPERIENCES = 'experiences'
export const OFFERINGS = 'offerings'
export const OCCURRENCES = 'occurrences'
export const LIVE_VIRTUAL_EVENTS = 'live-virtual-events'
export const FLEXIBLE = 'flexible'
export const VERIFY_EMAIL = 'verify-email'
export const CAREERS = 'careers'
export const RESET_PASSWORD = 'reset-password'
export const ABOUT_US = 'about-us'
export const VIDEO_MESSAGE = 'video-message'
export const VIDEO_CHAT = 'video-chat'
export const MASTER_CLASS = 'master-class'
export const COMMERCIAL_VIDEO_MESSAGE = 'commercial-video-message'
export const ACCOUNT = 'account'
export const EDIT_PROFILE = 'edit-profile'
export const NOTIFICATIONS = 'notifications'
export const CALENDAR = 'calendar'
export const PAYMENTS = 'payments'
export const CHANGE_PASSWORD = 'change-password'
export const READY_MADE_EVENT_LESSON = 'ready-made-event-lesson'
export const PREVIEW = 'preview'
export const COMMERCIAL_ENGAGEMENTS = 'commercial-engagements'
export const REQUEST = 'request'
export const JOIN = 'join'
export const NOT_FOUND = 'not-found'
export const TALENT_PENDING_FOR_APPROVAl = 'talent-pending-for-approval'
export const PROMO_CODES = 'promo-codes'
export const REMOVE = 'remove'
export const VERIFY = 'verify'
export const DRAFT = 'draft'
export const NEW = 'new'
export const LIST = 'list'
export const LISTING = 'listing'
export const RECURRING = 'recurring'
export const HOME = '/'
export const HOMEPAGE = 'homepage'
export const EDIT = 'edit'
export const SEARCH = 'search'
export const PURCHASE = 'purchase'
export const ORDERS = 'orders'
export const MEDIA = 'media'
export const FAVORITES = 'favorites'
export const REVIEWS = 'reviews'
export const BY_ME = 'by-me'
export const TO_ME = 'to-me'
export const DETAILS = 'details'
export const REQUESTS = 'requests'
export const PARTICIPANTS = 'participants'
export const PRODUCT = 'product'
export const SECURE_CHAT = 'secure-chat'
export const VIDEO_VOICE_OVER_LESSON = 'video-voice-over-lesson'
export const LIVE_IN_PERSON_LESSON = 'live-in-person-lesson'
export const LIVE_VIRTUAL_LESSON = 'live-virtual-lesson'
export const BOOKINGS = 'bookings'
export const KEYWORDS = 'keywords'
export const HOW_TO_VIDEOS = 'how-to-videos'
export const ROOM = 'room'
export const DOWNLOAD = 'download'
export const ACCOUNT_LINKS = 'account-links'
export const REFRESH = 'refresh'
export const RESOURCE_CENTER = 'resource-center'
export const RESOURCE_CENTER_GUIDES = 'guides'
export const RESOURCE_CENTER_HOW_TO_VIDEOS = 'how_to_videos'
export const RESOURCE_CENTER_FREE_ADVERTISEMENTS = 'free_advertisements'
export const RESOURCE_CENTER_MEDIA_KITS_TEMPLATES = 'media_kit_templates'
export const ENTOURAGE = 'entourage'
export const FULL = 'full'
export const LITE = 'lite'
export const UPGRADE = 'upgrade'
export const UNSUBSCRIBE_EMAIL = 'unsubscribe-email'
export const LANGUAGE_AND_CURRENCY = 'language-and-currency'
// Terms
export const SITE_TERMS_OF_SERVICE = 'siteterms'
export const TALENT_TERMS_OF_SERVICE = 'talentterms'
export const PRIVACY_POLICY = 'privacy'
export const COMMUNITY_GUIDELINES = 'guidelines'
export const FEE_TERMS = 'feeterms'
export const BOOKING_FEE_SCALE = 'bookingfeescale'

// Hash routes
export const HASH_LOGIN = 'login'
export const HASH_SEARCH_BY = 'search-by'
export const HASH_OFFERING = 'offering'
export const HASH_TALENT = 'talent'
export const HASH_LOCATION = 'location'

export const URGENT = 'urgent'
