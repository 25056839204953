// Modules
import React from 'react'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser'

// Utils
import { getUserTimeZone } from 'utils/user'
import { parseDate } from 'utils/services/timezone'
import { getMonthDayYearLocaleDate } from 'utils/date';

// Legacy components
import LineString from '_legacy/components/Table/table-components/types/common/LineString.js'


export default React.memo(function UploadDate ({ isLoading, uploadedAt }) {
  const timeZone = useUser(getUserTimeZone)
  const locale = useUserLocale()
  const date = getMonthDayYearLocaleDate(parseDate(uploadedAt, timeZone), locale)

  return <LineString isLoading={isLoading} str={date} />
})
