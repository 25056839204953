import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const PointerLine = ({ reverse, activeIconIndex }) => {
  const withLineDesktop = 16 + activeIconIndex * (62 + 7 * 2);

  return (
    <StyledWrapper>
      <StyledLineBefore widthLine={withLineDesktop} />
      <StyledLineArrow className="pointer-line-arrow" reverse={reverse} />
      <StyledLineAfter />
    </StyledWrapper>
  );
};

const line = css`
  height: 1px;
  min-width: 11px;
  transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
`

const StyledWrapper = styled.div`
  display: flex;
  max-width: 85%;
`

const StyledLineBefore = styled.div`
  ${line};
  background-color: ${({ theme }) => theme.colors.blue};
  width: ${({ widthLine }) =>  widthLine}px;
`
const StyledLineAfter = styled.div`
  ${line};
  flex: 1;
  background-image: linear-gradient(90deg, rgb(39, 40, 53) 0%, rgb(160, 161, 168) 80%);
`
const StyledLineArrow = styled.div`
  width: 6px;
  position: relative;
  ${({ reverse }) => reverse && css `
    transform: rotate(180deg);
  `}
  &:before, &:after {
    content: '';
    height: 1px;
    width: 5px;
    position: absolute;
    left: -1.1px;
    top: -2px;
    display: block;
    transform: rotate(-45deg);
    background-color: ${({ theme }) => theme.colors.blue};
  }
  &:after {
    left: 1.9px;
    transform: rotate(45deg);
  }
  &[reverse=true] {
    transform: rotate(180deg);
  }
`


PointerLine.defaultProps = {
  offset: 16.5,
  reverse: false,
};

PointerLine.propTypes = {
  maxWidth: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  offset: PropTypes.number,
  reverse: PropTypes.bool,
  activeIconIndex: PropTypes.number,
  sizeBlock: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default PointerLine;
