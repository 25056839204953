// Modules
import React from 'react'
import { useParams } from 'react-router-dom'
import set from 'lodash/set'

// Assets
import Icon from 'assets/icons/Icon'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useIsLoadingChat } from 'store/hooks/globalState/useChats'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useStartChat } from 'requests/chats'
import { useCheckComplaint } from 'requests/complaint'
import { useFetchLiveInPersonLessonBookingsDetails } from './state/request'
import { useLiveInPersonLessonBookingsDetails, useSetLiveInPersonLessonBookingsDetails } from './state/store'

// Utils
import { getLocationLabel, getLocationPlace } from 'utils/location'
import { getDateTimeProps, getDaysTo, getLocale, getMonthDayOnly, getTimeRange } from 'utils/date'
import { getTimeZoneFromOccurrenceLocation } from 'utils/forms/adapters/timezone'
import { getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText, getReasonText } from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderHold from 'components/OfferingDetails/components/OfferingsDetailsHeaderHold'
import OfferingDetailsWaiting from 'components/OfferingDetails/components/OfferingDetailsWaiting'
import OfferingDetailsBookingContact from 'components/OfferingDetails/components/OfferingDetailsBookingContact'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import { getLiveInPersonLessonBookingConfig } from './utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'
import AddToCalendarButton from 'components/Calendars/components/AddToCalendarButton'

const LiveInPersonLessonBookingsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const userTimeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  // state
  const order = useLiveInPersonLessonBookingsDetails()
  const clearOrder = useSetLiveInPersonLessonBookingsDetails(() => null)
  const isLoadingChat = useIsLoadingChat()

  const { review, occurrence, number, statusTimeline } = order || {}

  // requests
  const fetchBookingsDetails = useFetchLiveInPersonLessonBookingsDetails()
  const checkComplaint = useCheckComplaint()
  const startChat = useStartChat(occurrence?.talent?.id)
  const onReviewSuccess = React.useCallback(() => {
    fetchBookingsDetails()
    checkComplaint(orderId)
  }, [orderId, fetchBookingsDetails, checkComplaint])

  const handleContact = () => {
    if (!isLoadingChat && occurrence?.talent?.id) {
      return startChat()
    }
  }

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
  }, [])

  React.useEffect(() => () => {
    clearOrder()
  }, [])

  if (!occurrence) {
    return <PageLoader />
  }

  const { pendingAction, location, userUploads, talent, startTime: _startTime, endTime: _endTime } = occurrence

  const daysLeft = getDaysTo(new Date(pendingAction?.due), { locale: getLocale(userLang) })
  const timeZone = getTimeZoneFromOccurrenceLocation(occurrence)

  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })
  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)

  const reasonText = getReasonText({ t, ...order })
  const cancelText = getCancelText({ t, ...order })
  const c = getLiveInPersonLessonBookingConfig(order)
  const refundText = getAficionadoRefundText({ t, refundRequests: order.refundRequests })

  return (
    <div className="live-in-person-lesson-booking od">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.live-in-person-lesson')}
        title={`${t('booking-details.main.title')} #${number}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, userTimeZone, true)} />
          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />

                <div className="od__column mt-32">
                  {startTime && endTime && (
                    <div className="od__row">
                      <OfferingsDetailsHeaderItem
                        icon={<Icon.Calendar />}
                        item={formattedDate}
                        subitem={formattedTimeRange}
                      />
                    </div>
                  )}

                  {location.place && (
                    <OfferingsDetailsHeaderItem
                      icon={<Icon.Pin />}
                      item={getLocationPlace(location.place)}
                      subitem={getLocationLabel(location.place)}
                    />
                  )}
                </div>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.submitted-by.title')}</h6>
                <p className="od--main-text od--accented">
                  <OrderUploadFiles files={userUploads} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box">
            {c.isShowWaitingForApprove && (
              <OfferingDetailsWaiting
                waitingTitle={t('shared.offers.live-in-person-lesson')}
                waitingText={t('booking-details.time-left.text')}
                timeLeft={daysLeft}
              />
            )}

            {c.isShowContact && (
              <OfferingDetailsBookingContact
                title={t('shared.offers.live-in-person-lesson')}
                text={t('booking-details.contact.text')}
                contactText={t('booking-details.contact.title')}
                contactCallback={handleContact}
                // cancelText={t('booking-details.cancel.title')}
                // cancelCallback={() => openCancelModal()}
              />
            )}

            {c.isShowHold && (
              <OfferingsDetailsHeaderHold
                title={t('shared.offers.live-in-person-lesson')}
                onHoldTitle={t('order-details.info.hold')}
                date={getMonthDayOnly(startTime)}
                duration={getTimeRange([startTime, endTime])}
                locationMain={getLocationPlace(location.place)}
                locationFull={getLocationLabel(location.place)}
                typeText={t('shared.offers.live-in-person-lesson')}
              >
                <AddToCalendarButton
                  startTime={_startTime}
                  endTime={_endTime}
                  timeZone={timeZone}
                  className="fat"
                />
              </OfferingsDetailsHeaderHold>
            )}

            {c.isShowRejected && (
              <OfferingDetailsGenericStatus
                title={t('shared.offers.live-in-person-lesson')}
                type={t('order.header.rejected')}
                reasonText={reasonText}
              />
            )}

            {c.isShowCanceled && (
              <CancelStatusWithContact
                refundText={refundText}
                cancelText={cancelText}
                title={t('shared.offers.live-in-person-lesson')}
              />
            )}

            {c.isShowInProgress && (
              <OfferingDetailsGenericStatus
                title={t('shared.offers.live-in-person-lesson')}
                type={t('order.header.in-progress')}
              />
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsGenericStatus
                  title={t('shared.offers.live-in-person-lesson')}
                  type={t('booking-details.main-completed.title')}
                />
                <p className="od--main-text mb-12">{t('booking-details.main-completed.text')}</p>
                <OfferingDetailsReviewStars
                  review={review}
                  onSuccess={onReviewSuccess}
                  orderId={orderId}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('shared.offers.live-in-person-lesson')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}
          </div>

          <ComplaintInfo
            orderId={orderId}
            onSuccess={fetchBookingsDetails}
            userLink={getTalentLink(talent)}
            userName={getSaveFullName(talent)}
          />
        </div>
      </div>

    </div>
  )
}

export default LiveInPersonLessonBookingsDetails
