import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const UploadInfoModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate()
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="upload-info-modal">
        <h3 className="upload-info-modal__title">
          {t('modal.info.upload.title').toString().toUpperCase()}
        </h3>
        <br />

        <strong>{t('modal.info.upload.proportion.title')}</strong>
        {` ${t('modal.info.upload.proportion.value')}`}
        <br />

        <strong>{t('modal.info.upload.orientation.title')}</strong>
        {` ${t('modal.info.upload.orientation.value')}`}
        <br />

        <strong>{t('modal.info.upload.space.title')}</strong>
        {` ${t('modal.info.upload.space.value')}`}
        <br />

        <strong>{t('modal.info.upload.background.title')}</strong>
        {` ${t('modal.info.upload.background.value')}`}
        <br />
        <hr />

        <strong>{t('modal.info.upload.min.dimentions.title')}</strong>
        {` ${t('modal.info.upload.min.dimentions.value')}`}
        <br />

        <strong>{t('modal.info.upload.ideal.dimentions.title')}</strong>
        {` ${t('modal.info.upload.ideal.dimentions.value')}`}
        <br />

        <strong>{t('modal.info.upload.min.resolution.title')}</strong>
        <br />
        {t('modal.info.upload.min.resolution.ios.value')}
        <br />
        {t('modal.info.upload.min.resolution.android.value')}
        <br />

        <strong>{t('modal.info.upload.ideal.resolution.title')}</strong>
        <br />
        {t('modal.info.upload.ideal.resolution.ios.value')}
        <br />
        {t('modal.info.upload.ideal.resolution.android.value')}
        <br />
        <hr />


        <strong>{t('modal.info.upload.tips.title')}</strong>
        <br />
        <strong>{t('modal.info.upload.tips.value.1')}</strong>
        <br />
        {t('modal.info.upload.tips.value.2')}
        <br />
        <strong>{t('modal.info.upload.tips.value.3')}</strong>
        <br />
        {t('modal.info.upload.tips.value.4')}
        <br />
        <hr />

        <strong>{t('modal.info.upload.formats.title')}</strong>
        <br />

        <strong>{t('modal.info.upload.formats.ideal.title')}</strong>
        {` ${t('modal.info.upload.formats.ideal.value')}`}
        <br />

        <strong>{t('modal.info.upload.formats.acceptable.title')}</strong>
        {` ${t('modal.info.upload.formats.acceptable.value')}`}
        <br />

        {t('modal.info.upload.note.title')}
      </div>
    </ModalPortal>
  );
};

UploadInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default UploadInfoModal;
