import React from 'react'
import PropTypes from 'prop-types'

import { Row } from '../Template';
import RadioSwitcher from './RadioSwitcher';

const RadioSwitcherRow = ({ nameBlock, tip, name, isRequired, defaultChecked }) => {
  return (
    <Row
      isRequired={isRequired}
      alignItems='center'
      nameBlock={nameBlock}
      tip={tip}
      component={<RadioSwitcher name={name} defaultChecked={defaultChecked} />}
    />
  )
}

RadioSwitcherRow.propTypes = {
  nameBlock: PropTypes.string,
  isRequired: PropTypes.bool,
  name: PropTypes.string,
  defaultChecked: PropTypes.bool,
  tip: PropTypes.oneOfType(
    [
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
      PropTypes.func
    ]),
}

export default RadioSwitcherRow
