import React, { useCallback, useRef } from 'react'
import { ReactComponent as VideoIcon } from '../../assets/video-icon.svg'
import { ReactComponent as VideoOffIcon } from '../../assets/video-off-icon.svg'
import './index.scss'

import useLocalVideoToggle from '../../hooks/useLocalVideoToggle'
import useDevices from '../../hooks/useDevices'


export default function ToggleVideoButton(props) {
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle()
  const lastClickTimeRef = useRef(0)
  const { hasVideoInputDevices } = useDevices()

  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now()
      toggleVideoEnabled()
    }
  }, [toggleVideoEnabled])

  return (
    <button
      className='video-button video-button--video'
      onClick={toggleVideo}
      disabled={!hasVideoInputDevices || props.disabled}
    >
      {isVideoEnabled ? <VideoIcon /> : <VideoOffIcon />}
      {!hasVideoInputDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
    </button>
  )
}
