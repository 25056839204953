import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';


const CustomSelect = React.memo(function CustomSelect ({
  className = '', label, placeholder = '', options = [], value, changeHandler
}) {
  const t = useGetTranslate();

  const selectedOption = options.find(option => option.value === value);
  const translatedOptions = options.map(option => ({ ...option, label: t(option.label) }));

  return (
    <StyledWrapper className={`react-select-container filter ${className}`}>
      {label && (
        <div className='select-label'>
          {t(label)}
        </div>
      )}
      <StyledSelect
        classNamePrefix="react-select"
        components={{ IndicatorSeparator: () => null }}
        isSearchable={false}
        placeholder={t(placeholder)}
        options={translatedOptions}
        onChange={changeHandler}
        value={selectedOption}
      />
    </StyledWrapper>
  );
});

CustomSelect.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
};

const StyledWrapper = styled.div`
  width: 100%;
  margin-right: 8px;

  .react-select__control {
    height: 48px;
  }
  
  .select-label {
    font-size: 13px;
    font-weight: 700;
    line-height: 18px;
    margin-bottom: 4px;
    color: ${({ theme: { colors } }) => colors.lightGrey};
    text-transform: uppercase;
  }
`;

const StyledSelect = styled(Select)`
  background: #fff;
`;

export default CustomSelect;
