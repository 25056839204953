import React from 'react'

import TableRowsList from './TableRowsList'

const loadingRows = new Array(10).fill({ isLoading: true })

export default function TableLoading ({ tableName, tableConfig, actionsConfig, bulkActionsConfig }) {
  return (
    <TableRowsList
      isLoading
      tableName={tableName}
      tableConfig={tableConfig}
      actionsConfig={actionsConfig}
      bulkActionsConfig={bulkActionsConfig}
      rows={loadingRows}
    />
  )
}
