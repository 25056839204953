import React from 'react'

const KeywordSearchAggregationList = ({ title, list, handleOnClick }) => {
  return (
    <div className="keyword-search__aggregation">
      <h6>{title}</h6>
      <div className="keyword-search__aggregation--list">
        {list?.map((item, i) => (
          <div className="keyword-search__aggregation--item" key={i} onClick={() => handleOnClick(item.value)}>
            <p>{item.label}</p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default KeywordSearchAggregationList
