import React from 'react'
import PropTypes from 'prop-types'
import { types } from 'shared';

import LibraryWithCrop from '_legacy/components/Library/LibraryWithCrop';
import PictureWithText from '_legacy/components/Library/configs/common/PictureWithText';
import HeadshotBackdrop from '_legacy/components/Library/configs/Headshot/HeadshotBackdrop';

const { PHOTO } = types.file.Types


const CommonHeadshot = ({ name }) => {
  const dropZoneProps = {
    accept: PHOTO,
  }
  const cropModalProps = {
    cropShape: 'round',
    header: 'Crop Headshot Image'
  }
  const components = {
    customPicture: PictureWithText,
    customBackdrop: HeadshotBackdrop
  }
  const libraryModalProps = {
    enableTypes: PHOTO
  }

  return (
    <LibraryWithCrop
      name={name}
      components={components}
      dropZoneProps={dropZoneProps}
      cropModalProps={cropModalProps}
      libraryModalProps={libraryModalProps}
    />
  )
}

CommonHeadshot.propTypes = {
  name: PropTypes.string,
}

export default CommonHeadshot
