// Modules
import React from 'react'

// Store (hooks)
import { useIsLoadingChat } from 'store/hooks/globalState/useChats'

// Legacy components
import LoaderIndicator from '_legacy/components/Common/Loader'

// Components
import EmptyAficionadosList from './EmptyAficionadosList'
import AficionadoListItem from './AficionadoListItem'


const AficionadosList = ({ list = [] }) => {
  const isLoading = useIsLoadingChat()

  return (
    <div className="aficionados-list__wrapper">
      {isLoading && (
        <div className="chats__loader">
          <LoaderIndicator size={24} fill="#636583" />
        </div>
      )}

      {!isLoading && !list || list.length === 0 ? (
        <EmptyAficionadosList />
      ) : (
        <>
          {!isLoading && list.map((aficionado, index) => (
            <AficionadoListItem
              key={index}
              aficionado={aficionado}
              isLoading={isLoading}
            />
          ))}
        </>
      )}
    </div>
  )
}

export default AficionadosList
