import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'
import { useFormContext } from 'react-hook-form'

import Countdown, { zeroPad } from 'react-countdown'
import { Trans } from 'store/hooks/globalState/useTranslates';

const DEFAULT_DELAY = 60_000
function PhoneResendCodeCountdown({ fields }) {
  const { setValue, watch } = useFormContext()
  const [showCountdown, setShowCountdown] = React.useState(false)
  const remainingAttempts = watch(fields.remainingAttempts)

  const handleCountDownStop = React.useCallback(() => {
    setValue(fields.canRetry, true, { shouldValidate: true })
    setShowCountdown(false)
  }, [])

  const startTime = React.useMemo(() => Date.now() + DEFAULT_DELAY, [remainingAttempts])
  React.useEffect(() => setShowCountdown(!!remainingAttempts), [remainingAttempts])

  if(!showCountdown) return null

  return (
    <Countdown
      date={startTime}
      onComplete={handleCountDownStop}
      renderer={({ minutes, seconds }) => (
        <TimeWrapper>
          <Trans
            tKey="register-user.form.phone.verification.retry-in-time"
            phs={[{ ph: '{{minutesCountdown}}', value: zeroPad(minutes) }, { ph: '{{secondsCountdown}}', value: zeroPad(seconds) }]}
          />
        </TimeWrapper>
      )}
    />
  )
}

const TimeWrapper = styled.p`
  padding: 6px;
`

PhoneResendCodeCountdown.propTypes = {
  fields: PropTypes.object,
};

export default PhoneResendCodeCountdown
