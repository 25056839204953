import React from 'react'
import findKey from 'lodash/findKey'

import { useUser } from 'store/hooks/globalState/useUser'
import { useFileLibrary } from 'store/hooks/globalState/useFileLibrary'
import { useFileEntities } from 'store/hooks/globalState/useFileEntites'

import { setEntitiesData, setLibraryData } from 'utils/storage'


const withSubscribeLibrary = Component => props => {
  const user = useUser()
  const fileLibrary = useFileLibrary()
  const fileEntities = useFileEntities()

  React.useEffect(() => {
    if (!user && !findKey(fileEntities, 'isLoading')) {
      setLibraryData(fileLibrary)
      setEntitiesData(fileEntities)
    }
  }, [user, fileLibrary, fileEntities])

  return <Component {...props} />
}

export default withSubscribeLibrary
