import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import * as r from '_legacy/constants/routes'

import Template from '_legacy/components/template'
import NewTemplate from '_legacy/components/template/new-template'
import LandingTemplate from '_legacy/components/LandingTemplate'

import { useIsUserHasTalent, useIsUserLoggedIn } from 'store/hooks/globalState/useUser'
import { HOME } from '_legacy/constants/routes'


export const IfAuthRoute = props => {
  const isUserLoggedIn = useIsUserLoggedIn()
  if (!isUserLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: HOME,
          hash: r.HASH_LOGIN,
          state: props.location.pathname,
        }}
      />
    )
  }
  return <Route {...props} />
}

export const IfNotAuthRoute = props => {
  const isUserLoggedIn = useIsUserLoggedIn()
  if (isUserLoggedIn) return <Redirect to={HOME} />
  return <Route {...props} />
}

export const IfUserHasTalentRoute = props => {
  const isUserHasTalent = useIsUserHasTalent()
  if (!isUserHasTalent) return <Redirect to={HOME} />
  return <Route {...props} />
}


export const MobileSwitchRoute = ({
  component,
  desktopComponent = null,
  mobileMediaQUery = 'only screen and (max-width: 1024px)',
  ...props
}) => {
  const [isMobile, setIsMobile] = React.useState(true)

  const onResize = () => {
    const isMediaMatched = window.matchMedia(mobileMediaQUery).matches
    setIsMobile(isMediaMatched)
  }

  React.useEffect(() => {
    onResize()

    window.addEventListener('resize', onResize)

    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [])

  return (
    isMobile
      ? <Route {...props} component={component} />
      : <Route {...props} component={desktopComponent} />
  )
}

export const landingHOC = (Component, templateParams, extraProps) => (
  <LandingTemplate {...templateParams}>
    <Component {...extraProps} />
  </LandingTemplate>
)

export const templateHOC = (Component, templateParams, extraProps) => (
  <Template {...templateParams}>
    <Component {...extraProps} />
  </Template>
)

export const newTemplateHOC = (Component, templateParams, extraProps) => (
  <NewTemplate {...templateParams}>
    <Component {...extraProps} />
  </NewTemplate>
)
