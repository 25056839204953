import React from 'react'
import { CardElement, useElements } from '@stripe/react-stripe-js'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'
import { compose, find, prop } from 'lodash/fp'
import { OrderPaymentStatus } from 'shared'
import { useHistory } from 'react-router-dom'

import * as r from '_legacy/constants/routes'
import Button from '_legacy/components/Button'
import StripeFieldset from '_legacy/components/StripeFieldset'
import PaymentsSelect from '_legacy/components/PaymentsSelect'
import { notify } from 'utils/notify'

import { useEntouragePurchase } from 'requests/purchase'
import { useFetchPaymentCard } from 'requests/payments'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { usePaymentCards } from 'store/hooks/globalState/usePaymentCards'
import { useGetPrice } from 'store/hooks/globalState/useGetPrice'
import { useUser } from 'store/hooks/globalState/useUser'

import './index.scss'

const EntouragePaymentForm = React.memo(({ entourageOrder }) => {
  const t = useGetTranslate()
  const elements = useElements()
  const history = useHistory()

  const user = useUser()
  const getPrice = useGetPrice()
  const paymentCards = usePaymentCards()
  const fetchPaymentCard = useFetchPaymentCard()
  const [error, setError] = React.useState(null)
  const [isLoading, setLoading] = React.useState(false)
  const [nameOnCard, setNameOnCard] = React.useState('')
  const [disabledPay, setDisabled] = React.useState(false)
  const [saveCard, setSaveCard] = React.useState(false)

  const purchase = useEntouragePurchase({
    setLoading,
    setDisabled,
    saveCard,
    onSuccess: () => history.push(`/${r.ENTOURAGE}/${r.VERIFY}`),
  })

  const getPaymentMethodId = compose(
    prop('paymentMethodId'),
    find(({ card }) => card.isDefault)
  )

  const price = get(entourageOrder, 'price')
  const orderId = get(entourageOrder, 'id')
  const orderStatus = get(entourageOrder, 'paymentStatus')
  const userEmail = get(user, 'user.email')

  const handleSubmit = event => {
    event.preventDefault()

    purchase({
      orderId,
      card: elements.getElement(CardElement),
      billing_details: { email: userEmail, name: nameOnCard },
      defaultPaymentId: getPaymentMethodId(paymentCards),
    })
  }

  React.useEffect(() => {
    if (OrderPaymentStatus.COMPLETED === orderStatus) {
      notify(t('payment-page.norify.order-completed'), 'success')
      setDisabled(true)
    }
  }, [])

  React.useEffect(() => fetchPaymentCard(), [])

  return (
    <div className="payment-card__form-wrapper">
      <form onSubmit={handleSubmit} className="payment-card__form">
        <label className="payment-card__field">
          <span className="payment-card__label">{t('payment-page.form.field.email.label')}</span>
          <input type="text" className="payment-card__input" disabled value={userEmail} />
        </label>

        {isEmpty(paymentCards) ? (
          <StripeFieldset
            nameOnCard={nameOnCard}
            setNameOnCard={setNameOnCard}
            error={error}
            setError={setError}
            CardElement={CardElement}
            saveCard={saveCard}
            setSaveCard={setSaveCard}
          />
        ) : (
          <div className="payment-card__field">
            <span className="payment-card__label">{t('payment-page.form.field.card.label')}</span>
            <PaymentsSelect listCard={paymentCards} />
          </div>
        )}

        <Button
          className="mt-32"
          text={`${t('payment-page.form.button.pay')} ${getPrice(price)}`}
          typeButton="submit"
          disabled={disabledPay}
          loader={isLoading}
          stretch
          paddingVertical={18}
          paddingHorizontal={20}
        />
      </form>
    </div>
  )
})

EntouragePaymentForm.propTypes = {
  data: PropTypes.object,
  setDiscountPrice: PropTypes.func,
}

export default EntouragePaymentForm
