import { socialMediaLinkInitialState } from '_legacy/components/RegisterForms/ProfileEdit/components/SocialMediaLinks/config';

export const defaultValues = {
  type: null,
  profileImage: '',
  firstName: '',
  lastName: '',
  nickname: '',
  email: '',
  backupEmail: '',
  password: '',
  address: null,
  city: null,
  state: null,
  zipCode: '',
  country: '',
  dateOfBirth: '',
  phone: '',
  socialMedia: socialMediaLinkInitialState,
  marketingCommunicationsOptions: 'true',

  // temp values (remove in submit)
  sign: '',
  verifyPhoneKey: '',
  rePassword: '',
  accept: false,
}
