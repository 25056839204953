import React from 'react'
import { EXPERIENCES } from '_legacy/constants/routes'

import NewSlider from './NewSlider'
import { CardText } from './CardText'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function TopExperiences() {
  const t = useGetTranslate()

  return (
    <NewSlider
      title={t('home.slider.top-experiences.title')}
      dataUrl="offers/experiences/top"
      dataField="topExperiences"
      navigatePath={EXPERIENCES}
      CardText={CardText}
    />
  )
})
