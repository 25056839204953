import React from 'react'
import { useStripe } from '@stripe/react-stripe-js';

import { deletePaymentCard, getPaymentCard, setDefaultPaymentCard, setPaymentCard } from 'api/appApi/purchase'
import { usePaymentCards, useSetPaymentCards } from 'store/hooks/globalState/usePaymentCards'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { notify } from 'utils/notify';

export const createPaymentMethod = async ({ stripe, card, billing_details }) => {
  const { paymentMethod, error } = await stripe.createPaymentMethod({ type: 'card', card, billing_details })
  if (error) throw error.code
  return paymentMethod;
}

export function useFetchPaymentCard() {
  const setPaymentCards = useSetPaymentCards((prev, next) => next)

  return React.useCallback(async (paymentMethodId) => {
    try {
      const { cards } = await getPaymentCard(paymentMethodId)
      setPaymentCards(cards)
    }
    catch (error) {
      console.warn(error)
    }
  }, [setPaymentCards])
}

export function useSetDefaultPaymentCard(setLoading = () => { }) {
  const setPaymentCards = useSetPaymentCards((prev, next) => next)

  return React.useCallback(async (paymentMethodId) => {
    setLoading(true)
    try {
      const { cards } = await setDefaultPaymentCard(paymentMethodId)
      setPaymentCards(cards)
    }
    catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setPaymentCards])
}


export function useSetPaymentCard({ setLoading }) {
  const t = useGetTranslate()
  const stripe = useStripe()
  const fetchPaymentCard = useFetchPaymentCard()
  const paymentCards = usePaymentCards()
  const setPaymentCards = useSetPaymentCards((prev, next) => next)
  const setDefaultPaymentCard = useSetDefaultPaymentCard()

  const closeModal = useStateModal(() => null)

  return React.useCallback(async ({ card, billing_details }) => {
    setLoading(true)

    try {
      const paymentMethod = await createPaymentMethod({ stripe, card, billing_details })
      const { isNeedRequiresAction, secret, cards } = await setPaymentCard(paymentMethod.id)
      if (cards) setPaymentCards(cards)
      if (isNeedRequiresAction) {
        const resp = await stripe.confirmCardSetup(secret, { payment_method: paymentMethod.id })
        fetchPaymentCard()
        if (paymentCards.length === 0) setDefaultPaymentCard(paymentMethod.id)
        if (resp.error) throw resp.error.code;
        if (resp.setupIntent.status !== 'succeeded') throw resp.code;
      }

      notify(t('payment-modal.notify.success'), 'success')
      closeModal()
    } catch (e) {
      const errorMessage = e ? `payment-page.error.card.${e}` : 'payment-page.error.card.some-error'

      notify(t(errorMessage), 'error')
    }
    finally {
      setLoading(false)
    }
  }, [t, setLoading, setPaymentCards])
}

export function useDeletePaymentCard(setLoading) {
  const setPaymentCards = useSetPaymentCards((prev, next) => next)
  return React.useCallback(async (paymentMethodId) => {
    setLoading(true)
    try {
      const { cards } = await deletePaymentCard(paymentMethodId)
      setPaymentCards(cards)
    }
    catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setPaymentCards])
}

