import React from 'react'

import * as r from '_legacy/constants/routes'

import ExperienceOrderDetailsParticipants from '../components/ExperienceOrderDetailsParticipants'
import ExperienceOrderDetailsRequests from '../components/ExperienceOrderDetailsRequests'

export const defaultRoute = `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/:id`

export const getDetailsConfig = id => {
  return [
    {
      translation: 'order-details.menu.requests',
      pathname: `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/${id}/${r.REQUESTS}`,
      pathComponent: `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/:orderId/${r.REQUESTS}`,
      itemRef: React.useRef(),
      component: ExperienceOrderDetailsRequests,
    },
    {
      translation: 'order-details.menu.participants',
      pathname: `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/${id}/${r.PARTICIPANTS}`,
      pathComponent: `/${r.ORDERS}/${r.EXPERIENCES}/${r.DETAILS}/:orderId/${r.PARTICIPANTS}`,
      itemRef: React.useRef(),
      component: ExperienceOrderDetailsParticipants,
    },
  ];
}
