import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import padCharsStart from 'lodash/fp/padCharsStart'

import { calculateCountdownFromNow } from 'utils/date'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button'

const withZero = padCharsStart('0', 2)

const OfferingDetailsCountdown = ({ countdownDate, handleClick, title }) => {
  const t = useGetTranslate()
  const [
    {
      expired,
      values: { days, hours, minutes, seconds }
    },
    setCountDown
  ] = useState(() => calculateCountdownFromNow(countdownDate));

  useEffect(() => {
    if (expired) return undefined;
    const intervalId = setInterval(
      () => setCountDown(calculateCountdownFromNow(countdownDate)),
      1000
    );
    return () => {
      clearInterval(intervalId);
    };
  }, [expired, countdownDate]);

  return (
    <>
      <div className="od__countdown">
        <div className="od__countdown--item">
          <p>{withZero(days)}</p>
          <span>{t('order-details.countdown.days')}</span>
        </div>
        <div className="od__countdown--item">
          <p>:</p>
        </div>
        <div className="od__countdown--item">
          <p>{withZero(hours)}</p>
          <span>{t('order-details.countdown.hours')}</span>
        </div>

        <div className="od__countdown--item">
          <p>:</p>
        </div>
        <div className="od__countdown--item">
          <p>{withZero(minutes)}</p>
          <span>{t('order-details.countdown.minutes')}</span>
        </div>

        <div className="od__countdown--item">
          <p>:</p>
        </div>
        <div className="od__countdown--item">
          <p>{withZero(seconds)}</p>
          <span>seconds</span>
        </div>
      </div>

      {countdownDate &&
        <Button
          classBtn="button mb-16"
          handleOnClick={handleClick}
          text={title}
          disabled={countdownDate && !expired}
          typeButton="submit"
          stretch
          fontSize="0.875"
          paddingVertical="16"
          paddingHorizontal="10"
        />
      }
    </>
  )
}

OfferingDetailsCountdown.propTypes = {
  countdownDate: PropTypes.string,
  handleClick: PropTypes.func,
  title: PropTypes.string,
}

export default OfferingDetailsCountdown;
