import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import ModalPortal from 'portals/ModalPortal';

const Modal = React.memo(({ isOpen, onClose, children, isMobileFullScreen }) => {
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen={isMobileFullScreen}>
      <ModalInner>
        {children}
      </ModalInner>
    </ModalPortal>
  );
});

const ModalInner = styled.div`
  background: #fff;
  padding: 16px 40px 40px;
  
  display: flex;
  flex-direction: column;
  flex: 1;

  box-shadow: ${props => props.theme.boxShadows.modal};
  border-radius: ${props => props.theme.borderRadius.modal};

  min-height: 484px;
  max-height: 940px;
  width: 510px;

  @media screen and (max-width: 1366px) {
    padding: 16px 24px 24px;
    width: 478px;
    max-height: 575px;
  }

  @media screen and (max-width: 768px) and (max-height: 1024px) {
    max-height: 703px;
  }

  @media screen and (max-width: 414px) {
    max-height: none;
    width: 100%;
    border-radius: 0;
    padding: 32px 16px 16px;
  }
`;

Modal.propTypes = {
  isOpen: PropTypes.bool,
  isMobileFullScreen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

export default Modal;
