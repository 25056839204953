import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'
import { useHistory, Link } from 'react-router-dom'

import { useSwitchToManager, useSwitchToTalent } from 'requests/talent-switcher'

import { useOnClickOutside } from 'store/hooks/useOnClickOutside'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useConnectedTalents } from 'store/hooks/globalState/useConnectedTalents'
import {
  useTalentId,
  useUser,
  useUserFullName,
} from 'store/hooks/globalState/useUser'
import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState'

import Icon from 'assets/icons/Icon'
import ProfilePic from 'assets/profile-pick-light.svg'

import { TALENT, REGISTER } from '_legacy/constants/routes'

import { notify } from 'utils/notify'

const MobileTalentSwitcher = ({
  mobileSwitcherActive,
  setMobileSwitcherActive,
}) => {
  const t = useGetTranslate()
  const talentId = useTalentId()
  const fullName = useUserFullName()
  const connectedTalents = useConnectedTalents()
  const userPhoto = useUser(user => get(user, 'user.profileImage.fullUrl', ProfilePic))
  const switchToTalent = useSwitchToTalent()
  const switchToManager = useSwitchToManager()
  const history = useHistory()
  const blockFullpage = useSetFullpageBlock((prev, next) => next, [])

  const activeClass = mobileSwitcherActive ? 'active' : '';

  const convertedProfiles = React.useMemo(() => {
    return connectedTalents?.map(profile => ({
      ...profile,
      isActive: talentId === profile.talent.id,
    }))
  }, [connectedTalents, talentId])

  const handleAgentSwitch = () => {
    history.push('/')
    switchToManager()
    setMobileSwitcherActive(false)
    blockFullpage(false)
  }

  const handleTalentSwitch = (id, name) => {
    history.push('/')
    switchToTalent(id)
    setMobileSwitcherActive(false)
    blockFullpage(false)
    notify(`Switched to ${name}`, 'success')
  }

  const node = React.useRef()
  useOnClickOutside(node, () => {
    setMobileSwitcherActive(false)
    blockFullpage(false)
  })

  const handleSwitcherClose = () => {
    setMobileSwitcherActive(!mobileSwitcherActive)
    blockFullpage(false)
  }

  const onAddNewTalentClick = () => {
    setMobileSwitcherActive(false)
  }

  return (
    <StyledMobileSwitcher ref={node} className={`${activeClass}`}>
      <StyledHeader>
        <h4>{t('switch-profile.mobile.header')}</h4>
        <Icon.Close onClick={handleSwitcherClose} />
      </StyledHeader>
      <StyledSwitcherHeading>
        <StyledSwitcherTitle>
          <img alt={fullName} src={userPhoto} />
          <StyledSwitcherInfo onClick={handleAgentSwitch}>
            <p>{fullName}</p>
            <span>{t('switch-profile.my-profile')}</span>
          </StyledSwitcherInfo>
        </StyledSwitcherTitle>
        <StyledSwitcherHeader>
          {t('switch-profile.header')}
        </StyledSwitcherHeader>
      </StyledSwitcherHeading>

      <StyledSwitcherList>
        {convertedProfiles?.map(({ id, talent, isActive }) => (
          <StyledSwitcherItem
            key={id}
            isActive={isActive}
            onClick={() => handleTalentSwitch(id, talent?.displayName)}
          >
            <img
              alt={get(talent, 'nickname', 'avatar')}
              src={get(talent, 'profileImage.fullUrl', ProfilePic)}
            />
            <p>{get(talent, 'displayName')}</p>
          </StyledSwitcherItem>
        ))}
      </StyledSwitcherList>
      <StyledLink onClick={onAddNewTalentClick} to={`/${REGISTER}/${TALENT}`}>
        + {t('switch-profile.action')}
      </StyledLink>
    </StyledMobileSwitcher>
  )
}

const StyledMobileSwitcher = styled.div`
  background: #ffffff;
  box-shadow: 0 20px 60px rgb(0 0 0 / 30%);
  border-radius: 4px;
  z-index: 99999;
  min-width: 320px;
  height: 100vh;
  width: 100%;
  position: absolute;
  display: block;
  top: 0;
  left: -100%;
  -webkit-transition: all 0.15s ease;
  transition: all 0.3s ease-out;
  overflow: scroll;
  &.active {
    left: 0;
  }
`

const StyledHeader = styled.div`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 18px 16px;
  justify-content: space-between;
  h4 {
    font-family: Playfair Display;
    font-style: normal;
    font-weight: 900;
    font-size: 23px;
    line-height: 29px;
    font-feature-settings: 'case' on, 'liga' off;
    color: #272835;
  }
`

const StyledSwitcherHeading = styled.div`
  padding: 12px 16px 8px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
  border-radius: 4px 4px 0 0;
`

const StyledSwitcherHeader = styled.div`
  margin-top: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #9495a7;
`

const StyledSwitcherTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  img {
    background: none;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    overflow: hidden;
  }
`

const StyledSwitcherInfo = styled.div`
  margin-left: 12px;
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #626473;
  }
  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: #9495a7;
  }
`

const StyledSwitcherList = styled.div`
  height: calc(100% - 315px);
  scrollbar-width: none;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
  background: #ffffff;
  &::-webkit-scrollbar-thumb {
    background: #babbcd;
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
`

const StyledSwitcherItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
  padding: 8px 16px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background: none;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #626473;
    margin-left: 12px;
  }
`

const StyledLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 48px;
  color: #626473;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px -2px 5px rgb(0 0 0 / 15%);
  border-radius: 0 0 4px 4px;
  cursor: pointer;
`

MobileTalentSwitcher.propTypes = {
  mobileSwitcherActive: PropTypes.bool,
  setMobileSwitcherActive: PropTypes.func,
}

export default MobileTalentSwitcher
