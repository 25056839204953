import React from 'react';
import styled from 'styled-components';

import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher';
import { Row } from '_legacy/common';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Signature = () => {
  const t = useGetTranslate();

  return (
    <SignatureWrapper
      nameBlock={t('create-experience.form.signature.label')}
      component={<RadioSwitcher name="signaturesOk" />}
    />
  );
};

const SignatureWrapper = styled(Row)`
  align-items: center;
`;

export default Signature;
