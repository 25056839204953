import React from 'react'
import { useFormContext } from 'react-hook-form'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import { Row } from '_legacy/common/Template'
import { Checkbox } from '_legacy/common/Checkbox'
import { ErrorMessage } from '_legacy/common/ErrorMessage'
import * as r from '_legacy/constants/routes'

const DataProcessingConsent = React.memo(function DataProcessingConsent({ withTalent }) {
  const t = useGetTranslate()

  const { errors } = useFormContext();

  const config = [
    {
      name: 'siteTerms',
      show: true,
      textNode: <div>
        <span>{t('register-user.form.data-processing-consent.text1')}</span>
        <a href={`/${r.SITE_TERMS_OF_SERVICE}`} target='_blank' rel='noopener norefferrer'>
          { t('footer.policies.terms_and_conditions_policy')}&nbsp;
        </a>
        <span>{t('register-user.form.data-processing-consent.text2')}</span>
        <a href={`/${r.PRIVACY_POLICY}`} target='_blank' rel='noopener norefferrer'>
          { t('footer.policies.privacy_terms')}
        </a>
      </div>
    },
    {
      name: 'talentTerms',
      show: withTalent,
      textNode: <div>
        <span>{t('register-user.form.data-processing-consent.text1')}</span>
        <a href={`/${r.TALENT_TERMS_OF_SERVICE}`} target='_blank' rel='noopener norefferrer'>
          { t('footer.policies.talent_terms')}
        </a>
      </div>
    }
  ]

  return (
    <Row
      className="data-processing-consent"
      component={
        config.filter(({ show }) => show).map(({ name, textNode }) => (
          <div key={name} className="check">
            <div className="check-inner">
              <Checkbox
                isRequired
                name={name}
              />
              {textNode}
            </div>
            {errors?.[name] && <ErrorMessage className="mb-12" message={errors?.[name]?.message} />}
          </div>
        ))
      }
    />
  )
});

export default DataProcessingConsent
