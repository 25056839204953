import React from 'react';
import { useFormContext } from 'react-hook-form';

// components
import EEmail from '../../../shared/components/Email';

const Email = ({ name, disabled, shouldValidate = true }) => {
  const { watch } = useFormContext();
  const isVerificationSuccess = watch('isVerificationSuccess');

  return (
    <EEmail
      shouldValidate={shouldValidate}
      isDisabled={isVerificationSuccess || disabled}
      name={name}
    />
  );
};

export default Email;
