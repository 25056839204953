import { types } from 'shared';

import { maximumUsesOptions } from '_legacy/containers/PromoCodes/utils/maximumUsesOptions';

const { PromoCodeDiscountType, ApplicableOfferings } = types;

export const defaultValues = (t) => ({
  name: '',
  code: '',
  discountType: PromoCodeDiscountType.PERCENTAGE,
  discountAmount: null,
  applyToAllOffers: ApplicableOfferings.ALL,
  applicableOfferingsIds: [],
  expiresAt: null,
  limitOfUsers: maximumUsesOptions(t)[0],
})
