import get from 'lodash/get'

import {
  submitCharitiesAdapter,
  submitBiographiesAdapter,
  submitLanguagesAdapter,
  submitLicensesAdapter,
  submitCategoriesAdapter,
  submitCategoryAdapter,
} from 'utils/forms/adapters'

export default value =>  {
  return {
    firstName: get(value, 'firstName'),
    lastName: get(value, 'lastName'),
    nickname: get(value, 'nickname'),
    displayNameType: get(value, 'displayNameType'),
    primaryCategory: submitCategoryAdapter(get(value, 'primaryCategory')),
    categories: submitCategoriesAdapter(get(value, 'categories')),
    title: get(value, 'title'),
    fullBodyImage: get(value, 'fullBodyImage'),
    profileImage: get(value, 'profileImage'),
    halfBodyImage: get(value, 'halfBodyImage'),
    location: get(value, 'location.value'),
    languages: submitLanguagesAdapter(get(value, 'languages')),
    nationality: get(value, 'nationality.value'),
    charities: submitCharitiesAdapter(get(value, 'charities')),
    ownWords: get(value, 'ownWords'),
    biographies: submitBiographiesAdapter(get(value, 'biographies')),
    promoCode: get(value, 'promoCode.id'),
    licenses: submitLicensesAdapter(get(value, 'licenses')),
  }
}
