import React from 'react'
import { useFormContext } from 'react-hook-form'
import get from 'lodash/get'
import { enums } from 'shared'
import cond from 'lodash/cond'
import constant from 'lodash/constant'
import isEqual from 'lodash/fp/isEqual'

import { useFetch } from 'store/hooks/useFetch'
import { getTimeZoneByGooglePlaceId } from 'api/appApi/integration/locations/timezone/timezone'
import { getLocations } from 'api/appApi/integration/locations/autocomplete/locations.js'

export const KEY = 'timeZone'
const { FIXED, FLEXIBLE } = enums.TypesOfLocation


const TimeZoneHandler = () => {
  const [activeLocation, setActiveLocation] = React.useState()
  const [{ data }, getTimeZone] = useFetch(getTimeZoneByGooglePlaceId)
  const { watch, register, setValue } = useFormContext()
  const formState = watch()

  const typesOfLocation = get(formState, 'typesOfLocation')
  const location = get(formState, ['location', 'label'])
  const firstFlexibleLocation = get(formState, ['locations', 0, 'label'])

  const currentLocation = cond([
    [isEqual(FIXED), constant(location)],
    [isEqual(FLEXIBLE), constant(firstFlexibleLocation)],
  ])(typesOfLocation)

  // TODO: Get googlePlaceId as location.value from BE (at edit exp form) to get rid of +1 request
  const getGooglePlaceId = async () => {
    const locationsResponse = await getLocations({
      input: currentLocation
    })

    const googlePlaceId = get(locationsResponse, 'locations[0].placeId', '')
    setActiveLocation(googlePlaceId)
  }

  React.useEffect(() => {
    getGooglePlaceId()
  }, [currentLocation])

  React.useEffect(() => {
    if (activeLocation) getTimeZone({ googlePlaceId: activeLocation })
  }, [activeLocation])

  React.useEffect(() => {
    setValue(KEY, get(data, 'timeZoneId'))
  }, [data])

  React.useEffect(() => {
    register(KEY)
  }, [])

  return null
}

export default TimeZoneHandler
