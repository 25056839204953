import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty'
import prop from 'lodash/fp/prop'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useIsUserHasTalent, useUserType } from 'store/hooks/globalState/useUser';
import { useEditProfile } from 'store/hooks/globalState/useEditProfile';
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';

import { usePostEditProfile } from 'requests/edit-profile'

import { defaultValues } from './utils/defaultValues';
import { getViewerTypes } from './utils/getViewerTypes';

// components
import RequiredLabel from '../shared/components/RequiredLabel';
import SubmitButton from '../shared/components/SubmitButton';
import TalentForm from './TalentForm';
import AgentForm from './AgentForm';
import PageLoader from '_legacy/common/Template/PageLoader';
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import { ACCOUNT, EDIT_PROFILE } from '_legacy/constants/routes'
import { getAdapter as getEditProfileAdapter } from '_legacy/components/RegisterForms/ProfileEdit/utils/getAdapter';
import { useTalentCategories } from 'store/hooks/globalState/useCategories';

const EditProfileForm = React.memo(({ showAgent, showTalent }) => {
  useSetPathToNavigate({ talentRoute: `/${ACCOUNT}/${EDIT_PROFILE}`, agentRoute: `/${ACCOUNT}/${EDIT_PROFILE}` })
  const t = useGetTranslate()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
  })

  const editProfile = usePostEditProfile(methods)
  const userType = useUserType()
  const typeWithTalent = useIsUserHasTalent()
  const profileLoading = useEditProfile(prop('loading'))
  const profileData = useEditProfile(prop('data'))
  const categories = useTalentCategories()

  React.useEffect(() => {
    if (profileData) {
      const normalizedData = getEditProfileAdapter(profileData, t, categories)
      methods.reset(normalizedData, { isDirty: false, dirtyFields: false })
    }
  }, [profileData])


  if (profileLoading && !profileData) return <PageLoader />

  const viewerTypes = getViewerTypes(userType, typeWithTalent, profileData?.user?.registrationType)
  const onSubmit = values => editProfile(values, methods.formState.dirtyFields)

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <form onSubmit={methods.handleSubmit(onSubmit)} className='white-background'>
        <RequiredLabel />
        {showAgent && <AgentForm viewerTypes={viewerTypes} />}
        {showTalent && <TalentForm viewerTypes={viewerTypes} />}
        <SubmitButton
          text={t('shared.words.publish')}
          paddingHorizontal={45}
          paddingVertical={13}
          disabled={!methods.formState.isDirty || profileLoading}
        />
        <MissedData />
      </form>
    </FormProvider>
  );
});


export default EditProfileForm;
