import { always, cond, pipe, T, merge } from 'lodash/fp'
import { statuses } from '../utils'

const fillLiveVirtualLessonOrderConfig = merge({
  isShowClosed: false,
  isShowCompleted: false,
  isShowCanceled: false,

  isShowCalendar: false,
  isShowCounter: false,
})

export const getLiveVirtualLessonOrderConfig = pipe(
  cond([
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [statuses.isOrderClosed, always({ isShowClosed: true })],
    [statuses.isOrderCompleted, always({ isShowCompleted: true })],

    [statuses.isBookedWithTime, always({ isShowCounter: true })],
    [statuses.isBookedWithoutTime, always({ isShowCalendar: true })],

    [T, always({})],
  ]),
  fillLiveVirtualLessonOrderConfig
)
