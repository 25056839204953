import React from 'react'
import { ToastContainer } from 'react-toastify'

import { useOnClickOutside } from 'store/hooks/useOnClickOutside'

import Header from '_legacy/components/Header'
import Footer from '_legacy/components/Footer'
import Cookies from 'components/Cookies'
import MobileMenu from '_legacy/components/MobileMenu'
import MobileTalentSwitcher from '_legacy/components/MobileMenu/MobileTalentSwitcher'

import BottomNavigation from 'components/BottomNavigation'

export default function DesktopTemplate ({
  children, themeType, backgroundType
}) {
  const [mobileMenuActive, setMobileMenuActive] = React.useState(false)
  const [mobileSwitcherActive, setMobileSwitcherActive] = React.useState(false)
  const node = React.useRef()
  useOnClickOutside(node, () => setMobileMenuActive(false))
  return (
    <>
      <Header themeType={themeType} />
      <main
        className={`global-wrapper ${mobileMenuActive ? 'noscroll' : ''}`}
        background-type={backgroundType}
      >
        <ToastContainer style={{ width: 'auto' }} />
        {children}
      </main>
      <Footer />
      <Cookies themeType={themeType} />
      <div ref={node}>
        <MobileMenu
          mobileMenuActive={mobileMenuActive}
          setMobileMenuActive={setMobileMenuActive}
          mobileSwitcherActive={mobileSwitcherActive}
          setMobileSwitcherActive={setMobileSwitcherActive}
        />
        <MobileTalentSwitcher
          mobileSwitcherActive={mobileSwitcherActive}
          setMobileSwitcherActive={setMobileSwitcherActive}
        />
        <BottomNavigation
          mobileMenuActive={mobileMenuActive}
          setMobileMenuActive={setMobileMenuActive}
        />
      </div>
    </>
  )
}
