import React from 'react';

import RowInput from '_legacy/common/Input/RowInput';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const name = 'ageRequirement';

const Age = () => {
  const t = useGetTranslate();

  return (
    <RowInput
      name={name}
      label={t('create-experience.form.age.label')}
      placeholder={t('create-experience.form.age.placeholder')}
    />
  );
};

export default Age;
