import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common';
import CommonHeadshot from './CommonHeadshot';

const UploaderImages = ({ name }) => {
  const t = useGetTranslate();

  return (
    <StyledWrapper>
      <Row
        nameBlock={t('register.upload.headshot')}
        subNameBlock={t('register.upload.headshot-label')}
        component={
          <div className="headshot-container">
            <div className="headshot-item headshot-item--single">
              <div className="headshot-item__image-wrapper">
                <CommonHeadshot name={name} />
              </div>
              <div className="upload-label upload-label--light">
                {t('register.upload.hint.desc.headshot')}
              </div>
            </div>
          </div>
        }
      />
    </StyledWrapper>
  )
};

UploaderImages.propTypes = {
  name: PropTypes.string
}

const StyledWrapper = styled.div`
  .row-right {
    @media only screen and (max-width: 768px) {
      margin: 0 auto;
    }
  }
`

export default UploaderImages;
