// TODO: Someday get "ready to show" data from BE to avoid hardcoded FE calculations
// Modules
import propOr from 'lodash/fp/propOr'
import map from 'lodash/fp/map'
import compose from 'lodash/fp/compose'
import all from 'lodash/fp/all'
import propEq from 'lodash/fp/propEq'
import { OrderStatus, TalentOfferPendingActionType } from 'shared'

// Utils
import { getStepTitle } from './getStepTitle'
import { getStepDate } from './getStepDate'
import { parseDate } from 'utils/services/timezone'
import {
  removeDraftStatus,
  removeClosedStatusIfAficionado,
  addApprovedStep,
  addCompletedStep
} from './draftSteps'

// Constants
import { STATUS_STATE } from '../constants'

// TODO: remove after StatusBar will pass QA
// const trace = title => item => {
//   const { debug } = console
//   debug(`${title}: `, item)
//   return item
// }

const {
  APPROVED,
  COMPLETED,
} = OrderStatus

const {
  INACTIVE,
  ACTIVE,
  DONE,
} = STATUS_STATE

const { REVIEW_BOOKING_REQUEST } = TalentOfferPendingActionType

const getStatusTimeline = propOr([], 'order.statusTimeline')
const getPendingAction = propOr({}, 'pendingAction')

//
// Adding "virtual" COMPLETED or APPROVED and COMPLETED steps depending on pending action
// This is needed because statusTimeline from BE don't contain future statuses that need to be displayed to user
//
const addVirtualStatuses = isReview => steps =>
  isReview
    ? compose(addCompletedStep, addApprovedStep)(steps)
    : addCompletedStep(steps)

//
// All statusTimeline statuses are DONE by default
// If date is missing (like in case with "virtual statuses") they are marked as inactive
// Later one of these statuses will be transformed to ACTIVE
//
const getStepState = date => {
  if(!date) {
    return INACTIVE
  } else {
    return DONE
  }
}

const convertToStep = ({ status, date, isDue, timeZone }) => ({
  status,
  state: getStepState(date),
  title: getStepTitle(status),
  subtitle: getStepDate(date, timeZone, isDue),
})

//
// Only one of statuses can be ACTIVE
// Mostly it's COMPLETED, but in one case we can have APPROVED as active (if booking request is in review)
//
const markActiveStep = isReview => steps => {
  const isOrderFinished = all(propEq('state', DONE))(steps)

  if(isOrderFinished) return steps

  const activeStepStatus = isReview
    ? APPROVED
    : COMPLETED

  return steps?.map(step => {
    if (step.status === activeStepStatus) {
      step.state = ACTIVE
    }
    return step
  })
}

const markDueStatus = (isReview, due) => steps =>
  steps?.map(step => {
    if (step.status === APPROVED && isReview) {
      step.date = due
      step.isDue = true
    }
    return step
  })

const parseTimeZone = timeZone => steps =>
  steps.map(step =>
    ({
      ...step,
      date: parseDate(step?.date, timeZone),
      timeZone,
    })
  )

export const getSteps = (occurrence, timeZone, isAficionado = false) => {
  const pendingAction = getPendingAction(occurrence)
  const statusTimeline = getStatusTimeline(occurrence)

  if (!getStatusTimeline(occurrence).length) return []

  const isReview = pendingAction?.type === REVIEW_BOOKING_REQUEST
  const due = pendingAction?.due

  const getStepsFromTimeline = compose(
    // trace('a markActiveStep'),
    markActiveStep(isReview),
    // trace('a convertToStep'),
    map(convertToStep),
    // trace('a parseTimeZone'),
    parseTimeZone(timeZone),
    // trace('a markDueStatus'),
    markDueStatus(isReview, due),
    // trace('a addVirtualStatuses'),
    addVirtualStatuses(isReview),
    // trace('a removeClosedStatusIfAficionado'),
    removeClosedStatusIfAficionado(isAficionado),
    // trace('a removeDraftStatus'),
    removeDraftStatus,
  )

  const steps = getStepsFromTimeline(statusTimeline)

  return steps
}
