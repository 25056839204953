import React from 'react';

import { string } from 'prop-types';

import { ReactComponent as Loader } from 'assets/page-loader.svg'

import './pageLoader.scss';

const PageLoader = ({ className, ...restProps }) =>
  <div {...restProps} className={`page-loader ${className}`}>
    <Loader />
  </div>;

PageLoader.propTypes = {
  className: string,
}

export default PageLoader;
