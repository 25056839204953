import React from 'react'
import get from 'lodash/get'

import Checkbox from '../../common/Checkbox'

export default function TableRowCheckbox ({ isLoading, rowId, bulkActionsConfig, ...props }) {
  const { getFilters, setFilters } = bulkActionsConfig
  const checked = getFilters(current => get(current, 'selected', []).find(item => (item === rowId)), [rowId])
  const selectRow = setFilters((prev, next) => {
    const selected = get(prev, 'selected', [])
    if (selected.includes(next)) {
      return { ...prev, selected: selected.filter(item => item !== next) }
    }
    return { ...prev, selected: [...selected, next] }
  })
  return (
    <Checkbox
      isLoading={isLoading}
      id={rowId}
      checked={checked}
      clickHandler={selectRow}
      {...props}
    />
  )
}
