// Modules
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import set from 'lodash/set'

// Assets
import Icon from 'assets/icons/Icon'

import { routesList } from 'router/routesMap'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useIsLoadingChat } from 'store/hooks/globalState/useChats'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { useStartChat } from 'requests/chats'
import { useCheckComplaint } from 'requests/complaint'
import { useFetchLiveVirtualLessonBookingsDetails } from './state/requests'
import { useLiveVirtualLessonBookingsDetails, useSetLiveVirtualLessonBookingsDetails } from './state/store'

// Utils
import { getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getDateTimeProps } from 'utils/date'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText } from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import OfferingDetailsCountdown from 'components/OfferingDetails/components/OfferingDetailsCountdown'
import OfferingDetailsBookingContact from 'components/OfferingDetails/components/OfferingDetailsBookingContact'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import { getLiveVirtualLessonBookingConfig } from './utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'
import AddToCalendarButton from 'components/Calendars/components/AddToCalendarButton'

const LiveVirtualLessonBookingsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const history = useHistory()
  const timeZone = useUser(getUserTimeZone)

  // state
  const isLoadingChat = useIsLoadingChat()
  const order = useLiveVirtualLessonBookingsDetails()
  const clearOrder = useSetLiveVirtualLessonBookingsDetails(() => null)

  const { review, occurrence, number, statusTimeline } = order || {}

  // requests
  const fetchBookingsDetails = useFetchLiveVirtualLessonBookingsDetails()
  const checkComplaint = useCheckComplaint()
  const startChat = useStartChat(occurrence?.talent?.id)
  const onReviewSuccess = React.useCallback(() => {
    fetchBookingsDetails()
    checkComplaint(orderId)
  }, [orderId, fetchBookingsDetails, checkComplaint])

  // chats handlers
  const handleContact = () => {
    if (!isLoadingChat && occurrence?.talent?.id) {
      return startChat()
    }
  }

  const handleStartEvent = () => history.push(routesList.liveVirtualLessonRoom(occurrence.id))

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
  }, [])

  React.useEffect(() => () => {
    clearOrder()
  }, [])

  if (!order) return <PageLoader />

  const { startTime: _startTime, endTime: _endTime, userUploads, talent } = occurrence
  const { formattedDate, formattedTimeRange, startTime, endTime } = getDateTimeProps({ ...occurrence, timeZone })
  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)
  const cancelText = getCancelText({ t, ...order })
  const c = getLiveVirtualLessonBookingConfig(order)
  const refundText = getAficionadoRefundText({ t, refundRequests: order.refundRequests })

  return (
    <div className="live-virtual-lesson-booking od__margins">
      <OfferingDetailsTitle
        typeHeader={t('booking-details.live-virtual-lesson.heading')}
        title={`${t('booking-details.main.title')} #${number}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, timeZone, true)} />
          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />

                <div className="od__column mt-32">
                  {startTime && endTime && (
                    <OfferingsDetailsHeaderItem
                      icon={<Icon.Calendar />}
                      item={formattedDate}
                      subitem={formattedTimeRange}
                    />
                  )}
                </div>
              </div>
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.submitted-by.title')}</h6>
                <div className="od--main-text od--accented">
                  {userUploads.filter(Boolean).length && <OrderUploadFiles files={userUploads} />}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowContact && (
              <OfferingDetailsBookingContact
                title={t('order-details.live-virtual-lesson.heading')}
                text={t('booking-details.contact.text')}
                contactText={t('booking-details.contact.title')}
                contactCallback={handleContact}
              />
            )}

            {c.isShowCounter && (
              <div className="od--with-padding">
                <p className="od--uppercase-title">{t('order-details.live-virtual-lesson.heading')}</p>
                <OfferingDetailsCountdown
                  countdownDate={_startTime}
                  handleClick={handleStartEvent}
                  title={t('booking-details.join.title')}
                />
                <AddToCalendarButton
                  startTime={_startTime}
                  endTime={_endTime}
                  timeZone={timeZone}
                  className="fat"
                />
              </div>
            )}

            {c.isShowCanceled && (
              <CancelStatusWithContact
                title={t('order-details.live-virtual-lesson.heading')}
                refundText={refundText}
                cancelText={cancelText}
              />
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsGenericStatus
                  title={t('order-details.live-virtual-lesson.heading')}
                  type={t('booking-details.main-completed.title')}
                />
                <p className="od--main-text mb-12">{t('booking-details.main-completed.text')}</p>
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={orderId}
                  onSuccess={onReviewSuccess}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('order-details.live-virtual-lesson.heading')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}
          </div>

          <ComplaintInfo
            orderId={orderId}
            onSuccess={fetchBookingsDetails}
            userLink={routesList.talentPublicPage(talent.plaqueNickname)}
            userName={getSaveFullName(talent)}
          />
        </div>
      </div>
    </div>
  )
}

export default LiveVirtualLessonBookingsDetails
