import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import NewLanding from '_legacy/containers/NewLanding'
import OldLanding from '_legacy/containers/Landing'
// import TestLanding from '_legacy/containers/TestLanding'
import Account from '_legacy/containers/Account/Account'
import EditProfile from '_legacy/containers/Edit/EditProfile'
import AccountNotifications from '_legacy/containers/Account/Notifications'
import Calendar from '_legacy/containers/Account/Calendar'
import ChangePassword from '_legacy/containers/Account/ChangePassword'
import RemoveAccount from '_legacy/containers/Account/RemoveAccount/RemoveAccount'
import ProfileManagers from '_legacy/containers/Account/ProfileManagers'
import AccountEntourageStatus from '_legacy/containers/Account/AccountEntourageStatus'
import RegistrationTalent from '_legacy/containers/Registration/Talent'
import RegistrationTalentDraft from '_legacy/containers/Registration/TalentDraft'
import RegistrationUser from '_legacy/containers/Registration/User'
import RegistrationAficionado from '_legacy/containers/Registration/Aficionado'
import ResetPassword from '_legacy/containers/ResetPassword'
import CommercialEngagements from '_legacy/containers/CommercialEngagements'
import CommercialProposalPage from '_legacy/containers/CommercialEngagements/CommercialProposalPage'
import CommercialEngagementsRequest from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest'
import TalentInvitation from '_legacy/containers/Registration/TalentInvitation'
import ExperiencePublic from '_legacy/containers/PublicViews/ExperiencePublic'
import LiveVirtualEventPublic from '_legacy/containers/PublicViews/LiveVirtualEventsPublic'
import ReadyMadeEventPublic from '_legacy/containers/ReadyMadeEventPublic/ReadyMadeEventPublic'
import Lessons from 'components/Lessons/'
import LessonsPublic from 'components/LessonsPublic/'
import NewOfferings from '_legacy/containers/Offerings/NewOfferings'
import ManageOfferings from '_legacy/containers/Offerings/ManageOfferings'
import CreateExperience from '_legacy/containers/Offerings/Experience/CreateExperience'
import EditExperience from '_legacy/containers/Offerings/Experience/EditExperience'
import EditExperienceDraft from '_legacy/containers/Offerings/Experience/EditExperienceDraft'
import CreateReadyMadeEvents from '_legacy/containers/ReadyMadeEvents/CreateReadyMadeEvents'
import EditReadyMadeEvents from '_legacy/containers/ReadyMadeEvents/EditReadyMadeEvents'
import CreateLiveVirtualEvents from '_legacy/containers/Offerings/LiveVirtualEvent/CreateLiveVirtualEvent'
// eslint-disable-next-line no-unused-vars
import EditLiveVirtualEvent from '_legacy/containers/Offerings/LiveVirtualEvent/EditLiveVirtualEvent'
// eslint-disable-next-line no-unused-vars
import EditLiveVirtualEventDraft from '_legacy/containers/Offerings/LiveVirtualEvent/EditLiveVirtualEventDraft'
// eslint-disable-next-line no-unused-vars
import CreateCommercialVideoMessage from '_legacy/containers/Offerings/CommercialVideoMessage/CreateCommercialVideoMessage'
// eslint-disable-next-line no-unused-vars
import EditCommercialVideoMessage from '_legacy/containers/Offerings/CommercialVideoMessage/EditCommercialVideoMessage'
import RecurringListExperience from 'components/OfferingDetails/RecurringList/RecurringListExperience'
import RecurringListVirtualEvents from 'components/OfferingDetails/RecurringList/RecurringListVirtualEvents'
import ManagePromoCodes from '_legacy/containers/PromoCodes/ManagePromoCodes'
import VerifyRemoveAccount from '_legacy/containers/Account/RemoveAccount/VerifyRemoveAccount'
import Contact from '_legacy/containers/Contact'
import WhyJoin from '_legacy/containers/WhyJoin'
import NotFound from '_legacy/containers/NotFound'
import TalentPendingForApproval from '_legacy/containers/TalentPendingForApproval'
import AboutUs from '_legacy/containers/AboutUs'
import FAQs from 'components/FAQs'
import HowItWorks from '_legacy/containers/HowItWorks'
import Careers from '_legacy/components/Careers'
import TalentPublicView from '_legacy/components/TalentPublicView'
import VerifyEmail from '_legacy/components/VerifyEmail'
import CreateVideoChat from '_legacy/containers/Offerings/VideoChat/CreateVideoChat'
import EditVideoChat from '_legacy/containers/Offerings/VideoChat/EditVideoChat'
import CreateVideoMessage from '_legacy/containers/Offerings/VideoMessage/CreateVideoMessage'
import EditVideoMessage from '_legacy/containers/Offerings/VideoMessage/EditVideoMessage'
import PromoCodes from '_legacy/containers/PromoCodes/PromoCodes'
import VideoMessagePublic from '_legacy/containers/PublicViews/VideoMessagePublic'
import VideoChatPublic from '_legacy/containers/PublicViews/VideoChatPublic'
import SearchTalents from '_legacy/containers/Search/SearchTalents'
import VideoMessageRequest from '_legacy/containers/VideoMessageRequest/VideoMessageRequest'
import Purchase from '_legacy/containers/Purchase/Purchase'
import EntouragePurchase from '_legacy/containers/Purchase/EntouragePurchase'
import SearchOfferings from '_legacy/containers/Search/SearchOfferings'
import ManageOrders from 'components/ManageOrders'
import FavoritesTalents from '_legacy/containers/Favorites/FavoritesTalents'
import FavoritesOfferings from '_legacy/containers/Favorites/FavoritesOfferings'
import MyMedia from '_legacy/containers/MyMedia'
import Payments from '_legacy/containers/Account/Payments'
import Notifications from '_legacy/containers/Notifications'
import NotificationsHandler from '_legacy/containers/Notifications/NotificationsHandler'
import Reviews from '_legacy/containers/Reviews'
import OfferingDetailsRouter from 'components/OfferingDetails/OrderDetails'
import Chats from '_legacy/components/Chats'
import MyBookings from 'components/MyBookings'
import BookingsDetailsRouter from 'components/OfferingDetails/BookingsDetails'
import AficionadoProfile from 'components/AficionadoProfile'
import KeywordSearch from 'components/Search/KeywordSearch'
import HowToVideos from 'components/HowToVideos'
import TwilioVideos from 'components/TwilioVideos'
import VideoMessageDownloadPage from 'components/DownloadPage/VideoMessageDownloadPage'
import RefreshPage from '_legacy/components/PaymentSettings/components/RefreshPage'
import VideoMessageRequestDraft from '_legacy/containers/VideoMessageRequest/components/VideoMessageRequestDraft/VideoMessageRequestDraft'
import ResourceCenter from 'components/ResourceCenter'
import EntouragePage from 'components/EntouragePublicPage/EntouragePage'
import EntourageUpgradePage from 'components/EntouragePublicPage/EntourageUpgradePage'
import EntourageVerify from 'components/EntourageVerify'
import EntouragePageFull from 'components/EntouragePublicPage/EntouragePageFull'
import RTalentCalendar from 'components/Calendars/TalentCalendar'
import CalendarHandler from 'components/Calendars/CalendarHandler'
import UnsubscribeFromUpdates from 'components/UnsubscribeFromUpdates'
import TermsPages from 'components/TermsPages'
import CurrencyAndLanguage from 'components/CurrencyAndLanguage'

import {
  IfAuthRoute,
  IfNotAuthRoute,
  IfUserHasTalentRoute,
  newTemplateHOC,
  templateHOC,
  landingHOC,
} from 'router/templates'

import * as r from '_legacy/constants/routes'

const termsPagesRoutes = [
  r.SITE_TERMS_OF_SERVICE,
  r.TALENT_TERMS_OF_SERVICE,
  r.FEE_TERMS,
  r.PRIVACY_POLICY,
  r.COMMUNITY_GUIDELINES,
  r.BOOKING_FEE_SCALE
]

const Routers = () => (
  <Switch>
    <Route path={r.HOME} exact render={() => landingHOC(NewLanding, { themeType: 'dark-theme', backgroundType: 'home', isNewLanding: true })} />
    <Route path={`/${r.HOMEPAGE}`} exact render={() => landingHOC(OldLanding, { themeType: 'dark-theme', backgroundType: 'home' })} />
    {/*<Route path={'/test'} exact render={() => templateHOC(TestLanding, { themeType: 'dark-theme', backgroundType: 'home' })} />*/}

    {termsPagesRoutes.map(routePath => (
      <Route
        exact
        key={routePath}
        path={`/${routePath}`}
        render={() => templateHOC(TermsPages)}
      />
    ))}

    <Route path={`/${r.SEARCH}/${r.TALENT}`} exact render={() => templateHOC(SearchTalents)} />
    <Route path={`/${r.SEARCH}/${r.OFFERINGS}`} exact render={() => templateHOC(SearchOfferings)} />
    <Route path={`/${r.SEARCH}/${r.KEYWORDS}`} exact component={KeywordSearch} />

    <Route path={`/${r.LANGUAGE_AND_CURRENCY}`} exact render={() => newTemplateHOC(CurrencyAndLanguage, { backgroundType: 'account' })} />

    <IfAuthRoute path={`/${r.FAVORITES}/${r.TALENT}`} exact render={() => templateHOC(FavoritesTalents)} />
    <IfAuthRoute path={`/${r.FAVORITES}/${r.OFFERINGS}`} exact render={() => templateHOC(FavoritesOfferings)} />

    <IfAuthRoute path={`/${r.OFFERINGS}/:offeringName/:orderId/${r.PURCHASE}`} exact render={() => templateHOC(Purchase, { themeType: 'light-theme payment-page' })} />

    <IfAuthRoute path={`/${r.SECURE_CHAT}/:chatId`} exact render={() => templateHOC(Chats)} />
    <IfAuthRoute path={`/${r.SECURE_CHAT}`} exact render={() => templateHOC(Chats)} />

    <IfAuthRoute path={`/${r.ACCOUNT}`} exact render={() => newTemplateHOC(Account, { backgroundType: 'account' })} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.EDIT_PROFILE}`} exact render={() => templateHOC(EditProfile, { backgroundType: 'edit-profile' })} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.NOTIFICATIONS}`} exact render={() => templateHOC(AccountNotifications, { backgroundType: 'account' })} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.CALENDAR}`} exact render={() => newTemplateHOC(Calendar)} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.PAYMENTS}`} exact render={() => templateHOC(Payments)} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.CHANGE_PASSWORD}`} exact render={() => templateHOC(ChangePassword, { backgroundType: 'account' })} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.ENTOURAGE}`} exact render={() => templateHOC(AccountEntourageStatus, { backgroundType: 'account' })} />
    <IfAuthRoute path={`/${r.ACCOUNT}/${r.REMOVE}`} exact render={() => newTemplateHOC(RemoveAccount)} />
    <IfAuthRoute path={`/${r.MEDIA}/${r.LIST}`} exact render={() => templateHOC(MyMedia)} />
    <IfUserHasTalentRoute path={`/${r.PROFILE_MANAGERS}/${r.LIST}`} exact render={() => templateHOC(ProfileManagers)} />
    <IfAuthRoute path={`/${r.NOTIFICATIONS}`} exact render={() => templateHOC(Notifications)} />
    <IfAuthRoute path={`/${r.NOTIFICATIONS}/:notificationId/:actionType`} exact render={() => templateHOC(NotificationsHandler)} />
    <IfAuthRoute path={`/${r.ACCOUNT_LINKS}/${r.REFRESH}`} exact render={() => templateHOC(RefreshPage)} />

    <IfAuthRoute path={`/${r.REVIEWS}/${r.LIST}`} render={() => templateHOC(Reviews)} />

    <IfAuthRoute path={`/${r.REGISTER}/${r.TALENT}`} exact render={() => templateHOC(RegistrationTalent)} />
    <IfAuthRoute path={`/${r.REGISTER}/${r.TALENT}/${r.DRAFT}`} exact render={() => templateHOC(RegistrationTalentDraft)} />
    <IfNotAuthRoute path={`/${r.REGISTER}/${r.USER}`} exact render={() => templateHOC(RegistrationUser)} />
    <IfNotAuthRoute path={`/${r.REGISTER}/${r.AFICIONADO}`} exact render={() => templateHOC(RegistrationAficionado)} />

    <Route path={`/${r.RESET_PASSWORD}/:key`} exact render={() => templateHOC(ResetPassword)} />

    <Route path={`/${r.USER}/:plaqueNickname`} exact render={() => templateHOC(AficionadoProfile, { themeType: 'dark-theme', backgroundType: 'aficionado' })} />
    <Route path={`/${r.TALENT}/${r.PREVIEW}/:link`} exact render={() => templateHOC(TalentPublicView, { backgroundType: 'talent' }, { isPreview: true })} />
    <IfNotAuthRoute path={`/${r.TALENT}/${r.JOIN}/:link`} exact render={() => templateHOC(TalentInvitation)} />

    {/* Public views start */}
    <Route path={`/${r.EXPERIENCES}/:talentPlaqueNickname/:offerSequentialId`} exact render={() => templateHOC(ExperiencePublic, { themeType: 'dark-theme', backgroundType: 'experience' })} />
    <Route path={`/${r.LIVE_VIRTUAL_EVENTS}/:talentPlaqueNickname/:offerSequentialId`} exact render={() => templateHOC(LiveVirtualEventPublic, { themeType: 'dark-theme', backgroundType: 'experience' })} />
    <Route path={`/${r.LESSONS}/:talentPlaqueNickname`} exact render={() => templateHOC(LessonsPublic, { themeType: 'dark-theme', backgroundType: 'lessons' })} />
    <Route path={`/${r.VIDEO_MESSAGE}/:talentPlaqueNickname/:offerSequentialId`} exact render={() => templateHOC(VideoMessagePublic, { backgroundType: 'video-message' })} />
    <Route path={`/${r.VIDEO_CHAT}/:talentPlaqueNickname/:offerSequentialId`} exact render={() => templateHOC(VideoChatPublic, { backgroundType: 'video-message' })} />
    <Route path={`/${r.READY_MADE_EVENT_LESSON}/:talentPlaqueNickname/:offerSequentialId`} exact render={() => templateHOC(ReadyMadeEventPublic)} />
    {/* Public views end */}

    <Route path={`/:talentNickname/${r.COMMERCIAL_ENGAGEMENTS}/:commercialVideoId`} exact render={() => templateHOC(CommercialEngagements, { backgroundType: 'commercial-engagement', themeType: 'dark-theme', })} />
    <Route path={`/:talentNickname/${r.COMMERCIAL_ENGAGEMENTS}`} exact render={() => templateHOC(CommercialProposalPage, { backgroundType: 'commercial-engagement', themeType: 'dark-theme', })} />
    <IfAuthRoute path={`/:talentNickname/${r.COMMERCIAL_ENGAGEMENTS}/${r.REQUEST}/:commercialVideoId`} exact render={() => templateHOC(CommercialEngagementsRequest, { backgroundType: 'video-mess-commercial' })} />
    <IfAuthRoute path={`/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/:talentPlaqueNickname/:offerSequentialId/${r.REQUEST}`} exact render={() => templateHOC(VideoMessageRequest, { backgroundType: 'video-mess-commercial' })} />
    <IfAuthRoute path={`/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/:talentPlaqueNickname/:offerSequentialId/${r.REQUEST}/${r.DRAFT}`} exact render={() => templateHOC(VideoMessageRequestDraft, { backgroundType: 'video-mess-commercial' })} />

    <IfUserHasTalentRoute path={`/${r.OFFERINGS}`} exact render={() => templateHOC(NewOfferings)} />
    <IfUserHasTalentRoute path={`/${r.LISTING}/${r.LIST}`} exact render={() => templateHOC(ManageOfferings)} />
    <IfAuthRoute path={`/${r.BOOKINGS}/${r.LIST}`} exact render={() => templateHOC(MyBookings)} />

    <IfAuthRoute path={`/${r.ROOM}/:offerType/:occurrenceId`} render={TwilioVideos} />

    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.EXPERIENCES}/${r.NEW}`} exact render={() => templateHOC(CreateExperience)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.EXPERIENCES}/:id/${r.EDIT}`} exact render={() => templateHOC(EditExperience)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.EXPERIENCES}/:id/${r.DRAFT}`} exact render={() => templateHOC(EditExperienceDraft)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.NEW}`} exact render={() => templateHOC(CreateVideoMessage)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.EDIT}`} exact render={() => templateHOC(EditVideoMessage)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.NEW}`} exact render={() => templateHOC(CreateVideoChat)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.EDIT}`} exact render={() => templateHOC(EditVideoChat)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.LESSONS}`} exact render={() => templateHOC(Lessons, { themeType: 'dark-theme', backgroundType: 'lessons' })} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.READY_MADE_EVENT_LESSON}/${r.NEW}`} exact render={() => templateHOC(CreateReadyMadeEvents)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.READY_MADE_EVENT_LESSON}/:id/${r.DRAFT}`} exact render={() => templateHOC(EditReadyMadeEvents)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.MASTER_CLASS}/${r.NEW}`} exact render={() => templateHOC(CreateLiveVirtualEvents, null, { isMasterclass: true })} />

    <Route path={`/${r.ENTOURAGE}`} exact render={() => templateHOC(EntouragePage, { themeType: 'dark-theme', backgroundType: 'default-dark' })} />
    <Route path={`/${r.ENTOURAGE}/${r.FULL}`} exact render={() => templateHOC(EntouragePageFull, { themeType: 'dark-theme', backgroundType: 'default-dark' })} />
    <Route path={`/${r.ENTOURAGE}/${r.UPGRADE}`} exact render={() => templateHOC(EntourageUpgradePage, { themeType: 'dark-theme', backgroundType: 'default-dark' })} />
    <Route path={`/${r.ENTOURAGE}/${r.PURCHASE}`} exact render={() => templateHOC(EntouragePurchase, { themeType: 'light-theme payment-page' })} />
    <Route path={`/${r.ENTOURAGE}/${r.VERIFY}`} exact render={() => templateHOC(EntourageVerify, { themeType: 'light-theme payment-page' })} />

    {/* todo: add later */}
    {/*<IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.NEW}`} exact render={() => templateHOC(CreateLiveVirtualEvents)} />*/}
    {/*<IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/:id/${r.EDIT}`} exact render={() => templateHOC(EditLiveVirtualEvent)} />*/}
    {/*<IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/:id/${r.DRAFT}`} exact render={() => templateHOC(EditLiveVirtualEventDraft)} />*/}
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.NEW}`} render={() => templateHOC(CreateCommercialVideoMessage)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/:id/${r.EDIT}`} render={() => templateHOC(EditCommercialVideoMessage)} />

    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.EXPERIENCES}/${r.RECURRING}/${r.LIST}/:id`} exact render={() => templateHOC(RecurringListExperience)} />
    <IfUserHasTalentRoute path={`/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.RECURRING}/${r.LIST}/:id`} exact render={() => templateHOC(RecurringListVirtualEvents)} />

    <IfUserHasTalentRoute path={`/${r.ORDERS}/${r.LIST}`} exact render={() => templateHOC(ManageOrders)} />
    <Route path={`/${r.ORDERS}`} render={() => templateHOC(OfferingDetailsRouter)} />
    <Route path={`/${r.DOWNLOAD}/${r.VIDEO_MESSAGE}/:offerVideoMessageOccurrenceId`} render={() => templateHOC(VideoMessageDownloadPage)} />

    <Route path={`/${r.BOOKINGS}`} render={() => templateHOC(BookingsDetailsRouter)} />

    <IfUserHasTalentRoute path={`/${r.PROMO_CODES}/${r.LIST}`} exact render={() => templateHOC(ManagePromoCodes)} />
    <IfUserHasTalentRoute path={`/${r.PROMO_CODES}/${r.NEW}`} exact render={() => templateHOC(PromoCodes)} />

    <Route path={`/${r.VERIFY_EMAIL}/:key`} exact render={() => templateHOC(VerifyEmail)} />
    <Route path={`/${r.VERIFY}-${r.REMOVE}-${r.ACCOUNT}/:key`} exact render={() => templateHOC(VerifyRemoveAccount)} />

    <Route path={`/${r.ABOUT_US}`} exact render={() => templateHOC(AboutUs, { themeType: 'dark-theme', backgroundType: 'default-dark' })} />
    <Route path={`/${r.CAREERS}`} exact render={() => templateHOC(Careers)} />
    <Route path={`/${r.CONTACT}`} exact render={() => templateHOC(Contact)} />
    <Route path={`/${r.FAQS}`} exact render={() => templateHOC(FAQs)} />
    <Route path={`/${r.HOW_IT_WORKS}`} exact render={() => templateHOC(HowItWorks, { themeType: 'dark-theme', backgroundType: 'default-dark' })} />
    <Route path={`/${r.WHY_JOIN}`} exact render={() => templateHOC(WhyJoin, { themeType: 'dark-theme', backgroundType: 'why-join' })} />
    <Route path={`/${r.HOW_TO_VIDEOS}`} exact render={() => templateHOC(HowToVideos)} />
    <Route path={`/${r.RESOURCE_CENTER}`} exact render={() => templateHOC(ResourceCenter)} />
    <Route path={`/${r.UNSUBSCRIBE_EMAIL}/:email`} exact render={() => templateHOC(UnsubscribeFromUpdates)} />

    <Route path={`/${r.NOT_FOUND}`} exact render={() => templateHOC(NotFound)} />
    <Route path={`/${r.TALENT_PENDING_FOR_APPROVAl}`} exact render={() => templateHOC(TalentPendingForApproval)} />

    <IfAuthRoute path={`/${r.CALENDAR}`} exact render={() => templateHOC(CalendarHandler)} />
    <IfUserHasTalentRoute path={`/r${r.CALENDAR}`} exact render={() => templateHOC(RTalentCalendar)} />

    <Route path={'/:talentNickname'} exact render={() => templateHOC(TalentPublicView, { backgroundType: 'talent' })} />

    <Redirect to={`/${r.NOT_FOUND}`} />
  </Switch>
)

export default Routers
