import React from 'react'
import get from 'lodash/get'
import cx from 'classnames'
import { components } from 'react-select'

const OptionWithColor = props => {
  const background = get(props, 'data.color')
  const disabled = get(props, 'data.disabled')

  const classes = cx('select-option', { 'select-option--disabled': disabled })

  return (
    <components.Option {...props} className={classes}>
      <span style={{ background }} className="color-section" />
      {props.children}
    </components.Option>
  )
}

export default OptionWithColor
