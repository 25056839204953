import React from 'react'
import get from 'lodash/get'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import { ChatType } from 'shared'
import { useHistory } from 'react-router-dom'
import { Client } from '@twilio/conversations'

import {
  useChatClient,
  useSetChatClient,
  useChatsList,
  useSetChatsList,
  useSetHasUnreadChatMessages,
} from 'store/hooks/globalState/useChats'
import { useUser, useIsUserLoading } from 'store/hooks/globalState/useUser'

import { useFetchUserChat, useFetchUserChats } from 'requests/chats'

import { getTwilioAccessToken, getRefreshToken } from 'utils/storage'
import { getTalentOrUserId } from 'utils/user'
import { refreshTokens } from 'api/utils'

import { SECURE_CHAT } from '_legacy/constants/routes'

// Set up showing error/debug messages here
const SHOW_DEBUG = false
const SHOW_ERROR = true

// eslint-disable-next-line no-console
const debug = SHOW_DEBUG ? console.debug : noop
const error = SHOW_ERROR ? console.error : noop

const twilioConnectionStatuses = {
  connecting: 'connecting',
  connected: 'connected',
  disconnecting: 'disconnecting',
  disconnected: 'disconnected',
  denied: 'denied',
}

const twilioTokenStatuses = {
  tokenAboutToExpire: 'tokenAboutToExpire',
  tokenExpired: 'tokenExpired',
}


const withTwilioConversations = Component => props => {
  const history = useHistory()

  const user = useUser()
  const currentUserId = useUser(getTalentOrUserId)

  const [twilioClientStatus, setTwilioClientStatus] = React.useState()
  const [twilioClientTokenStatus, setTwilioClientTokenStatus] = React.useState()

  const chatClient = useChatClient()
  const setChatClient = useSetChatClient((prev, next) => next)

  const chatsList = get(useChatsList(), 'list', {})

  const setChatList = useSetChatsList((prev, next) => next)

  const updateChatInList = useSetChatsList(
    (prev, chatId, chat) => ({
      ...prev,
      list: {
        ...prev.list,
        [chatId]: { ...prev.list[chatId], ...chat },
      },
    }),
    [chatsList]
  )

  const isUserLoading = useIsUserLoading()

  const setHasUnreadChatMessages = useSetHasUnreadChatMessages((prev, next) => next)

  const updateToken = React.useCallback(() => {
    refreshTokens(getRefreshToken())
      .then(async () => {
        if (chatClient) {
          chatClient.updateToken(getTwilioAccessToken())
          setTwilioClientTokenStatus(null)
        } else {
          // eslint-disable-next-line no-use-before-define
          connect(getTwilioAccessToken())
          setTwilioClientTokenStatus(null)
        }
      })
      .catch(e => {
        error(`Conversations::updateClientToken ${e}`)
      })
  }, [])

  const fetchUserChat = useFetchUserChat()
  const fetchUserChats = useFetchUserChats()

  const connect = React.useCallback(
    token => {
      Client.create(token)
        .then(client => {
          window.chatClient = client
          setChatClient(client)
        })
        .catch(e => {
          error(`Conversations::connect ${e}`)
          updateToken()
        })
    },
    [setChatClient]
  )

  const disconnect = React.useCallback(() => {
    debug('Conversations::disconnect')
    chatClient?.shutdown()
    setChatClient(null)
    setTwilioClientStatus(null)
    setTwilioClientTokenStatus(null)
  }, [])

  // create twilio connection
  React.useEffect(() => {
    const { log } = console

    if (user && !isUserLoading) {
      const token = getTwilioAccessToken()
      if (token) {
        connect(token)
        log('connect - connect - connect')
      }
    }

    return () => {
      if (chatClient) {
        log('disconnect - disconnect - disconnect')

        disconnect()
        setChatList({ list: {}, pageInfo: null })

        // redirect on user profile changes
        if (history.location.pathname.includes(`/${SECURE_CHAT}`)) {
          history.push(`/${SECURE_CHAT}/`)
        }
      }
    }
  }, [user, isUserLoading])

  // listeners
  React.useEffect(() => {
    if (chatClient) {
      // - - - - - - - - - - - - - - - CONNECTION - - - - - - - - - - - - - - -

      chatClient.on('connectionStateChanged', status => {
        debug(`Conversations::${status.toUpperCase()}`)
        setTwilioClientStatus(status)
      })

      chatClient.on('tokenAboutToExpire', () => {
        debug('Conversations::tokenAboutToExpire')
        setTwilioClientTokenStatus(twilioTokenStatuses.tokenAboutToExpire)
      })

      chatClient.on('tokenExpired', () => {
        debug('Conversations::tokenExpired')
        setTwilioClientTokenStatus(twilioTokenStatuses.tokenExpired)
      })

      chatClient.on('connectionError', data => {
        debug('Conversations::connectionError', data)
        setChatClient(null)
        updateToken()
      })

      // - - - - - - - - - - - - - - - CONVERSATION - - - - - - - - - - - - - - -

      chatClient.on('conversationAdded', conversation => {
        debug('Conversations::conversationAdded', conversation.sid)
      })

      chatClient.on('conversationJoined', async chatInstance => {
        debug('Conversations::conversationJoined', chatInstance.sid)
      })

      chatClient.on('conversationLeft', chat => {
        debug('Conversations::conversationLeft', chat.sid)
      })

      chatClient.on('conversationRemoved', chat => {
        debug('Conversations::conversationRemoved', chat.sid)
      })

      chatClient.on('conversationUpdated', data => {
        debug('Conversations::conversationUpdated', data)

        const chat = Object.values(chatsList).find(
          ch =>
            ch.chatInfo.twilioConversationSid ===
            data.conversation.sid
        )

        if (chat) {
          updateChatInList(chat.chatInfo.id, {
            chatInstance: data.conversation,
            wasUpdated: true,
          })
        }
      })

      // - - - - - - - - - - - - - - - PARTICIPANT - - - - - - - - - - - - - - -

      chatClient.on('participantJoined', data => {
        debug('Conversations::participantJoined', data)

        const chat = Object.values(chatsList).find(ch => ch.chatInfo.twilioConversationSid === data.conversation.sid)

        if (chat) {
          fetchUserChat({ chatId: chat.chatInfo.id, chatClient, withoutLoader: true })
        } else {
          fetchUserChats({ chatClient })
        }
      })

      chatClient.on('participantLeft', data => {
        debug('Conversations::participantLeft', data)
      })

      chatClient.on('participantUpdated', data => {
        debug('Conversations::participantUpdated', data)

        const chat = Object.values(chatsList).find(
          ch =>
            ch.chatInfo.twilioConversationSid ===
            data.participant.conversation.sid
        )

        if (chat) {
          updateChatInList(chat.chatInfo.id, {
            chatInstance: data.participant.conversation,
            participantWasUpdated: true,
          })
        }
      })

      // - - - - - - - - - - - - - - - MESSAGE - - - - - - - - - - - - - - -

      chatClient.on('messageAdded', data => {
        debug('Conversations::messageAdded', data)

        if (data.conversation.attributes?.conversationType === ChatType.VIDEO_CHAT) {
          return
        }

        const chat = Object.values(chatsList).find(
          ch => ch.chatInfo.twilioConversationSid === data.conversation.sid
        )

        if (chat) {
          updateChatInList(chat.chatInfo.id, {
            chatInfo: {
              ...chat.chatInfo,
              lastMessageDate: data.dateCreated,
            },
            chatInstance: data.conversation,
            wasUpdated: true,
            hasNewMessages: true,
            newMessage: data,
            newMessageSid: data.sid,
          })
        }

        if (!chat) {
          fetchUserChats({ chatClient })
        }
      })

      chatClient.on('messageRemoved', data => {
        debug('Conversations::messageRemoved', data)
      })

      chatClient.on('messageUpdated', data => {
        debug('Conversations::messageUpdated', data)
      })

      chatClient.on('typingStarted', data => {
        debug('Conversations::typingStarted', data)
      })

      chatClient.on('typingEnded', data => {
        debug('Conversations::typingStarted', data)
      })

      // OTHER
      chatClient.on('userSubscribed', data => {
        debug('Conversations::userSubscribed', data)
      })

      chatClient.on('userUnsubscribed', data => {
        debug('Conversations::userUnsubscribed', data)
      })
    }

    return () => {
      if (chatClient) {
        chatClient.removeAllListeners()
      }
    }
  }, [
    chatClient,
    chatsList,
    updateChatInList,
    setTwilioClientTokenStatus,
    setTwilioClientStatus,
  ])

  // renew client
  React.useEffect(() => {
    if (
      twilioClientTokenStatus === twilioTokenStatuses.tokenExpired &&
      twilioClientStatus === twilioConnectionStatuses.disconnected
    ) {
      updateToken()
    }

    if (
      twilioClientTokenStatus === twilioTokenStatuses.tokenAboutToExpire &&
      twilioClientStatus === twilioConnectionStatuses.connected
    ) {
      updateToken()
    }
  }, [chatClient, twilioClientStatus, twilioClientTokenStatus])

  // fetch user chats list
  React.useEffect(() => {
    if (user) {
      const isConnected =
        twilioClientStatus === twilioConnectionStatuses.connected

      if (isConnected && !isUserLoading) {
        fetchUserChats({ chatClient })
      }
    }
  }, [twilioClientStatus, user, isUserLoading])

  // check chats on unread messages
  React.useEffect(async () => {
    if (chatClient && !isEmpty(chatsList)) {
      const hasUnreadChatMessages = await Object.values(chatsList).reduce(async (accP, { chatInstance }) => {
        const acc = await accP

        const lastMessage = (await chatInstance.getMessages(1)).items[0]

        const totalMessagesCount = await chatInstance.getMessagesCount()
        const lastReadMessageIndex = chatInstance.lastReadMessageIndex ?? -1

        let unreadMessagesCount = totalMessagesCount - (lastReadMessageIndex + 1)

        const lastMessageIsFromCurrentUser =
          lastMessage && lastMessage.author === currentUserId && lastMessage.index === lastReadMessageIndex + 1

        if (lastMessageIsFromCurrentUser) {
          unreadMessagesCount = 0
        }

        return acc || !!unreadMessagesCount
      }, Promise.resolve(false))

      setHasUnreadChatMessages(hasUnreadChatMessages)
    }
  }, [chatClient, chatsList, setHasUnreadChatMessages])

  return <Component {...props} />
}

export default withTwilioConversations
