import React from 'react'
import { errorNames, api } from 'shared'
import get from 'lodash/get'
import { notify } from '../../notify'
import { useGetTranslate } from '../../../store/hooks/globalState/useTranslates'

const ERROR_PREFIX = '_errors'

const errorsMap = {
  DEFAULT: `${ERROR_PREFIX}.default`,

  [api.auth.signIn.errors.EMAIL_IS_NOT_VERIFIED]: `${ERROR_PREFIX}.email-is-not-verified`,

  [api.verify.identityVerification.request.errors.SOMETHING_WRONG]: `${ERROR_PREFIX}.something-wrong`,

  [api.talents.join.invite.errors.USERS_ALREADY_INVITED]: `${ERROR_PREFIX}.users-already-invited`,
  [api.talents.join.invite.errors.EMAILS_ALREADY_INVITED]: `${ERROR_PREFIX}.emails-already-invited`,
  [api.talents.join.invite.errors.TALENT_ALREADY_HAS_USER]: `${ERROR_PREFIX}.talent-already-has-user`,
  [api.talents.join.invite.errors
    .TALENT_CANNOT_INVITE_OTHER_TALENT]: `${ERROR_PREFIX}.talent-cannot-invite-other-talent`,

  // todo: replace in src/requests/public-views/experience/experience-booking.js
  [errorNames.offers.BOOK_WRONG_TYPE_ERROR]: `${ERROR_PREFIX}.book-wrong-type`,
  [errorNames.offers.BOOK_ALREADY_HAS_ORDER_ERROR]: `${ERROR_PREFIX}.book-already-has-order`,
  [errorNames.offers.BOOK_OUT_OF_TIME_BOUND_ERROR]: `${ERROR_PREFIX}.book-out-time-bound`,
  [errorNames.offers.BOOK_PARTICIPANTS_LIMIT_EXCEEDED_ERROR]: `${ERROR_PREFIX}.book-participants-limit-exceeded`,
  [errorNames.offers.BOOK_OFFER_FOREIGN_LOCATION]: `${ERROR_PREFIX}.book-offer-foreign-location`,
  [errorNames.offers.BOOK_OCCURRENCES_LIMIT_EXCEEDED_ERROR]: `${ERROR_PREFIX}.book-occurrences-limit-exceeded`,
  [errorNames.offers.BOOK_OCCURRENCES_OVERLAP_ERROR]: `${ERROR_PREFIX}.book-occurrences-overlap`,
  [errorNames.offers.BOOK_OFFER_IS_NOT_PUBLIC]: `${ERROR_PREFIX}.book-offer-is-not-public`,

  // common
  [errorNames.common.BAD_REQUEST]: `${ERROR_PREFIX}.bad-request`,
  [errorNames.common.INTERNAL_SERVER_ERROR]: `${ERROR_PREFIX}.internal-server-error`,
  [errorNames.common.FORBIDDEN]: `${ERROR_PREFIX}.forbidden`,
  [errorNames.common.UNAUTHORIZED]: `${ERROR_PREFIX}.unauthorized`,
  [errorNames.common.VALIDATION_FAILED]: `${ERROR_PREFIX}.validation-failed`,
  [errorNames.common.FORBIDDEN_USER_TYPE]: `${ERROR_PREFIX}.forbidden-user-type`,
}

/**
 * Function to get error message trans key
 * @func getError
 * @param {string} key - Backend error name
 * @return {string} Message translate
 *
 * @example
 *     getError(error.name)
 */
export const getError = key => get(errorsMap, key, errorsMap.DEFAULT)

export const useNotifyError = () => {
  const t = useGetTranslate()

  return React.useCallback(
    error => {
      notify(t(getError(error.name)), 'error')
    },
    [t]
  )
}
