import React from 'react'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '../ErrorMessage'
import styled, { css } from 'styled-components'
import cond from 'lodash/cond'
import matches from 'lodash/matches'
import constant from 'lodash/constant'
import stubTrue from 'lodash/stubTrue'
import get from 'lodash/get'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const MaskInput = ({
  name,
  rules,
  defaultValue = '',
  placeholder,
  requiredMessage,
  isRequired,
  isDisabled,
  className = '',
  whiteTheme,
  onCustomChange,
  ...props
}) => {
  const t = useGetTranslate()
  const { control, errors } = useFormContext()
  const requiredText = 'shared.words.required'
  const error = get(errors, name)
  const errorMessageRequired = requiredMessage ? requiredMessage : requiredText

  return (
    <StyledWrapper>
      <Controller
        name={name}
        control={control}
        rules={{ required: isRequired && errorMessageRequired, ...rules }}
        defaultValue={defaultValue}
        render={({ onChange, value }) => (
          <StyledInput
            value={value}
            thousandSeparator
            decimalSeparator={false}
            placeholder={t(placeholder)}
            autoComplete="nope"
            error={Boolean(error)}
            isDisabled={isDisabled}
            whiteTheme={whiteTheme}
            className={className}
            onValueChange={({ value }) => {
              onChange(value)
              onCustomChange && onCustomChange(value)
            }}
            {...props}
          />
        )}
      />
      {error && <ErrorMessage message={error?.message} className="error" />}
    </StyledWrapper>
  )
}

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')],
])

const StyledWrapper = styled.div`
  position: relative;

  .error {
    position: absolute;
    top: 110%;
    left: 0;
  }
`

const StyledInput = styled(NumberFormat)`
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 4px;
  border: 2px solid ${getBorderColor};
  padding: 17px;

  font-size: 18px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  line-height: normal;
  color: ${({ whiteTheme }) => (whiteTheme ? '#fff' : '#000')};

  &::placeholder {
    color: #9495a7;
  }

  ${({ isDisabled }) =>
  isDisabled &&
    css`
      background-color: #e9eaf0;
      color: #9495a7;
      cursor: not-allowed;
      pointer-events: none;

      &:hover {
        border-color: #babbcd;
      }

      &:focus {
        pointer-events: none;
      }
    `}
  &:focus, &:hover {
    border-color: ${({ whiteTheme }) => (whiteTheme ? '#fff' : '#000')};
  }

  ${({ isDisabled }) =>
      isDisabled &&
    css`
      cursor: not-allowed;
      pointer-events: none;
      background-color: #e9eaf0;

      &:hover {
        border-color: #babbcd;
      }
    `}
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`

MaskInput.propTypes = {
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  isRequired: PropTypes.bool,
  isDisabled: PropTypes.bool,
  whiteTheme: PropTypes.bool,
  className: PropTypes.string,
  requiredMessage: PropTypes.string,
  onCustomChange: PropTypes.func,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default MaskInput
