import React from 'react'

import {
  useUserLang,
  useTranslates,
  useFetchTranslates
} from 'store/hooks/globalState/useTranslates'


const withTranslates = Component => props => {
  const userLang = useUserLang()
  const translates = useTranslates()
  const fetchTranslates = useFetchTranslates()

  React.useEffect(() => {
    fetchTranslates(userLang)
  }, [userLang])

  // TODO: add loader here
  if (!translates) return null

  return <Component {...props} />
}

export default withTranslates
