import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types';

import Checkbox from '_legacy/containers/PromoCodes/components/common/Checkbox.js';

const OfferingCheck = React.memo(({ offering, bulkActionsConfig }) => {
  const { getFilters, setFilters } = bulkActionsConfig
  const checked = getFilters(current => get(current, 'selected', []).find(item => item.id === offering.id), [offering.id])

  const selectRow = setFilters((prev, next) => {
    const selected = get(prev, 'selected', [])
    if (selected.includes(next)) {
      return { ...prev, selected: selected.filter(item => item !== next) }
    }
    return { ...prev, selected: [...selected, next] }
  })

  return (
    <Checkbox
      item={offering}
      checked={checked}
      clickHandler={selectRow}
    />
  )
})

OfferingCheck.propTypes = {
  offering: PropTypes.object.isRequired,
  bulkActionsConfig: PropTypes.object.isRequired,
}

export default OfferingCheck
