import { TalentOffersType, TalentOfferPendingActionType } from 'shared'

import Orders from './Orders'
import Type from './Type'
import Participants from './Participants'
import DateTime from '_legacy/components/Table/table-components/types/common/DateTime'
import Location from './Location'
import CurrentPendingActions from './CurrentPendingActions'
import Status from './Status'

export const ORDERS = 'orders'
export const ORDER = 'order'
export const TYPE = 'type'
export const PARTICIPANTS = 'participants'
export const DATE_TIME = 'date_time'
export const LOCATION = 'location'
export const CURRENT_PENDING_ACTIONS = 'current_pending_actions'
export const STATUS = 'status'

export const elementsTypes = {
  [ORDER]: ORDER,
  [TYPE]: TYPE,
  [PARTICIPANTS]: PARTICIPANTS,
  [DATE_TIME]: DATE_TIME,
  [LOCATION]: LOCATION,
  [CURRENT_PENDING_ACTIONS]: CURRENT_PENDING_ACTIONS,
  [STATUS]: STATUS
}

export const manageOrdersComponents = {
  [ORDER]: Orders,
  [TYPE]: Type,
  [PARTICIPANTS]: Participants,
  [DATE_TIME]: DateTime,
  [LOCATION]: Location,
  [CURRENT_PENDING_ACTIONS]: CurrentPendingActions,
  [STATUS]: Status
}

export const currentActionStatus = {
  [TalentOffersType.IN_PERSON_EXPERIENCE]: {
    [TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST]: 'my-orders.current-action.status.review-book-request',
    [TalentOfferPendingActionType.HOLD]: 'my-orders.current-action.status.hold-experience',
    [TalentOfferPendingActionType.LEAVE_REVIEW]: 'my-orders.current-action.status.leave-review',
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.SET_TIME]: 'my-orders.current-action.status.set-time',
  },
  [TalentOffersType.VIDEO_CHAT]: {
    [TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST]: 'my-orders.current-action.status.review-book-request',
    [TalentOfferPendingActionType.HOLD]: 'my-orders.current-action.status.hold-video-chat',
    [TalentOfferPendingActionType.LEAVE_REVIEW]: 'my-orders.current-action.status.leave-review',
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.SET_TIME]: 'my-orders.current-action.status.set-time',
  },
  [TalentOffersType.VIDEO_MESSAGE]: {
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
  },
  [TalentOffersType.LIVE_IN_PERSON_LESSON]: {
    [TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST]: 'my-orders.current-action.status.review-book-request',
    [TalentOfferPendingActionType.HOLD]: 'my-orders.current-action.status.hold-person-lesson',
    [TalentOfferPendingActionType.LEAVE_REVIEW]: 'my-orders.current-action.status.leave-review',
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.SET_TIME]: 'my-orders.current-action.status.set-lesson-time',
  },
  [TalentOffersType.VIRTUAL_LESSON]: {
    [TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST]: 'my-orders.current-action.status.review-book-request',
    [TalentOfferPendingActionType.HOLD]: 'my-orders.current-action.status.hold-virtual-lesson',
    [TalentOfferPendingActionType.LEAVE_REVIEW]: 'my-orders.current-action.status.leave-review',
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
    [TalentOfferPendingActionType.SET_TIME]: 'my-orders.current-action.status.set-time',
  },
  [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: {
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none'
  },
  [TalentOffersType.READY_MADE_LESSON]: {
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
  },
  [TalentOffersType.VIRTUAL_EVENT]: {
    [TalentOfferPendingActionType.HOLD]: 'my-orders.current-action.status.virtual-event',
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
  },
  [TalentOffersType.FOR_COMMERCIAL_USE]: {
    [TalentOfferPendingActionType.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
    [TalentOfferPendingActionType.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.none',
    [TalentOfferPendingActionType.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
  },
}
