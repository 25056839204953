import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import classnames from 'classnames';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetch } from 'store/hooks/useFetch';
import { subscribeForUpdates as subscribeForUpdatesApi } from 'api/appApi/oneDirectionRequests';
import { notify } from 'utils/notify';

export const SubscribeForUpdates = () => {
  const t = useGetTranslate();
  const [{ data }, doFetch] = useFetch(subscribeForUpdatesApi);
  const { register, handleSubmit, errors, reset } = useForm();

  React.useEffect(() => {
    if (data) {
      notify(t('footer.email-subscriptions.notifications.success.message'), 'success');
      reset();
    }
  }, [data]);


  return (
    <form onSubmit={handleSubmit(doFetch)}>
      <div>
        <input
          type="email"
          name="email"
          placeholder="youremail@example.com"
          className={classnames('register-input', { error: errors.email })}
          ref={register({
            required: true,
            pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
      </div>
      <span>{t('footer.subscribe')}</span>
    </form>
  );
};

SubscribeForUpdates.propTypes = {
  onSubmit: PropTypes.func,
};
