import React from 'react'
import styled from 'styled-components'
import isEmpty from 'lodash/isEmpty'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Loader from '../../types/common/Loader'

import eventPath from 'utils/eventPath'

export default React.memo(function ActionDropdown ({ isLoading, rowId, actionsConfig, row }) {
  const t = useGetTranslate()
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const toggleMenu = React.useCallback(() => !isLoading && setIsOpen(current => !current), [isLoading, setIsOpen])

  const closeMenu = React.useCallback((e) => {
    if (eventPath(e).includes(ref.current)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const parsedOptions = React.useMemo(() => {
    return actionsConfig
      .filter(({ shouldShow }) => shouldShow ? shouldShow(row) : true)
      .map(({ label, cbFn }, i) => {
        return (
          <div
            key={i}
            className='menu-item'
            onClick={() => cbFn([rowId])}
          >
            {t(label)}
          </div>
        )
      })
  }, [actionsConfig, rowId, t])

  if (isLoading) return <StyledLoader />

  if (isEmpty(parsedOptions)) return <StyledWrapper />

  return (
    <StyledWrapper
      ref={ref}
      isLoading={isLoading}
      onClick={toggleMenu}
    >
      <div className='dott' />
      <div className='dott' />
      <div className='dott' />
      {isOpen && (
        <div className='menu-wrapper'>
          {parsedOptions}
        </div>
      )}
    </StyledWrapper>
  )
})

const StyledLoader = styled(Loader)`
  width: 12px;
  height: 24px;
`

const StyledWrapper = styled.div`
  position: relative;
  padding: 2px 4px;
  cursor: pointer;
  width: 12px;
  
  &:hover {
    .dott {
      background: ${({ theme: { colors } }) => colors.blue};
    }
  }
  .dott {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${({ theme: { colors } }) => colors.lightGrey};
    margin: 2px 0;
    transition: background 0.2s;
  }
  .menu-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border-radius: 4px;
    min-width: 140px;
    z-index: 5;
    white-space: nowrap;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    .menu-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      color: ${({ theme: { colors } }) => colors.grey};
      padding: 7px 16px;
      margin: 4px 0;
      :hover {
        background: ${({ theme: { colors } }) => colors.ultraLightBlue};
        cursor: pointer;
      }
    }
    .menu-item.active {
      color: ${({ theme: { colors } }) => colors.blue};
      font-weight: 700;
    }
  }
`
