export const NAME = 'name'
export const CODE = 'code'
export const OFFERINGS = 'offerings'
export const DISCOUNT = 'discount'
export const EXPIRATION_DATE = 'expirationDate'
export const USAGE_LIMIT = 'usageLimit'
export const STATUS = 'status'

export const elementsTypes = {
  [NAME]: NAME,
  [CODE]: CODE,
  [OFFERINGS]: OFFERINGS,
  [DISCOUNT]: DISCOUNT,
  [EXPIRATION_DATE]: EXPIRATION_DATE,
  [USAGE_LIMIT]: USAGE_LIMIT,
  [STATUS]: STATUS
}

import Name from './Name'
import Code from './Code'
import Offerings from './Offerings'
import Discount from './Discount'
import ExpirationDate from './ExpirationDate'
import UsageLimit from './UsageLimit'
import Status from './Status'

export const PROMO_CODES = 'promo-codes'

export const promoCodesComponents = {
  [NAME]: Name,
  [CODE]: Code,
  [OFFERINGS]: Offerings,
  [DISCOUNT]: Discount,
  [EXPIRATION_DATE]: ExpirationDate,
  [USAGE_LIMIT]: UsageLimit,
  [STATUS]: Status
}
