import { get } from 'lodash'

import { lessonLocation } from 'utils/services/loaders/locations'
import { getCharitiesForForm } from 'utils/services/loaders/charities'
import { getDaysTimesTypeOptions } from '../../utils.js'
import { getEntourageAdapter, getEntourageTypeAdapter } from 'utils/forms/adapters/entourage'


export const liveInPersonLessonFormAdapter = (values, t) => {
  const {
    location,
    active,
    lengthOfTime,
    price,
    charities,
    specialInstructions,
    daysTimesType,
  } = values

  const mediaId = get(values, 'media.file.fileToken')

  return {
    active: String(active),
    lengthOfTime: String(lengthOfTime / 60),
    location: lessonLocation(location),
    price: String(price),
    charities:  getCharitiesForForm(charities),
    media: mediaId,
    mediaTemp: mediaId,
    specialInstructions,
    daysTimesType: getDaysTimesTypeOptions(t).find(option => option.value === daysTimesType),
    entourage: getEntourageAdapter(values),
    entourageType: getEntourageTypeAdapter(values),
  }
}
