import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Loader from './Loader'

export default React.memo(function LineString ({ isLoading, str, className }) {
  const t = useGetTranslate()
  if (isLoading) return <Loader />
  return (
    <StyledWrapper className={className}>
      {str || t('table.text.n-a')}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme: { colors } }) => colors.grey};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
    font-weight: 700;
    line-height: 26px;
    color: ${({ theme: { colors } }) => colors.blue};
  }
`
