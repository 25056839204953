import { eachDayOfInterval, endOfWeek, format, startOfWeek } from 'date-fns'
import { getLocale } from 'utils/date'

export const buildWorkingDaysLabel = (days = [], userLang = 'EN') => {
  const locale = getLocale(userLang)

  return eachDayOfInterval({
    start: startOfWeek(new Date(), { weekStartsOn: 1 }),
    end: endOfWeek(new Date(), { weekStartsOn: 1 }),
  })
    .reduce((acc, day) => [...acc, format(day, 'E', { locale })], [])
    .filter((day, index) => days.includes(index))
    .join(', ')
}

