import React from 'react'

import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'

import {
  useReadyMadeEventLessonsDetailsOrderTable as useTable,
  useReadyMadeEventLessonsDetailsOrderFilters as useFilters,
  useSetReadyMadeEventLessonsDetailsOrderFilters as useSetFilters,
} from '../../state/store.js'
import { useFetchReadyMadeEventLessonsDetailsOrderTable as fetchOrders } from '../../state/requests.js'

import { READY_MADE_EVENT_LESSONS_DETAILS_ORDERS, tableConfig, fields } from './configs/tableConfig.js'
import { filtersConfig } from './configs/filtersConfig.js'
import { subFiltersConfig, counterConfig } from './configs/subFiltersConfig.js'


const ReadyMadeEventLessonsDetailsOrdersTable = () => {
  return (
    <div className='wrapper content-wrapper ready-made-event-lessons-details-orders-table'>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useFilters}
        setFn={useSetFilters}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useFilters}
        setFn={useSetFilters}
      />
      <Table
        tableName={READY_MADE_EVENT_LESSONS_DETAILS_ORDERS}
        emptyTableText={'table.empty-requests'}
        tableConfig={tableConfig}
        getTableFn={useTable}
        fields={fields}
        getFiltersFn={useFilters}
        setFiltersFn={useSetFilters}
        fetchTableFn={fetchOrders}
      />
    </div>
  )
}

export default ReadyMadeEventLessonsDetailsOrdersTable
