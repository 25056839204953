
import React from 'react'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { tzdb } from 'utils/services/timezone'
import { getTimeZoneAdapter } from 'utils/forms/adapters'

import { Row } from '_legacy/common'
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent.js'


const TimeZoneSelect = ({ name = 'timezone' }) => {
  const t = useGetTranslate()
  const requiredText = t('edit-profile.form.time-zone.errors.required')

  const options = tzdb.map(getTimeZoneAdapter)

  return (
    <Row
      nameBlock={t('edit-profile.form.time-zone.label')}
      component={
        <SelectComponent
          name={name}
          options={options}
          placeholder={t('edit-profile.form.time-zone.placeholder')}
          rules={{
            validate: (p) => !!p?.label === true || requiredText,
          }}
        />
      }
    />
  )
}

TimeZoneSelect.propTypes = {
  name: PropTypes.string
}

export default TimeZoneSelect
