import React from 'react';
import PropTypes from 'prop-types';

export const AccountMenuList = ({
  children,
}) => {
  return (
    <div className="account-menu__item--list">
      {children}
    </div>
  );
};

AccountMenuList.propTypes = {
  children: PropTypes.node,
};
