import { _post, _get } from 'api/utils'

export const leaveComplaint = ({ message, orderId }) =>
  _post(`/orders/${orderId}/leave-complaint`, {
    needAuthorization: true,
    body: { message },
  })

export const checkComplaint = ({ orderId }) =>
  _get(`/orders/${orderId}/check-complaint-accessibility`, {
    needAuthorization: true,
  })
