import React from 'react'
import cond from 'lodash/fp/cond'

import Button from '_legacy/components/Button'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useModal } from 'store/hooks/useModal'
import CancelModal from '../CancelModal'
import ModalWithControls from 'portals/ModalWithControls'
import { useCancelOccurrence } from '../../OrderDetails/ExperienceOrderDetails/state/requests/experience-order-details'

const CancelOccurrenceButton = ({
  offerId,
  onSuccess,
  message,
  header,
  refundBtnLabel,
  immediatelyOpenCancelModal,
  immediatelyCancelOrder,
  cancelBtnText
}) => {
  const t = useGetTranslate()
  const cancelOccurrence = useCancelOccurrence(onSuccess)

  const [isCancelOrderModalOpen, openCancelOrderModal, closeCancelOrderModal] = useModal(false)
  const [isCancelReasonModalOpen, openCancelReasonModal, closeCancelReasonModal] = useModal(false)

  const onCancelOrderModal = React.useCallback(
    cond([
      [() => immediatelyOpenCancelModal, openCancelReasonModal],
      [() => immediatelyCancelOrder, () => cancelOccurrence({ offerId })],
      [() => true, openCancelOrderModal],
    ]),
    [immediatelyOpenCancelModal, immediatelyCancelOrder, openCancelOrderModal, cancelOccurrence]
  )

  const onRefund = React.useCallback(() => {
    closeCancelOrderModal()
    openCancelReasonModal()
  }, [])

  const onCancelOccurrence = React.useCallback(({ reason, message }) => {
    cancelOccurrence({ offerId, reason, message })
  }, [offerId])

  return (
    <React.Fragment>
      <Button
        primary
        stretch
        text={cancelBtnText || t('order.answer.buttons.cancel-order')}
        paddingVertical={16}
        handleOnClick={onCancelOrderModal}
      />

      <ModalWithControls
        isOpen={isCancelOrderModalOpen}
        onClose={closeCancelOrderModal}
        header={t(header) || t('cancel-order-modal.header')}
        message={t(message) || t('cancel-order-modal.message')}
        buttonsConfig={[
          { text: t('cancel-order-modal.controls.go-back'), handleOnClick: closeCancelOrderModal, primary: true },
          { text: t(refundBtnLabel) || t('cancel-order-modal.controls.refund'), handleOnClick: onRefund },
        ]}
      />

      <CancelModal
        orderId={offerId}
        onClose={closeCancelReasonModal}
        isOpen={isCancelReasonModalOpen}
        cancelHandler={onCancelOccurrence}
        buttonText="cancel-reason.cancel-occurrence.button"
      />
    </React.Fragment>
  )
}

export default CancelOccurrenceButton
