import React from 'react'
import { range } from 'lodash'

const LoaderList = () => {
  const list = range(0, 10)
  return (
    <ul className="cascader-list__list">
      {list.map(i => (
        <li
          key={i}
          className="cascader-list__list-item cascader-list__list-item--loading"
        />
      ))}
    </ul>
  )
}

export default LoaderList
