import { _post, _get } from '../../utils';

export const login = ({ email, password }) => _post(
  '/auth/sign-in',
  { body: { email, password } }
);

export const logout = () => _post('/auth/logout', { needAuthorization: true });

export const me = () => _post('/auth/me', { needAuthorization: true });
export const changePassword = (data) => _post('/auth/change-password', { needAuthorization: true, body: data });
export const profiles = () => _get('/auth/profiles', { needAuthorization: true });
export const switchProfile = (switchProfileId) => _post(`/auth/profiles/${switchProfileId}/switch`, { needAuthorization: true });
export const switchToAgent = () => _post('/auth/profiles/me/switch', { needAuthorization: true });
