import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from 'assets/icons/Icon'

const Loader = ({ size = 20, fill, className }) => (
  <Icon.Spinner className={cx('loader', className)} fill={fill} width={size} height={size} />
)

Loader.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
  className: PropTypes.string,
}

export default Loader
