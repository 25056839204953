import React from 'react'
import range from 'lodash/range'

import ViewStarIcon from 'assets/icons/ViewStarIcon';

const FillStar = () => <ViewStarIcon percentageFill={100} size={20} />
const UnFillStar = () => <ViewStarIcon percentageFill={0} size={20} />

const RatingOption = ({ value }) => {
  const rating = [...range(0, value).fill(true), ...range(value, 5).fill(false)]
  return rating.map(filled => filled ? <FillStar /> : <UnFillStar />)
}

export default RatingOption
