import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/get'
import { EntourageSubscriptionType } from 'shared'

import { ReactComponent as EntourageFullIcon } from './icons/EntourageFullIcon.svg'
import { ReactComponent as EntourageLiteIcon } from './icons/EntourageLiteIcon.svg'

const sizes = {
  lg: 'lg',
  md: 'md',
  sm: 'sm',
}

const entourageIcons = {
  [EntourageSubscriptionType.LITE]: EntourageLiteIcon,
  [EntourageSubscriptionType.FULL]: EntourageFullIcon,
}

const DefaultIcon = () => <div />

const EntourageLogo = ({ type, size = sizes.md, className, ...props }) => {
  const Icon = get(entourageIcons, type, DefaultIcon)
  const classes = cx('entourage-logo', className, {
    'entourage-logo--lg': size === sizes.lg,
    'entourage-logo--md': size === sizes.md,
    'entourage-logo--sm': size === sizes.sm,
  })

  return <Icon className={classes} {...props} />
}

EntourageLogo.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizes)),
  type: PropTypes.oneOf([EntourageSubscriptionType.LITE, EntourageSubscriptionType.FULL]),
}

export default EntourageLogo
