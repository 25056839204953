import React from 'react'
import styled from 'styled-components'
import compact from 'lodash/compact'

import { useSubMenuActive } from 'store/hooks/globalState/useGlobalState'

import CustomPicture from '_legacy/common/CustomPicture'

export default function Overlay ({ layers, isMain }) {
  const isActive = useSubMenuActive(current => isMain && current, [isMain])

  const convertedLayers = React.useMemo(() => {
    if (!layers) return [];
    const personLayer = layers.find(({ layerType }) => layerType === 'PERSON');
    if (!isMain) return [personLayer];
    const otherLayers = layers.filter(({ layerType }) => layerType === 'OTHER');
    return [personLayer, ...otherLayers].sort((a, b) => a.index - b.index);
  }, [isMain, layers]);

  const parsedLayers = React.useMemo(() => {
    return compact(convertedLayers).map((file) => {
      return (
        <StyledCustomPicture
          key={file.image.id}
          file={file.image.file}
          layerType={file.layerType}
          isActive={isActive}
        />
      );
    });
  }, [convertedLayers, isActive]);

  return (
    <StyledWrapper
      isMain={isMain}
    >
      {parsedLayers}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;
  user-select: none;
  ${({ isMain }) => !isMain && 'filter: saturate(0%)'};
  ${({ isMain }) => !isMain && 'overflow: hidden'};
`

const StyledCustomPicture = styled(CustomPicture)`
  position: absolute;
  display: block;
  width: 274%;
  height: 107%;
  bottom: 0;
  opacity: ${({ layerType, isActive }) => layerType === 'PERSON' || isActive ? 1 : 0};
  transition: opacity 350ms;
`
