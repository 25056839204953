import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import ModalPortal from 'portals/ModalPortal'
import Button from '_legacy/components/Button'

const ChangeRouteModal = ({ onDiscard, onDraft, onSubmit, onClose }) => {
  const t = useGetTranslate()
  return (
    <ModalPortal showCloseButton isOpen>
      <StyledModal>
        <h1>{t('prompt-modal.header')}</h1>
        <p>{t('prompt-modal.text')}</p>
        <div className="buttons-wrapper">
          <Button
            primary
            text={t('prompt-modal.button.back-to-editing')}
            typeButton="button"
            handleOnClick={onClose}
            paddingVertical={13}
            paddingHorizontal={30}
          />
          <Button
            primary
            text={t('prompt-modal.button.discard-changes')}
            typeButton="button"
            handleOnClick={onDiscard}
            paddingVertical={13}
            paddingHorizontal={30}
          />
          <Button
            text={t('prompt-modal.button.save-as-draft')}
            typeButton="button"
            handleOnClick={onDraft}
            paddingVertical={13}
            paddingHorizontal={30}
          />
          <Button
            text={t('prompt-modal.button.save-and-publish')}
            typeButton="submit"
            handleOnClick={onSubmit}
            paddingVertical={13}
            paddingHorizontal={30}
          />
        </div>
      </StyledModal>
    </ModalPortal>
  )
}

const StyledModal = styled.div`
  background: white;
  padding: 24px;
  width: 600px;

  h1 {
    font-size: 28px;
    font-weight: 700;
    font-family: 'Playfair Display', sans-serif;
    line-height: 1.25;
    color: ${({ theme }) => theme.colors.blue};
    margin: 0 auto;
    text-align: center;
  }

  p {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.colors.grey};
    line-height: 1.625;
    margin: 16px 0;
  }

  .buttons-wrapper {
    gap: 24px;
    display: flex;
    justify-content: flex-end;
  }
`

ChangeRouteModal.propTypes = {
  onConfirm: PropTypes.func,
  onDraft: PropTypes.func,
}

export default ChangeRouteModal
