import {
  createUseGlobalState,
  createUseSetGlobalState,
} from '../useGlobalState'

const tableKey = 'reviewsOfMeTable'
const filtersKey = 'reviewsOfMeFilters'

export const useReviewsOfMeTable = createUseGlobalState(tableKey)
export const useSetReviewsOfMeTable = createUseSetGlobalState(tableKey)

export const useReviewsOfMeFilters = createUseGlobalState(filtersKey)
export const useSetReviewsOfMeFilters = createUseSetGlobalState(filtersKey)
