import map from 'lodash/map';
import { types } from 'shared';

export const states = map(
  types.locations.USAStatesKeypair,
  (label, key) => ({ value: key, label }),
);

export const countries = map(
  types.locations.Countries,
  (label, key) => ({ value: key, label: label.native, data: label.code }),
);
