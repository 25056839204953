import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import PreJoinHeader from '../PreJoinHeader'
import Button from '_legacy/components/Button'

import { routesList } from 'router/routesMap'
import * as r from '_legacy/constants/routes'
import { getSaveFullName, getTalentId, getUserPublicViewLink } from 'utils/user'

import { useUser } from 'store/hooks/globalState/useUser'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useVideoState } from '../../hooks/useVideoState'

const transConfig = {
  [r.VIDEO_CHAT]: 'Live Video Chat',
  [r.LIVE_VIRTUAL_LESSON]: 'Live Virtual Lesson',
}

const endCallRouteConfig = {
  [r.VIDEO_CHAT]: routesList.videoChatOrderDetails,
  [r.LIVE_VIRTUAL_LESSON]: routesList.liviuVirtualLessonOrderDetails
}

const reconnectCallRouteConfig = {
  [r.VIDEO_CHAT]: routesList.videoChatRoom,
  [r.LIVE_VIRTUAL_LESSON]: routesList.liveVirtualLessonRoom
}

export default function RejoinScreen() {
  const t = useGetTranslate()
  const history = useHistory()
  const { occurrenceId, offerType } = useParams()
  const talentId = useUser(getTalentId)
  const { verifyVideoChatRoom, closeVideoChatRoom, previewInfo } = useVideoState()

  useEffect(() => {
    verifyVideoChatRoom(occurrenceId)
  }, [occurrenceId])

  const handleEnd = async () => {
    const endCallApi = endCallRouteConfig[offerType]

    closeVideoChatRoom(occurrenceId).then(() => {
      history.push(endCallApi(occurrenceId))
    })
  }

  const handleReconnect = () => {
    const reconnectCallApi = reconnectCallRouteConfig[offerType]

    history.push(reconnectCallApi(occurrenceId))
  }

  const currentUserIsOfferCreator = talentId === previewInfo?.occurrenceTalentId

  return (
    <>
      <PreJoinHeader />
      <div className="rejoin-screen wrapper content-wrapper">
        <h1 className="rejoin-screen__header">
          <Trans tKey="video-chat.rejoin-screen.header" phs={[{ ph: '{{offerName}}', value: transConfig[offerType] }]} />
        </h1>
        <p className="rejoin-screen__description">
          <Trans
            tKey={
              currentUserIsOfferCreator
                ? 'video-chat.rejoin-screen.description-with-end-message'
                : 'video-chat.rejoin-screen.description'
            }
            phs={[
              { ph: '{{offerName}}', value: transConfig[offerType] },
              { ph: '{{participantName}}', value: getSaveFullName(previewInfo?.remoteParticipant) },
              { ph: '{{participantLink}}', value: getUserPublicViewLink(previewInfo?.remoteParticipant) },
            ]}
          />
        </p>
        <div className="rejoin-screen__controls">
          {currentUserIsOfferCreator && (
            <Button primary className="mr-24" handleOnClick={handleEnd} text={t('video-chat.rejoin-screen.end-chat')} />
          )}
          <Button handleOnClick={handleReconnect} text={t('video-chat.rejoin-screen.reconnect')} />
        </div>
      </div>
    </>
  )
}
