import React from 'react'
import { OrderStatus, OrderPaymentStatus } from 'shared'
import { isEqual, includes } from 'lodash/fp'
import get from 'lodash/get'

import Button from '_legacy/components/Button'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useAcceptOrderRequest, useRejectOrderRequest } from 'components/OfferingDetails/OrderDetails/ExperienceOrderDetails/state/requests/experience-order-requests'
import { statuses } from '_legacy/configs/table/manageOrders'

import CommonStatus from '_legacy/components/Table/table-components/types/common/Status'

const { APPROVED, REJECTED, UNDER_REVIEW, BOOKED, COMPLETED, ADMIN_REVIEW } = OrderStatus
const { CANCELED, FROZEN } = OrderPaymentStatus

const Status = ({ isLoading, id, status, paymentStatus }) => {
  const t = useGetTranslate()
  const handleAccept = useAcceptOrderRequest()
  const handleReject = useRejectOrderRequest()

  const isOptionUnderReview = isEqual(status, UNDER_REVIEW)
  const isCanceled = isEqual(paymentStatus, CANCELED)
  const showStatus = includes(status, [APPROVED, REJECTED, BOOKED, COMPLETED, ADMIN_REVIEW, CANCELED])

  const isShowRejected = isOptionUnderReview && isCanceled
  const isShowActions = isOptionUnderReview && isEqual(paymentStatus, FROZEN)

  if (isLoading) return <Loader />

  //TODO Find better solution to handle stripe response
  const formattedStatus = isShowRejected ? REJECTED : status

  return (
    <>
      {isShowActions &&
        <div className="action-cell">
          <Button
            text={t('order-details.actions.accept')}
            typeButton="button"
            handleOnClick={() => handleAccept(id)}
            paddingVertical={4}
            fontSize="0.75"
          />
          <Button
            primary
            text={t('order-details.actions.cancel')}
            typeButton="button"
            handleOnClick={() => handleReject(id)}
            paddingVertical={4}
            fontSize="0.75"
          />
        </div>
      }
      {(isShowRejected || showStatus) &&
        <CommonStatus
          className='centered'
          isLoading={isLoading}
          status={formattedStatus}
          config={get(statuses, formattedStatus, '')}
        />
      }
    </>
  )
}

export default Status
