import React from 'react'

import { useCurrencyCode, useCurrencyRate } from 'store/hooks/globalState/useCurrency'
import { getPriceCurrencyLabel, getPriceLabel } from '_legacy/components/Price/price-config'

export function useGetPrice() {
  const currencyRate = useCurrencyRate()
  const currencyCode = useCurrencyCode()

  return React.useCallback((price, config) => {
    const currencyLabel = getPriceCurrencyLabel(currencyCode)
    const formattedPrice = Math.round(Number(price) * Number(currencyRate))
    const priceLabel = getPriceLabel(formattedPrice, currencyCode)

    return [priceLabel, config?.withCurrency ? currencyLabel : ''].join(' ')
  }, [currencyRate, currencyCode])
}
