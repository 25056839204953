import React from 'react'
import { EntourageSubscriptionType } from 'shared'
import { prop, propEq } from 'lodash/fp'
import cx from 'classnames'

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { checkAbilityToStartBackgroundVerification, startBackgroundVerification } from '../api/api'
import { ReactComponent as IDVerifiedIcon } from '../icons/IDVerifiedIcon.svg'
import { ReactComponent as IDNoVerifiedIcon } from '../icons/IDNoVerifiedIcon.svg'
import Button from '_legacy/components/Button'

const getLink = prop('screening.invite.link')

const isFullVerified = propEq('user.entourageType', EntourageSubscriptionType.FULL)

const isIDVerificationPassed = propEq('user.identityIsVerified', true)

const BackgroundCheck = () => {
  const user = useUser()
  const t = useGetTranslate()
  const [verificationInfo, setVerificationInfo] = React.useState(null)
  const [isAbleToVerification, setIsAbleToVerification] = React.useState(null)

  const isCardDisabled = !isAbleToVerification || !isIDVerificationPassed(user)

  React.useEffect(() => {
    checkAbilityToStartBackgroundVerification()
      .then(() => {
        setIsAbleToVerification(true)

        startBackgroundVerification().then(setVerificationInfo)
      })
      .catch(() => setIsAbleToVerification(false))
  }, [])

  if (isFullVerified(user)) {
    return (
      <div className="entourage-verify-card">
        <IDVerifiedIcon className="entourage-verify-card__icon" />
        <h3 className="entourage-verify-card__header">{t('entourage-verify.background-check.header.verified')}</h3>
      </div>
    )
  }

  return (
    <div className={cx('entourage-verify-card', { 'entourage-verify-card--disabled': isCardDisabled })}>
      <IDNoVerifiedIcon className="entourage-verify-card__icon" />
      <h3 className="entourage-verify-card__header">{t('entourage-verify.background-check.header.no-verified')}</h3>
      <p className="entourage-verify-card__text">{t('entourage-verify.background-check.text.no-verified')}</p>
      <Button
        text={t('entourage-verify.background-check.button.no-verified')}
        handleOnClick={() => window.open(getLink(verificationInfo), '_blank')}
      />
    </div>
  )
}

export default BackgroundCheck
