import React from 'react'
import { get, isNumber } from 'lodash'
import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { submitAddressAdapter } from './utils/addressAdapter'
import { sendPaymentData } from 'api/appApi/purchase'
import { notify } from 'utils/notify'

const isObjEmpty = obj => !Object.values(obj).filter(Boolean).length

const parseProps = data => {
  const totalPrice = get(data, 'order.totalPrice')
  const discountPrice = get(data, 'discountPromoCode.calculatedData.totalPrice')
  const actualPrice = isNumber(discountPrice) ? discountPrice : totalPrice

  const orderId = get(data, 'order.id')

  const label = get(data, 'order.talentOffer.title')

  const shippingAddress = submitAddressAdapter(data)

  const promoCodeId = get(data, 'discountPromoCode.promoCode.id') || undefined

  return {
    orderId,
    label,
    amount: actualPrice * 100,
    shippingAddress: isObjEmpty(shippingAddress) ? undefined : shippingAddress,
    promoCodeId,
  }
}

const CheckoutForm = ({ data, openSuccessModal }) => {
  const t = useGetTranslate()
  const stripe = useStripe()
  const [paymentRequest, setPaymentRequest] = React.useState(null)
  const { orderId, label, amount, promoCodeId, shippingAddress } = parseProps(data)

  const onPaymentmethod = async (ev) => {
    const { secret, isNeedRequiresAction } = await sendPaymentData({
      orderId,
      paymentMethodId: ev.paymentMethod.id,
      promoCodeId,
      shippingAddress,
    })

    if (isNeedRequiresAction) {
      const { paymentIntent, error: stripeError } = await stripe.confirmCardPayment(secret, {
        payment_method: ev.paymentMethod.id,
      })

      if (stripeError || paymentIntent.status !== 'succeeded') {
        notify(stripeError.message, 'error')
        ev.complete('fail')
        return
      }
    }

    ev.complete('success')

    openSuccessModal()
  }

  React.useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label,
          amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      })

      // Check the availability of the Payment Request API.
      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr)
        }
      })

      pr.on('paymentmethod', onPaymentmethod)
    }
  }, [stripe])

  // Update amount
  React.useEffect(() => {
    if (paymentRequest) {
      paymentRequest.update({
        total: {
          label,
          amount,
        },
      })

      paymentRequest.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(paymentRequest)
        }
      })

      paymentRequest.off('paymentmethod')

      paymentRequest.on('paymentmethod', onPaymentmethod)
    }
  }, [paymentRequest, amount, label, promoCodeId, shippingAddress])

  if (paymentRequest) {
    return (
      <div className="mobile-payment-form">
        <h6 className="mobile-payment-form__header">{t('payment-page.checkout-form.header')}</h6>
        <p className="mobile-payment-form__info">{t('payment-page.checkout-form.info')}</p>
        <div className="mobile-payment-form__buttons">
          <PaymentRequestButtonElement
            options={{
              paymentRequest,
              style: {
                paymentRequestButton: {
                  theme: 'dark',
                  height: '60px',
                },
              },
            }}
          />
        </div>
      </div>
    )
  }

  return null
}

export default CheckoutForm
