import React from 'react'
import styled from 'styled-components'
import get from 'lodash/get'
import isBoolean from 'lodash/isBoolean'
import { useHistory } from 'react-router-dom';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Viewer from './Viewer'

import getFile from 'utils/getFile'
import { getMonthDayYearDate } from 'utils/date'

export default React.memo(function Card ({
  slide, wrapperDimensions, setCurrentSlide, CardText
}) {
  const history = useHistory();
  const t = useGetTranslate()
  const firstName = get(slide, 'talent.firstName', '')
  const lastName = get(slide, 'talent.lastName', '')
  const talentNickname = get(slide, 'talent.plaqueNickname', '')
  const date = get(slide, 'startTime', '')
  const name = get(slide, 'name', '')
  const multipleDates = get(slide, 'multipleDates')

  const altImg = React.useMemo(() => {
    return `${firstName} ${lastName}`
  }, [firstName, lastName])

  const file = getFile(slide)
  const mediaType = get(file, 'type');

  const isMain = slide.type === 'main'

  const onClickText = React.useCallback(() => {
    if (!isMain) return
    history.push(`/${talentNickname}`)
  }, [isMain, talentNickname])

  const isDateVisible = date
    ? getMonthDayYearDate(date)
    : t('home.slider.top-experiences.date')

  const text = React.useMemo(() => {
    if (!CardText || !wrapperDimensions.width) return null

    return (
      <CardText
        onClick={onClickText}
        mediaType={mediaType}
        isMain={isMain}
        firstName={firstName}
        lastName={lastName}
        date={isBoolean(multipleDates) && isDateVisible}
        name={name}
        wrapperDimensions={wrapperDimensions}
      />
    )
  }, [wrapperDimensions, CardText, isMain, talentNickname, firstName, lastName, date, name])

  return (
    <StyledWrapper
      className={slide.type}
      isMain={isMain}
      wrapperDimensions={wrapperDimensions}
      onClick={setCurrentSlide}
    >
      <Viewer
        isMain={isMain}
        file={file && { ...file, originalName: altImg }}
      />
      <div className='overlay' />
      {text}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  transition-duration: 350ms;
  transition-timing-function: linear;
  width: ${({ wrapperDimensions }) => wrapperDimensions.height * 0.66}px;
  height: ${({ wrapperDimensions }) => wrapperDimensions.height * 0.88}px;
  transition-property: transform;
  background: #000;
  box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.3);
  &.left-outer {
    transform: translate(${({ wrapperDimensions }) => -(wrapperDimensions.height * 0.66 / 2)}px, 0) scale(0);
    z-index: 10;
    pointer-events: none;
    filter: blur(4px);
  }
  &.left-2 {
    transform: translate(${({ wrapperDimensions }) => -(wrapperDimensions.height * 0.66 / 2)}px, 0) scale(0.75);
    filter: blur(2px);
    z-index: 30;
  }
  &.left-1 {
    transform: translate(${({ wrapperDimensions }) => -(wrapperDimensions.height * 0.66 / 3.5)}px, 0) scale(0.85);
    filter: blur(1px);
    z-index: 40;
  }
  &.main {
    z-index: 50;
  }
  &.right-1 {
    transform: translate(${({ wrapperDimensions }) => wrapperDimensions.height * 0.66 / 3.5}px, 0) scale(0.85);
    filter: blur(1px);
    z-index: 40;
  }
  &.right-2 {
    transform: translate(${({ wrapperDimensions }) => wrapperDimensions.height * 0.66 / 2}px, 0) scale(0.75);
    filter: blur(2px);
    z-index: 30;
  }
  &.right-outer {
    transform: translate(${({ wrapperDimensions }) => wrapperDimensions.height * 0.66 / 2}px, 0) scale(0);
    z-index: 10;
    pointer-events: none;
    filter: blur(4px);
  }
  .overlay {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(0deg, rgba(79, 81, 106, 0.7), rgba(79, 81, 106, 0.7)), linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, #272835 80.73%);
    ${({ isMain }) => isMain && `
      height: 75%;
      background-image: linear-gradient(180deg,rgba(79,81,106,0),rgba(79,81,106,.85) 67%,rgba(79,81,106,.95));
    `};
  }
`
