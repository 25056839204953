import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const OwnWordsInfoModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="upload-info-modal">
        <h3 className="upload-info-modal__title">
          {t('register.form.talent-own-words.label')}
        </h3>
        <br />
        {t('register.form.talent-own-words.info.modal')}
      </div>
    </ModalPortal>
  );
};

OwnWordsInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default OwnWordsInfoModal;
