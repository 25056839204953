import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import Button from '../Button';
import ModalPortal from 'portals/ModalPortal';

const PasswordChangedModal = ({ isModalOpen, closeModal }) => {
  const t = useGetTranslate()
  return (
    <ModalPortal isOpen={isModalOpen} onClose={closeModal} showCloseButton>
      <div className="password-changed-modal">
        <span className="password-changed-modal__check-icon" />
        <h1 className="password-changed-modal__header">
          {t('reset-password.modal.password-changed.header')}
        </h1>
        <p className="password-changed-modal__text">
          {t('reset-password.modal.password-changed.text')}
        </p>
        <Button
          text={t('reset-password.modal.password-changed.button')}
          handleOnClick={closeModal}
          stretch
          paddingVertical="16"
          paddingHorizontal="20"
        />
      </div>
    </ModalPortal>
  );
};

PasswordChangedModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

export default PasswordChangedModal
