import React from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'
import pick from 'lodash/fp/pick'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useRegisterAsTalent } from 'requests/auth'
import { useRegisterAsTalentDraft } from 'requests/auth'
import { getUser, isAgentOrManager } from 'utils/user'

import { Container, Title } from '_legacy/common'
import RegistrationFormTalent from '_legacy/components/RegisterForms/Talent'
import defaultValues from '_legacy/components/RegisterForms/Talent/utils/defaultValues'
import submitAdapter from '_legacy/components/RegisterForms/Talent/utils/submitAdapter'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

const RegistrationTalent = () => {
  const t = useGetTranslate()
  const user = useUser()
  const agentOrManager = isAgentOrManager(user)
  const { firstName, lastName, nickname } = compose(pick(['firstName', 'lastName', 'nickname']), getUser)(user)

  const methods = useForm({
    mode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  })

  const registerAsTalent = useRegisterAsTalent()
  const registerAsTalentHandler = compose(registerAsTalent, submitAdapter)

  const registerAsTalentDraft = useRegisterAsTalentDraft()
  const registerAsTalentDraftHandler = compose(registerAsTalentDraft, submitAdapter, methods.getValues)

  // set initial data to form
  React.useEffect(() => {
    if (!agentOrManager) {
      methods.setValue('firstName', firstName)
      methods.setValue('lastName', lastName)
      methods.setValue('nickname', nickname)
    }
  }, [agentOrManager])

  return (
    <>
      <StyledWrapper>
        <ChangeRouteHandler
          shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
          onDraft={registerAsTalentDraftHandler}
        />
        <Container>
          <Title className="title">{t('register.talent.header')}</Title>
          <RegistrationFormTalent
            methods={methods}
            onSubmit={registerAsTalentHandler}
            onDraft={registerAsTalentDraftHandler}
            userWithoutTalent={agentOrManager}
          />
        </Container>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  margin-top: 50px;
`

export default withLibrary(RegistrationTalent)

