export const getAsyncLoad = (apiFunction, itemsName, itemPreparator) => async (search = '', _, { page }) => {
  const { [itemsName]: items, pageInfo } = await apiFunction({ name: search, page });

  return {
    options: items.map(itemPreparator),
    hasMore: pageInfo.remains !== 0,
    additional: {
      page: pageInfo.page + 1,
    }
  };
};
