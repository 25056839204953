import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

export const CommonCheckbox = React.memo(
  React.forwardRef(({ label, secondary, className = '', ...props }, ref) => {
    return (
      <StyledCheckbox secondary={secondary} className={className}>
        <span>{label}</span>
        <input type="checkbox" {...props} ref={ref} />
        <i />
      </StyledCheckbox>
    )
  })
)

export const Checkbox = React.memo(({ validation, isRequired, ...props }) => {
  const { register } = useFormContext();

  return (
    <CommonCheckbox
      ref={register({
        required: isRequired && 'shared.words.required',
        ...validation,
      })}
      {...props}
    />
  );
});

export const StyledCheckbox = styled.label`
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 35px;
  margin-bottom: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ i {
    background-color: #ccc;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ i {
      background-color: #272835;
      border: none;

      &:before {
        display: block;
      }
    }

    &:disabled ~ i {
      background-color: #ccc;
    }
  }

  i {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    border: 2px solid #babacd;
    border-radius: 4px;

    &:before {
      display: none;
      content: '';
      position: absolute;
      top: 20%;
      left: 50%;
      width: 4px;
      height: 12px;
      border: solid white;
      border-width: 0 2px 2px 0;
      transform: rotate(40deg) translateX(-50%);
    }
  }

  span {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    color: #272835;
    padding-left: 6px;

    strong {
      font-weight: 700;
      color: inherit;
    }

    ${({ disabled }) =>
  disabled &&
      css`
        color: #9495a7;
      `}
  }

  ${({ secondary }) =>
        secondary &&
    css`
      input:checked ~ i {
        background-color: white;
      }

      i {
        border-color: white;
        background-color: rgba(0, 0, 0, 0);

        &:before {
          border-color: #272835;
        }
      }

      span {
        color: #272835;
      }
    `}
`

Checkbox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  name: PropTypes.string,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  validation: PropTypes.object,
  isRequired: PropTypes.bool,
  className: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default Checkbox
