import { types } from 'shared';
import kebabCase from 'lodash/kebabCase';

const {
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  RECURRING_DAY_TIME_OPTION,
  UP_TO_5_DAY_TIME_OPTIONS,
} = types.experiences.DaysTimesType;

const daysTimeTypes = [
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  RECURRING_DAY_TIME_OPTION,
  UP_TO_5_DAY_TIME_OPTIONS,
];

export const getDayTypeLabel = type =>
  `create-experience.form.days-times-type.options.${kebabCase(type)}`;

export const getDayTypesWithTranslate = t =>
  daysTimeTypes.map(type => ({
    value: type,
    label: t(getDayTypeLabel(type)),
  }));
