import React from 'react'
import get from 'lodash/get'

import {
  fetchResultsByKeywords as getResultsByKeywordsApi,
  getRecentSearches as getRecentSearchesApi
} from './api'
import {
  useSetSearchResults,
  useSetRecentSearchList,
} from './store';

import { useWindowDimensions } from 'store/hooks';

export function useFetchSearchResult() {
  const setSearchResults = useSetSearchResults((prev, next) => next)
  const { width } = useWindowDimensions()

  return React.useCallback(({ search }, isAuthorized) => {
    if (search === '') {
      setSearchResults({})
      return
    }

    getResultsByKeywordsApi({
      query: { search },
      isAuthorized,
    })
      .then(({ talentOffers, talents }) => {
        setSearchResults({ talentOffers, talents })
      })
      .catch(error => {
        console.warn(error)
      })
  },
  [setSearchResults, width]
  )
}

export function useFetchUserRecentSearches() {
  const setUserRecentSearchList = useSetRecentSearchList((_, next) => next)

  return React.useCallback(() => {
    getRecentSearchesApi()
      .then(result => {
        const keywords = get(result, 'history.keywords', [])
        setUserRecentSearchList(keywords)
      })
  }, [setUserRecentSearchList])
}
