import React from 'react';
import compose from 'lodash/fp/compose';
import { notify } from 'utils/notify'
import { CancelOrderReason } from 'shared'

import { fetchManagerOrders } from './api'
import normalizeParams from 'utils/normalizeParams'
import {
  useManageOrdersFilters,
  useSetManageOrdersFilters,
  useSetManageOrdersTable,
} from './store'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'

import { parseRangeDate } from '_legacy/components/Table/Filters/utils/rangeDate'
import { getTalentId } from 'utils/user'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useCurrentPage } from 'store/hooks/globalState/useUtils'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { fields } from '_legacy/configs/table/recurringList'
import {
  cancelOccurrence as cancelOccurrenceApi,
  checkAvailabilityToCancelFromList as checkAvailabilityToCancel,
} from '../../OfferingDetails/RecurringList/RecurringListExperience/state/api'

const normalize = compose(normalizeParams, parseRangeDate)

export const useFetchManageOrders = () => {
  const setManageOrdersFilters = useSetManageOrdersFilters((prev, next) => ({ ...prev, ...next }))
  const setManageOrdersTable = useSetManageOrdersTable((prev, next) => next)
  const handleResponseError = useHandleResponseError()

  const talentId = useUser(getTalentId)

  return React.useCallback(
    ({ ...options }) => {
      const query = normalize(options)
      setManageOrdersTable(null)
      fetchManagerOrders({ id: talentId, query })
        .then(({ occurrences, pageInfo }) => {
          setManageOrdersFilters(pageInfo)
          setManageOrdersTable(occurrences)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setManageOrdersTable, setManageOrdersFilters]
  )
}

// actual, here we cancel occurrence
export function useCancelOccurrence() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const page = useCurrentPage()
  const fetchManageOrders = useFetchManageOrders()
  const manageOrdersFilters = useManageOrdersFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )

  return React.useCallback(({ message, reason, occurrenceId, offerId }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message || ''
    }
    cancelOccurrenceApi({
      talentId,
      id: offerId,
      occurrenceId,
      body
    }).then(() => {
      notify(t('order-details.experience.order-cancel-success'), 'success')
      fetchManageOrders({ ...manageOrdersFilters, page })
    })
      .catch((err) => {
        console.warn(err)
      })
  }, [t, page, fetchManageOrders, manageOrdersFilters])
}

export function useTryCancelOccurrence(openModal) {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const cancelOccurrence = useCancelOccurrence()

  return React.useCallback(({ occurrenceId, offerId }) => {
    checkAvailabilityToCancel({ talentId, offerId, experienceOccurrenceId: occurrenceId })
      .then(() => {
        cancelOccurrence({ occurrenceId, reason: CancelOrderReason.OTHER, message: '' })
      })
      .catch((err) => {
        if (err.context?.isPassedOccurrence) {
          notify(t('order-details.experience.order-cannot-be-canceled'), 'error')
        } else {
          openModal()
        }
      })
  }, [t])
}
