// Base imports
import React from 'react'

// Requests
import { useFetchExperienceBookingDetailsParticipants } from '../../state/requests'

// Store (Hooks)
import {
  useExperienceBookingDetailsParticipantsFilters,
  useSetExperienceBookingDetailsParticipantsFilters,
  useExperienceBookingDetailsParticipants,
} from '../../state/store'

// Utils
import {
  counterParticipantsConfig,
  fields,
} from '_legacy/configs/table/bookingDetailsParticipants'

// Legacy components
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import {
  BOOKING_DETAILS_PARTICIPANTS,
  elementsTypes,
} from '_legacy/components/Table/table-components/types/booking-participants/config'

const tableConfig = [
  {
    header: 'table.header.aficionado',
    type: elementsTypes.aficionado,
  },
  {
    header: 'table.header.participants',
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    type: elementsTypes.status,
    className: 'centered',
  },
]

const ExperienceBookingsDetailsParticipants = () => {
  const fetchTable = () => useFetchExperienceBookingDetailsParticipants()

  return (
    <div className="wrapper content-wrapper">
      <SubFilters
        counterConfig={counterParticipantsConfig}
        getFn={useExperienceBookingDetailsParticipantsFilters}
        setFn={useSetExperienceBookingDetailsParticipantsFilters}
      />
      <Table
        tableName={BOOKING_DETAILS_PARTICIPANTS}
        emptyTableText={'table.empty-participants'}
        tableConfig={tableConfig}
        getTableFn={useExperienceBookingDetailsParticipants}
        fields={fields}
        getFiltersFn={useExperienceBookingDetailsParticipantsFilters}
        setFiltersFn={useSetExperienceBookingDetailsParticipantsFilters}
        fetchTableFn={fetchTable}
      />
    </div>
  )
}

export default ExperienceBookingsDetailsParticipants
