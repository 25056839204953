import { createUseGlobalState, createUseSetGlobalState } from 'store/hooks/globalState/useGlobalState'

/* States for Virtual Event Recurring list */
export const useVirtualEventsOfferingDetailsHeader = createUseGlobalState('virtualEventsOfferingDetailsHeader')
export const useSetVirtualEventsOfferingDetailsHeader = createUseSetGlobalState('virtualEventsOfferingDetailsHeader')

export const useVirtualEventsOfferingDetailsTableFilters = createUseGlobalState('virtualEventsOfferingDetailsFilters')
export const useSetVirtualEventsOfferingDetailsTableFilters = createUseSetGlobalState('virtualEventsOfferingDetailsFilters')

export const useVirtualEventsOfferingDetailsTable = createUseGlobalState('virtualEventsOfferingDetailsTable')
export const useSetVirtualEventsOfferingDetailsTable = createUseSetGlobalState('virtualEventsOfferingDetailsTable')
/* States for Recurring list */
