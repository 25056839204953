import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form';

import { useModal } from 'store/hooks/useModal'

import LibraryWrapper from '../components/Library/LibraryWrapper'
import LibraryCropModal from './components/LibraryCropModal'
import SingleSelectLibraryModal from '../SingleSelectLibrary/components/SingleSelectLibraryModal'
import SingleSelectLibraryButton from '../SingleSelectLibrary/components/SingleSelectLibraryButton'

const LibraryWithCrop = React.memo(({ name, rules, dropZoneProps, cropModalProps, components, libraryModalProps }) => {
  const _tempKey = name + 'Temp'
  const _tempCropKey = name + 'TempCrop'

  const [isLibraryOpen, openLibraryModal, closeLibraryModal] = useModal(false)
  const [isCropOpen, openCropModal, closeCropModal] = useModal(false)

  const { setValue, getValues, register } = useFormContext()

  React.useEffect(() => {
    register(_tempCropKey)
  }, [register, _tempCropKey])

  const onCloseLibraryModal = () => {
    setValue(_tempKey, null)
    closeLibraryModal()
  }

  const onCloseCropModal = () => {
    setValue(_tempKey, null)
    closeCropModal()
  }

  const onSubmit = () => {
    const tempValue = getValues(_tempKey)
    setValue(_tempCropKey, tempValue)
    openCropModal()
    closeLibraryModal()
  }

  return (
    <React.Fragment>
      <LibraryWrapper rules={rules} name={name}>
        <SingleSelectLibraryButton
          isLibraryOpen={isLibraryOpen}
          onClick={openLibraryModal}
          name={name}
          {...components}
        />
      </LibraryWrapper>
      <SingleSelectLibraryModal
        name={_tempKey}
        onSubmit={onSubmit}
        isOpen={isLibraryOpen}
        closeModal={onCloseLibraryModal}
        dropZoneProps={dropZoneProps}
        libraryModalProps={libraryModalProps}
      />
      <LibraryCropModal
        name={name}
        tempName={_tempKey}
        closeModal={onCloseCropModal}
        isModalOpen={isCropOpen}
        {...cropModalProps}
      />
    </React.Fragment>
  )
})

LibraryWithCrop.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  dropZoneProps: PropTypes.objectOf(
    PropTypes.shape({
      accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      hintComponent: PropTypes.element,
      multiple: PropTypes.bool,
      noClick: PropTypes.bool,
      noDrag: PropTypes.bool,
      maxSize: PropTypes.number,
    })
  ),
  libraryModalProps: PropTypes.objectOf(PropTypes.shape({
    header: PropTypes.string,
    submitBtnText: PropTypes.string,
  })),
  cropModalProps: PropTypes.objectOf(
    PropTypes.shape({
      cropShape: PropTypes.oneOf(['rect', 'round']),
      aspect: PropTypes.number,
      header: PropTypes.string,
    })
  ),
  components: PropTypes.objectOf(
    PropTypes.shape({
      customPicture: PropTypes.element,
      customBackdrop: PropTypes.element,
    })
  ),
}

export default LibraryWithCrop
