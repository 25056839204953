import React from 'react'
import LineString from '_legacy/components/Table/table-components/types/common/LineString'

const Order = ({ isLoading, number }) => {

  return (
    <LineString isLoading={isLoading} str={number} />
  )
}

export default Order
