import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import './index.scss'

import { ReactComponent as DisconnectIcon } from '../../assets/dissconect-icon.svg'
import useVideoContext from '../../hooks/useVideoContext'
import { routesList } from 'router/routesMap'
import * as r from '_legacy/constants/routes'

const routeConfig = {
  [r.VIDEO_CHAT]: routesList.videoChatRoomEnd,
  [r.LIVE_VIRTUAL_LESSON]: routesList.liveVirtualLessonRoomEnd,
}

export default function EndCallButton() {
  const { room } = useVideoContext()
  const { occurrenceId, offerType } = useParams()
  const history = useHistory()

  const handleDisconnect = () => {
    room.disconnect()
    routeConfig[offerType](occurrenceId)
    history.push(routeConfig[offerType](occurrenceId))
  }

  return (
    <button className='video-button video-button--disconnect' type="button" onClick={handleDisconnect}>
      <DisconnectIcon />
    </button>
  )
}
