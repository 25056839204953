
import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import isEmpty from 'lodash/isEmpty'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useFetchLiveVirtualEventDraft,
  useCreateLiveVirtualEvents,
  useEditLiveVirtualEventDraft
} from 'requests/live-virtual-events'
import { useLiveVirtualEvents, useSetLiveVirtualEvents } from 'store/hooks/globalState/useLiveVirtualEvents'

import Form from '_legacy/containers/LiveVirtualEvents/Form'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import OfferingDescription from '_legacy/containers/Offerings/components/OfferingDescription'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

import { getDefaultValues } from '_legacy/containers/LiveVirtualEvents/utils/defaultValues'
import { createLiveVirtualEventsAdapter, getLiveVirtualEventsDataAdapter } from '_legacy/containers/LiveVirtualEvents/utils/createLiveVirtualEventsAdapter'

const EditLiveVirtualEventDraft = () => {
  const t = useGetTranslate()
  const fetchLiveVirtualEventDraft = useFetchLiveVirtualEventDraft()
  const editLiveVirtualEventDraft = useEditLiveVirtualEventDraft()
  const createLiveVirtualEvent = useCreateLiveVirtualEvents()
  const liveVirtualEvent = useLiveVirtualEvents()
  const clearVirtualEvent = useSetLiveVirtualEvents(() => null)

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    shouldUnregister: false,
    mode: 'onChange',
  })

  const onSubmit = compose(
    createLiveVirtualEvent,
    createLiveVirtualEventsAdapter,
  )

  const onDraft = compose(
    editLiveVirtualEventDraft,
    createLiveVirtualEventsAdapter,
    methods.getValues
  )

  React.useEffect(() => {
    fetchLiveVirtualEventDraft()
    return () => {
      clearVirtualEvent()
    }
  }, [])

  React.useEffect(() => {
    if (!isEmpty(liveVirtualEvent)) {
      const adapted = getLiveVirtualEventsDataAdapter(liveVirtualEvent, t)
      methods.reset(adapted)
    }
  }, [liveVirtualEvent])

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('live-virtual-events.title.edit-draft')}</OfferingTitle>
      <OfferingDescription>{t('live-virtual-events.subtitle')}</OfferingDescription>
      <Form methods={methods} onSubmit={onSubmit} onDraft={onDraft} />
    </OfferingTemplate>
  )
}

export default withLibrary(EditLiveVirtualEventDraft)
