import React from 'react';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import PriceInput from '_legacy/common/Input/PriceInput';
import { Row } from '_legacy/common';

const PricePerParticipant = () => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('create-experience.form.price-per-participant.label')}
      component={
        <PriceWrapper>
          <PriceInput
            name='pricePerParticipant'
            placeholder='create-experience.form.price-per-participant.placeholder'
            requiredMessage='create-experience.form.price-per-participant.errors.required'
            isRequired
          />
        </PriceWrapper>
      }
    />
  );
};

const PriceWrapper = styled.div`
  .input-mask {
    height: 60px;
  }
`;

export default PricePerParticipant;
