import styled from 'styled-components'
import PropTypes from 'prop-types'
import React from 'react';

const RemoveButton = ({ onClick, className }) => {
  return (
    <StyledButton className={`remove-button ${className}`} onClick={onClick}>
      <i className="icon-trash icon-trash__styles" />
    </StyledButton>
  )
}

const StyledButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: 24px;
  top: 21px;
  z-index: 1;

  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 32px;

  border-radius: 0;
  box-shadow: 3px 3px 7px rgba(209, 210, 225, 0.3),
    -3px -3px 7px rgba(250, 250, 255, 0.5);
  opacity: 0;
  cursor: pointer;

  border: none;
  width: 48px;
  height: 48px;
  padding: 11px 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  i {
    padding: 0;
    font-size: 20px;
  }
`

RemoveButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default RemoveButton
