import React from 'react'

import { updateOccurrenceTime } from '../api'
import { notify } from 'utils/notify'
import {
  checkAvailabilityToCancel,
  useCheckIsOfferEditable,
  useFetchExperienceOrderDetails,
} from './experience-order-details'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export function useUpdateOccurrenceTime() {
  const t = useGetTranslate()
  const fetchOccurrence = useFetchExperienceOrderDetails()
  const fetchIsEditable = useCheckIsOfferEditable()
  const checkIsCancelable = checkAvailabilityToCancel()

  return React.useCallback(
    ({ offerExperienceOccurrenceId, startTime, endTime }) => {
      updateOccurrenceTime({ startTime, endTime, offerExperienceOccurrenceId })
        .then(() => {
          notify(t('order-details.edit.time-was-updated'))
          fetchOccurrence()
          fetchIsEditable()
          checkIsCancelable()
        })
    },
    [fetchOccurrence, fetchIsEditable, checkIsCancelable, t]
  )
}
