// Modules
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { RejectOrderReason } from 'shared'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Legacy components
import TextArea from '_legacy/common/TextArea'
import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent'

import { reasonsAdapter } from './utils'

const RejectModal = ({ isOpen, onClose, orderId, rejectHandler }) => {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      rejectReason: '',
      message: '',
    },
  })
  const rejectReason = methods.watch('rejectReason')

  const t = useGetTranslate()
  const isBtnDisabled = rejectReason?.value === 0

  const onSubmit = ({ message = '', rejectReason }) => {
    const reason = rejectReason?.value
    rejectHandler({ reason, ordersIds: [orderId], message })
    onClose()
  }

  const reasonOptions = [
    {
      label: t(reasonsAdapter[RejectOrderReason.SCHEDULING_ISSUE]),
      value: RejectOrderReason.SCHEDULING_ISSUE,
    },
    {
      label: t(reasonsAdapter[RejectOrderReason.PAYMENT_ISSUE]),
      value: RejectOrderReason.PAYMENT_ISSUE,
    },
    {
      label: t(reasonsAdapter[RejectOrderReason.AFICIONADO_PROFILE_ISSUE]),
      value: RejectOrderReason.AFICIONADO_PROFILE_ISSUE,
    },
    {
      label: t(reasonsAdapter[RejectOrderReason.OTHER]),
      value: RejectOrderReason.OTHER,
    },
  ]

  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <FormProvider {...methods}>
        <div className="reject-modal">
          <h1 className="modal-header">{t('reject-modal.header')}</h1>
          <p className="modal-text">{t('reject-modal.sub-header')}</p>

          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <label className="input-label">
              {t('reject-reason.reason.label')}
              <SelectComponent
                name={'rejectReason'}
                placeholder={t('reject-reason.reason.placeholder')}
                options={reasonOptions}
                rules={{ required: 'shared.words.required' }}
                menuPortalTarget={document.body}
              />
            </label>

            {rejectReason?.value === RejectOrderReason.OTHER && (
              <label className="input-label">
                {t('reject-reason.other.label')}
                <TextArea
                  maxLength={200}
                  name="message"
                  className="review-modal__textarea"
                  placeholder={t('reject-reason.other.placeholder')}
                  isRequired
                  requiredMessage={t('reject-reason.required.message')}
                />
              </label>
            )}

            <Button
              stretch
              typeButton="submit"
              paddingVertical={16}
              disabled={isBtnDisabled}
              text={t('reject-modal.form.submit-button')}
            />
          </form>
        </div>
      </FormProvider>
    </ModalPortal>
  )
}

export default RejectModal
