import React, { useState } from 'react'
import Select from 'react-select'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useDevices from '../../hooks/useDevices'
import useVideoContext from '../../hooks/useVideoContext'
import useMediaStreamTrack from '../../hooks/useMediaStreamTrack'
import VideoTrack from '../VideoTrack/VideoTrack'
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../constants'


export default function VideoInputList() {
  const t = useGetTranslate()
  const { videoInputDevices } = useDevices()
  const { localTracks } = useVideoContext()
  const localVideoTrack = localTracks.find(track => track.kind === 'video')

  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack)
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  )
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId

  function replaceTrack(newDeviceId) {
    // Here we store the device ID in the component state. This is so we can re-render this component display
    // to display the name of the selected device when it is changed while the users camera is off.
    setStoredLocalVideoDeviceId(newDeviceId)
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId)
    localVideoTrack?.restart({ ...DEFAULT_VIDEO_CONSTRAINTS, deviceId: { exact: newDeviceId } })
  }

  const videoInputDevicesOptions = videoInputDevices.map(device => ({ value: device.deviceId, label: device.label }))
  const activeVideoOutputOption = videoInputDevicesOptions.find(option => option.value === localVideoInputDeviceId)
  const activeVideoOutputLabel = activeVideoOutputOption?.label ?? t('video-chat.device-selection-modal.no-local-video')


  return (
    <div className="media-list">
      {localVideoTrack && (
        <div className='media-list__video-wrapper'>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}

      {videoInputDevices.length > 1 ? (
        <>
          <h6 className="media-list__header">
            {t('video-chat.device-selection-modal.video-input')}
          </h6>
          <div className="react-select-container">
            <Select
              classNamePrefix="react-select"
              components={{ IndicatorSeparator: () => null }}
              isSearchable={false}
              options={videoInputDevicesOptions}
              onChange={option => replaceTrack(option.value)}
              value={activeVideoOutputOption}
            />
          </div>
        </>
      ) : (
        <>
          <h6 className="media-list__header">{t('video-chat.device-selection-modal.video-input')}</h6>
          <div className="media-list__default">{activeVideoOutputLabel}</div>
        </>
      )}
    </div>
  )
}
