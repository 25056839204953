import get from 'lodash/get';

import {
  submitCharitiesAdapter,
  getCharitiesAdapter,
  getRadio,
  submitRadio
} from 'utils/forms/adapters'
import {
  getMultiSelectLibraryAdapter,
  submitMultiSelectLibraryAdapter
} from 'utils/services/validators/library';

export const videoMessageDefaultValues = {
  price: '',
  enableInYourServices: 'true',
  whichCharities: [
    { charity: { value: null, label: '' }, percent: '' }
  ],
  media: [],
  mediaTemp: []
}

export const getVideoMessageAdapter = data => ({
  price: String(get(data, 'price')),
  enableInYourServices: getRadio(data.active),
  whichCharities: getCharitiesAdapter(data.whichCharities),
  ...getMultiSelectLibraryAdapter('media', data),
})

export const submitVideoMessageAdapter = (formState) => {
  return {
    price: Number(formState.price),
    enableInYourServices: submitRadio(formState.enableInYourServices),
    whichCharities: submitCharitiesAdapter(formState.whichCharities),
    ...submitMultiSelectLibraryAdapter('media', formState)
  }
}
