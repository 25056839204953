import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import Biography from '_legacy/components/Common/Biography';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Biographies = ({ name }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('edit-profile.form.biographies.label')}
      subNameBlock={t('edit-profile.form.biographies.label-tip')}
      component={<Biography name={name} />}
    />
  );
};

Biographies.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Biographies;
