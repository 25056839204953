import React from 'react'
import PropTypes from 'prop-types'
import { FormProvider } from 'react-hook-form'
import styled from 'styled-components'
import { PRECISION_TYPES } from '_legacy/components/Form/Selects/basic/LocationsContants';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation'

import Input from '_legacy/components/RegisterForms/shared/components/Input'
import Nickname from '_legacy/components/RegisterForms/shared/components/Nickname'
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel'
import FullBodyImage from '_legacy/components/RegisterForms/Talent/components/FullBodyImage'
import HalfBodyImage from '_legacy/components/RegisterForms/Talent/components/HalfBodyImage'
import DisplayNameType from '_legacy/components/RegisterForms/Talent/components/DisplayNameType'
import Location from '_legacy/components/RegisterForms/Talent/components/Location'
import Languages from '_legacy/components/RegisterForms/Talent/components/Languages'
import Nationality from '_legacy/components/RegisterForms/Talent/components/Nationality'
import Charities from '_legacy/components/RegisterForms/Talent/components/Charities'
import Biographies from '_legacy/components/RegisterForms/Talent/components/Biographies'
import Licenses from '_legacy/components/RegisterForms/Talent/components/Licenses'
// import CalendarSync from '_legacy/components/RegisterForms/Talent/components/CalendarSync'
import OwnWords from './components/OwnWords'
import Categories from './components/Categories'
import Submit from './components/Submit'
import HeadshotWithExample from '../shared/components/HeadshotImage/HeadshotWithExample'
import PromoCode from '_legacy/components/RegisterForms/shared/PromoCode'
import { Row } from '_legacy/common'
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import PromoCodeInfoModal from '_legacy/components/Modal/PromoCodeInfoModal'

const RegistrationFormTalent = React.memo(function RegistrationFormTalent({ methods, onSubmit, onDraft, userWithoutTalent }) {
  useSetPathToNavigate({ talentRoute: '/', agentRoute: '/' })
  const t = useGetTranslate()

  return (
    <FormProvider {...methods}>
      <StyledForm onSubmit={methods.handleSubmit(onSubmit)} className="white-background">
        <RequiredLabel />

        <Input
          label={t('register.form.talent-first-name.label')}
          name="firstName"
          placeholder={t('register.form.talent-first-name.placeholder')}
          validation={{
            required: 'register.form.talent-first-name.errors.required',
          }}
          isRequired
        />

        <Input
          label={t('register.form.talent-last-name.label')}
          name="lastName"
          placeholder={t('register.form.talent-last-name.placeholder')}
          validation={{
            required: 'register.form.talent-last-name.errors.required',
          }}
          isRequired
        />

        <Nickname />

        <DisplayNameType name="displayNameType" />

        <Categories />

        <FullBodyImage isRequired={false} name="fullBodyImage" />

        {userWithoutTalent && <HeadshotWithExample name="profileImage" />}

        <HalfBodyImage isRequired={false} name="halfBodyImage" />

        <Location isRequired nameBlock={t('register.form.location-city.label')} precision={PRECISION_TYPES.CITY} />

        <Languages />

        <Nationality />

        <Charities name="charities" />

        <OwnWords isRequired={false} name="ownWords" />

        <Biographies name="biographies" />

        <Row
          nameBlock={t('register.promocode.label')}
          component={<PromoCode isRegistration />}
          tip={<TipButton modalComponent={PromoCodeInfoModal} />}
        />

        <Licenses />

        {/* EN-3230 hide calendar sync */}
        {/*<CalendarSync />*/}

        <Submit onDraftHandler={userWithoutTalent && onDraft} />

        <MissedData />
      </StyledForm>
    </FormProvider>
  )
})

const StyledForm = styled.form`
  margin-top: 50px;
  padding-bottom: 50px;

  .charity-tip,
  .description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #626473;
    line-height: 1.625;
    margin-top: 16px;
    margin-bottom: 0;
    a {
      text-decoration: none;
      color: #626473;
    }
  }
`

RegistrationFormTalent.propTypes = {
  methods: PropTypes.object,
  onSubmit: PropTypes.func,
  onDraft: PropTypes.func,
  userWithoutTalent: PropTypes.bool,
}

export default RegistrationFormTalent
