import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// components
import ErrorMessage from '../ErrorMessages';

const CommonInput = ({
  type = 'text',
  name,
  placeholder,
  forwardRef,
  label,
  errorMessage,
  max,
  min,
  defaultValue,
  value,
  autoComplete = 'nope',
  showError,
  disabled,
  onChange,
  inputStyle,
  inputType = 'default',
  inputClassName,
  labelClassName,
  containerClassName,
  labelContainerClassName,
  component,
  onFocus,
  onBlur,
  ...props
}) => {
  const inputClasses = cx(`input input-${inputType}`, inputClassName, {
    error: !!errorMessage || showError,
  });
  const labelClasses = cx('input-label', labelClassName);
  const containerClasses = cx('input-container', containerClassName);
  const labelContainerClasses = cx('input__label-container', labelContainerClassName);

  return (
    <div className={containerClasses}>
      <label>
        <div className={labelContainerClasses}>
          {label && <span className={labelClasses}>{label}</span>}
          {component}
        </div>
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          name={name}
          ref={forwardRef}
          className={inputClasses}
          min={min}
          max={max}
          defaultValue={defaultValue}
          autoComplete={autoComplete}
          value={value}
          disabled={disabled}
          onChange={onChange}
          style={inputStyle}
          {...props}
        />
      </label>
      {errorMessage && <ErrorMessage message={errorMessage}/>}
    </div>
  );
}
;

CommonInput.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  label: PropTypes.string,
  forwardRef: PropTypes.func,
  errorMessage: PropTypes.string,

  inputClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,

  max: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  min: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  value: PropTypes.PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),

  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,

  onChange: PropTypes.func,
  inputStyle: PropTypes.object,
  inputType: PropTypes.oneOf(['default', 'modal', 'lessons']),

  component: PropTypes.oneOfType([
    PropTypes.arrayOf([PropTypes.node]),
    PropTypes.node,
    PropTypes.func,
  ]),
  showError: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};

export default CommonInput;
