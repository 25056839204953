import React from 'react';

import { Row } from '_legacy/common';
import Recurring from '../Recurring';
import WindowItem from '../TimeWindow/WindowItem';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const RecurringWindow = () => {
  const t = useGetTranslate();

  return (
    <>
      <Row
        isRequired
        nameBlock={t('create-experience.form.time-window.label')}
        component={<WindowItem datePickerName="dateOfExperience" />}
      />
      <Recurring />
    </>
  );
};

export default RecurringWindow;
