import React from 'react'
import { OrderStatus, OrderPaymentStatus } from 'shared'
import { isEqual, includes } from 'lodash/fp'
import get from 'lodash/get'

import Button from '_legacy/components/Button'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useAcceptOrderRequest, useRejectOrderRequest } from 'components/OfferingDetails/OrderDetails/ExperienceOrderDetails/state/requests/experience-order-requests'
import { useGlobalIsLoading } from 'components/OfferingDetails/OrderDetails/ExperienceOrderDetails/state/store'
import { statuses } from '_legacy/configs/table/manageOrders'

// TODO rewrite this one after tables rewrite (we can't handle request queue with existed table implementation)
import StatusLoader from '_legacy/components/Common/Loader'

import CommonStatus from '_legacy/components/Table/table-components/types/common/Status'
import RejectModal from 'components/OfferingDetails/components/RejectModal'

import { useModal } from 'store/hooks/useModal'

const { APPROVED, REJECTED, UNDER_REVIEW, BOOKED, COMPLETED, CLOSED, ADMIN_REVIEW } = OrderStatus
const { CANCELED, FROZEN } = OrderPaymentStatus

const Status = ({ isLoading, id, status, paymentStatus }) => {
  const t = useGetTranslate()
  const [isOpenRejectModal, openRejectModal, closeRejectModal] = useModal(false)

  const handleAccept = useAcceptOrderRequest()
  const handleReject = useRejectOrderRequest()
  const load = useGlobalIsLoading()
  const isOptionUnderReview = isEqual(status, UNDER_REVIEW)
  const isCanceled = isEqual(paymentStatus, CANCELED)
  const showStatus = includes(status, [APPROVED, REJECTED, BOOKED, COMPLETED, CLOSED, CANCELED, ADMIN_REVIEW])

  const isShowRejected = isOptionUnderReview && isCanceled
  const isShowActions = isOptionUnderReview && isEqual(paymentStatus, FROZEN)


  if (isLoading) return <Loader />

  //TODO Find better solution to handle stripe response
  const formattedStatus = isShowRejected ? REJECTED : status

  return (
    load ?
      <div className="action-cell">
        <StatusLoader size="20" fill="#636583" />
      </div> :
      <>
        {isShowActions &&
        <div className="action-cell">
          <Button
            text={t('order-details.actions.accept')}
            typeButton="button"
            handleOnClick={() => handleAccept(id)}
            paddingVertical={4}
            fontSize="0.75"
            loader={load}
          />
          <Button
            primary
            text={t('order-details.actions.cancel')}
            typeButton="button"
            handleOnClick={() => openRejectModal()}
            paddingVertical={4}
            fontSize="0.75"
          />
          <RejectModal
            orderId={id}
            isOpen={isOpenRejectModal}
            onClose={closeRejectModal}
            rejectHandler={handleReject}
          />
        </div>
        }
        {(isShowRejected || showStatus) &&
        <CommonStatus
          className='centered'
          isLoading={isLoading}
          status={formattedStatus}
          config={get(statuses, formattedStatus, '')}
        />
        }
      </>
  )
}

export default Status
