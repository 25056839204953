import React from 'react'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import get from 'lodash/get'

import { COMMERCIAL_ENGAGEMENTS, REQUEST } from '_legacy/constants/routes'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Button from '_legacy/components/Button/BookNowButton'
import CommonPrice from '_legacy/components/Price'

const Price = ({ currentCommercialVideoMessage }) => {
  const t = useGetTranslate();
  const { talentNickname, commercialVideoId } = useParams()
  const price = get(currentCommercialVideoMessage[0], 'price')
  return (
    <PriceContainer>
      <PriceInner>
        <PriceLabel>Price</PriceLabel>
        <CommonPrice price={price} withCurrency />
      </PriceInner>
      <Button
        isLink
        text={t('shared.words.book-now')}
        paddingVertical={13}
        paddingHorizontal={24}
        path={`/${talentNickname}/${COMMERCIAL_ENGAGEMENTS}/${REQUEST}/${commercialVideoId}`}
      />
    </PriceContainer>
  )
}

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: start;
    margin-bottom: 16px;
  }

  @media screen and (max-width: 767px) {
    text-align: center;
    align-items: center;
    margin-bottom: 24px;
  }
`

const PriceInner = styled.div`
  ._price__value {
    font-size: 45px;
    line-height: 55px;

    @media screen and (max-width: 991px) {
      margin-bottom: 24px;
    }
  }
`

const PriceLabel = styled.p`
  font-family: 'Playfair Display', sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 29px;

  color: ${({ theme }) => theme.colors.blue};

  margin-bottom: 6px;
`

export default Price
