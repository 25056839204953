import React from 'react'
import { notify } from 'utils/notify'

import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import * as r from '_legacy/constants/routes'
import Button from './Button'

import { hashHistory } from 'utils/history'


const BookNowButton = ({ handleOnClick, text, ...rest }) => {
  const t = useGetTranslate()
  const isUserLoggedIn = useIsUserLoggedIn()
  const _text = text || t('shared.words.book-now')

  const handleRedirect = () => {
    notify(t('book.notify.login'), 'error')
    hashHistory.push(`${r.HASH_LOGIN}`)
  }

  const props = !isUserLoggedIn
    ? { ...rest, text: _text, handleOnClick: handleRedirect, typeButton: 'button', isLink: false }
    : { ...rest, text: _text, handleOnClick  }

  return <Button {...props} />
}

BookNowButton.defaultProps = {
  paddingVertical: 13,
  paddingHorizontal: 30,
}

export default BookNowButton
