// Modules
import React from 'react'
import { TalentOfferPendingActionType as T } from 'shared'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getUserTimeZone } from 'utils/user'
import { getShortMonthDate } from 'utils/date'
import { parseDate } from 'utils/services/timezone'

// Constants
import { currentActionsTitles } from './config'


const PendingAction = ({ action, title }) => {
  const t = useGetTranslate()
  const timeZone = useUser(getUserTimeZone)

  const { due, type } = action

  const pendingActionTitle = type === T.HOLD ?
    `${t('my-orders.current-action.status.host')} ${title}`
    : t(currentActionsTitles[type])

  const formattedDue = getShortMonthDate(parseDate(due, timeZone))

  return (
    <div className="order-pending-action">
      <div className="order-pending-action__name">
        <p className="order-pending-action__title">{t('table.header.current-pending-actions')}</p>
        <p className="order-pending-action__value">{pendingActionTitle}</p>
      </div>
      <div className="order-pending-action__date">
        <p className="order-pending-action__title">{t('my-orders.current-action.due')}</p>
        <p className="order-pending-action__value">{formattedDue}</p>
      </div>
    </div>
  )
}

export default PendingAction
