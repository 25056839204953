import styled from 'styled-components';
import PropTypes from 'prop-types';

export const Title = styled.h2`
  font-size: 60px;
  font-weight: 900;
  font-family: 'Playfair Display', sans-serif;
  line-height: 1.25;
  color: ${({ theme: { colors } }) => colors.blue};
  margin: 0 auto;
  text-align: center;
  @media only screen and (max-width: 768px) {
    font-size: 48px;
  }
  @media only screen and (max-width: 575px) {
    font-size: 42px;
  }
  @media only screen and (max-width: 425px) {
    font-size: 34px;
  }
  ${({ ...p }) => p}
`;

Title.propTypes = {
  fontSize: PropTypes.number,
  fontWeight: PropTypes.number,
  color: PropTypes.string,
  margin: PropTypes.string,
  textAlign: PropTypes.string,
};

