import React from 'react';
import PropTypes from 'prop-types';
import { MARGIN } from './constants';
import List from './List';

const SingleSlideList = ({ size, isMirroredInside, slide }) => {
  return (
    <List offsetX={MARGIN}>
      {React.cloneElement(slide, {
        size,
        isNext: false,
        isCurrent: true,
        isMirroredInside,
      })}
    </List>
  );
};

SingleSlideList.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMirroredInside: PropTypes.bool,
  slide: PropTypes.object,
};

export default SingleSlideList;
