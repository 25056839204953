import React from 'react';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import FormRow from '../../../components/Form/Row';
import Button from '../../../components/Button';
import NotificationsForm from './NotificationForm/NotificationsForm';

const DesktopNotificationsTemplate = ({ isLoading, config }) => {
  const t = useGetTranslate();

  return (
    <>
      <NotificationsForm config={config} />

      <FormRow
        right={
          <Button
            text={t('account.notifications.form.submit-button.label')}
            typeButton='submit'
            paddingHorizontal={55}
            classBtn='notifications-submit-btn'
            disabled={isLoading}
          />
        }
      />
    </>
  );
};

export default DesktopNotificationsTemplate;
