import React from 'react';
import PropTypes from 'prop-types';
import { types } from 'shared';
import castArray from 'lodash/castArray'
import compact from 'lodash/compact'

import FileList from '../../components/Library/FileList';
import RadioListItem from './RadioListItem';

const { VIDEO, PHOTO } = types.file.Types

const SingleSelectFileList = React.memo(({ name, enableTypes }) => {
  const isDisabled = (file) => {
    const _enableTypes = compact(castArray(enableTypes))
    if (_enableTypes.length) {
      return !_enableTypes.includes(file.type)
    }
    return false
  }

  return (
    <FileList enableTypes={enableTypes}>
      {file => <RadioListItem key={file.id} file={file} name={name} disabled={isDisabled(file)} />}
    </FileList>
  );
});

SingleSelectFileList.propTypes = {
  name: PropTypes.string,
  enableTypes: PropTypes.oneOf([VIDEO, PHOTO]),
};

export default SingleSelectFileList;
