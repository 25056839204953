import React from 'react'
import { useParams } from 'react-router-dom'
import isFunction from 'lodash/isFunction'
import { BulkOrdersAction } from 'shared'
import { notify } from 'utils/notify'

import {
  getVideoVoiceOverLesson,
  submitVideoVoiceOverLesson,
  uploadFileToVideoVoiceOverLesson,
  orderRequestAction,
} from './api'

import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { useSetVideoVoiceOverLessonOrderDetails } from 'store/hooks/globalState/useVideoVoiceOverLessonOrderDetails'
import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

export function useFetchVideoVoiceOverLessonOrderDetails() {
  const { orderId: occurrenceId } = useParams()
  const addFilesToLibrary = useAddFilesToLibrary()

  const setOrderDetails = useSetVideoVoiceOverLessonOrderDetails((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getVideoVoiceOverLesson({ occurrenceId })
      .then((details) => {
        setOrderDetails(details)
        addFilesToLibrary(details.uploads)
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [addFilesToLibrary, occurrenceId, setOrderDetails])
}

export function useUploadVideos() {
  const { orderId: occurrenceId } = useParams()
  const fetchOccurrence = useFetchVideoVoiceOverLessonOrderDetails()

  return React.useCallback((data) => {
    uploadFileToVideoVoiceOverLesson({ occurrenceId, data })
      .then(() => {
        fetchOccurrence()
      })
      .catch()
  }, [occurrenceId, fetchOccurrence])
}

export function useSubmitAnswer(onSuccess) {
  const { orderId: occurrenceId } = useParams()
  const fetchOccurrence = useFetchVideoVoiceOverLessonOrderDetails()

  return React.useCallback(() => {
    submitVideoVoiceOverLesson({ occurrenceId })
      .then(() => {
        fetchOccurrence()

        if (isFunction(onSuccess)) onSuccess()
      })
      .catch(console.warn)
  }, [occurrenceId, fetchOccurrence])
}

export function useCancelOrder() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchVideoVoiceOverLessonOrderDetails = useFetchVideoVoiceOverLessonOrderDetails()

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message
    }

    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.notification.cancelled'))
        fetchVideoVoiceOverLessonOrderDetails()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t, fetchVideoVoiceOverLessonOrderDetails]
  )
}
