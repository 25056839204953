import * as passwordValidator from 'password-validator';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import compact from 'lodash/compact';
import {
  checkPasswordForChange as checkPasswordForChangeApi,
  checkPasswordForReset as checkPasswordForResetApi,
} from '../../../api/appApi/auth';

const errorKeys = {
  minLength: 'minLength',
  hasUppercase: 'hasUppercase',
  hasLowercase: 'hasLowercase',
  hasDigits: 'hasDigits',
  hasPartOfCred: 'hasPartOfCred',
  same: 'same',
};

export const minLength = password => {
  const passwordValidatorSchema = new passwordValidator();
  passwordValidatorSchema.is().min(12);
  return passwordValidatorSchema.validate(password, { list: true }).length > 0;
};

export const hasUppercase = password => {
  const passwordValidatorSchema = new passwordValidator();
  passwordValidatorSchema.is().has().uppercase();
  return passwordValidatorSchema.validate(password, { list: true }).length > 0;
};

export const hasLowercase = password => {
  const passwordValidatorSchema = new passwordValidator();
  passwordValidatorSchema.is().has().lowercase();
  return passwordValidatorSchema.validate(password, { list: true }).length > 0;
};

export const hasDigits = password => {
  const passwordValidatorSchema = new passwordValidator();
  passwordValidatorSchema.is().has().digits(1);
  return passwordValidatorSchema.validate(password, { list: true }).length > 0;
};

export const containForbiddenWord = (password, forbiddenWords) => {
  return find(compact(forbiddenWords),
    s => password.toLowerCase().indexOf(s.toLowerCase()) !== -1
  );
};

export const checkPasswordForReset = debounce(async (password, resetPasswordKey) => {
  try {
    await checkPasswordForResetApi({ data: { password }, resetPasswordKey });
    return true;
  } catch {
    return false;
  }
},
1200,
{ leading: true },
);

export const checkPasswordForChange = debounce(async (password) => {
  try {
    await checkPasswordForChangeApi({ data: { password } });
    return true;
  } catch {
    return false;
  }
},
1200,
{ leading: true },
);

export const getBasicValidationConfig = () => ({
  [errorKeys.minLength]: v => !minLength(v),
  [errorKeys.hasUppercase]: v => !hasUppercase(v),
  [errorKeys.hasLowercase]: v => !hasLowercase(v),
  [errorKeys.hasDigits]: v => !hasDigits(v),
});

export const getAsyncWithOldPasswordValidationConfig = (oldPassword) => ({
  ...getBasicValidationConfig(),
  [errorKeys.hasPartOfCred]: checkPasswordForChange,
  [errorKeys.same]: password => password !== oldPassword
});

export const getAsyncValidationConfigForReset = (resetPasswordKey) => ({
  ...getBasicValidationConfig(),
  [errorKeys.hasPartOfCred]: v => checkPasswordForReset(v, resetPasswordKey)
});

export const getValidationConfig = (forbiddenWords = []) => ({
  ...getBasicValidationConfig(),
  [errorKeys.hasPartOfCred]: v => !containForbiddenWord(v, forbiddenWords),
});

export const basicErrorsConfig = [
  { key: errorKeys.minLength, message: 'shared.password-block.errors.length' },
  { key: errorKeys.hasUppercase, message: 'shared.password-block.errors.uppercase' },
  { key: errorKeys.hasLowercase, message: 'shared.password-block.errors.lowercase' },
  { key: errorKeys.hasDigits, message: 'shared.password-block.errors.digits' },
];

export const errorsConfigWithPartOfCred = [
  ...basicErrorsConfig,
  { key: errorKeys.hasPartOfCred, message: 'shared.password-block.errors.forbidden' },
];

export const errorsConfigWithSamePassword = [
  ...basicErrorsConfig,
  { key: errorKeys.same, message: 'shared.password-block.errors.same' },
];

export const errorsConfigForResetPassword = [
  ...basicErrorsConfig,
  { key: errorKeys.same, message: 'shared.password-block.errors.same' },
  { key: errorKeys.hasPartOfCred, message: 'shared.password-block.errors.forbidden' },
];
