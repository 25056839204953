import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { Container, Title, SubTitle } from '_legacy/common'
import RegistrationFormAficionado from '_legacy/components/RegisterForms/Aficionado'

const RegistrationAficionado = () => {
  const t = useGetTranslate()

  return (
    <StyledWrapper>
      <Container>
        <SubTitle className="sub-title">{t('register-aficionado.page.sub-header')}</SubTitle>
        <Title className="title">{t('register-aficionado.page.header')}</Title>
        <RegistrationFormAficionado />
      </Container>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .sub-title {
    margin-bottom: 10px;
  }
  .title {
    margin-bottom: 50px;
  }
  .radio-wrapper {
    display: flex;
    align-items: center;
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: ${({ theme }) => theme.colors.grey};
    line-height: 1.625;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export default RegistrationAficionado
