import React from 'react'

import Rating from '_legacy/components/Rating'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

export default React.memo(function Review({ isLoading, rating, description }) {
  if (isLoading) return <Loader className="loader" />

  return (
    <div className="table-review">
      <Rating rating={rating} />
      <p className="table-review__comment">{description}</p>
    </div>
  )
})
