import React from 'react'
import axios from 'axios'

import normalizeParams from 'utils/normalizeParams'
import { getUsers as getUsersApi } from 'api/appApi/search/users'

import { useSetIsLoadingChatsList } from 'store/hooks/globalState/useChats'
import { useSetSearchAficionados } from 'store/hooks/globalState/useSearchList'

export const getSource = () => {
  const CancelToken = axios.CancelToken
  return CancelToken.source()
}

export function useFetchAficionadosSearchItems() {
  const setSearchList = useSetSearchAficionados((prev, next = []) => ({
    ...prev,
    list: next,
  }))

  const setIsLoading = useSetIsLoadingChatsList((prev, next) => next)

  return React.useCallback(
    ({ ...query }) => {
      const params = normalizeParams(query)

      setIsLoading(true)

      getUsersApi(params).then(({ items = [] }) => {
        setSearchList(items)

        setIsLoading(false)
      })
    },
    [setSearchList]
  )
}
