import React from 'react'
import './index.scss'

import EntouragePublicPage from './components/EntouragePublicPage'

import { upgradePageConfig, statusKeys } from './configs/config'

const EntourageUpgradePage = React.memo(() => {
  return <EntouragePublicPage pageConfig={upgradePageConfig} initialStatus={statusKeys.upgrade} />
})

export default EntourageUpgradePage
