import React from 'react'
import PropTypes from 'prop-types'
import debounce from 'lodash/debounce'
import cx from 'classnames'

import { ReactComponent as FollowIcon } from 'assets/follow-icon.svg'
import { useUpdateFollowOffering, useUpdateFollowTalent } from 'requests/follow'
import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser'

const REQUEST_TYPES = {
  TALENT: 'TALENT',
  OFFERING: 'OFFERING',
}


const FollowButton = ({
  id,
  type,
  isFollowed,
  text,
  className = '',
}) => {
  const [localFollowed, setLocalFollowed] = React.useState(isFollowed)

  const isLoggedId = useIsUserLoggedIn()

  const updateFollowTalent = useUpdateFollowTalent(setLocalFollowed)
  const updateFollowOffering = useUpdateFollowOffering()
  const request = type === REQUEST_TYPES.TALENT ? updateFollowTalent : updateFollowOffering

  const debouncedRequest = React.useCallback(debounce(request, 1000), [])

  const handleFollow = e => {
    e.preventDefault()
    setLocalFollowed(prev => !prev)
    debouncedRequest({ id, isFollowed: localFollowed })
  }

  const classList = cx(
    'follow-button',
    className,
    { 'active': localFollowed },
  )

  if (!isLoggedId) return null

  return (
    <button
      type="button"
      className={classList}
      onClick={handleFollow}
    >
      <FollowIcon className='follow-button__icon' />

      {text &&
        <span className='follow-button__text'>{text}</span>
      }
    </button>
  )
}

FollowButton.propTypes = {
  id: PropTypes.string,
  type: PropTypes.oneOf([REQUEST_TYPES.TALENT, REQUEST_TYPES.OFFERING]),
  isFollowed: PropTypes.bool,
  text: PropTypes.string,
  className: PropTypes.string,
}

export default FollowButton
