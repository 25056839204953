import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import { ErrorMessage } from '_legacy/common/ErrorMessage'
import { useFormContext } from 'react-hook-form'
import cond from 'lodash/cond'
import matches from 'lodash/matches'
import constant from 'lodash/constant'
import stubTrue from 'lodash/stubTrue'

const TextArea = ({
  name,
  placeholder,
  maxLength = 150,
  tip,
  isRequired,
  className = '',
  requiredMessage,
}) => {
  const t = useGetTranslate()
  const { register, watch, errors } = useFormContext()
  const value = watch(name, '')
  const error = get(errors, name)

  const errorMessageRequired = requiredMessage
    ? requiredMessage
    : 'shared.words.required'

  return (
    <StyledWrapper className={className}>
      <StyledTextarea
        className="textarea"
        placeholder={t(placeholder)}
        name={name}
        ref={register({
          required: isRequired && errorMessageRequired,
          maxLength,
        })}
        error={Boolean(error)}
      />
      <StyledBottom>
        {maxLength && (
          <StyledLength>
            {value?.length} / {maxLength}{' '}
            {tip || t('shared.words.character.plural')}
          </StyledLength>
        )}
        {error && <ErrorMessage message={error.message} className="error" />}
      </StyledBottom>
    </StyledWrapper>
  )
}

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')],
])

const StyledWrapper = styled.div`
  position: relative;
`

const StyledTextarea = styled.textarea`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 239px;
  background-color: rgba(255, 255, 255, 0);
  border-radius: 4px;
  border: 2px solid ${getBorderColor};
  resize: none;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.7;
  font-family: 'Montserrat', sans-serif;
  padding: 14px 18px;
  @media only screen and (max-width: 425px) {
    height: 160px;
  }

  &::placeholder {
    color: #9495a7;
  }
`
const StyledLength = styled.span`
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  color: #272835;
  margin-left: auto;
  flex-shrink: 0;
  text-transform: lowercase;
`

const StyledBottom = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 4px;
`;

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  tip: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredMessage: PropTypes.string,
}

export default TextArea
