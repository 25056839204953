import React from 'react'
import { isEmpty, isNil, head } from 'lodash/fp'
import { useParams } from 'react-router-dom'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'
import Icon from 'assets/icons/Icon'

import { getMonthDayYearDate } from 'utils/date'
import { getOfferTranslateKey, isChosenLocationOfferType, isOnlineOfferType } from 'utils/offer'
import { getFirstLocationSubtitle, getFirstLocationTitle, getLocationTimeZone } from 'utils/location'
import Button from '_legacy/components/Button'
import { getPublicPageRoute } from 'router/routesMap'

// todo: move to new structure
import Drawer from 'components/Calendar/components/Drawer'
import { getCalendarTime } from 'components/Calendar/utils/getCalendarDateTime'
import { getSlotBackground } from 'components/Calendar/BaseCalendar/configs/slot-config'
import AddToCalendarButton from '../AddToCalendarButton'
import { useUser } from 'store/hooks/globalState/useUser'
import { getUserTimeZone } from 'utils/user'
import { fromSToM } from 'utils/convertTime'
import { getTimezoneAbbr, parseDate } from 'utils/services/timezone'

const SelectedSlotSidebar = ({ isOpen, close, selectedEvent, hour12Format, showAddToCalendarButton = false }) => {
  const t = useGetTranslate()
  const { talentNickname } = useParams()
  const userTimeZone = useUser(getUserTimeZone)

  const { extendedProps } = selectedEvent || {}
  const { occurrence, beforeDriveTime, afterDriveTime } = extendedProps || {}
  const {
    offerType,
    title,
    locations,
    participantsCount,
    maxParticipantsCount,
    price,
    offerSequentialId,
    startTime: _startTime,
    endTime: _endTime,
    talent
  } = occurrence || {}

  const timeZone = isChosenLocationOfferType(offerType) ? getLocationTimeZone(head(locations)) : userTimeZone
  const timeZoneAbbr = getTimezoneAbbr(timeZone)

  const startTime = parseDate(_startTime, timeZone)
  const endTime = parseDate(_endTime, timeZone)

  const date = getMonthDayYearDate(startTime)
  const getTime = getCalendarTime(hour12Format)
  const duration = `${getTime(startTime)} - ${getTime(endTime)} (${timeZoneAbbr})`

  const options = [
    {
      shouldShow: Boolean(date),
      icon: <Icon.Calendar />,
      item: date,
      subitem: duration,
    },
    {
      shouldShow: !!beforeDriveTime || !!afterDriveTime,
      icon: <Icon.DrivingTimeOutlined />,
      item: `${fromSToM(beforeDriveTime || 0)} min / ${fromSToM(afterDriveTime || 0)} min`,
      subitem: t('calendar.info-sidebar.title.driving-time'),
    },
    {
      shouldShow: isChosenLocationOfferType(offerType) && !isEmpty(locations),
      icon: <Icon.Pin />,
      item: getFirstLocationTitle(locations),
      subitem: getFirstLocationSubtitle(locations),
    },
    {
      shouldShow: !isNil(participantsCount) && !isNil(maxParticipantsCount),
      icon: <Icon.Participants />,
      item: `${participantsCount} of ${maxParticipantsCount}`,
    },
    {
      shouldShow: !isNil(price),
      icon: <Icon.Pricetag />,
      item: `$${price} ${t('currency.usd')}`,
      subitem: t('order-details.price-per-person'),
    },
    {
      shouldShow: isOnlineOfferType(offerType),
      icon: <Icon.Pin />,
      item: t('recurring-list.locations.online'),
    },
  ].filter(option => option.shouldShow)

  return (
    <Drawer isOpen={isOpen} close={close} headerLabel={t(getOfferTranslateKey(offerType))}>
      <div className="calendar-sidebar">
        <div className="calendar-sidebar-heading">
          <span style={{ backgroundColor: getSlotBackground(offerType) }} />
          <h6>{title || t('calendar.slot.default-title')}</h6>
        </div>
        {options.map((option, i) => (
          <OfferingsDetailsHeaderItem key={i} icon={option.icon} item={option.item} subitem={option.subitem} />
        ))}
        <Button
          isLink
          stretch
          paddingVertical="10"
          paddingHorizontal="10"
          text={t('calendar.new-event-form.button.more-info')}
          path={getPublicPageRoute(offerType, talentNickname || talent?.plaqueNickname, offerSequentialId)}
        />
        {showAddToCalendarButton && (
          <AddToCalendarButton
            startTime={startTime && startTime.toLocaleString()}
            endTime={endTime && endTime.toLocaleString()}
            title={title}
            location={getFirstLocationTitle(locations)}
            timeZone={timeZone}
          />
        )}
      </div>
    </Drawer>
  )
}

export default SelectedSlotSidebar
