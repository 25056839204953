import React from 'react'

const INITIAL_STATE = {
  showWeekends: true,
}


export const withShowWeekends = CalendarComponent => ({
  talentId,
  openListOptions = [],
  ...restProps
}) => {
  const [showWeekends, setShowWeekends] = React.useState(INITIAL_STATE.showWeekends)
  const toggleShowWeekends = React.useCallback(() => setShowWeekends(current => !current), [])

  const openListOptionsWithShowWeekends = [...openListOptions,
    { label: 'calendar.dot-button.show-hide-weekends', cb: toggleShowWeekends },
  ]

  React.useEffect(() => {
    setShowWeekends(INITIAL_STATE.showWeekends)
  }, [talentId])

  return (
    <CalendarComponent
      weekends={showWeekends}
      openListOptions={openListOptionsWithShowWeekends}
      { ...restProps }
    />
  )
}
