import React from 'react';
import styled from 'styled-components'

import TopVideoMessages from './TopVideoMessages'
import TopExperiences from './TopExperiences'
import ArrowScrollDown from '../ArrowScrollDown'

export const WrapedTopVideoMessages = React.memo(({ setActiveSection }) => {
  return (
    <StyledSection className="section">
      <StyledWrapper>
        <TopVideoMessages />
      </StyledWrapper>
      <ArrowScrollDown clickHandler={() => setActiveSection(2)} />
    </StyledSection>
  );
})

export const WrapedTopExperiences = React.memo(({ setActiveSection }) => {
  return (
    <StyledSection className="section">
      <StyledWrapper>
        <TopExperiences />
      </StyledWrapper>
      <ArrowScrollDown clickHandler={() => setActiveSection(3)} />
    </StyledSection>
  )
})

export const WrapedBothSliders = React.memo(({ setActiveSection }) => {
  return (
    <StyledSection className="section">
      <StyledWrapper>
        <TopVideoMessages />
        <TopExperiences />
      </StyledWrapper>
      <ArrowScrollDown clickHandler={() => setActiveSection(2)} />
    </StyledSection>
  )
})

const StyledSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
`

const StyledWrapper = styled.div`
  flex-grow: 1;
  width: 100%;
  max-width: 1440px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  @media only screen and (max-width: 1440px) {
    padding: 0 40px;
  }
  @media only screen and (max-width: 1024px) {
    padding: 0;
  }
`
