import React from 'react'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Price from '_legacy/components/Price'
import FilePreview from '_legacy/components/Library/components/Library/FilePreview'

const OrderExpected = ({ media, received }) => {
  const t = useGetTranslate()

  return (
    <div className="od__answer-grid mb-24">
      <FilePreview file={media} className="od__answer-media" />
      <div className="od__column">
        <h6 className="od--main-text mb-12">
          {t('order.answer.label.payment-expected')}
        </h6>
        <Price className="od--status-title mb-16" price={received} withCurrency />

        <p className="od--main-text">
          {t('order.label.finalized')}
        </p>
      </div>
    </div>
  )
}

export default OrderExpected
