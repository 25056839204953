import React from 'react';
import { useParams } from 'react-router';

import {
  fetchBookingsDetails as fetchHeading
} from 'components/OfferingDetails/BookingsDetails/state/api.js'

import {
  useSetLiveVirtualLessonBookingsDetails as useSetHeading
} from './store.js'
import { useUser } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getUserId } from 'utils/user'

export function useFetchLiveVirtualLessonBookingsDetails() {
  const { orderId } = useParams()
  const userId = useUser(getUserId)

  const setHeading = useSetHeading((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchHeading({ userId, orderId })
      .then(setHeading)
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [userId, orderId, setHeading])
}
