import { pipe, cond, always, merge, T } from 'lodash/fp'
import { statuses } from '../utils'


const fillLiveVirtualLessonBookingConfig = merge({
  isShowCounter: false,
  isShowContact: false,

  isShowCanceled: false,
  isShowCompleted: false,
})

export const getLiveVirtualLessonBookingConfig = pipe(
  cond([
    [statuses.isBookedWithTime, always({ isShowCounter: true })],
    [statuses.isBookedWithoutTime, always({ isShowContact: true })],

    [statuses.isOrderCompletedOrClosed, always({ isShowCompleted: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [T, always({})],
  ]),
  fillLiveVirtualLessonBookingConfig
)
