import { io } from 'socket.io-client'

const { error, debug } = console

const SOCKET_SERVER_URL = process.env.REACT_APP_API_URL
const SOCKET_AUTH_TOKEN = process.env.REACT_APP_SOCKET_AUTH_TOKEN
const CONNECT_EVENT = 'connect'
const DISCONNECT_EVENT = 'disconnect'

export class IDVerification {
  constructor() {
    if (IDVerification._instance) {
      return IDVerification._instance
    }

    IDVerification._instance = this

    this._socket = null
  }

  connect() {
    this._socket = io(SOCKET_SERVER_URL, { autoConnect: false, auth: { token: SOCKET_AUTH_TOKEN } })

    try {
      this._socket.removeAllListeners(CONNECT_EVENT)
      this._socket.on(CONNECT_EVENT, () => {
        debug('IDVerification::Connected', this._socket.id)

        this._socket.removeAllListeners(DISCONNECT_EVENT)
        this._socket.on(DISCONNECT_EVENT, () => {
          debug('IDVerification::Disconnected')
        })
      })

      this._socket.connect()
    } catch (e) {
      error('IDVerification::Error', e)
    }

    return this
  }

  backgroundCheck({ checkId, setCheckResult }) {

    const callbackHandler = (...args) => {
      debug('IDVerification::Notification', ...args)
      try {
        setCheckResult.call(this, ...args)
      } catch (e) {
        error('IDVerification::Error', e)
      }
    }

    try {
      if (checkId) {
        debug('IDVerification::Joining Check', checkId)
      }
      this._socket.on(checkId, callbackHandler)
      this._socket.on('error', e => error('Socket::Error', e))
    } catch (e) {
      error('IDVerification::Error', e)
    }

    return this
  }

  disconnect() {
    try {
      if (this._socket)
        this._socket.disconnect()
    } catch (e) {
      error('IDVerification::Error', e)
    }

    return this
  }
}
