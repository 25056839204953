import React from 'react'
import PropTypes from 'prop-types'
import { types } from 'shared'

import CustomPicture from '_legacy/common/CustomPicture'
import { CustomVideoPlayer } from '_legacy/common/custom-video/CustomVideoPlayer/'

const { PHOTO, VIDEO } = types.file.Types

const MediaPlayer = ({ media, className }) => {
  if (media.type === VIDEO) {
    return <CustomVideoPlayer file={media} className={className} />
  }
  if (media.type === PHOTO) {
    return <CustomPicture file={media} className={className} />
  }

  return null
}

MediaPlayer.propTypes = {
  media: PropTypes.object,
  className: PropTypes.string,
}

export default MediaPlayer
