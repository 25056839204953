import get from 'lodash/get'
import { addWeeks, subWeeks, isPast } from 'date-fns'
import { TalentOffersType, CalendarSlotType } from 'shared'
import { cond, map, T, pipe, prop, pick, eq, merge, propEq, uniqueId, compose } from 'lodash/fp'

import { getSlotBackground, slotBackground } from '../../Calendar/BaseCalendar/configs/slot-config'
import { isChosenLocationOfferType } from 'utils/offer'
import { getLocationTimeZone } from 'utils/location'
import { parseDate } from 'utils/services/timezone'

const AVAILABLE_SLOTS_GROUP = 'AVAILABLE_SLOTS_GROUP'

export const slotTypes = {
  lock: 'LOCK',
  offer: 'OFFER',
  order: 'ORDER',
  background: 'BACKGROUND',
}

export const slotsServerAdapter = map(slot => ({
  ...slot,
  start: slot.startTime,
  end: slot.endTime,
}))

const allLessons = [
  TalentOffersType.VIRTUAL_LESSON,
  TalentOffersType.READY_MADE_LESSON,
  TalentOffersType.LIVE_IN_PERSON_LESSON,
]

export const queryNormalizer = ({ occurrenceTypeIn, typeIn, ...props }) => {
  const typeInValue = (typeIn || []).filter(type => type !== 'SYNCED_CALENDAR')
  let occurrenceTypeInValue = occurrenceTypeIn || []

  if (occurrenceTypeIn.includes('LESSONS')) {
    occurrenceTypeInValue = occurrenceTypeInValue.filter(i => i !== 'LESSONS')
    occurrenceTypeInValue = [...allLessons, ...occurrenceTypeInValue]
  }

  return {
    ...props,
    typeIn: typeInValue,
    occurrenceTypeIn: occurrenceTypeInValue,
    startTime: subWeeks(props.startTime, 1),
    endTime: addWeeks(props.endTime, 1),
  }
}

export const businessHoursServerAdapter = calendar => {
  const startTime = get(calendar, 'settings.workingHours.from')
  const endTime = get(calendar, 'settings.workingHours.to')
  const daysOfWeek = get(calendar, 'settings.workingHours.on')

  return daysOfWeek?.length && { startTime, endTime, daysOfWeek }
}

const getSlotColor = cond([
  [propEq('type', 'OFFER'), compose(getSlotBackground, prop('occurrence.offerType'))],
  [propEq('type', 'MY_EVENT'), () => slotBackground[CalendarSlotType.MY_EVENT]],
  [propEq('type', 'TIME_UNAVAILABLE'), () => slotBackground[CalendarSlotType.TIME_UNAVAILABLE]],
])

// ****** talent public view calendar ******
const isPastSlot = pipe(prop('start'), date => new Date(date), isPast)
const isPersonalTime = propEq('type', CalendarSlotType.MY_EVENT)
const isUnavailableTime = propEq('type', CalendarSlotType.TIME_UNAVAILABLE)
const isHasNotEnableSlots = pipe(
  prop('occurrence'),
  pick(['maxParticipantsCount', 'participantsCount']),
  ({ maxParticipantsCount, participantsCount }) => maxParticipantsCount - participantsCount,
  eq(0)
)

const withOffer = slot => ({
  slotType: slotTypes.offer,
  borderColor: getSlotBackground(slot.occurrence?.offerType),
  backgroundColor: getSlotBackground(slot.occurrence?.offerType),
})
const eventLockAdapter = merge({
  title: '',
  locked: true,
  slotType: slotTypes.lock,
  backgroundColor: slotBackground.LOCK,
  borderColor: slotBackground.LOCK,
})

export const talentPublicViewEvent = cond([
  [isHasNotEnableSlots, eventLockAdapter],
  [isPastSlot, eventLockAdapter],
  [isPersonalTime, eventLockAdapter],
  [isUnavailableTime, eventLockAdapter],
  [T, withOffer],
])

export const toTalentPublicViewCalendarEvents = map(slot => ({
  ...slot,
  ...talentPublicViewEvent(slot),
  editable: false,
}))

// ****** order page calendar ******
export const toOrderPageCalendarEvents = map(slot => ({
  ...slot,
  ...withOffer(slot),
  editable: false,
}))

// ****** user calendar ******
const withZonedTime = (slot) => {
  const timezone = isChosenLocationOfferType(slot.occurrence.offerType)
    ? getLocationTimeZone(slot.occurrence?.locations?.[0])
    : slot.userTimezone

  return {
    start: parseDate(slot.start, timezone),
    end: parseDate(slot.end, timezone)
  }
}

export const toUserCalendarEvents = map(slot => ({
  ...slot,
  ...withOffer(slot),
  ...withZonedTime(slot),
  editable: false,
  afterDriveTime: undefined,
  beforeDriveTime: undefined,
  borderColor: getSlotBackground(slot.occurrence?.offerType),
  backgroundColor: getSlotBackground(slot.occurrence?.offerType),
}))

// ****** talent calendar ******
export const toDraftEvent = slot => ({
  ...slot,
  editable: true,
  draft: true,
  id: uniqueId('time-slot-'),
  overlap: false,
  backgroundColor: getSlotColor(slot),
  borderColor: getSlotColor(slot),
})

// ****** booking calendar ******
export const toBookEvent = slot => ({
  title: slot?.title,
  start: slot?.start,
  end: slot?.end,
  color: slotBackground.AFICIONADO,
  editable: true,
  overlap: true,
  durationEditable: false,
  aficionadoChoose: true,
  constraint: AVAILABLE_SLOTS_GROUP,
  slotType: slotTypes.order,
})

export const toBackgroundEvents = map((slot) => ({
  ...slot,
  title: ' ',
  id: uniqueId('available-slots-'),
  groupId: AVAILABLE_SLOTS_GROUP,
  display: 'background',
  overlap: true,
  editable: false,
  slotType: slotTypes.background,
}))

export const toLockEvents = map((slot) => ({
  ...slot,
  backgroundColor: slotBackground.LOCK,
  borderColor: slotBackground.LOCK,
  editable: false,
  slotType: slotTypes.lock,
}))
