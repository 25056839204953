import { _get, _patch, _post } from '../../utils';

export const getTalents = ({ name, page = 1, limit = 10 }) => _get('/talents', { query: { name, page, limit } });

export const acceptCommercialEngagement = ({ talentId, data }) => _post(`/talents/${talentId}/commercial-engagements`, { body: data });

export const getEditTalentData = ({ talentId }) => _get(`talents/${talentId}/edit`, { needAuthorization: true });
export const editTalent = ({ talentId, body }) => _patch(`talents/${talentId}`, { needAuthorization: true, body });

export const removeAccount = (data) => _post('/auth/me/requests/remove-accounts', { body: data, needAuthorization: true })

export const verifyRemoveAccount = (removeAccountKey) => _post(`/verify/remove-account/confirm/${removeAccountKey}`)
