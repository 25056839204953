import React from 'react'
import { last, get } from 'lodash'
import { TalentOffersType } from 'shared'
import { addWeeks, subWeeks } from 'date-fns'

import {
  getTalentsCalendarSlots as fetchTalentsCalendarApi,
  getTalentsCalendar as getTalentsCalendarApi
} from '../api/calendar'

import { useUser } from 'store/hooks/globalState/useUser'

import { useSetTalentCalendarBusinessHours, useSetTalentCalendarSlots } from '../store/useCalendar'
import { getSource } from 'requests/upload'
import {
  applyDriveTime,
  applyTimeZone,
  getValidSlotsByStartEndTime,
} from 'components/Calendar/AficionadoCalendar/utils/getFreeSlots/getFreeSlots'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getUserTimeZone } from 'utils/user'

const allLessons = [
  TalentOffersType.VIRTUAL_LESSON,
  TalentOffersType.READY_MADE_LESSON,
  TalentOffersType.LIVE_IN_PERSON_LESSON,
]
export const queryNormalizer = ({ occurrenceTypeIn, typeIn, ...props }) => {
  const typeInValue = (typeIn || []).filter(type => type !== 'SYNCED_CALENDAR') // todo: implement later
  let occurrenceTypeInValue = occurrenceTypeIn || []

  if (occurrenceTypeIn.includes('LESSONS')) {
    occurrenceTypeInValue = occurrenceTypeInValue.filter(i => i !== 'LESSONS')
    occurrenceTypeInValue = [...allLessons, ...occurrenceTypeInValue]
  }

  return {
    ...props,
    typeIn: typeInValue,
    occurrenceTypeIn: occurrenceTypeInValue,
    startTime: subWeeks(props.startTime, 1),
    endTime: addWeeks(props.endTime, 1)
  }
}


export function useFetchTalentCalendar(talentId) {
  const sourcesRef = React.useRef([])
  const setTalentCalendar = useSetTalentCalendarSlots((prev, next) => next)
  const handleResponseError = useHandleResponseError()
  const userTimeZone = useUser(getUserTimeZone)

  return React.useCallback(
    filters => {

      sourcesRef.current.forEach((token) => token.cancel('FILE_ABORT_ERR'))

      const token = getSource()
      sourcesRef.current = [...sourcesRef.current, token]

      fetchTalentsCalendarApi(
        {
          talentId,
          query: { ...queryNormalizer(filters), limit: 1000, sortField: 'startTime' }
        },
        { cancelToken: last(sourcesRef.current).token }
      )
        .then(({ slots }) => {
          const validSlots = getValidSlotsByStartEndTime(slots)
          const withDriveTime = applyDriveTime(validSlots)
          const withTimeZone = applyTimeZone(withDriveTime, userTimeZone)
          setTalentCalendar(withTimeZone)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [talentId, setTalentCalendar, sourcesRef]
  )
}


export function useFetchBusinessHours(talentId) {
  const setTalentCalendarBusinessHours = useSetTalentCalendarBusinessHours((prev, next) => next)

  return React.useCallback(() => {
    getTalentsCalendarApi({ talentId })
      .then(({ calendar }) => {
        setTalentCalendarBusinessHours({
          startTime: get(calendar, 'settings.workingHours.from'),
          endTime: get(calendar, 'settings.workingHours.to'),
          daysOfWeek: get(calendar, 'settings.workingHours.on')
        })
      })
  }, [setTalentCalendarBusinessHours])
}
