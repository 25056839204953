export default function getFile (data) {
  if (!data) return null;
  const { media, photo } = data;
  if (media) {
    const mainMedia = media.find(item => item.isPrimary) || media[0];
    if (!mainMedia) return null;
    return mainMedia.file;
  }
  if (photo) return photo.file;
  return null;
}
