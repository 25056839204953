import React from 'react'
import Icon from 'assets/icons/Icon'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useChatContext from '../../../../hooks/useChatContext'

export default function ChatWindowHeader() {
  const t = useGetTranslate()
  const { setIsChatWindowOpen } = useChatContext()

  return (
    <div className="chat-window-header">
      <div className="chat-window-header__text">{t('video-chat.chat-window-header.message')}</div>
      <button type="button" className="chat-window-header__btn" onClick={() => setIsChatWindowOpen(false)}>
        <Icon.Close fill="#9495A7" />
      </button>
    </div>
  )
}
