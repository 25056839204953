import { states } from '../../shared/components/MultiLocationSelect/data'

export const defaultValues = {
  type: null,
  firstName: '',
  lastName: '',
  applicantId: '',
  identityIsVerified: false,
  nickname: '',
  email: '',
  backupEmail: '',
  password: '',
  address: null,
  city: null,
  state: null,
  usaState: states[0],
  zipCode: '',
  country: '',
  profileImage: null,
  licenses: [],
  sign: '',
  notificationPreferences: {
    email: 'true',
    sms: 'true'
  },
  phone: '',
  dateOfBirth: '',

  rePassword: '',
  accept: false
};
