import React from 'react'
import styled from 'styled-components'
import { api } from 'shared'

import { filtersConfig, counterConfig, subFiltersConfig, fields } from '_legacy/configs/table/manageOfferings'
import { shouldShowEditAction } from './actions-config'
import { useModal } from 'store/hooks/useModal'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useManageOfferingsFilters,
  useSetManageOfferingsFilters,
  useManageOfferingsTable,
} from 'store/hooks/globalState/useManageOfferings'
import {
  useFetchManageOfferings,
  usePublishOffer,
  useUnpublishOffer,
  useNavigateToEditOffer,
} from 'requests/manage-offerings'

import AddOfferingButton from '_legacy/components/Offerings/AddOfferingButton'
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import {
  MANAGE_OFFERINGS,
  elementsTypes,
} from '_legacy/components/Table/table-components/types/manage-offerings/config'
import Table from '_legacy/components/Table/table-components/Table'
import DeleteOfferingModal from 'components/DeleteOfferingModal'

import {
  isPublishablePublishedOffer,
  isPublishableNotPublishedOffer,
  isEnablablePublishedOffer,
  isEnablableNotPublishedOffer,
} from 'utils/offer'

const { DATE, LOCATION, PRICE, TITLE, STATUS } = api.talents.talent.offers.query.SortField

const tableConfig = [
  {
    header: 'table.header.offering',
    field: TITLE,
    type: elementsTypes.offering,
  },
  {
    header: 'table.header.date-time',
    field: DATE,
    type: elementsTypes.dateTime,
  },
  {
    header: 'table.header.location',
    field: LOCATION,
    type: elementsTypes.location,
  },
  {
    header: 'table.header.price',
    field: PRICE,
    type: elementsTypes.price,
  },
  {
    header: 'table.header.status',
    field: STATUS,
    type: elementsTypes.status,
  },
]

export default function ManageOfferings() {
  const t = useGetTranslate()
  const publishOffer = usePublishOffer()
  const unpublishOffer = useUnpublishOffer()
  const navigateToEditOffer = useNavigateToEditOffer()
  const [isDeleteOfferingsModalOpen, openDeleteOfferingsModal, closeDeleteOfferingsModal] = useModal(false)
  const [offerIds, setOfferIds] = React.useState([])

  const handleDeleteOffering = React.useCallback(offerIds => {
    openDeleteOfferingsModal()
    setOfferIds(offerIds)
  }, [])

  const handleClose = React.useCallback(() => {
    closeDeleteOfferingsModal()
    setOfferIds([])
  }, [])

  const actionsConfig = React.useMemo(() => {
    return [
      { label: t('table.actions.publish-offer'), cbFn: publishOffer, shouldShow: isPublishableNotPublishedOffer },
      { label: t('table.actions.unpublish-offer'), cbFn: unpublishOffer, shouldShow: isPublishablePublishedOffer },
      { label: t('table.actions.enable-offer'), cbFn: publishOffer, shouldShow: isEnablableNotPublishedOffer },
      { label: t('table.actions.disable-offer'), cbFn: unpublishOffer, shouldShow: isEnablablePublishedOffer },
      { label: t('table.actions.delete-offer'), cbFn: handleDeleteOffering },
      { label: t('table.actions.edit-offer'), cbFn: navigateToEditOffer, shouldShow: shouldShowEditAction },
    ]
  }, [t, publishOffer, unpublishOffer, handleDeleteOffering])

  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useManageOfferingsFilters,
      setFilters: useSetManageOfferingsFilters,
      actions: [
        { label: t('table.actions.publish-offer'), cbFn: publishOffer },
        { label: t('table.actions.unpublish-offer'), cbFn: unpublishOffer },
        { label: t('table.actions.delete-offer'), cbFn: handleDeleteOffering },
      ],
    }
  }, [t, useManageOfferingsFilters, useSetManageOfferingsFilters, publishOffer, unpublishOffer, handleDeleteOffering])

  return (
    <>
      <StyledWrapper className="wrapper content-wrapper">
        <h1 className="title">{t('table.title.manage-my-offerings')}</h1>
        <Filters
          filtersConfig={filtersConfig}
          getFn={useManageOfferingsFilters}
          setFn={useSetManageOfferingsFilters}
          ButtonComponent={AddOfferingButton}
        />
        <SubFilters
          counterConfig={counterConfig}
          subFiltersConfig={subFiltersConfig}
          getFn={useManageOfferingsFilters}
          setFn={useSetManageOfferingsFilters}
        />
        <Table
          tableName={MANAGE_OFFERINGS}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          bulkActionsConfig={bulkActionsConfig}
          getTableFn={useManageOfferingsTable}
          fields={fields}
          getFiltersFn={useManageOfferingsFilters}
          setFiltersFn={useSetManageOfferingsFilters}
          fetchTableFn={useFetchManageOfferings}
        />
      </StyledWrapper>

      <DeleteOfferingModal
        onClose={handleClose}
        isOpen={isDeleteOfferingsModalOpen}
        offerIds={offerIds}
      />
    </>
  )
}

const StyledWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 40px;

  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: 900;
    line-height: 75px;
    color: ${({ theme: { colors } }) => colors.blue};
    text-align: center;
  }

  .row-content {
    grid-template-columns: 30fr 20fr 28fr 11fr 11fr;
    /* @media screen and (max-width: 1024px) {
      grid-template-columns: 27fr 33fr 20fr 20fr;
    } */
  }
`
