import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import isFunction from 'lodash/isFunction'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import { Row } from '_legacy/common/Template'
import Button from '_legacy/components/Button'

const SubmitButtons = ({ disabled, onDraftHandler }) => {
  const t = useGetTranslate()

  return (
    <Row
      className={'submit-button'}
      component={
        <SubmitBlockWrapper>
          <Button
            text={t('shared.words.publish')}
            typeButton="submit"
            disabled={disabled}
          />
          {isFunction(onDraftHandler) && (
            <Button
              text={t('register.buttons.draft')}
              disabled={disabled}
              handleOnClick={onDraftHandler}
            />
          )}
        </SubmitBlockWrapper>
      }
    />
  )
}

const SubmitBlockWrapper = styled.div`
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
`


SubmitButtons.propTypes = {
  disabled: PropTypes.bool,
  onDraftHandler: PropTypes.func,
}

export default SubmitButtons
