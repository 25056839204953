import { CancelOrderReason } from 'shared'

export const reasonsCancelAdapter = {
  [CancelOrderReason.SCHEDULING_ISSUE]: 'cancel-reason.reason.scheduling',
  [CancelOrderReason.WEATHER_CONDITIONS_ISSUE]: 'cancel-reason.reason.weather-issue',
  [CancelOrderReason.TECHNICAL_ISSUE]: 'cancel-reason.reason.technical-issue',
  [CancelOrderReason.INAPPROPRIATE_INFO_ISSUE]: 'cancel-reason.reason.inappropriate-info',
  [CancelOrderReason.OTHER]: 'cancel-reason.reason.other',
  [CancelOrderReason.BY_SYSTEM]: 'cancel-reason.reason.by-system',
}
