import React from 'react'
import cx from 'classnames'

import { getShortMonthDate } from 'utils/date'
import { useModal } from 'store/hooks/useModal'
import { withUser } from 'store/hooks/globalState/useUser'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useAcceptBookingRequest,
  useMarkAsRead,
  useMarkAsUnRead,
  useRejectBookingRequest,
} from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import RejectModal from 'components/OfferingDetails/components/RejectModal'
import { getBookingRequestPhsConfig, buildRequestTransKey } from './utils/utils'
import { isPending, statusTrans } from '../config'
import { getOrderPageRoute } from 'router/routesMap'

const BookingRequestNotification = props => {
  const { id, createdAt, isRead, isOverdue, isTable, isMenu, status, options } = props
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()
  const rejectBookingRequest = useRejectBookingRequest()
  const acceptBookingRequest = useAcceptBookingRequest()
  const [isRejectReasonModalOpen, openRejectReasonModal, closeModalRejectReasonModal] = useModal(false)

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  const declineHandler = React.useCallback(({ message = '', rejectReason }) => {
    rejectBookingRequest(id, rejectReason, message)
  }, [id])

  const acceptHandler = () => acceptBookingRequest(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'
  const transKey = buildRequestTransKey(props)
  const phs = getBookingRequestPhsConfig(props)

  return (
    <div className={containerClasses} id={id} key={transKey}>
      <div className="notification-item__icon">
        <Icon.Bell id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans phs={phs} tKey={transKey} />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>

      {isPending(status) && (
        <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
          {t(markBtnText)}
        </button>
      )}

      {isPending(status) ? (
        <div className="notification-item__controls">
          <Button
            isLink
            path={getOrderPageRoute(options.offerType, options.occurrenceId)}
            text={t('shared.words.review')}
            className="notification-item__action-button"
          />
          <Button
            primary
            handleOnClick={openRejectReasonModal}
            text={t('shared.words.decline')}
            className="notification-item__action-button"
          />
          <Button
            handleOnClick={acceptHandler}
            text={t('shared.words.accept')}
            className="notification-item__action-button"
          />
        </div>
      ) : (
        <div className="notification-item__controls notification-item__controls--status">
          <p className="notification-status">{t(statusTrans[status])}</p>
        </div>
      )}

      <RejectModal
        isOpen={isRejectReasonModalOpen}
        onClose={closeModalRejectReasonModal}
        rejectHandler={declineHandler}
      />
    </div>
  )
}

export default withUser(BookingRequestNotification)
