import React from 'react'
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import TableLoader from './TableLoader'
import SelectCleaner from './SelectCleaner'
import TableHeader from './TableHeader'
import TableContent from './TableContent'
import Pagination from './Pagination'

const mandatory = ['tableName', 'fields', 'getFiltersFn', 'setFiltersFn', 'fetchTableFn', 'getTableFn']
export default function Table (props) {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 768)

  let isValidProps = true
  mandatory.forEach(field => {
    if (!props[field]) {
      console.warn(`<Table /> missed "${field}"`)
      isValidProps = false
    }
  })

  if (!isValidProps) return null

  const {
    tableName, emptyTableText, tableConfig = [], fields, getFiltersFn, setFiltersFn, fetchTableFn, getTableFn,
    actionsConfig, bulkActionsConfig, components, withRejected
  } = props

  return (
    <>
      <TableLoader
        fields={fields}
        getFiltersFn={getFiltersFn}
        fetchTableFn={fetchTableFn}
      />
      <SelectCleaner
        fields={fields}
        getFiltersFn={getFiltersFn}
        setFiltersFn={setFiltersFn}
      />
      <TableStyledWrapper isMobile={isMobile}>
        <TableHeader
          tableName={tableName}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          bulkActionsConfig={bulkActionsConfig}
          getTableFn={getTableFn}
          getFiltersFn={getFiltersFn}
          setFiltersFn={setFiltersFn}
          withRejected={withRejected}
        />
        <TableContent
          tableName={tableName}
          emptyTableText={emptyTableText}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          bulkActionsConfig={bulkActionsConfig}
          getTableFn={getTableFn}
          components={components}
          withRejected={withRejected}
        />
      </TableStyledWrapper>
      <Pagination
        getFiltersFn={getFiltersFn}
      />
    </>
  )
}

Table.propTypes = {
  tableName: PropTypes.string.isRequired,
  tableConfig: PropTypes.array.isRequired,
  actionsConfig: PropTypes.array,
  getTableFn: PropTypes.func.isRequired,
}

export const TableStyledWrapper = styled.div`
  ${({ isMobile }) => isMobile
  ? css`
    `
  : css`
      background: #fff;
      border-radius: 4px;
      box-shadow: 10px 10px 20px rgba(209, 210, 225, 0.3), -10px -10px 20px rgba(250, 250, 255, 0.5);
      .table-content-wrapper {
        padding-bottom: 34px;
        height: 100%;
      }
      .col {
        overflow: hidden;
      }
      .col:not(:last-child) {
        padding-right: 10px;
      }
    `};
`
