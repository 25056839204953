import React from 'react'
import { ReactComponent as SettingsIcon } from '../../assets/settings-icon.svg'
import './index.scss'

import { useModal } from 'store/hooks/useModal'

import DeviceSelectionDialog from '../DeviceSelectionModal'


export default function ToggleSettingsButton() {
  const [isDeviceSelectionModalOpen, openDeviceSelectionModal, closeDeviceSelectionModal] = useModal(false)

  return (
    <React.Fragment>
      <button className="video-button video-button--settings" onClick={openDeviceSelectionModal}>
        <SettingsIcon />
      </button>
      <DeviceSelectionDialog isOpen={isDeviceSelectionModalOpen} onClose={closeDeviceSelectionModal} />
    </React.Fragment>
  )
}
