// Modules
import React from 'react'
import { isEqual, isFuture } from 'date-fns'
import { useHistory } from 'react-router-dom'
import filter from 'lodash/fp/filter'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import { routesList } from 'router/routesMap'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoChatsOrderDetailsHeader, useUpdateVideChatOccurrenceTime } from './state/requests'
import { useSetVideoChatsOrderDetailsHeader, useVideoChatsOrderDetailsHeader } from './state/store'

// Utils
import { getSaveFullName, getUserLink, getUserTimeZone } from 'utils/user'
import { getDateTimeProps } from 'utils/date'
import { prepareDate } from 'utils/services/timezone'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import {
  getCharitiesTitle, getComplaintText,
  getTalentRefundText,
  isOccurrenceFullyBooked,
  toDate,
} from 'components/OfferingDetails/utils'

// Components
import BookingCalendar from 'components/Calendars/BookingCalendar'
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import PendingAction from 'components/OfferingDetails/components/PendingAction'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsCountdown from 'components/OfferingDetails/components/OfferingDetailsCountdown'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import DashButton from '_legacy/components/Button/DashButton'
import { getVideoChatOrderConfig } from './utils'
import ComplaintInfo from '../../components/ComplaintInfo'
import CancelOrderButton from '../../components/CancelOrderButton'
import CancelStatus from '../../components/CancelStatus'
import AddToCalendarButton from 'components/Calendars/components/AddToCalendarButton'

const VideoChatOrderDetails = () => {
  const t = useGetTranslate()
  const history = useHistory()
  const [orderSlot, setOrderSlot] = React.useState(null)

  const currentUser = useUser()
  const timeZone = useUser(getUserTimeZone)

  const updateTime = useUpdateVideChatOccurrenceTime()
  const fetchOccurrence = useFetchVideoChatsOrderDetailsHeader()

  const occurrence = useVideoChatsOrderDetailsHeader()
  const clearOccurrence = useSetVideoChatsOrderDetailsHeader(() => null)

  const {
    id: occurrenceId,
    title,
    duration,
    startTime: _startTime,
    endTime: _endTime,
    user,
    order,
    review,
    pendingAction,
    totalCharityFee,
  } = occurrence || {}

  // date/time
  const { formattedDate, formattedTimeRange, startTime } = getDateTimeProps({ ...occurrence, timeZone })

  // modals
  const [isOpenCalendarModal, openCalendarModal, closeCalendarModal] = useModal(false)

  // calendar handlers
  const onConfirm = orderSlot => {
    updateTime({
      startTime: prepareDate(orderSlot?.start, timeZone),
      endTime: prepareDate(orderSlot?.end, timeZone),
    })
  }

  const beforeTransform = filter(slot => !isEqual(new Date(slot.start), new Date(occurrence.startTime)))

  // others
  const handleStartEvent = () => history.push(routesList.videoChatRoom(occurrenceId))

  // effects
  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => {
    if (occurrence) {
      const orderSlot = occurrence.startTime
        ? { start: new Date(occurrence.startTime), end: new Date(occurrence.endTime), title: occurrence.title }
        : { title: occurrence.title }

      setOrderSlot(orderSlot)
    }
  }, [occurrence])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!occurrence) return <PageLoader />

  const c = getVideoChatOrderConfig(occurrence)

  const cancelOrderButton = <CancelOrderButton orderId={order.id} onSuccess={fetchOccurrence} />
  const refundText = getTalentRefundText({ t, refundRequests: occurrence.order.refundRequests })
  const complaintText = getComplaintText({ complaints: occurrence.complaints })

  return (
    <div className="video-chat-order od__margins">
      <OfferingDetailsTitle
        typeHeader={t('order-details.virtual-chats.header')}
        title={`${t('order-details.virtual-chats.header-with')} ${getSaveFullName(user)}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrence, timeZone)} />
          {pendingAction && <PendingAction action={pendingAction} title={t('order-details.virtual-chats.header')} />}

          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.aficionado')}</h6>
                <UserInfo user={user} />
              </div>

              {startTime && (
                <div className="od__column">
                  <h6 className="od--uppercase-title od--uppercase-title--mobile mb-16">
                    {t('order-details.virtual-chats.video-date')}
                  </h6>
                  <p className="od--main-text od--accented">{formattedDate}</p>
                  <p className="od--small-text">{formattedTimeRange}</p>
                </div>
              )}

              {isFuture(toDate(startTime)) && isOccurrenceFullyBooked(occurrence) && (
                <div className="od__column od--centered">
                  <DashButton onClick={openCalendarModal} text={t('order-details.virtual-chats.change-date')} />
                </div>
              )}
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            {c.isShowCalendar && (
              <div className="od__column od--with-padding">
                <p className="od--uppercase-title mb-24">{t('order-details.virtual-chats.info-header')}</p>
                <Button
                  handleOnClick={openCalendarModal}
                  iconComponent={<Icon.ViewCalendar fill="#fff" />}
                  text={t('order-details.virtual-chats.propose-date')}
                  typeButton="submit"
                  fontSize="0.875"
                  paddingVertical="16"
                  paddingHorizontal="10"
                  className="mb-16"
                />
                {cancelOrderButton}
              </div>
            )}

            {c.isShowCounter&& (
              <div className="od--with-padding">
                <p className="od--uppercase-title">{t('order-details.virtual-chats.info-header')}</p>
                <OfferingDetailsCountdown
                  countdownDate={_startTime}
                  handleClick={handleStartEvent}
                  title={t('order-details.virtual-chats.start-event')}
                />
                <AddToCalendarButton
                  startTime={_startTime}
                  endTime={_endTime}
                  timeZone={timeZone}
                  className="fat"
                />
                {cancelOrderButton}
              </div>
            )}

            {c.isShowCompleted && (
              <React.Fragment>
                <OfferingDetailsEscrowFunds
                  title={t('order-details.virtual-chats.info-header')}
                  subtitle={t('order.description.funds')}
                  price={order?.totalPrice}
                  description={t('order.description.virtual-chats')}
                />
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={order.id}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('order-details.virtual-chats.header')}
                  reviewType={'order.answer.rate.with'}
                />
              </React.Fragment>
            )}

            {c.isShowClosed && (
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('order-details.virtual-chats.info-header')}
                  subtitle={t('order.description.received')}
                  price={order?.totalPrice}
                />
                {!!totalCharityFee && order?.totalPrice && (
                  <OfferingDetailsEscrowFunds
                    className="od--bordered"
                    subtitle={getCharitiesTitle(t, order?.totalPrice, totalCharityFee)}
                    price={totalCharityFee}
                    description={t('order.description.charities.text')}
                  />
                )}
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={order.id}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('order-details.virtual-chats.info-header')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            )}

            {c.isShowCanceled && (
              <CancelStatus
                refundText={refundText}
                complaintText={complaintText}
                title={t('shared.offers.video-chat')}
              />
            )}
          </div>

          <ComplaintInfo
            orderId={order?.id}
            onSuccess={fetchOccurrence}
            userLink={getUserLink(user)}
            userName={getSaveFullName(user)}
          />
        </div>
      </div>

      <BookingCalendar
        openOnDate={orderSlot?.start}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
        isOpen={isOpenCalendarModal}
        onClose={closeCalendarModal}
        eventDuration={duration}
        talentId={currentUser.talent.id}
        eventTitle={title}
        beforeTransform={beforeTransform}
        onConfirm={onConfirm}
      />
    </div>
  )
}

export default VideoChatOrderDetails
