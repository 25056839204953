import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const StyledWrapper = styled.p`
  color: red;
  line-height: 1.3;
  margin-top: 12px;
  ${({ ...props }) => props}
`

export const ErrorMessage = ({ message, className = '', ...props }) => {
  const t = useGetTranslate()
  return (
    <StyledWrapper className={className} {...props}>
      {t(message)}
    </StyledWrapper>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
}
