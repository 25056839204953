import { curry, pipe, compact, head, prop, props } from 'lodash/fp'

import { getTalentOrUserId } from 'utils/user'

const getReceiverId = pipe(
  props(['receiverTalent', 'receiverUser']),
  compact,
  head,
  prop('id')
)


export const getIsOwnByNotificationProps = curry((props, user) => {
  const viewerId = getTalentOrUserId(user)
  const receiverId = getReceiverId(props)
  return viewerId === receiverId
})
