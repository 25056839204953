import React from 'react';
import { UserType } from 'shared'

import { useIsUserHasTalent, useUserType } from 'store/hooks/globalState/useUser';

import { config } from './config';
import NavigationLinksList from '_legacy/common/mobile-navigation/NavigationLinksList'
import NavigationHeader from '_legacy/common/mobile-navigation/NavigationHeader'
import ProfileEdit from '_legacy/components/RegisterForms/ProfileEdit'


function UserMenu({ routesConfig }) {
  const userType = useUserType()
  const isUserHasTalent = useIsUserHasTalent()
  const showMenu = userType === UserType.TALENT_USER

  return !showMenu
    ? <ProfileEdit showAgent={!isUserHasTalent} showTalent={isUserHasTalent} />
    : <NavigationLinksList routesConfig={routesConfig} />
}

const MobileEditProfileTemplate = () => {
  const [slug, setSlug] = React.useState(null);
  const routesConfig = [
    {
      path: '',
      text: 'account.menu.profile.edit-user',
      clickHandler: () => setSlug('user'),
    },
    {
      path: '',
      text: 'account.menu.profile.edit-talent',
      clickHandler: () => setSlug('talent'),
    }
  ]

  const parsedItems = React.useMemo(() => config.filter(item => item.label === slug), [slug])

  return slug ? (
    parsedItems.map((items, i) => (
      <div key={i}>
        <NavigationHeader text={`account.menu.profile.edit-${slug}`} clickHandler={() => setSlug(null)} />
        <ProfileEdit showAgent={!items.isTalent} showTalent={items.isTalent} />
      </div>
    ))
  ) : (
    <>
      <NavigationHeader text={'account.menu.profile.edit-profile'} />
      <UserMenu routesConfig={routesConfig} />
    </>
  )
};

export default MobileEditProfileTemplate;
