import styled from 'styled-components'
import PropTypes from 'prop-types'

const PictureWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(39, 40, 53, 0) 0%,
      rgba(39, 40, 53, 0.8) 67.71%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
      visibility: visible;
    }
  }

  .custom-picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

PictureWrapper.propTypes = {
  className: PropTypes.string,
}

export default PictureWrapper
