import React from 'react'

import { cancelSubscription } from '../api/api'
import { useGetMe } from 'requests/auth'

export function useCancelRenewal() {
  const getMe = useGetMe()

  return React.useCallback(() => {
    cancelSubscription()
      .then(() => {
        getMe()
      })
  }, [])
}
