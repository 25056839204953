import React from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import PropTypes from 'prop-types';
import { flatMap, isEmpty, map, get } from 'lodash';
import { PROFILE_MANAGERS, LIST } from '_legacy/constants/routes';
import { useNavigateWithReload } from 'store/hooks/globalState/useSwitchNavigation';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import CommonInput from '../Form/Inputs/Common';
import Button from '../Button';
import { types } from 'shared';
import { emailPattern } from 'utils/services/validators/validators';
import { useFetch } from 'store/hooks/useFetch';
import { appApi } from 'api';
import { notify } from 'utils/notify';
import getTypeInvite from './utils';
import { useStateModal } from 'store/hooks/globalState/useModal';
import { getError } from 'utils/services/errors/error-config'
const {
  TALENT_USER,
} = types.UserType;
const ViaEmail = ({ type }) => {
  const t = useGetTranslate();
  const closeModal = useStateModal(() => null)
  const { handleSubmit, control, register, errors, watch , setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      managers: [{
        name: '',
        email: '',
      }]
    },
  });
  const nevigate = useNavigateWithReload();
  const [{ data, error, isLoading }, sendInvitationsByEmail] = useFetch(appApi.talent.join.joinEmail);

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'managers'
  });

  const formState = watch();
  const watchManagers = get(formState, 'managers')

  const emptyField = isEmpty(watchManagers[0]?.name) || isEmpty(watchManagers[0]?.email);

  const onSubmit = async (value) => {
    const invitations = map(flatMap(Object.values(value)), (d) => ({ name: d.name, email: d.email }));
    await sendInvitationsByEmail({ data: invitations, type });
  };

  React.useEffect(() => {
    if (data) {
      closeModal()
      nevigate(`/${PROFILE_MANAGERS}/${LIST}`,t('join-to-talent.notify.success'), 'success')
    }
  }, [data]);

  React.useEffect(() => {
    if (error) {
      setError('entreevip', { type: 'server', message: t(getError(error?.name)) });
      notify(t(getError(error?.name)), 'error');
    }
  }, [error]);

  return (
    <>
      {type === TALENT_USER ?
        (<h2 className='joinToTalent-modal__subtitle'>{t('join-to-talent.subtitle1')}</h2>) :
        (<h2 className='joinToTalent-modal__subtitle'>{t('join-to-talent.subtitle')}</h2>)}
      <form onSubmit={handleSubmit(onSubmit)} className='joinToTalent-email__form'>
        <div className='joinToTalent-email__form_wr'>
          {fields.map(({ id, value }, index) => (
            <div key={id} className='joinToTalent-email__form_item'>
              <CommonInput
                name={`managers[${index}].name`}
                label={t(`join-to-talent.email.label.name.${getTypeInvite(type)}`)}
                placeholder={t('join-to-talent.email.placeholder.name')}
                defaultValue={value}
                containerClassName='joinToTalent-email__form_name'
                errorMessage={errors?.managers?.[index]?.name?.message}
                forwardRef={register({
                  required: 'join-to-talent.name.error.required'
                })}
              />
              <CommonInput
                name={`managers[${index}].email`}
                label={t(`join-to-talent.email.label.email.${getTypeInvite(type)}`)}
                placeholder={t(`join-to-talent.email.placeholder.email.${getTypeInvite(type)}`)}
                defaultValue={value}
                containerClassName='joinToTalent-email__form_email'
                errorMessage={errors?.managers?.[index]?.email?.message}
                forwardRef={register({
                  required: 'join-to-talent.name.error.required',
                  pattern: {
                    value: emailPattern,
                    message: 'restore-password.modal.email.errors.invalid'
                  },
                  validate: v =>
                    !watchManagers
                      .filter((_, idx) => idx !== index)
                      .find(({ email }) => email === v) || t('join-to-talent.email.error.unique')
                })}
              />
              {index !== 0 && <button
                type='button'
                className='joinToTalent-email__button--remove'
                onClick={() => remove(index)}
              />}
            </div>
          ))}
          {fields.length < 5 && (
            <button
              type='button'
              className='joinToTalent-email__button--add'
              onClick={append}
            >
              + {t('join-to-talent.email.add')}
            </button>
          )}
        </div>
        <Button
          typeButton='submit'
          text={t('join-to-talent.button.submit')}
          classBtn='joinToTalent-email__button--submit joinToTalent-modal__submit'
          paddingVertical='16'
          paddingHorizontal='56'
          disabled={!isEmpty(errors) || emptyField}
          loader={isLoading}
        />
      </form>
    </>
  );
};

ViaEmail.propTypes = {
  type: PropTypes.string,
};

export default ViaEmail;
