import React from 'react'
import './index.scss'

//import { getMyMediaMenuItems } from './menu-config'
import MyMediaTable from './MyMediaTable'

const MyMedia = () => {
  //const menuItems = getMyMediaMenuItems()

  return (
    <div className='my-media'>
      <MyMediaTable />
    </div>
  )
}

export default MyMedia
