import React from 'react';
import PropTypes from 'prop-types';
import { Types } from 'shared/dist/src/types/file';

// components
import { Row } from '_legacy/common/Template';

// utils
import Uploader from './Uploader';

const UploaderFiles = React.memo(function UploaderFiles({ name, label, subLabel, titleHint, textHint, typesFiles, requiredMessage, isRequired, ...props }) {
  return (
    <Row
      className={'license'}
      nameBlock={label}
      subNameBlock={subLabel}
      isRequired={isRequired}
      component={
        <Uploader
          name={name}
          titleHint={titleHint}
          textHint={textHint}
          isRequired={isRequired}
          typesFiles={typesFiles}
          requiredMessage={requiredMessage}
        />
      }
      {...props}
    />
  );
});


UploaderFiles.defaultProps = {
  typesFiles: Types.FILE
};

UploaderFiles.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  subLabel: PropTypes.string,
  titleHint: PropTypes.string.isRequired,
  textHint: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredMessage: PropTypes.string,
  typesFiles: PropTypes.oneOfType([
    PropTypes.string, PropTypes.arrayOf(PropTypes.string)
  ]),
};

export default UploaderFiles;
