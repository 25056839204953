import React from 'react'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { isArrayEqual, getUniqueArrayBy } from 'utils/helpers/array'

import Checkbox from '_legacy/containers/PromoCodes/components/common/Checkbox'

const SelectAll = ({ bulkActionsConfig, filteredList }) => {
  const { getFilters, setFilters } = bulkActionsConfig

  const selectedList = getFilters(current => get(current, 'selected', []))
  const limit = getFilters(current => get(current, 'limit'))

  const selectAll = setFilters(prev => {
    const selected = get(prev, 'selected', [])

    // Selected is bigger than filtered and all filtered are selected
    const isFilterUsed = filteredList.length
      && filteredList.length <= selectedList.length
      && filteredList.every(item => selectedList.filter(it => it.id === item.id).length)

    if (isArrayEqual(selected, filteredList)) return { ...prev, selected: [] }
    if (isFilterUsed) {
      // Remove only filtered items from selected
      return { ...prev, selected: selectedList.filter(item => !filteredList.filter(it => it.id === item.id).length) }
    }

    // Add filtered and prev selected items
    return { ...prev, selected: getUniqueArrayBy([...selectedList, ...filteredList], 'id') }
  }, [limit, filteredList, selectedList])

  const isChecked = (filteredList.length && filteredList.length <= selectedList.length)
    ? filteredList.every(item => selectedList.filter(it => it.id === item.id).length)
    : isArrayEqual(selectedList, filteredList)

  return (
    <Checkbox
      checked={isChecked}
      clickHandler={selectAll}
    />
  )
}

SelectAll.propTypes = {
  bulkActionsConfig: PropTypes.object.isRequired,
  filteredList: PropTypes.arrayOf(PropTypes.object),
}

export default SelectAll;
