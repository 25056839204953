import { types } from 'shared';

import { filters } from '_legacy/components/Table/Filters/config'

const {
  OPEN,
  CANCELLED,
  COMPLETED,
  FULLY_BOOKED,
  IN_PROGRESS,
  EXPIRED,
} = types.talent.OfferOccurrenceStatus

export const statuses = {
  [OPEN]: {
    label: 'table.filters.statuses.open',
    color: 'lightGrey'
  },
  [FULLY_BOOKED]: {
    label: 'table.filters.statuses.fully-booked',
    color: 'lightGrey'
  },
  [COMPLETED]: {
    label: 'table.filters.statuses.completed',
    color: 'lightGrey'
  },
  [IN_PROGRESS]: {
    label: 'table.filters.statuses.in-progress',
    color: 'lightGrey'
  },
  [CANCELLED]: {
    label: 'table.filters.statuses.cancelled',
    color: 'lightGrey'
  },
  [EXPIRED]: {
    label: 'table.filters.statuses.expired',
    color: 'lightGrey'
  }
}

const statusOptions = Object.keys(statuses).map(key => ({ value: key, label: statuses[key].label }))

export const filtersConfig = [
  {
    type: filters['range-datepicker'],
    placeholder: 'table.filters.expiration-date',
    field: 'date',
  },
  {
    type: filters.select,
    placeholder: 'table.filters.status',
    field: 'statusIn',
    options: statusOptions,
  },
]

export const counterConfig = {
  field: 'count',
  label: 'table.filters.offering',
  pluralLabel: 'table.filters.occurrences'
}

//const { DATE, LOCATION, PRICE, STATUS, TITLE } = api.talents.talent.offers.query.SortField;

// const sortFields = {
//   [DATE]: 'table.header.date-time',
//   [LOCATION]: 'table.header.location',
//   [REQUESTS]: 'table.header.requests',
//   [PARTICIPANTS]: 'table.header.participants',
//   [STATUS]: 'table.header.status',
// }
// const sortFieldOptions = Object.keys(sortFields).map(key => ({ value: key, label: sortFields[key] }))

///  SAME AS ON PROMO CODES WE RECEIVE FROM BE FIELDS THAT DIFFERS FROM DESIGNS AND REQUIREMENTS UNCOMMENT AND TEST AFTER CHANGE FROM BE

export const subFiltersConfig = [
  // {
  //   label: 'table.filters.sort-by',
  //   field: 'sortOrder',
  //   options: sortFieldOptions
  // },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [{ value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }]
  }
]

export const defaultConfig = [
  {
    field: 'fields'
  },
  {
    field: 'relations'
  },
  {
    field: 'experienceIdIn'
  }
]

export const detailsConfig = {
  fields: ['id', 'recurring', 'occurrencesLimit', 'name', 'daysTimesType', 'timeWindows', 'maxNumberOfParticipants'],
  relations: ['photo', 'video', 'talent', 'whichCharities', 'otherTalentParticipating']
}

const additionalFields = [
  'sortOrder',
  'sortField'
]

export const fields = [
  ...defaultConfig.map(({ field }) => field),
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields
]
