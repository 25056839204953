import React from 'react'
import PropTypes from 'prop-types'

import { FAVORITES, TALENT, OFFERINGS } from '_legacy/constants/routes'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import NavigationList from '_legacy/components/Search/Navigation/NavigationList'
import List from '_legacy/components/Favorites/List'

import './index.scss'

const Favorites = ({
  fields,
  clearFn,
  getListFn,
  fetchListFn,
  getFiltersFn,
  setFiltersFn,
  EmptyListComponent,
}) => {
  const t = useGetTranslate()
  const clearSearchFilters = clearFn()
  React.useEffect(() => () => clearSearchFilters(), [])

  const navigationProps = [
    {
      linkTo: `/${FAVORITES}/${TALENT}`,
      linkText: t('favorites.navigation.talent.label'),
    },
    {
      linkTo: `/${FAVORITES}/${OFFERINGS}`,
      linkText: t('favorites.navigation.offering.label'),
    },
  ]

  return (
    <div className="favorites-wrapper">
      <NavigationList navigationProps={navigationProps} />
      <List
        fields={fields}
        getListFn={getListFn}
        EmptyList={EmptyListComponent}
        fetchListFn={fetchListFn}
        getFiltersFn={getFiltersFn}
        setFiltersFn={setFiltersFn}
      />
    </div>
  )
}

Favorites.propTypes = {
  fields: PropTypes.array,
  getListFn: PropTypes.func,
  clearFn: PropTypes.func,
  filtersConfig: PropTypes.array,
  fetchListFn: PropTypes.func,
  getFiltersFn: PropTypes.func,
  setFiltersFn: PropTypes.func,
  EmptyListComponent: PropTypes.func,
}

export default Favorites
