import React from 'react'
import { PromotionalMediaType } from 'shared'
import { cond, propEq, always, T, prop, compose, __ } from 'lodash/fp'

import LineString from '../common/LineString'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const config = {
  [PromotionalMediaType.LESSONS_PROMO]: 'shared.offers.lessons',
  [PromotionalMediaType.VIDEO_CHAT_PROMO]: 'shared.offers.video-chat',
  [PromotionalMediaType.VIDEO_MESSAGE_PROMO]: 'shared.offers.video-message',
  [PromotionalMediaType.IN_PERSON_EXPERIENCE_PROMO]: 'shared.offers.experience',
  [PromotionalMediaType.LIVE_VIRTUAL_EVENT_PROMO]: 'shared.offers.live-virtual-event',
  [PromotionalMediaType.READY_MADE_LESSON_PROMO]: 'shared.offers.ready-made-lessons',
}

const getLabel = cond([
  [propEq('length', 0), always('shared.words.na')],
  [propEq('length', 1), compose(prop(__, config), prop([0, 'type']))],
  [T, always('shared.words.multiple')],
])

export default React.memo(function Promotional ({ isLoading, promotionalMedia }) {
  const t = useGetTranslate()
  return (
    <LineString
      isLoading={isLoading}
      str={t(getLabel(promotionalMedia))}
    />
  )
})
