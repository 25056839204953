import React from 'react'
import styled from 'styled-components'

export default function Arrow ({
  className = '', clickHandler
}) {
  return (
    <StyledWrapper
      className={className}
      onClick={clickHandler}
    >
      <div className='arrow' />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  cursor: pointer;
  @media screen and (max-width: 1024px) {
    width: 21px;
    height: 21px;
    &.left {
      margin-right: -10px;
    }
    &.right{
      margin-left: -10px;
    }
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
  .arrow {
    background: transparent;
    border: none;
    color: white;
    width: 21px;
    height: 21px;
    padding: 10px;
    box-shadow: 2px -2px white inset;
    transition: 0.2s;
    flex-shrink: 0;
    z-index: 150;
  }
  &.left .arrow {
    transform: rotate(45deg) translate(2px, -2px);
  }
  &.right .arrow {
    transform: rotate(-135deg) translate(2px, -2px);
  }
`
