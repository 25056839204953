import React from 'react'
import { notify } from 'utils/notify'

import { unsubscribeFromUpdates } from './api'
import { getAccessToken } from 'utils/storage'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export function useUnsubscribeFromUpdates() {
  const t = useGetTranslate()

  return React.useCallback(({ email }) => {
    unsubscribeFromUpdates({
      email,
      authorization: !!getAccessToken()
    })
      .then(() => {
        notify(t('unsubscribe.email.success'), 'success')
      })
      .catch(error => {
        notify(t('unsubscribe.email.error'), 'error')
        console.warn(error)
      })
  },
  []
  )
}
