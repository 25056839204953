import React from 'react'
import styled from 'styled-components'

import defaultBg from 'assets/landing/carousel-default-bg.jpg'

import CustomPicture from '_legacy/common/CustomPicture'

export default function CardBack ({
  file, isMain
}) {
  return (
    <>
      <StyledCustomPicture
        file={file || { fullUrl: defaultBg, originalName: 'card-back' }}
        isMain={isMain}
      />
      <StyledImageOverlay
        isMain={isMain}
      />
    </>
  )
}

const StyledCustomPicture = styled(CustomPicture)`
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  ${({ isMain }) => !isMain && 'filter: saturate(0%)'};
`

const StyledImageOverlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ isMain }) => isMain ? 0 : 0.9};
  ${({ isMain }) => !isMain && 'background-image: linear-gradient(90deg, rgba(27, 28, 37, 0.9) 0%, #505166 100%)'};
  transition: opacity 350ms ease;
  z-index: 5;
`
