import { cond, equals, constant, T } from 'lodash/fp'

import { TypesOfDays } from 'shared'

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  BOTH,
} = TypesOfDays

export const getLessonsPreviewNoteText = daysTimesType =>
  cond([
    [equals(ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR), constant('lessons.preview.note.any-available')],
    [equals(ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME), constant('lessons.preview.note.allow-aficionado')],
    [equals(BOTH), constant('lessons.preview.note.both')],
    [T, constant('lessons.preview.note.both')],
  ])(daysTimesType)
