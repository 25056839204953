import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import map from 'lodash/fp/map'
import styled from 'styled-components'

import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser'
import { useFetchUserSearchLocationHistory } from 'requests/auth/history'
import { useUserSearchLocationHistory } from 'store/hooks/globalState/auth/history'
import { useOffersTopLocation } from 'store/hooks/globalState/useLocationList'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  getLocation,
  locationsLoader,
  transformLatestLocation,
  transformTopLocation,
  getGroupedOptions,
  CURRENT_LOCATION,
} from './config'

import { ReactComponent as Pointer } from 'assets/pointer.svg'

import Select from '../index'
import { useFetchOfferTopLocations } from 'requests/search/offers'

const OffersLocationSelect = ({ getFn, setFn, field }) => {
  const t = useGetTranslate()
  const isUserLoggedIn = useIsUserLoggedIn()
  const fetchUserSearchLocationHistory = useFetchUserSearchLocationHistory()
  const fetchOfferTopLocation = useFetchOfferTopLocations()

  const value = getFn(state => get(state, field))
  const setValue = setFn((prev, next) => ({ ...prev, [field]: next }))
  const clearValue = setFn(prev => ({ ...prev, [field]: undefined }))

  const latestLocations = useUserSearchLocationHistory(map(transformLatestLocation))
  const topLocations = useOffersTopLocation(map(transformTopLocation))
  const groupedOptions = getGroupedOptions(latestLocations, topLocations)

  const onChange = (option, e, setInputValue) => {
    const onSuccess = data => {
      setValue({
        label: option.label,
        value: {
          latitude: data.coords.latitude,
          longitude: data.coords.longitude,
        },
      })
    }
    const onError = () => {
      clearValue()
      setInputValue('')
    }

    const actionType = get(e, 'action')
    const value = get(option, 'value')

    if (actionType === 'select-option' && value === CURRENT_LOCATION) {
      getLocation(onSuccess, onError)
      return
    }

    if (actionType === 'clear') {
      clearValue()
      return
    }

    setValue(option)
  }

  React.useEffect(() => {
    if (isUserLoggedIn) fetchUserSearchLocationHistory()
  }, [isUserLoggedIn])

  React.useEffect(() => {
    fetchOfferTopLocation()
  }, [])

  return (
    <StyledWrapper className="filter">
      <Select
        defaultMenuIsOpen
        placeholder={t('search.filters.location.placeholder')}
        loadOptions={locationsLoader}
        onChange={onChange}
        value={value}
        groupedOptions={groupedOptions}
        inputIcon={Pointer}
      />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .react-select__control {
    border: 2px solid rgb(233, 234, 240);
    height: 48px;
    background-color: white;
  }
`

OffersLocationSelect.propTypes = {
  getFn: PropTypes.func,
  setFn: PropTypes.func,
  field: PropTypes.string,
}

export default OffersLocationSelect
