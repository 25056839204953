import React from 'react'

import { roles } from '_legacy/configs/table/profileManagers'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import LineString from '../common/LineString'

export default React.memo(function Role ({ isLoading, role }) {
  const t = useGetTranslate()
  const tkey = roles[role]
  return (
    <LineString
      isLoading={isLoading}
      str={t(tkey)}
    />
  )
})
