import React from 'react'
import styled from 'styled-components'

export default React.memo(function Shadow ({
  type
}) {
  return (
    <StyledWrapper
      className={type}
    />
  )
})

const StyledWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  filter: blur(4px);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0) 0, rgba(0, 0, 0, 0.4) 100%);
  transition-property: height, transform, top, left;
  transition: 350ms ease;
  &.card-3 {
    width: 110%;
    height: 60%;
  }
  &.card-2 {
    width: 110%;
    height: 40%;
  }
  &.card-1 {
    width: 110%;
    height: 30%;
  }
  &.left-outer {
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    transform: perspective(19px) rotateX(2deg) translate(0, 0) skewX(-45deg);
  }
  &.left-3 {
    left: -90%;
    top: 105%;
    transform: perspective(19px) rotateX(1deg) skewX(-46deg);
  }
  &.left-2 {
    left: -65%;
    top: 105%;
    transform: perspective(19px) rotateX(2deg) skewX(-46deg);
  }
  &.left-1 {
    left: -45%;
    top: 105%;
    transform: perspective(19px) rotateX(2deg) skewX(-40deg);
  }
  &.main {
    width: 90%;
    height: 35%;
    left: 5%;
    top: 105%;
    transform: perspective(19px) rotateX(2deg) skewX(0deg);
  }
  &.right-1 {
    left: 45%;
    top: 105%;
    transform: perspective(19px) rotateX(2deg) skewX(40deg);
  }
  &.right-2 {
    left: 66%;
    top: 105%;
    transform: perspective(19px) rotateX(2deg) skewX(46deg);
  }
  &.right-3 {
    left: 90%;
    top: 105%;
    transform: perspective(19px) rotateX(1deg) skewX(46deg);
  }
  &.right-outer {
    width: 100%;
    height: 0;
    left: 0;
    top: 0;
    transform: perspective(19px) rotateX(2deg) skewX(45deg);
  }
`
