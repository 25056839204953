import React, { createContext, useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import useVideoContext from '../../hooks/useVideoContext'
import { useChatClient } from 'store/hooks/globalState/useChats'


export const ChatContext = createContext(null)

export const ChatProvider = ({ children }) => {
  const history = useHistory()
  const { search, state, pathname } = useLocation()
  const { room, onError } = useVideoContext()
  const isChatWindowOpenRef = useRef(false)
  const [isChatWindowOpen, _setIsChatWindowOpen] = useState(false)
  const [conversation, setConversation] = useState(null)
  const [messages, setMessages] = useState([])
  const [hasUnreadMessages, setHasUnreadMessages] = useState(false)

  const chatClient = useChatClient()

  const [videoChatInfo, setVideoChatInfo] = useState(null)

  const connect = useCallback(
    (videoRoomChat) => {
      setVideoChatInfo(videoRoomChat)
    },
    [],
  )

  useEffect(() => {
    if (conversation) {
      const handleMessageAdded = (message) => setMessages(oldMessages => [...oldMessages, message])

      conversation.getMessages().then(newMessages => setMessages(newMessages.items))
      conversation.on('messageAdded', handleMessageAdded)

      return () => {
        conversation.off('messageAdded', handleMessageAdded)
      }
    }
  }, [conversation])

  useEffect(() => {
    // If the chat window is closed and there are new messages, set hasUnreadMessages to true
    if (!isChatWindowOpenRef.current && messages.length) {
      setHasUnreadMessages(true)
    }
  }, [messages])

  useEffect(() => {
    isChatWindowOpenRef.current = isChatWindowOpen
    if (isChatWindowOpen) setHasUnreadMessages(false)
  }, [isChatWindowOpen])

  useEffect(() => {
    if (room && chatClient && videoChatInfo) {
      chatClient
        .getConversationBySid(videoChatInfo.twilioConversationSid)
        .then(newConversation => {
          window.videoChatConversation = newConversation
          setConversation(newConversation)
        })
        .catch(e => {
          console.error(e)
          onError(new Error('There was a problem getting the Conversation associated with this room.'))
        })
    }
  }, [room, chatClient, onError])

  const setIsChatWindowOpen = useCallback((value) => {
    const query = new URLSearchParams(search)
    query.delete('chat');

    if (value === true) {
      query.set('chat', 'true')
      const newQuery = query.toString() ? `?${query.toString()}` : ''
      history.push(`${state || pathname}${newQuery}`)
    } else {
      history.goBack()
    }
  }, [search])

  useEffect(() => {
    const query = new URLSearchParams(search)

    if (query.get('chat') === 'true') {
      _setIsChatWindowOpen(true)
    } else {
      _setIsChatWindowOpen(false)
    }
  }, [search])

  return (
    <ChatContext.Provider
      value={{ isChatWindowOpen, setIsChatWindowOpen, connect, hasUnreadMessages, messages, videoChatInfo, conversation }}
    >
      {children}
    </ChatContext.Provider>
  )
}
