import { format, getDate } from 'date-fns';
import { enums } from 'shared';
import { daysInWeek, repeatEveryType } from './config';

export const weekOfMonth = (date) => {
  const prefixes = ['FIRST', 'SECOND', 'THIRD', 'FOURTH', 'LAST'];
  return prefixes[0 | (getDate(date) / 7)];
};

export const repeatOn = date => {
  return [
    {
      label: `Monthly on day ${getDate(date)}`,
      value: enums.Recurrence.RepeatEveryOnMonthTypeEnum.SINGLE_DATE,
    },
    {
      label: `Monthly on the ${weekOfMonth(date).toLowerCase()} ${format(new Date(date), 'dddd')}`,
      value: enums.Recurrence.RepeatEveryOnMonthTypeEnum.POSITION_DATE,
    },
  ];
};

export const repeatEveryTypeWithTranslate = (t) => repeatEveryType.map(option => ({
  label: t(option.label),
  value: option.value,
}));

export const daysInWeekWithTranslate = (t) => daysInWeek.map(day => t(day));

