import { _get, _post } from 'api/utils'

export const getNotifications = ({ query = {} }) => _get(
  '/notifications/',
  { needAuthorization: true, query }
)

export const updateNotifications = ({ actionType, notificationIds, body }) => _post(
  `/notifications/bulk/${actionType}`,
  { needAuthorization: true, query: { notificationIds }, body }
)
