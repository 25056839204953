import React from 'react';
import styled from 'styled-components';

import CustomLegalTermsList from '_legacy/containers/CommercialEngagements/CommercialEngagementsRequest/CommercialEngagementsRequestForm/component/CustomLegalTermsList';
import Body2 from '_legacy/common/Typography/Body2';

const LegalTerms = ({ className, commercialTerms }) => {
  return (
    <LegalTermsContainer className={className}>
      <Body2 bold>Terms</Body2>
      <CustomLegalTermsList commercialTerms={commercialTerms[0]}/>
    </LegalTermsContainer>
  );
};

const LegalTermsContainer = styled.div`
  background: rgba(186, 187, 205, 0.7);
  padding: 8px 16px;
  
  .list {
    margin-right: 0;
    margin-left: 0;

    &__item {
      color: #272835;
  
      &:not(:last-of-type) {
        margin-bottom: 6px;
      }
    }
  }

`;

export default LegalTerms;
