import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components'

import { Radio } from '_legacy/common';
import Loader from '_legacy/components/Common/Loader';
import FilePreview from '../../components/Library/FilePreview';
import FileInfo from '../../components/Library/FileInfo';

const RadioListItem = ({ file, name, disabled }) => {
  return (
    <FileItemWrapper disabled={disabled}>
      <FilePreviewContainer file={file} />

      <FileInfoContainer file={file} />

      <FileControlContainer>
        {file?.isLoading
          ? <Loader size={24} fill='#636583' />
          : <Radio value={file.fileToken} name={name} />}
      </FileControlContainer>
    </FileItemWrapper>
  );
};

const FileItemWrapper = styled.li`
  display: grid;
  grid-gap: 12px;
  grid-template: 'file info control' 52px / 52px 1fr 50px;

  cursor: default;
  margin-bottom: 12px;

  ${({ disabled }) =>
  disabled &&
    css`
      opacity: 0.4;
      cursor: not-allowed;
      pointer-events: none;
    `}
`

const FilePreviewContainer = styled(FilePreview)`
  grid-area: file;
`;

const FileInfoContainer = styled(FileInfo)`
  grid-area: info;
`;

const FileControlContainer = styled.div`
  grid-area: control;
  align-self: center;
  
  i {
    margin-right: 0;
  }
`;

RadioListItem.propTypes = {
  file: PropTypes.object,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

export default RadioListItem;
