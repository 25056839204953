// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';
import PropTypes from 'prop-types';

import { Row, Input } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Field = React.memo(function Field({ name, label, placeholder, isRequired, requiredMessage, className = '' }) {
  const t = useGetTranslate();
  return (
    <Row
      nameBlock={t(label)}
      isRequired={isRequired}
      className={className}
      component={
        <Input
          isRequired={isRequired}
          name={name}
          placeholder={t(placeholder)}
          requiredMessage={requiredMessage}
        />
      }
    />
  );
});

Field.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  requiredMessage: PropTypes.string,
};

export default Field;
