import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useFormContext } from 'react-hook-form';
import { set, addMinutes, subMinutes } from 'date-fns';

import TimeSelectWithTimeZone from '_legacy/components/CreateExperience/components/TimeSelectWithTimeZone';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import './index.scss'

const TimeSelector = ({
  selectedDate = Date.now(),
  startTimeName = 'startTime',
  endTimeName = 'endTime',
  startTimeDefaultValue,
  endTimeDefaultValue,
}) => {
  const t = useGetTranslate();

  const { watch } = useFormContext();
  const values = watch();
  const startTime = get(values, [startTimeName, 'value']);
  const endTime = get(values, [endTimeName, 'value']);

  const isSelectedDayIsToday = new Date(selectedDate).getDate() === new Date().getDate();

  const minStartTime = isSelectedDayIsToday
    ? new Date()
    : set(new Date(), { hours: 0, minutes: 0 });
  const maxStartTime = set(new Date(), { hours: 23, minutes: 30 });

  const minEndTime = isSelectedDayIsToday
    ? addMinutes(new Date(), 15)
    : set(new Date(), { hours: 0, minutes: 15 });
  const maxEndTime = set(new Date(), { hours: 23, minutes: 45 });

  return (
    <div className='time-selector'>
      <div className='time-selector_item'>
        <h5 className='time-selector_label' textAlign="end">
          {t('create-experience.form.time.start.label')}
        </h5>
        <TimeSelectWithTimeZone
          name={startTimeName}
          minTime={minStartTime}
          defaultValue={startTimeDefaultValue}
          maxTime={endTime ? subMinutes(new Date(endTime), 15) : maxStartTime}
          rules={{ required: 'shared.words.required' }}
        />
      </div>
      <div className='time-selector_item'>
        <h5 className='time-selector_label' textAlign="end">
          {t('create-experience.form.time.end.label')}
        </h5>
        <TimeSelectWithTimeZone
          name={endTimeName}
          defaultValue={endTimeDefaultValue}
          maxTime={maxEndTime}
          minTime={startTime ? addMinutes(new Date(startTime), 15) : minEndTime}
          rules={{ required: 'shared.words.required' }}
        />
      </div>
    </div>
  )
};


TimeSelector.propTypes = {
  startTimeName: PropTypes.string,
  endTimeName: PropTypes.string,
  startTimeDefaultValue: PropTypes.object,
  endTimeDefaultValue: PropTypes.object,
  selectedDate: PropTypes.instanceOf(Date),
};

export default TimeSelector;
