import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import { Link } from 'react-router-dom'
import './index.scss'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import ChatButton from '_legacy/components/Button/ChatButton'
import Rating from '_legacy/components/Rating'
import AvatarWithEntourageType from 'components/AvatarWithEntourageType'

import { useChat } from 'requests/chats'
import {
  getSaveFullName,
  getId,
  getUserPublicViewLink,
  getUserId,
  getEntourageType,
} from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'

const UserInfo = ({
  user,
  chatId,
  showChatButton = true,
  ratingStarsSize = 16,
  showUserLink = false,
}) => {
  const t = useGetTranslate()

  const userId = getId(user)
  const fullName = getSaveFullName(user)
  const entourageType = getEntourageType(user)
  const photoFile = get(user, 'profileImage.file')
  const rating = get(user, 'reviewsInfo.averageRating')
  const totalReviewsCount = get(user, 'reviewsInfo.totalReviewsCount')

  const { isLoading, handleStartChat } = useChat(userId, chatId)

  const currentUserId = useUser(getUserId)
  const isSameUser = userId !== currentUserId

  return (
    <div className="user-info">
      <div className="user-info__avatar">
        <AvatarWithEntourageType file={photoFile} alt={fullName} entourageType={entourageType} entourageSize='sm' />

        {showChatButton && isSameUser &&
          <ChatButton
            isLoading={isLoading}
            onClick={handleStartChat}
            className="user-info__chat-link"
          />}
      </div>

      <div className="user-info__info">
        {
          showUserLink ? (
            <Link className="user-info__user-link" to={getUserPublicViewLink(user)}>
              <h6 className="user-info__fullname">{fullName}</h6>
            </Link>
          ) : (
            <h6 className="user-info__fullname">{fullName}</h6>
          )
        }

        <div className="user-info__stats">
          <Rating rating={rating} size={ratingStarsSize} />

          <Link className="user-info__reviews-link" to={getUserPublicViewLink(user)}>
            {totalReviewsCount} {t('shared.words.reviews')}
          </Link>
        </div>
      </div>
    </div>
  )
}

UserInfo.propTypes = {
  user: PropTypes.object,
  chatId: PropTypes.string,
  showChatButton: PropTypes.bool,
  showBackgroundReportsLink: PropTypes.bool,
  ratingStarsSize: PropTypes.number,
  showUserLink: PropTypes.bool,
}

export default UserInfo
