import React from 'react';
import get from 'lodash/get';

import {
  switchToTalent as switchToTalentApi,
  switchToManager as switchToManagerApi,
  getConnectedTalents as getConnectedTalentsApi,
} from 'api/appApi/auth/profiles';

import { useGetTranslate, useGetTranslateWithKey } from 'store/hooks/globalState/useTranslates';
import { setTokens } from 'utils/storage';
import { useSetUser, useSetIsUserLoading, useUser } from 'store/hooks/globalState/useUser';
import { useSetFileLibrary } from 'store/hooks/globalState/useFileLibrary';
import { useSetConnectedTalents } from 'store/hooks/globalState/useConnectedTalents';
import { libraryInitialState } from 'store/hooks/globalState/useGlobalState';
import { notify } from 'utils/notify';
import { getFullName, getTalentPlaqueNickname, getUserPlaqueNickname } from 'utils/user'
import { useHistory, useRouteMatch } from 'react-router-dom';

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

function getNotifyName (userData, t) {
  return get(userData, 'displayName') || getFullName(userData) || t('switch-profile.talent-profile');
}

export function useFetchConnectedTalents () {
  const setConnectedTalents = useSetConnectedTalents((prev, next) => next);

  return React.useCallback(() => {
    setConnectedTalents(null);
    getConnectedTalentsApi()
      .then(({ profiles }) => {
        setConnectedTalents(profiles);
      })
      .catch(error => console.warn(error));
  },
  [setConnectedTalents]);
}

export function useSwitchToTalent() {
  const t = useGetTranslate();
  const tKey = useGetTranslateWithKey();
  const setIsSwitching = useSetIsUserLoading((prev, next) => next)
  const setTalent = useSetUser((prev, next) => ({ ...prev, talent: next.talent, user: next.user }));
  const clearFileLibrary = useSetFileLibrary(() => libraryInitialState);
  const handleResponseError = useHandleResponseError()
  const talentPlaqueNickname = useUser(getTalentPlaqueNickname)
  const userPlaqueNickname = useUser(getUserPlaqueNickname)
  const isUserOnProfileEdit = useRouteMatch({ path: '/'+talentPlaqueNickname, exact: true }) !== null
  const isUserOnPreviewProfile = useRouteMatch({ path: '/user/'+talentPlaqueNickname, exact: true }) !== null || useRouteMatch({ path: '/user/'+userPlaqueNickname, exact: true }) !== null
  const history = useHistory();

  return React.useCallback((switchProfileId) => {
    setIsSwitching(true)

    switchToTalentApi(switchProfileId)
      .then(({ userData, accessToken, refreshToken, twilioAccessToken }) => {
        setTokens({ accessToken, refreshToken, twilioAccessToken });
        setTalent(userData);
        clearFileLibrary();
        const notifyLabel = tKey({
          tKey: 'switch-profile.notify.success',
          phs: [{ ph: '{{name}}', value: getNotifyName(userData?.talent, t) }]
        });

        setTimeout(() => notify(notifyLabel), 10);

        if(isUserOnProfileEdit) {
          history.push(userData.talent.plaqueNickname);
        }
        else if (isUserOnPreviewProfile) {
          history.push('/user/'+userData.talent.plaqueNickname);
        }
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
      .finally(() => setIsSwitching(false))
  }, [setTalent, clearFileLibrary, tKey, t, setIsSwitching]);
}

export function useSwitchToManager() {
  const t = useGetTranslate();
  const tKey = useGetTranslateWithKey();
  const setIsSwitching = useSetIsUserLoading((prev, next) => next)
  const setUser = useSetUser((prev, next) => ({ ...prev, talent: undefined, user: next }));
  const clearFileLibrary = useSetFileLibrary(() => libraryInitialState);
  const handleResponseError = useHandleResponseError()
  const talentPlaqueNickname = useUser(getTalentPlaqueNickname)
  const userPlaqueNickname = useUser(getUserPlaqueNickname)
  const history = useHistory();
  const isUserOnPreviewProfile = useRouteMatch({ path: '/user/'+talentPlaqueNickname, exact: true }) !== null || useRouteMatch({ path: '/user/'+userPlaqueNickname, exact: true }) !== null


  return React.useCallback(() => {
    setIsSwitching(true)

    switchToManagerApi()
      .then(({ userData, refreshToken, accessToken, twilioAccessToken }) => {
        setTokens({ accessToken, refreshToken, twilioAccessToken });
        setUser(userData.user);
        clearFileLibrary();

        const notifyLabel = tKey({
          tKey: 'switch-profile.notify.success',
          phs: [{ ph: '{{name}}', value: getNotifyName(userData?.talent, t) }]
        });

        setTimeout(() => notify(notifyLabel), 10);

        if (isUserOnPreviewProfile) {
          history.push('/user/' + userData.user.plaqueNickname);
        }
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
      .finally(() => setIsSwitching(false))
  }, [setUser, clearFileLibrary, tKey, t, setIsSwitching]);
}
