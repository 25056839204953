import { compose, filter, map, props, where, cond, prop, equals, allPass, pick } from 'lodash/fp'
import kebabCase from 'lodash/kebabCase';
import differenceInSeconds from 'date-fns/differenceInSeconds'
import min from 'lodash/min'
import { getSelectValue } from 'utils/forms/adapters/select'
import { types } from 'shared';

const { FIXED, FLEXIBLE } = types.experiences.TypesOfDate;

const {
  RECURRING_DAY_TIME_OPTION,
  UP_TO_5_DAY_TIME_OPTIONS,
} = types.experiences.DaysTimesType;


const typesOfDate = [FIXED, FLEXIBLE];

const getTypesOfDateItemLabel = type =>
  `create-experience.form.types-of-date.${kebabCase(type)}.label`;

export const getTypesOfDateWithTranslate = t =>
  typesOfDate.map(type => ({
    value: type,
    label: t(getTypesOfDateItemLabel(type)),
  }));

export const isTimeWindow = compose(
  equals(UP_TO_5_DAY_TIME_OPTIONS),
  getSelectValue,
  prop('daysTimesType')
);
export const isRecurring = compose(
  equals(RECURRING_DAY_TIME_OPTION),
  getSelectValue,
  prop('daysTimesType')
)
export const isCustomRecurring = compose(
  equals('CUSTOM'),
  getSelectValue,
  prop('typeOfRecurring')
)

const toDate = date => new Date(date)

const isTimeSelected = where({ startTime: Boolean, endTime: Boolean })

export const getWindowDuration = compose(
  ([l, r]) => differenceInSeconds(r, l),
  map(toDate),
  props(['startTime.value', 'endTime.value'])
)

const findSmallestWindow = compose(
  min,
  map(getWindowDuration),
  filter(isTimeSelected),
)

// formState
export const getMaxFlexibleEventDuration = cond([
  [isTimeWindow, compose(findSmallestWindow, prop('timeWindows'))],
  [allPass([isRecurring, isTimeSelected]), compose(getWindowDuration, pick(['startTime', 'endTime']))],
])
