import React from 'react'
import cx from 'classnames'

import './index.scss'

import { ReactComponent as ChatIcon } from '../../assets/chat-icon.svg'
import { ReactComponent as ChatOffIcon } from '../../assets/chat-off-icon.svg'
import useChatContext from '../../hooks/useChatContext'

export default function ToggleChatButton() {
  const { isChatWindowOpen, setIsChatWindowOpen, conversation, hasUnreadMessages } = useChatContext()

  const toggleChatWindow = () => {
    setIsChatWindowOpen(!isChatWindowOpen)
  }

  return (
    <button
      className={cx('video-button video-button--chat', { 'video-button--unread-messages': hasUnreadMessages })}
      onClick={toggleChatWindow}
      disabled={!conversation}
    >
      {isChatWindowOpen ? <ChatOffIcon /> : <ChatIcon />}
      Chat
    </button>
  )
}
