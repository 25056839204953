export const DATE = 'date'
export const LOCATION = 'location'
export const REQUESTS = 'requests'
export const PARTICIPANTS = 'participants'
export const STATUS = 'status'

export const elementsTypes = {
  [DATE]: DATE,
  [LOCATION]: LOCATION,
  [REQUESTS]: REQUESTS,
  [PARTICIPANTS]: PARTICIPANTS,
  [STATUS]: STATUS
}

import DateTime from './DateTime'
import Location from './Location'
import Requests from './Requests'
import Participants from './Participants'
import Status from './Status'

export const RECURRING_LIST = 'recurring-list'

export const recurringListComponents = {
  [DATE]: DateTime,
  [LOCATION]: Location,
  [REQUESTS]: Requests,
  [PARTICIPANTS]: Participants,
  [STATUS]: Status
}
