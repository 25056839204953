import { _post, _patch } from '../../utils';

export const asTalent = (data) => _post(
  '/auth/sign-up/talent',
  { needAuthorization: true, body: data }
);

export const asAficionado = (data) => _post(
  '/auth/sign-up/user/aficionado',
  { needAuthorization: false, body: data }
)

export const asUser = (data) => _post(
  '/auth/sign-up/user/talent',
  { body: data }
);

export const asTalentDraft = (data) => _patch(
  '/auth/sign-up/talent',
  { needAuthorization: true, body: data }
)

