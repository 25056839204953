import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import ModalPortal from 'portals/ModalPortal'
import Button from '_legacy/components/Button'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { isSafari } from 'utils/isBrowser';

const ChangeRouteModal = ({
  isOpen,
  closeModal,
  onDiscard,
  onDraft,
  onSubmit,
  isDraft
}) => {
  const t = useGetTranslate()
  return (
    <ModalPortal showCloseButton isOpen={isOpen} onClose={closeModal} isMobileFullScreen>
      <StyledModal>
        <TextContainer>
          <StyledHeader>{t('prompt-modal.header')}</StyledHeader>
          <StyledText>{t('prompt-modal.text')}</StyledText>
        </TextContainer>
        <ButtonContainer className="buttons-wrapper" isSafari={isSafari()}>
          <Button
            stretch
            primary
            text={t('prompt-modal.button.back-to-editing')}
            typeButton="button"
            handleOnClick={closeModal}
            paddingVertical={13}
          />
          <Button
            stretch
            primary
            text={t('prompt-modal.button.discard-changes')}
            typeButton="button"
            handleOnClick={onDiscard}
            paddingVertical={13}
          />
          {isDraft ? (
            <Button
              stretch
              text={t('prompt-modal.button.save-as-draft')}
              typeButton="button"
              handleOnClick={onDraft}
              paddingVertical={13}
            />
          ) : (
            <Button
              stretch
              text={t('prompt-modal.button.save-and-publish')}
              typeButton="submit"
              handleOnClick={onSubmit}
              paddingVertical={13}
            />
          )}
        </ButtonContainer>
      </StyledModal>
    </ModalPortal>
  )
}

const StyledModal = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.modal};
  box-shadow: ${({ theme }) => theme.boxShadows.modal};

  display: flex;
  flex-direction: column;

  padding: 16px 40px 40px 40px;
  background: white;

  @media screen and (max-width: 1440px) {
    padding: 16px 24px 24px 24px;
  }

  @media screen and (max-width: 575px) {
    max-width: 100%;
    height: 100%;
    border-radius: 0;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 575px) {
    flex: 1;
    justify-content: center;
  }
`

const StyledHeader = styled.h1`
  font-size: 32px;
  font-weight: 700;
  font-family: 'Playfair Display', sans-serif;
  line-height: 1.25;
  color: ${({ theme }) => theme.colors.blue};
  margin: 0 auto 24px;
  text-align: center;

  @media screen and (max-width: 1440px) {
    font-size: 28px;
  }

  @media screen and (max-width: 575px) {
    font-size: 23px;
    margin-bottom: 24px;
  }
`

const StyledText = styled.p`
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: ${({ theme }) => theme.colors.grey};
  line-height: 1.625;
  margin: 16px 0;

  @media screen and (max-width: 575px) {
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  
  ${({ isSafari }) => isSafari && css`
    .button--primary:hover .button__text {
      background-image: none;
      -webkit-text-fill-color: ${({ theme }) => theme.colors.blue};
    }
  `};

  @media screen and (max-width: 1023px) {
    grid-template-rows: repeat(3, 1fr);
    grid-template-columns: 1fr;
  }
`

ChangeRouteModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  isDraft: PropTypes.bool,
  onDiscard: PropTypes.func,
  onDraft: PropTypes.func,
  onSubmit: PropTypes.func,
}

export default ChangeRouteModal
