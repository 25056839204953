import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const Checkbox = ({ className, icon, item, checked, clickHandler }) => {
  const handleClick = React.useCallback(() => {
    if (!clickHandler) return
    clickHandler(item)
  }, [item, clickHandler])

  return (
    <StyledWrapper
      className={className}
      checked={checked}
      onClick={handleClick}
    >
      <div className={icon} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  border: 2px solid ${({ theme: { colors } }) => colors.blueText};
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
  :hover {
    border-color: ${({ theme: { colors } }) => colors.blue};
  }
  ${({ checked }) => checked && css`
    background: ${({ theme: { colors } }) => colors.blue};
    border-color: ${({ theme: { colors } }) => colors.blue};
  `};
  .chekmark {
    width: 6px;
    height: 12px;
    border-right: solid 2px #fff;
    border-bottom: solid 2px #fff;
    transform: rotate(40deg) translate(-1px, -1px);
  }
  .minus {
    width: 8px;
    height: 2px;
    background: #fff;
  }
`

Checkbox.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  item: PropTypes.oneOfType([
    PropTypes.object, PropTypes.array, PropTypes.string, PropTypes.number
  ]),
  checked: PropTypes.bool,
  clickHandler: PropTypes.func,
}

Checkbox.defaultProps = {
  className: '',
  icon: 'chekmark'
}

export default Checkbox
