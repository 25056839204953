import React from 'react'
import { identity, range } from 'lodash'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form'
import cx from 'classnames'
import './index.scss'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'

const labels = {
  1: 'rating.label.terrible',
  2: 'rating.label.poor',
  3: 'rating.label.average',
  4: 'rating.label.good',
  5: 'rating.label.excellent',
}

const StarRating = ({
  name = 'rating',
  disabled,
  defaultChecked,
  onClick = identity,
}) => {
  const t = useGetTranslate()
  const { register, watch } = useFormContext()
  const value = watch(name)
  const [hover, setHover] = React.useState()

  return (
    <div className={cx('star-rating', { 'star-rating--disabled': disabled })}>
      {range(1, 6).map(star => (
        <label
          key={star}
          className={cx('star-rating__label', {
            'star-rating__label--active': star <= (+hover || +value),
          })}
          onClick={onClick}
          onMouseEnter={() => setHover(star)}
          onMouseLeave={() => setHover(null)}
        >
          <input
            disabled={disabled}
            name={name}
            type="radio"
            ref={register()}
            value={String(star)}
            defaultChecked={String(defaultChecked) === String(star)}
            className="star-rating__input"
          />
          <Icon.RatingStar className="star-rating__icon" />
          <span className="star-rating__star-label">{t(labels[star])}</span>
        </label>
      ))}
    </div>
  )
}

StarRating.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func,
  name: PropTypes.string,
}

export default StarRating
