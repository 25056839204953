import { _get, _post, _patch } from 'api/utils';

export const createReadyMadeEvent = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/ready-made-events`,
  { body: data, needAuthorization: true },
);
export const createReadyMadeEventDraft = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/ready-made-events/drafts`,
  { body: data, needAuthorization: true },
);

export const getReadyMadeEventDraft = ({ talentId, talentReadyMadeEventDraftId }) => _get(
  `/talents/${talentId}/offers/ready-made-events/drafts/${talentReadyMadeEventDraftId}`,
  { needAuthorization: true },
);

export const updateReadyMadeEventDraft = ({ talentId, talentReadyMadeEventDraftId, data }) => _patch(
  `/talents/${talentId}/offers/ready-made-events/drafts/${talentReadyMadeEventDraftId}`,
  { body: data, needAuthorization: true },
);
