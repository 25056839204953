import { pipe, find, propEq, propOr } from 'lodash/fp'

export const langOptions = [
  { value: 'EN', label: 'English' },
  { value: 'ES', label: 'Español' },
  { value: 'PT', label: 'Português' },
]

export const getLangLabelByCode = pipe(
  code => find(propEq('value', code), langOptions),
  propOr('', 'label')
)

