import { TypesOfDays } from 'shared'
import castArray from 'lodash/castArray';
import get from 'lodash/get';
import compact from 'lodash/compact';

import {
  getCharitiesForForm,
  getCharitiesForSubmit,
} from 'utils/services/loaders/charities';
import {
  getMultiSelectLibraryAdapter,
  submitMultiSelectLibraryAdapter
} from 'utils/services/validators/library';

export const videoChatDefaultValues = {
  price: '',
  // defaultTaboos: [],
  customTaboos: [],
  enableInYourServices: 'true',
  whichCharities: [],
  media: [],
  mediaTemp: []
}

export const daysTimesTypeOptions = [
  {
    value: TypesOfDays.ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
    label: 'video-chat.form.days-times-type.options.any-available-day-time-in-talent-calendar',
  },
  {
    value: TypesOfDays.ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
    label: 'video-chat.form.days-times-type.options.allow-aficionado-to-propose-day-time',
  },
  {
    value: TypesOfDays.BOTH,
    label: 'video-chat.form.days-times-type.options.both',
  }
]


export function getVideoChatAdapter(data, t) {
  const daysTimesTypeOption = daysTimesTypeOptions.find(option => option.value === data.daysTimesType)
  return {
    price: String(get(data, 'price')),
    defaultTaboos: data.defaultTaboos || [],
    customTaboos: data.customTaboos || [],
    enableInYourServices: String(data.active),
    whichCharities: getCharitiesForForm(data.whichCharities),
    duration: data.duration / 60,
    daysTimesType: { ...daysTimesTypeOption, label: t(daysTimesTypeOption?.label) },
    ...getMultiSelectLibraryAdapter('media', data)
  };
}

export function submitVideoChatAdapter(formState) {
  return {
    price: Number(formState.price),
    enableInYourServices: formState.enableInYourServices === 'true',
    taboos: {
      defaultTaboos: formState.defaultTaboos,
      customTaboos: compact(castArray(formState.customTaboos)),
    },
    whichCharities: getCharitiesForSubmit(formState.whichCharities),
    duration: Number(formState.duration) * 60,
    daysTimesType: formState.daysTimesType.value,
    ...submitMultiSelectLibraryAdapter('media', formState)
  };
}
