import get from 'lodash/get';
import { api } from 'shared';

import { ReactComponent as LiveVideoChats } from 'assets/offerings-icons/live-video-chats.svg';
import { ReactComponent as VideoMessage } from 'assets/offerings-icons/video-message.svg';
import { ReactComponent as Lessons } from 'assets/offerings-icons/lessons.svg';
import { ReactComponent as VideoMessageForCommercialUse } from 'assets/offerings-icons/video-message-for-commercial-use.svg';
import { ReactComponent as ReadyMadeLesson } from 'assets/offerings-icons/ready-made-lesson.svg';
import { ReactComponent as VirtualEvent } from 'assets/offerings-icons/virtual-event.svg';
import { ReactComponent as InPersonExperience } from 'assets/offerings-icons/in-person-experience.svg';

const {
  FOR_COMMERCIAL_USE, IN_PERSON_EXPERIENCE, LIVE_IN_PERSON_LESSON, READY_MADE_LESSON,
  VIDEO_CHAT, VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, VIRTUAL_EVENT, VIRTUAL_LESSON,
} = api.talents.talent.offers.query.TypeFilter;

export const offeringsInfo = (offerings) => ({
  [IN_PERSON_EXPERIENCE]: {
    label: 'table.filters.offerings-types.in-person-experience',
    Icon: InPersonExperience,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'experience.primaryMedia.file', {}),
  },
  [VIDEO_MESSAGE]: {
    label: 'table.filters.offerings-types.video-message',
    Icon: VideoMessage,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'videoMessage.primaryMedia.file', {}),
  },
  [VIDEO_CHAT]: {
    label: 'table.filters.offerings-types.live-video-chat',
    Icon: LiveVideoChats,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'videoChat.primaryMedia.file', {}),
  },
  [VIDEO_VOICE_OVER_LESSON]: {
    label: 'table.filters.offerings-types.video-voice-over-lesson',
    Icon: Lessons,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'videoVoiceOverLesson.primaryMedia.file', {}),
  },
  [VIRTUAL_LESSON]: {
    label: 'table.filters.offerings-types.virtual-lesson',
    Icon: Lessons,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'virtualLesson.primaryMedia.file', {}),
  },
  [LIVE_IN_PERSON_LESSON]: {
    label: 'table.filters.offerings-types.live-in-person-lesson',
    Icon: Lessons,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'liveInPersonLesson.primaryMedia.file', {}),
  },
  [VIRTUAL_EVENT]: {
    label: 'table.filters.offerings-types.virtual-event',
    Icon: VirtualEvent,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'liveVirtualEvent.primaryMedia.file', {}),
  },
  [READY_MADE_LESSON]: {
    label: 'table.filters.offerings-types.ready-made-lesson',
    Icon: ReadyMadeLesson,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'readyMadeEvent.primaryMedia.file', {}),
  },
  [FOR_COMMERCIAL_USE]: {
    label: 'table.filters.offerings-types.video-message-for-commercial-use',
    Icon: VideoMessageForCommercialUse,
    title: get(offerings, 'title', ''),
    price: get(offerings, 'price', 'N/A'),
    media: get(offerings, 'commercialVideoMessage.primaryMedia.file', {}),
  },
});

