import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from '_legacy/components/Button';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const BookNowButton = ({ onClick, isLink, path, disabled }) => {
  const t = useGetTranslate()
  return (
    <StyledButton
      text={t('shared.words.book-now')}
      handleOnClick={onClick}
      path={path}
      isLink={isLink}
      fontSize="0.875"
      paddingVertical="10"
      paddingHorizontal="22"
      disabled={disabled}
    />
  )
};

const StyledButton = styled(Button)`
  .button {
    &__item {
      padding: 7px 14px;
    }
    &__text {
      font-size: 14px;
    }
  }
`

BookNowButton.propTypes = {
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  path: PropTypes.string,
};

export default BookNowButton
