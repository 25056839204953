import { _post } from '../../utils';

export const checkPasswordForReset = ({ data, resetPasswordKey }) => _post(
  `/auth/check-password/reset-password/${resetPasswordKey}`,
  { body: data },
);

export const checkPasswordForChange = ({ data }) => _post(
  '/auth/check-password/change-password',
  { body: data, needAuthorization: true },
);
