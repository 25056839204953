export const INVITED_USER = 'invitedUser'
export const EMAIL = 'email'
export const ROLE = 'role'
export const STATUS = 'status'

export const elementsTypes = {
  [INVITED_USER]: INVITED_USER,
  [EMAIL]: EMAIL,
  [ROLE]: ROLE,
  [STATUS]: STATUS
}

import InvitedUser from './InvitedUser'
import Email from './Email'
import Role from './Role'
import Status from './Status'

export const PROFILE_MANAGERS = 'profile-managers'

export const profileManagersComponents = {
  [INVITED_USER]: InvitedUser,
  [EMAIL]: Email,
  [ROLE]: Role,
  [STATUS]: Status
}
