import React from 'react'
import { isFunction } from 'lodash'

import { getTalentId } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { editTalentsCalendarSlot } from '../api/calendar'
import { useFetchTalentCalendar } from './talent-calendar'
import { useTalentCalendarFilters } from '../store/useCalendar'

export function useEditEvent() {
  const talentId = useUser(getTalentId)
  const calendarFilters = useTalentCalendarFilters()
  const fetchTalentCalendar = useFetchTalentCalendar(talentId)

  return React.useCallback(
    ({ slotId, data }, onSuccess) => {
      editTalentsCalendarSlot({ slotId, talentId, data }).then(() => {
        fetchTalentCalendar(calendarFilters)

        if (isFunction) {
          onSuccess()
        }
      })
    },
    [talentId, calendarFilters, fetchTalentCalendar]
  )
}
