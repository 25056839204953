import React from 'react';
import PropTypes from 'prop-types'

import Button from '_legacy/components/Button'

const OfferingDetailsBookingContact = ({
  title,
  text,
  contactText,
  contactCallback,
  cancelText,
  cancelCallback,
}) => {
  return (
    <div className="od--with-padding od__column od--centered">
      <h3 className="od--uppercase-title mb-24">{title}</h3>
      <p className="od--main-text mb-24">{text}</p>
      <Button
        stretch
        handleOnClick={contactCallback}
        text={contactText}
        typeButton="submit"
        fontSize="0.875"
        paddingVertical="16"
        paddingHorizontal="10"
        classBtn="button mb-16"
      />
      <Button
        stretch
        primary
        paddingVertical={16}
        text={cancelText}
        handleOnClick={cancelCallback}
      />
    </div>
  )
}

OfferingDetailsBookingContact.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  contactText: PropTypes.string,
  contactCallback: PropTypes.func,
  cancelText: PropTypes.string,
  cancelCallback: PropTypes.func,
}

export default OfferingDetailsBookingContact;
