import { _post } from '../../utils';

export const checkPhone = ({ phone }) => _post(
  '/verify/phone/check',
  { body: { phone } },
);

export const validatePhone = ({ phone }) => _post(
  '/verify/phone/validate',
  { body: { phone } },
)
