import React from 'react';
import styled from 'styled-components';
import { Container } from '_legacy/common';
import { types } from 'shared'
import prop from 'lodash/fp/prop'

const { PHOTO, VIDEO } = types.file.Types

import { useUser } from 'store/hooks/globalState/useUser';

import AccountTemplate from '_legacy/containers/Account/AccountTemplate';
import MobileEditProfileTemplate from '_legacy/containers/Account/EditProfile/MobileEditProfileTemplate'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'
import { useFetchEditProfile } from 'requests/edit-profile';
import { useFetchBulkFileLibrary } from 'requests/file-library';
import DesktopEditProfileTemplate from '_legacy/containers/Account/EditProfile/DesktopEditProfileTemplate';
import { getFileLibraryIds, getTalentId } from 'utils/user'

const EditProfile = () => {
  const windowWidth = useWindowDimensions(prop('width'))

  const talentId = useUser(getTalentId)
  const fileLibrariesIds = useUser(getFileLibraryIds)

  const fetchEditProfile = useFetchEditProfile()
  const fetchFileLibrary = useFetchBulkFileLibrary()

  React.useEffect(() => {
    fetchEditProfile();
  }, [talentId])

  React.useEffect(() => {
    fetchFileLibrary({ fileLibrariesIds, types: [PHOTO, VIDEO] })
  }, [fileLibrariesIds])

  return (
    <AccountTemplate isNested={true} title={'account.menu.profile.edit-profile'}>
      <StyledWrapper>
        <Container>
          {windowWidth <= 1024
            ? <MobileEditProfileTemplate />
            : <DesktopEditProfileTemplate />}
        </Container>
      </StyledWrapper>
    </AccountTemplate>
  );
};

const StyledWrapper = styled.div`
  .sub-title {
    margin-bottom: 10px;
  }
  .radio-wrapper {
    display: flex;
    align-items: center;
    @media only screen and (max-width: 768px) {
      flex-direction: column;
      label {
        width: 100%;
        margin-right: 0;
        margin-bottom: 16px;
      }
    }
  }
  .description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #626473;
    line-height: 1.625;
    margin-top: 16px;
    margin-bottom: 0;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
`;

export default EditProfile;
