import React from 'react'
import { types } from 'shared'
import { isEmpty } from 'lodash/fp'
import cond from 'lodash/cond'
import { FormProvider, useForm } from 'react-hook-form'

import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { getTimezoneAbbr, parseDate } from 'utils/services/timezone'
import { getMonthDayYearDate, getTimeRange } from 'utils/date'
import { getLocationTimeZone } from 'utils/location'
import { useBookExperience, useBookOccurrence } from 'requests/public-views/experience/experience-booking'

import JoinModal from '_legacy/components/PublicView/JoinModal'
import PublicInfoForm from '_legacy/components/PublicView/PublicInfoForm'
import SelectFormItem from '_legacy/components/PublicView/SelectFormItem'
import PublicFormItem from '_legacy/components/PublicView/PublicFlexibleFormItemContext'
import { buildLocations, buildOccurrences, fillSelectedLocation, getJoinButtonKey, getProposeButtonKey } from '../utils'
import { useStateModal } from 'store/hooks/globalState/useModal';
import { ALERT_MODAL } from '_legacy/components/Modal/modals-types'

const { FLEXIBLE } = types.experiences.TypesOfLocation

const SELECT_KEYS = {
  JOIN: 'JOIN',
  PROPOSE: 'PROPOSE',
}

const AllowAficionadoToPropose = React.memo(({ experience }) => {
  const showAlertModal = useStateModal(ALERT_MODAL)
  const { id, locations, pricePerParticipant, typesOfLocation, occurrencesLimit } = experience
  const isFlexibleTypeOfLocation = React.useMemo(() => typesOfLocation === FLEXIBLE, [])

  const t = useGetTranslate()
  const methods = useForm({ shouldUnregister: false })

  const [isSubmitting, setIsSubmitting] = React.useState()
  const [selectedOccurrence, setSelectedOccurrence] = React.useState(null)

  const bookExperience = useBookExperience(setIsSubmitting)
  const bookOccurrence = useBookOccurrence(setIsSubmitting)

  // ****** modal state ******
  const [isJoinModalOpen, openJoinModal, closeJoinModal] = useModal(false)

  // ****** form state ******
  const watchedLocation = methods.watch('location')  || locations?.[0]?.id

  // ****** state ******
  const isPropose = React.useMemo(() => selectedOccurrence && !selectedOccurrence.startTime, [selectedOccurrence])

  const { availableOccurrences, locationTimeZoneAbbr, locationOptions, selectedRange, dateTimeTitle, dateTimeSubTitle } = React.useMemo(() => {
    const availableOccurrences = buildOccurrences(experience)

    // ****** location options ******
    const filledLocations = selectedOccurrence?.bookedLocation
      ? fillSelectedLocation([selectedOccurrence?.bookedLocation], locations)
      : locations
    const locationOptions = buildLocations(filledLocations)
    const selectedLocation = locations.find(location => location.id === watchedLocation)
    const locationTimeZone = getLocationTimeZone(selectedLocation)
    const locationTimeZoneAbbr = getTimezoneAbbr(locationTimeZone)

    // ****** time label ******
    const selectedStartTime = parseDate(selectedOccurrence?.startTime, locationTimeZone)
    const selectedEndTime = parseDate(selectedOccurrence?.endTime, locationTimeZone)

    const dateTimeTitle = cond([
      [() => isPropose, () => t('experience-public-view.select.propose')],
      [() => selectedOccurrence, () => getMonthDayYearDate(selectedStartTime)],
      [() => !selectedOccurrence, () => t('experience-public-view.select.choose')],
    ])()

    const dateTimeSubTitle = cond([
      [() => isPropose, () => ''],
      [() => selectedOccurrence, () => getTimeRange([selectedStartTime, selectedEndTime])],
      [() => !selectedOccurrence, () => ''],
    ])()

    return {
      availableOccurrences,
      locationTimeZoneAbbr,
      locationOptions,
      selectedRange,
      dateTimeTitle,
      dateTimeSubTitle
    }
  }, [t, experience, watchedLocation, selectedOccurrence, isPropose])

  const dateTimeOptionsData = [];
  if(!isEmpty(availableOccurrences)) {
    dateTimeOptionsData.push({ value: SELECT_KEYS.JOIN, label: t(getJoinButtonKey(experience)), disabled: isEmpty(availableOccurrences) })
  }
  if(availableOccurrences.length !== occurrencesLimit) {
    dateTimeOptionsData.push({ value: SELECT_KEYS.PROPOSE, label: t(getProposeButtonKey(experience)), disabled: availableOccurrences.length === occurrencesLimit })
  }

  const dateTimeOptions = React.useMemo(() => dateTimeOptionsData, [t, availableOccurrences, occurrencesLimit, experience])

  // ****** handlers ******
  const customOnChange = React.useCallback((value) => {
    if (value === SELECT_KEYS.JOIN) {
      openJoinModal()
    }

    if (value === SELECT_KEYS.PROPOSE) {
      showAlertModal({ message : t('experience-public-view.select.determined') })
      setSelectedOccurrence({
        startTime: undefined,
        endTime: undefined
      })
    }
  }, [])

  // ****** submit ******
  const onSubmit = React.useCallback(() => {
    if (isPropose) {
      bookExperience({
        experienceId: id,
        startTime: undefined,
        endTime: undefined,
        location: watchedLocation,
        includedFriends: [],
        invitedFriends: []
      })
    } else {
      bookOccurrence({
        offerExperienceOccurrenceId: selectedOccurrence.id,
        location: watchedLocation,
        includedFriends: [],
        invitedFriends: []
      })
    }
  }, [selectedOccurrence, isPropose, watchedLocation])

  return (
    <FormProvider {...methods}>
      <PublicInfoForm
        isSubmitting={isSubmitting}
        onSubmit={methods.handleSubmit(onSubmit)}
        pricePerParticipant={pricePerParticipant}
        buttonProps={{ disabled: isEmpty(selectedOccurrence) }}
        locations={locations}
      >
        <h3>{t('experience.info.heading')}</h3>
        <p className="public-view__timezone-reminder">
          {t('experience.info.timezone-reminder-location')}:<div>{locationTimeZoneAbbr}</div>
        </p>

        <div className="public-view__info-list">
          {/*date select*/}
          <SelectFormItem
            header={t('experience-public-view.select.choose')}
            options={dateTimeOptions}
            customTitle={dateTimeTitle}
            subtitle={dateTimeSubTitle}
            onChange={customOnChange}
          />

          {/*location*/}
          {!isEmpty(locationOptions) && isFlexibleTypeOfLocation && (
            <PublicFormItem
              name="location"
              title={t('experience.info.location')}
              items={locationOptions}
            />
          )}
        </div>
      </PublicInfoForm>

      <JoinModal
        isOpen={isJoinModalOpen}
        onClose={closeJoinModal}
        list={availableOccurrences}
        joinHandler={setSelectedOccurrence}
      />
    </FormProvider>
  )
})

export default AllowAficionadoToPropose
