import { isValid } from 'date-fns'

import { getShortMonthDate, getDaysOrHoursDuration } from 'utils/date'
import { parseDate } from 'utils/services/timezone'


const getCountLabel = (date, timeZone) =>
  getDaysOrHoursDuration(
    parseDate(new Date(), timeZone),
    date,
  )

export const getStepDate = (date, timeZone, isDue = false) => {
  if(!isValid(date)) return 'Pending'

  return isDue
    ? getCountLabel(date, timeZone)
    : getShortMonthDate(date)
}
