import React, { useState } from 'react'
import PropTypes from 'prop-types'

// components
import CustomVideo from '_legacy/common/custom-video/CustomVideo'

import ModalPortal from 'portals/ModalPortal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import LoaderIndicator from '_legacy/components/Common/Loader'


const VideoModal = ({ isModalOpen, closeModal, file, showPrimaryButton = false, onPrimaryButtonClick }) => {
  const t = useGetTranslate()
  const [isLoading, setIsLoading] = useState(true)

  const onLoad = () => {
    setIsLoading(false)
  }

  const handleClose = () => {
    setIsLoading(true)
    closeModal()
  }

  return (
    <ModalPortal isOpen={isModalOpen} onClose={handleClose} showCloseButton={!isLoading} isMobileFullScreen>
      <div className={`video-modal ${isLoading ? 'loading' : ''}`}>
        <CustomVideo
          className={`video-with-autoplay${isLoading ? '--hidden' : ''}`}
          file={file}
          controls
          onLoadedMetadata={onLoad}
          autoplay
        />
        { isLoading && <LoaderIndicator size={24} fill="#636583" /> }

        {showPrimaryButton && (
          <button className="image-modal__primary-button" onClick={onPrimaryButtonClick}>
            {t('shared.words.make-primary')}
          </button>
        )}
      </div>
    </ModalPortal>
  )
}
VideoModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  file: PropTypes.object,
  showPrimaryButton: PropTypes.bool,
  onPrimaryButtonClick: PropTypes.func,
}
export default VideoModal
