import React from 'react'
import styled from 'styled-components'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Icon from 'assets/icons/Icon'
import BackdropWrapper from '../../components/Backdrop/BackdropWrapper';

const HeadshotBackdrop = () => {
  const t = useGetTranslate()
  return (
    <HeadshotBackdropWrapper>
      <Icon.Camera />
      <Title>{t('register.upload.hint.label-short')}</Title>
    </HeadshotBackdropWrapper>
  )
}

const HeadshotBackdropWrapper = styled(BackdropWrapper)`
  border-radius: 50%;
`

const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 1.625;
  color: #272835;
  margin-top: 16px;
  margin-bottom: 0;
  text-align: center;
`

export default HeadshotBackdrop
