import { _get, _post, _patch } from 'api/utils';

export const createExperience = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/experiences`,
  { body: data, needAuthorization: true },
);
export const createExperienceDraft = ({ talentId, data }) => _post(
  `/talents/${talentId}/offers/experiences/drafts`,
  { body: data, needAuthorization: true },
);

export const getExperienceById = ({ talentId, experienceId }) => _get(
  `/talents/${talentId}/offers/experiences/${experienceId}`,
  { needAuthorization: true },
)

export const getExperienceDraftById = ({ talentId, experienceDraftId }) => _get(
  `/talents/${talentId}/offers/experiences/drafts/${experienceDraftId}`,
  { needAuthorization: true },
)

export const updateExperienceById = ({ talentId, experienceId, data }) => _patch(
  `/talents/${talentId}/offers/experiences/${experienceId}`,
  { needAuthorization: true, body: data },
)

export const updateExperienceDraftById = ({ talentId, experienceDraftId, data }) => _patch(
  `/talents/${talentId}/offers/experiences/drafts/${experienceDraftId}`,
  { needAuthorization: true, body: data },
)
