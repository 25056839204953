import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const CalendarHeader = ({ text, timeZoneAbbr }) => {
  const t = useGetTranslate()

  return (
    <>
      <h1 className="talent-calendar-heading">{text}</h1>
      {
        timeZoneAbbr &&
          <p className="talent-calendar-heading__time-zone">
            {t('calendar.talent.current-time-zone')}:
            <span>{timeZoneAbbr}</span>
          </p>
      }
    </>
  )
}

export default CalendarHeader
