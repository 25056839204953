import React from 'react';
import { oneOfType, string, array, object } from 'prop-types';

import CustomPicture from '_legacy/common/CustomPicture';

const getDesktopLayers = (layers = [], altImg) => {
  return layers.map(layer => (
    <CustomPicture
      key={layer.id}
      style={{ zIndex: layer.index }}
      file={{ ...layer.image?.file, originalName: altImg }}
    />
  ));
};

const ImageLayer = ({ layers, altImg, className = '' }) => (
  <div className={`tpv-image-layer ${className}`}>{getDesktopLayers(layers, altImg)}</div>
);

export default ImageLayer

ImageLayer.propTypes = {
  layers: oneOfType([array, object]),
  altImg: string,
  className: string,
};
