import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
// Assets
import Icon from 'assets/icons/Icon'

function Arrows({ direction, onClick, visible }) {
  if(!visible) return null

  return (
    <StyledWrapper onClick={onClick} direction={direction}>
      {direction === 'left' ? <Icon.ArrowSliderPrev /> : <Icon.ArrowSliderNext />}
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  padding: 0 40px;
  position: absolute;
  top: 25%;
  ${props => (props.direction === 'left' ? 'left: 0;' : 'right: 0;')};
  cursor: pointer;
  z-index: 2;
  transition: all .25s;

  :hover {
    transform: scale(1.5);

    svg path {
      stroke: white;    
     }
  }
`

Arrows.propTypes = {
  direction: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  visible: PropTypes.bool
}

Arrows.defaultProps = {
  direction: 'right',
  visible: true
}

export default Arrows
