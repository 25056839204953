// Modules
import React from 'react'
import { addMonths } from 'date-fns'

// Assets
import Icon from 'assets/icons/Icon'
import AppIcon from 'assets/images/app-icon.png'

// Store (hooks)
import { useGetTranslate, useTranslates } from 'store/hooks/globalState/useTranslates'

// Utils
import { getCookie, setCookie } from 'utils/cookies'


const isSafari = () => {
  const ua = window.navigator.userAgent;
  const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
  const webkit = !!ua.match(/WebKit/i);
  return iOS && webkit && !ua.match(/CriOS/i);
}

const isStandalone = () => window.matchMedia('(display-mode: standalone)').matches

const suspenda2hsModal = months => setCookie('APP_SAFARI_HIDE_A2HS', true, { expires: addMonths(new Date(), months) })


const IOSa2hsModal = () => {
  const [showModal, setShowModal] = React.useState(false)
  const translates = useTranslates()
  const t = useGetTranslate()

  React.useEffect(() => {
    if(!getCookie('APP_SAFARI_HIDE_A2HS') && isSafari()) {
      setShowModal(true)
    }
  }, [])

  const onNotNowClick = () => {
    suspenda2hsModal(1)
    setShowModal(false)
  }

  const onDontShowMeAgainClick = () => {
    suspenda2hsModal(999)
    setShowModal(false)
  }

  if (!translates || !showModal || isStandalone()) return null

  return (
    <div className="ios-a2hs-modal__wrapper">
      <div className="ios-a2hs-modal">
        <Icon.Close
          className="ios-a2hs-modal__close"
          onClick={onNotNowClick}
        />

        <h4 className="ios-a2hs-modal__title mb-16">{t('a2hs.header')}</h4>

        <div className="ios-a2hs-modal__body mb-16">
          <img className="ios-a2hs-modal__body__logo" src={AppIcon} alt='app-icon' />

          <ul className="ml-16">
            <li className="mb-8">
              {t('a2hs.steps.1a')}
              <Icon.A2HSSafari className="ios-a2hs-modal__body__save-button-img" />
              {t('a2hs.steps.1b')}
            </li>
            <li>{t('a2hs.steps.2')}</li>
          </ul>
        </div>

        <div className="ios-a2hs-modal__actions mb-16">
          <button type="button" className="button__item" onClick={onNotNowClick}>{t('a2hs.not-now')}</button>
          <button type="button" className="button__item" onClick={onDontShowMeAgainClick}>{t('a2hs.not-ever')}</button>
        </div>

        <p className="ios-a2hs-modal__cta">{t('a2hs.cta')}</p>

        <span className="ios-a2hs-modal__pointer" />
      </div>
    </div>
  )
}

export default IOSa2hsModal
