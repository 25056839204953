import { api } from 'shared'
const { PROXIMITY } = api.searches.results.offers.search.SortField

export const parseProximity = params => {
  const { sortField, sortByLocationLatitude, sortByLocationLongitude, ...rest } = params

  if (sortField === PROXIMITY && sortByLocationLatitude && sortByLocationLongitude) {
    return {
      ...rest,
      sortField,
      sortByLocationLatitude,
      sortByLocationLongitude
    }
  }

  return {
    ...rest,
    sortField
  }
}
