import {
  PROFILE_MANAGERS,
  profileManagersComponents,
} from './profile-managers/config'
import {
  MANAGE_OFFERINGS,
  manageOfferingsComponents,
} from './manage-offerings/config'
import { PROMO_CODES, promoCodesComponents } from './manage-promo-codes/config'
import {
  RECURRING_LIST,
  recurringListComponents,
} from './recurring-list/config'
import { ORDERS, manageOrdersComponents } from './manage-orders/config'
import { MY_MEDIA, myMediaComponents } from './my-media/config'
import { NOTIFICATIONS, notificationsComponents } from './notifications/config'
import { REVIEWS, reviewsComponents } from './reviews/config'
import { OFFERINGS_DETAILS_REQUESTS, OFFERINGS_DETAILS_PARTICIPANTS, orderDetailsComponents } from './order-details/config'
import { PRODUCT_FILES, productFilesComponents } from './product-files/config'
import {
  READY_MADE_EVENT_LESSONS_DETAILS_ORDERS,
  readyMadeEventLessonsOrdersComponents,
} from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/components/ReadyMadeEventLessonsDetailsOrdersTable/configs/tableConfig'
import {
  READY_MADE_EVENT_LESSONS_DETAILS_PRODUCT,
  readyMadeEventLessonsProductComponents,
} from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/components/ReadyMadeEventLessonsDetailsProductTable/configs/tableConfig'
import { MY_BOOKINGS, myBookingsComponents } from './my-bookings/config'
import { BOOKING_DETAILS_PARTICIPANTS, bookingParticipantsComponents } from './booking-participants/config'


const tableComponents = {
  [PROFILE_MANAGERS]: profileManagersComponents,
  [MANAGE_OFFERINGS]: manageOfferingsComponents,
  [PROMO_CODES]: promoCodesComponents,
  [RECURRING_LIST]: recurringListComponents,
  [ORDERS]: manageOrdersComponents,
  [MY_MEDIA]: myMediaComponents,
  [NOTIFICATIONS]: notificationsComponents,
  [REVIEWS]: reviewsComponents,
  [OFFERINGS_DETAILS_REQUESTS]: orderDetailsComponents,
  [OFFERINGS_DETAILS_PARTICIPANTS]: orderDetailsComponents,
  [READY_MADE_EVENT_LESSONS_DETAILS_ORDERS]: readyMadeEventLessonsOrdersComponents,
  [READY_MADE_EVENT_LESSONS_DETAILS_PRODUCT]: readyMadeEventLessonsProductComponents,
  [PRODUCT_FILES]: productFilesComponents,
  [MY_BOOKINGS]: myBookingsComponents,
  [BOOKING_DETAILS_PARTICIPANTS]: bookingParticipantsComponents
}

export default tableComponents
