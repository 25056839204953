import { _get, _patch } from '../../utils';

export const getProfileToEdit = () => _get(
  '/auth/me/profile/edit',
  { needAuthorization: true }
);

export const editProfile = ({ talent, user, agentOrManager, talentUserUser }) => _patch(
  '/auth/me/profile/edit',
  { needAuthorization: true, body: { talent, user, agentOrManager, talentUserUser } }
);

export const editTalentAsDraft = ({ talent }) => _patch(
  '/auth/me/profile/edit/talent/draft',
  { needAuthorization: true, body: talent }
)
