import React from 'react';
import { Trans } from 'store/hooks/globalState/useTranslates'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Silhouette from 'assets/images/about-us-silhouette.png';

const configPurposes = [
  {
    title: 'about-us.purposes.item1.title',
    desc: 'about-us.purposes.item1.desc',
  },
  {
    title: 'about-us.purposes.item2.title',
    desc: 'about-us.purposes.item2.desc',
  },
  {
    title: 'about-us.purposes.item3.title',
    desc: 'about-us.purposes.item3.desc',
  },
  {
    title: 'about-us.purposes.item4.title',
    desc: 'about-us.purposes.item4.desc',
  },
  {
    title: 'about-us.purposes.item5.title',
    desc: 'about-us.purposes.item5.desc',
  },
  {
    title: 'about-us.purposes.item6.title',
    desc: 'about-us.purposes.item6.desc',
  },
  {
    title: 'about-us.purposes.item7.title',
    desc: 'about-us.purposes.item7.desc',
  },
  {
    title: 'about-us.purposes.item8.title',
    desc: 'about-us.purposes.item8.desc',
  },
  {
    title: 'about-us.purposes.item9.title',
    desc: 'about-us.purposes.item9.desc',
  },
];

export const AboutUs = () => {
  const t = useGetTranslate()
  return (
    <div className="about-us wrapper content-wrapper">
      <div className='about-us__main'>
        <div className='about-us__main--circle'>
          <p className='about-us__main--text about-us__main--text-right'>
            <Trans tKey='about-us.slogan1' />
          </p>
          <p className='about-us__main--text about-us__main--text-left '>
            <Trans tKey='about-us.slogan2' />
          </p>
          <div className='about-us__main--silhouette'>
            <img src={Silhouette} alt='Silhouette' />
          </div>
        </div>
        <div className='about-us__main--title'>
          <h4>{t('about-us.silhouette.title1')}</h4>
          <h2>{t('about-us.silhouette.title2')}</h2>
        </div>
      </div>

      <div className='about-us__title'>
        <h2>{t('about-us.title')}</h2>
        <div className='about-us__title--wr'>
          <p>
            <Trans tKey='about-us.desc1' />
          </p>
          <p>
            <Trans tKey='about-us.desc2' />
          </p>
        </div>
      </div>

      <div className="about-us__mission">
        <div className='about-us__mission_header'>
          <h3 className="about-us__mission_title">{t('about-us.mission.title')}</h3>
        </div>
        <p className='about-us__mission_content'>
          <Trans>
            To democratize the <strong>Talent-to-Fan</strong> connection.
          </Trans>
        </p>
      </div>

      <div className='about-us__purposes'>
        <h2 className='about-us__purposes_title'>{t('about-us.purposes.title')}</h2>
        <div className='about-us__purposes_wr'>
          {
            configPurposes.map(({ title, desc }, idx) => (
              <div key={idx} className="about-us__purposes_item">
                <h4>{t(title)}</h4>
                <p>{t(desc)}</p>
              </div>
            ))
          }
        </div>
      </div>

    </div>
  );
};
