import React from 'react'
import PropTypes from 'prop-types'
import './utils/offeringCard.scss'
import { EntourageSubscriptionType } from 'shared'

import Price from '_legacy/components/Price'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { ReactComponent as EntourageIcon } from 'components/EntouragePublicPage/icons/EntourageIcon.svg'


const type = {
  [EntourageSubscriptionType.FULL]: 'payment-page.entourage-card.full',
  [EntourageSubscriptionType.LITE]: 'payment-page.entourage-card.lite',
}

const EntourageCard = ({ entourageOrder }) => {
  const t = useGetTranslate()
  return (
    <div className="offering-card">
      <div className="offering-card__background">
        <EntourageIcon className="offering-card__logo" />
      </div>

      <div className="offering-card__content">
        <div className="offering-card__fullName">
          <p className="offering-card__name-offering offering-card__name-offering--center">
            {t(type[entourageOrder.entourageType])}
          </p>
        </div>

        <div className="offering-card__price">
          <Price withCurrency price={entourageOrder.price} className="offering-card__newprice" />
        </div>
      </div>
    </div>
  )
}

EntourageCard.propTypes = {
  data: PropTypes.object,
  discountPrice: PropTypes.number,
};

export default EntourageCard;
