import React from 'react'
import './index.scss'


import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useNotificationsTableFilters,
  useSetNotificationsTableFilters,
  useNotificationsTable,
} from 'store/hooks/globalState/useNotificationsState'

import {
  fields,
  filtersConfig,
  counterConfig,
  subFiltersConfig,
} from '_legacy/configs/table/notifications'
import {
  NOTIFICATIONS,
  elementsTypes
} from '_legacy/components/Table/table-components/types/notifications/config'
import { useFetchNotificationsTable, useMarkAsRead, useRemoveNotification } from 'requests/notifications';


import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import SettingsButton from './TableComponents/SettingButton'
import MobileCardHeader from './TableComponents/MobileCardHeader'


const tableConfig = [
  {
    header: 'table.header.notification',
    type: elementsTypes.notification
  }
]


const Notifications = () => {
  const t = useGetTranslate()
  const removeNotification = useRemoveNotification()
  const markAsRead = useMarkAsRead()

  const actionsConfig = React.useMemo(() => {
    return [
      { label: t('table.actions.remove'), cbFn: removeNotification },
    ]
  }, [t])


  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useNotificationsTableFilters,
      setFilters: useSetNotificationsTableFilters,
      actions: [
        { label: t('table.actions.remove'), cbFn: removeNotification },
        { label: t('table.actions.mark-as-read'), cbFn: markAsRead },
      ]
    }
  }, [t, useNotificationsTableFilters, useSetNotificationsTableFilters])


  return (
    <div className='notifications-page'>
      <h1 className='notifications-page__header'>
        {t('notifications-table.header')}
      </h1>
      <div className='notifications-table wrapper content-wrapper'>
        <Filters
          filtersConfig={filtersConfig}
          getFn={useNotificationsTableFilters}
          setFn={useSetNotificationsTableFilters}
          ButtonComponent={SettingsButton}
        />
        <SubFilters
          counterConfig={counterConfig}
          subFiltersConfig={subFiltersConfig}
          getFn={useNotificationsTableFilters}
          setFn={useSetNotificationsTableFilters}
        />
        <Table
          tableName={NOTIFICATIONS}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          bulkActionsConfig={bulkActionsConfig}
          fields={fields}
          getTableFn={useNotificationsTable}
          getFiltersFn={useNotificationsTableFilters}
          setFiltersFn={useSetNotificationsTableFilters}
          fetchTableFn={useFetchNotificationsTable}
          withRejected
          components={{
            MobileCardHeader
          }}
        />
      </div>
    </div>
  )
}

export default Notifications
