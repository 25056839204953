import React from 'react';
import PropTypes from 'prop-types';
import { types } from 'shared'
import { useFormContext } from 'react-hook-form';

import { useModal } from 'store/hooks/useModal';

import FileName from './components/FileName';
import LibraryWrapper from '../components/Library/LibraryWrapper';
import SingleSelectLibraryModal from './components/SingleSelectLibraryModal';
import SingleSelectLibraryButton from './components/SingleSelectLibraryButton';

const { VIDEO, PHOTO } = types.file.Types

const SingleSelectLibrary = React.memo(({ name, rules, dropZoneProps, components, libraryModalProps }) => {
  const tempName = name + 'Temp'
  const [isOpen, openModal, closeModal] = useModal(false);
  const { setValue, getValues } = useFormContext()

  const onClose = () => {
    setValue(tempName, getValues(name))
    closeModal()
  }

  const onOpen = () => {
    setValue(tempName, getValues(name))
    openModal()
  }

  const onSubmit = () => {
    setValue(name, getValues(tempName), { shouldDirty: true, shouldValidate: true })
    closeModal()
  }

  return (
    <React.Fragment>
      <LibraryWrapper rules={rules} name={name}>
        <SingleSelectLibraryButton
          onClick={onOpen}
          name={name}
          maxSize={dropZoneProps?.maxSize}
          {...components}
        />
        <FileName name={name} />
      </LibraryWrapper>
      <SingleSelectLibraryModal
        name={tempName}
        isOpen={isOpen}
        onSubmit={onSubmit}
        closeModal={onClose}
        dropZoneProps={dropZoneProps}
        libraryModalProps={libraryModalProps}
      />
    </React.Fragment>
  )
});

SingleSelectLibrary.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  dropZoneProps: PropTypes.objectOf(
    PropTypes.shape({
      accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      hintComponent: PropTypes.element,
      multiple: PropTypes.bool,
      noClick: PropTypes.bool,
      noDrag: PropTypes.bool,
      maxSize: PropTypes.number,
    })
  ),
  libraryModalProps: PropTypes.objectOf(PropTypes.shape({
    header: PropTypes.string,
    submitBtnText: PropTypes.string,
    enableTypes: PropTypes.oneOf([VIDEO, PHOTO]),
  })),
  components: PropTypes.objectOf(
    PropTypes.shape({
      customPicture: PropTypes.element,
      customBackdrop: PropTypes.element,
    })
  ),
};

export default SingleSelectLibrary;
