import React from 'react'
import PropTypes from 'prop-types'

import ModalPortal from 'portals/ModalPortal'
import Button from '_legacy/components/Button'
import LoaderIndicator from '_legacy/components/Common/Loader'

const ModalWithControls = ({ isOpen, onClose, header, message, buttonsConfig, isLoading }) => {
  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <div className="modal-with-controls">
        {isLoading ? (
          <LoaderIndicator className="modal-with-controls__loader" size={24} fill="#636583" />
        ) : (
          <>
            <h3 className="modal-with-controls__header">{header}</h3>
            <p className="modal-with-controls__message">{message}</p>
            <div className="modal-with-controls__controls">
              {buttonsConfig.map((btnProps, idx) => (
                <Button stretch key={idx} {...btnProps} />
              ))}
            </div>
          </>
        )}
      </div>
    </ModalPortal>
  )
}

ModalWithControls.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  header: PropTypes.string,
  message: PropTypes.string,
  buttonsConfig: PropTypes.array,
  isLoading: PropTypes.bool,
}

export default ModalWithControls
