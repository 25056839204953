import React from 'react'
import PropTypes from 'prop-types'

import { Row } from '_legacy/common'
import SelectComponent from '../../../Form/Selects/SelectComponent' // todo: refactor with components
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getDayTypesWithTranslate } from './config'

const SetDaysTime = ({ name = 'daysTimesType' }) => {
  const t = useGetTranslate()
  const options = getDayTypesWithTranslate(t)

  return (
    <Row
      isRequired
      nameBlock={t('create-experience.form.set-days.label')}
      component={
        <SelectComponent
          name={name}
          placeholder={t('create-experience.form.set-days.placeholder')}
          options={options}
          rules={{
            required: 'create-experience.form.set-days.errors.required',
          }}
        />
      }
    />
  )
}

SetDaysTime.propTypes = {
  name: PropTypes.string,
}

export default SetDaysTime
