import map from 'lodash/map'

const licenseItemAdapter = (licenseItem) => {
  return {
    ...licenseItem,
    file: {
      ...licenseItem.file,
      name: licenseItem.file.originalName
    }
  }
}

export const getLicensesAdapter = licenses => {
  return (licenses || []).map(licenseItemAdapter).filter(Boolean)
}

export const submitLicensesAdapter = licenses => {
  if (!licenses) return

  return map(licenses, 'fileToken').filter(Boolean)
}
