import React from 'react';

import RowTextarea from '_legacy/common/Textarea/RowTextarea';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const WhatToBring = () => {
  const t = useGetTranslate();
  return (
    <RowTextarea
      label={t('create-experience.form.what-to-bring.label')}
      name="whatToBring"
      placeholder={t('create-experience.form.what-to-bring.placeholder')}
      max={200}
      height={150}
    />
  );
};

export default WhatToBring;
