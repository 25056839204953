import React from 'react'
import { useParams } from 'react-router-dom'
import { types } from 'shared'
import { where, isEqual, uniqueId, sortBy, cond, T, compose, propEq } from 'lodash/fp'

import {
  getLiveVirtualEvent,
  getLiveVirtualEventOccurrence,
} from 'api/appApi/offers/publicViews'

import {
  useSetLiveVirtualEvents,
  useSetLiveVirtualEventsOccurrence,
} from 'store/hooks/globalState/usePublicView'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const TypesOfDate = types.experiences.TypesOfDate

export const getDoesNotRepeatOccurrence = ({
  startTime,
  endTime,
  maxNumberOfParticipants,
  participantsCount = 0
}) => ([{
  id: uniqueId('id'),
  startTime: startTime,
  endTime: endTime,
  date: startTime,
  maxNumberOfParticipants: maxNumberOfParticipants - participantsCount,
}])

export const getSlotByOccurrence = ({
  occurrence,
  maxNumberOfParticipants,
}) => ({
  id: uniqueId('id'),
  offerLiveVirtualEventOccurrenceId: occurrence?.id,
  startTime: occurrence?.startTime,
  endTime: occurrence?.endTime,
  date: occurrence?.startTime,
  maxNumberOfParticipants: maxNumberOfParticipants - occurrence.participantsCount,
})

export const getSlotsByOccurrence = ({
  availableOccurrences,
  maxNumberOfParticipants,
}) =>
  availableOccurrences
    .map(occurrence =>
      getSlotByOccurrence({
        occurrence,
        maxNumberOfParticipants,
      }))


const isAvailableOccurrences = where({
  typesOfDate: isEqual(TypesOfDate.FIXED),
})

const isExpWithoutAvailableOccurrence = where({
  availableOccurrences: propEq('length', 0)
})

export const getLiveVirtualEventTimeSlots = compose(
  sortBy('date'),
  cond([
    [isExpWithoutAvailableOccurrence, getDoesNotRepeatOccurrence],
    [isAvailableOccurrences, getSlotsByOccurrence],
    [T, getSlotsByOccurrence],
  ])
)

export function useFetchLiveVirtualEvent() {
  const setLiveVirtualEventsPublicView = useSetLiveVirtualEvents((_, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getLiveVirtualEvent({ talentPlaqueNickname, offerSequentialId })
      .then(({ liveVirtualEvent, currentUserPersonalData }) => {
        setLiveVirtualEventsPublicView({
          liveVirtualEvent,
          currentUserPersonalData,
          slots: getLiveVirtualEventTimeSlots(liveVirtualEvent)
        });
      })
      .catch(error => {
        handleResponseError(error)
      })
  }, [setLiveVirtualEventsPublicView, talentPlaqueNickname, offerSequentialId])
}

export function useFetchLiveVirtualEventOccurrence() {
  const setLiveVirtualEventsOccurrence = useSetLiveVirtualEventsOccurrence((prev, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getLiveVirtualEventOccurrence({ talentPlaqueNickname, offerSequentialId })
      .then(({ virtualOccurrence, currentUserPersonalData }) => {
        setLiveVirtualEventsOccurrence({ virtualOccurrence, currentUserPersonalData });
      })
      .catch(error => handleResponseError(error))
  }, [setLiveVirtualEventsOccurrence, talentPlaqueNickname, offerSequentialId])
}

