import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import classnames from 'classnames'

import MediaPlayer from './MediaPlayer'

import './index.scss'

const PublicSliderSlide = ({ file, className }) => {
  const media = get(file, 'file')

  if (!media) return null

  const classNames = classnames(
    'public-slider-slide',
    className,
  )

  return (
    <div className={classNames}>
      <MediaPlayer className={'public-slider-player'} media={media} />
    </div>
  )
}

PublicSliderSlide.propTypes = {
  file: PropTypes.object,
  className: PropTypes.string,
}

export default PublicSliderSlide
