import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const BoldButton = ({ className, ...props }) => {
  const classes = cx('bold-button', className)
  return (
    <button className={classes} type='button' {...props} />
  )
}

BoldButton.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default BoldButton
