import React from 'react'

import ModalPortal from 'portals/ModalPortal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const instructions = [
  'how-to-use-calendar-modal.instructions.1',
  'how-to-use-calendar-modal.instructions.2',
  'how-to-use-calendar-modal.instructions.3',
  'how-to-use-calendar-modal.instructions.4',
  'how-to-use-calendar-modal.instructions.5',
  'how-to-use-calendar-modal.instructions.6',
  'how-to-use-calendar-modal.instructions.7',
]

const HowToUseCalendarModal = React.memo(({ onClose, isOpen }) => {
  const t = useGetTranslate()
  return (
    <ModalPortal onClose={onClose} isOpen={isOpen} showCloseButton isMobileFullScreen>
      <div className="how-to-use-calendar-modal">
        <h1 className="how-to-use-calendar-modal__header">{t('how-to-use-calendar-modal.header')}</h1>
        <ul className="how-to-use-calendar-modal__instruction-list">
          {instructions.map(tKey => (
            <li className="how-to-use-calendar-modal__instruction-item" key={tKey}>
              {t(tKey)}
            </li>
          ))}
        </ul>
      </div>
    </ModalPortal>
  )
})

export default HowToUseCalendarModal
