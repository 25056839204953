import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import get from 'lodash/get';

import { ErrorMessage } from '../ErrorMessage';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import StyledTextarea from './StyledTextarea';
import CharactersTip from '../CharactersTip';

const Textarea = React.memo(function Textarea({
  isRequired,
  name,
  type = 'text',
  placeholder = '',
  validation = {},
  className,
  shouldShowError = true,
  isDisabled,
  height,
  max,
  ...props
}) {
  const { register, errors } = useFormContext();
  const error = get(errors, name)
  const t = useGetTranslate();
  const registerText = t('shared.words.required');

  return (
    <StyledWrapper isDisabled={isDisabled} className={`textarea-wrapper ${className}`}>
      <StyledTextarea
        className='textarea'
        name={name}
        type={type}
        placeholder={placeholder}
        ref={register({ required: isRequired && registerText, maxLength: max,  ...validation })}
        error={Boolean(errors?.[name])}
        autocomplete='off'
        isDisabled={isDisabled}
        height={height}
        {...props}
      />
      <HintWrapper>
        {shouldShowError && error && <ErrorMessage className='error' message={error?.message} />}
        <CharactersTip maxLength={max} name={name} />
      </HintWrapper>
    </StyledWrapper>
  );
});

const HintWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  
  ${({ isDisabled }) => isDisabled && css`
    cursor: not-allowed;
  `}

`;

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  validation: PropTypes.object,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  shouldShowError: PropTypes.bool,
  isDisabled: PropTypes.bool,
  height: PropTypes.number,
  max: PropTypes.number,
};

export default Textarea;
