import React from 'react'
import useMainParticipant from '../../hooks/useMainParticipant'
import useVideoContext from '../../hooks/useVideoContext'
import ParticipantTracks from '../ParticipantTracks'
import MainParticipantInfo from '../MainParticipantInfo'

export default function MainParticipant() {
  const mainParticipant = useMainParticipant()
  const { room } = useVideoContext()
  const localParticipant = room?.localParticipant

  const videoPriority = mainParticipant !== localParticipant ? 'high' : null

  return (
    <MainParticipantInfo participant={mainParticipant}>
      <ParticipantTracks
        participant={mainParticipant}
        videoOnly
        enableScreenShare={mainParticipant !== localParticipant}
        videoPriority={videoPriority}
        isLocalParticipant={mainParticipant === localParticipant}
      />
    </MainParticipantInfo>
  )
}
