import get from 'lodash/get'

export const parseParticipant = params => {
  const { participants, ...rest } = params
  const participantsCountFrom = get(participants, 'from')
  const participantsCountTo = get(participants, 'to')

  return {
    ...rest,
    participantsCountFrom,
    participantsCountTo,
  }
}

export const parseOrderParticipant = params => {
  const { participants, ...rest } = params
  const numberOfParticipantsFrom = get(participants, 'from')
  const numberOfParticipantsTo = get(participants, 'to')

  return {
    ...rest,
    numberOfParticipantsFrom,
    numberOfParticipantsTo,
  }
}
