import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import get from 'lodash/get'

import Icon from 'assets/icons/Icon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const CategoriesSelectListItem = ({ item, handleClick, withCount, isSelected: active, showGridIcon }) => {
  const t = useGetTranslate()

  const label = t(get(item, 'n', ''))
  const talentsCount = get(item, 'c', 0)
  const nested = get(item, 'sc', 0) > 0

  const listItemClasses = cx('cascader-list__list-item', {
    'cascader-list__list-item--nested': nested,
    'cascader-list__list-item--active': active,
  })

  return (
    <li onClick={handleClick} className={listItemClasses} >
      <p className='all-categories'>
        {showGridIcon && <Icon.GridIcon className='cascader-list__icon' />}
        {label}
      </p>
      {withCount && <span className="cascader-list__counter">{talentsCount}</span>}
    </li>
  )
}

CategoriesSelectListItem.propTypes = {
  item: PropTypes.object,
  handleClick: PropTypes.func,
  isSelected: PropTypes.bool,
  showGridIcon: PropTypes.bool,
  withCount: PropTypes.string,
}

export default CategoriesSelectListItem

