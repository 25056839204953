// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'

import get from 'lodash/get'
import head from 'lodash/fp/head'
import pipe from 'lodash/fp/pipe'
import propOr from 'lodash/fp/propOr'
import lte from 'lodash/fp/lte'

import cx from 'classnames'

// Store (hoooks)
import { useChatsList, useChatClient, useIsLoadingChatsList } from 'store/hooks/globalState/useChats'
import { useSearchAficionados } from 'store/hooks/globalState/useSearchList'
import { useIsUserLoading } from 'store/hooks/globalState/useUser'
import { useFetchUserChat, useFetchUserChats } from 'requests/chats'

// Utils
import { sortChatsByUpdatedDate } from '../helpers'

// Legacy components
import LoaderIndicator from '_legacy/components/Common/Loader'

// Components
import AficionadosList from './AficionadosList'
import ChatsHeader from './ChatsHeader'
import ChatsList from './ChatsList'

// Constants
import { SECURE_CHAT } from '_legacy/constants/routes'


const ChatsPanel = ({ isMobile }) => {
  const history = useHistory()
  const { chatId } = useParams()

  const chatClient = useChatClient()

  const fetchUserChat = useFetchUserChat()
  const fetchUserChats = useFetchUserChats()

  const chats = sortChatsByUpdatedDate(Object.values(get(useChatsList(), 'list', {})))

  const pageInfo = useChatsList(propOr(null,  'pageInfo'))

  const isUserLoading = useIsUserLoading()
  const isChatsLoading = useIsLoadingChatsList()
  const isChatsInitialLoading = isChatsLoading && get(pageInfo, 'page', 0) < 1
  const isLoading = isUserLoading || isChatsInitialLoading || !chatClient

  const aficionados = useSearchAficionados(propOr([],  'list'))
  const isSearchingAficionados = useSearchAficionados(pipe(propOr(0,  'searchLike.length'), lte(2)))

  const hasMore = get(pageInfo, 'remains', 0) > 0

  const fetchChatById = () => {
    if (chatId && chatClient) {
      fetchUserChat({ chatId, chatClient })
    }
  }

  const fetchNextPage = () => {
    if (!isChatsLoading) {
      fetchUserChats({ chatClient, page: get(pageInfo, 'page', 0) + 1 })
    }
  }

  const reFetchChats = () => {
    if (chatClient) {
      fetchUserChats({ chatClient })
    }
  }

  const setFirstChatAsActive = () => {
    if (!chatId && !isMobile) {
      const firstChatInList = head(chats)

      if (firstChatInList) {
        return history.replace(`/${SECURE_CHAT}/${firstChatInList.chatInfo.id}/`)
      }
    }
  }

  React.useEffect(fetchChatById, [chatId, chatClient])

  React.useEffect(setFirstChatAsActive, [chatId, chats.length, isMobile])

  React.useEffect(reFetchChats, [chatClient])

  const mobileClassName = cx({ 'mobile-view': isMobile })

  return (
    <div className={`chats__left-panel ${mobileClassName}`}>
      <ChatsHeader />

      {isLoading && (
        <div className="chats__loader">
          <LoaderIndicator size={24} fill="#636583" />
        </div>
      )}

      {!isLoading && isSearchingAficionados && <AficionadosList list={aficionados} />}

      {!isLoading && !isSearchingAficionados && (
        <ChatsList
          hasMore={hasMore}
          list={chats}
          fetchNext={fetchNextPage}
        />
      )}
    </div>
  )
}

ChatsPanel.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default ChatsPanel
