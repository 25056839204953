import { api, errorNames } from 'shared'

const { INTERNAL_SERVER_ERROR } = errorNames.common

const {
  INVALID_PASSWORD_TO_REMOVE_ACCOUNT,
  ALREADY_HAS_ACTIVE_REQUEST,
  REQUEST_ALREADY_IN_PROCESSING
} = api.auth.me.requests.removeAccounts.request.errors


const {
  REMOVE_ACCOUNT_KEY_ALREADY_USED,
  REMOVE_ACCOUNT_KEY_IS_EXPIRED,
  REMOVE_ACCOUNT_KEY_NOT_FOUND
} = api.verify.removeAccount.confirm.errors

export const removeRequestErrorsConfig = {
  [INVALID_PASSWORD_TO_REMOVE_ACCOUNT]: 'account.menu.delete.request.error.invalid-password',
  [ALREADY_HAS_ACTIVE_REQUEST]: 'account.menu.delete.request.error.already-has-active',
  [REQUEST_ALREADY_IN_PROCESSING]: 'account.menu.delete.request.error.already-in-processing',
  [REMOVE_ACCOUNT_KEY_ALREADY_USED]: 'account.menu.delete.request.error.account-key-already-used',
  [REMOVE_ACCOUNT_KEY_IS_EXPIRED]: 'account.menu.delete.request.error.account-key-is-expired',
  [REMOVE_ACCOUNT_KEY_NOT_FOUND]: 'account.menu.delete.request.error.account-key-not-found',
  [INTERNAL_SERVER_ERROR]: 'shared.words.internal-server-error',
}
export const removeVerifyRequestErrorsConfig = {
  [INVALID_PASSWORD_TO_REMOVE_ACCOUNT]: 'account.menu.delete.request-verify.error.invalid-password',
  [ALREADY_HAS_ACTIVE_REQUEST]: 'account.menu.delete.request-verify.error.already-has-active',
  [REQUEST_ALREADY_IN_PROCESSING]: 'account.menu.delete.request-verify.error.already-in-processing',
  [REMOVE_ACCOUNT_KEY_ALREADY_USED]: 'account.menu.delete.request-verify.error.account-key-already-used',
  [REMOVE_ACCOUNT_KEY_IS_EXPIRED]: 'account.menu.delete.request-verify.error.account-key-is-expired',
  [REMOVE_ACCOUNT_KEY_NOT_FOUND]: 'account.menu.delete.request-verify.error.account-key-not-found',
  [INTERNAL_SERVER_ERROR]: null,
}
