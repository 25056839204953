// TODO: refactor this component structure and styles
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFormContext } from 'react-hook-form';
import identity from 'lodash/identity'

const PublicRadioInputItem = ({ value, title, label, className, name, customOnChange = identity }) => {
  const t = useGetTranslate()
  const { register } = useFormContext()

  return (
    <StyledRadio className={className}>
      <input
        type="radio"
        name={name}
        ref={register()}
        value={value}
        onChange={() => customOnChange({ value, name })}
      />

      <i />

      <div>
        { title &&
          <StyledTitle>{title}</StyledTitle>
        }

        <span>{t(label)}</span>
      </div>
    </StyledRadio>
  );
};

const StyledTitle = styled.label`
  font-weight: 700;
  margin-bottom: 4px;
`;

const StyledRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  &:not(:last-of-type) {
    margin-right: 45px;
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &:checked + i:after {
      content: ' ';
      background: black;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
    }
  }

  i {
    position: relative;
    height: 26px;
    width: 26px;
    min-width: 26px;
    margin-right: 14px;
    background: transparent;
    border: 2px solid #000;
    cursor: pointer;
    appearance: none;
    outline: none;
    border-radius: 50%;
  }

  & > div {
    display: flex;
    flex-direction: column;
  }

  span {
    color: #000;
    line-height: 1.5;
  }
`;

PublicRadioInputItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
};

export default PublicRadioInputItem;
