import React from 'react'
import cx from 'classnames'
import { Types } from 'shared/dist/src/types/file'
import styled from 'styled-components'
import get from 'lodash/get'
import PropTypes from 'prop-types'

import { useModal } from 'store/hooks/useModal'

import CustomPicture from '_legacy/common/CustomPicture'
import CustomVideo from '_legacy/common/custom-video/CustomVideo'
import ImageModal from '_legacy/components/Modal/ImageModal'
import VideoModal from '_legacy/components/Modal/VideoModal'
import Loader from '_legacy/components/Common/Loader'

const isImage = fileType => fileType === 'image' || fileType === Types.PHOTO
const isVideo = fileType => fileType === 'video' || fileType === Types.VIDEO

const FilePreview = ({ className, file, iconSize, isLoading, resource }) => {
  const fileType = get(file, 'type')
  const [modalState, openModal, closeModal] = useModal(false)
  const PreviewModal = isImage(fileType) ? ImageModal : VideoModal

  const iconClasses = cx('item-preview__icon', {
    'icon-image-fill': isImage(fileType),
    'icon-play-circle-o': isVideo(fileType),
  })

  const preview = isImage(fileType) ? (
    <CustomPicture className="item-preview__image" file={file} />
  ) : (
    <CustomVideo className="item-preview__video" file={file} />
  )

  return (
    <FilePreviewContainer className={className}>
      <div className={resource ? `${resource} item-preview` : " item-preview "} onClick={openModal}>
        {preview}
        {isLoading ? <Loader fill="#fff" /> : <i className={iconClasses} style={{ fontSize: iconSize }} />}
      </div>
      <PreviewModal
        closeModal={closeModal}
        isModalOpen={modalState}
        file={file}
      />
    </FilePreviewContainer>
  )
}

const FilePreviewContainer = styled.div`
  width: 100%;
  height: 100%;
`

FilePreview.propTypes = {

  className: PropTypes.string,
  file: PropTypes.object,
  iconSize: PropTypes.oneOfType[PropTypes.number, PropTypes.string],
  isLoading: PropTypes.bool

}

export default FilePreview
