// Modules
import React from 'react'
import { Router, Link } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'

// Routing
import * as r from '_legacy/constants/routes'
import { history } from 'utils/history'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'

// Utils
import { closeOverlay } from '../../utils'

// Legacy components
import LoginForm from '_legacy/components/SignIn/LoginForm'

// Components
import BottomNavigationOverlay from '../../BottomNavigationOverlay'


const Login = () => {
  const t = useGetTranslate()
  const user = useUser()
  const isUserLoggedIn = !isEmpty(user)

  React.useEffect(() => {
    isUserLoggedIn && closeOverlay()
  }, [isUserLoggedIn])

  return (
    <BottomNavigationOverlay
      title={t('footer.mobile.sign_in')}
      className="sign-in-overlay"
    >
      <LoginForm closeModal={closeOverlay} />
      <div className="login-modal__footer">
        <h6>{t('login.dont-have-account')}</h6>
        <h6>
          {t('login.tip.sign-up')}&nbsp;
          <Router history={history}>
            <Link to={`/${r.REGISTER}/${r.AFICIONADO}`} onClick={closeOverlay}>
              {t('login.tip.aficionado')}
            </Link>{' '}
            {t('login.tip.or')}&nbsp;
            <Link to={`/${r.REGISTER}/${r.USER}`} onClick={closeOverlay}>
              {t('login.tip.talent')}
            </Link>
          </Router>
        </h6>
      </div>
    </BottomNavigationOverlay>
  )
}

export default Login


