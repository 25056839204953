import React from 'react';
import PropTypes from 'prop-types';
import { MARGIN, WIDTH } from './constants';
import List from './List';

const calculateSlidesPosition = (slides, currentSlideIndex) => {
  let newSlides = [...slides];

  newSlides = [
    newSlides[newSlides.length - 1],
    ...newSlides.slice(0, newSlides.length - 1),
  ];

  for (let i = 0; i < currentSlideIndex; i++) {
    newSlides = [...newSlides.slice(1), newSlides[0]];
  }

  for (let i = 0; i > currentSlideIndex; i--) {
    newSlides = [
      newSlides[newSlides.length - 1],
      ...newSlides.slice(0, newSlides.length - 1),
    ];
  }

  return newSlides;
};

const MultipleSlideList = ({
  size,
  currentSlideIndex,
  isMirroredInside,
  slides,
}) => {
  const offsetX = (WIDTH + MARGIN * 2) * (currentSlideIndex + 1) + MARGIN;
  const marginOffsetX = (WIDTH + MARGIN * 2) * currentSlideIndex + MARGIN;

  const newSlides = calculateSlidesPosition(slides, currentSlideIndex);
  const optimizedSlides = [...newSlides.slice(0, 5)];

  return (
    <List marginOffsetX={marginOffsetX} offsetX={offsetX}>
      {optimizedSlides.map((child, idx) =>
        React.cloneElement(child, {
          size,
          isMirroredInside,
          isCurrent: idx === 1,
          isNext: idx === 0 || idx === 2,
        })
      )}
    </List>
  );
};

MultipleSlideList.propTypes = {
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  currentSlideIndex: PropTypes.number.isRequired,
  isMirroredInside: PropTypes.bool.isRequired,
  slides: PropTypes.array.isRequired,
};

export default MultipleSlideList;
