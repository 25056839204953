import React from 'react'
import prop from 'lodash/fp/prop'
import { Link, useHistory } from 'react-router-dom'
import cx from 'classnames'
import './index.scss'

import * as r from '_legacy/constants/routes'

import { useMarkAllAsRead } from 'requests/notifications'
import { useOnClickOutside } from 'store/hooks/useOnClickOutside'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState'
import { useNotificationsMenuFilters } from 'store/hooks/globalState/useNotificationsState'

import Icon from 'assets/icons/Icon'
import NotificationList from './NotificationList'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'
import NotificationCounter from './NotificationCounter'


const NotificationMenu = () => {
  const t = useGetTranslate()
  const history = useHistory()
  const [showNotification, setShowNotification] = React.useState(false)
  const blockFullpage = useSetFullpageBlock((_, next) => next, []);
  const unreadNotificationsCount = useNotificationsMenuFilters(prop('count'))

  const markAllAsRead = useMarkAllAsRead()

  const classes = cx('notification-menu', {
    'notification-menu--active': showNotification
  })

  const onBellClick = () => {
    if (unreadNotificationsCount === 0) history.push(`/${r.NOTIFICATIONS}`)

    if (!showNotification) {
      setShowNotification(true)
      blockFullpage(true)
    } else {
      blockFullpage(false)
      setShowNotification(false)
    }
  }

  const notificationNode = React.useRef()
  useOnClickOutside(notificationNode, () => {
    blockFullpage(false)
    setShowNotification(false);
  })

  const notificationCountLabel = unreadNotificationsCount === 1
    ? t('notification-menu.new-count.singular')
    : unreadNotificationsCount + ' New Notifications' // todo: add translate


  return (
    <div className="user-notifications user-account__notifications" ref={notificationNode}>
      <PopperTooltip Component={t('user-account.tooltip.notifications')} placement={'bottom'} offset={[0, 6]}>
        <div onClick={onBellClick}>
          <i className="icon-bell user-account__notifications--icon" />
          <NotificationCounter unreadNotificationsCount={unreadNotificationsCount} />
        </div>
      </PopperTooltip>
      <div className={classes}>
        <div className='notification-menu__header'>
          <p className='notification-menu__count'>{notificationCountLabel}</p>
          <div className='notification-menu__controls'>
            <button type='button' className='mark-as-read-button' onClick={markAllAsRead}>
              {t('notification-menu.mark-all-as-read')}
            </button>
            <Link to={`/${r.ACCOUNT}/${r.NOTIFICATIONS}`} className='notification-menu__setting-link'>
              <Icon.Settings />
            </Link>
          </div>
        </div>
        <NotificationList />
        <Link
          to={`/${r.NOTIFICATIONS}`}
          className='notification-menu__footer-link'
          onClick={() => setShowNotification(false)}
        >
          {t('notification-menu.show-all-notifications')}
        </Link>
      </div>
    </div>
  )
}

export default NotificationMenu
