import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import { Title } from '_legacy/common';
import EntouragePaymentForm from './EntouragePaymentForm';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useEntourageOrder } from 'store/hooks/globalState/useEntourageOrder'
import { fontsCardElement } from './utils/stylesStripe'
import EntourageCard from './EntourageCard'
import { Redirect } from 'react-router-dom'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const EntouragePurchase = () => {
  const t = useGetTranslate()
  const entourageOrder = useEntourageOrder()

  if (!entourageOrder) {
    return <Redirect to={'/'} />
  }

  return (
    <div className="payment-card">
      <Title className="payment-card__title">{t('payment-page.header')}</Title>
      <div className='payment-card__wrapper'>
        <EntourageCard entourageOrder={entourageOrder} />
        <Elements stripe={stripePromise} options={{ fontsCardElement }}>
          <EntouragePaymentForm entourageOrder={entourageOrder} />
        </Elements>
      </div>
    </div>
  )
};

export default EntouragePurchase;
