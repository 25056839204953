import React from 'react';
import get from 'lodash/get';
import { useHistory, useParams } from 'react-router';
import { api } from 'shared';
const { LIVE_VIRTUAL_EVENT_NOT_FOUND } = api.talents.talent.offers.liveVirtualEvents.liveVirtualEvent.errors;
const { LIVE_VIRTUAL_EVENT_DRAFT_NOT_FOUND } = api.talents.talent.offers.liveVirtualEvents.drafts.draft.errors;

import * as routes from '_legacy/constants/routes';

import {
  getLiveVirtualEventsTemplateById as getLiveVirtualEventsTemplateByIdApi,
  createLiveVirtualEvents as createLiveVirtualEventsApi,
  createLiveVirtualEventsDraft as createLiveVirtualEventsDraftApi,
  getLiveVirtualEventById as getLiveVirtualEventByIdApi,
  getLiveVirtualEventDraftById as getLiveVirtualEventDraftByIdApi,
  patchLiveVirtualEventById as patchLiveVirtualEventByIdApi,
  patchLiveVirtualEventDraftById as patchLiveVirtualEventDraftByIdApi,
} from 'api/appApi/offers/liveVirtualEvents';

import { notify } from 'utils/notify';
import { useTalentId } from 'store/hooks/globalState/useUser';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetLiveVirtualEvents } from 'store/hooks/globalState/useLiveVirtualEvents';
import { useAddFileToEntities } from 'store/hooks/globalState/useFileEntites';
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { useStateModal } from 'store/hooks/globalState/useModal';

import { getLiveVirtualEventsDataAdapter } from '../_legacy/containers/LiveVirtualEvents/utils/createLiveVirtualEventsAdapter';
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';
import { useAddFilesToLibrary } from '../store/hooks/globalState/useFileLibrary'
import { getFilesFromMedia } from '../utils/services/validators/library'

export function useFetchLiveVirtualEventsTemplateById (methods) {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const setLiveVirtualEvents = useSetLiveVirtualEvents((prev, next) => next);
  const addFilesToLibrary = useAddFilesToLibrary()

  return React.useCallback(({ liveVirtualEventTemplateId, isDraft }) => {
    getLiveVirtualEventsTemplateByIdApi({ talentId, isDraft, liveVirtualEventTemplateId })
      .then(({ template }) => {
        if (methods) {

          if (template.media) {
            const media = getFilesFromMedia(template)
            addFilesToLibrary(media)
          }

          const data = getLiveVirtualEventsDataAdapter(template, t);

          methods.reset(data)
        }
      });
  }, [t, talentId, setLiveVirtualEvents, addFilesToLibrary]);
}

export function useCreateLiveVirtualEvents(setIsLoading) {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL);

  return React.useCallback((data) => {
    setIsLoading(true)
    // todo: remove conditional when backend fixed it
    createLiveVirtualEventsApi({ data, talentId })
      .then(({ isPromotionalMediaNeeded }) => {
        if (isPromotionalMediaNeeded) {
          notify(t('live-virtual-events.form.notification.create'));
          openAskModal();
        } else {
          navigate(`/${routes.OFFERINGS}`, t('live-virtual-events.form.notification.create'));
        }
      })
      .catch(err => {
        console.warn(err);
      })
      .finally(() => setIsLoading(false))
  }, [t, talentId, navigate]);
}

export function useCreateLiveVirtualEventsDraft(setIsLoading) {
  const t = useGetTranslate();
  const talentId = useTalentId();
  const navigate = useNavigate();

  return React.useCallback((data) => {
    setIsLoading(true)

    createLiveVirtualEventsDraftApi({ data, talentId })
      .then(() => {
        navigate(`/${routes.OFFERINGS}`, t('live-virtual-events.form.notification.draft'));
      })
      .catch(err => {
        console.warn(err);
      })
      .finally(() => setIsLoading(false))
  }, [t, talentId, navigate]);
}

export function useFetchLiveVirtualEvent() {
  const { id } = useParams();
  const talentId = useTalentId();
  const setLiveVirtualEvents = useSetLiveVirtualEvents((_, next) => next);
  const addFilesToEntities = useAddFileToEntities();
  const history = useHistory();

  return React.useCallback(() => {
    getLiveVirtualEventByIdApi({ talentId, talentLiveVirtualEventId: id })
      .then(({ liveVirtualEvent }) => {
        const media = get(liveVirtualEvent, 'media', []);
        addFilesToEntities(media.map(i => i.file));
        setLiveVirtualEvents(liveVirtualEvent);
      })
      .catch(err => {
        if (err.name === LIVE_VIRTUAL_EVENT_NOT_FOUND) {
          history.push(`/${routes.NOT_FOUND}`);
        }
        console.warn(err);
      });
  }, [talentId, setLiveVirtualEvents, id, addFilesToEntities]);
}

export function useFetchLiveVirtualEventDraft () {
  const { id } = useParams();
  const talentId = useTalentId();
  const setLiveVirtualEvents = useSetLiveVirtualEvents((prev, next) => next);
  const history = useHistory();

  return React.useCallback(() => {
    getLiveVirtualEventDraftByIdApi({ talentId, liveVirtualEventDraftId: id })
      .then(({ liveVirtualEventDraft }) => {
        setLiveVirtualEvents(liveVirtualEventDraft);
      })
      .catch(err => {
        if (err.name === LIVE_VIRTUAL_EVENT_DRAFT_NOT_FOUND) {
          history.push(`/${routes.NOT_FOUND}`);
        }
      });
  }, [talentId, setLiveVirtualEvents, id]);
}

export function useEditLiveVirtualEvent () {
  const t = useGetTranslate();
  const { id } = useParams();
  const talentId = useTalentId();
  const navigate = useNavigate();

  return React.useCallback((body) => {
    patchLiveVirtualEventByIdApi({ talentId, talentLiveVirtualEventId: id, body })
      .then(() => {
        navigate(`/${routes.OFFERINGS}`, t('live-virtual-events.form.notification.edit-success'));
      })
      .catch(err => {
        console.warn(err);
      });
  }, [t, talentId, id]);
}

export function useEditLiveVirtualEventDraft () {
  const t = useGetTranslate();
  const { id } = useParams();
  const talentId = useTalentId();
  const navigate = useNavigate();

  return React.useCallback((body) => {
    patchLiveVirtualEventDraftByIdApi({ talentId, liveVirtualEventDraftId: id, body })
      .then(() => {
        navigate(`/${routes.OFFERINGS}`, t('live-virtual-events.form.notification.edit-success'));
      })
      .catch(err => {
        console.warn(err);
      });
  }, [t, talentId, id, navigate]);
}
