import React from 'react'

import { useFetchCurrency } from 'requests/integrations/currency'

import { TIME_UNITS_IN_MS, TIME_UNITS } from 'utils/convertTime'


const withFetchCurrency = Component => props => {
  const INTERVAL_MIN = 5
  const fetchCurrency = useFetchCurrency()

  React.useEffect(() => {
    fetchCurrency()

    const interval = setInterval(() => {
      fetchCurrency()
    }, TIME_UNITS_IN_MS[TIME_UNITS.m] * INTERVAL_MIN)

    return () => clearInterval(interval)
  }, [])

  return <Component {...props} />
}

export default withFetchCurrency
