import React from 'react';
import { useHistory } from 'react-router-dom';
import uniqBy from 'lodash/uniqBy'

import { notify } from 'utils/notify';

import {
  createPromoCodes as createPromoCodesApi,
  verifyPromoCodes as verifyPromoCodesApi,
} from 'api/appApi/promoCodes';

import { _get } from 'api/utils';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useTalentId } from 'store/hooks/globalState/useUser';
import { LIST, PROMO_CODES } from '_legacy/constants/routes';
import {
  useSetOfferingsPromoCodesFilters,
  useSetOfferingsPromoCodesTable,
} from 'store/hooks/globalState/useOfferingsPromoCodes';
import normalizeParams from 'utils/normalizeParams';

export function useFetchOfferingsPromoCodes () {
  const setOfferingsPromoCodesFilters = useSetOfferingsPromoCodesFilters((prev, next) => ({ ...prev, ...next }));
  const setOfferingsPromoCodesTable = useSetOfferingsPromoCodesTable((prev, next) => uniqBy([...prev, ...next], 'id'));
  const id = useTalentId();

  return React.useCallback((options) => {
    const query = normalizeParams(options);
    _get(`/talents/${id}/promo-codes/search-offers`, {
      needAuthorization: true, query
    })
      .then(({ offers, pageInfo }) => {
        setOfferingsPromoCodesFilters(pageInfo);
        setOfferingsPromoCodesTable(offers);
      })
      .catch((error) => console.warn(error));
  }, [id, setOfferingsPromoCodesFilters, setOfferingsPromoCodesTable]);
}


export const useCreatePromoCodes = () => {
  const t = useGetTranslate()
  const talentId = useTalentId()
  const history = useHistory()

  return React.useCallback(data => {
    createPromoCodesApi({ data, talentId })
      .then(() => {
        notify(t('promo-codes.notification.success'), 'success')
        history.push(`/${PROMO_CODES}/${LIST}`)
      })
      .catch(err => {
        console.warn(err)
        notify(t('promo-codes.notification.error'), 'error')
      })
  },
  [t, talentId])
}

export const useVerifyPromoCodes = (setError) => React.useCallback(promoCode => {
  verifyPromoCodesApi(promoCode).catch(setError)
}, [])
