// Modules
import React from 'react'
import { FileType } from 'shared'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoMessageOccurrence } from './state/requests'
import { useVideoMessageOrder, useSetVideoMessageOrder } from './state/store'

// Utils
import { getDaysTo, getLocale } from 'utils/date'
import { getSaveFullName, getUserLink, getUserTimeZone } from 'utils/user'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getCharitiesTitle, getTalentRefundText } from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import PendingAction from 'components/OfferingDetails/components/PendingAction'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsEscrowFunds from 'components/OfferingDetails/components/OfferingDetailsEscrowFunds'
import UploadVideoVoiceMessageBlock from './components/UploadVideoVoiceMessageBlock'
import OrderExpected from './components/OrderExpected'
import CancelStatus from '../../components/CancelStatus'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import { getVideoMessageOrderConfig } from './utils'

const CommercialVideoMessageOrderDetails = () => {
  const t = useGetTranslate()
  const timeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  // state
  const occurrence = useVideoMessageOrder()
  const clearOccurrence = useSetVideoMessageOrder(() => null)
  const fetchOccurrence = useFetchVideoMessageOccurrence()

  // effects
  React.useEffect(() => {
    fetchOccurrence()
  }, [])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!occurrence) return <PageLoader />

  const { review, order } = occurrence || {}
  const { user, price, upload, pendingAction, recipient, message, totalCharityFee } = occurrence || {}
  const c = getVideoMessageOrderConfig(occurrence)
  const refundText = getTalentRefundText({ t, refundRequests: occurrence.order.refundRequests })
  const daysLeft = getDaysTo(new Date(pendingAction?.due), { locale: getLocale(userLang) })

  return (
    <div className="video-message-order od__margins">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.video-message')}
        title={`${t('shared.offers.order')} #${order.plaqueNumber}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrence, timeZone)} />
          {pendingAction && <PendingAction action={pendingAction} title={t('shared.offers.video-message')} />}
          <div className="od__inner-wrapper">
            <div className="od__row od__column-m mb-32">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.aficionado')}</h6>
                <UserInfo user={user} />
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.video-for')}</h6>
                <p className="od--main-text od--accented">{recipient?.name}</p>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.occasion')}</h6>
                <p className="od--main-text od--accented">{message?.occasionType}</p>
              </div>
            </div>
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.script')}</h6>
                <p className="od--main-text">{message?.script}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          {c.isShowUpload && (
            <UploadVideoVoiceMessageBlock
              offerType={t('shared.offers.video-message')}
              uploadedFileId={upload?.file?.id}
              date={daysLeft}
              orderId={order.id}
              onSuccessCancel={fetchOccurrence}
            />
          )}

          {c.isShowWaitForPayment && (
            <div className="od--shadow-box od--centered">
              <div className="od--with-padding">
                <h3 className="od--uppercase-title mb-24">{t('shared.offers.video-message')}</h3>
                <OrderExpected received={price} media={upload?.file} />
              </div>
              <OfferingDetailsReviewStars
                review={review}
                orderId={order.id}
                onSuccess={fetchOccurrence}
                userLink={getUserLink(user)}
                userFullName={getSaveFullName(user)}
                offerType={t('shared.offers.video-message')}
                reviewType={'order.answer.rate.with'}
              />
            </div>
          )}

          {c.isShowClosed && (
            <div className="od--shadow-box od--centered">
              <div className="od--centered">
                <OfferingDetailsEscrowFunds
                  title={t('shared.offers.video-message')}
                  subtitle={t('order.description.received')}
                  price={order?.totalPrice}
                />
                {!!totalCharityFee && order?.totalPrice && (
                  <OfferingDetailsEscrowFunds
                    className="od--bordered"
                    subtitle={getCharitiesTitle(t, order?.totalPrice, totalCharityFee)}
                    price={totalCharityFee}
                    description={t('order.description.charities.text')}
                  />
                )}
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={order.id}
                  onSuccess={fetchOccurrence}
                  userLink={getUserLink(user)}
                  userFullName={getSaveFullName(user)}
                  offerType={t('shared.offers.video-message')}
                  reviewType={'order.answer.rate.with'}
                />
              </div>
            </div>
          )}

          {c.isShowCanceled && (
            <div className="od--shadow-box od--centered">
              <CancelStatus
                refundText={refundText}
                title={t('shared.offers.video-message')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default withLibrary(CommercialVideoMessageOrderDetails, { types: FileType.VIDEO })
