import { _get, _post } from 'api/utils';

export const getExperiencePublic = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/experiences/public/${talentPlaqueNickname}/${offerSequentialId}`, { needAuthorization: true });

export const bookExperienceTimeSlot = ({ experienceId, ...body }) =>
  _post(`/offers/experiences/${experienceId}/book`, { needAuthorization: true, body })

export const bookExperienceByOccurrenceId = ({ offerExperienceOccurrenceId, ...body }) =>
  _post(`/offers/experiences/occurrences/${offerExperienceOccurrenceId}/book`, { needAuthorization: true, body })
