import { initialStateBiography } from '../components/Biographies/initialStateBiography'
import { initialStateCharities } from '../components/Charities/initialStateCharities'
import { initialStateLanguages } from '../components/Languages/initialStateLanguages'

export default {
  title: '',
  firstName: '',
  lastName: '',
  displayNameType: 'FIRST_NAME_AND_LAST_NAME',
  fullBodyImage: null,
  profileImage: null,
  halfBodyImage: null,
  location: '',
  languages: initialStateLanguages,
  nationality: '',
  charities: initialStateCharities,
  ownWords: '',
  biographies: initialStateBiography,
  licenses: [],
  primaryCategory: [],
  categories: [[]],
  promoCode: null,
}
