import map from 'lodash/map'
import get from 'lodash/get'

const getFileId = file => get(file, 'file.fileToken')

export const getFullBodyImage = getFileId

export const getHalfBodyImage = getFileId

export const getProfileImage = getFileId

export const getFullBodyImages = (fullBodyImages, fullBodyImage) => {
  return [
    ...(fullBodyImages || []).map(getFileId),
    getFullBodyImage(fullBodyImage)
  ]
}

export const getProfileMedia = (profileMedia) => {
  return map(profileMedia, 'fileToken')
}
