import React from 'react'
import { useParams } from 'react-router-dom'
import { types } from 'shared'
import { where, isEqual, uniqueId, sortBy, cond, always, isEmpty, T, compose, propEq } from 'lodash/fp'

import { getExperiencePublic } from 'api/appApi/offers/publicViews/experience'
import { useSetExperiencePublicView } from 'store/hooks/globalState/usePublicView'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const TypesOfDate = types.experiences.TypesOfDate
const DaysTimesType = types.experiences.DaysTimesType


const applyOccurrence = (slot, occurrence) => {
  return {
    ...slot,
    offerExperienceOccurrenceId: occurrence.id,
    maxNumberOfParticipants: slot.maxNumberOfParticipants - occurrence.participantsCount,
    locations: [slot.locations.find(location => location.id === occurrence.bookedLocation?.id)].filter(Boolean),
  }
}

const isDateEqual = (left, right) => {
  return isEqual(new Date(left), new Date(right))
}

const mapSlotsWithOccurrence = (slots, occurrences) => {
  if (isEmpty(occurrences)) return slots

  return slots.map(slot => {
    const occurrence = occurrences.find(occurrence => isDateEqual(slot.startTime, occurrence.startTime))
    return occurrence ? applyOccurrence(slot, occurrence) : slot
  })
}

const isExpWithoutAvailableOccurrence = where({
  typesOfDate: isEqual(TypesOfDate.FIXED),
  availableOccurrences: propEq('length', 0)
})

const isTimeWindow = where({
  typesOfDate: isEqual(TypesOfDate.FLEXIBLE),
  daysTimesType: isEqual(DaysTimesType.UP_TO_5_DAY_TIME_OPTIONS)
})

const isRecurring = where({
  typesOfDate: isEqual(TypesOfDate.FLEXIBLE),
  daysTimesType: isEqual(DaysTimesType.RECURRING_DAY_TIME_OPTION)
})

const isAvailableOccurrences = where({
  typesOfDate: isEqual(TypesOfDate.FIXED),
})

const isAllowAficionadoToProposeDayTime = where({
  daysTimesType: isEqual(DaysTimesType.ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME)
})

const isAnyAvailableDayTimeInCalendar = where({
  daysTimesType: isEqual(DaysTimesType.ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR),
})


export const getSlotByOccurrence = ({
  occurrence,
  locations,
  maxNumberOfParticipants
}) => {
  return {
    id: uniqueId('id'),
    offerExperienceOccurrenceId: occurrence.id,
    startTime: occurrence.startTime,
    endTime: occurrence.endTime,
    date: occurrence.startTime,
    maxNumberOfParticipants: maxNumberOfParticipants - occurrence.participantsCount,
    locations: occurrence.bookedLocation
      ? [locations.find(l => l.id === occurrence.bookedLocation.id)]
      : locations
  }
}

export const getSlotsByOccurrence = ({
  availableOccurrences,
  locations,
  maxNumberOfParticipants
}) => {
  return availableOccurrences
    .map(occurrence => getSlotByOccurrence({
      occurrence,
      locations,
      maxNumberOfParticipants
    }))
}


export const getSlotByUpToFiveOption = ({
  timeWindow,
  locations,
  maxNumberOfParticipants,
}) => {
  return {
    id: uniqueId('id'),
    startTime: timeWindow.startTime,
    endTime: timeWindow.endTime,
    date: timeWindow.startTime,
    maxNumberOfParticipants: maxNumberOfParticipants,
    locations: locations,
  }
}

export const getSlotsByUpToFiveOption = ({
  timeWindows,
  locations,
  maxNumberOfParticipants,
  availableOccurrences,
}) => {
  const slots = timeWindows
    .map(timeWindow => getSlotByUpToFiveOption({
      timeWindow,
      locations,
      maxNumberOfParticipants
    }))

  return mapSlotsWithOccurrence(slots, availableOccurrences)
}


export const getSlotByRecurring = ({
  date,
  locations,
  maxNumberOfParticipants,
  timeWindows,
}) => {
  return {
    id: uniqueId('id'),
    startTime: timeWindows.startTime,
    endTime: timeWindows.endTime,
    date,
    maxNumberOfParticipants: maxNumberOfParticipants,
    locations: locations,
  }
}

export const getSlotsByRecurring = ({
  availableRecurringDates,
  locations,
  maxNumberOfParticipants,
  timeWindows,
  availableOccurrences,
}) => {
  const slots = availableRecurringDates
    .map(date => getSlotByRecurring({
      date,
      locations,
      maxNumberOfParticipants,
      timeWindows: timeWindows[0]
    }))
  return mapSlotsWithOccurrence(slots, availableOccurrences)
}


export const getSlotsWithoutOptions = ({
  availableOccurrences = [],
  maxNumberOfParticipants
}) => {
  return availableOccurrences.map(occurrence => ({
    id: occurrence.id,
    bookedLocation: occurrence.bookedLocation,
    participantsCount: occurrence.participantsCount,
    maxNumberOfParticipants: maxNumberOfParticipants,
    startTime: new Date(occurrence.startTime),
    endTime: new Date(occurrence.endTime)
  }))
}


export const getDoesNotRepeatOccurrence = ({
  startTime,
  endTime,
  maxNumberOfParticipants,
  locations,
  participantsCount = 0
}) => {
  return [
    {
      id: uniqueId('id'),
      startTime,
      endTime,
      date: startTime,
      maxNumberOfParticipants: maxNumberOfParticipants - participantsCount,
      locations: locations,
    }
  ]
}

export const getExperienceTimeSlots = compose(
  sortBy('date'),
  cond([
    [isExpWithoutAvailableOccurrence, getDoesNotRepeatOccurrence],
    [isTimeWindow, getSlotsByUpToFiveOption],
    [isRecurring, getSlotsByRecurring],
    [isAvailableOccurrences, getSlotsByOccurrence],
    // [isAllowAficionadoToProposeDayTime, getSlotsWithoutOptions],
    // [isAnyAvailableDayTimeInCalendar, getSlotsWithoutOptions],
    [T, always([])],
  ])
)

export const getJoinSlots = compose(
  sortBy('startTime'),
  cond([
    [isAllowAficionadoToProposeDayTime, getSlotsWithoutOptions],
    [isAnyAvailableDayTimeInCalendar, getSlotsWithoutOptions],
    [T, always([])]
  ])
)

export function useFetchExperiencePublicView() {
  const setExperiencePublicView = useSetExperiencePublicView((_, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getExperiencePublic({ talentPlaqueNickname, offerSequentialId })
      .then(({ experience, currentUserPersonalData }) => {
        setExperiencePublicView({
          experience,
          currentUserPersonalData,
          slots: getExperienceTimeSlots(experience),
          joinSlots: getJoinSlots(experience)
        })
      })
      .catch(error => handleResponseError(error))
  }, [setExperiencePublicView, talentPlaqueNickname, offerSequentialId])
}
