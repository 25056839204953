import React from 'react'

import Participant from '../Participant/Participant'
import useVideoContext from '../../hooks/useVideoContext'
import useParticipants from '../../hooks/useParticipant'
import useMainParticipant from '../../hooks/useMainParticipant'

export default function ParticipantList() {
  const { room } = useVideoContext()
  const localParticipant = room?.localParticipant
  const participants = useParticipants()

  const mainParticipant = useMainParticipant()

  if (participants.length === 0) return null // Don't render this component if there are no remote participants.

  return (
    <aside className='participant-list'>
      <Participant participant={localParticipant} isLocalParticipant={true} />

      {participants.map(participant => {
        const hideParticipant = participant === mainParticipant

        return (
          <Participant
            key={participant.sid}
            participant={participant}
            hideParticipant={hideParticipant}
          />
        )
      })}
    </aside>
  )
}
