import get from 'lodash/get'

import { getCharitiesForForm } from 'utils/services/loaders/charities'


export const voiceOverLessonFormAdapter = values => {
  const {
    active,
    price,
    charities,
    media,
    specialInstructions,
  } = values

  const mediaId = get(media, 'file.fileToken')

  return {
    active: String(active),
    price: String(price),
    charities: getCharitiesForForm(charities),
    media: mediaId,
    mediaTemp: mediaId,
    specialInstructions,
  }
}
