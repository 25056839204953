import { CalendarSlotType, TalentOffersType } from 'shared'
import propOr from 'lodash/fp/propOr'
import __ from 'lodash/fp/__'

import * as c from 'components/Calendar/categoriesColourTypes.module.scss'

export const slotBackground = {
  OFFER: c.offer,

  [CalendarSlotType.MY_EVENT]: c.myEvent,
  [CalendarSlotType.TIME_UNAVAILABLE]: c.timeUnavailable,
  SYNCED_CALENDAR: c.syncedCalendar,

  [TalentOffersType.IN_PERSON_EXPERIENCE]: c.inPersonExperience,
  [TalentOffersType.VIRTUAL_EVENT]: c.virtualEvent,
  [TalentOffersType.VIDEO_CHAT]: c.videoChat,
  [TalentOffersType.LIVE_IN_PERSON_LESSON]: c.lessons,
  [TalentOffersType.VIRTUAL_LESSON]: c.lessons,
  [TalentOffersType.READY_MADE_LESSON]: c.lessons,
  LOCK: c.lock,
  AFICIONADO: c.aficionado,
}

export const getSlotBackground = propOr(slotBackground.OFFER, __, slotBackground)
