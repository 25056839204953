import { filtersConfig } from './filtersConfig.js'
import { subFiltersConfig } from './subFiltersConfig.js'

// TODO: Check if it would be better to keep all column components in a one place instead of near the each separate table
import Media from '../components/Media'
import Actions from '../components/Actions'


export const READY_MADE_EVENT_LESSONS_DETAILS_PRODUCT = 'ready-made-event-lessons-details-product'
export const FILE = 'file'
export const ACTIONS = 'actions'

export const elementsTypes = {
  [FILE]: FILE,
  [ACTIONS]: ACTIONS
}

export const tableConfig = [
  {
    header: 'table.header.file',
    type: elementsTypes.file,
    field: 'originalName',
  },
  {
    header: 'table.header.actions',
    type: elementsTypes.actions,
    className: 'centered',
  },
]

const additionalFields = ['sortOrder']

export const fields = [
  ...filtersConfig.map(({ field }) => field),
  ...subFiltersConfig.map(({ field }) => field),
  ...additionalFields,
]

export const readyMadeEventLessonsProductComponents = {
  [FILE]: Media,
  [ACTIONS]: Actions,
}
