import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import eventPath from 'utils/eventPath'

const CustomSubSelect = React.memo(function CustomSubSelect ({
  className = '', options = [], label = '', field, getFn, setFn
}) {
  const t = useGetTranslate()
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const togleMenu = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])

  const currentValue = getFn(current => get(current, field, ''))
  const handleChange = setFn((prev, value) => {
    setIsOpen(false)
    return { ...prev, [field]: value }
  })

  const closeMenu = React.useCallback((e) => {
    if (eventPath(e).includes(ref.current)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const selectedLabel = React.useMemo(() => {
    const option = options.find(({ value }) => value === currentValue)
    if (!option) return ''
    return option.label
  }, [options, currentValue])

  const parsedOptions = React.useMemo(() => {
    return options.map(({ value, label }) => {
      return (
        <div
          key={value}
          className={`menu-item ${value === currentValue ? 'active' : ''}`}
          onClick={() => handleChange(value)}
        >
          {t(label)}
        </div>
      )
    })
  }, [options, currentValue, handleChange, t])

  return (
    <StyledWrapper
      // onClick={(e) => e.stopPropagation()}
      ref={ref}
      isOpen={isOpen}
      className={className}
    >
      <div
        className='select-wrapper'
        onClick={togleMenu}
      >
        <div className='select-label'>
          {label ? `${t(label)} ${t(selectedLabel)}` : t(selectedLabel)}
        </div>
        <div className='handler' />
      </div>
      {isOpen && (
        <div className='menu-wrapper'>
          {parsedOptions}
        </div>
      )}
    </StyledWrapper>
  )
})

CustomSubSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  label: PropTypes.string,
  field: PropTypes.string.isRequired,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired
}

const StyledWrapper = styled.div`
  position: relative;
  display: inline-block;
  border-bottom: dashed 1px ${({ theme: { colors } }) => colors.blue};
  margin-left: 24px;
  .select-wrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    .select-label {
      font-size: 13px;
      font-weight: 500;
      line-height: 22px;
      color: ${({ theme: { colors } }) => colors.blue};
    }
    .handler {
      width: 7px;
      height: 7px;
      border-right: solid 2px ${({ theme: { colors } }) => colors.blue};
      border-bottom: solid 2px ${({ theme: { colors } }) => colors.blue};
      transform: rotate(45deg);
      margin-left: 7px;
      ${({ isOpen }) => isOpen && `
        transform: rotate(-135deg);
      `};
      transition: transform 0.2s, border-color 0.2s;
    }
  }
  .menu-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    background: #fff;
    border-radius: 4px;
    transform: translate(-50%, 10px);
    min-width: 140px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    z-index: 5;
    .menu-item {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      color: ${({ theme: { colors } }) => colors.grey};
      padding: 7px 16px;
      margin: 4px 0;
      white-space: nowrap;
      :hover {
        background: ${({ theme: { colors } }) => colors.ultraLightBlue};
        cursor: pointer;
      }
    }
    .menu-item.active {
      color: ${({ theme: { colors } }) => colors.blue};
      font-weight: 700;
    }
  }
  :last-child {
    .menu-wrapper {
      right: 0;
      left: auto;
      transform: translate(0, 10px);
    }
  }
`

export default CustomSubSelect
