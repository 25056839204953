import cond from 'lodash/cond';
import matches from 'lodash/matches';
import uniq from 'lodash/uniq';
import constant from 'lodash/constant';
import castArray from 'lodash/castArray';
import React from 'react';
import { types } from 'shared';
import { createArray } from '../_legacy/containers/Experience/utils';

import Icon from '../assets/icons/Icon';

const isFileFuncFactory = types => file => !!Object.keys(file).length && types.includes(file.type);

export const getImageShortTypes = () => ['jpeg', 'jpg', 'png'];
export const getVideoShortTypes = () => ['mp4', 'quicktime', 'webm', 'ogg', 'mpeg', '3gpp'];
export const getFilesShortTypes = () => ['*'];

export const getImageTypes = () => getImageShortTypes().map(type => `image/${type}`);
export const getVideoTypes = () => getVideoShortTypes().map(type => `video/${type}`);
export const getFilesTypes = () => getFilesShortTypes().map(type => `application/${type}`);

const Types = types.file.Types;

export const getAvailableExtensions = types => {
  const arrayOfTypes = castArray(types);

  return arrayOfTypes.reduce((acc, type) => {
    switch (type) {
    case 'image':
    case Types.PHOTO:
      return [...acc, ...getImageTypes()];
    case 'video':
    case Types.VIDEO:
      return [...acc, ...getVideoTypes()];
    case 'files':
    case Types.FILE:
      return [...acc, ...getFilesTypes()];
    default:
      return acc;
    }
  }, []);
};

export const getFileExtension = (fileUrl = '') => fileUrl.split('.').pop();
export const getFileType = (fileUrl) => {
  return getImageShortTypes().includes(getFileExtension(fileUrl)) ? 'image' : 'video';
};

export const isPhotoFunc = isFileFuncFactory(getImageTypes);
export const isVideoFunc = isFileFuncFactory(getVideoTypes);

export const toSharedType = accept => {
  const array = createArray(accept);
  const types = array.map(ex => ex.split('/')[0]);
  const uniqTypes = uniq(types);

  return uniqTypes.reduce((acc, type) => {
    switch (type) {
    case 'image':
    case Types.PHOTO:
      return [...acc, Types.PHOTO];
    case 'video':
    case Types.VIDEO:
      return [...acc, Types.VIDEO];
    default:
      return acc;
    }
  }, []);
};

export const getDocumentIconByFileName = (fileName) => {
  const extension = getFileExtension(fileName);

  const config = {
    ['pptx']: Icon.DocumentPptx,
    ['png']: Icon.DocumentImg,
    ['jpg']: Icon.DocumentImg,
    ['img']: Icon.DocumentImg,
    ['pdf']: Icon.DocumentPdf,
    ['doc']: Icon.DocumentDoc,
    ['docx']: Icon.DocumentDoc,
  };

  const DocumentIcon = config[extension] || Icon.DocumentDefault;

  return <DocumentIcon />;
};

export const getNormalizeFileType = (type) => {
  const prefixType = type.split('/')[0];

  return cond([
    [matches('image'), constant(Types.PHOTO)],
    [matches('video'), constant(Types.VIDEO)],
  ])(prefixType);
};

export const getNormalizeFile = file => ({
  fileId: file.data.filename,
  type: getNormalizeFileType(file.file.type),
  fullUrl: URL.createObjectURL(file.file),
  originalName: file.file.name,
  linksCount: 1,
});
