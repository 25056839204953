import { differenceInSeconds, format } from 'date-fns'
import { convertSeconds, dateFormats, getLocale } from 'utils/date'
import { getTimezoneAbbr } from 'utils/services/timezone'

export const buildEventInfoLabel = (
  t,
  event,
  userLang = 'EN',
  hour12 = true,
  timeZone
) => {
  const timeFormat = hour12 ? dateFormats.timeAmPm : dateFormats.militaryTime
  const locale = getLocale(userLang)

  const date = format(new Date(event.start), dateFormats.shortMonthDate, { locale })
  const startTime = format(new Date(event.start), timeFormat, { locale })
  const endTime = format(new Date(event.end), timeFormat, { locale })
  const duration = convertSeconds(differenceInSeconds(event.end, event.start), { locale })

  const timeZoneAbbr = getTimezoneAbbr(timeZone)

  return `${date}, ${startTime} - ${endTime} ${timeZoneAbbr} (${t('aficionado-calendar.duration-time')} ${duration})`
}
