import React from 'react'

import identity from 'lodash/fp/identity'
import { useUserLang } from 'store/hooks/globalState/useTranslates'
import { getLocale } from 'utils/date'
import { getEventTimeFormats } from '../../Calendars/configs/hour-formats'


export const withHoursFormat = CalendarComponent => ({
  openListOptions = [],
  hour12Format = true,
  setHour12Format = identity,
  ...restProps
}) => {
  const userLang = useUserLang()
  const locale = getLocale(userLang)
  const toggleHourFormat = React.useCallback(() => setHour12Format(current => !current), [])

  const openListOptionsWithHoursFormat = [...openListOptions,
    { label: 'calendar.dot-button.hour-format', cb: toggleHourFormat }
  ]

  return (
    <CalendarComponent
      {...restProps}
      {...getEventTimeFormats(hour12Format, locale)}
      openListOptions={openListOptionsWithHoursFormat}
    />
  )
}
