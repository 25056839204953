import React from 'react'
import { FileType } from 'shared'
import get from 'lodash/get'

import CustomVideo from './custom-video/CustomVideo'
import CustomPicture from './CustomPicture'

const EmptyMedia = props => <div {...props} />

const mediaConfig = {
  [FileType.PHOTO]: CustomPicture,
  [FileType.VIDEO]: CustomVideo,
}

const CustomMedia = props => {
  const fileType = get(props, 'file.type')
  const Component = get(mediaConfig, fileType, EmptyMedia)

  return <Component {...props} />
}

CustomMedia.propTypes = {}

export default CustomMedia
