import React from 'react';
import { map } from 'lodash';

import { columns } from '../config';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import FormRow from '_legacy/components/Form/Row';
import Label from '_legacy/components/Form/Label';
import NotificationHead from './NotificationHead';
import NotificationBody from './NotificationBody';

const NotificationsForm = ({ config }) => {
  const t = useGetTranslate()
  const rowHead = map(columns, key => t(`account.notifications.layout.block.${key}`));

  return config.map(category => (
    <FormRow
      key={category.label}
      left={<Label text={t(category.tKey)}/>}
      right={
        <div className='notifications__wrapper'>
          <NotificationHead rows={rowHead}/>
          <NotificationBody rows={category.category}/>
        </div>
      }
    />
  ));
};

export default NotificationsForm
