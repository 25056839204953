import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import Cropper from '../Cropper';
import Button from '../Button';
import Range from '../Range';
import Icon from 'assets/icons/Icon';
import ModalPortal from 'portals/ModalPortal';
import { imageSrcToBlob } from '../Cropper/utils'

const CropModal = ({ isModalOpen, closeModal, aspect, image, onComplete, onBack, cropShape, showGrid, showBackButton, header }) => {
  const t = useGetTranslate()
  const [zoom, setZoom] = useState(1);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [photo, setPhoto] = useState();
  const cropHeader = header || t('shared.words.crop-image')

  const reset = () => {
    setZoom(1);
    setCrop({ x: 0, y: 0 })
    setPhoto(null)
  }

  const closeHandler = () => {
    reset()
    closeModal()
  }

  const completeHandler = () => {
    onComplete(photo)
    reset()
  }

  React.useEffect(async () => {
    if (image && !photo) {
      const _photo = await imageSrcToBlob(image)
      setPhoto(_photo)
    }
  }, [photo, image])

  return (
    <ModalPortal isMobileFullScreen isOpen={isModalOpen} onClose={closeHandler} showCloseButton overlayClassName='__crop-modal-overlay'>
      <div className='modal crop-modal'>
        <div className="crop-modal__header">
          {showBackButton && (
            <button className='crop-modal-back' onClick={onBack}>
              <Icon.BackArrow/>
              <span className='crop-modal-back__btn'>Back</span>
            </button>
          )}
          <h2 className="crop-modal-header">{cropHeader}</h2>
        </div>
        <div className="crop-modal__body">
          <Cropper
            aspect={aspect}
            image={image}
            onComplete={setPhoto}
            zoom={zoom}
            setZoom={setZoom}
            cropShape={cropShape}
            crop={crop}
            setCrop={setCrop}
            showGrid={showGrid}
          />
        </div>
        <div className="crop-modal__controls">
          <div className="crop-controls">
            <div className="crop-controls__title">
              <p>Zoom</p>
            </div>
            <div className="crop-controls__range">
              <Range defaultValue={zoom} onChange={setZoom} min={1} max={3} step={0.1}/>
            </div>
            <div className="crop-controls__button">
              <Button
                disabled={!photo}
                text={'Crop & Save'}
                paddingHorizontal={26}
                paddingVertical={13}
                handleOnClick={completeHandler}
              />
            </div>
          </div>
        </div>
      </div>
    </ModalPortal>
  );
};

CropModal.defaultProps = {
  aspect: 3 / 4,
  onBack: () => {},
  cropShape: 'rect',
  showGrid: false,
  showBackButton: false
};

CropModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  aspect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onComplete: PropTypes.func,
  onBack: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.bool,
  ]),
  image: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  cropShape: PropTypes.oneOf(['rect', 'round']),
  showGrid: PropTypes.bool,
  showBackButton: PropTypes.bool,
};

export default CropModal;
