import React from 'react';

import ModalPortal from 'portals/ModalPortal';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { StyledModalPortal } from '_legacy/components/Modal/PromoCodes/styled';

export const DiscountHint = () => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen showCloseButton>
      <StyledModalPortal>
        {t('promo-codes.form.discount.hint.text')}
      </StyledModalPortal>
    </ModalPortal>
  )
}
