import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Field = ({ value, onClick, placeholder, isOpen, className = '' }) => {
  const t = useGetTranslate()
  return (
    <StyledWrapper className={`filter ${className}`}>
      <input
        readOnly
        className="custom-input"
        type="text"
        value={value}
        placeholder={t(placeholder)}
        onClick={onClick}
      />
      <IconS isOpen={isOpen} width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M13 1L7 7L1 0.999999" stroke="#9495A7" strokeWidth="2"/>
      </IconS>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  .custom-input {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme: { colors } }) => colors.blue};
    outline: none;
    padding: 10px 18px;
    background: #fff;
    border: solid 2px ${({ theme: { colors } }) => colors.ultraLightBlue};
    border-radius: 4px;
    transition: all 0.2s;
    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.lightGrey};
    }
    &:hover {
      border: solid 2px ${({ theme: { colors } }) => colors.blue};
    }
    &:focus {
      border: solid 2px ${({ theme: { colors } }) => colors.blue};
    }
  }
  .icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`

const IconS = styled.svg`
  position: absolute;
  top: 50%;
  right: 18px;
  transform: translateY(-50%) rotate(${({ isOpen }) =>  isOpen ? '-180deg' : 0});
  transition: transform .2s ease-in-out;
`

export default Field
