// Modules
import React from 'react'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

// Assets
import Icon from 'assets/icons/Icon'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSearchAficionados, useSetSearchAficionados } from 'store/hooks/globalState/useSearchList'
import { useFetchAficionadosSearchItems } from 'requests/search/aficionados'


const ChatsHeader = () => {
  const t = useGetTranslate()

  const searchLike = get(useSearchAficionados(), 'searchLike', '')
  const setSearchLike = useSetSearchAficionados((prev, next) => ({
    ...prev,
    ...next,
  }))

  const debouncedSearch = React.useCallback(debounce(useFetchAficionadosSearchItems(), 500), [])

  const handleChange = event => {
    const searchLike = event.target.value

    setSearchLike({ searchLike })

    if (searchLike.length >= 2) {
      debouncedSearch({ searchLike, page: 1, limit: 30 })
    }
  }

  return (
    <div className="chats-list-header">
      <h4>{t('chats.header.title')}</h4>

      <div className="members-search__wrapper">
        <input
          type="text"
          className="members-search__input"
          placeholder={t('chats.users-search.input-placeholder')}
          onChange={handleChange}
          value={searchLike}
        />
        <Icon.Search className="members-search__icon" />
      </div>
    </div>
  )
}

export default ChatsHeader
