import React from 'react'
import ReactDatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import { addDays } from 'date-fns'

import { ReactComponent as Icon } from 'assets/calendar.svg'

import { getCalendarDate } from 'utils/date'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import ClearButton from '../styles/ClearButton'
import StyledWrapper from './StyledWrapper'

import compose from 'lodash/fp/compose'
import compact from 'lodash/fp/compact'
import map from 'lodash/fp/map'
import join from 'lodash/fp/join'
import path from 'lodash/fp/path'

const getDate = compose(
  join(' – '),
  map(getCalendarDate),
  compact,
)

const RangeDatePicker = React.memo(({ className, placeholder, field, getFn, setFn, disabled }) => {
  const t = useGetTranslate()
  const [show, setShow] = React.useState(false)

  const dateFrom = getFn(path([field, 'from']))
  const dateTo = getFn(path([field, 'to']))

  const handleChangeFrom = setFn((prev, from) => ({ ...prev, [field]: { ...prev[field], from } }))
  const handleChangeTo = setFn((prev, to) => ({ ...prev, [field]: { ...prev[field], to } }))
  const handleClear = setFn(prev => ({ ...prev, [field]: null }))

  const onChange = dates => {
    const [start, end] = dates
    handleChangeFrom(start)
    handleChangeTo(end)
  }

  const onClickOutside = () => {
    if (dateFrom) {
      handleChangeTo(addDays(dateFrom, 1))
    }
    setShow(false)
  }

  React.useEffect(() => {
    if (dateTo) setShow(false)
  }, [dateTo])

  React.useEffect(() => {
    if (disabled && (dateFrom || dateTo)) {
      handleClear()
    }
  }, [dateFrom, dateTo, disabled])

  return (
    <StyledWrapper className={`filter ${className}`} disabled={disabled}>
      <ReactDatePicker
        disabled={disabled}
        open={show}
        value={getDate([dateFrom, dateTo])}
        onInputClick={() => setShow(true)}
        onClickOutside={onClickOutside}
        placeholderText={t(placeholder)}
        popperPlacement="top"
        selected={dateFrom}
        onChange={onChange}
        startDate={dateFrom}
        endDate={dateTo}
        selectsRange
      />
      {dateFrom
        ? <ClearButton onClick={handleClear} />
        : <Icon className="icon" />}
    </StyledWrapper>
  )
})


RangeDatePicker.defaultProps = {
  className: '',
  placeholder: ''
}

RangeDatePicker.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.string,
  getFn: PropTypes.func,
  setFn: PropTypes.func,
}

export default RangeDatePicker
