import React from 'react'
import styled from 'styled-components'
import { TaxpayerStatus } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { Row } from '_legacy/common'
import RadioList from '_legacy/common/Radio/RadioList'

const taxesOptions = [
  { label: 'register-user.form.taxes.options.us', value: TaxpayerStatus.US },
  { label: 'register-user.form.taxes.options.no-us', value: TaxpayerStatus.NOT_US }
]

const Taxes = ({ name = 'taxpayerStatus', showTaxMessage }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('register-user.form.taxes.label')}
      component={
        <>
          <RadioList name={name} options={taxesOptions} defaultChecked={TaxpayerStatus.US} />
          {showTaxMessage && <Message>{t('register-user.form.taxes.message')}</Message>}
        </>
      }
    />
  )
}

const Message = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 1.625;
  color: #626473;
  
  margin-top: 16px;
`

export default Taxes
