import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch, useParams, Redirect } from 'react-router-dom'

// hooks
import { VideoProvider } from './components/VideoProvider/VideoProvider'
import useConnectionOptions from './utils/useConnectionOptions'
import { ChatProvider } from './components/ChatProvider/ChatProvider'
import VideoStateProvider from './state/VideoState'
import PreJoinScreens from './components/PreJoinScreen'
import { RoomStateTypes, useRoomState } from './hooks/useRoomState'
import Room from './components/Room'
import MenuBar from './components/MenuBar'
import Header from './components/RoomHeader'
import RejoinScreen from './components/RejoinScreen'
import PreJoinHeader from './components/PreJoinHeader'
import { routesList } from 'router/routesMap'
import PageLoader from '_legacy/common/Template/PageLoader'
import { useVideoState } from './hooks/useVideoState'

const App = () => {
  const roomState = useRoomState()

  if (roomState === RoomStateTypes.disconnected) {
    return (
      <>
        <PreJoinHeader />
        <div className="twilio-video-container wrapper content-wrapper">
          <PreJoinScreens />
        </div>
      </>
    )
  }

  if (roomState === RoomStateTypes.connected) {
    return (
      <>
        <Header />
        <div className="twilio-video-container">
          <Room />
          <MenuBar />
        </div>
      </>
    )
  }
}

const TwilioVideos = () => {
  const match = useRouteMatch()
  const { occurrenceId } = useParams()
  const connectionOptions = useConnectionOptions()
  const { setError, isRoomExist, verifyVideoChatRoom } = useVideoState()

  useEffect(() => {
    verifyVideoChatRoom(occurrenceId)
  }, [occurrenceId])

  if (isRoomExist === null) {
    return (
      <>
        <PreJoinHeader />
        <PageLoader />
      </>
    )
  }

  if (isRoomExist === false) {
    return <Redirect to={routesList.notFound()} />
  }

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ChatProvider>
        <Switch>
          <Route exact path={`${match.path}`} component={App} />
          <Route exact path={`${match.path}/end`} component={RejoinScreen} />
        </Switch>
      </ChatProvider>
    </VideoProvider>
  )
};

export default () => (
  <VideoStateProvider>
    <TwilioVideos />
  </VideoStateProvider>
)
