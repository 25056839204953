import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { types } from 'shared'
import { isSameDay } from 'date-fns'
import { isEmpty, cond } from 'lodash/fp'

import { useModal } from 'store/hooks/useModal'
import { getLocationName, getLocationTimeZone } from 'utils/location'
import { useBookExperience, useBookOccurrence } from 'requests/public-views/experience/experience-booking'

import { getTimezoneAbbr } from 'utils/services/timezone'
import { getMonthDayYearDate, getTimeRange } from 'utils/date'
import {
  buildInitialDates,
  buildLocations,
  buildOccurrences,
  buildParticipantLabel,
  buildTimeWindow,
  getJoinButtonKey,
  getProposeButtonKey,
} from '../utils'
import { filterByDuration, getSlotsInRange } from 'components/Calendars/utils'

import JoinModal from '_legacy/components/PublicView/JoinModal'
import OptionsModal from '_legacy/components/PublicView/OptionsModal'
import SelectFormItem from '_legacy/components/PublicView/SelectFormItem'
import PublicInfoForm from '_legacy/components/PublicView/PublicInfoForm'
import PublicFixedFormItem from '_legacy/components/PublicView/PublicFixedFormItem'
import PublicFormItem from '_legacy/components/PublicView/PublicFlexibleFormItemContext'
import BookingCalendar from 'components/Calendars/BookingCalendar'

const SELECT_KEYS = {
  JOIN: 'JOIN',
  PROPOSE: 'PROPOSE',
}

const { FLEXIBLE } = types.experiences.TypesOfLocation

const TimeWindow = React.memo(({ experience }) => {
  const { occurrencesLimit, id, maxNumberOfParticipants, locations, pricePerParticipant, typesOfLocation, duration, title, talent, minBookingTimeBeforeExperience, travelTime } = experience
  const isFlexibleTypeOfLocation = React.useMemo(() => typesOfLocation === FLEXIBLE, [])
  const filteredTimeWindows = React.useMemo(() => buildTimeWindow(experience), [experience])
  const methods = useForm({ shouldUnregister: false })

  const t = useGetTranslate()

  // ****** slot state ******
  const [selectedSlot, setSelectedSlot] = React.useState()
  const [orderSlot, setOrderSlot] = React.useState(null)

  // ****** request hooks ******
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const bookExperience = useBookExperience(setIsSubmitting)
  const bookOccurrence = useBookOccurrence(setIsSubmitting)

  // ****** modal state ******
  const [isJoinModalOpen, openJoinModal, closeJoinModal] = useModal(false)
  const [isTalentCalendarModalOpened, openTalentCalendarModal, closeTalentCalendarModal] = useModal(false)
  const [isInitialDateModalOpen, openInitialDateModal, closeInitialDateModal] = useModal(false)

  // ****** join state ******
  const availableOccurrences = React.useMemo(() => buildOccurrences(experience), [experience])
  const [selectedOccurrence, setSelectedOccurrence] = React.useState(null)

  // ****** flow state ******
  const dateTimeOptionsData = [];
  if(!isEmpty(availableOccurrences)) {
    dateTimeOptionsData.push({ value: SELECT_KEYS.JOIN, label: t(getJoinButtonKey(experience)), disabled: isEmpty(availableOccurrences) })
  }
  if(availableOccurrences.length !== occurrencesLimit) {
    dateTimeOptionsData.push({ value: SELECT_KEYS.PROPOSE, label: t(getProposeButtonKey(experience)), disabled: availableOccurrences.length === occurrencesLimit })
  }

  const flowOptions = React.useMemo(() => dateTimeOptionsData, [t, occurrencesLimit, availableOccurrences, experience])

  const [selectedFlow, setSelectedFlow] = React.useState(null)
  const onFlowChange = React.useCallback(value => {
    setSelectedFlow(value)
    if (value === SELECT_KEYS.JOIN) openJoinModal()
    if (value === SELECT_KEYS.PROPOSE) openInitialDateModal()
  }, [])

  // ****** date state ******
  const [selectedDate, setSelectedDate] = React.useState(null)
  const dateOptions = React.useMemo(() => buildInitialDates(experience), [experience])
  const onDateChange = React.useCallback(value => {
    setSelectedDate(value)
    setOrderSlot(null)
    setSelectedSlot(null)
  }, [])

  // ****** location state ******
  const locationOptions = React.useMemo(() => buildLocations(locations), [locations])
  const watchedLocation = methods.watch('location') || locations[0]?.id
  const selectedLocation = locations.find(location => location.id === watchedLocation)
  const locationTimeZone = getLocationTimeZone(selectedLocation)
  const locationTimeZoneAbbr = getTimezoneAbbr(locationTimeZone)

  // ****** flags ******
  const isFlowNotSelected = selectedFlow === null
  const isJoinFlow = selectedFlow === SELECT_KEYS.JOIN
  const isProposeFlow = selectedFlow === SELECT_KEYS.PROPOSE

  const isJoinFlowWithSelectedOccurrence = isJoinFlow && !!selectedOccurrence
  const isProposeFlowWithDate = isProposeFlow && selectedDate
  const isProposeFlowWithDateTime = isProposeFlowWithDate && selectedSlot

  const showSelectedLocation = isFlexibleTypeOfLocation && isJoinFlow && selectedOccurrence?.bookedLocation
  const showLocationSelect = isFlexibleTypeOfLocation && (isFlowNotSelected || isProposeFlow)

  // ****** participant state ******
  const participantTitle = React.useMemo(
    cond([
      [() => isJoinFlowWithSelectedOccurrence, () => buildParticipantLabel(selectedOccurrence.participantsCount, maxNumberOfParticipants)],
      [() => isProposeFlow, () => buildParticipantLabel(0, maxNumberOfParticipants)],
      [() => true, () => '-'],
    ]),
    [isJoinFlowWithSelectedOccurrence, isProposeFlow, maxNumberOfParticipants]
  )

  const dateTitle = React.useMemo(
    cond([
      [() => isJoinFlowWithSelectedOccurrence, () => getMonthDayYearDate(selectedOccurrence.start)],
      [() => isProposeFlowWithDate, () => getMonthDayYearDate(selectedDate)],
      [() => true, () => t('experience.info.select-time')],
    ]),
    [t, selectedFlow, selectedDate, selectedOccurrence]
  )

  const timeTitle = React.useMemo(
    cond([
      [() => isJoinFlowWithSelectedOccurrence, () => getTimeRange([selectedOccurrence.start, selectedOccurrence.end])],
      [() => isProposeFlowWithDateTime, () => getTimeRange([selectedSlot.start, selectedSlot.end])],
      [() => true, () => t('experience.info.select-date')],
    ]),
    [t, isJoinFlowWithSelectedOccurrence, isProposeFlowWithDateTime]
  )

  // ****** submit ******
  const onSubmit = e => {
    e.preventDefault()

    if (isJoinFlow) {
      bookOccurrence({
        offerExperienceOccurrenceId: selectedOccurrence.id,
        // location: selectedOccurrence?.location?.id, // todo: wait some BE fixes
        location: locations[0]?.id,
        includedFriends: [],
        invitedFriends: [],
      })
    } else {
      bookExperience({
        experienceId: id,
        startTime: selectedSlot.start,
        endTime: selectedSlot.end,
        location: selectedLocation?.id,
        includedFriends: [],
        invitedFriends: [],
      })
    }
  }

  const isButtonDisabled = React.useMemo(
    cond([
      [() => isJoinFlowWithSelectedOccurrence, () => false],
      [() => isProposeFlowWithDateTime, () => false],
      [() => true, () => true],
    ]),
    [isJoinFlowWithSelectedOccurrence, isProposeFlowWithDateTime]
  )

  const onTimeHandler = React.useCallback(
    cond([
      [() => isProposeFlowWithDate, openTalentCalendarModal],
      [() => true, e => e],
    ]),
    [isProposeFlowWithDate]
  )

  const onCalendarConfirm = React.useCallback(({ start, end }) => {
    setSelectedSlot({ start, end })

    const isCurrentDate = isSameDay(new Date(selectedDate), start)

    if (!isCurrentDate) {
      const option = dateOptions.find(option => isSameDay(new Date(option.value), start))
      setSelectedDate(option.value)
    }

    closeTalentCalendarModal()
  }, [selectedDate, dateOptions])

  const afterTransform = React.useCallback(slots => {
    const slotsInTimeWindows = filteredTimeWindows
      .map(window => getSlotsInRange(slots, new Date(window.startTime), new Date(window.endTime)))
      .flat()

    return filterByDuration(slotsInTimeWindows, duration)
  }, [filteredTimeWindows, duration])

  return (
    <>
      <FormProvider {...methods}>
        <PublicInfoForm
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          pricePerParticipant={pricePerParticipant}
          buttonProps={{ disabled: isButtonDisabled }}
          locations={locations}
        >
          <h3>{t('experience.info.heading')}</h3>
          <p className="public-view__timezone-reminder">
            {t('experience.info.timezone-reminder-location')}:<div>{locationTimeZoneAbbr}</div>
          </p>

          <div className="public-view__info-list">
            <SelectFormItem
              header={t('experience-public-view.select.choose')}
              options={flowOptions}
              customTitle={dateTitle}
              onChange={onFlowChange}
            />

            {showSelectedLocation && (
              <PublicFixedFormItem
                title={t('experience.info.location')}
                fixedItem={getLocationName(selectedOccurrence.bookedLocation)}
              />
            )}
            {showLocationSelect && (
              <PublicFormItem name="location" title={t('experience.info.location')} items={locationOptions} />
            )}

            <PublicFixedFormItem title={t('experience.info.time')} fixedItem={timeTitle} onClick={onTimeHandler} />

            <PublicFixedFormItem title={t('experience.info.current-participant')} fixedItem={participantTitle} />
          </div>
        </PublicInfoForm>

        <BookingCalendar
          isOpen={isTalentCalendarModalOpened}
          onClose={closeTalentCalendarModal}
          timeZone={locationTimeZone}
          travelTime={travelTime}
          minBookingTimeBeforeExperience={minBookingTimeBeforeExperience}
          eventTitle={title}
          eventDuration={duration}
          onConfirm={onCalendarConfirm}
          orderSlot={orderSlot}
          setOrderSlot={setOrderSlot}
          talentId={talent.id}
          afterTransform={afterTransform}
          openOnDate={selectedDate}
        />

        <OptionsModal
          name="date"
          isOpen={isInitialDateModalOpen}
          onClose={closeInitialDateModal}
          title={t('')}
          options={dateOptions}
          onChange={onDateChange}
          value={selectedDate}
        />

        <JoinModal
          isOpen={isJoinModalOpen}
          onClose={closeJoinModal}
          list={availableOccurrences}
          joinHandler={setSelectedOccurrence}
        />
      </FormProvider>
    </>
  )
})

export default TimeWindow
