import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import get from 'lodash/get';
import head from 'lodash/head';
import pick from 'lodash/pick';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { Row } from '_legacy/common';
import ArrayListItem from '_legacy/common/Template/ArrayListItem';
import WindowItem from './WindowItem';
import AddMore from '_legacy/common/Buttons/AddMore';

const TimeWindow = ({ name = 'timeWindows' }) => {
  const t = useGetTranslate();
  const { control, setValue, watch } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name });

  const values = watch();
  const value = get(values, name, []);

  const removeHandler = index => {
    if (index === 0 && fields.length === 1) {
      setValue(name, [{ date: null, startTime: null, endTime: null }]);
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    const { date, startTime, endTime } = pick(head(value), ['date', 'startTime', 'endTime']);
    return date || startTime || endTime;
  };

  const shouldShowAddButton = fields.length < 5;

  const getMarginClassName = idx =>
    idx === fields.length - 1 && idx !== 4 ? 'mb-24' : 'mb-50';

  const getBlockName = index =>
    `${t('create-experience.form.time-windows.label')} ${index + 1}:`;

  return (
    <TimeList>
      {fields.map((field, index) => (
        <Row
          className={`${getMarginClassName(index)}`}
          isRequired
          key={field.id}
          nameBlock={getBlockName(index)}
          component={
            <ArrayListItem
              showRemoveButton={showRemoveButton(index)}
              onRemove={() => removeHandler(index)}
            >
              <WindowItem
                datePickerDefaultValue={field.date}
                datePickerName={`${name}[${index}].dateOfExperience`}
                startTimeDefaultValue={field.startTime}
                endTimeDefaultValue={field.endTime}
                startTimeName={`${name}[${index}].startTime`}
                endTimeName={`${name}[${index}].endTime`}
              />
            </ArrayListItem>
          }
        />
      ))}
      {shouldShowAddButton && (
        <Row
          component={
            <AddMore
              onClick={append}
              title={t('create-experience.form.time-window.button.add-range')}
            />
          }
        />
      )}
    </TimeList>
  );
};

TimeWindow.propTypes = {
  name: PropTypes.string,
};

const TimeList = styled.ul`
  position: relative;
  .remove-button {
    top: 30px;
  }

  .mb-50 {
    margin-bottom: 50px;
  }

  .mb-24 {
    margin-bottom: 24px;
  }
`;

export default TimeWindow;
