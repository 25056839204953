import { addSeconds, compareAsc, isWithinInterval, subSeconds } from 'date-fns'

export const getEventPosition = (start, duration, backgroundEvents) => {
  const end = addSeconds(start, duration)

  const backgroundEvent = backgroundEvents.find(event => isWithinInterval(start, event))

  if (!backgroundEvent) return

  const isInRange = isWithinInterval(end, backgroundEvent)
  if (isInRange) {
    return { start, end }
  } else {
    const end = backgroundEvent.end
    const start = subSeconds(end, duration)
    const isInRange = compareAsc(start, backgroundEvent.start) === 1

    if (isInRange) {
      return { start, end }
    }
  }
}
