import React from 'react'
import PropTypes from 'prop-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const PublicHowToList = ({ icon, numericBg, title, text }) => {
  const t = useGetTranslate()

  return (
    <div className="public-view__how-to-item">
      <div className="public-view__how-to-item--icon">{icon}</div>
      <div className="public-view__how-to-item--description">
        <div className="public-view__how-to-item--number">{numericBg}</div>
        <div className="public-view__how-to-item--content">
          <h2>{t(title)}</h2>
          <p>{t(text)}</p>
        </div>
      </div>
    </div>
  )
}

PublicHowToList.propTypes = {
  icon: PropTypes.node,
  numericBg: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default PublicHowToList
