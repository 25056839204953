// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { ChatType } from 'shared'

// Assets
import Icon from 'assets/icons/Icon'

// Store (hooks)
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { getEventViewInfo } from '_legacy/components/Chats/helpers'

// Components
import { EventDetails } from './EventDetails'
import { ChatParticipant } from './ChatParticipant'


const ActiveChatDetails = ({
  closeChatDetails,
  selectedChat: {
    chatInfo
  }
}) => {
  const t = useGetTranslate()

  const isEventChat = chatInfo.type === ChatType.EVENT_GROUP && chatInfo.event

  const eventIcon = isEventChat
    && getEventViewInfo(chatInfo.eventType).icon({ fill: '#9495A7', width: '16px' })

  const eventLabel = isEventChat && getEventViewInfo(chatInfo.eventType).label

  return (
    <div className="active-chat-details__wrapper">
      <div className="active-chat-details__header">
        <p className="active-chat-details__header-title">
          {isEventChat ? (
            <>
              {eventIcon}
              <p>{eventLabel}</p>
            </>
          ) : (
            t('chats.chat.chat-details.info-label')
          )}
        </p>

        <Icon.Close
          className="active-chat-details__close-icon"
          onClick={() => closeChatDetails()}
          fill="#9495A7"
        />
      </div>

      {isEventChat && <EventDetails chatInfo={chatInfo} />}

      <div className="active-chat-details__participants-list">
        <p className="active-chat-details__participants-list-header">
          <Trans
            tKey="chats.chat.chat-details.participants-count"
            phs={[{ ph: '{{participantsCount}}', value: chatInfo.participants.length }]}
          />
        </p>

        <ul>
          {chatInfo.participants.map(participant => (
            <ChatParticipant key={participant.id} participant={participant} />
          ))}
        </ul>
      </div>
    </div>
  )
}

ActiveChatDetails.propTypes = {
  closeChatDetails: PropTypes.func.isRequired,
  selectedChat: PropTypes.objectOf(
    PropTypes.shape({
      chatInfo: PropTypes.object.isRequired,
    })
  ),
}

export default ActiveChatDetails
