import { _get, _post } from '../../utils';

const urls = '/talents/join/invite';

export const info = ({ talentInvitationLink }) => _get(`/talents/join/${talentInvitationLink}`);

export const join = ({ talentInvitationLink, data }) => _post(`/talents/join/${talentInvitationLink}?linkingType=LINK_NEW`, { body: data });

export const search = ({ name = '', page = 1, limit = 5, type = '' }) =>
  _get(`${urls}/entreevip/search`, { query: { input: name, page, limit, types: type }, needAuthorization: true });

export const joinAgentOrManager = ({ data, type }) =>
  _post(`${urls}/entreevip`, { query: { type }, body: { usersIds: data }, needAuthorization: true });

export const joinEmail = ({ data, type }) =>
  _post(`${urls}/email`, { query: { type }, body: { invites: data }, needAuthorization: true });

export const joinLink = ({ type }) =>
  _post(`${urls}/link`, { query: { type }, needAuthorization: true });
