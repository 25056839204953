import {
  getLiveVirtualEventsTemplates as getLiveVirtualEventsTemplatesApi
} from 'api/appApi/offers/liveVirtualEvents';

const getTemplateLabel = (item, i) => {
  if (!item.name) {
    return `{Offering name} draft ${i}`;
  }

  return item.name
}

const templatePreparator = i => item => {
  // eslint-disable-next-line no-param-reassign
  if (!item.name) i += 1;

  return {
    value: item.id,
    label: getTemplateLabel(item, i),
    data: item,
  };
};

export const getAsyncTemplates = async ({ name, talentId }) => {
  try {
    const { templates } = await getLiveVirtualEventsTemplatesApi({
      name,
      talentId,
    });
    return {
      options: templates.map(templatePreparator(0)),
      hasMore: false,
    };
  } catch {
    return {
      options: [],
      hasMore: false,
    };
  }
};
