import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'

const ModalPortal = ({
  children,
  isOpen,
  onClose,
  showCloseButton,
  isMobileFullScreen,
  ...props
}) => isOpen ? (
  ReactDOM.createPortal(
    <Modal
      isSubModal={false}
      isOpen={isOpen}
      onClose={onClose}
      showCloseButton={showCloseButton}
      isMobileFullScreen={isMobileFullScreen}
      {...props}
    >
      {children}
    </Modal>,
    document.getElementById('modal')
  )
) : null

ModalPortal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
  isMobileFullScreen: PropTypes.bool,
}

export default ModalPortal
