import React from 'react';
import PropTypes from 'prop-types';

const CloseButton = ({ onClick, type = 'button', className = '' }) => {
  return (
    <button
      className={`close-button ${className}`}
      type={type}
      onClick={onClick}
    />
  );
};

CloseButton.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  className: PropTypes.string,
};

export default CloseButton;
