import React from 'react';
import PriceInput from '_legacy/common/Input/PriceInput';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const DiscountPrice = () => {
  const t = useGetTranslate();
  return (
    <PriceInput
      name='discountAmount'
      placeholder={t('promo-codes.form.discount.amount.placeholder')}
      requiredMessage={t('promo-codes.form.discount.amount.error.required')}
      className='discount'
      isRequired
    />
  )
}

export default DiscountPrice;
