import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '../Template';
import { Input } from './Input';

const RowInput = ({ label, subNameBlock, ...props }) => {
  return (
    <Row
      nameBlock={label}
      subNameBlock={subNameBlock}
      isRequired={props?.isRequired}
      component={<Input {...props} />}
    />
  );
};

RowInput.propTypes = {
  label: PropTypes.string,
  subNameBlock: PropTypes.string,
  isRequired: PropTypes.bool,
};

export default RowInput;
