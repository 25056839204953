import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

// IMPORTANT: do not forget about stroke-width option in styles
const ArrowIcon = ({ className, degrees }) => (
  <StyledArrow
    className={className}
    degrees={degrees}
    width="10" height="20"
    viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 1L11 11L1 21" stroke="#626473" strokeWidth="2"/>
  </StyledArrow>
);

const StyledArrow = styled.svg`
  ${({ degrees }) => degrees && css`
    transform: rotate(${degrees}deg);
  `}
`;

ArrowIcon.defaultProps = {
  className: '',
  degrees: 0,
};

ArrowIcon.propTypes = {
  className: PropTypes.string,
  degrees: PropTypes.number,
};

export default ArrowIcon;
