import React from 'react'
import { types } from 'shared';
const { PHOTO, VIDEO } = types.file.Types

import { Row } from '_legacy/common/Template'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import MultiSelectLibrary from '_legacy/components/Library/MultiSelectLibrary'
import MultiSelectBackdrop from '../../../../Library/configs/MutliSelect/MultiSelectBackdrop'

const UploadProfilePics = () => {
  const t = useGetTranslate()

  return (
    <Row
      nameBlock={t('edit-profile.form.profile-pics.label')}
      component={
        <MultiSelectLibrary
          maxSelectItems={5}
          name="user.profileMedia"
          dropZoneProps={{ accept: [PHOTO, VIDEO] }}
          customBackdrop={MultiSelectBackdrop}
        />
      }
    />
  )
}

export default UploadProfilePics
