import React from 'react'
import { useFormContext } from 'react-hook-form';
import { get, isEmpty } from 'lodash'
import { errorNames } from 'shared'

import { Input } from '_legacy/common/Input';
import Button from '_legacy/components/Button';

import { appApi } from 'api';
import { useFetch } from 'store/hooks/useFetch';
import { useGetTranslate, useGetTranslateWithKey } from 'store/hooks/globalState/useTranslates';
import { discountView } from 'utils/discountView';
import { getMonthDayYearDate } from 'utils/date'

import './index.scss'

const PROMO_CODE_VALUE = 'promoCodeValue'

const errorsList = {
  [errorNames.promoCodes.PROMO_CODE_IS_EXPIRED]: 'register.promocode.errors.expired',
  [errorNames.promoCodes.PROMO_CODE_NOT_FOUND]: 'register.promocode.errors.not-found',
  [errorNames.promoCodes.PROMO_CODE_IS_INACTIVE]: 'register.promocode.errors.inactive',
  [errorNames.promoCodes.PROMO_CODE_LIMIT_EXCEEDED]: 'register.promocode.errors.limit-exeeded',
  [errorNames.promoCodes.PROMO_CODE_IS_NOT_APPLICABLE_FOR_TALENT]: 'register.promocode.errors.not-applicable-for-talent',
  [errorNames.promoCodes.PROMO_CODE_HAVE_NOT_STARTED_YET]: 'register.promocode.errors.start-date-on-future',
  [errorNames.promoCodes.FORBIDDEN_TO_USE_PROMO_CODE_ON_EDIT]: 'register.promocode.errors.forbidden-to-use-on-edit',
}

const PromoCode = React.memo(({ name = 'promoCode', isRegistration = false }) => {
  const t = useGetTranslate()
  const tKey = useGetTranslateWithKey()
  const { watch, setValue, register, setError } = useFormContext()
  const formValue = watch()
  const promoCode = get(formValue, name)

  const promoCodeValue = watch(PROMO_CODE_VALUE, '')

  const code = get(promoCode, 'code')
  const discountAmount = get(promoCode, 'discountAmount')
  const discountType = get(promoCode, 'discountType')

  const [{ data, error, isLoading }, verifyPromocode] = useFetch(appApi.promoCodes.verifyPromoCodesForTalent)

  const sendPromoCode = () => verifyPromocode({ promoCode: promoCodeValue.toUpperCase(), isRegistration })

  const handleRemove = () => {
    setValue(PROMO_CODE_VALUE, null, { shouldDirty: true })
    setValue(name, null, { shouldDirty: true })
  };

  React.useEffect(() => register(name), [register])

  React.useEffect(() => {
    if (data) {
      setValue(name, data ?.promoCode, { shouldDirty: true })
    }
  }, [data])

  React.useEffect(() => {
    if (error) {
      const errorKey = get(error, 'name')
      const startsAt = get(error, 'context.startsAt')
      const startDate = startsAt && getMonthDayYearDate(startsAt)

      const message = tKey({
        tKey: errorsList[errorKey],
        phs: [{ ph: '{{startDate}}', value: startDate }],
      })

      setError(PROMO_CODE_VALUE, { message })
    }
  }, [error])

  return (
    <label className="promo-code payment-card__field">
      {isEmpty(promoCode) && <span className='payment-card__promo-code_enter'>
        <Input
          type='text'
          name={PROMO_CODE_VALUE}
          placeholder={t('register.promocode.placeholder')}
          validation={{
            pattern: {
              value: /^[-A-Za-z0-9]+$/,
              message: t('register.promocode.errors.invalid')
            }
          }}
        />
        <Button
          typeButton='button'
          text={t('payment-page.promo-code.apply')}
          stretch
          paddingVertical={13}
          paddingHorizontal={20}
          disabled={isEmpty(promoCodeValue)}
          handleOnClick={sendPromoCode}
          loader={isLoading}
        />
      </span>}
      {!isEmpty(promoCode) && <span className='payment-card__promo-code-view'>
        <span className="payment-card__promo-code-view_wr">
          <p>{code}</p>
          <span>
            {discountView(discountType, discountAmount)}
            &nbsp;
            {t('register.promocode.discount')}
          </span>
        </span>
        <span className="payment-card__promo-code-view_btn" onClick={handleRemove} />
      </span>}
    </label>
  )
})

export default PromoCode
