import React from 'react';
import get from 'lodash/get';

import Button from '_legacy/components/Button';
import CustomSelect from '_legacy/components/Table/Filters/Selects/components/Select';
import SelectAll from '_legacy/containers/PromoCodes/components/Custom/SelectAll';
import CustomInput from '_legacy/components/Table/Filters/CustomInput';
import ModalPortal from 'portals/ModalPortal';
import OfferingsList from '_legacy/containers/PromoCodes/components/Custom/OfferingsList';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useStateModal } from 'store/hooks/globalState/useModal';
import {
  useOfferingsPromoCodesFilters,
  useOfferingsPromoCodesTable,
  useSetOfferingsPromoCodesFilters,
} from 'store/hooks/globalState/useOfferingsPromoCodes';
import { offeringsTypesOptions } from '_legacy/configs/table/managePromoCodes';

import {
  StyledButtons,
  StyledContent,
  StyledHeader,
  StyledHeaderRow,
  StyledNav,
  StyledPanel,
  StyledSelected,
  StyledWrapper,
} from '_legacy/containers/PromoCodes/components/Custom/ModalCustomSelectsStyled';

const ModalCustomSelects = React.memo(({ onDone, props }) => {
  const t = useGetTranslate();
  const handleCloseModal = useStateModal(() => null);

  const bulkActionsConfig = React.useMemo(() => ({
    getFilters: useOfferingsPromoCodesFilters,
    setFilters: useSetOfferingsPromoCodesFilters,
  }), [useOfferingsPromoCodesFilters, useSetOfferingsPromoCodesFilters]);

  const selected = useOfferingsPromoCodesFilters(state => get(state, 'selected', 0));
  const oldSelect = React.useRef(selected);
  const setOldSelected = useSetOfferingsPromoCodesFilters(prev => ({ ...prev, selected: oldSelect.current }));

  const handleCancel = React.useCallback(() => {
    setOldSelected()
    handleCloseModal()
  },[])

  const handleSubmit = ({ selected, props }) => {
    onDone && onDone({ selected, props })
    handleCloseModal()
  }

  const offers = useOfferingsPromoCodesTable()

  const input = useOfferingsPromoCodesFilters(state => get(state, 'input', ''))
  const select = useOfferingsPromoCodesFilters(state => get(state, 'offeringTypeIn', ''))

  const applySelectFilter = ({ type }) => select ? type === select : true
  const applyInputFilter = ({ title }) => input ? title.includes(input) : true

  const offersWithFilters = offers.filter(applyInputFilter).filter(applySelectFilter)

  return (
    <ModalPortal isOpen showCloseButton onClose={handleCancel}>
      <StyledWrapper>
        <StyledHeader>
          <h4>{t('promo-codes.form.custom.modal.title')}</h4>
        </StyledHeader>
        <StyledContent>
          <StyledNav>
            <CustomInput
              field='input'
              getFn={useOfferingsPromoCodesFilters}
              setFn={useSetOfferingsPromoCodesFilters}
              placeholder={t('promo-codes.form.custom.modal.filed.search.placeholder')}
              className='filter__input'
            />
            <CustomSelect
              options={offeringsTypesOptions}
              field='offeringTypeIn'
              getFn={useOfferingsPromoCodesFilters}
              setFn={useSetOfferingsPromoCodesFilters}
              placeholder='promo-codes.form.custom.modal.filed.filter.placeholder'
              className='filter__select'
            />
          </StyledNav>
          <StyledHeaderRow>
            <span className='text'>
              {offersWithFilters.length} {t('promo-codes.form.custom.modal.list.header.total')}
            </span>
            <span className='text text--price'>{t('promo-codes.form.custom.modal.list.header.price')}</span>
            <SelectAll
              bulkActionsConfig={bulkActionsConfig}
              filteredList={offersWithFilters}
            />
          </StyledHeaderRow>
          <OfferingsList
            bulkActionsConfig={bulkActionsConfig}
            listLength={(offers || []).length}
            filteredList={offersWithFilters}
          />
        </StyledContent>
        <StyledPanel>
          <StyledSelected>
            {t('promo-codes.form.custom.modal.total-selected')}
            <span> {selected.length}</span>
          </StyledSelected>
          <StyledButtons>
            <Button
              text={t('promo-codes.form.custom.modal.button.cancel')}
              primary
              handleOnClick={handleCancel}
            />
            <Button
              text={t('promo-codes.form.custom.modal.button.done')}
              handleOnClick={() => handleSubmit({ selected, props })}
            />
          </StyledButtons>
        </StyledPanel>
      </StyledWrapper>
    </ModalPortal>
  );
});

export default ModalCustomSelects;

