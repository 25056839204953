import React from 'react'
import { useHistory } from 'react-router-dom'

import paginateLinkBuilder from 'utils/paginateLinkBuilder'

export default React.memo(function NextButton ({ page, pagesCount }) {
  const history = useHistory()
  const navigateForward = React.useCallback(() => {
    if (page === pagesCount) return
    history.push(paginateLinkBuilder(page + 1))
  }, [page, pagesCount, history])
  return (
    <div
      className={`button-wrapper next ${page === pagesCount ? 'disabled' : ''}`}
      onClick={navigateForward}
    >
      <div className='arrow next' />
    </div>
  )
})
