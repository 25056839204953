import { get } from 'lodash'

export const getAvailableRange = (slots = [], from, to) => {
  const result = []

  for (let i = 0; i <= slots.length; i++) {
    result.push({
      start: get(slots, [i - 1, 'end'], from),
      end: get(slots, [i, 'start'], to),
    })
  }

  return result
}
