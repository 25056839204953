import { _get, _post, _patch } from 'api/utils'

export const getExperienceOccurrence = ({ talentId, orderId }) => _get(
  `/talents/${talentId}/offers/experiences/occurrences/${orderId}`,
  { needAuthorization: true },
)

export const getExperienceOccurrenceOrders = ({ talentId, orderId, query }) => _get(
  `/talents/${talentId}/offers/experiences/occurrences/${orderId}/orders`,
  { needAuthorization: true, query },
)

export const checkExperienceOccurrenceAvailability = ({ talentId, offerId }) => _get(
  `/talents/${talentId}/offers/${offerId}/check-availability-to-edit`,
  { needAuthorization: true },
)

export const orderRequestAction = ({ talentId, actionType, ordersIds, body }) => _post(
  `/talents/${talentId}/orders/bulk/${actionType}`, {
    needAuthorization: true, query: { ordersIds }, body
  })

export const rejectOrders = ({ talentId, offerId }) => _post(
  `/talents/${talentId}/offers/${offerId}/reject-orders`,
  { needAuthorization: true }
)

export const checkOccurrenceAvailabilityToCancel = ({ talentId, offerId, experienceOccurrenceId }) => _get(
  `/talents/${talentId}/offers/${offerId}/occurrences/${experienceOccurrenceId}/check-availability-to-cancel`,
  { needAuthorization: true },
)

export const cancelOccurrence = ({ talentId, offerId, experienceOccurrenceId, body }) => _post(
  `/talents/${talentId}/offers/${offerId}/occurrences/${experienceOccurrenceId}/cancel-occurrence`,
  { needAuthorization: true, body }
)

export const updateOccurrenceTime = ({ offerExperienceOccurrenceId, startTime, endTime }) => _patch(
  `/offers/experiences/occurrences/${offerExperienceOccurrenceId}/update-time`,
  { needAuthorization: true, body: { startTime, endTime } }
)
