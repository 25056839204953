import React from 'react'
import PropTypes from 'prop-types'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const ErrorMessage = ({ message, styles }) => {
  const t = useGetTranslate()
  return (
    <div className="error-message-container">
      <span className="error-message" style={styles}>
        {t(message)}
      </span>
    </div>
  )
}

ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
  styles: PropTypes.object,
}

export default ErrorMessage
