
// import { addSeconds, subSeconds } from 'date-fns'
import uniqueId from 'lodash/uniqueId'
import get from 'lodash/get'
import { getSlotBackground } from '../configs/slot-config'

export const calendarAdapter = events =>
  (events || []).filter(Boolean).map(item => {
    const offerType = item.occurrence?.offerType || item.type
    const editable = get(item, 'editable', false)

    return {
      id: item.id,
      title: item.title,
      groupId: item.groupId,
      editable,
      constraint: item.constraint,
      overlap: item.overlap,
      start: item.startTime,
      end: item.endTime,
      durationEditable: item.durationEditable,
      display: item.display,
      borderColor: item.display === 'background' ? null : getSlotBackground(item.type),
      backgroundColor: item.display === 'background' ? null : getSlotBackground(offerType),
      extendedProps: {
        aficionadoChoose: item.aficionadoChoose,
        start: item.startTime,
        end: item.endTime,
        afterDriveTime: item.afterDriveTime,
        beforeDriveTime: item.beforeDriveTime,

        // should add additional prop "info" (to another field (participants, ...))
        offerType,
        participants: item.occurrence?.participantsCount,
        maxParticipants: item.occurrence?.maxParticipantsCount,
        price: item.occurrence?.price,
        locations: item.occurrence?.locations,
        offerSequentialId: item.occurrence?.offerSequentialId,
        occurrenceId: item.occurrence?.id,

        //two properties below optionally but they needed to add different styles for slots
        textColor: '#FFFFFF',
      }
    }
  })

export const createDraftEventTimeSlot = event => {
  return {
    ...event,
    title: event.title,
    startTime: event.start,
    endTime: event.end,
    editable: true,
    backgroundColor: getSlotBackground('OFFER'),
    draft: true,
    id: uniqueId('time-slot-'),
  }
}
