// Modules
import React from 'react'
import { useParams } from 'react-router-dom'
import always from 'lodash/fp/always'
import { FileType } from 'shared'
import set from 'lodash/set'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoVoiceOverLessonBookingDetails } from './state/request'
import { useSetVideoVoiceOverLessonBookingDetails, useVideoVoiceOverLessonBookingDetails } from './state/store'

// Utils
import { getDaysTo, getLocale } from 'utils/date'
import { getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText } from 'components/OfferingDetails/utils'
import { getVideoVoiceOverLessonOrderConfig } from './utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import ProductTable from 'components/OfferingDetails/components/ProductTable'
import OrderUploadFiles from 'components/OfferingDetails/components/OrderUploadFiles'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsGenericStatus from 'components/OfferingDetails/components/OfferingDetailsGenericStatus'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsWaiting from 'components/OfferingDetails/components/OfferingDetailsWaiting'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'

const VideoVoiceOverLessonBookingDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const timeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  // state
  const order = useVideoVoiceOverLessonBookingDetails()
  const clearOrder = useSetVideoVoiceOverLessonBookingDetails(() => null)
  const fetchBookingsDetails = useFetchVideoVoiceOverLessonBookingDetails()
  const { review, occurrence, number, statusTimeline } = order || {}

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
  }, [])

  React.useEffect(() => () => {
    clearOrder()
  }, [])

  if (!order) return <PageLoader />

  const { pendingAction, uploads, userUploads, talent } = occurrence
  const daysLeft = getDaysTo(new Date(pendingAction?.due), { locale: getLocale(userLang) })
  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)
  const cancelText = getCancelText({ t, ...order })
  const refundText = getAficionadoRefundText({ t, refundRequests: order.refundRequests })
  const c = getVideoVoiceOverLessonOrderConfig(order)

  return (
    <div className="video-voice-over-lesson-order od">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.video-voice-over-lesson')}
        title={`${t('booking-details.main.title')} #${number}`}
      />

      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, timeZone, true)} />
          <div className="od__inner-wrapper">
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.submitted-by.title')}</h6>
                <p className="od--main-text od--accented">
                  <OrderUploadFiles files={userUploads} />
                </p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box">
            {c.isShowWaiting && (
              <OfferingDetailsWaiting
                waitingTitle={t('shared.offers.video-voice-over-lesson')}
                waitingText={t('booking-details.time-left.text')}
                timeLeft={daysLeft}
              />
            )}

            {c.isShowCanceled && (
              <CancelStatusWithContact
                refundText={refundText}
                cancelText={cancelText}
                title={t('shared.offers.video-voice-over-lesson')}
              />
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <OfferingDetailsGenericStatus
                  title={t('shared.offers.video-voice-over-lesson')}
                  type={t('booking-details.main-completed.title')}
                />
                <p className="od--main-text mb-12">{t('booking-details.main-completed.text')}</p>
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={orderId}
                  onSuccess={fetchBookingsDetails}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('shared.offers.video-voice-over-lesson')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {c.isShowCompleted && (
        <div className="wrapper content-wrapper mt-100 mb-62">
          <ProductTable getTableFn={always(uploads || [])} />
        </div>
      )}
    </div>
  )
}

export default withLibrary(VideoVoiceOverLessonBookingDetails, { types: FileType.VIDEO })
