import React from 'react';
import compose from 'lodash/fp/compose'
import { useParams } from 'react-router';

import {
  getExperienceOccurrenceOrders as getExperienceOccurrenceOrdersApi,
} from '../api'

import {
  useSetExperienceOrderParticipants,
  useSetExperienceOrderParticipantsFilters,
} from '../store'
import { useTalentId } from 'store/hooks/globalState/useUser';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import normalizeParams from 'utils/normalizeParams';
import { parseOrderParticipant } from '_legacy/components/Table/Filters/utils/participant'
import { OrderStatus } from 'shared'

const normalizer = compose(
  normalizeParams,
  parseOrderParticipant,
)

const { CLOSED, APPROVED, COMPLETED, ADMIN_REVIEW, CANCELED, REFUNDED } = OrderStatus

export function useFetchExperienceOrderParticipants() {
  const setExperienceOrderParticipantsFilters = useSetExperienceOrderParticipantsFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setExperienceOrderParticipants = useSetExperienceOrderParticipants(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  const talentId = useTalentId()
  const { orderId } = useParams()
  return React.useCallback((options) => {
    const query = {
      orderStatusIn: [ADMIN_REVIEW, APPROVED, COMPLETED, CLOSED, CANCELED, REFUNDED],
      ...normalizer(options)
    }

    setExperienceOrderParticipants(null)

    getExperienceOccurrenceOrdersApi({ talentId, orderId, query })
      .then(({ items, pageInfo }) => {
        setExperienceOrderParticipantsFilters(pageInfo);
        setExperienceOrderParticipants(items);
      })
      .catch((error) => {
        handleResponseError(error)
        console.warn(error)
      });
  }, [orderId, setExperienceOrderParticipantsFilters, setExperienceOrderParticipants]);
}
