// Modules
import React from 'react'

// Store (hooks)
import { useUser, useUserLocale } from 'store/hooks/globalState/useUser';

// Utils
import { getShortDateWithLocaleTime } from 'utils/date'
import { getUserTimeZone } from 'utils/user'
import { parseDate } from 'utils/services/timezone'

// Legacy components
import LineString from '_legacy/components/Table/table-components/types/common/LineString'


export default React.memo(function ReviewDate({ isLoading, createdAt }) {
  const timeZone = useUser(getUserTimeZone)
  const locale = useUserLocale()
  const date = getShortDateWithLocaleTime(parseDate(createdAt, timeZone), locale)

  return <LineString isLoading={isLoading} str={date} />
})
