import React from 'react';
import styled from 'styled-components';

import { Row } from '_legacy/common/Template';
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent';
import { getDurationOptions } from '../../utils/timeConfig'
import { getLocale } from 'utils/date'

const Duration = ({ name = 'duration', maxDuration, minValue }) => {
  const t = useGetTranslate();
  const locale = useUserLang(getLocale)
  const durationOptions = getDurationOptions({ locale })

  return (
    <Row
      nameBlock={t('create-experience.form.duration.label')}
      component={
        <StyledDurationWrapper>
          <SelectComponent
            options={durationOptions}
            name={name}
            rules={{
              required: 'create-experience.form.duration.placeholder',
              validate: ({ value }) => {
                if (maxDuration && maxDuration < value) return 'create-experience.form.duration.error.max'
                if (minValue && minValue > value) return 'create-experience.form.duration.error.min'
              },
            }}
            placeholder={t('create-experience.form.duration.placeholder')}
          />
        </StyledDurationWrapper>
      }
    />
  )
};

const StyledDurationWrapper = styled.div`
  max-width: 262px;
`;

export default Duration;
