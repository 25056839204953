import React from 'react'
import styled from 'styled-components'
import prop from 'lodash/fp/prop'
import { useHistory } from 'react-router-dom'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSwitchToManager } from 'requests/talent-switcher';
import { useUser, useUserFullName } from 'store/hooks/globalState/useUser';
import { useSwitchAgent, useSetNavigationAgent, useShouldNavigate, useSwitchNavigationPath } from 'store/hooks/globalState/useSwitchNavigation'
import Avatar from '_legacy/components/Avatar'

const SwitcherHeader = () => {
  const history = useHistory()

  const t = useGetTranslate()
  const fullName = useUserFullName()
  const switchToManager = useSwitchToManager();
  const userPhoto = useUser(prop('user.profileImage.file'))

  const switchAgent = useSwitchAgent()
  const shouldNavigate = useShouldNavigate()
  const navigationPath = useSwitchNavigationPath()
  const setSwitchToAgent = useSetNavigationAgent()

  const handleManagerSwitch = () => {
    setSwitchToAgent(true)
    if (navigationPath?.agentRoute) {
      history.push(navigationPath?.agentRoute)
    }
  }

  React.useEffect(() => {
    if (switchAgent && shouldNavigate) {
      switchToManager()
      setSwitchToAgent(null)
    }
  }, [switchAgent, shouldNavigate])

  return (
    <StyledSwitcherHeading>
      <StyledSwitcherTitle>
        <Avatar file={userPhoto} />
        <StyledSwitcherInfo onClick={handleManagerSwitch}>
          <p>{fullName}</p>
          <span>{t('switch-profile.my-profile')}</span>
        </StyledSwitcherInfo>
      </StyledSwitcherTitle>
      <StyledSwitcherHeader>{t('switch-profile.header')}</StyledSwitcherHeader>
    </StyledSwitcherHeading>
  )
}

const StyledSwitcherHeading = styled.div`
  padding: 12px 16px 8px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgb(0 0 0 / 15%);
  border-radius: 4px 4px 0 0;
`

const StyledSwitcherHeader = styled.div`
  margin-top: 12px;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightGrey};
`

const StyledSwitcherTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  
  .avatar {
    width: 40px;
    height: 40px;
  }
`

const StyledSwitcherInfo = styled.div`
  margin-left: 12px;
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grey};
  }
  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightGrey};
  }
`

export default SwitcherHeader
