import { TalentOfferPendingActionType as T } from 'shared'

// TODO: Maybe share this map across the app
export const currentActionsTitles = {
  [T.WAIT_FOR_PAYMENT]: 'my-orders.current-action.status.wait-for-payment',
  [T.REVIEW_BOOKING_REQUEST]: 'my-orders.current-action.status.review-book-request',
  [T.LEAVE_REVIEW]: 'my-orders.current-action.status.leave-review',
  [T.AFICIONADO_CONFIRM]: 'my-orders.current-action.status.aficionado-confirm',
  [T.UPLOAD_VIDEO]: 'my-orders.current-action.status.upload-video',
}
