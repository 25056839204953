import React from 'react';

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState';

import FullPage from '_legacy/components/Landing/full-page';
import CarouselSection from '_legacy/components/Landing/CarouselSection';
import { WrapedBothSliders, WrapedTopVideoMessages, WrapedTopExperiences } from '_legacy/components/Landing/SlidersSection';
import ReasonsToJoinSection from '_legacy/components/Landing/ReasonsToJoinSection';

export default function Landing () {
  const windowDimensions = useWindowDimensions();

  React.useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0];
    body.style.backgroundColor = '#272835';

    return () => (body.style.backgroundColor = '#ffffff');
  }, []);

  const config = React.useMemo(() => {
    const { width, height } = windowDimensions;
    if (width <= 768 || height <= 600) {
      return [
        CarouselSection,
        WrapedTopVideoMessages,
        WrapedTopExperiences,
        ReasonsToJoinSection
      ];
    }
    return [
      CarouselSection,
      WrapedBothSliders,
      ReasonsToJoinSection
    ];
  }, [windowDimensions]);

  return (
    <FullPage config={config} />
  );
}
