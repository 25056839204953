// Modules
import _get from 'lodash/get'
import constant from 'lodash/constant'
import isNumber from 'lodash/isNumber'
import cond from 'lodash/cond'
import stubTrue from 'lodash/stubTrue'
import matches from 'lodash/matches'

import { set } from 'date-fns'

import {
  RecurringRepeatEveryType,
  RecurringRepeatEveryMonthType,
  RecurringPredefinedValues,
  RecurringEndsType,
} from 'shared'

// Utils
import { isISODateString } from 'utils/date'
import { repeatEveryTypeWithTranslate, repeatOn as _repeatOn } from '_legacy/containers/Experience/translates'
import { weekOfMonth } from '_legacy/containers/Experience/utils'
import { parseDate, prepareDate } from 'utils/services/timezone'
import { getTimeZoneFromOccurrence } from 'utils/forms/adapters/timezone'

import { recurringModalDefaultState } from '_legacy/components/CreateExperience/utils/defaultValues'

const { DAY, WEEK, MONTH, YEAR } = RecurringRepeatEveryType
const { SINGLE_DATE, POSITION_DATE } = RecurringRepeatEveryMonthType
const { CUSTOM } = RecurringPredefinedValues
const { NEVER } = RecurringEndsType

// TODO: Add all end types to the shared module
const recurringEndsType = {
  NEVER: NEVER,
  AFTER: 'AFTER',
  ON: 'ON'
}


/**
 *
 * Transform reccuring data from form data into occurrence view
 *
**/
export const prepareRecurring = formData => {
  const get = (path, defaultValue) => _get(formData, path, defaultValue)

  const value = formData?.recurring?.value
  const timeZone = formData?.timeZone

  if (value !== CUSTOM) {
    return { type: value }
  }

  const dateOfExperience = prepareDate(get('dateOfExperience'), timeZone)

  const endsType = {
    NEVER,
    ON: prepareDate(get(['recurringModal', 'ends-on']), timeZone),
    AFTER: Number(get(['recurringModal', 'after-on'])),
  }

  const typeMonth = {
    [SINGLE_DATE]: {
      type: SINGLE_DATE,
      dayNumber: new Date(dateOfExperience).getDate(),
    },
    [POSITION_DATE]: {
      type: POSITION_DATE,
      positionType: weekOfMonth(dateOfExperience),
      positionDay: new Date(dateOfExperience).getDay(),
    },
  }

  const repeatEvery = {
    [DAY]: {
      through: +get(['recurringModal', 'through']),
      type: DAY,
    },
    [WEEK]: {
      through: +get(['recurringModal', 'through']),
      on: get(['recurringModal', 'on'], []).map(Number),
      type: WEEK,
    },
    [MONTH]: {
      type: MONTH,
      through: +get(['recurringModal', 'through']),
      on: typeMonth[get(['recurringModal', 'repeat-on', 'value'])],
    },
    [YEAR]: {
      through: +get(['recurringModal', 'through']),
      type: YEAR,
    },
  }

  return {
    type: CUSTOM,
    options: {
      ends: endsType[get(['recurringModal', 'ends'])],
      repeatEvery: repeatEvery[get(['recurringModal', 'type', 'value'])],
    },
  }
}


/**
 *
 * Transform reccuring data from occurrence view into form data
 *
**/
export const parseRecurring = (occurrence, t) => {
  if (occurrence?.recurring?.type !== CUSTOM) {
    return recurringModalDefaultState
  }

  const { ON, AFTER, NEVER } = recurringEndsType

  const recurringOptions = _get(occurrence, 'recurring.options')
  const get = (path, defaultValues) => _get(recurringOptions, path, defaultValues)

  const timeZone = getTimeZoneFromOccurrence(occurrence)
  const startTime = occurrence?.startTime
  const dateOfExperience = parseDate(startTime, timeZone)

  const through = +get('repeatEvery.through')

  const types = repeatEveryTypeWithTranslate(t)
  const type = types.find(type => type.value === get('repeatEvery.type'))
  const typeValue = type?.value

  const on = (typeValue === WEEK)
    ? get('repeatEvery.on', []).map(String)
    : null

  const getRepeatOn = () => {
    const isSingle = get('repeatEvery.on.type') === SINGLE_DATE

    const dateOfExperienceRepeatOn = set(dateOfExperience, {
      date: +get('repeatEvery.on.dayNumber'),
    })

    return isSingle
      ? _repeatOn(dateOfExperienceRepeatOn)[0]
      : _repeatOn(dateOfExperience)[1]
  }

  const repeatOn = (typeValue === MONTH)
    ? getRepeatOn()
    : null

  const getEndsType = cond([
    [isISODateString, constant(ON)],
    [isNumber, constant(AFTER)],
    [matches(NEVER), constant(NEVER)],
    [stubTrue, constant(undefined)],
  ])

  const occurrenceEnds = get('ends')
  const ends = getEndsType(occurrenceEnds)

  const endsOn = (!!occurrenceEnds && ends === ON)
    ? parseDate(occurrenceEnds, timeZone)
    : null

  const afterOn = (+occurrenceEnds && ends === AFTER)
    ? +occurrenceEnds
    : null

  return {
    ['through']: through,
    ['type']: type,
    ['on']: on,
    ['ends']: ends,
    ['after-on']: afterOn,
    ['ends-on']: endsOn,
    ['repeat-on']: repeatOn,
  }
}
