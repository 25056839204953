import { cond, always, prop, T } from 'lodash/fp'

import ManagerInvitationResponse from './ManagerInvitationResponse'
import ManagerInvitationRequest from './ManagerInvitationRequest'

const ManagerInvitation = cond([
  [prop('additionalType'), always(ManagerInvitationResponse)],
  [T, always(ManagerInvitationRequest)],
])

export default ManagerInvitation
