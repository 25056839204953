import React from 'react'

import FullPage from '_legacy/components/NewLanding/full-page'
import GettingStarted from '_legacy/components/NewLanding/GettingStarted'
import EverythingYouNeedSection from '_legacy/components/NewLanding/EverythingYouNeedSection'
import TalentMemberBenefitsSection from '_legacy/components/NewLanding/TalentMemberBenefitsSection'
import DemocratizingTalentAccessSection from '_legacy/components/NewLanding/DemocratizingTalentAccessSection'

export default function NewLanding() {
  React.useEffect(() => {
    const body = document.getElementsByTagName('BODY')[0]
    body.style.backgroundColor = '#272835'

    return () => (body.style.backgroundColor = '#ffffff')
  }, [])

  const config = React.useMemo(
    () => [
      DemocratizingTalentAccessSection,
      EverythingYouNeedSection,
      TalentMemberBenefitsSection,
      GettingStarted,
    ],
    []
  )

  return <FullPage config={config} />
}
