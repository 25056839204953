// TODO: Refactor wrappers and forms according to DRY after project ends
import React from 'react'
import { string, number, oneOfType } from 'prop-types'
import { get, isEmpty, castArray } from 'lodash'
import { FormProvider, useForm } from 'react-hook-form'

import { FileType } from 'shared'

import { getCharityLabel } from 'utils/charities'
import { useModal } from 'store/hooks/useModal.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'

import { useBookVoiceOverLessons } from './state/requests.js'

import Price from '_legacy/components/Price/'
import BookNowButton from '_legacy/components/Button/BookNowButton.js'
import MultiSelectLibraryModal from '_legacy/components/Library/MultiSelectLibrary/components/MultiSelectLibraryModal.js'

import OrderedRulesList from 'components/OrderedRulesList/'

const { VIDEO } = FileType


const VoiceOverLessonBook = ({
  responseTime,
  price,
  specialInstructions,
  rules,
  id,
  offeringType,
  charities
}) => {
  const t = useGetTranslate()
  const bookVoiceOverLessons = useBookVoiceOverLessons()
  const methods = useForm()
  const form = methods.watch()
  const selectedFileLength = get(form, 'userFilesTokens.length', 0)
  const [isOpen, openModal, closeModal] = useModal()

  const onSubmit = () => {
    bookVoiceOverLessons({
      offerVoiceOverLessonId: id,
      userFilesTokens: castArray(methods.getValues('userFilesTokens')),
      offeringType: offeringType,
    })
  }

  return (
    <div>
      <div className="lesson-preview">
        <OrderedRulesList rules={rules} />

        { specialInstructions && (
          <div className="special-instructions">
            <h6>{t('lessons.preview.special-instructions')}</h6>
            <p>{specialInstructions}</p>
          </div>
        )}

        { responseTime &&
          <div>
            <div className="response-time">
              <span className="label">{t('lessons.preview.response-time')} &nbsp;</span>
              <span className="value">{responseTime}</span>
            </div>
          </div>
        }

        <div className="lesson-preview__inner">
          {!isEmpty(charities) && (
            <div className="charity-wrap">
              <span className="label">{t('lessons.preview.charity-beneficiary')} &nbsp;</span>
              {charities.map((charity) => (
                <p className="value" key={charity.id}>{getCharityLabel(charity)};&nbsp;</p>
              ))}
              <p className='charities-tip'>{t('shared.charities.tip')}</p>
            </div>
          )}
        </div>

        <Price price={Number(price)} withCurrency />

        <BookNowButton
          typeButton="submit"
          classBtn="lessons__button"
          paddingVertical="13"
          handleOnClick={openModal}
        />
      </div>

      <FormProvider {...methods}>
        <MultiSelectLibraryModal
          name={'userFilesTokens'}
          isOpen={isOpen}
          onSubmit={onSubmit}
          closeModal={closeModal}
          maxSelectItems={5}
          libraryModalProps={{
            subHeader: t('lessons.book-modal.sub-header'),
            enableTypes: VIDEO,
            submitBtnDisabled: selectedFileLength === 0,
          }}
          dropZoneProps={{
            maxSize: 1000,
            accept: VIDEO
          }}
        />
      </FormProvider>
    </div>
  )
}

VoiceOverLessonBook.propTypes = {
  responseTime: string,
  price: string,
  specialInstructions: string,
  rules: string,
  id: oneOfType([string, number]),
  offeringType: string,
}

export default VoiceOverLessonBook
