import React from 'react';
import PropTypes from 'prop-types';

import BasicActions from '_legacy/components/TalentPublicView/DesktopSlider/BasicActions'
import ArrowButton from '_legacy/components/TalentPublicView/DesktopSlider/components/ArrowButton'

const Actions = ({ slidesCount, onNext, onPrev }) => {

  return (
    <BasicActions.WithoutArrows
      slidesCount={slidesCount}
      onNext={onNext}
      onPrev={onPrev}
      topOffset={48}
      padding="0 0 0 11px"
      className="tbv-slider-actions-video-messages"
    >
      <div className="tbv-slider-actions-video-messages-content">
        {slidesCount > 1 && (
          <ArrowButton
            className="tbv-slider-actions-left-arrow"
            isMirrored={true}
            onClick={onPrev}
          />
        )}
        {slidesCount > 1 && (
          <ArrowButton
            className="tbv-slider-actions-right-arrow"
            onClick={onNext}
          />
        )}
      </div>
    </BasicActions.WithoutArrows>
  );
};

Actions.propTypes = {
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  slidesCount: PropTypes.number.isRequired,
  id: PropTypes.string,
};

export default Actions
