import React from 'react'

import TwoLineString from '../common/TwoLineString'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getLocationDetails, getLocationName } from 'utils/location'

const Location = React.memo(({ isLoading, occurrence }) => {
  const t = useGetTranslate()
  const tooltipLocation = <div>
    <p>{t(getLocationName(occurrence))}</p>
    <p>{t(getLocationDetails(occurrence))}</p>
  </div>

  return (
    <PopperTooltip Component={tooltipLocation}>
      <TwoLineString
        isLoading={isLoading}
        mainText={t(getLocationName(occurrence))}
        subText={t(getLocationDetails(occurrence))}
      />
    </PopperTooltip>
  )
})

export default Location
