import React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import cx from 'classnames';
import get from 'lodash/get';
import compact from 'lodash/compact';

import AsyncSelect from './basic/AsyncSelectComponent';
import { loadCharitiesOptions } from 'utils/services/loaders/charities';
import { Trans } from 'store/hooks/globalState/useTranslates';

const LoaderMessage = () => (
  <div className='react-select-loader'>
    <span className='react-select-loader__message'>Loading</span>
    <span className='react-select-loader__dot'>.</span>
    <span className='react-select-loader__dot'>.</span>
    <span className='react-select-loader__dot'>.</span>
  </div>
);

const CharitiesSelect = ({ name, placeholder = 'charities.charity.placeholder', }) => {
  const { errors, watch, setValue } = useFormContext();
  const { append, remove, fields } = useFieldArray({ name });
  const error = get(errors, name);
  const value = watch(name, []);
  const loadWrapper = async (...props) => {
    const response = await loadCharitiesOptions(...props);
    return {
      ...response,
      options: response.options.filter(
        o => !watch(name, [])?.find(f => f && f.value === o.value),
      ),
    };
  };

  const appendHandler = () => {
    append({ value: null, label: null });
  };

  const removeHandler = (index) => {
    if (index === 0 && fields.length === 1) {
      setValue(name, [{ value: null, label: null }], { shouldDirty: true });
    } else {
      remove(index);
    }
  };

  const showRemoveButton = idx => {
    if (idx !== 0) return true;
    if (value[0].value) return true;
  };

  const classes = cx('react-select-container', {
    'react-select-container--danger': error,
  });

  const val = watch(name, []);

  return (
    <div className='_charities-list-wrapper'>
      <ul className='_charities-list'>
        {compact(fields).map((item, idx) => (
          <li key={item.id} className='charities-list__item'>
            <div className='charities-list__item-wrapper'>
              <div className={classes}>
                <AsyncSelect
                  loadOptionsOnMenuOpen={false}
                  loadOptions={loadWrapper}
                  placeholder={placeholder}
                  defaultValue={item}
                  name={`${name}.${idx}`}
                  loadingMessage={LoaderMessage}
                  classNamePrefix="react-select"
                  // isClearable={val[idx]?.value}
                  isShowArrow={!val[idx]?.value}
                />
              </div>

              {showRemoveButton(idx) && (
                <button
                  type='button'
                  onClick={() => removeHandler(idx)}
                  className='form-row__remove form-row__remove--black'
                />
              )}
            </div>
          </li>
        ))}
      </ul>
      {fields.length < 3 && (
        <button
          className='mt-20 form-row__button form-row__button--black'
          type='button'
          onClick={appendHandler}
        >
          + Add more
        </button>
      )}
      <span className='charity-tip'><Trans tKey='shared.charities.text' /></span>
    </div>
  );
};

CharitiesSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.object,
  placeholder: PropTypes.string,
  charitiesState: PropTypes.array,
  setCharitiesState: PropTypes.func,
};

export default CharitiesSelect;
