import clamp from 'date-fns/clamp'

export const getSlotsInRange = (slots = [], start, end) => {
  return slots
    .map(slot => {
      if (slot.end.getTime() < start.getTime()) return null
      if (slot.start.getTime() > end.getTime()) return null

      return {
        ...slot,
        start: clamp(slot.start, { start, end }),
        end: clamp(slot.end, { start, end }),
      }
    })
    .filter(Boolean)
}
