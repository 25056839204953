import React from 'react'

import Search from '_legacy/components/Search'
import EmptyTalentList from '_legacy/components/Search/List/EmptyTalentList'

import {
  useSearchList,
  useSearchListFilters,
  useClearSearchFilters,
  useSetSearchListFilters,
} from 'store/hooks/globalState/useSearchList'
import { useSearchPromotedTalents } from 'store/hooks/globalState/useSearchPromotedList'
import { useFetchSearchItems } from 'requests/search/talents'
import { useFetchPromotedTalentsItems } from '_legacy/components/Search/FeaturedSlider/state/requests'
import { filtersConfig } from '_legacy/configs/search/talent'
import { fields } from '_legacy/configs/search/talent'

const SearchTalents = () => {
  return (
    <Search
      fields={fields}
      getListFn={useSearchList}
      getPromotedListFn={useSearchPromotedTalents}
      filtersConfig={filtersConfig}
      clearFn={useClearSearchFilters}
      fetchListFn={useFetchSearchItems}
      fetchPromotedListFn={useFetchPromotedTalentsItems}
      getFiltersFn={useSearchListFilters}
      EmptyListComponent={EmptyTalentList}
      setFiltersFn={useSetSearchListFilters}
    />
  )
}

export default SearchTalents
