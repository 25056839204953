import React from 'react'
import styled from 'styled-components'
import './index.scss'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import HintModal from './HintModal'
import SelectAll from './SelectAll'
import BulkHeader from './BulkHeader'
import SortItem from './SortItem'

export default function DesktopHeader({
  tableConfig,
  actionsConfig,
  bulkActionsConfig,
  getTableFn,
  getFiltersFn,
  setFiltersFn,
  tableName,
  withRejected,
}) {
  const t = useGetTranslate()
  const parsedHeaderConfig = React.useMemo(() => {
    return tableConfig.map(
      ({ header, hint, field, className }, i) => {
        return (
          <div key={i} className={`col col-${i + 1} ${className}`}>
            <SortItem
              title={header}
              field={field}
              getFn={getFiltersFn}
              setFn={setFiltersFn}
            />
            {hint && <HintModal header={header} hint={hint} />}
          </div>
        )
      }
    )
  }, [tableConfig, t])

  return (
    <>
      {bulkActionsConfig && (
        <BulkHeader bulkActionsConfig={bulkActionsConfig} />
      )}
      <StyledWrapper actionsConfig={actionsConfig}>
        {bulkActionsConfig && (
          <SelectAll
            bulkActionsConfig={bulkActionsConfig}
            getTableFn={getTableFn}
            withRejected={withRejected}
          />
        )}
        <StyledRowContent
          className={`row-content ${tableName}-row`}
          count={tableConfig.length}
          bulkActionsConfig={bulkActionsConfig}
        >
          {parsedHeaderConfig}
        </StyledRowContent>
      </StyledWrapper>
    </>
  )
}

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding-left: 34px;
  ${({ actionsConfig }) => actionsConfig && 'padding-right: 28px'};
  border-bottom: solid 1px ${({ theme: { colors } }) => colors.ultraLightBlue};
`

const StyledRowContent = styled.div`
  display: grid;
  grid-template-columns: ${({ count }) => `repeat(${count}, 1fr)`};
  grid-gap: 5px;
  align-items: center;
  width: 100%;
  height: 70px;
  .col {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.lightGrey};
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }
`
