import React from 'react';
import { ReactComponent as MicrophoneIcon } from '../../assets/microphone-icon.svg'
import { ReactComponent as MicrophoneOffIcon } from '../../assets/microphone-off-icon.svg'
import './index.scss'

import useVideoContext from '../../hooks/useVideoContext'
import useLocalAudioToggle from '../../hooks/useLocalAudioToggle'

export default function ToggleAudioButton({ disabled }) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');

  return (
    <button
      className='video-button video-button--audio'
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || disabled}
    >
      {isAudioEnabled ? <MicrophoneIcon /> : <MicrophoneOffIcon /> }
      {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}
    </button>
  );
}
