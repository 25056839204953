import Desktop from './Desktop/Desktop';
import Empty from './Desktop/Empty';
import MobileSlide from './Mobile/MobileSlide';
import MobileEmpty from './Mobile/MobileEmpty';

Desktop.Empty = Empty;
MobileSlide.Empty = MobileEmpty;

const Slide = {
  DesktopSlide: Desktop,
  MobileSlide
};

export default Slide;
