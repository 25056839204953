import { _get, _patch } from '../../utils';

export const getNotifications = ({ talentId }) => _get(
  `/talents/${talentId}/settings/notifications`,
  { needAuthorization: true }
);
export const patchNotifications = ({ talentId, data }) => _patch(
  `/talents/${talentId}/settings/notifications`,
  { body: data, needAuthorization: true }
);
