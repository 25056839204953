// Base imports
import React from 'react'
import propEq from 'lodash/fp/propEq'
import { OrderStatus } from 'shared'

// Requests
import { useFetchExperienceOrderParticipants } from '../state/requests/experience-order-participants'

// Store (Hooks)
import {
  useExperienceOrderParticipantsFilters,
  useSetExperienceOrderParticipantsFilters,
  useExperienceOrderParticipants,
} from '../state/store'

// Utils
import {
  filtersConfig,
  counterParticipantsConfig,
  subFiltersConfig,
  fields,
} from '_legacy/configs/table/orderDetailsRequests'

// Legacy components
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import {
  OFFERINGS_DETAILS_PARTICIPANTS,
  elementsTypes,
} from '_legacy/components/Table/table-components/types/order-details/config'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import CancelModal from '../../../components/CancelModal'
import { useModal } from 'store/hooks/useModal'
import { useCancelOrder } from '../state/requests/experience-order-details'

const sortFields = {
  AFICIONADO_FULL_NAME: 'AFICIONADO_FULL_NAME',
  PARTICIPANTS_COUNT: 'PARTICIPANTS_COUNT',
  ORDER_PRICE_AMOUNT: 'ORDER_PRICE_AMOUNT',
  ORDER_NUMBER: 'ORDER_NUMBER',
}

const tableConfig = [
  {
    header: 'table.header.aficionado',
    field: sortFields.AFICIONADO_FULL_NAME,
    type: elementsTypes.aficionado,
  },
  {
    header: 'table.header.order',
    type: elementsTypes.order,
    field: sortFields.ORDER_NUMBER,
  },
  {
    header: 'table.header.participants',
    field: sortFields.PARTICIPANTS_COUNT,
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.amount',
    field: sortFields.ORDER_PRICE_AMOUNT,
    type: elementsTypes.amount,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    type: elementsTypes.status,
    className: 'centered',
  },
]

const shouldShowCancelAction = propEq('status', OrderStatus.APPROVED)

const ExperienceOrderDetailsParticipants = () => {
  const t = useGetTranslate()
  const cancelOrder = useCancelOrder()
  const [orderIdToRemove, setOrderIdToRemove] = React.useState(null)
  const [isCancelOrderModalOpen, openCancelOrderModal, closeCancelOrderModal] = useModal(false)

  const handleCancelOrder = React.useCallback(([orderId]) => {
    setOrderIdToRemove(orderId)
    openCancelOrderModal()
  }, [])

  const actionsConfig = React.useMemo(() => {
    return [{ label: t('table.actions.cancel'), cbFn: handleCancelOrder, shouldShow: shouldShowCancelAction }]
  }, [t])

  return (
    <div className="wrapper content-wrapper">
      <Filters
        filtersConfig={filtersConfig}
        getFn={useExperienceOrderParticipantsFilters}
        setFn={useSetExperienceOrderParticipantsFilters}
      />
      <SubFilters
        counterConfig={counterParticipantsConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useExperienceOrderParticipantsFilters}
        setFn={useSetExperienceOrderParticipantsFilters}
      />
      <Table
        tableName={OFFERINGS_DETAILS_PARTICIPANTS}
        emptyTableText={'table.empty-participants'}
        tableConfig={tableConfig}
        getTableFn={useExperienceOrderParticipants}
        fields={fields}
        actionsConfig={actionsConfig}
        getFiltersFn={useExperienceOrderParticipantsFilters}
        setFiltersFn={useSetExperienceOrderParticipantsFilters}
        fetchTableFn={useFetchExperienceOrderParticipants}
      />

      <CancelModal
        orderId={orderIdToRemove}
        onClose={closeCancelOrderModal}
        isOpen={isCancelOrderModalOpen}
        cancelHandler={cancelOrder}
        buttonText="cancel-reason.cancel-occurrence.button-cancel-order"
      />
    </div>
  )
}

export default ExperienceOrderDetailsParticipants
