import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { types } from 'shared';

import { RadioButton, Row } from '_legacy/common';
import HintButton from '_legacy/common/Buttons/HintButton';
import Custom from '_legacy/containers/PromoCodes/components/Custom';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { PROMO_CODES_APPLICABLE_OFFERINGS_HIT } from '_legacy/components/Modal/modals-types.js';

const { ApplicableOfferings } = types;

const Offerings = ({ name }) => {
  const t = useGetTranslate();
  const { control } = useFormContext()

  const typesOfOfferings = useWatch({
    control,
    defaultValue: ApplicableOfferings.ALL,
    name,
  });

  return (
    <Row
      nameBlock={t('promo-codes.form.applicable-offerings.label')}
      tip={<HintButton modalType={PROMO_CODES_APPLICABLE_OFFERINGS_HIT} />}
      isRequired
      component={
        <>
          <RadioButton
            name={name}
            value={ApplicableOfferings.ALL}
            label={t('promo-codes.form.applicable-offerings.all-offerings.label')}
            customValidation={{
              required: 'promo-codes.form.applicable-offerings.error.required',
            }}
          />
          <RadioButton
            name={name}
            value={ApplicableOfferings.CUSTOM}
            label={t('promo-codes.form.applicable-offerings.custom.label')}
            customValidation={{
              required: 'promo-codes.form.applicable-offerings.error.required',
            }}
          />
          {typesOfOfferings === ApplicableOfferings.CUSTOM && <Custom />}
        </>
      }
    />
  )
}

Offerings.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Offerings;
