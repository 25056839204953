import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Icon from 'assets/icons/Icon';
import { useStateModal } from 'store/hooks/globalState/useModal';

const HintButton = ({ modalType }) => {
  const handleOpenDiscount = useStateModal(modalType);
  return (
    <StyledHint className="_hint">
      <StyledHintButton
        type='button'
        onClick={handleOpenDiscount}
      >
        <Icon.BoxInfo />
      </StyledHintButton>
    </StyledHint>
  )
}

const StyledHint = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  position: absolute;
  top: 100%;
  right: 0;
  @media only screen and (max-width: 768px) {
    top: auto;
    bottom: 0;
  }
`
const StyledHintButton = styled.button`
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: none;
  cursor: pointer;
  transition: all .3s linear;

  &:hover {
    box-shadow: 0 4px 25px rgba(0, 0, 0, 0.45);
  }

  @media only screen and (max-width: 768px) {
    padding: 2px;
  }
`

HintButton.propTypes = {
  modalType: PropTypes.string,
}

export default HintButton;
