import React from 'react'
import get from 'lodash/get'

import OfferIcons from '_legacy/components/OfferIcons'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'
import CustomMedia from '_legacy/common/CustomMedia'

const Offering = ({ isLoading, offerOccurrence }) => {
  const title = get(offerOccurrence, 'title', '')
  const type = get(offerOccurrence, 'type', '')
  const primaryMedia = get(offerOccurrence, 'primaryMedia.file')

  if (isLoading) return <Loader className="loader" />

  return (
    <div className="offering-table-item">
      <CustomMedia
        className="offering-table-item__picture"
        file={primaryMedia}
      />
      <div className="offering-table-item__info">
        <h6 className="offering-table-item__title">{title}</h6>
        <div className="offering-table-item__offer">
          <OfferIcons type={type} />
          <span className="offering-table-item__offer-title">{type}</span>
        </div>
      </div>
    </div>
  )
}

export default Offering
