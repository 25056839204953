// Modules
import React from 'react'
import debounce from 'lodash/debounce'
import compose from 'lodash/fp/compose'
import any from 'lodash/fp/any'
import cx from 'classnames'

// Assets
import { ReactComponent as NearMeIcon } from 'assets/near-me.svg'
import { ReactComponent as Pointer } from 'assets/pointer.svg'

// Routing
import * as r from '_legacy/constants/routes'

// Requests
import {
  useFetchGooglePlaceByCoordinates as useFetchLocationName,
  useFetchCountsByLocationAggregated as useFetchLocationList,
  useFetchCountsByLocationTop as useFetchLocationTop,
} from './state/requests'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useSetSearchOffersFilters, useSetSearchListFilters } from 'store/hooks/globalState/useSearchList'

// Utils
import { getLocation } from '_legacy/components/Table/Filters/Selects/components/Async/LocationSelect/config'
import {
  getCityNameAdapter,
  getCountsByLocationAdapter,
  onItemClick,
} from './utils'

// Legacy components
import Loader from '_legacy/components/Common/Loader'

// Components
import LocationSearchItem from './LocationSearchItem'


const LocationSearch = ({ className, onSubmit }) => {

  // State
  const [locationSearchValue, setLocationSearchValue] = React.useState('')
  const [locationList, setLocationList] = React.useState([])
  const [locationTop, setLocationTop] = React.useState([])
  const [sortByProximity, setSortByProximity] = React.useState(false)

  const [isLocationNameLoading, setIsLocationNameLoading] = React.useState(false)
  const [isLocationListLoading, setIsLocationListLoading] = React.useState(false)
  const [isLocationTopLoading, setIsLocationTopLoading] = React.useState(false)

  // Hooks
  const t = useGetTranslate()
  const setOfferSearchFilters = useSetSearchOffersFilters((p, n) => ({ ...p, ...n }))
  const setSearchListFilters = useSetSearchListFilters((p, n) => ({ ...p, ...n }))

  // Fetch city name by geolocation
  const onFetchLocationNameSuccess = compose(setLocationSearchValue, getCityNameAdapter)
  const fetchLocationName = useFetchLocationName(setIsLocationNameLoading, onFetchLocationNameSuccess)

  // Fetch talents and offers counters for each location
  const onFetchLocationTopSuccess = compose(setLocationTop, getCountsByLocationAdapter)
  const fetchLocationTop = useFetchLocationTop(setIsLocationTopLoading, onFetchLocationTopSuccess)

  // Fetch talents and offers counters for each location
  const onFetchLocationListSuccess = compose(setLocationList, getCountsByLocationAdapter)
  const fetchLocationList = useFetchLocationList(setIsLocationListLoading, onFetchLocationListSuccess)

  const getLocationList = React.useCallback(debounce((input, sortByProximity) => fetchLocationList({ input, sortByProximity }), 1000), [])

  // Effects
  React.useEffect(() => {
    locationSearchValue && getLocationList(locationSearchValue, sortByProximity)
  }, [locationSearchValue, sortByProximity])

  React.useEffect(() => {
    fetchLocationTop({ limit: 5 })
  }, [])

  // Event listeners
  const onSetUpUserLocationClick = () => {
    const onGetLocationSuccess = data => {
      fetchLocationName({
        latitude: data?.coords?.latitude,
        longitude: data?.coords?.longitude,
      })
    }
    setSortByProximity(true)
    getLocation(onGetLocationSuccess)
  }

  const onLocationInputChange = e => {
    setSortByProximity(false)
    setLocationSearchValue(e.target.value)
  }

  const onOfferingItemClick = onItemClick({
    redirectUrl: `/${r.SEARCH}/${r.OFFERINGS}`,
    setter: setOfferSearchFilters,
    callback: onSubmit,
  })

  const onTalentItemClick = onItemClick({
    redirectUrl: `/${r.SEARCH}/${r.TALENT}`,
    setter: setSearchListFilters,
    callback: onSubmit,
  })

  // Loaders
  const showLoader = any(Boolean, [isLocationNameLoading, isLocationListLoading, isLocationTopLoading])
  const showTopLocations = !showLoader && !locationSearchValue
  const showSearchResults = !showLoader && locationSearchValue

  return (
    <div className={cx('location-search', className)}>
      <div className="location-search__input-wrapper default mb-16">
        <Pointer className="location-search__input-wrapper__icon" />
        <input
          type="text"
          name="location"
          placeholder={t('search.input.placeholder')}
          className="input input-default location-search__input-wrapper__input"
          value={locationSearchValue}
          onChange={onLocationInputChange}
        />
      </div>

      {showLoader &&
        <div className="location-search__loader-wrapper">
          <Loader size={20} fill="#636583" />
        </div>
      }

      {showTopLocations &&
        <>
          <h4 className="location-search__header mb-16">
            {t('search.filters.location.group.near-me')}
          </h4>

          <span
            className="location-search__set-location mb-16"
            onClick={onSetUpUserLocationClick}
          >
            <NearMeIcon className="location-search__set-location__icon" />
            <span>
              {t('search.filters.location.set-current-location')}
            </span>
          </span>
          <h4 className="location-search__header">
            {t('search.filters.location.group.top')}
          </h4>
          <ul className="location-search__list">
            {(locationTop || [])
              .filter(Boolean)
              .map((item, i) =>
                <LocationSearchItem
                  key={i}
                  onTalentItemClick={onTalentItemClick}
                  onOfferingItemClick={onOfferingItemClick}
                  item={item}
                />
              )
            }
          </ul>
        </>
      }

      {showSearchResults &&
        <ul className="location-search__list">
          {(locationList || [])
            .filter(Boolean)
            .map((item, i) =>
              <LocationSearchItem
                key={i}
                onTalentItemClick={onTalentItemClick}
                onOfferingItemClick={onOfferingItemClick}
                item={item}
              />
            )
          }
        </ul>
      }
    </div>
  )
}

export default LocationSearch
