import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Slider from '../../../Slider';
import Slide from '../../../Slide';

import Actions from './Actions';
import Empty from './Empty';

const LiveVideoChats = ({ firstName, covers = [], plaqueNickname, offerSequentialId }) => {
  const t = useGetTranslate();
  const slides = covers.map((cover, i) => (
    <Slide.DesktopSlide
      key={`video-chats-${i}`}
      file={cover}
      offerSequentialId={offerSequentialId}
      isPromo={cover.isPromo}
    />
  ));

  return (
    <Slider
      size="big" // change to 'small' if you want small slider
      title={t('talent-public-view.sliders.live-video-chats.header.label')}
      withGradientOverhead={true}
      slides={slides}
      empty={<Empty firstName={firstName} />}
      Actions={Actions}
      bottomSpace={40}
      plaqueNickname={plaqueNickname}
    />
  );
};

LiveVideoChats.propTypes = {
  firstName: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  offerSequentialId: PropTypes.number.isRequired,
};

export default LiveVideoChats;
