import React from 'react'
import styled from 'styled-components'
import compose from 'lodash/fp/compose'
import { useForm } from 'react-hook-form'
import { types } from 'shared'
const { PHOTO, VIDEO } = types.file.Types

import {
  useReadyMadeEvents,
  useSetReadyMadeEvents
} from 'store/hooks/globalState/useReadyMadeEvents'
import { useTalentLibraryId } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import {
  useUpdateReadyMadeEvent,
  useUpdateReadyMadeEventDraft,
  useFetchReadyMadeEventDraft
} from 'requests/ready-made-video-events'
import { useFetchFileLibrary } from 'requests/file-library'

import { Container, Title } from '_legacy/common'
import PageLoader from '_legacy/common/Template/PageLoader'
import ReadyMadeEventForm from '_legacy/components/FormReadyMadeEvent/ReadyMadeEventForm'

import getAdapter from '_legacy/components/FormReadyMadeEvent/getAdapter'
import submitAdapter from '_legacy/components/FormReadyMadeEvent/submitAdapter'
import defaultValues from '_legacy/components/FormReadyMadeEvent/defaultValues'
import { useRequiredTaxInfoModalHandler } from 'store/hooks/globalState/useUser';

const EditReadyMadeEvents= () => {
  const t = useGetTranslate()
  const fileLibraryId = useTalentLibraryId()
  const readyMadeEvents = useReadyMadeEvents()
  const fetchFileLibrary = useFetchFileLibrary()
  const taxInfoModalHandler = useRequiredTaxInfoModalHandler()
  const options = { openExtraModal: taxInfoModalHandler }
  const updateReadyMadeEvent = useUpdateReadyMadeEvent(options)
  const clearReadyMadeEvent = useSetReadyMadeEvents(() => null)
  const fetchReadyMadeEventDraft = useFetchReadyMadeEventDraft()
  const updateReadyMadeEventDraft = useUpdateReadyMadeEventDraft()

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    shouldUnregister: false,
  })

  // fetch draft info
  React.useEffect(() => {
    fetchReadyMadeEventDraft()
    return clearReadyMadeEvent;
  }, [])

  // fetch file library
  React.useEffect(() => {
    fetchFileLibrary({ fileLibraryId, types: [PHOTO, VIDEO] })
  }, [fileLibraryId])

  // update form
  React.useEffect(() => {
    if (readyMadeEvents) methods.reset(getAdapter(readyMadeEvents))
  }, [readyMadeEvents])

  if (!readyMadeEvents) {
    return <PageLoader />
  }

  const onCreate = compose(updateReadyMadeEvent, submitAdapter)
  const onDraft = compose(updateReadyMadeEventDraft, submitAdapter, methods.getValues)

  return (
    <StyledWrapper>
      <Container>
        <Title>{t('offerings.ready-made.title')}</Title>
        <h3 className="subtitle">{t('offerings.ready-made.subtitle')}</h3>
        <ReadyMadeEventForm
          methods={methods}
          onDraft={onDraft}
          onCreate={onCreate}
        />
      </Container>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-top: 30px;
  .subtitle {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 22px;
    line-height: 1.5;
    text-align: center;
    color: ${({ theme: { colors } }) => colors.grey};
    margin: 40px 0;
    @media only screen and (max-width: 575px) {
      font-size: 18px;
      margin: 20px 0;
    }
  }
`

export default EditReadyMadeEvents
