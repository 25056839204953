// Modules
import React from 'react'
import { NavLink, useParams } from 'react-router-dom'
import inRange from 'lodash/inRange'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { ChatMessageType, ChatType } from 'shared'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useChatsList } from 'store/hooks/globalState/useChats'

// Utils
import { getAmPmTime } from 'utils/date'
import { parseDate } from 'utils/services/timezone'
import {
  getSaveFullName,
  getTalentId,
  getUserId,
  getUserTimeZone,
} from 'utils/user'
import {
  formatSystemMessage,
  getChatViewDate,
  getEventViewInfo,
} from '_legacy/components/Chats/helpers'

// Legacy components
import Avatar from '_legacy/components/Avatar'

// Constants
import { SECURE_CHAT } from '_legacy/constants/routes'


const ChatListItem = ({
  chat: {
    chatInstance,
    chatInfo,
    hasNewMessages,
    newMessageSid,
    participantWasUpdated,
  }
}) => {
  const t = useGetTranslate()
  const { chatId } = useParams()

  const talentId = useUser(getTalentId)
  const userId = useUser(getUserId)

  const timeZone = useUser(getUserTimeZone)

  const currentUserId = talentId || userId

  const chatsList = useChatsList()

  const selectedChat = (chatId && chatsList[chatId]) || null

  const [lastMessage, setLastMessage] = React.useState()
  const [lastMessageAuthor, setLastMessageAuthor] = React.useState()
  const [unreadMessagesCount, setUnreadMessagesCount] = React.useState()

  const getAdditionalChatInfo = async () => {
    const latestMessage = (await chatInstance.getMessages(1)).items[0]

    if (latestMessage) {
      const latestMessageAuthor = chatInfo.participants.find(p => p.id === latestMessage.author)
      setLastMessageAuthor(latestMessageAuthor)
    }

    const totalMessagesCount = await chatInstance.getMessagesCount()
    const lastReadMessageIndex = chatInstance.lastReadMessageIndex ?? -1

    let chatUnreadMessagesCount = totalMessagesCount - (lastReadMessageIndex + 1)

    const latestMessageIsFromCurrentUser =
      latestMessage && latestMessage.author === currentUserId && latestMessage.index === lastReadMessageIndex + 1

    if (latestMessage?.index === lastReadMessageIndex + 1 || latestMessageIsFromCurrentUser) {
      chatUnreadMessagesCount = 0
    }
    setLastMessage(latestMessage)
    setUnreadMessagesCount(chatUnreadMessagesCount)
  }

  const processNewMessages = async () => {
    if (
      participantWasUpdated ||
      (hasNewMessages && !lastMessage) ||
      (hasNewMessages && lastMessage && newMessageSid !== lastMessage.sid)
    ) {
      await getAdditionalChatInfo()
    }
  }

  React.useEffect(getAdditionalChatInfo, [chatInstance])

  React.useEffect(processNewMessages, [
    lastMessage,
    chatInstance,
    newMessageSid,
    hasNewMessages,
    participantWasUpdated,
  ])

  const chatLink = `/${SECURE_CHAT}/${chatInfo.id}`

  const lastMessageBody = lastMessage?.body

  const chatViewDate = chatInstance && getChatViewDate(lastMessage, chatInstance)
  const formattedChatViewDate = getAmPmTime(parseDate(chatViewDate, timeZone))

  const lastMessageIsFromCurrentUser = lastMessageAuthor?.id === currentUserId

  const lastMessageAuthorLabel = lastMessageIsFromCurrentUser
    ? t('chats.chat.message.current-user-author-label')
    : getSaveFullName(lastMessageAuthor)

  const isEventChat = chatInfo?.type === ChatType.EVENT_GROUP

  const eventIcon = isEventChat && getEventViewInfo(chatInfo.eventType).icon({ width: '16px' })

  const systemMessage = lastMessage?.attributes?.messageType === ChatMessageType.SYSTEM

  const formattedMessage =
    lastMessage &&
    systemMessage &&
    formatSystemMessage({
      chatInfo,
      currentUserId,
      message: lastMessage,
      currentUserMessage: lastMessageIsFromCurrentUser,
      messageAuthorDetails: lastMessageAuthor,
    })

  const isChatActive = selectedChat?.chatInstance?.sid === chatInstance?.sid

  const wrapperClassName = cx('chat__wrapper', { active: isChatActive })

  return (
    <NavLink className={wrapperClassName} exact to={chatLink}>
      <Avatar file={chatInfo?.image?.file} className="chat__icon" />

      <div className="chat__info">
        <div className="chat__info-header">
          <div className="chat__info-header-chat-title-wrapper">
            {isEventChat && eventIcon}
            <p className="chat__info-header-chat-title">{chatInfo?.title}</p>
          </div>

          <div className="chat__info-header-last-message-date">{formattedChatViewDate}</div>
        </div>

        <div className="chat__info-message-wrapper">
          <p className="chat__info-last-message">
            {lastMessage && (
              <>
                {!systemMessage && (
                  <span className="chat__info-last-message-sender">
                    {lastMessageAuthorLabel}
                    :&nbsp;
                  </span>
                )}
                {systemMessage ? formattedMessage.message : lastMessageBody}
              </>
            )}

            {!lastMessage && t('chats.chat.chat-is-empty')}
          </p>

          {unreadMessagesCount > 0 && (
            <div className="unread-messages-counter">
              <span>{inRange(unreadMessagesCount, 99) ? unreadMessagesCount : '99+'}</span>
            </div>
          )}
        </div>
      </div>
    </NavLink>
  )
}

ChatListItem.propTypes = {
  chat: PropTypes.objectOf(
    PropTypes.shape({
      chatInfo: PropTypes.object.isRequired,
      chatInstance: PropTypes.object.isRequired,
      participantWasUpdated: PropTypes.bool,
      newMessageSid: PropTypes.string,
      hasNewMessages: PropTypes.bool,
    })
  ).isRequired,
}
ChatListItem.defaultProps = {
  chat: {
    participantWasUpdated: false,
    hasNewMessages: false,
    newMessageSid: null,
  },
}

export default ChatListItem
