import { _get } from 'api/utils'

export const getOffers = ({ query }) => _get(
  '/searches/results/offers/search',
  { needAuthorization: true, query }
)

export const getOffersTopLocation = ({ query }) => _get(
  '/searches/results/offers/locations/top',
  { query }
)
