import React from 'react';
import styled from 'styled-components';

export default React.memo(function CustomCircle ({ className = '' }) {
  return (
    <StyledWrapper className={className} />
  );
});

const StyledWrapper = styled.div`
  && {
    width: 16px;
    height: 16px;
    background: ${({ theme: { colors } }) => colors.blue};
    border-radius: 50%;
    &.accepted {
      background: ${({ theme: { colors } }) => colors.accepted};
    }
    &.pending {
      background: ${({ theme: { colors } }) => colors.lightGrey};
    }
  }
`;
