import React from 'react'
import styled from 'styled-components'
import bg from 'assets/new-landing/section-2-bg.jpg'

import Title from './Title'
import Carousel from './Carousel'

function EverythingYouNeedSection() {
  return (
    <StyledSection className="section">
      <div className="content wrapper content-wrapper styled-wrapper">
        <Title />
        <Carousel />
      </div>
    </StyledSection>
  )
}

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
  background: #292835 url(${bg}) center / cover no-repeat;

  @media only screen and (max-width: 768px) {
      justify-content: center;
  }

  .styled-wrapper {
    padding-top: 155px;
    margin: 0 auto auto;
    
    @media only screen and (max-width: 1440px) {
      padding: 155px 40px 0;
    }
    
    @media only screen and (max-width: 1024px) {
      padding: 100px 24px 0;
    }
    @media only screen and (max-width: 768px) {
      margin: 0;
      padding: 16px 0;
    
    @media only screen and (max-width: 480px) {
      padding: 100px 16px 0;
    }
  }
`

export default EverythingYouNeedSection
