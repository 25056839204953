import { _post, _get, } from 'api/utils'

export const removeProfileManagers = ({ talentId, profileManagerIds }) => _post(
  `/talents/${talentId}/profile-managers/bulk/remove`,
  { needAuthorization: true, body: { profileManagerIds } }
)

export const getProfileManagers = ({ talentId, query }) => _get(
  `/talents/${talentId}/profile-managers`,
  { needAuthorization: true, query }
)

export const resendInvitationProfileManager = ({ talentId, profileManagerIds }) => _post(
  `/talents/${talentId}/profile-managers/bulk/resend-invitation`,
  { needAuthorization: true, body: { profileManagerIds } }
)

export const cancelInviteProfileManager = ({ talentId, profileManagerIds }) => _post(
  `/talents/${talentId}/profile-managers/bulk/cancel-invitation`,
  { needAuthorization: true, body: { profileManagerIds } }
)

export const disconnectProfileManager = ({ talentId, profileManagerIds }) => _post(
  `/talents/${talentId}/profile-managers/bulk/disconnect`,
  { needAuthorization: true, body: { profileManagerIds } }
)
