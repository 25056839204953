import React from 'react'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import PrevButton from './PrevButton'
import PagesList from './PagesList'
import NextButton from './NextButton'

export default function Pagination ({
  className = '', paginationConfig, getFiltersFn
}) {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = +query.get('page') || 1
  const limit = getFiltersFn(current => get(current, get(paginationConfig, 'limitField', 'limit'), 1))
  const count = getFiltersFn(current => get(current, get(paginationConfig, 'countField', 'count'), 1))
  const pagesCount = React.useMemo(() => Math.ceil(count / limit), [count, limit])

  if (!pagesCount || pagesCount <= 1) return null

  return (
    <StyledWrapper className={className}>
      <PrevButton
        page={page}
      />

      <PagesList
        pagesCount={pagesCount}
        page={page}
      />

      <NextButton
        pagesCount={pagesCount}
        page={page}
      />
    </StyledWrapper>
  )
}

Pagination.propTypes = {
  className: PropTypes.string,
  paginationConfig: PropTypes.object,
  getFiltersFn: PropTypes.func.isRequired
}

const StyledWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 32px 0;
  .button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin: 0 4px;
    font-size: 16px;
    font-weight: 700;
    color: ${({ theme: { colors } }) => colors.lightGrey};
    @media screen and (max-width: 480px) {
      width: 35px;
      height: 35px;
      margin: 0 2px;
    }
    :hover:not(.disabled) {
      color: ${({ theme: { colors } }) => colors.blue};
      cursor: pointer;
      .arrow {
        border-color: ${({ theme: { colors } }) => colors.blue};
        cursor: pointer;
      }
    }
  }
  .active-page {
    width: 48px;
    height: 48px;
    margin: 0 4px;
    @media screen and (max-width: 480px) {
      width: 35px;
      height: 35px;
      margin: 0 2px;
    }
  }
  .arrow {
    width: 10px;
    height: 10px;
    border-top: solid 2px ${({ theme: { colors } }) => colors.lightGrey};
    border-right: solid 2px ${({ theme: { colors } }) => colors.lightGrey};
    transition: all 0.2s;
  }
  .arrow.prev {
    transform: rotate(-135deg);
  }
  .arrow.next {
    transform: rotate(45deg);
  }
`
