import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

// utils
import { emailPattern } from 'utils/services/validators/emailValidator';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

// components
import Input from '../Input';

const BackupEmail = ({ name = 'backupEmail' }) => {
  const t = useGetTranslate();
  const { control } = useFormContext();
  const email = useWatch({ control, name: 'email', defaultValue: null });

  return (
    <Input
      label={t('register-user.form.backup-email.label')}
      name={name}
      type='email'
      placeholder={t('register-user.form.backup-email.placeholder')}
      validation={{
        pattern: {
          value: emailPattern,
          message: 'form.email.errors.invalid'
        },
        validate: backupEmail => backupEmail !== email || 'form.backup-email.errors.cannot-be-equal-with-email'
      }}
    />
  );
};

export default BackupEmail;
