import React from 'react'
import { types } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import LineString from '../common/LineString'

const { ApplicableOfferings } = types

export default React.memo(function Offerings ({ isLoading, applicableOfferingsType }) {
  const t = useGetTranslate()

  // TODO we need add from BE custom offers count
  const offeringsType =
    applicableOfferingsType === ApplicableOfferings.ALL
      ? t('promo-codes.offerings.all')
      : `${t('promo-codes.offerings.custom')}`
  return <LineString isLoading={isLoading} str={offeringsType} />
})
