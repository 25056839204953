// Modules
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import size from 'lodash/fp/size'
import always from 'lodash/fp/always'

// Assets
import { ReactComponent as DownloadIcon } from 'assets/download-icon.svg'

// Routing
import * as r from '_legacy/constants/routes'

// Store (Hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { bulkFileDownload } from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/state/requests'
import { useFetchReadyMadeVideoBookingsDetails as fetchHeading } from './state/request'
import { useReadyMadeVideosBookingDetails, useSetReadyMadeVideosBookingDetails } from './state/store'

// Utils
import { toMoneyWithComa } from 'utils/moneyTransformator'

// Components
import ProductTable from 'components/OfferingDetails/components/ProductTable'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'

// Legacy components
import Button from '_legacy/components/Button'
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'

// Constants
import { DELIVERY_TYPES } from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/constants'
import { getSaveFullName, getTalentLink } from 'utils/user'

const ReadyMadeVideoBookingsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()

  // state
  const fetchBookingsDetails = fetchHeading()
  const order = useReadyMadeVideosBookingDetails()
  const clearOrder = useSetReadyMadeVideosBookingDetails(() => null)
  const { review, occurrence, number } = order || {}

  // handlers
  const handleDownload = () => {
    const files = occurrence?.uploads ?? []
    const filesIds = files.map(upload => upload.id)
    bulkFileDownload(files)(filesIds)
  }

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
  }, [])

  React.useEffect(() => () => {
    clearOrder()
  }, [])

  if (!order) return <PageLoader />

  const { title, price, deliveryType, talent, offer, uploads, receptionInstructions } = occurrence

  // TODO: add Completed right column
  return (
    <div className="ready-made-video-booking od">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.ready-made-lesson')}
        title={`${t('booking-details.main.title')} #${number}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__inner-wrapper">
            <h4 className="od--event-name mb-24">{title}</h4>

            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.ready-made-video.delivery')}</h6>
                <p className="od--main-text od--accented">{t(DELIVERY_TYPES[deliveryType])}</p>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.ready-made-video.product-price')}</h6>
                <p className="od--main-text od--accented">{`$${toMoneyWithComa(price)} (USD)`}</p>
              </div>
            </div>

            {receptionInstructions && (
              <div className="od__row od__column-m">
                <div className="od__column">
                  <h6 className="od--uppercase-title mb-16">{t('order.video-message.script')}</h6>
                  <p className="od--main-text">{receptionInstructions}</p>
                </div>
              </div>
            )}

            <Link
              className="od__row od__public-link"
              to={`/${r.READY_MADE_EVENT_LESSON}/${talent.plaqueNickname}/${offer.sequentialId}`}
            >
              <div className="od--main-text od--accented od__public-link--text">
                {t('order-details.info.link-text')}
              </div>
              <div className="od__public-link--arrow" />
            </Link>
          </div>
        </div>

        <div>
          <div className="od--shadow-box od--centered">
            <div className="od--with-padding od__column">
              <h3 className="od--uppercase-title mb-12">{t('order.header.your-product')}</h3>
              <Button
                stretch
                typeButton="button"
                paddingVertical="16"
                handleOnClick={handleDownload}
                iconComponent={<DownloadIcon fill="white" />}
                text={`${t('shared.words.download-all')} (${size(uploads)})`}
              />
            </div>
            <OfferingDetailsReviewStars
              orderId={orderId}
              review={review}
              onSuccess={fetchBookingsDetails}
              userLink={getTalentLink(talent)}
              userFullName={getSaveFullName(talent)}
              offerType={t('shared.offers.ready-made-lesson')}
              reviewType={'order.answer.rate.by'}
            />
          </div>
        </div>
      </div>

      <div className="wrapper content-wrapper mt-100">
        <ProductTable getTableFn={always(uploads || [])} />
      </div>
    </div>
  )
}

export default ReadyMadeVideoBookingsDetails
