import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import PhoneConfirmButton from './PhoneConfirmButton';
import PhoneWithAsyncValidation from '_legacy/common/Input/PhoneWithAsyncValidation';
import PhoneResendCodeCountdown from './PhoneResendCodeCountdown';
import VerificationAttemptsCoundown from './VerificationAttemptsCoundown';

const PhoneConfirm = ({ fields, isEditable }) => {
  const { watch } = useFormContext();
  const value = watch([fields.isCodeConfirm, fields.isPhoneSand, fields.canRetry, fields.remainingAttempts]);
  const isPhoneSand = get(value, fields.isPhoneSand);
  const isCodeConfirm = get(value, fields.isCodeConfirm);
  const remainingAttempts = get(value, fields.remainingAttempts);
  const canRetry = get(value, fields.canRetry);

  const disabledField = isPhoneSand && (!remainingAttempts || !canRetry)

  return (
    <PhoneConfirmWrapper>
      <PhoneWithAsyncValidation
        fields={fields}
        disabled={disabledField}
        isEditable={isEditable}
      />
      <PhoneConfirmButton fields={fields} />
      {isPhoneSand && !isCodeConfirm && <VerificationAttemptsCoundown fields={fields} /> }
      {isPhoneSand && !isCodeConfirm && <PhoneResendCodeCountdown fields={fields} />}
    </PhoneConfirmWrapper>
  );
};

PhoneConfirm.propTypes = {
  isEditable: PropTypes.bool,
  fields: PropTypes.object,
};

const PhoneConfirmWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
`;

export default PhoneConfirm;
