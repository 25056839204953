import React from 'react'
import { useParams } from 'react-router'

import {
  fetchBookingsDetails as fetchBookingsDetailsApi,
  fetchBookingsDetailsParticipants,
} from 'components/OfferingDetails/BookingsDetails/state/api'

import {
  useSetExperienceBookingDetails,
  useSetExperienceBookingDetailsParticipantsFilters,
  useSetExperienceBookingDetailsParticipants
} from './store'
import { useUser } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getUserId } from 'utils/user'

export function useFetchExperienceBookingDetails() {
  const { orderId } = useParams()
  const userId = useUser(getUserId)

  const setLiveVideoChatBookingsDetails = useSetExperienceBookingDetails((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchBookingsDetailsApi({ userId, orderId })
      .then(setLiveVideoChatBookingsDetails)
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [userId, orderId, setLiveVideoChatBookingsDetails])
}

export function useFetchExperienceBookingDetailsParticipants() {
  const setExperienceBookingDetailsParticipantsFilters = useSetExperienceBookingDetailsParticipantsFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setExperienceBookingDetailsParticipants = useSetExperienceBookingDetailsParticipants(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  const userId = useUser(getUserId)

  const { orderId } = useParams()
  return React.useCallback(() => {
    setExperienceBookingDetailsParticipants(null)

    fetchBookingsDetailsParticipants({ userId, orderId })
      .then(({ orders, pageInfo }) => {
        setExperienceBookingDetailsParticipantsFilters(pageInfo)
        setExperienceBookingDetailsParticipants(orders)
      })
      .catch((error) => {
        setExperienceBookingDetailsParticipants([])
        handleResponseError(error)
        console.warn(error)
      })
  }, [orderId, setExperienceBookingDetailsParticipantsFilters, setExperienceBookingDetailsParticipants])
}
