import React from 'react'
import { compact, concat, isFunction, isNil, isNull } from 'lodash'
import { addWeeks, isWithinInterval, startOfWeek } from 'date-fns'

import { fetchTalentAvailableCalendar } from '../requests/talent-calendar'
import { fetchTalentPersonalCalendarBusinessHours } from '../requests/business-hourse'

import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import BaseCalendar from '../BaseCalendar'
import MenuDots from '../../Calendar/components/MenuDots'
import ModalPortal from 'portals/ModalPortal'
import LoaderIndicator from '_legacy/components/Common/Loader'
import CalendarModalHeader from '../components/CalendarModalHeader'
import HowToUseCalendarModal from '../components/HowToUseCalendarModal'

import { getLocale } from 'utils/date'
import { getUserTimeZone } from 'utils/user'
import { toBookEvent } from '../utils/adapters'
import { initialView } from '../../Calendar/BaseCalendar/configs/calendar-config'
import { calendarFilters } from '../configs/filters'
import { getEventTimeFormats } from '../configs/hour-formats'
import { bookingCalendarConfig, calendarScrollTime } from '../configs/calendar'
import { getEventPosition } from '../utils/getEventPosition/getEventPosition'
import { buildEventInfoLabel } from '../utils/buildEventInfoLabel/buildEventInfoLabel'
import WorkingHours from '../components/WorkingHours'

const BookingCalendar = ({
  orderSlot = {},
  setOrderSlot,
  isOpen,
  onClose,
  onConfirm,
  talentId,
  eventTitle,
  eventDuration,
  travelTime,
  minBookingTimeBeforeExperience = 0,
  openOnDate,
  beforeTransform,
  afterTransform,
  timeZone,
}) => {
  const t = useGetTranslate()
  const userLang = useUserLang()
  const userTimeZone = useUser(getUserTimeZone)
  const timeZoneId = timeZone || userTimeZone
  const locale = getLocale(userLang)

  const calendarRef = React.useRef(null)
  const [isHowToUseModalOpen, openHowToUseModal, closeHowToUseModal] = useModal(false)

  // ************ calendar state ************
  const [localOrderSlot, setLocalOrder] = React.useState(orderSlot)
  const [events, setEvents] = React.useState({
    lockEvents: [],
    backgroundEvents: [],
  })
  const [businessHours, setBusinessHours] = React.useState(null)
  const [filters, setFilters] = React.useState(calendarFilters)
  const prevFilters = React.useRef(calendarFilters)

  // ************ dot menu ************
  const [hour12Format, , , toggleHourFormat] = useModal(true)
  const [showWeekends, , , toggleShowWeekends] = useModal(true)

  const openListOptions = React.useMemo(
    () => [
      { label: 'calendar.dot-button.hour-format', cb: toggleHourFormat },
      { label: 'calendar.dot-button.show-hide-weekends', cb: toggleShowWeekends },
    ],
    []
  )

  // ************ fetch fns ************
  const fetchCalendarSlots = React.useCallback(async () => {
    const { slotsWithBackground, slotsWithLock } = await fetchTalentAvailableCalendar(
      {
        talentId,
        filters,
        event: { businessHours, duration: eventDuration, minBookingTimeBeforeExperience, travelTime },
      },
      { beforeTransform, afterTransform }
    )

    setEvents({
      lockEvents: slotsWithLock,
      backgroundEvents: slotsWithBackground,
    })
  }, [talentId, filters, businessHours, beforeTransform, afterTransform])

  const fetchBusinessHours = React.useCallback(async () => {
    const businessHours = await fetchTalentPersonalCalendarBusinessHours({ talentId })

    setBusinessHours(businessHours)
  }, [talentId])

  // ************ calendar navigation handlers ************
  const onNextButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.next()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onPrevButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.prev()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onTodayButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.today()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onTimeGridDay = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    const interval = { start: calendar.view.activeStart, end: calendar.view.activeEnd }

    if (localOrderSlot?.start && isWithinInterval(localOrderSlot.start, interval)) {
      calendar.changeView('timeGridDay', localOrderSlot.start)
      return
    }
    calendar.changeView('timeGridDay')
  }, [localOrderSlot])

  const onChange = React.useCallback(info => {
    const { start, end } = info.event
    setLocalOrder(prev => ({ ...prev, start, end }))
  }, [])

  const onClick = React.useCallback(
    event => {
      const slot = getEventPosition(event.date, eventDuration, events.backgroundEvents)

      if (slot) {
        setLocalOrder(prev => ({ ...prev, start: slot.start, end: slot.end }))
      }
    },
    [events, eventDuration]
  )

  // ************ calendar controls ************
  const onConfirmHandler = React.useCallback(() => {
    prevFilters.current = filters
    setOrderSlot(localOrderSlot)

    if (isFunction(onConfirm)) {
      onConfirm(localOrderSlot)
    }

    onClose()
  }, [localOrderSlot, onConfirm])

  const onCancelHandler = React.useCallback(() => {
    setLocalOrder(toBookEvent({ ...orderSlot, title: eventTitle }))
    setFilters(prevFilters.current)

    onClose()
  }, [orderSlot, eventTitle])

  // ************ effects ************
  // fetch slots
  React.useEffect(() => {
    if (!isNull(businessHours)) {
      fetchCalendarSlots()
    }
  }, [businessHours, fetchCalendarSlots, filters, talentId, beforeTransform, afterTransform])

  // fetch business hours
  React.useEffect(fetchBusinessHours, [fetchBusinessHours, talentId])

  // go to selected slot
  React.useEffect(() => {
    if (openOnDate && isOpen) {
      const startTime = startOfWeek(new Date(openOnDate))
      const endTime = addWeeks(startTime, 1)
      setFilters(prev => ({ ...prev, startTime, endTime }))

      const calendar = calendarRef.current.getApi()
      calendar.gotoDate(openOnDate)
    }
  }, [openOnDate, isOpen])

  // update local order
  React.useEffect(() => {
    setLocalOrder(toBookEvent({ ...orderSlot, title: eventTitle }))
  }, [orderSlot])

  const allEvents = compact(concat(localOrderSlot, events?.lockEvents, events?.backgroundEvents))

  return (
    <>
      <ModalPortal isOpen={isOpen} onClose={onCancelHandler} showCloseButton isMobileFullScreen>
        <div className="aficionado-calendar">
          <CalendarModalHeader onInfoBtnClick={openHowToUseModal}>
            {t('aficionado-calendar.header')}
          </CalendarModalHeader>
          <WorkingHours
            businessHours={businessHours}
            timezone={timeZoneId}
            userLang={userLang}
            hour12Format={hour12Format}
          />
          <div className="aficionado-calendar__main">
            <div className="calendar calendar--pr32">
              <MenuDots optionsList={openListOptions} />
              <BaseCalendar
                initialDate={localOrderSlot?.start || new Date()}
                slotEventOverlap={false}
                eventDurationEditable={false}
                selectAllow={() => false}
                events={allEvents}
                weekends={showWeekends}
                customButtons={{
                  prev: { click: onPrevButton },
                  next: { click: onNextButton },
                  today: { click: onTodayButton, text: t('calendar.talent.today-btn') },
                  timeGridDay: { click: onTimeGridDay, text: t('calendar.talent.day-btn') },
                }}
                height="100%"
                ref={calendarRef}
                eventChange={onChange}
                dateClick={onClick}
                scrollTime={calendarScrollTime}
                {...getEventTimeFormats(hour12Format, locale)}
                {...bookingCalendarConfig}
                initialView={initialView.week}
              />
            </div>
          </div>
          <div className="aficionado-calendar__footer">
            <div className="aficionado-calendar__event-info">
              {isNil(events) && <LoaderIndicator size={24} fill="#636583" />}

              {Boolean(localOrderSlot?.start) && (
                <React.Fragment>
                  <Icon.ViewCalendar className="aficionado-calendar__event-info-icon" />
                  {buildEventInfoLabel(t, localOrderSlot, userLang, hour12Format, timeZoneId)}
                </React.Fragment>
              )}
            </div>
            <div className="aficionado-calendar__controls">
              <Button
                paddingVertical={8}
                primary
                text={t('aficionado-calendar.buttons.cancel')}
                handleOnClick={onCancelHandler}
              />
              <Button
                paddingVertical={8}
                disabled={isNil(localOrderSlot?.start)}
                text={t('aficionado-calendar.buttons.confirm')}
                handleOnClick={onConfirmHandler}
              />
            </div>
          </div>
        </div>
      </ModalPortal>
      <HowToUseCalendarModal isOpen={isHowToUseModalOpen} onClose={closeHowToUseModal} />
    </>
  )
}

export default React.memo(BookingCalendar)
