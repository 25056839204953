export const socialOptions = [
  {
    label: 'Facebook',
    value: 'facebook',
  },
  {
    label: 'Twitter',
    value: 'twitter',
  },
  {
    label: 'Instagram',
    value: 'instagram',
  },
]

export const arr = [
  {
    header: 'why-join.header.expand-reach',
    text: 'why-join.expand-reach',
  },
  {
    header: 'why-join.header.convenient',
    text: 'why-join.convenient',
  },
  {
    header: 'why-join.header.brand-monetize',
    text: 'why-join.brand-monetize',
  },
  {
    header: 'why-join.header.fun',
    text: 'why-join.fun',
  },
  {
    header: 'why-join.header.platform-for-giving',
    text: 'why-join.platform-for-giving',
  },
  {
    header: 'why-join.header.feel-secure',
    text: 'why-join.feel-secure',
  },
  {
    header: 'why-join.header.feel-safe',
    text: 'why-join.feel-safe',
  },
  {
    header: 'why-join.header.enable-agent',
    text: 'why-join.enable-agent',
  },
  {
    header: 'why-join.header.enhance-image',
    text: 'why-join.enhance-image',
  },
  {
    header: 'why-join.header.free-to-join',
    text: 'why-join.free-to-join',
  },
  {
    header: 'why-join.header.easy-use',
    text: 'why-join.easy-use',
  },
  {
    header: 'why-join.header.easy-get-started',
    text: 'why-join.easy-get-started',
  },
]

export const defaultValues = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  socialChannelHandle: [
    { social: socialOptions[0], username: '', followersCount: '' },
  ],
  preferredSocialChannel: {
    social: socialOptions[0],
    username: '',
    followersCount: '',
  },
}

export const submitAdapter = ({ preferredSocialChannel, email, ...values }) => {
  if (preferredSocialChannel.username) {
    values.preferredSocialChannel = {
      ...preferredSocialChannel,
      followersCount: preferredSocialChannel.followersCount
        ? +preferredSocialChannel.followersCount
        : null,
    }
  }

  if (email.length) {
    values.email = email
  }

  const socialChannelHandle = values.socialChannelHandle
    .filter(channel => !!channel.username)
    .map(channel => ({
      username: channel.username,
      social: channel.social.value,
      followersCount: channel.followersCount ? +channel.followersCount : null,
    }))

  return { ...values, socialChannelHandle }
}
