import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select'
import get from 'lodash/get'
import { Controller, useFormContext } from 'react-hook-form';
import cx from 'classnames';
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { ErrorMessage } from '../ErrorMessage';
import { StyledOption } from '_legacy/components/CreateExperience/components/TimeSelectWithTimeZone/common'

const StyledMenuList = styled(components.MenuList)`
  &::-webkit-scrollbar {
    width: 3px;
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
  }
`

const Select = ({
  name,
  options = [],
  rules,
  defaultValue,
  placeholder,
  disabled,
  blackTheme,
  className,
  isRequired,
  isSearchable,
}) => {
  const t = useGetTranslate()
  const { control, errors } = useFormContext();
  const error = get(errors, name)
  const classes = cx('react-select-container', className, {
    'react-select-container--black-theme': blackTheme,
    'react-select-container--danger': error,
  });
  const optionsWithTranslate = options.map((option) => ({ ...option, label: t(option.label) }))
  const defaultValueWithTranslate = defaultValue ? { ...(defaultValue || {}), label: t(defaultValue?.label) } : undefined

  return (
    <StyledWrapper className={classes}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValueWithTranslate}
        rules={{
          required: isRequired && 'shared.words.required',
          ...rules
        }}
        render={({ onChange, value }) => (
          <ReactSelect
            menuPortalTarget={document.body}
            value={value}
            onChange={onChange}
            options={optionsWithTranslate}
            placeholder={placeholder}
            isDisabled={disabled}
            classNamePrefix="react-select"
            isSearchable={isSearchable}
            components={{
              MenuList: StyledMenuList,
              Option: StyledOption,
              IndicatorSeparator: () => null,
            }}
          />
        )}
      />
      {error && (
        <ErrorMessage message={error.message} className="error" />
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  position: relative;

  .error {
    position: absolute;
    top: 100%;
    padding-top: 4px;
  }
`;

Select.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  rules: PropTypes.object,
  defaultValue: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  blackTheme: PropTypes.bool,
  className: PropTypes.string,
  isRequired: PropTypes.bool,
  CustomOption: PropTypes.node
};

export default Select;
