import React from 'react';
import some from 'lodash/some';
import PropTypes from 'prop-types';

import { useFileEntities } from 'store/hooks/globalState/useFileEntites';

import Button from '_legacy/components/Button/Button';

const SubmitButton = ({ children, onClick, disabled }) => {
  const fileEntities = useFileEntities();
  const isSomeFileLoading = some(fileEntities, 'isLoading');

  return (
    <Button
      stretch
      typeButton="button"
      disabled={disabled || isSomeFileLoading}
      handleOnClick={onClick}
      paddingVertical="16"
      text={children}
    />
  );
};

SubmitButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SubmitButton;
