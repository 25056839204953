import React from 'react'
import PropTypes from 'prop-types'

import NavigationItem from './NavigationItem'

import './index.scss'

const NavigationList = ({ navigationProps }) => {
  return (
    <nav className='search-navigation'>
      <ul className='search-navigation__list'>
        {navigationProps.map((item, i) => (
          <NavigationItem key={i} to={item.linkTo} text={item.linkText} />
        ))}
      </ul>
    </nav>
  )
}

NavigationList.propTypes = {
  navigationProps: PropTypes.array,
}

export default NavigationList
