import React from 'react';
import styled from 'styled-components';

import RadioSwitcher from '_legacy/common/Radio/RadioSwitcher';
import { Row } from '_legacy/common';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const name = 'takingPicturesOk';

const TakingPicture = () => {
  const t = useGetTranslate();

  return (
    <TakingPictureWrapper
      nameBlock={t('create-experience.form.taking-picture.label')}
      component={<RadioSwitcher name={name} />}
    />
  );
};

const TakingPictureWrapper = styled(Row)`
  align-items: center;
`;

export default TakingPicture;
