import React from 'react'
import styled from 'styled-components'
import always from 'lodash/fp/always'
import propEq from 'lodash/fp/propEq'
import { UserRegistrationType } from 'shared'
import { filtersConfig, counterConfig, subFiltersConfig, fields, sortKeys } from '_legacy/configs/table/myMedia'
import { SELECT_PROMOTIONAL_MEDIA_MODAL, REMOVE_MEDIA_MODAL } from '_legacy/components/Modal/modals-types'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useMyMediaFilters,
  useMyMediaTable,
  useSetMyMediaFilters,
} from 'store/hooks/globalState/useMyMedia'
import { useStateModal } from 'store/hooks/globalState/useModal'
import { useIsUserHasTalent, useUser } from 'store/hooks/globalState/useUser'
import {
  useFetchMyMedia,
  useUploadMedia
} from 'requests/my-media'

import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import { MY_MEDIA, elementsTypes } from '_legacy/components/Table/table-components/types/my-media/config'
import { getImageTypes, getVideoTypes } from 'utils/isFile'


const tableConfig = [
  {
    header: 'table.header.media',
    field: sortKeys.MEDIA,
    type: elementsTypes.media
  },
  {
    header: 'table.header.used-in',
    field: sortKeys.USED_COUNT,
    type: elementsTypes.usedIn
  },
  {
    header: 'table.header.promotional',
    field: sortKeys.PROMOTIONAL,
    type: elementsTypes.promotional,
    hint: 'table.header.hint.promotional'
  },
  {
    header: 'table.header.upload-date',
    field: sortKeys.UPLOAD_DATE,
    type: elementsTypes.uploadDate
  }
]

const UploadMediaButton = React.memo(function UploadMediaButton() {
  const t = useGetTranslate();
  const uploadMedia = useUploadMedia()

  const acceptTypes = [getImageTypes(), getVideoTypes()].join(', ')

  const onChange = e => {
    const files = Array.from(e.target.files)
    files.forEach(uploadMedia)
  }

  return (
    <div className='my-media-upload-wrapper button-component'>
      <label className='my-media-upload'>
        <span className='my-media-upload__text'>{t('my-library.table.button.upload-media')}</span>
        <input
          type='file'
          multiple
          className='my-media-upload__input'
          onChange={onChange}
          accept={acceptTypes}
        />
      </label>
    </div>
  );
});


export default function MyMedia() {
  const t = useGetTranslate()
  const isUserHasTalent = useIsUserHasTalent()
  const isAficionado = useUser(propEq('user.registrationType', UserRegistrationType.AFICIONADO))
  const openRemoveMediaModal = useStateModal(REMOVE_MEDIA_MODAL)
  const openPromotionalMediaModal = useStateModal(SELECT_PROMOTIONAL_MEDIA_MODAL)


  const actionsConfig = React.useMemo(() => {
    return [
      {
        label: 'table.actions.remove',
        cbFn: openRemoveMediaModal,
      },
      {
        label: 'table.actions.set-as-promotional',
        cbFn: openPromotionalMediaModal,
        shouldShow: always(isUserHasTalent)
      },
      {
        label: 'table.actions.share-on-my-profile',
        cbFn: () => alert('don\'t implement'),
        shouldShow: always(isAficionado)
      }
    ]
  }, [t, openRemoveMediaModal, openPromotionalMediaModal])


  const bulkActionsConfig = React.useMemo(() => {
    return {
      getFilters: useMyMediaFilters,
      setFilters: useSetMyMediaFilters,
      actions: [
        { label: 'table.actions.remove', cbFn: openRemoveMediaModal },
      ]
    };
  }, [t, useMyMediaFilters, useSetMyMediaFilters, openPromotionalMediaModal, openPromotionalMediaModal])


  return (
    <StyledWrapper className='wrapper content-wrapper'>
      <h1 className='title'>{t('my-library.header')}</h1>
      <Filters
        filtersConfig={filtersConfig}
        getFn={useMyMediaFilters}
        setFn={useSetMyMediaFilters}
        ButtonComponent={UploadMediaButton}
      />
      <SubFilters
        counterConfig={counterConfig}
        subFiltersConfig={subFiltersConfig}
        getFn={useMyMediaFilters}
        setFn={useSetMyMediaFilters}
      />
      <Table
        tableName={MY_MEDIA}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        bulkActionsConfig={bulkActionsConfig}
        getTableFn={useMyMediaTable}
        fields={fields}
        getFiltersFn={useMyMediaFilters}
        setFiltersFn={useSetMyMediaFilters}
        fetchTableFn={useFetchMyMedia}
      />
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 100px;
  .title {
    margin: 50px 0 40px;
    font-family: 'Playfair Display';
    font-size: 60px;
    font-weight: 900;
    line-height: 75px;
    color: ${({ theme: { colors } }) => colors.blue};
    text-align: center;
  }
  .row-content {
    grid-template-columns: 350px 3fr 300px 2fr;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 250px 3fr 150px 2fr;
    }
    @media screen and (max-width: 991px) {
      grid-template-columns: 200px 100px 100px 2fr;
    }
  }
`;
