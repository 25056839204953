import cond from 'lodash/cond'
import compose from 'lodash/fp/compose'
import negate from 'lodash/fp/negate'
import prop from 'lodash/fp/prop'
import isEqual from 'lodash/fp/isEqual'
import T from 'lodash/fp/T'
import omit from 'lodash/fp/omit'

const getCharitiesValue = ({ charitiesIdsIn, ...rest }) => ({
  ...rest, charitiesIdsIn: [charitiesIdsIn?.value].filter(Boolean)
})

export const parseCharities = cond([
  [compose(negate(Boolean), prop('charitiesIdsIn')), omit('charitiesIdsIn')],
  [compose(negate(Boolean), prop('charitiesIdsIn.value')), omit('charitiesIdsIn')],
  // todo: fix when backend add it
  // [compose(isEqual('AFICIONADO_CHOOSES'), prop('charitiesIdsIn.value')), merge({ charitiesIdsIn: 'AFICIONADO_CHOOSES' })],
  // [compose(isEqual('NOT_SPECIFIED'), prop('charitiesIdsIn.value')), merge({ charitiesIdsIn: 'AFICIONADO_CHOOSES' })],
  [compose(isEqual('AFICIONADO_CHOOSES'), prop('charitiesIdsIn.value')), omit('charitiesIdsIn')],
  [compose(isEqual('NOT_SPECIFIED'), prop('charitiesIdsIn.value')), omit('charitiesIdsIn')],
  [T, getCharitiesValue],
])
