import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import get from 'lodash/get'

import { useModal } from 'store/hooks/useModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Field from './Field'
import PublicRadioInputItem from '_legacy/components/PublicView/PublicRadioInputItem'
import DropdownWrapper from '_legacy/components/DropdownWrapper'
import Button from '_legacy/components/Button'

const CustomRadio = ({ placeholder, options = [], field, getFn, setFn }) => {
  const t = useGetTranslate()
  const [active, , close, toggle] = useModal(false)
  const value = getFn(current => get(current, field))
  const handleChange = setFn((prev, value) => ({ ...prev, [field]: value }))
  const [localState, setLocalState] = React.useState(value)
  const label = options.find(option => String(option.value) === String(value))?.label

  const onCancel = () => {
    setLocalState(value)
    close()
  }

  const onApply = () => {
    handleChange(localState)
    close()
  }

  return (
    <StyledContainer>
      <Field
        isOpen={active}
        value={t(label)}
        onClick={toggle}
        placeholder={placeholder}
      />

      <DropdownWrapper active={active} close={close}>
        <StyledWrapper>
          <StyledList>
            {options.map(option => (
              <PublicRadioInputItem
                key={option.value}
                label={option.label}
                value={option.value}
                checked={String(localState) === String(option.value)}
                onClick={setLocalState}
              />
            ))}
          </StyledList>

          <ButtonContainer>
            <Button
              primary
              text={t('shared.words.cancel')}
              handleOnClick={onCancel} fontSize={0.75}
            />
            <Button text={t('shared.words.apply')} handleOnClick={onApply} fontSize={0.75} />
          </ButtonContainer>
        </StyledWrapper>
      </DropdownWrapper>
    </StyledContainer>
  )
}

CustomRadio.propTypes = {
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
      label: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
    })
  ),
  field: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
  getFn: PropTypes.func,
  setFn: PropTypes.func,
}

const StyledContainer = styled.div`
  position: relative;
`

const StyledWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`

const StyledList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 12px;
`

export default CustomRadio
