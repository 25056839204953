import React from 'react'
import cx from 'classnames'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Icon from 'assets/icons/Icon'

const CalendarButton = ({ className, ...props }) => {
  const t = useGetTranslate()
  const classes = cx('action-button', 'calendar-button', className)

  return (
    <button type="button" className={classes} {...props}>
      <Icon.ViewCalendar className="action-button__icon" />
      <span className="action-button__text">{t('shared.words.view-calendar-mode')}</span>
    </button>
  )
}

export default CalendarButton
