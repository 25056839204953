import React from 'react'
import cx from 'classnames'

import { routesList } from 'router/routesMap'
import { getShortMonthDate } from 'utils/date'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import { getSaveFullName } from 'utils/user'
import { useUser } from 'store/hooks/globalState/useUser'
import { getIsOwnByNotificationProps } from '../utils'

const NewMessageNotification = (props) => {
  const { id, createdAt, isRead, isOverdue, isTable, isMenu, options, sender, receiverTalent, receiverUser } = props
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()

  const receiver = receiverTalent || receiverUser
  const currentUserIsReceiver = useUser(getIsOwnByNotificationProps(props), [props])

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  const message = React.useMemo(() => {
    return (
      <Trans
        tKey='notification-menu.item.message.new-message'
        phs={[
          { ph: '{{senderName}}', value: getSaveFullName(sender) }
        ]}
      />
    )
  }, [sender])

  const agentMessage = React.useMemo(() => {
    return (
      <Trans
        tKey='notification-menu.item.message.new-message--agent'
        phs={[
          { ph: '{{senderName}}', value: getSaveFullName(sender) },
          { ph: '{{talentFullName}}', value: getSaveFullName(receiver) },
        ]}
      />
    )
  }, [sender, receiver])

  return (
    <div className={containerClasses} id={id} key={id + currentUserIsReceiver}>
      <div className="notification-item__icon">
        <Icon.NewMessageNotificationIcon id={String(id + isMenu)} />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>

      <div className="notification-item__text notification-text">
        {currentUserIsReceiver ? message : agentMessage}
      </div>

      {currentUserIsReceiver && (
        <div className="notification-item__controls">
          <Button
            isLink
            handleOnClick={() => markAsRead(id)}
            path={routesList.secureChatId(options.chatId)}
            text={t('notification.buttons.open-message')}
            className="notification-item__action-button"
          />
        </div>
      )}
    </div>
  )
}

export default NewMessageNotification
