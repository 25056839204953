import React from 'react'
import cx from 'classnames'
import get from 'lodash/get'

import { useMarkAsRead, useMarkAsUnRead } from 'requests/notifications'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'

import { getSaveFullName, getTalentId, isAgentOrManager } from 'utils/user'
import { getShortMonthDate } from 'utils/date'

import Icon from 'assets/icons/Icon'
import Button from '_legacy/components/Button'
import * as r from '_legacy/constants/routes'
import { getOrderPageRoute } from 'router/routesMap'

const ReviewReceivedNotification = ({
  id,
  createdAt,
  isRead,
  isOverdue,
  isTable,
  isMenu,
  sender,
  receiverTalent,
  receiverUser,
  occurrence,
}) => {
  const t = useGetTranslate()
  const markAsRead = useMarkAsRead()
  const markAsUnRead = useMarkAsUnRead()
  const isAgent = useUser(isAgentOrManager)
  const talentId = useUser(getTalentId)

  const receiverTalentId = get(receiverTalent, 'id')
  const receiverUserId = get(receiverUser, 'id')

  // handler
  const markBtnHandler = isRead ? () => markAsUnRead(id) : () => markAsRead(id)

  // classes
  const markBtnClasses = cx('notification-item__mark-btn', 'mark-as-read-button', {
    'mark-as-read-button--unread': isRead,
  })

  const containerClasses = cx('notification-item', {
    'notification-item--read': isRead,
    'notification-item--overdue': isOverdue,
    'notification-item--table': isTable,
    'notification-item--menu': isMenu,
  })

  // text
  const markBtnText = isRead ? 'notification-menu.mark-as-unread' : 'notification-menu.mark-as-read'

  const aficionadoProps = {
    tKey: 'notification-menu.item.message.aficionado-new-review',
    phs: [
      { ph: '{{userLink}}', value: `/${get(sender, 'plaqueNickname', '')}` },
      { ph: '{{userName}}', value: getSaveFullName(sender) },
      { ph: '{{offeringLink}}', value: getOrderPageRoute(occurrence.type, occurrence.id) },
      { ph: '{{offeringName}}', value: get(occurrence, 'title') },
    ],
  }

  const agentProps = {
    tKey: 'notification-menu.item.message.agent-new-review',
    phs: [
      { ph: '{{talentLink}}', value: `/${get(receiverTalent || receiverUser, 'plaqueNickname', '')}` },
      { ph: '{{talentName}}', value: getSaveFullName(receiverTalent || receiverUser) },
      { ph: '{{userLink}}', value: `/${get(sender, 'plaqueNickname', '')}` },
      { ph: '{{userName}}', value: getSaveFullName(sender) },
      { ph: '{{offeringLink}}', value: getOrderPageRoute(occurrence.type, occurrence.id) },
      { ph: '{{offeringName}}', value: get(occurrence, 'title') },
    ],
  }

  const talentProps = {
    tKey: 'notification-menu.item.message.talent-new-review',
    phs: [
      { ph: '{{userLink}}', value: `/${get(sender, 'plaqueNickname', '')}` },
      { ph: '{{userName}}', value: getSaveFullName(sender) },
      { ph: '{{offeringLink}}', value: getOrderPageRoute(occurrence.type, occurrence.id) },
      { ph: '{{offeringName}}', value: get(occurrence, 'title') },
    ],
  }

  let props = {}
  if (receiverUserId) {
    props = aficionadoProps
  }
  if (talentId === receiverTalentId && !isAgent) {
    props = talentProps
  }
  if (talentId === receiverTalentId && isAgent) {
    props = agentProps
  }

  return (
    <div className={containerClasses} id={id}>
      <div className="notification-item__icon">
        <Icon.Review id={String(id + isMenu)} />
      </div>
      <div className="notification-item__text notification-text">
        <Trans {...props} />
      </div>
      <time dateTime={createdAt} className="notification-item__date notification-date">
        {getShortMonthDate(createdAt)}
      </time>
      <button type="button" className={markBtnClasses} onClick={markBtnHandler}>
        {t(markBtnText)}
      </button>

      <div className="notification-item__controls">
        <Button
          isLink
          path={`/${r.REVIEWS}/${r.LIST}/${r.BY_ME}`}
          text={t('shared.words.review')}
          className="notification-item__action-button"
        />
      </div>
    </div>
  )
}

export default ReviewReceivedNotification
