import React from 'react';
import BasicMobileOverlay from '_legacy/components/TalentPublicView/DesktopSlider/Overlay/BasicMobileOverlay'

const Overlay = () => {
  return (
    <BasicMobileOverlay withGradientOverhead={true}>
      <div className="tbv-slider-slide-overlay-live-experience">
      </div>
    </BasicMobileOverlay>
  );
};

export default Overlay;
