import styled, { css } from 'styled-components';

const ModalButton = styled.button.attrs({
  type: 'button'
})`
  width: 100%;
  height: 100%;
  padding: 0;

  background-color: transparent;
  cursor: pointer;
  border: none;
  
  ${({ error }) => error && css`
    > div {
      border-color: red;
    }
  `}
  
`;

export default ModalButton;
