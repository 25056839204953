import React from 'react'
import styled from 'styled-components'

import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import DropZoneWrapper from '../../components/DropZone/DropZoneWrapper'

const MediaDropZone = ({ maxSize }) => {
  const t = useGetTranslate()
  return (
    <MediaDropZoneWrapper>
      <i className="icon-frame icon-camera" />
      <h4 className="title">{t('video-message.form.media.placeholder.title')}</h4>
      <p className="description">
        <Trans tKey="video-message.form.media.placeholder.text" phs={[{ ph: '{{max-size}}', value: maxSize }]} />
      </p>
    </MediaDropZoneWrapper>
  )
}

const MediaDropZoneWrapper = styled(DropZoneWrapper)`
  height: 100px;

  i {
    font-size: 38px;
  }

  .title {
    padding: 0;
    margin: 0;
    font-size: 13px;
  }

  .description {
    padding: 0;
    margin: 0;
    font-size: 13px;
  }
`

export default MediaDropZone
