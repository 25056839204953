import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'portals/ModalPortal';

const EntreeVIPVideoModal = () => {
  return (
    <ModalPortal isOpen isMobileFullScreen closeOnClickOutside>
      <iframe
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        src="https://www.youtube.com/embed/80TpqU9Q-uc"
        className="videoModalIframe"
        allowFullScreen
        frameBorder="0"
        height="540"
        width="960"
      />
    </ModalPortal>
  );
};

EntreeVIPVideoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default EntreeVIPVideoModal;
