import React from 'react'
import { useHistory } from 'react-router-dom'

import {
  useSetIsLoadingChatsList,
  useSetIsLoadingChat,
  useSetChatsList,
  useChatClient,
  useIsLoadingChat
} from 'store/hooks/globalState/useChats'
import { useSetSearchAficionados } from 'store/hooks/globalState/useSearchList'

import { notify } from '../utils/notify'
import { SECURE_CHAT } from '_legacy/constants/routes'
import { startChat, getUserChat, getUserChats } from 'api/appApi/chats'
import { routesList } from 'router/routesMap'

export function useStartChat(participantId) {
  const history = useHistory()
  const setIsLoading = useSetIsLoadingChat((prev, next) => next)

  const resetSearchAficionados = useSetSearchAficionados(() => ({
    list: [],
    pageInfo: {
      page: 1,
      limit: 30,
    },
    searchLike: '',
  }))

  const updateChatList = useSetChatsList(
    (prev, chatId, chat) => ({
      ...prev,
      list: {
        ...prev.list,
        [chatId]: { ...prev[chatId], ...chat },
      },
      pageInfo: {
        ...prev.pageInfo,
        count: (prev.pageInfo?.count || 0) + 1,
      },
    }),
    []
  )

  const chatClient = useChatClient()

  return React.useCallback(() => {
    setIsLoading(true)

    startChat({ participantId }).then(async ({ chat }) => {
      const chatInstance = await chatClient.getConversationBySid(chat.twilioConversationSid)

      updateChatList(chat.id, { chatInstance, chatInfo: chat })

      resetSearchAficionados()

      setIsLoading(false)
      history.push(`/${SECURE_CHAT}/${chat.id}/`)
    })
  }, [setIsLoading, updateChatList, participantId])
}

export function useFetchUserChat() {
  const setIsLoading = useSetIsLoadingChat((prev, next) => next)

  const updateChatList = useSetChatsList(
    (prev, chatId, chat) => ({
      ...prev,
      list: {
        ...prev.list,
        [chatId]: { ...prev[chatId], ...chat },
      },
      pageInfo: {
        ...prev.pageInfo,
        count: (prev.pageInfo?.count || 0) + 1,
      },
    }),
    []
  )

  return React.useCallback(
    ({ chatId, chatClient, withoutLoader = false }) => {
      if (!withoutLoader) setIsLoading(true)

      getUserChat({ chatId })
        .then(async ({ chat }) => {
          const chatInstance = await chatClient.getConversationBySid(chat.twilioConversationSid)

          const chatData = { chatInstance, chatInfo: chat }

          updateChatList(chat.id, chatData)

          if (!withoutLoader) setIsLoading(false)
        })
        .catch(err => {
          console.warn(err)
          notify('Chat not found', 'error')

          if (!withoutLoader) setIsLoading(false)
        })
    },
    [setIsLoading, updateChatList]
  )
}

export function useFetchUserChats() {
  const setIsLoading = useSetIsLoadingChatsList((prev, next) => next)

  const updateChatsList = useSetChatsList(
    (prev, next) => ({
      ...prev,
      list: {
        ...prev.list,
        ...next.list,
      },
      pageInfo: {
        ...prev.pageInfo,
        ...next.pageInfo,
      },
    }),
    []
  )

  return React.useCallback(
    ({ chatClient, page = 1 }) => {
      setIsLoading(true)

      getUserChats({
        query: {
          page,
          limit: 10,
        },
      })
        .then(async ({ chats, pageInfo }) => {
          const list = await chats.reduce(async (accP, chat) => {
            const acc = await accP

            const chatInstance = await chatClient.getConversationBySid(chat.twilioConversationSid).catch(e => {
              console.warn(e)
            })

            if (!chatInstance) return acc

            const tmc = await chatInstance.getMessagesCount()
            const lrmi = chatInstance.lastReadMessageIndex

            const umc = tmc - (lrmi + 1)

            return {
              ...acc,
              [chat.id]: { chatInstance, chatInfo: chat, unreadMessagesCount: umc },
            }
          }, Promise.resolve({}))

          updateChatsList({ list, pageInfo })
          setIsLoading(false)
        })
        .catch(err => {
          console.warn(err)
          // todo: check after EN-1965
          // notify('Fail to fetch chats', 'error')
          setIsLoading(false)
        })
    },
    [setIsLoading, updateChatsList]
  )
}

export function useChat(userId, chatId) {
  const history = useHistory()
  const startChat = useStartChat(userId)
  const isLoading = useIsLoadingChat()

  const handleStartChat = () => {
    if (chatId) {
      history.push(routesList.secureChatId(chatId))
      return
    }

    if (!isLoading && userId) {
      return startChat()
    }
  }

  return { isLoading, handleStartChat }
}
