import React from 'react';
import { useHistory } from 'react-router-dom'
import cx from 'classnames'

import Icon from 'assets/icons/Icon';
import { TALENT, REGISTER } from '_legacy/constants/routes';

import { useModal } from 'store/hooks/useModal'
import { useOnClickOutside } from 'store/hooks/useOnClickOutside';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetchConnectedTalents } from 'requests/talent-switcher';
import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState'
import { useIsUserHasTalent } from 'store/hooks/globalState/useUser'
import { useSwitchToManager } from 'requests/talent-switcher'


import SwitcherHeader from './SwitcherHeader';
import ProfileList from './ProfileList';

const TalentSwitcher = () => {
  const history = useHistory()
  const t = useGetTranslate();
  const fetchConnectedTalents = useFetchConnectedTalents();
  const blockFullpage = useSetFullpageBlock((prev, next) => next, []);
  const [isOpen, openSwitcher, closeSwitcher] = useModal(false);
  const userHasTalent = useIsUserHasTalent()
  const switchToManager = useSwitchToManager()

  const onClick = () => {
    if (!isOpen) {
      openSwitcher();
      blockFullpage(true)
      fetchConnectedTalents();
    } else {
      blockFullpage(false)
      closeSwitcher();
    }
  };

  const node = React.useRef();
  useOnClickOutside(node, () => {
    blockFullpage(false)
    closeSwitcher();
  })

  const handleAddNewTalent = () => {
    if (userHasTalent) {
      switchToManager()
      history.push(`/${REGISTER}/${TALENT}`)
    } else {
      history.push(`/${REGISTER}/${TALENT}`)
    }
    closeSwitcher()
  }

  const classes = cx('user-account__switcher-wrapper', {
    'active': isOpen
  })

  return (
    <div className="user-account__switcher" ref={node}>
      <Icon.SwitchProfile onClick={onClick} className="switcher-icon" />

      <div className={classes} isActive={isOpen}>
        <SwitcherHeader />
        <ProfileList />
        <button className="user-account__switcher-button" onClick={handleAddNewTalent}>
          + {t('switch-profile.action')}
        </button>
      </div>
    </div>
  )
};

export default TalentSwitcher;
