import React from 'react';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import get from 'lodash/get';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { validatePhone as validatePhoneApi } from 'api/appApi/utils';

import { ErrorMessage } from '_legacy/common/index';
import PhoneInput from '_legacy/common/Input/PhoneInput';
import { sharedErrors } from '_legacy/common/Input/phoneErrorMessages';

const getPhone = (phone = '') =>
  phone.startsWith('+') ? phone.slice(1) : phone;

const needValidate = phone => getPhone(phone).length > 2;

const PhoneWithValidation = ({ name = 'phone', disabled, isRequired }) => {
  const t = useGetTranslate();
  const { clearErrors, setError, errors, watch } = useFormContext();
  const error = get(errors, name);
  const phone = watch(name, '') || '';
  const [localError, setLocalError] = React.useState();

  const validatePhone = async (phone = '', disabled) => {
    if (!disabled && needValidate(phone || '')) {
      try {
        await validatePhoneApi({ phone });
        clearErrors(name);
        setLocalError(null);
      } catch (e) {
        setError(name, { message: t(sharedErrors[e.name]) });
        setLocalError(t(sharedErrors[e.name]));
      }
    } else {
      if (!isRequired || disabled) {
        clearErrors(name);
        setLocalError(null);
      }
    }
  };

  const debounceValidate = React.useCallback(debounce(validatePhone, 400), []);

  React.useEffect(() => {
    debounceValidate(phone, disabled);
  }, [phone, disabled]);

  return (
    <PhoneWrapper>
      <PhoneInput
        disabled={disabled}
        name={name}
        validation={{
          validate: {
            required: phone =>
              isRequired
                ? needValidate(phone) || 'shared.form.phone.errors.required'
                : true,
            validation: () => localError,
          },
        }}
      />
      {error && <StyledErrors message={error.message} />}
    </PhoneWrapper>
  );
};

const PhoneWrapper = styled.div`
  position: relative;
`;

const StyledErrors = styled(ErrorMessage)`
  position: absolute;
`;

PhoneWithValidation.propTypes = {
  name: PropTypes.string,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
};

export default PhoneWithValidation;
