import React from 'react'
import PropTypes from 'prop-types';
import get from 'lodash/get'
import mapValues from 'lodash/mapValues'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import DesktopFilters from './DesktopFilters'
import MobileFilters from './MobileFilters'

const mandatory = ['filtersConfig', 'getFn', 'setFn']

export default function Filters (props) {
  const isMobile = useWindowDimensions(current => get(current, 'width') <= 768)
  const clear = props.setFn((prev) => ({ ...mapValues(prev, () => undefined), limit: prev.limit, sortField: prev.sortField }))

  React.useEffect(() => () => clear(), [])

  let isValidProps = true
  mandatory.forEach(field => {
    if (!props[field]) {
      console.warn(`<Filters /> missed "${field}"`)
      isValidProps = false
    }
  })

  if (!isValidProps) return null

  if (isMobile) {
    return (
      <MobileFilters
        {...props}
      />
    )
  }

  return (
    <DesktopFilters
      {...props}
    />
  )
}

Filters.propTypes = {
  className: PropTypes.string,
  filtersConfig: PropTypes.array.isRequired,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired
}
