import React from 'react'

import { notify } from 'utils/notify'
import { getPdfLinkToPreview } from '../api/api'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export function useGetLink() {
  const t = useGetTranslate()

  return React.useCallback(() => {
    getPdfLinkToPreview()
      .then(({ link }) => {
        if (link?.pdf) {
          window.open(link.pdf, '_blank')
        } else {
          notify(t('account.entourage.full-status.nothing-to-view'))
        }
      })
  }, [t])
}
