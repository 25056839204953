import React from 'react'

import { statuses } from '_legacy/configs/table/managePromoCodes'

import CommonStatus from '../common/Status'

export default React.memo(function Status ({ isLoading, status }) {
  const config = statuses[status]
  return (
    <CommonStatus
      isLoading={isLoading}
      status={status}
      config={config}
    />
  )
})
