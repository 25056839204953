import React from 'react'

import Button from '_legacy/components/Button'
import ModalPortal from 'portals/ModalPortal'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const EditEventModal = ({ isOpen, onClose, editHandler }) => {
  const t = useGetTranslate()

  return (
    <ModalPortal isOpen={isOpen} isMobileFullScreen showCloseButton onClose={onClose}>
      <div className="edit-event-modal">
        <h1 className="modal-header">{t('edit-event-modal.header')}</h1>
        <p className="modal-text">{t('edit-event-modal.text')}</p>
        <div className="edit-event-modal__controls">
          <Button primary handleOnClick={onClose} text={t('edit-event-modal.button.cancel')} />
          <Button handleOnClick={editHandler} text={t('edit-event-modal.button.change')} />
        </div>
      </div>
    </ModalPortal>
  )
}

export default EditEventModal
