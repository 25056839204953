import React from 'react';
import { useParams } from 'react-router';
import compose from 'lodash/fp/compose';

import {
  fetchRecurringVirtualEventsHeader as fetchRecurringVirtualEventsHeaderApi,
  fetchRecurringVirtualEventsList as fetchRecurringVirtualEventsListApi,
} from './api'

import normalizeParams from 'utils/normalizeParams';
import {
  useSetVirtualEventsOfferingDetailsHeader,
  useSetVirtualEventsOfferingDetailsTableFilters,
  useSetVirtualEventsOfferingDetailsTable,
} from './store'
import { useTalentId } from 'store/hooks/globalState/useUser';

import { detailsConfig } from '_legacy/configs/table/recurringList'

import { parseRangeDateRecurring } from '_legacy/components/Table/Filters/utils/rangeDate'

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

const normalize = compose(normalizeParams, parseRangeDateRecurring)

/* Recurring details Virtual Event start */
export function useFetchVirtualEventsOfferingDetailsHeader() {
  const setVirtualEventsOfferingDetailsHeader = useSetVirtualEventsOfferingDetailsHeader((prev, next) => next);
  const talentId = useTalentId()
  const { id } = useParams();
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    const query = normalizeParams(detailsConfig);
    setVirtualEventsOfferingDetailsHeader(null);
    fetchRecurringVirtualEventsHeaderApi({ talentId, id, query })
      .then(({ liveVirtualEvent }) => {
        setVirtualEventsOfferingDetailsHeader({ offering: liveVirtualEvent });
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [id, setVirtualEventsOfferingDetailsHeader]);
}

export function useFetchVirtualEventsOfferingDetails() {
  const setVirtualEventsOfferingDetailsTableFilters = useSetVirtualEventsOfferingDetailsTableFilters((prev, next) => ({ ...prev, ...next }));
  const setVirtualEventsOfferingDetailsTable = useSetVirtualEventsOfferingDetailsTable((prev, next) => next);
  const { id } = useParams();
  const talentId = useTalentId()

  return React.useCallback((options) => {
    const query = {
      liveVirtualEventIdIn: [id],
      ...normalize(options)
    }

    setVirtualEventsOfferingDetailsTableFilters({ liveVirtualEventIdIn: id })
    setVirtualEventsOfferingDetailsTable(null)
    fetchRecurringVirtualEventsListApi({ talentId, query })
      .then(({ pageInfo, occurrences }) => {
        setVirtualEventsOfferingDetailsTableFilters(pageInfo)
        const parsedOccurrence = occurrences.map(obj => ({ ...obj, type: 'virtual-event' }))
        setVirtualEventsOfferingDetailsTable(parsedOccurrence)
      })
      .catch((error) => console.warn(error));
  }, [id, setVirtualEventsOfferingDetailsTableFilters, setVirtualEventsOfferingDetailsTable]);
}
/* Recurring details Virtual Event end */
