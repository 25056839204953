import { addDays, differenceInCalendarDays, isSameDay, set } from 'date-fns'

export const splitSlotsByDate = slots => {
  const result = []

  for (let i = 0; i < slots.length; i++) {
    const current = slots[i]
    const calendarDays = differenceInCalendarDays(new Date(current.end), new Date(current.start))

    if (calendarDays > 0) {
      for (let j = 0; j <= calendarDays; j++) {
        const slot = {
          ...current,

          start: isSameDay(new Date(current.start), addDays(new Date(current.start), j))
            ? new Date(current.start)
            : set(addDays(new Date(current.start), j), { hours: 0, minutes: 0 }),

          end: isSameDay(new Date(current.end), addDays(new Date(current.start), j))
            ? new Date(current.end)
            : set(addDays(new Date(current.start), j), { hours: 23, minutes: 59 }),
        }
        result.push(slot)
      }
    } else {
      result.push({
        ...current,
        start: new Date(current.start),
        end: new Date(current.end),
      })
    }
  }

  return result
}
