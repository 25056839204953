// Base imports
import React from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { isNumber, prop, propEq } from 'lodash/fp'
import { TalentOfferStatus } from 'shared'

// Assets
import Icon from 'assets/icons/Icon'

// Requests
import { usePublishOffer, useUnpublishOffer } from 'requests/manage-offerings'
import { useFetchExperienceOfferingDetailsHeader } from './state/requests'

// Store (Hooks)
import {
  useExperienceOfferingDetailsHeader,
  useExperienceOfferingDetailsTableFilters,
  useSetExperienceOfferingDetailsHeader,
} from './state/store'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { useCurrencyCode, useCurrencyRate } from 'store/hooks/globalState/useCurrency'

// Components
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'

// Utils
import { getTalentPlaqueNickname } from 'utils/user'
import { getOfferingDateLabel, getOfferLocationSubtitle, getOfferLocationTitle } from '../utils'
import { formatPrice } from 'utils/helpers/price'

// Legacy components
import PageLoader from '_legacy/common/Template/PageLoader'
import Button from '_legacy/components/Button'
import CustomPicture from '_legacy/common/CustomPicture'

import '../index.scss'
import {
  useCheckIsOfferEditable,
  useRejectOrders,
} from '../../OrderDetails/ExperienceOrderDetails/state/requests/experience-order-details'
import { routesList } from 'router/routesMap'
import ExperienceRecurringList from './components/ExperienceRecurringList'
import LocationTooltip from '../../components/LocationTooltip'
import ModalWithControls from 'portals/ModalWithControls'
import { useGetRecurringOptions } from '_legacy/components/CreateExperience/components/Recurring/config'
import { useIsExperienceEditable } from '../../OrderDetails/ExperienceOrderDetails/state/store'
import { notify } from 'utils/notify'
import RejectModal from '../../components/RejectModal'

const useGetRecurringLabel = () => {
  const t = useGetTranslate()
  const getRecurringOptions = useGetRecurringOptions()

  return React.useCallback(offering => {
    const { date, recurring } = offering
    const recurringOptions = getRecurringOptions(new Date(date))

    if (recurring?.type) {
      const option = recurringOptions.find(propEq('value', recurring.type))
      return option.label
    } else {
      return t('recurring-list.repeatable-dates.custom')
    }
  }, [t, getRecurringOptions])
}

const RecurringListExperience = () => {
  const t = useGetTranslate()
  const { id: offerId } = useParams()
  const history = useHistory()

  const getRecurringLabel = useGetRecurringLabel()
  const userNickname = useUser(getTalentPlaqueNickname)
  const [isEditOpen, openEditModal, closeEditModal] = useModal(false)
  const [isRejectModalOpened, openRejectModal, closeRejectModal] = useModal(false)

  const checkIsOfferEditable = useCheckIsOfferEditable()
  const fetchOfferingDetails = useFetchExperienceOfferingDetailsHeader()
  const publishOffer = usePublishOffer(fetchOfferingDetails)
  const unPublishOffer = useUnpublishOffer(fetchOfferingDetails)
  const rejectOrders = useRejectOrders(() => routesList.editExperience(offerId))

  const editableProps = useIsExperienceEditable()
  const clearOccurrence = useSetExperienceOfferingDetailsHeader(() => null)

  const currencyCode = useCurrencyCode()
  const currencyRate = useCurrencyRate()
  const offering = useExperienceOfferingDetailsHeader(prop('offering'))
  const isPublished = useExperienceOfferingDetailsHeader(propEq('offering.status', TalentOfferStatus.PUBLISHED))
  const occurrencesCount = useExperienceOfferingDetailsTableFilters(prop('count'))

  const handleUnPublishOffer = () => unPublishOffer([offerId])
  const handlePublishOffer = () => publishOffer([offerId])
  const handleRejectOrders = ({ message, reason }) => rejectOrders(offerId, message, reason)

  const handleEdit = React.useCallback(() => {
    if (!editableProps) return

    if (!editableProps.hasPendingBookingRequests && !editableProps.hasBookedParticipants) {
      history.push(routesList.editExperience(offerId))
    } else if (editableProps.hasPendingBookingRequests && !editableProps.hasBookedParticipants) {
      openEditModal()
    } else {
      notify(t('recurring-list.notification.cannot-edit-exp'), 'error')
    }
  }, [editableProps, offerId])

  React.useEffect(() => {
    fetchOfferingDetails()
    checkIsOfferEditable(offerId)
  }, [offerId])

  React.useEffect(() => () => {
    clearOccurrence()
  }, [])

  if (!offering) return <PageLoader />

  const { name, locations, maxNumberOfParticipants, photo, pricePerParticipant, sequentialId } = offering

  return (
    <div className="recurring-list">
      <OfferingDetailsTitle typeHeader={t('recurring-list.header.experience')} title={name} />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              <CustomPicture file={photo.file} />
            </div>

            <div className="od__column od--padding-info">
              <OfferingsDetailsHeaderItem
                icon={<Icon.Calendar />}
                item={getOfferingDateLabel(t, offering)}
                subitem={getRecurringLabel(offering)}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Pin />}
                item={getOfferLocationTitle(t, offering)}
                subitem={getOfferLocationSubtitle(t, offering)}
                TooltipComponent={locations?.length > 1 ? <LocationTooltip /> : null}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Participants />}
                item={`${t('recurring-list.info.participants')} ${maxNumberOfParticipants || t('recurring-list.info.unlimited')}`}
              />
              <OfferingsDetailsHeaderItem
                icon={<Icon.Pricetag />}
                item={formatPrice(pricePerParticipant, currencyCode, currencyRate)}
                subitem={t('recurring-list.info.participants-price')}
              />

              <Link className="od__row od__public-link" to={routesList.experiencePublicPage(userNickname, sequentialId)}>
                <div className="od--main-text od--accented od__public-link--text">
                  {t('recurring-list.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>

        <div className="od--shadow-box od--centered">
          <div className="od--with-padding">
            <p className="od--uppercase-title mb-24">{t('recurring-list.header.experience')}</p>

            {isNumber(occurrencesCount) && (
              <React.Fragment>
                <p className="od--main-text mb-8">{t('recurring-list.actions.occurrences')}</p>
                <p className="od--status-title mb-32">{occurrencesCount}</p>
              </React.Fragment>
            )}

            <div className="od__column">
              <Button
                classBtn="button mb-16"
                handleOnClick={handleEdit}
                text={t('recurring-list.actions.edit')}
                disabled={editableProps?.hasBookedParticipants}
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
              />
              <Button
                primary
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
                text={isPublished ? t('recurring-list.actions.unpublish') : t('recurring-list.actions.publish')}
                handleOnClick={isPublished ? handleUnPublishOffer : handlePublishOffer}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="recurring-list__table wrapper content-wrapper">
        <ExperienceRecurringList />
      </div>

      {/* Edit Modal */}
      <ModalWithControls
        isOpen={isEditOpen}
        onClose={closeEditModal}
        header={t('recurring-list.edit.header')}
        message={t('recurring-list.edit.text')}
        buttonsConfig={[
          { text: t('recurring-list.edit.cancelText'), handleOnClick: closeEditModal, primary: true },
          { text: t('recurring-list.edit.discardText'), handleOnClick: openRejectModal },
        ]}
      />

      <RejectModal
        isOpen={isRejectModalOpened}
        onClose={closeRejectModal}
        rejectHandler={handleRejectOrders}
      />
    </div>
  )
}

export default RecurringListExperience
