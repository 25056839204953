import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ClearButton = ({ className, onClick }) => {
  return (
    <StyledCloseHandler className={className} onClick={onClick}>
      <div className="cross left" />
      <div className="cross right" />
    </StyledCloseHandler>
  )
}

const StyledCloseHandler = styled.div`
  position: absolute;
  right: 14px;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  margin: auto;
  cursor: pointer;
  :hover {
    .cross {
      background: ${({ theme: { colors } }) => colors.blue};
    }
  }
  .cross {
    position: absolute;
    width: 3px;
    height: 18px;
    background: ${({ theme: { colors } }) => colors.lightGrey};
    transition: background 0.2s;
  }
  .cross.left {
    transform: rotate(45deg);
  }
  .cross.right {
    transform: rotate(-45deg);
  }
`

ClearButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
}

export default ClearButton
