import { map } from 'lodash'

export const columns = {
  entreevip: 'inApp',
  email: 'email',
}

const getNotification = (label, disabled, value, path = []) => {
  return { label, disabled, value, name: [...path, label].join('.') }
}

// obj
//  {
//    email: { disabled: false, value: false }
//    sms: { disabled: false, value: false }
//  }
// ['foo', 'bar'] -> for nested form
// =>
// {
// email: { disabled: false, value: false, label: email, name: 'foo.bar.email' }
const getNotifications = (obj, path) => {
  return map(obj, (o, key) => (getNotification(key, o.disabled, o.value, path)))
}


// ****** reminders options ******
const reminder = {
  label: 'reminder',
  tKey: 'account.notifications.layout.category.reminders',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: true, value: true },
      [columns.email]: { disabled: false, value: true },
    },
    ['reminders']
  ),
}

const reminders = {
  label: 'reminders',
  tKey: 'account.notifications.layout.block.reminders',
  category: [reminder],
}


// ****** community communication options ******
const messages = {
  label: 'messages',
  tKey: 'account.notifications.layout.category.messages',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: false },
      [columns.email]: { disabled: false, value: false },
    },
    ['communityCommunications', 'newMessages']
  ),
}

const reviews = {
  label: 'reviews',
  tKey: 'account.notifications.layout.category.reviews',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: false },
      [columns.email]: { disabled: false, value: false },
    },
    ['communityCommunications', 'newReviews']
  ),
}

const communityCommunication = {
  label: 'communityCommunication',
  tKey: 'account.notifications.layout.block.communityCommunication',
  category: [messages, reviews],
}


// ****** account related ******
const myBookingCommunicationsUser = {
  label: 'myBookingsCommunications',
  tKey: 'account.notifications.layout.category.myBookingCommunications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: true, value: true },
      [columns.email]: { disabled: true, value: true },
    },
    ['accountRelated', 'myBookingsCommunications']
  ),
}

const myBookingCommunicationsTalent = {
  label: 'myBookingsCommunications',
  tKey: 'account.notifications.layout.category.myBookingCommunications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: true },
      [columns.email]: { disabled: false, value: true },
    },
    ['accountRelated', 'myBookingsCommunications']
  ),
}

const myOfferingCommunications = {
  label: 'myOfferingsCommunications',
  tKey: 'account.notifications.layout.category.myOfferingsCommunications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: true },
      [columns.email]: { disabled: false, value: true },
    },
    ['accountRelated', 'myOfferingsCommunications']
  ),
}

const invitationsUser = {
  label: 'invitations',
  tKey: 'account.notifications.layout.category.invitations',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: true, value: true },
      [columns.email]: { disabled: true, value: true },
    },
    ['accountRelated', 'invitations']
  ),
}

const invitationsTalent = {
  label: 'invitations',
  tKey: 'account.notifications.layout.category.invitations',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: true },
      [columns.email]: { disabled: false, value: true },
    },
    ['accountRelated', 'invitations']
  ),
}

const adminNotificationsUser = {
  label: 'adminNotifications',
  tKey: 'account.notifications.layout.category.adminNotifications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: true, value: true },
      [columns.email]: { disabled: true, value: true },
    },
    ['accountRelated', 'adminNotifications']
  ),
}

const adminNotificationsTalent = {
  label: 'adminNotifications',
  tKey: 'account.notifications.layout.category.adminNotifications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: true },
      [columns.email]: { disabled: false, value: true },
    },
    ['accountRelated', 'adminNotifications']
  ),
}

const userAccountRelated = {
  label: 'accountRelated',
  tKey: 'account.notifications.layout.block.accountRelated',
  category: [myBookingCommunicationsUser, invitationsUser, adminNotificationsUser],
}

const talentAccountRelated = {
  label: 'accountRelated',
  tKey: 'account.notifications.layout.block.accountRelated',
  category: [myOfferingCommunications, myBookingCommunicationsTalent, invitationsTalent, adminNotificationsTalent],
}


// ****** other ******
const promo = {
  label: 'promo',
  tKey: 'account.notifications.layout.category.promo',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: false },
      [columns.email]: { disabled: false, value: false },
    },
    ['other', 'promotions']
  ),
}

const news = {
  label: 'news',
  tKey: 'account.notifications.layout.category.news',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: false },
      [columns.email]: { disabled: false, value: false },
    },
    ['other', 'news']
  ),
}

const newTalentNotifications = {
  label: 'newTalentNotifications',
  tKey: 'account.notifications.layout.category.newTalentNotifications',
  notifications: getNotifications(
    {
      [columns.entreevip]: { disabled: false, value: false },
      [columns.email]: { disabled: false, value: false },
    },
    ['other', 'newTalentNotifications']
  ),
}

const other = {
  label: 'other',
  tKey: 'account.notifications.layout.block.other',
  category: [promo, news, newTalentNotifications],
}


// ****** configs ******
export const talentNotificationConfig = [
  reminders,
  communityCommunication,
  talentAccountRelated,
  other,
]

export const userNotificationConfig = [
  reminders,
  userAccountRelated,
  communityCommunication,
  other,
]
