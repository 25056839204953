import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const PublicRadioInputItem = ({ value, subvalue, label, className, checked, onClick }) => {
  const t = useGetTranslate()
  return (
    <StyledRadio className={className}>
      <input
        type="radio"
        //name={value}
        value={value}
        onChange={event => onClick(event.target.value)}
        checked={checked}
      />
      <i />
      <span>{t(label)}</span>
      <StyledSubValue>{subvalue}</StyledSubValue>
    </StyledRadio>
  );
};

const StyledSubValue = styled.label`
  font-weight: 500;
`;

const StyledRadio = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 12px;

  &:not(:last-of-type) {
    margin-right: 45px;
  }

  input {
    position: absolute;
    height: 1px;
    width: 1px;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;

    &:checked + i:after {
      content: ' ';
      background: black;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      position: absolute;
      top: calc(50% - 6px);
      left: calc(50% - 6px);
    }
  }

  i {
    position: relative;
    height: 26px;
    width: 26px;
    margin-right: 14px;
    background: transparent;
    border: 2px solid #000;
    cursor: pointer;
    appearance: none;
    outline: none;
    border-radius: 50%;
  }

  span {
    color: #000;
  }
`;

PublicRadioInputItem.propTypes = {
  value: PropTypes.string.isRequired,
  subvalue: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  checked: PropTypes.bool,
};

export default PublicRadioInputItem;
