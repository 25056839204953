import React from 'react'
import compose from 'lodash/fp/compose'
import {
  managePromoCodes,
  inactivatePromoCodes,
  changeOfferingsPromoCodes,
} from 'api/appApi/promoCodes'

import {
  useCurrentPage,
  SUCCESS_NOTIFICATION,
  useTableActionsNotifications,
} from 'store/hooks/globalState/useUtils'
import {
  useSetManagePromoCodesFilters,
  useSetManagePromoCodesTable,
  useManagePromoCodesFilters,
} from 'store/hooks/globalState/useManagePromoCodes'
import { useUser } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getTalentId } from 'utils/user'
import normalizeParams from 'utils/normalizeParams'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { parseRangeDateForPromo } from '_legacy/components/Table/Filters/utils/rangeDate'

import { fields } from '_legacy/configs/table/managePromoCodes'

const normalize = compose(
  normalizeParams,
  parseRangeDateForPromo
)

export function useFetchManagePromoCodes() {
  const setManagePromoCodesFilters = useSetManagePromoCodesFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const setManagePromoCodesTable = useSetManagePromoCodesTable(
    (prev, next) => next
  )
  const handleResponseError = useHandleResponseError()

  return React.useCallback(
    (options, user) => {
      const query = normalize(options)
      setManagePromoCodesTable(null)
      managePromoCodes({ id: getTalentId(user), query })
        .then(({ nodes, pageInfo }) => {
          setManagePromoCodesFilters(pageInfo)
          setManagePromoCodesTable(nodes)
        })
        .catch(error => {
          handleResponseError(error)
          console.warn(error)
        })
    },
    [setManagePromoCodesFilters, setManagePromoCodesTable]
  )
}

export function useInactivatePromoCodes() {
  const fetchPromoCodes = useFetchManagePromoCodes()
  const filtersOptions = useManagePromoCodesFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    promoCodeIds => {
      inactivatePromoCodes({ id: getTalentId(user), promoCodeIds })
        .then(({ results }) => {
          fetchPromoCodes({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.pc.inactivated',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [fetchPromoCodes, filtersOptions, page, tableActionsNotifications]
  )
}

export function useChangeOfferingsPromoCodes() {
  const fetchPromoCodes = useFetchManagePromoCodes()
  const filtersOptions = useManagePromoCodesFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )
  const page = useCurrentPage()
  const tableActionsNotifications = useTableActionsNotifications()
  const user = useUser()

  return React.useCallback(
    ({ talentOffersIds, promoCodeIds }) => {
      changeOfferingsPromoCodes({
        id: getTalentId(user),
        talentOffersIds,
        promoCodeIds,
      })
        .then(({ results }) => {
          fetchPromoCodes({ ...filtersOptions, page }, user)
          const config = {
            [SUCCESS_NOTIFICATION]: 'table.notifications.pc.offerings-changed',
          }
          tableActionsNotifications({ results, config })
        })
        .catch(error => {
          console.warn(error)
        })
    },
    [user, fetchPromoCodes, filtersOptions, page, tableActionsNotifications]
  )
}
