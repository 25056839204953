import React from 'react'

import { useGetMe } from 'requests/auth'
import { useFetchOffersCategories, useFetchTalentCategories } from 'requests/categories'

import { getAccessToken } from 'utils/storage'
import { IDVerification } from 'utils/services/idVerification'


const withFetchInitialData = Component => props => {
  const accessToken = getAccessToken()
  const getMe = useGetMe()
  const fetchTalentCategories = useFetchTalentCategories()
  const fetchOffersCategories = useFetchOffersCategories()
  const NewCheck = new IDVerification()
  React.useEffect(() => {
    fetchTalentCategories()
    fetchOffersCategories()
    NewCheck.connect()
    if (accessToken) {
      getMe()
    }
  }, [accessToken])

  return <Component {...props} />
}

export default withFetchInitialData
