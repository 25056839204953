import React from 'react';
import PropTypes from 'prop-types';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import styled from 'styled-components'
import NotificationCheckbox from './NotificationCheckbox';

const MobileNotificationsItem = ({ category }) => {
  const t = useGetTranslate()
  return category.map((item, i) => (
    <StyledItem key={i}>
      <StyledLabel>{t(`account.notifications.layout.mobile.${item.label}`)}</StyledLabel>
      {item.notifications.map(notification => (
        <StyledRow key={item.label + notification.label}>
          <p>{t(`account.notifications.layout.block.${notification.label}`)}</p>
          <NotificationCheckbox
            key={notification.name}
            content={notification}
          />
        </StyledRow>
      ))}
    </StyledItem>
  ));
};

const StyledLabel = styled.h6`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  text-transform: capitalize;
  font-feature-settings: 'case' on, 'liga' off;
  color: #272835;
  margin-top: 16px;
`;

const StyledRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 24px 0;
  p {
    font-size: 14px;
    color: #626473;
  }
`;

const StyledItem = styled.div`
  border-bottom: 1px solid #e9eaf0;
`;

MobileNotificationsItem.propTypes = {
  content: PropTypes.shape({
    name: PropTypes.string,
    disabled: PropTypes.bool,
    value: PropTypes.bool,
  }),
};

export default MobileNotificationsItem;
