import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Slider from '../../../Slider';
import Slide from '../../../Slide';

import Actions from './Actions';
import Empty from './Empty';

const VideoMessages = ({ firstName, covers = [], plaqueNickname, offerSequentialId }) => {
  const t = useGetTranslate();

  const slides = covers.map((cover, i) => (
    <Slide.DesktopSlide
      key={`video-messages-${i}`}
      file={cover}
      offerSequentialId={offerSequentialId}
      isPromo={cover.isPromo}
    />
  ));

  return (
    <Slider
      size="big"
      title={t('talent-public-view.sliders.video-messages.header.label')}
      slides={slides}
      empty={<Empty firstName={firstName} />}
      Actions={Actions}
      isMirroredInside
      withGradientOverhead={true}
      bottomSpace={40}
      plaqueNickname={plaqueNickname}
    />
  );
};

VideoMessages.propTypes = {
  firstName: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  offerSequentialId: PropTypes.number.isRequired,
};

export default VideoMessages;
