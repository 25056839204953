import React from 'react';
import { useFormContext } from 'react-hook-form';

// components
import LLastName from '../../../shared/components/LastName';

const LastName = () => {
  const { watch } = useFormContext();
  const isVerificationSuccess = watch('isVerificationSuccess');

  return (
    <LLastName isDisabled={isVerificationSuccess} />
  );
};

export default LastName;
