import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { types } from 'shared'

import { useModal } from 'store/hooks/useModal';

import SelectedList from './components/SelectedList';
import LibraryWrapper from '../components/Library/LibraryWrapper';
import MultiSelectLibraryModal from './components/MultiSelectLibraryModal';
import MultiSelectLibraryButton from './components/MultiSelectLibraryButton';

const { VIDEO, PHOTO } = types.file.Types


const MultiSelectLibrary = React.memo(({ name, rules, maxSelectItems = 30, dropZoneProps, libraryModalProps, customBackdrop }) => {
  const tempName = name + 'Temp'
  const primaryName = name + 'PrimaryFile';

  const [isOpen, openModal, closeModal] = useModal(false);
  const { setValue, getValues } = useFormContext();
  const onClose = () => {
    setValue(tempName, getValues(name))
    closeModal()
  }

  const onOpen = () => {
    setValue(tempName, getValues(name))
    openModal()
  }

  const onSubmit = () => {
    setValue(name, getValues(tempName), { shouldDirty: true, shouldValidate: true })
    closeModal()
  }

  return (
    <React.Fragment>
      <LibraryWrapper rules={rules} name={name}>
        <MultiSelectLibraryButton name={name} onClick={onOpen} customBackdrop={customBackdrop} maxSize={dropZoneProps?.maxSize} />
        <SelectedList name={name} primaryName={primaryName} />
      </LibraryWrapper>
      <MultiSelectLibraryModal
        name={tempName}
        isOpen={isOpen}
        onSubmit={onSubmit}
        closeModal={onClose}
        maxSelectItems={maxSelectItems}
        dropZoneProps={dropZoneProps}
        libraryModalProps={libraryModalProps}
      />
    </React.Fragment>
  )
});

MultiSelectLibrary.propTypes = {
  name: PropTypes.string,
  rules: PropTypes.object,
  maxSelectItems: PropTypes.number,
  dropZoneProps: PropTypes.objectOf(
    PropTypes.shape({
      accept: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
      ]),
      hintComponent: PropTypes.element,
      multiple: PropTypes.bool,
      noClick: PropTypes.bool,
      noDrag: PropTypes.bool,
      maxSize: PropTypes.number,
    })
  ),
  libraryModalProps: PropTypes.objectOf(PropTypes.shape({
    header: PropTypes.string,
    submitBtnText: PropTypes.string,
    enableTypes: PropTypes.oneOf([VIDEO, PHOTO]),
  })),
  customBackdrop: PropTypes.element,
};

export default MultiSelectLibrary;
