import React from 'react'

import ChatWindow from '../ChatWindow'
import ParticipantList from '../ParticipantList'
import MainParticipant from '../MainParticipant'
import useVideoContext from '../../hooks/useVideoContext'
import useLocalTracks from '../VideoProvider/hooks/useLocalTracks'

export default function Room() {
  const { room } = useVideoContext()
  const { removeLocalAudioTrack, removeLocalVideoTrack } = useLocalTracks()

  React.useEffect(
    () => () => {
      removeLocalVideoTrack()
      removeLocalAudioTrack()
      room.disconnect()
    },
    []
  )

  return (
    <div className="room">
      <MainParticipant />
      <ParticipantList />
      <ChatWindow />
    </div>
  )
}
