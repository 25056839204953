import { pipe, cond, always, merge, T } from 'lodash/fp'
import { statuses } from '../utils'


const fillVideoMessageBookingConfig = merge({
  isShowWaiting: false,
  isShowCompleted: false,
  isShowCanceled: false,
})

export const getVideoMessageBookingConfig = pipe(
  cond([
    [statuses.isOrderBooked, always({ isShowWaiting: true })],
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [statuses.isOrderCompletedOrClosed, always({ isShowCompleted: true })],
    [T, always({})],
  ]),
  fillVideoMessageBookingConfig
)


