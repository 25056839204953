import React from 'react'

import LineString from '../common/LineString'

const Participants = ({ isLoading, participantsCount }) => {
  return (
    <LineString
      isLoading={isLoading}
      str={participantsCount}
      className='centered'
    />
  )
}

export default Participants
