import React from 'react'
import styled from 'styled-components';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function Reason ({ className = '', bg, title, description }) {
  const t = useGetTranslate()
  return (
    <StyledWrapper>
      <div className='title-wrapper'>
        <img
          className={`bg-image ${className}`}
          src={bg}
          alt={className}
        />
        <h5 className='title'>
          {t(title)}
        </h5>
      </div>
      <p className='description'>
        {t(description)}
      </p>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.li`
  padding-top: 100px;
  @media only screen and (max-width: 1440px) {
    padding-top: 40px;
  }
  @media only screen and (max-width: 1280px) {
    padding-top: 32px;
  }
  @media only screen and (max-width: 1024px) {
    padding-top: 20px;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 100px;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 62px;
  }
  .title-wrapper {
    position: relative;
    margin-bottom: 12px;
    @media only screen and (max-width: 1440px) {
      margin-bottom: 0;
      padding-bottom: 12px;
    }
    @media only screen and (max-width: 768px) {
      margin-bottom: 12px;
      padding-bottom: 0;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 0;
      padding-bottom: 12px;
    }
    .title {
      position: relative;
      font-family: 'Playfair Display';
      font-size: 23px;
      font-weight: 900;
      line-height: 29px;
      color: #fff;
      margin-left: 25px;
      @media only screen and (max-width: 1440px) {
        margin-left: 0;
      }
      @media only screen and (max-width: 768px) {
        margin-left: 25px;
      }
      @media only screen and (max-width: 480px) {
        margin-left: 0;
      }
    }
    .bg-image {
      position: absolute;
      display: block;
      left: 0;
      bottom: 0;
      z-index: 0;
      @media only screen and (max-width: 1440px) {
        left: auto;
        right: 30px;
      }
      @media only screen and (max-width: 768px) {
        left: 0;
        right: auto;
      }
      @media only screen and (max-width: 480px) {
        left: auto;
        right: 0;
      }
    }
    @media only screen and (max-width: 1440px) {
      .bg-image.access {
        width: 64px;
      }
      .bg-image.format {
        width: 43px;
      }
      .bg-image.included {
        width: 60px;
      }
      .bg-image.passion {
        width: 46px;
      }
      .bg-image.one-on-one {
        width: 60px;
      }
      .bg-image.giving {
        width: 60px;
      }
    }
    @media only screen and (max-width: 1280px) {
      .bg-image.access {
        width: 48px;
      }
      .bg-image.format {
        width: 32px;
      }
      .bg-image.included {
        width: 46px;
      }
      .bg-image.passion {
        width: 34px;
      }
      .bg-image.one-on-one {
        width: 48px;
      }
      .bg-image.giving {
        width: 44px;
      }
    }
    @media only screen and (max-width: 768px) {
      .bg-image.access {
        width: 100px;
      }
      .bg-image.format {
        width: 68px;
      }
      .bg-image.included {
        width: 93px;
      }
      .bg-image.passion {
        width: 72px;
      }
      .bg-image.one-on-one {
        width: 94px;
      }
      .bg-image.giving {
        width: 94px;
      }
    }
    @media only screen and (max-width: 480px) {
      .bg-image.access {
        width: 64px;
      }
      .bg-image.format {
        width: 43px;
      }
      .bg-image.included {
        width: 60px;
      }
      .bg-image.passion {
        width: 46px;
      }
      .bg-image.one-on-one {
        width: 60px;
      }
      .bg-image.giving {
        width: 60px;
      }
    }
  }
  .description {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    color: ${({ theme: { colors } }) => colors.blueText};
  }
`
