import get from 'lodash/get'

export const parseDuration = params => {
  const { duration, ...rest } = params
  const durationFrom = get(duration, 'from')
  const durationTo = get(duration, 'to')

  return {
    ...rest,
    durationFrom,
    durationTo,
  }
}
