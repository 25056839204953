import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useHistory } from 'react-router';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import map from 'lodash/map';

import { FileType } from 'shared'

import { topicOptions } from './config';
import { emailPattern } from 'utils/services/validators/validators';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useFetch } from 'store/hooks/useFetch';
import { contactUs as contactUsApi } from 'api/appApi/oneDirectionRequests';
import { notify } from 'utils/notify';

// components
import { Row, Input } from '_legacy/common';
import TextArea from '_legacy/common/TextArea';
import Button from '_legacy/components/Button/Button';
import Select from '_legacy/common/Select/Common';
import PhoneWithAsyncValidation from '_legacy/common/Input/PhoneWithValidation';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'
import Uploader from '_legacy/common/UploaderFiles/Uploader';

const { FILE, PHOTO } = FileType

const submitAdapter = ({ topic, title, ...data }) => {
  return {
    ...data,
    files: map(get(data, 'files'), 'fileToken'),
    topic: topic.value,
    title: title || undefined,
  };
};

const Contact = () => {
  const t = useGetTranslate();
  const history = useHistory();
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      lastName: '',
      company: '',
      title: '',
      email: '',
      phone: '',
      topic: null,
      message: '',
    }
  });

  const { handleSubmit } = methods;
  const [{ data, isLoading }, contactUs] = useFetch(contactUsApi);

  const onSubmit = compose(contactUs, submitAdapter);

  React.useEffect(() => {
    if (data) {
      notify(t('contact.form.message.success'));
      history.push('/');
    }
  }, [data]);

  return (
    <FormProvider {...methods}>
      <form
        className="contact-form white-background default"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h1 className="contact-header">{t('contact.header.label')}</h1>

        <div className="contact-subheader">
          <h3>{t('contact.subheader.label')}</h3>
          <p>{t('contact.information.label')}</p>
        </div>

        <RequiredLabel />
        <Row
          nameBlock={t('contact.form.first-name.label')}
          isRequired
          component={
            <Input
              name="firstName"
              isRequired
              placeholder={t('contact.form.first-name.placeholder')}
              requiredMessage='contact.form.first-name.errors.required'
            />
          }
        />
        <Row
          nameBlock={t('contact.form.last-name.label')}
          isRequired
          component={
            <Input
              name="lastName"
              isRequired
              placeholder={t('contact.form.last-name.placeholder')}
              requiredMessage='contact.form.last-name.errors.required'
            />
          }
        />
        <Row
          nameBlock={t('contact.form.company.label')}
          component={
            <Input
              name="company"
              placeholder={t('contact.form.company.placeholder')}
            />
          }
        />
        <Row
          nameBlock={t('contact.form.title.label')}
          component={
            <Input
              name="title"
              placeholder={t('contact.form.title.placeholder')}
            />
          }
        />
        <Row
          nameBlock={t('contact.form.email.label')}
          isRequired
          component={
            <Input
              name='email'
              type='email'
              placeholder={t('contact.form.email.placeholder')}
              requiredMessage='contact.form.email.errors.required'
              isRequired
              validation={{
                pattern: {
                  value: emailPattern,
                  message: 'contact.form.email.errors.invalid-email'
                }
              }}
            />
          }
        />
        <Row
          nameBlock={t('contact.form.phone.label')}
          isRequired
          component={
            <PhoneWithAsyncValidation name='phone' isRequired />
          }
        />
        <Row
          nameBlock={t('contact.form.topic.label')}
          component={
            <Select
              name='topic'
              options={topicOptions(t)}
              rules={{ required: 'contact.form.topic.errors.required' }}
              placeholder={t('contact.form.topic.placeholder')}
            />
          }
        />
        <Row
          nameBlock={t('contact.form.message.label')}
          isRequired
          component={
            <TextArea
              name='message'
              placeholder={t('contact.form.message.placeholder')}
              maxLength={500}
              requiredMessage='contact.form.message.errors.required'
              isRequired
            />
          }
        />
        <Row
          nameBlock={t('contact.form.upload.label')}
          component={
            <Uploader
              name='files'
              titleHint={t('contact.form.upload.hint.label')}
              textHint={t('contact.form.upload.hint.text')}
              typesFiles={[FILE, PHOTO]}
            />
          }
        />
        <Row
          component={
            <Button
              disabled={isLoading}
              text={t('contact.form.button.submit')}
              typeButton="submit"
              paddingVertical="13"
              paddingHorizontal="56"
            />
          }
        />
        <MissedData />
      </form>
    </FormProvider>
  );
};

export default Contact;
