import React from 'react';
import filter from 'lodash/filter';
import pick from 'lodash/pick';
import map from 'lodash/map';
import get from 'lodash/get';

import Slide from '_legacy/components/TalentPublicView/DesktopSlider/Slide';
import LiveExperiences from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences';
import LiveVideoChats from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveVideoChats';
import VideoMessages from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/VideoMessages';
import LiveVirtualEvents from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveVirtualEvents';
import ReadyMadeEvents from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/ReadyMadeEvents';
import Lessons from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/Lessons';
import { COMMERCIAL_ENGAGEMENTS } from '_legacy/constants/routes';
import { getMedia, isVideo } from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveVirtualEvents/Desktop/LiveVirtualEvents';
import * as r from '_legacy/constants/routes';

import { getDateLabel } from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences/Desktop/LiveExperiences'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getFullMonthDate } from 'utils/date'

export const transformLiveExperiencesSlides = ({ liveExperiencesSlides, firstName, plaqueNickname, toggelPause }) => {
  const t = useGetTranslate()
  const _getDate = getDateLabel(t)
  if (liveExperiencesSlides.length === 0) {
    return [<LiveExperiences.Mobile.Empty key={'empty-live-experiences'} isEmpty={true} firstName={firstName} isMobile />];
  }

  return liveExperiencesSlides.map(slide => {
    const { id, photo, video, name, sequentialId, commercialUseOfExperiencefootage } = slide
    const path = `/${r.EXPERIENCES}/${plaqueNickname}/${sequentialId}`

    return (
      <Slide.MobileSlide
        key={id}
        toggelPause={toggelPause}
        file={video || photo}
        overlay={
          <LiveExperiences.Mobile.Overlay
            date={_getDate(slide)}
            name={name}
            isCommercial={commercialUseOfExperiencefootage ? commercialUseOfExperiencefootage : false}
            isVideo={slide?.isPromo ? photo?.file?.type : video?.file?.type}
            path={path}
            isPromo={slide.isPromo}
            hideControl={slide.hideControl}
          />
        }
      />
    );
  }
  );
};

export const transformVideoMessagesSlides = ({ videoMessagesSlides, videoMessagesOfferId, commercialVideoId, firstName, plaqueNickname, toggelPause }) => {
  if (videoMessagesSlides.length === 0) {
    return [<VideoMessages.Mobile.Empty key={'empty-video-messages'} isEmpty={true} firstName={firstName} isMobile />];
  }
  let totalVideoMessages = 1;
  videoMessagesSlides.forEach(item => {
    if(item.isPromo === true){
      totalVideoMessages = 2;
    }
  })
  return videoMessagesSlides.map((slide,index) =>
    <Slide.MobileSlide
      key={slide.file.id}
      file={slide}
      toggelPause={toggelPause}
      isPromo={slide.file.isPromo}
      overlay={
        <VideoMessages.Mobile.Overlay
          slide={slide}
          isPromo={slide.isPromo}
          hideControl={slide.hideControl}
          isVideo={slide?.file?.type}
          path= {slide.isCommercial === false ? `/${r.VIDEO_MESSAGE}/${plaqueNickname}/${videoMessagesOfferId}` :
          `/${plaqueNickname}/${COMMERCIAL_ENGAGEMENTS}/${commercialVideoId[index-totalVideoMessages]}` }
        />
      }
    />)
};

export const transformLiveVideoChatsSlides = ({ videoChatsSlides, videoChatsOfferId, firstName, plaqueNickname, toggelPause }) => {
  if (videoChatsSlides.length === 0) {
    return [<LiveVideoChats.Mobile.Empty key={'empty-live-video-chats'} isEmpty={true} firstName={firstName} isMobile />];
  }

  return videoChatsSlides.map((slide) =>
    <Slide.MobileSlide
      key={slide.id}
      file={slide}
      isPromo={slide.isPromo}
      toggelPause={toggelPause}
      overlay={
        <LiveVideoChats.Mobile.Overlay
          slide={slide}
          path={`/${r.VIDEO_CHAT}/${plaqueNickname}/${videoChatsOfferId}`}
          isVideo={slide?.file?.type}
          isPromo={slide.isPromo}
          hideControl={slide.hideControl}
        />
      }
    />);
};

export const transformLessonsSlides = ({ lessonsSlides, firstName, plaqueNickname, toggelPause }) => {
  if (lessonsSlides.length === 0) {
    return [<Lessons.Mobile.Empty key={'empty-lessons'} isEmpty={true} firstName={firstName} isMobile />];
  }

  return lessonsSlides.map(slide =>
    <Slide.MobileSlide
      isPromo={slide.isPromo}
      id={slide.id} key={slide.media.id} file={slide.media}
      toggelPause={toggelPause}
      overlay={
        <Lessons.Mobile.OverlayLessonsMobile
          name={slide.title}
          path={`/${r.LESSONS}/${plaqueNickname}`}
          isVideo={slide?.media?.file?.type}
          isPromo={slide.isPromo}
          hideControl={slide.hideControl}
        />
      }
    />);
};

export const transformReadyMadeEventsSlides = ({ readyMadeEvents, firstName, plaqueNickname, toggelPause }) => {
  if (readyMadeEvents.length === 0) {
    return [<ReadyMadeEvents.Mobile.Empty key={'empty-ready-made-event'} isEmpty={true} firstName={firstName} isMobile />];
  }

  return readyMadeEvents.map(event => {
    const { id, productName, sequentialId } = event

    return (
      <Slide.MobileSlide
        key={id}
        isPromo={event.isPromo}
        file={getMedia(event)}
        toggelPause={toggelPause}
        overlay={
          <ReadyMadeEvents.Mobile.Overlay
            name={productName}
            isVideo={isVideo(event)}
            path={`/${r.READY_MADE_EVENT_LESSON}/${plaqueNickname}/${sequentialId}`}
            isPromo={event.isPromo}
            hideControl={event.hideControl}
          />}
      />
    );
  })
}

export const transformLiveVirtualEventsSlides = ({ liveVirtualEvents, firstName, plaqueNickname, toggelPause }) => {
  const t = useGetTranslate()
  if (liveVirtualEvents.length === 0) {
    return [
      <LiveVirtualEvents.Mobile.Empty
        key={'empty-live-virtual-events'}
        isEmpty={true}
        firstName={firstName}
        isMobile
        hideControl
        customTitle={t('shared.words.coming-soon')}
      />
    ];
  }

  return liveVirtualEvents.map(event => {
    const { id, date, name, sequentialId } = event

    return (
      <Slide.MobileSlide
        key={id}
        isPromo={event.isPromo}
        toggelPause={toggelPause}
        file={getMedia(event)}
        path={`/${r.LIVE_VIRTUAL_EVENTS}/${plaqueNickname}/${sequentialId}`}
        overlay={<LiveVirtualEvents.Mobile.Overlay
          key={id}
          date={getFullMonthDate(date)}
          name={name}
          isVideo={isVideo(event)}
        />}
      />
    );
  })
};

export function getLessons(res) {
  const lessons = filter(pick(res, ['liveInPersonLesson', 'liveVirtualLesson', 'videoVoiceOverLesson']), 'id')
  return map(lessons, lesson => ({ media: get(lesson, 'media'), }))
}
