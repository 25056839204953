import React from 'react'

import LineString from '../common/LineString'

import { toMoneyWithComa } from 'utils/moneyTransformator'

const Amount = ({ isLoading, totalPrice }) => {
  return (
    <LineString
      isLoading={isLoading}
      className="bold centered"
      str={`$${toMoneyWithComa(totalPrice)} (USD)`}
    />
  )
}

export default Amount
