import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactCropper from 'react-easy-crop';
import { getCroppedImg } from './utils';

const Cropper = ({ image, aspect, onComplete, zoom, setZoom, cropShape, crop, setCrop, showGrid }) => {
  const onCrop = useCallback(async (_, croppedAreaPixels) => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      onComplete(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [image]);

  return (
    <ReactCropper
      image={image}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      onCropChange={setCrop}
      onCropComplete={onCrop}
      onZoomChange={setZoom}
      cropShape={cropShape}
      showGrid={showGrid}
      restrictPosition={false}
    />
  );
};

Cropper.propTypes = {
  image: PropTypes.any,
  aspect: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  onComplete: PropTypes.func,
  zoom: PropTypes.number,
  setZoom: PropTypes.func,
  cropShape: PropTypes.oneOf(['rect', 'round']),
  showGrid: PropTypes.bool,
  crop: PropTypes.object,
  setCrop: PropTypes.func,
};

export default Cropper;
