import { api } from 'shared';

const {
  LINK_NOT_FOUND,
  LINK_WAS_EXPIRED,
  LINK_ALREADY_USED,
  TALENT_ALREADY_HAS_USER,
  YOU_ALREADY_JOINED_THIS_TALENT,
  FORBIDDEN_TO_JOIN_AGENT_BEING_NOT_AN_AGENT,
} = api.talents.join.join.errors;

export const errors = {
  [LINK_ALREADY_USED]: 'talent-invitation.form.errors.link-not-found',
  [LINK_NOT_FOUND]: 'talent-invitation.form.errors.link-already-use',
  [LINK_WAS_EXPIRED]: 'talent-invitation.form.errors.link-was-expired',
  [TALENT_ALREADY_HAS_USER]: 'talent-invitation.form.errors.talent-already-has-user',
  [YOU_ALREADY_JOINED_THIS_TALENT]: 'talent-invitation.form.errors.you-already-joined-this.talent',
  [FORBIDDEN_TO_JOIN_AGENT_BEING_NOT_AN_AGENT]: 'talent-invitation.form.errors.forbidden-to-join-agent-being-not-an-agent',
};
