import React from 'react'

import * as r from '_legacy/constants/routes'

import ExperienceBookingsDetailsParticipants from '../components/ExperienceBookingsDetailsParticipants/'

export const defaultRoute = `/${r.BOOKINGS}/${r.EXPERIENCES}/${r.DETAILS}/:id`

export const getDetailsConfig = id => {
  return [
    {
      translation: 'order-details.menu.participants',
      pathname: `/${r.BOOKINGS}/${r.EXPERIENCES}/${r.DETAILS}/${id}/${r.PARTICIPANTS}`,
      pathComponent: `/${r.BOOKINGS}/${r.EXPERIENCES}/${r.DETAILS}/:orderId/${r.PARTICIPANTS}`,
      itemRef: React.useRef(),
      component: ExperienceBookingsDetailsParticipants,
    },
  ];
}
