import React from 'react'
import get from 'lodash/get'

import { ErrorMessage } from '_legacy/common';
import { optionsCardElement } from '_legacy/containers/Purchase/utils/stylesStripe';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const StripeFieldset = React.memo(({ nameOnCard, setNameOnCard, error, setError, CardElement, saveCard, setSaveCard }) => {
  const t = useGetTranslate()
  const errorCode = get(error, 'error.code')
  const classError = errorCode ? 'error' : ''

  return (
    <>
      <label className="payment-card__field">
        <span className="payment-card__label">{t('payment-page.form.field.name.label')}</span>
        <input
          type='text'
          className="payment-card__input"
          placeholder={t('payment-page.form.field.name.placeholder')}
          value={nameOnCard}
          onChange={({ target }) => setNameOnCard(target.value)}
        />
      </label>

      <label className="payment-card__field">
        <span className="payment-card__label">{t('payment-page.form.field.card.label')}</span>
        <CardElement
          options={optionsCardElement}
          className={`payment-card__input payment-card__card-info ${classError}`}
          onChange={setError}
        />
        {errorCode && <ErrorMessage className='_error-message' message={t(`payment-page.error.card.${errorCode}`)} />}
      </label>

      {setSaveCard && <label className="payment-card__check">
        <input
          type="checkbox"
          value={saveCard}
          onChange={({ target }) => setSaveCard(target.checked)}
        />
        <i />
        <p className="payment-card__check--text">{t('payment-page.card.label.save')}</p>
      </label>}
    </>
  )
})

export default StripeFieldset
