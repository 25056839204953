import React from 'react'
import cx from 'classnames'
import { types } from 'shared'
import { isEqual, omit } from 'lodash'
import { isFuture, isSameDay, set } from 'date-fns'
import { FormProvider, useForm } from 'react-hook-form'

import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useEditEvent } from '../../Calendar/TalentCalendar/state/requests/edit-event'
import { useDeleteEvent } from '../../Calendar/TalentCalendar/state/requests/delete-event'

import { fetchTalentPersonalCalendarBusinessHours } from '../requests/business-hourse'
import { fetchTalentCalendarSlots } from '../requests/talent-calendar'

import { getTalentId } from 'utils/user'

import BaseCalendar from '../BaseCalendar'
import Button from '_legacy/components/Button'
import Drawer from '../../Calendar/components/Drawer'
import MenuDots from '../../Calendar/components/MenuDots'
import InfoSidebar from '../../Calendar/components/InfoSidebar'
import NewEventForm from '../../Calendar/components/NewEventForm'
import CalendarHeader from '../../Calendar/components/CalendarHeader'
import EditEventModal from '../../Calendar/components/EditEventModal'
import DeleteEventModal from '../../Calendar/components/DeleteEventModal'
import WorkingHoursModal from '../../Calendar/components/WorkingHoursModal'
import CategoriesSidebar from '../components/CategoriesSidebar'

import { toDraftEvent } from '../utils/adapters'
import { calendarFilters, talentCalendarSidebarFilters } from '../configs/filters'
import { getHourFormat } from '../configs/hour-formats'
import { talentCalendarConfig } from '../configs/calendar'
import { getSlotBackground } from '../../Calendar/BaseCalendar/configs/slot-config'
import { timeSlotAdapter, useCreateEvent } from '../../Calendar/TalentCalendar/state/requests/create-event'
import { parseRecurring } from 'utils/forms/adapters/recurring'
import { getDrawerHeader } from '../../Calendar/TalentCalendar'
import { getEventTypeOption, getRecurringOption } from '../utils/form-utils'

const DOES_NOT_REPEAT = types.experiences.recurrence.RecurringDefinedInAdvanceValues.DOES_NOT_REPEAT


const TalentCalendar = () => {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)

  // ************ refs ************
  const calendarRef = React.useRef(null)
  const newEventFormDrawerRef = React.useRef(null)
  const infoSidebarDrawerRef = React.useRef(null)

  // ************ hooks ************
  const createEvent = useCreateEvent()
  const editEvent = useEditEvent()
  const deleteEvent = useDeleteEvent()

  // ************ modals ************
  const [isDeleteModalOpen, openDeleteModal, closeDeleteModal] = useModal(false)
  const [isEditModalOpen, openEditModal, closeEditModal] = useModal(false)
  const [isWorkingHoursModalOpen, openWorkingHoursModal, closeWorkingHoursModal] = useModal(false)

  // ************ calendar state ************
  const [slots, setSlots] = React.useState([])
  const prevSlots = React.useRef(slots)
  const [businessHours, setBusinessHours] = React.useState(null)
  const [filters, setFilters] = React.useState(calendarFilters)
  const [selectedSlotId, setSelectedSlotId] = React.useState(null)

  // ************ drawers ************
  const [isNewEventDrawerOpen, openNewEventDrawer, closeNewEventDrawer] = useModal(false)
  const [isInfoSidebarDrawerOpen, openInfoSidebarDrawer, closeInfoSidebarDrawer] = useModal(false)

  // ************ drawers handlers ************
  const closeNewEventDrawerHandler = React.useCallback(() => {
    setSelectedSlotId(null)
    setSlots(prevSlots.current)
    closeNewEventDrawer()
  }, [prevSlots.current])

  const closeInfoSidebarDrawerHandler = React.useCallback(() => {
    setSelectedSlotId(null)
    closeInfoSidebarDrawer()
  }, [])

  // ************ dot menu ************
  const [hour12Format, , , toggleHourFormat] = useModal(true)
  const [showWeekends, , , toggleShowWeekends] = useModal(true)

  const openListOptions = React.useMemo(() => [
    { label: 'calendar.dot-button.set-working-hours', cb: openWorkingHoursModal },
    { label: 'calendar.dot-button.hour-format', cb: toggleHourFormat },
    { label: 'calendar.dot-button.show-hide-weekends', cb: toggleShowWeekends },
  ], [])

  // ************ slots handlers ************
  const updateTimeSlotById = React.useCallback((slotId, updatedSlot) => {
    setSlots(prev => prev.map(slot => (slot.id === slotId ? { ...slot, ...updatedSlot } : slot)))
  }, [])

  const addTimeSlot = React.useCallback(slot => {
    setSlots(prev => [...prev, slot])
  }, [])

  const getSlotById = React.useCallback((slotId) => {
    return slots.find(slot => slot.id === slotId)
  }, [slots])

  // ************ fetch fns ************
  const fetchCalendarSlots = React.useCallback(async () => {
    const slots = await fetchTalentCalendarSlots({ talentId, filters })

    setSlots(slots)
    prevSlots.current = slots
  }, [talentId, filters])

  const fetchBusinessHours = React.useCallback(async () => {
    const businessHours = await fetchTalentPersonalCalendarBusinessHours({ talentId })

    setBusinessHours(businessHours)
  }, [talentId])

  // ************ form init ************
  const initialState = React.useMemo(() => ({
    date: null,
    startTime: null,
    endTime: null,
    eventType: '',
    title: '',
    recurring: getRecurringOption(t, DOES_NOT_REPEAT),
    'repeat-on': '',
  }), [])

  const methods = useForm({ mode: 'onChange', shouldUnregister: false, defaultValues: initialState })

  const formState = methods.watch()
  const prevForm = React.useRef(formState)

  // ************ calendar navigation handlers ************
  const onNextButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.next()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onPrevButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.prev()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onTodayButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.today()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  // ************ calendar events handlers ************
  const onAllow = React.useCallback(event => {
    return isFuture(event.start) && isSameDay(new Date(event.start), new Date(event.end))
  }, [])

  const onSelect = React.useCallback(event => {
    const calendar = calendarRef.current.getApi()
    calendar.unselect()

    closeInfoSidebarDrawer()
    openNewEventDrawer()

    if (getSlotById(selectedSlotId)?.editable) {
      methods.setValue('date', event.start)
      methods.setValue('startTime', event.start)
      methods.setValue('endTime', event.end)

      updateTimeSlotById(selectedSlotId, {
        start: event.start,
        end: event.end
      })
    } else {
      methods.reset({
        ...initialState,
        date: event.start,
        startTime: event.start,
        endTime: event.end,
      })

      const draftEventTimeSlot = toDraftEvent(event)
      addTimeSlot(draftEventTimeSlot)
      setSelectedSlotId(draftEventTimeSlot.id)
    }
  }, [selectedSlotId, slots])

  const onChange = React.useCallback(({ event }) => {
    methods.setValue('date', event.start)
    methods.setValue('startTime', event.start)
    methods.setValue('endTime', event.end)
  }, [])

  const onEventClick = React.useCallback(eventInfo => {
    if (!getSlotById(eventInfo.event.id)?.editable) {
      setSlots(prevSlots.current)
      closeNewEventDrawer()
      setSelectedSlotId(eventInfo.event.id)
      openInfoSidebarDrawer()
    }
  }, [prevSlots.current])

  // ************ form event handlers ************
  const onCreate = React.useCallback(formValues => {
    createEvent(formValues, () => {
      fetchCalendarSlots()
      closeNewEventDrawer()
      setSelectedSlotId(null)
    })
  }, [fetchCalendarSlots])

  const onEdit = React.useCallback(() => {
    editEvent({ slotId: selectedSlotId, data: omit(timeSlotAdapter(methods.getValues()), 'type') }, () => {
      fetchCalendarSlots()
      closeNewEventDrawer()
      closeEditModal()
      setSelectedSlotId(null)
    })
  }, [selectedSlotId, fetchCalendarSlots])

  const onDelete = React.useCallback(() => {
    deleteEvent({ slotId: selectedSlotId }, () => {
      fetchCalendarSlots()
      closeInfoSidebarDrawer()
      setSelectedSlotId(null)
      closeDeleteModal()
    })
  }, [fetchCalendarSlots, selectedSlotId])

  const onOpenEdit = React.useCallback(() => {
    closeInfoSidebarDrawer()
    openNewEventDrawer()
    updateTimeSlotById(selectedSlotId, { editable: true, overlap: false })
    const slot = getSlotById(selectedSlotId)

    methods.reset({
      date: new Date(slot.start),
      startTime: new Date(slot.start),
      endTime: new Date(slot.end),
      eventType: getEventTypeOption(t, slot.type),
      title: slot.title,
      recurring: getRecurringOption(t, slot.recurring?.type || DOES_NOT_REPEAT),
      ...parseRecurring(slot, t),
    })
  }, [selectedSlotId, slots])

  const onCancel = React.useCallback(() => {
    setSelectedSlotId(null)
    setSlots(prevSlots.current)
    closeNewEventDrawer()
  }, [prevSlots])

  // ************ effects ************
  React.useEffect(() => {
    if (getSlotById(selectedSlotId)?.editable && !isEqual(formState, prevForm.current)) {
      const startTime = formState.startTime || prevForm.current.startTime
      const endTime = formState.endTime || prevForm.current.endTime

      const newSlot = {
        start: set(new Date(formState.date), {
          hours: new Date(startTime).getHours(),
          minutes: new Date(startTime).getMinutes(),
        }).toISOString(),
        end: set(new Date(formState.date), {
          hours: new Date(endTime).getHours(),
          minutes: new Date(endTime).getMinutes(),
        }).toISOString(),
        title: formState.title,
        type: formState.eventType?.value, // todo: check that is needed
        borderColor: getSlotBackground(formState.eventType?.value),
        backgroundColor: getSlotBackground(formState.eventType?.value)
      }

      updateTimeSlotById(selectedSlotId, newSlot)
      prevForm.current = formState
    }
  }, [selectedSlotId, formState])

  React.useEffect(fetchCalendarSlots, [fetchCalendarSlots, filters, talentId])

  React.useEffect(fetchBusinessHours, [fetchBusinessHours, talentId])

  return (
    <FormProvider {...methods}>
      <div className="talent-calendar">
        <CalendarHeader text={t('calendar.talent.heading')} />

        <Button
          className={'talent-calendar__add-event'}
          text={t('calendar.button.add-event')}
          handleOnClick={() => {}}
          disabled={!slots}
        />
        <div className="talent-calendar__wrapper">
          <div className="talent-calendar__sidebar">
            <CategoriesSidebar options={talentCalendarSidebarFilters} filters={filters} setFilters={setFilters} />

            <Drawer
              ref={newEventFormDrawerRef}
              isOpen={isNewEventDrawerOpen}
              close={closeNewEventDrawerHandler}
              headerLabel={t(getDrawerHeader(getSlotById(selectedSlotId)))}
            >
              <NewEventForm
                talentId={talentId}
                recurringId={getSlotById(selectedSlotId)?.recurringId}
                onCancel={onCancel}
                showTypeSelect={!getSlotById(selectedSlotId)?.createdAt}
                showSelectDateFormInitialState={getSlotById(selectedSlotId)?.createdAt}
                onSubmit={getSlotById(selectedSlotId)?.createdAt ? openEditModal : onCreate}
                submitBtnText={getSlotById(selectedSlotId)?.createdAt && t('calendar.new-event-form.button.save')}
                hour12Format={hour12Format}
              />
            </Drawer>

            <Drawer
              ref={infoSidebarDrawerRef}
              isOpen={isInfoSidebarDrawerOpen}
              close={closeInfoSidebarDrawerHandler}
              headerLabel={t(getDrawerHeader(getSlotById(selectedSlotId)))}
            >
              <InfoSidebar
                {...getSlotById(selectedSlotId)}
                onEdit={onOpenEdit}
                onDelete={openDeleteModal}
                hour12Format={hour12Format}
              />
            </Drawer>
          </div>

          <div className="talent-calendar__wrapper">
            <div className={cx('talent-calendar_inner', { ['drawer-opened']: isInfoSidebarDrawerOpen || isNewEventDrawerOpen })}>
              <div className="calendar calendar--pr32">
                <MenuDots optionsList={openListOptions} />
                <BaseCalendar
                  slotEventOverlap={false}
                  events={slots}
                  businessHours={businessHours}
                  weekends={showWeekends}
                  customButtons={{
                    prev: { click: onPrevButton },
                    next: { click: onNextButton },
                    today: { click: onTodayButton, text: t('calendar.talent.today-btn') },
                  }}
                  ref={calendarRef}
                  select={onSelect}
                  eventChange={onChange}
                  eventAllow={onAllow}
                  selectAllow={onAllow}
                  eventClick={onEventClick}
                  {...getHourFormat(hour12Format)}
                  {...talentCalendarConfig}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <WorkingHoursModal
        defaultData={businessHours}
        isOpen={isWorkingHoursModalOpen}
        closeModal={closeWorkingHoursModal}
      />
      <DeleteEventModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal} deleteHandler={onDelete} />
      <EditEventModal isOpen={isEditModalOpen} onClose={closeEditModal} editHandler={onEdit} />
    </FormProvider>
  )
}

export default TalentCalendar
