import Orders from './Orders'
import Type from './Type'
import Talent from './Talent'
import Occurrence from './Occurrence'
import Location from './Location'
import Booked from './Booked'
import Status from '../manage-orders/Status'

export const MY_BOOKINGS = 'my-bookings'
export const ORDER = 'order'
export const TYPE = 'type'
export const TALENT = 'talent'
export const OCCURRENCE = 'occurrence'
export const LOCATION = 'location'
export const BOOKED = 'booked'
export const STATUS = 'status'

export const elementsTypes = {
  [ORDER]: ORDER,
  [TYPE]: TYPE,
  [TALENT]: TALENT,
  [OCCURRENCE]: OCCURRENCE,
  [LOCATION]: LOCATION,
  [BOOKED]: BOOKED,
  [STATUS]: STATUS,
}

export const myBookingsComponents = {
  [ORDER]: Orders,
  [TYPE]: Type,
  [TALENT]: Talent,
  [OCCURRENCE]: Occurrence,
  [LOCATION]: Location,
  [BOOKED]: Booked,
  [STATUS]: Status
}
