import React from 'react'
import cx from 'classnames'

import eventPath from 'utils/eventPath'
import Icon from 'assets/icons/Icon';

import './index.scss'

export default function Dropdown({
  className = '', label, children, isDropdownOpen, setIsDropdownOpen
}) {
  const ref = React.useRef()
  const [isLocalOpen, setIsLocalOpen] = React.useState(false)

  const isOpen = typeof isDropdownOpen !== 'undefined' ? isDropdownOpen : isLocalOpen
  const setIsOpen = typeof setIsDropdownOpen !== 'undefined' ? setIsDropdownOpen : setIsLocalOpen

  const closeMenu = React.useCallback((e) => {
    if (eventPath(e).includes(ref.current)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const classes = cx('dropdown-wrapper', className, { 'active': isOpen })

  return (
    <div
      ref={ref}
      className={classes}
    >
      <div
        className='label-wrapper'
        onClick={() => setIsOpen(current => !current)}
      >
        <div className='dropdown-label'>
          {label}
        </div>
        <Icon.ArrowDownSelectMenu />
      </div>

      {isOpen && children}
    </div>
  )
}
