import React from 'react'
import * as r from '_legacy/constants/routes'
import ReadyMadeEventLessonsDetailsOrdersTable from '../components/ReadyMadeEventLessonsDetailsOrdersTable'
import ReadyMadeEventLessonsDetailsProductTable from '../components/ReadyMadeEventLessonsDetailsProductTable'


export const defaultRoute = `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/:orderId`

export const getMenuItems = orderId => {
  return [
    {
      translation: 'ready-made-event-lesson-details.menu.orders',
      pathname: `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/${orderId}/${r.ORDERS}`,
      pathComponent: `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/:orderId/${r.ORDERS}`,
      itemRef: React.useRef(),
      component: ReadyMadeEventLessonsDetailsOrdersTable,
    },
    {
      translation: 'ready-made-event-lesson-details.menu.product',
      pathname: `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/${orderId}/${r.PRODUCT}`,
      pathComponent: `/${r.ORDERS}/${r.READY_MADE_EVENT_LESSON}/${r.DETAILS}/:orderId/${r.PRODUCT}`,
      itemRef: React.useRef(),
      component: ReadyMadeEventLessonsDetailsProductTable,
    },
  ];
}
