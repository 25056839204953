import map from 'lodash/map'

const sortFields = {
  occurrenceOfferType: 'table.header.offering',
  createdAt: 'table.header.date-time',
  rating: 'table.header.review',
  to: 'table.header.to',
}

const sortFieldOptions = map(sortFields, (label, value) => ({ value, label }))

export const counterConfig = {
  field: 'count',
  label: 'table.filters.review',
  pluralLabel: 'table.filters.reviews',
}

export const subFiltersConfig = [
  {
    label: 'table.filters.sort-by',
    field: 'sortField',
    options: sortFieldOptions,
  },
  {
    label: 'table.filters.show',
    field: 'limit',
    options: [
      { value: 10, label: '10' },
      { value: 25, label: '25' },
      { value: 50, label: '50' },
    ],
  },
]

const additionalFields = ['sortOrder']

export const fields = [...additionalFields, ...map(subFiltersConfig, 'field')]
