import React from 'react'
import prop from 'lodash/fp/prop'

import AvatarWithName from '../common/AvatarWithName'

export default React.memo(function InvitedUser({
  isLoading,
  toUser,
  toTalent,
  fromUser,
  fromTalent,
}) {
  const user = toUser || toTalent || fromUser || fromTalent
  const id = prop('id', user)
  const firstName = prop('firstName', user)
  const lastName = prop('lastName', user)
  const profileImage = prop('profileImage', user)

  return (
    <AvatarWithName
      id={id}
      isLoading={isLoading}
      firstName={firstName}
      lastName={lastName}
      image={profileImage}
    />
  )
})
