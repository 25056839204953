import React from 'react'
import compose from 'lodash/fp/compose'

import normalizeParams from 'utils/normalizeParams'
import {
  getTalents as getTalentsApi,
  getTalentTopLocation as getTalentTopLocationApi
} from 'api/appApi/search/talent'
import { useSetSearchList, useSetSearchListFilters } from 'store/hooks/globalState/useSearchList';
import { useSetTalentTopLocation } from 'store/hooks/globalState/useLocationList'

import { parseRadius } from '_legacy/components/Table/Filters/utils/radius'
import { parseLocation } from '_legacy/components/Table/Filters/utils/location'
import { parseFollowers } from '_legacy/components/Table/Filters/utils/followers'
import { parseTalentCategoryIn } from '_legacy/components/Table/Filters/utils/categoryIn'
import { parseSort } from '_legacy/components/Table/Filters/utils/sort'
import { getLimitByResolution } from 'utils/getLimitByResolution';
import { useWindowDimensions } from 'store/hooks';

const normalizer = compose(
  normalizeParams,
  parseFollowers,
  parseRadius,
  parseLocation,
  parseTalentCategoryIn,
  parseSort
)

export function useFetchSearchItems({ isMobile }) {
  const setSearchList = useSetSearchList((prev, next) => next)
  const mergeSearchList = useSetSearchList((prev, next = []) => ([...(prev || []), ...next]))
  const setSearchListFilters = useSetSearchListFilters((prev, next) => ({ ...prev, ...next }))
  const { width } = useWindowDimensions()

  return React.useCallback(({ ...query }) => {
    const params = normalizer(query)
    getTalentsApi({
      query: {
        limit: getLimitByResolution(width),
        fields: ['id', 'firstName', 'lastName', 'nickname'],
        relations: 'halfBodyImage',
        ...params,
      },
    }).then(({ talents = [], pageInfo }) => {
      if (isMobile && 1 < pageInfo?.page) {
        mergeSearchList(talents)
      } else {
        setSearchList(talents)
      }
      setSearchListFilters(pageInfo)
    })
  },
  [setSearchListFilters]
  )
}

export function useFetchTalentTopLocations() {
  const setTalentTopLocation = useSetTalentTopLocation((prev, next) => next)
  return React.useCallback(() => {
    getTalentTopLocationApi({ query: { limit: 5 } })
      .then(({ data: { topLocations } }) => {
        setTalentTopLocation(topLocations)
      })
  }, [setTalentTopLocation])
}

