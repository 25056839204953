export const OFFERING = 'offering'
export const REVIEW = 'review'
export const USER = 'user'
export const REVIEW_DATE = 'review-date'

export const elementsTypes = {
  [OFFERING]: OFFERING,
  [REVIEW]: REVIEW,
  [USER]: USER,
  [REVIEW_DATE]: REVIEW_DATE,
}

export const REVIEWS = 'reviews'

import ReviewDate from './ReviewDate'
import Review from './Review'
import Offering from './Offering'
import User from './User'

export const reviewsComponents = {
  [OFFERING]: Offering,
  [USER]: User,
  [REVIEW_DATE]: ReviewDate,
  [REVIEW]: Review,
}
