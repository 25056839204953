import React from 'react';
import PropTypes from 'prop-types';

import { Row } from '_legacy/common';
import LanguageSelect from '_legacy/components/Form/Selects/LanguageSelect';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Languages = ({ name = 'languages' }) => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('register.languages.label')}
      isRequired
      component={<LanguageSelect name={name} className='languages' isRequired />}
    />
  );
};

Languages.propTypes = {
  name: PropTypes.string,
};

export default Languages;
