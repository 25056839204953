import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Icon from 'assets/icons/Icon'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

function Description({ items }) {
  const t = useGetTranslate()
  return (
    <StyledItemsWrapper>
      {items.map(item => (
        <StyledItem key={item}>
          <Icon.ArrowSliderNext />
          {t(item)}
        </StyledItem>
      ))}
    </StyledItemsWrapper>
  )
}

const StyledItemsWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 13px 20px;

  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    margin-left: 12px;
    grid-row-gap: 0;
  }

  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    margin-left: 12px;
    grid-row-gap: 0;
  }
`

const StyledItem = styled.li`
  align-items: center;
  height: 35px;
  margin: 0 0 0 4px;
  font-family: Montserrat;
  font-size: 16px;
  color: #babbcd;
  text-align: left;

  display: flex;

  svg {
    width: 7px;
    height: 11px;
    margin: 2px 10px;
  }

  @media only screen and (max-width: 480px) {
    width: auto;
  }
`

Description.propTypes = {
  items: PropTypes.array.isRequired
}

export default Description
