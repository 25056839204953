import React from 'react'
import { get, noop } from 'lodash'
import { useParams } from 'react-router-dom';
import { errorNames,  } from 'shared'

import {
  createExperience as createExperienceApi,
  createExperienceDraft as createExperienceDraftApi,
  getExperienceById as getExperienceByIdApi,
  getExperienceDraftById as getExperienceDraftByIdApi,
  updateExperienceById as updateExperienceByIdApi,
  updateExperienceDraftById as updateExperienceDraftByIdApi
} from 'api/appApi/offers/experience'
import {
  publishOffer as publishOfferApi
} from 'api/appApi/offers/offers'
import { notify } from 'utils/notify'

import { OFFERINGS } from '_legacy/constants/routes'

import { useAddFilesToLibrary } from 'store/hooks/globalState/useFileLibrary'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useTalentId } from 'store/hooks/globalState/useUser'
import { useSetExperience } from 'store/hooks/globalState/offerings/experience'
import { useNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { PROMOTIONAL_MEDIA_ASK_MODAL } from '_legacy/components/Modal/modals-types';
import { useStateModal } from 'store/hooks/globalState/useModal';

export function useCreateExperience(setLoading = noop, methods = noop, options = {}) {
  const t = useGetTranslate();
  const talentId = useTalentId()
  const navigate = useNavigate()
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL)
  const { openExtraModal } = options

  return React.useCallback((data) => {
    setLoading(true)
    createExperienceApi({ data, talentId })
      .then(({ isPromotionalMediaNeeded }) => {
        if (isPromotionalMediaNeeded) {
          notify(t('video-message.form.message.create.successfully'))
          openAskModal({ openExtraModal })
        } else {
          navigate(`/${OFFERINGS}`, t('create-experience.form.notify.publish.success'))
          openExtraModal()
        }
      })
      .catch(err => {
        if (methods && err.name === errorNames.calendar.TIME_SLOT_OVERLAP) {
          methods.setError('dateOfExperience', { message: t('create-experience.form.errors.event-overlaps') })
        }

        console.warn(err)
      })
      .finally(() => setLoading(false))
  }, [t, talentId, navigate, openAskModal])
}

export function useCreateExperienceDraft() {
  const t = useGetTranslate();
  const talentId = useTalentId()
  const navigate = useNavigate()

  return React.useCallback((data) => {
    createExperienceDraftApi({ data, talentId })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('create-experience.form.notify.draft.success'))
      })
      .catch(err => {
        console.warn(err);
      });
  }, [t, talentId]);
}

export function useFetchExperience() {
  const { id } = useParams()
  const talentId = useTalentId()
  const setExperience = useSetExperience((_, next) => next)
  const addFilesToFileLibrary = useAddFilesToLibrary()

  return React.useCallback(() => {
    getExperienceByIdApi({ talentId, experienceId: id })
      .then(({ experience }) => {
        const photo = get(experience, 'file.photo')
        const video = get(experience, 'file.video')
        addFilesToFileLibrary([photo, video])
        setExperience(experience)
      })
      .catch(err => {
        console.warn(err)
      })
  }, [id, talentId, setExperience, addFilesToFileLibrary])
}

export function useFetchExperienceDraft() {
  const { id } = useParams()
  const talentId = useTalentId()
  const setExperience = useSetExperience((_, next) => next)
  const addFilesToFileLibrary = useAddFilesToLibrary()

  return React.useCallback(() => {
    getExperienceDraftByIdApi({ talentId, experienceDraftId: id })
      .then(({ experienceDraft }) => {
        const photo = get(experienceDraft, 'photo')
        const video = get(experienceDraft, 'video')
        addFilesToFileLibrary([photo, video])
        setExperience(experienceDraft)
      })
      .catch(err => {
        console.warn(err)
      })
  }, [id, talentId, setExperience, addFilesToFileLibrary])
}

export function useUpdateExperience(setLoading = noop, methods = noop) {
  const { id } = useParams()
  const talentId = useTalentId()
  const setExperience = useSetExperience((_, next) => next)
  const navigate = useNavigate()
  const t = useGetTranslate();

  return React.useCallback((data) => {
    setLoading(true)

    updateExperienceByIdApi({ talentId, experienceId: id, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('create-experience.form.notify.update.success'))
      })
      .catch(err => {
        if (methods && err.name === errorNames.calendar.TIME_SLOT_OVERLAP) {
          methods.setError('dateOfExperience', { message: t('create-experience.form.errors.event-overlaps') })
        }

        console.warn(err)
      })
      .finally(() => setLoading(false))
  }, [id, talentId, setExperience, t, navigate])
}

export function useUpdateExperienceDraft() {
  const { id } = useParams()
  const talentId = useTalentId()
  const setExperience = useSetExperience((_, next) => next)
  const navigate = useNavigate()
  const t = useGetTranslate();

  return React.useCallback((data) => {
    updateExperienceDraftByIdApi({ talentId, experienceDraftId: id, data })
      .then(() => {
        navigate(`/${OFFERINGS}`, t('create-experience.form.notify.draft.success'))
      })
      .catch(err => {
        console.warn(err)
      })
  }, [id, talentId, setExperience, navigate, t])
}

export function usePublishExperienceDraft() {
  const { id } = useParams()
  const t = useGetTranslate();
  const talentId = useTalentId()
  const navigate = useNavigate()
  const openAskModal = useStateModal(PROMOTIONAL_MEDIA_ASK_MODAL)

  return React.useCallback(async (data) => {
    try {
      const { experienceDraft, isPromotionalMediaNeeded } = await updateExperienceDraftByIdApi({ talentId, experienceDraftId: id, data })
      await publishOfferApi({ offerId: experienceDraft.id, talentId })

      if (isPromotionalMediaNeeded) {
        notify(t('video-message.form.message.create.successfully'))
        openAskModal()
      } else {
        navigate(`/${OFFERINGS}`, t('create-experience.form.notify.publish.success'))
      }
    } catch (err) {
      console.warn(err)
    }
  }, [id, talentId, talentId, t, openAskModal])
}
