import { _post } from '../../utils';

export const identityVerification = ({
  firstName,
  lastName,
  email,
  governmentIssuedFile,
  photoFile
}) => {
  const formData = new FormData();

  formData.append('governmentIssuedFile', governmentIssuedFile);
  formData.append('photoFile', photoFile);

  return _post(
    '/verify/identity-verification/request',
    {
      query: { firstName, lastName, email },
      body: formData
    }
  );
};
