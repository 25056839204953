import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// components
import ErrorMessage from '../ErrorMessages';

const TextAreaCommon = ({
  label,
  placeholder,
  name,
  max = 150,
  register,
  height = 150,
  tip,
  watch,
  error,
  labelClassName,
  labelContainerClassName,
  containerClassName
}) => {
  const t = useGetTranslate()
  const value = watch(name, '');
  const classes = cx('text-area-group__field', { error: error });
  const containerClasses = cx('text-area-group', containerClassName);
  const labelContainerClasses = cx('text-area-group__label-container', labelContainerClassName);
  const labelClasses = cx('text-area-group__label', labelClassName);

  return (
    <div className={containerClasses} style={{ position: 'relative' }}>
      <label>
        <div className={labelContainerClasses}>
          {label && <span className={labelClasses}>{label}</span>}
        </div>
        <textarea
          className={classes}
          placeholder={placeholder}
          name={name}
          ref={register}
          style={{ height }}
        />
        <span className="text-area-group__tip">
          {value.length} / {max} {tip || t('shared.words.character.plural')}
        </span>
      </label>
      {error && <ErrorMessage message={error.message}/>}
    </div>
  );
};

TextAreaCommon.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  register: PropTypes.func,
  height: PropTypes.number,
  tip: PropTypes.string,
  watch: PropTypes.func,
  error: PropTypes.object,
  labelClassName: PropTypes.string,
  labelContainerClassName: PropTypes.string,
  containerClassName: PropTypes.string,
};

export default TextAreaCommon
