import React from 'react'
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import pick from 'lodash/pick';
import prop from 'lodash/fp/prop'
import { enUS, es, pt } from 'date-fns/locale';

import {
  createUseGlobalState,
  createUseSetGlobalState,
} from './useGlobalState';
import { TaxpayerStatus } from 'shared';
import { useFetchEditProfile } from 'requests/edit-profile';
import { useEditProfile } from './useEditProfile';
import { useStateModal } from './useModal';
import { REQUIRED_PAYMENTS_INFORMATION_MODAL } from '_legacy/components/Modal/modals-types';

const USER = 'user'
const IS_USER_LOADING = 'isUserLoading'


export const useIsUserLoading = createUseGlobalState(IS_USER_LOADING);
export const useSetIsUserLoading = createUseSetGlobalState(IS_USER_LOADING);


export const useUser = createUseGlobalState(USER);
export const useSetUser = createUseSetGlobalState(USER);

export const useIsUserLoggedIn = () => {
  const user = useUser();
  return !isEmpty(user);
};

export const useIsUserHasTalent = () => {
  return !isEmpty(useUser(state => get(state, 'talent')))
}

export const useIsTalentHasUser = () => {
  return !isEmpty(useUser(state => get(state, 'talent.user')))
}

export const useTalentId = () => {
  return useUser(state => get(state, 'talent.id'));
}

export const useTalentLibraryId = () => {
  return useUser(state => get(state, ['talent', 'fileLibrary', 'id']))
}

export const userUserData = (key, defaultValue) => {
  return useUser(state => get(state, key, defaultValue));
}

export const useUserType = () => {
  return useUser(state => get(state, 'user.type'))
}

export const useUserFullName = () => {
  const user = userUserData('user');
  const { firstName, lastName } = pick(user, ['firstName', 'lastName']);
  return `${firstName || ''} ${lastName || ''}`.trim();
}


export const useTalentFullName = () => {
  const talent = userUserData('talent');
  const { firstName, lastName } = pick(talent, ['firstName', 'lastName']);
  return `${firstName || ''} ${lastName || ''}`.trim();
}

export const useUserLocale = () => {
  const userLang = useUser(prop('user.settings.language'))
  return {
    EN: enUS,
    ES: es,
    PT: pt
  }[userLang] || enUS
}

export const usePlaqueNickname = () => {
  return useUser(state => get(state, 'talent.plaqueNickname'))
}

export const withUser = Component => props => {
  const user = useUser()
  return <Component {...props} user={user} />
}

export const useRequiredTaxInfoModalHandler = () => {
  const { talent } = useUser()
  const { socialSecurityOrTIN, taxpayerStatus, licenses } = useEditProfile(prop('data.user')) || {}
  const fetchEditProfile = useFetchEditProfile()
  const openRequiredPaymentsInfoModal = useStateModal(REQUIRED_PAYMENTS_INFORMATION_MODAL)

  React.useEffect(()=>{
    fetchEditProfile()
  }, [talent.id])

  const isRequiredTaxInfoCompleted = React.useMemo(() => {
    const hasSocialSecurityOrTIN = !!socialSecurityOrTIN

    if(taxpayerStatus === TaxpayerStatus.US) {
      const hasLicenses = licenses?.length > 0

      return hasLicenses && hasSocialSecurityOrTIN
    }

    return hasSocialSecurityOrTIN
  }, [socialSecurityOrTIN, taxpayerStatus, licenses])

  return React.useCallback(() => {
    if(!isRequiredTaxInfoCompleted)
      openRequiredPaymentsInfoModal()
  }, [isRequiredTaxInfoCompleted])
}
