import React from 'react'
import { string } from 'prop-types'
import { useForm, FormProvider } from 'react-hook-form'

import TextAreaField from '_legacy/common/Textarea/TextAreaField'
import BlockWithBorder from '_legacy/common/Template/BlockWithBorder'
import Field from './component/shared/Field'
import Email from './component/shared/Email'
import Phone from './component/shared/Phone'
import Checkbox from './component/shared/Checkbox'
import Industry from './component/Company/Industry'
import LegalTermsBlock from './component/LegalTerms'
import SubmitButton from './component/SubmitButton'
import HappensBlock from './component/HappensBlock'
import RequiredLabel from './component/shared/RequiredLabel'
import MissedData from '_legacy/components/RegisterForms/shared/components/MissedData'

import submitAdapter from './utils/submitAdapter'
import defaultValues from './utils/defaultValues'
import { happensBlockConfig } from './utils/config'
import { useBookOffer } from 'requests/offerings/book'
import { COMMERCIAL_VIDEO_MESSAGE } from '_legacy/constants/routes'


const CommercialEngagementsRequestForm = ({ offerId, fullName, currentCommercialVideo }) => {
  const [loader, setLoader] = React.useState(false)
  const bookVideoMessage = useBookOffer(setLoader)
  const methods = useForm({
    defaultValues, mode: 'onChange', criteriaMode: 'all'
  })

  const onSubmit = data => bookVideoMessage({ offerId, data: submitAdapter(data), offeringType: COMMERCIAL_VIDEO_MESSAGE })

  return (
    <FormProvider {...methods}>
      <form className="commercial-engagements-request-form white-background" onSubmit={methods.handleSubmit(onSubmit)}>
        <BlockWithBorder title='request-video-mes-commercial.company.title'>
          <RequiredLabel />
          <Email
            name='company.toEmail'
            label='request-video-mes-commercial.company.email.label'
            placeholder='request-video-mes-commercial.company.email.placeholder'
            isRequired
          />
          <Field
            name='company.name'
            label='request-video-mes-commercial.company.name.label'
            placeholder='request-video-mes-commercial.company.name.placeholder'
            requiredMessage='request-video-mes-commercial.company.name.error'
            isRequired
          />
          <Industry />
          <Field
            name='company.productType'
            label='request-video-mes-commercial.company.product-type.label'
            placeholder='request-video-mes-commercial.company.product-type.placeholder'
            requiredMessage='request-video-mes-commercial.company.product-type.error'
            isRequired
          />
          <Field
            name='company.productName'
            label='request-video-mes-commercial.company.product-name.label'
            placeholder='request-video-mes-commercial.company.product-name.placeholder'
          />
          <Phone name='company.phone' />
          <Checkbox name='company.phoneDeliverVersion' />
        </BlockWithBorder>

        <BlockWithBorder title='request-video-mes-commercial.requestor.title'>
          <RequiredLabel />
          <Field
            name='requestor.name'
            label='request-video-mes-commercial.requestor.name.label'
            placeholder='request-video-mes-commercial.requestor.name.placeholder'
            requiredMessage='request-video-mes-commercial.requestor.name.error'
            isRequired
          />
          <Field
            name='requestor.title'
            label='request-video-mes-commercial.requestor.block-title.label'
            placeholder='request-video-mes-commercial.requestor.block-title.placeholder'
            requiredMessage='request-video-mes-commercial.requestor.block-title.error'
            isRequired
          />
          <Email
            name='requestor.fromEmail'
            label='request-video-mes-commercial.requestor.email.label'
            subLabel='request-video-mes-commercial.requestor.email.sublabel'
            placeholder='request-video-mes-commercial.requestor.email.placeholder'
            isRequired
          />
          <Checkbox name='requestor.fromEmailDeliverVersion' />
          <Phone name='requestor.phone' />
          <Checkbox name='requestor.phoneDeliverVersion' />
        </BlockWithBorder>

        <BlockWithBorder title='request-video-mes-commercial.message.title'>
          <RequiredLabel />
          <Field
            name='message.productDescription'
            label='request-video-mes-commercial.message.product.label'
            placeholder='request-video-mes-commercial.message.product.placeholder'
            requiredMessage='request-video-mes-commercial.message.product.error'
            isRequired
          />
          <TextAreaField
            name='message.script'
            maxLength={500}
            label='request-video-mes-commercial.message.script.label'
            placeholder='request-video-mes-commercial.message.script.placeholder'
            requiredMessage='request-video-mes-commercial.message.script.error'
            isRequired
          />
          <TextAreaField
            name='message.otherInstructions'
            maxLength={500}
            label='request-video-mes-commercial.message.other.label'
            placeholder='request-video-mes-commercial.message.other.placeholder'
          />

          <LegalTermsBlock currentCommercialVideo={currentCommercialVideo}/>

          <SubmitButton loader={loader} />

          <MissedData />
        </BlockWithBorder>

        <HappensBlock fullName={fullName} config={happensBlockConfig} />

      </form>
    </FormProvider>
  )
}

CommercialEngagementsRequestForm.propTypes = {
  offerId: string,
  fullName: string,
}

export default CommercialEngagementsRequestForm
