import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { Row } from '_legacy/common/Template'
import { Checkbox } from '_legacy/common/Checkbox'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'
import CommunicationsPreferenceInfoModal from '_legacy/components/Modal/CommunicationsPreferenceInfoModal'

const nameEmail = 'notificationPreferences.email'
const nameSms = 'notificationPreferences.sms'

const CommunicationsPreference = React.memo(function CommunicationsPreference() {
  const t = useGetTranslate()

  return (
    <NotificationWrapper
      nameBlock={t('register-aficionado.form.communications-preference.label')}
      tip={<TipButton modalComponent={CommunicationsPreferenceInfoModal} />}
      component={
        <CommunicationPreferenceTemplate>
          <Checkbox
            label={t('register-aficionado.form.communications-preference.email')}
            name={nameEmail}
          />
          <Checkbox
            label={t('register-aficionado.form.communications-preference.sms')}
            name={nameSms}
          />
        </CommunicationPreferenceTemplate>
      }
    />
  )
})

const NotificationWrapper = styled(Row)`
  .row-left {
    padding-top: 0;
  }
`

const CommunicationPreferenceTemplate = styled.div`
  display: flex;
  padding-top: 12px;

  label {
    margin-right: 48px;
  }
`

export default CommunicationsPreference
