import React from 'react';
import { string } from 'prop-types';
import TalentInfoBlockTab from '../TalentInfoBlockTab/';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const TalentInfoBlockHometown = ({ city, state, country }) => {
  const t = useGetTranslate()
  return (
    <TalentInfoBlockTab
      header={t('talent-public-view.info-block.hometown')}
      info={
        (city || state)
          ? `${city}, ${state || country}`
          : t('talent-public-view.info-block.not-specified')
      }
    />
  );
};

TalentInfoBlockHometown.propTypes = {
  city: string.isRequired,
  state: string.isRequired,
};

export default TalentInfoBlockHometown;
