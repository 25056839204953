import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import cx from 'classnames'

import { ErrorMessage } from '_legacy/common/ErrorMessage';

import './index.scss'

export const StyledRadioButton = ({ children, dark, ...props }) => {
  return (
    <label className={cx('radio-buttons-item', { 'radio-buttons-item--dark': dark })}>
      <input className="radio-buttons-item__input" type="radio" {...props} />
      <span className="radio-buttons-item__label">{children}</span>
    </label>
  )
}

export const RadioButton = ({ label, name, value, disabled = false, subtitle, customValidation }) => {
  const { register } = useFormContext();
  return (
    <label className="radio-buttons-item">
      <input
        className="radio-buttons-item__input"
        ref={register(customValidation)}
        type="radio"
        name={name}
        value={value}
        disabled={disabled}
      />
      <span className="radio-buttons-item__label">{label}</span>
      {subtitle && (
        <span className="radio-buttons-item__sub-title">&nbsp;{subtitle}</span>
      )}
    </label>
  );
};

export const RadioButtons = ({ name, options = [], customValidation = {}, disabled }) => {
  const { errors } = useFormContext();
  return (
    <>
      <div className="radio-buttons">
        {options.map((option, index) => (
          <RadioButton
            key={index}
            customValidation={customValidation}
            {...option}
            name={name}
            disabled={disabled}
          />
        ))}
      </div>
      {errors?.[name] && <ErrorMessage message={errors?.[name].message} />}
    </>
  );
};

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
  })).isRequired,
  customValidation: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

RadioButton.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  disabled: PropTypes.bool,
};

