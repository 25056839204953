import { CalendarSlotType, TalentOffersType } from 'shared'
import { getSlotBackground } from 'components/Calendar/BaseCalendar/configs/slot-config'

export const filters = [
  {
    field: 'occurrenceTypeIn',
    label: 'calendar.filters.offerings',
    options: [
      {
        value: TalentOffersType.IN_PERSON_EXPERIENCE,
        label: 'calendar.filters.in-person-experience',
        color: getSlotBackground(TalentOffersType.IN_PERSON_EXPERIENCE),
      },
      {
        value: TalentOffersType.VIRTUAL_EVENT,
        label: 'calendar.filters.virtual-event',
        color: getSlotBackground(TalentOffersType.VIRTUAL_EVENT),
      },
      {
        value: TalentOffersType.VIDEO_CHAT,
        label: 'calendar.filters.video-chat',
        color: getSlotBackground(TalentOffersType.VIDEO_CHAT),
      },
      {
        value: 'LESSONS',
        label: 'calendar.filters.lessons',
        color: getSlotBackground('LESSONS'),
      },
    ],
  },
  {
    field: 'typeIn',
    label: 'calendar.filters.other',
    options: [
      {
        value: CalendarSlotType.MY_EVENT,
        label: 'calendar.filters.my-event',
        color: getSlotBackground(CalendarSlotType.MY_EVENT),
      },
      {
        value: CalendarSlotType.TIME_UNAVAILABLE,
        label: 'calendar.filters.time-unavailable',
        color: getSlotBackground(CalendarSlotType.TIME_UNAVAILABLE),
      },
      {
        value: 'SYNCED_CALENDAR', // todo: implement later
        label: 'calendar.filters.synced-calendar',
        color: getSlotBackground(CalendarSlotType.MY_EVENT),
      },
    ],
  },
]
