import React from 'react'
import styled from 'styled-components'

import CustomPicture from '_legacy/common/CustomPicture'
import Loader from './Loader'

import { ReactComponent as DefaultImage } from 'assets/images/icon/defaultAvatar.svg'

export default React.memo(function Avatar({
  isLoading, className, image, onClick
}) {
  if (isLoading) return <StyledLoader />

  if (!image) {
    return (
      <StyledPlaceholder
        className={className}
        onClick={onClick}
      >
        <DefaultImage />
      </StyledPlaceholder>
    )
  }

  return (
    <StyledAvatar
      className={className}
      onClick={onClick}
      file={image.file}
      src={image}
      alt='avatar'
    />
  )
})

const StyledLoader = styled(Loader)`
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
`

const StyledPlaceholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${({ theme: { colors } }) => colors.ultraLightBlue};
  font-size: 21px;
  font-weight: 700;
  color: ${({ theme: { colors } }) => colors.blue};
  cursor: pointer;
`

const StyledAvatar = styled(CustomPicture)`
  display: block;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  cursor: pointer;
`
