import React from 'react';
import PropTypes from 'prop-types';

// components
import { Row } from '_legacy/common/Template';
import Button from '_legacy/components/Button';

const SubmitButton = ({ text, disabled }) => (
  <Row
    className='submit-button'
    component={
      <Button
        text={text}
        typeButton="submit"
        disabled={disabled}
        paddingVertical={13}
        paddingHorizontal={20}
      />
    }
  />
);

SubmitButton.defaultProps = {
  paddingHorizontal: 55,
};

SubmitButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  paddingHorizontal: PropTypes.number,
  paddingVertical: PropTypes.number
};

export default SubmitButton;
