export const happensBlockConfig = [
  {
    numb: '01',
    title: 'video-message.request.happens.block1.title',
    desc: 'video-message.request.happens.block1.desc'
  },
  {
    numb: '02',
    title: 'video-message.request.happens.block2.title',
    desc: 'video-message.request.happens.block2.desc'
  },
  {
    numb: '03',
    title: 'video-message.request.happens.block3.title',
    desc: 'video-message.request.happens.block3.desc'
  },
];
