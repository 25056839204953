import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'

// components
import CustomPicture from '_legacy/common/CustomPicture'
import ModalPortal from 'portals/ModalPortal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const ImageModal = ({ isModalOpen, closeModal, file, showPrimaryButton = false, onPrimaryButtonClick }) => {
  const t = useGetTranslate()
  const imageRef = useRef()
  const [isHorizontal, setIsHorizontal] = useState(false)

  const onLoad = image => {
    setIsHorizontal(image.naturalHeight <= image.naturalWidth)
  }

  return (
    <ModalPortal isOpen={isModalOpen} onClose={closeModal} showCloseButton isMobileFullScreen>
      <div className="image-modal">
        <CustomPicture
          imgRef={imageRef}
          file={file}
          className={`image-modal--${isHorizontal ? 'horizontal' : 'vertical'}`}
          onLoad={() => onLoad(imageRef.current)}
        />
        {showPrimaryButton && (
          <button className="image-modal__primary-button" onClick={onPrimaryButtonClick}>
            {t('shared.words.make-primary')}
          </button>
        )}
      </div>
    </ModalPortal>
  )
}
ImageModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  imageUrl: PropTypes.string,
  showPrimaryButton: PropTypes.bool,
  onPrimaryButtonClick: PropTypes.func,
}
export default ImageModal
