import { _post } from 'api/utils'

export const activateEntourage = ({ type }) =>
  _post('/auth/me/entourage/activate', {
    needAuthorization: true,
    query: { type },
  })

export const deactivateEntourage = () =>
  _post('/auth/me/entourage/deactivate', {
    needAuthorization: true,
  })
