import { _get } from '../../utils';

export const getTalentPublicView = ({ talentNickname }) => _get(
  `/talents/public-view/${talentNickname}`,
  { needAuthorization: true }
);
export const getTalentReviews = ({ talentNickname, page = 1, limit = 10 }) => _get(`/talents/public-view/${talentNickname}/reviews?page=${page}&limit=${limit}`);

export const preview = {
  getPreviewTalentPublicView: ({ previewTalentLink }) => _get(`/talents/preview/${previewTalentLink}`),
  getPreviewTalentReviews: ({ previewTalentLink, page = 1, limit = 10 }) => _get(`/talents/preview/${previewTalentLink}/reviews?page=${page}&limit=${limit}`)
};
