// Modules
import React from 'react'
import { Link } from 'react-router-dom'
import isFunction from 'lodash/isFunction'

// Routing
import * as r from '_legacy/constants/routes'


// Utils
import { history } from 'utils/history'
import { closeOverlay } from '../../utils'

// Assets
import Icon from 'assets/icons/Icon'
import { ReactComponent as Logo } from 'assets/logo.svg'


const BottomNavigationOverlayHeader = ({
  title,
  onClose,
  onBack,
  isShowBack = true,
}) => {
  const onCloseDefault = closeOverlay
  const onBackDefault = () => history.goBack()

  const onClickBack = isFunction(onBack) ? onBack : onBackDefault
  const onClickClose = isFunction(onClose) ? onClose : onCloseDefault

  return (
    <div className="bottom-navigation__overlay__header">
      <div className="bottom-navigation__overlay__header__logo">
        <Link to={`${r.HOME}`}>
          <Logo />
        </Link>
      </div>

      <div className="bottom-navigation__overlay__header__navigation">
        {
          isShowBack && (
            <a onClick={onClickBack}>
              <Icon.BackArrow />
            </a>
          )
        }

        <h3 className="bottom-navigation__overlay__header__navigation__title">{title}</h3>

        <a onClick={onClickClose}>
          <Icon.Close />
        </a>
      </div>
    </div>
  )
}

export default BottomNavigationOverlayHeader
