import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const BasicMobileOverlay = ({ children }) => {
  return <StyledOverlay>{children}</StyledOverlay>;
};

const StyledOverlay = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0) 31.56%, rgba(39, 40, 53, 0.15) 46.15%, rgba(39, 40, 53, 0.6) 69.06%, rgba(39, 40, 53, 0.6) 100%);
`

BasicMobileOverlay.propTypes = {
  children: PropTypes.array.isRequired,
};

export default BasicMobileOverlay;
