import { _get } from 'api/utils'

export const getReviewsByMe = ({
  query: { page, sortOrder, limit, sortField },
}) =>
  _get('/reviews/by-me', {
    needAuthorization: true,
    query: { page, sortOrder, limit, sortField },
  })

export const getReviewsOfMe = ({
  query: { page, sortOrder, limit, sortField },
}) =>
  _get('/reviews/of-me', {
    needAuthorization: true,
    query: { page, sortOrder, limit, sortField },
  })
