export const OFFERING = 'offering'
export const DATE_TIME = 'dateTime'
export const LOCATION = 'location'
export const PRICE = 'price'
export const STATUS = 'status'

export const elementsTypes = {
  [OFFERING]: OFFERING,
  [DATE_TIME]: DATE_TIME,
  [LOCATION]: LOCATION,
  [PRICE]: PRICE,
  [STATUS]: STATUS
}

import Offering from './Offering'
import DateTime from '_legacy/components/Table/table-components/types/common/DateTime'
import Location from './Location'
import Price from './Price'
import Status from './Status'

export const MANAGE_OFFERINGS = 'manage-offerings'

export const manageOfferingsComponents = {
  [OFFERING]: Offering,
  [DATE_TIME]: DateTime,
  [LOCATION]: Location,
  [PRICE]: Price,
  [STATUS]: Status
}
