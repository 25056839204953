import React from 'react'
import range from 'lodash/range'

import { filters } from '_legacy/components/Table/Filters/config'
import ViewStarIcon from 'assets/icons/ViewStarIcon';


const FillStar = () => <ViewStarIcon percentageFill={100} size={20} />
const UnFillStar = () => <ViewStarIcon percentageFill={0} size={20} />

const Rating = ({ value }) => {
  const rating = [...range(0, value).fill(true), ...range(value, 5).fill(false)]
  return rating.map(filled => filled ? <FillStar /> : <UnFillStar />)
}

const followersCountOptions = [
  { value: { from: 0, to: 100 }, label: 'search.filters.followers.from-0-to-100' },
  { value: { from: 100, to: 1000 }, label: 'search.filters.followers.from-100-to-1000' },
  { value: { from: 1000, to: 10000 }, label: 'search.filters.followers.from-1000-to-10000' },
  { value: { from: 10000, to: 1000000 }, label: 'search.filters.followers.from-10000-to-1000000' },
  { value: { from: 1000000 }, label: 'search.filters.followers.from-100000' },
]


const ratingOptions = [
  { value: 1, label: <Rating value={1} /> },
  { value: 2, label: <Rating value={2} /> },
  { value: 3, label: <Rating value={3} /> },
  { value: 4, label: <Rating value={4} /> },
  { value: 5, label: <Rating value={5} /> },
]

export const followersConfig = {
  type: filters.select,
  placeholder: 'search.filters.followers.placeholder',
  field: 'followers',
  options: followersCountOptions,
}

export const ratingConfig = {
  type: filters.select,
  placeholder: 'search.filters.rating.placeholder',
  field: 'ratingFrom',
  options: ratingOptions,
}

export const locationConfig = {
  type: filters['talent-location-select'],
  field: 'location',
}
