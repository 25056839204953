import React from 'react'
import { useSwipeable } from 'react-swipeable'
import styled from 'styled-components'

import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState'

import bg from 'assets/landing/slider-bg.png'
import bgMobile from 'assets/landing/slider-bg-mobile.png'

import SlidesLoader from './SlidesLoader'
import CardsList from './CardsList'
import BookNow from './BookNow'

const ratio = 1.2
const widthScale = 1
const heightScale = 1

function calcWrapper (ref) {
  if (!ref.current) return { width: 0, height: 0 }
  const ww = ref.current.offsetWidth
  const wh = ref.current.offsetHeight
  if (ww / wh > ratio) {
    return {
      width: Math.round((wh) * widthScale * ratio),
      height: Math.round((wh) * heightScale)
    }
  } else {
    return {
      width: Math.round((ww) * widthScale),
      height: Math.round(((ww) * heightScale) / ratio),
    }
  }
}

export default React.memo(function NewSlider ({
  title, dataUrl, dataField, navigatePath, CardText
}) {
  const { width, height } = useWindowDimensions()
  const ref = React.useRef()
  const [wrapperDimensions, setWrapperDimensions] = React.useState(calcWrapper(ref))
  const [currentSlide, setCurrentSlide] = React.useState(null)
  const [slides, setSlides] = React.useState(null)

  React.useLayoutEffect(() => {
    setTimeout(() => setWrapperDimensions(calcWrapper(ref)), 500)
  }, [width, height, setWrapperDimensions])

  const navigateToLeft = React.useCallback(() => {
    if (!slides) return
    setCurrentSlide(current => {
      const currentIndex = slides.findIndex(item => item.id === current)
      if (!~currentIndex) return slides[slides.length - 1].id
      if (currentIndex === 0) return slides[slides.length - 1].id
      return slides[currentIndex - 1].id
    })
  }, [slides, setCurrentSlide])

  const navigateToRight = React.useCallback(() => {
    if (!slides) return
    setCurrentSlide(current => {
      const currentIndex = slides.findIndex(item => item.id === current)
      if (!~currentIndex) return slides[1].id
      if (currentIndex === slides.length -1) return slides[0].id
      return slides[currentIndex + 1].id
    })
  }, [slides, setCurrentSlide])

  const handlers = useSwipeable({
    onSwipedLeft: navigateToRight,
    onSwipedRight: navigateToLeft,
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  })

  const path = React.useMemo(() => {
    const convertedPath = [slides]
      .filter(Boolean)
      .map(items =>
        items
          .filter(
            currentSlide
              ? item => item.id === currentSlide
              : item => item.id === slides[0].id
          )
          .map(item => `${item.talent.plaqueNickname}/${item.sequentialId}`)
      )

    return `/${navigatePath}/${convertedPath}`
  }, [navigatePath, currentSlide, slides])

  return (
    <StyledWrapper>
      <h2 className='title'>
        {title}
      </h2>
      <SlidesLoader
        dataUrl={dataUrl}
        dataField={dataField}
        setSlides={setSlides}
      />
      <div
        ref={ref}
        className='cards-container'
      >
        <CardsList
          wrapperDimensions={wrapperDimensions}
          currentSlide={currentSlide}
          setCurrentSlide={setCurrentSlide}
          navigateToLeft={navigateToLeft}
          navigateToRight={navigateToRight}
          slides={slides}
          CardText={CardText}
          handlers={handlers}
        />
      </div>
      <BookNow
        path={path}
        navigateToLeft={navigateToLeft}
        navigateToRight={navigateToRight}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 135px 20px 50px;
  background: url(${bg}) center / 100% no-repeat;
  background-size: contain;
  @media only screen and (max-width: 1024px) {
    padding: var(--theme-header-height-small) 20px 135px;
  }
  @media only screen and (max-width: 480px) {
    padding: var(--theme-header-height-small) 0 150px;
    background: url(${bgMobile}) center / 100% no-repeat;
  }
  .title {
    font-family: 'Playfair Display';
    text-align: center;
    font-size: 42px;
    font-weight: 900;
    color: #fff;
    margin-bottom: 45px;
    @media only screen and (max-width: 1024px) {
      font-size: 32px;
    }
    @media only screen and (max-width: 480px) {
      font-size: 28px;
    }
  }
  .cards-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 90px);
    min-height: 180px;
    height: 100%;
    max-height: 480px;
    @media only screen and (max-width: 1366px) and (orientation: landscape) {
      max-height: 360px;
    }
    @media only screen and (max-width: 1024px) and (orientation: landscape) {
      max-height: 265px;
      width: calc(100% - 30px);
    }
    @media only screen and (max-width: 1024px) and (orientation: portrait) {
      max-height: 360px;
    }
    @media only screen and (max-width: 825px) and (orientation: landscape) {
      min-height: 180px;
    }
  }
`
