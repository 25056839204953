import { defaultCalendarConfig } from 'components/Calendar/BaseCalendar/configs/calendar-config.js'

import CalendarSlot from 'components/Calendar/components/CalendarSlot/'

export const talentCalendarConfig = {
  ...defaultCalendarConfig,
  eventContent: CalendarSlot,
  headerToolbar: {
    left: 'prev,next today',
    center: 'title',
    right: 'timeGridWeek timeGridDay listWeek'
  },
}
