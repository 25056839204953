import React from 'react'

import { getCurrencyRate as getCurrencyRateApi } from 'api/appApi/integration/currency'

import { useSetCurrency } from 'store/hooks/globalState/useCurrency'

export function useFetchCurrency() {
  const setFetchedCurrencies = useSetCurrency((prev, fetchedCurrencies) => ({
    ...prev,
    fetchedCurrencies,
  }))

  return React.useCallback(() => {
    getCurrencyRateApi()
      .then(({ conversion }) => {
        setFetchedCurrencies(conversion)
      })
  }, [setFetchedCurrencies])
}
