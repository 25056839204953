import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import prop from 'lodash/fp/prop'
import { useWindowDimensions } from 'store/hooks/globalState/useGlobalState';
const infiniteSlides = (slides, currentSlideIndex, WIDTH) => {
  const multiplyIndex = Math.floor(currentSlideIndex / slides.length);
  const marginOffsetX = -WIDTH * (currentSlideIndex);
  return {
    skipOffsetX: -WIDTH * (slides.length * multiplyIndex),
    marginOffsetX,
    slides: [...slides]
  };
};

const List = ({ slides, currentSlideIndex }) => {
  const Check = useWindowDimensions(prop('width'));
  let WIDTH;
  if(Check <= 950 && Check > 366){
    WIDTH = 344;
  }
  else if(Check <= 366){
    WIDTH = 290;
  }
  else{
    WIDTH = 450;
  }
  const { slides: infinitySlides, skipOffsetX, marginOffsetX } = infiniteSlides(slides, currentSlideIndex, WIDTH);

  return (
    <StyledWrapper slides={slides} width={WIDTH} className="mb-list" marginLeft={marginOffsetX - skipOffsetX}>
      {infinitySlides}
    </StyledWrapper>
  );
};

const  StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ slides }) => slides.length}, ${({ width }) => width}px);
  transition: .3s margin-left ease;
  margin-left: ${({ marginLeft }) => marginLeft}px;
`

List.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired
};

export default List;
