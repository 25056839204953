import React from 'react'
import PropTypes from 'prop-types';
import styled from 'styled-components'
import get from 'lodash/get'
import debounce from 'lodash/debounce'

import { ReactComponent as SearchIcon } from 'assets/search-icon.svg'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import ClearButton from './styles/ClearButton';

const CustomInput = React.memo(function CustomInput ({
  className = '', placeholder = '', field, getFn, setFn, inputRef
}) {
  const t = useGetTranslate()
  const value = getFn(current => get(current, field, ''))
  const [localSearch, setLocalSearch] = React.useState(value)

  const handleChange = setFn((prev, next) => ({ ...prev, [field]: next }))

  const debounced = React.useRef(debounce((searchText) => {
    handleChange(searchText)
  }, 500))

  React.useEffect(() => {
    debounced.current(localSearch)
  }, [localSearch])

  React.useEffect(() => {
    setLocalSearch(value)
  }, [value])

  return (
    <StyledWrapper className={`filter ${className}`}>
      <input
        className='custom-input'
        ref={inputRef}
        type='text'
        value={localSearch}
        placeholder={t(placeholder)}
        onChange={(e) => setLocalSearch(e.target.value)}
      />
      {localSearch
        ? <ClearButton onClick={() => setLocalSearch('')} />
        : <SearchIcon className="icon" />}
    </StyledWrapper>
  )
})

CustomInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  field: PropTypes.string.isRequired,
  getFn: PropTypes.func.isRequired,
  setFn: PropTypes.func.isRequired,
  inputRef: PropTypes.any
}

const StyledWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 300px;
  margin-right: 8px;
  .custom-input {
    display: block;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: ${({ theme: { colors } }) => colors.blue};
    outline: none;
    padding: 10px 18px;
    background: #fff;
    border: solid 2px ${({ theme: { colors } }) => colors.ultraLightBlue};
    border-radius: 4px;
    transition: all 0.2s;
    padding-right: 36px;
    &::placeholder {
      font-size: 16px;
      font-weight: 500;
      color: ${({ theme: { colors } }) => colors.lightGrey};
    }
    &:hover {
      border: solid 2px ${({ theme: { colors } }) => colors.blue};
    }
    &:focus {
      border: solid 2px ${({ theme: { colors } }) => colors.blue};
    }
  }
  .icon {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100%;
  }
`

export default CustomInput
