import React from 'react'
import { pipe, prop, includes, __ } from 'lodash/fp'
import { EntourageSubscriptionType } from 'shared'

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import { Row } from '_legacy/common'
import Button from '_legacy/components/Button'
import { routesList } from 'router/routesMap'
import { ReactComponent as EntourageInactiveIcon } from '../icons/EntourageInactiveIcon.svg'

const Inactive = () => {
  const t = useGetTranslate()
  const hasUpgrade = useUser(
    pipe(
      prop('user.entourageSubscription.entourageOrder.entourageType'),
      includes(__, [EntourageSubscriptionType.LITE, EntourageSubscriptionType.FULL])
    )
  )

  return (
    <Row
      nameBlock={t('account.entourage.inactive.title')}
      component={
        <div className="account-entourage">
          <div className="account-entourage__status-wrapper">
            <div className="account-entourage__status">
              <EntourageInactiveIcon className="account-entourage__status-icon" />
              <div className="account-entourage__status-info">
                <strong>
                  {t(hasUpgrade ? 'account.entourage.in-progress.status' : 'account.entourage.inactive.status')}
                </strong>
              </div>
            </div>
          </div>

          <p className="account-entourage__text">
            {t(hasUpgrade ? 'account.entourage.in-progress.text' : 'account.entourage.inactive.text')}
          </p>

          <Button
            isLink
            className="account-entourage__button"
            path={hasUpgrade ? routesList.entourageVerify() : routesList.entouragePublic()}
            text={hasUpgrade ? t('account.entourage.inactive.verify') : t('account.entourage.inactive.learn-more')}
          />
        </div>
      }
    />
  )
}

export default Inactive
