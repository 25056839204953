import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Arrow from './Arrow'
import Button from '_legacy/components/Button';

export default React.memo(function MobileCarouselButton ({
  talentNickname, navigateToLeft, navigateToRight
}) {
  const t = useGetTranslate()
  return (
    <StyledWrapper>
      <Arrow
        className='mobile-arrow left'
        clickHandler={navigateToLeft}
      />
      <Button
        withoutLoader
        text={t('home-page.buttons.visit-profile')}
        path={`/${talentNickname}`}
        classBtn="button-wrapper"
        isLink
        gradient
        paddingHorizontal="18"
        fontSize="1"
      />
      <Arrow
        className='mobile-arrow right'
        clickHandler={navigateToRight}
      />
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: none;
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 10px 0 155px;
    padding: 0 25px;
    .button-wrapper {
      width: 164px;
      height: 56px;
      z-index: 1000;
    }
    .mobile-arrow {
      display: block;
      margin: 0;
    }
  }
`
