import React from 'react';
import { object } from 'prop-types';

import CalendarButtonWithTip from '_legacy/components/Button/CalendarButton/CalendarButtonWithTip'
import DesktopSlider from '../DesktopSlider';
import CommercialEngagementText from '../CommercialEngagementBook/CommercialEngagementText';
import ImageLayer from '../ImageLayer/';
import { getSlides } from '../utils.js';


const DesktopSlidersBlock = React.memo(function DesktopSlidersBlock({ talentData, openCalendar }) {
  const {
    nickname,
    firstName,
    lastName,
    publicView,
    videoMessage,
    videoChat,
    plaqueNickname,
    title
  } = talentData

  const {
    LiveExperiencesCover,
    LiveVideoChatsCover,
    LiveVirtualEventsCover,
    VideoMessagesCover,
    LessonsCover,
    ReadyMadeEventsCover,
  } = getSlides(talentData)

  return (
    <div className='displayFlex'>
      <div className="desktop-slider-block">
        <DesktopSlider.Mobile
          liveExperiencesSlides={LiveExperiencesCover}
          videoChats={{ media: LiveVideoChatsCover, offerSequentialId: videoChat?.sequentialId }}
          videoMessages={{ media: VideoMessagesCover, offerSequentialId: videoMessage?.sequentialId }}
          lessonsSlides={LessonsCover}
          readyMadeEvents={ReadyMadeEventsCover}
          liveVirtualEvents={LiveVirtualEventsCover}
          firstName={firstName}
          plaqueNickname={plaqueNickname}
        />
        <div style={{ marginTop:'30px' }}>
          <CalendarButtonWithTip className='view-in-calendar-mode center-button ' onClick={openCalendar} />
        </div>
        <div >
          <CommercialEngagementText nickname={nickname}  />
        </div>
      </div>
      <div className='slideImage'>
        <div className='lineCenter'>
          <div className="vl-up-line "></div>
          <span className='vertical'>{title ? title.toUpperCase() : 'Champion'}</span>
          <div className="vl-down-line"></div>
        </div>
        <ImageLayer className='main-image' layers={publicView?.layers} altImg={`${firstName} ${lastName}`} />
      </div>
    </div>
  )
});

DesktopSlidersBlock.propTypes = {
  talentData: object.isRequired,
};

export default DesktopSlidersBlock;
