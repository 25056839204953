import React from 'react'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

import Loader from './Loader'

export default React.memo(function TwoLineStringWithBold ({ isLoading, mainText, subText }) {
  const t = useGetTranslate()
  if (isLoading) {
    return (
      <StyledWrapper>
        <Loader className='loader' />
      </StyledWrapper>
    )
  }
  return (
    <StyledWrapper>
      <div className='main-text'>
        {mainText || t('table.text.n-a')}
      </div>
      {subText && (
        <PopperTooltip Component={subText}>
          <div className='sub-text'>
            {subText}
          </div>
        </PopperTooltip>
      )}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 768px) {
    margin-top: 4px;
  }
  .loader {
    height: 19px;
    margin: 4px;
  }
  .main-text {
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    color: ${({ theme: { colors } }) => colors.blue};
    @media screen and (max-width: 768px) {
      font-weight: 700;
      line-height: 26px;
      color: ${({ theme: { colors } }) => colors.blue};
    }
  }
  .sub-text {
    font-size: 13px;
    font-weight: 500;
    line-height: 22px;
    color: ${({ theme: { colors } }) => colors.grey};
  }
`
