import React from 'react'

import normalizeParams from 'utils/normalizeParams'
import {
  fetchHowToVideoList as howToVideoListApi,
} from './api'
import {
  useSetHowToVideos,
  useSetHowToVideosFilters
} from './store';
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchHowToVideos() {
  const setHowToVideos = useSetHowToVideos((prev, next) => next)
  const setHowToVideosFilters = useSetHowToVideosFilters(
    (prev, next) => ({ ...prev, ...next })
  )
  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ ...query }) => {
    const params = normalizeParams(query)
    howToVideoListApi({
      query: {
        ...params,
      }
    })
      .then(({ howToVideos, pageInfo }) => {
        setHowToVideos(howToVideos)
        setHowToVideosFilters(pageInfo)
      })
      .catch(error => {
        console.warn(error)
        handleResponseError(error)
      })
  },
  [setHowToVideosFilters]
  )
}
