import React from 'react'

import { statuses } from '_legacy/configs/table/manageOrders'

import CommonStatus from '_legacy/components/Table/table-components/types/common/Status'

const Status = React.memo(({ isLoading, status }) => (
  <CommonStatus
    isLoading={isLoading}
    status={status}
    config={statuses[status]}
  />
))

export default Status
