import { getLangLabelByCode } from '../language/language'

export const getCurrencyAndLanguageLabel = (languageCode, currencyCode) => {
  const language = getLangLabelByCode(languageCode)

  return `${language} / ${currencyCode}`
}


export const getLanguageLabel = (languageCode) => {
  return getLangLabelByCode(languageCode)
}
