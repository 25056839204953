import React from 'react'

import RatingOption from 'components/forms/RatingOption/'

import { filters } from '_legacy/components/Table/Filters/config'

const ratingOptions = [
  { value: 1, label: <RatingOption value={1} /> },
  { value: 2, label: <RatingOption value={2} /> },
  { value: 3, label: <RatingOption value={3} /> },
  { value: 4, label: <RatingOption value={4} /> },
  { value: 5, label: <RatingOption value={5} /> },
]

export const filtersConfig = [
  {
    type: filters.search,
    placeholder: 'table.filters.search',
    field: 'search'
  },
  {
    type: filters.select,
    placeholder: 'search.filters.rating.placeholder',
    field: 'ratingFrom',
    options: ratingOptions
  },
]
