import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import compose from 'lodash/fp/compose';
import get from 'lodash/get';
import CommercialVideoMessageForm from '_legacy/components/CommmercialVideoMessageForm';
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler';
import withLibrary from '_legacy/containers/Offerings/components/withLibrary';
import RequiredLabel from '_legacy/components/RegisterForms/shared/components/RequiredLabel';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useCommercialVideoMessage } from 'store/hooks/globalState/commercialVideoMessage';
import { useFetchCommercialVideoMessage, useUpdateCommercialVideoMessage } from 'requests/commercial-video-message';
import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation';
import { submitVideoMessageAdapter, getVideoMessageAdapter, videoMessageDefaultValues } from '_legacy/components/CommmercialVideoMessageForm/utils';
import { OFFERINGS } from '_legacy/constants/routes';

const EditCommercialVideoMessage = () => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()
  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: videoMessageDefaultValues,
  })

  const commercialVideoMessageData = useCommercialVideoMessage(state => get(state, 'data'))
  const commercialVideoMessageLoading = useCommercialVideoMessage(state => get(state, 'loading'))

  const fetchCommercialVideoMessage = useFetchCommercialVideoMessage()
  const updateCommercialVideoMessage = useUpdateCommercialVideoMessage()

  React.useEffect(() => {
    fetchCommercialVideoMessage()
  }, [])

  React.useEffect(() => {
    if (isEmpty(commercialVideoMessageData)) {
      methods.reset(videoMessageDefaultValues)
    } else {
      methods.reset(getVideoMessageAdapter(commercialVideoMessageData))
    }
  }, [commercialVideoMessageData])

  const onSubmit = compose(updateCommercialVideoMessage, submitVideoMessageAdapter)

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(onSubmit)}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />
      <div className="video-message-commercial-use">
        <h1 className="header">
          {t('vmcu.header.title.edit')}
        </h1>
        <RequiredLabel />
        <CommercialVideoMessageForm {...methods} onSubmit={onSubmit} isLoading={commercialVideoMessageLoading} />
      </div>
    </FormProvider>
  );
};

export default withLibrary(EditCommercialVideoMessage);
