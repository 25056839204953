// Modules
import React from 'react'

// Assets
import Icon from 'assets/icons/Icon'

// Routing
import * as r from '_legacy/constants/routes'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { history, hashHistory } from 'utils/history'

// Components
import BottomNavigationOverlay from '../../BottomNavigationOverlay'

const linksConfig = [
  {
    title: 'footer.mobile.search-by-keywords',
    onClick: () => history.push(`/${r.SEARCH}/${r.KEYWORDS}`),
    IconComponent: Icon.Search,
  },
  {
    title: 'footer.mobile.search-by-talent',
    onClick: () => hashHistory.push(`/${r.HASH_SEARCH_BY}/${r.HASH_TALENT}`),
    IconComponent: Icon.Star,
  },
  {
    title: 'footer.mobile.search-by-offering',
    onClick: () => hashHistory.push(`/${r.HASH_SEARCH_BY}/${r.HASH_OFFERING}`),
    IconComponent: Icon.OfferingTicket,
  },
  {
    title: 'footer.mobile.search-by-location',
    onClick: () => hashHistory.push(`/${r.HASH_SEARCH_BY}/${r.HASH_LOCATION}`),
    IconComponent: Icon.Location,
  },
]


const Search = () => {
  const t = useGetTranslate()

  return (
    <BottomNavigationOverlay
      title={t('footer.mobile.search')}
      className={'bottom-navigation__overlay'}
    >
      <ul>
        {
          linksConfig
            .map(({ title, onClick, IconComponent }, i) =>
              <li className="bottom-navigation__overlay--search__item" key={i} onClick={onClick}>
                <IconComponent className="bottom-navigation__overlay--search__item__icon" />
                <p className="bottom-navigation__overlay--search__item__title">{t(title)}</p>
                <Icon.ShevronRight className="bottom-navigation__overlay--search__item__icon__shevron-right" />
              </li>
            )
        }
      </ul>
    </BottomNavigationOverlay>
  )
}

export default Search
