import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Icon from 'assets/icons/Icon';

const Button = React.memo(function Button ({
  fontSize,
  fontWeight,
  paddingVertical,
  paddingHorizontal,
  text,
  isLink,
  path,
  classBtn,
  typeButton,
  stretch,
  disabled,
  shadow,
  primary,
  secondary,
  loader,
  style,
  iconComponent,
  iconStyleWr,
  handleOnClick,
  withoutLoader,
  className = ''
}) {

  const classesWr = cx('button', {
    'button--primary': primary,
    'button--secondary': secondary,
    'button--disabled': disabled,
    'button--stretch': stretch,
    'button--loader': loader,
  });

  const classesBtn = cx('button__item', {
    'button--shadow': shadow,
  });

  const styleVariable = {
    '--padding-top-bottom-btn': `${paddingVertical}px`,
    '--padding-left-right-btn': `${paddingHorizontal}px`,
    '--font-size-btn': `${fontSize}em`,
    '--font-weight-btn': fontWeight,
  };

  return (
    <div className={`${classesWr} ${className} ${classBtn || ''}`} style={style}>
      {isLink
        ? (
          <Link to={path} disabled={disabled} style={styleVariable} className={classesBtn} onClick={handleOnClick}>
            {iconComponent && <span className={`button__icon ${iconStyleWr || ''}`}>{iconComponent}</span>}
            <span className="button__text">{text}</span>
            {!withoutLoader &&<Icon.Spinner className="button__spinner loader" fill={!primary && 'white'} />}
          </Link>
        )
        : (
          <button type={typeButton} disabled={disabled} style={styleVariable} className={classesBtn} onClick={handleOnClick}>
            {iconComponent && <span className={`button__icon ${iconStyleWr || ''}`}>{iconComponent}</span>}
            <span className="button__text">{text}</span>
            {!withoutLoader && <Icon.Spinner className="button__spinner loader" fill={!primary && 'white'} />}
          </button>
        )}
    </div>
  );
});

Button.defaultProps = {
  isLink: false,
  path: '',
  typeButton: 'button',
  disabled: false,
  paddingVertical: 8,
  paddingHorizontal: 18,
  fontSize: 1,
  fontWeight: 700,
};

Button.propTypes = {
  text: PropTypes.string.isRequired,
  typeButton: PropTypes.string,
  isLink: PropTypes.bool,
  path: PropTypes.string,
  disabled: PropTypes.bool,
  shadow: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  stretch: PropTypes.bool,
  paddingVertical: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  paddingHorizontal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fontSize: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  fontWeight: PropTypes.number,
  handleOnClick: PropTypes.func,
  iconStyleWr: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]),
  classBtn: PropTypes.string,
  style: PropTypes.object,
  loader: PropTypes.bool,
  iconComponent: PropTypes.oneOfType(
    [
      PropTypes.arrayOf([PropTypes.node]),
      PropTypes.node,
      PropTypes.func
    ]),
};

export default Button;
