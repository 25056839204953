import React from 'react'
import { UserType } from 'shared'

import ProfileEdit from '_legacy/components/RegisterForms/ProfileEdit'
import { useIsUserHasTalent, useUserType } from 'store/hooks/globalState/useUser'

const DesktopEditProfileTemplate = () => {
  const userType = useUserType()
  const isUserHasTalent = useIsUserHasTalent()
  const isAgent = userType === UserType.AGENT

  return (
    <ProfileEdit
      showAgent={!(isAgent && isUserHasTalent)}
      showTalent={isUserHasTalent}
    />
  )
}

export default DesktopEditProfileTemplate
