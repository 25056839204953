import React from 'react';
import compose from 'lodash/fp/compose'

import { useRegisterAsUser } from 'requests/auth'
import { submitAdapter } from './utils/submitAdapter';
import RegistrationForm from './Form';

const RegistrationFormUser = React.memo(function RegistrationFormUser() {
  const [loading, setLoading] = React.useState()
  const registerAsUser = useRegisterAsUser(setLoading)
  const onSubmit = compose(registerAsUser, submitAdapter);

  return (
    <RegistrationForm onSubmit={onSubmit} isLoading={loading} />
  );
});


export default RegistrationFormUser;

