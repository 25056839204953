import React from 'react';

import ModalPortal from 'portals/ModalPortal';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const TermPrivacyPolice = () => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen showCloseButton>
      <div className="legal-terms-modal">
        <h1 className="modal-header">{t('shared.terms.privacy.title')}</h1>
      </div>
    </ModalPortal>
  )
}
export default TermPrivacyPolice
