import React from 'react'
import { useFormContext } from 'react-hook-form'
import { head, isEqual, compose, prop, omit, isEmpty } from 'lodash/fp'
import debounce from 'lodash/debounce'
import cx from 'classnames'
import { addMinutes, set, subMinutes } from 'date-fns'

import { getTalentsCalendarCheckOverlap } from '../../TalentCalendar/state/api/calendar'
import { timeSlotAdapter } from '../../TalentCalendar/state/requests/create-event'

// components
import Icon from 'assets/icons/Icon'
import { Input } from '_legacy/common'
import Button from '_legacy/components/Button'
import Select from '_legacy/common/Select/Common'
import Radio from '_legacy/components/Form/Radio/Radio'
import Datepicker from '_legacy/common/DatePicker/DatePicker'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'

import { repeatEveryTypeWithTranslate as getRepeatEveryTypeWithTranslate } from '_legacy/containers/Experience/translates'
import { editTypeOptions, getMonthOptions, typeOptions } from './options'
import { getMinAvailableTime } from 'utils/date'
import { getCalendarTimeFormat, getCalendarTime, getCalendarDate } from 'components/Calendar/utils/getCalendarDateTime'
import SelectWithColor from '_legacy/components/SelectWithColor'
import TimePicker from '_legacy/common/DatePicker/TimePicker'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Label from 'components/forms/Label'
import DaysList from 'components/forms/DaysList'
import { useGetRecurringOptions } from '_legacy/components/CreateExperience/components/Recurring/config'

// select
const getValue = prop('value')
const isSelectCustom = compose(isEqual('CUSTOM'), getValue)
const isSelectWeek = compose(isEqual('WEEK'), getValue)
const isSelectMonth = compose(isEqual('MONTH'), getValue)
// radio
const isOn = isEqual('ON')
const isAfter = isEqual('AFTER')


const NewEventForm = ({ talentId, recurringId, showTypeSelect, onCancel, cancelBtnText, onSubmit, submitBtnText, showSelectDateFormInitialState = false, hour12Format }) => {
  const t = useGetTranslate()
  const methods = useFormContext()
  const getRecurringOptions = useGetRecurringOptions()

  const [isValid, setIsValid] = React.useState(true)
  const [showSelectDateForm, setShowSelectDateForm] = React.useState(showSelectDateFormInitialState)


  const { eventType, ...dateFormProps } = methods.watch(['type', 'ends', 'date', 'endTime', 'eventType', 'startTime', 'recurring'])
  const { type, ends, date, endTime, startTime, recurring } = dateFormProps
  const prevFormState = React.useRef(dateFormProps)

  const timeFormat = getCalendarTimeFormat(hour12Format)
  const getTime = getCalendarTime(hour12Format)
  const getDate = getCalendarDate(hour12Format)

  const displayedTime = `${getTime(startTime)} - ${getTime(endTime)}`
  const displayedDate = getDate(date)

  const recurringOptions = getRecurringOptions(date ? new Date(date) : new Date())

  const monthOptions = getMonthOptions(date)
  const repeatEveryTypeWithTranslate = getRepeatEveryTypeWithTranslate(t)

  const checkOverlap = React.useCallback(debounce(async (formState, recurringId) => {
    try {
      await getTalentsCalendarCheckOverlap({
        body: {
          recurringIdToExclude: recurringId,
          ...omit(['title', 'type'], timeSlotAdapter(formState))
        },
        talentId,
      })

      setIsValid(true)
    } catch {
      setIsValid(false)
    }
  }, 400), [])

  React.useEffect(() => {
    if (!isEqual(prevFormState.current, dateFormProps)) {
      checkOverlap(dateFormProps, recurringId)
      prevFormState.current = dateFormProps
    }
  }, [dateFormProps, recurringId])

  return (
    <form className="new-event-form" onSubmit={methods.handleSubmit(onSubmit)}>
      <div className="new-event-form__inner">
        {showSelectDateForm ? (
          <React.Fragment>
            {/* DATE */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.date.label')} </Label>
              <Datepicker
                name="date"
                minDate={new Date()}
                className="new-event-form__form-control"
                placeholder={t('calendar.new-event-form.date.placeholder')}
                dateFormat={t('utils.date.format')}
                locale={t('utils.date.locale')}
                portalId="root-portal"
              />
            </div>

            {/* START TIME */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.start-time.label')} </Label>
              <TimePicker
                name="startTime"
                minTime={getMinAvailableTime(date)}
                maxTime={subMinutes(new Date(endTime), 15)}
                placeholder={t('calendar.new-event-form.start-time.placeholder')}
                menuPlacement={'auto'}
                portalId="root-portal"
                dateFormat={timeFormat}
                timeFormat={timeFormat}
              />
            </div>

            {/* END TIME */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.end-time.label')}</Label>
              <TimePicker
                name="endTime"
                minTime={addMinutes(new Date(startTime), 15)}
                maxTime={set(startTime, { minutes: 59, hours: 23 })}
                placeholder={t('calendar.new-event-form.end-time.placeholder')}
                portalId="root-portal"
                dateFormat={timeFormat}
                timeFormat={timeFormat}
              />
            </div>
          </React.Fragment>
        ) : (
          <div className="new-event-form__form-group">
            <OfferingsDetailsHeaderItem
              icon={<Icon.Calendar />}
              item={displayedDate}
              subitem={displayedTime}
            />
            <button className="new-event-form__change-btn" type="button" onClick={() => setShowSelectDateForm(true)}>
              {t('calendar.new-event-form.button.change')}
            </button>
          </div>
        )}

        {/* EVENT TYPE */}
        {showTypeSelect && (
          <div className="new-event-form__form-group">
            <Label>{t('calendar.new-event-form.type.label')} </Label>
            <SelectWithColor
              name="eventType"
              isSearchable={false}
              options={showSelectDateFormInitialState ? editTypeOptions :typeOptions}
              className="new-event-form__form-control"
              placeholder={t('calendar.new-event-form.type.placeholder')}
              menuPortalTarget={document.body}
            />
          </div>
        )}

        {eventType && (
          <React.Fragment>
            {/* TITLE */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.name.label')} </Label>
              <Input
                name="title"
                validation={{
                  required: 'create-experience.form.name.errors.required',
                }}
                className="new-event-form__form-control"
                placeholder={t('calendar.new-event-form.name.placeholder')}
              />
            </div>

            {/* RECURRING */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.recurring.label')} </Label>
              <Select
                isSearchable={false}
                className="new-event-form__form-control"
                name="recurring"
                placeholder={t('calendar.new-event-form.recurring.placeholder')}
                options={recurringOptions}
              />
            </div>
          </React.Fragment>
        )}

        {isSelectCustom(recurring) && (
          <React.Fragment>
            {/* THROUGH */}
            <div className="new-event-form__form-group">
              <Label>{t('calendar.new-event-form.through.label')}</Label>
              <div className="new-event-form__repeat-every">
                <Input
                  min={1}
                  type="number"
                  name="through"
                  defaultValue={1}
                  placeholder={t('calendar.new-event-form.through.placeholder')}
                />
                <Select
                  name="type"
                  className="new-event-form__form-control"
                  options={repeatEveryTypeWithTranslate}
                  defaultValue={head(repeatEveryTypeWithTranslate)}
                />
              </div>
            </div>

            {/* REPEAT EVERY (month days) */}
            {isSelectMonth(type) && (
              <div className="new-event-form__form-group">
                <Label>{t('calendar.new-event-form.repeat-on.label')}</Label>
                <Select
                  name="repeat-on"
                  options={monthOptions}
                  defaultValue={head(monthOptions)}
                  className="new-event-form__form-control"
                  placeholder={t('calendar.new-event-form.repeat-on.label')}
                />
              </div>
            )}

            {/* REPEAT ON (week days) */}
            {isSelectWeek(type) && (
              <div className="new-event-form__form-group cr-modal__item">
                <Label>{t('experience.modal.repeat-on')}</Label>
                <DaysList name="on" />
              </div>
            )}

            <div className="new-event-form__form-group">
              <Label>{t('experience.modal.repeat-ends.label')}</Label>

              {/* NEVER */}
              <div className="new-event-form__radio-group">
                <Radio
                  className="radio-wrapper"
                  name="ends"
                  value="NEVER"
                  defaultChecked
                  label={t('experience.modal.repeat-ends.never')}
                />
              </div>

              {/* ON */}
              <div className="new-event-form__radio-group">
                <Radio className="radio-wrapper" name="ends" value="ON" label={t('experience.modal.repeat-ends.on')} />
                <Datepicker
                  defaultValue={new Date()}
                  name="ends-on"
                  placeholder={t('register.form.date-of-birth.placeholder')}
                  dateFormat={t('utils.date.format')}
                  locale={t('utils.date.locale')}
                  disabled={!isOn(ends)}
                  minDate={new Date()}
                  portalId="root-portal"
                />
              </div>

              {/* AFTER */}
              <div className="new-event-form__radio-group">
                <Radio
                  className="radio-wrapper"
                  name="ends"
                  value="AFTER"
                  label={t('experience.modal.repeat-ends.after')}
                />
                <div className="ends-group__after-occurrence-input">
                  <Input
                    type="number"
                    placeholder="1"
                    name="after-on"
                    min={1}
                    defaultValue={1}
                    isDisabled={!isAfter(ends)}
                  />

                  <label className={cx('new-event-form__after-occurrence-label', { disabled: !isAfter(ends) })}>
                    {t('experience.modal.occurrence')}
                  </label>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {!isValid && (
        <div className='new-event-form__controls'>
          <p className='new-event-form__error-message'>{t('calendar.new-event-form.errors.overlap')}</p>
        </div>
      )}

      <div className="new-event-form__controls new-event-form__controls--2-cols">
        <Button
          primary
          handleOnClick={onCancel}
          text={cancelBtnText || t('calendar.new-event-form.button.cancel')}
        />
        <Button
          typeButton="submit"
          disabled={!eventType || !isValid || !isEmpty(methods.errors)}
          text={submitBtnText || t('calendar.new-event-form.button.create')}
        />
      </div>
    </form>
  )
}

export default NewEventForm
