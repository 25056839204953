import React from 'react'
import { useParams } from 'react-router-dom'

import {
  getOccurrencePublic,
  getVideoMessage,
  getReadyMadePublic,
  getVideoChat,
} from 'api/appApi/offers/publicViews'

import {
  useSetExperiencePublicViewOccurrence,
  useSetVideoMessagePublic,
  useSetReadyMadeEventsPublic,
  useSetVideoChatPublic,
} from 'store/hooks/globalState/usePublicView'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

export function useFetchExperiencePublicViewOccurrence() {
  const setExperiencePublicViewOccurrence = useSetExperiencePublicViewOccurrence((prev, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    setExperiencePublicViewOccurrence(null);
    getOccurrencePublic({ talentPlaqueNickname, offerSequentialId })
      .then(({ occurrence, currentUserPersonalData }) => {
        setExperiencePublicViewOccurrence({ occurrence, currentUserPersonalData });
      })
      .catch(error => handleResponseError(error))
  }, [setExperiencePublicViewOccurrence, talentPlaqueNickname, offerSequentialId])
}

export function useFetchVideoMessage() {
  const setVideoMessage = useSetVideoMessagePublic((prev, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getVideoMessage({ talentPlaqueNickname, offerSequentialId })
      .then(({ videoMessage }) => {
        setVideoMessage(videoMessage);
      })
      .catch(error => handleResponseError(error))
  }, [setVideoMessage, talentPlaqueNickname, offerSequentialId])
}

export function useFetchReadyMadeEvents() {
  const setReadyMadeEvents = useSetReadyMadeEventsPublic((prev, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getReadyMadePublic({ talentPlaqueNickname, offerSequentialId })
      .then(({ readyMadeEvent }) => {
        setReadyMadeEvents(readyMadeEvent);
      })
      .catch(error => handleResponseError(error))
  }, [setReadyMadeEvents, talentPlaqueNickname, offerSequentialId])
}

export function useFetchVideoChat() {
  const setVideoChat = useSetVideoChatPublic((prev, next) => next)
  const { talentPlaqueNickname, offerSequentialId } = useParams()
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    getVideoChat({ talentPlaqueNickname, offerSequentialId })
      .then(({ videoChat }) => setVideoChat(videoChat))
      .catch(error => handleResponseError(error))
  }, [setVideoChat, talentPlaqueNickname, offerSequentialId])
}
