// TODO: Refactor wrappers and forms according to DRY after project ends
import React, { useEffect } from 'react'
import { object, arrayOf } from 'prop-types'
import { isEmpty } from 'lodash'
import { useForm, FormProvider } from 'react-hook-form'
import { PRECISION_TYPES } from '_legacy/components/Form/Selects/basic/LocationsContants.js'
import { FileType, TypesOfEntourage } from 'shared'

import { OFFERINGS } from '_legacy/constants/routes.js'
import ChangeRouteHandler from '_legacy/components/ChangeRouteHandler'

import { useSetPathToNavigate } from 'store/hooks/globalState/useSwitchNavigation.js'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'

import { initialCharities } from 'utils/services/loaders/charities'

import { liveInPersonLessonFormAdapter } from './utils.js'
import { getDaysTimesTypeOptions } from '../../utils.js'

import { useUpdateLiveInPersonLesson } from 'requests/lessons.js'

import Button from '_legacy/components/Button/'
import Radio from '_legacy/components/Form/Radio/Radio.js'
import TextAreaCommon from '_legacy/components/Form/Inputs/TextAreaCommon.js'
import LocationsSelect from '_legacy/components/Form/Selects/basic/LocationsSelect.js'
import CharitiesSelect from '_legacy/components/Form/Selects/basic/CharitiesSelect.js'
import MaskInput from '_legacy/components/Form/Inputs/MaskInputs/MaskInput.js'
import SingleSelectLibrary from '_legacy/components/Library/SingleSelectLibrary/'
import MediaPicture from '_legacy/components/Library/configs/Media/MediaPicture.js'
import SelectComponent from '_legacy/components/Form/Selects/SelectComponent'
import { ErrorMessage } from '_legacy/common'
import TipButton from '_legacy/components/Form/LabelTips/TipButton'

import OrderedRulesList from 'components/OrderedRulesList/'

import LessonBackdrop from '../LessonBackdrop/'
import PriceWithLabel from '../PriceWithLabel/'
import { submitRadio } from 'utils/forms/adapters'
import EntourageMembershipModal from 'components/EntourageMembershipModal'
import EntourageMembershipTypeModal from 'components/EntourageMembershipTypeModal'

const { PHOTO, VIDEO } = FileType
import { useRequiredTaxInfoModalHandler } from 'store/hooks/globalState/useUser';


const LiveInPersonLessonForm = ({
  defaultData,
  rules,
}) => {
  useSetPathToNavigate({ talentRoute: `/${OFFERINGS}`, agentRoute: '/' })

  const t = useGetTranslate()

  const [loading, setLoading] = React.useState()

  const taxInfoModalHandler = useRequiredTaxInfoModalHandler()
  const options = { openExtraModal: taxInfoModalHandler }
  const updateLiveInPersonLesson = useUpdateLiveInPersonLesson(options)

  const methods = useForm({
    mode: 'onChange',
    shouldUnregister: false,
    defaultValues: {
      charities: initialCharities,
    },
  })
  const entourage = submitRadio(methods.watch('entourage'))

  useEffect(() => {
    if (!isEmpty(defaultData) && !methods.getValues('price')) {
      const data = liveInPersonLessonFormAdapter(defaultData, t)
      methods.reset(data)
    }
  }, [defaultData])

  return (
    <FormProvider {...methods}>
      <ChangeRouteHandler
        isDraft={false}
        onSubmit={methods.handleSubmit(value => updateLiveInPersonLesson(value, setLoading))}
        shouldHandleNavigation={!isEmpty(methods.formState.dirtyFields)}
      />

      <form
        className="lesson-edit-form darken"
        onSubmit={methods.handleSubmit(value => updateLiveInPersonLesson(value, setLoading))}
      >
        <OrderedRulesList rules={rules} />

        <div className="lessons-field include-service">
          <h3 className="label">
            <span>{t('lessons.entourage.label')}</span>
            <TipButton modalComponent={EntourageMembershipModal} />
          </h3>


          <div className="include-service__inner">
            <Radio
              value={true}
              name="entourage"
              label={t('lessons.entourage.options.yes')}
              defaultChecked
              whiteTheme
            />

            <Radio
              value={false}
              name="entourage"
              label={t('lessons.entourage.options.no')}
              whiteTheme
            />
          </div>
        </div>

        {entourage && (
          <div className="lessons-field include-service">
            <h3 className="label">
              <span>{t('lessons.entourage-type.label')}</span>
              <TipButton modalComponent={EntourageMembershipTypeModal} />
            </h3>

            <div className="include-service__inner">
              <Radio
                value={TypesOfEntourage.FULL}
                name="entourageType"
                label={t('lessons.entourage-type.full')}
                defaultChecked
                whiteTheme
              />

              <Radio
                value={TypesOfEntourage.EITHER}
                name="entourageType"
                label={t('lessons.entourage-type.full-or-lite')}
                whiteTheme
              />
            </div>
          </div>
        )}

        <div className="lessons-field include-service">
          <h3 className="label">{t('lessons.label.services-include')}</h3>

          <div className="include-service__inner">
            <Radio
              value={true}
              name="active"
              register={methods.register}
              label={t('shared.words.label.yes')}
              defaultChecked
              whiteTheme
            />

            <Radio
              value={false}
              name="active"
              register={methods.register}
              label={t('shared.words.label.no')}
              whiteTheme
            />
          </div>
        </div>

        <MaskInput
          name='lengthOfTime'
          label={t('lessons.label.length-of-time')}
          placeholder={t('lessons.placeholder.length-of-time')}
          rules={{ required: 'lessons.required.length-of-time' }}
          containerClassName='lessons-field'
        />

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.schedule-options')}</h3>
          <SelectComponent
            name='daysTimesType'
            placeholder={t('create-experience.form.set-days.placeholder')}
            options={getDaysTimesTypeOptions(t)}
            register={methods.register}
            isRequired
            rules={{
              required: 'create-experience.form.set-days.errors.required',
            }}
            blackTheme
          />
        </div>

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.location')}</h3>
          <LocationsSelect
            isCreatable
            name='location'
            blackTheme
            precision={PRECISION_TYPES.ADDRESS_AND_LOCATION}
            placeholder={t('lessons.placeholder.location')}
            rules={{ validate: value => !!value?.label === true || t('lessons.required.location') }}
          />
        </div>

        <PriceWithLabel
          name='price'
          label={t('lessons.label.price')}
          placeholder='lessons.placeholder.price'
          requiredMessage='lessons.required.price'
          isRequired
          whiteTheme
        />

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.which-charities')}</h3>
          <CharitiesSelect
            name='charities'
            error={methods.errors.charities}
            blackTheme
            className='lesson-charities'
          />
        </div>

        <div className="lessons-field">
          <h3 className="label">{t('lessons.label.media.name')}</h3>
          <div className="lesson-media-wrapper">
            <SingleSelectLibrary
              name='media'
              components={{ customBackdrop: LessonBackdrop, customPicture: MediaPicture }}
              dropZoneProps={{ accept: [PHOTO, VIDEO] }}
              rules={{ required: 'lessons.label.media.required' }}
            />
          </div>
        </div>

        <TextAreaCommon
          register={methods.register({
            maxLength: {
              value: 500,
              message: 'lessons.errors.special-instructions.maxLength',
            },
          })}
          name="specialInstructions"
          placeholder={t('lessons.placeholder.special-instructions')}
          label={t('lessons.label.special-instructions')}
          watch={methods.watch}
          max={500}
          error={methods.errors.specialInstructions}
          containerClassName="lessons-textarea lessons-field"
        />

        <Button
          disabled={loading}
          text={t('lessons.button.publish')}
          typeButton="submit"
          paddingVertical="13"
          classBtn="lessons__button"
        />
        {!isEmpty(methods.formState.errors) &&
          <ErrorMessage
            message={t(
              'register-aficionado.form.errors.required-data-is-missing'
            )}
          />
        }
      </form>
    </FormProvider>
  )
}

LiveInPersonLessonForm.defaultProps = {
  defaultData: {},
}

LiveInPersonLessonForm.propTypes = {
  defaultData: object,
  rules: arrayOf(object),
}

export default LiveInPersonLessonForm
