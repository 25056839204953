import { types } from 'shared';
import map from 'lodash/map';

const { NationalitiesList } = types.languages;

export const nationalities = map(NationalitiesList, (value, key) => ({
  label: value.name,
  value: key
}));

export const getNationalitiesAdapter = nationality => {
  return nationalities?.find(n => n.value === nationality)
}
