import Slider from '_legacy/components/TalentPublicView/DesktopSlider/Slider'
import LiveExperiences from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveExperiences';
import LiveVideoChats from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveVideoChats';
import VideoMessages from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/VideoMessages';
import Lessons from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/Lessons';
import LiveVirtualEvents from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/LiveVirtualEvents';
import ReadyMadeEvents from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/ReadyMadeEvents';
import CommercialEngagements from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/CommercialEngagements'
import VideoMessagesPublic from '_legacy/components/TalentPublicView/DesktopSlider/prototypes/VideoMessagesPublic'
import Mobile from '_legacy/components/TalentPublicView/DesktopSlider/Mobile'

Slider.LiveExperiences = LiveExperiences;
Slider.LiveVideoChats = LiveVideoChats;
Slider.VideoMessages = VideoMessages;
Slider.Lessons = Lessons;
Slider.LiveVirtualEvents = LiveVirtualEvents;
Slider.ReadyMadeEvents = ReadyMadeEvents;
Slider.CommercialEngagements = CommercialEngagements
Slider.VideoMessagesPublic = VideoMessagesPublic

Slider.Mobile = Mobile;

export default Slider;
