import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';
import c from 'classnames'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import CloseIcon from 'mdi-react/CloseIcon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import Footer from '_legacy/components/Footer'
import styles from './gettingStarted.module.scss'
import bg from './bg.jpg'
import video from './bg_video.mp4'

function GettingStarted({ active }) {
  const [activeSlide, setActiveSlide] = useState(1)
  const [activeInterval, setActiveInterval] = useState(null)
  const [stopped, setStopped] = useState(false)
  const t = useGetTranslate()

  useEffect(() => {
    if (active === true && !stopped) {
      const interval = setInterval(() => {
        setActiveSlide((prev) => {
          let nextSlide = prev + 1

          if (nextSlide > 5) {
            nextSlide = 1
          }

          return nextSlide
        })
      }, 20000)

      setActiveInterval(interval)
    }
  }, [active])

  const clickSlide = (num) => {
    clearInterval(activeInterval)
    setStopped(true)
    setActiveSlide(num)
    setActiveInterval(null)
  }

  const stopInterval = () => {
    clearInterval(activeInterval)
    setStopped(true)
    setActiveInterval(null)
    setActiveSlide(null)
  }

  let videoData = '#';
  if(!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    navigator.userAgent
  )) {
    videoData = video;
  }

  return (
    <div className={c(styles.section, 'section')} id="getting-started">
      <video className={styles.videoBg} autoPlay loop muted poster={bg}>
        <source src={videoData} type="video/mp4" />
      </video>
      <div className={c(styles.sectionWrapper, 'wrapper', 'content-wrapper')}>
        <div className={styles.title}>{t('home.getting-started.title')}</div>
        <div className={styles.boxes}>
          <div className={styles.titlesListContainer}>
            <div className={styles.titlesList}>
              <div
                className={c(styles.titlesItem, activeSlide === 1 && styles.active, activeInterval === null && activeSlide === null && styles.inactiveInterval)}
                onClick={() => clickSlide(1)}
              >
                1. {t('home.getting-started.profile-images')}
                <ChevronRightIcon className={c(styles.chevronRight, activeSlide !== null && styles.chevronRightHidden)} />
              </div>
              <div
                className={c(styles.titlesItem, activeSlide === 2 && styles.active, activeInterval === null && activeSlide === null && styles.inactiveInterval)}
                onClick={() => clickSlide(2)}
              >
                2. {t('home.getting-started.register')}
                <ChevronRightIcon className={c(styles.chevronRight, activeSlide !== null && styles.chevronRightHidden)} />
              </div>
              <div
                className={c(styles.titlesItem, activeSlide === 3 && styles.active, activeInterval === null && activeSlide === null && styles.inactiveInterval)}
                onClick={() => clickSlide(3)}
              >
                3. {t('home.getting-started.images-videos')}
                <ChevronRightIcon className={c(styles.chevronRight, activeSlide !== null && styles.chevronRightHidden)} />
              </div>
              <div
                className={c(styles.titlesItem, activeSlide === 4 && styles.active, activeInterval === null && activeSlide === null && styles.inactiveInterval)}
                onClick={() => clickSlide(4)}
              >
                4. {t('home.getting-started.setup-offers')}
                <ChevronRightIcon className={c(styles.chevronRight, activeSlide !== null && styles.chevronRightHidden)} />
              </div>
              <div
                className={c(styles.titlesItem, activeSlide === 5 && styles.active, activeInterval === null && activeSlide === null && styles.inactiveInterval)}
                onClick={() => clickSlide(5)}
              >
                5. {t('home.getting-started.invite-followers')}
                <ChevronRightIcon className={c(styles.chevronRight, activeSlide !== null && styles.chevronRightHidden)} />
              </div>
            </div>
            {activeSlide !== null && (
              <div className={styles.closIcon}>
                <CloseIcon onClick={() => stopInterval()} />
              </div>
            )}
          </div>
          <div className={styles.texts}>
            <div className={styles.sep} />
            <div className={c(activeSlide !== 1 && styles.hidden)}>
              <div className={styles.imagesTitle}>{t('home.getting-started.profile-images1')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.profile-images2')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.profile-images3')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.profile-images4')}</div>
              <div className={styles.imagesDesc}>{t('home.getting-started.profile-images5')}</div>
            </div>
            <div className={c(activeSlide !== 2 && styles.hidden)}>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.register1')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.register2')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.register3')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.register4')}</div>
            </div>
            <div className={c(activeSlide !== 3 && styles.hidden)}>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.images-videos1')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.images-videos2')}</div>
            </div>
            <div className={c(activeSlide !== 4 && styles.hidden)}>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.setup-offers1')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.setup-offers2')}</div>
            </div>
            <div className={c(activeSlide !== 5 && styles.hidden)}>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.invite-followers1')}</div>
              <div className={styles.imagesText}><ChevronRightIcon /> {t('home.getting-started.invite-followers2')}</div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

GettingStarted.propTypes = {
  active: PropTypes.bool,
}

GettingStarted.defaultProps = {
  active: false,
}

export default GettingStarted
