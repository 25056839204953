import React from 'react'

import Collapse from 'components/Collapse'
import CookiesDetailsItem from 'components/Cookies/CookiesDetailsItem'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const CookiesDetails = () => {
  const t = useGetTranslate()

  const data = [
    {
      title: t('cookies.necessary.title'),
      text: t('cookies.necessary.text'),
      list: [
        {
          title: 'Stripe',
          withBorder: true,
          items: [
            {
              title: '__stripe_mid',
              text: t('cookies.necessary.list.stripe.mid.text'),
              expire: t('cookies.necessary.list.stripe.mid.expire'),
            },
            {
              title: '__stripe_sid',
              text: t('cookies.necessary.list.stripe.sid.text'),
              expire: t('cookies.necessary.list.stripe.sid.expire'),
            },
            {
              title: 'm.stripe.com',
              text: t('cookies.necessary.list.stripe.m.text'),
              expire: t('cookies.necessary.list.stripe.m.expire'),
            }
          ]
        },
        {
          title: 'Onfido',
          withBorder: true,
          items: [
            {
              title: 'onfido-js-sdk-woopra',
              text: t('cookies.necessary.list.onfido.onfido-js.text'),
              expire: t('cookies.necessary.list.onfido.onfido-js.expire'),
            },
          ]
        }
      ]
    },
    {
      title: t('cookies.preferences.title'),
      text: t('cookies.preferences.text'),
      list: [
        {
          title: 'Cookiebot',
          withBorder: true,
          items: [
            {
              title: 'preferences.language',
              text: t('cookies.preferences.list.cookiebot.language.text'),
              expire: t('cookies.preferences.list.cookiebot.language.expire'),
            },
            {
              title: 'preferences.currency',
              text: t('cookies.preferences.list.cookiebot.currency.text'),
              expire: t('cookies.preferences.list.cookiebot.currency.expire'),
            },
          ]
        }
      ]
    },
    {
      title: t('cookies.statistics.title'),
      text: t('cookies.statistics.text'),
      list: [
        {
          title: 'Google Tag Manager',
          withBorder: true,
          items: [
            {
              title: '_ga',
              text: t('cookies.preferences.list.googletagmanager.ga.text'),
              expire: t('cookies.preferences.list.googletagmanager.ga.expire'),
            },
          ]
        }
      ]
    },
    {
      title: t('cookies.marketing.title'),
      text: t('cookies.marketing.text'),
      list: [
        {
          title: 'Google Tag Manager',
          withBorder: true,
          items: [
            {
              title: '_ga_F2YCQY2G',
              text: t('cookies.marketing.list.googletagmanager.ga.text'),
              expire: t('cookies.marketing.list.googletagmanager.ga.expire'),
            },
          ]
        }
      ]
    },
  ]

  return (
    <div className="cookies-details">
      {data.map(({ title, list, text }) => (
        <Collapse {...{ title, text }} key={title}>
          {list.map(({ title, text, withBorder, items }) => (
            <Collapse key={title} {...{ title, text, withBorder }}>
              {items.map(({ title, ...rest }) => (
                <CookiesDetailsItem key={title} {...{ title, ...rest }} />
              ))}
            </Collapse>
          ))}
        </Collapse>
      ))}
    </div>
  )
};

export default CookiesDetails
