import React from 'react'
import { BulkOrdersAction } from 'shared'
import { useParams } from 'react-router-dom'
import isFunction from 'lodash/isFunction'

import { notify } from 'utils/notify'
import { getTalentId } from 'utils/user'
import { useSetLiveInPersonOrderDetails } from './store'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useSetTalentCalendarFilters,
  useSetTalentCalendarSlots,
} from 'components/Calendar/TalentCalendar/state/store/useCalendar'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { orderRequestAction, getLiveInPersonOccurrence, updateLiveInPersonOccurrenceTime } from './api'

export function useFetchLiveInPersonLessonOrderDetails() {
  const { orderId: occurrenceId } = useParams()

  const setBookingsDetails = useSetLiveInPersonOrderDetails((prev, next) => next)
  const addTimeSlot = useSetTalentCalendarSlots((prev, slot) => [...(prev || []), slot])
  const setTimeFilters = useSetTalentCalendarFilters((prev, startTime, endTime) => ({ ...prev, startTime, endTime }))

  return React.useCallback(() => {
    getLiveInPersonOccurrence({ occurrenceId })
      .then((details) => {
        setBookingsDetails(details)
      })
  }, [occurrenceId, setBookingsDetails, addTimeSlot, setTimeFilters])
}

export function useUpdateLiveInPersonOccurrenceTime() {
  const { orderId: occurrenceId } = useParams()
  const fetchLiveInPersonLessonOrderDetails = useFetchLiveInPersonLessonOrderDetails()

  return React.useCallback(
    date => {
      updateLiveInPersonOccurrenceTime({ occurrenceId, data: date })
        .then(() => {
          fetchLiveInPersonLessonOrderDetails()
        })
    },
    [occurrenceId]
  )
}

export function useAcceptOrderRequest() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchLiveInPersonLessonOrderDetails = useFetchLiveInPersonLessonOrderDetails()

  return React.useCallback(
    ordersIds => {
      orderRequestAction({ talentId, actionType: BulkOrdersAction.ACCEPT, ordersIds })
        .then(() => {
          notify(t('order-details.notification.accepted'))
          fetchLiveInPersonLessonOrderDetails()
        })
    },
    [talentId, t, fetchLiveInPersonLessonOrderDetails]
  )
}

export function useRejectOrderRequest() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const fetchLiveInPersonLessonOrderDetails = useFetchLiveInPersonLessonOrderDetails()

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ ordersIds, reason, message }) => {
    const body = {
      rejectReason: reason,
      rejectReasonDescription: message
    }
    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.REJECT,
      ordersIds,
      body
    })
      .then(() => {
        notify(t('order-details.notification.rejected'))
        fetchLiveInPersonLessonOrderDetails()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t, fetchLiveInPersonLessonOrderDetails]
  )
}

export function useCancelOrder(onSuccess) {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)

  const handleResponseError = useHandleResponseError()

  return React.useCallback(({ offerId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message
    }
    orderRequestAction({
      talentId,
      actionType: BulkOrdersAction.CANCEL,
      ordersIds: offerId,
      body
    })
      .then(() => {
        notify(t('order-details.notification.cancelled'))
        if (isFunction(onSuccess)) onSuccess()
      })
      .catch(err => {
        handleResponseError(err)
        console.warn(err)
      })
  },
  [talentId, t]
  )
}
