import { _delete, _get, _put } from 'api/utils'

export const getFiles = ({ fileLibraryId, query }) => _get(`/file-libraries/${fileLibraryId}/files`, { needAuthorization: true, query });

export const getBulkFileLibrary = ({ types, fileLibrariesIds, limit, page }) => _get(
  '/file-libraries/bulk/files',
  { needAuthorization: true, query: { types, fileLibrariesIds, limit, page } }
)

export const removeFile = ({ fileLibraryId, fileId }) => _delete(
  `/file-libraries/${fileLibraryId}/files/${fileId}`,
  { needAuthorization: true }
)

export const bulkRemoveFile = ({ filesIds, fileLibrariesIds }) => _delete(
  '/file-libraries/bulk/files',
  { needAuthorization: true, query: { filesIds, fileLibrariesIds } }
)

export const getMedia = ({ query }) => _get(
  '/file-libraries/bulk/media',
  { needAuthorization: true, query }
)

export const setMediaToPromotional = ({ fileId, promotionalMediaTypes }) => _put(
  `/files/${fileId}/set-to-promotional`,
  { needAuthorization: true, query: { promotionalMediaTypes } }
)

