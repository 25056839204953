import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import compose from 'lodash/fp/compose';
import { useParams } from 'react-router-dom';
import { api } from 'shared';
const { errors: errorsKeysSubmit } = api.auth.resetPassword.submit;

import { submitAdapter } from './utils/submitAdapter';
import { resetPassword as resetPasswordApi } from 'api/appApi/auth/resetPassword';
import { useFetch } from 'store/hooks/useFetch';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import {
  errorsConfigForResetPassword,
  getAsyncValidationConfigForReset,
} from 'utils/services/validators/passwordValidators';

import SubmitButton from '../RegisterForms/shared/components/SubmitButton';
import PasswordBlock from '../RegisterForms/shared/components/Password';
import { useLogout } from 'requests/auth';
import { useIsUserLoggedIn } from 'store/hooks/globalState/useUser';

const ResetPasswordForm = (props) => {
  const { key } = useParams();
  const t = useGetTranslate();
  const isUserLoggedIn = useIsUserLoggedIn();
  const logout = useLogout();
  const methods = useForm({ mode: 'onChange', criteriaMode: 'all' });
  const [{ error, data, isLoading }, resetPassword] = useFetch(resetPasswordApi);
  const onSubmit = compose(resetPassword, submitAdapter(key));

  // handle successful submit
  React.useEffect(() => {
    if (data) {
      props.handleModal(true);
      if (isUserLoggedIn) {
        logout()
      }
    }
  }, [data, isLoading]);

  // handle submit error
  React.useEffect(() => {
    if (error?.name === errorsKeysSubmit.REPEAT_PASSWORD) {
      methods.setError('password', { type: 'server.same', message: '' });
    }
  }, [error]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <PasswordBlock
          rePasswordLabel={t('reset-password.form.re-password.label')}
          passwordLabel={t('reset-password.form.password.label')}
          errorsConfig={errorsConfigForResetPassword}
          passwordValidationConfig={getAsyncValidationConfigForReset(key)}
        />
        <SubmitButton text={t('shared.words.submit')} disabled={isLoading} />
      </form>
    </FormProvider>
  );
};

export default ResetPasswordForm;
