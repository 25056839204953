import React from 'react';
import PropTypes from 'prop-types';
import kebabCase from 'lodash/kebabCase';
import styled from 'styled-components';

// components
import { Row } from '_legacy/common/Template';
import { RadioButtons } from '_legacy/common/Radio';

import { types } from 'shared';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const profileTypes = [
  types.UserType.TALENT_USER,
  types.UserType.AGENT,
  types.UserType.MANAGER,
];

const ProfileManagers = ({ options, name = 'type', disabled, ...props }) => {
  const t = useGetTranslate();

  const optionsWithTranslate = profileTypes.map(type => ({
    value: type,
    label: t(`register-user.form.profile-type.${kebabCase(type)}.label`),
  }));

  return (
    <ProfileManagersWrapper
      nameBlock={t('register-user.form.profile-type.label')}
      component={
        <RadioButtons
          name={name}
          disabled={disabled}
          options={options || optionsWithTranslate}
          customValidation={{ required: 'shared.words.required' }}
          {...props}
        />
      }
    />
  );
};

ProfileManagers.propTypes = {
  options: PropTypes.array,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

const ProfileManagersWrapper = styled(Row)`
  .row-left {
    padding-top: 0;
  }
`;

export default ProfileManagers;
