import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'

const ResourceCenterRouter = ({ config }) => (
  <HashRouter hashType="noslash">
    <Switch>
      {/* Default router should be at the end */}
      {[...config]
        .reverse()
        .map(({
          to,
          Component,
          isDefault,
          activeHash,
          ...props
        }, index) => (
          isDefault ? (
            <Route
              key={index}
              render={() => <Component {...props} shouldScroll={activeHash === to} />}
            />
          ) : (
            <Route
              key={to}
              exact
              path={`/${to}`}
              render={() => <Component {...props} shouldScroll={activeHash === to} />}
            />
          )
        ))}
    </Switch>
  </HashRouter>
)

ResourceCenterRouter.propTypes = {
  to: PropTypes.string,
  Component: PropTypes.element,
  isDefault: PropTypes.bool,
  activeHash: PropTypes.string,
}

export default ResourceCenterRouter
