import React from 'react'
import { isNil, isEmpty, merge } from 'lodash'
import PropTypes from 'prop-types'

import { getTalentId } from 'utils/user'
import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { checkAvailabilityToEdit } from 'api/appApi/talent/offers'

import { useDeleteOffer } from 'requests/manage-offerings'

import ModalWithControls from 'portals/ModalWithControls'
import CancelModal from '../OfferingDetails/components/CancelModal'

const DeleteOfferingModal = ({ offerIds, isOpen, onClose }) => {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const initialOfferIds = React.useRef(null)

  const deleteOffer = useDeleteOffer()
  const [hasOrders, setHasOrders] = React.useState(null)
  const [isCancelOfferingsModalOpen, openCancelOfferingsModal, closeCancelOfferingsModal] = useModal(false)

  const onDelete = React.useCallback(() => {
    onClose()
    deleteOffer({ offerIds })
  }, [offerIds])

  const onCancel = React.useCallback(() => {
    onClose()
    openCancelOfferingsModal()
  }, [])

  const cancelHandler = React.useCallback(({ reason, message }) => {
    deleteOffer({
      offerIds: initialOfferIds.current,
      cancelReason: reason,
      cancelReasonDescription: message
    })
  }, [initialOfferIds.current])

  React.useEffect(() => {
    if (!isEmpty(offerIds)) {
      initialOfferIds.current = offerIds

      checkAvailabilityToEdit({ talentId, offerIds })
        .then(() => {
          setHasOrders(false)
        })
        .catch(({ context }) => {
          setHasOrders(context?.hasBookedParticipants || context?.hasPendingBookingRequests)
        })
    } else {
      setHasOrders(null)
    }
  }, [offerIds])

  const modalBodyProps = React.useMemo(() => {
    const baseProps = {
      header: t('offer-details.delete.header'),
    }

    let additionalProps
    if (hasOrders) {
      additionalProps = {
        message: t('offer-details.delete.pending-booking-requests'),
        buttonsConfig: [
          { text: t('offer-details.delete.buttons.reject-all'), handleOnClick: onCancel },
          { text: t('offer-details.delete.buttons.go-back'), handleOnClick: onClose, primary: true },
        ],
      }
    } else {
      additionalProps = {
        message: t('offer-details.delete.without-request'),
        buttonsConfig: [
          { text: t('offer-details.delete.buttons.yes'), handleOnClick: onDelete },
          { text: t('offer-details.delete.buttons.cancel'), handleOnClick: onClose, primary: true },
        ],
      }
    }

    return merge(additionalProps, baseProps)
  }, [t, hasOrders])

  return (
    <div>
      <ModalWithControls
        isOpen={isOpen}
        onClose={onClose}
        isLoading={isNil(hasOrders)}
        {...modalBodyProps}
      />
      <CancelModal
        isOpen={isCancelOfferingsModalOpen}
        onClose={closeCancelOfferingsModal}
        orderId={offerIds}
        cancelHandler={cancelHandler}
        buttonText="cancel-reason.cancel-occurrence.delete-button"
      />
    </div>
  )
}

DeleteOfferingModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  offerIds: PropTypes.array,
}

export default React.memo(DeleteOfferingModal)
