import { kebabCase } from 'lodash'

import { TypesOfDays } from 'shared'

const {
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  BOTH,
} = TypesOfDays;

const daysTimesTypeOptions = [
  ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR,
  ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME,
  BOTH,
]

const getDayTypeLabel = type => `create-experience.form.days-times-type.options.${kebabCase(type)}`;

export const getDaysTimesTypeOptions = t =>
  daysTimesTypeOptions.map(type => ({
    value: type,
    label: t(getDayTypeLabel(type)),
  }))
