import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates.js'


const LessonBackdrop = () => {
  const t = useGetTranslate()

  return (
    <div className="lesson-backdrop-wrapper">
      <i className='icon-frame icon-camera' />
      <h3>{t('lessons.label.media.title')}</h3>
    </div>
  )
}

export default LessonBackdrop
