// Base imports
import React from 'react'
import { useParams, Redirect } from 'react-router-dom'
import set from 'lodash/set'

// Routing
import { routesList } from 'router/routesMap'

// Store (Hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { useFetchVideoMessageBookingsDetails } from './state/request'
import { useSetVideoMessageRequestDraft } from '_legacy/containers/VideoMessageRequest/state/store'
import { useVideoMessageBookingsDetails, useSetVideoMessageBookingsDetails } from './state/store'

// Utils
import { getDaysTo, getLocale } from 'utils/date'
import { getSaveFullName, getTalentLink, getUserTimeZone } from 'utils/user'
import { getSteps } from 'components/OfferingDetails/components/StatusBar/utils/getSteps'
import { getAficionadoRefundText, getCancelText } from 'components/OfferingDetails/utils'

// Components
import StatusBar from 'components/OfferingDetails/components/StatusBar'
import VideoMessageBookingDownload from './components/VideoMessageBookingDownload'
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingDetailsReviewStars from 'components/OfferingDetails/components/OfferingDetailsReviewStars'
import OfferingDetailsWaiting from 'components/OfferingDetails/components/OfferingDetailsWaiting'

// Legacy components
import UserInfo from '_legacy/components/UserInfo'
import PageLoader from '_legacy/common/Template/PageLoader'
import { getVideoMessageBookingConfig } from './utils'
import CancelStatusWithContact from '../../components/CancelStatusWithContact'

const VideoMessageBookingsDetails = () => {
  const t = useGetTranslate()
  const { orderId } = useParams()
  const timeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()

  // state
  const order = useVideoMessageBookingsDetails()
  const clearOrder = useSetVideoMessageBookingsDetails(() => null)
  const setDraftRequest = useSetVideoMessageRequestDraft((_, next) => next)
  const { review, occurrence, number, statusTimeline } = order || {}

  // requests
  const fetchBookingsDetails = useFetchVideoMessageBookingsDetails()

  // effects
  React.useEffect(() => {
    fetchBookingsDetails()
  }, [])

  React.useEffect(() => () => {
    clearOrder()
  }, [])

  if (!occurrence) {
    return <PageLoader />
  }

  const { pendingAction, upload, talent, message, recipient, status, offer } = occurrence

  const daysLeft = getDaysTo(new Date(pendingAction?.due), { locale: getLocale(userLang) })

  if (status === 'DRAFT') {
    setDraftRequest(occurrence)
    return <Redirect to={routesList.videoMessageRequestDraft(talent.plaqueNickname, offer.sequentialId)} />
  }

  const occurrenceWithTimeLine = set(occurrence, 'order.statusTimeline', statusTimeline)
  const cancelText = getCancelText({ t, ...order })
  const refundText = getAficionadoRefundText({ t, refundRequests: order.refundRequests })
  const c = getVideoMessageBookingConfig(order)

  return (
    <div className="video-message-booking od">
      <OfferingDetailsTitle
        typeHeader={t('shared.offers.video-message')}
        title={`${t('booking-details.main.title')} #${number}`}
      />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <StatusBar steps={getSteps(occurrenceWithTimeLine, timeZone, true)} />

          <div className="od__inner-wrapper">
            <div className="od__row od__column-m mb-32">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('booking-details.talent.title')}</h6>
                <UserInfo user={talent} />
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.video-for')}</h6>
                <p className="od--main-text od--accented">{recipient?.name}</p>
              </div>

              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.occasion')}</h6>
                <p className="od--main-text od--accented">{message?.occasionType}</p>
              </div>
            </div>
            <div className="od__row od__column-m">
              <div className="od__column">
                <h6 className="od--uppercase-title mb-16">{t('order.video-message.script')}</h6>
                <p className="od--main-text">{message?.script}</p>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="od--shadow-box">
            {c.isShowCanceled && (
              <CancelStatusWithContact
                refundText={refundText}
                cancelText={cancelText}
                title={t('shared.offers.video-message')}
              />
            )}

            {c.isShowWaiting && (
              <OfferingDetailsWaiting
                waitingTitle={t('booking-details.your-video-message')}
                waitingText={t('booking-details.time-left.text')}
                timeLeft={daysLeft}
              >
                {/* Hidden for now due to changes from Chris */}
                {/* <Button
                  primary
                  stretch
                  paddingVertical={16}
                  text={t('booking-details.cancel.title')}
                  handleOnClick={() => openCancelModal()}
                /> */}
              </OfferingDetailsWaiting>
            )}

            {c.isShowCompleted && (
              <div className="od--centered">
                <div className="od--with-padding od__column">
                  <h3 className="od--uppercase-title mb-24">{t('booking-details.your-video-message')}</h3>
                  <VideoMessageBookingDownload media={upload?.file} />
                </div>
                <OfferingDetailsReviewStars
                  review={review}
                  orderId={orderId}
                  onSuccess={fetchBookingsDetails}
                  userLink={getTalentLink(talent)}
                  userFullName={getSaveFullName(talent)}
                  offerType={t('shared.offers.video-message')}
                  reviewType={'order.answer.rate.by'}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default VideoMessageBookingsDetails
