import React from 'react'
import PropTypes from 'prop-types'
import cond from 'lodash/cond'
import hasIn from 'lodash/fp/hasIn'
import invoke from 'lodash/fp/invoke'
import T from 'lodash/fp/T'
import get from 'lodash/get'
import noop from 'lodash/noop'
import classnames from 'classnames'

import { ReactComponent as MuteIcon } from 'assets/media-controls/mute.svg'
import { ReactComponent as PlayIcon } from 'assets/media-controls/play.svg'
import { ReactComponent as PauseIcon } from 'assets/media-controls/pause.svg'
import { ReactComponent as FullScreenIcon } from 'assets/media-controls/full-screen.svg'
import { ReactComponent as VolumeIcon } from 'assets/media-controls/volume.svg'
import transparent from 'assets/transparent.png'

import { isIos } from 'utils/isBrowser'
import { useFullscreen } from 'store/hooks/useFullscreen'

import Poster from '../Poster'

import './CustomVideoPlayer.scss'

const { warn } = console

export const CustomVideoPlayer = React.memo(function CustomVideoPlayer({
  className = '',
  file,
  isStoped,
  isVolumed,
  setGlobalIsPlay = noop,
  autoPlay,
  muted
}) {
  const ref = React.useRef()
  const isFullscreen = useFullscreen()
  const [localIsPlay, setLocalIsPlay] = React.useState(false)
  const [localIsVolume, setLocalIsVolume] = React.useState(true)

  const PlayControl = localIsPlay ? PauseIcon : PlayIcon
  const VolumeControl = localIsVolume ? VolumeIcon : MuteIcon

  const triggerFullScreen = cond([
    [hasIn('requestFullscreen'), invoke('requestFullscreen')],
    [hasIn('webkitRequestFullscreen'), invoke('webkitRequestFullscreen')],
    [hasIn('mozRequestFullScreen'), invoke('mozRequestFullScreen')],
    [hasIn('msRequestFullscreen'), invoke('msRequestFullscreen')],
    [T, () => warn('Fullscreen API is not supported.')],
  ])

  const onFullScreen = () => {
    if (ref.current) {
      triggerFullScreen(ref.current)
    }
  }

  // play/pause
  React.useEffect(() => {
    if (isStoped) setLocalIsPlay(false)
  }, [isStoped, setLocalIsPlay])

  React.useEffect(()=>{
    if(autoPlay && muted)
    {
      setLocalIsPlay(true)
      setLocalIsVolume(false)
    }
  },[])
  React.useEffect(() => {
    if (localIsPlay && ref.current.paused) ref.current.play()
    if (!localIsPlay && !ref.current.paused) ref.current.pause()
    setGlobalIsPlay(localIsPlay)
  }, [localIsPlay, ref])

  // turn-on/turn-off
  React.useEffect(() => {
    if (isVolumed) setLocalIsVolume(true)
  }, [isVolumed, setLocalIsVolume])

  React.useEffect(() => {
    if (localIsVolume && !ref.current.volume) ref.current.volume = 1
    if (!localIsVolume && ref.current.volume) ref.current.volume = 0
  }, [localIsVolume, ref])

  if (!file) return null

  const sources =
    get(file, 'file.preview.sources') || get(file, 'preview.sources')

  const classNames = classnames(
    'custom-video-player',
    { play: !!localIsPlay },
    className,
    { fullscreen: !!isFullscreen }
  )

  return (
    <div className={classNames}>
      <Poster sources={sources} />
      <video
        ref={ref}
        preload="none"
        poster={transparent}
        src={get(file, 'file.fullUrl') || get(file, 'fullUrl')}
        onEnded={() => setLocalIsPlay(false)}
        tabIndex="-1"
        webkitplaysinline={isIos()}
        autoPlay={autoPlay}
        muted={muted}
        playsInline={isIos()}
        x-webkit-airplay={isIos() || 'allow'}
        controlsList={isIos() || 'nodownload'}
        controls={isIos()}
      />

      {(!isStoped && !isIos()) && (
        <VolumeControl
          className="video-icon volume-icon"
          onClick={() => setLocalIsVolume(p => !p)}
        />
      )}
      {(!isVolumed && !isIos()) && (
        <PlayControl
          className="video-icon play-icon"
          onClick={() => setLocalIsPlay(p => !p)}
        />
      )}
      {(!isStoped && !isIos()) && (
        <FullScreenIcon
          className="video-icon fullscreen-icon"
          onClick={onFullScreen}
        />
      )}
    </div>
  )
})

CustomVideoPlayer.propTypes = {
  className: PropTypes.string,
  file: PropTypes.object,
  isStoped: PropTypes.bool,
  isVolumed: PropTypes.bool,
}
