import { _get } from 'api/utils'

export const fetchResultsByKeywords = ({ query, isAuthorized }) => _get(
  '/searches/results/keywords/search',
  { needAuthorization: isAuthorized, query }
)

export const getRecentSearches = () => _get(
  '/auth/me/history/searches/results/keywords',
  { needAuthorization: true }
)
