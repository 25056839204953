import React from 'react'
import styled from 'styled-components'
import kebabCase from 'lodash/kebabCase'
import get from 'lodash/get'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useFetchTalentInvitation } from 'requests/talent-invitation'
import { useTalentInvitation } from 'store/hooks/globalState/talentInvitation'

import { Container, Title, SubTitle } from '_legacy/common'
import LoaderTemplate from '_legacy/common/Template/LoaderTemplate'
import TalentInvitationForm from '_legacy/components/RegisterForms/TalentInvitation'


const TalentInvitation = () => {
  const t = useGetTranslate()
  const fetchTalentInvitation = useFetchTalentInvitation()
  const linkType = useTalentInvitation(state => get(state, 'data'))

  React.useEffect(() => {
    fetchTalentInvitation()
  }, [])

  if (!linkType) {
    return <LoaderTemplate />
  }

  return (
    <StyledWrapper>
      <Container>
        <SubTitle className="sub-title">
          {t(`register-user.form.profile-type.${kebabCase(linkType)}.label`)}
        </SubTitle>
        <Title className="title">{t('talent-invitation.header')}</Title>
        <TalentInvitationForm />
      </Container>
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  .sub-title {
    margin-bottom: 10px;
  }

  .title {
    margin-bottom: 50px;
  }

  .radio-wrapper {
    display: flex;
    align-items: center;
  }

  .description {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Montserrat', sans-serif;
    color: #626473;
    line-height: 1.625;
    margin-top: 16px;
    margin-bottom: 0;
  }
`

export default TalentInvitation
