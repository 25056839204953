import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import TwoLineString from '_legacy/components/Table/table-components/types/common/TwoLineString'
import { getLocationDetails, getLocationName } from 'utils/location'

// todo: merge with mange orders location
const normalizeOccurrence = ({ location, type }) => ({
  type,
  locations: location?.places || [location?.place]
})

const Location = React.memo(({ isLoading, ...rest }) => {
  const t = useGetTranslate()
  const occurrence = normalizeOccurrence(rest)

  return (
    <TwoLineString
      isLoading={isLoading}
      mainText={t(getLocationName(occurrence))}
      subText={t(getLocationDetails(occurrence))}
    />
  )
})

export default Location
