import React from 'react'
import { useParams } from 'react-router'

import {
  fetchBookingsDetails as fetchHeading
} from 'components/OfferingDetails/BookingsDetails/state/api'

import { useUser } from 'store/hooks/globalState/useUser'
import { useSetVideoVoiceOverLessonBookingDetails as useSetHeading } from './store.js'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getUserId } from 'utils/user'

export function useFetchVideoVoiceOverLessonBookingDetails() {
  const { orderId } = useParams()
  const userId = useUser(getUserId)

  const setHeading = useSetHeading((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchHeading({ userId, orderId })
      .then(setHeading)
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [userId, orderId, setHeading])
}
