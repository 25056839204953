import React from 'react';
import PropTypes from 'prop-types'

const OfferingDetailsTitle = ({
  typeHeader,
  title,
}) => {
  return (
    <div className="od__title wrapper content-wrapper">
      <h3>{typeHeader}</h3>
      <h1>{title}</h1>
    </div>
  )
}

OfferingDetailsTitle.propTypes = {
  typeHeader: PropTypes.string,
  title: PropTypes.string,
}

export default OfferingDetailsTitle;
