import React from 'react';
import PropTypes from 'prop-types';

const LocationIcon = ({ className }) => {
  return (
    <svg
      viewBox="0 0 20 28"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.99935 0.666626C4.84602 0.666626 0.666016 4.84663 0.666016 9.99996C0.666016 17 9.99935 27.3333 9.99935 27.3333C9.99935 27.3333 19.3327 17 19.3327 9.99996C19.3327 4.84663 15.1527 0.666626 9.99935 0.666626ZM9.99935 13.3333C8.15935 13.3333 6.66602 11.84 6.66602 9.99996C6.66602 8.15996 8.15935 6.66663 9.99935 6.66663C11.8393 6.66663 13.3327 8.15996 13.3327 9.99996C13.3327 11.84 11.8393 13.3333 9.99935 13.3333Z" />
    </svg>
  );
};

LocationIcon.defaultProps = {
  className: '',
};

LocationIcon.propTypes = {
  className: PropTypes.string,
};

export default LocationIcon;
