import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get'
import { types } from 'shared';
import { useFormContext } from 'react-hook-form';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import Modal from '../../components/Modal/Modal';
import DropZone from '../../components/DropZone/DropZone';
import ModalHeader from '../../components/Modal/ModalHeader';
import ModalSubmitButton from '../../components/Modal/SubmitButton';
import SingleSelectFileList from './SingleSelectFileList';

const { VIDEO, PHOTO } = types.file.Types


const SingleSelectLibraryModal = React.memo(({ isOpen, closeModal, name, onSubmit, dropZoneProps, libraryModalProps }) => {
  const { watch } = useFormContext()
  const value = watch(name)

  const t = useGetTranslate();
  const submitBtnText = get(libraryModalProps, 'submitBtnText', t('shared.words.upload'))
  const header = get(libraryModalProps, 'header', t('video-message.form.media.placeholder.header'))

  return (
    <Modal isOpen={isOpen} onClose={closeModal} isMobileFullScreen>
      <ModalHeader>{header}</ModalHeader>
      <DropZone {...dropZoneProps} />
      <SingleSelectFileList name={name} enableTypes={libraryModalProps?.enableTypes} />
      <ModalSubmitButton disabled={!value} onClick={onSubmit}>{submitBtnText}</ModalSubmitButton>
    </Modal>
  );
});

SingleSelectLibraryModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  name: PropTypes.string,
  enableTypes: PropTypes.oneOf([VIDEO, PHOTO]),
  dropZoneProps: PropTypes.shape({
    accept: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    hintComponent: PropTypes.element,
    multiple: PropTypes.bool,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    maxSize: PropTypes.number,
  }),
  libraryModalProps: PropTypes.shape({
    header: PropTypes.string,
    submitBtnText: PropTypes.string,
  }),
};

export default SingleSelectLibraryModal;
