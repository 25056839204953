import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import round from 'lodash/round'

import { getPriceCurrencyLabel, getPriceLabel } from './price-config'

import { useCurrencyCode, useCurrencyRate } from 'store/hooks/globalState/useCurrency'

import './index.scss'


const Price = React.memo(({ price, withCurrency = false, rounded = false, className }) => {
  const classes = cx('_price', className)
  const currencyRate = useCurrencyRate()
  const currencyCode = useCurrencyCode()

  const currencyLabel = getPriceCurrencyLabel(currencyCode)
  const formattedPrice = round(Number(price) * Number(currencyRate), rounded ? 2 : 0)
  const priceLabel = getPriceLabel(formattedPrice, currencyCode)

  return (
    <div className={classes}>
      <span itemProp="price" className="_price__value">
        {priceLabel}
      </span>
      {withCurrency && (
        <data itemProp="priceCurrency" value={currencyCode} className="_price__currency">
          &nbsp;{currencyLabel}
        </data>
      )}
    </div>
  )
})

Price.propTypes = {
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  withCurrency: PropTypes.bool,
  rounded: PropTypes.bool,
  className: PropTypes.string,
}

export default Price
