
import map from 'lodash/map'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import { api } from 'shared'
const { FOLLOWERS_COUNT, NAME } = api.searches.results.talents.search.SortField

import { filters } from '_legacy/components/Table/Filters/config'
import mileToKm from 'utils/mileToKm'
import { languagesList } from '_legacy/components/Register/config'

const offeringTypesFilter = api.talents.talent.offers.query.TypeFilter

const searchSortFieldOptions = [
  { value: NAME, label: 'search.list-header.select.name.label' },
  { value: FOLLOWERS_COUNT, label: 'search.list-header.select.popular.label' },
]

export const searchSubFiltersConfig = [
  {
    field: 'sortField',
    options: searchSortFieldOptions,
  },
]

const getOfferingTransKey = value =>
  `table.filters.offerings-types.${kebabCase(value)}`

const offeringsTypesOptions = map(offeringTypesFilter, (value, key) => ({
  label: getOfferingTransKey(value),
  value: key,
}))

export const locationKmRadiusOptions = [
  { value: mileToKm(5), label: 'search.filters.miles.5' },
  { value: mileToKm(25), label: 'search.filters.miles.25' },
  { value: mileToKm(50), label: 'search.filters.miles.50' },
  { value: mileToKm(100), label: 'search.filters.miles.200' },
  { value: 'UNLIMITED', label: 'search.filters.miles.unlimited' },
]

export const searchConfig = {
  type: filters.search,
  placeholder: 'table.filters.search',
  field: 'search',
}

export const offeringTypesConfig = {
  type: filters.select,
  placeholder: 'search.filters.available-offering.placeholder',
  field: 'availableOfferingTypesIn',
  options: offeringsTypesOptions,
}

export const profileTypeConfig = {
  type: filters['categories-select'],
  field: 'categoryIn',
}

const shouldShowRangeFilter = filterState => {
  return get(filterState, 'location.value')
}

export const locationKmRadiusConfig = {
  placeholder: 'search.filters.miles.placeholder',
  type: filters.radio,
  field: 'locationKmRadius',
  options: locationKmRadiusOptions,
  shouldShow: shouldShowRangeFilter,
}

export const languageConfig = {
  type: filters.select,
  placeholder: 'search.filters.languages.placeholder',
  field: 'languagesIn',
  options: languagesList,
}
