import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row } from '_legacy/common/Template';
import Button from '_legacy/components/Button';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const SubmitButtons = ({ onDraft }) => {
  const t = useGetTranslate();

  return (
    <Row
      component={
        <SubmitWrapper>
          <Button
            text={t('shared.words.publish')}
            typeButton='submit'
            paddingHorizontal='45'
            paddingVertical='14'
          />
          <Button
            primary
            text={t('shared.words.draft')}
            type='button'
            handleOnClick={onDraft}
            paddingHorizontal='20'
            paddingVertical='14'
          />
        </SubmitWrapper>
      }
    />
  );
};

const SubmitWrapper = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  column-gap: 24px;
  @media only screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    grid-row-gap: 15px;
  }
`;

SubmitButtons.propTypes = {
  onDraft: PropTypes.func.isRequired,
};

export default SubmitButtons;
