import React, { useEffect, useRef } from 'react'
import { bool, func, number, oneOf, shape, string } from 'prop-types'
import { clamp, get } from 'lodash/fp'

import { types } from 'shared'

import Tooltip from '_legacy/components/Tooltip'

import './CardText.scss'

import { fitText } from 'utils/fixtext'

const FONT_SIZE_SCALING_BREAKPOINT = 1920

export const CardText = ({
  onClick,
  firstName,
  lastName,
  date,
  name,
  mediaType,
  isMain,
  wrapperDimensions,
}) => {
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const mediaTypeClass = mediaType === types.file.Types.VIDEO ? 'video' : ''

  useEffect(() => {
    const width = get('width', wrapperDimensions) || 360
    const newFontSize = clamp(9, 18, (width * 16) / 360)

    const root = document.documentElement
    root.style.setProperty(
      '--slider-section-card-text__font-size',
      `${newFontSize}px`
    )

    const minFontSize = window.matchMedia(
      `(min-width: ${FONT_SIZE_SCALING_BREAKPOINT}px)`
    ).matches
      ? 18
      : 12

    if (firstNameRef.current) {
      fitText(firstNameRef.current, {
        minFontSize: minFontSize,
      })
    }
    if (lastNameRef.current) {
      fitText(lastNameRef.current, {
        minFontSize: minFontSize,
      })
    }
  }, [wrapperDimensions])

  return (
    <div
      onClick={onClick}
      className={`slider-section__card-text ${mediaTypeClass}`}
    >
      <span
        ref={firstNameRef}
        className="first-name clamp-long-word"
      >
        {firstName}
      </span>

      <div ref={lastNameRef} className="last-name clamp-long-word">
        {lastName}
      </div>

      {name && (
        <Tooltip disable={!isMain} tooltipElement={name} className="name">
          <span className="clamp-line-2">{name}</span>
        </Tooltip>
      )}

      {date && <span className="date">{date}</span>}
    </div>
  )
}

CardText.propTypes = {
  onClick: func,
  firstName: string,
  lastName: string,
  name: string,
  date: string,

  mediaType: oneOf([types.file.Types.VIDEO, types.file.Types.PHOTO]),
  isMain: bool,
  wrapperDimensions: shape({
    width: number,
  }),
}
