import { get, sortBy } from 'lodash'
import { differenceInWeeks, addDays, subMilliseconds, min, isFuture } from 'date-fns'
import { propEq, where, isNil, compose, allPass, negate, anyPass, pipe, eq } from 'lodash/fp'
import {
  OrderStatus,
  OfferOccurrenceStatus,
  TalentOfferPendingActionType,
  RejectOrderReason,
  CancelOrderReason,
  RefundRequestStatus
} from 'shared'

import { getDaysOrHoursDuration } from 'utils/date.js'
import { reasonsAdapter } from './components/RejectModal/utils'
import { reasonsCancelAdapter } from './components/CancelModal/utils'


// Number of days when offering is allowed to be accepted or cancelled before being rejected by system
const offeringBookingLimitTime = 6

/**
 * Time left to approve offerings calculations
 * @param {obj} occurrence - Occurence instance
 *   @param {string} startTime - Occurence start date (in ISO format)
 *   @param {number} minBookingTimeBeforeExperience - Time before occurrence starts but new offerings aren't allowed (in ms)
 *   @param {string} earliestRequestDate - Earliest not expired offerings creation date (in ISO format)
 */
export const getTimeLeftString = occurrence => {
  const { startTime, minBookingTimeBeforeExperience, earliestRequestDate } = occurrence

  if (!earliestRequestDate || !startTime) return

  // HARD LIMIT
  const occurenceTime = new Date(startTime)
  const isMoreThanWeekLeft = differenceInWeeks(occurenceTime, new Date()) >= 1

  const lastPossibleBookingTime = isMoreThanWeekLeft
    ? occurenceTime
    : subMilliseconds(occurenceTime, minBookingTimeBeforeExperience || 0)

  // SOFT LIMIT
  // Substract 1 sec to prevent earliestRequestDate and Date.now() to have the same value (for a very fast clickers :))
  // Because date-fns interval has the minimum value of seconds
  // TODO: check out different solution
  const earliestOfferingTime = subMilliseconds(new Date(earliestRequestDate), 1000)
  const earliestOfferingLastPossibleBookingTime = addDays(earliestOfferingTime, offeringBookingLimitTime)

  // We need to display either
  // HARD LIMIT (time when all offerings rejected automatically because of occurence start time)
  // or
  // SOFT LIMIT (time to approve the offerings that will be rejected first)
  // depending on what will be sooner
  const bookingTime = min([lastPossibleBookingTime, earliestOfferingLastPossibleBookingTime])

  const timeLeftToBook = getDaysOrHoursDuration(new Date(), bookingTime)

  return timeLeftToBook
}


export const toDate = date => (date ? new Date(date) : undefined)
export const calculatePercentage = (base, part) => Math.floor((part / base) * 100)

// List of order statuses
export const isDraft = propEq('orderStatus', OrderStatus.DRAFT)
export const isUnderReview = propEq('orderStatus', OrderStatus.UNDER_REVIEW)
export const isBooked = propEq('orderStatus', OrderStatus.BOOKED)
export const isApproved = propEq('orderStatus', OrderStatus.APPROVED)
export const isRejected = propEq('orderStatus', OrderStatus.REJECTED)
export const isCompleted = propEq('orderStatus', OrderStatus.COMPLETED)
export const isAdminReview = propEq('orderStatus', OrderStatus.ADMIN_REVIEW)
export const isCancelled = propEq('orderStatus', OrderStatus.CANCELED)
export const isClosed = propEq('orderStatus', OrderStatus.CLOSED)
export const isRefunded = propEq('orderStatus', OrderStatus.REFUNDED)

// List of occurrence statuses
export const isOccurrenceDraft = propEq('status', OfferOccurrenceStatus.DRAFT)
export const isOccurrenceOpen = propEq('status', OfferOccurrenceStatus.OPEN)
export const isOccurrenceCompleted = propEq('status', OfferOccurrenceStatus.COMPLETED)
export const isOccurrenceCancelled = propEq('status', OfferOccurrenceStatus.CANCELLED)
export const isOccurrenceInProgress = propEq('status', OfferOccurrenceStatus.IN_PROGRESS)
export const isOccurrenceAwaitingConfirm = propEq('status', OfferOccurrenceStatus.AWAITING_AFICIONADO_CONFIRM)
export const isOccurrenceFullyBooked = propEq('status', OfferOccurrenceStatus.FULLY_BOOKED)
export const isOccurrenceExpired = propEq('status', OfferOccurrenceStatus.EXPIRED)

// List of pending actions
export const isPendingTypeReviewBooking = propEq('pendingAction.type', TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST)
export const isPendingTypeHold = propEq('pendingAction.type', TalentOfferPendingActionType.HOLD)
export const isPendingTypeLeaveReview = propEq('pendingAction.type', TalentOfferPendingActionType.LEAVE_REVIEW)
export const isPendingTypeAficionadoConfirm = propEq('pendingAction.type', TalentOfferPendingActionType.AFICIONADO_CONFIRM)
export const isPendingTypeWaitForPayment = propEq('pendingAction.type', TalentOfferPendingActionType.WAIT_FOR_PAYMENT)
export const isPendingTypeSetTime = propEq('pendingAction.type', TalentOfferPendingActionType.SET_TIME)
export const isPendingTypeUploadVideo = propEq('pendingAction.type', TalentOfferPendingActionType.UPLOAD_VIDEO)

// Formatted status values
/* Some of statuses require may need additional check for example:
  expired order have "status" expired but "orderStatus" can be approved
  so we need to look to occurrence "status", "orderStatus", "pendingAction" and "start/end time" to know final order status
*/

export const orderFinished = anyPass([isOccurrenceCompleted, isOccurrenceExpired, isOccurrenceCancelled])

export const isVideoUploading = compose(Boolean, isPendingTypeUploadVideo)

export const isWaitingForApprove = allPass([isUnderReview, isPendingTypeReviewBooking, negate(orderFinished)])
export const isWaitingForUpload = allPass([isBooked, isPendingTypeUploadVideo])
export const isBookedOrApproved = anyPass([isBooked, isApproved])
export const isCompletedOrClosed = anyPass([isCompleted, isClosed])
export const isCompletedOrAdminReview = anyPass([isCompleted, isAdminReview])
export const isCancelledOrAdminReview = anyPass([isCancelled, isAdminReview])

export const isDateFuture = compose(isFuture, toDate)
export const isWithoutTime = where({
  startTime: isNil,
  endTime: isNil,
})
export const isWithTime = where({
  startTime: isDateFuture,
  endTime: isDateFuture,
})


export const isAcceptedWithoutTime = allPass([
  isBookedOrApproved, isWithoutTime
])

export const isAcceptedWithTime = allPass([
  isApproved, isWithTime
])

export const isWaitingForStart = allPass([
  isBooked, isWithTime
])

export const isWaitingOrInProgress = anyPass([
  isWaitingForStart, isOccurrenceInProgress
])

export const isOnHold = allPass([isPendingTypeHold, isApproved, negate(isOccurrenceInProgress), negate(orderFinished)])
export const isShowHoldStatus = anyPass([isOnHold, isUnderReview])
export const isShowExperienceHoldStatus = anyPass([isOnHold, negate(isUnderReview)])

export const isShowUploadBlock = allPass([negate(isCancelled), isVideoUploading])

export const isShowEscrowBlock = allPass([negate(isCancelled), isPendingTypeWaitForPayment])

export const isShowCanceledBlock = anyPass([isCancelled, isOccurrenceCancelled, isAdminReview])
export const isShowRejectedBlock = anyPass([isRejected, isOccurrenceExpired])


export const isCancelable = anyPass([
  propEq('hasBookedParticipants', true),
  propEq('hasPendingBookingRequests', true),
  propEq('hasPassedOccurrences', true)])


export const getReasonText = ({ t, rejectedReason, rejectedReasonDescription }) => {
  return rejectedReason !== RejectOrderReason.OTHER ? t(reasonsAdapter[rejectedReason]) : rejectedReasonDescription
}

export const getCancelText = ({ t, cancelReason, cancelReasonDescription }) => {
  return cancelReason !== CancelOrderReason.OTHER ? t(reasonsCancelAdapter[cancelReason]) : cancelReasonDescription
}

export const getCharitiesTitle = (t, totalOrderPrice, totalCharityFee) => {
  return `${t('order.description.charities.title')} (${calculatePercentage(totalOrderPrice, totalCharityFee)}%)`
}

export const getParticipantTitle = ({ t, participantsCount, maxNumberOfParticipants }) => {
  return `${participantsCount} ${t('shared.words.of')} ${maxNumberOfParticipants}`
}

export const getPriceTitle = ({ t, pricePerParticipant }) => {
  return `$${pricePerParticipant} ${t('currency.usd')}`
}


export const getTalentRefundText = ({ t, refundRequests }) => {
  const sortedRequests = sortBy(refundRequests, 'createdAt')
  const reason = get(sortedRequests, [0, 'reason'])
  const status = get(sortedRequests, [0, 'status'])

  const talentRefundTitlePatterns = {
    [RefundRequestStatus.APPROVED]: t('refund-request.talent.approved'),
    [RefundRequestStatus.REJECTED]: `${t('refund-request.talent.rejected')} ${reason}`,
    [RefundRequestStatus.PENDING]: t('refund-request.talent.pending'),
  }

  return get(talentRefundTitlePatterns, status, '')
}

export const getAficionadoRefundText = ({ t, refundRequests }) => {
  const sortedRequests = sortBy(refundRequests, 'createdAt')
  const reason = get(sortedRequests, [0, 'reason'])
  const status = get(sortedRequests, [0, 'status'])

  const talentRefundTitlePatterns = {
    [RefundRequestStatus.APPROVED]: t('refund-request.aficionado.approved'),
    [RefundRequestStatus.REJECTED]: `${t('refund-request.aficionado.rejected')} ${reason}`,
    [RefundRequestStatus.PENDING]: t('refund-request.aficionado.pending'),
  }

  return get(talentRefundTitlePatterns, status, '')
}

export const getComplaintText = ({ complaints }) => {
  return get(complaints, [0, 'message'])
}

// ****** Common Occurrence Predicates ******
const isTimeSelected = where({
  startTime: Boolean,
  endTime: Boolean,
})

const isTimeNotSelected = where({
  startTime: isNil,
  endTime: isNil,
})

export class OrderStatusFactory {
  constructor(
    orderStatusSelector,
    occurrenceStatusSelector,
    occurrenceSelector,
    orderPendingActionTypeSelector
  ) {
    // oder statuses
    this.isOrderBooked = pipe(orderStatusSelector, eq(OrderStatus.BOOKED))
    this.isOrderUnderReview = pipe(orderStatusSelector, eq(OrderStatus.UNDER_REVIEW))
    this.isOrderAdminReview = pipe(orderStatusSelector, eq(OrderStatus.ADMIN_REVIEW))
    this.isOrderApproved = pipe(orderStatusSelector, eq(OrderStatus.APPROVED))
    this.isOrderCanceled = pipe(orderStatusSelector, eq(OrderStatus.CANCELED))
    this.isOrderCompleted = pipe(orderStatusSelector, eq(OrderStatus.COMPLETED))
    this.isOrderClosed = pipe(orderStatusSelector, eq(OrderStatus.CLOSED))
    this.isOrderRejected = pipe(orderStatusSelector, eq(OrderStatus.REJECTED))

    // occurrence statuses
    this.isOccurrenceInProgress = pipe(occurrenceStatusSelector, eq(OfferOccurrenceStatus.IN_PROGRESS))

    // pending action statuses
    this.isOrderWaitForPayment = pipe(orderPendingActionTypeSelector, eq(TalentOfferPendingActionType.WAIT_FOR_PAYMENT))
    this.isOrderUploadVideo = pipe(orderPendingActionTypeSelector, eq(TalentOfferPendingActionType.UPLOAD_VIDEO))
    this.isReviewBookingRequest = pipe(orderPendingActionTypeSelector, eq(TalentOfferPendingActionType.REVIEW_BOOKING_REQUEST))

    // status compose
    this.isOrderCompletedOrClosed = anyPass([this.isOrderCompleted, this.isOrderClosed])
    this.isOrderCanceledOrAdminReview = anyPass([this.isOrderCanceled, this.isOrderAdminReview])

    // occurrence time
    this.isOccurrenceWithTime = pipe(occurrenceSelector, isTimeSelected)
    this.isOccurrenceWithoutTime = pipe(occurrenceSelector, isTimeNotSelected)

    // for in person exp & live in person lesson
    this.isOrderApprovedWithTime = allPass([this.isOrderApproved, this.isOccurrenceWithTime])
    this.isOrderApprovedWithoutTime = allPass([this.isOrderApproved, this.isOccurrenceWithoutTime])

    // for video chat & live virtual lesson
    this.isBookedWithTime = allPass([this.isOrderBooked, this.isOccurrenceWithTime])
    this.isBookedWithoutTime = allPass([this.isOrderBooked, this.isOccurrenceWithoutTime])
  }
}
