import { _get, _post } from 'api/utils';

export const getLiveVirtualEvent = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/live-virtual-events/public/${talentPlaqueNickname}/${offerSequentialId}`);

export const getLiveVirtualEventOccurrence = ({ talentPlaqueNickname, offerSequentialId }) =>
  _get(`/offers/live-virtual-events/occurrences/public/${talentPlaqueNickname}/${offerSequentialId}`);

export const bookLiveVirtualEventByOccurrenceId = ({ offerLiveVirtualEventOccurrenceId, ...body }) =>
  _post(`offers/live-virtual-events/occurrences/${offerLiveVirtualEventOccurrenceId}/book`, { needAuthorization: true, body })
