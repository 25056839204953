import { TalentOffersType } from 'shared'
import { __, includes, merge } from 'lodash/fp'
import { filteredSchedulingOptions, schedulingOptions } from '../../configs/search/offerings/configs'

const { VIDEO_MESSAGE, VIDEO_VOICE_OVER_LESSON, FOR_COMMERCIAL_USE, VIDEO_CHAT, LIVE_IN_PERSON_LESSON, VIRTUAL_LESSON, READY_MADE_LESSON } = TalentOffersType

const isIn = items => includes(__, items)

const setDisable = merge({ disabled: true })
const unsetDisable = merge({ disabled: false })

const setSchedulingOptions = merge(__, { options: schedulingOptions })
const setFilteredOptions = merge(__, { options: filteredSchedulingOptions })

export function handleDisable(offeringTypesIn, config) {
  return [
    {
      field: 'scheduleOptionsIn',
      isInOffer: isIn([
        VIDEO_MESSAGE,
        VIDEO_VOICE_OVER_LESSON,
        FOR_COMMERCIAL_USE,
        READY_MADE_LESSON
      ]),
      onSuccess: setDisable,
      onFail: unsetDisable,
    },
    {
      field: 'date',
      isInOffer: isIn([
        VIDEO_MESSAGE,
        VIDEO_VOICE_OVER_LESSON,
        FOR_COMMERCIAL_USE,
        VIDEO_CHAT,
        VIRTUAL_LESSON,
        LIVE_IN_PERSON_LESSON,
        READY_MADE_LESSON,
      ]),
      onSuccess: setDisable,
      onFail: unsetDisable,
    },
    {
      field: 'duration',
      isInOffer: isIn([
        VIDEO_MESSAGE,
        VIDEO_VOICE_OVER_LESSON,
        FOR_COMMERCIAL_USE,
        READY_MADE_LESSON
      ]),
      onSuccess: setDisable,
      onFail: unsetDisable,
    },
    {
      field: 'participants',
      isInOffer: isIn([
        READY_MADE_LESSON
      ]),
      onSuccess: setDisable,
      onFail: unsetDisable
    },
    {
      field: 'charitiesIdsIn',
      isInOffer: isIn([
        READY_MADE_LESSON
      ]),
      onSuccess: setDisable,
      onFail: unsetDisable
    },
    {
      field: 'scheduleOptionsIn',
      isInOffer: isIn([
        VIDEO_CHAT,
        VIRTUAL_LESSON,
        LIVE_IN_PERSON_LESSON
      ]),
      onSuccess: setFilteredOptions,
      onFail: setSchedulingOptions,
    },
  ].reduce(
    (acc, { field, isInOffer, onSuccess, onFail }) =>
      acc.map(item => (item.field === field ? (isInOffer(offeringTypesIn) ? onSuccess(item) : onFail(item)) : item)),
    config
  )
}
