import React from 'react';
import PropTypes from 'prop-types';
import Common from './Common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const TimePicker = (props) => {
  const t = useGetTranslate()
  return (
    <Common
      showTimeSelect
      showTimeSelectOnly
      showYearDropdown
      timeIntervals={15}
      timeCaption={t('calendar.new-event-form.time-picker.time.caption')}
      dateFormat="h:mm aa"
      {...props}
    />
  )
};

TimePicker.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.string,
  minTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  maxTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default TimePicker;
