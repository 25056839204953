import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

import Modal from 'components/Modal'

const SubModalPortal = ({ children, isOpen, onClose, showCloseButton }) =>
  isOpen ? (
    ReactDOM.createPortal(
      <Modal
        isSubModal
        isOpen={isOpen}
        onClose={onClose}
        showCloseButton={showCloseButton}
      >
        {children}
      </Modal>,
      document.getElementById('sub-modal')
    )
  ) : null

SubModalPortal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  showCloseButton: PropTypes.bool,
}

export default SubModalPortal
