// Modules
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { useUnsubscribeFromUpdates } from './state/requests'

const UnsubscribeFromUpdates = () => {
  const history = useHistory()
  const { email } = useParams()
  const handleUnsubscribe = useUnsubscribeFromUpdates()
  React.useEffect(() => {
    handleUnsubscribe({ email });
    history.replace('/')
  }, [])

  return null
}

export default UnsubscribeFromUpdates
