import React from 'react'
import styled, { keyframes } from 'styled-components'

export default function ArrowScrollDown ({ className, clickHandler }) {
  return (
    <StyledWrapper className={className} onClick={clickHandler}>
      <span className="down-arrow-1" />
      <span className="down-arrow-2" />
    </StyledWrapper>
  )
}

const move = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateY(10px) rotate(45deg);
  }
`

const StyledWrapper = styled.div`
  display: block;
  position: absolute;
  margin: auto;
  left: 50%;
  bottom: 20px;
  transform: translateX(-50%);
  z-index: 200;
  opacity: .3;
  @media only screen and (max-width: 1024px)  {
    bottom: 105px;
  }
  span {
    display: block;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    margin: 0 0 3px 5px;
    animation: ${move} 1.3s infinite;
    cursor: pointer;
  }
  .down-arrow-1 {
    margin-top: 6px;
    animation-delay: 0.15s;
  }
  .down-arrow-2 {
    margin-top: 6px;
    animation-delay: 0.3s;
  }
`
