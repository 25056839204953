import React from 'react'

import { useRoomState } from '../../hooks/useRoomState'

import ToggleSettingsButton from '../Buttons/ToggleSettingsButton'
import ToggleAudioButton from '../Buttons/ToggleAudioButton'
import ToggleVideoButton from '../Buttons/ToggleVideoButton'
import ToggleChatButton from '../Buttons/ToggleChatButton'
import EndCallButton from '../Buttons/EndCallButton'

export default function MenuBar() {
  const roomState = useRoomState()
  const isReconnecting = roomState === 'reconnecting'

  return (
    <footer className="menu-bar content-wrapper">
      <div className='menu-bar__main-controls'>
        <ToggleAudioButton disabled={isReconnecting} />
        <ToggleVideoButton disabled={isReconnecting} />
        <ToggleSettingsButton disabled={isReconnecting} />
        <EndCallButton />
        {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && (
          <ToggleChatButton />
        )}
      </div>
    </footer>
  )
}
