import React from 'react';
import PropTypes from 'prop-types';
import { get, pipe, eq } from 'lodash/fp';
import { types } from 'shared';

import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Slider from '../../../Slider';
import Slide from '../../../Slide';

// eslint-disable-next-line no-unused-vars
import Actions from './Actions';
import Empty from './Empty';
import Overlay from '../../LiveExperiences/Desktop/Overlay';

import { getFullMonthDate } from 'utils/date'
import { parseDate } from 'utils/services/timezone'
import { getUserTimeZone } from 'utils/user'


export const isTypeVideo = pipe(
  get('type'),
  eq(types.FileType.VIDEO)
)
export const getMedia = get('media')
export const getMediaFile = get('media.file')
export const isVideo = pipe(getMediaFile, isTypeVideo)

const LiveVirtualEvents = ({ firstName, covers = [], plaqueNickname }) => {
  const t = useGetTranslate()
  const [globalIsPlay, setGlobalIsPlay] = React.useState(false)

  const timeZone = useUser(getUserTimeZone)

  // eslint-disable-next-line no-unused-vars
  const slides = covers.map((cover, i) => (
    <Slide.DesktopSlide
      key={`live-virtual-events-${i}`}
      offerSequentialId={cover.sequentialId}
      file={getMedia(cover)}
      isPromo={cover.isPromo}
      setGlobalIsPlay={setGlobalIsPlay}
      overlay={!globalIsPlay ?
        <Overlay
          key={cover.id}
          date={getFullMonthDate(parseDate(cover.date, timeZone))}
          name={cover.name}
          video={isVideo(cover)}
        /> : null
      }
    />
  ))

  return (
    <Slider
      size="big"
      title={t('talent-public-view.sliders.liveVirtualEvents.header.label')}
      titleFontSize={27}
      withGradientOverhead={true}
      empty={<Empty firstName={firstName} />}
      // slides={slides}
      // Actions={Actions}
      slides={[]}
      Actions={() => null}
      plaqueNickname={plaqueNickname}
    />
  );
}

LiveVirtualEvents.propTypes = {
  firstName: PropTypes.string.isRequired,
  plaqueNickname: PropTypes.string.isRequired,
  covers: PropTypes.array.isRequired,
};

export default LiveVirtualEvents
