import React from 'react'
import PropTypes from 'prop-types'

const KeywordSearchListItem = ({ icon, item, onClick }) => {
  return (
    <div className="keyword-search__list--item" onClick={onClick}>
      {icon}
      <p>{item}</p>
    </div>
  )
}

KeywordSearchListItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  item: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]),
  onClick: PropTypes.func,
}

export default KeywordSearchListItem
