import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

// initial state
export const charitiesInitialState = [{ charity: null, percent: '' }]
export const charitiesIdInitialState = [{ name: null, id: null }]

// utils
export const withBraces = string => '(' + string + ')'

export const charitiesPreparator = item => {
  const id = get(item, 'id')
  const name = get(item, 'name')
  const ein = get(item, 'ein')

  if (id && name) {
    return {
      value: item.id,
      label: `${name}${ein ? withBraces(ein) : ''}`,
    }
  }
}

// form
export const submitCharitiesAdapter = (formCharities = []) => {
  return formCharities
    .filter(c => c.charity?.value && c.percent)
    .map(({ charity, percent }) => ({
      charityId: charity.value,
      percent: +percent,
    }))
}

export const submitCharitiesWithoutPercentAdapter = (formCharities = []) => {
  return formCharities
    .filter(c => c?.value)
    .map(({ value }) => value)
}

// back
export const getCharitiesWithoutPercentAdapter = backendCharities => {
  if (isEmpty(backendCharities)) {
    return charitiesIdInitialState
  }

  return backendCharities.map(charity => charitiesPreparator(charity))
}

export const getCharitiesAdapter = backendCharities => {
  if (isEmpty(backendCharities)) {
    return charitiesInitialState
  }

  return backendCharities.map(({ charity, percent }) => ({
    charity: charitiesPreparator(charity),
    percent: +percent,
  }))
}
