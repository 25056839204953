import React from 'react'

import UserInfo from '_legacy/components/UserInfo'
import Loader from '_legacy/components/Table/table-components/types/common/Loader'

export default React.memo(function Aficionado({ isLoading, user }) {

  if (!user) return null

  if (isLoading) return <Loader />

  return (
    <UserInfo user={user} />
  )
})
