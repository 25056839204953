import React from 'react'
import cx from 'classnames'

import Icon from 'assets/icons/Icon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import useTrack from '../../hooks/useTrack'
import usePublications from '../../hooks/usePublications'
import useIsTrackSwitchedOff from '../../hooks/useIsTrackSwitchedOff'
import useParticipantIsReconnecting from '../../hooks/useParticipantIsReconnecting'

export default function ParticipantInfo({
  participant,
  onClick,
  children,
  isLocalParticipant,
  hideParticipant,
}) {
  const t = useGetTranslate()
  const publications = usePublications(participant)

  const videoPublication = publications.find(p => !p.trackName.includes('screen') && p.kind === 'video')

  const isVideoEnabled = Boolean(videoPublication)

  const videoTrack = useTrack(videoPublication)
  const isVideoSwitchedOff = useIsTrackSwitchedOff(videoTrack)

  const isParticipantReconnecting = useParticipantIsReconnecting(participant)

  return (
    <div onClick={onClick} className={cx('participant-info', { 'participant-info--hide': hideParticipant })}>
      <div className={'classes.infoContainer'}>
        <div className={'classes.infoRowBottom'}>
          <span className={'classes.identity'}>
            <span className={'classes.typeography'}>
              {participant.identity}
              {isLocalParticipant && ' (You)'}
            </span>
          </span>
        </div>
      </div>

      <div className="participant-info__inner">
        {(!isVideoEnabled || isVideoSwitchedOff) && (
          <div className="participant-info__avatar-container">
            <Icon.DefaultAvatar className="participant-info__avatar-icon" />
          </div>
        )}

        {isParticipantReconnecting && (
          <div className="participant-info__reconnecting">{t('video-chat.participant.reconnecting')}</div>
        )}

        {children}
      </div>
    </div>
  )
}
