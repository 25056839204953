import React from 'react'
import { isNull, identity } from 'lodash'

import { useModal } from 'store/hooks/useModal'
import { useUser } from 'store/hooks/globalState/useUser'
import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'
import { fetchTalentCalendarSlots } from '../requests/talent-calendar'
import { fetchTalentPersonalCalendarBusinessHours } from '../requests/business-hourse'

import { talentCalendarConfig } from '../configs/calendar'
import { calendarFilters } from '../configs/filters'
import { getUserTimeZone } from 'utils/user'

import ModalPortal from 'portals/ModalPortal'
import BaseCalendar from '../BaseCalendar'
import MenuDots from '../../Calendar/components/MenuDots'
import WorkingHours from '../components/WorkingHours'
import CalendarModalHeader from '../components/CalendarModalHeader'
import SelectedSlotSidebar from '../components/SelectedSlotSidebar/SelectedSlotSidebar'
import CategoriesSidebar from '../components/CategoriesSidebar'
import { getEventTimeFormats } from '../configs/hour-formats'
import { getLocale } from 'utils/date'

const TalentPublicViewCalendar = ({ isOpen, onClose, header = '', talentId, slotMapper = identity, options = [] }) => {
  const t = useGetTranslate()
  const calendarRef = React.useRef(null)
  const timeZone = useUser(getUserTimeZone)
  const userLang = useUserLang()
  const locale = getLocale(userLang)

  // ************ calendar state ************
  const [selectedEvent, setSelectedEvent] = React.useState(null)
  const [events, setEvents] = React.useState({
    lockEvents: [],
    backgroundEvents: [],
  })
  const [businessHours, setBusinessHours] = React.useState(null)
  const [filters, setFilters] = React.useState(calendarFilters)
  const [isDrawerOpen, openDrawer, closeDrawer] = useModal(false)

  // ************ dot menu ************
  const [hour12Format, setHourFormat] = React.useState(true)
  const [showWeekends, setShowWeekends] = React.useState(true)

  const toggleHourFormat = React.useCallback(() => setHourFormat(curr => !curr), [])
  const toggleShowWeekends = React.useCallback(() => setShowWeekends(curr => !curr), [])

  const openListOptions = React.useMemo(
    () => [
      { label: 'calendar.dot-button.hour-format', cb: toggleHourFormat },
      { label: 'calendar.dot-button.show-hide-weekends', cb: toggleShowWeekends },
    ],
    []
  )

  // ************ fetch fns ************
  const fetchCalendarSlots = React.useCallback(async () => {
    const slots = await fetchTalentCalendarSlots({ talentId, filters })
    setEvents(slotMapper(slots))
  }, [talentId, filters])

  const fetchBusinessHours = React.useCallback(async () => {
    const businessHours = await fetchTalentPersonalCalendarBusinessHours({ talentId })
    setBusinessHours(businessHours)
  }, [talentId])

  // ************ calendar navigation handlers ************
  const onNextButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.next()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onPrevButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.prev()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  const onTodayButton = React.useCallback(() => {
    const calendar = calendarRef.current.getApi()
    calendar.today()

    setFilters(prev => ({
      ...prev,
      startTime: calendar.view.activeStart,
      endTime: calendar.view.activeEnd,
    }))
  }, [])

  // ************ calendar events handlers ************
  const onEventClick = React.useCallback(e => {
    if (!e?.event?.extendedProps?.locked) {
      setSelectedEvent(e.event)
      openDrawer()
    }
  }, [])

  // ************ effects ************
  React.useEffect(() => {
    if (!isNull(businessHours)) {
      fetchCalendarSlots()
    }
  }, [businessHours, fetchCalendarSlots, filters, talentId])

  React.useEffect(fetchBusinessHours, [fetchBusinessHours, talentId])

  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="tpvc">
        <CalendarModalHeader>{header}</CalendarModalHeader>
        <WorkingHours
          businessHours={businessHours}
          timezone={timeZone}
          hour12Format={hour12Format}
          userLang={userLang}
        />
        <div className="tpvc__wrapper">
          <div className="tpvc__info">
            <SelectedSlotSidebar
              isOpen={isDrawerOpen}
              close={closeDrawer}
              selectedEvent={selectedEvent}
              hour12Format={hour12Format}
            />
            <CategoriesSidebar
              options={options}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
          <div className="tpvc__calendar calendar calendar--pr32">
            <MenuDots optionsList={openListOptions} />
            <BaseCalendar
              events={events}
              businessHours={businessHours}
              weekends={showWeekends}
              customButtons={{
                prev: { click: onPrevButton },
                next: { click: onNextButton },
                today: { click: onTodayButton, text: t('calendar.talent.today-btn') },
              }}
              ref={calendarRef}
              eventClick={onEventClick}
              slotEventOverlap={false}
              eventDurationEditable={false}
              selectAllow={() => false}
              {...getEventTimeFormats(hour12Format, locale)}
              {...talentCalendarConfig}
            />
          </div>
        </div>
      </div>
    </ModalPortal>
  )
}

export default TalentPublicViewCalendar
