// Modules
import React from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'

// Routing
import { routesList } from 'router/routesMap'
import { MobileSwitchRoute } from 'router/templates'

// Utils
import { useGetNavigationPanelItems } from './utils'

// Legacy components
import LoginModal from '_legacy/components/SignIn'

// Components
import BottomNavigationPanel from './BottomNavigationPanel'
import Search from './BottomNavigationComponents/Search'
import Talent from './BottomNavigationComponents/Talent'
import Offering from './BottomNavigationComponents/Offering'
import Location from './BottomNavigationComponents/Location'
import Login from './BottomNavigationComponents/Login'

const routesConfig = [
  {
    path: routesList.hashSearchBy(),
    component: Search,
  },
  {
    path: routesList.hashSeachByTalent(),
    component: Talent,
  },
  {
    path: routesList.hashSeachByOffering(),
    component: Offering,
  },
  {
    path: routesList.hashSeachByLocation(),
    component: Location,
  },
  {
    path: routesList.hashLogin(),
    component: Login,
    desktopComponent: LoginModal,
  }
]


const BottomNavigation = ({ mobileMenuActive, setMobileMenuActive }) => {
  const navigationPanelItems = useGetNavigationPanelItems(mobileMenuActive, setMobileMenuActive)

  return (
    <div className="bottom-navigation">
      <HashRouter
        hashType="noslash"
      >
        <Switch>
          {
            routesConfig
              .map((item, i) =>
                <MobileSwitchRoute key={i} exact {...item} />
              )
          }
          <Route component={null} />
        </Switch>
      </HashRouter>

      <BottomNavigationPanel items={navigationPanelItems} />
    </div>
  )
}

export default BottomNavigation
