import React from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

const mapMessageTypeToIcon = {
  info: <i className="icon-check_in_circle" />,
  success: <i className="icon-check_in_circle" />,
  warn: <i className="icon-close-icon" />,
  error: <i className="icon-close-icon" />,
};

const CloseButton = ({ closeToast }) => <span onClick={closeToast}>OK</span>;
CloseButton.propTypes = { closeToast: PropTypes.func };

/**
 * @param message - The notifcation message
 * @param {('info'|'success'|'warn'|'error')} type - The allowed type of the notification
 */
export const notify = (message, type = 'info', onClick, duration) => {
  let autoClose = duration || 5000;

  // infinity handle
  if (duration === false) {
    autoClose = false;
  }

  return toast[type](<>{mapMessageTypeToIcon[type]} {message}</>,{
    position: toast.POSITION.TOP_CENTER,
    autoClose,
    onClick,
    hideProgressBar: true,
    // closeButton: CloseButton,
  });
};
