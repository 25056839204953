import compose from 'lodash/fp/compose'

import { isValid } from 'date-fns'
import { format, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import tzdb from './tzdb.js'

const toDate = date => new Date(date)
const isNotNull = date => date !== null ? date : ''
const isValidDate = date => isValid(date) ? date: ''

const toDateAndValidate = compose(isValidDate, toDate, isNotNull)

export const UTCTimeZone = 'Etc/Utc'

/**
* @param {string} timeZone - timezone id, e.g. America/New_York, Europe/London, etc.
* @param {Date, string, number} date - js date object or date string or ms
*/


export const isValidTimeZone = timeZone => tzdb.includes(timeZone)

export const getZonedTimeToUtc = (date, timeZone) => zonedTimeToUtc(date, timeZone)
export const getUtcToZonedTime = (date, timeZone) => utcToZonedTime(date, timeZone)
export const getBrowserTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone
export const getISOString = date => date?.toISOString()

export const replaceUnderscore = (str = '') => str.replace(/_/g, '')

export const getTimezoneAbbr = timeZone => {
  const timezoneAbbr = format(getUtcToZonedTime(new Date(), timeZone), 'z', { timeZone })
  const timezoneLabel = replaceUnderscore(timeZone)
  return `${timezoneLabel} (${timezoneAbbr})`
}

// BE -> FE
// Transform UTC backend date to zoned time
export const parseDate = (date, timeZone) => {
  const _date = toDateAndValidate(date)

  if(!_date) return _date

  return getUtcToZonedTime(_date, timeZone)
}

// FE -> BE
// Transform zoned time to UTC for the backend
export const prepareDate = (date, timeZone) => {
  const _date = toDateAndValidate(date)

  if(!_date) return _date

  return getISOString(getZonedTimeToUtc(_date, timeZone))
}
