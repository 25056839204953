import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Row } from '_legacy/common';
import CharitiesContext from '_legacy/components/Form/Selects/CharitiesContext';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const Charities = ({ name }) => {
  const t = useGetTranslate();

  return (
    <StyledWrapper
      nameBlock={t('register.form.charities.label')}
      component={<CharitiesContext name={name} placeholder={t('register.form.charities.placeholder')} />}
    />
  );
};

const StyledWrapper = styled(Row)`
  .charity-tip {
    margin-top: 30px;
    padding-top: 0;
    color: #626473;
    a {
      color: #626473;
      text-decoration: none;
    }
  }
  .form-row__remove {
    flex: 0 0 26px;
    height: 26px;
    margin-left: 20px;
  }
  @media only screen and (max-width: 1024px) {
    .charities-list__item {
      &-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .react-select-container {
          width: 100%;
        }
        .form-row__remove {
          position: relative;
          right: 0;
          transform: translateY(0);
        }
      }
    }
  }
  @media only screen and (max-width: 991px) {
    .charities-list__item {
      position: relative;
      &:not(:first-of-type) {
        padding-right: 50px;
      }
      &-wrapper {
        position: static;
      }
    }
  }
`;

Charities.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Charities;
