import { always, cond, pipe, T, merge } from 'lodash/fp'
import { statuses } from '../utils'

const fillVideoMessageOrderConfig = merge({
  isShowUpload: false,
  isShowWaitForPayment: false,
  isShowCanceled: false,
  isShowClosed: false,
})

export const getVideoMessageOrderConfig = pipe(
  cond([
    [statuses.isOrderCanceledOrAdminReview, always({ isShowCanceled: true })],
    [statuses.isOrderClosed, always({ isShowClosed: true })],

    [statuses.isOrderUploadVideo, always({ isShowUpload: true })],
    [statuses.isOrderWaitForPayment, always({ isShowWaitForPayment: true })],

    [T, always({})],
  ]),
  fillVideoMessageOrderConfig
)
