import React from 'react'
import { useParams } from 'react-router'

import { fetchBookingsDetails as fetchBookingsDetailsApi } from 'components/OfferingDetails/BookingsDetails/state/api'

import { useSetLiveVideoChatBookingsDetails } from './store'
import { useUser } from 'store/hooks/globalState/useUser'
import { useHandleResponseError } from 'store/hooks/useHandleResponseError'

import { getUserId } from 'utils/user'

export function useFetchVideoChatBookingsDetails() {
  const { orderId } = useParams()
  const userId = useUser(getUserId)

  const setLiveVideoChatBookingsDetails = useSetLiveVideoChatBookingsDetails((_, next) => next)
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    fetchBookingsDetailsApi({ userId, orderId })
      .then(setLiveVideoChatBookingsDetails)
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [userId, orderId, setLiveVideoChatBookingsDetails])
}
