import set from 'date-fns/set'
import get from 'lodash/get'

const setEndDay = date => date ? set(new Date(date), { hours: 23, minutes: 59 }) : undefined

export const parseRangeDate = params => {
  const { date, ...rest } = params
  const dateFrom = get(date, 'from')
  const dateTo = setEndDay(get(date, 'to'))

  return {
    ...rest,
    dateFrom,
    dateTo,
  }
}

//TODO change fields for recurring list from BE
export const parseRangeDateRecurring = params => {
  const { date, ...rest } = params
  const startTimeFrom = get(date, 'from')
  const startTimeTo = setEndDay(get(date, 'to'))

  return {
    ...rest,
    startTimeFrom,
    startTimeTo,
  }
}

export const parseRangeDateForPromo = params => {
  const { date, ...rest } = params
  const expiresAtFrom= get(date, 'from')
  const expiresAtTo = setEndDay(get(date, 'to'))

  return {
    ...rest,
    expiresAtFrom,
    expiresAtTo,
  }
}
