import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import LoaderIndicator from '_legacy/components/Common/Loader';
import OfferingItem from '_legacy/containers/PromoCodes/components/Custom/OfferingItem';

import { useFetchOfferingsPromoCodes } from 'requests/promoCodes/promo-codes';
import useScroll from 'store/hooks/useScroll';
import { useOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes';

import { Loader, StyledList } from '_legacy/containers/PromoCodes/components/Custom/ModalCustomSelectsStyled';

const OfferingsList = React.memo(({
  bulkActionsConfig,
  listLength,
  filteredList,
}) => {
  const parentRef = React.useRef();
  const childRef = React.useRef();

  const fetchOfferingsPromoCodes = useFetchOfferingsPromoCodes();
  const page = useOfferingsPromoCodesFilters(state => get(state, 'page', 0));
  const count = useOfferingsPromoCodesFilters(state => get(state, 'count', 0));

  const hasMore = listLength < count;

  const fetchNext = () => fetchOfferingsPromoCodes({ page: page + 1 });

  useScroll({ parentRef, childRef, callback: fetchNext, hasMore });

  return (
    <StyledList ref={parentRef}>
      {filteredList.map((offering) => (
        <OfferingItem key={offering.id} offering={offering} bulkActionsConfig={bulkActionsConfig} />
      ))}
      <Loader ref={childRef}>
        {hasMore && <LoaderIndicator size={24} fill='#636583' />}
      </Loader>
    </StyledList>
  )
})

OfferingsList.propTypes = {
  bulkActionsConfig: PropTypes.object,
  filteredList: PropTypes.arrayOf(PropTypes.object),
  listLength: PropTypes.number,
}

export default OfferingsList
