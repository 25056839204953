import { _get } from 'api/utils'

export const getFavoriteTalents = ({ userId, query }) =>
  _get(`/users/${userId}/followed-talents`, {
    needAuthorization: true, query
  })

export const getFavoriteOffers = ({ userId, query }) =>
  _get(`/users/${userId}/followed-offers`, {
    needAuthorization: true, query
  })
