import React from 'react'
import Icon from 'assets/icons/Icon'

const CalendarModalHeader = ({ children, onInfoBtnClick }) => {
  return (
    <div className="calendar-modal-header">
      {onInfoBtnClick && (
        <button onClick={onInfoBtnClick} className="calendar-modal-header__info-btn" type="button">
          <Icon.BoxInfo className="calendar-modal-header__info-icon" />
        </button>
      )}
      <h3 className="calendar-modal-header__title">{children}</h3>
    </div>
  )
}

export default CalendarModalHeader
