import React from 'react'
import inRange from 'lodash/inRange'

let supportsPassive = false
try {
  window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
    get: function () { return (supportsPassive = true); }
  }));
} catch(e) {
  console.warn(e)
}

const wheelOpt = supportsPassive ? { passive: false } : false
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel'

let initialPoint

export default React.memo(function ScrollHandler({ scrollHandler, isBlocked, ignoreList }) {
  React.useEffect(() => {
    function handleScroll(e) {
      const inIgnoreList = !!ignoreList.filter(selector => !!e.target.closest(selector)).length

      if (!isBlocked && !inIgnoreList) {
        e.preventDefault()
        let direction = 0
        if (e.deltaY > 0) direction = 1
        if (e.deltaY < 0) direction = -1
        scrollHandler({ direction, deltaY: e.deltaY })
      }
    }
    window.addEventListener(wheelEvent, handleScroll, wheelOpt)
    return () => window.removeEventListener(wheelEvent, handleScroll, wheelOpt)
  }, [scrollHandler, isBlocked])

  React.useEffect(() => {
    function setInitialPoint(e) {
      initialPoint = e.touches[0].pageY
    }
    function handleScroll(e) {
      const deltaY = initialPoint - e.touches[0].pageY
      initialPoint = e.touches[0].pageY

      if (inRange(deltaY, -20, 20)) return

      let direction = 0
      if (deltaY > 0) direction = 1
      if (deltaY < 0) direction = -1
      scrollHandler({ direction, deltaY })
    }
    window.addEventListener('touchstart', setInitialPoint, wheelOpt)
    window.addEventListener('touchmove', handleScroll, wheelOpt)
    return () => {
      window.removeEventListener('touchstart', setInitialPoint, wheelOpt)
      window.removeEventListener('touchmove', handleScroll, wheelOpt)
    }
  }, [scrollHandler])

  return null
})
