import React, { useState } from 'react';
import PropTypes from 'prop-types';

import OutsideHandler from '../Form/OutsideHandler';
import Button from './Button';
import Icon from 'assets/icons/Icon';

export const SignUpButton = ({
  className,
  text,
  children,
  ...props
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(prevState => !prevState);
  };

  const onBlur = () => setIsDropdownOpen(false);

  const clickOutsideHandler = () => {
    setIsDropdownOpen(false);
  };

  return (
    <OutsideHandler handler={clickOutsideHandler} className="sign-up">
      <Button
        text={text}
        iconComponent={<Icon.ArrowDownSelect fill="white" />}
        iconStyleWr={isDropdownOpen && 'on'}
        classBtn={className}
        handleOnClick={toggleDropdown}
        {...props}
      />
      <div
        className={`sign-up__content ${isDropdownOpen ? 'on' : ''}`}
        onBlur={onBlur}
        onClick={toggleDropdown}
      >
        {children}
      </div>
    </OutsideHandler>
  );
};

SignUpButton.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
