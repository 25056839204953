import React from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import cond from 'lodash/cond';
import matches from 'lodash/matches';
import constant from 'lodash/constant';
import stubTrue from 'lodash/stubTrue';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { ErrorMessage } from '_legacy/common';
import CustomOffering from '_legacy/containers/PromoCodes/components/Custom/CustomOffering';
import Icon from 'assets/icons/Icon'

import { useStateModal } from 'store/hooks/globalState/useModal';
import { useOfferingsPromoCodesFilters } from 'store/hooks/globalState/useOfferingsPromoCodes';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { PROMO_CODES_SELECTS_OFFERINGS } from '_legacy/components/Modal/modals-types';

const Custom = () => {
  const t = useGetTranslate()
  const handleOpen = useStateModal(PROMO_CODES_SELECTS_OFFERINGS)
  const { formState: { isSubmitted } } = useFormContext()

  const selectedList = useOfferingsPromoCodesFilters(state => get(state, 'selected', []));

  const shouldShowError = isSubmitted && isEmpty(selectedList)

  return (
    <StyledWrapper>
      <StyledButton
        type='button'
        onClick={handleOpen}
        error={shouldShowError}
      >
        {t('promo-codes.form.custom.select-button')}
        <Icon.Arrow className='arrow' />
      </StyledButton>
      {!isEmpty(selectedList) &&
        <StyledList>
          {selectedList.map(offering => <CustomOffering key={offering.id} offering={offering} />)}
        </StyledList>
      }
      {shouldShowError && (
        <ErrorMessage className='error' message={t('promo-codes.form.custom.error.required')} />
      )}
    </StyledWrapper>
  )
}

const getBorderColor = cond([
  [matches({ error: true }), constant('red')],
  [stubTrue, constant('#BABBCD')],
]);

const StyledWrapper = styled.div`
  position: relative;
  margin-top: 24px;
  .error {
    position: absolute;
    top: 110%;
  }
`

const StyledList = styled.ul`
  margin-top: 20px;
  max-height: 300px;
  height: 100%;
  overflow-y: scroll;
`

const StyledButton = styled.button`
  position: relative;
  width: 100%;
  background-color: transparent;
  outline: none;
  text-align: left;
  border: 2px solid ${getBorderColor};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 18px;
  font-weight: 500;
  font-size: 18px;
  line-height: 1.625;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  &:hover {
    border-color: ${({ theme }) => theme.colors.blue};
  }
  .arrow {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translateY(-50%);
    fill: ${({ theme }) => theme.colors.lightGrey};
  }
`

export default Custom
