import React from 'react';
import PropTypes from 'prop-types';

const LanguageIcon = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 27 27"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 19.0014C26.9775 19.0764 26.9563 19.1514 26.935 19.2264C26.9138 19.3014 26.8925 19.3764 26.87 19.4514C26.4602 20.8011 25.1807 21.6909 23.6363 21.6959C20.3975 21.7009 17.1587 21.7009 13.9149 21.6909C13.66 21.6909 13.46 21.7559 13.2551 21.9109C11.1359 23.5256 9.0117 25.1353 6.88749 26.74C6.76254 26.835 6.62259 26.915 6.47264 26.9599C5.92285 27.1249 5.45802 26.775 5.45802 26.2001C5.45469 25.2734 5.4558 24.3445 5.45691 23.4164V23.4158C5.45747 22.9522 5.45802 22.4888 5.45802 22.0259V21.6909H5.15313C4.90247 21.6861 4.65128 21.687 4.40024 21.6879H4.40017C3.87538 21.6898 3.35126 21.6917 2.834 21.6409C1.19961 21.491 0.00505271 20.1362 0.00505271 18.5015C0.00605138 17.4857 0.00744912 16.4695 0.00884725 15.453C0.0144465 11.382 0.0200519 7.30661 5.45726e-05 3.23439C-0.0099417 1.46972 1.35455 0.109979 3.1089 0.00499882C3.21886 0 3.32881 0 3.43378 0H23.6462C24.8208 0 25.8004 0.439917 26.4602 1.42473C26.6318 1.68224 26.7375 1.98455 26.8435 2.28793C26.8918 2.42628 26.9402 2.56485 26.995 2.69949C27 8.13348 27 13.5675 27 19.0014ZM16.1886 15.1407H14.5383V7.27238C14.6803 7.27238 14.8218 7.27154 14.9631 7.27071C15.3854 7.26821 15.8047 7.26573 16.2241 7.28572C16.3232 7.29239 16.4365 7.43241 16.5003 7.5391C16.9853 8.32575 17.4686 9.11407 17.9519 9.90238L17.9522 9.90287C18.4357 10.6914 18.9191 11.4799 19.4043 12.2668L19.4045 12.2672C19.4753 12.3804 19.5461 12.4937 19.6734 12.7002V7.29238H21.2883V15.1407C21.1544 15.1407 21.0195 15.1415 20.8842 15.1424L20.8839 15.1424C20.4753 15.1449 20.0618 15.1474 19.6522 15.1274C19.5686 15.1208 19.4782 14.9789 19.4146 14.8791L19.4114 14.874C18.8011 13.8757 18.1935 12.875 17.5853 11.8733L17.5852 11.873C17.1981 11.2355 16.8108 10.5976 16.4224 9.95962C16.3657 9.86626 16.309 9.77291 16.1816 9.68622C16.1886 11.4999 16.1886 13.307 16.1886 15.1407ZM12.768 8.64298V7.26905H6.22993V15.144H12.9799V13.7433H8.09904V11.6925H12.3518V10.3252H8.09148V8.64298H12.768Z"
      />
    </svg>
  );
};

LanguageIcon.defaultProps = {
  className: '',
};

LanguageIcon.propTypes = {
  className: PropTypes.string,
};

export default LanguageIcon;
