import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import isFunction from 'lodash/isFunction'
import cx from 'classnames'
import useOutsideClick from './useOutsideClick'

import { AccountMenuList } from './AccountMenuList/AccountMenuList'
import Icon from 'assets/icons/Icon'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const AccountMenuItem = ({
  icon,
  name,
  linkProps = [],
  subMenuActive,
  isLink,
  linkTo,
  hasUnreadChatMessages,
}) => {
  const t = useGetTranslate()
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [isHovered, setIsHovered] = useState(false)

  const toggleDropdown = () => {
    if (!isLink) {
      setIsDropdownOpen(prevState => !prevState)
    }
  }

  const accountClasses = cx('account-menu__item', {
    opened: isDropdownOpen,
    'unread-messages-indicator': hasUnreadChatMessages
  })

  const activeClass = isHovered ? 'opened' : ''

  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, () => {
    setIsDropdownOpen(false)
  })

  useEffect(() => {
    if (subMenuActive) {
      setIsDropdownOpen(false)
    }
  }, [subMenuActive])

  return (
    <div
      className={accountClasses}
      ref={wrapperRef}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Icon.HeaderBottomArrow />
      <div className={`account-menu__line-indicator ${activeClass}`}>
        <Icon.HeaderMiddleArrow />
      </div>
      <div className="account-menu__item--heading" onClick={toggleDropdown}>
        {isLink ? (
          <Link to={linkTo}>
            <i className={`account-menu__item--icon ${icon}`} />
            {t(name)}
          </Link>
        ) : (
          <p>
            <i className={`account-menu__item--icon ${icon}`} />
            {t(name)}
            <Icon.ArrowDownSelectMenu />
          </p>
        )}
      </div>
      <AccountMenuList>
        <ul className="account-menu__item--body">
          <Icon.HeaderTopArrow className="account-menu__item--triangle" />
          {linkProps.map(({ value, target, onLinkClick, onClick }) => (
            <li
              key={value}
              onClick={() => {
                if (isFunction(onLinkClick)) onLinkClick()
                setIsDropdownOpen(false)
              }}
            >
              {onClick
                ? <button type='button' onClick={onClick}>{t(value)}</button>
                : <Link to={target}>{t(value)}</Link>
              }
            </li>
          ))}
        </ul>
      </AccountMenuList>
    </div>
  )
}

AccountMenuItem.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  linkProps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      target: PropTypes.string,
    })
  ),
  buttonProps: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  subMenuActive: PropTypes.bool,
}

export default AccountMenuItem
