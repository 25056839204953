import React from 'react'

import LineString from '_legacy/components/Table/table-components/types/common/LineString.js'

import { toMoneyWithComa } from 'utils/moneyTransformator'


const Amount = ({ isLoading, totalPrice }) =>
  <LineString
    isLoading={isLoading}
    className="bold"
    str={`$${toMoneyWithComa(totalPrice)} (USD)`}
  />

export default Amount

