import React from 'react'
import styled from 'styled-components';

import moreRevenue from 'assets/new-landing/more_revenue.svg';
import higherRevenue from 'assets/new-landing/higher_revenue.svg';
import lessFees from 'assets/new-landing/less_fees.svg';
import lessFrustration from 'assets/new-landing/less_frustration.svg';
import moreConvenience from 'assets/new-landing/more_convenience.svg';
import moreEnjoyment from 'assets/new-landing/more_enjoyment.svg';
import moreSecure from 'assets/new-landing/more_secure.svg';
import charity from 'assets/new-landing/charity.svg';

import Reason from './Reason'

const reasons = [
  {
    className: 'more_revenue',
    bg: moreRevenue,
    title: 'home.talent-member-benefits-2.subheader.more_revenue',
    description: 'home.talent-member-benefits-2.text.more_revenue',
  },
  {
    className: 'higher_revenue',
    bg: higherRevenue,
    title: 'home.talent-member-benefits-2.subheader.higher_revenue',
    description: 'home.talent-member-benefits-2.text.higher_revenue',
  },
  {
    className: 'less_fees',
    bg: lessFees,
    title: 'home.talent-member-benefits-2.subheader.less_fees',
    description: 'home.talent-member-benefits-2.text.less_fees',
  },
  {
    className: 'less_frustration',
    bg: lessFrustration,
    title: 'home.talent-member-benefits-2.subheader.less_frustration',
    description: 'home.talent-member-benefits-2.text.less_frustration',
  },
  {
    className: 'more_convenience',
    bg: moreConvenience,
    title: 'home.talent-member-benefits-2.subheader.more_convenience',
    description: 'home.talent-member-benefits-2.text.more_convenience',
  },
  {
    className: 'more_enjoyment',
    bg: moreEnjoyment,
    title: 'home.talent-member-benefits-2.subheader.more_enjoyment',
    description: 'home.talent-member-benefits-2.text.more_enjoyment',
  },
  {
    className: 'more_secure',
    bg: moreSecure,
    title: 'home.talent-member-benefits-2.subheader.more_secure',
    description: 'home.talent-member-benefits-2.text.more_secure',
  },
  {
    className: 'charity',
    bg: charity,
    title: 'home.talent-member-benefits-2.subheader.charity',
    description: 'home.talent-member-benefits-2.text.charity',
  },
];

export default React.memo(function ReasonsList () {
  return (
    <StyledWrapper>
      {reasons.map((reason, i) => (
        <Reason
          key={i}
          {...reason}
        />
      ))}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 35px 130px;
  padding: 10px 20px 0 80px;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 0 20px;
    margin: 0 auto 120px;
  }
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 35px;
    padding: 0 20px;
  }
  @media only screen and (max-width: 480px) {
    grid-template-columns: 1fr;
    padding: 0 13px;
    width: 360px;
  }
  @media only screen and (max-width: 380px) {
    width: unset;
  }
`
