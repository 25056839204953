export const videoVoiceTextContentBook = [
  {
    key: 'lessons.video-voice.content.book-1',
  },
  {
    key: 'lessons.video-voice.content.book-2',
    phs: [{
      ph: '{{talentName}}',
      value: null,
    }]
  },
]

export const liveVirtualTextContentBook = [
  {
    key: 'lessons.live-virtual.content.book-1',
  },
  {
    key: 'lessons.live-virtual.content.book-2'
  },
  {
    key: 'lessons.live-virtual.content.book-3',
    phs: [{
      ph: '{{talentName}}',
      value: null,
    }]
  }
]

export const liveInPersonTextContentBook = [
  {
    key: 'lessons.live-in-person.content.book-2',
  },
  {
    key: 'lessons.live-in-person.content.book-3'
  }
]
