import React from 'react';
import { useParams } from 'react-router';
import { CancelOrderReason } from 'shared'
import compose from 'lodash/fp/compose';
import { notify } from 'utils/notify'

import {
  fetchRecurringExperienceHeader as fetchRecurringExperienceHeaderApi,
  fetchRecurringExperienceList as fetchRecurringExperienceListApi,
  checkAvailabilityToCancelFromList as checkAvailabilityToCancel,
  cancelOccurrence as cancelOccurrenceApi,
} from './api'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import normalizeParams from 'utils/normalizeParams';
import {
  useSetExperienceOfferingDetailsHeader,
  useSetExperienceOfferingDetailsTableFilters,
  useSetExperienceOfferingDetailsTable,
  useExperienceOfferingDetailsTableFilters,
} from './store'
import { useUser } from 'store/hooks/globalState/useUser'

import { getTalentId } from 'utils/user'

import { detailsConfig, fields } from '_legacy/configs/table/recurringList'

import { parseRangeDateRecurring } from '_legacy/components/Table/Filters/utils/rangeDate'

import { useHandleResponseError } from 'store/hooks/useHandleResponseError'
import { sanitizeFilterFields } from '_legacy/components/Table/utils'
import { useCurrentPage } from 'store/hooks/globalState/useUtils'

const normalize = compose(normalizeParams, parseRangeDateRecurring)

/* Recurring details Experience start */
export function useFetchExperienceOfferingDetailsHeader() {
  const setExperienceOfferingDetailsHeader = useSetExperienceOfferingDetailsHeader((prev, next) => next);
  const talentId = useUser(getTalentId)
  const { id } = useParams();
  const handleResponseError = useHandleResponseError()

  return React.useCallback(() => {
    const query = normalizeParams(detailsConfig);
    fetchRecurringExperienceHeaderApi({ talentId, id, query })
      .then(({ experience }) => {
        setExperienceOfferingDetailsHeader({ offering: experience });
      })
      .catch(error => {
        handleResponseError(error)
        console.warn(error)
      })
  }, [id, setExperienceOfferingDetailsHeader]);
}

export function useFetchExperienceOfferingDetails() {
  const setExperienceOfferingDetailsTableFilters = useSetExperienceOfferingDetailsTableFilters((prev, next) => ({ ...prev, ...next }));
  const setExperienceOfferingDetailsTable = useSetExperienceOfferingDetailsTable((prev, next) => next);
  const { id } = useParams();
  const talentId = useUser(getTalentId)

  return React.useCallback((options) => {
    const query = {
      fields: ['id', 'startTime', 'endTime', 'status', 'requestsCount', 'participantsCount', 'offerType'],
      relations: ['locations'],
      experienceIdIn: id,
      ...normalize(options)
    }

    setExperienceOfferingDetailsTableFilters({ experienceIdIn: id })
    setExperienceOfferingDetailsTable(null)
    fetchRecurringExperienceListApi({ talentId, query })
      .then(({ pageInfo, occurrences }) => {
        setExperienceOfferingDetailsTableFilters(pageInfo)
        const parsedOccurrence = occurrences.map(obj => ({ ...obj, type: 'experience' }))
        setExperienceOfferingDetailsTable(parsedOccurrence)
      })
      .catch((error) => console.warn(error));
  }, [id, setExperienceOfferingDetailsTableFilters, setExperienceOfferingDetailsTable]);
}
/* Recurring details end */

/* Cancel Recurring occurrence (one or one & all in future) */
export function checkOccurrenceFromList({ openRejectAndCancelModal, openCancelModal, setOccurrenceToCancel }) {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const { id: offerId } = useParams()

  return React.useCallback(experienceOccurrenceId => {
    checkAvailabilityToCancel({ talentId, offerId, experienceOccurrenceId })
      .then(() => {
        setOccurrenceToCancel({ talentId, offerId, experienceOccurrenceId })
        openCancelModal()
      })
      .catch((error) => {
        if (error.context?.isPassedOccurrence) {
          notify(t('cancel-occurrence.error.past-event'), 'error')
        }
        if (error.context?.hasBookedParticipants || error.context?.hasPendingBookingRequests) {
          setOccurrenceToCancel({
            talentId,
            offerId,
            experienceOccurrenceId,
            hasBookedParticipants: error.context?.hasBookedParticipants
          })
          openRejectAndCancelModal(offerId)
        }
        console.warn(error)
      });
  }, [offerId]);
}

export function useCancelOccurrenceFromList() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const { orderId: experienceOccurrenceId } = useParams()
  const fetchExperienceOfferingDetailsHeader = useFetchExperienceOfferingDetailsHeader()

  return React.useCallback(({ offerId, reason, message, cancelProps }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message || ''
    }

    const id = cancelProps?.offerId || offerId
    const occurrenceId = cancelProps?.experienceOccurrenceId || experienceOccurrenceId

    cancelOccurrenceApi({ talentId, id, occurrenceId, body })
      .then(() => {
        notify(t('order-details.experience.cancel-success'), 'success')
        fetchExperienceOfferingDetailsHeader()
      })
      .catch((err) => console.warn(err))
  }, [talentId, experienceOccurrenceId])
}


export function useCancelOccurrence() {
  const t = useGetTranslate()
  const talentId = useUser(getTalentId)
  const { id: offerId } = useParams()
  const fetchExperienceOffering = useFetchExperienceOfferingDetails()
  const page = useCurrentPage()
  const experienceOfferingOptions = useExperienceOfferingDetailsTableFilters(
    current => sanitizeFilterFields({ current, fields }),
    []
  )

  return React.useCallback(({ occurrenceId, reason, message }) => {
    const body = {
      cancelReason: reason,
      cancelReasonDescription: message || ''
    }
    cancelOccurrenceApi({ talentId, id: offerId, occurrenceId, body })
      .then(() => {
        notify(t('order-details.experience.cancel-success'), 'success')
        fetchExperienceOffering({ ...experienceOfferingOptions, page })
      })
      .catch((err) => console.warn(err))
  }, [talentId, offerId, experienceOfferingOptions])
}

export function useTryCancelOccurrence(openModal) {
  const talentId = useUser(getTalentId)
  const { id: offerId } = useParams()
  const cancelOccurrence = useCancelOccurrence()

  return React.useCallback((experienceOccurrenceId) => {
    checkAvailabilityToCancel({ talentId, offerId, experienceOccurrenceId })
      .then(() => {
        cancelOccurrence({
          occurrenceId: experienceOccurrenceId,
          reason: CancelOrderReason.OTHER,
          message: '',
        })
      }).catch(() => {
        openModal()
      })
  }, [talentId, offerId, cancelOccurrence])
}

