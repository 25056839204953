import { types } from 'shared';

const {
  DOES_NOT_REPEAT,
} = types.experiences.recurrence.RecurringDefinedInAdvanceValues;

import { getRecurringLabel } from '_legacy/components/CreateExperience/components/Recurring/config';
import { recurringModalDefaultState } from '_legacy/components/CreateExperience/utils/defaultValues';


export const getDefaultValues = (t) => ({
  prefill: undefined,
  price: undefined,
  dateOfExperience: undefined,
  recurring: {
    value: DOES_NOT_REPEAT,
    label: t(getRecurringLabel(DOES_NOT_REPEAT)),
  },
  recurringModal: recurringModalDefaultState,
  startTime: undefined,
  endTime: undefined,
  talentMember: [],
  type: undefined,
  customType: undefined,
  name: undefined,
  description: undefined,
  requiredPhysicalItem: undefined,
  physicalItemsIncludedInPrice: undefined,
  physicalItems: undefined,
  media: undefined,
  charities: [
    { charity: { value: null, label: '' }, percent: '' }
  ],
})
