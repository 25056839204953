import React from 'react';
import PropTypes from 'prop-types';

import AsyncSelect from './AsyncSelectComponent';
import { loadLocationsOptions, loadAddressOptions, loadCitiesOptions, loadAddressandlocationsOptions } from 'utils/services/loaders/locations';
import cond from 'lodash/cond'
import is from 'lodash/fp/isEqual'
import always from 'lodash/fp/always'
import T from 'lodash/fp/T'
import { PRECISION_TYPES } from './LocationsContants';

const LocationsSelect = ({ precision, ...restProps }) => {
  const optionsLoader = cond([
    [is(PRECISION_TYPES.LOCATION), always(loadLocationsOptions)],
    [is(PRECISION_TYPES.CITY), always(loadCitiesOptions)],
    [is(PRECISION_TYPES.ADDRESS), always(loadAddressOptions)],
    [is(PRECISION_TYPES.ADDRESS_AND_LOCATION), always(loadAddressandlocationsOptions)],
    [T, always(() => null)],
  ])(precision || PRECISION_TYPES.LOCATION)

  const locationsLoader = async (...props) => {
    if (props[0].trim().length === 0) return { options: [], hasMore: false };
    const response = await optionsLoader(...props);
    return response;
  };

  return (
    <AsyncSelect
      isShowArrow={false}
      openMenuOnClick={false}
      loadOptions={locationsLoader}
      {...restProps}
    />
  );
};

LocationsSelect.propTypes = {
  placeholder: PropTypes.string,
  precision: PropTypes.string,
  clearErrors: PropTypes.func,
  blackTheme: PropTypes.bool,
  name: PropTypes.string,
  rules: PropTypes.object,
  error: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  isCreatable: PropTypes.bool,
};

export default LocationsSelect;
