import { _get } from 'api/utils'

export const getLocationDetails = ({ googlePlaceId } = {}) =>
  _get('/integrations/locations/details/', { query: { googlePlaceId } })

export const getTimezone = ({ latitude, longitude, timestamp = 0 } = {}) =>
  _get('/integrations/locations/timezone/', {
    query: { latitude, longitude, timestamp },
  })

// todo: fix backend will merge request
export const getTimeZoneByGooglePlaceId = async ({ googlePlaceId }) => {
  const data = await getLocationDetails({ googlePlaceId })
  const lat = data.original.geometry.location.lat
  const lng = data.original.geometry.location.lng
  return await getTimezone({ latitude: lat, longitude: lng })
}
