// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'

import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'

import cx from 'classnames'

// Store (hooks)
import { useChatsList, useIsLoadingChat } from 'store/hooks/globalState/useChats'

// Legacy components
import LoaderIndicator from '_legacy/components/Common/Loader'


// Components
import ActiveChatHeader from './ActiveChatHeader'
import ActiveChatInput from './ActiveChatInput'
import ActiveChatDetails from './ActiveChatDetails'
import ActiveChatMessages from './ActiveChatMessages'
import NotActiveChat from './NotActiveChat'


const ActiveChat = ({ isMobile }) => {
  const [isChatDetailsOpened, setIsChatDetailsOpened] = React.useState(false)
  const [selectedChat, setSelectedChat] = React.useState(null)

  const { chatId } = useParams()

  const chatsList = get(useChatsList(), 'list', {})
  const isLoadingChat = useIsLoadingChat()

  const getActiveChat = () => {
    if (chatId && !isEmpty(chatsList)) {
      setSelectedChat(chatsList[chatId] || null)
    } else {
      setSelectedChat(null)
    }
  }

  React.useEffect(getActiveChat, [chatId, chatsList, setSelectedChat])

  const mobileClassName = cx({ 'mobile-view': isMobile })

  return !isMobile || (isMobile && chatId) ? (
    <div className={`active-chat-wrapper ${mobileClassName}`}>
      {chatId && isLoadingChat ? (
        <div className="active-chat-loader">
          <LoaderIndicator size={24} fill="#636583" />
        </div>
      ) : selectedChat ? (
        <>
          <div className="active-chat-body">
            <ActiveChatHeader
              isMobile={isMobile}
              selectedChat={selectedChat}
              openChatDetails={() => setIsChatDetailsOpened(!isChatDetailsOpened)}
            />

            <ActiveChatMessages isMobile={isMobile} selectedChat={selectedChat} />
            <ActiveChatInput selectedChat={selectedChat} isMobile={isMobile} />
          </div>

          {isChatDetailsOpened && (
            <ActiveChatDetails selectedChat={selectedChat} closeChatDetails={() => setIsChatDetailsOpened(false)} />
          )}
        </>
      ) : (
        !isMobile && <NotActiveChat />
      )}
    </div>
  ) : (
    <div />
  )
}

ActiveChat.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default ActiveChat
