import { getCookie, setCookie } from 'utils/cookies'

const parseCookie = (data) => data ? JSON.parse(data) : undefined
const stringifyCookie = (data) => JSON.stringify(data)

export const cookies = {
  TYPES: {
    MAIN_COOKIES: 'main-cookies',
    PREFERENCES: 'preferences',
    LANGUAGE: 'preferences.language',
    CURRENCY: 'preferences.currency',
  },
  OPTIONS: {
    'max-age': 60*60*24*365
  },
  // Main Cookies getter/setter
  get main() {
    const mainCookies = getCookie(cookies.TYPES.MAIN_COOKIES)
    return parseCookie(mainCookies)
  },
  set main(data) {
    setCookie(cookies.TYPES.MAIN_COOKIES, stringifyCookie(data), cookies.OPTIONS)
  },
  // Preferences getter
  get preferences() {
    const mainCookies = cookies.main
    if (mainCookies) return parseCookie(mainCookies?.[cookies.TYPES.PREFERENCES])
    return undefined
  },
  // Language getter/setter
  get language() {
    return parseCookie(getCookie(cookies.TYPES.LANGUAGE))
  },
  set language(value) {
    setCookie(cookies.TYPES.LANGUAGE, stringifyCookie(value), cookies.OPTIONS)
  },
  // Currency getter/setter
  get currency() {
    return parseCookie(getCookie(cookies.TYPES.CURRENCY))
  },
  set currency(value) {
    setCookie(cookies.TYPES.CURRENCY, stringifyCookie(value), cookies.OPTIONS)
  }
}
