import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'

import Icon from 'assets/icons/Icon'
import LoaderIndicator from '_legacy/components/Common/Loader'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const ChatButton = ({ to, className, onClick, isLoading, ...props }) => {
  const t = useGetTranslate()
  const classes = cx(
    'action-button',
    'action-button--mobile-hidden',
    'chat-button',
    className,
    { 'disabled': isLoading }
  )

  return to ? (
    <Link to={to} className={classes} {...props}>
      <Icon.Messages className="action-button__icon" />
      <span className="action-button__text">{t('shared.words.chat')}</span>
    </Link>
  ) : (
    <div
      className={classes}
      {...props}
      {...(!isLoading ? { onClick: onClick } : {}) }
    >
      <Icon.Messages className="action-button__icon" />
      {isLoading
        ? <LoaderIndicator size={16} fill="#636583" />
        : <span className="action-button__text">{t('shared.words.chat')}</span> }
    </div>
  )
}

export default ChatButton
