import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import ArrowIcon from 'assets/icons/ArrowIcon';

const RequestButton = ({ onClick, isMirrored, disabled, className }) => (
  <StyledButton onClick={onClick} disabled={disabled} className={className}>
    <ArrowIcon
      degrees={isMirrored ? 180 : 0}
    />
  </StyledButton>
);

const StyledButton = styled.button`
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color .3s ease-in-out;
  &:hover, &:active {
    background-color: #0c171f22;
  }
  ${({ disabled }) => disabled && css`
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
  `}
`

RequestButton.propTypes = {
  onClick: PropTypes.func,
  isMirrored: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default RequestButton;
