import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import map from 'lodash/map';
import { types } from 'shared';
import isEqual from 'lodash/fp/isEqual'

const isUsa = isEqual('UNITED_STATES')

export const states = map(
  types.locations.USAStatesKeypair,
  (label, key) => ({ value: key, label }),
);

export const countries = map(
  types.locations.Countries,
  (label, key) => ({ value: key, label: label.native, data: label.code }),
);


export const submitAddressAdapter = address => {
  if (isEmpty(address)) return

  const addressLabel = get(address, 'address.label')
  const addressValue = get(address, 'address.value')
  const googlePlaceId = addressLabel === addressValue ? null : addressValue;
  const country = get(address, 'country.value');
  const state = get(
    address,
    isUsa(country) ? 'usaState.value' : 'state'
  );

  const cityValue = get(address, 'city.value')
  const cityLabel = get(address, 'city.label')

  const city = cityValue === cityLabel
    ? { value: cityLabel }
    : { googlePlaceId: cityValue, value: cityLabel }

  return {
    country,
    state,
    zipCode: get(address, 'zipCode'),
    city,
    address: { value: addressLabel, googlePlaceId, },
  };
}

export const getAddressAdapter = backendAddress => {
  if (!backendAddress) return {};

  const { city, country, address, state, zipCode, googlePlaceId } = backendAddress;
  const _country = find(countries, ['value', country]);
  const _usaState = isUsa(_country)
    ? states.find(s => s.value === state)
    : states[0];
  const _state = isUsa(_country) ? '' : state;

  return {
    usaState: _usaState,
    state: _state,
    zipCode,
    city: { value: city, label: city },
    address: { value: googlePlaceId || address, label: address },
    country: _country,
  };
}
