import React from 'react'

import ModalPortal from 'portals/ModalPortal'
import PublicRadioInputItem from '../PublicRadioInputItem'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const OptionsModal = ({ title, options, isOpen, onClose, name, onChange, value }) => {
  const t = useGetTranslate()

  let optionsHTML = t('shared.words.no-more-dates-available-for-booking');
  if(options.length > 0) {
    optionsHTML = (
      <div className='options-modal__list'>
        {(options || []).map(item => (
          <PublicRadioInputItem
            key={item.value}
            name={name}
            value={String(item.value)}
            label={item.label}
            title={item.title}
            checked={item.value === value}
            onClick={onChange}
          />
        ))}
      </div>);
  }

  return (
    <ModalPortal showCloseButton isOpen={isOpen} onClose={onClose}>
      <div className='options-modal'>
        <h1 className='options-modal__title'>{title || ''}</h1>
        {optionsHTML}
      </div>
    </ModalPortal>
  )
}

export default OptionsModal
