import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const MobileEmpty = ({ icon, content }) => (
  <StyledMobileSlide
    className="mb-slide slide-elements"
  >
    <StyledMobileContent className="mb-content" margin={0}>
      <StyledContentEmptyIcon className="mb-icon">{icon}</StyledContentEmptyIcon>
      <StyledContentEmptyText className="mb-content">{content}</StyledContentEmptyText>
      {/* <StyledContentEmptyHint className="mb-hint">{hint}</StyledContentEmptyHint> */}
    </StyledMobileContent>
  </StyledMobileSlide>
);

export const StyledMobileSlide = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const StyledMobileContent = styled.div`
  position: relative;
  padding: 14px 10px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(90deg, #f9fafd 40%, #dbdde6 100%), linear-gradient(90deg, #f9fafd 0%, #dbdde6 100%);
  flex: 0 0 400px;
  max-width: 400px;
  height: 525px;
  box-shadow: 0 0 24px rgba(0, 0, 0, 0.2);
  margin: ${({ margin }) => margin};
  ${({ border }) => border && css`
    border: 6px solid ${({ theme }) => theme.colors.white};
    padding: 0;
  `}

  @media screen and (max-width: 950px) {
    flex: 0 0 270px;
    max-width: 270px;
    height: 360px;
  }
`
export const StyledContentEmptyIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
  height: 90px;
  @media screen and (max-width: 575px) {
    margin-top: auto;
  }
  svg path {
    fill: ${({ theme }) => theme.colors.lightGrey};
  }
`
export const StyledContentEmptyText = styled.div`
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 1.62;
  text-align: center;
  color: ${({ theme }) => theme.colors.grey};
`
export const StyledContentEmptyHint = styled.div`
  margin-top: auto;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.62;
  text-align: center;
  color: ${({ theme }) => theme.colors.gultraLightBluerey}
`

MobileEmpty.propTypes = {
  icon: PropTypes.object.isRequired,
  content: PropTypes.object.isRequired,
  hint: PropTypes.object.isRequired,
};

export default MobileEmpty;
