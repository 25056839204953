import React from 'react'
import { SystemMessageType, TalentOffersType } from 'shared'

import Icon from 'assets/icons/Icon'
import { getFullName } from 'utils/user'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'

export const getEventViewInfo = eventType => {
  const t = useGetTranslate()

  const eventViewInfo = {
    [TalentOffersType.FOR_COMMERCIAL_USE]: {
      label: t('chats.chat.event-details.event-type.for-commercial-use'),
      icon: props => <Icon.ForCommercialUseEvent {...props} />,
    },
    [TalentOffersType.IN_PERSON_EXPERIENCE]: {
      label: t('chats.chat.event-details.event-type.in-person-experience'),
      icon: props => <Icon.InPersonExperiencesActive {...props} />,
    },
    [TalentOffersType.LIVE_IN_PERSON_LESSON]: {
      label: t('chats.chat.event-details.event-type.live-in-person-lesson'),
      icon: props => <Icon.LessonsVirtualEventsActive {...props} />,
    },
    [TalentOffersType.READY_MADE_LESSON]: {
      label: t('chats.chat.event-details.event-type.ready-made-lesson'),
      icon: props => <Icon.ReadyMadeEventsActive {...props} />,
    },
    [TalentOffersType.VIDEO_CHAT]: {
      label: t('chats.chat.event-details.event-type.video-chat'),
      icon: props => <Icon.VideoChatsActive {...props} />,
    },
    [TalentOffersType.VIDEO_MESSAGE]: {
      label: t('chats.chat.event-details.event-type.video-message'),
      icon: props => <Icon.VideoMessagesActive {...props} />,
    },
    [TalentOffersType.VIDEO_VOICE_OVER_LESSON]: {
      label: t('chats.chat.event-details.event-type.video-voice-over-lesson'),
      icon: props => <Icon.LessonsVirtualEventsActive {...props} />,
    },
    [TalentOffersType.VIRTUAL_EVENT]: {
      label: t('chats.chat.event-details.event-type.virtual-event'),
      icon: props => <Icon.LiveVirtualEventsActive {...props} />,
    },
    [TalentOffersType.VIRTUAL_LESSON]: {
      label: t('chats.chat.event-details.event-type.virtual-lesson'),
      icon: props => <Icon.LessonsVirtualEventsActive {...props} />,
    },
  }

  return eventViewInfo[eventType]
}

export const getChatViewDate = (lastMessage, chatInstance) => {
  return lastMessage ? lastMessage.dateUpdated || lastMessage.dateCreated : chatInstance.dateCreated
}

export const sortChatsByUpdatedDate = (chats = []) =>
  chats.sort((a, b) => {
    return (
      new Date(b.chatInfo.lastMessageDate ?? b.chatInfo.createdAt) -
      new Date(a.chatInfo.lastMessageDate ?? a.chatInfo.createdAt)
    )
  })

export const formatSystemMessage = ({ chatInfo, message, currentUserMessage, currentUserId, messageAuthorDetails }) => {
  const newParticipantAccepted = message.attributes?.systemMessageType === SystemMessageType.NEW_PARTICIPANT

  if (newParticipantAccepted) {
    const newParticipant = newParticipantAccepted
      ? chatInfo?.participants?.find(p => p.id === message.attributes?.newParticipantId)
      : null

    let messageTransProps = {}
    let messageWithMarkupTransProps = {}

    const chatOwnerFullName = getFullName(messageAuthorDetails)
    const chatTitle = chatInfo.title

    if (currentUserMessage) {
      // current user (chat owner) accepted somebody to chat
      const phs = [
        {
          ph: '{{newParticipantFullName}}',
          value: getFullName(newParticipant),
        },
        { ph: '{{chatTitle}}', value: chatTitle },
      ]

      messageTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.current-user-accepted-somebody.text',
      }

      messageWithMarkupTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.current-user-accepted-somebody.markup',
      }
    } else if (currentUserId === newParticipant?.id) {
      // chat owner accepted current user to chat
      const phs = [
        { ph: '{{chatOwnerFullName}}', value: chatOwnerFullName },
        { ph: '{{chatTitle}}', value: chatTitle },
      ]

      messageTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.current-user-was-accepted-by-somebody.text',
      }

      messageWithMarkupTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.current-user-was-accepted-by-somebody.markup',
      }
    } else {
      // chat owner accepted somebody to chat
      const phs = [
        { ph: '{{chatOwnerFullName}}', value: chatOwnerFullName },
        {
          ph: '{{newParticipantFullName}}',
          value: getFullName(newParticipant),
        },
        { ph: '{{chatTitle}}', value: chatTitle },
      ]

      messageTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.somebody-was-accepted-by-somebody.text',
      }

      messageWithMarkupTransProps = {
        phs,
        tKey: 'chats.chat.system-message.new-chat-participant.somebody-was-accepted-by-somebody.markup',
      }
    }

    return {
      message: <Trans {...messageTransProps} />,
      messageWithMarkup: <p><Trans {...messageWithMarkupTransProps} /></p>,
    }
  }

  return {
    message: message.body,
    messageWithMarkup: message.body,
  }
}
