import compose from 'lodash/fp/compose'
import startCase from 'lodash/startCase'
import lowerCase from 'lodash/lowerCase'

import { types } from 'shared'

const { FACEBOOK, INSTAGRAM, LINKED_IN, TIK_TOK, TWITTER } = types.user.socialMedia.Types
const socialOptions = [FACEBOOK, INSTAGRAM, LINKED_IN, TIK_TOK, TWITTER]

export const getOptionLabel = compose(startCase, lowerCase)

export const getSocialOption = option => ({
  value: option,
  label: getOptionLabel(option),
})

export const getOptionsWithTranslate = () => socialOptions.map(getSocialOption)

export const socialMediaLinkInitialState = [
  {
    social: getOptionsWithTranslate()[0],
    link: ''
  }
]
