import React from 'react'

import LineString from '../common/LineString'

import { useCurrencyCode, useCurrencyRate } from 'store/hooks/globalState/useCurrency'
import { formatPrice } from 'utils/helpers/price'


export default React.memo(function Status({ isLoading, price }) {
  const currencyCode = useCurrencyCode()
  const currencyRate = useCurrencyRate()

  const formattedPrice = formatPrice(price, currencyCode, currencyRate)

  return (
    <LineString
      isLoading={isLoading}
      str={formattedPrice}
    />
  )
})
