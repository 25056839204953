import React from 'react';
import PropTypes from 'prop-types';
import List from './List';
import styled from 'styled-components';

const Machine = React.memo(function Machine({ isPause, setPause, empty, setIsPlay, setCurrentSlideIndex, autoPlay, slides, currentSlideIndex, pauseVideoByIndex }) {
  function playSlide(currentInd)
  {
    pauseVideoByIndex(currentInd-1)
    let index = currentInd;
    while(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty === true)
    {
      index++;
    }

    if(typeof slides[index] !== 'undefined' && slides[index].props.isEmpty !== true)
    {
      setCurrentSlideIndex(index);
      if(slides[index].props.file.file.type === 'VIDEO' || slides[index].props.file.type === 'VIDEO'){
        setPause(true)
        setIsPlay(false)
      }
      else{
        setPause(false)
      }
    }
    else
    {
      playSlide(0)
    }
  }

  React.useEffect(()=>{
    if(!isPause && !empty)
    {
      const interval = setInterval(() => {
        playSlide(currentSlideIndex+1);
      }, 5000);
      return () => clearInterval(interval);
    }

  },[autoPlay, slides, isPause])

  return (
    <StyledWrapper className="mb-machine">
      <List slides={slides} currentSlideIndex={currentSlideIndex} />
    </StyledWrapper>
  )
});

const StyledWrapper = styled.div`
  padding-top: 26px;
  padding-bottom: 24px;
  overflow: hidden;
`

Machine.propTypes = {
  slides: PropTypes.array.isRequired,
  currentSlideIndex: PropTypes.number.isRequired,
  setCurrentSlideIndex: PropTypes.func.isRequired
};

export default Machine;
