// TODO: Refactor this component and move to new structure

// Modules
import React from 'react'
import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import { TypesOfDays } from 'shared'

// Store (hooks)
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useVideoChatPublic, useSetVideoChatPublic } from 'store/hooks/globalState/usePublicView'
import { useFetchVideoChat } from 'requests/public-views'
import { useBookVideoChat } from 'requests/public-views/video-chat/video-chat-booking'

// Utils
import { scrollToRef } from 'utils/scrollToRef'
import { getFullMonthWithTime } from 'utils/date'
import { getCharityLabel } from 'utils/charities'
import { prepareDate, getTimezoneAbbr } from 'utils/services/timezone'
import { getUserTimeZone } from 'utils/user'
import { getVideoChatNoteText } from './utils'

// Legacy components
import IconHiW from 'assets/icons/HowItWorkIcons'
import PublicSlider from '_legacy/components/PublicSlider/'
import PageLoader from '_legacy/common/Template/PageLoader'
import ArrowScroll from '_legacy/components/Landing/ArrowScrollDown'
import PublicTaboos from '_legacy/components/PublicView/PublicTaboos'
import PublicHowToList from '_legacy/components/PublicView/PublicHowToList'
import PublicHeadingBlock from '_legacy/components/PublicView/PublicHeadingBlock'
import PublicViewBorderedCard from '_legacy/components/PublicView/PublicViewBorderedCard'
import PublicDescriptionOneLine from '_legacy/components/PublicView/PublicDescriptionOneLine'
import Price from '_legacy/components/Price'
import Button from '_legacy/components/Button/BookNowButton'

// Components
import BookingCalendar from 'components/Calendars/BookingCalendar'

// Constants
import CalendarButton from 'components/Buttons/CalendarButton'

const howItWork = [
  {
    icon: <IconHiW.Member />,
    number: '01',
    title: 'video-chat.public.how-it-work.block-1.title',
    text: 'video-chat.public.how-it-work.block-1.text',
  },
  {
    icon: <IconHiW.Schedule />,
    number: '02',
    title: 'video-chat.public.how-it-work.block-2.title',
    text: 'video-chat.public.how-it-work.block-2.text',
  },
  {
    icon: <IconHiW.Organize />,
    number: '03',
    title: 'video-chat.public.how-it-work.block-3.title',
    text: 'video-chat.public.how-it-work.block-3.text',
  },
  {
    icon: <IconHiW.Enjoy />,
    number: '04',
    title: 'video-chat.public.how-it-work.block-4.title',
    text: 'video-chat.public.how-it-work.block-4.text',
  },
]

const { ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME, ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR, BOTH } = TypesOfDays

const VideoChatPublic = () => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [isBookingCalendarOpen, openBookingCalendar, closeBookingCalendarModal] = useModal(false)

  const [orderSlot, setOrderSlot] = React.useState(null)

  const howToRef = React.useRef(null)

  const t = useGetTranslate()

  const fetchVideoChat = useFetchVideoChat()
  const bookVideoChat = useBookVideoChat(setIsLoading)
  const clearVideoChat = useSetVideoChatPublic(() => null)
  const videoChat = useVideoChatPublic()

  const timezone = useUser(getUserTimeZone)
  const timezoneAbbr = getTimezoneAbbr(timezone)

  React.useEffect(() => {
    fetchVideoChat()

    return () => {
      clearVideoChat()
    }
  }, [])

  if (!videoChat) return <PageLoader />

  const {
    media,
    price,
    talent,
    whichCharities,
    defaultTaboos,
    customTaboos,
    duration = 60000,
    title,
    daysTimesType,
  } = videoChat

  // Transform selected dates to UTC ISO sting
  const startTime = prepareDate(orderSlot?.start, timezone)
  const endTime = prepareDate(orderSlot?.end, timezone)

  const onSubmit = () => {
    const { id: offerVideoChatId, daysTimesType } = videoChat

    const options = {
      startTime: startTime ? startTime : null,
      endTime: endTime ? endTime : null,
    }

    if (daysTimesType === ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME) {
      bookVideoChat({ offerVideoChatId })
    } else {
      bookVideoChat({
        offerVideoChatId,
        ...options,
      })
    }
  }

  const taboosList = [defaultTaboos, customTaboos].filter(Boolean).flat()
  const sortMedia = sortBy(media, ({ isPrimary }) => !isPrimary)
  const convertedCharities = whichCharities.filter(Boolean).map(getCharityLabel).join('; ')

  const isAnyAvailable = daysTimesType === ANY_AVAILABLE_DAY_TIME_IN_TALENT_CALENDAR
  const isBoth = daysTimesType === BOTH

  const isShowDateTime = isAnyAvailable || isBoth
  const isBookButtonDisabled = (isAnyAvailable && !orderSlot) || isLoading

  const formattedDateTime = orderSlot?.start ? `${getFullMonthWithTime(orderSlot.start)} ${timezoneAbbr}` : null

  return (
    <div className="public-view video-chat">
      <PublicHeadingBlock name={t('video-chat.public.header')} className="inverted short" />

      <div className="wrapper content-wrapper">
        <PublicViewBorderedCard borderColor={'gradient'}>
          <div className="slider">
            <PublicSlider covers={sortMedia} />
          </div>

          <div className="description">
            <PublicDescriptionOneLine>
              <Trans
                tKey="video-chat.public.message-text"
                phs={[
                  { ph: '{{displayName}}', value: talent.displayName },
                  { ph: '{{talentLink}}', value: talent.plaqueNickname },
                ]}
              />
            </PublicDescriptionOneLine>

            <div className="video-chat__calendar-and-price">
              <div className="video-chat__calendar-and-price__wrapper">
                <div className="public-view__price">
                  <div className="price-wrapper">
                    <p>{t('video-message.label.price')}</p>
                    <Price price={price} withCurrency />
                  </div>

                  <p className="public-view__note">
                    <strong>{t('video-chat.public.note')}</strong>&nbsp;{t(getVideoChatNoteText(daysTimesType))}
                  </p>

                  {isShowDateTime && <CalendarButton onClick={openBookingCalendar} time={formattedDateTime} />}

                  <div className="button-wrapper">
                    <Button
                      paddingVertical="13"
                      paddingHorizontal="26"
                      handleOnClick={onSubmit}
                      text={t('video-message.label.book-now')}
                      disabled={isBookButtonDisabled}
                    />
                  </div>
                </div>
              </div>
            </div>

            {!isEmpty(convertedCharities) && (
              <PublicDescriptionOneLine className="public-view-description--charity-list">
                <span className="public-view-description--charity-list__title">
                  {t('video-message.text.charity-list')}{' '}
                </span>
                <p className="inline grayed">{convertedCharities}</p>
                <span className="tip">{t('video-message.text.charity-percent')}</span>
              </PublicDescriptionOneLine>
            )}

            <div className="taboos-wrapper">
              <PublicTaboos title="video-chat.public.taboos.label" list={taboosList} />
            </div>
          </div>

          <div className="taboos-wrapper--mobile">
            <PublicTaboos title="video-chat.public.taboos.label" list={taboosList} />
          </div>
        </PublicViewBorderedCard>

        <div className="arrow-down">
          <ArrowScroll clickHandler={() => scrollToRef(howToRef)} />
        </div>
      </div>

      <div className="public-view__how-to public-view__how-to--video-chat" ref={howToRef}>
        <div className="public-view__how-to--list wrapper content-wrapper">
          <h1>{t('video-chat.title.how-it-work')}</h1>
          {howItWork.filter(Boolean).map(({ icon, number, title, text }) => (
            <PublicHowToList key={number} icon={icon} numericBg={number} title={t(title)} text={t(text)} />
          ))}
        </div>
      </div>

      <BookingCalendar
        isOpen={isBookingCalendarOpen}
        onClose={closeBookingCalendarModal}
        orderSlot={orderSlot}
        setOrderSlot={setOrderSlot}
        talentId={talent.id}
        eventDuration={duration}
        eventTitle={title}
      />
    </div>
  )
}

export default VideoChatPublic
