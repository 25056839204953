import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

export default React.memo(function NavigationLink ({
  className = '', path = '/', clickHandler, text
}) {
  const t = useGetTranslate()
  const handleClick = React.useCallback((e) => {
    if (!clickHandler) return
    e.preventDefault()
    clickHandler()
  }, [clickHandler])

  return (
    <StyledLink
      className={className}
      to={path}
      onClick={handleClick}
    >
      <div className='text'>
        {t(text)}
      </div>
      <div className='arrow' />
    </StyledLink>
  )
})

const StyledLink = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 7px 16px;
  background: #fff;
  .text {
    font-family: 'Montserrat';
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    color: ${({ theme: { colors } }) => colors.grey};
  }
  .arrow {
    width: 12px;
    height: 12px;
    border-right: solid 2px ${({ theme: { colors } }) => colors.grey};
    border-bottom: solid 2px ${({ theme: { colors } }) => colors.grey};
    transform: rotate(-45deg);
  }
`
