// Modules
import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import cx from 'classnames'
import { ChatType } from 'shared'

// Assets
import { ReactComponent as BackIcon } from 'assets/arrow-back-icon.svg'

// Utils
import { getEventViewInfo } from '_legacy/components/Chats/helpers'

// Legacy components
import Avatar from '_legacy/components/Avatar'

// Constants
import { SECURE_CHAT } from '_legacy/constants/routes'


const ActiveChatHeader = ({
  selectedChat: {
    chatInfo
  },
  openChatDetails,
  isMobile
}) => {
  const history = useHistory()

  const navigateToChats = React.useCallback(() => {
    history.replace(`/${SECURE_CHAT}/`)
  }, [])

  const mobileClassName = cx({ 'mobile-view': isMobile })

  const isEventChat = chatInfo.type === ChatType.EVENT_GROUP

  const eventIcon = isEventChat
    && getEventViewInfo(chatInfo.eventType).icon({ fill: '#9495A7', width: '16px' })

  const eventLabel = isEventChat && getEventViewInfo(chatInfo.eventType).label

  return (
    <div className={`chat-header-wrapper ${mobileClassName}`}>
      {!isMobile ? (
        <>
          <Avatar file={chatInfo?.image?.file} className="chat__icon" />

          <div className="chat-header-info-wrapper" onClick={() => openChatDetails()}>
            <div className="chat-info">
              <p className="chat-info__chat-title">{chatInfo.title}</p>

              {isEventChat && (
                <div className="chat-info__chat-type">
                  {eventIcon}
                  <p>{eventLabel}</p>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <BackIcon className="back-btn" onClick={navigateToChats} />

          <div className="chat-header-info-wrapper" onClick={() => openChatDetails()}>
            <div className="chat-info">
              <p className="chat-info__chat-title">{chatInfo.title}</p>
            </div>

            <Avatar file={chatInfo?.image?.file} className="chat__icon" />
          </div>
        </>
      )}
    </div>
  )
}

ActiveChatHeader.propTypes = {
  openChatDetails: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  selectedChat: PropTypes.objectOf(
    PropTypes.shape({
      chatInfo: PropTypes.object,
    })
  ),
}

export default ActiveChatHeader
