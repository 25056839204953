import React from 'react'
import get from 'lodash/get'
import size from 'lodash/size'

import LineString from '_legacy/components/Table/table-components/types/common/LineString'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const Orders = ({ isLoading, orders }) => {
  const t = useGetTranslate()
  const multiple = 1 < size(orders)
  const orederNumber = multiple ? t('my-orders.order.multiple') : get(orders, ['0', 'number'])

  return (
    <LineString isLoading={isLoading} str={orederNumber} />
  )
}

export default Orders
