import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const PublicDetailsBlock = ({
  children,
  heading,
}) => {
  return (
    <Fragment>
      <StyledExperienceDetails className="wrapper content-wrapper">
        <h4>{heading}</h4>
        <StyledExperienceDetailsList>
          {children}
        </StyledExperienceDetailsList>
      </StyledExperienceDetails>
    </Fragment>
  );
};

export const StyledExperienceDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 72px;
  
  h4 {
    font-size: 2.625em;
    line-height: 54px;
    margin: 72px 0;
    text-align: center;
    color: var(--theme-color--blue);
    font-family: var(--theme-fontFamily--primary);
    font-weight: var(--theme-fontWeight--black);
  }

  @media only screen and (max-width: 1024px) {
    h4 {
      margin: 68px 0;
      font-size: 1.75em;
    }
  }

  @media only screen and (max-width: 767px) {
    margin-bottom: 32px;
    
    h4 {
      font-size: 1.375em;
      margin: 32px 0;
    }
  }
`

export const StyledExperienceDetailsList = styled.div`
  display: grid;
  grid-template-columns: 35% 35%;
  grid-row-gap: 48px;
  grid-column-gap: 30%;
  max-width: 940px;
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
  }
`;

export const StyledExperienceDetailsTerms = styled.p`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 0.75em;
  line-height: 26px;
  color: #272835;
  margin: 80px 0 100px;
  text-align: center;
  cursor: pointer;
  span {
    font-weight: 700;
    text-decoration: underline;
  }
`;

export const StyledInfoListItemModal = styled.div`
  padding: 40px;
  background: #ffffff;
  box-shadow: 0px 20px 60px rgb(0 0 0 / 30%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  flex-direction: column;
`

PublicDetailsBlock.propTypes = {
  children: PropTypes.node,
  heading: PropTypes.string,
};

export default PublicDetailsBlock;
