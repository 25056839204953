import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { ReactComponent as LiveVideoChats } from 'assets/offerings-icons/live-video-chats.svg'
import { ReactComponent as VideoMessage } from 'assets/offerings-icons/video-message.svg'
import { ReactComponent as InPersonExperience } from 'assets/offerings-icons/in-person-experience.svg'
import { ReactComponent as Lessons } from 'assets/offerings-icons/lessons.svg'
import { ReactComponent as VirtualEvent } from 'assets/offerings-icons/virtual-event.svg'
import { ReactComponent as ReadyMadeLesson } from 'assets/offerings-icons/ready-made-lesson.svg'
import { ReactComponent as VideoMessageForCommercialUse } from 'assets/offerings-icons/video-message-for-commercial-use.svg'

import * as r from '_legacy/constants/routes';
import * as offerings from '_legacy/constants/offerings'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useMyOfferings } from 'store/hooks/globalState/useManageOfferings'
import { useFetchMyOfferings } from 'requests/manage-offerings'

import CustomButton from '_legacy/components/Button/CustomButton'

import eventPath from 'utils/eventPath'

const options = [
  {
    type: offerings.VIDEO_CHAT,
    Icon: LiveVideoChats,
    label: 'table.filters.offerings-types.live-video-chat',
    href: `/${r.OFFERINGS}/${r.VIDEO_CHAT}/${r.NEW}`
  },
  {
    type: offerings.VIDEO_MESSAGE,
    Icon: VideoMessage,
    label: 'table.filters.offerings-types.video-message',
    href: `/${r.OFFERINGS}/${r.VIDEO_MESSAGE}/${r.NEW}`
  },
  {
    type: offerings.EXPERIENCES,
    Icon: InPersonExperience,
    label: 'table.filters.offerings-types.in-person-experience',
    href: `/${r.OFFERINGS}/${r.EXPERIENCES}/${r.NEW}`,
    isStatic: true
  },
  {
    type: offerings.LESSONS,
    Icon: Lessons,
    label: 'table.filters.offerings-types.lessons',
    href: `/${r.OFFERINGS}/${r.LESSONS}`
  },
  {
    type: offerings.LAST_LIVE_VIRTUAL_EVENT,
    Icon: VirtualEvent,
    label: 'table.filters.offerings-types.virtual-event',
    href: `/${r.OFFERINGS}/${r.LIVE_VIRTUAL_EVENTS}/${r.NEW}`,
    isStatic: true
  },
  {
    type: offerings.LAST_MASTERCLASS,
    Icon: VirtualEvent,
    label: 'table.filters.offerings-types.live-masterclasses',
    href: `/${r.OFFERINGS}/${r.MASTER_CLASS}/${r.NEW}`,
    isStatic: true
  },
  {
    type: offerings.READY_MADE_EVENT,
    Icon: ReadyMadeLesson,
    label: 'table.filters.offerings-types.ready-made-lesson',
    href: `/${r.OFFERINGS}/${r.READY_MADE_EVENT_LESSON}/${r.NEW}`,
    isStatic: true
  },
  {
    type: offerings.COMMERCIAL_VIDEO_MESSAGE,
    Icon: VideoMessageForCommercialUse,
    label: 'table.filters.offerings-types.for-commercial-use',
    href: `/${r.OFFERINGS}/${r.COMMERCIAL_VIDEO_MESSAGE}/${r.NEW}`
  }
]

export default React.memo(function AddOfferingButton ({ className = '' }) {
  const t = useGetTranslate()
  const fetchMyOfferings = useFetchMyOfferings()
  const myOfferings = useMyOfferings()
  const ref = React.useRef()
  const [isOpen, setIsOpen] = React.useState(false)

  const togleMenu = React.useCallback(() => setIsOpen(current => !current), [setIsOpen])

  const closeMenu = React.useCallback((e) => {
    if (eventPath(e).includes(ref.current)) return
    setIsOpen(false)
  }, [ref, setIsOpen])

  React.useEffect(() => {
    fetchMyOfferings()
  }, [])

  React.useEffect(() => {
    window.addEventListener('click', closeMenu)
    return () => window.removeEventListener('click', closeMenu)
  }, [])

  const parsedOptions = React.useMemo(() => {
    if (!myOfferings) return null
    return options
      .filter(({ isStatic, type }) => isStatic || !myOfferings[type])
      .map(({ type, Icon, label, href }) => {
        return (
          <Link
            key={type}
            className='menu-item'
            to={href}
          >
            <Icon className='icon' />
            {t(label)}
          </Link>
        )
      })
  }, [myOfferings, options, t])

  return (
    <StyledWrapper
      ref={ref}
      className={className}
      isOpen={isOpen}
    >
      <CustomButton
        className='custom-button'
        clickHandler={togleMenu}
      >
        <div className='button-label'>
          + {t('table.button.mo')}
        </div>
        <div className='handler' />
      </CustomButton>
      {isOpen && (
        <div className='menu-wrapper'>
          {parsedOptions}
        </div>
      )}
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  position: relative;
  .custom-button {
    width: 100%;
    .handler {
      width: 9px;
      height: 9px;
      border-right: solid 2px #fff;
      border-bottom: solid 2px #fff;
      transform: rotate(45deg);
      margin-top: -4px;
      margin-left: 9px;
      ${({ isOpen }) => isOpen && `
        transform: rotate(-135deg);
        margin-top: 4px;
      `};
      transition: transform 0.2s, border-color 0.2s;
    }
  }
  .menu-wrapper {
    position: absolute;
    top: 100%;
    left: 50%;
    background: #fff;
    border-radius: 4px;
    transform: translate(-50%, 10px);
    min-width: 140px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    z-index: 5;
    .menu-item {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      color: ${({ theme: { colors } }) => colors.grey};
      padding: 7px 16px;
      margin: 4px 0;
      white-space: nowrap;
      .icon {
        margin-right: 12px;
        path {
          fill: ${({ theme: { colors } }) => colors.grey}
        }
      }
      :hover {
        background: ${({ theme: { colors } }) => colors.ultraLightBlue};
        cursor: pointer;
      }
    }
    .menu-item.active {
      color: ${({ theme: { colors } }) => colors.blue};
      font-weight: 700;
    }
  }
  :last-child {
    .menu-wrapper {
      right: 0;
      left: auto;
      transform: translate(0, 10px);
    }
  }
`
