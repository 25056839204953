import { _post, _get } from 'api/utils'

export const startChat = ({ participantId }) =>
  _post(`chats/start-chat/${participantId}`, {
    needAuthorization: true,
  })

export const getUserChat = ({ chatId }) =>
  _get(`chats/${chatId}`, {
    needAuthorization: true,
  })

export const getUserChats = ({ query = {} }) =>
  _get('chats/my-chats-list', {
    needAuthorization: true,
    query,
  })
