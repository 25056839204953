// Modules
import React from 'react'

// Store (hooks)
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

// Utils
import { closeOverlay } from 'components/BottomNavigation/utils'

// Components
import BottomNavigationOverlay from '../../BottomNavigationOverlay'
import LocationSearch from 'components/LocationSearch'


const Location = () => {
  const t = useGetTranslate()

  return (
    <BottomNavigationOverlay
      title={t('footer.mobile.search-by-location')}
    >
      <LocationSearch onSubmit={closeOverlay} />
    </BottomNavigationOverlay>
  )
}

export default Location
