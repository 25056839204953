import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import get from 'lodash/get'
import { useCommercialEngagements, useSetCommercialEngagements } from 'store/hooks/globalState/useCommercialEngagements'
import {
  getCommercialEngagement as getCommercialEngagementApi,
  requestCommercialEngagement as requestCommercialEngagementApi,
  requestCommercialEngagementVideoMessage,
} from 'api/appApi/offers/commercialEngagements'
import { notify } from 'utils/notify'
import { useGetTranslate } from '../store/hooks/globalState/useTranslates'

export function useFetchCommercialEngagements() {
  const setCommercialEngagements = useSetCommercialEngagements((prev, next) => next)

  const { talentNickname } = useParams()

  return React.useCallback(() => {
    getCommercialEngagementApi({ talentNickname })
      .then(({ talent }) => {
        setCommercialEngagements(talent)
      })
      .catch(() => {
        setCommercialEngagements({})
      })
  }, [setCommercialEngagements, talentNickname])
}

export function useRequestCommercialEngagements(setIsLoading) {
  const t = useGetTranslate()
  const talentId = useCommercialEngagements(state => get(state, 'id'))
  const history = useHistory()

  return React.useCallback(
    data => {
      setIsLoading(true)
      requestCommercialEngagementApi({ talentId, data })
        .then(() => {
          notify(t('commercial-engagements.form.message.success'))
          history.push('/')
        })
        .catch(err => console.warn(err))
        .finally(() => setIsLoading(false))
    },
    [talentId, t]
  )
}

export function useRequestCommercialEngagementVideoMessage() {
  const t = useGetTranslate()
  const history = useHistory()

  return React.useCallback(({ offerId, data }) => {
    requestCommercialEngagementVideoMessage({ offerId, data })
      .then(() => {
        // todo: pull out notify from router
        setTimeout(() => {
          notify(t('request-video-mes-commercial.notify.success'), 'success')
        }, 10);
        history.push('/');
      })
      .catch(() => {
        notify(t('request-video-mes-commercial.notify.error'), 'error')
      })
  }, [t])
}
