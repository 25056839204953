import get from 'lodash/get'

export default  value => {
  const companyPhoneDeliverVersion = Boolean(get(value, 'company.phoneDeliverVersion'))
  const requestorPhoneDeliverVersion = Boolean(get(value, 'requestor.phoneDeliverVersion'))

  return {
    'company': {
      'toEmail': get(value, ['company', 'toEmail']),
      'name': get(value, ['company', 'name']),
      'industry': get(value, ['company', 'industry']),
      'productType': get(value, ['company', 'productType']),
      'productName': get(value, ['company', 'productName']),
      'phone': companyPhoneDeliverVersion ? value.company.phone : undefined,
      'phoneDeliverVersion': companyPhoneDeliverVersion,
      'makeTheMessagePublic': get(value, ['company', 'makeTheMessagePublic']) === 'true',
    },
    'requestor': {
      'name': get(value, ['requestor', 'name']),
      'title': get(value, ['requestor', 'title']),
      'fromEmail': get(value, ['requestor', 'fromEmail']),
      'fromEmailDeliverVersion': Boolean(get(value, ['requestor', 'fromEmailDeliverVersion'], undefined)),
      'phone': requestorPhoneDeliverVersion ? value.requestor.phone : undefined,
      'phoneDeliverVersion': requestorPhoneDeliverVersion
    },
    'message': {
      'productDescription': get(value, ['message', 'productDescription']),
      'script': get(value, ['message', 'script']),
      'otherInstructions': get(value,['message', 'otherInstructions'])
    }
  }
}
