import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Loader from '../../components/Common/Loader';

const LoaderTemplate = ({ size = 64, fill = '#636583' }) => {
  return (
    <LoaderWrapper>
      <Loader size={size} fill={fill} />
    </LoaderWrapper>
  );
};

const LoaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

LoaderTemplate.propTypes = {
  size: PropTypes.number,
  fill: PropTypes.string,
};

export default LoaderTemplate;
