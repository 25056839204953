import isEqual from 'lodash/fp/isEqual'
import prop from 'lodash/fp/prop'
import compose from 'lodash/fp/compose'

import { getDateRangeOptions } from '_legacy/components/CreateExperience/components/TimeSelectWithTimeZone/utils'

export const getMinutesAndHours = date => {
  const _date = new Date(date || undefined)
  return {
    hours: _date.getHours(),
    minutes: _date.getMinutes()
  }
}

export const getTimeSelectOption = date => {
  const dateOptions = getDateRangeOptions()
  return dateOptions.find(
    compose(
      isEqual(getMinutesAndHours(date)),
      getMinutesAndHours,
      prop('value')
    )
  )
}
