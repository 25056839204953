import React from 'react'
import { offers, api } from 'shared'
import { set } from 'lodash'
import { head, last, prop } from 'lodash/fp'
const offersType = offers.Type
const responseTypes = api.categories.ResponseType

import {
  getTalentsCategories as getTalentCategoriesApi,
  getOffersCategories as getOffersCategoriesApi,
} from 'api/appApi/category/category'
import { useSetTalentCategories, useSetOffersCategories } from 'store/hooks/globalState/useCategories'

const offerNames = {
  [offersType.FOR_COMMERCIAL_USE]: 'shared.offers.for-commercial-use',
  [offersType.IN_PERSON_EXPERIENCE]: 'shared.offers.in-person-experience',
  [offersType.READY_MADE_LESSON]: 'shared.offers.ready-made-lesson',
  [offersType.VIDEO_CHAT]: 'shared.offers.video-chat',
  [offersType.VIDEO_MESSAGE]: 'shared.offers.video-message',
  [offersType.VIDEO_VOICE_OVER_LESSON]: 'shared.offers.video-voice-over-lesson',
  [offersType.VIRTUAL_EVENT]: 'shared.offers.virtual-event',
  [offersType.VIRTUAL_LESSON]: 'shared.offers.virtual-lesson',
  [offersType.LIVE_IN_PERSON_LESSON]: 'shared.offers.live-in-person-lesson',
}

export function useFetchTalentCategories() {
  const setTalentCategories = useSetTalentCategories((_, next) => next)

  return React.useCallback(() => {
    getTalentCategoriesApi()
      .then(({ categories }) => {
        setTalentCategories(categories)
      })
  }, [setTalentCategories])
}

export function useFetchOffersCategories() {
  const setOffersCategories = useSetOffersCategories((_, next) => next)

  return React.useCallback(() => {
    // getOffersCategoriesApi()
    getOffersCategoriesApi({ responseType: responseTypes.BY_PARENT_ID })
      .then(({ categories = [] }) => {
        const categoriesWithName = categories.map(category => ({
          ...category,
          n: offerNames[category.t],
          sC: category.s.length,
          i: category.t,
        }))
        setOffersCategories(categoriesWithName)
      })
  }, [setOffersCategories])
}

const findPath = (categories, ids, path = [], level = 0) => {
  const idx = categories.findIndex(c => c.i === ids[level])
  if (idx > -1) {
    path.push(idx)
    if (categories[idx]?.s) {
      path.push('s')
      return findPath(categories[idx]?.s, ids, path, level + 1)
    }
  }
  return [...path, 's']
}

export function useFetchOffersCategoriesByParentId() {
  const setOffersCategories = useSetOffersCategories((prev, ids, subcategories) => {
    const path = findPath(prev, ids)
    return set(prev, path, subcategories)
  })

  return React.useCallback((ids) => {
    getOffersCategoriesApi({
      offerTypeIn: [head(ids)],
      responseType: responseTypes.BY_PARENT_ID,
      parentId: last(ids)
    })
      .then(({ categories }) => {
        const newCategories = prop([0, 's'], categories)
        setOffersCategories(ids, newCategories)
      })
  }, [setOffersCategories])
}


export function useFetchFirstLevelOffersCategories() {
  const setOffersCategories = useSetOffersCategories((_, next) => next)

  return React.useCallback(() => {
    getOffersCategoriesApi({ responseType: responseTypes.BY_PARENT_ID })
      .then(({ categories }) => {
        const categoriesWithName = categories.map(category => ({
          ...category,
          n: offerNames[category.t],
          i: category.t
        }))
        setOffersCategories(categoriesWithName)
      })
  }, [setOffersCategories])
}
