import styled from 'styled-components';

const DropZoneWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-width: ${({ imgSrc }) => !imgSrc && '2px'};
  border-style: solid;
  border-color: ${({ error }) => !error ? '#BABBCD' : 'red'};
  outline: none;
  cursor: pointer;
  border-radius: ${({ theme }) => theme.borderRadius.default};
  margin-bottom: ${({ desc }) => desc ? '8px' : '0'};

  i {
    color: ${({ theme }) => theme.colors.lightGrey};
  }
  
  .title {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.625;
    color: #272835;
    margin-top: 16px;
    margin-bottom: 0;
    text-align: center;
  }

  .description {
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.625;
    text-align: center;
    color: #9495A7;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0;
    
    @media only screen and (max-width: 768px) {
      margin-bottom: 16px;
    }
  }
`

export default DropZoneWrapper
