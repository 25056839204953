import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

import Icon from 'assets/icons/Icon'

const Drawer = React.forwardRef(({ children, isOpen, close, headerLabel, className }, ref) => {
  const classes = cx('drawer', className, {
    'drawer--active': isOpen,
  })

  return (
    <div ref={ref} className={classes}>
      <div className="drawer__header">
        <h6 className="drawer__label">{headerLabel}</h6>
        <button type="button" className="drawer__close-btn" onClick={close}>
          <Icon.Close fill="#9495a7" width={12} height={12} />
        </button>
      </div>
      <div className="drawer__inner">{children}</div>
    </div>
  )
})

Drawer.propTypes = {
  children: PropTypes.node,
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  className: PropTypes.string,
}

export default Drawer
