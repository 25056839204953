import React from 'react'

const handledKeys = [32, 38, 40]

export default React.memo(function KeyHandler ({ scrollHandler }) {
  React.useEffect(() => {
    function handleKeyPress ({ keyCode }) {
      if (!handledKeys.includes(keyCode)) return
      let direction
      if (keyCode === 32 || keyCode === 40) direction = 1
      if (keyCode === 38) direction = -1
      scrollHandler({ direction, deltaY: 0 })
    }
    window.addEventListener('keydown', handleKeyPress, false);
    return () =>  window.removeEventListener('keydown', handleKeyPress, false);
  }, [scrollHandler])
  return null
})
