import React from 'react'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import omit from 'lodash/omit'
import identity from 'lodash/fp/identity'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { mapper, getIds, getAllTalentCount } from '../utils'

import LoaderList from './LoaderList'
import CategoriesSelectListItem from './CategoriesSelectListItem'

const DesktopCategoriesSelect = React.memo(
  ({
    allCategoriesLabel,
    getFn,
    withCount,
    value,
    setValue,
    level,
    showAllCategories,
    filterFn = identity,
  }) => {
    const t = useGetTranslate()

    const onItemClick = item => setValue([...value.slice(0, level), item])
    const items = getFn(
      state => filterFn(mapper(state, getIds(value), level), level, value),
      [level, value, filterFn]
    )

    const shouldShowNextLevelList = get(value, [level, 'sc']) > 0
    const currentLevelId = get(value, [level, 'i'])

    // generate props for current column header list item
    const headerItem = {
      s: [],
      i: get(value, [level - 1, 'i']),
      n: `${t('shared.words.all')} ${t(get(value, [level - 1, 'n'], allCategoriesLabel))}`,
      c: get(value, [level - 1, 'c'], getAllTalentCount(items)),
      isHeader: true,
    }

    if (!items) return <LoaderList />

    return (
      <>
        <ul className="cascader-list__list" key={'prev' + level}>
          {showAllCategories && (
            <CategoriesSelectListItem
              key={headerItem?.i}
              item={headerItem}
              withCount={withCount}
              handleClick={() => onItemClick(omit(headerItem, 'n'))}
            />
          )}
          {items.map(item => (
            <CategoriesSelectListItem
              item={item}
              key={item.i}
              withCount={withCount}
              handleClick={() => onItemClick(item)}
              isSelected={currentLevelId === item.i}
            />
          ))}
        </ul>
        {shouldShowNextLevelList && (
          <DesktopCategoriesSelect
            getFn={getFn}
            value={value}
            level={level + 1}
            setValue={setValue}
            key={'next' + level}
            withCount={withCount}
            showAllCategories={showAllCategories}
          />
        )}
      </>
    )
  }
)

DesktopCategoriesSelect.defaultProps = {
  level: 0,
  showAllCategories: false,
}

DesktopCategoriesSelect.propTypes = {
  getFn: PropTypes.func.isRequired,
  withHeading: PropTypes.bool,
  withCount: PropTypes.bool,
  value: PropTypes.array.isRequired,
  setValue: PropTypes.func.isRequired,
  level: PropTypes.number,
  allCategoryTitle: PropTypes.string,
  showAllCategories: PropTypes.bool,
  filterFn: PropTypes.func,
  allCategoriesLabel: PropTypes.string,
}

export default DesktopCategoriesSelect
