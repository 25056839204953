import React from 'react';
import styled from 'styled-components';

import { Row } from '_legacy/common/Template';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const RequiredLabel = () => {
  const t = useGetTranslate();

  return (
    <Wrapper>
      <Row component={t('talent-invitation.form.tip.required')} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .row {
    margin-bottom: 16px;
    
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
  
    color: #272835;
  }
`;

export default RequiredLabel;
