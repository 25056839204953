import format from 'date-fns/format'
import { dateFormats } from 'utils/date'

export const getHourFormat = hour12 => ({
  eventTimeFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
    hour12: hour12,
  },
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    meridiem: 'short',
    hour12: hour12,
  },
})

export const getEventTimeFormat = (date, hour12, locale) => {
  const { year, month, day, hour, minute, second } = date.date
  const d = new Date(year, month, day, hour, minute, second)
  const dateFormat = hour12 ? dateFormats.timeAmPm : dateFormats.militaryTime
  return format(new Date(d), dateFormat, { locale })
}

export const getEventTimeFormats = (hour12, locale) => ({
  eventTimeFormat: date => getEventTimeFormat(date, hour12, locale),
  slotLabelFormat: date => getEventTimeFormat(date, hour12, locale),
})
