// TODO: Refactor wrappers and forms according to DRY after project ends
import React, { useState }  from 'react'
import { string, bool } from 'prop-types'
import cx from 'classnames'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'


const LessonBookWrapper = ({
  showActiveBlock,
  header,
  children
}) => {
  const t = useGetTranslate()
  const [isShow, setIsShow] = useState(false)
  const handleShowBlock = () => setIsShow(prevState => !prevState)

  return (
    <div className={cx('lesson-wrap', { on: showActiveBlock })}>
      <div className="header">
        <div className="header-line-block"/>
        <div className="title">{t(header)}</div>
        <div className="header-line-block"/>
      </div>

      <div className='content'>
        <div className={`content__wrapper ${isShow ? 'on' : ''}`}>
          { children }
        </div>

        <button type='button' className="lesson__more" onClick={handleShowBlock}>
          {t('lessons.button.show')} &nbsp;
          {isShow ? t('lessons.button.less') : t('lessons.button.more')}
        </button>
      </div>
    </div>
  )
}


LessonBookWrapper.propTypes = {
  header: string.isRequired,
  showActiveBlock: bool,
}

export default LessonBookWrapper
