import { types } from 'shared'
import map from 'lodash/map'
import get from 'lodash/get'

const { Levels, List } = types.languages

export const languagesList = map(List, (value, key) => ({
  label: value.native,
  value: key
}))

export const levelOptions = (t) => [
  {
    label: t('register.languages.language-levels.native'),
    value: Levels.NATIVE,
  },
  {
    label: t('register.languages.language-levels.fluent'),
    value: Levels.FLUENT,
  },
  {
    label: t('register.languages.language-levels.basic'),
    value: Levels.BASIC
  },
]

export const transformLanguages = (t, { level, language }) => {
  const lvl = levelOptions(t).find(op => op.value === level);
  const lang = languagesList.find(op => op.value === language);
  return { level: lvl, language: lang };
};

export const getLanguagesAdapter = (languages, t) => {
  return languages?.map(({ level, language }) => transformLanguages(t, { level, language }))
}

const getLanguageOption = languageOption => {
  const level = get(languageOption, 'level.value')
  const language = get(languageOption, 'language.value')
  return (level && language) ? { level, language } : null
}

export const submitLanguagesAdapter = (languages) => {
  return languages
    ? languages.map(getLanguageOption).filter(Boolean)
    : undefined
}
