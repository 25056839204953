import { _post } from '../../utils';

export const contactUs = (data) => {
  return _post('/one-direction-requests/contact-us', { body: data });
};

export const subscribeForUpdates = ({ email }) => _post('/email-subscriptions/subscribe', {
  body: {
    email,
  },
});

export const whyJoin = (data) => _post('/one-direction-requests/why-join', { body: data });
export const careers = (data) => _post('/one-direction-requests/careers', { body: data });
