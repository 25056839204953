import { _get, _patch } from 'api/utils'

export const getUserNotificationSettings = () => _get('/auth/me/settings/notifications', { needAuthorization: true })

export const updateUserNotificationSettings = ({ data }) =>
  _patch('/auth/me/settings/notifications', { needAuthorization: true, body: data })

export const updateUserSettings = ({ data }) =>
  _patch('/auth/me/settings/', {
    needAuthorization: true,
    body: data,
  })
