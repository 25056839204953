import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

let iconIndex = 0;

const getIndex = () => iconIndex++;

const ViewStarIcon = ({
  className,
  percentageFill,
  leftColor,
  rightColor,
  size,
  ...props
}) => {
  const index = useMemo(getIndex);
  const gradientId = `ViewStarIconGradient_${index}`;
  return (
    <svg
      viewBox="0 0 18 17"
      className={`${className} base-icon`}
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: size, height: size }}
      {...props}
    >
      <linearGradient id={gradientId}>
        <stop offset={`${percentageFill}%`} stopColor={leftColor} />
        <stop offset={`${percentageFill}%`} stopColor={rightColor} />
      </linearGradient>
      <path
        fill={`url(#${gradientId})`}
        d="M8.11941 0.636343C8.49691 -0.0651469 9.50309 -0.0651453 9.88059 0.636345L11.8634 4.32079C12.0076 4.58877 12.2659 4.7765 12.5654 4.83084L16.6822 5.578C17.466 5.72025 17.7769 6.67718 17.2264 7.25298L14.335 10.2773C14.1247 10.4972 14.026 10.801 14.0669 11.1025L14.6284 15.2487C14.7354 16.0382 13.9214 16.6296 13.2036 16.2839L9.43387 14.4686C9.15969 14.3366 8.84031 14.3366 8.56613 14.4686L4.79638 16.2839C4.07864 16.6296 3.26464 16.0382 3.37156 15.2487L3.93313 11.1025C3.97398 10.801 3.87528 10.4972 3.66499 10.2773L0.773573 7.25298C0.223071 6.67718 0.533997 5.72025 1.31781 5.578L5.43463 4.83084C5.73406 4.7765 5.99244 4.58877 6.13665 4.32079L8.11941 0.636343Z"
      />
    </svg>
  );
};

ViewStarIcon.defaultProps = {
  className: '',
  percentageFill: 100,
  leftColor: '#272835',
  rightColor: '#9495A7',
  size: 24,
};

ViewStarIcon.propTypes = {
  className: PropTypes.string,
  percentageFill: PropTypes.number,
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
  size: PropTypes.number,
};

export default ViewStarIcon;
