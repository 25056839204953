import React from 'react'
import PropTypes from 'prop-types'
import has from 'lodash/fp/has'

import PageLoader from '_legacy/common/Template/PageLoader'
import SearchTalentCard from '_legacy/components/Search/SearchCardItem/SearchTalentCard'
import SearchOfferingCard from '_legacy/components/Search/SearchCardItem/SearchOfferingCard'
import Pagination from '_legacy/components/Table/table-components/Pagination';

import '_legacy/components/Search/List/index.scss'

const isOffering = has('offerType')

const FavoritesList = ({ getListFn, getFiltersFn, EmptyList }) => {
  const list = getListFn()

  if (!list) return <PageLoader />

  if (list.length === 0) return <EmptyList />

  return (
    <>
      <div className="search-list">
        {list.map((value) => (
          <div key={value.id} className='search-list__item'>
            {isOffering(value)
              ? <SearchOfferingCard value={value} />
              : <SearchTalentCard value={value}/>}
          </div>
        ))}
      </div>
      <Pagination getFiltersFn={getFiltersFn} />
    </>
  )
}

FavoritesList.propTypes = {
  getListFn: PropTypes.func,
  getFiltersFn: PropTypes.func,
  EmptyList: PropTypes.element,
}

export default FavoritesList
