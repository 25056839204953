import React from 'react'

import Icon from 'assets/icons/Icon'

const LockSlot = () => {
  return (
    <div className="calendar-slot">
      <div className="calendar-slot__locked">
        <Icon.Lock />
      </div>
    </div>
  )
}

export default LockSlot
