import React from 'react';
import { useFormContext } from 'react-hook-form';

// components
import FFirstName from '../../../shared/components/FirstName';

const FirstName = () => {
  const { watch } = useFormContext();
  const isVerificationSuccess = watch('isVerificationSuccess', false);

  return (
    <FFirstName isDisabled={isVerificationSuccess} />
  );
};

export default FirstName;
