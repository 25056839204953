import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const NicknameInfoModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="upload-info-modal">
        <h3 className="upload-info-modal__title">{t('register-user.form.nick-name.label')}</h3>
        <br/>
        {t('register-user.form.nick-name.modal-hint')}
      </div>
    </ModalPortal>
  );
};

NicknameInfoModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default NicknameInfoModal;
