// TODO: refactor component (Formatting, remove Styled, module structure)

import React from 'react';

import { Row, Input } from '_legacy/common';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const IndustryField = () => {
  const t = useGetTranslate();

  return (
    <Row
      nameBlock={t('request-video-mes-commercial.company.industry.label')}
      isRequired
      component={
        <Input
          name='company.industry'
          placeholder={t('request-video-mes-commercial.company.industry.placeholder')}
          requiredMessage='request-video-mes-commercial.company.industry.error.required'
          isRequired
          validation={{
            pattern: {
              value: /^[a-zA-Z\s]*$/,
              message: t('request-video-mes-commercial.company.industry.error.not-valid')
            }
          }}
        />
      }
    />
  );
};

export default IndustryField;
