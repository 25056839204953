import React from 'react'
import PropTypes from 'prop-types'
import { useFormContext } from 'react-hook-form';

import { useFileEntityById } from 'store/hooks/globalState/useFileEntites';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const PreviousImage = ({ name, id }) => {
  const t = useGetTranslate()
  const { setValue } = useFormContext()
  const img = useFileEntityById(id)

  if (!img) return null

  const onClick = () => setValue(name, id, { shouldDirty: true })

  return (
    <li key={img.fileId} className='list__item'>
      <button type='button' className='list__button' onClick={onClick}>
        <span>{t('register.upload.hint.button.choose')}</span>
        <img src={img.fullUrl} alt={img.originalName} />
      </button>
    </li>
  )
}

PreviousImage.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
}

export default PreviousImage
