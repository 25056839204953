import React, { useState, useEffect } from 'react'
import cx from 'classnames'
import {
  compose,
  findLastIndex,
  last,
  size,
  subtract,
  divide,
  __,
  propEq,
  anyPass,
  head,
} from 'lodash/fp'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import StatusBarStep from './StatusBarStep/'
import Icon from 'assets/icons/Icon'

import { STATUS_STATE } from './constants.js'

const isStepActive = propEq('state', STATUS_STATE.ACTIVE)
const isStepDone = propEq('state', STATUS_STATE.DONE)

const isShowActiveStart = compose(anyPass([isStepActive, isStepDone]), head)
const isShowActiveEnd = compose(anyPass([isStepActive, isStepDone]), last)

const getActiveStepIndex = steps => {
  const index = findLastIndex(isStepActive)(steps)
  return index !== -1 ? index : steps?.length - 1
}

const getSliceLength = compose(divide(100), subtract(__, 1), size)


const StatusBar = ({ steps: defaultSteps }) => {
  const [expanded, setExpanded] = useState(true)
  const [steps, setSteps] = useState(defaultSteps)
  const [isMediaMatches, setIsMediaMatches] = useState(null)
  const t = useGetTranslate()

  const activeStepIndex = getActiveStepIndex(steps)

  useEffect(() => {
    const media = window.matchMedia('(max-width: 768px)')
    setIsMediaMatches(media.matches)

    const onMediaChange = e => {
      setIsMediaMatches(e.matches)
    }

    media.addListener(onMediaChange)

    return () => {
      media.removeListener(onMediaChange)
    }
  }, [])

  useEffect(() => {
    expanded
      ? setSteps(defaultSteps)
      : setSteps([defaultSteps[activeStepIndex]])
  }, [defaultSteps])

  useEffect(() => {
    expanded
      ? setSteps(defaultSteps)
      : setSteps([steps[activeStepIndex]])
  }, [expanded])

  useEffect(() => {
    isMediaMatches === false && setExpanded(true)
    isMediaMatches === true && setExpanded(false)
  }, [isMediaMatches])

  const onClick = isMediaMatches ? () => setExpanded(!expanded) : () => {}

  if (!steps?.length) return null

  const slice = getSliceLength(steps)

  const lineStyles = {
    '--status-line-width': `${slice * activeStepIndex}%`,
  }

  const lineClasses = cx('status-bar__line', {
    'status-bar__line--start': isShowActiveStart(steps),
    'status-bar__line--end': isShowActiveEnd(steps),
  })

  const expandClasses = cx('status-bar__expand', {
    expanded,
  })

  const statusBarClasses = cx('status-bar', {
    ['status-bar--vertical']: isMediaMatches,
  })

  return (
    <div className={statusBarClasses} onClick={onClick}>

      <span className={expandClasses} onClick={onClick}>
        <Icon.ArrowDownSelectMenu width="9" />
      </span>

      <div className="status-bar__container">

        <div className={lineClasses}>
          <div className="status-bar__line--main" style={lineStyles}>
            <div className="status-bar__line--main__wrapper">
              <div className="status-bar__line--main--bg" />
              <div className="status-bar__line--main--progress" />
            </div>
          </div>
        </div>

        <div className="status-bar__container__steps">
          {steps?.filter(Boolean).map(({ title, subtitle, state }, index) =>
            <StatusBarStep
              key={title + index}
              title={t(title)}
              subtitle={t(subtitle)}
              state={state}
            />
          )}
        </div>

      </div>
    </div>
  )
}

export default StatusBar
