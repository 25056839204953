import React from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import classnames from 'classnames'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import { useSetFullpageBlock } from 'store/hooks/globalState/useGlobalState';

import { Title, Checkbox } from '_legacy/common';
import Button from '_legacy/components/Button';
import Icon from 'assets/icons/Icon';

import ControlledCollapse from 'components/ControlledCollapse';
import CookiesDetails from 'components/Cookies/CookiesDetails';

import { cookies } from 'utils/services/cookies';

const Cookies = ({ themeType }) => {
  const t = useGetTranslate();
  const [close, setClose] = React.useState(false);
  const [collapsed, setCollapsed] = React.useState(true);
  const blockFullpage = useSetFullpageBlock((prev, next) => next, []);

  const methods = useForm();

  const mainCookies = cookies.main;

  const handleSubmit = (data) => {
    cookies.main = data;
    setClose(true);
  }

  const handleSubmitOneOption = () => handleSubmit({ necessary: true });

  const handleSubmitSelectionOption = () => {
    const values = methods.getValues();

    const data = {
      necessary: values.necessary === 'true',
      preferences: values.preferences === 'true',
      statistics: values.statistics === 'true',
      marketing: values.marketing === 'true',
    };

    handleSubmit(data);
  };

  const handleSubmitAllOption = async () => {
    const data = {
      necessary: true,
      preferences: true,
      statistics: true,
      marketing: true,
    };

    handleSubmit(data);
  };

  const onClickDetails = () => {
    setCollapsed(!collapsed)
  }

  React.useEffect(() => {
    blockFullpage(!collapsed)
  }, [collapsed])

  return !close && !mainCookies && (
    <section className={`cookies ${themeType}`}>
      <FormProvider {...methods}>
        <div className="cookies__wrapper">
          <Title className="title">{t('cookie.title')}</Title>
          <p className="description">{t('cookie.description')}</p>
          <div className="buttons">
            <Button
              text={t('cookie.button.text1')}
              fontSize='.75'
              secondary
              stretch
              handleOnClick={handleSubmitOneOption}
            />
            <Button
              text={t('cookie.button.text2')}
              fontSize='.75'
              stretch
              handleOnClick={handleSubmitSelectionOption}
            />
            <Button
              text={t('cookie.button.text3')}
              fontSize='.75'
              stretch
              handleOnClick={handleSubmitAllOption}
            />
          </div>
          <hr className='line'/>
          <div className='bottom'>
            <div className='options'>
              <Checkbox
                name='necessary'
                value='true'
                label={t('cookie.checkbox.label1')}
                defaultChecked
                disabled
                secondary
                className='checkboxCustom'
              />
              <Checkbox
                name='preferences'
                value='true'
                label={t('cookie.checkbox.label2')}
                secondary
                className='checkboxCustom'
              />
              <Checkbox
                name='statistics'
                value='true'
                label={t('cookie.checkbox.label3')}
                secondary
                className='checkboxCustom'
              />
              <Checkbox
                name='marketing'
                value='true'
                label={t('cookie.checkbox.label4')}
                secondary
                className='checkboxCustom'
              />
            </div>
            <button
              type="button"
              className={classnames('details', {
                'collapse': !collapsed
              })}
              onClick={onClickDetails}
            >
              {t('cookie.button.details')} <Icon.ArrowDownSelect />
            </button>
          </div>

          <ControlledCollapse collapsed={collapsed}>
            <CookiesDetails />
          </ControlledCollapse>
        </div>
      </FormProvider>
    </section>
  )
};

Cookies.propTypes = {
  themeType: PropTypes.string
};

export default Cookies;
