import React from 'react'
import PropTypes from 'prop-types'

const PublicDescriptionOneLine = ({ children, className = '' }) => {
  return (
    <div className={`public-view-description--one-line ${className}`}>
      {children}
    </div>
  )
}

PublicDescriptionOneLine.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default PublicDescriptionOneLine
