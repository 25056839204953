import Actions from 'components/OfferingDetails/OrderDetails/ReadyMadeEventLessonsDetails/components/ReadyMadeEventLessonsDetailsProductTable/components/Actions'

export const ACTION = 'action'
export const FILE = 'file'

export const elementsTypes = {
  [ACTION]: ACTION,
  [FILE]: FILE,
}

export const PRODUCT_FILES = 'product-files'

import File from './File'

export const productFilesComponents = {
  [ACTION]: Actions,
  [FILE]: File,
}
