import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useHistory } from 'react-router-dom'

import PopperTooltip from '_legacy/components/Tooltip/PopperTooltip'

import {
  useUser,
} from 'store/hooks/globalState/useUser';
import { useLogout } from 'requests/auth'

import Icon from 'assets/icons/Icon';
import TalentSwitcher from './UserProfile';
import NotificationMenu from '../NotificationMenu'

import { getUserFullName, getTalentFullName, isAgentOrManager, isAgentWithTalent } from 'utils/user'

import './index.scss'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

const UserAccount = ({ isAccountMenuActive, onClick }) => {
  const t = useGetTranslate();
  const user = useUser()
  const fullName = getUserFullName(user);
  const talentFullName = getTalentFullName(user)
  const logout = useLogout()
  const history = useHistory()
  const isUserAgent = isAgentOrManager(user);
  const isUserAgentWithTalent = isAgentWithTalent(user);

  const usernameNode = isUserAgentWithTalent
    ? <><p>{fullName}</p><p>as <span>{talentFullName}</span></p></>
    : <p>{fullName}</p>

  const usernameClassName = [
    'user-profile-name',
    isUserAgentWithTalent && 'with-talent'
  ]
    .filter(Boolean)
    .join(' ');

  const logoutHandler = () => {
    logout()
    history.push('/')
  }

  return (
    <div className="user-account">
      <div className="user-profile user-account__profile" onClick={onClick}>
        <div className={usernameClassName}>
          {usernameNode}
        </div>
        <div className="user-profile-photo user-account__indicator">
          <Icon.ArrowDownSelectMenu className={cx({ opened: isAccountMenuActive })} />
        </div>
      </div>

      <div className="user-account__icons">
        {isUserAgent &&
          <PopperTooltip Component={t('user-account.tooltip.switcher')} placement={'bottom'}>
            <TalentSwitcher />
          </PopperTooltip>
        }

        <PopperTooltip Component={t('user-account.tooltip.logout')} placement={'bottom'}>
          <div className="user-account__exit" onClick={logoutHandler}>
            <Icon.SignOut />
          </div>
        </PopperTooltip>

        <NotificationMenu />
      </div>

    </div>
  );
};

UserAccount.propTypes = {
  isAccountMenuActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UserAccount;
