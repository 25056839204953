import React from 'react';
import PropTypes from 'prop-types';

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import ModalPortal from 'portals/ModalPortal';

const PhysicalItemsIncludedPriceModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate();
  return (
    <ModalPortal isOpen={isOpen} onClose={onClose} showCloseButton isMobileFullScreen>
      <div className="upload-info-modal">
        <h3 className="upload-info-modal__title">
          {t('live-virtual-events.form.physical-items-included.modal.header')}
        </h3>
        {t('live-virtual-events.form.physical-items-included.modal.hint')}
        <br/>
      </div>
    </ModalPortal>
  );
};

PhysicalItemsIncludedPriceModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

export default PhysicalItemsIncludedPriceModal;
