import { _post } from 'api/utils'

export const followTalent = ({ talentId }) => _post(
  `/talents/${talentId}/actions/follow`,
  { needAuthorization: true }
)

export const unFollowTalent = ({ talentId }) => _post(
  `/talents/${talentId}/actions/unfollow`,
  { needAuthorization: true }
)

export const followOffer = ({ offerId }) => _post(
  `/offers/${offerId}/actions/follow`,
  { needAuthorization: true }
)

export const unFollowOffer = ({ offerId }) => _post(
  `/offers/${offerId}/actions/unfollow`,
  { needAuthorization: true }
)
