import React from 'react'

import TableLoading from './TableLoading'
import EmptyTable from './EmptyTable'
import TableRowsList from './TableRowsList'

export default function TableContent ({
  tableName,
  emptyTableText,
  tableConfig,
  actionsConfig,
  bulkActionsConfig,
  getTableFn,
  components,
  withRejected,
}) {
  const rows = getTableFn()

  if (!rows) {
    return (
      <TableLoading
        tableName={tableName}
        tableConfig={tableConfig}
        actionsConfig={actionsConfig}
        bulkActionsConfig={bulkActionsConfig}
      />
    )
  }

  if (rows.length === 0) return <EmptyTable text={emptyTableText}/>

  return (
    <TableRowsList
      tableName={tableName}
      tableConfig={tableConfig}
      actionsConfig={actionsConfig}
      bulkActionsConfig={bulkActionsConfig}
      rows={rows}
      components={components}
      withRejected={withRejected}
    />
  )
}
