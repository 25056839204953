import React from 'react';
import PropTypes from 'prop-types';
import ModalPortal from 'portals/ModalPortal';
import styled from 'styled-components';
import { useSetModal } from 'store/hooks/globalState/useModal';
import Button from '_legacy/components/Button'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
const AlertModal = ({ message }) => {
  const t = useGetTranslate();
  const closeModal = useSetModal(() => null)
  return (
    <ModalPortal isOpen onClose={closeModal} showCloseButton >
      <div className="alert-modal-card">
        <p className="alert-modal__text">
          { message }
        </p>
        <ButtonContainer>
          <Button
            text= {t('shared.words.ok')}
            typeButton="button"
            paddingVertical={13}
            handleOnClick={closeModal}
          />
        </ButtonContainer>
      </div>
    </ModalPortal>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-self: flex-end;
  @media screen and (max-width: 575px) {
    margin-top: auto;
  }
  .button {
    width: 150px;
    margin: auto;
  }
  
  .button:not(:last-of-type) {
    margin-right: 16px;
  }

  @media screen and (max-width: 575px) {
    flex-direction: column-reverse;
    width: 100%;

    .button:not(:last-of-type) {
      margin-right: 0 ;
      margin-top: 16px;
      
    }
  }
`

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func
};

export default AlertModal;
