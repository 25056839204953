import React from 'react'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

import Button from '_legacy/components/Button';

import { createConnectedAccount, refreshAccountLink } from '_legacy/components/PaymentSettings/state/api'

const PaymentAccountConnect = ({ isStripeAccountReady }) => {
  const t = useGetTranslate()

  const [connectUrl, setConnectUrl] = React.useState(null)
  const [connectButtonText, setConnectButtonText] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    if (isStripeAccountReady === null) {
      createConnectedAccount()
        .then(({ url }) => {
          setIsLoading(false)
          setConnectButtonText('account.menu.payments.button.connect')
          setConnectUrl(url)
        })
        .catch(err => console.warn(err))
    } else if (isStripeAccountReady === false) {
      refreshAccountLink()
        .then(({ url }) => {
          setIsLoading(false)
          setConnectButtonText('account.menu.payments.button.finish-connect')
          setConnectUrl(url)
        })
        .catch(err => console.warn(err))
    }
  }, [isStripeAccountReady])


  const runStripeConnect = () => {
    setIsLoading(true)
    window.location.href = connectUrl
  }


  return (
    <div className='payment-settings'>
      <Button
        handleOnClick={() => runStripeConnect()}
        text={t(connectButtonText)}
        classBtn='payment-settings--button'
        loader={isLoading}
        fontSize={0.75}
        primary
        paddingVertical={18}
      />
      <p className="payment-settings--description">{t('account.menu.payments.settings.description')}</p>
    </div>
  );
};

export default PaymentAccountConnect;
