import React from 'react'
import styled from 'styled-components'

import { useSubMenuActive } from 'store/hooks/globalState/useGlobalState'

export default React.memo(function Title () {
  const isActive = useSubMenuActive()
  return (
    <StyledWrapper>
      <h3>One-on-One VIP Experiences</h3>
      <h1 className={`${isActive ? 'active' : ''}`}>
        Live Your Dream
      </h1>
    </StyledWrapper>
  )
})

const StyledWrapper = styled.div`
  padding-top: 135px;
  color: #9495a7;
  text-align: center;
  position: relative;
  z-index: 1;
  h3 {
    font-size: 18px;
    font-size: 26px;
  }
  h1 {
    font-family: 'Playfair Display';
    font-size: 63px;
    font-weight: 900;
    opacity: 1;
    color: #ffffff;
    transition: 0.35s ease;
    transition-property: color, blur;
    &.active {
      color: #9495a7;
      opacity: 0.8;
    }
  }
  @media (max-width: 1560px) {
    h3 {
      font-size: 18px;
    }
    h1 {
      font-size: 50px;
    }
  }
  @media only screen and (max-width: 1366px) {
    h1 {
      font-size: 42px;
    }
  }
  @media only screen and (max-width: 1024px) {
    h3 {
      font-size: 16px;
    }
    h1 {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 1024px) and (orientation: landscape) {
    padding-top: var(--theme-header-height-small);
  }
  @media only screen and (max-width: 768px) and (orientation: portrait) {
    padding-top: 135px;
    h1 {
      color: #ffffff !important;
      opacity: 1 !important;
    }
  }
  @media only screen and (max-width: 480px) {
    h3 {
      font-size: 12px;
    }
    h1 {
      font-size: 28px;
    }
  }
  @media only screen and (max-width: 480px) and (orientation: portrait) {
    padding-top: var(--theme-header-height-small);
  }
`
