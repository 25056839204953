import { _get, _post } from 'api/utils';

export const fetchRecurringExperienceHeader = ({ talentId, id, query }) => _get(`/talents/${talentId}/offers/experiences/${id}`, {
  needAuthorization: true, query
});


export const fetchRecurringExperienceList = ({ talentId, query }) => _get(`/talents/${talentId}/offers/experiences/occurrences`, {
  needAuthorization: true, query
})

export const checkAvailabilityToCancelFromList = ({ talentId, offerId, experienceOccurrenceId, query }) => _get(
  `/talents/${talentId}/offers/${offerId}/occurrences/${experienceOccurrenceId}/check-availability-to-cancel`,
  { needAuthorization: true, query },
)

export const cancelOccurrence = ({ talentId, id, occurrenceId, body }) => _post(
  `/talents/${talentId}/offers/${id}/occurrences/${occurrenceId}/cancel-occurrence`,
  { needAuthorization: true, body }
)
