import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const Range = React.memo(function Range ({ min, max, step, defaultValue, onChange }) {
  const inputRef = useRef();

  const handleMove = React.useCallback(() => {
    const inputElement = inputRef.current;
    const percent =
      ((inputElement.value - inputElement.min) /
        (inputElement.max - inputElement.min)) *
      100;
    inputElement.style.setProperty('--webkitProgressPercent', `${percent}%`);
  }, []);

  useEffect(() => {
    handleMove();
  }, [defaultValue]);

  return (
    <div className='range'>
      <input
        className='range__input'
        type={'range'}
        ref={inputRef}
        min={min}
        max={max}
        step={step}
        value={defaultValue}
        onChange={e => {
          handleMove();
          onChange(e.currentTarget.value);
        }}
      />
    </div>
  );
});

Range.defaultProps = {
  min: 0,
  max: 100,
  step: 1,
  defaultValue: 0,
  onChange: () => {
  },
};

Range.propTypes = {
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
};

export default Range;
