import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import uniq from 'lodash/uniq'
import isEmpty from 'lodash/isEmpty'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import PreviousImage from './PreviousImage';

const PreviousImages = ({ label, name, namePrevious }) => {
  const t = useGetTranslate();
  const { watch } = useFormContext()
  const currentImgId = watch(name)
  const previousImagesIds = watch(namePrevious, [])

  const filtered = uniq(previousImagesIds.filter(id => id !== currentImgId));

  if (isEmpty(filtered)) return null

  return (
    <StyledWrapper>
      {label && <h4 className='label'>{t(label)}</h4>}

      <ul className='list'>
        {filtered.slice(0, 5).map((id) => (
          <PreviousImage key={id} id={id} name={name} namePrevious={namePrevious} />
        ))}
      </ul>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  margin-top: 24px;
  .label {
    display: inline-block;
    margin: 0 0 12px 0;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    line-height: 1.4;
    text-transform: uppercase;
    color: #626473;
  }
  .list {
    display: grid;
    grid-template-columns: repeat(5, 96px);
    grid-column-gap: 20px;
    @media only screen and (max-width: 1024px) {
      grid-template-columns: repeat(5, 1fr);
    }

    @media only screen and (max-width: 575px) {
      grid-template-columns: repeat(5, 59px);
      grid-column-gap: 12px;
      height: 91px;
    }
    &__item {
      height: 148px;
      @media only screen and (max-width: 575px) {
        height: 91px;
      }
    }
    &__button {
      width: 100%;
      height: 100%;
      position: relative;
      background-color: #E9EAF0;
      outline: none;
      border: none;
      cursor: pointer;
      margin: 0;
      padding: 0;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 100%;
        height: 100%;
        background: linear-gradient(180deg, rgba(39, 40, 53, 0) 0%, rgba(39, 40, 53, 0.8) 67.71%);
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
      &:hover {
        &:before,
        span {
          opacity: 1;
          visibility: visible;
        }
      };
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      span {
        position: absolute;
        left: 50%;
        bottom: 24px;
        transform: translateX(-50%);
        z-index: 1;
        background-color: #fff;
        border: 2px solid #272835;
        border-radius: 4px;
        font-family: 'Montserrat', sans-serif;
        font-weight: bold;
        font-size: 12px;
        line-height: 1.4;
        color: #272835;
        padding: 5px 8px;
        cursor: pointer;
        opacity: 0;
        transition: opacity .3s ease-in-out;
      }
    }
  }
`;

PreviousImages.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  namePrevious: PropTypes.string,
};

export default PreviousImages;
