import React from 'react'
import { useForm } from 'react-hook-form'
import compose from 'lodash/fp/compose'
import prop from 'lodash/fp/prop'
import isNil from 'lodash/isNil'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import {
  useExperience,
  useSetExperience
} from 'store/hooks/globalState/offerings/experience'
import {
  useFetchExperience,
  useUpdateExperience,
  useCreateExperienceDraft,
} from 'requests/offerings/experience'

import CreateExperienceForm from '_legacy/components/CreateExperience'
import OfferingTitle from '_legacy/containers/Offerings/components/OfferingTitle'
import OfferingTemplate from '_legacy/containers/Offerings/components/OfferingTemplate'
import withLibrary from '_legacy/containers/Offerings/components/withLibrary'
import PageLoader from '_legacy/common/Template/PageLoader'

import { createExperienceAdapter } from '_legacy/components/CreateExperience/utils/createExperienceAdapter'
import { getDefaultValues } from '_legacy/components/CreateExperience/utils/defaultValues'
import getExperienceAdapter from '_legacy/components/CreateExperience/utils/getExperienceAdapter'
import { useGetRecurringOptions } from '_legacy/components/CreateExperience/components/Recurring/config'

const EditExperience = () => {
  const t = useGetTranslate()
  const [isLoading, setIsLoading] = React.useState(false)

  const fetchExperience = useFetchExperience()
  const clearOccurrence = useSetExperience(() => null)
  const experience = useExperience()
  const getRecurringOptions = useGetRecurringOptions()
  const recurringIdToExclude = useExperience(prop('recurringId'))

  const methods = useForm({
    defaultValues: getDefaultValues(t),
    mode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: false,
    shouldUnregister: false,
  })

  const updateExperience = useUpdateExperience(setIsLoading, methods)
  const createExperienceDraft = useCreateExperienceDraft()

  const onSubmit = compose(
    updateExperience,
    createExperienceAdapter
  )

  const onDraft = compose(
    createExperienceDraft,
    createExperienceAdapter,
    methods.getValues
  )

  React.useEffect(() => {
    fetchExperience()
    return clearOccurrence
  }, [])

  React.useEffect(() => {
    if (!isNil(experience)) {
      const formState = getExperienceAdapter(experience, t)
      const recurring = getRecurringOptions(formState.dateOfExperience).find(o => o.value === experience?.recurring?.type)

      methods.reset({ ...formState, recurring }, { isDirty: false })
    }
  }, [experience])

  if (isNil(experience)) {
    return <PageLoader />
  }

  return (
    <OfferingTemplate>
      <OfferingTitle>{t('create-experience.header.edit')}</OfferingTitle>
      <CreateExperienceForm
        methods={methods}
        onSubmit={onSubmit}
        onDraft={onDraft}
        isLoading={isLoading}
        isSubmitDisabled={false}
        recurringIdToExclude={recurringIdToExclude}
      />
    </OfferingTemplate>
  )
}

export default withLibrary(EditExperience)
