import React from 'react'
import prop from 'lodash/fp/prop'

import Search from '_legacy/components/Search'
import EmptyOffersList from '_legacy/components/Search/List/EmptyOffersList'

import {
  useSearchOffers,
  useSearchOffersFilters,
  useSetSearchOffersFilters,
  useClearSearchOffersFilters,
} from 'store/hooks/globalState/useSearchList'
import { useSearchPromotedOffers } from 'store/hooks/globalState/useSearchPromotedList'
import { useFetchOffersItems } from 'requests/search/offers'
import { useFetchPromotedOffersItems } from '_legacy/components/Search/FeaturedSlider/state/requests'
import { filtersConfig, fields } from '_legacy/configs/search/offerings'
import { handleDisable } from './utils'

const SearchOffers = () => {
  const offeringTypesIn = useSearchOffersFilters(prop('offeringTypesIn'))
  const mappedFilterConfig = handleDisable(offeringTypesIn, filtersConfig)

  return (
    <Search
      fields={fields}
      getListFn={useSearchOffers}
      getPromotedListFn={useSearchPromotedOffers}
      filtersConfig={mappedFilterConfig}
      clearFn={useClearSearchOffersFilters}
      fetchListFn={useFetchOffersItems}
      fetchPromotedListFn={useFetchPromotedOffersItems}
      getFiltersFn={useSearchOffersFilters}
      EmptyListComponent={EmptyOffersList}
      setFiltersFn={useSetSearchOffersFilters}
    />
  )
}

export default SearchOffers
