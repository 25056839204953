import { useState } from 'react';

export const useFetch = (apiFunction) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const fetch = async (data) => {
    try {
      setIsLoading(true);
      const _data = await apiFunction(data);
      setData(_data);
    } catch(e) {
      setError(e);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return [{ data, error, isLoading }, fetch];
};
