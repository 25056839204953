import React from 'react'
import { useHistory } from 'react-router-dom'

import paginateLinkBuilder from 'utils/paginateLinkBuilder'

export default React.memo(function PrevButton ({ page }) {
  const history = useHistory()
  const navigateBack = React.useCallback(() => {
    if (page <= 1) return
    history.push(paginateLinkBuilder(page - 1))
  }, [page, history])
  return (
    <div
      className={`button-wrapper ${page === 1 ? 'disabled' : ''}`}
      onClick={navigateBack}
    >
      <div className='arrow prev' />
    </div>
  )
})
