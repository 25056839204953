// TODO: create a separate folder with an adequate module structure
import { always, compact, compose, cond, curry, join, map, T } from 'lodash/fp'
import { format, formatDuration, intervalToDuration, isValid, parseISO } from 'date-fns/fp'
import { format as formatLocale, addDays, compareAsc, differenceInCalendarDays, differenceInHours, isToday, set, startOfWeek, formatDuration as formatDurationLocale } from 'date-fns'
import range from 'lodash/range'
import clamp from 'lodash/clamp'
import { enUS, es, pt } from 'date-fns/locale'

import { TIME_UNITS, TIME_UNITS_IN_MS } from './convertTime'
import { getTimezoneAbbr, parseDate } from './services/timezone'

export const dateFormats = {
  shortMonthDate: 'MMM dd, yyyy',
  fullMonthDate: 'MMMM dd, yyyy',
  sortDateWithTime: 'MMM dd p',
  dayTime: 'eee',
  monthDate: 'MMMM d, yyyy',
  timeAmPm: 'h:mm aaa',
  calendarDate: 'MM/dd/yy',
  monthDayOnly: 'MMMM dd',
  militaryTime: 'HH:mm',
  fullMonthWithTime: 'dd MMMM, h:mm aaa',
  fullMonthWithMilitaryTime: 'dd MMMM, HH:mm',
  externalCalendarTime: 'dd-MM-yyyy h:mm aaa',
}

const toDate = date => date ? new Date(date) : null

export const getFormattedLocaleDate = curry((dateFormat, date, locale) => {
  try {
    return formatLocale(toDate(date), dateFormat, { locale })
  } catch {
    return ''
  }
})

export const getShortDateWithLocaleTime = getFormattedLocaleDate(dateFormats.shortMonthDate)
export const getMonthDayYearLocaleDate = getFormattedLocaleDate(dateFormats.fullMonthDate)
export const getShortMonthLocaleDate = getFormattedLocaleDate(dateFormats.shortMonthDate)
export const getDayLocaleDate = getFormattedLocaleDate(dateFormats.dayTime)

export const getFormattedDate = dateFormat =>
  compose(
    cond([
      [isValid, format(dateFormat)],
      [T, always('')],
    ]),
    toDate
  )

export const getFormattedDateRange = (dateFormat, separator = ' - ') =>
  compose(join(separator), compact, map(dateFormat))

export const getShortDateWithTime = getFormattedDate(dateFormats.shortMonthDate)

export const getFullMonthDate = getFormattedDate(dateFormats.sortDateWithTime)

export const getCalendarDate= getFormattedDate(dateFormats.calendarDate)

export const getDayDate = getFormattedDate(dateFormats.dayTime)

//TODO check why here same value as above
export const getShortMonthDate = getFormattedDate(dateFormats.shortMonthDate)

export const getDateRange = getFormattedDateRange(getShortDateWithTime, ' - ')

export const getAmPmTime = getFormattedDate(dateFormats.timeAmPm)

export const getMonthDate = getFormattedDate(dateFormats.monthDate)

export const getMonthDayYearDate = getFormattedDate(dateFormats.fullMonthDate)

export const getMonthDayOnly = getFormattedDate(dateFormats.monthDayOnly)

export const getMilitaryTime = getFormattedDate(dateFormats.militaryTime)

export const getFullMonthWithTime = getFormattedDate(dateFormats.fullMonthWithTime)

export const getTimeRange = getFormattedDateRange(getAmPmTime, ' - ')

/*
 * Returns the ceiled duration in days or hours between two dates
 * @param {Date} from - Start date
 * @param {Date} to - End date
 */
export const getDaysOrHoursDuration = curry((from, to) => {
  const duration = intervalToDuration({
    start: from,
    end: to,
  })

  // +1 to ceil time left values
  if(duration.days) {
    return formatDuration({
      days: duration.days + 1,
    })
  } else {
    return formatDuration({
      hours: duration.hours + 1,
    })
  }
})

export const getDaysOrHoursDurationFromNow = getDaysOrHoursDuration(Date.now())

export const getDaysInWeek = (locale) => {
  const firstDOW = startOfWeek(new Date())
  return range(7).map((e, i) => getDayLocaleDate(addDays(firstDOW, i), locale))
}

export const getMinAvailableTime = date => {
  if (isToday(new Date(date))) return new Date()

  return set(new Date(date), {
    hours: 0,
    minutes: 0
  })
}


export const convertSeconds = (seconds, locale) => {
  return formatDuration(
    {
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor(seconds / 60) % 60,
      seconds: Math.round(seconds % 60)
    },
    { format: ['hours', 'minutes', 'seconds'], locale }
  );
}

export const isDateRangeValid = ({ startTime, endTime }) => compareAsc(new Date(startTime), new Date(endTime)) === -1

export const secondsFromStartDate = date => {
  const dateInstance = new Date(date)
  return dateInstance.getHours() * 3600 + dateInstance.getMinutes() * 60
}

export const militaryTimeToDate = (date = '') => {
  const [hours, minutes, seconds] = date.split(':')
  return set(new Date(), { hours, minutes, seconds })
}

export const getDateTo = curry((count, date) => {
  const dateInstance = new Date(date)
  const diffInDays = differenceInCalendarDays(dateInstance, new Date())

  return diffInDays < 1
    ? differenceInHours(new Date(), dateInstance) + 'Hours Left'
    : clamp(diffInDays, 1, count) + ' Days Left'
})

export const diffFromNowTo = (count, from, now = new Date()) => {
  const dateTo = addDays(new Date(from), count)

  if (now > dateTo) return 'Cancelled'

  const diff = differenceInCalendarDays(dateTo, now)

  return diff <= 1
    ? differenceInHours(dateTo, now) + ' Hours Left'
    : diff + ' Days Left'
}


export const timeBeforeReject = (dueTime) => {
  if (!dueTime) return

  return getDaysOrHoursDuration(new Date(), new Date(dueTime))
}

export const getDaysTo = (dueDate, options) => {
  if (!dueDate || !isValid(dueDate)) return ''

  const duration = intervalToDuration({
    start: new Date(),
    end: dueDate,
  })

  if(duration.days) {
    return formatDurationLocale({ days: duration.days + 1, }, options)
  } else {
    return formatDurationLocale({ hours: duration.hours + 1 }, options)
  }
}

export const calculateCountdownFromNow = date => {
  const now = new Date().getTime()

  const distance = new Date(date).getTime() - now

  if (distance <= 0) {
    return {
      expired: true,
      values: {
        days: 0,
        hours: 0,
        minutes: 0,
      }
    }
  }

  return {
    expired: false,
    values: {
      days: Math.floor(distance / TIME_UNITS_IN_MS[TIME_UNITS.d]).toString().padStart(2, '0'),
      hours: Math.floor((distance % TIME_UNITS_IN_MS[TIME_UNITS.d]) / TIME_UNITS_IN_MS[TIME_UNITS.h]).toString().padStart(2, '0'),
      minutes: Math.floor((distance % TIME_UNITS_IN_MS[TIME_UNITS.h]) / TIME_UNITS_IN_MS[TIME_UNITS.m]).toString().padStart(2, '0'),
      seconds: Math.floor((distance % TIME_UNITS_IN_MS[TIME_UNITS.m]) / TIME_UNITS_IN_MS[TIME_UNITS.s]).toString().padStart(2, '0'),
    }
  }
}

export const getDurationFromMs = ms =>
  intervalToDuration({
    start: new Date(0),
    end: new Date(ms),
  })

export const isISODateString = compose(
  isValid,
  parseISO,
)

const localeConfig = {
  EN: enUS,
  ES: es,
  PT: pt
}

export const dateLocaleCalendar = {
  pt: 'PT',
  es: 'ES',
  'en-gb': 'EN'
}

export const getLocale = userLang => localeConfig[userLang] || enUS

export const getDateTimeProps = ({ startTime, endTime, timeZone }) => {
  const timeZoneAbbr = getTimezoneAbbr(timeZone)

  const zonedStartTime = startTime ? parseDate(startTime, timeZone) : ''
  const zonedEndTime = endTime ? parseDate(endTime, timeZone) : ''

  const formattedDate = getMonthDate(zonedStartTime)
  const formattedTimeRange = `${getTimeRange([zonedStartTime, zonedEndTime])} (${timeZoneAbbr})`

  return {
    timeZoneAbbr,
    formattedDate,
    formattedTimeRange,
    startTime: zonedStartTime,
    endTime: zonedEndTime,
  }
}
