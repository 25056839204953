import React from 'react';

import StyledDropzone from '_legacy/common/Dropzone';
import { UploadHint } from '_legacy/common';
import Icon from 'assets/icons/Icon';

import { useUploadFileToFileLibrary } from 'requests/upload';
import { useGetTranslate } from 'store/hooks/globalState/useTranslates';
import PropTypes from 'prop-types';
import MediaDropZone from '../../configs/Media/MediaDropZone';

const DefaultHint = () => {
  const t = useGetTranslate()

  return (
    <UploadHint
      iconComponent={<Icon.File />}
      title={t('register.upload.hint.file.subtitle')}
      text={t('register.upload.hint.file.subtitle')}
    />
  )
}

const DropZone = ({ accept, maxSize = 200 }) => {
  const uploadFile = useUploadFileToFileLibrary();
  const onDrop = files => files.forEach(uploadFile);

  const Hint = MediaDropZone || DefaultHint;

  return (
    <StyledDropzone onDrop={onDrop} multiple={true} accept={accept} maxSize={maxSize}>
      <Hint maxSize={maxSize} />
    </StyledDropzone>
  );
};

DropZone.propTypes = {
  accept: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  hintComponent: PropTypes.element,
  multiple: PropTypes.bool,
  noClick: PropTypes.bool,
  noDrag: PropTypes.bool,
  maxSize: PropTypes.number,
}

export default DropZone;
