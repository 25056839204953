import React from 'react'
import PropTypes from 'prop-types'
import range from 'lodash/range'

import ViewStarIcon from 'assets/icons/ViewStarIcon'

const Rating = ({ rating, size = 20, leftColor, rightColor }) => {
  return (
    <div className="user-star-rating">
      {range(0, 5).map(star => (
        <ViewStarIcon
          size={size}
          key={star}
          percentageFill={(rating - star) * 100}
          leftColor={leftColor}
          rightColor={rightColor}
        />
      ))}
    </div>
  )
}

Rating.propTypes = {
  rating: PropTypes.number.isRequired,
  size: PropTypes.number,
  leftColor: PropTypes.string,
  rightColor: PropTypes.string,
}

export default Rating
