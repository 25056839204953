import React from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import get from 'lodash/get'

import { TALENT, REGISTER, DRAFT } from '_legacy/constants/routes'

import { useTalentId } from 'store/hooks/globalState/useUser'
import { useSwitchToTalent } from 'requests/talent-switcher'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useConnectedTalents } from 'store/hooks/globalState/useConnectedTalents'
import { useSwitchId, useSetIdToSwitch, useShouldNavigate, useSwitchNavigationPath } from 'store/hooks/globalState/useSwitchNavigation'
import Avatar from '_legacy/components/Avatar'

const ProfileList = () => {
  const history = useHistory()

  const t = useGetTranslate()
  const talentId = useTalentId()
  const switchToTalent = useSwitchToTalent()
  const connectedTalents = useConnectedTalents()

  const switchId = useSwitchId()
  const setIdToSwitch = useSetIdToSwitch()
  const shouldNavigate = useShouldNavigate()
  const navigationPath = useSwitchNavigationPath()

  const handleTalentSwitch = (id, isDraft) => {
    setIdToSwitch(id)
    const path = isDraft
      ? `/${REGISTER}/${TALENT}/${DRAFT}`
      : navigationPath?.talentRoute
    if (path) history.push(path)
  }

  React.useEffect(() => {
    if (switchId && shouldNavigate) {
      switchToTalent(switchId)
      setIdToSwitch(null)
    }
  }, [shouldNavigate, switchId, switchToTalent, setIdToSwitch])

  const convertedProfiles = React.useMemo(
    () => connectedTalents?.map(p => ({ ...p, isActive: talentId === p.talent.id })),
    [connectedTalents, talentId]
  )

  return (
    <StyledSwitcherList>
      {convertedProfiles?.map(({ id, talent, isActive, isDraft }) => (
        <StyledSwitcherItem
          key={id}
          isActive={isActive}
          onClick={() => handleTalentSwitch(id, isDraft)}
        >
          <Avatar file={talent?.profileImage?.file} alt={talent?.nickname?.avatar} />
          <StyledSwitcherTitle>
            <p>{get(talent, 'displayName')}</p>
            {isDraft && <span>{t('shared.words.draft-label')}</span>}
          </StyledSwitcherTitle>
        </StyledSwitcherItem>
      ))}
    </StyledSwitcherList>
  )
}

const StyledSwitcherList = styled.div`
  max-height: 170px;
  overflow: hidden;
  overflow-y: scroll;
  box-shadow: inset 0 2px 5px rgb(0 0 0 / 15%);
  background: #ffffff;
  scrollbar-width: none;
  &::-webkit-scrollbar-thumb {
    background: #${({ theme }) => theme.colors.blueText};
    border-radius: 4px;
  }
  &::-webkit-scrollbar {
    display: block;
    width: 4px;
  }
`

const StyledSwitcherItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 48px;
  cursor: pointer;
  padding: 8px 16px;
  .avatar {
    width: 40px;
    height: 40px;
  }
  p {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.grey};
  }
  span {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 13px;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.lightGrey};
  }

  ${({ isActive }) =>
  isActive &&
    css`
      img {
        border: 2px solid black;
      }
      p {
        font-weight: 500;
        color: black;
      }
    `}
`

const StyledSwitcherTitle = styled.div`
  padding-left: 12px;
`

export default ProfileList
