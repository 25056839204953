import styled, { css } from 'styled-components';

export const StyledRow = styled.div`
  display: grid;
  grid-template-columns: 10fr 2fr 0fr;
  grid-column-gap: 16px;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px;
  ${({ isSelected }) => isSelected && css`
    background-color: #D5DFEC;
  `};
  @media screen and (max-width: 767px) {
    padding: 12px 14px;
  }
  @media screen and (max-width: 575px) {
    grid-template-columns: 2fr 1fr 0fr;
  }
`

export const StyledInfo = styled.div`
  display: flex;
  align-items: center;
`

export const StyledImage = styled.div`
  width: 44px;
  max-width: 44px;
  height: 56px;
  box-shadow: 0 0  2px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  margin-right: 16px;
  img {
    border-radius: 4px;
  }
`

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.625; 
  color: ${({ theme }) => theme.colors.blue};
  display: block;
  max-width: 400px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  @media screen and (max-width: 575px) {
    font-size: 14px;
    max-width: 150px;
  }
  @media screen and (max-width: 425px) {
    max-width: 120px;
  }
`

export const StyledName= styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.lightGrey};
  svg {
    margin-right: 6px;
    flex-shrink: 0;
    path {
      fill: ${({ theme }) => theme.colors.lightGrey};
    }
  }

  @media screen and (max-width: 575px) {
    font-size: 10px;
    max-width: 150px;
  }
`
export const StyledPrice = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 1.625;
  color: ${({ theme }) => theme.colors.blue};
  text-align: right;
`
