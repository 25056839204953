import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { types } from 'shared'

import { useGetTranslate } from 'store/hooks/globalState/useTranslates';

import { SubscribeForUpdates } from './SubscribeForUpdates';
import {
  WHY_JOIN,
  SITE_TERMS_OF_SERVICE,
  COMMUNITY_GUIDELINES,
  TALENT_TERMS_OF_SERVICE,
  PRIVACY_POLICY,
} from '_legacy/constants/routes';
import SocialList from '_legacy/components/SocialList'

const { FACEBOOK, INSTAGRAM, TIK_TOK, TWITTER } = types.user.socialMedia.Types

const socialMedia = [
  { link: 'Entreevip-109268221559095', social: FACEBOOK },
  { link: 'entreevipusa', social: INSTAGRAM },
  { link: '@entreevip', social: TIK_TOK },
  { link: 'EntreeVip', social: TWITTER },
]

const Footer = ({ id = null }) => {
  const t = useGetTranslate();

  const currentYear = new Date().getFullYear();

  return (
    <footer id={id} className="footer">
      <div className="footer__desktop">
        <div className="content wrapper content-wrapper">

          <div className='footer__wrapper'>
            <div className='footer__blocks'>
              <div className="footer__block">
                <h5 className="footer__title">{t('footer.company.title')}</h5>
                <ul className="footer__nav">
                  <li>
                    <Link to='/about-us'>{t('footer.company.about_us')}</Link>
                  </li>
                  <li>
                    <Link to='/careers'>{t('footer.company.careers')}</Link>
                  </li>
                  <li>
                    <Link to='/contact'>{t('footer.company.contact')}</Link>
                  </li>
                </ul>
              </div>

              <div className="footer__block">
                <h5 className="footer__title">{t('footer.platform.title')}</h5>
                <ul className="footer__nav">
                  <li>
                    <Link to={`/${WHY_JOIN}`}>{t('footer.platform.talent_enrollment')}</Link>
                  </li>
                  <li>
                    <Link to='/faqs'>{t('footer.platform.faqs')}</Link>
                  </li>
                  <li>
                    <Link to='/how-it-works'>
                      {t('footer.platform.how_it_works')}
                    </Link>
                  </li>
                  <li>
                    <Link to='/how-to-videos'>
                      {t('footer.platform.how_to_videos')}
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="footer__block">
                <h5 className="footer__title">{t('footer.policies.title')}</h5>
                <ul className="footer__nav">
                  <li>
                    <Link to={`/${SITE_TERMS_OF_SERVICE}`}>{t('footer.policies.site_terms')}</Link>
                  </li>
                  <li>
                    <Link to={`/${COMMUNITY_GUIDELINES}`}>{t('footer.policies.guidelines_terms')}</Link>
                  </li>
                  <li>
                    <Link to={`/${TALENT_TERMS_OF_SERVICE}`}>{t('footer.policies.talent_terms')}</Link>
                  </li>
                  <li>
                    <Link to={`/${PRIVACY_POLICY}`}>{t('footer.policies.privacy_terms')}</Link>
                  </li>
                </ul>
              </div>

              <div className="footer__block footer__block--social">
                <h5 className="footer__title">{t('footer.follow_us')}</h5>
                <SocialList socialList={socialMedia} />
              </div>

              <div className="footer__mailing-list">
                <h5 className="footer__title">{t('footer.mailing_list')}</h5>
                <SubscribeForUpdates />
              </div>

              <div className="footer__copyright">
                © {currentYear} EntreeVIP, Inc
              </div>

            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

Footer.propTypes = {
  id: PropTypes.string
};

export default Footer
