import React from 'react'
import { useForm } from 'react-hook-form'
import get from 'lodash/get'

import { Title, Container } from '_legacy/common'
import PageLoader from '_legacy/common/Template/PageLoader'
import VideoMessageRequestForm from '../../VideoMessageRequestForm'
import { VIDEO_MESSAGE } from '_legacy/constants/routes'

import { useBookOffer } from 'requests/offerings/book'
import { useFetchVideoMessage } from 'requests/public-views'
import { useSetVideoMessagePublic, useVideoMessagePublic } from 'store/hooks/globalState/usePublicView'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import defaultValues from '../../utils/dafaultValues'
import submitAdapter from '../../utils/submitAdapter'
import { getFullName, getUser } from 'utils/user'
import getAdapter from '../../utils/getAdapter'

import { useVideoMessageRequestDraft } from '../../state/store'
import { useUser } from 'store/hooks/globalState/useUser'

const VideoMessageRequestDraft = () => {
  const t = useGetTranslate()
  const [loader, setLoader] = React.useState(false)
  const fetchVideoMessage = useFetchVideoMessage()
  const bookVideoMessage = useBookOffer(setLoader)
  const videoMessage = useVideoMessagePublic()
  const clearVideoMessage = useSetVideoMessagePublic(() => null)
  const draftBooking = useVideoMessageRequestDraft()
  const user = useUser(getUser)

  const methods = useForm({ defaultValues, mode: 'onChange' })

  React.useEffect(() => {
    if (!videoMessage) fetchVideoMessage()
    return () => clearVideoMessage()
  }, [])

  React.useEffect(() => {
    if (draftBooking) methods.reset(getAdapter(draftBooking))
  }, [draftBooking])

  if (!videoMessage) return <PageLoader />

  const offerId = get(videoMessage, 'id')

  const onSubmit = data => bookVideoMessage({ offerId, data: submitAdapter(data, user), type: VIDEO_MESSAGE })

  const fullName = getFullName(videoMessage.talent)

  return (
    <div className="video-message-request">
      <Container>
        <Title className="_title">
          {t('video-message.request.header')}
          <br />
          <span className="fullName">{fullName}</span>
        </Title>
        <VideoMessageRequestForm methods={methods} onSubmit={onSubmit} fullName={fullName} loader={loader} />
      </Container>
    </div>
  )
}

export default VideoMessageRequestDraft
