import React from 'react';

const LoaderMessage = () => (
  <div className="react-select-loader">
    <span className="react-select-loader__message">Loading</span>
    <span className="react-select-loader__dot">.</span>
    <span className="react-select-loader__dot">.</span>
    <span className="react-select-loader__dot">.</span>
  </div>
);

export default LoaderMessage;
