import React from 'react'
import { useWatch } from 'react-hook-form'
import { types } from 'shared'
const { ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME } = types.experiences.DaysTimesType

import { useGetTranslate, useUserLang } from 'store/hooks/globalState/useTranslates'

import { Row } from '_legacy/common/Template'
import Select from '_legacy/common/Select/Common'
import { getMinBookingTimeOptions } from './config'
import { getLocale } from 'utils/date'

const MinBookingTimeBeforeExperience = ({ name = 'minBookingTimeBeforeExperience' }) => {
  const t = useGetTranslate()
  const locale = useUserLang(getLocale)
  const minBookingTimeOptions = getMinBookingTimeOptions({ locale })
  const daysTimesType = useWatch({ name: 'daysTimesType' })

  if (daysTimesType?.value === ALLOW_AFICIONADO_TO_PROPOSE_DAY_TIME) {
    return null
  }

  return (
    <Row
      subNameBlock={t('create-experience.form.min-booking-time.tip')}
      nameBlock={t('create-experience.form.min-booking-time.label')}
      component={<Select name={name} options={minBookingTimeOptions} defaultValue={minBookingTimeOptions[2]} />}
    />
  )
}

export default MinBookingTimeBeforeExperience
