import {
  createUseGlobalState,
  createUseSetGlobalState,
} from '../useGlobalState'

const tableKey = 'reviewsByMeTable'
const filtersKey = 'reviewsByMeFilters'

export const useReviewsByMeTable = createUseGlobalState(tableKey)
export const useSetReviewsByMeTable = createUseSetGlobalState(tableKey)

export const useReviewsByMeFilters = createUseGlobalState(filtersKey)
export const useSetReviewsByMeFilters = createUseSetGlobalState(filtersKey)
