import React from 'react'

import InfoModal from 'portals/InfoModal'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'

const EntourageMembershipTypeModal = ({ isOpen, onClose }) => {
  const t = useGetTranslate()
  return (
    <InfoModal onClose={onClose} isOpen={isOpen}>
      <h3>{t('entourage-membership-modal.header')}</h3>
      <p>{t('entourage-membership-modal.info-2')}</p>
    </InfoModal>
  )
}

export default EntourageMembershipTypeModal
