import React from 'react'
import { PromoCodeDiscountType } from 'shared'

import LineString from '../common/LineString'

export default React.memo(function Discount({ isLoading, discountAmount, discountType }) {
  const discount =
    discountType === PromoCodeDiscountType.PERCENTAGE
      ? `${discountAmount}%`
      : `$${discountAmount}`
  return <LineString isLoading={isLoading} str={discount} />
})
