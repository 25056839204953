import React from 'react'
import { NotificationStatus } from 'shared'

import BookingRequestNotification from './BookingRequestNotification'
import BookingRequestRejectedNotification from './BookingRequestRejectedNotification'
import BookingRequestAcceptedNotification from './BookingRequestApprovedNotification'
import EmptyNotification from '../EmptyNotification'

const bookingRequestConfig = {
  [NotificationStatus.ACCEPTED]: BookingRequestAcceptedNotification,
  [NotificationStatus.REJECTED]: BookingRequestRejectedNotification,
  [null]: BookingRequestNotification
}

const BookingRequestNotificationHandler = (props) => {
  const Component = bookingRequestConfig[props.additionalType] || EmptyNotification
  return <Component {...props} />
}

export default BookingRequestNotificationHandler
