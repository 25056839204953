import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ToggleVideoButton from '../Buttons/ToggleVideoButton'
import ToggleAudioButton from '../Buttons/ToggleAudioButton'
import useChatContext from '../../hooks/useChatContext'
import useVideoContext from '../../hooks/useVideoContext'
import LocalVideoPreview from '../LocalVideoPreview'
import ToggleSettingsButton from '../Buttons/ToggleSettingsButton'

import Button from '_legacy/components/Button'
import { Trans, useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { getSaveFullName } from 'utils/user'
import { useVideoState } from '../../hooks/useVideoState'
import * as r from '_legacy/constants/routes'

const transConfig = {
  [r.VIDEO_CHAT]: 'Live Video Chat',
  [r.LIVE_VIRTUAL_LESSON]: 'Live Virtual Lesson',
}


export default function PreJoinScreens() {
  const { occurrenceId, offerType } = useParams();
  const t = useGetTranslate()
  const { isFetching, joinVideoChatRoom, previewInfo } = useVideoState()
  const { connect: chatConnect } = useChatContext()
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting, getAudioAndVideoTracks } = useVideoContext()
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting
  const [mediaError, setMediaError] = useState()

  const handleJoin = () => {
    joinVideoChatRoom(occurrenceId)
      .then(({ twilioAccessToken, videoRoomChat, ...rest }) => {
        videoConnect(twilioAccessToken, rest)
        chatConnect(videoRoomChat)
      })
  }

  useEffect(() => {
    if (!mediaError) {
      getAudioAndVideoTracks().catch(error => {
        setMediaError(error)
      })
    }
  }, [mediaError])

  return (
    <div className="pre-join-screen">
      <div className="pre-join-screen__video">
        <LocalVideoPreview />
        <div className="pre-join-screen__video-controls">
          <ToggleAudioButton disabled={disableButtons} />
          <ToggleVideoButton disabled={disableButtons} />
          <ToggleSettingsButton disabled={disableButtons} />
        </div>
      </div>

      <div className="pre-join-screen__description">
        <h1 className="pre-join-screen__header">
          <Trans
            tKey="video-chat.pre-join-screen.header"
            phs={[
              { ph: '{{offerName}}', value: transConfig[offerType] },
              { ph: '{{participantName}}', value: getSaveFullName(previewInfo?.remoteParticipant) },
            ]}
          />
        </h1>
        <Button
          paddingVertical={13}
          text={t('video-chat.pre-join-screen.join-button')}
          handleOnClick={handleJoin}
          disabled={disableButtons}
          className="pre-join-screen__join-button"
        />
      </div>
    </div>
  )
}
