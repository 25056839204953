// Base imports
import React from 'react'
import { useParams } from 'react-router'
import { Link, useHistory } from 'react-router-dom'
import { experiences } from 'shared'
import prop from 'lodash/fp/prop'

// Assets
import Icon from 'assets/icons/Icon'

// Requests
import { useFetchVirtualEventsOfferingDetailsHeader, useFetchVirtualEventsOfferingDetails } from './state/requests'
import { usePublishOffer, useUnpublishOffer } from 'requests/manage-offerings'

// Store (Hooks)
import {
  useSetVirtualEventsOfferingDetailsTableFilters,
  useVirtualEventsOfferingDetailsTable,
  useVirtualEventsOfferingDetailsTableFilters,
  useVirtualEventsOfferingDetailsHeader,
  useSetVirtualEventsOfferingDetailsHeader,
} from './state/store'
import { useGetTranslate } from 'store/hooks/globalState/useTranslates'
import { useUser } from 'store/hooks/globalState/useUser'
import { useModal } from 'store/hooks/useModal'

// Components
import OfferingDetailsTitle from 'components/OfferingDetails/components/OfferingDetailsTitle'
import OfferingsDetailsHeaderItem from 'components/OfferingDetails/components/OfferingsDetailsHeaderItem'

// Utils
import { getTalentPlaqueNickname } from 'utils/user'
import { isPublishedOffer, isDraftOfferStatus } from 'utils/offer'
import { getExperienceDateType, getOccurrencesLimit } from '../utils'

// Legacy components
import * as routes from '_legacy/constants/routes'
import { filtersConfig, counterConfig, subFiltersConfig, fields } from '_legacy/configs/table/recurringList'
import { elementsTypes } from '_legacy/components/Table/table-components/types/recurring-list/config'
import Filters from '_legacy/components/Table/Filters'
import SubFilters from '_legacy/components/Table/SubFilters'
import Table from '_legacy/components/Table/table-components/Table'
import PageLoader from '_legacy/common/Template/PageLoader'
import { RECURRING_LIST } from '_legacy/components/Table/table-components/types/recurring-list/config'
import CustomMedia from '_legacy/common/CustomMedia'
import Button from '_legacy/components/Button'
import HandleEditModal from '_legacy/components/OfferingsDetails/HandleEditModal'

import '../index.scss'

const sortFields = {
  DATE: 'DATE',
  LOCATION: 'LOCATION',
  PARTICIPANTS: 'PARTICIPANTS',
  STATUS: 'STATUS',
}

const tableConfig = [
  {
    header: 'table.header.date',
    field: sortFields.DATE,
    type: elementsTypes.date,
  },
  {
    header: 'table.header.location',
    field: sortFields.LOCATION,
    type: elementsTypes.location,
  },
  {
    header: 'table.header.participants',
    field: sortFields.PARTICIPANTS,
    type: elementsTypes.participants,
    className: 'centered',
  },
  {
    header: 'table.header.status',
    field: sortFields.STATUS,
    type: elementsTypes.status,
    className: 'centered',
  },
]

//TODO check if this needed for virtual events
const {
  DOES_NOT_REPEAT,
  DAILY,
  WEEKLY_ON_MONDAY,
  MONTHLY_ON_THE_SECOND_MONDAY,
  ANNUALLY_ON_AUGUST_10,
  EVERY_WEEKDAY_MONDAY_TO_FRIDAY,
  CUSTOM,
} = experiences.recurrence.RecurringDefinedInAdvanceValues

const repeatableDates = {
  [DOES_NOT_REPEAT]: 'recurring-list.repeatable-dates.not-repeat',
  [DAILY]: 'recurring-list.repeatable-dates.daily',
  [WEEKLY_ON_MONDAY]: 'recurring-list.repeatable-dates.weekly-on-monday',
  [MONTHLY_ON_THE_SECOND_MONDAY]: 'recurring-list.repeatable-dates.monthly-on-second-monday',
  [ANNUALLY_ON_AUGUST_10]: 'recurring-list.repeatable-dates.annually-on-august',
  [EVERY_WEEKDAY_MONDAY_TO_FRIDAY]: 'recurring-list.repeatable-dates.monday-to-friday',
  [CUSTOM]: 'recurring-list.repeatable-dates.custom',
}

const RecurringListVirtualEvents = () => {
  const t = useGetTranslate()
  const { id } = useParams()
  const history = useHistory()
  const userNickname = useUser(getTalentPlaqueNickname)
  const [isOpen, openModal, closeModal] = useModal()
  const [localIsPublished, setLocalIsPublished] = React.useState(false)

  const publishOffer = usePublishOffer(() => setLocalIsPublished(true))
  const unpublishOffer = useUnpublishOffer(() => setLocalIsPublished(false))
  const fetchOfferingDetails = useFetchVirtualEventsOfferingDetailsHeader()
  const clearOccurrence = useSetVirtualEventsOfferingDetailsHeader(() => null)

  const offering = useVirtualEventsOfferingDetailsHeader(prop('offering'))
  const occurrencesCount = useVirtualEventsOfferingDetailsTableFilters(prop('count'))

  //TODO: Random boolean value to test booked & request in progress before it done on BE
  const activeRequests = Math.random() >= 0.5
  const isBooked = Math.random() >= 0.5

  const cancel = () => alert('not implemented')
  const cancelAllFurther = () => alert('not implemented')

  React.useEffect(() => {
    fetchOfferingDetails()
    return () => {
      clearOccurrence()
    }
  }, [])

  React.useEffect(() => {
    const isPublished = isPublishedOffer(offering)
    const isDraft = isDraftOfferStatus(offering)

    setLocalIsPublished(isPublished && !isDraft)
  }, [offering])

  const actionsConfig = React.useMemo(() => [
    { label: t('table.actions.cancel'), cbFn: cancel },
    { label: t('table.actions.cancel-this-and-future'), cbFn: cancelAllFurther },
  ], [t, cancel, cancelAllFurther])

  if (!offering) return <PageLoader />

  const { name, maxNumberOfParticipants, sequentialId, recurring, price, media } = offering

  const convertedLimit = occurrencesCount || t('recurring-list.info.unlimited')
  const experienceDateType = getExperienceDateType(t, offering)
  const occurrenceLimit = getOccurrencesLimit(t, offering)

  const infoConfig = [
    {
      icon: <Icon.Calendar />,
      item: `${experienceDateType} ${occurrenceLimit}`,
      subitem: recurring ? t(repeatableDates[recurring?.type]) : t('recurring-list.repeatable-dates.custom'),
    },
    {
      icon: <Icon.Pin />,
      item: t('recurring-list.info.virtual-event'),
      subitem: t('recurring-list.locations.online'),
    },
    {
      icon: <Icon.Participants />,
      item: `${t('recurring-list.info.participants')} ${maxNumberOfParticipants || t('recurring-list.info.unlimited')}`,
      subitem: '',
    },
    {
      icon: <Icon.Pricetag />,
      item: `$${price} ${t('currency.usd')}`,
      subitem: `${t('recurring-list.info.participants-price')}`,
    },
  ]

  const editButtonProps = {
    header: t('recurring-list.edit.header'),
    text: t('recurring-list.edit.text'),
    discardText: t('recurring-list.edit.discardText'),
    viewText: t('recurring-list.edit.viewText'),
    cancelText: t('recurring-list.edit.cancelText'),
  }

  const onRejectAllAndEdit = () => {
    history.push(`/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${id}/${routes.EDIT}`)
    closeModal()
  }

  const onViewRequests = () => {
    alert('this action not implemented')
    closeModal()
  }

  const handleEdit = () => {
    activeRequests
      ? openModal()
      : history.push(`/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${id}/${routes.EDIT}`)
  }

  const handlePublish = () => {
    publishOffer([id])
  }

  const handleUnpublish = () => {
    unpublishOffer([id])
  }

  const publishButtonProps = localIsPublished
    ? {
      handleOnClick: handleUnpublish,
      text: t('recurring-list.actions.unpublish'),
    }
    : {
      handleOnClick: handlePublish,
      text: t('recurring-list.actions.publish'),
    }

  return (
    <div className="recurring-list">
      <OfferingDetailsTitle typeHeader={t('recurring-list.header.virtual-event')} title={name} />
      <div className="od__heading wrapper content-wrapper">
        <div className="od--shadow-box">
          <div className="od__row od__column-m">
            <div className="od__media-aside">
              {media?.[0] ? <CustomMedia file={{ ...media?.[0].file, originalName: '' }} /> : <img alt={''} />}
            </div>
            <div className="od__column od--padding-info">
              {infoConfig.map((item, i) => (
                <OfferingsDetailsHeaderItem key={i} {...item} />
              ))}
              <Link
                className="od__row od__public-link"
                to={`/${routes.LIVE_VIRTUAL_EVENTS}/${userNickname}/${sequentialId}`}
              >
                <div className="od--main-text od--accented od__public-link--text">
                  {t('recurring-list.info.link-text')}
                </div>
                <div className="od__public-link--arrow" />
              </Link>
            </div>
          </div>
        </div>
        <div className="od--shadow-box od--centered">
          <div className="od--with-padding">
            {isBooked && (
              <HandleEditModal
                isOpen={isOpen}
                closeModal={closeModal}
                onRejectAllAndEdit={onRejectAllAndEdit}
                onViewRequests={onViewRequests}
                onCancel={closeModal}
                {...editButtonProps}
              />
            )}
            <p className="od--uppercase-title mb-24">{t('order-details.live-virtual-event.header')}</p>

            {convertedLimit && (
              <React.Fragment>
                <p className="od--main-text mb-8">{t('recurring-list.actions.occurrences')}</p>
                <p className="od--status-title mb-32">{convertedLimit}</p>
              </React.Fragment>
            )}

            <div className="od__column">
              <Button
                classBtn="button mb-16"
                handleOnClick={handleEdit}
                path={`/${routes.OFFERINGS}/${routes.LIVE_VIRTUAL_EVENTS}/${id}/${routes.EDIT}`}
                text={t('recurring-list.actions.edit')}
                disabled={isBooked}
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
              />
              <Button
                primary
                typeButton="submit"
                fontSize="0.875"
                paddingVertical="16"
                paddingHorizontal="10"
                {...publishButtonProps}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="recurring-list__table wrapper content-wrapper">
        <Filters
          filtersConfig={filtersConfig}
          getFn={useVirtualEventsOfferingDetailsTableFilters}
          setFn={useSetVirtualEventsOfferingDetailsTableFilters}
        />
        <SubFilters
          counterConfig={counterConfig}
          subFiltersConfig={subFiltersConfig}
          getFn={useVirtualEventsOfferingDetailsTableFilters}
          setFn={useSetVirtualEventsOfferingDetailsTableFilters}
        />
        <Table
          tableName={RECURRING_LIST}
          tableConfig={tableConfig}
          actionsConfig={actionsConfig}
          getTableFn={useVirtualEventsOfferingDetailsTable}
          fields={fields}
          getFiltersFn={useVirtualEventsOfferingDetailsTableFilters}
          setFiltersFn={useSetVirtualEventsOfferingDetailsTableFilters}
          fetchTableFn={useFetchVirtualEventsOfferingDetails}
        />
      </div>
    </div>
  )
}

export default RecurringListVirtualEvents
