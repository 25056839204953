
// import compose from 'lodash/fp/compose';

import propOr from 'lodash/fp/propOr';

const getNormalizedAddress = (original) => {
  const _address = propOr('', 'formattedAddress', original);


  return {
    value: original.placeId,
    label: _address,
  };
};
const getNormalizedName = (original) => {
  const name = propOr('', 'name', original);


  return {
    value: original.placeId,
    label: name,
  };
};

export const getAdapter = ({ original }) => {


  const LocationName = getNormalizedName(original);

  const address = getNormalizedAddress(original);

  return { address, LocationName };
};

export const getAddressForSubmit = value => {
  return {
    LocationsName: { value: value.name.label, googlePlaceId: value.LocationName.value },
  }
}
