import React from 'react'

import LineString from '_legacy/components/Table/table-components/types/common/LineString'


const Orders = ({ isLoading, number }) => {
  return (
    <LineString isLoading={isLoading} str={number} />
  )
}

export default Orders
