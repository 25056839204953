import { __, compose, filter, includes, map, prop, propOr, sortBy, where } from 'lodash/fp'
import { getPriceCurrencyLabel } from '_legacy/components/Price/price-config'

export const supportedCurrencies = ['USD', 'EUR', 'MXN', 'CAD', 'BRL']

const getCurrencyOption = ({ code }) => ({
  value: code,
  label: getPriceCurrencyLabel(code),
})

export const getCurrencies = compose(
  map(getCurrencyOption),
  sortBy(prop('order')),
  map(currency => ({ ...currency, order: supportedCurrencies.indexOf(currency.code) })),
  filter(where({ code: includes(__, supportedCurrencies) })),
  propOr([], 'fetchedCurrencies')
)
