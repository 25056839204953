import { createUseGlobalState, createUseSetGlobalState } from './useGlobalState'

export const useFavoritesTalents = createUseGlobalState('favoritesTalents')
export const useSetFavoritesTalents = createUseSetGlobalState('favoritesTalents')

export const useFavoritesTalentsFilters = createUseGlobalState('favoritesTalentsFilters')
export const useSetFavoritesTalentsFilters = createUseSetGlobalState('favoritesTalentsFilters')

export const useFavoritesOffers = createUseGlobalState('favoritesOffers')
export const useSetFavoritesOffers = createUseSetGlobalState('favoritesOffers')

export const useFavoritesOffersFilters = createUseGlobalState('favoritesOffersFilters')
export const useSetFavoritesOffersFilters = createUseSetGlobalState('favoritesOffersFilters')

const filtersInitialState = {
  sortField: '',
  limit: 10,
  page: 1,
  sortOrder: undefined,
}

export function useClearTalentFilters() {
  return useSetFavoritesTalentsFilters(() => filtersInitialState)
}

export function useClearOffersFilters() {
  return useSetFavoritesOffersFilters(() => filtersInitialState)
}
