import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Title from './Title'
import Description from './Description'

function SlideTemplate({ title, items }) {
  return (
    <StyledWrapper>
      <Title title={title} />
      <Description items={items} />
    </StyledWrapper>
  )
}

const StyledWrapper = styled.div`
  margin-left: 23%;
  width: 60%;
  height: 35vh;
  margin-bottom: 90px;

  @media only screen and (max-width: 768px) {
    height: 60vh;
    margin: 0;
    width: 100%;
  }
`
SlideTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
}

export default SlideTemplate
