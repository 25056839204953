// Modules
import cond from 'lodash/cond'
import isEqual from 'lodash/fp/isEqual'

import { formatDistanceStrict, differenceInMinutes } from 'date-fns'

import { api } from 'shared'

// Utils
import { parseDate, getTimezoneAbbr } from 'utils/services/timezone'
import { getShortDateWithLocaleTime, getTimeRange } from 'utils/date'
import { fromMToMs } from 'utils/convertTime'

const formattedDurationLocale = (timeInMinutes, locale) => {
  return formatDistanceStrict(0, fromMToMs(timeInMinutes), { locale })
}

const getTextFromDuration = ({ timeInMinutes }, locale) => {
  if (!timeInMinutes) return {}

  return {
    mainText: formattedDurationLocale(timeInMinutes, locale),
    subText: '',
  }
}

const getTextFromRange = (dateTime, timeZone, locale) => {
  const { from, to } = dateTime

  if (!from || !to || !timeZone) return {}

  const date = getShortDateWithLocaleTime(from, locale)
  const range = getTimeRange([from, to])
  const duration = formattedDurationLocale(differenceInMinutes(to, from), locale)
  const timeZoneAbbr = getTimezoneAbbr(timeZone)

  return {
    mainText: date,
    subText: `${range} (${timeZoneAbbr} | ${duration})`,
  }
}

export const getDateTimeText = (dateTime, timeZone, locale) => {
  if (!dateTime) return

  const type = dateTime?.type

  const { DURATION, RANGE } = api.talents.talent.offers.DateTimeType

  return cond([
    [isEqual(DURATION), () => getTextFromDuration(dateTime, locale)],
    [isEqual(RANGE), () => getTextFromRange(dateTime, timeZone, locale)],
  ])(type)
}

export const applyTimezoneToDateTime = (dateTime, timeZone) => ({
  ...dateTime,
  date: parseDate(dateTime?.date, timeZone),
  from: parseDate(dateTime?.from, timeZone),
  to: parseDate(dateTime?.to, timeZone),
})

